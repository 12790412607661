// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles-tagMailCC-JVkUa{margin-top:5px;margin-bottom:5px;display:inline-block;padding-top:0.5px;padding-bottom:0.5px}.styles-toolTipMailCC-EcpAx{margin:10px;float:right}.styles-mailTag-Ti4Vc{margin-top:5px}.styles-tagObjectTag-T5QpA{margin-top:5px}.styles-wantsLocationTag-mgVwZ{margin-top:4px}.styles-jobPreferenceTag-ppaRl{margin-top:4px}.styles-tooltipCopy-iHxEr:hover span{display:initial}.styles-tooltipCopy-iHxEr:hover span .styles-tagMailCC-JVkUa{display:inline-block}
`, "",{"version":3,"sources":["webpack://./frontend/src/hooks/contacts/styles.scss"],"names":[],"mappings":"AAAA,wBACE,cAAe,CACf,iBAAkB,CAClB,oBAAqB,CACrB,iBAAkB,CAClB,oBAAqB,CACtB,4BAGC,WAAY,CACZ,WACF,CAAC,sBAGC,cAAe,CAChB,2BAGC,cAAe,CAChB,+BAGC,cAAe,CAChB,+BAGC,cAAe,CAChB,qCAKK,eAAgB,CAHtB,6DAKQ,oBAAqB","sourcesContent":[".tagMailCC {\n  margin-top: 5px;\n  margin-bottom: 5px;\n  display: inline-block;\n  padding-top: 0.5px;\n  padding-bottom: 0.5px;\n}\n\n.toolTipMailCC {\n  margin: 10px;\n  float: right\n}\n\n.mailTag {\n  margin-top: 5px;\n}\n\n.tagObjectTag {\n  margin-top: 5px;\n}\n\n.wantsLocationTag {\n  margin-top: 4px;\n}\n\n.jobPreferenceTag {\n  margin-top: 4px;\n}\n\n.tooltipCopy {\n  &:hover {\n    span {\n      display: initial;\n      .tagMailCC {\n        display: inline-block;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tagMailCC": `styles-tagMailCC-JVkUa`,
	"toolTipMailCC": `styles-toolTipMailCC-EcpAx`,
	"mailTag": `styles-mailTag-Ti4Vc`,
	"tagObjectTag": `styles-tagObjectTag-T5QpA`,
	"wantsLocationTag": `styles-wantsLocationTag-mgVwZ`,
	"jobPreferenceTag": `styles-jobPreferenceTag-ppaRl`,
	"tooltipCopy": `styles-tooltipCopy-iHxEr`
};
export default ___CSS_LOADER_EXPORT___;
