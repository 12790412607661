// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProjectPeriodEndFormItem-container-Nx0Wa{width:100%;text-align:left}.ProjectPeriodEndFormItem-userInput-u2Rt3{width:100%}.ProjectPeriodEndFormItem-tooltip-ePbV8{margin-left:5px}.ProjectPeriodEndFormItem-infoIcon-wutDb{align-self:center}.ProjectPeriodEndFormItem-customDatePicker-L5cXR{width:100%;height:35px}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/project/ProjectBoardPage/ProjectBoardSearchDrawer/ProjectPeriodEndFormItem/ProjectPeriodEndFormItem.scss"],"names":[],"mappings":"AAAA,0CACE,UAAW,CACX,eAAgB,CACjB,0CAGC,UAAW,CACZ,wCAGC,eAAgB,CACjB,yCAGC,iBAAkB,CACnB,iDAGC,UAAW,CACX,WAAY","sourcesContent":[".container {\n  width: 100%;\n  text-align: left;\n}\n\n.userInput {\n  width: 100%;\n}\n\n.tooltip {\n  margin-left: 5px;\n}\n\n.infoIcon {\n  align-self: center;\n}\n\n.customDatePicker {\n  width: 100%;\n  height: 35px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ProjectPeriodEndFormItem-container-Nx0Wa`,
	"userInput": `ProjectPeriodEndFormItem-userInput-u2Rt3`,
	"tooltip": `ProjectPeriodEndFormItem-tooltip-ePbV8`,
	"infoIcon": `ProjectPeriodEndFormItem-infoIcon-wutDb`,
	"customDatePicker": `ProjectPeriodEndFormItem-customDatePicker-L5cXR`
};
export default ___CSS_LOADER_EXPORT___;
