"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var api_1 = require("~/domain/api");
var utils_1 = require("~/utils/utils");
var organizations = function (client) {
    return {
        simple: function (query) {
            var url = api_1.Endpoint.organization.simple;
            if (query) {
                url += "?" + (0, utils_1.createQueryString)(query);
            }
            return client.get(url);
        },
        bulkUpdate: function (postData) {
            var url = api_1.Endpoint.organizations;
            var data = (0, utils_1.convertCamelObjectToSnakeObject)(postData);
            return client.patch(url, data);
        },
    };
};
exports.default = organizations;
