// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PlanCurrentPlanInfo-sectionTitle-Jp7tf{font-size:14px}.PlanCurrentPlanInfo-sectionContent-lLU_B{font-size:24px}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/PlanPage/PlanCurrentUserInfo/PlanCurrentUserInfo.scss"],"names":[],"mappings":"AAAA,wCACI,cAAe,CAClB,0CAGG,cAAe","sourcesContent":[".sectionTitle {\n    font-size: 14px;\n}\n\n.sectionContent {\n    font-size: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sectionTitle": `PlanCurrentPlanInfo-sectionTitle-Jp7tf`,
	"sectionContent": `PlanCurrentPlanInfo-sectionContent-lLU_B`
};
export default ___CSS_LOADER_EXPORT___;
