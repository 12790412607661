// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContactOrganizationNameFormItem-container-pFz80{width:100%;text-align:left}.ContactOrganizationNameFormItem-userInput-sSU1b{width:100%}.ContactOrganizationNameFormItem-tooltip-ttnyz{margin-left:5px}.ContactOrganizationNameFormItem-infoIcon-xFXcf{align-self:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/ContactSearchPage/ContactSearchDrawer/ContactOrganizationNameFormItem/ContactOrganizationNameFormItem.scss"],"names":[],"mappings":"AAAA,iDACI,UAAW,CACX,eAAgB,CACnB,iDAGG,UAAW,CACd,+CAGG,eAAgB,CACnB,gDAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ContactOrganizationNameFormItem-container-pFz80`,
	"userInput": `ContactOrganizationNameFormItem-userInput-sSU1b`,
	"tooltip": `ContactOrganizationNameFormItem-tooltip-ttnyz`,
	"infoIcon": `ContactOrganizationNameFormItem-infoIcon-xFXcf`
};
export default ___CSS_LOADER_EXPORT___;
