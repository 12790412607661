import createEditPage from './Factories/createEditPage';
import Paths from '../Routes/Paths';
import { CONTACT_EDIT_PAGE } from './pageIds';
import ContactForm from '../Forms/ContactForm/ContactForm';
import { Endpoint } from '../../domain/api';
import { convertContactResponseDataEntry, contactFormToAPI } from '../../domain/data';

const pageId = CONTACT_EDIT_PAGE;
const resourceURL = `${Endpoint.getBaseUrl()}/${Endpoint.contacts}`;
const commentTemplateUrl = `${Endpoint.getBaseUrl()}/${Endpoint.commentTemplateContact}`;

const resourceName = 'contacts'
const commentsReducerName = 'contactEditPageComments'
const deleteAuthorized = (authorizedActions) => { return authorizedActions && authorizedActions[resourceName] && authorizedActions[resourceName]['delete'] }
const accessAuthorized = () => { return true }

const ContactEditPageContainer = createEditPage(
  pageId,
  'contactEditPage',
  '取引先担当者 編集',
  ContactForm,
  resourceURL,
  '',
  Paths.contacts,
  convertContactResponseDataEntry,
  contactFormToAPI,
  commentsReducerName,
  commentTemplateUrl,
  deleteAuthorized,
  accessAuthorized,
  resourceName,
  true,
  undefined,
  true,
  Paths.contacts,
  'contactEditPageNewComments',
  'contactEditPageEditComments',
  'contactEditPageReplyComments'
);

export default ContactEditPageContainer;
export {
  pageId,
  commentsReducerName,
  commentTemplateUrl,
};
