import React from "react";
import { Col, Form, Select, Row, Tooltip } from "antd";
import {
    FileTextTwoTone,
    LockTwoTone,
    QuestionCircleFilled,
    ProfileTwoTone,
} from "@ant-design/icons";
import { iconPrimaryColor, Links, iconCustomColor } from "~/utils/constants";
import styles from "./OrganizationDocumentFormItem.scss";

const OrganizationDocumentFormItem = ({ disabled = false }) => {
    const items = [
        {
            value: "has_outsourcing_basic_contact",
            title: "業務委託基本契約",
            icon: <FileTextTwoTone twoToneColor={iconPrimaryColor} />,
        },
        {
            value: "has_nda",
            title: "NDA",
            icon: <LockTwoTone twoToneColor={iconPrimaryColor} />,
        },
        {
            value: "has_handring_personal_information",
            title: "個人情報の取り扱い",
            icon: <ProfileTwoTone twoToneColor={iconPrimaryColor} />,
        },
    ];

    return (
        <Col span={24} style={{ marginBottom: "1%" }}>
            <Row className={styles.container}>
                <Col span={23}>
                    <Form.Item colon={false} name="document" noStyle>
                        <Select
                            showSearch={false}
                            className={styles.container}
                            placeholder="契約書類"
                            mode="multiple"
                            allowClear
                            disabled={disabled}
                            getPopupContainer={(trigger) => trigger.parentNode}>
                            {items.map((item) => {
                                return (
                                    <Select.Option
                                        key={item.value}
                                        className={styles.select_option}
                                        value={item.value}>
                                        {item.icon} {item.title}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={1} className={styles.infoIcon}>
                    <Tooltip
                        title={
                            <span>
                                複数選択をすると
                                <a
                                    href={Links.helps.filter.or}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    OR検索
                                </a>
                            </span>
                        }>
                        <QuestionCircleFilled style={{ color: iconCustomColor }} className={styles.tooltip} />
                    </Tooltip>
                </Col>
            </Row>
        </Col>
    );
};

export default OrganizationDocumentFormItem;
