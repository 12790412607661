// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CorporateScoreFormItem-container-l5ZO5{width:100%;text-align:left}.CorporateScoreFormItem-userInput-aClwf{width:100%}.CorporateScoreFormItem-tooltip-DqwNk{margin-left:5px}.CorporateScoreFormItem-infoIcon-zNXGz{align-self:center}.CorporateScoreFormItem-select_option-IHcOg{text-align:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/OrganizationSearchPage/OrganizationSearchDrawer/CorporateScoreFormItem/CorporateScoreFormItem.scss"],"names":[],"mappings":"AAAA,wCACI,UAAW,CACX,eAAgB,CACnB,wCAGG,UAAW,CACd,sCAGG,eAAgB,CACnB,uCAGG,iBAAkB,CACrB,4CAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n\n.select_option {\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `CorporateScoreFormItem-container-l5ZO5`,
	"userInput": `CorporateScoreFormItem-userInput-aClwf`,
	"tooltip": `CorporateScoreFormItem-tooltip-DqwNk`,
	"infoIcon": `CorporateScoreFormItem-infoIcon-zNXGz`,
	"select_option": `CorporateScoreFormItem-select_option-IHcOg`
};
export default ___CSS_LOADER_EXPORT___;
