// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EmailBody-container-KBhNE{text-align:left}.EmailBody-emailBox-LtBGu{overflow-wrap:break-word;word-wrap:break-word}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/EmailComponents/EmailBody.scss"],"names":[],"mappings":"AAAA,2BACE,eAAgB,CACjB,0BAGC,wBAAyB,CACzB,oBAAqB","sourcesContent":[".container {\n  text-align: left;\n}\n\n.emailBox {\n  overflow-wrap: break-word;\n  word-wrap: break-word;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `EmailBody-container-KBhNE`,
	"emailBox": `EmailBody-emailBox-LtBGu`
};
export default ___CSS_LOADER_EXPORT___;
