// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddonAlert-wrapper-sI7vN{margin-bottom:3%}.AddonAlert-alert-E9012{text-align:left}#AddonAlert-paragraphs-sCgwN{list-style-type:disc;text-align:left}.AddonAlert-paragraph-vn3C4{margin-bottom:5px}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/AddonsPage/components/AddonAlert/AddonAlert.scss"],"names":[],"mappings":"AAAA,0BACI,gBAAiB,CACpB,wBAGG,eAAgB,CACnB,6BAGG,oBAAqB,CACrB,eAAgB,CACnB,4BAGG,iBAAkB","sourcesContent":[".wrapper {\n    margin-bottom: 3%;\n}\n\n.alert {\n    text-align: left;\n}\n\n#paragraphs {\n    list-style-type: disc;\n    text-align: left;\n}\n\n.paragraph {\n    margin-bottom: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `AddonAlert-wrapper-sI7vN`,
	"alert": `AddonAlert-alert-E9012`,
	"paragraphs": `AddonAlert-paragraphs-sCgwN`,
	"paragraph": `AddonAlert-paragraph-vn3C4`
};
export default ___CSS_LOADER_EXPORT___;
