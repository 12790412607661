// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PersonnelModifiedUserFormItem-container-xdkRR{width:100%;text-align:left}.PersonnelModifiedUserFormItem-userInput-wrSfL{width:100%}.PersonnelModifiedUserFormItem-tooltip-acJYP{margin-left:5px}.PersonnelModifiedUserFormItem-infoIcon-bi9aR{align-self:center}.PersonnelModifiedUserFormItem-marginBottom-ZlwJE{margin-bottom:1%}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/personnel/PersonnelBoardPage/PersonnelBoardSearchDrawer/PersonnelModifiedUserFormItem/PersonnelModifiedUserFormItem.scss"],"names":[],"mappings":"AAAA,+CACI,UAAW,CACX,eAAgB,CACnB,+CAGG,UAAW,CACd,6CAGG,eAAgB,CACnB,8CAGG,iBAAkB,CACrB,kDAGG,gBAAiB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n\n.marginBottom {\n    margin-bottom: 1%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PersonnelModifiedUserFormItem-container-xdkRR`,
	"userInput": `PersonnelModifiedUserFormItem-userInput-wrSfL`,
	"tooltip": `PersonnelModifiedUserFormItem-tooltip-acJYP`,
	"infoIcon": `PersonnelModifiedUserFormItem-infoIcon-bi9aR`,
	"marginBottom": `PersonnelModifiedUserFormItem-marginBottom-ZlwJE`
};
export default ___CSS_LOADER_EXPORT___;
