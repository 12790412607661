"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSalesBoardUpdateListOrderAPIMutation = exports.useSalesBoardUpdateListTitleAPIMutation = exports.useSalesBoardDeleteListAPIMutation = exports.useSalesBoardCreateListAPIMutation = exports.useSalesBoardFetchAllListCardsAPIQuery = exports.useSalesBoardFetchListCardsAPIQuery = exports.useSalesBoardFetchListsAPIQuery = void 0;
var react_query_1 = require("react-query");
var useClient_1 = require("~/hooks/useClient");
var useCustomQuery_1 = require("~/hooks/useCustomQuery");
var api_1 = require("~/networking/api");
var constants_1 = require("~/utils/constants");
var useCustomMutation_1 = require("~/hooks/useCustomMutation");
var AlertMessage_1 = require("~/components/Common/AlertMessage/AlertMessage");
var utils_1 = require("~/utils/utils");
var useSalesBoardFetchListsAPIQuery = function (_a) {
    var _b = _a === void 0 ? {} : _a, deps = _b.deps, options = _b.options;
    return (0, useCustomQuery_1.useCustomQuery)({
        queryKey: constants_1.QueryKeys.salesBoard.lists,
        deps: deps,
        options: __assign({}, options),
        apiRequest: api_1.salesAPI.board.fetchLists,
    });
};
exports.useSalesBoardFetchListsAPIQuery = useSalesBoardFetchListsAPIQuery;
var useSalesBoardFetchListCardsAPIQuery = function (_a) {
    var deps = _a.deps, options = _a.options;
    var getClientAuthorization = (0, useClient_1.useClient)().getClientAuthorization;
    return (0, useCustomQuery_1.useCustomQuery)({
        queryKey: constants_1.QueryKeys.salesBoard.cards,
        deps: deps,
        options: __assign(__assign({}, options), { enabled: !!(deps === null || deps === void 0 ? void 0 : deps.listId) && !!getClientAuthorization() }),
        apiRequest: function (query) {
            return api_1.salesAPI.board.fetchListCards(query === null || query === void 0 ? void 0 : query.listId);
        },
    });
};
exports.useSalesBoardFetchListCardsAPIQuery = useSalesBoardFetchListCardsAPIQuery;
var useSalesBoardFetchAllListCardsAPIQuery = function (_a) {
    var _b = _a === void 0 ? {} : _a, deps = _b.deps, options = _b.options;
    return (0, useCustomQuery_1.useCustomQuery)({
        queryKey: constants_1.QueryKeys.sales.board.allListCards,
        deps: deps,
        options: __assign({}, options),
        apiRequest: api_1.salesAPI.board.fetchAllListCards,
    });
};
exports.useSalesBoardFetchAllListCardsAPIQuery = useSalesBoardFetchAllListCardsAPIQuery;
var useSalesBoardCreateListAPIMutation = function () {
    var queryClient = (0, react_query_1.useQueryClient)();
    return (0, useCustomMutation_1.useCustomMutation)(api_1.salesAPI.board.createList, {
        onSuccess: function (_response) {
            queryClient.removeQueries({ queryKey: [constants_1.QueryKeys.sales.board.allListCards], inactive: true });
            queryClient.invalidateQueries(constants_1.QueryKeys.salesBoard.lists);
            queryClient.invalidateQueries(constants_1.QueryKeys.sales.board.allListCards);
            (0, AlertMessage_1.customSuccessMessage)(constants_1.SuccessMessages.generic.create);
        },
        onError: (function (error) {
            (0, utils_1.apiErrorMessage)(error, constants_1.ErrorMessages.generic.create);
        }),
    });
};
exports.useSalesBoardCreateListAPIMutation = useSalesBoardCreateListAPIMutation;
var useSalesBoardDeleteListAPIMutation = function () {
    var queryClient = (0, react_query_1.useQueryClient)();
    return (0, useCustomMutation_1.useCustomMutation)(api_1.salesAPI.board.deleteList, {
        onSuccess: function (_response) {
            queryClient.removeQueries({ queryKey: [constants_1.QueryKeys.sales.board.allListCards], inactive: true });
            queryClient.invalidateQueries(constants_1.QueryKeys.salesBoard.lists);
            queryClient.invalidateQueries(constants_1.QueryKeys.sales.board.allListCards);
            (0, AlertMessage_1.customSuccessMessage)(constants_1.SuccessMessages.generic.delete);
        },
        onError: (function (error) {
            (0, utils_1.apiErrorMessage)(error, constants_1.ErrorMessages.generic.delete);
        }),
    });
};
exports.useSalesBoardDeleteListAPIMutation = useSalesBoardDeleteListAPIMutation;
var useSalesBoardUpdateListTitleAPIMutation = function () {
    var queryClient = (0, react_query_1.useQueryClient)();
    var apiRequest = function (_a) {
        var listId = _a.listId, title = _a.title;
        return api_1.salesAPI.board.updateListTitle(listId, title);
    };
    return (0, useCustomMutation_1.useCustomMutation)(apiRequest, {
        onSuccess: function (_response) {
            queryClient.removeQueries({ queryKey: [constants_1.QueryKeys.sales.board.allListCards], inactive: true });
            queryClient.invalidateQueries(constants_1.QueryKeys.salesBoard.lists);
            queryClient.invalidateQueries(constants_1.QueryKeys.sales.board.allListCards);
        },
        onError: (function (error) {
            (0, utils_1.apiErrorMessage)(error, constants_1.ErrorMessages.generic.update);
        }),
    });
};
exports.useSalesBoardUpdateListTitleAPIMutation = useSalesBoardUpdateListTitleAPIMutation;
var useSalesBoardUpdateListOrderAPIMutation = function () {
    var queryClient = (0, react_query_1.useQueryClient)();
    var apiRequest = function (_a) {
        var listId = _a.listId, to = _a.to;
        return api_1.salesAPI.board.changeListPosition(listId, to.toPosition);
    };
    return (0, useCustomMutation_1.useCustomMutation)(apiRequest, {
        onSuccess: function () {
            queryClient.removeQueries({ queryKey: [constants_1.QueryKeys.sales.board.allListCards], inactive: true });
            queryClient.invalidateQueries(constants_1.QueryKeys.salesBoard.lists);
            queryClient.invalidateQueries(constants_1.QueryKeys.sales.board.allListCards);
        },
        onError: function (_err, _params, context) {
            return void queryClient.setQueriesData({
                queryKey: constants_1.QueryKeys.sales.board.allListCards,
                exact: false,
            }, function () { return context === null || context === void 0 ? void 0 : context.oldData; });
        },
    });
};
exports.useSalesBoardUpdateListOrderAPIMutation = useSalesBoardUpdateListOrderAPIMutation;
