// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ScheduledEmailSubjectFormItem-container-OKLBf{width:100%;text-align:left}.ScheduledEmailSubjectFormItem-userInput-lvR4H{width:100%}.ScheduledEmailSubjectFormItem-tooltip-xoy67{margin-left:5px}.ScheduledEmailSubjectFormItem-infoIcon-E35vv{align-self:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/ScheduledEmailSearchPage/ScheduledEmailSearchDrawer/ScheduledEmailSubjectFormItem/ScheduledEmailSubjectFormItem.scss"],"names":[],"mappings":"AAAA,+CACI,UAAW,CACX,eAAgB,CACnB,+CAGG,UAAW,CACd,6CAGG,eAAgB,CACnB,8CAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ScheduledEmailSubjectFormItem-container-OKLBf`,
	"userInput": `ScheduledEmailSubjectFormItem-userInput-lvR4H`,
	"tooltip": `ScheduledEmailSubjectFormItem-tooltip-xoy67`,
	"infoIcon": `ScheduledEmailSubjectFormItem-infoIcon-E35vv`
};
export default ___CSS_LOADER_EXPORT___;
