// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UploadDragger-timestamp-Y_G6B{margin-left:21px;font-size:80%;color:#989898}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Common/BoardCommon/UploadDragger/UploadDragger.scss"],"names":[],"mappings":"AAAA,+BACE,gBAAiB,CACjB,aAAc,CACd,aAAc","sourcesContent":[".timestamp {\n  margin-left: 21px;\n  font-size: 80%;\n  color: #989898;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"timestamp": `UploadDragger-timestamp-Y_G6B`
};
export default ___CSS_LOADER_EXPORT___;
