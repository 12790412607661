// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CorporateCategoryFormItem-container-HeWFm{width:100%;text-align:left}.CorporateCategoryFormItem-userInput-hAAkQ{width:100%}.CorporateCategoryFormItem-tooltip-SviHi{margin-left:5px}.CorporateCategoryFormItem-infoIcon-w6CsD{align-self:center}.CorporateCategoryFormItem-select_option-BdDYe{text-align:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/OrganizationSearchPage/OrganizationSearchDrawer/CorporateCategoryFormItem/CorporateCategoryFormItem.scss"],"names":[],"mappings":"AAAA,2CACI,UAAW,CACX,eAAgB,CACnB,2CAGG,UAAW,CACd,yCAGG,eAAgB,CACnB,0CAGG,iBAAkB,CACrB,+CAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n\n.select_option {\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `CorporateCategoryFormItem-container-HeWFm`,
	"userInput": `CorporateCategoryFormItem-userInput-hAAkQ`,
	"tooltip": `CorporateCategoryFormItem-tooltip-SviHi`,
	"infoIcon": `CorporateCategoryFormItem-infoIcon-w6CsD`,
	"select_option": `CorporateCategoryFormItem-select_option-BdDYe`
};
export default ___CSS_LOADER_EXPORT___;
