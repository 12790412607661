"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertUploadFileToUploadedFileModel = void 0;
var convertUploadFileToUploadedFileModel = function (data) {
    var _a, _b, _c;
    var response = data.response;
    var id = data.uid;
    var url = (_a = data.url) !== null && _a !== void 0 ? _a : "";
    var timestamp = (_b = data.timestamp) !== null && _b !== void 0 ? _b : new Date().toISOString();
    if (response) {
        id = response.id;
        url = response.file;
        if (data.status !== "done") {
            timestamp = response.createdTime;
        }
    }
    var obj = {
        id: id,
        uid: id,
        file: url,
        url: url,
        name: data.name,
        status: (_c = data.status) !== null && _c !== void 0 ? _c : "success",
        timestamp: timestamp,
    };
    return obj;
};
exports.convertUploadFileToUploadedFileModel = convertUploadFileToUploadedFileModel;
