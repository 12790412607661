// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SalesDescriptionFormItem-container-Om5n5{width:100%;text-align:left}.SalesDescriptionFormItem-userInput-X7So0{width:100%}.SalesDescriptionFormItem-tooltip-WNFDu{margin-left:5px}.SalesDescriptionFormItem-infoIcon-_zGOS{align-self:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/sales/SalesBoardPage/SalesBoardSearchDrawer/SalesDescriptionFormItem/SalesDescriptionFormItem.scss"],"names":[],"mappings":"AAAA,0CACI,UAAW,CACX,eAAgB,CACnB,0CAGG,UAAW,CACd,wCAGG,eAAgB,CACnB,yCAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `SalesDescriptionFormItem-container-Om5n5`,
	"userInput": `SalesDescriptionFormItem-userInput-X7So0`,
	"tooltip": `SalesDescriptionFormItem-tooltip-WNFDu`,
	"infoIcon": `SalesDescriptionFormItem-infoIcon-_zGOS`
};
export default ___CSS_LOADER_EXPORT___;
