// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProjectBoardCreatePopover-container-MFZvm{width:300px}.ProjectBoardCreatePopover-formWrapper-UrZoS{width:100%;display:flex;flex-direction:column;align-items:center;justify-content:center}.ProjectBoardCreatePopover-wrapper-UK7By{width:150px;margin-top:10px;margin-bottom:10px}.ProjectBoardCreatePopover-input-m1X3a{width:260px}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/project/ProjectBoardPage/ProjectBoardCreatePopover/ProjectBoardCreatePopover.scss"],"names":[],"mappings":"AAAA,2CACI,WAAY,CACf,6CAGG,UAAW,CACX,YAAa,CACb,qBAAsB,CACtB,kBAAmB,CACnB,sBAAuB,CAE1B,yCAGG,WAAY,CACZ,eAAgB,CAChB,kBAAmB,CACtB,uCAGG,WAAY","sourcesContent":[".container {\n    width: 300px;\n}\n\n.formWrapper {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n\n}\n\n.wrapper {\n    width: 150px;\n    margin-top: 10px;\n    margin-bottom: 10px;\n}\n\n.input{\n    width: 260px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ProjectBoardCreatePopover-container-MFZvm`,
	"formWrapper": `ProjectBoardCreatePopover-formWrapper-UrZoS`,
	"wrapper": `ProjectBoardCreatePopover-wrapper-UK7By`,
	"input": `ProjectBoardCreatePopover-input-m1X3a`
};
export default ___CSS_LOADER_EXPORT___;
