import createEditPage from "~/components/Pages/Factories/createEditPage";
import { SHARED_EMAIL_SETTING_PAGE } from "~/components/Pages/pageIds";
import SharedEmailSettingForm from "./components/SharedEmailSettingForm";
import { Endpoint } from "~/domain/api";
import Paths from "~/components/Routes/Paths";
import { convertSharedEmailSettingDataEntry } from "~/domain/data";

const pageId = SHARED_EMAIL_SETTING_PAGE;
const resourceURL = `${Endpoint.getBaseUrl()}/${Endpoint.sharedEmailSetting}`;

const resourceName = "shared_email_settings";
const accessAuthorized = (authorizedActions) => {
    return (
        authorizedActions &&
        authorizedActions[resourceName] &&
        authorizedActions[resourceName]["_all"]
    );
};

const SharedEmailSettingPage = createEditPage(
    pageId,
    "sharedEmailSettingPage",
    "マッチングメール設定",
    SharedEmailSettingForm,
    resourceURL,
    "",
    Paths.index,
    convertSharedEmailSettingDataEntry,
    (data) => {
        const protocol = data.protocol;
        const archive = protocol.includes("imap") ? data.archive : "";
        return {
            ...data,
            archive,
        };
    },
    undefined,
    undefined,
    () => {
        return true;
    },
    accessAuthorized,
    undefined,
    false
);
export default SharedEmailSettingPage;
