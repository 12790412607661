// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BoardBaseCardAssigneesSelect-select-V3Fog{width:100%}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Common/Board/BoardBaseCard/BoardBaseCardAssignees/BoardBaseCardAssigneePopover/BoardBaseCardAssigneesSelect/BoardBaseCardAssigneesSelect.scss"],"names":[],"mappings":"AAAA,2CACI,UAAW","sourcesContent":[".select {\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select": `BoardBaseCardAssigneesSelect-select-V3Fog`
};
export default ___CSS_LOADER_EXPORT___;
