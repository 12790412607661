// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CorporateTelFormItem-container-j7auW{width:100%;text-align:left}.CorporateTelFormItem-phonenumberInput-bt66_{width:100%}.CorporateTelFormItem-tooltip-uf1Jx{margin-left:5px}.CorporateTelFormItem-inlineField-W1M7O{display:inline-block;_display:inline}.CorporateTelFormItem-infoIcon-DKi7y{align-self:center}.CorporateTelFormItem-separater-dl_ov{align-self:center;text-align:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/OrganizationSearchPage/OrganizationSearchDrawer/CorporateTelFormItem/CorporateTelFormItem.scss"],"names":[],"mappings":"AAAA,sCACI,UAAW,CACX,eAAgB,CACnB,6CAGG,UAAW,CACd,oCAGG,eAAgB,CACnB,wCAGG,oBAAqB,EACrB,cAAgB,CACnB,qCAGG,iBAAkB,CACrB,sCAGG,iBAAkB,CAClB,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.phonenumberInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.inlineField {\n    display: inline-block;\n    _display: inline;\n}\n\n.infoIcon {\n    align-self: center;\n}\n\n.separater {\n    align-self: center;\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `CorporateTelFormItem-container-j7auW`,
	"phonenumberInput": `CorporateTelFormItem-phonenumberInput-bt66_`,
	"tooltip": `CorporateTelFormItem-tooltip-uf1Jx`,
	"inlineField": `CorporateTelFormItem-inlineField-W1M7O`,
	"infoIcon": `CorporateTelFormItem-infoIcon-DKi7y`,
	"separater": `CorporateTelFormItem-separater-dl_ov`
};
export default ___CSS_LOADER_EXPORT___;
