// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContactEmailPreferenceSearchForm-container-_rW2h{margin:16px auto;text-align:center;border:thin solid darkgray}.ContactEmailPreferenceSearchForm-headStyle-yeP7N{background-color:#cccccc}.ContactEmailPreferenceSearchForm-bodyStyle-s8Jum{background-color:#ffffff}.ContactEmailPreferenceSearchForm-row-GCy4n{padding-right:10%}.ContactEmailPreferenceSearchForm-field-WOyML{margin:8px auto}.ContactEmailPreferenceSearchForm-searchButton-XJxGS{margin:8px auto;text-align:left}.ContactEmailPreferenceSearchForm-inlineField-JUa64{margin:8px auto;display:inline-block;_display:inline}.ContactEmailPreferenceSearchForm-divider-K0tft{line-height:0.8;margin:8px auto !important}.ContactEmailPreferenceSearchForm-button-TRZbk{margin:16px 10%;width:24%}.ContactEmailPreferenceSearchForm-buttonWrapper-il_bl{text-align:center}.ContactEmailPreferenceSearchForm-alert-O8NtU{margin-top:16px;margin-bottom:16px}.ContactEmailPreferenceSearchForm-horizontalCheckboxStyle-JK4J1{margin-left:0 !important}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Forms/SearchForm.scss"],"names":[],"mappings":"AAAA,kDACE,gBAAiB,CACjB,iBAAkB,CAClB,0BAA2B,CAC5B,kDAGC,wBAAyB,CAC1B,kDAGC,wBAAyB,CAC1B,4CAGC,iBAAkB,CACnB,8CAGC,eAAgB,CACjB,qDAGC,eAAgB,CAChB,eAAgB,CACjB,oDAGC,eAAgB,CAChB,oBAAqB,EACrB,cAAgB,CACjB,gDAGC,eAAgB,CAChB,0BACF,CAAC,+CAGC,eAAgB,CAChB,SAAU,CACX,sDAGC,iBAAkB,CACnB,8CAGC,eAAgB,CAChB,kBAAmB,CACpB,gEAGG,wBAAyB","sourcesContent":[".container {\n  margin: 16px auto;\n  text-align: center;\n  border: thin solid darkgray;\n}\n\n.headStyle{\n  background-color: #cccccc;\n}\n\n.bodyStyle{\n  background-color: #ffffff;\n}\n\n.row {\n  padding-right: 10%;\n}\n\n.field {\n  margin: 8px auto;\n}\n\n.searchButton {\n  margin: 8px auto;\n  text-align: left;\n}\n\n.inlineField {\n  margin: 8px auto;\n  display: inline-block;\n  _display: inline;\n}\n\n.divider {\n  line-height: 0.8;\n  margin: 8px auto !important\n}\n\n.button {\n  margin: 16px 10%;\n  width: 24%;\n}\n\n.buttonWrapper {\n  text-align: center;\n}\n\n.alert {\n  margin-top: 16px;\n  margin-bottom: 16px;\n}\n\n.horizontalCheckboxStyle {\n    margin-left: 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ContactEmailPreferenceSearchForm-container-_rW2h`,
	"headStyle": `ContactEmailPreferenceSearchForm-headStyle-yeP7N`,
	"bodyStyle": `ContactEmailPreferenceSearchForm-bodyStyle-s8Jum`,
	"row": `ContactEmailPreferenceSearchForm-row-GCy4n`,
	"field": `ContactEmailPreferenceSearchForm-field-WOyML`,
	"searchButton": `ContactEmailPreferenceSearchForm-searchButton-XJxGS`,
	"inlineField": `ContactEmailPreferenceSearchForm-inlineField-JUa64`,
	"divider": `ContactEmailPreferenceSearchForm-divider-K0tft`,
	"button": `ContactEmailPreferenceSearchForm-button-TRZbk`,
	"buttonWrapper": `ContactEmailPreferenceSearchForm-buttonWrapper-il_bl`,
	"alert": `ContactEmailPreferenceSearchForm-alert-O8NtU`,
	"horizontalCheckboxStyle": `ContactEmailPreferenceSearchForm-horizontalCheckboxStyle-JK4J1`
};
export default ___CSS_LOADER_EXPORT___;
