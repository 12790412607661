// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ScheduledMailTemplateModal-modalButton-G28XU{width:100%}.ScheduledMailTemplateModal-listContainer-rwQ6m{max-height:35vh;overflow-x:scroll}.ScheduledMailTemplateModal-listContainer-rwQ6m li div{width:100%}.ScheduledMailTemplateModal-modalActions-RYK0K{margin-top:2%}.ScheduledMailTemplateModal-editCancelButton-XsxRf{margin-left:8px}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Modals/ScheduledMailTemplateModal/ScheduledMailTemplateModal.scss"],"names":[],"mappings":"AAAA,8CACE,UAAW,CACZ,gDAGC,eAAgB,CAChB,iBAAkB,CAFpB,uDAKU,UAAW,CACd,+CAKL,aAAc,CACf,mDAGC,eAAgB","sourcesContent":[".modalButton {\n  width: 100%;\n}\n\n.listContainer {\n  max-height: 35vh;\n  overflow-x: scroll;\n  li {\n      div {\n          width: 100%;\n      }\n  }\n}\n\n.modalActions {\n  margin-top: 2%;\n}\n\n.editCancelButton {\n  margin-left: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalButton": `ScheduledMailTemplateModal-modalButton-G28XU`,
	"listContainer": `ScheduledMailTemplateModal-listContainer-rwQ6m`,
	"modalActions": `ScheduledMailTemplateModal-modalActions-RYK0K`,
	"editCancelButton": `ScheduledMailTemplateModal-editCancelButton-XsxRf`
};
export default ___CSS_LOADER_EXPORT___;
