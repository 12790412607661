import queryString from 'query-string';

export const deleteEmptyValue = (params) => {
  const newObject = {};
  Object.assign(newObject, params);
  for (const key in newObject) {
    if (Object.prototype.hasOwnProperty.call(newObject, key)) {
      if (newObject[key] === '' || newObject[key] === undefined) {
        delete newObject[key];
      }
    }
  }
  return newObject;
};

export const arrayElementToString = (params) => {
  const newObject = {};
  Object.assign(newObject, params);
  for (const key in newObject) {
    if (Object.prototype.hasOwnProperty.call(newObject, key)) {
      if (Array.isArray(params[key])) {
        newObject[key] = params[key].join(',');
      }
    }
  }
  return newObject;
};

// params: an object that contains key-value pairs of query parameters.
// converter: An Function that convert key name of the params.
const objectToQueryString = params => queryString.stringify(arrayElementToString(deleteEmptyValue(params)));

export default objectToQueryString;
