// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BoardChecklist-wrapper-f3Hu_{width:100%;margin-bottom:1.5rem}.BoardChecklist-form-KP_Hi{width:100%}.BoardChecklist-title-t6UAF{font-size:1.1rem;font-weight:bold;padding-left:0;width:100%}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Common/Board/BoardChecklist/BoardChecklist.scss"],"names":[],"mappings":"AAAA,8BACI,UAAW,CACX,oBAAqB,CACxB,2BAGG,UAAW,CACd,4BAGG,gBAAiB,CACjB,gBAAiB,CACjB,cAAe,CACf,UAAW","sourcesContent":[".wrapper {\n    width: 100%;\n    margin-bottom: 1.5rem;\n}\n\n.form {\n    width: 100%;\n}\n\n.title {\n    font-size: 1.1rem;\n    font-weight: bold;\n    padding-left: 0;\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `BoardChecklist-wrapper-f3Hu_`,
	"form": `BoardChecklist-form-KP_Hi`,
	"title": `BoardChecklist-title-t6UAF`
};
export default ___CSS_LOADER_EXPORT___;
