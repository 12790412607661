"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePersonnelBoardUpdateScheduledMailTemplateMutateAPIMutation = exports.usePersonnelBoardFetchSCheduledEmailTemplateAPIQuery = void 0;
var boardCommon_1 = require("~/hooks/boardCommon");
var board_1 = require("~/hooks/common/board");
var useAuthorizedQuery_1 = require("~/hooks/useAuthorizedQuery");
var useClient_1 = require("~/hooks/useClient");
var useCustomMutation_1 = require("~/hooks/useCustomMutation");
var api_1 = require("~/networking/api");
var constants_1 = require("~/utils/constants");
var utils_1 = require("~/utils/utils");
var usePersonnelBoardFetchSCheduledEmailTemplateAPIQuery = function (_a) {
    var deps = _a.deps, options = _a.options;
    var getClientAuthorization = (0, useClient_1.useClient)().getClientAuthorization;
    return (0, useAuthorizedQuery_1.useAuthorizedQuery)({
        queryKey: constants_1.QueryKeys.personnel.board.scheduledEmailTemplate.fetch,
        deps: deps,
        options: __assign(__assign({}, options), { select: boardCommon_1.convertBoardScheduledEmailTemplateResponseModelToBoardScheduledEmailTemplateModel, enabled: !!(deps === null || deps === void 0 ? void 0 : deps.cardId) && !!getClientAuthorization() }),
        apiRequest: function (query) {
            return api_1.personnelAPI.board.fetchScheduledEmailTemplate(query === null || query === void 0 ? void 0 : query.cardId);
        },
    });
};
exports.usePersonnelBoardFetchSCheduledEmailTemplateAPIQuery = usePersonnelBoardFetchSCheduledEmailTemplateAPIQuery;
var usePersonnelBoardUpdateScheduledMailTemplateMutateAPIMutation = function () {
    var apiRequest = function (_a) {
        var cardId = _a.cardId, templateId = _a.templateId, postData = _a.postData;
        var data = (0, board_1.convertBoardScheduledEmailTemplateFormModelToBoardScheduledEmailTemplateModel)(postData);
        return api_1.personnelAPI.board.patchScheduledEmailCard(cardId, data, templateId);
    };
    return (0, useCustomMutation_1.useCustomMutation)(apiRequest, {
        onSuccess: function (response) { },
        onError: function (err) {
            (0, utils_1.apiErrorMessage)(err, constants_1.ErrorMessages.generic.update);
        },
    });
};
exports.usePersonnelBoardUpdateScheduledMailTemplateMutateAPIMutation = usePersonnelBoardUpdateScheduledMailTemplateMutateAPIMutation;
