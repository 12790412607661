"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useValidateCcEmailAPIMutation = exports.useValidateEmailAPIMutation = void 0;
var recoil_1 = require("recoil");
var useCustomMutation_1 = require("~/hooks/useCustomMutation");
var constants_1 = require("~/utils/constants");
var utils_1 = require("~/utils/utils");
var networking_1 = require("../networking");
var atom_1 = require("~/recoil/atom");
var useValidateEmailAPIMutation = function () {
    var setIsAppLoading = (0, recoil_1.useSetRecoilState)(atom_1.isAppLoading);
    var apiRequest = function (postData) {
        return networking_1.validateEmailAPI.checkEmail(postData);
    };
    return (0, useCustomMutation_1.useCustomMutation)(apiRequest, {
        onMutate: function () {
            setIsAppLoading(true);
        },
        onSuccess: function (response) {
            (0, utils_1.apiSuccessMessage)(response, constants_1.SuccessMessages.emailValidation.email);
        },
        onError: function (err) {
            (0, utils_1.apiErrorMessage)(err, constants_1.ErrorMessages.emailValidation.email);
        },
        onSettled: function () {
            setIsAppLoading(false);
        },
    });
};
exports.useValidateEmailAPIMutation = useValidateEmailAPIMutation;
var useValidateCcEmailAPIMutation = function () {
    var setIsAppLoading = (0, recoil_1.useSetRecoilState)(atom_1.isAppLoading);
    var apiRequest = function (postData) {
        var data = {
            cc_emails: postData.ccEmails,
        };
        return networking_1.validateEmailAPI.checkCcEmail(data);
    };
    return (0, useCustomMutation_1.useCustomMutation)(apiRequest, {
        onMutate: function () {
            setIsAppLoading(true);
        },
        onSuccess: function (response) {
            (0, utils_1.apiSuccessMessage)(response, constants_1.SuccessMessages.emailValidation.ccEmail);
        },
        onError: function (err) {
            (0, utils_1.apiErrorMessage)(err, constants_1.ErrorMessages.emailValidation.ccEmail);
        },
        onSettled: function () {
            setIsAppLoading(false);
        },
    });
};
exports.useValidateCcEmailAPIMutation = useValidateCcEmailAPIMutation;
