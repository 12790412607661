// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CommentDeleteButton-button-u6sDB{padding:0;margin:0 !important}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/DataDisplay/CommentList/CommentDeleteButton/CommentDeleteButton.scss"],"names":[],"mappings":"AAAA,kCACI,SAAU,CACV,mBAAoB","sourcesContent":[".button {\n    padding: 0;\n    margin: 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `CommentDeleteButton-button-u6sDB`
};
export default ___CSS_LOADER_EXPORT___;
