import React from "react";
import { QueryClient } from "react-query";
import createSearchPage from "~/components/Pages/Factories/createSearchPage";
import { SHARED_EMAIL_NOTIFICATION_SEARCH_PAGE } from "~/components/Pages/pageIds";
import {
    SharedEmailNotificationsTable,
    SharedEmailNotificationTableDisplaySettingModal,
} from "~/components/Tables/SharedEmailNotificationsTable";
import { Endpoint } from "~/domain/api";
import {
    convertSharedEmailNotificationResponseDataEntry,
    sharedEmailNotificationSearchParamToAPI,
} from "~/domain/data";
import SharedEmailNotificationAddButton from "~/components/Forms/SharedEmailNotificationAddButton";
import SharedEmailNotificationSearchDrawer from "./SharedEmailNotificationSearchDrawer/SharedEmailNotificationSearchDrawer";
import { useFetchSharedEmailSettingAPIQuery } from "~/hooks/sharedEmailSetting";
import NotFoundPage from "../NotFoundPage";
import SpinContainer from "~/components/Common/SpinContainer";
import { QueryKeys } from "~/utils/constants";
import { queryClient } from "~/utils/utils";

const pageId = SHARED_EMAIL_NOTIFICATION_SEARCH_PAGE;
const resourceURL = `${Endpoint.getBaseUrl()}/${
    Endpoint.sharedEmailNotifications
}`;

// NOTE: Take care since there is a subtle difference.
const resourceNameForAuthorizedAction = "shared_email_notification";
const resourceNameForReducer = "shared_email_notifications";

const accessAuthorized = (authorizedActions) => {
    return (
        authorizedActions &&
        authorizedActions[resourceNameForAuthorizedAction] &&
        authorizedActions[resourceNameForAuthorizedAction]["read"]
    );
};
const deleteAuthorized = (authorizedActions) => {
    return (
        authorizedActions &&
        authorizedActions[resourceNameForAuthorizedAction] &&
        authorizedActions[resourceNameForAuthorizedAction]["delete"]
    );
};

export const searchConditionSanitizer = (unshowList, targetObj) => {
    const newData = { ...targetObj };
    for (const fieldName of unshowList) {
        if (fieldName === "name") {
            newData["name"] = undefined;
        } else {
            newData[fieldName] = undefined;
        }
    }
    return newData;
};

const searchTemplatedAuthorized = () => true;

const SharedEmailNotificationSearchPageContainer = createSearchPage(
    pageId,
    "sharedEmailNotificationSearchPage",
    "自動マッチング条件 一覧",
    SharedEmailNotificationSearchDrawer,
    SharedEmailNotificationsTable,
    resourceURL,
    convertSharedEmailNotificationResponseDataEntry,
    sharedEmailNotificationSearchParamToAPI,
    false,
    undefined,
    undefined,
    true,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    deleteAuthorized,
    undefined,
    undefined,
    accessAuthorized,
    undefined,
    searchTemplatedAuthorized,
    resourceNameForReducer,
    false,
    [SharedEmailNotificationAddButton],
    [SharedEmailNotificationTableDisplaySettingModal],
    searchConditionSanitizer,
    undefined,
    undefined,
    undefined,
    () => true,
    () => "",
    QueryKeys.sharedEmailNotificationSetting.assignees,
)

const SharedEmailNotificationSearchPage = (props) => {
    const { data, isLoading } = useFetchSharedEmailSettingAPIQuery({});

    if (isLoading) {
        return <SpinContainer />;
    }

    if (!data?.username) {
        return <NotFoundPage />;
    }

    return <SharedEmailNotificationSearchPageContainer {...props} />
};

export default SharedEmailNotificationSearchPage;
