export const MY_PROFILE_PAGE = 'MY_PROFILE_PAGE';
export const MY_COMPANY_PAGE = 'MY_COMPANY_PAGE';
export const USER_SEARCH_PAGE = 'USER_SEARCH_PAGE';
export const USER_REGISTER_PAGE = 'USER_REGISTER_PAGE';
export const USER_INVITE_PAGE = 'USER_INVITE_PAGE';
export const USER_EDIT_PAGE = 'USER_EDIT_PAGE';
export const CONTACT_SEARCH_PAGE = 'CONTACT_SEARCH_PAGE';
export const CONTACT_REGISTER_PAGE = 'CONTACT_REGISTER_PAGE';
export const CONTACT_CSV_UPLOAD_PAGE = 'CONTACT_CSV_UPLOAD_PAGE';
export const CONTACT_EDIT_PAGE = 'CONTACT_EDIT_PAGE';
export const CONTACT_EMAIL_PREFERENCE_EDIT_PAGE = 'CONTACT_EMAIL_PREFERENCE_EDIT_PAGE';
export const SHARED_EMAIL_PAGE = 'SHARED_EMAIL_PAGE';
export const SHARED_EMAIL_DETAIL_PAGE = 'SHARED_EMAIL_DETAIL_PAGE';
export const SHARED_EMAIL_NOTIFICATION_SEARCH_PAGE = 'SHARED_EMAIL_NOTIFICATION_SEARCH_PAGE';
export const SHARED_EMAIL_NOTIFICATION_REGISTER_PAGE = 'SHARED_EMAIL_NOTIFICATION_REGISTER_PAGE';
export const SHARED_EMAIL_NOTIFICATION_EDIT_PAGE = 'SHARED_EMAIL_NOTIFICATION_EDIT_PAGE';
export const ORGANIZATION_SEARCH_PAGE = 'ORGANIZATION_SEARCH_PAGE';
export const SHARED_MAIL_SEARCH_PAGE = 'SHARED_MAIL_SEARCH_PAGE';
export const ORGANIZATION_REGISTER_PAGE = 'ORGANIZATION_REGISTER_PAGE';
export const ORGANIZATION_CSV_UPLOAD_PAGE = 'ORGANIZATION_CSV_UPLOAD_PAGE';
export const ORGANIZATION_EDIT_PAGE = 'ORGANIZATION_EDIT_PAGE';
export const SCHEDULED_MAIL_SEARCH_PAGE = 'SCHEDULED_MAIL_SEARCH_PAGE';
export const SCHEDULED_MAIL_REGISTER_PAGE = 'SCHEDULED_MAIL_REGISTER_PAGE';
export const SCHEDULED_MAIL_EDIT_PAGE = 'SCHEDULED_MAIL_EDIT_PAGE';
export const DISPLAY_SETTING_PAGE = 'DISPLAY_SETTING_PAGE';
export const SHARED_EMAIL_SETTING_PAGE = 'SHARED_EMAIL_SETTING_PAGE';
export const PASSWORD_CHANGE_PAGE = 'PASSWORD_CHANGE_PAGE';
export const EMAIL_RESEND_PAGE = 'EMAIL_RESEND_PAGE';
export const PERSONNEL_BOARD_PAGE = 'PERSONNEL_BOARD_PAGE';
export const PROJECT_BOARD_PAGE = 'PROJECT_BOARD_PAGE';
export const RECRUIT_BOARD_PAGE = 'RECRUIT_BOARD_PAGE';
export const SALES_BOARD_PAGE = 'SALES_BOARD_PAGE';
export const BACK_OFFICE_BOARD_PAGE = 'BACK_OFFICE_BOARD_PAGE';
export const PERSONNEL_DETAIL_BOARD_PAGE = 'PERSONNEL_DETAIL_BOARD_PAGE';
export const PROJECT_DETAIL_BOARD_PAGE = 'PROJECT_DETAIL_BOARD_PAGE';
export const RECRUIT_DETAIL_BOARD_PAGE = 'RECRUIT_DETAIL_BOARD_PAGE';
export const SALES_DETAIL_BOARD_PAGE = 'SALES_DETAIL_BOARD_PAGE';
export const BACK_OFFICE_DETAIL_BOARD_PAGE = 'BACK_OFFICE_DETAIL_BOARD_PAGE';

// Sub component definition
export const SCHEDULED_MAIL_REGISTER_PAGE_CONTACT_PICKER_FORM = 'SCHEDULED_MAIL_REGISTER_PAGE_CONTACT_PICKER_FORM';
export const SCHEDULED_MAIL_EDIT_PAGE_CONTACT_PICKER_FORM = 'SCHEDULED_MAIL_EDIT_PAGE_CONTACT_PICKER_FORM';
export const SCHEDULED_MAIL_PREVIEW_CONTAINER = 'SCHEDULED_MAIL_PREVIEW_CONTAINER';
export const EMAIL_STATS = 'EMAIL_STATS';
export const ORGANIZATION_STATS = 'ORGANIZATION_STATS';
export const DASHBOARD_STATS = 'DASHBOARD_STATS';
