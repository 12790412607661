// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PersonnelCommentUserFormItem-container-ncgNO{width:100%;text-align:left}.PersonnelCommentUserFormItem-userInput-SsZwg{width:100%}.PersonnelCommentUserFormItem-tooltip-nBrTs{margin-left:5px}.PersonnelCommentUserFormItem-infoIcon-c1YNe{align-self:center}.PersonnelCommentUserFormItem-marginBottom-Us26y{margin-bottom:1%}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/personnel/PersonnelBoardPage/PersonnelBoardSearchDrawer/PersonnelCommentUserFormItem/PersonnelCommentUserFormItem.scss"],"names":[],"mappings":"AAAA,8CACE,UAAW,CACX,eAAgB,CACjB,8CAGC,UAAW,CACZ,4CAGC,eAAgB,CACjB,6CAGC,iBAAkB,CACnB,iDAGC,gBAAiB","sourcesContent":[".container {\n  width: 100%;\n  text-align: left;\n}\n\n.userInput {\n  width: 100%;\n}\n\n.tooltip {\n  margin-left: 5px;\n}\n\n.infoIcon {\n  align-self: center;\n}\n\n.marginBottom {\n  margin-bottom: 1%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PersonnelCommentUserFormItem-container-ncgNO`,
	"userInput": `PersonnelCommentUserFormItem-userInput-SsZwg`,
	"tooltip": `PersonnelCommentUserFormItem-tooltip-nBrTs`,
	"infoIcon": `PersonnelCommentUserFormItem-infoIcon-c1YNe`,
	"marginBottom": `PersonnelCommentUserFormItem-marginBottom-Us26y`
};
export default ___CSS_LOADER_EXPORT___;
