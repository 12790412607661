import format from "string-format";

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Card, PageHeader, Spin} from "antd";

import {
    createAction,
    clearAction,
    fetchDisplaySettingAction,
    fetchApi,
} from "../../../actions/data";
import styles from "../page.scss";

import { Endpoint } from "../../../domain/api";

import {
    AUTHORIZED_ACTION_LOADING,
    AUTHORIZED_ACTION_LOADED,
} from "../../../actions/actionTypes";
import CustomBackToTop from "~/components/Common/CustomBackToTop/CustomBackToTop";
import { customSuccessMessage, customErrorMessage } from "~/components/Common/AlertMessage/AlertMessage";
import NotFoundPage from "../NotFoundPage";

const defaultResponseConverter = (data) => data;
const defaultFormConverter = (data) => data;

const displaySettingURL = `${Endpoint.getBaseUrl()}/${Endpoint.displaySetting}`;

const isEmpty = (array) => array.length === 0;

/**
 * This Factory creates a page component class that has common several handlers and data loading methods.
 * @param {string} pageId - A PageId that is used to dispatch action to corresponding reducer.
 * @param {string} reducerName - A reducerName that correspond to the page and that is defined in components/reducers/pages.js.
 * @param {string} pageTitle - A page title string, that will be rendered to a page header section.
 * @param {class} FormClass - A Subclass of component/Forms/base/Baseform, that corresponds to this page's resource.
 * @param {string} resourceURL - An endpoint URL of the target resources, correspond to the backend list API.
 * @param {string} redirectPathAfterCreate - An URL that will be invoked after the new resource has created.
 * @param {function} convertResponseDataEntry - A callback function that receives one object and returns new converted object.
 * @param {function} convertFormDataToAPI - A callback function that convert the form data to API Body data.
 * @return {element} A React component.
 */
const createRegisterPage = (
    pageId,
    reducerName,
    pageTitle,
    FormClass,
    resourceURL,
    redirectPathAfterCreate,
    convertResponseDataEntry = defaultResponseConverter,
    convertFormDataToAPI = defaultFormConverter,
    resourceName = undefined,
    accessAuthorized = () => {
        return true;
    }
) => {
    const Page = class extends Component {
        componentDidMount() {
            this.fetchDisplaySettingData();
        }

        fetchDisplaySettingData() {
            const { token, dispatch } = this.props;
            dispatch(
                fetchDisplaySettingAction(pageId, token, displaySettingURL)
            );
        }

        componentDidUpdate() {
            // Extracting props.
            const { pageState } = this.props;
            const { created, message, errorMessage } = pageState;
            // Popup messages if necessary.
            if (message) {
                customSuccessMessage(message);
            }
            if (errorMessage) {
                customErrorMessage(errorMessage);
            }
            // Redirect after resource creation.
            if (created && redirectPathAfterCreate) {
                this.moveToNextPage();
            }
        }

        componentWillUnmount() {
            const { dispatch } = this.props;
            dispatch(clearAction(pageId));
        }

        moveToNextPage = () => {
            const { history, pageState } = this.props;
            const { data } = pageState;
            const { id } = data;

            // Note: If redirectPathAfterCreate contains the holder {id},
            // Following code replaces it with a created resource's ID.
            const redirectPath = format(redirectPathAfterCreate, { id });

            history.push(redirectPath);
        };

        submitHandler = (values) => {
            const { token, dispatch } = this.props;
            dispatch(
                createAction(
                    pageId,
                    token,
                    resourceURL,
                    convertFormDataToAPI(values),
                    convertResponseDataEntry
                )
            );
        };

        resetFormHandler = () => {
            const { dispatch } = this.props;
            dispatch(clearAction(pageId));
        };

        render() {
            const { history, pageState, authorizedActions } = this.props;
            const {
                loading,
                data,
                fieldErrors,
                created,
                tagResisterResult,
                comment_templates,
                totalAvailableCount,
                displaySetting,
            } = pageState;

            if (!accessAuthorized(authorizedActions)) {
                return (
                    <div className={styles.container}>
                        <NotFoundPage {...this.props} />
                    </div>
                );
            }

            return (
                <div className={styles.container}>
                    <CustomBackToTop />
                    <Spin spinning={loading}>
                        <PageHeader
                            className={styles.pageHeader}
                            title={pageTitle}
                        />
                        <Card bodyStyle={{ padding: "8px" }}>
                            <FormClass
                                resourceURL={resourceURL}
                                initialData={data}
                                fieldErrors={fieldErrors}
                                submitHandler={this.submitHandler}
                                resetFormHandler={this.resetFormHandler}
                                created={created}
                                pageId={pageId}
                                tagResisterResult={tagResisterResult}
                                comment_templates={comment_templates}
                                totalAvailableCount={totalAvailableCount}
                                selectedRequireItemKeys={
                                    displaySetting &&
                                    displaySetting[resourceName] &&
                                    displaySetting[resourceName]["require"]
                                        ? displaySetting[resourceName][
                                              "require"
                                          ]
                                        : []
                                }
                                commentsReducerName={reducerName}
                            />
                        </Card>
                    </Spin>
                </div>
            );
        }
    };

    Page.propTypes = {
        dispatch: PropTypes.func.isRequired,
        history: PropTypes.shape({
            goBack: PropTypes.func.isRequired,
            push: PropTypes.func.isRequired,
        }).isRequired,
        token: PropTypes.string.isRequired,
        pageState: PropTypes.shape({
            loading: PropTypes.bool.isRequired,
            created: PropTypes.bool.isRequired,
            message: PropTypes.string.isRequired,
            errorMessage: PropTypes.string.isRequired,
            data: PropTypes.object.isRequired, // Just passing to a child component.
            fieldErrors: PropTypes.object.isRequired, // Just passing to a child component.
            tagResisterResult: PropTypes.string,
            comment_templates: PropTypes.arrayOf(
                PropTypes.shape({
                    title: PropTypes.string.isRequired,
                    content: PropTypes.string.isRequired,
                })
            ).isRequired,
            totalAvailableCount: PropTypes.number,
            displaySetting: PropTypes.object,
        }).isRequired,
        authorizedActions: PropTypes.object.isRequired,
    };

    Page.displayName = pageId;

    // Link Reducer state to Component props.
    function mapStateToProps(state) {
        return {
            token: state.login.token,
            pageState: state[reducerName],
            authorizedActions: state.login.authorizedActions,
        };
    }

    return withRouter(connect(mapStateToProps)(Page));
};

export default createRegisterPage;
