"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePersonnelBoardDownloadSkillSheetAPIMutation = exports.usePersonnelBoardDeleteProfileImageAPIMutate = exports.usePersonnelBoardDownloadProfileImageAPIMutation = exports.usePersonnelBoardMutateListAPIMutation = exports.useUpdateContractInformation = exports.useDeletePersonnelCardBoard = exports.useDuplicatePersonnelCardBoard = exports.useSearchScheduledEmail = exports.usePersonnelBoardDetailCard = exports.usePersonnelBoardDeleteContractAPIMutation = exports.usePersonnelBoardCreateUpdateContractAPIMutation = exports.usePersonnelBoardUpdateContractAPIMutation = exports.usePersonnelBoardFetchContractAPIQuery = exports.usePersonnelBoardCreateContractAPIMutation = exports.usePersonnelBoardFetchContractsAPIQuery = exports.usePersonnelBoardFetchSkillSheetAPIQuery = exports.usePersonnelBoardFetchTrainStationsAPIQuery = exports.usePersonnelBoardFetchSubCommentsAPIQuery = exports.usePersonnelBoardDeleteCommentAPIMutation = exports.usePersonnelBoardUpdateCommentAPIMutation = exports.usePersonnelBoardFetchCommentAPIQuery = exports.usePersonnelBoardCreateCommentAPIMutation = exports.usePersonnelBoardFetchCommentsAPIQuery = exports.usePersonnelBoardFetchChecklistItemsAPIQuery = exports.usePersonnelBoardDeleteSkillSheetAPIMutation = exports.usePersonnelBoardChangeCardPositionAPIMutation = exports.usePersonnelBoardDeleteCardAPIMutation = exports.useContractInformationAPIMutation = exports.usePersonnelBoardDuplicateCardAPIMutation = exports.usePersonnelBoardFetchListCardsAPIQuery = exports.usePersonnelBoardFetchListsAPIQuery = void 0;
var react_1 = require("react");
var recoil_1 = require("recoil");
var AlertMessage_1 = require("~/components/Common/AlertMessage/AlertMessage");
var api_1 = require("~/networking/api");
var atom_1 = require("~/recoil/atom");
var constants_1 = require("~/utils/constants");
var download_1 = require("./download");
var board_1 = require("./personnel/board");
var useCustomMutation_1 = require("./useCustomMutation");
var useAuthorizedQuery_1 = require("./useAuthorizedQuery");
var useScheduledEmail_1 = require("./useScheduledEmail");
var defaultDataCardDetail = {
    id: "",
    order: 0,
    listId: "",
    assignees: [],
    priority: {
        title: "中",
        value: "urgent",
    },
    period: {
        end: undefined,
        start: undefined,
        isFinished: false,
    },
    lastNameInitial: "",
    firstNameInitial: "",
    skills: [],
    dynamicRows: [],
    checklist: [],
    comments: [],
    isArchived: false,
};
var usePersonnelBoardFetchListsAPIQuery = function (_a) {
    var deps = _a.deps, options = _a.options;
    return (0, useAuthorizedQuery_1.useAuthorizedQuery)({
        queryKey: constants_1.QueryKeys.personnelBoard.lists,
        deps: deps,
        options: __assign({}, options),
        apiRequest: api_1.personnelAPI.board.fetchLists,
    });
};
exports.usePersonnelBoardFetchListsAPIQuery = usePersonnelBoardFetchListsAPIQuery;
var usePersonnelBoardFetchListCardsAPIQuery = function (_a) {
    var deps = _a.deps, options = _a.options;
    return (0, useAuthorizedQuery_1.useAuthorizedQuery)({
        queryKey: constants_1.QueryKeys.personnelBoard.cards,
        deps: deps,
        options: __assign({}, options),
        apiRequest: function (query) {
            return api_1.personnelAPI.board.fetchListCards(query === null || query === void 0 ? void 0 : query.listId);
        },
    });
};
exports.usePersonnelBoardFetchListCardsAPIQuery = usePersonnelBoardFetchListCardsAPIQuery;
var usePersonnelBoardDuplicateCardAPIMutation = function () {
    var apiRequest = function (postData) {
        return api_1.personnelAPI.board.duplicateCard(postData);
    };
    return (0, useCustomMutation_1.useCustomMutation)(apiRequest, {
        onSuccess: function () {
            (0, AlertMessage_1.customSuccessMessage)(constants_1.SuccessMessages.generic.copy);
        },
        onError: function () {
            (0, AlertMessage_1.customErrorMessage)(constants_1.ErrorMessages.generic.copy);
        },
    });
};
exports.usePersonnelBoardDuplicateCardAPIMutation = usePersonnelBoardDuplicateCardAPIMutation;
var useContractInformationAPIMutation = function () {
    var apiRequest = function (postData) {
        return api_1.personnelAPI.board.createContract(postData);
    };
    return (0, useCustomMutation_1.useCustomMutation)(apiRequest, {
        onSuccess: function () {
            (0, AlertMessage_1.customSuccessMessage)(constants_1.SuccessMessages.generic.update);
        },
        onError: function () {
            (0, AlertMessage_1.customErrorMessage)(constants_1.ErrorMessages.generic.update);
        },
    });
};
exports.useContractInformationAPIMutation = useContractInformationAPIMutation;
var usePersonnelBoardDeleteCardAPIMutation = function () {
    return (0, useCustomMutation_1.useCustomMutation)(api_1.personnelAPI.board.deleteCard, {
        onSuccess: function (response) {
            (0, AlertMessage_1.customSuccessMessage)(constants_1.SuccessMessages.generic.delete);
        },
        onError: function (err) {
            (0, AlertMessage_1.customErrorMessage)(constants_1.ErrorMessages.generic.delete);
        },
    });
};
exports.usePersonnelBoardDeleteCardAPIMutation = usePersonnelBoardDeleteCardAPIMutation;
var usePersonnelBoardChangeCardPositionAPIMutation = function () {
    var apiRequest = function (_a) {
        var cardId = _a.cardId, postData = _a.postData;
        return api_1.personnelAPI.board.changeCardPosition(cardId, postData);
    };
    return (0, useCustomMutation_1.useCustomMutation)(apiRequest, {
        onSuccess: function (response) { },
        onError: function (err) { },
    });
};
exports.usePersonnelBoardChangeCardPositionAPIMutation = usePersonnelBoardChangeCardPositionAPIMutation;
var usePersonnelBoardDeleteSkillSheetAPIMutation = function () {
    return (0, useCustomMutation_1.useCustomMutation)(api_1.personnelAPI.board.deleteSkillSheet, {
        onSuccess: function (response) { },
        onError: function (err) { },
    });
};
exports.usePersonnelBoardDeleteSkillSheetAPIMutation = usePersonnelBoardDeleteSkillSheetAPIMutation;
var usePersonnelBoardFetchChecklistItemsAPIQuery = function (_a) {
    var deps = _a.deps, options = _a.options;
    return (0, useAuthorizedQuery_1.useAuthorizedQuery)({
        queryKey: constants_1.QueryKeys.personnelBoard.checklistItems,
        deps: deps,
        options: __assign({}, options),
        apiRequest: function (query) {
            return api_1.personnelAPI.board.fetchChecklistItems(query === null || query === void 0 ? void 0 : query.checklistId);
        },
    });
};
exports.usePersonnelBoardFetchChecklistItemsAPIQuery = usePersonnelBoardFetchChecklistItemsAPIQuery;
var usePersonnelBoardFetchCommentsAPIQuery = function (_a) {
    var deps = _a.deps, options = _a.options;
    return (0, useAuthorizedQuery_1.useAuthorizedQuery)({
        queryKey: constants_1.QueryKeys.personnelBoard.comments,
        deps: deps,
        options: __assign({}, options),
        apiRequest: function (query) { return api_1.personnelAPI.board.fetchComments(query === null || query === void 0 ? void 0 : query.cardId); },
    });
};
exports.usePersonnelBoardFetchCommentsAPIQuery = usePersonnelBoardFetchCommentsAPIQuery;
var usePersonnelBoardCreateCommentAPIMutation = function () {
    return (0, useCustomMutation_1.useCustomMutation)(api_1.personnelAPI.board.createComment, {
        onSuccess: function (response) { },
        onError: function (err) { },
    });
};
exports.usePersonnelBoardCreateCommentAPIMutation = usePersonnelBoardCreateCommentAPIMutation;
var usePersonnelBoardFetchCommentAPIQuery = function (_a) {
    var deps = _a.deps, options = _a.options;
    return (0, useAuthorizedQuery_1.useAuthorizedQuery)({
        queryKey: constants_1.QueryKeys.personnelBoard.comment,
        deps: deps,
        options: __assign({}, options),
        apiRequest: function (query) {
            return api_1.personnelAPI.board.fetchComment(query === null || query === void 0 ? void 0 : query.commentId);
        },
    });
};
exports.usePersonnelBoardFetchCommentAPIQuery = usePersonnelBoardFetchCommentAPIQuery;
var usePersonnelBoardUpdateCommentAPIMutation = function () {
    var apiRequest = function (_a) {
        var commentId = _a.commentId, postData = _a.postData;
        return api_1.personnelAPI.board.updateComment(commentId, postData);
    };
    return (0, useCustomMutation_1.useCustomMutation)(apiRequest, {
        onSuccess: function (response) { },
        onError: function (err) { },
    });
};
exports.usePersonnelBoardUpdateCommentAPIMutation = usePersonnelBoardUpdateCommentAPIMutation;
var usePersonnelBoardDeleteCommentAPIMutation = function () {
    return (0, useCustomMutation_1.useCustomMutation)(api_1.personnelAPI.board.deleteComment, {
        onSuccess: function (response) { },
        onError: function (err) { },
    });
};
exports.usePersonnelBoardDeleteCommentAPIMutation = usePersonnelBoardDeleteCommentAPIMutation;
var usePersonnelBoardFetchSubCommentsAPIQuery = function (_a) {
    var deps = _a.deps, options = _a.options;
    return (0, useAuthorizedQuery_1.useAuthorizedQuery)({
        queryKey: constants_1.QueryKeys.personnelBoard.subComments,
        deps: deps,
        options: __assign({}, options),
        apiRequest: function (query) {
            return api_1.personnelAPI.board.fetchSubComments(query === null || query === void 0 ? void 0 : query.commentId);
        },
    });
};
exports.usePersonnelBoardFetchSubCommentsAPIQuery = usePersonnelBoardFetchSubCommentsAPIQuery;
var usePersonnelBoardFetchTrainStationsAPIQuery = function (_a) {
    var deps = _a.deps, options = _a.options;
    return (0, useAuthorizedQuery_1.useAuthorizedQuery)({
        queryKey: constants_1.QueryKeys.personnelBoard.trainStations,
        deps: deps,
        options: __assign({}, options),
        apiRequest: function (query) {
            return api_1.personnelAPI.board.fetchTrainStations(query === null || query === void 0 ? void 0 : query.name);
        },
    });
};
exports.usePersonnelBoardFetchTrainStationsAPIQuery = usePersonnelBoardFetchTrainStationsAPIQuery;
var usePersonnelBoardFetchSkillSheetAPIQuery = function (_a) {
    var deps = _a.deps, options = _a.options;
    return (0, useAuthorizedQuery_1.useAuthorizedQuery)({
        queryKey: constants_1.QueryKeys.personnelBoard.skillSheet,
        deps: deps,
        options: __assign({}, options),
        apiRequest: function (query) {
            return api_1.personnelAPI.board.fetchSkillSheet(query === null || query === void 0 ? void 0 : query.cardId);
        },
    });
};
exports.usePersonnelBoardFetchSkillSheetAPIQuery = usePersonnelBoardFetchSkillSheetAPIQuery;
var usePersonnelBoardFetchContractsAPIQuery = function (_a) {
    var deps = _a.deps, options = _a.options;
    return (0, useAuthorizedQuery_1.useAuthorizedQuery)({
        queryKey: constants_1.QueryKeys.personnelBoard.contracts,
        deps: deps,
        options: __assign({}, options),
        apiRequest: function (query) {
            return api_1.personnelAPI.board.fetchContracts(query === null || query === void 0 ? void 0 : query.cardId);
        },
    });
};
exports.usePersonnelBoardFetchContractsAPIQuery = usePersonnelBoardFetchContractsAPIQuery;
var usePersonnelBoardCreateContractAPIMutation = function () {
    return (0, useCustomMutation_1.useCustomMutation)(api_1.personnelAPI.board.createContract, {
        onSuccess: function (response) { },
        onError: function (err) { },
    });
};
exports.usePersonnelBoardCreateContractAPIMutation = usePersonnelBoardCreateContractAPIMutation;
var usePersonnelBoardFetchContractAPIQuery = function (_a) {
    var deps = _a.deps, options = _a.options;
    return (0, useAuthorizedQuery_1.useAuthorizedQuery)({
        queryKey: constants_1.QueryKeys.personnelBoard.contract,
        deps: deps,
        options: __assign({}, options),
        apiRequest: function (query) { return api_1.personnelAPI.board.fetchContract(query === null || query === void 0 ? void 0 : query.cardId); },
    });
};
exports.usePersonnelBoardFetchContractAPIQuery = usePersonnelBoardFetchContractAPIQuery;
var usePersonnelBoardUpdateContractAPIMutation = function () {
    var apiRequest = function (_a) {
        var contractId = _a.contractId, postData = _a.postData;
        return api_1.personnelAPI.board.updateContract(contractId, postData);
    };
    return (0, useCustomMutation_1.useCustomMutation)(apiRequest, {
        onSuccess: function (response) { },
        onError: function (err) { },
    });
};
exports.usePersonnelBoardUpdateContractAPIMutation = usePersonnelBoardUpdateContractAPIMutation;
var usePersonnelBoardCreateUpdateContractAPIMutation = function () {
    var apiRequest = function (_a) {
        var cardId = _a.cardId, postData = _a.postData;
        return api_1.personnelAPI.board.createUpdateContract(cardId, postData);
    };
    return (0, useCustomMutation_1.useCustomMutation)(apiRequest, {
        onSuccess: function (response) { },
        onError: function (err) { },
    });
};
exports.usePersonnelBoardCreateUpdateContractAPIMutation = usePersonnelBoardCreateUpdateContractAPIMutation;
var usePersonnelBoardDeleteContractAPIMutation = function () {
    return (0, useCustomMutation_1.useCustomMutation)(api_1.personnelAPI.board.deleteContract, {
        onSuccess: function (response) { },
        onError: function (err) { },
    });
};
exports.usePersonnelBoardDeleteContractAPIMutation = usePersonnelBoardDeleteContractAPIMutation;
var usePersonnelBoardDetailCard = function (cardId) {
    var _a = (0, react_1.useState)({
        cardId: cardId,
    }), deps = _a[0], setDeps = _a[1];
    var _b = (0, recoil_1.useRecoilState)(atom_1.personnelBoard), personnelBoardState = _b[0], setPersonnelBoardState = _b[1];
    var _c = (0, board_1.usePersonnelBoardFetchCardAPIQuery)({
        deps: deps,
        options: {
            enabled: !!deps.cardId,
        },
    }), isLoading = _c.isLoading, isSuccess = _c.isSuccess, dataDetail = _c.data, refetchFetchCard = _c.refetch;
    var _d = (0, exports.usePersonnelBoardFetchSkillSheetAPIQuery)({
        deps: deps,
        options: {
            enabled: !!deps.cardId,
        },
    }), skillSheets = _d.data, refetchListSkillSheet = _d.refetch;
    var updateCardOrderMutate = (0, board_1.usePersonnelBoardUpdateCardOrderAPIMutation)().mutate;
    var deleteSkillSheetMutate = (0, exports.usePersonnelBoardDeleteSkillSheetAPIMutation)().mutate;
    (0, react_1.useEffect)(function () {
        if (deps && deps.cardId) {
            refetchFetchCard();
            refetchListSkillSheet();
        }
    }, [deps, deps.cardId]);
    (0, react_1.useEffect)(function () {
        if (personnelBoardState && personnelBoardState.personnelId) {
            setDeps({ cardId: personnelBoardState.personnelId });
        }
    }, [personnelBoardState, personnelBoardState.personnelId]);
    var deleteSkillSheet = function (fileId) {
        deleteSkillSheetMutate(fileId, {
            onSuccess: function () {
                (0, AlertMessage_1.customSuccessMessage)(constants_1.SuccessMessages.files.delete);
            },
            onError: function (err) {
                var _a;
                var message = constants_1.ErrorMessages.generic.delete;
                if ((_a = err.response) === null || _a === void 0 ? void 0 : _a.data.detail) {
                    message = err.response.data.detail;
                }
                (0, AlertMessage_1.customErrorMessage)(message);
            },
        });
    };
    return {
        isLoading: isLoading,
        isSuccess: isSuccess,
        dataDetail: dataDetail,
        defaultDataCardDetail: defaultDataCardDetail,
        skillSheets: skillSheets,
        refetchFetchCard: refetchFetchCard,
        deleteSkillSheet: deleteSkillSheet,
    };
};
exports.usePersonnelBoardDetailCard = usePersonnelBoardDetailCard;
var useSearchScheduledEmail = function (params) {
    var _a = (0, useScheduledEmail_1.useFetchScheduledEmailSearchAPIQuery)({
        deps: {
            params: params,
        },
        options: {
            enabled: !!params,
        },
    }), listsScheduledMails = _a.data, isLoading = _a.isLoading;
    return {
        isLoading: isLoading,
        listsScheduledMails: listsScheduledMails,
    };
};
exports.useSearchScheduledEmail = useSearchScheduledEmail;
var useDuplicatePersonnelCardBoard = function () {
    var _a = (0, exports.usePersonnelBoardDuplicateCardAPIMutation)(), dupliateCardMutate = _a.mutate, dupliateCardData = _a.data;
    var duplicatePersonnelCardBoard = function (cardData) {
        dupliateCardMutate(cardData);
    };
    return {
        duplicatePersonnelCardBoard: duplicatePersonnelCardBoard,
        dupliateCardData: dupliateCardData,
    };
};
exports.useDuplicatePersonnelCardBoard = useDuplicatePersonnelCardBoard;
var useDeletePersonnelCardBoard = function () {
    var _a = (0, exports.usePersonnelBoardDeleteCardAPIMutation)(), deleteCardMutate = _a.mutate, deleteCardData = _a.data;
    var deletePersonnelCardBoard = function (cardId) {
        deleteCardMutate(cardId);
    };
    return {
        deletePersonnelCardBoard: deletePersonnelCardBoard,
        deleteCardData: deleteCardData,
    };
};
exports.useDeletePersonnelCardBoard = useDeletePersonnelCardBoard;
var useUpdateContractInformation = function () {
    var updateContractMutate = (0, exports.useContractInformationAPIMutation)().mutate;
    var updateContractInformation = function (values) {
        updateContractMutate(values);
    };
    return {
        updateContractInformation: updateContractInformation,
    };
};
exports.useUpdateContractInformation = useUpdateContractInformation;
var usePersonnelBoardMutateListAPIMutation = function () {
    return (0, useCustomMutation_1.useCustomMutation)(api_1.personnelAPI.board.fetchLists);
};
exports.usePersonnelBoardMutateListAPIMutation = usePersonnelBoardMutateListAPIMutation;
var usePersonnelBoardDownloadProfileImageAPIMutation = function () {
    var downloadingFileName = undefined;
    var _a = (0, download_1.useAPIDownload)(), onSuccess = _a.onSuccess, onError = _a.onError;
    var setIsAppLoading = (0, recoil_1.useSetRecoilState)(atom_1.isAppLoading);
    var apiRequest = function (_a) {
        var cardId = _a.cardId, fileName = _a.fileName;
        downloadingFileName = fileName;
        return api_1.personnelAPI.board.downloadProfileImage(cardId);
    };
    return (0, useCustomMutation_1.useCustomMutation)(apiRequest, {
        onMutate: function () {
            setIsAppLoading(true);
        },
        onSuccess: function (result) {
            onSuccess(result.data, downloadingFileName);
        },
        onError: function (err) {
            onError(err);
        },
        onSettled: function () {
            setIsAppLoading(false);
            downloadingFileName = undefined;
        },
    });
};
exports.usePersonnelBoardDownloadProfileImageAPIMutation = usePersonnelBoardDownloadProfileImageAPIMutation;
var usePersonnelBoardDeleteProfileImageAPIMutate = function () {
    return (0, useCustomMutation_1.useCustomMutation)(api_1.personnelAPI.board.deleteProfileImage, {
        onSuccess: function (result) {
            var message = constants_1.SuccessMessages.generic.delete;
            (0, AlertMessage_1.customSuccessMessage)(message);
        },
        onError: function (err) {
            var _a;
            var message = constants_1.ErrorMessages.generic.delete;
            if ((_a = err.response) === null || _a === void 0 ? void 0 : _a.data.detail) {
                message = err.response.data.detail;
            }
            (0, AlertMessage_1.customErrorMessage)(message);
        },
    });
};
exports.usePersonnelBoardDeleteProfileImageAPIMutate = usePersonnelBoardDeleteProfileImageAPIMutate;
var usePersonnelBoardDownloadSkillSheetAPIMutation = function () {
    var downloadingFileName = undefined;
    var _a = (0, download_1.useAPIDownload)(), onSuccess = _a.onSuccess, onError = _a.onError;
    var setIsAppLoading = (0, recoil_1.useSetRecoilState)(atom_1.isAppLoading);
    var apiRequest = function (_a) {
        var skillSheetId = _a.skillSheetId, fileName = _a.fileName;
        downloadingFileName = fileName;
        return api_1.personnelAPI.board.downloadSkillSheet(skillSheetId);
    };
    return (0, useCustomMutation_1.useCustomMutation)(apiRequest, {
        onMutate: function () {
            setIsAppLoading(true);
        },
        onSuccess: function (result) {
            onSuccess(result.data, downloadingFileName);
        },
        onError: function (err) {
            onError(err);
        },
        onSettled: function () {
            setIsAppLoading(false);
            downloadingFileName = undefined;
        },
    });
};
exports.usePersonnelBoardDownloadSkillSheetAPIMutation = usePersonnelBoardDownloadSkillSheetAPIMutation;
