// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProjectCsvUploadPage-downloadButtonWrapper-xmy3j{margin-top:8px;display:flex;justify-content:space-between;margin-bottom:16px}.ProjectCsvUploadPage-tableControlButton-jcsHB{margin-right:5px}.ProjectCsvUploadPage-tableControlButton-jcsHB:focus{border-color:#A7CF69;background:#A7CF69}.ProjectCsvUploadPage-tableNewLine-dnkjz{white-space:pre-wrap}.ProjectCsvUploadPage-errorMessages-TdGpr{margin-bottom:20px;text-align:left}.ProjectCsvUploadPage-nowrap-I5oly{white-space:nowrap}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/project/ProjectCsvUploadPage/ProjectCsvUploadPage.scss"],"names":[],"mappings":"AAAA,kDACI,cAAe,CACf,YAAa,CACb,6BAA8B,CAC9B,kBAAmB,CACtB,+CAGG,gBAAiB,CADrB,qDAGQ,oBAAqB,CACrB,kBAAmB,CACtB,yCAID,oBAAqB,CACxB,0CAGG,kBAAmB,CACnB,eAAgB,CACnB,mCAGG,kBAAmB","sourcesContent":[".downloadButtonWrapper {\n    margin-top: 8px;\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 16px;\n}\n\n.tableControlButton {\n    margin-right: 5px;\n    &:focus{\n        border-color: #A7CF69;\n        background: #A7CF69;\n    }\n}\n\n.tableNewLine {\n    white-space: pre-wrap;\n}\n\n.errorMessages {\n    margin-bottom: 20px;\n    text-align: left;\n}\n\n.nowrap {\n    white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"downloadButtonWrapper": `ProjectCsvUploadPage-downloadButtonWrapper-xmy3j`,
	"tableControlButton": `ProjectCsvUploadPage-tableControlButton-jcsHB`,
	"tableNewLine": `ProjectCsvUploadPage-tableNewLine-dnkjz`,
	"errorMessages": `ProjectCsvUploadPage-errorMessages-TdGpr`,
	"nowrap": `ProjectCsvUploadPage-nowrap-I5oly`
};
export default ___CSS_LOADER_EXPORT___;
