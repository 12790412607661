import axios from 'axios';
import { ErrorMessages } from '~/utils/constants';

export const LOGIN_URL = `${window.location.protocol}//${window.location.host}/api-token-auth/`;
export const LOGOUT_URL = `${window.location.protocol}//${window.location.host}/api-token-auth/logout`;

// TODO: replace this module via generic API.

export class LoginError extends Error {
  constructor(response) {
    super();
    this.message = response?.detail || ErrorMessages.auth.invalidCredentials;
    this.code = 'ERR_400';
  }
}

export class ServerError extends Error {
  constructor(response) {
    super();
    this.message = `サーバーエラーが発生しました。しばらく時間を置いてから再度お試しいただくか、サポートまでお問い合わせください。`;
    this.code = 'ERR_500';
  }
}

export class ConnectionError extends Error {
  constructor() {
    super();
    this.message = 'サーバーに接続できませんでした。サービスが一時停止している可能性があります。';
    this.code = 'ERR_600';
  }
}

export class ClientError extends Error {
  constructor() {
    super();
    this.message = 'リクエストの生成自体に失敗しました。開発者にお問い合わせください。';
    this.code = 'ERR_700';
  }
}

export const logout = (token) => (
  axios({
    url: LOGOUT_URL,
    headers: { Authorization: `Token ${token}` },
    method: "GET",
  })
    .then(res => res)
    .catch((error) => { // Not 200 OK.
      if (error.response) { 
        throw new ServerError(error.response);
      } else if (error.request) {
        throw new ConnectionError();
      } else {
        throw new ClientError();
      }
    })
);


export const login = (username, password) => (
  axios.post(LOGIN_URL, { username, password })
    .then((response) => {
      const {
        status,
        data: {
          token,
          user_id: userId,
          display_name: displayName,
          is_user_admin: isUserAdmin,
          avatar: avatar,
          role: role,
          authorized_actions: authorizedActions,
          intercom_user_hash: intercomUserHash,
          user_display_setting: userDisplaySetting,
        },
      } = response;
      return {
        status,
        token,
        userId,
        displayName,
        isUserAdmin,
        avatar,
        role,
        authorizedActions,
        intercomUserHash,
        userDisplaySetting,
      };
    })
    .catch((error) => { // Not 200 OK.
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error(error.message, error.response.data);

        if (error.response.status === 400) {
          throw new LoginError(error.response.data);
        } else {
          throw new ServerError(error.response);
        }
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.error(error.message, error.request);
        throw new ConnectionError();
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error(error.message, {});
        throw new ClientError();
      }
    })
);
