import React from "react";
import { Col, Form, Row, Select, Rate } from "antd";
import styles from "./CorporateScoreFormItem.scss";

const CorporateScoreFormItem = ({ disabled = false }) => {
    return (
        <Col span={24} style={{ marginBottom: "1%" }}>
            <Row className={styles.container}>
                <Col span={23}>
                    <Form.Item colon={false} noStyle>
                        <Row className={styles.container}>
                            <Col span={12}>
                                <Form.Item
                                    colon={false}
                                    name="score_inequality"
                                    noStyle>
                                    <Select
                                        className={styles.userInput}
                                        placeholder="取引先評価"
                                        allowClear
                                        getPopupContainer={(trigger) => trigger.parentNode}
                                        disabled={disabled}>
                                        <Select.Option className={styles.select_option} value="ge">以上</Select.Option>
                                        <Select.Option className={styles.select_option} value="eq">同じ</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    className={styles.userInput}
                                    colon={false}
                                    name="score"
                                    noStyle>
                                    <Rate
                                        allowClear={false}
                                        disabled={disabled}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form.Item>
                </Col>
                <Col span={1} className={styles.infoIcon}></Col>
            </Row>
        </Col>
    );
};

export default CorporateScoreFormItem;
