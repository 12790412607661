"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.paymentUpdate = exports.PaymentUpdateInitialState = exports.paymentDelete = exports.PaymentDeleteInitialState = exports.paymentForm = exports.PaymentFormInitialState = exports.paymentInfo = exports.PaymentInfoState = void 0;
var actionTypes_1 = require("~/actions/actionTypes");
var constants_1 = require("~/utils/constants");
exports.PaymentInfoState = {
    isLoading: false,
    paymentInfo: undefined,
    errorMessage: "",
};
var paymentInfo = function (state, action) {
    if (state === void 0) { state = exports.PaymentInfoState; }
    switch (action.type) {
        case actionTypes_1.PAYMENT_FETCH + actionTypes_1.LOADING:
            return {
                isLoading: true,
                paymentInfo: state.paymentInfo,
                errorMessage: "",
            };
        case actionTypes_1.PAYMENT_FETCH + actionTypes_1.LOADED:
            return {
                isLoading: false,
                paymentInfo: action.payload,
                errorMessage: "",
            };
        case actionTypes_1.PAYMENT_FETCH + actionTypes_1.VALIDATION_ERROR:
        case actionTypes_1.PAYMENT_FETCH + actionTypes_1.CANCELED:
        case actionTypes_1.PAYMENT_FETCH + actionTypes_1.ERROR:
            return __assign(__assign({}, state), { isLoading: false, errorMessage: constants_1.ErrorMessages.payment.fetchInfo });
        case actionTypes_1.PAYMENT_FETCH + actionTypes_1.CLEAR_ERROR:
            return __assign(__assign({}, state), { errorMessage: "" });
        case actionTypes_1.PAYMENT_FETCH + actionTypes_1.CLEAR:
            return __assign({}, exports.PaymentInfoState);
        default:
            return __assign({}, state);
    }
};
exports.paymentInfo = paymentInfo;
exports.PaymentFormInitialState = {
    isLoading: false,
    successMessage: "",
    errorMessage: "",
};
var paymentForm = function (state, action) {
    if (state === void 0) { state = exports.PaymentFormInitialState; }
    switch (action.type) {
        case actionTypes_1.PAYMENT_FORM + actionTypes_1.LOADING:
            return {
                isLoading: true,
                successMessage: "",
                errorMessage: "",
            };
        case actionTypes_1.PAYMENT_FORM + actionTypes_1.LOADED:
            return {
                isLoading: false,
                successMessage: constants_1.SuccessMessages.payment.register,
                errorMessage: "",
            };
        case actionTypes_1.PAYMENT_FORM + actionTypes_1.VALIDATION_ERROR:
        case actionTypes_1.PAYMENT_FORM + actionTypes_1.CANCELED:
        case actionTypes_1.PAYMENT_FORM + actionTypes_1.ERROR:
            return __assign(__assign({}, state), { isLoading: false, successMessage: "", errorMessage: constants_1.ErrorMessages.payment.register });
        case actionTypes_1.PAYMENT_FORM + actionTypes_1.CLEAR_SUCCESS:
            return __assign(__assign({}, state), { successMessage: "" });
        case actionTypes_1.PAYMENT_FORM + actionTypes_1.CLEAR_ERROR:
            return __assign(__assign({}, state), { errorMessage: "" });
        case actionTypes_1.PAYMENT_FORM + actionTypes_1.CLEAR:
            return __assign({}, exports.PaymentFormInitialState);
        default:
            return __assign({}, state);
    }
};
exports.paymentForm = paymentForm;
exports.PaymentDeleteInitialState = {
    isLoading: false,
    successMessage: "",
    errorMessage: "",
};
var paymentDelete = function (state, action) {
    if (state === void 0) { state = exports.PaymentDeleteInitialState; }
    switch (action.type) {
        case actionTypes_1.PAYMENT_DELETE + actionTypes_1.LOADING:
            return {
                isLoading: true,
                successMessage: "",
                errorMessage: "",
            };
        case actionTypes_1.PAYMENT_DELETE + actionTypes_1.LOADED:
            return {
                isLoading: false,
                successMessage: constants_1.SuccessMessages.payment.delete,
                errorMessage: "",
            };
        case actionTypes_1.PAYMENT_DELETE + actionTypes_1.VALIDATION_ERROR:
        case actionTypes_1.PAYMENT_DELETE + actionTypes_1.CANCELED:
        case actionTypes_1.PAYMENT_DELETE + actionTypes_1.ERROR:
            return __assign(__assign({}, state), { isLoading: false, successMessage: "", errorMessage: constants_1.ErrorMessages.payment.delete });
        case actionTypes_1.PAYMENT_DELETE + actionTypes_1.CLEAR_SUCCESS:
            return __assign(__assign({}, state), { successMessage: "" });
        case actionTypes_1.PAYMENT_DELETE + actionTypes_1.CLEAR_ERROR:
            return __assign(__assign({}, state), { errorMessage: "" });
        case actionTypes_1.PAYMENT_DELETE + actionTypes_1.CLEAR:
            return __assign({}, exports.PaymentDeleteInitialState);
        default:
            return __assign({}, state);
    }
};
exports.paymentDelete = paymentDelete;
exports.PaymentUpdateInitialState = {
    isLoading: false,
    successMessage: "",
    errorMessage: "",
};
var paymentUpdate = function (state, action) {
    if (state === void 0) { state = exports.PaymentUpdateInitialState; }
    switch (action.type) {
        case actionTypes_1.PAYMENT_UPDATE + actionTypes_1.LOADING:
            return {
                isLoading: true,
                successMessage: "",
                errorMessage: "",
            };
        case actionTypes_1.PAYMENT_UPDATE + actionTypes_1.LOADED:
            return {
                isLoading: false,
                successMessage: constants_1.SuccessMessages.payment.update,
                errorMessage: "",
            };
        case actionTypes_1.PAYMENT_UPDATE + actionTypes_1.VALIDATION_ERROR:
        case actionTypes_1.PAYMENT_UPDATE + actionTypes_1.CANCELED:
        case actionTypes_1.PAYMENT_UPDATE + actionTypes_1.ERROR:
            return __assign(__assign({}, state), { isLoading: false, successMessage: "", errorMessage: constants_1.ErrorMessages.payment.update });
        case actionTypes_1.PAYMENT_UPDATE + actionTypes_1.CLEAR_SUCCESS:
            return __assign(__assign({}, state), { successMessage: "" });
        case actionTypes_1.PAYMENT_UPDATE + actionTypes_1.CLEAR_ERROR:
            return __assign(__assign({}, state), { errorMessage: "" });
        case actionTypes_1.PAYMENT_UPDATE + actionTypes_1.CLEAR:
            return __assign({}, exports.PaymentUpdateInitialState);
        default:
            return __assign({}, state);
    }
};
exports.paymentUpdate = paymentUpdate;
