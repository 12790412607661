// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SalesCommentCreateDateFormItem-container-WpBNF{width:100%;text-align:left}.SalesCommentCreateDateFormItem-userInput-GbHh_{width:100%}.SalesCommentCreateDateFormItem-tooltip-ILJG0{margin-left:5px}.SalesCommentCreateDateFormItem-infoIcon-kuWZM{align-self:center}.SalesCommentCreateDateFormItem-marginBottom-xWTuK{margin-bottom:1%}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/sales/SalesBoardPage/SalesBoardSearchDrawer/SalesCommentCreateDateFormItem/SalesCommentCreateDateFormItem.scss"],"names":[],"mappings":"AAAA,gDACE,UAAW,CACX,eAAgB,CACjB,gDAGC,UAAW,CACZ,8CAGC,eAAgB,CACjB,+CAGC,iBAAkB,CACnB,mDAGC,gBAAiB","sourcesContent":[".container {\n  width: 100%;\n  text-align: left;\n}\n\n.userInput {\n  width: 100%;\n}\n\n.tooltip {\n  margin-left: 5px;\n}\n\n.infoIcon {\n  align-self: center;\n}\n\n.marginBottom {\n  margin-bottom: 1%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `SalesCommentCreateDateFormItem-container-WpBNF`,
	"userInput": `SalesCommentCreateDateFormItem-userInput-GbHh_`,
	"tooltip": `SalesCommentCreateDateFormItem-tooltip-ILJG0`,
	"infoIcon": `SalesCommentCreateDateFormItem-infoIcon-kuWZM`,
	"marginBottom": `SalesCommentCreateDateFormItem-marginBottom-xWTuK`
};
export default ___CSS_LOADER_EXPORT___;
