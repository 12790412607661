// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BoardBaseCard-card-DRBJA{width:252px;padding:0;background:#fff;margin-bottom:10px;border-radius:3px}.BoardBaseCard-card-DRBJA .ant-card-body{padding:0px}.BoardBaseCard-title-DnjMN{font-size:14px;font-weight:550;text-align:left;width:100%;white-space:normal}.BoardBaseCard-image-yqALB{width:100%;aspect-ratio:2 / 1;object-fit:cover}.BoardBaseCard-cursor-JObPo{cursor:pointer}.BoardBaseCard-content-ijR_g{padding:18px}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Common/Board/BoardBaseCard/BoardBaseCard.scss"],"names":[],"mappings":"AAAA,0BACI,WAAY,CACZ,SAAU,CACV,eAAgB,CAChB,kBAAmB,CACnB,iBAAkB,CALtB,yCAQQ,WAAY,CACf,2BAID,cAAe,CACf,eAAgB,CAChB,eAAgB,CAChB,UAAW,CACX,kBAAmB,CACtB,2BAGG,UAAW,CACX,kBAAmB,CACnB,gBAAiB,CACpB,4BAGG,cAAe,CAClB,6BAGG,YAAa","sourcesContent":[".card {\n    width: 252px;\n    padding: 0;\n    background: #fff;\n    margin-bottom: 10px;\n    border-radius: 3px;\n\n    :global(.ant-card-body) {\n        padding: 0px;\n    }\n}\n\n.title {\n    font-size: 14px;\n    font-weight: 550;\n    text-align: left;\n    width: 100%;\n    white-space: normal;\n}\n\n.image {\n    width: 100%;\n    aspect-ratio: 2 / 1;\n    object-fit: cover;\n}\n\n.cursor {\n    cursor: pointer;\n}\n\n.content {\n    padding: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `BoardBaseCard-card-DRBJA`,
	"title": `BoardBaseCard-title-DnjMN`,
	"image": `BoardBaseCard-image-yqALB`,
	"cursor": `BoardBaseCard-cursor-JObPo`,
	"content": `BoardBaseCard-content-ijR_g`
};
export default ___CSS_LOADER_EXPORT___;
