// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TagCsvUploadPage-downloadButtonWrapper-JuCrD{margin-top:8px;display:flex;justify-content:space-between;margin-bottom:16px}.TagCsvUploadPage-tableControlButton-qXAD9{margin-right:5px}.TagCsvUploadPage-tableControlButton-qXAD9:focus{border-color:#A7CF69;background:#A7CF69}.TagCsvUploadPage-tableNewLine-q_OEA{white-space:pre-wrap}.TagCsvUploadPage-errorMessages-OzhuC{margin-bottom:20px;text-align:left}.TagCsvUploadPage-nowrap-sPXux{white-space:nowrap}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/TagCsvUploadPage/TagCsvUploadPage.scss"],"names":[],"mappings":"AAAA,8CACI,cAAe,CACf,YAAa,CACb,6BAA8B,CAC9B,kBAAmB,CACtB,2CAGG,gBAAiB,CADrB,iDAGQ,oBAAqB,CACrB,kBAAmB,CACtB,qCAID,oBAAqB,CACxB,sCAGG,kBAAmB,CACnB,eAAgB,CACnB,+BAGG,kBAAmB","sourcesContent":[".downloadButtonWrapper {\n    margin-top: 8px;\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 16px;\n}\n\n.tableControlButton {\n    margin-right: 5px;\n    &:focus{\n        border-color: #A7CF69;\n        background: #A7CF69;\n    }\n}\n\n.tableNewLine {\n    white-space: pre-wrap;\n}\n\n.errorMessages {\n    margin-bottom: 20px;\n    text-align: left;\n}\n\n.nowrap {\n    white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"downloadButtonWrapper": `TagCsvUploadPage-downloadButtonWrapper-JuCrD`,
	"tableControlButton": `TagCsvUploadPage-tableControlButton-qXAD9`,
	"tableNewLine": `TagCsvUploadPage-tableNewLine-q_OEA`,
	"errorMessages": `TagCsvUploadPage-errorMessages-OzhuC`,
	"nowrap": `TagCsvUploadPage-nowrap-sPXux`
};
export default ___CSS_LOADER_EXPORT___;
