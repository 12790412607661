// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RecruitCreatedUserFormItem-container-gzFhw{width:100%;text-align:left}.RecruitCreatedUserFormItem-userInput-BhdK3{width:100%}.RecruitCreatedUserFormItem-tooltip-RjUDT{margin-left:5px}.RecruitCreatedUserFormItem-infoIcon-nOXNS{align-self:center}.RecruitCreatedUserFormItem-marginBottom-IQR9F{margin-bottom:1%}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/recruit/RecruitBoardPage/RecruitBoardSearchDrawer/RecruitCreatedUserFormItem/RecruitCreatedUserFormItem.scss"],"names":[],"mappings":"AAAA,4CACI,UAAW,CACX,eAAgB,CACnB,4CAGG,UAAW,CACd,0CAGG,eAAgB,CACnB,2CAGG,iBAAkB,CACrB,+CAGG,gBAAiB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n\n.marginBottom {\n    margin-bottom: 1%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `RecruitCreatedUserFormItem-container-gzFhw`,
	"userInput": `RecruitCreatedUserFormItem-userInput-BhdK3`,
	"tooltip": `RecruitCreatedUserFormItem-tooltip-RjUDT`,
	"infoIcon": `RecruitCreatedUserFormItem-infoIcon-nOXNS`,
	"marginBottom": `RecruitCreatedUserFormItem-marginBottom-IQR9F`
};
export default ___CSS_LOADER_EXPORT___;
