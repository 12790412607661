// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OrganizationsTable-paginator-nDgN9{margin:16px auto}.OrganizationsTable-tableWrapper-oVd6l{text-align:left}.OrganizationsTable-table-mD7xu{margin:16px auto}.OrganizationsTable-ignored-v1OWc{color:silver}.OrganizationsTable-genericTable-SclCh{word-break:break-all}.OrganizationsTable-newLine-_TQSl{white-space:pre-wrap}.OrganizationsTable-container-D4Lqb{margin-top:4%}.OrganizationsTable-tableSpacer-b5KFT{margin:7px 0}.OrganizationsTable-tooltipCopy-URznI:hover span{display:initial}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Tables/Table.scss"],"names":[],"mappings":"AAAA,oCACE,gBAAiB,CAClB,uCAGC,eAAgB,CACjB,gCAGC,gBAAiB,CAClB,kCAGC,YAAa,CACd,uCAGC,oBAAqB,CACtB,kCAGC,oBAAqB,CACtB,oCAGC,aAAc,CACf,sCAGC,YAAa,CACd,iDAKO,eAAgB","sourcesContent":[".paginator {\n  margin: 16px auto;\n}\n\n.tableWrapper {\n  text-align: left;\n}\n\n.table {\n  margin: 16px auto;\n}\n\n.ignored {\n  color: silver;\n}\n\n.genericTable {\n  word-break: break-all;\n}\n\n.newLine {\n  white-space: pre-wrap;\n}\n\n.container {\n  margin-top: 4%;\n}\n\n.tableSpacer {\n  margin: 7px 0;\n}\n\n.tooltipCopy {\n  &:hover {\n      span {\n        display: initial;\n      }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paginator": `OrganizationsTable-paginator-nDgN9`,
	"tableWrapper": `OrganizationsTable-tableWrapper-oVd6l`,
	"table": `OrganizationsTable-table-mD7xu`,
	"ignored": `OrganizationsTable-ignored-v1OWc`,
	"genericTable": `OrganizationsTable-genericTable-SclCh`,
	"newLine": `OrganizationsTable-newLine-_TQSl`,
	"container": `OrganizationsTable-container-D4Lqb`,
	"tableSpacer": `OrganizationsTable-tableSpacer-b5KFT`,
	"tooltipCopy": `OrganizationsTable-tooltipCopy-URznI`
};
export default ___CSS_LOADER_EXPORT___;
