// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PersonnelGenderFormItem-container-LOdAB{width:100%;text-align:left}.PersonnelGenderFormItem-userInput-WCY9W{width:100%}.PersonnelGenderFormItem-tooltip-ETkAq{margin-left:5px}.PersonnelGenderFormItem-infoIcon-YR11V{align-self:center}.PersonnelGenderFormItem-select_option-N5RMB{text-align:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/personnel/PersonnelBoardPage/PersonnelBoardSearchDrawer/PersonnelGenderFormItem/PersonnelGenderFormItem.scss"],"names":[],"mappings":"AAAA,yCACI,UAAW,CACX,eAAgB,CACnB,yCAGG,UAAW,CACd,uCAGG,eAAgB,CACnB,wCAGG,iBAAkB,CACrB,6CAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n\n.select_option {\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PersonnelGenderFormItem-container-LOdAB`,
	"userInput": `PersonnelGenderFormItem-userInput-WCY9W`,
	"tooltip": `PersonnelGenderFormItem-tooltip-ETkAq`,
	"infoIcon": `PersonnelGenderFormItem-infoIcon-YR11V`,
	"select_option": `PersonnelGenderFormItem-select_option-N5RMB`
};
export default ___CSS_LOADER_EXPORT___;
