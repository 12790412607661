// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RecruitDetailFormItem-container-_h44k{width:100%;text-align:left}.RecruitDetailFormItem-userInput-QQVG7{width:100%}.RecruitDetailFormItem-tooltip-a8whj{margin-left:5px}.RecruitDetailFormItem-infoIcon-fh7ZO{align-self:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/recruit/RecruitBoardPage/RecruitBoardSearchDrawer/RecruitDetailFormItem/RecruitDetailFormItem.scss"],"names":[],"mappings":"AAAA,uCACI,UAAW,CACX,eAAgB,CACnB,uCAGG,UAAW,CACd,qCAGG,eAAgB,CACnB,sCAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `RecruitDetailFormItem-container-_h44k`,
	"userInput": `RecruitDetailFormItem-userInput-QQVG7`,
	"tooltip": `RecruitDetailFormItem-tooltip-a8whj`,
	"infoIcon": `RecruitDetailFormItem-infoIcon-fh7ZO`
};
export default ___CSS_LOADER_EXPORT___;
