// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProjectCommentUserFormItem-container-bIwhi{width:100%;text-align:left}.ProjectCommentUserFormItem-userInput-HD8EM{width:100%}.ProjectCommentUserFormItem-tooltip-TTAY9{margin-left:5px}.ProjectCommentUserFormItem-infoIcon-Ro5gE{align-self:center}.ProjectCommentUserFormItem-marginBottom-wA6Nf{margin-bottom:1%}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/project/ProjectBoardPage/ProjectBoardSearchDrawer/ProjectCommentUserFormItem/ProjectCommentUserFormItem.scss"],"names":[],"mappings":"AAAA,4CACE,UAAW,CACX,eAAgB,CACjB,4CAGC,UAAW,CACZ,0CAGC,eAAgB,CACjB,2CAGC,iBAAkB,CACnB,+CAGC,gBAAiB","sourcesContent":[".container {\n  width: 100%;\n  text-align: left;\n}\n\n.userInput {\n  width: 100%;\n}\n\n.tooltip {\n  margin-left: 5px;\n}\n\n.infoIcon {\n  align-self: center;\n}\n\n.marginBottom {\n  margin-bottom: 1%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ProjectCommentUserFormItem-container-bIwhi`,
	"userInput": `ProjectCommentUserFormItem-userInput-HD8EM`,
	"tooltip": `ProjectCommentUserFormItem-tooltip-TTAY9`,
	"infoIcon": `ProjectCommentUserFormItem-infoIcon-Ro5gE`,
	"marginBottom": `ProjectCommentUserFormItem-marginBottom-wA6Nf`
};
export default ___CSS_LOADER_EXPORT___;
