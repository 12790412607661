// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StartupRegisterScalePage-startupPageContainer-HRonW{text-align:left;margin-top:55px}.StartupRegisterScalePage-startupPageContainer-HRonW .StartupRegisterScalePage-centerButton-L7caJ{margin-top:35px;text-align:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/StartupRegisterScalePage/StartupRegisterScalePage.scss"],"names":[],"mappings":"AAAA,qDACI,eAAgB,CAChB,eAAgB,CAFpB,kGAIQ,eAAgB,CAChB,iBAAkB","sourcesContent":[".startupPageContainer {\n    text-align: left;\n    margin-top: 55px;\n    .centerButton {\n        margin-top: 35px;\n        text-align: center;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"startupPageContainer": `StartupRegisterScalePage-startupPageContainer-HRonW`,
	"centerButton": `StartupRegisterScalePage-centerButton-L7caJ`
};
export default ___CSS_LOADER_EXPORT___;
