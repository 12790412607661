"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.otherSettingOptions = exports.functionSettingOptions = exports.commonSettingOptions = exports.extendSettingOptions = exports.dashboardSettingOptions = exports.matchingSettingOptions = exports.scheduledEmailSettingOptions = exports.customerSettingOptions = exports.genericSettingOptions = void 0;
exports.genericSettingOptions = [
    {
        label: "コメントテンプレート登録",
        fieldName: "commentTemplateRegistration",
    },
    {
        label: "コメントテンプレート編集",
        fieldName: "commentTemplateEdition",
    },
    {
        label: "コメントテンプレート削除",
        fieldName: "commentTemplateDeletion",
    },
    {
        label: "コメント登録（メンション）",
        fieldName: "commentRegistration",
        isFixed: true,
    },
    {
        label: "コメント編集（メンション）",
        fieldName: "commentEdit",
        isFixed: true,
    },
    {
        label: "コメント削除",
        fieldName: "commentDeletion",
    },
];
exports.customerSettingOptions = [
    {
        label: "取引先登録",
        fieldName: "organizationRegistration",
    },
    {
        label: "取引先編集",
        fieldName: "organizationEdition",
    },
    {
        label: "取引先ブロックリスト更新",
        fieldName: "organizationBlacklistEdition",
    },
    {
        label: "取引先削除",
        fieldName: "organizationDeletion",
    },
    {
        label: "取引先CSVアップロード",
        fieldName: "organizationUploadingCsv",
    },
    {
        label: "取引先CSVダウンロード",
        fieldName: "organizationDownloadingCsv",
    },
    {
        label: "取引先担当者登録",
        fieldName: "contactInfoRegistration",
    },
    {
        label: "取引先担当者編集",
        fieldName: "contactInfoEdition",
    },
    {
        label: "取引先担当者削除",
        fieldName: "contactInfoDeletion",
    },
    {
        label: "取引先担当者CSVアップロード",
        fieldName: "contactInfoUploadingCsv",
    },
    {
        label: "取引先担当者CSVダウンロード",
        fieldName: "contactInfoDownloadingCsv",
    },
];
exports.scheduledEmailSettingOptions = [
    {
        label: "配信メール予約",
        fieldName: "deliveryEmailRegistration",
    },
    {
        label: "配信メール編集",
        fieldName: "deliveryEmailEdition",
    },
    {
        label: "配信メール削除",
        fieldName: "deliveryEmailDeletion",
    },
];
exports.matchingSettingOptions = [
    {
        label: "マッチングメール削除",
        fieldName: "sharedEmailDeletion",
    },
];
exports.dashboardSettingOptions = [
    {
        label: "ダッシュボード更新",
        fieldName: "dashboardEdition",
    },
];
exports.extendSettingOptions = [
    {
        label: "アドオン追加",
        fieldName: "addonRegistration",
    },
    {
        label: "アドオン削除",
        fieldName: "addonDeletion",
    },
];
exports.commonSettingOptions = [
    {
        label: "自社プロフィール編集",
        fieldName: "companyProfileEdition",
    },
    {
        label: "ユーザー招待",
        fieldName: "userInvitation",
    },
    {
        label: "ユーザー登録",
        fieldName: "userRegistration",
    },
    {
        label: "ユーザー編集",
        fieldName: "userEdition",
    },
    {
        label: "ユーザー有効化",
        fieldName: "userActivation",
    },
    {
        label: "ユーザー無効化",
        fieldName: "userInactivation",
    },
    {
        label: "ユーザー削除",
        fieldName: "userDeletion",
    },
    {
        label: "必須項目設定編集",
        fieldName: "requiredSettingEdition",
    },
    {
        label: "タグ追加",
        fieldName: "tagRegistration",
    },
    {
        label: "タグ編集",
        fieldName: "tagEdition",
    },
    {
        label: "タグ削除",
        fieldName: "tagDeletion",
    },
];
exports.functionSettingOptions = [
    {
        label: "配信メール設定登録/編集",
        fieldName: "deliveryMailSettingsEdition",
    },
    {
        label: "マッチングメール設定登録/編集",
        fieldName: "sharedMailSettingsEdition",
    },
];
exports.otherSettingOptions = [
    {
        label: "ご利用プラン変更",
        fieldName: "planChanging",
    },
    {
        label: "ユーザー上限追加",
        fieldName: "addUserMax",
    },
    {
        label: "ユーザー上限削除",
        fieldName: "deleteUserMax",
    },
    {
        label: "お支払い情報変更",
        fieldName: "modifyPaymentInfo",
    },
    {
        label: "お支払い履歴領収書ダウンロード",
        fieldName: "downloadPaymentHistory",
    },
    {
        label: "月次決済の成功",
        fieldName: "monthlyPaymentSuccess",
    },
    {
        label: "決済失敗",
        fieldName: "paymentFailure",
    },
    {
        label: "パスワード再設定",
        fieldName: "resetPassword",
    },
];
