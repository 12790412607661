import React, { useRef, useLayoutEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Col, Row, Table, List, Pagination } from "antd";
import { isMobileDevices } from "../helpers";
import styles from "./Table.scss";

const isEmpty = (array) => array.length === 0;

export const setSortParams = (sortKey, sortOrder, originalColumns) => {
    const columns = originalColumns.slice(); // Deep copy original columns.
    columns.forEach((elem) => (elem.sortOrder = false)); // Disable all sort first.

    if (sortKey && sortOrder) {
        const idx = columns.findIndex((elem) => elem.key === sortKey);
        if (idx !== -1) columns[idx].sortOrder = sortOrder;
    }

    return columns;
};

export const getPaginationProps = (
    currentPage,
    pageSize,
    totalCount,
    onPageChange,
    onPageSizeChange
) => ({
    simple: isMobileDevices(),
    current: currentPage,
    defaultCurrent: currentPage,
    pageSize,
    defaultPageSize: pageSize,
    showSizeChanger: false,
    showTotal: (total, range) =>
        `合計${total}件中, ${range[0]}-${range[1]}を表示`,
    total: totalCount,
    pageSizeOptions: ["10", "50", "100"],
    onChange: onPageChange,
    onShowSizeChange: onPageSizeChange,
});

const GenericTable = ({
    tableName,
    columns,
    onMinify,
    data,
    sortKey,
    sortOrder,
    currentPage,
    pageSize,
    totalCount,
    onPageChange,
    onPageSizeChange,
    onTableChange,
    onRowClick,
    onColumnClick,
    loading,
    expandedRowRender,
    withSelection,
    onCheckColumn,
    scroll,
    leftTopButtons,
    rightTopButtons,
    leftBottomButtons,
    rightBottomButtons,
    isTreeData,
    onRefetch,
    expandIconColumnIndex,
    rowSelection: customRowSelection,
}) => {
    const filterType = "table";

    const { displaySetting } = useSelector((state) => state.displaySettingPage);
    const firstRender = useRef(true);

    const unshowTableColumn =
        displaySetting &&
        displaySetting[tableName] &&
        displaySetting[tableName][filterType]
            ? displaySetting[tableName][filterType]
            : [];
    const paginationProps = getPaginationProps(
        currentPage,
        pageSize,
        totalCount,
        onPageChange,
        onPageSizeChange
    );
    // Set current sort status.
    const columnsWithSortParams = setSortParams(sortKey, sortOrder, columns);
    const rowSelection = {
        ...(customRowSelection ?? {}),
        onChange: (_, selectedRows) => {
            onCheckColumn(selectedRows);
        },
        renderCell: (checked, record, index, originNode) => {
            return (
                <Col key={record.id} span={24}>
                    <Row justify="center">
                        <Col>{originNode}</Col>
                    </Row>
                </Col>
            );
        },
    };
    const selectedColumns = columnsWithSortParams.filter((column) => {
        return !unshowTableColumn.includes(column.key);
    });

    const tableColumns =
        unshowTableColumn && isEmpty(unshowTableColumn)
            ? columnsWithSortParams
            : selectedColumns;

    const tableColumnsWithHandler = useMemo(() => {
        if (!!onColumnClick) {
            return tableColumns.map((column) => ({
                ...column,
                onCell: (record) => ({
                    ...column,
                    onClick: () => onColumnClick(record, column),
                }),
            }));
        } else {
            return tableColumns;
        }
    }, [tableColumns, onColumnClick]);

    useLayoutEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        onRefetch(pageSize);
    }, [pageSize]);

    const expandable = {
        expandedRowRender,
        expandIconColumnIndex: expandIconColumnIndex?? (isTreeData? 3: -1)
    }

    if (isMobileDevices() && onMinify) {
        return (
            <div>
                <List
                    loading={loading}
                    dataSource={data}
                    renderItem={(item, index) => (
                        <List.Item
                            key={item.id ?? index}
                            onClick={() => onRowClick(item)}>
                            <List.Item.Meta
                                title={onMinify.renderTitle(item)}
                                description={onMinify.renderDescription(item)}
                            />
                        </List.Item>
                    )}
                />
                <Pagination {...paginationProps} className={styles.paginator} />
            </div>
        );
    }

    return (
        <Row span={24}>
            <Col span={24}>
                <Row span={24}>
                    <Col span={12}>
                        <Row justify="start">
                            {!!leftTopButtons && (
                                <>
                                    {leftTopButtons.map((button, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                {button}
                                            </React.Fragment>
                                        );
                                    })}
                                </>
                            )}
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row justify="end">
                            {!!rightTopButtons && (
                                <>
                                    {rightTopButtons.map((button, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                {button}
                                            </React.Fragment>
                                        );
                                    })}
                                </>
                            )}
                        </Row>
                    </Col>
                </Row>
                <Row span={24} style={{ marginTop: "1%", marginBottom: "1%" }}>
                    <Table
                        className={styles.genericTable}
                        columns={tableColumnsWithHandler}
                        dataSource={isEmpty(data) ? null : data}
                        hasData={!isEmpty(data)}
                        bordered
                        total={totalCount}
                        onChange={onTableChange}
                        onRow={(record) => ({
                            onClick: () => onRowClick(record),
                        })}
                        loading={loading}
                        pagination={{
                            position: [],
                            pageSize,
                        }}
                        expandable={expandable}
                        indentSize={isTreeData ? 0 : 15}
                        rowSelection={
                            withSelection
                                ? {
                                      type: "checkbox",
                                      ...rowSelection,
                                      fixed: "left",
                                      columnWidth: 35,
                                  }
                                : null
                        }
                        rowKey={(record) => record.id}
                        rowClassName={(record, _index) => {
                            if (
                                record.is_ignored === true || record.is_active ===false
                            ) {
                                return styles.ignored;
                            }
                        }}
                        scroll={scroll}
                    />
                </Row>
                <Row span={24}>
                    <Col span={12}>
                        <Row justify="start" align="bottom">
                            {!!leftBottomButtons && (
                                <>
                                    {leftBottomButtons.map((button, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                {button}
                                            </React.Fragment>
                                        );
                                    })}
                                </>
                            )}
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row justify="end">
                            {!!rightBottomButtons && (
                                <>
                                    {rightBottomButtons.map((button, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                {button}
                                            </React.Fragment>
                                        );
                                    })}
                                </>
                            )}
                        </Row>
                    </Col>
                </Row>
                <Row span={24} justify="end">
                    <Col>
                        <Pagination
                            {...paginationProps}
                            className={styles.paginator}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

GenericTable.propTypes = {
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            // A shape of AntDesign's column parameter.
            title: PropTypes.oneOfType([PropTypes.func, PropTypes.string]).isRequired,
            dataIndex: PropTypes.string.isRequired,
            key: PropTypes.string.isRequired,
            render: PropTypes.func,
        })
    ).isRequired,
    onMinify: PropTypes.shape({
        renderTitle: PropTypes.func.isRequired,
        renderDescription: PropTypes.func.isRequired,
    }),
    currentPage: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired,
    sortKey: PropTypes.string,
    sortOrder: PropTypes.string,
    onPageChange: PropTypes.func.isRequired,
    onTableChange: PropTypes.func.isRequired,
    onPageSizeChange: PropTypes.func.isRequired,
    onRowClick: PropTypes.func,
    onColumnClick: PropTypes.func,
    expandedRowRender: PropTypes.func,
    loading: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.object),
    withSelection: PropTypes.bool,
    onCheckColumn: PropTypes.func,
    scroll: PropTypes.object,
    isTreeData: PropTypes.bool,
    expandIconColumnIndex: PropTypes.number,
    rowSelection: PropTypes.object,
};

GenericTable.defaultProps = {
    loading: false,
    sortKey: undefined,
    sortOrder: undefined,
    data: [],
    onMinify: undefined,
    expandedRowRender: undefined,
    onRowClick: () => {},
    onColumnClick: undefined,
    withSelection: false,
    onCheckColumn: () => {},
    scroll: { y: 600 },
    isTreeData: false,
};

export default GenericTable;
