// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoginForm-container-bSkv5{max-width:300px}.LoginForm-button-BVMSp{margin:32px auto;width:100%}.LoginForm-alert-e6ao3{margin-top:16px;margin-bottom:16px}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/LoginPage/LoginForm/LoginForm.scss"],"names":[],"mappings":"AAAA,2BACI,eAAgB,CACnB,wBAGG,gBAAiB,CACjB,UAAW,CACd,uBAGG,eAAgB,CAChB,kBAAmB","sourcesContent":[".container {\n    max-width: 300px;\n}\n\n.button {\n    margin: 32px auto;\n    width: 100%;\n}\n\n.alert {\n    margin-top: 16px;\n    margin-bottom: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `LoginForm-container-bSkv5`,
	"button": `LoginForm-button-BVMSp`,
	"alert": `LoginForm-alert-e6ao3`
};
export default ___CSS_LOADER_EXPORT___;
