"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertBoardScheduledEmailTemplateFormModelToBoardScheduledEmailTemplateModel = exports.convertBoardScheduledEmailTemplateModelToBoardScheduledEmailTemplateFormModel = void 0;
var convertBoardScheduledEmailTemplateModelToBoardScheduledEmailTemplateFormModel = function (data) {
    if (!data) {
        return undefined;
    }
    var obj = __assign({ id: data.id, textFormat: data.textFormat, subject: data.subject, upper: data.body.upper, lower: data.body.lower, middle: data.body.middle, valuesInfo: "", sendCopyToSender: data.sendCopyToSender, sendCopyToShare: data.sendCopyToShare, excludeOrganizations: data.excludeOrganizations }, data.condition);
    return obj;
};
exports.convertBoardScheduledEmailTemplateModelToBoardScheduledEmailTemplateFormModel = convertBoardScheduledEmailTemplateModelToBoardScheduledEmailTemplateFormModel;
var convertBoardScheduledEmailTemplateFormModelToBoardScheduledEmailTemplateModel = function (data) {
    var body = {
        upper: data.upper,
        middle: data.middle,
        lower: data.lower,
    };
    var condition = __assign({}, data);
    var obj = {
        id: data.id,
        subject: data.subject,
        body: body,
        textFormat: data.textFormat,
        sendCopyToSender: data.sendCopyToSender,
        sendCopyToShare: data.sendCopyToShare,
        excludeOrganizations: data.excludeOrganizations,
        condition: condition,
    };
    return obj;
};
exports.convertBoardScheduledEmailTemplateFormModelToBoardScheduledEmailTemplateModel = convertBoardScheduledEmailTemplateFormModelToBoardScheduledEmailTemplateModel;
