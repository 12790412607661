// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HeaderIcon-headerIcon-Xyrku{margin:auto 6px;width:34px;border-color:transparent;color:#262626;background:transparent;align-items:center;justify-content:center;display:flex}.HeaderIcon-headerIcon-Xyrku:hover{border-color:transparent;color:#00059c;background:transparent}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Navigations/HeaderIcon/HeaderIcon.scss"],"names":[],"mappings":"AAEA,6BACI,eAAgB,CAChB,UAAW,CACX,wBAAyB,CACzB,aAAc,CACd,sBAAuB,CACvB,kBAAmB,CACnB,sBAAuB,CACvB,YAAa,CARjB,mCAUQ,wBAAyB,CACzB,aAbO,CAcP,sBAAuB","sourcesContent":["$color: #00059c;\n\n.headerIcon {\n    margin: auto 6px;\n    width: 34px;\n    border-color: transparent;\n    color: #262626;\n    background: transparent;\n    align-items: center;\n    justify-content: center;\n    display: flex;\n    &:hover {\n        border-color: transparent;\n        color: $color;\n        background: transparent;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerIcon": `HeaderIcon-headerIcon-Xyrku`
};
export default ___CSS_LOADER_EXPORT___;
