"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.myProfile = void 0;
var api_1 = require("~/domain/api");
var useMyProfile_1 = require("~/hooks/useMyProfile");
var utils_1 = require("~/utils/utils");
var myProfile = function (client) {
    return {
        fetchMyProfile: function () {
            var url = api_1.Endpoint.myProfilePath;
            return client.get(url);
        },
        update: function (data) {
            var url = api_1.Endpoint.myProfilePath;
            var postData = (0, useMyProfile_1.convertMyProfileFormModelToMyProfileRequestModel)(data);
            if (postData.avatar) {
                postData = (0, utils_1.convertObjectToFormData)(postData);
            }
            return client.patch(url, postData);
        },
    };
};
exports.myProfile = myProfile;
exports.default = exports.myProfile;
