import CreateRegisterPage from './Factories/createRegisterPage';

import { USER_INVITE_PAGE } from './pageIds';

import { Endpoint } from '../../domain/api';
import Path from '../Routes/Paths';
import UserInviteForm from '../Forms/UserInviteForm';

const pageId = USER_INVITE_PAGE;
const resourceURL = `${Endpoint.getBaseUrl()}/${Endpoint.users}`;

const UserInvitePageContainer = CreateRegisterPage(
  pageId, 'userInvitePage', 'ユーザー 招待', UserInviteForm, resourceURL, Path.users,
);

export default UserInvitePageContainer;
