// We need to define a message elements as a function, or you can not hide original in-placed value.

const JapaneseValidateMessages = {
  default: () => '入力に誤りがあります。',
  required: () => '必須項目です。',
  enum: candidates => `次のいずれかの値である必要があります。: ${candidates}`,
  whitespace: () => '空の文字列にはできません。',
  date: {
    format: () => '時刻表現が無効です。',
    parse: () => '時刻表現を解釈できませんでした。',
    invalid: () => '正しい時刻を入力してください。',
  },
  types: {
    string: () => '文字を入力してください。',
    method: () => '関数を入力してください。',
    array: () => '配列を入力してください。',
    object: () => 'オブジェクト(辞書)を入力してください。',
    number: () => '数字を入力してください。',
    date: () => '時刻を入力してください。',
    boolean: () => '真偽値を入力してください。',
    integer: () => '整数を入力してください。',
    float: () => '実数を入力してください。',
    regexp: () => '正規表現を入力してください。',
    email: () => '正しい形式のメールアドレスを入力してください。',
    url: () => '正しい形式のURLを入力してください。',
    hex: () => '16進数表現で入力してください。',
  },
  string: {
    len: (attrName, value) => `ちょうど ${value} 文字である必要があります。`,
    min: (attrName, limitValue) => `最低 ${limitValue} 文字は入力してください。`,
    max: (attrName, limitValue) => `${limitValue} 文字以内におさめてください。`,
    range: (attrName, lowerLimit, upperLimit) => `${lowerLimit} 文字から ${upperLimit} 文字以内におさめてください。`,
  },
  number: {
    len: (attrName, value) => `ちょうど ${value} である必要があります。` /* 使うことある? */,
    min: (attrName, limitValue) => `${limitValue} 以上の値である必要があります。`,
    max: (attrName, limitValue) => `${limitValue} 以下の値である必要があります。`,
    range: (attrName, lowerLimit, upperLimit) => `${lowerLimit} 以上 ${upperLimit} 以下である必要があります。`,
  },
  array: {
    len: (attrName, value) => `要素数がちょうど ${value} 個である必要があります。`,
    min: (attrName, limitValue) => `最低 ${limitValue} 個の要素を含む必要があります。`,
    max: (attrName, limitValue) => `要素数は ${limitValue} 個以下である必要があります。`,
    range: (attrName, lowerLimit, upperLimit) => `要素数は ${lowerLimit} 個から ${upperLimit} 個の範囲内である必要があります。`,
  },
  pattern: {
    mismatch: (attrName, inputValue, expression) => `入力された値は ルール ${expression} にマッチしていません。`,
  },
  clone: () => {
    const cloned = JSON.parse(JSON.stringify(this));
    cloned.clone = this.clone;
    return cloned;
  },
};

export default JapaneseValidateMessages;
