"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMyCompanyAPIQuery = exports.convertMyCompanyResponseModelToMyCompanyModel = void 0;
var date_1 = __importStar(require("~/domain/date"));
var useAuthorizedQuery_1 = require("./useAuthorizedQuery");
var constants_1 = require("~/utils/constants");
var api_1 = require("~/networking/api");
var convertMyCompanyResponseModelToMyCompanyModel = function (data) {
    return {
        id: data.id,
        name: data.name,
        domain_name: data.domain_name,
        address: data.address,
        building: data.building,
        capital_man_yen: data.capital_man_yen,
        establishment_date: (0, date_1.DateStrToMoment)(data.establishment_date),
        establishment_year: data.establishment_year,
        has_p_mark_or_isms: data.has_p_mark_or_isms,
        has_invoice_system: data.has_invoice_system,
        has_haken: data.has_haken,
        has_distribution: data.has_distribution,
        capital_man_yen_required_for_transactions: data.capital_man_yen_required_for_transactions,
        p_mark_or_isms: data.p_mark_or_isms,
        invoice_system: data.invoice_system,
        haken: data.haken,
        exceptional_organization_names: data.exceptional_organization_names,
        exceptional_organizations: data.exceptional_organizations,
        modified_time: (0, date_1.default)(data.modified_time),
        modified_user: data.modified_user__name,
        settlement_month: data.settlement_month
    };
};
exports.convertMyCompanyResponseModelToMyCompanyModel = convertMyCompanyResponseModelToMyCompanyModel;
var useMyCompanyAPIQuery = function (_a) {
    var _b = _a === void 0 ? {} : _a, deps = _b.deps, options = _b.options;
    return (0, useAuthorizedQuery_1.useAuthorizedQuery)({
        queryKey: constants_1.QueryKeys.company.companyInfo,
        deps: deps,
        options: __assign(__assign({}, options), { retry: false, select: exports.convertMyCompanyResponseModelToMyCompanyModel }),
        apiRequest: api_1.myCompanyAPI.fetchCompanyInfo,
    });
};
exports.useMyCompanyAPIQuery = useMyCompanyAPIQuery;
