// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProjectDynamicRowsFormItem-listItemContainer-b4OVb{margin-bottom:6px;text-align:left}.ProjectDynamicRowsFormItem-controlButtonRow-s9TjD{margin-left:25px}.ProjectDynamicRowsFormItem-tooltipContainer-F91cK{margin-top:6px}.ProjectDynamicRowsFormItem-tooltip-ZJZPX{margin-left:5px;color:#615748}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/project/ProjectBoardPage/ProjectBoardSearchDrawer/ProjectDynamicRowsFormItem/ProjectDynamicRowsFormItem.scss","webpack://./frontend/stylesheets/constants.scss"],"names":[],"mappings":"AAEA,oDACI,iBAAkB,CAClB,eAAgB,CACnB,mDAGG,gBAAiB,CACpB,mDAGG,cAAe,CAClB,0CAGG,eAAgB,CAChB,aCboB","sourcesContent":["@import \"~coreStylesheet\";\n\n.listItemContainer {\n    margin-bottom: 6px;\n    text-align: left;\n}\n\n.controlButtonRow {\n    margin-left: 25px;\n}\n\n.tooltipContainer {\n    margin-top: 6px;\n}\n\n.tooltip {\n    margin-left: 5px;\n    color: $komorebi-brown;\n}\n","/* アプリケーション全体 */\n$breakpoint-mobile: 768px;\n$width-small-screen: 1500px;\n$primary-color: #a7cf69;\n$komorebi-brown: #615748;\n\n/* ガントチャート関連 */\n$gantt-over70: #003656;\n$gantt-between40to70: #80d6ff;\n$gantt-under40: #d0edff;\n\n@mixin mobile-devices() {\n    @media screen and (max-width: $breakpoint-mobile) {\n        @content;\n    }\n}\n\n@mixin small-screen() {\n    @media screen and (max-width: $width-small-screen) {\n        @content;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listItemContainer": `ProjectDynamicRowsFormItem-listItemContainer-b4OVb`,
	"controlButtonRow": `ProjectDynamicRowsFormItem-controlButtonRow-s9TjD`,
	"tooltipContainer": `ProjectDynamicRowsFormItem-tooltipContainer-F91cK`,
	"tooltip": `ProjectDynamicRowsFormItem-tooltip-ZJZPX`
};
export default ___CSS_LOADER_EXPORT___;
