import React from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import moment from "moment";
import {
    Button,
    Checkbox,
    Form,
    Input,
    InputNumber,
    Select,
    Divider,
    Tabs,
    Radio,
    List,
    Tooltip,
    Rate,
    Col,
    Row,
    Typography,
    Switch,
    Result,
    Empty,
} from "antd";
import { CheckOutlined, CloseOutlined, PushpinFilled } from "@ant-design/icons";
import Path from "../Routes/Paths";
import BaseForm from "./base/BaseForm";
import { formatMoneyNumberString, parseMoneyNumberString } from "../helpers";
import { finalized } from "./helpers";
import { connect } from "react-redux";
import {
    fetchCommentTemplateAction,
    createCommentTemplateAction,
} from "~/actions/data";
import { Endpoint } from "~/domain/api";
import {
    alertTooltipLinkColor,
    ErrorMessages,
    iconPrimaryColor,
    Links,
    ONLY_HANKAKU_REGEX,
    NO_ONLY_SPACES_REGEX,
    HANKAKU_NUMBER_REGEX,
    RESTRICT_SPACE_REGEX,
    ONLY_SPACES_AND_NEWLINE_REGEX,
    warningColor,
    iconCustomColor,
} from "~/utils/constants";
import {
    PushpinOutlined,
    SnippetsOutlined,
    UserOutlined,
    CloudTwoTone,
    QuestionCircleFilled,
    InfoCircleTwoTone,
} from "@ant-design/icons";
import validateJapaneseMessages from "./validateMessages";
import BackButton from "../Common/BackButton/BackButton";
import Paths from "../Routes/Paths";
import CommentTemplateCreateModal from "~/components/DataDisplay/CommentList/CommentTemplateCreateModal/CommentTemplateCreateModal";
import CommentTemplateUpdateModal from "~/components/DataDisplay/CommentList/CommentTemplateUpdateModal/CommentTemplateUpdateModal";
import {
    TooltipContentLink,
} from "../Common/TooltipContentLink/TooltipContentLink";
import CustomMonthPicker from "../Common/CustomMonthPicker/CustomMonthPicker";
import OrganizationBranchTabPaneContent from "./OrganizationForm/OrganizationBranchTabPaneContent/OrganizationBranchTabPaneContent";
import CommentTemplateModal from "../Modals/CommentTemplateModal/CommentTemplateModal";
import { confirmModal } from "../Modals/ConfirmModal";
import TelInputFormItem from "./TelInputFormItem/TelInputFormItem";
import FaxInputFormItem from "./FaxInputFormItem/FaxInputFormItem";
import { disabledFutureDates } from "~/utils/utils";
import AddressInputFormItem from "./AddressInputFormItem/AddressInputFormItem";
import { CommentActions } from "~/actionCreators/commentActions";
import { CONTACT_FORM } from "~/actions/actionTypes";
import ValidateTextArea from "~/components/Common/ValidateTextArea/ValidateTextArea";
import UserMentionsForm from "./UserMentionsForm/UserMentionsForm";
import TagSelectFormItem from "~/components/Common/TagSelectFormItem/TagSelectFormItem";
import TextualNumberInput from "~/components/Common/TextualNumberInput";
import { withPlans } from "~/hooks/usePlan";
import styles from "./EditForm.scss";

const { TabPane } = Tabs;
const { TextArea } = Input;
const { Title, Text } = Typography;

const containerLayout = {
    xs: 24,
    sm: 24,
    md: 21,
    lg: 18,
    xl: 15,
    xxl: 13,
};

const tailLayout = {
    wrapperCol: {
        xs: { offset: 0, span: 24 },
        sm: { offset: 6, span: 18 },
    },
};

const organizationPaneFormItemLayout = {
    labelCol: {
        span: 5,
    },
    wrapperCol: {
        span: 19,
    },
};

const transactionTermsFormItemLayoutSpan = 6;
const transactionTermsFormItemLayout = {
    labelCol: {
        span: transactionTermsFormItemLayoutSpan,
    },
    wrapperCol: {
        span: 24 - transactionTermsFormItemLayoutSpan,
    },
};

const certificateItemLayout = {
    labelCol: {
        span: 9,
    },
    wrapperCol: {
        span: 8,
    },
};

const InputNumberWidth = 180;

const MaxTelLength = 15;
const MaxCommentLength = 2000;

const commentTemplateUrl = `${Endpoint.getBaseUrl()}/${
    Endpoint.commentTemplateOrganization
}`;

const defaultSettlementMonthOptions = [
    { label: "1月", value: 1 },
    { label: "2月", value: 2 },
    { label: "3月", value: 3 },
    { label: "4月", value: 4 },
    { label: "5月", value: 5 },
    { label: "6月", value: 6 },
    { label: "7月", value: 7 },
    { label: "8月", value: 8 },
    { label: "9月", value: 9 },
    { label: "10月", value: 10 },
    { label: "11月", value: 11 },
    { label: "12月", value: 12 },
]

class OrganizationForm extends BaseForm {
    constructor(props) {
        super(props);
        this.initialViewRendered = false;
    }

    state = {
        qualificationExpand: true,
        checked: false,
        content: "",
        commentTemplateEditing: false,
        newTitle: undefined,
        newContent: undefined,
        selectedIndex: undefined,
        listVisible: false,
        registerVisible: false,
        updateVisible: false,
        deleteVisible: false,
        transactionQualificationExpand: true,
        isBlacklisted: false,
        currentTel: false,
        numberOfTel: false,
        currentFax: false,
        numberOfFax: false,
        errorFields: [],
        tabKey: 1,
        isTitleValidationError: false,
        isContentValidationError: false,
        settlementMonth: defaultSettlementMonthOptions,
        errorMessage: "",
    };

    onChangeCheck = () => {
        const { checked } = this.state;
        this.setState({ checked: !checked });
    };

    onChangeContent = (value) => {
        this.setState({ content: value });
    };

    onChangeIsBlacklisted = (checked, event) => {
        this.setState({ isBlacklisted: checked });
    };

    onSearch = (value) => {
        const settlementMonth = defaultSettlementMonthOptions.filter(
            (option) => option.label.includes(value)
        );
        this.setState({ settlementMonth });
    }

    onPopupVisibleChange = (open) => {
        if (!open) {
            this.setState({ settlementMonth: defaultSettlementMonthOptions });
        }
    }

    handleSubmitWithCheck = (validatedValues) => {
        const { isBlacklisted } = this.state;
        let currentfieldtels = [];
        let currentfieldfaxs = [];
        this.baseform.current.validateFields().then((values) => {
            let telcheckresult = this.checkTelSelected(
                values,
                currentfieldtels
            );
            let faxcheckresult = this.checkFaxSelected(
                values,
                currentfieldfaxs
            );
            if (telcheckresult && faxcheckresult) {
                if (values["comment.content"]) {
                    values["comment"] = {
                        content: values["comment.content"],
                        is_important: this.state.checked,
                    };
                }
                if (!isBlacklisted) {
                    values["is_blacklisted"] = false;
                }
                values["name"] = values["name"].trim()
                values["branches"]?.forEach((branch)=>{
                    branch["name"]=branch["name"].trim()
                })
                this.submitHandler(values);
                this.setState({
                    checked: false,
                });
            }
        });
    };

    handleSubmitError = ({ values, errorFields, outOfDate }) => {
        let currentfieldtels = errorFields.filter(
            (field) =>
                field["name"][0] === "tel1" ||
                field["name"][0] === "tel2" ||
                field["name"][0] === "tel3"
        );
        let currentfieldfaxs = errorFields.filter(
            (field) =>
                field["name"][0] === "fax1" ||
                field["name"][0] === "fax2" ||
                field["name"][0] === "fax3"
        );
        this.checkTelSelected(values, currentfieldtels);
        this.checkNumberOfTelSelected(values, currentfieldtels);
        this.checkFaxSelected(values, currentfieldfaxs);
        this.checkNumberOfFaxSelected(values, currentfieldfaxs);
        if (errorFields) {
            let errorFieldNames = errorFields.map((field) => {
                return field["name"][0];
            });
            this.setState({ errorFields: errorFieldNames }); // stateに変更を入れないとエラーが画面に反映されないため
        }
    };

    handleKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
        }
    };

    componentDidMount() {
        this.fetchComments();
    }

    componentWillUnmount(){
        const { dispatch } = this.props;
        dispatch(CommentActions.newCommentClearAction());
        dispatch(CommentActions.editCommentClearAction());
    }

    componentDidUpdate() {
        const { dispatch, initialData, pageId } = this.props;
        if (
            !this.initialViewRendered &&
            initialData &&
            initialData.name &&
            this.baseform.current
        ) {
            dispatch({ type: pageId + CONTACT_FORM, initialData });
            this.baseform.current.setFieldsValue(initialData);
            this.setState({
                isBlacklisted: initialData.is_blacklisted,
            });
            this.initialViewRendered = true;
        }
    }

    redirectToContactForm = () => {
        const { history, dispatch, initialData, pageId } = this.props;
        dispatch({ type: pageId + CONTACT_FORM, initialData });
        history.push(Path.contactRegister);
    };

    fetchComments = () => {
        const { dispatch, token, pageId } = this.props;
        dispatch(fetchCommentTemplateAction(pageId, token, commentTemplateUrl));
    };

    createComments = (comment_templates) => {
        const { dispatch, token, pageId } = this.props;
        dispatch(
            createCommentTemplateAction(pageId, token, commentTemplateUrl, {
                templates: comment_templates,
            })
        );
    };

    // list
    showList = () => {
        this.setState({ listVisible: true });
    };

    // register
    showRegister = () => {
        this.setState({
            registerVisible: true,
            newTitle: undefined,
            newContent: undefined,
        });
    };

    registerOnOk = ({ newTitle, newContent }) => {
        const { comment_templates } = this.props;
        if (newTitle && newContent) {
            const newTemplate = {
                title: newTitle,
                content: newContent,
            };
            this.createComments([...comment_templates, newTemplate]);
            this.setState({ registerVisible: false });
            if (
                comment_templates
                    .map((template) => template.title)
                    .includes(newTitle)
            ) {
                return;
            }
            comment_templates.push(newTemplate);
        }
    };

    registerOnCancel = () => {
        this.setState({
            registerVisible: false,
            isTitleValidationError: false,
            isContentValidationError: false,
        });
    };

    // update
    showUpdate = (index) => {
        this.setState({
            updateVisible: true,
            selectedIndex: index,
            newTitle: undefined,
            newContent: undefined,
        });
    };

    updateOnOk = ({ newTitle: updatedTitle, newContent: updatedContent }) => {
        const { selectedIndex } = this.state;
        const { comment_templates } = this.props;
        let updated_templates = [];
        comment_templates.map((template, index) => {
            if (index === selectedIndex) {
                let title = updatedTitle ? updatedTitle : template.title;
                let content = updatedContent
                    ? updatedContent
                    : template.content;
                updated_templates.push({ title: title, content: content });
            } else {
                updated_templates.push(template);
            }
        });
        this.setState({ updateVisible: false });
        this.createComments(updated_templates);
    };

    updateOnCancel = () => {
        this.setState({ updateVisible: false, selectedIndex: undefined });
    };

    // delete
    showDelete = (index) => {
        this.setState({ deleteVisible: true, selectedIndex: index });
        confirmModal({
            title: "このテンプレートを削除しますか？",
            content: (
                <div>
                    <p>OKを押すと、削除が実行されます。</p>
                    <p>元には戻せません。</p>
                </div>
            ),
            onOk: this.deleteOnOk,
            onCancel: this.deleteOnCancel,
        });
    };

    deleteOnOk = () => {
        const { selectedIndex } = this.state;
        const { comment_templates } = this.props;
        let not_deleted_templates = [];
        comment_templates.map((template, index) => {
            if (index !== selectedIndex) {
                not_deleted_templates.push(template);
            }
        });
        this.setState({
            deleteVisible: false,
            comment_templates: not_deleted_templates,
        });
        this.createComments(not_deleted_templates);
    };

    deleteOnCancel = () => {
        this.setState({ deleteVisible: false, selectedIndex: undefined });
    };

    setTemplateValueToCommentForm = (index) => {
        const { comment_templates } = this.props;
        this.baseform.current.setFieldsValue({
            "comment.content": comment_templates[index].content,
        });
    };

    toggle = () => {
        const { qualificationExpand } = this.state;
        this.setState({ qualificationExpand: !qualificationExpand });
    };

    isRequiredItem = (key) => {
        const { selectedRequireItemKeys } = this.props;
        return selectedRequireItemKeys && selectedRequireItemKeys.includes(key);
    };

    getCurrentFieldError = (name) => {
        return (
            this.baseform.current &&
            this.baseform.current.getFieldError(name).length > 0
        );
    };

    checkTelSelected = (values, currentfieldtels) => {
        if (currentfieldtels.length > 0) {
            this.setState({ currentTel: false });
            return true;
        }
        if (!values["tel1"] && !values["tel2"] && !values["tel3"]) {
            this.setState({ currentTel: false });
            return true;
        }
        if (!values["tel1"] || !values["tel2"] || !values["tel3"]) {
            this.setState({ currentTel: true });
            return false;
        } else {
            this.setState({ currentTel: false });
            return true;
        }
    };

    checkNumberOfTelSelected = (values, currentfieldtels) => {
        if (currentfieldtels.length > 0) {
            this.setState({ currentTel: false });
            return true;
        }
        if (values["tel1"] === undefined) {
            values["tel1"] = "";
        }
        if (values["tel2"] === undefined) {
            values["tel2"] = "";
        }
        if (values["tel3"] === undefined) {
            values["tel3"] = "";
        }
        let tel = values["tel1"] + values["tel2"] + values["tel3"];
        if (tel.length > MaxTelLength) {
            this.setState({ numberOfTel: true });
            return false;
        } else {
            this.setState({ numberOfTel: false });
            return true;
        }
    };

    checkFaxSelected = (values, currentfieldfaxs) => {
        if (currentfieldfaxs.length > 0) {
            this.setState({ currentFax: false });
            return true;
        }
        if (!values["fax1"] && !values["fax2"] && !values["fax3"]) {
            this.setState({ currentFax: false });
            return true;
        }

        if (!values["fax1"] || !values["fax2"] || !values["fax3"]) {
            this.setState({ currentFax: true });
            return false;
        } else {
            this.setState({ currentFax: false });
            return true;
        }
    };

    checkNumberOfFaxSelected = (values, currentfieldfaxs) => {
        if (currentfieldfaxs.length > 0) {
            this.setState({ currentFax: false });
            return true;
        }
        if (values["fax1"] === undefined) {
            values["fax1"] = "";
        }
        if (values["fax2"] === undefined) {
            values["fax2"] = "";
        }
        if (values["fax3"] === undefined) {
            values["fax3"] = "";
        }

        let fax = values["fax1"] + values["fax2"] + values["fax3"];
        if (fax.length > MaxTelLength) {
            this.setState({ numberOfFax: true });
            return false;
        } else {
            this.setState({ numberOfFax: false });
            return true;
        }
    };

    infoTooltip = () => {
        return (
            <Tooltip
                title={
                    <span>
                        取引先登録／編集の取引条件と
                        <a
                            href={`${Path.myCompany}`}
                            target="_blank"
                            rel="noopener noreferrer">
                            自社プロフィール
                        </a>
                        の取引条件の双方をクリアしなければ、
                        <a
                            href={`${Path.scheduledMails}/register`}
                            target="_blank"
                            rel="noopener noreferrer">
                            配信メール予約／編集
                        </a>
                        の宛先一覧に表示されません。
                        <br />
                        ただし、商流を抜ける場合はその限りではありません。
                        <br />
                        <a
                            href={
                                Links.helps.commercialDistribution
                                    .transactionTermsPatterns
                            }
                            target="_blank"
                            rel="noopener noreferrer">
                            詳細
                        </a>
                    </span>
                }>
                <InfoCircleTwoTone twoToneColor={warningColor} />
            </Tooltip>
        );
    };

    tabBarExtraContent = () => {
        const { initialData, fieldErrors, authorizedActions } = this.props;
        const { isBlacklisted, tabKey } = this.state;

        let blacklistAuthorized =
            authorizedActions &&
            authorizedActions["organizations"] &&
            authorizedActions["organizations"]["blacklist"];

        return (
            <Row align="middle" gutter={16}>
                <Col>
                    <Form.Item
                            label={
                                <span style={{ fontSize: "10px" }}>
                                    ブロックリスト
                                </span>
                            }
                            className={styles.field}
                            help={fieldErrors.is_blacklisted}
                            name="is_blacklisted"
                            initialValue={initialData.is_blacklisted}
                            colon={false}
                            labelAlign="right"
                            labelCol={{ span: 16 }}>
                            {blacklistAuthorized ? (
                                <Switch
                                    size="small"
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    onChange={this.onChangeIsBlacklisted}
                                    checked={isBlacklisted}
                                />
                            ) : (
                                <Tooltip title={"特定の権限で操作できます"}>
                                    <Switch
                                        size="small"
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        checked={isBlacklisted}
                                        disabled={true}
                                    />
                                </Tooltip>
                            )}
                        </Form.Item>
                </Col>
                {tabKey === 3 && (
                    <Col>
                        {this.infoTooltip()}
                    </Col>
                )}
            </Row>
        );
    };

    contactList = () => {
        const { initialData } = this.props;
        const contacts = initialData.related_contacts;

        const renderDescription = (contact) => {
            const position = contact.position ? contact.position : "役職未登録";
            const department = contact.department
                ? contact.department
                : "部署未登録";

            return (
                <Col span={24}>
                    <Row>
                        <Col justify="start" span={12}>
                            <Text type="secondary" ellipsis>
                                {position}
                            </Text>
                        </Col>
                        <Col justify="end" span={12}>
                            <Text type="secondary" ellipsis>
                                {department}
                            </Text>
                        </Col>
                    </Row>
                </Col>
            );
        };

        return (
            <>
                {contacts && contacts.length ? (
                    <React.Fragment>
                        <List
                            header={<div>取引先担当者</div>}
                            size="small"
                            bordered={false}
                            split={false}
                            itemLayout="horizontal"
                            dataSource={contacts}
                            renderItem={(contact) => (
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={
                                            <UserOutlined
                                                style={{
                                                    color: iconPrimaryColor,
                                                }}
                                            />
                                        }
                                        title={<Link to={`${Path.contacts}/${contact.id}`} target="_blank" style={{color: iconPrimaryColor}}>{contact.display_name}</Link>}
                                        description={renderDescription(contact)}
                                    />
                                </List.Item>
                            )}
                            style={{ marginBottom: "24px" }}
                        />
                        <Row justify="center">
                            <Col>
                                <Button
                                    key={1}
                                    size="small"
                                    type="primary"
                                    onClick={() =>
                                        this.redirectToContactForm()
                                    }>
                                    担当者を登録する
                                </Button>
                            </Col>
                        </Row>
                    </React.Fragment>
                ) : (
                    <Result
                        icon={
                            <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description={false}
                            />
                        }
                        subTitle="データがありません"
                        extra={[
                            <Button
                                size="small"
                                key={1}
                                type="primary"
                                onClick={() => this.redirectToContactForm()}>
                                担当者を登録する
                            </Button>,
                        ]}
                    />
                )}
            </>
        );
    };

    buildDefaultSelectTags = () => {
        const { initialData } = this.props;
        let selectedTags = [];
        if (initialData.tag_id0 && initialData.tag_name0) {
            selectedTags.push({
                id: initialData.tag_id0,
                value: initialData.tag_name0,
            });
        }
        if (initialData.tag_id1 && initialData.tag_name1) {
            selectedTags.push({
                id: initialData.tag_id1,
                value: initialData.tag_name1,
            });
        }
        if (initialData.tag_id2 && initialData.tag_name2) {
            selectedTags.push({
                id: initialData.tag_id2,
                value: initialData.tag_name2,
            });
        }
        if (initialData.tag_id3 && initialData.tag_name3) {
            selectedTags.push({
                id: initialData.tag_id3,
                value: initialData.tag_name3,
            });
        }
        if (initialData.tag_id4 && initialData.tag_name4) {
            selectedTags.push({
                id: initialData.tag_id4,
                value: initialData.tag_name4,
            });
        }
        return selectedTags;
    };

    render() {
        const {
            initialData,
            fieldErrors,
            created,
            comment_templates,
            authorizedActions,
            comments,
            deleteButton,
            commentsReducerName,
            selectedRequireItemKeys,
            isPersonalPlan = false,
        } = this.props;
        const { selectedIndex, isBlacklisted, settlementMonth, errorMessage } = this.state;

        let createAuthorized =
            authorizedActions &&
            authorizedActions["organizations"] &&
            authorizedActions["organizations"]["create"];

        let updateAuthorized =
            authorizedActions &&
            authorizedActions["organizations"] &&
            authorizedActions["organizations"]["update"];

        let commentPinAuthorization =
            authorizedActions &&
            authorizedActions["comment"] &&
            authorizedActions["comment"]["pin"];

        const requiredFields = selectedRequireItemKeys
            .toString()
            .replace("tel", "tel1")
            .replace("fax", "fax1")
            .replace(
                "document",
                "has_outsourcing_basic_contact,has_nda,has_handring_personal_information"
            )
            .replace(
                "license",
                "has_invoice_system,has_haken,has_p_mark_or_isms"
            )
            .split(",")
            .filter((item) => item !== "score");

            const createCommentPinTooltipMessage = () => {
                if ( !(this.state.checked) && commentPinAuthorization) {
                    return "コメントを固定"
                }
                if ((this.state.checked) && commentPinAuthorization ) {
                    return "コメントの固定解除"
                }
                return ErrorMessages.isNotAuthorized
            }

        return (
            <Row
                justify="start"
                style={{ backgroundColor: isBlacklisted ? "#efefef" : "" }}>
                <Col {...containerLayout}>
                    <Form
                        onFinish={this.handleSubmitWithCheck}
                        onFinishFailed={this.handleSubmitError}
                        ref={this.baseform}
                        validateMessages={validateJapaneseMessages}
                        style={{ textAlign: "left" }}
                        labelAlign="right">
                        <Tabs
                            defaultActiveKey="1"
                            tabBarExtraContent={this.tabBarExtraContent()}
                            onTabClick={(key, _) =>
                                this.setState({ ...this.state, tabKey: +key })
                            }>
                            <TabPane tab="取引先情報" key="1">
                                <Form.Item
                                    {...organizationPaneFormItemLayout}
                                    label="法人番号"
                                    className={styles.field}
                                    validateStatus={
                                        fieldErrors.corporate_number
                                            ? "error"
                                            : undefined
                                    }
                                    help={fieldErrors.corporate_number}
                                    required={this.isRequiredItem(
                                        "corporate_number"
                                    )}>
                                    <Form.Item
                                        {...organizationPaneFormItemLayout}
                                        label=" "
                                        className={styles.field}
                                        help={fieldErrors.corporate_number}
                                        name="corporate_number"
                                        initialValue={
                                            initialData.corporate_number
                                        }
                                        rules={[
                                            {
                                                required:
                                                    this.isRequiredItem(
                                                        "corporate_number"
                                                    ),
                                            },
                                            {
                                                validator: (_, value) => {
                                                    const convertedValue =
                                                        String(value ?? "");
                                                    if (
                                                        convertedValue.length >= 1
                                                        && convertedValue.length !== 13
                                                    ) {
                                                        return Promise.reject(
                                                            new Error(
                                                                ErrorMessages.validation.length.max13
                                                            )
                                                        );
                                                    }
                                                    return Promise.resolve();
                                                },
                                            },
                                        ]}
                                        noStyle>
                                        <TextualNumberInput
                                            style={{ width: InputNumberWidth }}
                                            onKeyDown={this.handleKeyDown}
                                        />
                                    </Form.Item>
                                    <Tooltip title="外部ページへ移動します。">
                                        <a
                                            href={Links.services.houjinBangou}
                                            target="_blank"
                                            rel="noopener noreferrer">
                                            <CloudTwoTone
                                                style={{ marginLeft: 32 }}
                                                twoToneColor={iconPrimaryColor}
                                            />
                                            調べる
                                        </a>
                                    </Tooltip>
                                </Form.Item>
                                <Form.Item
                                    {...organizationPaneFormItemLayout}
                                    label="取引先名"
                                    className={styles.field}
                                    validateStatus={
                                        fieldErrors.name ? "error" : undefined
                                    }
                                    help={fieldErrors.name}
                                    name="name"
                                    initialValue={initialData.name}
                                    rules={[
                                        { required: true },
                                        {
                                            max: 100,
                                            message:
                                                ErrorMessages.validation.length
                                                    .max100,
                                        },
                                        {
                                                pattern: NO_ONLY_SPACES_REGEX,
                                                message: ErrorMessages.validation.regex.notOnlySpace,
                                        },
                                    ]}>
                                    <TextArea
                                        autoSize={{ minRows: 1 }}
                                        placeholder="サンプル株式会社"
                                    />
                                </Form.Item>
                                <Form.Item
                                    {...organizationPaneFormItemLayout}
                                    label="取引先ステータス"
                                    className={styles.field}
                                    help={fieldErrors.category}
                                    name="category"
                                    initialValue={initialData.category}
                                    rules={[{ required: true }]}>
                                    <Radio.Group>
                                        <Radio value="prospective">
                                            見込み客
                                        </Radio>
                                        <Radio value="approached">
                                            アプローチ済
                                        </Radio>
                                        <Radio value="exchanged">
                                            情報交換済
                                        </Radio>
                                        <Radio value="client">契約実績有</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item
                                    {...organizationPaneFormItemLayout}
                                    label="取引先評価"
                                    className={styles.field}
                                    help={fieldErrors.score}
                                    name="score"
                                    initialValue={initialData.score || 3}
                                    rules={[
                                        {
                                            required:
                                                this.isRequiredItem("score"),
                                        },
                                    ]}>
                                    <Rate allowClear={false} />
                                </Form.Item>
                                <Form.Item
                                    {...organizationPaneFormItemLayout}
                                    label="国籍"
                                    className={styles.field}
                                    help={fieldErrors.country}
                                    name="country"
                                    initialValue={initialData.country}
                                    rules={[
                                        {
                                            required:
                                                this.isRequiredItem("country"),
                                        },
                                    ]}>
                                    <Radio.Group>
                                        <Radio value="JP">日本</Radio>
                                        <Radio value="KR">韓国</Radio>
                                        <Radio value="CN">中国</Radio>
                                        <Radio value="OTHER">その他</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item
                                    {...organizationPaneFormItemLayout}
                                    label="設立年月"
                                    className={styles.field}
                                    help={fieldErrors.establishment_date}
                                    name="establishment_date"
                                    initialValue={
                                        initialData.establishment_date
                                    }
                                    rules={[
                                        {
                                            required:
                                                this.isRequiredItem(
                                                    "establishment_date"
                                                ),
                                        },
                                    ]}>
                                    <CustomMonthPicker
                                        placeholder="日付を選択"
                                        inputReadOnly
                                        disabledDate={disabledFutureDates}
                                    />
                                </Form.Item>
                                <Form.Item
                                    {...organizationPaneFormItemLayout}
                                    label="決算期"
                                    className={styles.field}
                                    help={fieldErrors.settlement_month}
                                    name="settlement_month"
                                    initialValue={initialData.settlement_month}
                                    rules={[
                                        {
                                            required:
                                                this.isRequiredItem(
                                                    "settlement_month"
                                                ),
                                        },
                                    ]}>
                                    <Select
                                        style={{ width: 120 }}
                                        placeholder="月を選択"
                                        showSearch
                                        filterOption={false}
                                        onSearch={this.onSearch}
                                        onDropdownVisibleChange={this.onPopupVisibleChange}
                                        allowClear>
                                        {settlementMonth.map((month)=>(
                                            <Select.Option key={month.value} value={month.value}>
                                                {month.label}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <AddressInputFormItem
                                    {...organizationPaneFormItemLayout}
                                    required={this.isRequiredItem("address")}
                                    className={styles.field}
                                    addressProps={{
                                        validateStatus:
                                            fieldErrors.address ||
                                            fieldErrors.building ||
                                            this.getCurrentFieldError("address")
                                                ? "error"
                                                : "success",
                                        help: fieldErrors.address,
                                        rules: [
                                            {
                                                required:
                                                    this.isRequiredItem(
                                                        "address"
                                                    ),
                                            },
                                        ],
                                        initialValue: initialData.address,
                                    }}
                                    buildingProps={{
                                        validateStatus: fieldErrors.building
                                            ? "error"
                                            : "success",
                                        help: fieldErrors.building,
                                        initialValue: initialData.building,
                                    }}
                                />
                                <TelInputFormItem
                                    {...organizationPaneFormItemLayout}
                                    validateStatus={
                                        fieldErrors?.tel ? "error" : undefined
                                    }
                                    help={fieldErrors?.tel}
                                    required={this.isRequiredItem("tel")}
                                />
                                <FaxInputFormItem
                                    {...organizationPaneFormItemLayout}
                                    validateStatus={
                                        fieldErrors?.fax ? "error" : undefined
                                    }
                                    help={fieldErrors?.fax}
                                    required={this.isRequiredItem("fax")}
                                    onKeyDown={this.handleKeyDown}
                                />
                                <Form.Item
                                    {...organizationPaneFormItemLayout}
                                    label="URL"
                                    className={styles.field}
                                    required={this.isRequiredItem(
                                        "domain_name"
                                    )}>
                                    <Input.Group>
                                        <Form.Item
                                            validateStatus={
                                                fieldErrors.domain_name
                                                    ? "error"
                                                    : undefined
                                            }
                                            help={fieldErrors.domain_name}
                                            name="domain_name"
                                            initialValue={
                                                initialData.domain_name
                                            }
                                            rules={[
                                                {
                                                    required:
                                                        this.isRequiredItem(
                                                            "domain_name"
                                                        ),
                                                },
                                                {
                                                    pattern: ONLY_HANKAKU_REGEX,
                                                    message:
                                                        ErrorMessages.validation
                                                            .regex.onlyHankaku,
                                                },
                                                {
                                                    pattern:
                                                        RESTRICT_SPACE_REGEX,
                                                    message:
                                                        ErrorMessages.validation
                                                            .regex.space,
                                                },
                                                {
                                                    max: 50,
                                                    message:
                                                        ErrorMessages.validation
                                                            .length.max50,
                                                },
                                            ]}
                                            style={{ marginBottom: 0 }}>
                                            <TextArea
                                                placeholder="https://"
                                                autoSize={{ minRows: 1 }}
                                            />
                                        </Form.Item>
                                    </Input.Group>
                                </Form.Item>
                                <Form.Item
                                    {...organizationPaneFormItemLayout}
                                    label="社員数"
                                    className={styles.field}
                                    help={fieldErrors.employee_number}
                                    name="employee_number"
                                    initialValue={initialData.employee_number}
                                    rules={[
                                        {
                                            required:
                                                this.isRequiredItem(
                                                    "employee_number"
                                                ),
                                        },
                                    ]}>
                                    <Radio.Group>
                                        <Radio value="very_low">~10名</Radio>
                                        <Radio value="low">11~30名</Radio>
                                        <Radio value="middle">31~50名</Radio>
                                        <Radio value="semi_middle">
                                            51~100名
                                        </Radio>
                                        <Radio value="high">101~300名</Radio>
                                        <Radio value="very_high">301名~</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item
                                    {...organizationPaneFormItemLayout}
                                    label={
                                        <span>
                                            商流&nbsp;
                                            <Tooltip
                                                title={
                                                    <span>
                                                        商流を抜けない：取引条件を満たさないと取引先対象外になる。<br />
                                                        商流を抜ける：取引条件を満たさなくても取引先対象外にならない。
                                                        <br />
                                                        <a
                                                            href={
                                                                Links.helps
                                                                    .commercialDistribution
                                                                    .about
                                                            }
                                                            target="_blank"
                                                            rel="noopener noreferrer">
                                                            詳細
                                                        </a>
                                                    </span>
                                                }>
                                                <QuestionCircleFilled
                                                    style={{
                                                        color: iconCustomColor,
                                                    }}
                                                    className={styles.tooltip}
                                                />
                                            </Tooltip>
                                        </span>
                                    }
                                    className={styles.field}
                                    help={fieldErrors.has_distribution}
                                    name="has_distribution"
                                    initialValue={initialData.has_distribution}
                                    rules={[
                                        {
                                            required:
                                                this.isRequiredItem(
                                                    "has_distribution"
                                                ),
                                        },
                                    ]}>
                                    <Radio.Group>
                                        <Radio value={false}>抜けない</Radio>
                                        <Radio value={true}>抜ける</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item
                                    {...organizationPaneFormItemLayout}
                                    label="請負"
                                    className={styles.field}
                                    help={fieldErrors.contract}
                                    name="contract"
                                    initialValue={initialData.contract}
                                    rules={[
                                        {
                                            required:
                                                this.isRequiredItem("contract"),
                                        },
                                    ]}>
                                    <Radio.Group>
                                        <Radio value={false}>なし</Radio>
                                        <Radio value={true}>あり</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item
                                    {...organizationPaneFormItemLayout}
                                    label="資本金"
                                    className={styles.field}
                                    validateStatus={
                                        fieldErrors.capital_man_yen
                                            ? "error"
                                            : undefined
                                    }
                                    help={fieldErrors.capital_man_yen}
                                    required={this.isRequiredItem(
                                        "capital_man_yen"
                                    )}>
                                    <Row align="middle" gutter={4}>
                                        <Col>
                                            <Form.Item
                                                name="capital_man_yen"
                                                initialValue={
                                                    initialData.capital_man_yen
                                                }
                                                rules={[
                                                    {
                                                        required:
                                                            this.isRequiredItem(
                                                                "capital_man_yen"
                                                            ),
                                                    },
                                                    {
                                                        type: "number",
                                                        min: 1,
                                                        message:
                                                            "1以上の値を入力してください",
                                                    },
                                                    {
                                                        pattern:
                                                            HANKAKU_NUMBER_REGEX,
                                                        message:
                                                            ErrorMessages
                                                                .validation
                                                                .regex
                                                                .onlyHankakuNumber,
                                                    },
                                                    {
                                                        validator: (
                                                            _,
                                                            value
                                                        ) => {
                                                            const convertedValue =
                                                                String(
                                                                    value ?? ""
                                                                );
                                                            if (
                                                                convertedValue.length >
                                                                9
                                                            ) {
                                                                return Promise.reject(
                                                                    new Error(
                                                                        ErrorMessages.validation.length.max9
                                                                    )
                                                                );
                                                            }
                                                            return Promise.resolve();
                                                        },
                                                    },
                                                ]}
                                                noStyle>
                                                <InputNumber
                                                    style={{
                                                        width: InputNumberWidth,
                                                    }}
                                                    formatter={
                                                        formatMoneyNumberString
                                                    }
                                                    parser={
                                                        parseMoneyNumberString
                                                    }
                                                    step={100}
                                                    onKeyDown={this.handleKeyDown}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col>
                                            <span>万円</span>
                                        </Col>
                                    </Row>
                                </Form.Item>
                                <Form.Item
                                    {...organizationPaneFormItemLayout}
                                    label="契約書類"
                                    className={styles.field}
                                    style={{ marginBottom: "0" }}></Form.Item>
                                <Form.Item>
                                    <div>
                                        <Form.Item
                                            {...certificateItemLayout}
                                            label="業務委託基本契約"
                                            className={styles.field}
                                            help={
                                                fieldErrors.has_outsourcing_basic_contact
                                            }
                                            name="has_outsourcing_basic_contact"
                                            initialValue={
                                                initialData.has_outsourcing_basic_contact
                                            }
                                            rules={[
                                                {
                                                    required:
                                                        this.isRequiredItem(
                                                            "document"
                                                        ),
                                                },
                                            ]}>
                                            <Radio.Group>
                                                <Radio value={false}>
                                                    未締結
                                                </Radio>
                                                <Radio value={true}>
                                                    締結済
                                                </Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                        <Form.Item
                                            {...certificateItemLayout}
                                            label="NDA"
                                            className={styles.field}
                                            help={fieldErrors.has_nda}
                                            name="has_nda"
                                            initialValue={initialData.has_nda}
                                            rules={[
                                                {
                                                    required:
                                                        this.isRequiredItem(
                                                            "document"
                                                        ),
                                                },
                                            ]}>
                                            <Radio.Group>
                                                <Radio value={false}>
                                                    未締結
                                                </Radio>
                                                <Radio value={true}>
                                                    締結済
                                                </Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                        <Form.Item
                                            {...certificateItemLayout}
                                            label="個人情報の取り扱い"
                                            className={styles.field}
                                            help={
                                                fieldErrors.has_handring_personal_information
                                            }
                                            name="has_handring_personal_information"
                                            initialValue={
                                                initialData.has_handring_personal_information
                                            }
                                            rules={[
                                                {
                                                    required:
                                                        this.isRequiredItem(
                                                            "document"
                                                        ),
                                                },
                                            ]}>
                                            <Radio.Group>
                                                <Radio value={false}>
                                                    未締結
                                                </Radio>
                                                <Radio value={true}>
                                                    締結済
                                                </Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </div>
                                </Form.Item>
                                <Form.Item
                                    {...organizationPaneFormItemLayout}
                                    label="保有資格"
                                    className={styles.field}
                                    style={{ marginBottom: "0" }}></Form.Item>
                                <Form.Item>
                                    <div>
                                        <Form.Item
                                            {...certificateItemLayout}
                                            label="Pマーク／ISMS"
                                            className={styles.field}
                                            help={
                                                fieldErrors.has_p_mark_or_isms
                                            }
                                            name="has_p_mark_or_isms"
                                            initialValue={
                                                initialData.has_p_mark_or_isms
                                            }
                                            rules={[
                                                {
                                                    required:
                                                        this.isRequiredItem(
                                                            "license"
                                                        ),
                                                },
                                            ]}>
                                            <Radio.Group>
                                                <Radio value={false}>
                                                    なし
                                                </Radio>
                                                <Radio value={true}>あり</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                        <Form.Item
                                            {...certificateItemLayout}
                                            label="インボイス登録事業者"
                                            className={styles.field}
                                            help={
                                                fieldErrors.has_invoice_system
                                            }
                                            name="has_invoice_system"
                                            initialValue={
                                                initialData.has_invoice_system
                                            }
                                            rules={[
                                                {
                                                    required:
                                                        this.isRequiredItem(
                                                            "license"
                                                        ),
                                                },
                                            ]}>
                                            <Radio.Group>
                                                <Radio value={false}>
                                                    なし
                                                </Radio>
                                                <Radio value={true}>あり</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                        <Form.Item
                                            {...certificateItemLayout}
                                            label="労働者派遣事業"
                                            className={styles.field}
                                            help={fieldErrors.has_haken}
                                            name="has_haken"
                                            initialValue={initialData.has_haken}
                                            rules={[
                                                {
                                                    required:
                                                        this.isRequiredItem(
                                                            "license"
                                                        ),
                                                },
                                            ]}>
                                            <Radio.Group>
                                                <Radio value={false}>
                                                    なし
                                                </Radio>
                                                <Radio value={true}>あり</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </div>
                                </Form.Item>
                                <TagSelectFormItem
                                    {...organizationPaneFormItemLayout}
                                    required={this.isRequiredItem("tags")}
                                    onTagAdd={(newTag) => {
                                        const {
                                            getFieldValue,
                                            setFieldsValue,
                                        } = this.baseform.current;
                                        const tags =
                                            getFieldValue("tags") ?? [];
                                        setFieldsValue({
                                            tags: [...tags, newTag.id],
                                        });
                                    }}
                                    defaults={this.buildDefaultSelectTags()}
                                />
                                <Form.Item
                                    {...tailLayout}
                                    className={styles.field}></Form.Item>
                            </TabPane>
                            <TabPane tab="取引先支店情報" key="2">
                                <OrganizationBranchTabPaneContent
                                    form={this.baseform.current}
                                />
                                <Form.Item
                                    {...tailLayout}
                                    className={styles.field}></Form.Item>
                            </TabPane>
                            <TabPane tab="取引条件" key="3">
                                <Form.Item>
                                    <Form.Item
                                        {...transactionTermsFormItemLayout}
                                        label="取引に必要な設立年数"
                                        className={styles.field}
                                        validateStatus={
                                            fieldErrors.establishment_year
                                                ? "error"
                                                : undefined
                                        }
                                        help={fieldErrors.establishment_year}>
                                        <Row align="middle" gutter={4}>
                                            <Col>
                                                <Form.Item
                                                    name="establishment_year"
                                                    initialValue={
                                                        initialData.establishment_year
                                                    }
                                                    rules={[
                                                        {
                                                            type: "number",
                                                            min: 1,
                                                            message:
                                                                "1以上の値を入力してください",
                                                        },
                                                        {
                                                            pattern:
                                                                HANKAKU_NUMBER_REGEX,
                                                            message:
                                                                ErrorMessages
                                                                    .validation
                                                                    .regex
                                                                    .onlyHankakuNumber,
                                                        },
                                                        {
                                                            validator: (
                                                                _,
                                                                value
                                                            ) => {
                                                                const convertedValue =
                                                                    String(
                                                                        value ??
                                                                            ""
                                                                    );
                                                                if (
                                                                    convertedValue.length >
                                                                    3
                                                                ) {
                                                                    return Promise.reject(
                                                                        new Error(
                                                                            ErrorMessages.validation.length.max3
                                                                        )
                                                                    );
                                                                }
                                                                return Promise.resolve();
                                                            },
                                                        },
                                                    ]}
                                                    noStyle>
                                                    <InputNumber
                                                        style={{
                                                            width: InputNumberWidth,
                                                        }}
                                                        formatter={
                                                            formatMoneyNumberString
                                                        }
                                                        parser={
                                                            parseMoneyNumberString
                                                        }
                                                        min={0}
                                                        step={1}
                                                        onKeyDown={this.handleKeyDown}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col>
                                                <span>年以上</span>
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                    <Form.Item
                                        {...transactionTermsFormItemLayout}
                                        label="取引に必要な資本金"
                                        className={styles.field}
                                        validateStatus={
                                            fieldErrors.capital_man_yen_required_for_transactions
                                                ? "error"
                                                : undefined
                                        }
                                        help={
                                            fieldErrors.capital_man_yen_required_for_transactions
                                        }>
                                        <Row align="middle" gutter={4}>
                                            <Col>
                                                <Form.Item
                                                    name="capital_man_yen_required_for_transactions"
                                                    initialValue={
                                                        initialData.capital_man_yen_required_for_transactions
                                                    }
                                                    rules={[
                                                        {
                                                            type: "number",
                                                            min: 1,
                                                        },
                                                        {
                                                            pattern:
                                                                HANKAKU_NUMBER_REGEX,
                                                            message:
                                                                ErrorMessages
                                                                    .validation
                                                                    .regex
                                                                    .onlyHankakuNumber,
                                                        },
                                                        {
                                                            validator: (
                                                                _,
                                                                value
                                                            ) => {
                                                                const convertedValue =
                                                                    String(
                                                                        value ??
                                                                            ""
                                                                    );
                                                                if (
                                                                    convertedValue.length >
                                                                    9
                                                                ) {
                                                                    return Promise.reject(
                                                                        new Error(
                                                                            ErrorMessages.validation.length.max9
                                                                        )
                                                                    );
                                                                }
                                                                return Promise.resolve();
                                                            },
                                                        },
                                                    ]}
                                                    noStyle>
                                                    <InputNumber
                                                        style={{
                                                            width: InputNumberWidth,
                                                        }}
                                                        formatter={
                                                            formatMoneyNumberString
                                                        }
                                                        parser={
                                                            parseMoneyNumberString
                                                        }
                                                        step={100}
                                                        onKeyDown={this.handleKeyDown}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col>
                                                <span>万円以上</span>
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                    <Form.Item
                                        {...transactionTermsFormItemLayout}
                                        label="取引に必要な資格"
                                        className={styles.field}
                                        help={fieldErrors.p_mark_or_isms}>
                                        <Form.Item
                                            {...transactionTermsFormItemLayout}
                                            label=" "
                                            className={styles.field}
                                            help={fieldErrors.p_mark_or_isms}
                                            name="p_mark_or_isms"
                                            initialValue={
                                                initialData.p_mark_or_isms
                                            }
                                            valuePropName="checked"
                                            noStyle>
                                            <Checkbox>Pマーク／ISMS</Checkbox>
                                        </Form.Item>
                                        <Form.Item
                                            {...transactionTermsFormItemLayout}
                                            label=" "
                                            className={styles.field}
                                            help={fieldErrors.invoice_system}
                                            name="invoice_system"
                                            initialValue={
                                                initialData.invoice_system
                                            }
                                            valuePropName="checked"
                                            noStyle>
                                            <Checkbox>
                                                インボイス登録事業者
                                            </Checkbox>
                                        </Form.Item>
                                        <Form.Item
                                            {...transactionTermsFormItemLayout}
                                            label=" "
                                            className={styles.field}
                                            help={fieldErrors.haken}
                                            name="haken"
                                            initialValue={initialData.haken}
                                            valuePropName="checked"
                                            noStyle>
                                            <Checkbox>労働者派遣事業</Checkbox>
                                        </Form.Item>
                                    </Form.Item>
                                    <Form.Item
                                        {...tailLayout}
                                        className={styles.field}></Form.Item>
                                </Form.Item>
                            </TabPane>
                            {initialData.id && (
                                <TabPane tab="所属取引先担当者" key="4">
                                    <Row span={24}>
                                        <Col span={18}>
                                            {this.contactList()}
                                            <Form.Item
                                                {...tailLayout}
                                                className={
                                                    styles.field
                                                }></Form.Item>
                                        </Col>
                                    </Row>
                                </TabPane>
                            )}
                        </Tabs>

                        {!initialData.id && (
                            <div>
                                <Title level={5}>コメント</Title>
                                <Form.Item
                                    className="validate-textarea"
                                    name="comment.content"
                                    rules={[
                                        {
                                            max: MaxCommentLength,
                                            message:
                                                ErrorMessages.validation.length
                                                    .max2000,
                                        },
                                        {
                                            validator: (_, value) => {
                                                if (ONLY_SPACES_AND_NEWLINE_REGEX.test(value)) {
                                                    this.setState({ errorMessage: ErrorMessages.validation.regex.notOnlySpace });
                                                    return Promise.reject(new Error(ErrorMessages.validation.regex.notOnlySpace));
                                                }
                                                this.setState({ errorMessage: "" });
                                                return Promise.resolve();
                                            },
                                        }
                                    ]}>
                                    <UserMentionsForm
                                        value={this.state.content}
                                        onChange={this.onChangeContent}
                                        status={
                                            this.state.content.length > MaxCommentLength || errorMessage
                                                ? "error"
                                                : undefined
                                        }
                                    />
                                </Form.Item>
                                <ValidateTextArea
                                    contentTextArea={this.state.content}
                                    errorMessages={
                                        ErrorMessages.validation.length.max2000
                                    }
                                    margin="0px"
                                    className="validate-textarea-error"
                                    extraErrorMessage={errorMessage}
                                />
                                <Form.Item>
                                    <Tooltip title={createCommentPinTooltipMessage()}>
                                        <Button
                                            className={styles.controlButton}
                                            size="small"
                                            onClick={() => this.onChangeCheck()}
                                            icon={
                                                this.state.checked ? (
                                                    <PushpinFilled />
                                                ) : (
                                                    <PushpinOutlined />
                                                )
                                            }
                                            type={"primary"}
                                            disabled={!commentPinAuthorization}
                                        />
                                    </Tooltip>
                                    <Tooltip
                                        title={isPersonalPlan ? (
                                            <span>
                                                この機能はライト以上でご利用可能です。アップグレードは
                                                <TooltipContentLink
                                                    to={Paths.plan.index}
                                                    title="こちら"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                />
                                                から。
                                            </span>
                                        ) : "コメントのテンプレート選択" }>
                                        <Button
                                            className={styles.focusButton}
                                            size="small"
                                            type="primary"
                                            onClick={() => this.showList()}
                                            icon={<SnippetsOutlined />}
                                            disabled={isPersonalPlan}
                                        />
                                    </Tooltip>
                                </Form.Item>
                                <CommentTemplateModal
                                    reducerId={commentsReducerName}
                                    isModalVisible={this.state.listVisible}
                                    onModalClose={() =>
                                        this.setState({ listVisible: false })
                                    }
                                    onCreateTemplate={this.showRegister}
                                    onInsertTemplate={
                                        this.setTemplateValueToCommentForm
                                    }
                                    onEditTemplate={this.showUpdate}
                                    onDeleteTemplate={this.showDelete}
                                />
                                <CommentTemplateCreateModal
                                    isOpen={this.state.registerVisible}
                                    onOk={this.registerOnOk}
                                    onCancel={this.registerOnCancel}
                                />
                                <CommentTemplateUpdateModal
                                    initialData={
                                        comment_templates[selectedIndex] &&
                                        comment_templates[selectedIndex]
                                            .title &&
                                        comment_templates[selectedIndex].content
                                            ? {
                                                  newTitle:
                                                      comment_templates[
                                                          selectedIndex
                                                      ].title,
                                                  newContent:
                                                      comment_templates[
                                                          selectedIndex
                                                      ].content,
                                              }
                                            : { newTitle: "", newContent: "" }
                                    }
                                    isOpen={this.state.updateVisible}
                                    onOk={this.updateOnOk}
                                    onCancel={this.updateOnCancel}
                                />
                            </div>
                        )}

                        <Row>
                            <Col span={24}>{comments}</Col>
                        </Row>

                        <Divider></Divider>
                        <Row span={24}>
                            <Col span={12}>
                                <Form.Item>
                                    <Row justify="start">
                                        {initialData.id && (
                                            <BackButton
                                                fallback={
                                                    Paths.organizations +
                                                        "?page=1"
                                                }
                                            />
                                        )}
                                        {(updateAuthorized && initialData.id) ||
                                        createAuthorized ? (
                                            <Form.Item shouldUpdate>
                                                {() => (
                                                    <Button
                                                        type="primary"
                                                        htmlType="submit"
                                                        className={
                                                            styles.button
                                                        }
                                                        disabled={
                                                            created ||
                                                            (this.baseform
                                                                .current &&
                                                                (!!this.baseform.current
                                                                    .getFieldsError()
                                                                    .filter(
                                                                        ({
                                                                            errors,
                                                                        }) =>
                                                                            errors.length
                                                                    ).length ||
                                                                    !this.baseform.current.isFieldsTouched(
                                                                        requiredFields,
                                                                        true
                                                                    )))
                                                        }>
                                                        {initialData.id
                                                            ? "更新"
                                                            : "登録"}
                                                    </Button>
                                                )}
                                            </Form.Item>
                                        ) : (
                                            <Tooltip
                                                title={
                                                    "特定の権限で操作できます"
                                                }>
                                                <Button
                                                    type="primary"
                                                    htmlType="submit"
                                                    className={styles.button}
                                                    disabled={true}>
                                                    {initialData.id
                                                        ? "更新"
                                                        : "登録"}
                                                </Button>
                                            </Tooltip>
                                        )}
                                    </Row>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Row justify="end">{deleteButton}</Row>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        );
    }
}

OrganizationForm.propTypes = {
    dispatch: PropTypes.func.isRequired,
    resourceURL: PropTypes.string.isRequired,
    history: PropTypes.shape({
        goBack: PropTypes.func.isRequired,
        push: PropTypes.func.isRequired,
    }).isRequired,
    initialData: PropTypes.shape({
        // Corresponds to backend API.
        corporate_number: PropTypes.number,
        name: PropTypes.string,
        address: PropTypes.string,
        domain_name: PropTypes.string,
        building: PropTypes.string,
        settlement_month: PropTypes.number,
        capital_man_yen: PropTypes.number,
        capital_man_yen_required_for_transactions: PropTypes.number,
        category: PropTypes.string,
        employee_number: PropTypes.string,
        contract: PropTypes.bool,
        establishment_date: PropTypes.instanceOf(moment),
        establishment_year: PropTypes.number,
        score: PropTypes.number,
        p_mark_or_isms: PropTypes.bool,
        invoice_system: PropTypes.bool,
        haken: PropTypes.bool,
        is_blacklisted: PropTypes.bool,
        has_p_mark_or_isms: PropTypes.bool,
        has_invoice_system: PropTypes.bool,
        has_distribution: PropTypes.bool,
        has_haken: PropTypes.bool,
        related_contacts: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                display_name: PropTypes.string.isRequired,
                email: PropTypes.string.isRequired,
            })
        ),
        comment: PropTypes.shape({
            content: PropTypes.string,
            is_important: PropTypes.bool,
        }),
        has_outsourcing_basic_contact: PropTypes.bool,
        has_nda: PropTypes.bool,
        has_handring_personal_information: PropTypes.bool,
    }), // Override in child class and use PropTypes.shape instead.
    fieldErrors: PropTypes.shape({
        corporate_number: PropTypes.arrayOf(PropTypes.string),
        name: PropTypes.arrayOf(PropTypes.string),
        address: PropTypes.arrayOf(PropTypes.string),
        domain_name: PropTypes.arrayOf(PropTypes.string),
        building: PropTypes.arrayOf(PropTypes.string),
        settlement_month: PropTypes.arrayOf(PropTypes.string),
        capital_man_yen: PropTypes.arrayOf(PropTypes.string),
        capital_man_yen_required_for_transactions: PropTypes.arrayOf(
            PropTypes.string
        ),
        category: PropTypes.arrayOf(PropTypes.string),
        employee_number: PropTypes.arrayOf(PropTypes.string),
        contract: PropTypes.arrayOf(PropTypes.string),
        establishment_date: PropTypes.arrayOf(PropTypes.instanceOf(moment)),
        establishment_year: PropTypes.arrayOf(PropTypes.string),
        score: PropTypes.arrayOf(PropTypes.string),
        p_mark_or_isms: PropTypes.arrayOf(PropTypes.bool),
        invoice_system: PropTypes.arrayOf(PropTypes.bool),
        haken: PropTypes.arrayOf(PropTypes.bool),
        has_p_mark_or_isms: PropTypes.arrayOf(PropTypes.bool),
        has_invoice_system: PropTypes.arrayOf(PropTypes.bool),
        has_distribution: PropTypes.arrayOf(PropTypes.bool),
        has_haken: PropTypes.arrayOf(PropTypes.bool),
        is_blacklisted: PropTypes.arrayOf(PropTypes.string),
        has_outsourcing_basic_contact: PropTypes.arrayOf(PropTypes.bool),
        has_nda: PropTypes.arrayOf(PropTypes.bool),
        has_handring_personal_information: PropTypes.arrayOf(PropTypes.bool),
    }).isRequired,
    submitHandler: PropTypes.func.isRequired,
    resetFormHandler: PropTypes.func.isRequired,
    created: PropTypes.bool,
    comment_templates: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            content: PropTypes.string.isRequired,
        })
    ).isRequired,
    pageId: PropTypes.string,
    authorizedActions: PropTypes.object.isRequired,
    selectedRequireItemKeys: PropTypes.arrayOf(PropTypes.string),
    comments: PropTypes.object,
    deleteButton: PropTypes.object,
    commentsReducerName: PropTypes.string,
    isPersonalPlan: PropTypes.bool,
};

OrganizationForm.defaultProps = {
    initialData: {},
    created: false,
    comment_templates: [],
};

function mapStateToProps(state) {
    return {
        token: state.login.token,
        authorizedActions: state.login.authorizedActions,
    };
}

const _OrganizationFormWrapper = finalized(OrganizationForm);

const OrganizationFormWrapper = connect(mapStateToProps)(
    _OrganizationFormWrapper
);

export default withPlans(withRouter(OrganizationFormWrapper));
