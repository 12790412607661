// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabContainer .ant-tabs-tab{padding:4px 10px !important}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Forms/ScheduledEmailForm/TextAreaTabFormItem/TextAreaTabFormItem.scss"],"names":[],"mappings":"AAAA,4BAEI,2BAA4B","sourcesContent":[":global {\n  .tabContainer .ant-tabs-tab {\n    padding: 4px 10px !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
