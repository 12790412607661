// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CheckListFormItem-wrapper-cat6W{width:100%;margin-bottom:1.5rem}.CheckListFormItem-title-q02da{font-size:1.1rem;font-weight:bold;padding-left:0;width:100%}.CheckListFormItem-scrollableForm-dFwIM{max-height:calc(39px * 10);overflow-y:auto;width:100%}.CheckListFormItem-formItem-kZuTI{margin-bottom:0px}.CheckListFormItem-addButton-idmRr{margin-top:1.5rem}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/DataDisplay/CheckList/CheckListFormItem/CheckListFormItem.scss"],"names":[],"mappings":"AAAA,iCACI,UAAW,CACX,oBAAqB,CACxB,+BAGG,gBAAiB,CACjB,gBAAiB,CACjB,cAAe,CACf,UAAW,CACd,wCAGG,0BAA2B,CAC3B,eAAgB,CAChB,UAAW,CACd,kCAGG,iBAAkB,CACrB,mCAGG,iBAAkB","sourcesContent":[".wrapper {\n    width: 100%;\n    margin-bottom: 1.5rem;\n}\n\n.title {\n    font-size: 1.1rem;\n    font-weight: bold;\n    padding-left: 0;\n    width: 100%;\n}\n\n.scrollableForm {\n    max-height: calc(39px * 10);\n    overflow-y: auto;\n    width: 100%;\n}\n\n.formItem{\n    margin-bottom: 0px;\n}\n\n.addButton{\n    margin-top: 1.5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `CheckListFormItem-wrapper-cat6W`,
	"title": `CheckListFormItem-title-q02da`,
	"scrollableForm": `CheckListFormItem-scrollableForm-dFwIM`,
	"formItem": `CheckListFormItem-formItem-kZuTI`,
	"addButton": `CheckListFormItem-addButton-idmRr`
};
export default ___CSS_LOADER_EXPORT___;
