"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var antd_1 = require("antd");
var icons_1 = require("@ant-design/icons");
var constants_1 = require("~/utils/constants");
var PopoverContent = function (_a) {
    var onCreateList = _a.onCreateList, inputRef = _a.inputRef;
    var form = antd_1.Form.useForm()[0];
    var _b = (0, react_1.useState)(false), valid = _b[0], setValid = _b[1];
    var onChangeValues = (0, react_1.useCallback)(function () { return __awaiter(void 0, void 0, void 0, function () {
        var error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (form.getFieldValue("title").replace(/\s+/g, "").length === 0) {
                        setValid(false);
                        return [2 /*return*/];
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, form.validateFields()];
                case 2:
                    _a.sent();
                    setValid(true);
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    setValid(false);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); }, [setValid, form]);
    var onFinish = (0, react_1.useCallback)(function (values) {
        if (values === null || values === void 0 ? void 0 : values.title) {
            onCreateList(values.title);
            form.resetFields();
            setValid(false);
        }
    }, [setValid, form, onCreateList]);
    (0, react_1.useEffect)(function () {
        console.log(0);
        if (inputRef.current) {
            console.log(1);
            inputRef.current.focus();
        }
    }, [inputRef]);
    return (react_1.default.createElement(antd_1.Form, { form: form, onFinish: onFinish },
        react_1.default.createElement(antd_1.Form.Item, { name: "title", rules: [
                { required: true, message: constants_1.ErrorMessages.form.required },
                { max: 17, message: constants_1.ErrorMessages.validation.length.max17 }
            ] },
            react_1.default.createElement(antd_1.Input, { ref: inputRef, placeholder: "\u30EA\u30B9\u30C8\u540D", style: { width: "250px" }, onChange: onChangeValues })),
        react_1.default.createElement(antd_1.Form.Item, null,
            react_1.default.createElement(antd_1.Button, { type: "primary", htmlType: "submit", disabled: !valid }, "\u4F5C\u6210"))));
};
var BoardListCreatePopover = function (_a) {
    var isAppendable = _a.isAppendable, listAuthorized = _a.listAuthorized, onCreateList = _a.onCreateList;
    var _b = (0, react_1.useState)(false), isShowPopOver = _b[0], setIsShowPopOver = _b[1];
    var inputRef = (0, react_1.useRef)(null);
    var closePopOver = function () { return setIsShowPopOver(false); };
    var onSubmit = function (title) {
        closePopOver();
        onCreateList(title);
    };
    var renderContent = function () { return (react_1.default.createElement(PopoverContent, { onCreateList: onSubmit, inputRef: inputRef })); };
    var onPopoverVisibleChange = function (bool) {
        setIsShowPopOver(bool);
        setTimeout(function () {
            if (bool && inputRef.current) {
                inputRef.current.focus();
            }
        }, 50);
    };
    return (react_1.default.createElement(react_1.default.Fragment, null,
        (!listAuthorized || !isAppendable) && (react_1.default.createElement(antd_1.Tooltip, { title: !listAuthorized ? "特定の権限で操作できます" : "登録可能な上限件数に達しています。" },
            react_1.default.createElement(antd_1.Button, { type: "primary", size: "small", disabled: true }, "\u30EA\u30B9\u30C8\u8FFD\u52A0"))),
        listAuthorized && isAppendable && (react_1.default.createElement(antd_1.Popover, { placement: "bottomRight", content: renderContent(), title: "\u30EA\u30B9\u30C8\u3092\u8FFD\u52A0", trigger: "click", visible: isShowPopOver, onVisibleChange: onPopoverVisibleChange },
            react_1.default.createElement(antd_1.Button, { type: "primary", size: "small", icon: react_1.default.createElement(icons_1.PlusOutlined, null) }, "\u30EA\u30B9\u30C8\u8FFD\u52A0")))));
};
exports.default = BoardListCreatePopover;
