import React from "react";
import PropTypes from "prop-types";
import { Tag, Tooltip, Space, Skeleton, Rate } from "antd";
import Path from "~/components/Routes/Paths";
import GenericTable from "~/components/Tables/GenericTable";
import { formatMoneyNumber } from "~/components/helpers";
import TooltipCopy from "~/components/Common/TooltipCopy/TooltipCopy";
import {
    SafetyCertificateTwoTone,
    IdcardTwoTone,
    BranchesOutlined,
    AccountBookTwoTone,
    FileTextTwoTone,
    LockTwoTone,
    ProfileTwoTone,
    ToolTwoTone,
} from "@ant-design/icons";
import { iconPrimaryColor, ORGANIZATION_STATUS } from "~/utils/constants";
import { useSelector } from "react-redux";
import ListCommentsPopover from "~/components/Common/ListCommentsPopover/ListCommentsPopover";
import { useRouter } from "~/hooks/router";
import { stringBreak } from "~/utils/utils";
import styles from "./OrganizationsTable.scss";

const columns = [
    {
        title: "法人番号",
        dataIndex: "corporate_number",
        key: "corporate_number",
        sorter: true,
        width: 150,
    },
    {
        title: "取引先名",
        dataIndex: "name",
        key: "name",
        width: 240,
        className: styles.tooltipCopy,
        render: (name, record, column_index) => {
            return (
                <span>
                    {name}{" "}
                    <ListCommentsPopover
                        comments={record.comments}
                        columnIndex={column_index}
                    />
                    <TooltipCopy copyContent={name} />
                </span>
            );
        },
    },
    {
        title: "取引先ステータス",
        key: "organization_category__order",
        sorter: true,
        width: 160,
        dataIndex: "organization_category__order",
        render: (tag, record) => {
            const status = ORGANIZATION_STATUS.find(
                (status) => status.value === record.category
            );
            if (typeof record.category !== "string" || status === undefined) {
                return <span />;
            }

            return (
                <span>
                    <Tag color={status?.color} key={record.category}>
                        {status?.title}
                    </Tag>
                </span>
            );
        },
    },
    {
        title: "取引先評価",
        dataIndex: "score",
        key: "score",
        sorter: true,
        width: 120,
        render: (score, record) => {
            return (
                <span>
                    <Rate
                        disabled
                        value={record.score}
                        style={{ fontSize: 11 }}
                    />
                </span>
            );
        },
    },
    {
        title: "国籍",
        dataIndex: "organization_country__order",
        key: "organization_country__order",
        width: 70,
        render: (country, record) => {
            if (record.country == "JP") {
                return (
                    <span role="img">
                        <Tooltip title="日本">🇯🇵</Tooltip>
                    </span>
                );
            } else if (record.country == "KR") {
                return (
                    <span role="img">
                        <Tooltip title="韓国">🇰🇷</Tooltip>
                    </span>
                );
            } else if (record.country == "CN") {
                return (
                    <span role="img">
                        <Tooltip title="中国">🇨🇳</Tooltip>
                    </span>
                );
            } else if (record.country == "OTHER") {
                return (
                    <span role="img">
                        <Tooltip title="その他">🏳️</Tooltip>
                    </span>
                );
            }
        },
    },
    {
        title: "設立年月",
        dataIndex: "establishment_date",
        key: "establishment_date",
        sorter: true,
        width: 110,
        render: (establishment_date, record) => {
            if (record.establishment_date) {
                return record.establishment_date.replace(
                    /(\d+)-(\d+)-(\d+)/,
                    "$1-$2"
                );
            } else {
                return "";
            }
        },
    },
    {
        title: "決算期",
        dataIndex: "settlement_month",
        key: "settlement_month",
        sorter: true,
        width: 90,
        render: (settlement_month, record) => {
            if (record.settlement_month) {
                return record.settlement_month + "月";
            } else {
                return "";
            }
        },
    },
    {
        title: "住所",
        dataIndex: "address",
        key: "address",
        width: 280,
        className: styles.tooltipCopy,
        render: (address, record, column_index) => {
            return (
                <>
                    {record.address && record.building ? (
                        <>
                            {record.address + " " + record.building}
                            <TooltipCopy
                                copyContent={
                                    record.address + " " + record.building
                                }
                            />
                        </>
                    ) : record.address ? (
                        <>
                            {record.address}
                            <TooltipCopy copyContent={record.address} />
                        </>
                    ) : (
                        ""
                    )}
                </>
            );
        },
    },
    {
        title: "タグ",
        key: "tag_objects",
        dataIndex: "tag_objects",
        width: 250,
        render: (tag_objects, record, column_index) => {
            if (!record["tag_objects"] || record["tag_objects"].length == 0) {
                return <span />;
            }
            var content_list = tag_objects.map((tag, index) => {
                const breakNumber = 18;
                if (tag.value.length > breakNumber) {
                    const valueList = stringBreak(tag.value, breakNumber);
                    return (
                        <Tag
                            key={column_index + "_" + index}
                            color={tag.color}
                            style={{ marginTop: "5px" }}>
                            {valueList.map((val, idx) => {
                                if (idx + 1 === valueList.length) {
                                    return val;
                                }
                                return (
                                    <React.Fragment key={idx}>
                                        {val}
                                        <br />
                                    </React.Fragment>
                                );
                            })}
                        </Tag>
                    );
                }
                return (
                    <Tag
                        key={column_index + "_" + index}
                        color={tag.color}
                        style={{ marginTop: "5px" }}>
                        {tag.value}
                    </Tag>
                );
            });

            return <span>{content_list}</span>;
        },
    },
    {
        title: "TEL",
        dataIndex: "tel1",
        key: "tel1",
        width: 140,
        className: styles.tooltipCopy,
        render: (tel1, record, column_index) => {
            return (
                <>
                    {record.tel1 && record.tel2 && record.tel3 && (
                        <>
                            {record.tel1 +
                                "-" +
                                record.tel2 +
                                "-" +
                                record.tel3}
                            <TooltipCopy
                                copyContent={
                                    record.tel1 +
                                    "-" +
                                    record.tel2 +
                                    "-" +
                                    record.tel3
                                }
                            />
                        </>
                    )}
                </>
            );
        },
    },
    {
        title: "FAX",
        dataIndex: "fax1",
        key: "fax1",
        width: 140,
        render: (fax1, record, column_index) => {
            if (record.fax1 && record.fax2 && record.fax3) {
                return record.fax1 + "-" + record.fax2 + "-" + record.fax3;
            } else {
                return "";
            }
        },
    },
    {
        title: "URL",
        dataIndex: "domain_name",
        key: "domain_name",
        width: 280,
        className: styles.tooltipCopy,
        render: (domain_name, record, column_index) => {
            return (
                <>
                    {domain_name}
                    <TooltipCopy copyContent={domain_name} />
                </>
            );
        },
    },
    {
        title: "社員数",
        dataIndex: "organization_employee_number__order",
        key: "organization_employee_number__order",
        sorter: true,
        width: 120,
        render: (employee_number, record) => {
            if (record.employee_number == "very_low") {
                return <span>~10名</span>;
            } else if (record.employee_number == "low") {
                return <span>11~30名</span>;
            } else if (record.employee_number == "middle") {
                return <span>31~50名</span>;
            } else if (record.employee_number == "semi_middle") {
                return <span>51~100名</span>;
            } else if (record.employee_number == "high") {
                return <span>101~300名</span>;
            } else if (record.employee_number == "very_high") {
                return <span>301名~</span>;
            }
        },
    },
    {
        title: "商流",
        dataIndex: "has_distribution",
        key: "has_distribution",
        width: 120,
        render: (has_distribution, record) => {
            if (record["has_distribution"]) {
                return (
                    <span>
                        <Tooltip title="抜ける">
                            <BranchesOutlined
                                style={{ color: iconPrimaryColor }}
                            />
                        </Tooltip>
                    </span>
                );
            }
        },
    },
    {
        title: "請負",
        dataIndex: "contract",
        key: "contract",
        width: 120,
        render: (contract, record) => {
            if (record["contract"]) {
                return (
                    <span>
                        <Tooltip title="あり">
                            <ToolTwoTone twoToneColor={iconPrimaryColor} />
                        </Tooltip>
                    </span>
                );
            }
        },
    },
    {
        title: "資本金",
        dataIndex: "capital_man_yen",
        key: "capital_man_yen",
        sorter: true,
        width: 120,
        render: (value) => {
            if (!value && value !== 0) {
                return null;
            }

            return (
                <p style={{ textAlign: "right" }}>
                    {value === 0 ? value : formatMoneyNumber(value)} 万円
                </p>
            );
        },
        align: "left",
    },
    {
        title: "契約書類",
        dataIndex: "document",
        key: "document",
        width: 110,
        render: (document, record) => {
            return (
                <React.Fragment>
                    {record["has_outsourcing_basic_contact"] && (
                        <Tooltip title="業務委託基本契約">
                            <FileTextTwoTone
                                twoToneColor={iconPrimaryColor}
                                data-testid="has_outsourcing_basic_contact"
                            />
                            &nbsp;
                        </Tooltip>
                    )}
                    {record["has_nda"] && (
                        <Tooltip title="NDA">
                            <LockTwoTone
                                twoToneColor={iconPrimaryColor}
                                data-testid="has_nda"
                            />
                            &nbsp;
                        </Tooltip>
                    )}
                    {record["has_handring_personal_information"] && (
                        <Tooltip title="個人情報の取り扱い">
                            <ProfileTwoTone
                                twoToneColor={iconPrimaryColor}
                                data-testid="has_handring_personal_information"
                            />
                            &nbsp;
                        </Tooltip>
                    )}
                </React.Fragment>
            );
        },
    },
    {
        title: "保有資格",
        dataIndex: "has_p_mark_or_isms",
        key: "has_p_mark_or_isms",
        width: 110,
        render: (has_p_mark_or_isms, record) => {
            return (
                <React.Fragment>
                    {record["has_p_mark_or_isms"] && (
                        <Tooltip title="Pマーク／ISMS">
                            <SafetyCertificateTwoTone
                                twoToneColor={iconPrimaryColor}
                                data-testid="has_p_mark_or_isms"
                            />
                            &nbsp;
                        </Tooltip>
                    )}
                    {record["has_invoice_system"] && (
                        <Tooltip title="インボイス登録事業者">
                            <AccountBookTwoTone
                                twoToneColor={iconPrimaryColor}
                                data-testid="has_invoice_system"
                            />
                            &nbsp;
                        </Tooltip>
                    )}
                    {record["has_haken"] && (
                        <Tooltip title="労働者派遣事業">
                            <IdcardTwoTone
                                twoToneColor={iconPrimaryColor}
                                data-testid="has_haken"
                            />
                            &nbsp;
                        </Tooltip>
                    )}
                </React.Fragment>
            );
        },
    },
    {
        title: "取引先支店名",
        dataIndex: "branch_name",
        key: "branch_name",
        width: 160,
        render: (branchName, record, column_index) => {
            const branches = record.branches;
            if (branches && branches.length) {
                return branches.map((branch, index) => {
                    return (
                        <p key={index} style={{ margin: 1 }}>
                            <Tag>{branch.name}</Tag>
                        </p>
                    );
                });
            }
            return <></>;
        },
    },
    {
        title: "取引先支店住所",
        dataIndex: "branch_address",
        key: "branch_address",
        width: 280,
        render: (branchAddress, record, column_index) => {
            const branches = record.branches;
            if (branches && branches.length) {
                return branches.map((branch, index) => {
                    if (branch.address) {
                        return (
                            <p key={index} style={{ margin: 1 }}>
                                <Tag>
                                    {branch.address}{" "}
                                    {branch.building ? branch.building : " "}
                                </Tag>
                            </p>
                        );
                    } else {
                        return <p key={index} style={{ margin: 1 }}></p>;
                    }
                });
            }
            return <></>;
        },
    },
    {
        title: "取引先支店TEL",
        dataIndex: "branch_tel1",
        key: "branch_tel1",
        width: 140,
        render: (branchTel1, record, column_index) => {
            const branches = record.branches;
            if (branches && branches.length) {
                return branches.map((branch, index) => {
                    if (branch.tel1 && branch.tel2 && branch.tel3) {
                        return (
                            <p key={index} style={{ margin: 1 }}>
                                <Tag>
                                    {branch.tel1 +
                                        "-" +
                                        branch.tel2 +
                                        "-" +
                                        branch.tel3}
                                </Tag>
                            </p>
                        );
                    } else {
                        return <p key={index} style={{ margin: 1 }}></p>;
                    }
                });
            }
            return <></>;
        },
    },
    {
        title: "取引先支店FAX",
        dataIndex: "branch_fax1",
        key: "branch_fax1",
        width: 140,
        render: (branchFax1, record, column_index) => {
            const branches = record.branches;
            if (branches && branches.length) {
                return branches.map((branch, index) => {
                    if (branch.fax1 && branch.fax2 && branch.fax3) {
                        return (
                            <p key={index} style={{ margin: 1 }}>
                                <Tag>
                                    {branch.fax1 +
                                        "-" +
                                        branch.fax2 +
                                        "-" +
                                        branch.fax3}
                                </Tag>
                            </p>
                        );
                    } else {
                        return <p key={index} style={{ margin: 1 }}></p>;
                    }
                });
            }
            return <></>;
        },
    },
    {
        title: "取引に必要な設立年数",
        dataIndex: "establishment_year",
        key: "establishment_year",
        sorter: true,
        width: 180,
        render: (establishment_year, record) => {
            if (record.establishment_year) {
                return record.establishment_year + "年";
            } else {
                return "";
            }
        },
    },
    {
        title: "取引に必要な資本金",
        dataIndex: "capital_man_yen_required_for_transactions",
        key: "capital_man_yen_required_for_transactions",
        sorter: true,
        width: 180,
        render: (value) => {
            if (!value && value !== 0) {
                return null;
            }

            return (
                <p style={{ textAlign: "right" }}>
                    {value === 0 ? value : formatMoneyNumber(value)} 万円
                </p>
            );
        },
        align: "left",
    },
    {
        title: "取引に必要な資格",
        dataIndex: "p_mark_or_isms",
        key: "p_mark_or_isms",
        width: 140,
        render: (p_mark_or_isms, record) => {
            if (
                record["p_mark_or_isms"] &&
                record["invoice_system"] &&
                record["haken"]
            ) {
                return (
                    <span>
                        <Tooltip title="Pマーク／ISMS">
                            <SafetyCertificateTwoTone
                                twoToneColor={iconPrimaryColor}
                                data-testid="p_mark_or_isms"
                            />
                        </Tooltip>
                        &nbsp;
                        <Tooltip title="インボイス登録事業者">
                            <AccountBookTwoTone
                                twoToneColor={iconPrimaryColor}
                                data-testid="invoice_system"
                            />
                        </Tooltip>
                        &nbsp;
                        <Tooltip title="労働者派遣事業">
                            <IdcardTwoTone
                                twoToneColor={iconPrimaryColor}
                                data-testid="haken"
                            />
                        </Tooltip>
                    </span>
                );
            } else if (
                record["p_mark_or_isms"] &&
                record["invoice_system"] &&
                !record["haken"]
            ) {
                return (
                    <span>
                        <Tooltip title="Pマーク／ISMS">
                            <SafetyCertificateTwoTone
                                twoToneColor={iconPrimaryColor}
                            />
                        </Tooltip>
                        &nbsp;
                        <Tooltip title="インボイス登録事業者">
                            <AccountBookTwoTone
                                twoToneColor={iconPrimaryColor}
                            />
                        </Tooltip>
                    </span>
                );
            } else if (
                record["p_mark_or_isms"] &&
                record["haken"] &&
                !record["invoice_system"]
            ) {
                return (
                    <span>
                        <Tooltip title="Pマーク／ISMS">
                            <SafetyCertificateTwoTone
                                twoToneColor={iconPrimaryColor}
                            />
                        </Tooltip>
                        &nbsp;
                        <Tooltip title="労働者派遣事業">
                            <IdcardTwoTone twoToneColor={iconPrimaryColor} />
                        </Tooltip>
                    </span>
                );
            } else if (
                record["invoice_system"] &&
                record["haken"] &&
                !record["p_mark_or_isms"]
            ) {
                return (
                    <span>
                        <Tooltip title="インボイス登録事業者">
                            <AccountBookTwoTone
                                twoToneColor={iconPrimaryColor}
                            />
                        </Tooltip>
                        &nbsp;
                        <Tooltip title="労働者派遣事業">
                            <IdcardTwoTone twoToneColor={iconPrimaryColor} />
                        </Tooltip>
                    </span>
                );
            } else if (
                record["p_mark_or_isms"] &&
                !(record["invoice_system"] && record["haken"])
            ) {
                return (
                    <span>
                        <Tooltip title="Pマーク／ISMS">
                            <SafetyCertificateTwoTone
                                twoToneColor={iconPrimaryColor}
                            />
                        </Tooltip>
                    </span>
                );
            } else if (
                record["invoice_system"] &&
                !(record["p_mark_or_isms"] && record["haken"])
            ) {
                return (
                    <span>
                        <Tooltip title="インボイス登録事業者">
                            <AccountBookTwoTone
                                twoToneColor={iconPrimaryColor}
                            />
                        </Tooltip>
                    </span>
                );
            } else if (
                record["haken"] &&
                !(record["p_mark_or_isms"] && record["invoice_system"])
            ) {
                return (
                    <span>
                        <Tooltip title="労働者派遣事業">
                            <IdcardTwoTone twoToneColor={iconPrimaryColor} />
                        </Tooltip>
                    </span>
                );
            }
        },
    },
    {
        title: "作成日時",
        dataIndex: "created_time",
        key: "created_time",
        sorter: true,
        width: 160,
    },
    {
        title: "更新日時",
        dataIndex: "modified_time",
        key: "modified_time",
        sorter: true,
        width: 160,
    },
];

const onMinify = {
    renderTitle: (item) => item.name,
    renderDescription: (item) => {
        const categoryName = Tags[item.category].displayName;
        return (
            <div>
                <p>{`住所: ${item.address ? item.address : "未登録"}`}</p>
                <p>{`取引先ステータス: ${categoryName}`}</p>
                <p>{`資本金: ${
                    item.capital ? `${item.capital}万円` : "未登録"
                }`}</p>
            </div>
        );
    },
};

const organizationsTable = (props) => {
    const { loading, pageId } = props;
    const router = useRouter();

    const onRowClick = (record) => {};

    const onColumnClick = (record) => {
        const url = `${Path.organizations}/${record.id}`;
        router.push(url);
    };

    const isDisplaySettingLoading = useSelector(
        (state) => state.displaySettingPage.isDisplaySettingLoading
    );

    const { displaySetting } = useSelector((state) => state.displaySettingPage);

    return (
        <>
            {isDisplaySettingLoading ? (
                <Space>
                    <Skeleton.Input
                        style={{ height: 200, width: 1200 }}
                        active
                        size="large"
                    />
                </Space>
            ) : (
                <GenericTable
                    {...props}
                    columns={columns}
                    onMinify={onMinify}
                    onRowClick={onRowClick}
                    onColumnClick={onColumnClick}
                    loading={loading}
                    withSelection={true}
                    pageSize={displaySetting?.organizations?.page_size ?? 10}
                />
            )}
        </>
    );
};

organizationsTable.propTypes = {
    currentPage: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired,
    sortKey: PropTypes.string,
    sortOrder: PropTypes.string,
    onPageChange: PropTypes.func.isRequired,
    onPageSizeChange: PropTypes.func.isRequired,
    onTableChange: PropTypes.func.isRequired,
    onCheckColumn: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    data: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            corporate_number: PropTypes.number,
            name: PropTypes.string,
            address: PropTypes.string,
            settlement_month: PropTypes.number,
            domain_name: PropTypes.string,
            capital_man_yen: PropTypes.number,
            category: PropTypes.string,
            score: PropTypes.number,
            created_time: PropTypes.string,
            modified_time: PropTypes.string,
            comments: PropTypes.arrayOf(
                PropTypes.shape({
                    created_user__name: PropTypes.string,
                    created_user__avatar: PropTypes.string,
                    created_time: PropTypes.string,
                    content: PropTypes.string,
                })
            ),
        })
    ),
};

organizationsTable.defaultProps = {
    loading: false,
    data: [],
    sortKey: undefined,
    sortOrder: undefined,
};

export default organizationsTable;
