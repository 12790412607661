import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import Moment from "moment";
import moment from "moment";
import format from "string-format";
import {
    Alert,
    Button,
    Card,
    Col,
    Form,
    message,
    Modal,
    PageHeader,
    Row,
    Spin,
    Steps,
    Tooltip,
    Typography,
} from "antd";
import {
    clearAction,
    copyAction,
    createAction,
    createApi,
    createScheduleTemplateAction,
    deleteAction,
    deleteApi,
    detachAction,
    fetchAction,
    fetchApi,
    fetchReservedDateAction,
    patchAction,
    resetData,
    resetError,
    searchAllAction,
    syncSelections,
    updateApi,
} from "~/actions/data";
import {
    changeTemplate,
    finishChangingTemplate,
    syncToPageState,
} from "~/actions/form";
import {
    SCHEDULED_TEMPLATE_LOADED,
    SCHEDULED_TEMPLATE_LOADING,
    SEARCH_TEMPLATE_COMMITTED,
    SEARCH_TEMPLATE_COMMITTED_FINISH,
    SEARCH_TEMPLATE_CREATED,
    SEARCH_TEMPLATE_DELETED,
    SEARCH_TEMPLATE_DELETING,
    SEARCH_TEMPLATE_LOADED,
    SEARCH_TEMPLATE_LOADING,
    SEARCH_TEMPLATE_UPDATED,
    SEARCH_TEMPLATE_UPDATING,
} from "~/actions/actionTypes";
import {
    customErrorMessage,
    customSuccessMessage,
} from "~/components/Common/AlertMessage/AlertMessage";
import { createModal } from "../Feedbacks/Modal/Modal";
import {
    SCHEDULED_MAIL_EDIT_PAGE,
    SCHEDULED_MAIL_EDIT_PAGE_CONTACT_PICKER_FORM,
    SCHEDULED_MAIL_PREVIEW_CONTAINER,
    SCHEDULED_MAIL_REGISTER_PAGE,
    SCHEDULED_MAIL_REGISTER_PAGE_CONTACT_PICKER_FORM,
} from "./pageIds";
import Paths from "../Routes/Paths";
import { downloadFile, Endpoint } from "~/domain/api";
import {
    contactProfileSearchFormToAPI,
    convertContactPreferenceSummaryResponseDataEntry,
    convertScheduledEmailCardResponseDataEntry,
    convertScheduledEmailPreviewResponseDataEntry,
    convertScheduledEmailResponseDataEntry,
    convertSearchTemplateResponseDataEntry,
} from "~/domain/data";
import { convertScheduledMailTemplateResponseModelToScheduledMailTemplateModel } from "~/hooks/useScheduledEmail";
import ScheduledEmailForm from "../Forms/ScheduledEmailForm";
import EmailHead from "./EmailComponents/EmailHead";
import EmailBody from "./EmailComponents/EmailBody";
import ErrorScreen from "../Screens/ErrorScreen";
import Uploader from "../Uploaders/Uploader";
import {
    DeleteOutlined,
    InfoCircleTwoTone,
    PlusOutlined,
    QuestionCircleFilled,
} from "@ant-design/icons";
import BackButton from "../Common/BackButton/BackButton";
import {
    DEFAULTS_STEP_FROM_ATTACHMENTS,
    ErrorMessages,
    iconCustomColor,
    infoColor,
    Links,
    pickerLocaleConfig,
    SuccessMessages,
    TooltipMessages,
} from "~/utils/constants";
import _ from "lodash";
import AboutThisData from "~/components/Common/AboutThisData/AboutThisData";
import CustomDatePicker from "../Common/CustomDatePicker/CustomDatePicker";
import CustomTimePicker from "../Common/CustomTimePicker/CustomTimePicker";
import NotFoundPage from "./NotFoundPage";
import { AlertTooltipContentLink } from "../Common/TooltipContentLink/TooltipContentLink";
import LimitDivider from "../Common/LimitDivider/LimitDivider";
import { confirmModal } from "../Modals/ConfirmModal";
import queryString from "query-string";
import { useFetchScheduledEmailSettingAPIQuery } from "~/hooks/scheduledEmailSetting";
import SpinContainer from "../Common/SpinContainer";
import ScheduledEmailSenderSelectStep from "./ScheduledEmailPage/components/ScheduledEmailSenderSelectStep";
import { usePlans } from "~/hooks/usePlan";
import styles from "./scheduledEmailPage.scss";
import { apiErrorMessage } from "../../utils/utils";
import { convertHyperlink } from "../DataDisplay/CommentList/CommentList";

const { Step } = Steps;
const { Paragraph, Link } = Typography;

const redirectPathAfterCreate = Paths.scheduledMails;
const scheduleTemplateURL = `${Endpoint.getBaseUrl()}/${
    Endpoint.scheduledEmails
}/template`;
const resourceURL = `${Endpoint.getBaseUrl()}/${Endpoint.scheduledEmails}`;
const resourcePersonnelURL = `${Endpoint.getBaseUrl()}/${
    Endpoint.personnelBoard
}/{id}/templates/snake`;
const resourceProjectURL = `${Endpoint.getBaseUrl()}/${
    Endpoint.projectBoard
}/{id}/templates/snake`;
const previewURL = `${Endpoint.getBaseUrl()}/${Endpoint.scheduledEmails}/{id}/${
    Endpoint.previewActionSuffix
}`;
const contactResourceURL = `${Endpoint.getBaseUrl()}/${
    Endpoint.contactEmailPreferences
}`;
const reservedDateResourceURL = `${Endpoint.getBaseUrl()}/${
    Endpoint.reservedDate
}`;
const attachmentUploadURL = (resourceId) =>
    `${Endpoint.getBaseUrl()}/${Endpoint.scheduledEmails}/${resourceId}/${
        Endpoint.scheduledEmailAttachmentsSuffix
    }`;
const attachmentEditURL = (resourceId) =>
    `${Endpoint.getBaseUrl()}/${
        Endpoint.scheduledEmailAttachments
    }/${resourceId}`;

const searchTemplateURL = `${Endpoint.getBaseUrl()}/${
    Endpoint.contactMailPreferenceSearchTemplate
}`;

const convertFormDataToAPI = (data) => ({
    ...data,
    exclude_organizations: data.exclude_organizations.filter((org) => org),
});

const resourceName = "配信メール";

const commitModal = createModal(resourceName, "予約登録");
const deleteModal = createModal(resourceName, "削除");

const stepTabMapping = {
    0: "base",
    1: "attachment",
    2: "contact",
    3: "preview",
};

const maxStepIndex = Object.keys(stepTabMapping).length - 1;

const step4Messages = [
    <span>
        所属取引先と取引先担当者名は自動挿入されます。自動挿入の該当箇所には黄色くハイライトがされています。
    </span>,
    <span>
        <AlertTooltipContentLink
            to={`${Paths.contacts}/register`}
            target="_blank"
            rel="noopener noreferrer"
            title="取引先担当者登録／編集"
        />
        にて登録をしたCC宛にも配信がされます。
    </span>,
];

const renderStep4AlertDescription = () => {
    return (
        <div>
            <Paragraph>
                <ul
                    style={{
                        listStyleType: "disc",
                        textAlign: "left",
                    }}
                >
                    {step4Messages.map((message, i) => (
                        <li
                            key={i}
                            style={{
                                marginBottom: "5px",
                            }}
                        >
                            {message}
                        </li>
                    ))}
                </ul>
            </Paragraph>
        </div>
    );
};

const ScheduledEmailPageCreator = (
    pageId,
    contactPickerFormId,
    pageReducerName,
    templateReducerName,
    contactPickerFormReducerName,
    pageTitle,
    isEdit,
    defaultStep = 0,
    planId
) => {
    class ScheduledEmailPage extends Component {
        constructor(props) {
            super(props);
            this.state = {
                referencingExistingObject: Boolean(props.match.params.id),
                currentStep: defaultStep,
                prevStep: undefined,
                activeTabKey: "base",
                additionalCount: 0,
                dateToSend: undefined,
                additionalDateToSend1: undefined,
                additionalDateToSend2: undefined,
                additionalDateToSend3: undefined,
                additionalDateToSend4: undefined,
                dateRegisterVisible: false,
                selectedDate: undefined,
                contactEmailPreferenceSearchForm: undefined,
                selectedMoment: undefined,
                showSelectedRowOnly: false,
                disabledSubmit: true,
                isProgressBar: false,
                personnelId: null,
                projectId: null,
                promotionId: undefined,
            };
            this.initialPickListDelivered = false;
            this.initialPickListData = false;
            this.hasSetSelectedMoment = false;
        }

        scheduledEmailForm = React.createRef();
        scheduledEmailTemplate = React.createRef();

        componentDidMount() {
            const stepLocalStorage = localStorage.getItem(
                DEFAULTS_STEP_FROM_ATTACHMENTS
            );
            if (stepLocalStorage) {
                this.setState({
                    currentStep: parseInt(stepLocalStorage),
                });
            }
            const search = this.props.location.search;
            const tabScheduledMailsCurrent = new URLSearchParams(search).get(
                "step;"
            );
            if (!!tabScheduledMailsCurrent) {
                this.setState({
                    currentStep: parseInt(tabScheduledMailsCurrent),
                });
            }
            const {
                match: {
                    params: { id: resourceId },
                },
            } = this.props;
            if (resourceId) {
                // for edit page, TODO: consider using mixin.
                this.fetchScheduledEmailData();
                this.setState({ disabledSubmit: false });
            }
            const cardPersonnelParam = queryString.parse(
                this.props.location.search
            );
            if (cardPersonnelParam && cardPersonnelParam.personnelId) {
                this.fetchScheduledEmailPersonnel(
                    cardPersonnelParam.personnelId
                );
                this.setState({
                    disabledSubmit: false,
                    personnelId: cardPersonnelParam.personnelId,
                });
            }
            if (cardPersonnelParam && cardPersonnelParam.projectId) {
                this.fetchScheduledEmailProject(cardPersonnelParam.projectId);
                this.setState({
                    disabledSubmit: false,
                    projectId: cardPersonnelParam.projectId,
                });
            }

            this.fetchReservedDateData();
            this.fetchSearchTemplateData();
            this.fetchScheduledMailTemplateData();
        }

        componentDidUpdate(prevProps) {
            const {
                pageState,
                previewState,
                contactPickerState,
                dispatch,
                history,
            } = this.props;
            const prevPageState = prevProps.pageState;
            const {
                committed,
                deleted,
                message: successMessage,
                errorMessage,
                data,
                errorExtraInfo,
            } = pageState;

            const {
                requireRefresh,
                requireRefreshTemplate,
                message: searchTemplateSuccessMessage,
            } = contactPickerState;
            const {
                loading: loadingPreview,
                errorMessage: previewErrorMessage,
            } = previewState;
            const {
                data: prevData,
                message: prevMessage,
                errorMessage: prevErrorMessage,
            } = prevPageState;
            const { selectedMoment, currentStep, prevStep } = this.state;

            if (searchTemplateSuccessMessage) {
                customSuccessMessage(searchTemplateSuccessMessage);
            }
            if (successMessage && successMessage !== prevMessage) {
                customSuccessMessage(successMessage);
            }
            if (errorMessage && errorMessage !== prevErrorMessage) {
                customErrorMessage(errorMessage);
                if (currentStep === 3 && prevStep !== 3) {
                    this.setState({
                        currentStep: prevStep,
                        prevStep: undefined,
                    });
                }
            }
            if ((committed || deleted) && redirectPathAfterCreate) {
                this.moveToNextPage();
            }
            if (errorExtraInfo) {
                if (errorExtraInfo.message) {
                    customErrorMessage(errorExtraInfo.message);
                }
                if (errorExtraInfo.redirect) {
                    history.push(errorExtraInfo.redirect);
                }
                dispatch(resetError(pageId));
            }

            // Set the contact list as a selected row in the table, only at the first time.
            if (!this.initialPickListDelivered && data.target_contacts) {
                this.initialPickListData = true;
                this.fetchTargetCandidateList();
                dispatch(
                    syncSelections(contactPickerFormId, data.target_contacts)
                );
                this.initialPickListDelivered = true;
            }
            // Update Preview If needed.
            // Check error status before fetch, or it causes an infinite loop on error.
            if (
                data.id &&
                !loadingPreview &&
                !previewErrorMessage &&
                data.modified_time_ts !== prevData.modified_time_ts
            )
                this.fetchPreview();
            if (requireRefreshTemplate) {
                this.fetchSearchTemplateData();
            }

            if (requireRefresh) {
                dispatch(finishChangingTemplate(contactPickerFormId));
            }

            if (!this.hasSetSelectedMoment && data && data.date_to_send) {
                const defaultValue = data.date_to_send;
                if (
                    defaultValue &&
                    selectedMoment &&
                    selectedMoment
                        .set("second", 0)
                        .set("millisecond", 0)
                        .isSame(
                            defaultValue.set("second", 0).set("millisecond", 0)
                        )
                ) {
                    this.setState({
                        selectedMoment: defaultValue,
                    });
                }
                this.hasSetSelectedMoment = true;
            }

            if (data.promotion_id !== prevData.promotion_id) {
                this.setState({
                    promotionId: data.promotion_id,
                });
            }
        }

        componentWillUnmount() {
            this.clearPageState();
            localStorage.setItem(DEFAULTS_STEP_FROM_ATTACHMENTS, "0");
        }

        setContactEmailPreferenceSearchForm = (form) => {
            this.setState({ contactEmailPreferenceSearchForm: form });
        };

        resetFormHandler = (initialSearchConditions = {}) => {
            const { dispatch } = this.props;
            this.setState({ selectedTemplateName: "選択してください" });
            dispatch(
                clearSearchForm(contactPickerFormId, initialSearchConditions)
            );
        };

        fetchSearchTemplateData() {
            const { token, dispatch } = this.props;
            dispatch(
                fetchApi(
                    contactPickerFormId,
                    token,
                    searchTemplateURL,
                    null,
                    (status = {
                        before: SEARCH_TEMPLATE_LOADING,
                        after: SEARCH_TEMPLATE_LOADED,
                    }),
                    convertSearchTemplateResponseDataEntry
                )
            );
        }

        fetchScheduledMailTemplateData() {
            const { token, dispatch } = this.props;
            dispatch(
                fetchApi(
                    pageId,
                    token,
                    scheduleTemplateURL,
                    null,
                    (status = {
                        before: SCHEDULED_TEMPLATE_LOADING,
                        after: SCHEDULED_TEMPLATE_LOADED,
                    }),
                    convertScheduledMailTemplateResponseModelToScheduledMailTemplateModel
                )
            );
        }

        getFormValues = () => this.scheduledEmailForm.current.getFieldsValue();

        isFieldError = (fieldName) => {
            return (
                this.scheduledEmailForm?.current?.getFieldError(fieldName)
                    .length > 0
            );
        };

        getCombinedFormValues = () => {
            const { contactPickerState, pageState } = this.props;
            const { data } = pageState;
            const { currentSearchConditions } = contactPickerState;
            const {
                additionalCount,
                dateToSend,
                additionalDateToSend1,
                additionalDateToSend2,
                additionalDateToSend3,
                additionalDateToSend4,
            } = this.state;
            return Object.assign({}, this.getFormValues(), {
                target_contacts: this.getExistsKeys(),
                send_type: currentSearchConditions["searchtype"],
                search_conditions: currentSearchConditions,
                date_to_send: dateToSend
                    ? dateToSend
                    : data && data.date_to_send
                    ? data.date_to_send
                    : undefined,
                additional_date_to_send1:
                    additionalCount > 0 ? additionalDateToSend1 : undefined,
                additional_date_to_send2:
                    additionalCount > 1 ? additionalDateToSend2 : undefined,
                additional_date_to_send3:
                    additionalCount > 2 ? additionalDateToSend3 : undefined,
                additional_date_to_send4:
                    additionalCount > 3 ? additionalDateToSend4 : undefined,
            });
        };

        moveToNextPage = () => {
            const { history } = this.props;
            history.push(redirectPathAfterCreate);
        };

        onContactSearch = (formValues) => {
            // A Handler for Ant Design Forms.
            const { token, dispatch } = this.props;
            const cardPersonnelParam = queryString.parse(
                this.props.location.search
            );
            const searchConditions = { ...formValues };
            if (!!this.scheduledEmailForm.current) {
                searchConditions.exclude_organizations =
                    this.scheduledEmailForm.current.getFieldValue(
                        "exclude_organizations"
                    );
            }
            if (cardPersonnelParam && cardPersonnelParam.organizationId) {
                searchConditions.exclude_organizations = [
                    ...searchConditions.exclude_organizations,
                    cardPersonnelParam.organizationId,
                ];
            }
            dispatch(
                searchAllAction(
                    contactPickerFormId,
                    token,
                    contactResourceURL,
                    1,
                    undefined,
                    searchConditions,
                    contactProfileSearchFormToAPI,
                    convertContactPreferenceSummaryResponseDataEntry
                )
            );
            // currentPage must be 1 because the search result could be under pageSize.
            this.initialPickListData = false;
        };

        resetSearchAllValue = () => {
            const { dispatch } = this.props;
            dispatch(resetData(contactPickerFormId));
            this.changeSearchTemplate(null);
        };

        onSelectChange = (selectedRowKeys) => {
            const { dispatch } = this.props;
            dispatch(syncSelections(contactPickerFormId, selectedRowKeys));
        };

        onSelectAll = () => {
            const { dispatch, contactPickerState } = this.props;
            const { data } = contactPickerState;
            const newSelectedRowKeys = data.map((entry) => entry.key);
            dispatch(syncSelections(contactPickerFormId, newSelectedRowKeys));
        };

        onDeselectAll = () => {
            const { dispatch } = this.props;
            dispatch(syncSelections(contactPickerFormId, []));
        };

        submitHandler = (values) => {
            // Get form state from each form and merge into page state.
            const { dispatch } = this.props;
            dispatch(syncToPageState(pageId, values));
        };

        handleFormChange = () => {
            // These are the required fields
            const subjectValue =
                this.scheduledEmailForm.current.getFieldsValue().subject;
            const textValue =
                this.scheduledEmailForm.current.getFieldsValue().text;
            const errorFields =
                this.scheduledEmailForm.current &&
                !!this.scheduledEmailForm.current
                    .getFieldsError()
                    .filter(({ errors }) => errors.length).length;
            this.setState({
                disabledSubmit: errorFields || !subjectValue || !textValue,
            });
        };

        createTemplate = (values) => {
            const { token, dispatch, templateState } = this.props;
            const { dataTemplate } = templateState;
            var created_templates = [];
            if (dataTemplate && dataTemplate.templates) {
                dataTemplate.templates.map((template, index) => {
                    created_templates.push(template);
                });
            }
            created_templates.push(values);
            dispatch(
                createScheduleTemplateAction(
                    pageId,
                    token,
                    scheduleTemplateURL,
                    { templates: created_templates },
                    "create"
                )
            );
        };

        updateTemplate = (values, selectedIndex) => {
            const { token, dispatch, templateState } = this.props;
            const { dataTemplate } = templateState;
            var updated_templates = [];
            dataTemplate?.templates?.map((template, index) => {
                if (index === selectedIndex) {
                    updated_templates.push(values);
                } else {
                    updated_templates.push(template);
                }
            });
            dispatch(
                createScheduleTemplateAction(
                    pageId,
                    token,
                    scheduleTemplateURL,
                    { templates: updated_templates },
                    "update"
                )
            );
        };

        deleteTemplate = (selectedIndex) => {
            const { token, dispatch, templateState } = this.props;
            const { dataTemplate } = templateState;
            var not_deleted_templates = [];
            dataTemplate?.templates?.map((template, index) => {
                if (index !== selectedIndex) {
                    not_deleted_templates.push(template);
                }
            });
            dispatch(
                createScheduleTemplateAction(
                    pageId,
                    token,
                    scheduleTemplateURL,
                    { templates: not_deleted_templates },
                    "delete"
                )
            );
        };

        /**
         * A delete handler which will be called when we click remove button of AntDesign's Uploader Component.
         * @param file {Object} - A file information trying to delete. Details are following.
         * {
         *  uid: 'uid',      // unique identifier, negative is recommend, to prevent interference with internal generated id
         *  name: 'xx.png'   // file name
         *  status: 'done', // options：uploading, done, error, removed
         *  response: '{"status": "success"}', // response from server. become undefined if we set file info as defaultFileList manually.
         *  linkProps: '{"download": "image"}', // additional html props of file link
         * }
         * @returns {*|Promise<T|boolean>}
         */
        onAttachmentRemove = (file) => {
            const { token } = this.props;
            const resourceId = file.response ? file.response.id : file.uid; //
            return detachAction(token, attachmentEditURL(resourceId), message);
        };

        /**
         * A file download handler which will be called when we click Uploaded file name.
         * @param file {Object} - A file information trying to download. Details are following.
         * {
         *  uid: 'uid',      // unique identifier, negative is recommend, to prevent interference with internal generated id
         *  name: 'xx.png'   // file name
         *  status: 'done', // options：uploading, done, error, removed
         *  response: '{"status": "success"}', // response from server. become undefined if we set file info as defaultFileList manually.
         *  linkProps: '{"download": "image"}', // additional html props of file link
         * }
         * @returns {*|Promise<T|boolean>}
         */
        onAttachmentPreview = (file) => {
            const { token } = this.props;
            const resourceId = file.response ? file.response.id : file.uid;
            downloadFile(token, attachmentEditURL(resourceId), file.name);
        };

        resetHandler = () => {
            const { token, dispatch, pageState } = this.props;
            const { data } = pageState;
            const { id: resourceId } = data;
            if (resourceId) {
                dispatch(deleteAction(pageId, token, resourceURL, resourceId));
            } else {
                this.clearPageState();
                this.moveToNextPage();
            }
        };

        // NOTE(joshua-hashimoto): ステップの制御を担当する
        validateStep = async (nextStep) => {
            const { currentStep } = this.state;
            // NOTE(joshua-hashimoto): 条件 -> Step1の必須入力欄が埋まっていること
            if (currentStep === 0) {
                try {
                    await this.scheduledEmailForm.current.validateFields();
                } catch (err) {
                    customErrorMessage(
                        "入力欄に問題があるようです。確認をお願いいたします。"
                    );
                    return false;
                }
            }
            // NOTE(joshua-hashimoto): 条件 -> Step3で宛先に最低でも1つ選択があること
            if (nextStep === 3) {
                const existsKeys = this.getExistsKeys();
                const isContactSelected = !!existsKeys.length;
                if (!isContactSelected) {
                    customErrorMessage(
                        "宛先が選択されていません。必ず宛先を選択してください。"
                    );
                    return false;
                }
            }
            return true;
        };

        onStepClick = async (current) => {
            const { currentStep } = this.state;
            const isStepMovable = await this.validateStep(current);
            if (!isStepMovable) {
                return;
            }
            this.onTabChange(stepTabMapping[current]);
            this.setState({
                currentStep: current,
                prevStep: current === 3 ? currentStep : undefined,
            });
        };
        onNextStep = async () => {
            const { currentStep } = this.state;
            const nextStep = Math.min(currentStep + 1, maxStepIndex);
            const isStepMovable = await this.validateStep(nextStep);
            if (!isStepMovable) {
                return;
            }
            this.onTabChange(stepTabMapping[nextStep]);
            this.setState({
                currentStep: nextStep,
                prevStep: nextStep === 3 ? currentStep : undefined,
            });
        };

        onPrevStep = () => {
            const { currentStep } = this.state;
            const prevStep = Math.max(currentStep - 1, 0);
            if (prevStep === 0) {
                this.fetchScheduledMailTemplateData();
            }
            this.onTabChange(stepTabMapping[prevStep]);
            this.setState({ currentStep: prevStep });
        };

        onTabChange = (activeKey) => {
            const { pageState, authorizedActions, contactPickerState } =
                this.props;
            const { created, data } = pageState;
            const { id: resourceId, status, search_condition } = data;
            const { currentSearchConditions } = contactPickerState;

            const searchCondition = {
                ...search_condition,
                ...currentSearchConditions,
            };

            let createAuthorized =
                !!authorizedActions?.["scheduled_mails"]?.["create"];

            let updateAuthorized =
                authorizedActions?.["scheduled_mails"]?.["update"];

            if (createAuthorized || (resourceId && updateAuthorized)) {
                const isCreating =
                    (activeKey === "attachment" ||
                        activeKey === "contact" ||
                        activeKey === "preview") &&
                    !created &&
                    !resourceId;
                // We need to create a scheduled email object before attachment upload or viewing preview.
                if (isCreating) {
                    this.saveDraftToServer(searchCondition);
                }

                const isUpdatingAndInLastTab =
                    status === "draft" && resourceId && activeKey === "preview";
                // If draft mode, switching to preview tab cause save.
                // Note: Do not auto-save if you editing/viewing queued email because it cause unintentional status change.
                if (isUpdatingAndInLastTab) {
                    this.saveDraftToServer(searchCondition);
                }

                const isUpdatingQueuedAndInLastTab =
                    status === "queued" &&
                    resourceId &&
                    activeKey === "preview";

                if (isUpdatingQueuedAndInLastTab) {
                    this.saveQueueToServer();
                }
            }

            this.setState({ activeTabKey: activeKey });
        };

        saveDraftToServer = (search_condition) => {
            const data = Object.assign({}, this.getCombinedFormValues(), {
                status: "draft",
                personnel_id: this.state.personnelId,
                project_id: this.state.projectId,
                promotion_id: this.state.promotionId,
                search_conditions: {
                    ...search_condition,
                    searchtype: this.state.personnelId
                        ? "personnel"
                        : this.state.projectId
                        ? "job"
                        : search_condition.searchtype,
                },
            });
            this.save(data);
        };

        saveQueueToServer = () => {
            const data = Object.assign({}, this.getCombinedFormValues(), {
                status: "queued",
            });
            this.save(data);
        };

        commitDraftToServer = () => {
            confirmModal({
                title: "この配信メールを「下書き」ステータスに変更しますか？",
                content: "OKを押すと、更新が実行されます。",
                onOk: () => {
                    const data = {
                        ...this.getCombinedFormValues(),
                        status: "draft",
                    };
                    this.commit(data);
                },
            });
        };

        fetchPreview = () => {
            const { dispatch, token, pageState } = this.props;
            const { data } = pageState;
            const { id: resourceId } = data;
            const url = format(previewURL, { id: resourceId });
            dispatch(
                fetchAction(
                    SCHEDULED_MAIL_PREVIEW_CONTAINER,
                    token,
                    url,
                    null,
                    convertScheduledEmailPreviewResponseDataEntry
                )
            );
        };

        commitItemToServer = () => {
            this.scheduledEmailForm.current
                .validateFields()
                .then(() => {
                    const data = Object.assign(
                        {},
                        this.getCombinedFormValues(),
                        { status: "queued", reserve: true }
                    );
                    this.commit(data);
                })
                .catch(() => {
                    customErrorMessage(
                        "必須項目の入力に不備があります。お手数ですが再度ご確認ください。"
                    );
                });
        };

        createCopyOnServer = () => {
            const { token, dispatch, pageState } = this.props;
            const { data } = pageState; // resource Id is not exist in forms.
            const { id: resourceId } = data;
            dispatch(
                copyAction(
                    pageId,
                    token,
                    resourceURL,
                    resourceId,
                    convertScheduledEmailResponseDataEntry,
                    true,
                    { message: SuccessMessages.generic.copy },
                    {
                        message: ErrorMessages.scheduledEmail.copyFailed,
                        redirect: Paths.scheduledMails,
                    }
                )
            );
        };

        clearPageState() {
            const { dispatch } = this.props;
            this.initialPickListDelivered = false;
            dispatch(clearAction(pageId)); // Make sure to clear the state.
            dispatch(clearAction(contactPickerFormId));
            dispatch(clearAction(SCHEDULED_MAIL_PREVIEW_CONTAINER));
        }

        fetchScheduledEmailPersonnel(cardId) {
            const { token, dispatch } = this.props;
            const url = format(resourcePersonnelURL, { id: cardId });
            dispatch(
                fetchAction(
                    pageId,
                    token,
                    url,
                    null,
                    convertScheduledEmailCardResponseDataEntry
                )
            );
        }

        fetchScheduledEmailProject(cardId) {
            const { token, dispatch } = this.props;
            const url = format(resourceProjectURL, { id: cardId });
            dispatch(
                fetchAction(
                    pageId,
                    token,
                    url,
                    null,
                    convertScheduledEmailCardResponseDataEntry
                )
            );
        }

        fetchScheduledEmailData() {
            const {
                match: {
                    params: { id: resourceId },
                },
            } = this.props;
            const { token, dispatch } = this.props;
            dispatch(
                fetchAction(
                    pageId,
                    token,
                    resourceURL,
                    resourceId,
                    convertScheduledEmailResponseDataEntry
                )
            );
        }

        fetchReservedDateData() {
            const { token, dispatch } = this.props;
            dispatch(
                fetchReservedDateAction(pageId, token, reservedDateResourceURL)
            );
        }

        fetchTargetCandidateList() {
            /* Fetch table data to avoid misunderstanding that no contact specified when the table is empty. */
            const { token, pageState, dispatch } = this.props;
            const { data } = pageState;
            // 検索条件が保存されている場合のみ、検索を実行する(検索パラメータなしの状態で検索は実行しない)
            if (
                data?.search_condition?.["searchtype"]
                // TODO(morihisa-sagawa): search_conditionの必須チェックNGであれば、空のdataでSEARCH_COMPLETEDを更新するように修正する必要あり
                // 詳細はhttps://trello.com/c/wnGvGN5Zチケットの鈴木さんコメント参照
            ) {
                dispatch(
                    searchAllAction(
                        contactPickerFormId,
                        token,
                        contactResourceURL,
                        1,
                        undefined,
                        data.search_condition,
                        contactProfileSearchFormToAPI,
                        convertContactPreferenceSummaryResponseDataEntry
                    )
                );
            }
        }

        setTimeZeroSeconds(formData) {
            let date_column_names = [
                "date_to_send",
                "additional_date_to_send1",
                "additional_date_to_send2",
                "additional_date_to_send3",
                "additional_date_to_send4",
            ];
            date_column_names.forEach((column_name, index) => {
                if (formData[column_name]) {
                    formData[column_name] = formData[column_name].format(
                        "YYYY-MM-DD HH:mm:00.000Z"
                    );
                }
            });
            return formData;
        }

        save(formData) {
            const { token, dispatch, pageState } = this.props;
            const { data } = pageState; // resource Id is not exist in forms.
            const { id: resourceId } = data;
            if (resourceId) {
                // If the data has an id, this resource is already created on the remote server.
                dispatch(
                    patchAction(
                        pageId,
                        token,
                        resourceURL,
                        resourceId,
                        convertFormDataToAPI(this.setTimeZeroSeconds(formData)),
                        convertScheduledEmailResponseDataEntry,
                        false
                    )
                );
            } else {
                dispatch(
                    createAction(
                        pageId,
                        token,
                        resourceURL,
                        convertFormDataToAPI(this.setTimeZeroSeconds(formData)),
                        convertScheduledEmailResponseDataEntry,
                        false
                    )
                );
            }
        }

        commit(formData) {
            const { token, dispatch, pageState } = this.props;
            const { data } = pageState; // resource Id is not exist in forms.
            const { id: resourceId } = data;
            if (resourceId) {
                // If the data has an id, this resource is already created on the remote server.
                dispatch(
                    patchAction(
                        pageId,
                        token,
                        resourceURL,
                        resourceId,
                        convertFormDataToAPI(this.setTimeZeroSeconds(formData)),
                        convertScheduledEmailResponseDataEntry,
                        true,
                        () => {},
                        {
                            message:
                                ErrorMessages.scheduledEmail.scheduleFailed,
                            redirect: Paths.scheduledMails,
                        }
                    )
                );
            } else {
                dispatch(
                    createAction(
                        pageId,
                        token,
                        resourceURL,
                        convertFormDataToAPI(this.setTimeZeroSeconds(formData)),
                        convertScheduledEmailResponseDataEntry,
                        true,
                        {
                            message:
                                ErrorMessages.scheduledEmail.scheduleFailed,
                            redirect: Paths.scheduledMails,
                        }
                    )
                );
            }
        }

        getExistsKeys() {
            const { contactPickerState } = this.props;
            const { data: contactData, selectedRowKeys } = contactPickerState;
            let contactIds = contactData.map((contact) => contact.key);
            return selectedRowKeys.filter((key) => contactIds.includes(key));
        }

        add = () => {
            const { additionalCount } = this.state;
            this.setState({
                additionalCount: additionalCount + 1,
                selectedMoment: undefined,
            });
        };

        remove = (index) => {
            const { additionalCount } = this.state;
            this.setState({
                ["additionalDateToSend" + index]: undefined,
                additionalCount: additionalCount - 1,
            });
        };

        closeDateModal = (e) => {
            this.setState({ dateRegisterVisible: false });
        };

        disabledhours = () => {
            const { selectedMoment } = this.state;
            const fixedDisabledAmHours = [0, 1, 2, 3, 4, 5, 6, 7];
            const fixedDisabledPmHours = [19, 20, 21, 22, 23];
            let disabledHours = [
                ...fixedDisabledAmHours,
                ...fixedDisabledPmHours,
            ];
            if (selectedMoment) {
                const selectedYear = selectedMoment.year();
                const selectedMonth = selectedMoment.month();
                const selectedDate = selectedMoment.date();

                const momentObj = moment();
                const currentYear = momentObj.year();
                const currentMonth = momentObj.month();
                const currentDate = momentObj.date();

                const isSameYear = selectedYear === currentYear;
                const isSameMonth = selectedMonth === currentMonth;
                const isSameDate = selectedDate === currentDate;

                if (isSameYear && isSameMonth && isSameDate) {
                    const currentHour = momentObj.hours();
                    const disabledRange = _.range(7, currentHour);
                    disabledHours = [...disabledHours, ...disabledRange];
                }
            }
            return _.uniq(disabledHours);
        };

        disableddate = (current) => {
            const { pageState } = this.props;
            const { reservedDateData } = pageState;
            const {
                additionalDateToSend1,
                additionalDateToSend2,
                additionalDateToSend3,
                additionalDateToSend4,
                dateToSend,
            } = this.state;
            const allTimeInvalid = [
                ...reservedDateData.date_to_sends,
                dateToSend ? dateToSend.format("YYYY-MM-DD HH:mm") : "",
                additionalDateToSend1
                    ? additionalDateToSend1.format("YYYY-MM-DD HH:mm")
                    : "",
                additionalDateToSend2
                    ? additionalDateToSend2.format("YYYY-MM-DD HH:mm")
                    : "",
                additionalDateToSend3
                    ? additionalDateToSend3.format("YYYY-MM-DD HH:mm")
                    : "",
                additionalDateToSend4
                    ? additionalDateToSend4.format("YYYY-MM-DD HH:mm")
                    : "",
            ];
            const currentHour = moment().hours();
            const currentMinute = moment().minutes();
            const isTimeExpanded =
                reservedDateData.is_use_delivery_term_available;
            let isFullSchedules = false;
            if (
                current.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
            ) {
                const hasValidTimeFromNow = this.getTimesInDay(
                    isTimeExpanded
                ).filter((time) => {
                    if (
                        time.hour > currentHour ||
                        (time.hour === currentHour &&
                            time.minute > currentMinute)
                    ) {
                        const timeString = `${time.hour < 10 ? "0" : ""}${
                            time.hour
                        }:${time.minute < 10 ? "0" : ""}${time.minute}`;
                        return !allTimeInvalid.includes(
                            `${current.format("YYYY-MM-DD")} ${timeString}`
                        );
                    }
                    return false;
                });
                isFullSchedules = hasValidTimeFromNow.length === 0;
            } else {
                if (reservedDateData && isTimeExpanded) {
                    const scheduleInDays = allTimeInvalid.filter((date) =>
                        date.includes(current.format("YYYY-MM-DD"))
                    );
                    isFullSchedules = scheduleInDays.length === 66;
                } else if (reservedDateData && !isTimeExpanded) {
                    const scheduleInDays = allTimeInvalid.filter(
                        (datetime) =>
                            datetime.includes(current.format("YYYY-MM-DD")) &&
                            (datetime.includes(":00") ||
                                datetime.includes(":30"))
                    );
                    isFullSchedules = scheduleInDays.length === 22;
                }
            }
            return (
                current < moment().startOf("day") ||
                moment().add(1, "months") < current ||
                current.day() === 0 ||
                current.day() === 6 ||
                (reservedDateData &&
                    reservedDateData.holidays &&
                    reservedDateData.holidays.includes(
                        current.format("YYYY-MM-DD")
                    )) ||
                isFullSchedules ||
                (moment().format("YYYY-MM-DD") ===
                    current.format("YYYY-MM-DD") &&
                    (currentHour >= 19 ||
                        (currentHour === 18 &&
                            currentMinute > (isTimeExpanded ? 50 : 30))))
            );
        };

        deleteSearchTemplate = () => {
            const { token, dispatch, contactPickerState } = this.props;
            let selectedTemplateIndex =
                contactPickerState.currentSearchTemplates.findIndex(
                    (e) => e.name === this.state.selectedTemplateName
                );

            if (selectedTemplateIndex >= 0) {
                confirmModal({
                    title: "このテンプレートを削除しますか？",
                    content: (
                        <>
                            <p>OKを押すと、削除が実行されます。</p>
                            <p>元には戻せません。</p>
                        </>
                    ),
                    onOk: () => {
                        this.changeSearchTemplate(null);
                        dispatch(
                            deleteApi(
                                contactPickerFormId,
                                token,
                                searchTemplateURL,
                                selectedTemplateIndex,
                                {
                                    before: SEARCH_TEMPLATE_DELETING,
                                    after: SEARCH_TEMPLATE_DELETED,
                                    commit: SEARCH_TEMPLATE_COMMITTED,
                                    afterCommit:
                                        SEARCH_TEMPLATE_COMMITTED_FINISH,
                                }
                            )
                        );
                    },
                });
            }
        };

        updateSearchTemplate = () => {
            const { token, dispatch, contactPickerState } = this.props;
            let selectedTemplateIndex =
                contactPickerState.currentSearchTemplates.findIndex(
                    (e) => e.name === this.state.selectedTemplateName
                );
            if (selectedTemplateIndex >= 0) {
                dispatch(
                    updateApi(
                        contactPickerFormId,
                        token,
                        searchTemplateURL,
                        selectedTemplateIndex,
                        {},
                        {
                            before: SEARCH_TEMPLATE_UPDATING,
                            after: SEARCH_TEMPLATE_UPDATED,
                            commit: SEARCH_TEMPLATE_COMMITTED,
                            afterCommit: SEARCH_TEMPLATE_COMMITTED_FINISH,
                        }
                    )
                );
            }
        };

        createSearchTemplate = (inputTemplateName) => {
            const { token, dispatch, contactPickerState } = this.props;
            const { currentSearchTemplates } = contactPickerState;
            let nameExists = currentSearchTemplates.some(
                (entry) => entry.name === inputTemplateName
            );

            if (nameExists) {
                customErrorMessage(
                    `「${inputTemplateName}」と同一名称のテンプレートが既に存在します。別のテンプレート名を入力してください。`
                );
                return Promise.reject();
            } else {
                let formValues =
                    this.state.contactEmailPreferenceSearchForm.getFieldsValue();
                // sortKey, sortOrder, selectedColumnKeysはテンプレート情報として復元しない(使用しない)が、
                // 下記の理由で一旦パラメータとして残す
                //  - 今後使用する可能性があるため
                //  - 削除することによる影響範囲が大きい(サーバー側も修正要)
                //  - もし、今後使用することになった時に復元コストがかかる
                return new Promise((resolve, reject) =>
                    createApi(
                        contactPickerFormId,
                        token,
                        searchTemplateURL,
                        {
                            templateName: inputTemplateName,
                            formValues: formValues,
                            pageSize: 1,
                            sortKey: "",
                            sortOrder: "",
                            selectedColumnKeys: [],
                        },
                        {
                            after: SEARCH_TEMPLATE_CREATED,
                            commit: SEARCH_TEMPLATE_COMMITTED,
                            afterCommit: SEARCH_TEMPLATE_COMMITTED_FINISH,
                        }
                    )(dispatch)
                        .then(() => {
                            resolve();
                            this.setState({
                                selectedTemplateName: inputTemplateName,
                            });
                        })
                        .catch((err) => {
                            apiErrorMessage(
                                err,
                                ErrorMessages.searchTemplate.create
                            );
                            reject();
                        })
                );
            }
        };

        changeSearchTemplate = (value) => {
            const { dispatch, contactPickerState } = this.props;
            this.setState({ selectedTemplateName: value });
            this.state.contactEmailPreferenceSearchForm.resetFields();
            // NOTE: 上のresetFields()ではこだわりのデータがクリアされないので、setFieldsValueで明示的に空の値を挿入
            this.state.contactEmailPreferenceSearchForm.setFieldsValue({
                category_inequality: undefined,
                contact__category: undefined,
                contact__organization__category_approached: undefined,
                contact__organization__category_client: undefined,
                contact__organization__category_exchanged: undefined,
                contact__organization__category_prospective: undefined,
                contact__organization__contract: undefined,
                contact__organization__has_handring_personal_information:
                    undefined,
                contact__organization__has_nda: undefined,
                contact__organization__has_outsourcing_basic_contact: undefined,
                contact__organization__organization_country_cn: undefined,
                contact__organization__organization_country_jp: undefined,
                contact__organization__organization_country_kr: undefined,
                contact__organization__organization_country_other: undefined,
                contact__organization__tags: undefined,
                contact__organization__tags__suffix: undefined,
                contact__staff: undefined,
                contact__tags: undefined,
                contact__tags__suffix: undefined,
            });
            //this.contactEmailReferenceSearchFormRef.resetAll();
            let searchTemplate =
                contactPickerState.currentSearchTemplates.find(
                    (e) => e.name === value
                ) || {};
            if (searchTemplate)
                dispatch(changeTemplate(contactPickerFormId, searchTemplate));
        };

        toggleSelectedRowFilter = () => {
            const { showSelectedRowOnly } = this.state;
            this.setState({ showSelectedRowOnly: !showSelectedRowOnly });
        };

        aboutThisData = () => {
            const {
                pageState: { data },
            } = this.props;
            const showData =
                data.created_time ||
                data.created_user ||
                data.modified_time ||
                data.modified_user;
            if (showData) {
                return <AboutThisData key="aboutThisData" data={data} />;
            } else {
                return undefined;
            }
        };

        disabledMinutes = (selectedHour) => {
            const possibleMinutes = [0, 10, 20, 30, 40, 50];
            const momentFormat = "YYYY-MM-DD HH:mm";
            const {
                pageState: { reservedDateData, data },
            } = this.props;
            const {
                dateToSend,
                additionalDateToSend1,
                additionalDateToSend2,
                additionalDateToSend3,
                additionalDateToSend4,
                selectedMoment,
            } = this.state;
            // すでに予約されている時刻のstring配列
            const reservedDates =
                reservedDateData && reservedDateData.date_to_sends
                    ? reservedDateData.date_to_sends.slice()
                    : [];
            // ユーザーが操作した値から時刻のstring配列を作成
            const userInputDates = [
                dateToSend,
                additionalDateToSend1,
                additionalDateToSend2,
                additionalDateToSend3,
                additionalDateToSend4,
            ];
            const inputDates = userInputDates.map((userInputDate) => {
                if (
                    userInputDate &&
                    !reservedDates.includes(userInputDate.format(momentFormat))
                ) {
                    return userInputDate.format(momentFormat);
                }
            });
            // APIからの値とユーザーが作成した値を１つの配列にまとめる
            let totalReservedDates = [
                ...reservedDates,
                ..._.uniq(inputDates),
            ].filter((date) => date !== undefined);
            // 上記の配列から、メソッドに渡されている年月日時のものを抽出する必要がある
            const reservedMinutes = totalReservedDates
                .map((dateString) => {
                    if (!dateString || !selectedMoment) {
                        return undefined;
                    }
                    const momentDate = moment(dateString);
                    const isSameYear =
                        momentDate.year() === selectedMoment.year();
                    const isSameMonth =
                        momentDate.month() === selectedMoment.month();
                    const isSameDate =
                        momentDate.date() === selectedMoment.date();
                    const isSameHour = momentDate.hour() === selectedHour;
                    const isNotTheSameMinute = !selectedMoment
                        .set("second", 0)
                        .set("millisecond", 0)
                        .isSame(
                            momentDate.set("second", 0).set("millisecond", 0)
                        );
                    if (
                        isSameYear &&
                        isSameMonth &&
                        isSameDate &&
                        isSameHour &&
                        isNotTheSameMinute
                    ) {
                        return momentDate.minute();
                    }
                })
                .filter((date) => !(date === undefined));
            const defaultDisabledMinutes =
                reservedDateData &&
                reservedDateData.is_use_delivery_term_available
                    ? []
                    : [10, 20, 40, 50];
            let totalDisabledMinutes = [
                ...reservedMinutes,
                ...defaultDisabledMinutes,
            ];
            const today = moment();
            if (
                selectedMoment &&
                selectedMoment instanceof moment &&
                selectedMoment.year() === today.year() &&
                selectedMoment.month() === today.month() &&
                selectedMoment.date() === today.date() &&
                selectedHour === today.hour()
            ) {
                const nowMinute = today.minute();
                const disabledFixedMinutes = possibleMinutes.filter(
                    (val) => val < nowMinute
                );
                totalDisabledMinutes = [
                    ...totalDisabledMinutes,
                    ...disabledFixedMinutes,
                ];
            }
            return _.uniq(totalDisabledMinutes);
        };

        datetimePicker = (defaultValue, onDateSelect, onTimeSelect) => {
            const customLocaleConfig = {
                ...pickerLocaleConfig,
                lang: {
                    ...pickerLocaleConfig.lang,
                    ok: "OK",
                },
            };
            return (
                <>
                    <Col key="datetimePicker">
                        <Row>
                            <Col key="datePicker">
                                <CustomDatePicker
                                    format="YYYY-MM-DD"
                                    placeholder="日付を選択"
                                    showToday={false}
                                    value={defaultValue}
                                    disabledDate={this.disableddate}
                                    inputReadOnly={true}
                                    onChange={onDateSelect}
                                    allowClear={false}
                                    dropdownClassName={
                                        styles.scheduledCustomPicker
                                    }
                                />
                            </Col>
                            <Col key="timePicker">
                                <CustomTimePicker
                                    placeholder="時間を選択"
                                    defaultValue={defaultValue}
                                    format="HH:mm"
                                    minuteStep={10}
                                    disabled={!defaultValue}
                                    disabledHours={this.disabledhours}
                                    disabledMinutes={this.disabledMinutes}
                                    inputReadOnly={true}
                                    showNow={false}
                                    allowClear={false}
                                    onChange={onTimeSelect}
                                    value={defaultValue}
                                    onOpenChange={(open) => {
                                        if (open) {
                                            this.setState({
                                                selectedMoment: defaultValue,
                                            });
                                        }
                                    }}
                                    locale={customLocaleConfig}
                                />
                            </Col>
                        </Row>
                    </Col>
                </>
            );
        };

        getTimesInDay = (isTermAvailable) => {
            const timeList = [];
            const timelineInDays = isTermAvailable ? 6 : 2;
            for (let i = 0; i < timelineInDays * 11; i += 1) {
                switch (i % timelineInDays) {
                    case 0:
                        timeList.push({
                            hour: parseInt(i / timelineInDays) + 8,
                            minute: 0,
                        });
                        break;
                    case 1:
                        timeList.push({
                            hour: parseInt(i / timelineInDays) + 8,
                            minute: isTermAvailable ? 10 : 30,
                        });
                        break;
                    case 2:
                        timeList.push({
                            hour: parseInt(i / timelineInDays) + 8,
                            minute: 20,
                        });
                        break;
                    case 3:
                        timeList.push({
                            hour: parseInt(i / timelineInDays) + 8,
                            minute: 30,
                        });
                        break;
                    case 4:
                        timeList.push({
                            hour: parseInt(i / timelineInDays) + 8,
                            minute: 40,
                        });
                        break;
                    case 5:
                        timeList.push({
                            hour: parseInt(i / timelineInDays) + 8,
                            minute: 50,
                        });
                        break;
                    default:
                        break;
                }
            }
            return timeList;
        };

        setDefaultHourMinute = (momentObj) => {
            const currentMoment = moment();
            const { pageState } = this.props;
            const { reservedDateData } = pageState;
            const {
                additionalDateToSend1,
                additionalDateToSend2,
                additionalDateToSend3,
                additionalDateToSend4,
                dateToSend,
            } = this.state;
            const allTimeInvalid = [
                ...reservedDateData.date_to_sends,
                dateToSend ? dateToSend.format("YYYY-MM-DD HH:mm") : "",
                additionalDateToSend1
                    ? additionalDateToSend1.format("YYYY-MM-DD HH:mm")
                    : "",
                additionalDateToSend2
                    ? additionalDateToSend2.format("YYYY-MM-DD HH:mm")
                    : "",
                additionalDateToSend3
                    ? additionalDateToSend3.format("YYYY-MM-DD HH:mm")
                    : "",
                additionalDateToSend4
                    ? additionalDateToSend4.format("YYYY-MM-DD HH:mm")
                    : "",
            ];
            if (
                currentMoment.format("YYYY-MM-DD") !==
                momentObj.format("YYYY-MM-DD")
            ) {
                const firstTimeValid = this.getTimesInDay(
                    reservedDateData.is_use_delivery_term_available
                ).find((time) => {
                    const timeString = `${time.hour < 10 ? "0" : ""}${
                        time.hour
                    }:${time.minute < 10 ? "0" : ""}${time.minute}`;
                    return !allTimeInvalid.includes(
                        `${momentObj.format("YYYY-MM-DD")} ${timeString}`
                    );
                });
                momentObj.hours(firstTimeValid?.hour || 8);
                momentObj.minute(firstTimeValid?.minute || 0);
                return;
            } else {
                const defaultHour = currentMoment.hours();
                const defaultMinute =
                    Math.ceil(currentMoment.minute() / 10) * 10;
                const firstTimeValid = this.getTimesInDay(
                    reservedDateData.is_use_delivery_term_available
                ).find((time) => {
                    const timeString = `${time.hour < 10 ? "0" : ""}${
                        time.hour
                    }:${time.minute < 10 ? "0" : ""}${time.minute}`;
                    return (
                        !allTimeInvalid.includes(
                            `${momentObj.format("YYYY-MM-DD")} ${timeString}`
                        ) &&
                        ((time.hour === defaultHour &&
                            time.minute >= defaultMinute) ||
                            time.hour > defaultHour)
                    );
                });
                momentObj.hours(
                    firstTimeValid ? firstTimeValid.hour : defaultHour
                );
                momentObj.minute(
                    firstTimeValid ? firstTimeValid.minute : defaultMinute
                );
            }
        };

        renderDateToSend = () => {
            const { dateToSend } = this.state;
            const {
                pageState: { data },
            } = this.props;
            return this.datetimePicker(
                dateToSend === undefined
                    ? data && data.date_to_send
                    : dateToSend,
                (momentObj) => {
                    if (!momentObj) {
                        this.setState({
                            dateToSend: undefined,
                            selectedMoment: undefined,
                        });
                        return;
                    }
                    this.setDefaultHourMinute(momentObj);
                    this.setState({
                        dateToSend: momentObj,
                        selectedMoment: momentObj,
                    });
                },
                (momentObj) => {
                    if (!momentObj) {
                        return;
                    }
                    const value =
                        dateToSend &&
                        dateToSend.year &&
                        dateToSend.month &&
                        dateToSend.day
                            ? dateToSend
                            : data.date_to_send;
                    const newDate = moment({
                        years: value.year(),
                        months: value.month(),
                        days: value.date(),
                        hours: momentObj.hours(),
                        minutes: momentObj.minute(),
                    });
                    this.setState({
                        dateToSend: newDate,
                    });
                }
            );
        };

        renderAdditinalDateToSend1 = () => {
            const { additionalDateToSend1 } = this.state;
            return this.datetimePicker(
                additionalDateToSend1,
                (momentObj) => {
                    if (!momentObj) {
                        this.setState({
                            additionalDateToSend1: undefined,
                            selectedMoment: undefined,
                        });
                        return;
                    }
                    this.setDefaultHourMinute(momentObj);
                    this.setState({
                        additionalDateToSend1: momentObj,
                        selectedMoment: momentObj,
                    });
                },
                (momentObj) => {
                    if (!momentObj) {
                        return;
                    }
                    const newDate = moment({
                        years: additionalDateToSend1.year(),
                        months: additionalDateToSend1.month(),
                        days: additionalDateToSend1.date(),
                        hours: momentObj.hours(),
                        minutes: momentObj.minute(),
                    });
                    this.setState({
                        additionalDateToSend1: newDate,
                    });
                }
            );
        };

        renderAdditinalDateToSend2 = () => {
            const { additionalDateToSend2 } = this.state;
            return this.datetimePicker(
                additionalDateToSend2,
                (momentObj) => {
                    if (!momentObj) {
                        this.setState({
                            additionalDateToSend2: undefined,
                            selectedMoment: undefined,
                        });
                        return;
                    }
                    this.setDefaultHourMinute(momentObj);
                    this.setState({
                        additionalDateToSend2: momentObj,
                        selectedMoment: momentObj,
                    });
                },
                (momentObj) => {
                    if (!momentObj) {
                        return;
                    }
                    const newDate = moment({
                        years: additionalDateToSend2.year(),
                        months: additionalDateToSend2.month(),
                        days: additionalDateToSend2.date(),
                        hours: momentObj.hours(),
                        minutes: momentObj.minute(),
                    });
                    this.setState({
                        additionalDateToSend2: newDate,
                    });
                }
            );
        };

        renderAdditinalDateToSend3 = () => {
            const { additionalDateToSend3 } = this.state;
            return this.datetimePicker(
                additionalDateToSend3,
                (momentObj) => {
                    if (!momentObj) {
                        this.setState({
                            additionalDateToSend3: undefined,
                            selectedMoment: undefined,
                        });
                        return;
                    }
                    this.setDefaultHourMinute(momentObj);
                    this.setState({
                        additionalDateToSend3: momentObj,
                        selectedMoment: momentObj,
                    });
                },
                (momentObj) => {
                    if (!momentObj) {
                        return;
                    }
                    const newDate = moment({
                        years: additionalDateToSend3.year(),
                        months: additionalDateToSend3.month(),
                        days: additionalDateToSend3.date(),
                        hours: momentObj.hours(),
                        minutes: momentObj.minute(),
                    });
                    this.setState({
                        additionalDateToSend3: newDate,
                    });
                }
            );
        };

        renderAdditinalDateToSend4 = () => {
            const { additionalDateToSend4 } = this.state;
            return this.datetimePicker(
                additionalDateToSend4,
                (momentObj) => {
                    if (!momentObj) {
                        this.setState({
                            additionalDateToSend4: undefined,
                            selectedMoment: undefined,
                        });
                        return;
                    }
                    this.setDefaultHourMinute(momentObj);
                    this.setState({
                        additionalDateToSend4: momentObj,
                        selectedMoment: momentObj,
                    });
                },
                (momentObj) => {
                    if (!momentObj) {
                        return;
                    }
                    const newDate = moment({
                        years: additionalDateToSend4.year(),
                        months: additionalDateToSend4.month(),
                        days: additionalDateToSend4.date(),
                        hours: momentObj.hours(),
                        minutes: momentObj.minute(),
                    });
                    this.setState({
                        additionalDateToSend4: newDate,
                    });
                }
            );
        };

        renderConfirmModalButton = () => {
            const { dateToSend } = this.state;
            const { pageState } = this.props;
            const { data } = pageState;
            const isDisableConfirm = !dateToSend && !data.date_to_send;
            const button = (
                <Button
                    key="submit"
                    type="primary"
                    disabled={isDisableConfirm}
                    onClick={commitModal(this.commitItemToServer)}
                >
                    OK
                </Button>
            );
            if (isDisableConfirm) {
                return (
                    <Tooltip title="配信日時を選択してください。">
                        {button}
                    </Tooltip>
                );
            }
            return button;
        };

        renderSaveDraftButton = () => {
            const { pageState, authorizedActions } = this.props;
            const { data } = pageState;
            const { id: resourceId } = data;
            let changeToDraftAuthorized =
                authorizedActions &&
                authorizedActions["scheduled_mails"] &&
                authorizedActions["scheduled_mails"]["change_to_draft"];
            const status = data.status;
            const isUnchangeable =
                status === "sending" || status === "sent" || status === "error";
            const isErrorSubjectOrText =
                this.isFieldError("subject") || this.isFieldError("text");
            const button = (
                <Button
                    type="default"
                    htmlType="submit"
                    onClick={this.commitDraftToServer}
                    size="small"
                    hidden={!resourceId || status === "draft"}
                    disabled={
                        (status !== "draft" && status !== "queued") ||
                        isUnchangeable ||
                        !changeToDraftAuthorized ||
                        isErrorSubjectOrText
                    }
                >
                    下書きに変更
                </Button>
            );
            if (!changeToDraftAuthorized) {
                return (
                    <Tooltip title={ErrorMessages.isNotAuthorized}>
                        {button}
                    </Tooltip>
                );
            }
            if (isUnchangeable) {
                return (
                    <Tooltip
                        title={TooltipMessages.scheduledEmails.undraftable}
                    >
                        {button}
                    </Tooltip>
                );
            }
            if (isErrorSubjectOrText) {
                return (
                    <Tooltip title={TooltipMessages.scheduledEmails.errorInput}>
                        {button}
                    </Tooltip>
                );
            }
            return button;
        };

        renderCopyButton = () => {
            const { pageState, authorizedActions } = this.props;
            const { data } = pageState;
            const { id: resourceId } = data;
            let copyAuthorized =
                authorizedActions &&
                authorizedActions["scheduled_mails"] &&
                authorizedActions["scheduled_mails"]["copy"];
            const isErrorSubjectOrText =
                this.isFieldError("subject") || this.isFieldError("text");
            const button = (
                <Button
                    type="default"
                    size="small"
                    htmlType="submit"
                    onClick={this.createCopyOnServer}
                    hidden={!resourceId}
                    disabled={!copyAuthorized || isErrorSubjectOrText}
                >
                    コピーを作成
                </Button>
            );
            if (!copyAuthorized) {
                return (
                    <Tooltip title={ErrorMessages.isNotAuthorized}>
                        {button}
                    </Tooltip>
                );
            }
            if (isErrorSubjectOrText) {
                return (
                    <Tooltip title={TooltipMessages.scheduledEmails.errorInput}>
                        {button}
                    </Tooltip>
                );
            }
            return button;
        };

        getSendLimit = () => {
            const { pageState } = this.props;
            const { data } = pageState;
            const sendLimit = data.send_limit ?? 1000;
            return sendLimit.toLocaleString();
        };

        updateValues = () => {
            const data = {
                ...this.getCombinedFormValues(),
            };
            this.commit(data);
        };

        renderUpdateButton = () => {
            const { authorizedActions, pageState, contactPickerState } =
                this.props;
            const updateAuthorized =
                authorizedActions &&
                authorizedActions["scheduled_mails"] &&
                authorizedActions["scheduled_mails"]["update"];
            const { data, loading } = pageState;
            const { loading: pickerLoading } = contactPickerState;
            const status = data.status;
            const isUnchangeable =
                status === "sending" || status === "sent" || status === "error";
            const existsKeys = this.getExistsKeys();
            const isErrorSubjectOrText =
                this.isFieldError("subject") || this.isFieldError("text");
            const button = (
                <Button
                    type="primary"
                    onClick={this.updateValues}
                    hidden={!data.id}
                    disabled={
                        (status !== "draft" && status !== "queued") ||
                        isUnchangeable ||
                        !updateAuthorized ||
                        this.state.disabledSubmit ||
                        loading ||
                        pickerLoading ||
                        (!existsKeys.length && status === "queued") ||
                        isErrorSubjectOrText
                    }
                >
                    更新
                </Button>
            );
            if (!updateAuthorized) {
                return (
                    <Tooltip title={ErrorMessages.isNotAuthorized}>
                        {button}
                    </Tooltip>
                );
            }
            if (isUnchangeable) {
                return (
                    <Tooltip
                        title={TooltipMessages.scheduledEmails.unupdateable}
                    >
                        {button}
                    </Tooltip>
                );
            }
            if (isErrorSubjectOrText) {
                return (
                    <Tooltip title={TooltipMessages.scheduledEmails.errorInput}>
                        {button}
                    </Tooltip>
                );
            }
            return button;
        };

        renderDeleteButton = () => {
            const { authorizedActions, pageState } = this.props;
            let deleteAuthorized =
                authorizedActions &&
                authorizedActions["scheduled_mails"] &&
                authorizedActions["scheduled_mails"]["delete"];

            const { data } = pageState;
            const button = (
                <Button
                    type="danger"
                    htmlType="submit"
                    onClick={deleteModal(this.resetHandler)}
                    disabled={!deleteAuthorized || data.status === "sending"}
                >
                    削除
                </Button>
            );
            if (!deleteAuthorized) {
                return (
                    <Tooltip title={ErrorMessages.isNotAuthorized}>
                        {button}
                    </Tooltip>
                );
            }
            if (data.status === "sending") {
                return (
                    <Tooltip
                        title={TooltipMessages.scheduledEmails.undeleteable}
                    >
                        {button}
                    </Tooltip>
                );
            }
            return button;
        };

        renderNextButton = () => {
            const { disabledSubmit, currentStep } = this.state;

            let isContactSelected = true;
            if (currentStep === 2) {
                const existsKeys = this.getExistsKeys();
                isContactSelected = !!existsKeys.length;
            }

            const isErrorSubjectOrText =
                this.isFieldError("subject") || this.isFieldError("text");
            const button = (
                <Button
                    type="primary"
                    onClick={this.onNextStep}
                    className={styles.colorBgFocus}
                    disabled={
                        disabledSubmit ||
                        isErrorSubjectOrText ||
                        !isContactSelected
                    }
                >
                    次へ
                </Button>
            );

            if (isErrorSubjectOrText) {
                return (
                    <Tooltip title={TooltipMessages.scheduledEmails.errorInput}>
                        {button}
                    </Tooltip>
                );
            }

            return button;
        };

        renderSteps = () => {
            const { currentStep } = this.state;
            const isDisableStep =
                this.isFieldError("subject") || this.isFieldError("text");

            let isContactSelected = true;
            if (currentStep === 2) {
                const existsKeys = this.getExistsKeys();
                isContactSelected = !!existsKeys.length;
            }

            return (
                <Steps
                    size="small"
                    current={currentStep}
                    onChange={this.onStepClick}
                >
                    {
                        <>
                            <Step title="基本情報" disabled={isDisableStep} />
                            <Step
                                title="ファイル操作"
                                disabled={isDisableStep}
                            />
                            <Step title="宛先選択" disabled={isDisableStep} />
                            <Step
                                title="最終確認"
                                disabled={isDisableStep || !isContactSelected}
                            />
                        </>
                    }
                </Steps>
            );
        };

        render() {
            const {
                currentUserId,
                pageState,
                previewState,
                templateState,
                contactPickerState,
                authorizedActions,
                planId,
            } = this.props;
            const {
                currentStep,
                referencingExistingObject,
                showSelectedRowOnly,
                disabledSubmit,
            } = this.state;
            const {
                loading,
                data,
                errorMessage,
                fieldErrors,
                tagResisterResult,
                reservedDateData,
            } = pageState;
            const { dataTemplate } = templateState;
            const {
                loading: previewLoading,
                data: previewData,
                errorMessage: previewErrorMessage,
            } = previewState;
            const { id: resourceId, attachments, search_condition } = data; // Both can be undefined when creating new resource.

            const initialLoadFailed =
                referencingExistingObject &&
                errorMessage &&
                Object.keys(data).length === 0;

            const {
                loading: pickerLoading,
                data: contactData,
                currentSearchConditions,
                currentSearchTemplates,
                searchTemplateTotalAvailableCount,
                fieldErrors: searchFieldErrors,
                message: pickerMessage,
                errorMessage: pickerError,
                requireRefresh,
            } = contactPickerState;

            let searchConditions = currentSearchConditions
                ? currentSearchConditions
                : search_condition;

            let existsKeys = this.getExistsKeys();

            const {
                additionalCount,
                selectedTemplateName,
                promotionId,
            } = this.state;

            let createAuthorized =
                authorizedActions &&
                authorizedActions["scheduled_mails"] &&
                authorizedActions["scheduled_mails"]["create"];

            let updateAuthorized =
                authorizedActions &&
                authorizedActions["scheduled_mails"] &&
                authorizedActions["scheduled_mails"]["update"];
            const contentBody = (
                <div>
                    <div className={styles.steps}>{this.renderSteps()}</div>
                    <div hidden={currentStep !== 0}>
                        <ScheduledEmailForm
                            currentStep={currentStep}
                            currentUserId={currentUserId}
                            resourceURL={resourceURL}
                            initialData={data}
                            fieldErrors={fieldErrors}
                            submitHandler={this.submitHandler}
                            handleFormChange={this.handleFormChange}
                            isEdit={isEdit}
                            formRef={this.scheduledEmailForm}
                            templateRef={this.scheduledEmailTemplate}
                            dataTemplate={dataTemplate}
                            createTemplate={this.createTemplate}
                            updateTemplate={this.updateTemplate}
                            deleteTemplate={this.deleteTemplate}
                            disabledSubmit={disabledSubmit}
                            promotionId={promotionId}
                            onPromotionIdChange={
                                (id) => this.setState({ promotionId: id })
                            }
                        />
                    </div>
                    <div hidden={currentStep !== 1}>
                        <div className={styles.uploaderContainer}>
                            {!!attachments && (
                                <React.Fragment>
                                    <div
                                        className={styles.sectionAttachmentType}
                                    >
                                        ファイル形式&nbsp;
                                        <Tooltip
                                            title={
                                                previewData.file_type === 1 ? (
                                                    <span>
                                                        1件の配信メールに対して発行されるダウンロードURLは1件です。
                                                        複数のファイルをアップロードした場合、ダウンロードページでは複数のファイルを選択してダウンロードをすることができます。
                                                    </span>
                                                ) : (
                                                    <span>
                                                        1件の配信メールに対して添付できるファイル数は10件までです。
                                                    </span>
                                                )
                                            }
                                        >
                                            <QuestionCircleFilled
                                                style={{
                                                    color: iconCustomColor,
                                                }}
                                                className={styles.tooltip}
                                            />
                                        </Tooltip>
                                        :{" "}
                                        {previewData.file_type === 1
                                            ? "ダウンロードURL"
                                            : "添付"}
                                    </div>
                                    {/*
                                        NOTE: cloneDeep of attachments is necessary to prevent it
                                        from being shared with ScheduledEmailForm's initialData.
                                    */}
                                    <Uploader
                                        name="file"
                                        action={attachmentUploadURL(resourceId)}
                                        attachments={_.cloneDeep(attachments)}
                                        onFilePreview={this.onAttachmentPreview}
                                        onFileRemove={this.onAttachmentRemove}
                                        disabled={
                                            data.status === "sent" ||
                                            !createAuthorized ||
                                            !(resourceId && updateAuthorized)
                                        }
                                        dataMail={data}
                                    />
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                    <div hidden={currentStep !== 2}>
                        <ScheduledEmailSenderSelectStep
                            pageId={pageId}
                            contactPickerFormReducerName={
                                contactPickerFormReducerName
                            }
                            onChangeSearchTemplate={this.changeSearchTemplate}
                            onCreateSearchTemplate={this.createSearchTemplate}
                            onDeleteSearchTemplate={this.deleteSearchTemplate}
                            onUpdateSearchTemplate={this.updateSearchTemplate}
                            onResetSearchTemplate={this.resetSearchAllValue}
                            isPickerLoading={pickerLoading}
                            pickerMessage={pickerMessage}
                            pickerError={pickerError}
                            showSelectedRowOnly={showSelectedRowOnly}
                            tagResisterResult={tagResisterResult}
                            onSelectChange={this.onSelectChange}
                            contactData={contactData}
                            existsKeys={existsKeys}
                            onSelectAll={this.onSelectAll}
                            onDeselectAll={this.onDeselectAll}
                            toggleSelectedRowFilter={
                                this.toggleSelectedRowFilter
                            }
                            resetSearchAllValue={this.resetSearchAllValue}
                            initialPickListData={this.initialPickListData}
                            setContactEmailPreferenceSearchForm={
                                this.setContactEmailPreferenceSearchForm
                            }
                            requireRefresh={requireRefresh}
                            onContactSearch={this.onContactSearch}
                            searchConditions={searchConditions}
                            searchFieldErrors={searchFieldErrors}
                            selectedTemplateName={selectedTemplateName}
                            currentSearchTemplates={currentSearchTemplates}
                            searchTemplateTotalAvailableCount={
                                searchTemplateTotalAvailableCount
                            }
                            planId={planId}
                        />
                    </div>
                    <div hidden={currentStep !== 3}>
                        <Col span={24} key="col3Span24">
                            <Row justify="center">
                                <Col
                                    xxl={16}
                                    xl={20}
                                    lg={22}
                                    md={22}
                                    sm={22}
                                    xs={22}
                                >
                                    <Col span={24} key="renderStep4Alert">
                                        <Alert
                                            style={{ textAlign: "left" }}
                                            message="自動挿入とCCについて"
                                            description={renderStep4AlertDescription()}
                                            type="info"
                                            showIcon
                                        />
                                    </Col>
                                    <Col
                                        key="previewErrorMessage"
                                        span={24}
                                        hidden={!previewErrorMessage}
                                    >
                                        <Alert
                                            style={{
                                                margin: "16px auto",
                                                textAlign: "left",
                                            }}
                                            message={previewErrorMessage}
                                            type="error"
                                            showIcon
                                        />
                                    </Col>
                                    <Col
                                        key="error"
                                        span={24}
                                        hidden={!previewData.over_max_byte_size}
                                    >
                                        <Alert
                                            style={{
                                                margin: "16px auto",
                                                textAlign: "left",
                                            }}
                                            message="2MBを超えるメールを配信することはできません"
                                            type="error"
                                            showIcon
                                        />
                                    </Col>
                                    <Col span={24} key="colCard">
                                        <Card
                                            type="inner"
                                            title="プレビュー"
                                            headStyle={{
                                                background: "#FFFFCC",
                                            }}
                                        >
                                            <EmailHead
                                                data={{
                                                    fromAddress:
                                                        previewData.sender_email,
                                                    email: previewData.sender_email,
                                                    subject:
                                                        previewData.subject,
                                                }}
                                            />
                                            <EmailBody
                                                text={previewData.body}
                                                resourceId={resourceId}
                                                text_format={
                                                    previewData.text_format
                                                }
                                                mark
                                            />
                                        </Card>
                                    </Col>
                                </Col>
                            </Row>
                        </Col>
                    </div>
                    <div hidden={currentStep < 2}>
                        <LimitDivider
                            currentCount={existsKeys.length}
                            limitCount={this.getSendLimit()}
                            prefix="配信数:"
                            suffix={
                                <Tooltip
                                    title={
                                        <span>
                                            選択をした宛先の件数が表示され、ここでの件数が実際に配信されます。
                                            <br />
                                            右側の件数は配信上限数が表示されます。
                                            <br />
                                            <a
                                                href={
                                                    Links.helps.scheduledEmails
                                                        .numberOfDeliveries
                                                }
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                詳細
                                            </a>
                                        </span>
                                    }
                                >
                                    <QuestionCircleFilled
                                        style={{ color: iconCustomColor }}
                                    />
                                </Tooltip>
                            }
                        />
                    </div>
                    <Col
                        span={24}
                        style={{ marginTop: "5%" }}
                        key="span4Span24"
                    >
                        <Row>
                            <Col span={6} key="col1Span6">
                                <Row justify="start">
                                    {currentStep === 0 ? (
                                        pageId !==
                                            SCHEDULED_MAIL_REGISTER_PAGE && (
                                            <BackButton
                                                fallback={
                                                    Paths.scheduledMails +
                                                    "?page=1"
                                                }
                                            />
                                        )
                                    ) : (
                                        <Button
                                            type="default"
                                            onClick={this.onPrevStep}
                                            className={styles.colorFocus}
                                        >
                                            戻る
                                        </Button>
                                    )}
                                </Row>
                            </Col>
                            <Col span={6}>{this.renderUpdateButton()}</Col>
                            <Col span={6}>
                                {(resourceId ||
                                    this.state.referencingExistingObject) &&
                                    this.renderDeleteButton()}
                            </Col>
                            <Col span={6} key="col4Span6">
                                <Row justify="end">
                                    {currentStep === maxStepIndex ? (
                                        existsKeys.length <= 0 ? (
                                            <Tooltip
                                                title={
                                                    <span>
                                                        宛先が選択されていません
                                                    </span>
                                                }
                                            >
                                                <Button
                                                    type="primary"
                                                    htmlType="submit"
                                                    className={
                                                        styles.colorBgFocus
                                                    }
                                                    onClick={() =>
                                                        this.setState({
                                                            dateRegisterVisible: true,
                                                        })
                                                    }
                                                    disabled={true}
                                                >
                                                    時刻選択
                                                </Button>
                                            </Tooltip>
                                        ) : createAuthorized ||
                                          (resourceId && updateAuthorized) ? (
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                onClick={() =>
                                                    this.setState({
                                                        dateRegisterVisible: true,
                                                    })
                                                }
                                                className={styles.colorBgFocus}
                                                disabled={
                                                    (data.status !== "draft" &&
                                                        data.status !==
                                                            "queued") ||
                                                    previewData.over_max_byte_size ||
                                                    previewErrorMessage
                                                }
                                            >
                                                時刻選択
                                            </Button>
                                        ) : (
                                            <Tooltip
                                                title={
                                                    <span>
                                                        特定の権限で操作できます
                                                    </span>
                                                }
                                            >
                                                <Button
                                                    type="primary"
                                                    htmlType="submit"
                                                    className={
                                                        styles.colorBgFocus
                                                    }
                                                    disabled={true}
                                                >
                                                    時刻選択
                                                </Button>
                                            </Tooltip>
                                        )
                                    ) : (
                                        this.renderNextButton()
                                    )}
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <div>
                        <Modal
                            title="配信時刻設定"
                            visible={this.state.dateRegisterVisible}
                            onOk={commitModal(this.commitItemToServer)}
                            onCancel={this.closeDateModal}
                            zIndex={100}
                            footer={[this.renderConfirmModalButton()]}
                        >
                            {this.state.dateRegisterVisible && loading && (
                                <div className={styles.customProgressBar}>
                                    <Spin />
                                </div>
                            )}
                            <Form>
                                <Col span={24} key="col5Span24">
                                    <Row justify="center">
                                        <Form.Item
                                            colon={false}
                                            className={styles.field}
                                            help={fieldErrors.date_to_send}
                                            noStyle
                                        >
                                            <Col span={6} key="col5Span6" />
                                            <Col
                                                flex="auto"
                                                key="AdditinalDateToSend"
                                            >
                                                <Row>
                                                    {this.renderDateToSend()}
                                                </Row>
                                                {additionalCount > 0 && (
                                                    <Row>
                                                        {this.renderAdditinalDateToSend1()}
                                                        <div
                                                            hidden={
                                                                additionalCount >
                                                                1
                                                            }
                                                        >
                                                            <Button
                                                                type="primary"
                                                                danger
                                                                onClick={() =>
                                                                    this.remove(
                                                                        1
                                                                    )
                                                                }
                                                                icon={
                                                                    <DeleteOutlined />
                                                                }
                                                            />
                                                        </div>
                                                    </Row>
                                                )}
                                                {additionalCount > 1 && (
                                                    <Row>
                                                        {this.renderAdditinalDateToSend2()}
                                                        <div
                                                            hidden={
                                                                additionalCount >
                                                                2
                                                            }
                                                        >
                                                            <Button
                                                                type="primary"
                                                                danger
                                                                onClick={() =>
                                                                    this.remove(
                                                                        2
                                                                    )
                                                                }
                                                                icon={
                                                                    <DeleteOutlined />
                                                                }
                                                            />
                                                        </div>
                                                    </Row>
                                                )}
                                                {additionalCount > 2 && (
                                                    <Row>
                                                        {this.renderAdditinalDateToSend3()}
                                                        <div
                                                            hidden={
                                                                additionalCount >
                                                                3
                                                            }
                                                        >
                                                            <Button
                                                                type="primary"
                                                                danger
                                                                onClick={() =>
                                                                    this.remove(
                                                                        3
                                                                    )
                                                                }
                                                                icon={
                                                                    <DeleteOutlined />
                                                                }
                                                            />
                                                        </div>
                                                    </Row>
                                                )}
                                                {additionalCount > 3 && (
                                                    <Row>
                                                        {this.renderAdditinalDateToSend4()}
                                                        <div
                                                            hidden={
                                                                additionalCount >
                                                                4
                                                            }
                                                        >
                                                            <Button
                                                                type="primary"
                                                                danger
                                                                onClick={() =>
                                                                    this.remove(
                                                                        4
                                                                    )
                                                                }
                                                                icon={
                                                                    <DeleteOutlined />
                                                                }
                                                            />
                                                        </div>
                                                    </Row>
                                                )}
                                            </Col>
                                            <div
                                                className={styles.buttonWrapper}
                                                hidden={additionalCount >= 4}
                                            >
                                                <Button
                                                    className={styles.button}
                                                    type="dashed"
                                                    onClick={this.add}
                                                >
                                                    <PlusOutlined />
                                                    時刻を追加
                                                </Button>
                                            </div>
                                        </Form.Item>
                                    </Row>
                                </Col>
                            </Form>

                            {!reservedDateData?.is_use_delivery_term_available && (
                                <div style={{ fontSize: "12px" }}>
                                    <InfoCircleTwoTone
                                        twoToneColor={infoColor}
                                    />
                                    <Link
                                        href="/addons"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        アドオン
                                    </Link>
                                    を購入することで配信間隔を
                                    <span style={{ fontWeight: "bold" }}>
                                        10分
                                    </span>
                                    にすることができます。
                                </div>
                            )}
                        </Modal>
                    </div>
                </div>
            );

            if (!isEdit && !createAuthorized && !resourceId) {
                return (
                    <div className={styles.container}>
                        <NotFoundPage {...this.props} />
                    </div>
                );
            }

            return (
                <div className={styles.container}>
                    <Spin
                        spinning={
                            (loading || previewLoading || pickerLoading) &&
                            !this.state.dateRegisterVisible
                        }
                    >
                        <PageHeader
                            title={pageTitle}
                            extra={[
                                <Col key="contentPageHeader">
                                    <Row>{this.aboutThisData()}</Row>
                                    <Row
                                        gutter={6}
                                        style={{
                                            marginTop: "5%",
                                            marginBottom: "3%",
                                        }}
                                    >
                                        <Col key="copyButton">
                                            {this.renderCopyButton()}
                                        </Col>
                                        <Col key="saveDraftButton">
                                            {this.renderSaveDraftButton()}
                                        </Col>
                                    </Row>
                                </Col>,
                            ]}
                        />
                        {initialLoadFailed ? (
                            <ErrorScreen message={errorMessage} />
                        ) : (
                            <Col span={24} col="content">
                                <Row>
                                    <Col span={2} key="col1Span2" />
                                    <Col span={18} key="contentBody">
                                        {contentBody}
                                    </Col>
                                    <Col span={2} key="col2Span2" />
                                </Row>
                            </Col>
                        )}
                    </Spin>
                </div>
            );
        }
    }
    ScheduledEmailPage.propTypes = {
        userName: PropTypes.string.isRequired,
        currentUserId: PropTypes.string.isRequired,
        dispatch: PropTypes.func.isRequired,
        history: PropTypes.shape({
            goBack: PropTypes.func.isRequired,
            push: PropTypes.func.isRequired,
        }).isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                id: PropTypes.string, // We got id when open this page with existing resource.
            }).isRequired,
        }).isRequired,
        token: PropTypes.string.isRequired,
        authorizedActions: PropTypes.object.isRequired,
        pageState: PropTypes.shape({
            loading: PropTypes.bool.isRequired,
            committed: PropTypes.bool.isRequired,
            created: PropTypes.bool.isRequired,
            message: PropTypes.string.isRequired,
            errorMessage: PropTypes.string.isRequired,
            data: PropTypes.shape({
                id: PropTypes.string,
                sender: PropTypes.string,
                sender__name: PropTypes.string,
                subject: PropTypes.string,
                text: PropTypes.string,
                status: PropTypes.string,
                date_to_send: PropTypes.instanceOf(Moment),
                sent_date: PropTypes.string,
                attachments: PropTypes.arrayOf(
                    PropTypes.shape({
                        uid: PropTypes.string,
                        name: PropTypes.string,
                        status: PropTypes.string,
                    })
                ),
                target_contacts: PropTypes.arrayOf(PropTypes.string),
                send_type: PropTypes.string,
                created_time: PropTypes.string,
                created_time_ts: PropTypes.string,
                created_user: PropTypes.string,
                modified_time: PropTypes.string,
                modified_time_ts: PropTypes.string,
                modified_user: PropTypes.string,
            }).isRequired,
            fieldErrors: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string))
                .isRequired, // Just passing to a child component.
            tagResisterResult: PropTypes.string,
            reservedDateData: PropTypes.shape({
                around_minutes: PropTypes.number,
                holidays: PropTypes.arrayOf(PropTypes.string),
                date_to_sends: PropTypes.arrayOf(PropTypes.string),
                is_use_delivery_term_available: PropTypes.bool,
            }),
            errorExtraInfo: PropTypes.shape({
                message: PropTypes.string,
                redirect: PropTypes.string,
            }),
        }).isRequired,
        contactPickerState: PropTypes.shape({
            loading: PropTypes.bool.isRequired,
            message: PropTypes.string.isRequired,
            errorMessage: PropTypes.string.isRequired,
            totalCount: PropTypes.number.isRequired,
            data: PropTypes.arrayOf(PropTypes.object).isRequired, // TODO: Add missing validation.
            selectedRowKeys: PropTypes.arrayOf(PropTypes.string.isRequired)
                .isRequired,
            currentSearchConditions: PropTypes.object.isRequired, // Just passing to a child component.
            currentSearchTemplates: PropTypes.arrayOf(PropTypes.object),
            searchTemplateTotalAvailableCount: PropTypes.number,
            requireRefreshTemplate: PropTypes.bool,
        }).isRequired,
        previewState: PropTypes.shape({
            loading: PropTypes.bool.isRequired,
            data: PropTypes.shape({
                sender_email: PropTypes.string,
                subject: PropTypes.string,
                body: PropTypes.string,
                date_to_send: PropTypes.string,
                file_type: PropTypes.number,
                password: PropTypes.string,
            }),
            errorMessage: PropTypes.string,
        }).isRequired,
        planId: PropTypes.number,
    };
    function mapStateToProps(state) {
        return {
            token: state.login.token,
            authorizedActions: state.login.authorizedActions,
            currentUserId: state.login.userId,
            userName: state.login.displayName,
            pageState: state[pageReducerName],
            templateState: state[templateReducerName],
            contactPickerState: state[contactPickerFormReducerName],
            previewState: state.scheduledEmailPreviewContainer,
        };
    }
    return connect(mapStateToProps)(ScheduledEmailPage);
};

const SettingCheckWrapper = (Component) => (props) => {
    const { data, isLoading } = useFetchScheduledEmailSettingAPIQuery({});
    const userId = useSelector((state) => state.login.userId);
    const { data: plan } = usePlans();

    if (isLoading) {
        return <SpinContainer />;
    }

    let settingExists = false;
    if (data) {
        if (data.common_smtp_server.username) {
            settingExists = true;
        } else {
            const userSetting = data.user_smtp_servers.find(
                (setting) => setting.user_id === userId
            );
            if (userSetting && userSetting.username) {
                settingExists = true;
            }
        }
    }

    if (settingExists) {
        return <Component planId={plan?.planId} {...props} />;
    } else {
        return <NotFoundPage />;
    }
};

export const ScheduledEmailRegisterPage = SettingCheckWrapper(
    ScheduledEmailPageCreator(
        SCHEDULED_MAIL_REGISTER_PAGE,
        SCHEDULED_MAIL_REGISTER_PAGE_CONTACT_PICKER_FORM,
        "scheduledEmailRegisterPage",
        "scheduledEmailPageTemplates",
        "scheduledEmailRegisterPageContactSearchForm",
        "配信メール 予約",
        false
    )
);

export const ScheduledEmailEditPage = SettingCheckWrapper(
    ScheduledEmailPageCreator(
        SCHEDULED_MAIL_EDIT_PAGE,
        SCHEDULED_MAIL_EDIT_PAGE_CONTACT_PICKER_FORM,
        "scheduledEmailEditPage",
        "scheduledEmailEditPageTemplates",
        "scheduledEmailEditPageContactSearchForm",
        "配信メール 編集",
        true
    )
);

export const ScheduledEmailAttachementPage = SettingCheckWrapper(
    ScheduledEmailPageCreator(
        SCHEDULED_MAIL_EDIT_PAGE,
        SCHEDULED_MAIL_EDIT_PAGE_CONTACT_PICKER_FORM,
        "scheduledEmailEditPage",
        "scheduledEmailPageTemplates",
        "scheduledEmailEditPageContactSearchForm",
        "配信メール 編集",
        true,
        1
    )
);
