// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProjGDistributionToFormItem-container-ic2Rk{width:100%;text-align:left}.ProjGDistributionToFormItem-userInput-c8fR2{width:100%}.ProjGDistributionToFormItem-tooltip-EdYX8{margin-left:5px}.ProjGDistributionToFormItem-infoIcon-ohXwW{align-self:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/project/ProjectGanttPage/ProjectGanttSearchDrawer/ProjGDistributionToFormItem/ProjGDistributionToFormItem.scss"],"names":[],"mappings":"AAAA,6CACI,UAAW,CACX,eAAgB,CACnB,6CAGG,UAAW,CACd,2CAGG,eAAgB,CACnB,4CAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ProjGDistributionToFormItem-container-ic2Rk`,
	"userInput": `ProjGDistributionToFormItem-userInput-c8fR2`,
	"tooltip": `ProjGDistributionToFormItem-tooltip-EdYX8`,
	"infoIcon": `ProjGDistributionToFormItem-infoIcon-ohXwW`
};
export default ___CSS_LOADER_EXPORT___;
