import React from "react";
import { Col, Form, Row, Tooltip, InputNumber } from "antd";
import { QuestionCircleFilled } from "@ant-design/icons";
import { iconCustomColor } from "~/utils/constants";
import { formatMoneyNumberString, parseMoneyNumberString } from "~/components/helpers";
import styles from "./CorporateCapitalFormItem.scss";

const CorporateCapitalFormItem = ({ disabled = false }) => {
    const maxCapitalFieldName = "capital_gt";
    const minCapitalFieldName = "capital_lt";

    return (
        <Col span={24} style={{ marginBottom: "1%" }}>
            <Form.Item
                colon={false}
                name="capital"
                wrapperCol={{ span: 24 }}
                noStyle>
                <Row className={styles.container} align="middle">
                    <Col span={11}>
                        <Form.Item
                            colon={false}
                            name={maxCapitalFieldName}
                            noStyle>
                            <InputNumber
                                className={styles.userInput}
                                placeholder="資本金 : 万円(下限)"
                                formatter={formatMoneyNumberString}
                                parser={parseMoneyNumberString}
                                step={100}
                                disabled={disabled}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={1}>
                        <Row justify="center">
                            <span>〜</span>
                        </Row>
                    </Col>
                    <Col span={11}>
                        <Form.Item
                            colon={false}
                            name={minCapitalFieldName}
                            noStyle>
                            <InputNumber
                                className={styles.userInput}
                                placeholder="資本金 : 万円(上限)"
                                formatter={formatMoneyNumberString}
                                parser={parseMoneyNumberString}
                                step={100}
                                disabled={disabled}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={1} className={styles.infoIcon}>
                        <Tooltip
                            title={
                                <span>
                                    左の入力欄には下限値を入力します。
                                    <br />
                                    例：「1000」を入力した場合、資本金1000万円以上が対象となります。
                                    <br />
                                    右の入力欄には上限値を入力します。
                                    <br />
                                    例：「1000」を入力した場合、資本金1000万円以下が対象となります。
                                </span>
                            }>
                            <QuestionCircleFilled
                                style={{ color: iconCustomColor }}
                                className={styles.tooltip}
                            />
                        </Tooltip>
                    </Col>
                </Row>
            </Form.Item>
        </Col>
    );
};

export default CorporateCapitalFormItem;
