// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AppHeader-disabled-OVleX{cursor:not-allowed !important}.AppHeader-avatarButtonWrapper-j1qbz{width:32px;height:32px;border-radius:50%;overflow:hidden}.AppHeader-menuItemWithRibbon-QriqB{line-height:40px}.AppHeader-menuItem-X1uGi{margin-top:0px;margin-bottom:0px}.AppHeader-flexContainer-apCMg{display:flex;justify-content:space-between;align-items:center;width:100%}.AppHeader-fakeLink-buA5r{color:#a7cf69;cursor:pointer}.AppHeader-fakeLink-buA5r .AppHeader-fakeLink-buA5r:hover{color:#a7cf69}.AppHeader-actionSettingMenu-bYGYo{width:80px;margin-top:8px;margin-bottom:8px}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Navigations/AppHeader.scss"],"names":[],"mappings":"AAAA,0BACI,6BAA8B,CACjC,qCAGG,UAAW,CACX,WAAY,CACZ,iBAAkB,CAClB,eAAgB,CACnB,oCAGG,gBAAiB,CACpB,0BAGG,cAAe,CACf,iBAAkB,CACrB,+BAGG,YAAa,CACb,6BAA8B,CAC9B,kBAAmB,CACnB,UAAW,CACd,0BAGG,aAAc,CACd,cAAe,CAFnB,0DAIQ,aAAc,CACjB,mCAID,UAAW,CACX,cAAe,CACf,iBAAkB","sourcesContent":[".disabled {\n    cursor: not-allowed !important;\n}\n\n.avatarButtonWrapper {\n    width: 32px;\n    height: 32px;\n    border-radius: 50%;\n    overflow: hidden;\n}\n\n.menuItemWithRibbon {\n    line-height: 40px;\n}\n\n.menuItem {\n    margin-top: 0px;\n    margin-bottom: 0px;\n}\n\n.flexContainer {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    width: 100%;\n}\n\n.fakeLink {\n    color: #a7cf69;\n    cursor: pointer;\n    .fakeLink:hover {\n        color: #a7cf69;\n    }\n}\n\n.actionSettingMenu {\n    width: 80px;\n    margin-top: 8px;\n    margin-bottom: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"disabled": `AppHeader-disabled-OVleX`,
	"avatarButtonWrapper": `AppHeader-avatarButtonWrapper-j1qbz`,
	"menuItemWithRibbon": `AppHeader-menuItemWithRibbon-QriqB`,
	"menuItem": `AppHeader-menuItem-X1uGi`,
	"flexContainer": `AppHeader-flexContainer-apCMg`,
	"fakeLink": `AppHeader-fakeLink-buA5r`,
	"actionSettingMenu": `AppHeader-actionSettingMenu-bYGYo`
};
export default ___CSS_LOADER_EXPORT___;
