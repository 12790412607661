// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PersonnelBoardPage-imageTop-GLPAw{width:100%;height:146px;object-fit:cover}.PersonnelBoardPage-contentBoard-ac1gk{padding:10px 10px 10px 20px}.PersonnelBoardPage-textContent-zoUKa{padding:10px 0}.PersonnelBoardPage-containerCustom-AqgZn{width:33.33%;background:#fff}.PersonnelBoardPage-tagContent-IX_C9{margin-bottom:7px}.PersonnelBoardPage-cursor-sSaQw{cursor:pointer}.PersonnelBoardPage-focusColor-KkbnT:focus{border-color:#A7CF69;background:#A7CF69}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/personnel/PersonnelBoardPage/PersonnelBoardPage.scss"],"names":[],"mappings":"AAAA,mCACI,UAAW,CACX,YAAa,CACb,gBAAiB,CACpB,uCAEG,2BAA2B,CAC9B,sCAEG,cAAe,CAClB,0CAEG,YAAa,CACb,eAAgB,CACnB,qCAEG,iBAAkB,CACrB,iCAEG,cAAe,CAClB,2CAEG,oBAAqB,CACrB,kBAAmB","sourcesContent":[".imageTop{\n    width: 100%;\n    height: 146px;\n    object-fit: cover;\n}\n.contentBoard{\n    padding:10px 10px 10px 20px,\n}\n.textContent{\n    padding: 10px 0;\n}\n.containerCustom{\n    width: 33.33%;\n    background: #fff;\n}\n.tagContent{\n    margin-bottom: 7px;\n}\n.cursor{\n    cursor: pointer;\n}\n.focusColor:focus{\n    border-color: #A7CF69;\n    background: #A7CF69;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imageTop": `PersonnelBoardPage-imageTop-GLPAw`,
	"contentBoard": `PersonnelBoardPage-contentBoard-ac1gk`,
	"textContent": `PersonnelBoardPage-textContent-zoUKa`,
	"containerCustom": `PersonnelBoardPage-containerCustom-AqgZn`,
	"tagContent": `PersonnelBoardPage-tagContent-IX_C9`,
	"cursor": `PersonnelBoardPage-cursor-sSaQw`,
	"focusColor": `PersonnelBoardPage-focusColor-KkbnT`
};
export default ___CSS_LOADER_EXPORT___;
