// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserAvatar-styleMenu-xxRIb{width:180px}.UserAvatar-styleIconAvatar-Kuh7W{font-size:30px;color:black;background-color:white}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Common/UserAvatar/UserAvatar.scss"],"names":[],"mappings":"AAAA,4BACI,WAAY,CACf,kCAGG,cAAe,CACf,WAAY,CACZ,sBAAuB","sourcesContent":[".styleMenu {\n    width: 180px;\n}\n\n.styleIconAvatar {\n    font-size: 30px;\n    color: black;\n    background-color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"styleMenu": `UserAvatar-styleMenu-xxRIb`,
	"styleIconAvatar": `UserAvatar-styleIconAvatar-Kuh7W`
};
export default ___CSS_LOADER_EXPORT___;
