import moment from "moment";

const getDateStr = (string) => {
    if (string === null || string === undefined) return "";
    const time = moment(string);
    return time.format("YYYY-MM-DD HH:mm");
};

export const getDateStrByDateFormat = (string) => {
    if (string === null || string === undefined) return "";
    const time = moment(string);
    return time.format("YYYY-MM-DD");
};

export const getDateStrByEstablishmentDateFormat = (string) => {
    if (string === null || string === undefined) return "";
    const time = moment(string);
    return time.format("YYYY-MM-01");
};

export const DateStrToMoment = (string) =>
    string ? moment(string) : undefined;

export const getTimeStrByDateFormat = (string) => {
    if (string === null || string === undefined) {
        return "";
    }

    const time = moment(string);
    return time.format("HH:mm:ss");
};

export default getDateStr;
