"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePersonnelBoardDeleteContractAPIMutation = exports.usePersonnelBoardCreateUpdateContractAPIMutation = exports.usePersonnelBoardFetchContractsAPIQuery = void 0;
var react_query_1 = require("react-query");
var useClient_1 = require("~/hooks/useClient");
var useCustomMutation_1 = require("~/hooks/useCustomMutation");
var useAuthorizedQuery_1 = require("~/hooks/useAuthorizedQuery");
var api_1 = require("~/networking/api");
var constants_1 = require("~/utils/constants");
var converters_1 = require("../converters");
var usePersonnelBoardFetchContractsAPIQuery = function (_a) {
    var _b = _a === void 0 ? {} : _a, deps = _b.deps, options = _b.options;
    var getClientAuthorization = (0, useClient_1.useClient)().getClientAuthorization;
    return (0, useAuthorizedQuery_1.useAuthorizedQuery)({
        queryKey: constants_1.QueryKeys.personnelBoard.contracts,
        deps: deps,
        options: __assign(__assign({}, options), { select: function (result) {
                return result.map(converters_1.convertPersonnelBoardContractModelToPersonnelBoardContractUpdateFormModel);
            }, enabled: !!(deps === null || deps === void 0 ? void 0 : deps.cardId) && !!getClientAuthorization() }),
        apiRequest: function (query) {
            return api_1.personnelAPI.board.fetchContracts(query === null || query === void 0 ? void 0 : query.cardId);
        },
    });
};
exports.usePersonnelBoardFetchContractsAPIQuery = usePersonnelBoardFetchContractsAPIQuery;
var usePersonnelBoardCreateUpdateContractAPIMutation = function () {
    var id = undefined;
    var queryClient = (0, react_query_1.useQueryClient)();
    var apiRequest = function (_a) {
        var cardId = _a.cardId, postData = _a.postData;
        id = cardId;
        return api_1.personnelAPI.board.createUpdateContract(cardId, postData);
    };
    return (0, useCustomMutation_1.useCustomMutation)(apiRequest, {
        retry: 1,
        onSuccess: function (response) {
            if (id) {
                queryClient.invalidateQueries([
                    constants_1.QueryKeys.personnelBoard.contracts,
                    { cardId: id },
                ]);
            }
        },
        onError: function (err) { },
        onSettled: function () {
            id = undefined;
        },
    });
};
exports.usePersonnelBoardCreateUpdateContractAPIMutation = usePersonnelBoardCreateUpdateContractAPIMutation;
// NOTE(joshua-hashimoto): 使ってない?
var usePersonnelBoardDeleteContractAPIMutation = function () {
    return (0, useCustomMutation_1.useCustomMutation)(api_1.personnelAPI.board.deleteContract, {
        onSuccess: function (response) { },
        onError: function (err) { },
    });
};
exports.usePersonnelBoardDeleteContractAPIMutation = usePersonnelBoardDeleteContractAPIMutation;
