"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.REQUIRED_EMAIL_FORM_RULES = void 0;
var constants_1 = require("~/utils/constants");
var getEmailFormValidationRule = function (required) { return [
    {
        pattern: constants_1.ONLY_HANKAKU_REGEX,
        message: constants_1.ErrorMessages
            .validation
            .regex
            .onlyHankaku,
    },
    {
        pattern: constants_1.RESTRICT_SPACE_REGEX,
        message: constants_1.ErrorMessages
            .validation
            .regex
            .space,
    },
    {
        max: 100,
        message: constants_1.ErrorMessages
            .validation
            .length
            .max100,
    },
    {
        required: required,
        type: "email",
    },
]; };
// Non required is not declared yet as not necessary now. Declare it if needed in the future,
exports.REQUIRED_EMAIL_FORM_RULES = getEmailFormValidationRule(true);
