import { message } from "antd";
import {
    CREATING,
    CREATED,
    VALIDATION_ERROR,
    ERROR,
    CLEAR,
    CANCELED,
    LOGOUT,
    TAG_CREATED,
    COMMENT_TEMPLATE_LOADED,
    COMMENT_TEMPLATE_CREATED,
    DISPLAY_SETTING_LOADED,
    DISPLAY_SETTING_UPDATED,
    INVITED,
    COMMENT_TEMPLATE_CREATING,
    CONTACT_FORM
} from "../../actions/actionTypes";
import {SuccessMessages} from "~/utils/constants";

export const defaultInitialState = {
    loading: false,
    created: false,
    message: "",
    errorMessage: "",
    data: {},
    fieldErrors: {},
    comment_templates: [],
    totalAvailableCount: 0,
    initialData: {}
};

const createRegisterPageReducer = (
    pageId,
    InitialState = defaultInitialState
) => {
    const Reducer = (state = InitialState, action) => {
        switch (action.type) {
              case pageId + CONTACT_FORM:
                return Object.assign({}, state, {
                  loading: false,
                  message: "",
                  errorMessage: "",
                  initialData: action.initialData
              });
            case pageId + CREATING:
                return Object.assign({}, state, {
                    loading: true,
                    message: "",
                    errorMessage: "",
                });
            case pageId + CANCELED:
                return Object.assign({}, state, { loading: false });
            case pageId + CREATED:
                return Object.assign({}, state, {
                    loading: false,
                    created: true,
                    message: SuccessMessages.generic.create,
                    errorMessage: "",
                    fieldErrors: {},
                    data: action.payload.data,
                });
            case pageId + INVITED:
                return Object.assign({}, state, {
                    loading: false,
                    created: true,
                    message: "招待メールを送信しました。",
                    errorMessage: "",
                    fieldErrors: {},
                    data: action.payload.data,
                });
            case pageId + ERROR:
                return Object.assign({}, state, {
                    loading: false,
                    message: "",
                    errorMessage: action.payload.error.detail,
                });
            case pageId + VALIDATION_ERROR:
                return Object.assign({}, state, {
                    loading: false,
                    message: "",
                    errorMessage: action.payload.error.detail,
                    fieldErrors: action.payload.error.field_errors,
                });
            case pageId + CLEAR:
                return Object.assign({}, state, InitialState);
            case LOGOUT:
                return Object.assign({}, state, defaultInitialState);
            case pageId + TAG_CREATED:
                return Object.assign({}, state, {
                    tagResisterResult: action.payload.data,
                });
            case pageId + COMMENT_TEMPLATE_LOADED:
                return {
                    ...state,
                    comment_templates: action.payload.data["templates"],
                    totalAvailableCount:
                        action.payload.data["total_available_count"],
                };
            case pageId + COMMENT_TEMPLATE_CREATING:
                return {
                    ...state,
                    message: "",
                    errorMessage: "",
                };
            case pageId + COMMENT_TEMPLATE_CREATED:
                return {
                    ...state,
                    comment_templates: action.payload.data["templates"],
                    totalAvailableCount:
                        action.payload.data["total_available_count"],
                };
            case pageId + DISPLAY_SETTING_LOADED:
                return Object.assign({}, state, {
                    displaySetting: action.payload.data["content_hash"],
                });
            case pageId + DISPLAY_SETTING_UPDATED:
                return { ...state, displaySetting: action.payload.data };
            default:
                return state;
        }
    };
    return Reducer;
};

export default createRegisterPageReducer;
