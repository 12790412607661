// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SalesAssigneeFormItem-container-JMflR{width:100%;text-align:left}.SalesAssigneeFormItem-userInput-ajPgj{width:100%}.SalesAssigneeFormItem-tooltip-Ll5j_{margin-left:5px}.SalesAssigneeFormItem-infoIcon-DU_2P{align-self:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/sales/SalesBoardPage/SalesBoardSearchDrawer/SalesAssigneeFormItem/SalesAssigneeFormItem.scss"],"names":[],"mappings":"AAAA,uCACI,UAAW,CACX,eAAgB,CACnB,uCAGG,UAAW,CACd,qCAGG,eAAgB,CACnB,sCAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `SalesAssigneeFormItem-container-JMflR`,
	"userInput": `SalesAssigneeFormItem-userInput-ajPgj`,
	"tooltip": `SalesAssigneeFormItem-tooltip-Ll5j_`,
	"infoIcon": `SalesAssigneeFormItem-infoIcon-DU_2P`
};
export default ___CSS_LOADER_EXPORT___;
