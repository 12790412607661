// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BoardTabPaneLayout-wrapper-rHnXm{overflow-y:scroll;height:75vh}.BoardTabPaneLayout-wrapper-rHnXm::-webkit-scrollbar{display:none}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Common/Board/BoardTabPaneLayout/BoardTabPaneLayout.scss"],"names":[],"mappings":"AAAA,kCACI,iBAAkB,CAClB,WAAY,CAFhB,qDAIQ,YAAa","sourcesContent":[".wrapper {\n    overflow-y: scroll;\n    height: 75vh;\n    &::-webkit-scrollbar {\n        display: none;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `BoardTabPaneLayout-wrapper-rHnXm`
};
export default ___CSS_LOADER_EXPORT___;
