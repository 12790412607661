// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EmailSearchCommentUserFormItem-container-Pdv9_{width:100%;text-align:left}.EmailSearchCommentUserFormItem-userInput-j8O4I{width:100%}.EmailSearchCommentUserFormItem-tooltip-TQIcW{margin-left:5px}.EmailSearchCommentUserFormItem-infoIcon-qNAvA{align-self:center}.EmailSearchCommentUserFormItem-marginBottom-emu9r{margin-bottom:1%}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/SharedEmailSearchPage/SharedEmailSearchDrawer/EmailSearchCommentUserFormItem/EmailSearchCommentUserFormItem.scss"],"names":[],"mappings":"AAAA,gDACI,UAAW,CACX,eAAgB,CACnB,gDAGG,UAAW,CACd,8CAGG,eAAgB,CACnB,+CAGG,iBAAkB,CACrB,mDAGG,gBAAiB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n\n.marginBottom{\n    margin-bottom: 1%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `EmailSearchCommentUserFormItem-container-Pdv9_`,
	"userInput": `EmailSearchCommentUserFormItem-userInput-j8O4I`,
	"tooltip": `EmailSearchCommentUserFormItem-tooltip-TQIcW`,
	"infoIcon": `EmailSearchCommentUserFormItem-infoIcon-qNAvA`,
	"marginBottom": `EmailSearchCommentUserFormItem-marginBottom-emu9r`
};
export default ___CSS_LOADER_EXPORT___;
