// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NgWordFormListRow-formItemWrapper-KxKt4{margin-bottom:0.5rem}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/ScheduledEmailSettingPage/ScheduledEmailSettingForm/ScheduledEmailSettingSendSettingsForm/components/NgWordFormListRow/NgWordFormListRow.scss"],"names":[],"mappings":"AAAA,yCACI,oBAAqB","sourcesContent":[".formItemWrapper {\n    margin-bottom: 0.5rem;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formItemWrapper": `NgWordFormListRow-formItemWrapper-KxKt4`
};
export default ___CSS_LOADER_EXPORT___;
