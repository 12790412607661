"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var api_1 = require("~/domain/api");
var startupDiscount = function (client) {
    return {
        fetchStartupDiscountInfo: function () {
            var url = api_1.Endpoint.startupDiscount;
            return client.get(url);
        },
        registerStartupDiscount: function (postData) {
            var url = api_1.Endpoint.startupDiscount;
            return client.post(url, postData);
        },
    };
};
exports.default = startupDiscount;
