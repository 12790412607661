// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SESBSFUserSmtpServerForm-formContainer-FCSMm{text-align:left;margin-bottom:5%}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/ScheduledEmailSettingPage/ScheduledEmailSettingForm/ScheduledEmailSettingBaseSettingsForm/SESBSFUserSmtpServerForm/SESBSFUserSmtpServerForm.scss"],"names":[],"mappings":"AAAA,8CACI,eAAgB,CAChB,gBAAiB","sourcesContent":[".formContainer {\n    text-align: left;\n    margin-bottom: 5%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formContainer": `SESBSFUserSmtpServerForm-formContainer-FCSMm`
};
export default ___CSS_LOADER_EXPORT___;
