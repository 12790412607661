import {
    WRITING_EDIT_COMMENTS,
    PARENT_CANNOT_SUBMIT_WHEN_EDIT_COMMENT_EXISTS,
    COMMENT_EDIT_CLEAR,
    UPDATED,
    VALIDATION_ERROR,
} from "../../actions/actionTypes";

const commentActionSuffix = "__COMMENTS";

export const EditCommentInitialState = {
    commentValue: "",
    commentError: "",
    error: undefined,
    method: "",
};

export const createEditCommentReducer = (
    pageId,
    initialState = EditCommentInitialState
) => {
    const editCommentReducer = (
        state = initialState,
        action
    ) => {
        switch (action.type) {
            case COMMENT_EDIT_CLEAR:
                return {
                    commentValue: "",
                    commentError: "",
                    error: undefined,
                    method: "",
                };
            case WRITING_EDIT_COMMENTS:
                return {
                    commentValue: action.payload.commentValue,
                    commentError: "",
                    error: undefined,
                    method: "",
                };
            case PARENT_CANNOT_SUBMIT_WHEN_EDIT_COMMENT_EXISTS:
                return {
                    ...state,
                    commentError: "コメントは更新してください。",
                    error: undefined,
                };
            case pageId + commentActionSuffix + UPDATED:
                return {
                    commentValue: "",
                    commentError: "",
                    error: undefined,
                    method: "",
                };
            case pageId + commentActionSuffix + VALIDATION_ERROR:
                if (action.payload.method !== 'patch') {
                    return {
                        ...state,
                    }
                }
                const error = action.payload.error;
                let errorMessage = error.message
                if (error.field_errors && error.field_errors.detail) {
                    errorMessage = error.field_errors.detail
                } else if(error.field_errors && error.field_errors.content) {
                    errorMessage = error.field_errors.content
                }
                return {
                    ...state,
                    commentError: errorMessage,
                    error: undefined,
                    method: "",
                }
            default:
                return { ...state };
        }
    };
    return editCommentReducer;
};

export default createEditCommentReducer;
