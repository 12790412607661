// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DynamicRowItem-container-_YLiY{width:100%;text-align:left}.DynamicRowItem-inlineField-EB6nm{display:inline-block}.DynamicRowItem-select_option-oYl1G{text-align:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/personnel/PersonnelBoardPage/PersonnelBoardSearchDrawer/PersonnelDynamicRowsFormItem/DynamicRowItem/DynamicRowItem.scss"],"names":[],"mappings":"AAAA,gCACI,UAAW,CACX,eAAgB,CACnB,kCAGG,oBAAqB,CACxB,oCAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.inlineField {\n    display: inline-block;\n}\n\n.select_option {\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `DynamicRowItem-container-_YLiY`,
	"inlineField": `DynamicRowItem-inlineField-EB6nm`,
	"select_option": `DynamicRowItem-select_option-oYl1G`
};
export default ___CSS_LOADER_EXPORT___;
