import {
    CANCELED,
    CLEAR,
    COMMITTED,
    COPIED,
    CREATED,
    CREATING,
    DELETED,
    ERROR,
    LOAD_LAST_PAGE,
    LOADED,
    LOADING,
    LOGOUT,
    RESET_ROWS,
    RESET_DATA,
    SEARCH_COMPLETED,
    SELECT_ROWS,
    UPDATED,
    UPDATING,
    DELETING,
    USE_CACHE,
    VALIDATION_ERROR,
    DISPLAY_SETTING_LOADING,
    DISPLAY_SETTING_LOADED,
    DISPLAY_SETTING_UPDATED,
    TAG_CREATING,
    TAG_CREATED,
    TAG_COMMITTED,
    COMMENT_TEMPLATE_LOADING,
    COMMENT_TEMPLATE_LOADED,
    COMMENT_TEMPLATE_CREATING,
    COMMENT_TEMPLATE_CREATED,
    COMMENT_TEMPLATE_COMMITTED,
    BULK_UPDATED,
    RESERVED_DATE_LOADED,
    RESERVED_DATE_LOADING,
    COLUMN_SETTING_CREATING,
    COLUMN_SETTING_CREATED,
    COLUMN_SETTING_COMMITTED,
    SYNC_COLUMN_KEYS,
    CHECKING_EMAIL_ADDRESS,
    SEARCH_LOADING,
    RESET_ERROR,
    CHECKLIST_TEMPLATE_LOADED,
    CHECKLIST_TEMPLATE_LOADING,
    CHECKLIST_TEMPLATE_CREATING,
    CHECKLIST_TEMPLATE_CREATED,
    CHECKLIST_TEMPLATE_COMMITTED,
} from "./actionTypes";

import {
    AuthorizationError,
    BadRequestError,
    Endpoint,
    MyAPI,
    NotFoundError,
} from "~/domain/api";
import { CheckEmailActions } from "~/actionCreators/checkEmailAction";
import { ScheduleMailAction } from "~/actionCreators/scheduleMailAction";
import { PlanSummaryActions } from "../actionCreators/planActions";
import { logout } from "~/domain/login";
import {
    MessageType,
    singletonMessage,
} from "~/components/Common/AlertMessage/AlertMessage";
import { ErrorMessages } from "~/utils/constants";

const defaultResponseConverter = (data) => data; // Do nothing.
const defaultQueryParamConverter = (params) => params; // Do nothing.

const handleFormError = (
    targetPageId,
    dispatch,
    error,
    sendData,
    method = undefined,
    errorExtraInfo = undefined,
) => {
    if (error instanceof BadRequestError) {
        dispatch({
            type: targetPageId + VALIDATION_ERROR,
            payload: { error, data: sendData, method },
        });
    } else if (error instanceof AuthorizationError) {
        dispatch({ type: targetPageId + CANCELED, error: false });
        dispatch({ type: LOGOUT, error: false });
    } else {
        dispatch({
            type: targetPageId + ERROR,
            payload: { error, data: sendData, errorExtraInfo },
        });
    }
};

const handleError = (targetPageId, dispatch, error, actionType = ERROR) => {
    if (error instanceof AuthorizationError) {
        dispatch({ type: targetPageId + CANCELED, error: false });
        dispatch({ type: LOGOUT, error: false });
    } else if (error instanceof NotFoundError && error.lastPageNumber) {
        // For page out of range on pagination.
        dispatch({
            type: targetPageId + LOAD_LAST_PAGE,
            payload: { lastPageNumber: error.lastPageNumber },
            error: false,
        });
    } else {
        dispatch({ type: targetPageId + actionType, payload: { error } });
    }
};

export const useCache = (targetPageId) => (dispatch) =>
    dispatch({ type: targetPageId + USE_CACHE });

export const clearAction = (targetPageId) => (dispatch) =>
    dispatch({ type: targetPageId + CLEAR }); // Clear a current data for forms.

export const fetchAction =
    (
        // Get a single resource or non-paginated resources.
        targetPageId,
        token,
        resourceUrl,
        resourceId = "",
        responseConverter = defaultResponseConverter
    ) =>
    (dispatch) => {
        const API = new MyAPI(resourceUrl, token, responseConverter);
        dispatch({ type: targetPageId + LOADING });
        API.get(resourceId)
            .then((result) => {
                dispatch({
                    type: targetPageId + LOADED,
                    payload: {
                        data: result.data,
                    },
                });
            })
            .catch((error) => {
                handleError(targetPageId, dispatch, error);
            });
    };

export const createAction =
    (
        targetPageId,
        token,
        resourceUrl,
        data,
        responseConverter = defaultResponseConverter,
        commit = true,
        errorExtraInfo = undefined
    ) =>
    (dispatch) => {
        const API = new MyAPI(resourceUrl, token, responseConverter);
        dispatch({ type: targetPageId + CREATING });
        return API.post(data)
            .then((result) => {
                dispatch({
                    type: targetPageId + CREATED,
                    payload: {
                        data: result.data,
                    },
                });
                if (commit) dispatch({ type: targetPageId + COMMITTED }); // for reducers that relate to pages which have an auto-save function.
            })
            .catch((error) => {
                handleFormError(
                    targetPageId,
                    dispatch,
                    error,
                    data,
                    "create",
                    errorExtraInfo
                );
                return error;
            });
    };

export const copyAction =
    (
        targetPageId,
        token,
        resourceUrl,
        resourceId,
        responseConverter = defaultResponseConverter,
        commit = true,
        payload = {},
        errorExtraInfo = undefined
    ) =>
    (dispatch) => {
        const API = new MyAPI(
            `${resourceUrl}/${resourceId}/${Endpoint.copyActionSuffix}`,
            token,
            responseConverter
        );
        dispatch({ type: targetPageId + CREATING });
        API.post({})
            .then((result) => {
                dispatch({
                    type: targetPageId + COPIED,
                    payload: {
                        data: result.data,
                        ...payload,
                    },
                });
                if (commit) dispatch({ type: targetPageId + COMMITTED }); // for reducers that relate to pages which have an auto-save function.
            })
            .catch((error) => {
                handleFormError(
                    targetPageId,
                    dispatch,
                    error,
                    {},
                    undefined,
                    errorExtraInfo
                );
            });
    };

/**
 * @param token {string} - An API token for the backend API.
 * @param attachmentResourceUrl {string} - A Endpoint URL of file deletion.
 * @param messageWriter - Ant Design's message component.
 * @returns {*|undefined|Promise<T | boolean>}
 */
export const detachAction = (
    // Synchronized method for form callbacks.
    token,
    attachmentResourceUrl,
    messageWriter
) => {
    const API = new MyAPI(attachmentResourceUrl, token);
    return API.delete()
        .then(() => {
            messageWriter.success("ファイルの削除に成功しました");
            return true;
        })
        .catch(() => {
            messageWriter.error(
                "ファイルの削除に失敗しました。詳しくはコンソールログを確認ください。"
            );
            return false;
        });
};

export const patchAction =
    (
        // Patch a single resource. (if response.data is an array, it will cause an error.)
        targetPageId,
        token,
        resourceUrl,
        resourceId = "",
        data,
        responseConverter = defaultResponseConverter,
        commit = true,
        onSuccess = () => {},
        errorExtraInfo = undefined
    ) =>
    (dispatch) => {
        const API = new MyAPI(resourceUrl, token, responseConverter);
        dispatch({ type: targetPageId + UPDATING });
        API.patch(resourceId, data)
            .then((result) => {
                dispatch({
                    type: targetPageId + UPDATED,
                    payload: {
                        data: result.data,
                    },
                });
                if (commit) dispatch({ type: targetPageId + COMMITTED }); // for reducers that relate to pages which have an auto-save function.
                onSuccess();
            })
            .catch((error) => {
                handleFormError(
                    targetPageId,
                    dispatch,
                    error,
                    data,
                    "patch",
                    errorExtraInfo
                );
            });
    };

export const displaySettingUpdateAction =
    (
        // Patch a single resource. (if response.data is an array, it will cause an error.)
        targetPageId,
        token,
        resourceUrl,
        resourceId = "",
        data,
        responseConverter = defaultResponseConverter,
        commit = true
    ) =>
    (dispatch) => {
        const API = new MyAPI(resourceUrl, token, responseConverter);
        dispatch({ type: targetPageId + UPDATING });
        API.patch(resourceId, data)
            .then((result) => {
                dispatch({
                    type: targetPageId + DISPLAY_SETTING_UPDATED,
                    payload: {
                        data: result.data,
                    },
                });
                if (commit) dispatch({ type: targetPageId + COMMITTED }); // for reducers that relate to pages which have an auto-save function.
            })
            .catch((error) => {
                handleFormError(targetPageId, dispatch, error, data);
            });
    };

export const deleteAction =
    (targetPageId, token, resourceUrl, resourceId = "", payload = {}) =>
    (dispatch) => {
        const API = new MyAPI(resourceUrl, token);
        dispatch({ type: targetPageId + LOADING });
        API.delete(resourceId)
            .then(() => {
                dispatch({ type: targetPageId + DELETED, payload });
            })
            .catch((error) => {
                handleError(targetPageId, dispatch, error);
            });
    };

export const bulkDeleteAction =
    (targetPageId, token, resourceUrl, data, payload = {}) =>
    (dispatch) => {
        const API = new MyAPI(resourceUrl, token);
        dispatch({ type: targetPageId + LOADING });
        API.bulkDelete(data)
            .then(() => {
                dispatch({ type: targetPageId + DELETED, payload });
            })
            .catch((error) => {
                handleError(targetPageId, dispatch, error);
            });
    };

export const bulkUpdateAction =
    (targetPageId, token, resourceUrl, ids, column, value, payload = {}, onSuccess = () => {}, onError = undefined) =>
    (dispatch) => {
        const API = new MyAPI(resourceUrl, token);
        dispatch({ type: targetPageId + LOADING });
        API.bulkUpdate(ids, column, value)
            .then(() => {
                dispatch({
                    type: targetPageId + BULK_UPDATED,
                    payload: { ...value, ...payload },
                });
                onSuccess();
            })
            .catch((error) => {
                const dispatchError = onError ? onError(error) : error;
                handleError(targetPageId, dispatch, dispatchError);
            });
    };

export const searchAction =
    (
        targetPageId,
        token,
        resourceUrl,
        currentPage,
        pageSize,
        searchConditions,
        queryParamConverter = defaultQueryParamConverter,
        responseConverter = defaultResponseConverter,
        sortKey,
        sortOrder,
        timestamp = undefined
    ) =>
    (dispatch) => {
        const API = new MyAPI(resourceUrl, token, responseConverter);
        dispatch({
            type: targetPageId + SEARCH_LOADING,
            payload: { timestamp },
        });
        API.search(
            searchConditions,
            queryParamConverter,
            currentPage,
            pageSize,
            sortKey,
            sortOrder
        )
            .then((result) => {
                dispatch({
                    type: targetPageId + SEARCH_COMPLETED,
                    payload: {
                        data: result.data,
                        currentPage,
                        pageSize,
                        totalCount: result.total,
                        searchConditions,
                        sortKey,
                        sortOrder,
                        requireRefresh: false,
                        requireResetSelectedRows: false,
                        timestamp,
                    },
                });
            })
            .catch((error) => {
                handleError(targetPageId, dispatch, error);
            });
    };

export const searchAllAction =
    (
        // TODO: refactor and reduce logic duplications.
        targetPageId,
        token,
        resourceUrl,
        currentPage,
        pageSize,
        searchConditions,
        queryParamConverter = defaultQueryParamConverter,
        responseConverter = defaultResponseConverter
    ) =>
    (dispatch) => {
        const API = new MyAPI(resourceUrl, token, responseConverter);
        dispatch({ type: targetPageId + LOADING });
        API.search(searchConditions, queryParamConverter, currentPage) // set pagesize undefined and let the server to determine.
            .then((result) => {
                dispatch({
                    type: targetPageId + SEARCH_COMPLETED,
                    payload: {
                        data: result.data,
                        currentPage,
                        pageSize,
                        totalCount: result.total,
                        searchConditions,
                    },
                });
            })
            .catch((error) => {
                handleError(targetPageId, dispatch, error);
            });
    };

export const resetRowSelections = (targetPageId) => (dispatch) => {
    dispatch({
        type: targetPageId + RESET_ROWS,
    });
};

export const resetData = (targetPageId) => (dispatch) => {
    dispatch({
        type: targetPageId + RESET_DATA,
    });
};

export const resetError = (targetPageId) => (dispatch) => {
    dispatch({
        type: targetPageId + RESET_ERROR,
    });
};

export const syncSelections = (targetPageId, selectedRowKeys) => (dispatch) => {
    dispatch({
        type: targetPageId + SELECT_ROWS,
        payload: { selectedRowKeys },
    });
};

export const simpleAction = (
    targetPageId,
    token,
    resourceUrl,
    data,
    responseConverter = defaultResponseConverter
) => {
    const API = new MyAPI(resourceUrl, token, responseConverter);
    return API.post(data);
};

export const simpleFetch = (
    // Get a single resource.
    token,
    resourceUrl,
    responseConverter = defaultResponseConverter
) => {
    const API = new MyAPI(resourceUrl, token, responseConverter);
    return API.get().then((response) => response.data);
};

export const fetchDisplaySettingAction =
    (
        targetPageId,
        token,
        resourceUrl,
        resourceName = null,
        responseConverter = defaultResponseConverter,
        requireRefresh = false
    ) =>
    (dispatch) => {
        const API = new MyAPI(resourceUrl, token, responseConverter);
        dispatch({ type: targetPageId + DISPLAY_SETTING_LOADING });
        API.get()
            .then((result) => {
                dispatch({
                    type: targetPageId + DISPLAY_SETTING_LOADED,
                    payload: {
                        data: result.data,
                        resourceName: resourceName,
                        requireRefresh: requireRefresh,
                    },
                });
            })
            .catch((error) => {
                handleError(targetPageId, dispatch, error);
            });
    };

export const createTagAction =
    (
        targetPageId,
        token,
        resourceUrl,
        data,
        responseConverter = defaultResponseConverter,
        commit = true
    ) =>
    (dispatch) => {
        const API = new MyAPI(resourceUrl, token, responseConverter);
        dispatch({ type: targetPageId + TAG_CREATING });
        API.post(data)
            .then((result) => {
                dispatch({
                    type: targetPageId + TAG_CREATED,
                    payload: {
                        data: result.data,
                    },
                });
                if (commit) dispatch({ type: targetPageId + TAG_COMMITTED });
            })
            .catch((error) => {
                handleFormError(targetPageId, dispatch, error, data);
            });
    };

export const fetchCommentTemplateAction =
    (
        targetPageId,
        token,
        resourceUrl,
        resourceId = null,
        responseConverter = defaultResponseConverter
    ) =>
    (dispatch) => {
        const API = new MyAPI(resourceUrl, token, responseConverter);
        dispatch({ type: targetPageId + COMMENT_TEMPLATE_LOADING });
        API.get(resourceId)
            .then((result) => {
                dispatch({
                    type: targetPageId + COMMENT_TEMPLATE_LOADED,
                    payload: {
                        data: result.data,
                    },
                });
            })
            .catch((error) => {
                handleError(targetPageId, dispatch, error);
            });
    };

export const createCommentTemplateAction =
    (
        targetPageId,
        token,
        resourceUrl,
        data,
        responseConverter = defaultResponseConverter,
        commit = true
    ) =>
    (dispatch) => {
        const API = new MyAPI(resourceUrl, token, responseConverter);
        dispatch({ type: targetPageId + COMMENT_TEMPLATE_CREATING });
        API.post(data)
            .then((result) => {
                dispatch({
                    type: targetPageId + COMMENT_TEMPLATE_CREATED,
                    payload: {
                        data: result.data,
                    },
                });
                if (commit)
                    dispatch({
                        type: targetPageId + COMMENT_TEMPLATE_COMMITTED,
                    });
            })
            .catch((error) => {
                handleFormError(targetPageId, dispatch, error, data);
            });
    };

export const fetchCheckListTempalteAction =
    (
        targetPageId,
        token,
        resourceUrl,
        resourceId = null,
        responseConverter = defaultResponseConverter
    ) =>
    (dispatch) => {
        const API = new MyAPI(resourceUrl, token, responseConverter);
        dispatch({ type: targetPageId + CHECKLIST_TEMPLATE_LOADING });
        API.get(resourceId)
            .then((result) => {
                dispatch({
                    type: targetPageId + CHECKLIST_TEMPLATE_LOADED,
                    payload: result.data,
                });
            })
            .catch((error) => {
                handleError(targetPageId, dispatch, error);
            });
    };

export const createCheckListTempalteAction = 
    (
        targetPageId,
        token,
        resourceUrl,
        data,
        responseConverter = defaultResponseConverter,
        commit = true
    ) =>
    (dispatch) => {
        const API = new MyAPI(resourceUrl, token, responseConverter);
        dispatch({ type: targetPageId + CHECKLIST_TEMPLATE_CREATING });
        API.post(data)
            .then((result) => {
                dispatch({
                    type: targetPageId + CHECKLIST_TEMPLATE_CREATED,
                    payload: result.data,
                });
                if (commit)
                    dispatch({
                        type: targetPageId + CHECKLIST_TEMPLATE_COMMITTED,
                    });
            })
            .catch((error) => {
                handleFormError(targetPageId, dispatch, error, data);
            });
    };

export const bulkCopyAction =
    (
        targetPageId,
        token,
        resourceUrl,
        data,
        responseConverter = defaultResponseConverter,
        commit = true,
        payload = {},
        errorExtraInfo = undefined
    ) =>
    (dispatch) => {
        const API = new MyAPI(resourceUrl, token, responseConverter);
        dispatch({ type: targetPageId + LOADING });
        API.post(data)
            .then((result) => {
                dispatch({
                    type: targetPageId + CREATED,
                    payload: {
                        data: result.data,
                    },
                });
                dispatch({
                    type: targetPageId + COPIED,
                    payload,
                });
                if (commit) dispatch({ type: targetPageId + COMMITTED });
            })
            .catch((error) => {
                handleFormError(
                    targetPageId,
                    dispatch,
                    error,
                    data,
                    undefined,
                    errorExtraInfo
                );
            });
    };

export const fetchReservedDateAction =
    (
        // Get a single resource or non-paginated resources.
        targetPageId,
        token,
        resourceUrl,
        responseConverter = defaultResponseConverter
    ) =>
    (dispatch) => {
        const API = new MyAPI(resourceUrl, token, responseConverter);
        dispatch({ type: targetPageId + RESERVED_DATE_LOADING });
        API.get()
            .then((result) => {
                dispatch({
                    type: targetPageId + RESERVED_DATE_LOADED,
                    payload: {
                        data: result.data,
                    },
                });
            })
            .catch((error) => {
                handleError(targetPageId, dispatch, error);
            });
    };

export const syncColumnKeys =
    (targetPageId, selectedColumnKeys) => (dispatch) => {
        dispatch({
            type: targetPageId + SYNC_COLUMN_KEYS,
            payload: { selectedColumnKeys },
        });
    };

export const createColumnSettingAction =
    (
        targetPageId,
        token,
        resourceUrl,
        data,
        responseConverter = defaultResponseConverter,
        commit = true
    ) =>
    (dispatch) => {
        const API = new MyAPI(resourceUrl, token, responseConverter);
        dispatch({ type: targetPageId + COLUMN_SETTING_CREATING });
        API.post(data)
            .then((result) => {
                dispatch({
                    type: targetPageId + COLUMN_SETTING_CREATED,
                    payload: {
                        data: data,
                    },
                });
                if (commit)
                    dispatch({ type: targetPageId + COLUMN_SETTING_COMMITTED }); // for reducers that relate to pages which have an auto-save function.
            })
            .catch((error) => {
                handleFormError(targetPageId, dispatch, error, data);
            });
    };

export const fetchApi =
    (
        targetPageId,
        token,
        resourceUrl,
        resourceId = null,
        status = {},
        responseConverter = defaultResponseConverter
    ) =>
    (dispatch) => {
        let actionStatus = Object.assign(
            { before: LOADING, after: LOADED, error: ERROR },
            status
        );
        const API = new MyAPI(resourceUrl, token, responseConverter);
        dispatch({ type: targetPageId + actionStatus.before });
        API.get(resourceId)
            .then((result) => {
                dispatch({
                    type: targetPageId + actionStatus.after,
                    payload: {
                        data: result.data,
                    },
                });
            })
            .catch((error) => {
                handleError(targetPageId, dispatch, error, actionStatus.error);
            });
    };

export const deleteApi =
    (targetPageId, token, resourceUrl, resourceId = null, status = {}) =>
    (dispatch) => {
        let actionStatus = Object.assign(
            { before: DELETING, after: DELETED, commit: COMMITTED },
            status
        );
        const API = new MyAPI(resourceUrl, token);
        dispatch({ type: targetPageId + actionStatus.before });
        API.delete(resourceId)
            .then(() => {
                dispatch({
                    type: targetPageId + actionStatus.after,
                });
                if (actionStatus.commit)
                    dispatch({ type: targetPageId + actionStatus.commit });
                if (actionStatus.afterCommit) {
                    dispatch({
                        type: targetPageId + actionStatus.afterCommit,
                    });
                }
            })
            .catch((error) => {
                handleError(targetPageId, dispatch, error);
            });
    };

export const createApi =
    (
        targetPageId,
        token,
        resourceUrl,
        data,
        status = {},
        responseConverter = defaultResponseConverter
    ) =>
    (dispatch) => {
        let actionStatus = Object.assign(
            { before: CREATING, after: CREATED, commit: COMMITTED },
            status
        );
        const API = new MyAPI(resourceUrl, token, responseConverter);
        dispatch({ type: targetPageId + actionStatus.before });
        return API.post(data)
            .then((result) => {
                dispatch({
                    type: targetPageId + actionStatus.after,
                    payload: {
                        data: result.data,
                    },
                });
                if (actionStatus.commit)
                    dispatch({ type: targetPageId + actionStatus.commit });
                if (actionStatus.afterCommit) {
                    dispatch({
                        type: targetPageId + actionStatus.afterCommit,
                    });
                }
                return Promise.resolve(result);
            })
            .catch((error) => {
                handleFormError(targetPageId, dispatch, error, data);
                return Promise.reject(error);
            });
    };

export const updateApi =
    (
        // Patch a single resource. (if response.data is an array, it will cause an error.)
        targetPageId,
        token,
        resourceUrl,
        resourceId = null,
        data,
        status = {},
        responseConverter = defaultResponseConverter
    ) =>
    (dispatch) => {
        let actionStatus = Object.assign(
            { before: UPDATING, after: UPDATED, commit: COMMITTED },
            status
        );
        const API = new MyAPI(resourceUrl, token, responseConverter);
        dispatch({ type: targetPageId + actionStatus.before });
        API.patch(resourceId, data)
            .then((result) => {
                dispatch({
                    type: targetPageId + actionStatus.after,
                    payload: {
                        data: result.data,
                    },
                });
                if (actionStatus.commit)
                    dispatch({ type: targetPageId + actionStatus.commit }); // for reducers that relate to pages which have an auto-save function.
                if (actionStatus.afterCommit) {
                    dispatch({
                        type: targetPageId + actionStatus.afterCommit,
                    });
                }
            })
            .catch((error) => {
                handleFormError(targetPageId, dispatch, error, data);
            });
    };

export const removeBranchFromOrganizationAction =
    (reducerActionType, token, url) => async (dispatch) => {
        dispatch({ type: reducerActionType + LOADING });
        const API = new MyAPI(url, token, (data) => data);
        try {
            const response = await API.delete();
            // NOTE(joshua-hashimoto): payloadのdataに渡しているidは、支店を削除した時に取引先編集画面の関連リソースタブが消えないようにするためのもの。
            //                         支店削除後の更新などに影響はないことは確認済み。
            dispatch({
                type: reducerActionType + LOADED,
                payload: { data: { id: "this-is-a-dummy-id" } },
            });
        } catch (err) {
            handleFormError(reducerActionType, dispatch, err, ERROR);
        }
    };

// PAYMENT: お支払い情報の取得
export const fetchPaymentInfoAction =
    (reducerActionType, token, url) => async (dispatch) => {
        dispatch({ type: reducerActionType + LOADING });
        const API = new MyAPI(url, token, (data) => data);
        try {
            const response = await API.get();
            dispatch({
                type: reducerActionType + LOADED,
                payload: response.data,
            });
        } catch (err) {
            handleError(reducerActionType, dispatch, err, ERROR);
        }
    };

// PAYMENT: お支払い情報の作成
export const createPaymentInfoAction =
    (reducerActionType, token, url, postData) => async (dispatch) => {
        dispatch({ type: reducerActionType + LOADING });
        const API = new MyAPI(url, token, (data) => data);
        try {
            const response = await API.post(postData);
            dispatch({
                type: reducerActionType + LOADED,
                payload: response.data,
            });
        } catch (err) {
            handleFormError(reducerActionType, dispatch, err, ERROR);
        }
    };

// PAYMENT: お支払い情報の更新
export const updatePaymentInfoAction =
    (reducerActionType, token, url, postData) => async (dispatch) => {
        dispatch({ type: reducerActionType + LOADING });
        const API = new MyAPI(url, token, (data) => data);
        try {
            const response = await API.patch(postData.card_id, postData);
            dispatch({
                type: reducerActionType + LOADED,
                payload: response.data,
            });
        } catch (err) {
            handleFormError(reducerActionType, dispatch, err, ERROR);
        }
    };

// PAYMENT: お支払い情報の削除
export const deletePaymentInfoAction =
    (reducerActionType, token, url, postData) => async (dispatch) => {
        dispatch({ type: reducerActionType + LOADING });
        const API = new MyAPI(url, token, (data) => data);
        try {
            const response = await API.objectDelete({
                card_id: postData.cardId,
            });
            dispatch({ type: reducerActionType + LOADED });
        } catch (err) {
            handleFormError(reducerActionType, dispatch, err, ERROR);
        }
    };

export const createScheduleTemplateAction =
    (
        targetPageId,
        token,
        resourceUrl,
        data,
        actionStatus,
        responseConverter = defaultResponseConverter,
        commit = true
    ) =>
    (dispatch) => {
        const API = new MyAPI(resourceUrl, token, responseConverter);
        dispatch(ScheduleMailAction.creatingTemplateAction(targetPageId));
        API.post(data)
            .then((result) => {
                dispatch(
                    ScheduleMailAction.createdTemplateAction(targetPageId, {
                        data: result.data,
                        actionStatus: actionStatus,
                    })
                );
                if (commit) {
                    dispatch(
                        ScheduleMailAction.committedTemplateAction(targetPageId)
                    );
                }
            })
            .catch((error) => {
                handleFormError(targetPageId, dispatch, error, data);
            });
    };

export const checkExistEmailsAction =
    (
        targetPageId,
        token,
        resourceUrl,
        data,
        responseConverter = defaultResponseConverter
    ) =>
    (dispatch) => {
        const API = new MyAPI(resourceUrl, token, responseConverter);
        dispatch({ type: targetPageId + CHECKING_EMAIL_ADDRESS });
        API.post(data)
            .then((result) => {
                dispatch(
                    CheckEmailActions.checkedAction(targetPageId, {
                        message: result.data.detail,
                    })
                );
            })
            .catch((error) => {
                dispatch(
                    CheckEmailActions.errorAction(targetPageId, {
                        errorMessage: error.detail,
                    })
                );
            })
            .finally(() => {
                dispatch(CheckEmailActions.clearAction(targetPageId));
            });
    };

// NOTE(Chikama): as plan is common in all the page, do not have to specify pageId
export const fetchPlanSummary =
    (token, resourceUrl, converter = defaultResponseConverter) =>
    (dispatch) => {
        const api = new MyAPI(resourceUrl, token, converter);
        dispatch(PlanSummaryActions.startFetchingPlan());
        api.get()
            .then((result) => {
                dispatch(PlanSummaryActions.planFetched(result.data));
            })
            .catch((e) => {
                console.error(e);
                singletonMessage(
                    ErrorMessages.plan.planInfo,
                    MessageType.ERROR
                );
                void logout(token);
            });
    };
