// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PriceInputFormItem-container-ziKnp{text-align:left}.PriceInputFormItem-userInput-w68nk{width:100%}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/personnel/PersonnelBoardPage/PersonnelBoardSearchDrawer/PersonnelPriceFormItem/PriceInputFormItem/PriceInputFormItem.scss"],"names":[],"mappings":"AAAA,oCACI,eAAgB,CACnB,oCAGG,UAAW","sourcesContent":[".container {\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PriceInputFormItem-container-ziKnp`,
	"userInput": `PriceInputFormItem-userInput-w68nk`
};
export default ___CSS_LOADER_EXPORT___;
