import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { Layout, Button, Tooltip, Row, Typography, Col, Mentions } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import Menu from "./Navigations/MainMenu";
import AppHeader from "./Navigations/AppHeader";
import { useGuardPlanSummary } from "~/hooks/usePlan";
import { Links } from "~/utils/constants";
import CustomTypographyLink from "~/components/Common/CustomTypographyLink/CustomTypographyLink"
import styles from "./AppContainer.scss";

const { Content, Sider, Footer } = Layout;
const { Text } = Typography;

const SIDE_BAR_WIDTH = 250;
const SIDE_BAR_WITH_RIBBON_WIDTH = 300;

const footerLayout = {
    borderTop: "1px solid #e8e8e8",
    width: "100%",
    textAlign: "center",
    padding: "25px 0",
    position: "absolute",
    bottom: "0",
};

const AppContainer = ({ isLoggedIn, children }) => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const { isNotValidUser, isPersonalPlan } = useGuardPlanSummary();
    const sidebarWidth = isPersonalPlan ? SIDE_BAR_WITH_RIBBON_WIDTH : SIDE_BAR_WIDTH;

    const toggleMenuCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    const year = useMemo(() => {
        return new Date().getFullYear();
    }, [])

    return (
        <Layout
            className="layout"
            style={{
                height: "100%",
                minHeight: "100vh",
                position: "relative",
                paddingBottom: "90px",
                boxSizing: "border-box",
            }}
        >
            <AppHeader isLoggedIn={isLoggedIn} />
            <Mentions className={styles.mention} />
            <Layout hasSider>
                {isLoggedIn && !isNotValidUser ? (
                    <Sider
                        width={sidebarWidth}
                        className={styles.sider}
                        breakpoint="md"
                        collapsedWidth="0"
                        trigger={null}
                        collapsed={isCollapsed}>
                        <Menu />
                    </Sider>
                ) : null}
                <Content className={styles.content}>
                    {isLoggedIn && !isNotValidUser ? (
                            <Tooltip
                            title={isCollapsed ? "展開" : "折りたたむ"}>
                            <Button
                                shape="circle"
                                type="primary"
                                onClick={toggleMenuCollapse}
                                className={styles.collapse}
                                icon={
                                    isCollapsed ? (
                                        <RightOutlined />
                                    ) : (
                                        <LeftOutlined />
                                    )
                                }
                            />
                        </Tooltip>
                    ) : null}
                    {children}
                </Content>
            </Layout>
            <Footer style={footerLayout}>
                <Col span={24} justify="center">
                    <Row justify="center">
                        <Col justify="center">
                            <CustomTypographyLink
                                href={Links.services.management}
                                title="運営会社"
                            />{" "}
                            /{" "}
                            <CustomTypographyLink
                                href={Links.services.tos}
                                title="利用規約"
                            />{" "}
                            /{" "}
                            <CustomTypographyLink
                                href={Links.services.privacyPolicy}
                                title="プライバシーポリシー"
                            />{" "}
                            /{" "}
                            <CustomTypographyLink
                                href={Links.services.termsOfSales}
                                title="特商法に基づく表示"
                            />
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Col justify="center">
                            <Text>
                                コモレビ ©{year} Created by Health Basis, Inc.
                            </Text>
                        </Col>
                    </Row>
                </Col>
            </Footer>
        </Layout>
    );
};

AppContainer.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
    children: PropTypes.element.isRequired, // Depends on Ant Design Library, hard to specify type.
};

export default AppContainer;
