// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-mentions-dropdown-menu{max-height:110px}.UserMentionsForm-mentionItem-XfOyh{display:flex;flex-direction:row;align-items:center}.UserMentionsForm-mentionItem-XfOyh .UserMentionsForm-userLabel-k6K9B{margin-left:5px}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Forms/UserMentionsForm/UserMentionsForm.scss"],"names":[],"mappings":"AAAA,4BACI,gBAAiB,CACpB,oCAGG,YAAa,CACb,kBAAmB,CACnB,kBAAmB,CAHvB,sEAKQ,eAAgB","sourcesContent":[":global(.ant-mentions-dropdown-menu) {\n    max-height: 110px;\n}\n\n.mentionItem {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    .userLabel {\n        margin-left: 5px;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mentionItem": `UserMentionsForm-mentionItem-XfOyh`,
	"userLabel": `UserMentionsForm-userLabel-k6K9B`
};
export default ___CSS_LOADER_EXPORT___;
