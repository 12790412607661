"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var utils_1 = require("./utils");
var attachments = function (client) {
    var path = function (dynamicPath) {
        if (dynamicPath === void 0) { dynamicPath = ""; }
        return utils_1.salesBoardBasePath + "/cards/attachments" + dynamicPath;
    };
    return {
        downloadAttachment: function (attachmentId) {
            var url = path("/" + attachmentId);
            return client.get(url, {
                responseType: "blob",
            });
        },
        deleteAttachment: function (attachmentId) {
            var url = path("/" + attachmentId);
            return client.delete(url);
        },
    };
};
exports.default = attachments;
