// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProjBPlaceFormItem-marginBottom0-PIvZX{margin-bottom:0}.ProjBPlaceFormItem-select_option-Mzabg{text-align:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/project/ProjectBoardPage/ProjectBoardDetailModal/ProjectBoardBaseInfoForm/ProjBPlaceFormItem/ProjBPlaceFormItem.scss"],"names":[],"mappings":"AAAA,wCACI,eAAgB,CACnB,wCAGG,iBAAkB","sourcesContent":[".marginBottom0 {\n    margin-bottom: 0;\n}\n\n.select_option {\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"marginBottom0": `ProjBPlaceFormItem-marginBottom0-PIvZX`,
	"select_option": `ProjBPlaceFormItem-select_option-Mzabg`
};
export default ___CSS_LOADER_EXPORT___;
