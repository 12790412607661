// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BackOfficeBoardListFormItem-container-RU1tw{width:100%;text-align:left}.BackOfficeBoardListFormItem-userInput-w4jph{width:100%}.BackOfficeBoardListFormItem-tooltip-RmSe1{margin-left:5px}.BackOfficeBoardListFormItem-infoIcon-jDxbe{align-self:center}.BackOfficeBoardListFormItem-select_option-KdCsb{text-align:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/backOffice/BackOfficeBoardPage/BackOfficeBoardSearchDrawer/BackOfficeBoardListFormItem/BackOfficeBoardListFormItem.scss"],"names":[],"mappings":"AAAA,6CACI,UAAW,CACX,eAAgB,CACnB,6CAGG,UAAW,CACd,2CAGG,eAAgB,CACnB,4CAGG,iBAAkB,CACrB,iDAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n\n.select_option {\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `BackOfficeBoardListFormItem-container-RU1tw`,
	"userInput": `BackOfficeBoardListFormItem-userInput-w4jph`,
	"tooltip": `BackOfficeBoardListFormItem-tooltip-RmSe1`,
	"infoIcon": `BackOfficeBoardListFormItem-infoIcon-jDxbe`,
	"select_option": `BackOfficeBoardListFormItem-select_option-KdCsb`
};
export default ___CSS_LOADER_EXPORT___;
