// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UsernameFormItem-container-pRR2B{width:100%;text-align:left}.UsernameFormItem-userInput-zLFTA{width:100%;margin-right:5px}.UsernameFormItem-tooltip-P_Jh9{margin-left:5px}.UsernameFormItem-infoIcon-bpTwu{align-self:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Forms/UserSearchForm/UsernameFormItem/UsernameFormItem.scss"],"names":[],"mappings":"AAAA,kCACI,UAAW,CACX,eAAgB,CACnB,kCAGG,UAAW,CACX,gBAAiB,CACpB,gCAGG,eAAgB,CACnB,iCAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n    margin-right: 5px;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `UsernameFormItem-container-pRR2B`,
	"userInput": `UsernameFormItem-userInput-zLFTA`,
	"tooltip": `UsernameFormItem-tooltip-P_Jh9`,
	"infoIcon": `UsernameFormItem-infoIcon-bpTwu`
};
export default ___CSS_LOADER_EXPORT___;
