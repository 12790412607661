// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReminderSettingsPage-container-cBGPH{text-align:left}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/ReminderSettingsPage/ReminderSettingsPage.scss"],"names":[],"mappings":"AAAA,sCACE,eAAgB","sourcesContent":[".container {\n  text-align: left;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ReminderSettingsPage-container-cBGPH`
};
export default ___CSS_LOADER_EXPORT___;
