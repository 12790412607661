// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BoardBaseCardDataStatuses-iconSpacer-rudeL{padding-right:7px}.BoardBaseCardDataStatuses-statuses-YZ6j_{font-size:12px}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Common/Board/BoardBaseCard/BoardBaseCardDataStatuses/BoardBaseCardDataStatuses.scss"],"names":[],"mappings":"AAAA,4CACI,iBACJ,CAAC,0CAGG,cAAe","sourcesContent":[".iconSpacer {\n    padding-right: 7px\n}\n\n.statuses {\n    font-size: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconSpacer": `BoardBaseCardDataStatuses-iconSpacer-rudeL`,
	"statuses": `BoardBaseCardDataStatuses-statuses-YZ6j_`
};
export default ___CSS_LOADER_EXPORT___;
