// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PlanPage-pagePlanTitle-JxD0n{margin-bottom:30px}.PlanPage-planCardsContainer-UkyYn{margin-top:7%;flex-wrap:nowrap}.PlanPage-planFunctionTableContainer-LeaOG{margin:12% 0}.PlanPage-planCardsScroller-g3akT{overflow-x:scroll}.PlanPage-planCardsScroller-g3akT::-webkit-scrollbar{width:10px;height:10px}.PlanPage-planCardsScroller-g3akT::-webkit-scrollbar-thumb{background-color:rgba(0,0,0,0.2);border-radius:10px}.PlanPage-cardRibbonPopular-ldoCv{margin-right:8.4%;height:29px;padding-top:5px;font-size:20px}.PlanPage-cardRibbonPopularPersonal-UM8ws{margin-right:8.4%;height:29px;padding-top:5px;font-size:16px}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/PlanPage/PlanPage.scss"],"names":[],"mappings":"AAAA,8BACI,kBAAmB,CACtB,mCAGG,aAAc,CACd,gBAAiB,CACpB,2CAGG,YAAa,CAChB,kCAGG,iBAAkB,CADtB,qDAIQ,UAAW,CACX,WAAY,CALpB,2DAQQ,gCAAoC,CACpC,kBAAmB,CACtB,kCAID,iBAAkB,CAClB,WAAY,CACZ,eAAgB,CAChB,cAAe,CAClB,0CAGG,iBAAkB,CAClB,WAAY,CACZ,eAAgB,CAChB,cAAe","sourcesContent":[".pagePlanTitle {\n    margin-bottom: 30px;\n}\n\n.planCardsContainer {\n    margin-top: 7%;\n    flex-wrap: nowrap;\n}\n\n.planFunctionTableContainer {\n    margin: 12% 0;\n}\n\n.planCardsScroller {\n    overflow-x: scroll;\n\n    &::-webkit-scrollbar {\n        width: 10px;\n        height: 10px;\n    }\n    &::-webkit-scrollbar-thumb {\n        background-color: rgba(0, 0, 0, 0.2);\n        border-radius: 10px;\n    }\n}\n\n.cardRibbonPopular{\n    margin-right: 8.4%;\n    height: 29px;\n    padding-top: 5px;\n    font-size: 20px;\n}\n\n.cardRibbonPopularPersonal{\n    margin-right: 8.4%;\n    height: 29px;\n    padding-top: 5px;\n    font-size: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pagePlanTitle": `PlanPage-pagePlanTitle-JxD0n`,
	"planCardsContainer": `PlanPage-planCardsContainer-UkyYn`,
	"planFunctionTableContainer": `PlanPage-planFunctionTableContainer-LeaOG`,
	"planCardsScroller": `PlanPage-planCardsScroller-g3akT`,
	"cardRibbonPopular": `PlanPage-cardRibbonPopular-ldoCv`,
	"cardRibbonPopularPersonal": `PlanPage-cardRibbonPopularPersonal-UM8ws`
};
export default ___CSS_LOADER_EXPORT___;
