// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PBImageFormItem-avatarContainer-HlYN7{display:flex;align-items:center;justify-content:center;width:220px;aspect-ratio:2 / 1;background-color:#ccc}.PBImageFormItem-avatar-GghRi{width:100%;height:100%;object-fit:cover}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/personnel/PersonnelBoardPage/PersonnelBoardDetailModal/PersonnelBoardBaseInfoForm/PBImageFormItem/PBImageFormItem.scss"],"names":[],"mappings":"AAAA,uCACI,YAAa,CACb,kBAAmB,CACnB,sBAAuB,CACvB,WAAY,CACZ,kBAAmB,CACnB,qBAAsB,CACzB,8BAGG,UAAW,CACX,WAAY,CACZ,gBAAiB","sourcesContent":[".avatarContainer {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 220px;\n    aspect-ratio: 2 / 1;\n    background-color: #ccc;\n}\n\n.avatar {\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"avatarContainer": `PBImageFormItem-avatarContainer-HlYN7`,
	"avatar": `PBImageFormItem-avatar-GghRi`
};
export default ___CSS_LOADER_EXPORT___;
