// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TenantMyCompanyInfo-formField-K3Ng7{text-align:left;width:100%;margin:8px 0}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/TenantCreatePage/TenantMyCompanyInfo/TenantMyCompanyInfo.scss"],"names":[],"mappings":"AAAA,qCACI,eAAgB,CAChB,UAAW,CACX,YAAa","sourcesContent":[".formField {\n    text-align: left;\n    width: 100%;\n    margin: 8px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formField": `TenantMyCompanyInfo-formField-K3Ng7`
};
export default ___CSS_LOADER_EXPORT___;
