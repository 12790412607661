// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BoardBaseComponent-headerContainer-Yoc_k{text-align:left;padding-left:6px;max-width:252px}.BoardBaseComponent-headerContainer-Yoc_k .BoardBaseComponent-titleText-RMDxn{margin-bottom:0;font-size:14px;white-space:normal}.BoardBaseComponent-headerContainer-Yoc_k .BoardBaseComponent-headerButtons-SArWD{flex-shrink:0}.BoardBaseComponent-boardContainer-Sjx58 .react-kanban-column{border-radius:3px}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Common/BoardComponent/BoardBaseComponent/BoardBaseComponent.scss"],"names":[],"mappings":"AAAA,0CACI,eAAgB,CAChB,gBAAiB,CACjB,eAAgB,CAHpB,8EAKQ,eAAgB,CAChB,cAAe,CACf,kBAAmB,CAP3B,kFAUQ,aAAc,CACjB,8DAKG,iBAAkB","sourcesContent":[".headerContainer {\n    text-align: left;\n    padding-left: 6px;\n    max-width: 252px;\n    .titleText {\n        margin-bottom: 0;\n        font-size: 14px;\n        white-space: normal;\n    }\n    .headerButtons {\n        flex-shrink: 0;\n    }\n}\n\n.boardContainer {\n    :global(.react-kanban-column) {\n        border-radius: 3px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerContainer": `BoardBaseComponent-headerContainer-Yoc_k`,
	"titleText": `BoardBaseComponent-titleText-RMDxn`,
	"headerButtons": `BoardBaseComponent-headerButtons-SArWD`,
	"boardContainer": `BoardBaseComponent-boardContainer-Sjx58`
};
export default ___CSS_LOADER_EXPORT___;
