import React from "react";
import { Col, Form, Row, Tooltip } from "antd";
import { QuestionCircleFilled } from "@ant-design/icons";
import TextualNumberInput from "~/components/Common/TextualNumberInput";
import { Links, iconCustomColor } from "~/utils/constants";
import styles from "./CorporateNumberFormItem.scss";

const CorporateNumberFormItem = ({ disabled = false }) => {
    const fieldName = "corporate_number";

    return (
        <Col span={24} style={{ marginBottom: "1%" }}>
            <Row className={styles.container}>
                <Col span={23}>
                    <Form.Item
                        wrapperCol={{ span: 20 }}
                        colon={false}
                        name={fieldName}
                        noStyle>
                        <TextualNumberInput
                            className={styles.userInput}
                            placeholder="法人番号(ハイフンなし)"
                            allowClear
                            disabled={disabled}
                        />
                    </Form.Item>
                </Col>
                <Col span={1} className={styles.infoIcon}>
                    <Tooltip
                        title={
                            <span>
                                <a
                                    href={Links.helps.filter.partialMatch}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    部分一致検索
                                </a>
                            </span>
                        }>
                        <QuestionCircleFilled
                            style={{ color: iconCustomColor }}
                            className={styles.tooltip}
                        />
                    </Tooltip>
                </Col>
            </Row>
        </Col>
    );
};

export default CorporateNumberFormItem;
