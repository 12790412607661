// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DetailSettingsTabPaneContent-formItem-ayNl3{margin-bottom:0}.DetailSettingsTabPaneContent-conditionText-SindP{text-align:right;display:block}.DetailSettingsTabPaneContent-reminderItem-geYE0{margin-bottom:4px}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/ReminderSettingsPage/components/DetailSettingsTabPaneContent/DetailSettingsTabPaneContent.scss"],"names":[],"mappings":"AAAA,6CACI,eAAgB,CACnB,kDAGG,gBAAiB,CACjB,aAAc,CACjB,iDAGG,iBAAkB","sourcesContent":[".formItem {\n    margin-bottom: 0;\n}\n\n.conditionText {\n    text-align: right;\n    display: block;\n}\n\n.reminderItem {\n    margin-bottom: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formItem": `DetailSettingsTabPaneContent-formItem-ayNl3`,
	"conditionText": `DetailSettingsTabPaneContent-conditionText-SindP`,
	"reminderItem": `DetailSettingsTabPaneContent-reminderItem-geYE0`
};
export default ___CSS_LOADER_EXPORT___;
