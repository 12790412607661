import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Col, Form, Row, Transfer, Tooltip } from "antd";
import { finalized } from "../helpers";
import validateJapaneseMessages from "../validateMessages";
import styles from "./RequiredDisplaySettingForm.scss";
import BackButton from "~/components/Common/BackButton/BackButton";

const formItemWithLabelLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 22 },
    },
};

const require_default_message = "固定の必須値です。";

const organization_require_items = [
    {
        key: "name",
        title: <Tooltip title={require_default_message}>取引先名</Tooltip>,
        disabled: true,
    },
    {
        key: "category",
        title: (
            <Tooltip title={require_default_message}>取引先ステータス</Tooltip>
        ),
        disabled: true,
    },
    { key: "corporate_number", title: "法人番号" },
    { key: "score", title: "取引先評価" },
    { key: "country", title: "国籍" },
    { key: "establishment_date", title: "設立年月" },
    { key: "settlement_month", title: "決算期" },
    { key: "address", title: "住所" },
    { key: "tel", title: "TEL" },
    { key: "fax", title: "FAX" },
    { key: "domain_name", title: "URL" },
    { key: "employee_number", title: "社員数" },
    { key: "has_distribution", title: "商流" },
    { key: "contract", title: "請負" },
    { key: "capital_man_yen", title: "資本金" },
    { key: "document", title: "契約書類" },
    { key: "license", title: "保有資格" },
    { key: "tags", title: "タグ" },
];

const contact_require_items = [
    {
        key: "name",
        title: (
            <Tooltip title={require_default_message}>取引先担当者名</Tooltip>
        ),
        disabled: true,
    },
    {
        key: "organization",
        title: <Tooltip title={require_default_message}>所属取引先</Tooltip>,
        disabled: true,
    },
    {
        key: "email_to",
        title: (
            <Tooltip title={require_default_message}>
                メールアドレス(TO)
            </Tooltip>
        ),
        disabled: true,
    },
    { key: "email_cc", title: "メールアドレス(CC)" },
    { key: "tel", title: "TEL" },
    { key: "position", title: "役職" },
    { key: "department", title: "部署" },
    { key: "staff", title: "自社担当者" },
    { key: "last_visit", title: "最終商談日" },
    { key: "tag", title: "タグ" },
    { key: "category", title: "相性" },
];

const organization_default_require_item_keys = ["name", "category"];
const contact_default_require_item_keys = ["name", "organization", "email_to"];

const RequiredDisplaySettingForm = ({
    initialData,
    submitHandler,
    resourceURL,
    fieldErrors,
}) => {
    const [form] = Form.useForm();
    const [targetKeys, setTargetKeys] = useState({
        organizations: {
            require: [],
        },
        contacts: { require: [], page_size: 10 },
    });
    const [selectedKeys, setSelectedKeys] = useState({
        organizations: {
            require: [],
            page_size: 10,
        },
        contacts: { require: [], page_size: 10 },
    });

    useEffect(() => {
        if (initialData && initialData.content_hash) {
            if (initialData.content_hash["organizations"]) {
                setTargetKeys(getHashWithDefault(initialData.content_hash));

                const { setFieldsValue } = form;
                if (
                    initialData.content_hash["organizations"] &&
                    initialData.content_hash["organizations"]["page_size"]
                )
                    setFieldsValue({
                        organizations_page_size:
                            initialData.content_hash["organizations"][
                                "page_size"
                            ],
                    });
                if (
                    initialData.content_hash["contacts"] &&
                    initialData.content_hash["contacts"]["page_size"]
                )
                    setFieldsValue({
                        contacts_page_size:
                            initialData.content_hash["contacts"]["page_size"],
                    });
            }
        }
    });

    const handleSubmitWithCheck = (_) => {
        form.validateFields().then((values) => {
            values["content"] = getHashWithoutDefault(targetKeys);
            submitHandler(values);
        });
    };

    const getHashWithDefault = (content_hash) => {
        content_hash["organizations"]["require"] =
            organization_default_require_item_keys.concat(
                content_hash["organizations"]["require"]
            );
        content_hash["contacts"]["require"] =
            contact_default_require_item_keys.concat(
                content_hash["contacts"]["require"]
            );
        return content_hash;
    };

    const getHashWithoutDefault = (content_hash) => {
        content_hash["organizations"]["require"] = content_hash[
            "organizations"
        ]["require"].filter(
            (item) => !organization_default_require_item_keys.includes(item)
        );
        content_hash["contacts"]["require"] = content_hash["contacts"][
            "require"
        ].filter((item) => !contact_default_require_item_keys.includes(item));
        return content_hash;
    };

    const organizationRequireHandleChange = (
        nextTargetKeys,
        direction,
        moveKeys
    ) => {
        let target_keys = targetKeys;
        const nextTargetKeysWithoutDefault = nextTargetKeys.filter(
            (item) => !organization_default_require_item_keys.includes(item)
        ); // デフォルト項目を削除
        target_keys["organizations"]["require"] =
            organization_default_require_item_keys.concat(
                nextTargetKeysWithoutDefault
            ); // デフォルトは常に先頭表示したいので先頭に詰め直す
        setTargetKeys(target_keys);
    };

    const organizationRequireHandleSelectChange = (
        sourceSelectedKeys,
        targetSelectedKeys
    ) => {
        let selected_keys = { ...selectedKeys };
        selected_keys["organizations"]["require"] = [
            ...sourceSelectedKeys,
            ...targetSelectedKeys,
        ];
        setSelectedKeys(selected_keys);
    };

    const contactRequireHandleChange = (
        nextTargetKeys,
        direction,
        moveKeys
    ) => {
        let target_keys = targetKeys;
        const nextTargetKeysWithoutDefault = nextTargetKeys.filter(
            (item) => !contact_default_require_item_keys.includes(item)
        ); // デフォルト項目を削除
        target_keys["contacts"]["require"] =
            contact_default_require_item_keys.concat(
                nextTargetKeysWithoutDefault
            ); // デフォルトは常に先頭表示したいので先頭に詰め直す
        setTargetKeys(target_keys);
    };

    const contactRequireHandleSelectChange = (
        sourceSelectedKeys,
        targetSelectedKeys
    ) => {
        let selected_keys = { ...selectedKeys };
        selected_keys["contacts"]["require"] = [
            ...sourceSelectedKeys,
            ...targetSelectedKeys,
        ];
        setSelectedKeys(selected_keys);
    };

    return (
        <Form
            form={form}
            onFinish={handleSubmitWithCheck}
            className={styles.container}
            validateMessages={validateJapaneseMessages}>
            <Form.Item
                {...formItemWithLabelLayout}
                label="取引先情報"
                className={styles.field}
                name="organization_require_items"
                colon={false}>
                <Transfer
                    dataSource={organization_require_items}
                    titles={["任意", "必須"]}
                    render={(item) => item.title}
                    targetKeys={targetKeys["organizations"]["require"]}
                    selectedKeys={selectedKeys["organizations"]["require"]}
                    onChange={organizationRequireHandleChange}
                    onSelectChange={organizationRequireHandleSelectChange}
                />
            </Form.Item>
            <Form.Item
                {...formItemWithLabelLayout}
                label="取引先担当者情報"
                className={styles.field}
                name="contact_require_items"
                colon={false}>
                <Transfer
                    dataSource={contact_require_items}
                    titles={["任意", "必須"]}
                    render={(item) => item.title}
                    targetKeys={targetKeys["contacts"]["require"]}
                    selectedKeys={selectedKeys["contacts"]["require"]}
                    onChange={contactRequireHandleChange}
                    onSelectChange={contactRequireHandleSelectChange}
                />
            </Form.Item>
            <Col span={24} style={{ marginTop: "5%" }}>
                <Row>
                    <BackButton />
                    <Button
                        type="primary"
                        htmlType="submit"
                        className={styles.button}>
                        更新
                    </Button>
                </Row>
            </Col>
        </Form>
    );
};

RequiredDisplaySettingForm.propTypes = {
    resourceURL: PropTypes.string.isRequired,
    initialData: PropTypes.shape({
        // Corresponds to backend API.
        content_hash: PropTypes.object,
    }), // Override in child class and use PropTypes.shape instead.
    fieldErrors: PropTypes.shape({
        content: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    submitHandler: PropTypes.func.isRequired,
};

RequiredDisplaySettingForm.defaultProps = {
    initialData: {},
};

const RequiredDisplaySettingFormWrapper = finalized(RequiredDisplaySettingForm);
export default RequiredDisplaySettingFormWrapper;
