import React, { useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { Button, Row, Col, Form, Drawer, Typography, Tooltip } from "antd";
import {
    MinusOutlined,
    PlusOutlined,
    CloseOutlined,
    SettingOutlined,
    UpOutlined,
    DownOutlined,
    ReloadOutlined,
} from "@ant-design/icons";
import { TooltipMessages } from "~/utils/constants";
import validateJapaneseMessages from "~/components/Forms/validateMessages";
import styles from "./SearchMenuDrawer.scss";

const { Title } = Typography;

const iconStyle = {
    alignSelf: "center",
};

const SearchMenuDrawer = ({
    isDrawerOpen,
    form,
    emptyForm = undefined,
    menuItems,
    unshowList,
    onReset,
    onUpdateUnshowList,
    otherControls,
    onFilter,
    searchTemplate,
    onDrawerClose,
    onFieldAdd,
    onFieldRemove,
    setInitialFormSearch,
}) => {
    const WARNING_MESSAGE = "検索対象は、必ず1つ保持する必要があります。";

    const [isEditSearchField, setIsEditSearchField] = useState(false);
    const [isUnshowSectionOpen, setIsUnshowSectionOpen] = useState(false);
    const [isDisabledClearButton, setIsDisabledClearButton] = useState(false);

    const onFilterSubmit = useDebouncedCallback((values) => {
        onFilter(values);
        setIsDisabledClearButton(false)
    },600);

    const onAddSearchField = (fieldKey) => {
        const newFields = unshowList.filter((field) => field !== fieldKey);
        onFieldAdd(newFields, fieldKey);
    };

    const onRemoveSearchField = (fieldKey, fieldNames) => {
        // Fix field name of ScheduledEmailSentDateFormItem
        // Avoid affecting the pull request: https://github.com/h-basis/umail/pull/1900
        const fieldNamesModified = String(fieldNames) === "send_date" ? ["sent_date"] : fieldNames
        const newFields = [...unshowList, fieldKey];
        onFieldRemove(newFields, fieldNamesModified);
    };

    const onClose = () => {
        onDrawerClose();
    };

    const onUpdateSearchField = () => {
        onUpdateUnshowList();
        setIsEditSearchField(false);
    };

    const renderEditSearchFieldButton = () => {
        return (
            <Col span={24}>
                {isEditSearchField ? (
                    <Button
                        type="default"
                        icon={<CloseOutlined />}
                        className={styles.buttonDefault}
                        onClick={() => {
                            setIsEditSearchField(false);
                            setInitialFormSearch();
                            setIsUnshowSectionOpen(false);
                        }}
                        data-testid="search-menu-drawer-edit-mode-close-button"
                    />
                ) : (
                    <Button
                        type="default"
                        icon={<SettingOutlined />}
                        onClick={() => {
                            setIsEditSearchField(true);
                            setIsUnshowSectionOpen(true);
                        }}
                        data-testid="search-menu-drawer-edit-mode-open-button"
                        className={styles.buttonDefault}
                    />
                )}
            </Col>
        );
    };

    const renderShowingSearchFields = () => {
        return menuItems
            .filter((item) => !unshowList.includes(item.fieldKey))
            .map((item) => (
                <Col span={24} key={item.fieldKey}>
                    <Row align="middle">
                        <Col span={isEditSearchField ? 23 : 24}>
                            {item.onClear ? (
                                <item.searchField
                                    onClear={item.onClear}
                                    isEditSearchField={isEditSearchField}
                                    onUpdateFormValue={item.onUpdateFormValue}
                                />
                            ) : (
                                <item.searchField
                                    isEditSearchField={isEditSearchField}
                                />
                            )}
                        </Col>
                        {isEditSearchField ? (
                            <Col span={1}>
                                <Button
                                    type="primary"
                                    danger
                                    icon={<MinusOutlined />}
                                    onClick={() =>
                                        onRemoveSearchField(
                                            item.fieldKey,
                                            item.fieldNames
                                        )
                                    }></Button>
                            </Col>
                        ) : undefined}
                    </Row>
                </Col>
            ));
    };

    const renderHiddenSearchFields = () => {
        return menuItems
            .filter((item) => unshowList.includes(item.fieldKey))
            .map((item) => (
                <Col span={24} key={item.fieldKey}>
                    <Row>
                        <Col span={isEditSearchField ? 23 : 24}>
                            {item.onClear ? (
                                <item.searchField
                                    onClear={item.onClear}
                                    disabled
                                    isEditSearchField={isEditSearchField}
                                />
                            ) : (
                                <item.searchField
                                    disabled
                                    isEditSearchField={isEditSearchField}
                                />
                            )}
                        </Col>
                        {isEditSearchField ? (
                            <Col span={1}>
                                <Button
                                    type="primary"
                                    icon={<PlusOutlined />}
                                    onClick={() =>
                                        onAddSearchField(item.fieldKey)
                                    }></Button>
                            </Col>
                        ) : undefined}
                    </Row>
                </Col>
            ));
    };

    const renderUpdateCancelButton = () => {
        return (
            <Button
                className={styles.buttonDefault}
                onClick={() => {
                    setIsEditSearchField(false);
                    setInitialFormSearch();
                }}>
                キャンセル
            </Button>
        );
    };

    const renderDrawerCloseButton = () => {
        return (
            <Button
                className={styles.buttonDefault}
                onClick={() => {
                    setInitialFormSearch();
                    onDrawerClose();
                }}>
                キャンセル
            </Button>
        );
    };

    const renderUpdateButton = () => {
        const fieldNames = menuItems.map((menuItem) => menuItem.fieldKey);
        const showList = fieldNames.filter(
            (name) => !unshowList.includes(name)
        );
        const cannotUpdate = !showList.length;
        const button = (
            <Button
                onClick={onUpdateSearchField}
                type="primary"
                style={{
                    marginLeft: 5,
                }}
                disabled={cannotUpdate}>
                検索対象を更新
            </Button>
        );
        if (cannotUpdate) {
            return <Tooltip title={WARNING_MESSAGE}>{button}</Tooltip>;
        }
        return button;
    };

    const renderClearButton = () => {
        return (
            <Tooltip title={TooltipMessages.searchTemplate.clear}>
                <Button
                    className={styles.clearButton}
                    icon={<ReloadOutlined data-testid="clear-search-template-button-icon"/>}
                    onClick={onReset}
                    disabled={isDisabledClearButton}
                    type="default"
                />
            </Tooltip>
        );
    };

    const renderDrawerFooter = () => {
        const cancelButton = isEditSearchField
            ? renderUpdateCancelButton()
            : renderDrawerCloseButton();
        const actionButton = isEditSearchField
            ? renderUpdateButton()
            : undefined;
        return (
            <Col span={24}>
                <Row justify="space-between">
                    <Col>{cancelButton}</Col>
                    <Col>{actionButton}</Col>
                </Row>
            </Col>
        );
    };

    return (
        <Drawer
            placement="right"
            onClose={onClose}
            visible={isDrawerOpen}
            forceRender
            width={540}
            closable={false}
            footer={renderDrawerFooter()}>
            <Row style={{ marginBottom: 25 }}>
                <Col span={23}>{searchTemplate}</Col>
                <Col span={1} style={iconStyle}>
                    {renderEditSearchFieldButton()}
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form
                        form={form}
                        onValuesChange={(changedValues, allValues) => {
                            if (Object.prototype.hasOwnProperty.call(changedValues, "inactive_filter")) {
                                setIsDisabledClearButton(true);
                            }
                            onFilterSubmit(allValues);
                        }}
                        validateMessages={validateJapaneseMessages}>
                        <Row>
                            <Col span={24}>
                                <Row justify="space-between">
                                    <Title level={4}>検索対象</Title>
                                    {renderClearButton()}
                                </Row>
                                <Row gutter={6}>
                                    {otherControls.map(
                                        (otherControl, index) => {
                                            return (
                                                <Col
                                                    key={index}
                                                    span={
                                                        isEditSearchField
                                                            ? 23
                                                            : 24
                                                    }
                                                    style={{
                                                        marginBottom: 10,
                                                    }}>
                                                    {otherControl}
                                                </Col>
                                            );
                                        }
                                    )}
                                    {renderShowingSearchFields()}
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <Row
                style={{
                    marginTop: 24,
                }}>
                <Col span={24}>
                    <Row>
                        <Col span={23}>
                            <Row justify="start">
                                <Title level={4}>検索対象外</Title>
                            </Row>
                        </Col>
                        <Col span={1}>
                            {isUnshowSectionOpen ? (
                                <Button
                                    data-testid="close-button"
                                    type="text"
                                    icon={<DownOutlined />}
                                    onClick={() =>
                                        setIsUnshowSectionOpen(false)
                                    }></Button>
                            ) : (
                                <Button
                                    data-testid="open-button"
                                    type="text"
                                    icon={<UpOutlined />}
                                    onClick={() =>
                                        setIsUnshowSectionOpen(true)
                                    }></Button>
                            )}
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row hidden={!isUnshowSectionOpen}>
                <Col span={24}>
                    <Form form={emptyForm}>
                        <Row>
                            <Col span={24}>
                                <Row gutter={6}>
                                    {renderHiddenSearchFields()}
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Drawer>
    );
};

export default SearchMenuDrawer;
