"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var api_1 = require("~/domain/api");
var userDisplaySetting = function (client) {
    return {
        fetch: function () {
            var url = api_1.Endpoint.userDisplaySetting;
            return client.get(url);
        },
        update: function (postData) {
            var url = api_1.Endpoint.userDisplaySetting;
            return client.patch(url, postData);
        },
    };
};
exports.default = userDisplaySetting;
