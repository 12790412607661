"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDisplaySettingAPI = void 0;
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var data_1 = require("~/actions/data");
var pageIds_1 = require("~/components/Pages/pageIds");
var api_1 = require("~/domain/api");
var useDisplaySettingAPI = function () {
    var pageId = pageIds_1.DISPLAY_SETTING_PAGE;
    var resourceURL = "".concat(api_1.Endpoint.getBaseUrl(), "/").concat(api_1.Endpoint.userDisplaySetting);
    var resourceId = "";
    var token = (0, react_redux_1.useSelector)(function (state) { return state.login.token; });
    var dispatch = (0, react_redux_1.useDispatch)();
    var fetchDisplaySetting = function () {
        dispatch((0, data_1.fetchDisplaySettingAction)(pageId, token, resourceURL));
    };
    var convertResponseDataEntry = function (data) {
        // do not conversion
        return data;
    };
    var convertFormDataToAPI = function (data) {
        // do not conversion
        return data;
    };
    var updateDisplaySetting = function (values) {
        dispatch((0, data_1.displaySettingUpdateAction)(pageId, token, resourceURL, resourceId, convertFormDataToAPI(values), convertResponseDataEntry));
    };
    (0, react_1.useEffect)(function () {
        fetchDisplaySetting();
    }, []);
    return {
        fetchDisplaySetting: fetchDisplaySetting,
        updateDisplaySetting: updateDisplaySetting,
    };
};
exports.useDisplaySettingAPI = useDisplaySettingAPI;
