// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MenuBoardSettingsComponent-container-bwSIS{width:380px;cursor:"pointer"}.MenuBoardSettingsComponent-firstMessage-hUNlb{margin-bottom:2px}.MenuBoardSettingsComponent-colorFocus-wGlc_:focus{border-color:#A7CF69;background-color:#A7CF69}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Common/BoardComponent/BoardBaseComponent/MenuBoardSettingsComponent/MenuBoardSettingsComponent.scss"],"names":[],"mappings":"AAAA,4CACE,WAAY,CACZ,gBAAiB,CAClB,+CAEC,iBAAkB,CACnB,mDAGG,oBAAqB,CACrB,wBAAyB","sourcesContent":[".container{\n  width: 380px;\n  cursor: \"pointer\",\n}\n.firstMessage {\n  margin-bottom: 2px;\n}\n.colorFocus{\n  &:focus{\n    border-color: #A7CF69;\n    background-color: #A7CF69;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `MenuBoardSettingsComponent-container-bwSIS`,
	"firstMessage": `MenuBoardSettingsComponent-firstMessage-hUNlb`,
	"colorFocus": `MenuBoardSettingsComponent-colorFocus-wGlc_`
};
export default ___CSS_LOADER_EXPORT___;
