// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UsersTable-paginator-d8bfD{margin:16px auto}.UsersTable-tableWrapper-ErWGA{text-align:left}.UsersTable-table-skgT0{margin:16px auto}.UsersTable-ignored-JsTTL{color:silver}.UsersTable-genericTable-Mm7nO{word-break:break-all}.UsersTable-newLine-MbX_P{white-space:pre-wrap}.UsersTable-container-a1v6U{margin-top:4%}.UsersTable-tableSpacer-VlPLs{margin:7px 0}.UsersTable-tooltipCopy-wQ5ki:hover span{display:block}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Tables/UsersTable/UsersTable.scss"],"names":[],"mappings":"AAAA,4BACI,gBAAiB,CACpB,+BAGG,eAAgB,CACnB,wBAGG,gBAAiB,CACpB,0BAGG,YAAa,CAChB,+BAGG,oBAAqB,CACxB,0BAGG,oBAAqB,CACxB,4BAGG,aAAc,CACjB,8BAGG,YAAa,CAChB,yCAKW,aAAc","sourcesContent":[".paginator {\n    margin: 16px auto;\n}\n\n.tableWrapper {\n    text-align: left;\n}\n\n.table {\n    margin: 16px auto;\n}\n\n.ignored {\n    color: silver;\n}\n\n.genericTable {\n    word-break: break-all;\n}\n\n.newLine {\n    white-space: pre-wrap;\n}\n\n.container {\n    margin-top: 4%;\n}\n\n.tableSpacer {\n    margin: 7px 0;\n}\n\n.tooltipCopy {\n    &:hover {\n        span {\n            display: block;\n        }\n    }\n}\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paginator": `UsersTable-paginator-d8bfD`,
	"tableWrapper": `UsersTable-tableWrapper-ErWGA`,
	"table": `UsersTable-table-skgT0`,
	"ignored": `UsersTable-ignored-JsTTL`,
	"genericTable": `UsersTable-genericTable-Mm7nO`,
	"newLine": `UsersTable-newLine-MbX_P`,
	"container": `UsersTable-container-a1v6U`,
	"tableSpacer": `UsersTable-tableSpacer-VlPLs`,
	"tooltipCopy": `UsersTable-tooltipCopy-wQ5ki`
};
export default ___CSS_LOADER_EXPORT___;
