// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SharedEmailNotificationsTable-assigneeTab-wqUmU{max-width:220px;margin-top:5px}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Tables/SharedEmailNotificationsTable/SharedEmailNotificationsTable.scss"],"names":[],"mappings":"AAAA,iDACE,eAAgB,CAChB,cAAe","sourcesContent":[".assigneeTab {\n  max-width: 220px;\n  margin-top: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"assigneeTab": `SharedEmailNotificationsTable-assigneeTab-wqUmU`
};
export default ___CSS_LOADER_EXPORT___;
