// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomTimePicker-popup-PZa1Z{width:150px}.CustomTimePicker-popup-PZa1Z .ant-picker-panel{width:100%}.CustomTimePicker-popup-PZa1Z .ant-picker-footer-extra{border-bottom:none}.CustomTimePicker-cancelButton-hVOjZ{position:absolute;height:42px;display:flex;align-items:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Common/CustomTimePicker/CustomTimePicker.scss"],"names":[],"mappings":"AAAA,8BACI,WAAY,CADhB,gDAIQ,UAAW,CAJnB,uDAQQ,kBAAmB,CACtB,qCAID,iBAAkB,CAClB,WAAY,CACZ,YAAa,CACb,kBAAmB","sourcesContent":[".popup {\n    width: 150px;\n\n    :global(.ant-picker-panel) {\n        width: 100%;\n    }\n\n    :global(.ant-picker-footer-extra) {\n        border-bottom: none;\n    }\n}\n\n.cancelButton {\n    position: absolute;\n    height: 42px;\n    display: flex;\n    align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popup": `CustomTimePicker-popup-PZa1Z`,
	"cancelButton": `CustomTimePicker-cancelButton-hVOjZ`
};
export default ___CSS_LOADER_EXPORT___;
