// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProjectDetailFormItem-container-qX0m2{width:100%;text-align:left}.ProjectDetailFormItem-userInput-xJ8Ds{width:100%}.ProjectDetailFormItem-tooltip-xvsnW{margin-left:5px}.ProjectDetailFormItem-infoIcon-PBcOG{align-self:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/project/ProjectBoardPage/ProjectBoardSearchDrawer/ProjectDetailFormItem/ProjectDetailFormItem.scss"],"names":[],"mappings":"AAAA,uCACI,UAAW,CACX,eAAgB,CACnB,uCAGG,UAAW,CACd,qCAGG,eAAgB,CACnB,sCAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ProjectDetailFormItem-container-qX0m2`,
	"userInput": `ProjectDetailFormItem-userInput-xJ8Ds`,
	"tooltip": `ProjectDetailFormItem-tooltip-xvsnW`,
	"infoIcon": `ProjectDetailFormItem-infoIcon-PBcOG`
};
export default ___CSS_LOADER_EXPORT___;
