// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BoardBaseCardAssignees-assignees-sJOPC{margin-top:8px;width:100%}.BoardBaseCardAssignees-assignees-sJOPC .ant-avatar{margin-left:0 !important;margin-right:5px}.BoardBaseCardAssignees-assigneeGroup-qVe8E{display:flex;flex-wrap:wrap}.BoardBaseCardAssignees-assigneeGroup-qVe8E .ant-avatar-group{width:auto !important;flex-wrap:wrap !important}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Common/Board/BoardBaseCard/BoardBaseCardAssignees/BoardBaseCardAssignees.scss"],"names":[],"mappings":"AAAA,wCACI,cAAe,CACf,UAAW,CAFf,oDAIQ,wBAAyB,CACzB,gBAAiB,CACpB,4CAID,YAAa,CACb,cAAe,CAFnB,8DAIQ,qBAAsB,CACtB,yBAA0B","sourcesContent":[".assignees {\n    margin-top: 8px;\n    width: 100%;\n    :global(.ant-avatar) {\n        margin-left: 0 !important;\n        margin-right: 5px;\n    }\n}\n\n.assigneeGroup {\n    display: flex;\n    flex-wrap: wrap;\n    :global(.ant-avatar-group){\n        width: auto !important;\n        flex-wrap: wrap !important;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"assignees": `BoardBaseCardAssignees-assignees-sJOPC`,
	"assigneeGroup": `BoardBaseCardAssignees-assigneeGroup-qVe8E`
};
export default ___CSS_LOADER_EXPORT___;
