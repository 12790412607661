// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ScheduledMailsTable-paginator-J7cbX{margin:16px auto}.ScheduledMailsTable-tableWrapper-tuu1c{text-align:left}.ScheduledMailsTable-table-hyfbA{margin:16px auto}.ScheduledMailsTable-ignored-ogTzR{color:silver}.ScheduledMailsTable-genericTable-hmjuF{word-break:break-all}.ScheduledMailsTable-newLine-zL1Ng{white-space:pre-wrap}.ScheduledMailsTable-container-fwn5N{margin-top:4%}.ScheduledMailsTable-tableSpacer-Q9g5J{margin:7px 0}.ScheduledMailsTable-tooltipCopy-i5vlD:hover span{display:initial}.ScheduledMailsTable-openCount-Smfn7{width:100%;height:100%;padding:16px}td.ScheduledMailsTable-openCountFormat-MnsGa{padding:0px !important}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Tables/Table.scss","webpack://./frontend/src/components/Tables/ScheduledMailsTable/ScheduledMailsTable.scss"],"names":[],"mappings":"AAAA,qCACE,gBAAiB,CAClB,wCAGC,eAAgB,CACjB,iCAGC,gBAAiB,CAClB,mCAGC,YAAa,CACd,wCAGC,oBAAqB,CACtB,mCAGC,oBAAqB,CACtB,qCAGC,aAAc,CACf,uCAGC,YAAa,CACd,kDAKO,eAAgB,CCjCxB,qCACI,UAAW,CACX,WAAY,CACZ,YAAa,CAChB,6CAGG,sBAAuB","sourcesContent":[".paginator {\n  margin: 16px auto;\n}\n\n.tableWrapper {\n  text-align: left;\n}\n\n.table {\n  margin: 16px auto;\n}\n\n.ignored {\n  color: silver;\n}\n\n.genericTable {\n  word-break: break-all;\n}\n\n.newLine {\n  white-space: pre-wrap;\n}\n\n.container {\n  margin-top: 4%;\n}\n\n.tableSpacer {\n  margin: 7px 0;\n}\n\n.tooltipCopy {\n  &:hover {\n      span {\n        display: initial;\n      }\n  }\n}\n","@import \"../Table\";\n\n.openCount {\n    width: 100%;\n    height: 100%;\n    padding: 16px;\n}\n\ntd.openCountFormat {\n    padding: 0px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paginator": `ScheduledMailsTable-paginator-J7cbX`,
	"tableWrapper": `ScheduledMailsTable-tableWrapper-tuu1c`,
	"table": `ScheduledMailsTable-table-hyfbA`,
	"ignored": `ScheduledMailsTable-ignored-ogTzR`,
	"genericTable": `ScheduledMailsTable-genericTable-hmjuF`,
	"newLine": `ScheduledMailsTable-newLine-zL1Ng`,
	"container": `ScheduledMailsTable-container-fwn5N`,
	"tableSpacer": `ScheduledMailsTable-tableSpacer-Q9g5J`,
	"tooltipCopy": `ScheduledMailsTable-tooltipCopy-i5vlD`,
	"openCount": `ScheduledMailsTable-openCount-Smfn7`,
	"openCountFormat": `ScheduledMailsTable-openCountFormat-MnsGa`
};
export default ___CSS_LOADER_EXPORT___;
