"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useIntercom = void 0;
var constants_1 = require("~/utils/constants");
var useIntercom = function () {
    var bootupIntercom = function () {
        window.Intercom("boot", {
            app_id: constants_1.intercomAppId,
        });
    };
    var setupIntercom = function (_a) {
        var userId = _a.userId, displayName = _a.displayName, intercomUserHash = _a.intercomUserHash;
        window.Intercom("boot", {
            app_id: constants_1.intercomAppId,
            name: displayName,
            user_id: userId,
            user_hash: intercomUserHash,
        });
    };
    var shutdownIntercom = function () {
        document.cookie = "intercom-session-".concat(constants_1.intercomAppId, "=;");
        document.cookie = "intercom-id-".concat(constants_1.intercomAppId, "=;");
        window.Intercom("shutdown");
        window.Intercom("boot", {
            app_id: constants_1.intercomAppId,
        });
    };
    return {
        bootupIntercom: bootupIntercom,
        setupIntercom: setupIntercom,
        shutdownIntercom: shutdownIntercom,
    };
};
exports.useIntercom = useIntercom;
