// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HorizontalScroller-container-yJLkH{overflow-x:auto;width:100%}.HorizontalScroller-content-hAA30{overflow:hidden}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Common/HorizontalScroller/HorizontalScroller.scss"],"names":[],"mappings":"AAAA,oCACI,eAAgB,CAChB,UAAW,CACd,kCAGG,eAAgB","sourcesContent":[".container {\n    overflow-x: auto;\n    width: 100%;\n}\n\n.content {\n    overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `HorizontalScroller-container-yJLkH`,
	"content": `HorizontalScroller-content-hAA30`
};
export default ___CSS_LOADER_EXPORT___;
