"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DisplaySettingActions = void 0;
var actionTypes_1 = require("~/actions/actionTypes");
var pageIds_1 = require("~/components/Pages/pageIds");
exports.DisplaySettingActions = {
    loadedAction: function (payload) {
        return {
            type: pageIds_1.DISPLAY_SETTING_PAGE + actionTypes_1.DISPLAY_SETTING_LOADED,
            payload: payload,
        };
    },
};
