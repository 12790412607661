// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CorporateBranchTelFormItem-container-ijzO8{width:100%;text-align:left}.CorporateBranchTelFormItem-phonenumberInput-iL55E{width:100%}.CorporateBranchTelFormItem-tooltip-qdWWP{margin-left:5px}.CorporateBranchTelFormItem-inlineField-hlYny{display:inline-block;_display:inline}.CorporateBranchTelFormItem-infoIcon-UF90y{align-self:center}.CorporateBranchTelFormItem-separater-B6Lvp{align-self:center;text-align:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/OrganizationSearchPage/OrganizationSearchDrawer/CorporateBranchTelFormItem/CorporateBranchTelFormItem.scss"],"names":[],"mappings":"AAAA,4CACI,UAAW,CACX,eAAgB,CACnB,mDAGG,UAAW,CACd,0CAGG,eAAgB,CACnB,8CAGG,oBAAqB,EACrB,cAAgB,CACnB,2CAGG,iBAAkB,CACrB,4CAGG,iBAAkB,CAClB,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.phonenumberInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.inlineField {\n    display: inline-block;\n    _display: inline;\n}\n\n.infoIcon {\n    align-self: center;\n}\n\n.separater {\n    align-self: center;\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `CorporateBranchTelFormItem-container-ijzO8`,
	"phonenumberInput": `CorporateBranchTelFormItem-phonenumberInput-iL55E`,
	"tooltip": `CorporateBranchTelFormItem-tooltip-qdWWP`,
	"inlineField": `CorporateBranchTelFormItem-inlineField-hlYny`,
	"infoIcon": `CorporateBranchTelFormItem-infoIcon-UF90y`,
	"separater": `CorporateBranchTelFormItem-separater-B6Lvp`
};
export default ___CSS_LOADER_EXPORT___;
