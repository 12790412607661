import {
    ERROR,
    LOADING,
    LOADED,
    CLEAR,
    LOGOUT,
} from "../../actions/actionTypes";

export const defaultInitialState = {
    loading: false,
    errorMessage: "",
    data: undefined,
};

const createReadOnlyPageReducer = (
    pageId,
    InitialState = defaultInitialState
) => {
    const Reducer = (state = InitialState, action) => {
        switch (action.type) {
            case pageId + LOADING:
                return Object.assign({}, state, {
                    loading: true,
                    message: "",
                    errorMessage: "",
                });
            case pageId + LOADED:
                return Object.assign({}, state, {
                    loading: false,
                    errorMessage: "",
                    data: action.payload.data,
                });
            case pageId + ERROR:
                return Object.assign({}, state, {
                    loading: false,
                    message: "",
                    errorMessage: action.payload.error.detail,
                });
            case pageId + CLEAR:
                return Object.assign({}, state, InitialState);
            case LOGOUT:
                return Object.assign({}, state, defaultInitialState);
            default:
                return state;
        }
    };
    return Reducer;
};

export default createReadOnlyPageReducer;
