// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContactTelFormItem-container-Yr900{width:100%;text-align:left}.ContactTelFormItem-phonenumberInput-KKwc5{width:100%}.ContactTelFormItem-tooltip-XpQgT{margin-left:5px}.ContactTelFormItem-inlineField-oEqof{display:inline-block;_display:inline}.ContactTelFormItem-infoIcon-nRqqy{align-self:center}.ContactTelFormItem-separater-YIaxn{align-self:center;text-align:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/ContactSearchPage/ContactSearchDrawer/ContactTelFormItem/ContactTelFormItem.scss"],"names":[],"mappings":"AAAA,oCACI,UAAW,CACX,eAAgB,CACnB,2CAGG,UAAW,CACd,kCAGG,eAAgB,CACnB,sCAGG,oBAAqB,EACrB,cAAgB,CACnB,mCAGG,iBAAkB,CACrB,oCAGG,iBAAkB,CAClB,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.phonenumberInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.inlineField {\n    display: inline-block;\n    _display: inline;\n}\n\n.infoIcon {\n    align-self: center;\n}\n\n.separater {\n    align-self: center;\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ContactTelFormItem-container-Yr900`,
	"phonenumberInput": `ContactTelFormItem-phonenumberInput-KKwc5`,
	"tooltip": `ContactTelFormItem-tooltip-XpQgT`,
	"inlineField": `ContactTelFormItem-inlineField-oEqof`,
	"infoIcon": `ContactTelFormItem-infoIcon-nRqqy`,
	"separater": `ContactTelFormItem-separater-YIaxn`
};
export default ___CSS_LOADER_EXPORT___;
