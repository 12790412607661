// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PersonnelSkillSheetFormItem-container-L5mRd{width:100%;text-align:left}.PersonnelSkillSheetFormItem-userInput-SYK7C{width:100%}.PersonnelSkillSheetFormItem-tooltip-xOXwp{margin-left:5px}.PersonnelSkillSheetFormItem-infoIcon-iJBnw{align-self:center}.PersonnelSkillSheetFormItem-select_option-QP6xh{text-align:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/personnel/PersonnelBoardPage/PersonnelBoardSearchDrawer/PersonnelSkillSheetFormItem/PersonnelSkillSheetFormItem.scss"],"names":[],"mappings":"AAAA,6CACI,UAAW,CACX,eAAgB,CACnB,6CAGG,UAAW,CACd,2CAGG,eAAgB,CACnB,4CAGG,iBAAkB,CACrB,iDAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n\n.select_option {\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PersonnelSkillSheetFormItem-container-L5mRd`,
	"userInput": `PersonnelSkillSheetFormItem-userInput-SYK7C`,
	"tooltip": `PersonnelSkillSheetFormItem-tooltip-xOXwp`,
	"infoIcon": `PersonnelSkillSheetFormItem-infoIcon-iJBnw`,
	"select_option": `PersonnelSkillSheetFormItem-select_option-QP6xh`
};
export default ___CSS_LOADER_EXPORT___;
