"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useActionNotificationSettingsUpdateAPIMutation = exports.useActionNotificationSettingsFetchAPIQuery = void 0;
var AlertMessage_1 = require("~/components/Common/AlertMessage/AlertMessage");
var useAuthorizedQuery_1 = require("~/hooks/useAuthorizedQuery");
var useCustomMutation_1 = require("~/hooks/useCustomMutation");
var constants_1 = require("~/utils/constants");
var utils_1 = require("~/utils/utils");
var networking_1 = require("../networking");
var converters_1 = require("../utils/converters");
var useActionNotificationSettingsFetchAPIQuery = function (_a) {
    var _b = _a === void 0 ? {} : _a, deps = _b.deps, options = _b.options;
    return (0, useAuthorizedQuery_1.useAuthorizedQuery)({
        queryKey: constants_1.QueryKeys.actionNotification.settings,
        deps: deps,
        options: __assign(__assign({}, options), { select: function (result) {
                return (0, converters_1.convertContentModelToFormModel)(result.action_settings);
            } }),
        apiRequest: networking_1.actionNotificationSettingsAPI.fetch,
    });
};
exports.useActionNotificationSettingsFetchAPIQuery = useActionNotificationSettingsFetchAPIQuery;
var useActionNotificationSettingsUpdateAPIMutation = function () {
    var apiRequest = function (postData) {
        var converted = (0, converters_1.convertFormModelToContentModel)(postData);
        return networking_1.actionNotificationSettingsAPI.update(converted);
    };
    return (0, useCustomMutation_1.useCustomMutation)(apiRequest, {
        onSuccess: function (response) {
            (0, AlertMessage_1.customSuccessMessage)(constants_1.SuccessMessages.generic.update);
        },
        onError: function (err) {
            (0, utils_1.apiErrorMessage)(err, constants_1.ErrorMessages.generic.update);
        },
    });
};
exports.useActionNotificationSettingsUpdateAPIMutation = useActionNotificationSettingsUpdateAPIMutation;
