import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import moment from "moment-timezone";
import { ConfigProvider, Spin } from "antd";
import AppContainer from "./AppContainer";
import Pages from "./Routes/Routes";
import { getRevisionNumber } from "../domain/api";
import { useSelector } from "react-redux";
import { useSystemNotificationsAPI } from "~/hooks/useNotification";
import {
    customLocale,
    SYSTEM_NOTIFICATION_LAST_FETCHED_TIMESTAMP,
} from "~/utils/constants";
import { useRecoilValue } from "recoil";
import {
    isCsvDownloading as isCsvDownloadingAtom,
    isAppLoading as isAppLoadingAtom,
} from "~/recoil/atom";
import { useIntercom } from "~/hooks/useIntercom";
import { useClient } from "~/hooks/useClient";

moment.locale("ja"); // setting a timezone to JP.
moment.tz.setDefault("Asia/Tokyo");

const App = () => {
    const {
        token,
        isAdminUser,
        authorizedActions,
        displayName,
        userId,
        intercomUserHash,
    } = useSelector((state) => state.login);
    const { bootupIntercom, setupIntercom } = useIntercom();
    const { setTokenToClient } = useClient();
    const { fetchSystemNotifications } = useSystemNotificationsAPI();
    const isCsvDownloading = useRecoilValue(isCsvDownloadingAtom);
    const isAppLoading = useRecoilValue(isAppLoadingAtom);

    const prepareClient = () => {
        if (!token) {
            return;
        }
        setTokenToClient(token);
    };

    const prepareIntercom = () => {
        if ((displayName && userId, intercomUserHash)) {
            setupIntercom({ userId, displayName, intercomUserHash });
        } else {
            bootupIntercom();
        }
    };

    const prepareVersionCheck = () => {
        getRevisionNumber().then((number) => {
            setInterval(
                // Check remote version every 10 minutes.
                () => {
                    getRevisionNumber().then((remoteVersion) => {
                        if (remoteVersion > number) {
                            location.reload();
                        }
                    });
                },
                600000
            );
        });
    };

    useEffect(() => {
        prepareClient();
        prepareIntercom();
        prepareVersionCheck();
    }, []);

    const lastFetchNotification = localStorage.getItem(
        `${SYSTEM_NOTIFICATION_LAST_FETCHED_TIMESTAMP}_${userId}`
    );

    useEffect(() => {
        if (token) {
            // NOTE(joshua-hashimoto): お知らせの取得
            if (!lastFetchNotification) {
                localStorage.setItem(
                    `${SYSTEM_NOTIFICATION_LAST_FETCHED_TIMESTAMP}_${userId}`,
                    moment().subtract(30, "days").toLocaleString()
                );
            }
            fetchSystemNotifications({
                currentPage: 1,
                unread: false,
            });
        }
    }, [token]);

    const isLoggedIn = !!token;

    return (
        <Router>
            <ConfigProvider locale={customLocale}>
                <div className="app">
                    <Spin spinning={isCsvDownloading || isAppLoading}>
                        <AppContainer
                            isLoggedIn={isLoggedIn}
                            authorizedActions={authorizedActions}>
                            <Pages
                                isLoggedIn={isLoggedIn}
                                isAdminUser={isAdminUser}
                            />
                        </AppContainer>
                    </Spin>
                </div>
            </ConfigProvider>
        </Router>
    );
};

export default App;
