// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TelFormItem-container-ohtUE{width:100%;text-align:left}.TelFormItem-phonenumberInput-mp6yI{width:100%}.TelFormItem-tooltip-GUQsI{margin-left:5px}.TelFormItem-inlineField-wMXSG{display:inline-block;_display:inline}.TelFormItem-infoIcon-AHjj7{align-self:center}.TelFormItem-separater-tCovm{align-self:center;text-align:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Forms/UserSearchForm/TelFormItem/TelFormItem.scss"],"names":[],"mappings":"AAAA,6BACI,UAAW,CACX,eAAgB,CACnB,oCAGG,UAAW,CACd,2BAGG,eAAgB,CACnB,+BAGG,oBAAqB,EACrB,cAAgB,CACnB,4BAGG,iBAAkB,CACrB,6BAGG,iBAAkB,CAClB,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.phonenumberInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.inlineField {\n    display: inline-block;\n    _display: inline;\n}\n\n.infoIcon {\n    align-self: center;\n}\n\n.separater {\n    align-self: center;\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `TelFormItem-container-ohtUE`,
	"phonenumberInput": `TelFormItem-phonenumberInput-mp6yI`,
	"tooltip": `TelFormItem-tooltip-GUQsI`,
	"inlineField": `TelFormItem-inlineField-wMXSG`,
	"infoIcon": `TelFormItem-infoIcon-AHjj7`,
	"separater": `TelFormItem-separater-tCovm`
};
export default ___CSS_LOADER_EXPORT___;
