// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BoardAssignedListFormItem-wrapper-P54B3{width:100%}.BoardAssignedListFormItem-formItem-R8cpV{margin-bottom:10px}.BoardAssignedListFormItem-select_option-sJ72a{text-align:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Common/Board/BoardCardBasicInfo/BoardAssignedListFormItem/BoardAssignedListFormItem.scss"],"names":[],"mappings":"AAAA,yCACI,UAAW,CACd,0CAGG,kBAAmB,CACtB,+CAGG,iBAAkB","sourcesContent":[".wrapper {\n    width: 100%;\n}\n\n.formItem {\n    margin-bottom: 10px;\n}\n\n.select_option {\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `BoardAssignedListFormItem-wrapper-P54B3`,
	"formItem": `BoardAssignedListFormItem-formItem-R8cpV`,
	"select_option": `BoardAssignedListFormItem-select_option-sJ72a`
};
export default ___CSS_LOADER_EXPORT___;
