// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BackOfficeCommentCreateDateFormItem-container-FATkh{width:100%;text-align:left}.BackOfficeCommentCreateDateFormItem-userInput-_0wio{width:100%}.BackOfficeCommentCreateDateFormItem-tooltip-F4AwN{margin-left:5px}.BackOfficeCommentCreateDateFormItem-infoIcon-TcRie{align-self:center}.BackOfficeCommentCreateDateFormItem-marginBottom-fJWd7{margin-bottom:1%}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/backOffice/BackOfficeBoardPage/BackOfficeBoardSearchDrawer/BackOfficeCommentCreateDateFormItem/BackOfficeCommentCreateDateFormItem.scss"],"names":[],"mappings":"AAAA,qDACE,UAAW,CACX,eAAgB,CACjB,qDAGC,UAAW,CACZ,mDAGC,eAAgB,CACjB,oDAGC,iBAAkB,CACnB,wDAGC,gBAAiB","sourcesContent":[".container {\n  width: 100%;\n  text-align: left;\n}\n\n.userInput {\n  width: 100%;\n}\n\n.tooltip {\n  margin-left: 5px;\n}\n\n.infoIcon {\n  align-self: center;\n}\n\n.marginBottom {\n  margin-bottom: 1%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `BackOfficeCommentCreateDateFormItem-container-FATkh`,
	"userInput": `BackOfficeCommentCreateDateFormItem-userInput-_0wio`,
	"tooltip": `BackOfficeCommentCreateDateFormItem-tooltip-F4AwN`,
	"infoIcon": `BackOfficeCommentCreateDateFormItem-infoIcon-TcRie`,
	"marginBottom": `BackOfficeCommentCreateDateFormItem-marginBottom-fJWd7`
};
export default ___CSS_LOADER_EXPORT___;
