"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var api_1 = require("~/domain/api");
var scheduledEmailSetting = function (client) {
    return {
        fetchScheduledEmailSetting: function () {
            var url = api_1.Endpoint.scheduledEmailSetting;
            return client.get(url);
        },
        testConnection: function (postData) {
            var url = api_1.Endpoint.scheduledEmailConnection;
            return client.post(url, postData);
        },
        updateScheduledEmailSetting: function (postData) {
            var url = api_1.Endpoint.scheduledEmailSetting;
            return client.patch(url, postData);
        },
        testDelivery: function (postData) {
            var url = api_1.Endpoint.scheduledEmailTestSending;
            return client.post(url, postData);
        },
    };
};
exports.default = scheduledEmailSetting;
