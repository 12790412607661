// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ScheduledEmailCreatedUserFormItem-container-X4_an{width:100%;text-align:left}.ScheduledEmailCreatedUserFormItem-userInput-FPh26{width:100%}.ScheduledEmailCreatedUserFormItem-tooltip-kxB_V{margin-left:5px}.ScheduledEmailCreatedUserFormItem-infoIcon-mmtnA{align-self:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/ScheduledEmailSearchPage/ScheduledEmailSearchDrawer/ScheduledEmailCreatedUserFormItem/ScheduledEmailCreatedUserFormItem.scss"],"names":[],"mappings":"AAAA,mDACI,UAAW,CACX,eAAgB,CACnB,mDAGG,UAAW,CACd,iDAGG,eAAgB,CACnB,kDAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ScheduledEmailCreatedUserFormItem-container-X4_an`,
	"userInput": `ScheduledEmailCreatedUserFormItem-userInput-FPh26`,
	"tooltip": `ScheduledEmailCreatedUserFormItem-tooltip-kxB_V`,
	"infoIcon": `ScheduledEmailCreatedUserFormItem-infoIcon-mmtnA`
};
export default ___CSS_LOADER_EXPORT___;
