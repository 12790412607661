import React from "react";
import ReactDom from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import App from "./components/App";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import store, { persistor } from "./store";
import LoadingScreen from "./components/Screens/loading";
import { Helmet } from "react-helmet";
import { RecoilRoot } from "recoil";
import RecoilDevtools from "./components/Common/RecoilDevtools/RecoilDevtools";
import PersonnelBoardFormsProvider from "./contexts/PersonnelBoardFormsProvider";
import ProjectBoardFormsProvider from "./contexts/ProjectBoardFormsProvider";
import RecruitBoardFormsProvider from "./contexts/RecruitBoardFormsProvider";
import SalesBoardFormsProvider from "./contexts/SalesBoardFormsProvider";
import BackOfficeBoardFormsProvider from "./contexts/BackOfficeBoardFormsProvider";
import { queryClient } from "./utils/utils";
import "../stylesheets/index.scss";

if (process.env.NODE_ENV !== "development") {
    console.log = () => {};
    console.info = () => {};
    console.error = () => {};
}

const ContextProviders = ({ children }) => {
    return (
        <BackOfficeBoardFormsProvider>
            <SalesBoardFormsProvider>
                <RecruitBoardFormsProvider>
                    <PersonnelBoardFormsProvider>
                        <ProjectBoardFormsProvider>
                            {children}
                        </ProjectBoardFormsProvider>
                    </PersonnelBoardFormsProvider>
                </RecruitBoardFormsProvider>
            </SalesBoardFormsProvider>
        </BackOfficeBoardFormsProvider>
    );
};

ReactDom.render(
    <QueryClientProvider client={queryClient}>
        <RecoilRoot>
            <Provider store={store}>
                <PersistGate loading={<LoadingScreen />} persistor={persistor}>
                    <Helmet>
                        <link
                            rel="icon"
                            href="/static/app_staffing/favicon.svg"
                        />
                    </Helmet>
                    <ContextProviders>
                        <App />
                    </ContextProviders>
                </PersistGate>
            </Provider>
            {process.env.NODE_ENV === "development" && <RecoilDevtools />}
        </RecoilRoot>
        <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>,
    document.querySelector(".container")
);
