// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MainMenu-container-QWgip{height:100%;border-right:0;text-align:left}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Navigations/MainMenu/MainMenu.scss"],"names":[],"mappings":"AAAA,0BACI,WAAY,CACZ,cAAe,CACf,eAAgB","sourcesContent":[".container {\n    height: 100%;\n    border-right: 0;\n    text-align: left;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `MainMenu-container-QWgip`
};
export default ___CSS_LOADER_EXPORT___;
