"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var api_1 = require("~/domain/api");
var account = function (client) {
    return {
        deleteAccount: function () {
            return client.delete(api_1.Endpoint.account);
        },
        passwordReset: function (postData) {
            var url = api_1.Endpoint.passwordResetMail;
            return client.post(url, postData);
        },
    };
};
exports.default = account;
