"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRecruitBoardMutateListAPIMutation = exports.useDeleteRecruitCardBoard = exports.useDuplicateRecruitCardBoard = exports.useSearchScheduledEmail = exports.useRecruitBoardDetailCard = exports.useRecruitBoardFetchSubCommentsAPIQuery = exports.useRecruitBoardDeleteCommentAPIMutation = exports.useRecruitBoardUpdateCommentAPIMutation = exports.useRecruitBoardFetchCommentAPIQuery = exports.useRecruitBoardCreateCommentAPIMutation = exports.useRecruitBoardFetchCommentsAPIQuery = exports.useRecruitBoardFetchChecklistItemsAPIQuery = exports.useRecruitBoardChangeCardPositionAPIMutation = exports.useRecruitBoardDeleteCardAPIMutation = exports.useRecruitBoardDuplicateCardAPIMutation = exports.useRecruitBoardFetchListCardsAPIQuery = exports.useRecruitBoardFetchListsAPIQuery = void 0;
var react_1 = require("react");
var recoil_1 = require("recoil");
var AlertMessage_1 = require("~/components/Common/AlertMessage/AlertMessage");
var api_1 = require("~/networking/api");
var atom_1 = require("~/recoil/atom");
var constants_1 = require("~/utils/constants");
var board_1 = require("./recruit/board");
var useCustomMutation_1 = require("./useCustomMutation");
var useCustomQuery_1 = require("./useCustomQuery");
var useScheduledEmail_1 = require("./useScheduledEmail");
var defaultDataCardDetail = {
    id: "",
    order: 0,
    listId: "",
    assignees: [],
    priority: {
        title: "中",
        value: "urgent",
    },
    period: {
        end: undefined,
        start: undefined,
        isFinished: false,
    },
    detail: "",
    tags: [],
    description: "",
    // dynamicRows: [],
    checklist: [],
    comments: [],
    attachments: [],
    isArchived: false,
};
var useRecruitBoardFetchListsAPIQuery = function (_a) {
    var deps = _a.deps, options = _a.options;
    return (0, useCustomQuery_1.useCustomQuery)({
        queryKey: constants_1.QueryKeys.recruitBoard.lists,
        deps: deps,
        options: __assign({}, options),
        apiRequest: api_1.recruitAPI.board.fetchLists,
    });
};
exports.useRecruitBoardFetchListsAPIQuery = useRecruitBoardFetchListsAPIQuery;
var useRecruitBoardFetchListCardsAPIQuery = function (_a) {
    var deps = _a.deps, options = _a.options;
    return (0, useCustomQuery_1.useCustomQuery)({
        queryKey: constants_1.QueryKeys.recruitBoard.cards,
        deps: deps,
        options: __assign({}, options),
        apiRequest: function (query) {
            return api_1.recruitAPI.board.fetchListCards(query === null || query === void 0 ? void 0 : query.listId);
        },
    });
};
exports.useRecruitBoardFetchListCardsAPIQuery = useRecruitBoardFetchListCardsAPIQuery;
var useRecruitBoardDuplicateCardAPIMutation = function () {
    var apiRequest = function (postData) {
        return api_1.recruitAPI.board.duplicateCard(postData);
    };
    return (0, useCustomMutation_1.useCustomMutation)(apiRequest, {
        onSuccess: function () {
            (0, AlertMessage_1.customSuccessMessage)(constants_1.SuccessMessages.generic.copy);
        },
        onError: function () {
            (0, AlertMessage_1.customErrorMessage)(constants_1.ErrorMessages.generic.copy);
        },
    });
};
exports.useRecruitBoardDuplicateCardAPIMutation = useRecruitBoardDuplicateCardAPIMutation;
var useRecruitBoardDeleteCardAPIMutation = function () {
    return (0, useCustomMutation_1.useCustomMutation)(api_1.recruitAPI.board.deleteCard, {
        onSuccess: function (response) {
            (0, AlertMessage_1.customSuccessMessage)(constants_1.SuccessMessages.generic.delete);
        },
        onError: function (err) {
            (0, AlertMessage_1.customErrorMessage)(constants_1.ErrorMessages.generic.delete);
        },
    });
};
exports.useRecruitBoardDeleteCardAPIMutation = useRecruitBoardDeleteCardAPIMutation;
var useRecruitBoardChangeCardPositionAPIMutation = function () {
    var apiRequest = function (_a) {
        var cardId = _a.cardId, postData = _a.postData;
        return api_1.recruitAPI.board.changeCardPosition(cardId, postData);
    };
    return (0, useCustomMutation_1.useCustomMutation)(apiRequest, {
        onSuccess: function (response) { },
        onError: function (err) { },
    });
};
exports.useRecruitBoardChangeCardPositionAPIMutation = useRecruitBoardChangeCardPositionAPIMutation;
var useRecruitBoardFetchChecklistItemsAPIQuery = function (_a) {
    var deps = _a.deps, options = _a.options;
    return (0, useCustomQuery_1.useCustomQuery)({
        queryKey: constants_1.QueryKeys.recruitBoard.checklistItems,
        deps: deps,
        options: __assign({}, options),
        apiRequest: function (query) {
            return api_1.recruitAPI.board.fetchChecklistItems(query === null || query === void 0 ? void 0 : query.checklistId);
        },
    });
};
exports.useRecruitBoardFetchChecklistItemsAPIQuery = useRecruitBoardFetchChecklistItemsAPIQuery;
var useRecruitBoardFetchCommentsAPIQuery = function (_a) {
    var deps = _a.deps, options = _a.options;
    return (0, useCustomQuery_1.useCustomQuery)({
        queryKey: constants_1.QueryKeys.recruitBoard.comments,
        deps: deps,
        options: __assign({}, options),
        apiRequest: function (query) { return api_1.recruitAPI.board.fetchComments(query === null || query === void 0 ? void 0 : query.cardId); },
    });
};
exports.useRecruitBoardFetchCommentsAPIQuery = useRecruitBoardFetchCommentsAPIQuery;
var useRecruitBoardCreateCommentAPIMutation = function () {
    return (0, useCustomMutation_1.useCustomMutation)(api_1.recruitAPI.board.createComment, {
        onSuccess: function (response) { },
        onError: function (err) { },
    });
};
exports.useRecruitBoardCreateCommentAPIMutation = useRecruitBoardCreateCommentAPIMutation;
var useRecruitBoardFetchCommentAPIQuery = function (_a) {
    var deps = _a.deps, options = _a.options;
    return (0, useCustomQuery_1.useCustomQuery)({
        queryKey: constants_1.QueryKeys.recruitBoard.comment,
        deps: deps,
        options: __assign({}, options),
        apiRequest: function (query) {
            return api_1.recruitAPI.board.fetchComment(query === null || query === void 0 ? void 0 : query.commentId);
        },
    });
};
exports.useRecruitBoardFetchCommentAPIQuery = useRecruitBoardFetchCommentAPIQuery;
var useRecruitBoardUpdateCommentAPIMutation = function () {
    var apiRequest = function (_a) {
        var commentId = _a.commentId, postData = _a.postData;
        return api_1.recruitAPI.board.updateComment(commentId, postData);
    };
    return (0, useCustomMutation_1.useCustomMutation)(apiRequest, {
        onSuccess: function (response) { },
        onError: function (err) { },
    });
};
exports.useRecruitBoardUpdateCommentAPIMutation = useRecruitBoardUpdateCommentAPIMutation;
var useRecruitBoardDeleteCommentAPIMutation = function () {
    return (0, useCustomMutation_1.useCustomMutation)(api_1.recruitAPI.board.deleteComment, {
        onSuccess: function (response) { },
        onError: function (err) { },
    });
};
exports.useRecruitBoardDeleteCommentAPIMutation = useRecruitBoardDeleteCommentAPIMutation;
var useRecruitBoardFetchSubCommentsAPIQuery = function (_a) {
    var deps = _a.deps, options = _a.options;
    return (0, useCustomQuery_1.useCustomQuery)({
        queryKey: constants_1.QueryKeys.recruitBoard.subComments,
        deps: deps,
        options: __assign({}, options),
        apiRequest: function (query) {
            return api_1.recruitAPI.board.fetchSubComments(query === null || query === void 0 ? void 0 : query.commentId);
        },
    });
};
exports.useRecruitBoardFetchSubCommentsAPIQuery = useRecruitBoardFetchSubCommentsAPIQuery;
var useRecruitBoardDetailCard = function (cardId) {
    var _a = (0, react_1.useState)({
        cardId: cardId,
    }), deps = _a[0], setDeps = _a[1];
    var _b = (0, recoil_1.useRecoilState)(atom_1.recruitBoard), recruitBoardState = _b[0], setRecruitBoardState = _b[1];
    var _c = (0, board_1.useRecruitBoardFetchCardAPIQuery)({
        deps: deps,
        options: {
            enabled: !!deps.cardId,
        },
    }), isLoading = _c.isLoading, isSuccess = _c.isSuccess, dataDetail = _c.data, refetchFetchCard = _c.refetch;
    var updateCardOrderMutate = (0, board_1.useRecruitBoardUpdateCardOrderAPIMutation)().mutate;
    (0, react_1.useEffect)(function () {
        if (deps && deps.cardId) {
            refetchFetchCard();
        }
    }, [deps, deps.cardId]);
    (0, react_1.useEffect)(function () {
        if (recruitBoardState && recruitBoardState.recruitId) {
            setDeps({ cardId: recruitBoardState.recruitId });
        }
    }, [recruitBoardState, recruitBoardState.recruitId]);
    return {
        isLoading: isLoading,
        isSuccess: isSuccess,
        dataDetail: dataDetail,
        defaultDataCardDetail: defaultDataCardDetail,
        refetchFetchCard: refetchFetchCard,
    };
};
exports.useRecruitBoardDetailCard = useRecruitBoardDetailCard;
var useSearchScheduledEmail = function (params) {
    var _a = (0, useScheduledEmail_1.useFetchScheduledEmailSearchAPIQuery)({
        deps: {
            params: params,
        },
        options: {
            enabled: !!params,
        },
    }), listsScheduledMails = _a.data, isLoading = _a.isLoading;
    return {
        isLoading: isLoading,
        listsScheduledMails: listsScheduledMails,
    };
};
exports.useSearchScheduledEmail = useSearchScheduledEmail;
var useDuplicateRecruitCardBoard = function () {
    var _a = (0, exports.useRecruitBoardDuplicateCardAPIMutation)(), dupliateCardMutate = _a.mutate, dupliateCardData = _a.data;
    var duplicateRecruitCardBoard = function (cardData) {
        dupliateCardMutate(cardData);
    };
    return {
        duplicateRecruitCardBoard: duplicateRecruitCardBoard,
        dupliateCardData: dupliateCardData,
    };
};
exports.useDuplicateRecruitCardBoard = useDuplicateRecruitCardBoard;
var useDeleteRecruitCardBoard = function () {
    var _a = (0, exports.useRecruitBoardDeleteCardAPIMutation)(), deleteCardMutate = _a.mutate, deleteCardData = _a.data;
    var deleteRecruitCardBoard = function (cardId) {
        deleteCardMutate(cardId);
    };
    return {
        deleteRecruitCardBoard: deleteRecruitCardBoard,
        deleteCardData: deleteCardData,
    };
};
exports.useDeleteRecruitCardBoard = useDeleteRecruitCardBoard;
var useRecruitBoardMutateListAPIMutation = function () {
    return (0, useCustomMutation_1.useCustomMutation)(api_1.recruitAPI.board.fetchLists);
};
exports.useRecruitBoardMutateListAPIMutation = useRecruitBoardMutateListAPIMutation;
