// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CommentUpdateForm-controlButton-Fhi44{margin-right:5px}.CommentUpdateForm-editCancelButton-PVizZ{margin-left:8px}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Forms/CommentUpdateForm/CommentUpdateForm.scss"],"names":[],"mappings":"AAAA,uCACI,gBAAiB,CACpB,0CAGG,eAAgB","sourcesContent":[".controlButton {\n    margin-right: 5px;\n}\n\n.editCancelButton {\n    margin-left: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"controlButton": `CommentUpdateForm-controlButton-Fhi44`,
	"editCancelButton": `CommentUpdateForm-editCancelButton-PVizZ`
};
export default ___CSS_LOADER_EXPORT___;
