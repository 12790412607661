// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProjectOrganizationIntroduceFormItem-container-DKxiN{width:100%;text-align:left}.ProjectOrganizationIntroduceFormItem-tooltip-TN8V0{margin-left:5px}.ProjectOrganizationIntroduceFormItem-infoIcon-HJF1o{align-self:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/project/ProjectBoardPage/ProjectBoardSearchDrawer/ProjectOrganizationIntroduceFormItem/ProjectOrganizationIntroduceFormItem.scss"],"names":[],"mappings":"AAAA,sDACE,UAAW,CACX,eAAgB,CACjB,oDAGC,eAAgB,CACjB,qDAGC,iBAAkB","sourcesContent":[".container {\n  width: 100%;\n  text-align: left;\n}\n\n.tooltip {\n  margin-left: 5px;\n}\n\n.infoIcon {\n  align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ProjectOrganizationIntroduceFormItem-container-DKxiN`,
	"tooltip": `ProjectOrganizationIntroduceFormItem-tooltip-TN8V0`,
	"infoIcon": `ProjectOrganizationIntroduceFormItem-infoIcon-HJF1o`
};
export default ___CSS_LOADER_EXPORT___;
