import { ORGANIZATION_CSV_UPLOAD_PAGE } from "./pageIds";
import { Endpoint } from "~/domain/api";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Upload, Button, Table, List, Row, Col } from "antd";
import NotFoundPage from "./NotFoundPage";
import { DownloadOutlined, InboxOutlined } from "@ant-design/icons";
import {
    customErrorMessage,
    customSuccessMessage,
} from "~/components/Common/AlertMessage/AlertMessage";
import CustomBackToTop from "~/components/Common/CustomBackToTop/CustomBackToTop";
import BackButton from "~/components/Common/BackButton/BackButton";
import CustomProgress from "~/components/Common/CustomProgress/CustomProgress";
import Paths from "~/components/Routes/Paths";
import { Links } from "~/utils/constants";
import styles from "./page.scss";

const pageId = ORGANIZATION_CSV_UPLOAD_PAGE;

const csvUploadURL = () =>
    `${Endpoint.getBaseUrl()}/${Endpoint.organizationsCsvUpload}`;

const CsvUploadPageCreator = (pageId, pageTitle) => {
    class CsvUploadPage extends Component {
        constructor(props) {
            super(props);
            this.state = {
                errorMessages: [],
                isProgressBar: false,
                uploadProgress: 0,
                isUploadSuccess: false,
            };
        }

        onChange = (info) => {
            const { status, response, percent } = info.file;
            if (status === "uploading") {
                this.setState({
                    errorMessages: [],
                    isProgressBar: true,
                    uploadProgress: Math.min(Math.round(percent), 99),
                    isUploadSuccess: false,
                });
            } else if (status === "done") {
                if (response["register_successed"]) {
                    this.setState({
                        uploadProgress: 100,
                        isUploadSuccess: true,
                    });
                    customSuccessMessage(
                        `${info.file.name} ファイルがアップロードされ、データの登録が完了しました。`
                    );
                    setTimeout(() => {
                        this.setState({ isProgressBar: false });
                    }, 2000);
                } else {
                    this.setState({
                        errorMessages: response["errorMessages"],
                        isProgressBar: false,
                    });
                    customErrorMessage(
                        `${info.file.name} のファイルがアップロードされましたが、入力エラーによりデータの登録に失敗しました。`
                    );
                }
            } else if (status === "error") {
                this.setState({ isProgressBar: false });
                customErrorMessage(
                    `${info.file.name} アップロードに失敗しました。`
                );
                if ("detail" in response) {
                    customErrorMessage(response["detail"]);
                }
            }
        };

        render() {
            const { token, authorizedActions } = this.props;
            const {
                errorMessages,
                isProgressBar,
                uploadProgress,
                isUploadSuccess,
            } = this.state;
            const columns = [
                {
                    title: "項目名",
                    dataIndex: "column_name",
                    key: "column_name",
                },
                {
                    title: "必須",
                    dataIndex: "require",
                    key: "require",
                    className: styles.nowrap,
                },
                { title: "備考", dataIndex: "description", key: "description" },
            ];
            const data = [
                {
                    column_name: "法人番号",
                    require: "",
                    description: "13桁の国税庁法人番号",
                },
                { column_name: "取引先名", require: "◯", description: "" },
                {
                    column_name: "取引先ステータス",
                    require: "◯",
                    description:
                        "「見込み客」「アプローチ済」「情報交換済」「契約実績有」のいずれかを入力",
                },
                {
                    column_name: "取引先評価",
                    require: "",
                    description: "1 ~ 5 の数字を入力",
                },
                {
                    column_name: "国籍",
                    require: "",
                    description: "「JP」「KR」「CN」「OTHER」のいずれかを入力",
                },
                {
                    column_name: "設立年月",
                    require: "",
                    description: "yyyy-mm(半角数字)で入力",
                },
                {
                    column_name: "決算期",
                    require: "",
                    description: "1 ~ 12 の数字を入力",
                },
                {
                    column_name: "住所(市区町村・町名・番地)",
                    require: "",
                    description: "",
                },
                { column_name: "住所(建物)", require: "", description: "" },
                {
                    column_name: "TEL",
                    require: "",
                    description: "ハイフンありで登録\n例：03-0000-0000",
                },
                {
                    column_name: "FAX",
                    require: "",
                    description: "ハイフンありで登録\n例：03-0000-0000",
                },
                { column_name: "URL", require: "", description: "" },
                {
                    column_name: "社員数",
                    require: "",
                    description:
                        "「~10名」「11~30名」「31~50名」「51~100名」「101~300名」「301名~」のいずれかを入力",
                },
                {
                    column_name: "商流",
                    require: "",
                    description:
                        "0：抜けない 1：抜ける 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "請負",
                    require: "",
                    description:
                        "0：なし 1：あり 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "資本金",
                    require: "",
                    description: "数字のみ入力(単位：万円)",
                },
                {
                    column_name: "契約書類 > 業務委託基本契約",
                    require: "",
                    description: "0：未締結 1：締結済",
                },
                {
                    column_name: "契約書類 > NDA",
                    require: "",
                    description: "0：未締結 1：締結済",
                },
                {
                    column_name: "契約書類 > 個人情報の取り扱い",
                    require: "",
                    description: "0：未締結 1：締結済",
                },
                {
                    column_name: "保有資格 > Pマーク／ISMS",
                    require: "",
                    description: "0：なし 1：あり",
                },
                {
                    column_name: "保有資格 > インボイス登録事業者",
                    require: "",
                    description: "0：なし 1：あり",
                },
                {
                    column_name: "保有資格 > 労働者派遣事業",
                    require: "",
                    description: "0：なし 1：あり",
                },
                { column_name: "取引先支店名", require: "", description: "" },
                {
                    column_name: "取引先支店住所(市区町村・町名・番地)",
                    require: "",
                    description: "",
                },
                {
                    column_name: "取引先支店住所(建物)",
                    require: "",
                    description: "",
                },
                {
                    column_name: "取引先支店TEL",
                    require: "",
                    description: "ハイフンありで登録\n例：03-0000-0000",
                },
                {
                    column_name: "取引先支店FAX",
                    require: "",
                    description: "ハイフンありで登録\n例：03-0000-0000",
                },
                {
                    column_name: "取引に必要な設立年数",
                    require: "",
                    description: "数字のみ入力",
                },
                {
                    column_name: "取引に必要な資本金",
                    require: "",
                    description: "数字のみ入力(単位：万円)",
                },
                {
                    column_name: "取引に必要な資格 > Pマーク／ISMS",
                    require: "",
                    description:
                        "0：なし 1：あり 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "取引に必要な資格 > インボイス登録事業者",
                    require: "",
                    description:
                        "0：なし 1：あり 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "取引に必要な資格 > 労働者派遣事業",
                    require: "",
                    description:
                        "0：なし 1：あり 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "ブロックリスト",
                    require: "",
                    description:
                        "0：なし 1：あり 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "タグ",
                    require: "",
                    description: "カンマ区切りで10個まで入力可能",
                },
            ];
            const errorColumns = [
                {
                    title: "行数",
                    dataIndex: "line",
                    key: "line",
                    width: "10%",
                },
                {
                    title: "項目名",
                    dataIndex: "columnName",
                    key: "columnName",
                    width: "25%",
                },
                {
                    title: "エラー内容",
                    dataIndex: "message",
                    key: "message",
                    width: "65%",
                },
            ];
            const errorRowHeight = 53.5; // actual size measured in devtool
            const maxDisplayErrorRows = 100;

            let csvAuthorized =
                authorizedActions &&
                authorizedActions["organizations"] &&
                authorizedActions["organizations"]["csv_upload"];
            if (!csvAuthorized) {
                return (
                    <div className={styles.container}>
                        <NotFoundPage {...this.props} />
                    </div>
                );
            }

            return (
                <div style={{ textAlign: "left" }}>
                    <CustomBackToTop />
                    <Upload
                        type="drag"
                        name="file"
                        action={csvUploadURL()}
                        headers={{ Authorization: `Token ${token}` }}
                        onChange={this.onChange}
                        showUploadList={false}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">
                            クリックまたはドラッグでCSVファイルをアップロード
                        </p>
                        <p className="ant-upload-hint">
                            ※取引先情報をCSVファイルで新規登録することができます。
                        </p>
                        <p className="ant-upload-hint">
                            ※取引先一覧でダウンロードできるファイルとは形式が異なるため、右下のサンプルCSVファイルをご参照ください。
                        </p>
                        <p className="ant-upload-hint">
                            （サンプルCSVファイルの列順序や列数を変更するとエラーになります。）
                        </p>
                        <p className="ant-upload-hint">
                            ※CSVファイルのヘッダー行の情報は取り込まれないため、ヘッダー行の変更は反映されません。
                        </p>
                        <p className="ant-upload-hint">
                            ※新規登録のみで更新には対応していないため、重複登録にご注意ください。
                        </p>
                        <p className="ant-upload-hint">
                            ※一度に登録できるデータ件数は300件までです。
                        </p>
                        <p className="ant-upload-hint">
                            ※入力データの前後にあるスペースは除去して登録されます。
                        </p>
                    </Upload>
                    <Row className={styles.downloadButtonWrapper}>
                        <Col>
                            <a
                                href={Links.helps.csv.upload}
                                target="_blank"
                                rel="noopener noreferrer">
                                <Button size="small">
                                    CSVアップロードヘルプ
                                </Button>
                            </a>
                        </Col>
                        <Col>
                            <Button
                                type="primary"
                                icon={<DownloadOutlined />}
                                size="small"
                                className={styles.tableControlButton}
                                href="/static/app_staffing/organizations.csv">
                                サンプル CSV ダウンロード
                            </Button>
                        </Col>
                    </Row>
                    <div
                        hidden={errorMessages.length === 0}
                        className={styles.errorMessages}>
                        <p>
                            ▼下記のエラー内容をご確認の上、対象のデータを修正してください。
                        </p>
                        <div className={styles.tableNewLine}>
                            <Table
                                columns={errorColumns}
                                dataSource={errorMessages}
                                pagination={false}
                                scroll={{
                                    y: errorRowHeight * maxDisplayErrorRows
                                }}
                            />
                        </div>
                    </div>
                    <div className={styles.tableNewLine}>
                        <Table
                            columns={columns}
                            dataSource={data}
                            pagination={false}
                        />
                    </div>
                    <Row style={{ marginTop: "1%" }}>
                        <Col>
                            <BackButton to={Paths.organizations} />
                        </Col>
                    </Row>
                    {isProgressBar && (
                        <div style={{ textAlign: "center" }}>
                            <CustomProgress
                                percentage={uploadProgress}
                                progressCompleted={isUploadSuccess}
                            />
                        </div>
                    )}
                </div>
            );
        }
    }
    CsvUploadPage.propTypes = {
        currentUserId: PropTypes.string.isRequired,
        token: PropTypes.string.isRequired,
        authorizedActions: PropTypes.object.isRequired,
        dispatch: PropTypes.func.isRequired,
    };
    function mapStateToProps(state) {
        return {
            token: state.login.token,
            authorizedActions: state.login.authorizedActions,
            currentUserId: state.login.userId,
        };
    }
    return connect(mapStateToProps)(CsvUploadPage);
};

export const OrganizationCsvUploadPage = CsvUploadPageCreator(
    pageId,
    "取引先 登録"
);
