// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ScheduledEmailBulkEditPage-pageHeader-XKsaX{padding:16px 0 0 0}.ScheduledEmailBulkEditPage-infoAlert-RETSS{text-align:left;margin:15px 0}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/ScheduledEmailBulkEditPage/ScheduledEmailBulkEditPage.scss"],"names":[],"mappings":"AAAA,6CACE,kBAAmB,CACpB,4CAGC,eAAgB,CAChB,aAAc","sourcesContent":[".pageHeader {\n  padding: 16px 0 0 0;\n}\n\n.infoAlert {\n  text-align: left;\n  margin: 15px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageHeader": `ScheduledEmailBulkEditPage-pageHeader-XKsaX`,
	"infoAlert": `ScheduledEmailBulkEditPage-infoAlert-RETSS`
};
export default ___CSS_LOADER_EXPORT___;
