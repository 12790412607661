"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPlanTableRows = exports.getPlanCards = void 0;
var getPersonalPlanCard = function () {
    return {
        planId: 4,
        planTitle: "パーソナル",
        defaultCount: 1,
        monthlyPrice: 10000,
        planSummary: [
            "1ユーザーのみ利用可能",
            "ユーザー追加:不可",
            "サポート利用無制限",
        ],
        aimedTo: [
            "顧客管理をオンライン上で行いたい",
            "メール配信を効率化したい",
        ],
        functions: [
            "取引先管理機能",
            "取引先担当者管理機能",
            "配信メール管理機能",
        ],
    };
};
var getLightPlanCard = function () {
    var plan = {
        planId: 1,
        planTitle: "ライト",
        defaultCount: 5,
        monthlyPrice: 30000,
        planSummary: [
            "5ユーザーまで無料",
            "ユーザー追加：月額3,000円(税抜)",
            "サポート利用無制限",
        ],
        aimedTo: [
            "顧客管理をオンライン上で行いたい",
            "メール配信を効率化したい",
        ],
        functions: [
            "ダッシュボード機能",
            "取引先管理機能",
            "取引先担当者管理機能",
            "配信メール管理機能",
            "アドオン機能",
        ],
    };
    return plan;
};
var getStandardPlanCard = function () {
    var plan = {
        planId: 2,
        planTitle: "スタンダード",
        defaultCount: 10,
        monthlyPrice: 50000,
        planSummary: [
            "10ユーザーまで無料",
            "ユーザー追加：月額5,000円(税抜)",
            "サポート利用無制限",
        ],
        aimedTo: [
            "インバウンドセールスを強化したい",
            "要員管理、案件管理を一元化したい",
        ],
        functions: [
            "マッチングメール管理",
            "要員管理",
            "案件管理",
            "その他、Coming soon...",
        ],
    };
    return plan;
};
var getProfessionalPlanCard = function () {
    var plan = {
        planId: 3,
        planTitle: "プロフェッショナル",
        defaultCount: 20,
        monthlyPrice: 100000,
        planSummary: [
            "20ユーザーまで無料",
            "ユーザー追加：月額10,000円(税抜)",
            "サポート利用無制限",
        ],
        aimedTo: [
            "営業活動を数字で管理、効率化したい",
            "より戦略的な営業施策を実施したい",
        ],
        functions: [
            "その他、Coming soon...",
        ],
    };
    return plan;
};
var getPlanCards = function () {
    var options = [
        getPersonalPlanCard(),
        getLightPlanCard(),
        getStandardPlanCard(),
        getProfessionalPlanCard(),
    ];
    return options;
};
exports.getPlanCards = getPlanCards;
var getPlanTableRows = function () {
    var options = [
        {
            menu: "ダッシュボード",
            functionName: "ダッシュボード",
            isPersonalPlanAvailable: "-",
            isLightPlanAvailable: "⚪︎",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            menu: "取引先管理",
            functionName: "取引先一覧／検索",
            isPersonalPlanAvailable: "⚪︎",
            personalPlanNotes: ["※検索項目制限あり", "※登録件数制限あり"],
            isLightPlanAvailable: "⚪︎",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            functionName: "取引先情報",
            isPersonalPlanAvailable: "⚪︎",
            isLightPlanAvailable: "⚪︎",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            functionName: "取引先支店情報",
            isPersonalPlanAvailable: "⚪︎",
            isLightPlanAvailable: "⚪︎",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            functionName: "取引先取引条件",
            isPersonalPlanAvailable: "⚪︎",
            isLightPlanAvailable: "⚪︎",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            functionName: "取引先コメント\n(メンション／スレッド)",
            isPersonalPlanAvailable: "⚪︎",
            isLightPlanAvailable: "⚪︎",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            functionName: "取引先CSVダウンロード",
            isPersonalPlanAvailable: "-",
            isLightPlanAvailable: "⚪︎",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            functionName: "取引先CSVアップロード",
            isPersonalPlanAvailable: "⚪︎",
            isLightPlanAvailable: "⚪︎",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            functionName: "取引先担当者一覧／検索",
            isPersonalPlanAvailable: "⚪︎",
            personalPlanNotes: ["※検索項目制限あり", "※登録件数制限あり"],
            isLightPlanAvailable: "⚪︎",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            functionName: "取引先担当者情報",
            isPersonalPlanAvailable: "⚪︎",
            isLightPlanAvailable: "⚪︎",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            functionName: "取引先担当者配信条件",
            isPersonalPlanAvailable: "⚪︎",
            isLightPlanAvailable: "⚪︎",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            functionName: "取引先担当者配信履歴",
            isPersonalPlanAvailable: "-",
            isLightPlanAvailable: "⚪︎",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            functionName: "取引先担当者コメント\n(メンション／スレッド)",
            isPersonalPlanAvailable: "⚪︎",
            isLightPlanAvailable: "⚪︎",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            functionName: "取引先担当者CSVダウンロード",
            isPersonalPlanAvailable: "-",
            isLightPlanAvailable: "⚪︎",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            functionName: "取引先担当者CSVアップロード",
            isPersonalPlanAvailable: "⚪︎",
            isLightPlanAvailable: "⚪︎",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            menu: "配信メール管理",
            functionName: "配信メール一覧／検索",
            isPersonalPlanAvailable: "⚪︎",
            isLightPlanAvailable: "⚪︎",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            functionName: "配信メール予約\n(TO配信)",
            isPersonalPlanAvailable: "⚪︎",
            isLightPlanAvailable: "⚪︎",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            functionName: "配信メールテンプレート",
            isPersonalPlanAvailable: "⚪︎",
            isLightPlanAvailable: "⚪︎",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            functionName: "配信メール宛先検索",
            isPersonalPlanAvailable: "⚪︎",
            isLightPlanAvailable: "⚪︎",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            functionName: "テキスト／HTML配信",
            isPersonalPlanAvailable: "⚪︎",
            isLightPlanAvailable: "⚪︎",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            functionName: "配信宛先最大1000件\n(アドオン最大5000件)",
            isPersonalPlanAvailable: "⚪︎",
            personalPlanNotes: ["※最大500件"],
            isLightPlanAvailable: "⚪︎",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            functionName: "配信添付容量最大2MB\n(アドオン最大10MB)",
            isPersonalPlanAvailable: "⚪︎",
            isLightPlanAvailable: "⚪︎",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            functionName: "配信データ保持期間最大半年\n(アドオン上限緩和)",
            isPersonalPlanAvailable: "⚪︎",
            personalPlanNotes: ["※最大30日"],
            isLightPlanAvailable: "⚪︎",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            "menu": "マッチングメール管理",
            functionName: "マッチングメール一覧／検索",
            isPersonalPlanAvailable: "-",
            isLightPlanAvailable: "-",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            functionName: "マッチングメール詳細",
            isPersonalPlanAvailable: "-",
            isLightPlanAvailable: "-",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            functionName: "マッチングメールコメント\n(メンション／スレッド)",
            isPersonalPlanAvailable: "-",
            isLightPlanAvailable: "-",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            functionName: "マッチングメール担当者設定",
            isPersonalPlanAvailable: "-",
            isLightPlanAvailable: "-",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            functionName: "マッチングメール転送",
            isPersonalPlanAvailable: "-",
            isLightPlanAvailable: "-",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            functionName: "マッチングメール\nデータ保持期間最大半年\n(アドオン上限緩和)",
            isPersonalPlanAvailable: "-",
            isLightPlanAvailable: "-",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            functionName: "自動マッチング条件一覧／検索",
            isPersonalPlanAvailable: "-",
            isLightPlanAvailable: "-",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            functionName: "自動マッチング通知",
            isPersonalPlanAvailable: "-",
            isLightPlanAvailable: "-",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            menu: "要員管理",
            functionName: "要員ボード／検索",
            isPersonalPlanAvailable: "-",
            isLightPlanAvailable: "-",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            functionName: "要員情報",
            isPersonalPlanAvailable: "-",
            isLightPlanAvailable: "-",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            functionName: "要員契約情報",
            isPersonalPlanAvailable: "-",
            isLightPlanAvailable: "-",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            functionName: "要員配信メールテンプレート",
            isPersonalPlanAvailable: "-",
            isLightPlanAvailable: "-",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            functionName: "要員配信履歴",
            isPersonalPlanAvailable: "-",
            isLightPlanAvailable: "-",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            functionName: "要員コメント\n(メンション／スレッド)",
            isPersonalPlanAvailable: "-",
            isLightPlanAvailable: "-",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            functionName: "要員ガントチャート／検索",
            isPersonalPlanAvailable: "-",
            isLightPlanAvailable: "-",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            menu: "案件管理",
            functionName: "案件ボード／検索",
            isPersonalPlanAvailable: "-",
            isLightPlanAvailable: "-",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            functionName: "案件情報",
            isPersonalPlanAvailable: "-",
            isLightPlanAvailable: "-",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            functionName: "案件契約情報",
            isPersonalPlanAvailable: "-",
            isLightPlanAvailable: "-",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            functionName: "案件配信メールテンプレート",
            isPersonalPlanAvailable: "-",
            isLightPlanAvailable: "-",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            functionName: "案件配信履歴",
            isPersonalPlanAvailable: "-",
            isLightPlanAvailable: "-",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            functionName: "案件コメント\n(メンション／スレッド)",
            isPersonalPlanAvailable: "-",
            isLightPlanAvailable: "-",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            functionName: "案件ガントチャート／検索",
            isPersonalPlanAvailable: "-",
            isLightPlanAvailable: "-",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            menu: "採用管理",
            functionName: "採用ボード／検索",
            isPersonalPlanAvailable: "-",
            isLightPlanAvailable: "-",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            functionName: "候補者情報",
            isPersonalPlanAvailable: "-",
            isLightPlanAvailable: "-",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            functionName: "候補者コメント\n(メンション／スレッド)",
            isPersonalPlanAvailable: "-",
            isLightPlanAvailable: "-",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            functionName: "採用ガントチャート／検索",
            isPersonalPlanAvailable: "-",
            isLightPlanAvailable: "-",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            menu: "アクション管理",
            functionName: "営業ボード／検索",
            isPersonalPlanAvailable: "-",
            isLightPlanAvailable: "-",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            functionName: "タスク情報",
            isPersonalPlanAvailable: "-",
            isLightPlanAvailable: "-",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            functionName: "タスクコメント\n(メンション／スレッド)",
            isPersonalPlanAvailable: "-",
            isLightPlanAvailable: "-",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            functionName: "営業ガントチャート／検索",
            isPersonalPlanAvailable: "-",
            isLightPlanAvailable: "-",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            functionName: "事務ボード／検索",
            isPersonalPlanAvailable: "-",
            isLightPlanAvailable: "-",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            functionName: "タスク情報",
            isPersonalPlanAvailable: "-",
            isLightPlanAvailable: "-",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            functionName: "タスクコメント\n(メンション／スレッド)",
            isPersonalPlanAvailable: "-",
            isLightPlanAvailable: "-",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            functionName: "事務ガントチャート／検索",
            isPersonalPlanAvailable: "-",
            isLightPlanAvailable: "-",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            menu: "全般",
            functionName: "検索条件テンプレート",
            isPersonalPlanAvailable: "-",
            isLightPlanAvailable: "⚪︎",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            functionName: "コメントテンプレート",
            isPersonalPlanAvailable: "-",
            isLightPlanAvailable: "⚪︎",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            menu: "通知",
            functionName: "お知らせ通知",
            isPersonalPlanAvailable: "⚪︎",
            isLightPlanAvailable: "⚪︎",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            functionName: "アクション通知",
            isPersonalPlanAvailable: "-",
            isLightPlanAvailable: "⚪︎",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            menu: "拡張設定",
            functionName: "アドオン",
            isPersonalPlanAvailable: "-",
            isLightPlanAvailable: "⚪︎",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            menu: "機能設定",
            functionName: "配信メール設定",
            isPersonalPlanAvailable: "⚪︎",
            isLightPlanAvailable: "⚪︎",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            functionName: "マッチングメール設定",
            isPersonalPlanAvailable: "-",
            isLightPlanAvailable: "-",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            menu: "共通設定",
            functionName: "自社情報",
            isPersonalPlanAvailable: "⚪︎",
            isLightPlanAvailable: "⚪︎",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            functionName: "自社取引条件",
            isPersonalPlanAvailable: "⚪︎",
            isLightPlanAvailable: "⚪︎",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            functionName: "ユーザー設定",
            isPersonalPlanAvailable: "⚪︎",
            personalPlanNotes: ["※ユーザー招待不可"],
            isLightPlanAvailable: "⚪︎",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            functionName: "必須項目設定",
            isPersonalPlanAvailable: "⚪︎",
            isLightPlanAvailable: "⚪︎",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            functionName: "タグ設定",
            isPersonalPlanAvailable: "⚪︎",
            personalPlanNotes: ["※保存項目制限あり"],
            isLightPlanAvailable: "⚪︎",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            functionName: "アクション通知設定",
            isPersonalPlanAvailable: "-",
            isLightPlanAvailable: "⚪︎",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            menu: "アカウント",
            functionName: "個人プロフィール",
            isPersonalPlanAvailable: "⚪︎",
            isLightPlanAvailable: "⚪︎",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            functionName: "リマインドメール通知設定",
            isPersonalPlanAvailable: "⚪︎",
            isLightPlanAvailable: "⚪︎",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            functionName: "クレジットカード決済",
            isPersonalPlanAvailable: "⚪︎",
            isLightPlanAvailable: "⚪︎",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            functionName: "お支払い履歴",
            isPersonalPlanAvailable: "⚪︎",
            isLightPlanAvailable: "⚪︎",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
        {
            functionName: "その他、Coming soon...",
            isPersonalPlanAvailable: "-",
            isLightPlanAvailable: "-",
            isStandardPlanAvailable: "⚪︎",
            isProfessionalPlanAvailable: "⚪︎",
        },
    ];
    return options;
};
exports.getPlanTableRows = getPlanTableRows;
