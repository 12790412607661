"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.personnelBoardUploadEndpointsCreator = exports.personnelBoardBasePath = void 0;
var api_1 = require("~/domain/api");
exports.personnelBoardBasePath = "board/personnel";
var personnelBoardUploadEndpointsCreator = function (cardId) {
    var basePath = api_1.Endpoint.getBaseUrl() +
        "/" +
        exports.personnelBoardBasePath +
        "/cards/" +
        cardId;
    var endpoints = {
        profile: basePath + "/profile_image",
        skillSheets: basePath + "/skillsheets",
    };
    return endpoints;
};
exports.personnelBoardUploadEndpointsCreator = personnelBoardUploadEndpointsCreator;
