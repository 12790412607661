// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SalesAttachmentFormItem-container-oNNX5{width:100%;text-align:left}.SalesAttachmentFormItem-userInput-hjKtw{width:100%}.SalesAttachmentFormItem-tooltip-GE6yL{margin-left:5px}.SalesAttachmentFormItem-infoIcon-dWn24{align-self:center}.SalesAttachmentFormItem-select_option-M2h7C{text-align:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/sales/SalesBoardPage/SalesBoardSearchDrawer/SalesAttachmentFormItem/SalesAttachmentFormItem.scss"],"names":[],"mappings":"AAAA,yCACI,UAAW,CACX,eAAgB,CACnB,yCAGG,UAAW,CACd,uCAGG,eAAgB,CACnB,wCAGG,iBAAkB,CACrB,6CAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n\n.select_option {\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `SalesAttachmentFormItem-container-oNNX5`,
	"userInput": `SalesAttachmentFormItem-userInput-hjKtw`,
	"tooltip": `SalesAttachmentFormItem-tooltip-GE6yL`,
	"infoIcon": `SalesAttachmentFormItem-infoIcon-dWn24`,
	"select_option": `SalesAttachmentFormItem-select_option-M2h7C`
};
export default ___CSS_LOADER_EXPORT___;
