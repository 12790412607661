import React from "react";
import PropTypes from "prop-types";

import { Button, Form, Checkbox, Select, Divider, Alert, Row, Col } from "antd";

import BaseForm from "./base/BaseForm";
import styles from "./EditForm.scss";
import { finalized } from "./helpers";

const { Option } = Select;

const formLayout = {
    labelCol: {
        sm: { span: 24 },
        md: { span: 12 },
    },
    wrapperCol: {
        sm: { span: 24 },
        md: { span: 12 },
    },
};

const columnLayout = {
    sm: { span: 24 },
    md: { span: 12 },
};

const paddingColumnLayout = {
    sm: { span: 24 },
    md: { span: 3 },
};

const triColumnLayout = {
    sm: { span: 24 },
    md: { span: 6 },
};

/* Note: This form is update only */
class ContactEmailPreferenceForm extends BaseForm {
    render() {
        const { form, initialData, fieldErrors } = this.props;
        const { getFieldDecorator } = form;
        const Field = getFieldDecorator; // an Alias
        return (
            <Form
                onSubmit={this.handleSubmit}
                {...formLayout}
                className={styles.container}>
                <div
                    hidden={!initialData.contact__organization__is_blacklisted}>
                    <Alert
                        message="所属する取引先がブロックリストに登録されているため、メールは配信されません"
                        type="warning"
                        showIcon
                    />
                </div>
                <Row>
                    <Form.Item
                        label="一斉配信メールを送信する"
                        className={styles.field}
                        help={fieldErrors.willing_to_receive_ads}>
                        {Field("willing_to_receive_ads", {
                            initialValue: initialData.willing_to_receive_ads,
                            valuePropName: "checked",
                        })(<Checkbox />)}
                    </Form.Item>
                </Row>
                <Divider>希望する情報種別</Divider>
                <Row>
                    <Col {...columnLayout}>
                        <Form.Item
                            label="案件 情報"
                            className={styles.field}
                            help={fieldErrors.wants_job_introduction}>
                            {Field("wants_job_introduction", {
                                initialValue:
                                    initialData.wants_job_introduction,
                                valuePropName: "checked",
                            })(<Checkbox />)}
                        </Form.Item>
                    </Col>
                    <Col {...columnLayout}>
                        <Form.Item
                            label="要員 情報"
                            className={styles.field}
                            help={fieldErrors.wants_personnel_introduction}>
                            {Field("wants_personnel_introduction", {
                                initialValue:
                                    initialData.wants_personnel_introduction,
                                valuePropName: "checked",
                            })(<Checkbox />)}
                        </Form.Item>
                    </Col>
                </Row>
                <Divider>希望する内容種別</Divider>
                <Row>
                    <Col {...paddingColumnLayout} />
                    <Col {...triColumnLayout}>
                        <Form.Item
                            label="インフラ 関連"
                            className={styles.field}
                            help={fieldErrors.wants_infra_items}>
                            {Field("wants_infra_items", {
                                initialValue: initialData.wants_infra_items,
                                valuePropName: "checked",
                            })(<Checkbox />)}
                        </Form.Item>
                    </Col>
                    <Col {...triColumnLayout}>
                        <Form.Item
                            label="開発 関連"
                            className={styles.field}
                            help={fieldErrors.wants_develop_items}>
                            {Field("wants_develop_items", {
                                initialValue: initialData.wants_develop_items,
                                valuePropName: "checked",
                            })(<Checkbox />)}
                        </Form.Item>
                    </Col>
                    <Col {...triColumnLayout}>
                        <Form.Item
                            label="営業・事務 関連"
                            className={styles.field}
                            help={fieldErrors.wants_sales_items}>
                            {Field("wants_sales_items", {
                                initialValue: initialData.wants_sales_items,
                                valuePropName: "checked",
                            })(<Checkbox />)}
                        </Form.Item>
                    </Col>
                    <Col {...paddingColumnLayout} />
                </Row>
                <Divider>希望エリア</Divider>
                <Row>
                    <Col {...columnLayout}>
                        <Form.Item
                            label="関東"
                            className={styles.field}
                            help={fieldErrors.wants_location_kanto_japan}>
                            {Field("wants_location_kanto_japan", {
                                initialValue:
                                    initialData.wants_location_kanto_japan,
                                valuePropName: "checked",
                            })(<Checkbox />)}
                        </Form.Item>
                    </Col>
                    <Col {...columnLayout}>
                        <Form.Item
                            label="関西"
                            className={styles.field}
                            help={fieldErrors.wants_location_kansai_japan}>
                            {Field("wants_location_kansai_japan", {
                                initialValue:
                                    initialData.wants_location_kansai_japan,
                                valuePropName: "checked",
                            })(<Checkbox />)}
                        </Form.Item>
                    </Col>
                </Row>
                <Divider>募集要員</Divider>
                <Row>
                    <Col {...paddingColumnLayout} />
                    <Col {...triColumnLayout}>
                        <Form.Item
                            label="正社員"
                            className={styles.field}
                            help={fieldErrors.wants_proper_personnel}>
                            {Field("wants_proper_personnel", {
                                initialValue:
                                    initialData.wants_proper_personnel,
                                valuePropName: "checked",
                            })(<Checkbox />)}
                        </Form.Item>
                    </Col>
                    <Col {...triColumnLayout}>
                        <Form.Item
                            label="個人事業主"
                            className={styles.field}
                            help={fieldErrors.wants_individual_personnel}>
                            {Field("wants_individual_personnel", {
                                initialValue:
                                    initialData.wants_individual_personnel,
                                valuePropName: "checked",
                            })(<Checkbox />)}
                        </Form.Item>
                    </Col>
                    <Col {...triColumnLayout}>
                        <Form.Item
                            label="ロースキル可"
                            className={styles.field}
                            help={fieldErrors.accepts_low_skilled_personnel}>
                            {Field("accepts_low_skilled_personnel", {
                                initialValue:
                                    initialData.accepts_low_skilled_personnel,
                                valuePropName: "checked",
                            })(<Checkbox />)}
                        </Form.Item>
                    </Col>
                    <Col {...paddingColumnLayout} />
                </Row>
                <Divider>可能な契約形態 (要員紹介時)</Divider>
                <Row>
                    <Col {...columnLayout}>
                        <Form.Item
                            label="準委任契約"
                            className={styles.field}
                            help={
                                fieldErrors.accepts_personnel_via_quasi_mandate_contract
                            }>
                            {Field(
                                "accepts_personnel_via_quasi_mandate_contract",
                                {
                                    initialValue:
                                        initialData.accepts_personnel_via_quasi_mandate_contract,
                                    valuePropName: "checked",
                                }
                            )(<Checkbox />)}
                        </Form.Item>
                    </Col>
                    <Col {...columnLayout}>
                        <Form.Item
                            label="派遣契約"
                            className={styles.field}
                            help={
                                fieldErrors.accepts_personnel_via_worker_dispatch_contract
                            }>
                            {Field(
                                "accepts_personnel_via_worker_dispatch_contract",
                                {
                                    initialValue:
                                        initialData.accepts_personnel_via_worker_dispatch_contract,
                                    valuePropName: "checked",
                                }
                            )(<Checkbox />)}
                        </Form.Item>
                    </Col>
                </Row>
                <Divider>商流に関する制限</Divider>
                <Row>
                    <Col {...columnLayout}>
                        <Form.Item
                            label="要員に関する商流制限"
                            className={styles.field}
                            help={fieldErrors.personnel_distribution_limit}>
                            {Field("personnel_distribution_limit", {
                                initialValue:
                                    initialData.personnel_distribution_limit,
                                rules: [{ required: true }],
                            })(
                                <Select style={{ width: 180 }}>
                                    <Select.Option value={0}>
                                        他社要員不可(自社まで)
                                    </Select.Option>
                                    <Select.Option value={255}>制限なし</Select.Option>
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                    <Col {...columnLayout}>
                        <Form.Item
                            label="案件に関する商流制限"
                            className={styles.field}
                            help={fieldErrors.job_distribution_limit}>
                            {Field("job_distribution_limit", {
                                initialValue:
                                    initialData.job_distribution_limit,
                                rules: [{ required: true }],
                            })(
                                <Select style={{ width: 180 }}>
                                    <Select.Option value={0}>
                                        他社案件不可(自社まで)
                                    </Select.Option>
                                    <Select.Option value={255}>制限なし</Select.Option>
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <div className={styles.buttonWrapper}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className={styles.button}>
                        登録
                    </Button>
                    <Button
                        type="default"
                        htmlType="reset"
                        className={styles.button}
                        onClick={this.handleReset}>
                        リセット
                    </Button>
                </div>
            </Form>
        );
    }
}

ContactEmailPreferenceForm.propTypes = {
    resourceURL: PropTypes.string.isRequired,
    initialData: PropTypes.shape({
        // Corresponds to backend API.
        willing_to_receive_ads: PropTypes.bool,
        contact__organization__is_blacklisted: PropTypes.bool,
        wants_job_introduction: PropTypes.bool,
        wants_personnel_introduction: PropTypes.bool,
        wants_infra_items: PropTypes.bool,
        wants_develop_items: PropTypes.bool,
        wants_sales_items: PropTypes.bool,
        accepts_personnel_via_worker_dispatch_contract: PropTypes.bool,
        accepts_personnel_via_quasi_mandate_contract: PropTypes.bool,
        wants_proper_personnel: PropTypes.bool,
        wants_individual_personnel: PropTypes.bool,
        accepts_low_skilled_personnel: PropTypes.bool,
        wants_location_kanto_japan: PropTypes.bool,
        wants_location_kansai_japan: PropTypes.bool,
        personnel_distribution_limit: PropTypes.number,
        job_distribution_limit: PropTypes.number,
    }), // Override in child class and use PropTypes.shape instead.
    fieldErrors: PropTypes.shape({
        willing_to_receive_ads: PropTypes.arrayOf(PropTypes.string),
        wants_job_introduction: PropTypes.arrayOf(PropTypes.string),
        wants_personnel_introduction: PropTypes.arrayOf(PropTypes.string),
        wants_infra_items: PropTypes.arrayOf(PropTypes.string),
        wants_develop_items: PropTypes.arrayOf(PropTypes.string),
        wants_sales_items: PropTypes.arrayOf(PropTypes.string),
        accepts_personnel_via_worker_dispatch_contract: PropTypes.arrayOf(
            PropTypes.string
        ),
        accepts_personnel_via_quasi_mandate_contract: PropTypes.arrayOf(
            PropTypes.string
        ),
        wants_proper_personnel: PropTypes.arrayOf(PropTypes.string),
        wants_individual_personnel: PropTypes.arrayOf(PropTypes.string),
        accepts_low_skilled_personnel: PropTypes.arrayOf(PropTypes.string),
        wants_location_kanto_japan: PropTypes.arrayOf(PropTypes.string),
        wants_location_kansai_japan: PropTypes.arrayOf(PropTypes.string),
        personnel_distribution_limit: PropTypes.arrayOf(PropTypes.string),
        job_distribution_limit: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    submitHandler: PropTypes.func.isRequired,
    resetFormHandler: PropTypes.func.isRequired,
};

ContactEmailPreferenceForm.defaultProps = {
    initialData: {},
};

const ContactEmailPreferenceFormWrapper = finalized(ContactEmailPreferenceForm);
export default ContactEmailPreferenceFormWrapper;
