// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IncludeInvalidSwitchFormItem-container-zdLvr{width:100%;text-align:left}.IncludeInvalidSwitchFormItem-userInput-lcP9h{width:100%}.IncludeInvalidSwitchFormItem-tooltip-L_eH6{margin-left:5px}.IncludeInvalidSwitchFormItem-infoIcon-Y3bYa{align-self:center}.IncludeInvalidSwitchFormItem-switchLabel-X6YTu{display:inline-block;align-self:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/OrganizationSearchPage/OrganizationSearchDrawer/IncludeInvalidSwitchFromItem/IncludeInvalidSwitchFormItem.scss"],"names":[],"mappings":"AAAA,8CACI,UAAW,CACX,eAAgB,CACnB,8CAGG,UAAW,CACd,4CAGG,eAAgB,CACnB,6CAGG,iBAAkB,CACrB,gDAGG,oBAAqB,CACrB,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n\n.switchLabel {\n    display: inline-block;\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `IncludeInvalidSwitchFormItem-container-zdLvr`,
	"userInput": `IncludeInvalidSwitchFormItem-userInput-lcP9h`,
	"tooltip": `IncludeInvalidSwitchFormItem-tooltip-L_eH6`,
	"infoIcon": `IncludeInvalidSwitchFormItem-infoIcon-Y3bYa`,
	"switchLabel": `IncludeInvalidSwitchFormItem-switchLabel-X6YTu`
};
export default ___CSS_LOADER_EXPORT___;
