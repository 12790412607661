import { USER_SEARCH_PAGE } from "./pageIds";

import createSearchPage from "./Factories/createSearchPage";

import { Endpoint } from "../../domain/api";
import { userSearchParamToAPI } from "../../domain/data";

import UserSearchForm from "../Forms/UserSearchForm/UserSearchForm";
import UserTable from "../Tables/UsersTable/UsersTable";

import getDateStr from "../../domain/date";
import UserAddButton from "../Forms/UserAddButton/UserAddButton";
import UserTableDisplaySettingModal from "../Tables/UsersTable/UserTableDisplaySettingModal/UserTableDisplaySettingModal";
import Paths from "../Routes/Paths";

const pageId = USER_SEARCH_PAGE;
const resourceURL = `${Endpoint.getBaseUrl()}/${Endpoint.users}`;

export const convertUserSearchPageResponseDataEntry = (data, index) => ({
    key: index,
    id: data.id,
    user_service_id: data.user_service_id,
    display_name: data.display_name,
    email: data.email,
    tel: data.tel,
    last_login: getDateStr(data.last_login),
    is_active: data.is_active,
    date_joined: getDateStr(data.date_joined),
    first_name: data.first_name,
    last_name: data.last_name,
    role: data.role,
    tel1: data.tel1,
    tel2: data.tel2,
    tel3: data.tel3,
});

const resourceName = "users";
const changeActiveStatusAuthorized = (authorizedActions) => {
    return (
        authorizedActions &&
        authorizedActions[resourceName] &&
        authorizedActions[resourceName]["_all"]
    );
};
const deleteAuthorized = (authorizedActions) => {
    return (
        authorizedActions &&
        authorizedActions[resourceName] &&
        authorizedActions[resourceName]["_all"]
    );
};
const csvAuthorized = (authorizedActions) => {
    return (
        authorizedActions &&
        authorizedActions[resourceName] &&
        authorizedActions[resourceName]["_all"]
    );
};
const columnSettingAuthorized = (authorizedActions) => {
    return (
        authorizedActions &&
        authorizedActions[resourceName] &&
        authorizedActions[resourceName]["_all"]
    );
};
const accessAuthorized = (authorizedActions) => {
    return (
        authorizedActions &&
        authorizedActions[resourceName] &&
        authorizedActions[resourceName]["_all"]
    );
};
const searchTemplateAuthorized = (authorizedActions) => {
    return true;
};
const editAuthorized = (authorizedActions) => {
    return !!authorizedActions?.[resourceName]?.["_all"];
};

const buildBulkEditUrl = (selectedRows) => {
    const query = new URLSearchParams();
    const users = selectedRows.map(
        ({ id, display_name }) => ({ id, display_name })
    );
    query.set("users", JSON.stringify(users));
    return `${Paths.userBulkEdit}?${query}`;
};

const UserSearchPageContainer = createSearchPage(
    pageId,
    "userSearchPage",
    "ユーザー設定",
    UserSearchForm,
    UserTable,
    resourceURL,
    convertUserSearchPageResponseDataEntry,
    userSearchParamToAPI,
    false,
    undefined,
    undefined,
    true,
    undefined,
    undefined,
    undefined,
    false,
    undefined,
    undefined,
    changeActiveStatusAuthorized,
    deleteAuthorized,
    csvAuthorized,
    columnSettingAuthorized,
    accessAuthorized,
    undefined,
    searchTemplateAuthorized,
    resourceName,
    false,  // NOTE(joshua-hashimoto): テーブル操作ボタンは直接設定する
    [UserAddButton],
    [UserTableDisplaySettingModal],
    undefined,
    undefined,
    undefined,
    true,
    editAuthorized,
    buildBulkEditUrl,
    undefined,
    ""
);

export default UserSearchPageContainer;
