// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NewTagModal-field-S1ZKM{max-width:800px;margin:8px 0}.NewTagModal-optionTag-TjVzM{margin-top:3px;margin-bottom:3px;margin-inline-end:4.8px;padding-inline-start:4px;padding-inline-end:4px;white-space:normal}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Common/NewTagModal/NewTagModal.scss"],"names":[],"mappings":"AAAA,yBACI,eAAgB,CAChB,YAAa,CAChB,6BAEG,cAAe,CACf,iBAAkB,CAClB,uBAAwB,CACxB,wBAAyB,CACzB,sBAAuB,CACvB,kBAAmB","sourcesContent":[".field {\n    max-width: 800px;\n    margin: 8px 0;\n}\n.optionTag {\n    margin-top: 3px;\n    margin-bottom: 3px;\n    margin-inline-end: 4.8px;\n    padding-inline-start: 4px;\n    padding-inline-end: 4px;\n    white-space: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"field": `NewTagModal-field-S1ZKM`,
	"optionTag": `NewTagModal-optionTag-TjVzM`
};
export default ___CSS_LOADER_EXPORT___;
