// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RecruitEndDateFormItem-container-ZhszU{width:100%;text-align:left}.RecruitEndDateFormItem-userInput-uPHUj{width:100%}.RecruitEndDateFormItem-tooltip-nszBa{margin-left:5px}.RecruitEndDateFormItem-infoIcon-fn7vx{align-self:center}.RecruitEndDateFormItem-customDatePicker-cR081{width:100%;height:35px}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/recruit/RecruitBoardPage/RecruitBoardSearchDrawer/RecruitEndDateFormItem/RecruitEndDateFormItem.scss"],"names":[],"mappings":"AAAA,wCACE,UAAW,CACX,eAAgB,CACjB,wCAGC,UAAW,CACZ,sCAGC,eAAgB,CACjB,uCAGC,iBAAkB,CACnB,+CAGC,UAAW,CACX,WAAY","sourcesContent":[".container {\n  width: 100%;\n  text-align: left;\n}\n\n.userInput {\n  width: 100%;\n}\n\n.tooltip {\n  margin-left: 5px;\n}\n\n.infoIcon {\n  align-self: center;\n}\n\n.customDatePicker {\n  width: 100%;\n  height: 35px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `RecruitEndDateFormItem-container-ZhszU`,
	"userInput": `RecruitEndDateFormItem-userInput-uPHUj`,
	"tooltip": `RecruitEndDateFormItem-tooltip-nszBa`,
	"infoIcon": `RecruitEndDateFormItem-infoIcon-fn7vx`,
	"customDatePicker": `RecruitEndDateFormItem-customDatePicker-cR081`
};
export default ___CSS_LOADER_EXPORT___;
