"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.projectBoardUploadEndpointsCreator = exports.projectBoardBasePath = void 0;
var api_1 = require("~/domain/api");
exports.projectBoardBasePath = "board/project";
var projectBoardUploadEndpointsCreator = function (cardId) {
    var basePath = api_1.Endpoint.getBaseUrl() + "/" + exports.projectBoardBasePath + "/cards/" + cardId;
    var endpoints = {
        attachments: basePath + "/attachments",
    };
    return endpoints;
};
exports.projectBoardUploadEndpointsCreator = projectBoardUploadEndpointsCreator;
