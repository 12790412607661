// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomProgress-customProgressBar-eQqg_{background-color:#9b9595a1;position:fixed;z-index:10000;width:100vw;height:100vh;top:0;left:0;bottom:0;right:0}.CustomProgress-customProgressBar-eQqg_ div{z-index:100000;position:absolute;align-items:center;justify-content:center;top:40%;left:0;right:0}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Common/CustomProgress/CustomProgress.scss"],"names":[],"mappings":"AAAA,wCACE,0BAA2B,CAC3B,cAAe,CACf,aAAc,CACd,WAAY,CACZ,YAAa,CACb,KAAM,CACN,MAAO,CACP,QAAS,CACT,OAAQ,CATV,4CAWI,cAAe,CACf,iBAAkB,CAClB,kBAAmB,CACnB,sBAAuB,CACvB,OAAQ,CACR,MAAO,CACP,OAAQ","sourcesContent":[".customProgressBar {\n  background-color: #9b9595a1;\n  position: fixed;\n  z-index: 10000;\n  width: 100vw;\n  height: 100vh;\n  top: 0;\n  left: 0;\n  bottom: 0;\n  right: 0;\n  div {\n    z-index: 100000;\n    position: absolute;\n    align-items: center;\n    justify-content: center;\n    top: 40%;\n    left: 0;\n    right: 0;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"customProgressBar": `CustomProgress-customProgressBar-eQqg_`
};
export default ___CSS_LOADER_EXPORT___;
