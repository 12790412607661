// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PersonnelCommentCreateDateFormItem-container-ebaOU{width:100%;text-align:left}.PersonnelCommentCreateDateFormItem-userInput-XD8NZ{width:100%}.PersonnelCommentCreateDateFormItem-tooltip-hwqqX{margin-left:5px}.PersonnelCommentCreateDateFormItem-infoIcon-F7Bt7{align-self:center}.PersonnelCommentCreateDateFormItem-marginBottom-EKI2f{margin-bottom:1%}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/personnel/PersonnelBoardPage/PersonnelBoardSearchDrawer/PersonnelCommentCreateDateFormItem/PersonnelCommentCreateDateFormItem.scss"],"names":[],"mappings":"AAAA,oDACE,UAAW,CACX,eAAgB,CACjB,oDAGC,UAAW,CACZ,kDAGC,eAAgB,CACjB,mDAGC,iBAAkB,CACnB,uDAGC,gBAAiB","sourcesContent":[".container {\n  width: 100%;\n  text-align: left;\n}\n\n.userInput {\n  width: 100%;\n}\n\n.tooltip {\n  margin-left: 5px;\n}\n\n.infoIcon {\n  align-self: center;\n}\n\n.marginBottom {\n  margin-bottom: 1%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PersonnelCommentCreateDateFormItem-container-ebaOU`,
	"userInput": `PersonnelCommentCreateDateFormItem-userInput-XD8NZ`,
	"tooltip": `PersonnelCommentCreateDateFormItem-tooltip-hwqqX`,
	"infoIcon": `PersonnelCommentCreateDateFormItem-infoIcon-F7Bt7`,
	"marginBottom": `PersonnelCommentCreateDateFormItem-marginBottom-EKI2f`
};
export default ___CSS_LOADER_EXPORT___;
