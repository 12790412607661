// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ExceptionalOrganizationFormItem-field-JpUdx{width:100%;text-align:left;margin:8px 0}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Forms/MyCompanyForm/ExceptionalOrganizationFormItem/ExceptionalOrganizationFormItem.scss"],"names":[],"mappings":"AAAA,6CACI,UAAW,CACX,eAAgB,CAChB,YAAa","sourcesContent":[".field {\n    width: 100%;\n    text-align: left;\n    margin: 8px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"field": `ExceptionalOrganizationFormItem-field-JpUdx`
};
export default ___CSS_LOADER_EXPORT___;
