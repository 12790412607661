// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SESBSFServerNameFormItem-tooltip-iwDAc{margin-left:5px;color:#615748}.SESBSFServerNameFormItem-infoIcon-vCyhk{align-self:center}.SESBSFServerNameFormItem-tooltipOverlay-RbDCG{min-width:270px}.SESBSFServerNameFormItem-field-KE1vZ{max-width:800px;margin:8px 0}@media screen and (max-width: 768px){.SESBSFServerNameFormItem-field-KE1vZ{margin:8px auto;width:100%}}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/ScheduledEmailSettingPage/ScheduledEmailSettingForm/ScheduledEmailSettingBaseSettingsForm/SESBSFServerNameFormItem/SESBSFServerNameFormItem.scss","webpack://./frontend/stylesheets/constants.scss"],"names":[],"mappings":"AAEA,wCACI,eAAgB,CAChB,aCAoB,CDCvB,yCAGG,iBAAkB,CACrB,+CAGG,eAAgB,CACnB,sCAGG,eAAgB,CAChB,YAAa,CAKhB,qCAPD,sCAIQ,eAAgB,CAChB,UAAW,CAElB","sourcesContent":["@import \"~coreStylesheet\";\n\n.tooltip {\n    margin-left: 5px;\n    color: $komorebi-brown;\n}\n\n.infoIcon {\n    align-self: center;\n}\n\n.tooltipOverlay {\n    min-width: 270px;\n}\n\n.field {\n    max-width: 800px;\n    margin: 8px 0;\n    @include mobile-devices {\n        margin: 8px auto;\n        width: 100%;\n    }\n}\n","/* アプリケーション全体 */\n$breakpoint-mobile: 768px;\n$width-small-screen: 1500px;\n$primary-color: #a7cf69;\n$komorebi-brown: #615748;\n\n/* ガントチャート関連 */\n$gantt-over70: #003656;\n$gantt-between40to70: #80d6ff;\n$gantt-under40: #d0edff;\n\n@mixin mobile-devices() {\n    @media screen and (max-width: $breakpoint-mobile) {\n        @content;\n    }\n}\n\n@mixin small-screen() {\n    @media screen and (max-width: $width-small-screen) {\n        @content;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": `SESBSFServerNameFormItem-tooltip-iwDAc`,
	"infoIcon": `SESBSFServerNameFormItem-infoIcon-vCyhk`,
	"tooltipOverlay": `SESBSFServerNameFormItem-tooltipOverlay-RbDCG`,
	"field": `SESBSFServerNameFormItem-field-KE1vZ`
};
export default ___CSS_LOADER_EXPORT___;
