// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SalesBoardCardContent-text-QEQAK{display:flex;justify-content:start;padding:10px 0 0 0;font-weight:550}.SalesBoardCardContent-textLabel-CBKfy{display:inline-block;width:3em;text-align:right}.SalesBoardCardContent-tag-YOXAH{margin-bottom:6px}.SalesBoardCardContent-tagRow-MNlSh{margin:0.5rem 0}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/sales/SalesBoardPage/SalesBoardCard/SalesBoardCardContent/SalesBoardCardContent.scss"],"names":[],"mappings":"AAAA,kCACI,YAAa,CACb,qBAAsB,CACtB,kBAAmB,CACnB,eAAgB,CACnB,uCAGG,oBAAqB,CACrB,SAAU,CACV,gBAAiB,CACpB,iCAGG,iBAAkB,CACrB,oCAGG,eAAgB","sourcesContent":[".text {\n    display: flex;\n    justify-content: start;\n    padding: 10px 0 0 0;\n    font-weight: 550;\n}\n\n.textLabel {\n    display: inline-block;\n    width: 3em;\n    text-align: right;\n}\n\n.tag {\n    margin-bottom: 6px;\n}\n\n.tagRow {\n    margin: 0.5rem 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": `SalesBoardCardContent-text-QEQAK`,
	"textLabel": `SalesBoardCardContent-textLabel-CBKfy`,
	"tag": `SalesBoardCardContent-tag-YOXAH`,
	"tagRow": `SalesBoardCardContent-tagRow-MNlSh`
};
export default ___CSS_LOADER_EXPORT___;
