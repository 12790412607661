// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ScheduledEmailSendTotalCountFormItem-container-UjNlx{width:100%;text-align:left}.ScheduledEmailSendTotalCountFormItem-userInput-VOOjv{width:100%}.ScheduledEmailSendTotalCountFormItem-tooltip-AwX1Z{margin-left:5px}.ScheduledEmailSendTotalCountFormItem-infoIcon-OBSvv{align-self:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/ScheduledEmailSearchPage/ScheduledEmailSearchDrawer/ScheduledEmailSendTotalCountFormItem/ScheduledEmailSendTotalCountFormItem.scss"],"names":[],"mappings":"AAAA,sDACI,UAAW,CACX,eAAgB,CACnB,sDAGG,UAAW,CACd,oDAGG,eAAgB,CACnB,qDAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ScheduledEmailSendTotalCountFormItem-container-UjNlx`,
	"userInput": `ScheduledEmailSendTotalCountFormItem-userInput-VOOjv`,
	"tooltip": `ScheduledEmailSendTotalCountFormItem-tooltip-AwX1Z`,
	"infoIcon": `ScheduledEmailSendTotalCountFormItem-infoIcon-OBSvv`
};
export default ___CSS_LOADER_EXPORT___;
