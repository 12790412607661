"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSearchTemplateStarBackOfficeBoardAPIMutation = exports.useSearchTemplateDeleteBackOfficeBoardAPIMutation = exports.useSearchTemplateCreateBackOfficeBoardAPIMutation = exports.useSearchTemplateFetchBackOfficeBoardAPIQuery = void 0;
var react_query_1 = require("react-query");
var AlertMessage_1 = require("~/components/Common/AlertMessage/AlertMessage");
var useCustomMutation_1 = require("~/hooks/useCustomMutation");
var useCustomQuery_1 = require("~/hooks/useCustomQuery");
var api_1 = require("~/networking/api");
var constants_1 = require("~/utils/constants");
var utils_1 = require("~/utils/utils");
var useSearchTemplateFetchBackOfficeBoardAPIQuery = function (_a) {
    var _b = _a === void 0 ? {} : _a, deps = _b.deps, options = _b.options;
    return (0, useCustomQuery_1.useCustomQuery)({
        queryKey: constants_1.QueryKeys.searchTemplate.backOffice.board,
        deps: deps,
        options: __assign({}, options),
        apiRequest: api_1.searchTemplateAPI.backOffice.board.fetch,
    });
};
exports.useSearchTemplateFetchBackOfficeBoardAPIQuery = useSearchTemplateFetchBackOfficeBoardAPIQuery;
var useSearchTemplateCreateBackOfficeBoardAPIMutation = function () {
    var queryClient = (0, react_query_1.useQueryClient)();
    return (0, useCustomMutation_1.useCustomMutation)(api_1.searchTemplateAPI.backOffice.board.create, {
        onSuccess: function (result) {
            (0, AlertMessage_1.customSuccessMessage)(constants_1.SuccessMessages.searchTemplate.create);
            var queryKeys = constants_1.QueryKeys.searchTemplate.backOffice.board;
            queryClient.invalidateQueries(queryKeys);
        },
        onError: function (err) {
            (0, utils_1.apiErrorMessage)(err, constants_1.ErrorMessages.searchTemplate.create);
        },
    });
};
exports.useSearchTemplateCreateBackOfficeBoardAPIMutation = useSearchTemplateCreateBackOfficeBoardAPIMutation;
var useSearchTemplateDeleteBackOfficeBoardAPIMutation = function () {
    var queryClient = (0, react_query_1.useQueryClient)();
    return (0, useCustomMutation_1.useCustomMutation)(api_1.searchTemplateAPI.backOffice.board.delete, {
        onSuccess: function (result) {
            (0, AlertMessage_1.customSuccessMessage)(constants_1.SuccessMessages.searchTemplate.delete);
            var queryKeys = constants_1.QueryKeys.searchTemplate.backOffice.board;
            queryClient.invalidateQueries(queryKeys);
        },
        onError: function (err) {
            (0, utils_1.apiErrorMessage)(err, constants_1.ErrorMessages.searchTemplate.delete);
        },
    });
};
exports.useSearchTemplateDeleteBackOfficeBoardAPIMutation = useSearchTemplateDeleteBackOfficeBoardAPIMutation;
var useSearchTemplateStarBackOfficeBoardAPIMutation = function () {
    var queryClient = (0, react_query_1.useQueryClient)();
    return (0, useCustomMutation_1.useCustomMutation)(api_1.searchTemplateAPI.backOffice.board.star, {
        onSuccess: function (_result) {
            var queryKeys = constants_1.QueryKeys.searchTemplate.backOffice.board;
            queryClient.invalidateQueries(queryKeys);
        },
        onError: function (err) {
            (0, utils_1.apiErrorMessage)(err, constants_1.ErrorMessages.searchTemplate.update);
        },
    });
};
exports.useSearchTemplateStarBackOfficeBoardAPIMutation = useSearchTemplateStarBackOfficeBoardAPIMutation;
