// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ScheduledEmailSendTypeFormItem-container-dTnbD{width:100%;text-align:left}.ScheduledEmailSendTypeFormItem-userInput-K5FHP{width:100%}.ScheduledEmailSendTypeFormItem-tooltip-vnJBS{margin-left:5px}.ScheduledEmailSendTypeFormItem-infoIcon-gNfbz{align-self:center}.ScheduledEmailSendTypeFormItem-select_option-phRq3{text-align:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/ScheduledEmailSearchPage/ScheduledEmailSearchDrawer/ScheduledEmailSendTypeFormItem/ScheduledEmailSendTypeFormItem.scss"],"names":[],"mappings":"AAAA,gDACI,UAAW,CACX,eAAgB,CACnB,gDAGG,UAAW,CACd,8CAGG,eAAgB,CACnB,+CAGG,iBAAkB,CACrB,oDAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n\n.select_option {\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ScheduledEmailSendTypeFormItem-container-dTnbD`,
	"userInput": `ScheduledEmailSendTypeFormItem-userInput-K5FHP`,
	"tooltip": `ScheduledEmailSendTypeFormItem-tooltip-vnJBS`,
	"infoIcon": `ScheduledEmailSendTypeFormItem-infoIcon-gNfbz`,
	"select_option": `ScheduledEmailSendTypeFormItem-select_option-phRq3`
};
export default ___CSS_LOADER_EXPORT___;
