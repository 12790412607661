// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProjBSettleMethodFormItem-marginBottom0-7hXUA{margin-bottom:0}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/project/ProjectBoardPage/ProjectBoardDetailModal/ProjectBoardBaseInfoForm/ProjBSettleMethodFormItem/ProjBSettleMethodFormItem.scss"],"names":[],"mappings":"AAAA,+CACI,eAAgB","sourcesContent":[".marginBottom0 {\n    margin-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"marginBottom0": `ProjBSettleMethodFormItem-marginBottom0-7hXUA`
};
export default ___CSS_LOADER_EXPORT___;
