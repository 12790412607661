// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CheckListItem-rowSpacing-JxLHK{width:100%}.CheckListItem-inputFormItem-sCl7z,.CheckListItem-checkboxFormItem-XcGaA,.CheckListItem-deleteBtn-YmP1Z{margin-bottom:0}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/DataDisplay/CheckList/CheckListFormItem/CheckListItem/CheckListItem.scss"],"names":[],"mappings":"AAAA,gCACI,UAAW,CACd,wGAMG,eAAgB","sourcesContent":[".rowSpacing {\n    width: 100%;\n}\n\n\n.inputFormItem,\n.checkboxFormItem,\n.deleteBtn {\n    margin-bottom: 0; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rowSpacing": `CheckListItem-rowSpacing-JxLHK`,
	"inputFormItem": `CheckListItem-inputFormItem-sCl7z`,
	"checkboxFormItem": `CheckListItem-checkboxFormItem-XcGaA`,
	"deleteBtn": `CheckListItem-deleteBtn-YmP1Z`
};
export default ___CSS_LOADER_EXPORT___;
