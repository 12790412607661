import React from "react";
import { Col, Form, Row, Input, Tooltip } from "antd";
import { QuestionCircleFilled } from "@ant-design/icons";
import styles from "./CorporateNameFormItem.scss";
import { Links, iconCustomColor } from "~/utils/constants";

const CorporateNameFormItem = ({ disabled = false }) => {
    const fieldName = "name";

    return (
        <Col span={24} style={{ marginBottom: "1%" }}>
            <Row className={styles.container}>
                <Col span={23}>
                    <Form.Item colon={false} name={fieldName} noStyle>
                        <Input
                            className={styles.userInput}
                            placeholder="取引先名"
                            allowClear
                            disabled={disabled}
                        />
                    </Form.Item>
                </Col>
                <Col span={1} className={styles.infoIcon}>
                    <Tooltip
                        title={
                            <span>
                                <a
                                    href={Links.helps.filter.partialMatch}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    部分一致検索
                                </a>
                            </span>
                        }>
                        <QuestionCircleFilled
                            style={{ color: iconCustomColor }}
                            className={styles.tooltip}
                        />
                    </Tooltip>
                </Col>
            </Row>
        </Col>
    );
};

export default CorporateNameFormItem;
