// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProjectPaymentFormItem-container-2BedQ{width:100%;text-align:left}.ProjectPaymentFormItem-userInput-q2NLk{width:100%}.ProjectPaymentFormItem-tooltip-lBbGK{margin-left:5px}.ProjectPaymentFormItem-infoIcon-Comc2{align-self:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/project/ProjectBoardPage/ProjectBoardSearchDrawer/ProjectPaymentFormItem/ProjectPaymentFormItem.scss"],"names":[],"mappings":"AAAA,wCACI,UAAW,CACX,eAAgB,CACnB,wCAGG,UAAW,CACd,sCAGG,eAAgB,CACnB,uCAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ProjectPaymentFormItem-container-2BedQ`,
	"userInput": `ProjectPaymentFormItem-userInput-q2NLk`,
	"tooltip": `ProjectPaymentFormItem-tooltip-lBbGK`,
	"infoIcon": `ProjectPaymentFormItem-infoIcon-Comc2`
};
export default ___CSS_LOADER_EXPORT___;
