// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BackOfficeAssigneeStatsGraph-titleText-lsD0W{margin-top:-2px !important;margin-bottom:-2px !important;text-align:left}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/DashboardPage/GraphCard/GraphCard.scss"],"names":[],"mappings":"AAAA,8CACI,0BAA2B,CAC3B,6BAA8B,CAC9B,eAAgB","sourcesContent":[".titleText {\n    margin-top: -2px !important;\n    margin-bottom: -2px !important;\n    text-align: left;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleText": `BackOfficeAssigneeStatsGraph-titleText-lsD0W`
};
export default ___CSS_LOADER_EXPORT___;
