// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SalesBoardListFormItem-container-l_OmW{width:100%;text-align:left}.SalesBoardListFormItem-userInput-PlMon{width:100%}.SalesBoardListFormItem-tooltip-hBsHr{margin-left:5px}.SalesBoardListFormItem-infoIcon-OaD1g{align-self:center}.SalesBoardListFormItem-select_option-YFAdr{text-align:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/sales/SalesBoardPage/SalesBoardSearchDrawer/SalesBoardListFormItem/SalesBoardListFormItem.scss"],"names":[],"mappings":"AAAA,wCACI,UAAW,CACX,eAAgB,CACnB,wCAGG,UAAW,CACd,sCAGG,eAAgB,CACnB,uCAGG,iBAAkB,CACrB,4CAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n\n.select_option {\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `SalesBoardListFormItem-container-l_OmW`,
	"userInput": `SalesBoardListFormItem-userInput-PlMon`,
	"tooltip": `SalesBoardListFormItem-tooltip-hBsHr`,
	"infoIcon": `SalesBoardListFormItem-infoIcon-OaD1g`,
	"select_option": `SalesBoardListFormItem-select_option-YFAdr`
};
export default ___CSS_LOADER_EXPORT___;
