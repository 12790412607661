// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProjectBoardPage-focusColor-c6tlA:focus{border-color:#A7CF69;background:#A7CF69}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/project/ProjectBoardPage/ProjectBoardPage.scss"],"names":[],"mappings":"AAAA,yCACI,oBAAqB,CACrB,kBAAmB","sourcesContent":[".focusColor:focus{\n    border-color: #A7CF69;\n    background: #A7CF69;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"focusColor": `ProjectBoardPage-focusColor-c6tlA`
};
export default ___CSS_LOADER_EXPORT___;
