// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NotificationItem-checkboxGroupFormItem-Wo8MI{margin-bottom:0;padding-bottom:0}.NotificationItem-contentWrapper-oJBz4{padding-top:5px}.NotificationItem-errorMessageWrapper-Lazly{height:24px}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/ActionNotificationSettingsPage/components/NotificationItem/NotificationItem.scss"],"names":[],"mappings":"AAAA,8CACI,eAAgB,CAChB,gBAAiB,CACpB,uCAGG,eAAgB,CACnB,4CAGG,WAAY","sourcesContent":[".checkboxGroupFormItem {\n    margin-bottom: 0;\n    padding-bottom: 0;\n}\n\n.contentWrapper {\n    padding-top: 5px;\n}\n\n.errorMessageWrapper {\n    height: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkboxGroupFormItem": `NotificationItem-checkboxGroupFormItem-Wo8MI`,
	"contentWrapper": `NotificationItem-contentWrapper-oJBz4`,
	"errorMessageWrapper": `NotificationItem-errorMessageWrapper-Lazly`
};
export default ___CSS_LOADER_EXPORT___;
