// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BackOfficeGanttInfo-over-AgOeJ{color:#EB5A46}.BackOfficeGanttInfo-today-fPXWf{color:#FFAB4A}.BackOfficeGanttInfo-prevDay-dcI1g{color:#1890FF}.BackOfficeGanttInfo-completed-cp9o7{color:#A7CF69}.BackOfficeGanttInfo-default-SaURP{color:#BFBFBF}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/backOffice/BackOfficeGanttPage/BackOfficeGanttInfo/BackOfficeGanttInfo.scss"],"names":[],"mappings":"AAEA,gCACI,aAAc,CACjB,iCAGG,aAAc,CACjB,mCAGG,aAAc,CACjB,qCAGG,aAAc,CACjB,mCAGG,aAAc","sourcesContent":["@import \"~coreStylesheet\";\n\n.over {\n    color: #EB5A46;\n}\n\n.today {\n    color: #FFAB4A;\n}\n\n.prevDay {\n    color: #1890FF;\n}\n\n.completed {\n    color: #A7CF69;\n}\n\n.default {\n    color: #BFBFBF;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"over": `BackOfficeGanttInfo-over-AgOeJ`,
	"today": `BackOfficeGanttInfo-today-fPXWf`,
	"prevDay": `BackOfficeGanttInfo-prevDay-dcI1g`,
	"completed": `BackOfficeGanttInfo-completed-cp9o7`,
	"default": `BackOfficeGanttInfo-default-SaURP`
};
export default ___CSS_LOADER_EXPORT___;
