// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SalesEndDateFormItem-container-p2grS{width:100%;text-align:left}.SalesEndDateFormItem-userInput-GxjzI{width:100%}.SalesEndDateFormItem-tooltip-fHuOE{margin-left:5px}.SalesEndDateFormItem-infoIcon-aUz_y{align-self:center}.SalesEndDateFormItem-customDatePicker-hAYQD{width:100%;height:35px}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/sales/SalesGanttPage/SalesGanttSearchDrawer/SalesEndDateFormItem/SalesEndDateFormItem.scss"],"names":[],"mappings":"AAAA,sCACE,UAAW,CACX,eAAgB,CACjB,sCAGC,UAAW,CACZ,oCAGC,eAAgB,CACjB,qCAGC,iBAAkB,CACnB,6CAGC,UAAW,CACX,WAAY","sourcesContent":[".container {\n  width: 100%;\n  text-align: left;\n}\n\n.userInput {\n  width: 100%;\n}\n\n.tooltip {\n  margin-left: 5px;\n}\n\n.infoIcon {\n  align-self: center;\n}\n\n.customDatePicker {\n  width: 100%;\n  height: 35px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `SalesEndDateFormItem-container-p2grS`,
	"userInput": `SalesEndDateFormItem-userInput-GxjzI`,
	"tooltip": `SalesEndDateFormItem-tooltip-fHuOE`,
	"infoIcon": `SalesEndDateFormItem-infoIcon-aUz_y`,
	"customDatePicker": `SalesEndDateFormItem-customDatePicker-hAYQD`
};
export default ___CSS_LOADER_EXPORT___;
