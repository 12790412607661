// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TagTable-tooltipCopy-BnPdj:hover span{display:block}.TagTable-isSkillIcon-WwUfU{color:#a7cf69}.TagTable-isSkillCol-mgl4F{margin:0 auto}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/TagPage/TagTable/TagTable.scss","webpack://./frontend/stylesheets/constants.scss"],"names":[],"mappings":"AAEA,uCAGY,aAAc,CACjB,4BAKL,aCRmB,CDStB,2BAGG,aAAe","sourcesContent":["@import \"~coreStylesheet\";\n\n.tooltipCopy {\n    &:hover {\n        span {\n            display: block;\n        }\n    }\n}\n   \n.isSkillIcon {\n    color: $primary-color;\n}\n\n.isSkillCol {\n    margin:  0 auto;\n}\n","/* アプリケーション全体 */\n$breakpoint-mobile: 768px;\n$width-small-screen: 1500px;\n$primary-color: #a7cf69;\n$komorebi-brown: #615748;\n\n/* ガントチャート関連 */\n$gantt-over70: #003656;\n$gantt-between40to70: #80d6ff;\n$gantt-under40: #d0edff;\n\n@mixin mobile-devices() {\n    @media screen and (max-width: $breakpoint-mobile) {\n        @content;\n    }\n}\n\n@mixin small-screen() {\n    @media screen and (max-width: $width-small-screen) {\n        @content;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltipCopy": `TagTable-tooltipCopy-BnPdj`,
	"isSkillIcon": `TagTable-isSkillIcon-WwUfU`,
	"isSkillCol": `TagTable-isSkillCol-mgl4F`
};
export default ___CSS_LOADER_EXPORT___;
