// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PersonnelStartDateFormItem-container-TKTP7{width:100%;text-align:left}.PersonnelStartDateFormItem-userInput-BjQL2{width:100%}.PersonnelStartDateFormItem-tooltip-refLN{margin-left:5px}.PersonnelStartDateFormItem-infoIcon-sx6i9{align-self:center}.PersonnelStartDateFormItem-customDatePicker-fsXWj{width:100%;height:35px}.PersonnelStartDateFormItem-marginBottom-lKWhV{margin-bottom:1%}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/personnel/PersonnelBoardPage/PersonnelBoardSearchDrawer/PersonnelStartDateFormItem/PersonnelStartDateFormItem.scss"],"names":[],"mappings":"AAAA,4CACE,UAAW,CACX,eAAgB,CACjB,4CAGC,UAAW,CACZ,0CAGC,eAAgB,CACjB,2CAGC,iBAAkB,CACnB,mDAGC,UAAW,CACX,WAAY,CACb,+CAGC,gBAAiB","sourcesContent":[".container {\n  width: 100%;\n  text-align: left;\n}\n\n.userInput {\n  width: 100%;\n}\n\n.tooltip {\n  margin-left: 5px;\n}\n\n.infoIcon {\n  align-self: center;\n}\n\n.customDatePicker {\n  width: 100%;\n  height: 35px;\n}\n\n.marginBottom {\n  margin-bottom: 1%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PersonnelStartDateFormItem-container-TKTP7`,
	"userInput": `PersonnelStartDateFormItem-userInput-BjQL2`,
	"tooltip": `PersonnelStartDateFormItem-tooltip-refLN`,
	"infoIcon": `PersonnelStartDateFormItem-infoIcon-sx6i9`,
	"customDatePicker": `PersonnelStartDateFormItem-customDatePicker-fsXWj`,
	"marginBottom": `PersonnelStartDateFormItem-marginBottom-lKWhV`
};
export default ___CSS_LOADER_EXPORT___;
