import { Col } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import styles from './Description.scss';

const Description = ({ term, children, span }) => (
  <Col span={span}>
    <div className={styles.description}>
      <div className={styles.term}>{`${term}:`}</div>
      <div className={styles.detail}>{children}</div>
    </div>
  </Col>
);

Description.propTypes = {
  term: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  span: PropTypes.number,
};

Description.defaultProps = {
  children: '',
  span: 24, // 24 = occupy whole 1 row.
};

export default Description;
