// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SharedEmailsTable-itemSpacing-S4kdv{margin-right:6px}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Tables/SharedEmailsTable/SharedEmailsTable.scss"],"names":[],"mappings":"AAAA,qCACI,gBAAiB","sourcesContent":[".itemSpacing {\n    margin-right: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"itemSpacing": `SharedEmailsTable-itemSpacing-S4kdv`
};
export default ___CSS_LOADER_EXPORT___;
