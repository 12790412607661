// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PersonnelNameFormItem-container-Sazid{width:100%;text-align:left}.PersonnelNameFormItem-userInput-CKBWu{width:50%}.PersonnelNameFormItem-tooltip-yVwvB{margin-left:5px}.PersonnelNameFormItem-infoIcon-zCDBW{align-self:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/personnel/PersonnelBoardPage/PersonnelBoardSearchDrawer/PersonnelNameFormItem/PersonnelNameFormItem.scss"],"names":[],"mappings":"AAAA,uCACI,UAAW,CACX,eAAgB,CACnB,uCAGG,SAAU,CACb,qCAGG,eAAgB,CACnB,sCAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 50%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PersonnelNameFormItem-container-Sazid`,
	"userInput": `PersonnelNameFormItem-userInput-CKBWu`,
	"tooltip": `PersonnelNameFormItem-tooltip-yVwvB`,
	"infoIcon": `PersonnelNameFormItem-infoIcon-zCDBW`
};
export default ___CSS_LOADER_EXPORT___;
