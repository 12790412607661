import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Col, Form, Row, Tabs, Tooltip } from "antd";
import { InfoCircleTwoTone } from "@ant-design/icons";
import validateJapaneseMessages from "../validateMessages";
import CompanyInfoPane from "./CompanyInfoPane";
import TransactionTermsPane from "./TransactionTermsPane";
import BackButton from "../../Common/BackButton/BackButton";
import { warningColor } from "~/utils/constants";
import Path from "../../Routes/Paths";
import {
    TooltipContentLink,
    AlertTooltipContentLink,
} from "~/components/Common/TooltipContentLink/TooltipContentLink";
import styles from "./MyCompanyForm.scss";
import { useSetRecoilState } from "recoil";
import {  ajaxItemSelect as ajaxItemSelectAtom } from "~/recoil/atom";

const { TabPane } = Tabs;

const MyCompanyForm = ({
    resourceURL,
    initialData = {},
    fieldErrors,
    submitHandler,
}) => {
    const [form] = Form.useForm();
    const [errorFields, setErrorFields] = useState([]);
    const [currentTabKey, setCurrentTabKey] = useState(1);
    const setAjaxItemSelect = useSetRecoilState(ajaxItemSelectAtom)

    const handleSubmitError = ({ values, errorFields, outOfDate }) => {
        if (errorFields) {
            let errorFieldNames = errorFields.map((field) => {
                return field["name"][0];
            });
            setState({ errorFields: errorFieldNames }); // stateに変更を入れないとエラーが画面に反映されないため
        }
    };

    const handleSubmitWithCheck = (values) => {
        const submitValue = { ...values };
        submitValue["name"] = submitValue["name"].trim();
        submitHandler(submitValue);
    };

    const infoTooltip = () => {
        return (
            <Tooltip
                title={
                    <span>
                        <a
                            href={`${Path.organizations}/register`}
                            target="_blank"
                            rel="noopener noreferrer">
                            取引先登録／編集
                        </a>
                        の取引条件と
                        <a
                            href={`${Path.myCompany}`}
                            target="_blank"
                            rel="noopener noreferrer">
                            自社プロフィール
                        </a>
                        の取引条件の双方をクリアしなければ取引対象外となり、
                        <a
                            href={`${Path.scheduledMails}/register`}
                            target="_blank"
                            rel="noopener noreferrer">
                            配信メール予約／編集
                        </a>
                        の宛先一覧に表示されません。
                    </span>
                }>
                <InfoCircleTwoTone twoToneColor={warningColor} />
            </Tooltip>
        );
    };

    const tooltipMessage = (value, color) => {
        if (value == "Organizations" && !color) {
            return (
                <TooltipContentLink
                    to={`${Path.organizations}/register`}
                    target="_blank"
                    rel="noopener noreferrer"
                    title="取引先登録／編集"
                />
            );
        } else if (value == "Organizations" && color == "warning") {
            return (
                <AlertTooltipContentLink
                    to={`${Path.organizations}/register`}
                    target="_blank"
                    rel="noopener noreferrer"
                    title="取引先登録／編集"
                />
            );
        } else if (value == "MyCompany" && !color) {
            return (
                <TooltipContentLink
                    to={`${Path.myCompany}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    title="自社プロフィール"
                />
            );
        } else if (value == "MyCompany" && color == "warning") {
            return (
                <AlertTooltipContentLink
                    to={`${Path.myCompany}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    title="自社プロフィール"
                />
            );
        } else if (value == "ScheduledMails" && !color) {
            return (
                <TooltipContentLink
                    to={`${Path.scheduledMails}/register`}
                    target="_blank"
                    rel="noopener noreferrer"
                    title="配信メール予約／編集"
                />
            );
        } else if (value == "ScheduledMails" && color == "warning") {
            return (
                <AlertTooltipContentLink
                    to={`${Path.scheduledMails}/register`}
                    target="_blank"
                    title="配信メール予約／編集"
                />
            );
        }
    };

    useEffect(() => {
        if (initialData && initialData.name && form) {
            form.setFieldsValue(initialData);
        }
    }, [initialData]);

    useEffect(() => {
      if (initialData &&
          initialData.exceptional_organizations &&
            initialData.exceptional_organizations.length) {
            const arr = [];
            initialData.exceptional_organizations.map((value, keyNumber) => {
                arr.push({
                    keyNumber,
                    value
                })
            })
            setAjaxItemSelect(arr)
        } else {
            setAjaxItemSelect([])
        }
    }, [initialData]);

    return (
        <Form
            onFinish={handleSubmitWithCheck}
            onFinishFailed={handleSubmitError}
            className={styles.container}
            form={form}
            validateMessages={validateJapaneseMessages}
            labelAlign="right">
            <Tabs
                defaultActiveKey="1"
                tabBarExtraContent={
                    currentTabKey === 2 ? infoTooltip() : undefined
                }
                onTabClick={(key) => setCurrentTabKey(+key)}>
                <TabPane tab="自社情報">
                    <CompanyInfoPane
                        initialData={initialData}
                        fieldErrors={fieldErrors}
                        form={form}
                        tooltipMessage={tooltipMessage}
                    />
                </TabPane>
                <TabPane tab="取引条件" key="2">
                    <TransactionTermsPane
                        initialData={initialData}
                        fieldErrors={fieldErrors}
                        tooltipMessage={tooltipMessage}
                    />
                </TabPane>
            </Tabs>
            <Form.Item>
                <Row justify="start">
                    <Col>
                        <BackButton />
                    </Col>
                    <Col>
                        <Form.Item shouldUpdate>
                            {() => (
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    disabled={!!form.getFieldsError().filter(({ errors }) => errors.length).length}
                                >
                                    更新
                                </Button>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
            </Form.Item>
        </Form>
    );
};

MyCompanyForm.propTypes = {
    resourceURL: PropTypes.string.isRequired,
    initialData: PropTypes.shape({
        // Corresponds to backend API.
        name: PropTypes.string,
        domain_name: PropTypes.string,
        address: PropTypes.string,
        building: PropTypes.string,
        capital_man_yen: PropTypes.number,
        has_p_mark_or_isms: PropTypes.bool,
        has_invoice_system: PropTypes.bool,
        has_haken: PropTypes.bool,
        has_distribution: PropTypes.bool,
        capital_man_yen_required_for_transactions: PropTypes.number,
        establishment_year: PropTypes.number,
        p_mark_or_isms: PropTypes.bool,
    }), // Override in child class and use PropTypes.shape instead.
    fieldErrors: PropTypes.shape({
        name: PropTypes.arrayOf(PropTypes.string),
        domain_name: PropTypes.arrayOf(PropTypes.string),
        address: PropTypes.arrayOf(PropTypes.string),
        building: PropTypes.arrayOf(PropTypes.string),
        capital_man_yen: PropTypes.arrayOf(PropTypes.string),
        has_p_mark_or_isms: PropTypes.arrayOf(PropTypes.bool),
        has_invoice_system: PropTypes.arrayOf(PropTypes.bool),
        has_haken: PropTypes.arrayOf(PropTypes.string),
        has_distribution: PropTypes.arrayOf(PropTypes.string),
        capital_man_yen_required_for_transactions: PropTypes.arrayOf(
            PropTypes.string
        ),
        establishment_year: PropTypes.arrayOf(PropTypes.string),
        p_mark_or_isms: PropTypes.arrayOf(PropTypes.bool),
    }).isRequired,
    submitHandler: PropTypes.func.isRequired,
};

export default MyCompanyForm;
