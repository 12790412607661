// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PersonnelContractEndFormItem-container-ZUhrA{width:100%;text-align:left}.PersonnelContractEndFormItem-userInput-Q30lS{width:100%}.PersonnelContractEndFormItem-tooltip-ev4zM{margin-left:5px}.PersonnelContractEndFormItem-infoIcon-JvBOY{align-self:center}.PersonnelContractEndFormItem-customDatePicker-jDv_e{width:100%;height:35px}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/personnel/PersonnelBoardPage/PersonnelBoardSearchDrawer/PersonnelContractEndFormItem/PersonnelContractEndFormItem.scss"],"names":[],"mappings":"AAAA,8CACE,UAAW,CACX,eAAgB,CACjB,8CAGC,UAAW,CACZ,4CAGC,eAAgB,CACjB,6CAGC,iBAAkB,CACnB,qDAGC,UAAW,CACX,WAAY","sourcesContent":[".container {\n  width: 100%;\n  text-align: left;\n}\n\n.userInput {\n  width: 100%;\n}\n\n.tooltip {\n  margin-left: 5px;\n}\n\n.infoIcon {\n  align-self: center;\n}\n\n.customDatePicker {\n  width: 100%;\n  height: 35px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PersonnelContractEndFormItem-container-ZUhrA`,
	"userInput": `PersonnelContractEndFormItem-userInput-Q30lS`,
	"tooltip": `PersonnelContractEndFormItem-tooltip-ev4zM`,
	"infoIcon": `PersonnelContractEndFormItem-infoIcon-JvBOY`,
	"customDatePicker": `PersonnelContractEndFormItem-customDatePicker-jDv_e`
};
export default ___CSS_LOADER_EXPORT___;
