import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Row, Col, Form, Tooltip, Badge } from "antd";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import IncludeInvalidSwitchFormItem from "./IncludeInvalidSwitchFromItem/IncludeInvalidSwitchFormItem";
import UsernameFormItem from "./UsernameFormItem/UsernameFormItem";
import EmailFormItem from "./EmailFormItem/EmailFormItem";
import IdFormItem from "./IdFormItem/IdFormItem";
import TelFormItem from "./TelFormItem/TelFormItem";
import RoleFormItem from "./RoleFormItem/RoleFormItem";
import LastLoginFormItem from "./LastLoginFormItem/LastLoginFormItem";
import moment from "moment";
import { useDisplaySettingAPI } from "~/hooks/useDisplaySettingAPI";
import SearchMenuDrawer from "~/components/Common/SearchMenuDrawer/SearchMenuDrawer";
import { useSearchSync } from "~/hooks/useSearch";
import styles from "./UserSearchForm.scss";

const filterType = "search";

const emptyData = {
    display_name: "",
    email: "",
    role: undefined,
    last_login: [],
    first_name: "",
    last_name: "",
    tel1: "",
    tel2: "",
    tel3: "",
    inactive_filter: false,
    user_service_id: ""
};

const UserSearchForm = React.forwardRef(
    ({ tableName, initialData = emptyData, submitHandler = () => {} }, ref) => {
        const [form] = Form.useForm();
        const [isSearchDrawerOpen, setIsSearchDrawerOpen] = useState(false);
        const [unshowSearchFields, setUnshowSearchFields] = useState([]);
        const [filledSearchFieldCount, setFilledSearchFieldCount] = useState(0);
        const { displaySetting } = useSelector(
            (state) => state.displaySettingPage
        );
        const { updateDisplaySetting } = useDisplaySettingAPI();
        const { syncToUrl, queryParamsToObj } = useSearchSync();

        const countFilterField = (formValues) => {
            let count = 0;
            if (!!formValues.inactive_filter) {
                count += 1;
            }
            if (!!formValues.first_name || !!formValues.last_name) {
                count += 1;
            }
            if (!!formValues.user_service_id) {
                count += 1;
            }
            if (!!formValues.role && !!formValues.role.length) {
                count += 1;
            }
            if (!!formValues.email) {
                count += 1;
            }
            if (!!formValues.tel1 || !!formValues.tel2 || !!formValues.tel3) {
                count += 1;
            }
            if (!!formValues.last_login && !!formValues.last_login.length) {
                count += 1;
            }
            return count;
        };

        const submitToFilter = (values) => {
            syncToUrl(values);
            submitHandler(values);
            const count = countFilterField(values);
            setFilledSearchFieldCount(count);
        };

        const onReset = () => {
            form.setFieldsValue(emptyData);
            submitHandler(emptyData);
            syncToUrl({});
            const count = countFilterField({});
            setFilledSearchFieldCount(count);
        };

        const onClear = async(fieldKey, value) => {
            try {
                const values = await form.validateFields();
                values[fieldKey] = value;
                submitHandler(values);
            } catch (err) {
                console.error(err);
            }
        };

        const menuItems = [
            {
                fieldKey: "name",
                searchField: UsernameFormItem,
                fieldNames: ["last_name", "first_name"],
                onClear,
            },
            {
                fieldKey: "user_service_id",
                searchField: IdFormItem,
                fieldNames: ["user_service_id"],
                onClear,
            },
            {
                fieldKey: "email",
                searchField: EmailFormItem,
                fieldNames: ["email"],
                onClear,
            },
            {
                fieldKey: "role",
                searchField: RoleFormItem,
                fieldNames: ["role"],
                onClear: undefined,
            },
            {
                fieldKey: "tel",
                searchField: TelFormItem,
                fieldNames: ["tel1", "tel2", "tel3"],
                onClear,
            },
            {
                fieldKey: "last_login",
                searchField: LastLoginFormItem,
                fieldNames: ["last_login"],
                onClear: undefined,
            },
        ];

        const onUpdateSearchField = () => {
            const postData = {
                content: {
                    users: {
                        search: [...unshowSearchFields],
                    },
                },
            };
            updateDisplaySetting(postData);
        };

        const onDrawerClose = () => {
            setIsSearchDrawerOpen(false);
        };

        const onFieldAdd = (newUnshowFields) => {
            setUnshowSearchFields(newUnshowFields);
        };

        const onFieldRemove = (newUnshowFields, fieldNames) => {
            setUnshowSearchFields(newUnshowFields);
            form.resetFields(fieldNames);
            const values = form.getFieldsValue();
            submitHandler(values);
            syncToUrl(values);
            const count = countFilterField(values);
            setFilledSearchFieldCount(count);
        };

        const getUrlSearchFields = () => {
            const queryParamsObj = queryParamsToObj();
            const unshowList = getUnshowSearchFields();
            const isNameExist = unshowList.includes("name");
            if (isNameExist) {
                queryParamsObj.last_name = undefined;
                queryParamsObj.first_name = undefined;
            }
            const isUserServiceIDExist = unshowList.includes("user_service_id");
            if (isUserServiceIDExist) {
                queryParamsObj.user_service_id = undefined;
            }
            const isRoleExist = unshowList.includes("role");
            if (isRoleExist) {
                queryParamsObj.role = undefined;
            }
            const isEmailExist = unshowList.includes("email");
            if (isEmailExist) {
                queryParamsObj.email = undefined;
            }
            const isTelExist = unshowList.includes("tel");
            if (isTelExist) {
                queryParamsObj.tel1 = undefined;
                queryParamsObj.tel2 = undefined;
                queryParamsObj.tel3 = undefined;
            }
            const isLastLoginExist = unshowList.includes("last_login");
            if (isLastLoginExist) {
                queryParamsObj.last_login = undefined;
            }
            return queryParamsObj;
        };

        useEffect(() => {
            setInitialFormSearch();
            const tempData = { ...initialData };
            const lastLogins = tempData.last_login ?? [];
            if (lastLogins.length) {
                tempData.last_login = lastLogins.map((lastLogin) =>
                    moment(lastLogin)
                );
            }
            const queryParamsObj = getUrlSearchFields();
            const formValues = {
                ...tempData,
                ...queryParamsObj,
            };
            form.setFieldsValue(formValues);
            const count = countFilterField(formValues);
            setFilledSearchFieldCount(count);
        }, [displaySetting]);

        const getUnshowSearchFields = () => {
            const dataExist =
                displaySetting &&
                displaySetting[tableName] &&
                displaySetting[tableName][filterType];
            return dataExist ? displaySetting[tableName][filterType] : [];
        };

        const setInitialFormSearch = () => {
            const unshowSearchFields = getUnshowSearchFields();
            setUnshowSearchFields(unshowSearchFields);
        };

        return (
            <>
                <Col>
                    <Row justify="end">
                        <Button.Group>
                            <Button
                                type="primary"
                                size="small"
                                icon={<SearchOutlined />}
                                onClick={() => setIsSearchDrawerOpen(true)}>
                                絞り込み検索
                            </Button>
                            {filledSearchFieldCount ? (
                                <Tooltip title="現在検索中のフィールドを全てクリアします">
                                    <Badge
                                        count={filledSearchFieldCount}
                                        size="small">
                                        <Button
                                            type="primary"
                                            size="small"
                                            icon={<CloseOutlined />}
                                            onClick={onReset}
                                        />
                                    </Badge>
                                </Tooltip>
                            ) : undefined}
                        </Button.Group>
                    </Row>
                </Col>
                <SearchMenuDrawer
                    isDrawerOpen={isSearchDrawerOpen}
                    onDrawerClose={onDrawerClose}
                    form={form}
                    menuItems={menuItems}
                    unshowList={unshowSearchFields}
                    setInitialFormSearch={setInitialFormSearch}
                    setUnshowList={setUnshowSearchFields}
                    onReset={onReset}
                    onUpdateUnshowList={onUpdateSearchField}
                    otherControls={[<IncludeInvalidSwitchFormItem />]}
                    onFilter={submitToFilter}
                    searchTemplate={undefined}
                    onFieldAdd={onFieldAdd}
                    onFieldRemove={onFieldRemove}
                />
            </>
        );
    }
);

export default UserSearchForm;
