// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BackOfficeBoardTabPaneContent-controlButtonsWrapper-nAd5x{margin-top:2rem}.BackOfficeBoardTabPaneContent-section-mD8UJ{margin-top:3rem}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/backOffice/BackOfficeBoardPage/BackOfficeBoardDetailModal/BackOfficeBoardTabPaneContent/BackOfficeBoardTabPaneContent.scss"],"names":[],"mappings":"AAAA,2DACI,eAAgB,CACnB,6CAGG,eAAgB","sourcesContent":[".controlButtonsWrapper {\n    margin-top: 2rem;\n}\n\n.section {\n    margin-top: 3rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"controlButtonsWrapper": `BackOfficeBoardTabPaneContent-controlButtonsWrapper-nAd5x`,
	"section": `BackOfficeBoardTabPaneContent-section-mD8UJ`
};
export default ___CSS_LOADER_EXPORT___;
