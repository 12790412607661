import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
    Button,
    Card,
    PageHeader,
    Spin,
    Tooltip,
    Typography,
    Col,
    Row,
} from "antd";
import { CommentListCreator } from "~/components/DataDisplay/CommentList/CommentList";
import Paths from "../../Routes/Paths";
import ErrorScreen from "../../Screens/ErrorScreen";
import {
    fetchAction,
    patchAction,
    deleteAction,
    clearAction,
    fetchDisplaySettingAction,
    fetchApi,
} from "../../../actions/data";
import styles from "../page.scss";
import { showDeleteModal } from "../../Feedbacks/Modal/Modal";
import { Endpoint } from "../../../domain/api";
import NotFoundPage from "../NotFoundPage";
import {
    AUTHORIZED_ACTION_LOADING,
    AUTHORIZED_ACTION_LOADED,
    PARENT_CANNOT_SUBMIT_WHEN_NEW_COMMENT_EXISTS,
    COMMENT_NEW_CLEAR,
    COMMENT_EDIT_CLEAR,
    PARENT_CANNOT_SUBMIT_WHEN_EDIT_COMMENT_EXISTS,
    PARENT_CANNOT_SUBMIT_WHEN_REPLY_COMMENT_EXISTS,
    RESET_ERROR_MESSAGE,
} from "../../../actions/actionTypes";
import AboutThisData from "~/components/Common/AboutThisData/AboutThisData";
import CustomBackToTop from "~/components/Common/CustomBackToTop/CustomBackToTop";
import { MY_PROFILE_PAGE, USER_EDIT_PAGE, USER_REGISTER_PAGE, USER_SEARCH_PAGE } from "../pageIds";
import { ErrorMessages, SuccessMessages, TooltipMessages } from "~/utils/constants";
import {
    customSuccessMessage,
    customErrorMessage,
    DEFAULT_LINE_SEPARATOR
} from "~/components/Common/AlertMessage/AlertMessage";

const { Title } = Typography;

const defaultResponseConverter = (data) => data;
const defaultFormConverter = (data) => data;
const defaultRedirectTo = Paths.index;
const displaySettingURL = `${Endpoint.getBaseUrl()}/${Endpoint.displaySetting}`;

const isEmpty = (array) => array.length === 0;

/**
 * This Factory creates a page component class that has common several handlers and data loading methods.
 * @param {string} pageId - A PageId that is used to dispatch action to corresponding reducer.
 * @param {string} reducerName - A reducerName that correspond to the page and that is defined in components/reducers/pages.js.
 * @param {string} pageTitle - A page title string, that will be rendered to a page header section.
 * @param {class} FormClass - A Subclass of component/Forms/base/Baseform, that corresponds to this page's resource.
 * @param {string} resourceURL - An endpoint URL of the target resources, correspond to the backend list API.
 * @param {string} resourceSuffix - An additional suffix of URL of the target resources, correspond to the backend list API.
 * for example, if the backend API has an URL of /contact/xxx-xxx-xxx../profile, you must specify this value as /profile.
 * @param {string} redirectPathAfterUpdate - An URL that will be invoked after the resource has updated or deleted.
 * @param {function} convertResponseDataEntry - A callback function that receives one object and returns new converted object.
 * @param {function} convertFormDataToAPI - A callback function that convert the form data to API Body data.
 * @param {string} commentsReducerName - A Reducer name that handles actions related to comment component. specify if you want to use comment list in this page.
 * @return {element} A React component.
 */
const createEditPage = (
    pageId,
    reducerName,
    pageTitle,
    FormClass,
    resourceURL,
    resourceSuffix = "",
    redirectTo = defaultRedirectTo,
    convertResponseDataEntry = defaultResponseConverter,
    convertFormDataToAPI = defaultFormConverter,
    commentsReducerName = undefined,
    commentTemplateUrl = undefined,
    deleteAuthorized = () => {
        return true;
    },
    accessAuthorized = () => {
        return true;
    },
    resourceName = undefined,
    useDisplaySetting = true,
    getPageTitle = () => {
        return pageTitle;
    },
    backIcon = true,
    returnPath = Paths.index,
    newCommentReducerName = undefined,
    editCommentReducerName = undefined,
    replyCommentReducerName = undefined
) => {
    const CommentList = CommentListCreator(
        pageId,
        commentsReducerName,
        commentTemplateUrl,
        newCommentReducerName,
        editCommentReducerName,
        replyCommentReducerName
    );

    const Page = class extends Component {
        constructor(props) {
            super(props);
            const {
                match: {
                    params: { id },
                },
            } = this.props;
            this.resourceId = id ? id + resourceSuffix : resourceSuffix; // Get resource id from given URL, via props from react router.
        }
        state = {
            hasRanFinalActions: false,
        };

        componentDidMount() {
            const { token, dispatch } = this.props;
            dispatch(
                fetchAction(
                    pageId,
                    token,
                    resourceURL,
                    this.resourceId,
                    convertResponseDataEntry
                )
            );
            if (useDisplaySetting) this.fetchDisplaySettingData();
            dispatch({
                type: COMMENT_NEW_CLEAR,
            });
            dispatch({
                type: COMMENT_EDIT_CLEAR,
            });
        }

        fetchDisplaySettingData() {
            const { token, dispatch } = this.props;
            dispatch(
                fetchDisplaySettingAction(pageId, token, displaySettingURL)
            );
        }

        componentDidUpdate() {
            // Extracting props.
            const { history, pageState, dispatch } = this.props;
            const { deleted, updated, message, errorMessage, displaySetting } =
                pageState;
            if (errorMessage) {
                customErrorMessage(errorMessage, {lineSeparator: DEFAULT_LINE_SEPARATOR});
                dispatch({ type: pageId + RESET_ERROR_MESSAGE });
            }
            // Redirect after resource modification.
            if (deleted || updated) {
                if (!this.state.hasRanFinalActions) {
                    // Popup messages if necessary.
                    if (message) {
                      customSuccessMessage(message);
                    }
                    if (redirectTo) {
                        history.push(redirectTo);
                    } else {
                        history.goBack();
                    }
                    this.setState({
                        hasRanFinalActions: true,
                    });
                }
            }
        }

        componentWillUnmount() {
            const { dispatch } = this.props;
            dispatch(clearAction(pageId)); // Make sure to clear the state of forms, especially after delete.
        }

        submitHandler = (values) => {
            const { token, dispatch, newCommentState, editCommentState, replyCommentState } =
                this.props;

            if (
                newCommentState !== undefined &&
                typeof newCommentState === "object"
            ) {
                const { commentValue: newCommentValue } = newCommentState;
                if (newCommentValue) {
                    dispatch({
                        type: PARENT_CANNOT_SUBMIT_WHEN_NEW_COMMENT_EXISTS,
                    });
                    return;
                }
            }

            if (
                editCommentState !== undefined &&
                typeof editCommentState === "object"
            ) {
                const { commentValue: editCommentValue } = editCommentState;
                if (editCommentValue) {
                    dispatch({
                        type: PARENT_CANNOT_SUBMIT_WHEN_EDIT_COMMENT_EXISTS,
                    });
                    return;
                }
            }

            if (
                replyCommentState !== undefined &&
                typeof replyCommentState === "object"
            ) {
                const { commentValue: replyCommentValue } = replyCommentState;
                if (replyCommentValue) {
                    dispatch({
                        type: PARENT_CANNOT_SUBMIT_WHEN_REPLY_COMMENT_EXISTS,
                    });
                    return;
                }
            }

            dispatch(
                patchAction(
                    pageId,
                    token,
                    resourceURL,
                    this.resourceId,
                    convertFormDataToAPI(values),
                    convertResponseDataEntry
                )
            );
        };

        resetFormHandler = () => {
            const { dispatch, token } = this.props;
            dispatch(
                fetchAction(
                    pageId,
                    token,
                    resourceURL,
                    this.resourceId,
                    convertResponseDataEntry
                )
            );
        };

        deleteHandler = () => {
            const { dispatch, token } = this.props;
            const successMessage = pageId === USER_EDIT_PAGE ? SuccessMessages.user.delete : SuccessMessages.generic.delete
            dispatch(deleteAction(pageId, token, resourceURL, this.resourceId, { message: successMessage }));
        };

        aboutThisData = () => {
            const {
                pageState: { data },
            } = this.props;
            const showData =
                data.created_time ||
                data.created_user ||
                data.modified_time ||
                data.modified_user;
            if (showData) {
                return <AboutThisData key="aboutThisData" data={data} />;
            } else {
                return undefined;
            }
        };

        renderExtra = () => {
            const isNotUserRegisterPage = pageId !== USER_REGISTER_PAGE;
            const extra = [];
            if (isNotUserRegisterPage) {
                return [...extra, this.aboutThisData()];
            }
            return extra;
        };

        render() {
            const {
                pageState,
                authorizedActions,
                match: {
                    params: { resourcename, typename },
                },
            } = this.props;

            const {
                canNotDelete,
                loading,
                data,
                fieldErrors,
                errorMessage,
                tagResisterResult,
                displaySetting,
            } = pageState;
            const initialLoadFailed =
                errorMessage && Object.keys(data).length === 0;

            const comments = (
                <div>
                    <Title level={5}>コメント一覧</Title>
                    <CommentList
                        resourceUrl={`${resourceURL}/${this.resourceId}/${Endpoint.commentUrlSuffix}`}
                    />
                </div>
            );

            const renderDeleteButton = () => {
                const { currentUserId } = this.props;
                const button = (
                    <Button
                        type="danger"
                        onClick={showDeleteModal(
                            this.deleteHandler,
                            reducerName,
                        )}
                        htmlType="button"
                        hidden={canNotDelete}
                        disabled={
                            !deleteAuthorized(authorizedActions, data) ||
                            (pageId === USER_EDIT_PAGE && this.resourceId === currentUserId)
                        }>
                        削除
                    </Button>
                );
                if (!deleteAuthorized(authorizedActions, data)) {
                    return (
                        <Tooltip title={ErrorMessages.isNotAuthorized}>
                            {button}
                        </Tooltip>
                    )
                }
                if (pageId === USER_EDIT_PAGE && this.resourceId === currentUserId) {
                    return (
                        <Tooltip title={TooltipMessages.userEdit.cannotDeleteUser}>
                            {button}
                        </Tooltip>
                    )
                }
                return button;
            }

            const contentBody = (
                <Card bodyStyle={{ padding: "8px" }}>
                    <FormClass
                        resourceURL={resourceURL}
                        resourceId={this.resourceId}
                        initialData={data}
                        fieldErrors={fieldErrors}
                        submitHandler={this.submitHandler}
                        resetFormHandler={this.resetFormHandler}
                        pageId={pageId}
                        tagResisterResult={tagResisterResult}
                        authorizedActions={authorizedActions}
                        selectedRequireItemKeys={
                            displaySetting &&
                            displaySetting[resourceName] &&
                            displaySetting[resourceName]["require"]
                                ? displaySetting[resourceName]["require"]
                                : []
                        }
                        comments={commentsReducerName ? comments : <div />}
                        deleteButton={renderDeleteButton()}
                        resourceName={resourcename}
                        typeName={typename}
                        commentsReducerName={commentsReducerName}
                    />
                </Card>
            );

            if (!accessAuthorized(authorizedActions, data)) {
                return (
                    <div className={styles.container}>
                        <NotFoundPage {...this.props} />
                    </div>
                );
            }

            const extra = this.renderExtra();

            return (
                <div className={styles.container}>
                    <CustomBackToTop />
                    <Spin spinning={loading}>
                        {backIcon ? (
                            <PageHeader
                                className={styles.pageHeader}
                                title={getPageTitle(this.props)}
                                extra={extra}
                            />
                        ) : (
                            <PageHeader
                                className={styles.pageHeader}
                                title={getPageTitle(this.props)}
                                backIcon={false}
                                extra={extra}
                            />
                        )}
                        {initialLoadFailed ? (
                            <ErrorScreen message={errorMessage} />
                        ) : (
                            contentBody
                        )}
                    </Spin>
                </div>
            );
        }
    };

    Page.propTypes = {
        dispatch: PropTypes.func.isRequired,
        token: PropTypes.string.isRequired,
        role: PropTypes.string.isRequired,
        authorizedActions: PropTypes.object.isRequired,
        currentUserId: PropTypes.string,
        history: PropTypes.shape({
            goBack: PropTypes.func.isRequired,
            push: PropTypes.func.isRequired,
        }).isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                id: PropTypes.string, // Sometimes it becomes empty.
            }).isRequired,
        }).isRequired,
        pageState: PropTypes.shape({
            canNotDelete: PropTypes.bool.isRequired,
            updated: PropTypes.bool.isRequired,
            deleted: PropTypes.bool.isRequired,
            loading: PropTypes.bool.isRequired,
            message: PropTypes.string.isRequired,
            errorMessage: PropTypes.string.isRequired,
            data: PropTypes.object.isRequired, // Just passing to child component.
            fieldErrors: PropTypes.object.isRequired, // Just passing to a child component.
            tagResisterResult: PropTypes.string,
            displaySetting: PropTypes.object,
        }).isRequired,
    };

    Page.displayName = pageId;

    // Link Reducer state to Component props.
    function mapStateToProps(state) {
        return {
            token: state.login.token,
            authorizedActions: state.login.authorizedActions,
            role: state.login.role,
            currentUserId: state.login.userId,
            pageState: state[reducerName],
            commentState: state[commentsReducerName],
            newCommentState: state[newCommentReducerName],
            editCommentState: state[editCommentReducerName],
            replyCommentState: state[replyCommentReducerName],
        };
    }

    return connect(mapStateToProps)(Page);
};

export default createEditPage;
