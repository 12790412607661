// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EmailSearchStaffFormItem-container-X0xHp{width:100%;text-align:left}.EmailSearchStaffFormItem-userInput-XjjnA{width:100%}.EmailSearchStaffFormItem-tooltip-Samdm{margin-left:5px}.EmailSearchStaffFormItem-infoIcon-kLTcI{align-self:center}.EmailSearchStaffFormItem-marginBottom-_LgCM{margin-bottom:1%}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/SharedEmailSearchPage/SharedEmailSearchDrawer/EmailSearchStaffFormItem/EmailSearchStaffFormItem.scss"],"names":[],"mappings":"AAAA,0CACI,UAAW,CACX,eAAgB,CACnB,0CAGG,UAAW,CACd,wCAGG,eAAgB,CACnB,yCAGG,iBAAkB,CACrB,6CAGG,gBAAiB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n\n.marginBottom {\n    margin-bottom: 1%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `EmailSearchStaffFormItem-container-X0xHp`,
	"userInput": `EmailSearchStaffFormItem-userInput-XjjnA`,
	"tooltip": `EmailSearchStaffFormItem-tooltip-Samdm`,
	"infoIcon": `EmailSearchStaffFormItem-infoIcon-kLTcI`,
	"marginBottom": `EmailSearchStaffFormItem-marginBottom-_LgCM`
};
export default ___CSS_LOADER_EXPORT___;
