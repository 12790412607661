// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CheckListTemplateListItem-contaienr-GrEDS{width:100%}.CheckListTemplateListItem-title-FNEnn{text-align:left}.CheckListTemplateListItem-editActionButton-xOY9D{margin-left:5px}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/DataDisplay/CheckList/CheckListTempalteListItem/CheckListTemplateListItem.scss"],"names":[],"mappings":"AAAA,2CACI,UAAW,CACd,uCAGG,eAAgB,CACnB,kDAGG,eAAgB","sourcesContent":[".contaienr {\n    width: 100%;\n}\n\n.title {\n    text-align: left;\n}\n\n.editActionButton {\n    margin-left: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contaienr": `CheckListTemplateListItem-contaienr-GrEDS`,
	"title": `CheckListTemplateListItem-title-FNEnn`,
	"editActionButton": `CheckListTemplateListItem-editActionButton-xOY9D`
};
export default ___CSS_LOADER_EXPORT___;
