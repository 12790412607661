import React, { useState } from "react";
import {Button, Col, Form, Input, Popover, Row, Typography} from "antd";
import { PlusOutlined, SettingOutlined } from "@ant-design/icons";
import Board from "@asseinfo/react-kanban";
import HeaderMenuBoard from "~/components/Common/BoardCommon/HeaderMenuBoard/HeaderMenuBoard";
import PersonnelBoardCreatePopover from "~/components/Pages/personnel/PersonnelBoardPage/PersonnelBoardCreatePopover/PersonnelBoardCreatePopover";
import ProjectBoardCreatePopover from "~/components/Pages/project/ProjectBoardPage/ProjectBoardCreatePopover/ProjectBoardCreatePopover";
import RecruitBoardCreatePopover from "~/components/Pages/recruit/RecruitBoardPage/RecruitBoardCreatePopover/RecruitBoardCreatePopover";
import SalesBoardCreatePopover from "~/components/Pages/sales/SalesBoardPage/SalesBoardCreatePopover/SalesBoardCreatePopover";
import BackOfficeBoardCreatePopover from "~/components/Pages/backOffice/BackOfficeBoardPage/BackOfficeBoardCreatePopover/BackOfficeBoardCreatePopover";
import MenuBoardSettings from "./MenuBoardSettingsComponent/MenuBoardSettingsComponent";
import {
    usePersonnelBoardCreateCardAPIMutation,
    usePersonnelBoardUpdateCardOrderAPIMutation,
    usePersonnelBordUpdateListOrderAPIMutation,
    usePersonnelBoardUpdateListTitleAPIMutation,
} from "~/hooks/personnel/board";
import {
    useProjectBoardChangeCardPositionAPIMutation,
    useProjectBoardCreateCardAPIMutation,
    useProjectBoardUpdateListTitleAPIMutation,
    useProjectBoardUpdateListOrderAPIMutation,
} from "~/hooks/project/board";
import {
    useRecruitBoardUpdateCardOrderAPIMutation,
    useRecruitBoardCreateCardAPIMutation,
    useRecruitBoardUpdateListOrderAPIMutation,
    useRecruitBoardUpdateListTitleAPIMutation,
} from "~/hooks/recruit/board";
import {
    useSalesBoardUpdateCardOrderAPIMutation,
    useSalesBoardCreateCardAPIMutation,
    useSalesBoardUpdateListOrderAPIMutation,
    useSalesBoardUpdateListTitleAPIMutation,
} from "~/hooks/sales/board";
import {
    useBackOfficeBoardUpdateCardOrderAPIMutation,
    useBackOfficeBoardCreateCardAPIMutation,
    useBackOfficeBoardUpdateListOrderAPIMutation,
    useBackOfficeBoardUpdateListTitleAPIMutation,
} from "~/hooks/backOffice/board";
import { customErrorMessage } from "../../AlertMessage/AlertMessage";
import { ErrorMessages } from "~/utils/constants";
import styles from "./BoardBaseComponent.scss";

const { Title, Text } = Typography;

const TITLE_MAX_LENGTH = 17;

const EditableTitleForm = ({ title, onChange  }) => {
    const [form] = Form.useForm();

    const onSubmit = () => {
        const newTitle = form.getFieldValue("title");
        onChange(newTitle);
    };

    return (
        <Form
            form={form}
            initialValues={{ title: title }}
        >
            <Form.Item
                name="title"
                rules={[
                    { required: true, message: ErrorMessages.form.required },
                    { max: TITLE_MAX_LENGTH, message: ErrorMessages.validation.length.max17 }
                ]}
            >
                <Input
                    autoFocus={true}
                    placeholder="リスト名"
                    onBlur={onSubmit}
                    onKeyUp={(e) => {
                        if (e.nativeEvent.isComposing || e.key !== "Enter") return;
                        onSubmit();
                    }}
                    style={{width: "240px"}}
                />
            </Form.Item>
        </Form>
    );
};

const EditableTitle = ({ listAuthorized, title, onChange }) => {
    const [isEditing, setIsEditing] = useState(false);

    const onClickTitle = () => {
        if (!listAuthorized) return;
        setIsEditing(true);
    }

    const onBlur = (newTitle) => {
        setIsEditing(false);
        onChange(newTitle);
    };

    return (
        <>
            {!isEditing && (
                <Title
                    level={5}
                    className={styles.titleText}
                    onClick={onClickTitle}
                >
                    {title}
                </Title>
            )}
            {isEditing && (<EditableTitleForm title={title} onChange={onBlur} />)}
        </>
    );
}

const iconButtonStyle = {
    backgroundColor: "#eeeeee",
};

function BoardBaseComponent({
    cardType,
    boardData,
    renderCard,
    onSettings,
    onCreate,
    updateAuthorized,
    archiveAuthorized,
    listAuthorized,
}) {
    const { mutate: createNewPersonnel } =
        usePersonnelBoardCreateCardAPIMutation();
    const { mutate: changePersonnelCardOrder } =
        usePersonnelBoardUpdateCardOrderAPIMutation();
    const { mutate: changePersonnelListOrder } =
        usePersonnelBordUpdateListOrderAPIMutation();
    const { mutate: changePersonnelBoardListTitle } =
        usePersonnelBoardUpdateListTitleAPIMutation();
    const { mutate: createNewProject } = useProjectBoardCreateCardAPIMutation();
    const { mutate: changeProjectCardOrder } =
        useProjectBoardChangeCardPositionAPIMutation()
    const { mutate: changeProjectListOrder } =
        useProjectBoardUpdateListOrderAPIMutation();
    const { mutate: changeProjectBoardListTitle } =
        useProjectBoardUpdateListTitleAPIMutation();
    const { mutate: createNewRecruit } = useRecruitBoardCreateCardAPIMutation();
    const { mutate: changeRecruitCardOrder } =
        useRecruitBoardUpdateCardOrderAPIMutation();
    const { mutate: changeRecruitListOrder } =
        useRecruitBoardUpdateListOrderAPIMutation();
    const { mutate: changeRecruitBoardListTitle } =
        useRecruitBoardUpdateListTitleAPIMutation();
    const { mutate: createNewSales } = useSalesBoardCreateCardAPIMutation();
    const { mutate: changeSalesCardOrder } =
        useSalesBoardUpdateCardOrderAPIMutation();
    const { mutate: changeSalesListOrder } =
        useSalesBoardUpdateListOrderAPIMutation();
    const { mutate: changeSalesBoardListTitle } =
        useSalesBoardUpdateListTitleAPIMutation();
    const { mutate: createNewBackOffice } =
        useBackOfficeBoardCreateCardAPIMutation();
    const { mutate: changeBackOfficeCardOrder } =
        useBackOfficeBoardUpdateCardOrderAPIMutation();
    const { mutate: changeBackOfficeListOrder } =
        useBackOfficeBoardUpdateListOrderAPIMutation();
    const { mutate: changeBackOfficeBoardListTitle } =
        useBackOfficeBoardUpdateListTitleAPIMutation();

    const isPersonnelBoard = cardType === "personnel";

    const renderColumnHeader = ({ id, title, listCardTotal }) => {
        const [isDropDownPlus, setIsDropDownPlus] = useState(false);
        const [isVisibleSetting, setVisibleSetting] = useState(false);
        const onCreatePersonnel = (values) => {
            const { lastNameInitial, firstNameInitial } = values;
            setIsDropDownPlus(false);
            createNewPersonnel(
                {
                    listId: id,
                    lastNameInitial,
                    firstNameInitial,
                },
                {
                    onSuccess: (response) => {
                        const result = response.data;
                        onCreate?.(result.id);
                    },
                }
            );
        };

        const onCreateProject = (values) => {
            const { detail } = values;
            setIsDropDownPlus(false);
            createNewProject(
                {
                    listId: id,
                    detail,
                },
                {
                    onSuccess: (response) => {
                        const result = response.data;
                        onCreate?.(result.id);
                    },
                }
            );
        };

        const onCreateRecruit = (values) => {
            const { detail } = values;
            setIsDropDownPlus(false);
            createNewRecruit(
                {
                    listId: id,
                    detail,
                },
                {
                    onSuccess: (response) => {
                        const result = response.data;
                        onCreate?.(result.id);
                    },
                }
            );
        };

        const onCreateSales = (values) => {
            const { detail } = values;
            setIsDropDownPlus(false);
            createNewSales(
                {
                    listId: id,
                    detail,
                },
                {
                    onSuccess: (response) => {
                        const result = response.data;
                        onCreate?.(result.id);
                    },
                }
            );
        };

        const onCreateBackOffice = (values) => {
            const { detail } = values;
            setIsDropDownPlus(false);
            createNewBackOffice(
                {
                    listId: id,
                    detail,
                },
                {
                    onSuccess: (response) => {
                        const result = response.data;
                        onCreate?.(result.id);
                    },
                }
            );
        };

        const onCreateCard = () => {
            setIsDropDownPlus(true);
        };

        const onClosePlus = () => {
            setIsDropDownPlus(false);
        };

        const handleVisiblePlusChange = (newVisible) => {
            setIsDropDownPlus(newVisible);
        };

        const handleTitleChange = (value) => {
            if (value === title) return;
            if (value && value.replace(/\s+/g, "").length > 0) {
                if (value.length > TITLE_MAX_LENGTH) return;
                if (cardType === "personnel") {
                    changePersonnelBoardListTitle({ listId: id, title: value });
                } else if (cardType === "project") {
                    changeProjectBoardListTitle({ listId: id, title: value });
                } else if (cardType === "recruit") {
                    changeRecruitBoardListTitle({ listId: id, title: value });
                } else if (cardType === "sales") {
                    changeSalesBoardListTitle({ listId: id, title: value });
                } else if (cardType === "back_office") {
                    changeBackOfficeBoardListTitle({ listId: id, title: value });
                }
            }
        };

        return (
            <Col key={id} span={24} style={{ marginBottom: "1%" }}>
                <Row justify="space-between" align="middle">
                    <Col>
                        <Row justify="start" align="middle">
                            <Col span={24} className={styles.headerContainer}>
                                <EditableTitle
                                    title={title}
                                    listAuthorized={listAuthorized}
                                    onChange={handleTitleChange}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row justify="space-between" align="middle">
                    <Col className={styles.headerContainer}>
                        {listCardTotal !== undefined &&
                            <Text type="secondary">
                                {`${listCardTotal} カード`}
                            </Text>
                        }
                    </Col>
                    <Col className={styles.headerButtons}>
                        <Row gutter={5}>
                            <Col>
                                <Popover
                                    key={id}
                                    content={
                                        isPersonnelBoard ? (
                                            <PersonnelBoardCreatePopover
                                                listId={id}
                                                onClose={onClosePlus}
                                                onCreate={onCreatePersonnel}
                                                isVisible={isDropDownPlus}
                                            />
                                        ) : cardType === "project" ? (
                                            <ProjectBoardCreatePopover
                                                listId={id}
                                                onClose={onClosePlus}
                                                onCreate={onCreateProject}
                                                isVisible={isDropDownPlus}
                                            />
                                        ) : cardType === "recruit" ? (
                                            <RecruitBoardCreatePopover
                                                listId={id}
                                                onClose={onClosePlus}
                                                onCreate={onCreateRecruit}
                                                isVisible={isDropDownPlus}
                                            />
                                        ) : cardType === "sales" ? (
                                            <SalesBoardCreatePopover
                                                listId={id}
                                                onClose={onClosePlus}
                                                onCreate={onCreateSales}
                                                isVisible={isDropDownPlus}
                                            />
                                        ) : cardType === "back_office" ? (
                                            <BackOfficeBoardCreatePopover
                                                listId={id}
                                                onClose={onClosePlus}
                                                onCreate={onCreateBackOffice}
                                                isVisible={isDropDownPlus}
                                            />
                                        ) : null
                                    }
                                    visible={isDropDownPlus}
                                    onVisibleChange={handleVisiblePlusChange}
                                    trigger={["click"]}
                                    placement="bottomRight">
                                    <Button
                                        type="text"
                                        icon={<PlusOutlined />}
                                        onClick={onCreateCard}
                                        style={iconButtonStyle}
                                    />
                                </Popover>
                            </Col>
                            <Col>
                                <Popover
                                    title={
                                        <HeaderMenuBoard
                                            title="リスト操作"
                                            onClose={() =>
                                                setVisibleSetting(false)
                                            }
                                        />
                                    }
                                    content={
                                        <MenuBoardSettings
                                            cardType={cardType}
                                            onSettings={onSettings}
                                            setVisibleSetting={
                                                setVisibleSetting
                                            }
                                            listId={id}
                                            sortAuthorized={updateAuthorized}
                                            updateAuthorized={updateAuthorized}
                                            archiveAuthorized={
                                                archiveAuthorized
                                            }
                                            listAuthorized={listAuthorized}
                                        />
                                    }
                                    trigger={["click"]}
                                    visible={isVisibleSetting}
                                    placement="topRight"
                                    onVisibleChange={(v) =>
                                        setVisibleSetting(v)
                                    }>
                                    <Button
                                        type="text"
                                        icon={<SettingOutlined />}
                                        onClick={() => setVisibleSetting(true)}
                                        style={iconButtonStyle}
                                    />
                                </Popover>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        );
    };

    const onCardDragEnd = (card, from, to) => {
        if (!updateAuthorized) {
            customErrorMessage(ErrorMessages.isNotAuthorized);
            return;
        }
        const cardId = card.id;
        if (isPersonnelBoard) {
            changePersonnelCardOrder({ cardId, position: {from, to}});
        } else if (cardType === "project") {
            changeProjectCardOrder({ cardId, position: {from, to} });
        } else if (cardType === "recruit") {
            changeRecruitCardOrder({ cardId, position: {from, to} });
        } else if (cardType === "sales") {
            changeSalesCardOrder({ cardId, position: {from, to} });
        } else if (cardType === "back_office") {
            changeBackOfficeCardOrder({ cardId, position: {from, to} });
        }
    };

    const onColumnDragEnd = (column, from, to) => {
        if (!updateAuthorized) {
            customErrorMessage(ErrorMessages.isNotAuthorized);
            return;
        }
        const listId = column.id;
        if (isPersonnelBoard) {
            changePersonnelListOrder({listId, from, to});
        } else if (cardType === "project") {
            changeProjectListOrder({listId, from, to});
        } else if (cardType === "recruit") {
            changeRecruitListOrder({listId, from, to});
        } else if (cardType === "sales") {
            changeSalesListOrder({listId, from, to});
        } else if (cardType === "back_office") {
            changeBackOfficeListOrder({listId, from, to});
        }
    };

    return (
        <div className={styles.boardContainer}>
            <Board
                renderColumnHeader={renderColumnHeader}
                renderCard={renderCard}
                allowRemoveColumn={false}
                allowRenameColumn={false}
                disableColumnDrag={!listAuthorized}
                onColumnDragEnd={listAuthorized ? onColumnDragEnd : () => {}}
                onCardDragEnd={onCardDragEnd}>
                {boardData}
            </Board>
        </div>
    );
}

export default BoardBaseComponent;
