"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.backOfficeBoard = exports.salesBoard = exports.recruitBoard = exports.projectBoard = exports.personnelBoard = exports.ajaxItemSelect = exports.isCsvDownSuccessAtom = exports.isAppLoading = exports.isCsvDownloading = void 0;
var recoil_1 = require("recoil");
var constants_1 = require("~/utils/constants");
exports.isCsvDownloading = (0, recoil_1.atom)({
    key: constants_1.QueryKeys.csvDownload.isLoading,
    default: false,
});
exports.isAppLoading = (0, recoil_1.atom)({
    key: constants_1.QueryKeys.others.isAppLoading,
    default: false,
});
exports.isCsvDownSuccessAtom = (0, recoil_1.atom)({
    key: constants_1.QueryKeys.csvDownload.isSuccess,
    default: false,
});
exports.ajaxItemSelect = (0, recoil_1.atom)({
    key: constants_1.QueryKeys.ajaxSelect.items,
    default: [],
});
exports.personnelBoard = (0, recoil_1.atom)({
    key: constants_1.QueryKeys.personnel.personnel,
    default: {
        isCreateCheckItem: false,
        checkListId: '',
        isUpdatePersonnel: false,
        personnelId: '',
        dataCreatedPersonnel: {
            isCreated: false,
            isDropDownPlus: false,
        }
    },
});
exports.projectBoard = (0, recoil_1.atom)({
    key: constants_1.QueryKeys.project.project,
    default: {
        isCreateCheckItem: false,
        checkListId: '',
        projectId: '',
        dataCreatedProject: {
            isCreated: false,
            isDropDownPlus: false,
        }
    },
});
exports.recruitBoard = (0, recoil_1.atom)({
    key: constants_1.QueryKeys.recruit.recruit,
    default: {
        isCreateCheckItem: false,
        checkListId: "",
        isUpdateRecruit: false,
        recruitId: "",
        dataCreatedRecruit: {
            isCreated: false,
            isDropDownPlus: false,
        }
    },
});
exports.salesBoard = (0, recoil_1.atom)({
    key: constants_1.QueryKeys.sales.sales,
    default: {
        isCreateCheckItem: false,
        checkListId: "",
        isUpdateSales: false,
        salesId: "",
        dataCreatedSales: {
            isCreated: false,
            isDropDownPlus: false,
        }
    },
});
exports.backOfficeBoard = (0, recoil_1.atom)({
    key: constants_1.QueryKeys.backOffice.backOffice,
    default: {
        isCreateCheckItem: false,
        checkListId: "",
        isUpdateBackOffice: false,
        backOfficeId: "",
        dataCreatedBackOffice: {
            isCreated: false,
            isDropDownPlus: false,
        }
    },
});
