// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ScheduledEmailDownloadCountFormItem-container-yrNun{width:100%;text-align:left}.ScheduledEmailDownloadCountFormItem-userInput-ipxQj{width:100%}.ScheduledEmailDownloadCountFormItem-tooltip-tqnbv{margin-left:5px}.ScheduledEmailDownloadCountFormItem-infoIcon-Ht_GY{align-self:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/ScheduledEmailSearchPage/ScheduledEmailSearchDrawer/ScheduledEmailDownloadCountFormItem/ScheduledEmailDownloadCountFormItem.scss"],"names":[],"mappings":"AAAA,qDACI,UAAW,CACX,eAAgB,CACnB,qDAGG,UAAW,CACd,mDAGG,eAAgB,CACnB,oDAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ScheduledEmailDownloadCountFormItem-container-yrNun`,
	"userInput": `ScheduledEmailDownloadCountFormItem-userInput-ipxQj`,
	"tooltip": `ScheduledEmailDownloadCountFormItem-tooltip-tqnbv`,
	"infoIcon": `ScheduledEmailDownloadCountFormItem-infoIcon-Ht_GY`
};
export default ___CSS_LOADER_EXPORT___;
