// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SalesModifiedUserFormItem-container-BhPlB{width:100%;text-align:left}.SalesModifiedUserFormItem-userInput-BO0Hw{width:100%}.SalesModifiedUserFormItem-tooltip-QDz_l{margin-left:5px}.SalesModifiedUserFormItem-infoIcon-vQTda{align-self:center}.SalesModifiedUserFormItem-marginBottom-OmFhN{margin-bottom:1%}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/sales/SalesBoardPage/SalesBoardSearchDrawer/SalesModifiedUserFormItem/SalesModifiedUserFormItem.scss"],"names":[],"mappings":"AAAA,2CACI,UAAW,CACX,eAAgB,CACnB,2CAGG,UAAW,CACd,yCAGG,eAAgB,CACnB,0CAGG,iBAAkB,CACrB,8CAGG,gBAAiB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n\n.marginBottom {\n    margin-bottom: 1%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `SalesModifiedUserFormItem-container-BhPlB`,
	"userInput": `SalesModifiedUserFormItem-userInput-BO0Hw`,
	"tooltip": `SalesModifiedUserFormItem-tooltip-QDz_l`,
	"infoIcon": `SalesModifiedUserFormItem-infoIcon-vQTda`,
	"marginBottom": `SalesModifiedUserFormItem-marginBottom-OmFhN`
};
export default ___CSS_LOADER_EXPORT___;
