// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CorporateCommentCreateDateFormItem-container-UFDFO{width:100%;text-align:left}.CorporateCommentCreateDateFormItem-userInput-sMW0v{width:100%}.CorporateCommentCreateDateFormItem-tooltip-u7bIb{margin-left:5px}.CorporateCommentCreateDateFormItem-infoIcon-kBVVf{align-self:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/OrganizationSearchPage/OrganizationSearchDrawer/CorporateCommentCreateDateFormItem/CorporateCommentCreateDateFormItem.scss"],"names":[],"mappings":"AAAA,oDACE,UAAW,CACX,eAAgB,CACjB,oDAGC,UAAW,CACZ,kDAGC,eAAgB,CACjB,mDAGC,iBAAkB","sourcesContent":[".container {\n  width: 100%;\n  text-align: left;\n}\n\n.userInput {\n  width: 100%;\n}\n\n.tooltip {\n  margin-left: 5px;\n}\n\n.infoIcon {\n  align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `CorporateCommentCreateDateFormItem-container-UFDFO`,
	"userInput": `CorporateCommentCreateDateFormItem-userInput-sMW0v`,
	"tooltip": `CorporateCommentCreateDateFormItem-tooltip-u7bIb`,
	"infoIcon": `CorporateCommentCreateDateFormItem-infoIcon-kBVVf`
};
export default ___CSS_LOADER_EXPORT___;
