// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TenantBasicInfo-disclaimerText-c6sI2{text-align:left;font-size:11px}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/TenantCreatePage/TenantBasicInfo/TenantBasicInfo.scss"],"names":[],"mappings":"AAAA,sCACI,eAAgB,CAChB,cAAe","sourcesContent":[".disclaimerText {\n    text-align: left;\n    font-size: 11px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"disclaimerText": `TenantBasicInfo-disclaimerText-c6sI2`
};
export default ___CSS_LOADER_EXPORT___;
