// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ScheduledEmailStatusFormItem-container-YGtDe{width:100%;text-align:left}.ScheduledEmailStatusFormItem-userInput-pZ704{width:100%}.ScheduledEmailStatusFormItem-tooltip-yGdD2{margin-left:5px}.ScheduledEmailStatusFormItem-infoIcon-sM0wB{align-self:center}.ScheduledEmailStatusFormItem-select_option-IVSz7{text-align:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/ScheduledEmailSearchPage/ScheduledEmailSearchDrawer/ScheduledEmailStatusFormItem/ScheduledEmailStatusFormItem.scss"],"names":[],"mappings":"AAAA,8CACI,UAAW,CACX,eAAgB,CACnB,8CAGG,UAAW,CACd,4CAGG,eAAgB,CACnB,6CAGG,iBAAkB,CACrB,kDAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n\n.select_option {\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ScheduledEmailStatusFormItem-container-YGtDe`,
	"userInput": `ScheduledEmailStatusFormItem-userInput-pZ704`,
	"tooltip": `ScheduledEmailStatusFormItem-tooltip-yGdD2`,
	"infoIcon": `ScheduledEmailStatusFormItem-infoIcon-sM0wB`,
	"select_option": `ScheduledEmailStatusFormItem-select_option-IVSz7`
};
export default ___CSS_LOADER_EXPORT___;
