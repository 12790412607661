"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var api_1 = require("~/domain/api");
var gantt = function (client) {
    return {
        fetch: function () {
            var url = api_1.Endpoint.searchTemplate.project.gantt;
            return client.get(url);
        },
        create: function (postData) {
            var url = api_1.Endpoint.searchTemplate.project.gantt;
            return client.post(url, postData);
        },
        delete: function (index) {
            var url = api_1.Endpoint.searchTemplate.project.gantt + "/" + index;
            return client.delete(url);
        },
        star: function (index) {
            var url = api_1.Endpoint.searchTemplate.project.gantt + "/" + index;
            return client.patch(url);
        },
    };
};
exports.default = gantt;
