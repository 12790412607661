"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRecruitBoardDeleteChecklistItemAPIMutation = exports.useRecruitBoardUpdateChecklistItemAPIMutation = exports.useRecruitBoardCreateChecklistItemAPIMutation = exports.useRecruitBoardDeleteChecklistAPIMutation = exports.useRecruitBoardUpdateChecklistAPIMutation = exports.useRecruitBoardCreateChecklistAPIMutation = exports.useRecruitBoardFetchChecklistsAPIQuery = void 0;
var react_query_1 = require("react-query");
var useCustomMutation_1 = require("~/hooks/useCustomMutation");
var useCustomQuery_1 = require("~/hooks/useCustomQuery");
var api_1 = __importStar(require("~/networking/api"));
var constants_1 = require("~/utils/constants");
var useRecruitBoardFetchChecklistsAPIQuery = function (_a) {
    var deps = _a.deps, options = _a.options;
    return (0, useCustomQuery_1.useCustomQuery)({
        queryKey: constants_1.QueryKeys.recruitBoard.checklists,
        deps: deps,
        options: __assign(__assign({}, options), { enabled: !!(deps === null || deps === void 0 ? void 0 : deps.cardId) &&
                !!api_1.default.defaults.headers.common["Authorization"] }),
        apiRequest: function (query) {
            return api_1.recruitAPI.board.fetchChecklists(query === null || query === void 0 ? void 0 : query.cardId);
        },
    });
};
exports.useRecruitBoardFetchChecklistsAPIQuery = useRecruitBoardFetchChecklistsAPIQuery;
var useRecruitBoardCreateChecklistAPIMutation = function () {
    var queryClient = (0, react_query_1.useQueryClient)();
    return (0, useCustomMutation_1.useCustomMutation)(api_1.recruitAPI.board.createChecklist, {
        onSuccess: function (response) {
            queryClient.invalidateQueries(constants_1.QueryKeys.recruitBoard.checklists);
        },
        onError: function (err) { },
    });
};
exports.useRecruitBoardCreateChecklistAPIMutation = useRecruitBoardCreateChecklistAPIMutation;
var useRecruitBoardUpdateChecklistAPIMutation = function () {
    var queryClient = (0, react_query_1.useQueryClient)();
    var apiRequest = function (_a) {
        var checklistId = _a.checklistId, postData = _a.postData;
        return api_1.recruitAPI.board.updateChecklist(checklistId, postData);
    };
    return (0, useCustomMutation_1.useCustomMutation)(apiRequest, {
        onSuccess: function (response) {
            queryClient.invalidateQueries(constants_1.QueryKeys.recruitBoard.checklists);
        },
        onError: function (err) { },
    });
};
exports.useRecruitBoardUpdateChecklistAPIMutation = useRecruitBoardUpdateChecklistAPIMutation;
var useRecruitBoardDeleteChecklistAPIMutation = function () {
    var queryClient = (0, react_query_1.useQueryClient)();
    return (0, useCustomMutation_1.useCustomMutation)(api_1.recruitAPI.board.deleteChecklist, {
        onSuccess: function (response) {
            queryClient.invalidateQueries(constants_1.QueryKeys.recruitBoard.checklists);
        },
        onError: function (err) { },
    });
};
exports.useRecruitBoardDeleteChecklistAPIMutation = useRecruitBoardDeleteChecklistAPIMutation;
var useRecruitBoardCreateChecklistItemAPIMutation = function () {
    var queryClient = (0, react_query_1.useQueryClient)();
    var apiRequest = function (_a) {
        var checklistId = _a.checklistId, postData = _a.postData;
        return api_1.recruitAPI.board.createChecklistItem(checklistId, postData);
    };
    return (0, useCustomMutation_1.useCustomMutation)(apiRequest, {
        onSuccess: function (response) {
            queryClient.invalidateQueries(constants_1.QueryKeys.recruitBoard.checklists);
        },
        onError: function (err) { },
    });
};
exports.useRecruitBoardCreateChecklistItemAPIMutation = useRecruitBoardCreateChecklistItemAPIMutation;
var useRecruitBoardUpdateChecklistItemAPIMutation = function () {
    var queryClient = (0, react_query_1.useQueryClient)();
    var apiRequest = function (_a) {
        var checklistId = _a.checklistId, itemId = _a.itemId, postData = _a.postData;
        return api_1.recruitAPI.board.updateChecklistItem(checklistId, itemId, postData);
    };
    return (0, useCustomMutation_1.useCustomMutation)(apiRequest, {
        onSuccess: function (response) {
            queryClient.invalidateQueries(constants_1.QueryKeys.recruitBoard.checklists);
        },
        onError: function (err) { },
    });
};
exports.useRecruitBoardUpdateChecklistItemAPIMutation = useRecruitBoardUpdateChecklistItemAPIMutation;
var useRecruitBoardDeleteChecklistItemAPIMutation = function () {
    var queryClient = (0, react_query_1.useQueryClient)();
    var apiRequest = function (_a) {
        var checklistId = _a.checklistId, itemId = _a.itemId;
        return api_1.recruitAPI.board.deleteChecklistItem(checklistId, itemId);
    };
    return (0, useCustomMutation_1.useCustomMutation)(apiRequest, {
        onSuccess: function (response) {
            queryClient.invalidateQueries(constants_1.QueryKeys.recruitBoard.checklists);
        },
        onError: function (err) { },
    });
};
exports.useRecruitBoardDeleteChecklistItemAPIMutation = useRecruitBoardDeleteChecklistItemAPIMutation;
