// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OpenRankRow-openRankLabel-F1vWw{display:flex;justify-content:center;align-items:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/ContactDetailPage/OpenRankRow/OpenRankRow.scss"],"names":[],"mappings":"AAAA,iCACE,YAAa,CACb,sBAAuB,CACvB,kBAAmB","sourcesContent":[".openRankLabel {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"openRankLabel": `OpenRankRow-openRankLabel-F1vWw`
};
export default ___CSS_LOADER_EXPORT___;
