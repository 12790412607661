// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PersonnelSkillFormItem-container-vn9xd{width:100%;text-align:left}.PersonnelSkillFormItem-userInput-YgCyC{width:100%}.PersonnelSkillFormItem-tooltip-g_hIf{margin-left:5px}.PersonnelSkillFormItem-infoIcon-LdBu8{align-self:center}.PersonnelSkillFormItem-select_option-xdOtk{text-align:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/personnel/PersonnelBoardPage/PersonnelBoardSearchDrawer/PersonnelSkillFormItem/PersonnelSkillFormItem.scss"],"names":[],"mappings":"AAAA,wCACI,UAAW,CACX,eAAgB,CACnB,wCAGG,UAAW,CACd,sCAGG,eAAgB,CACnB,uCAGG,iBAAkB,CACrB,4CAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n\n.select_option {\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PersonnelSkillFormItem-container-vn9xd`,
	"userInput": `PersonnelSkillFormItem-userInput-YgCyC`,
	"tooltip": `PersonnelSkillFormItem-tooltip-g_hIf`,
	"infoIcon": `PersonnelSkillFormItem-infoIcon-LdBu8`,
	"select_option": `PersonnelSkillFormItem-select_option-xdOtk`
};
export default ___CSS_LOADER_EXPORT___;
