"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var api_1 = require("~/domain/api");
var utils_1 = require("~/utils/utils");
var gantt = function (client) {
    return {
        fetch: function (query) {
            var url = api_1.Endpoint.recruit.gantt;
            if (query) {
                var queryString = (0, utils_1.createQueryString)(query, true);
                url += "?" + queryString;
            }
            return client.get(url);
        },
    };
};
exports.default = gantt;
