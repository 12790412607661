import {
    LOADING,
    LOADED,
    CREATING,
    CREATED,
    DELETED,
    SYNC_FORM_STATE_TO_PAGE,
    LOGOUT,
    UPDATED,
    COMMENT_TEMPLATE_LOADED,
    COMMENT_TEMPLATE_CREATED,
    VALIDATION_ERROR,
} from "../../actions/actionTypes";

const commentActionSuffix = "__COMMENTS";
const subCommentActionSuffix = "__SUBCOMMENTS";

export const defaultInitialState = {
    loading: false,
    requireRefresh: true,
    submitting: false,
    formValues: "",
    items: [],
    subItems: [],
    parentCommentId: "",
    comment_templates: [],
    totalAvailableCount: 0,
};

const createCommentListReducer = (
    pageId,
    initialState = defaultInitialState
) => {
    const Reducer = (state = initialState, action) => {
        switch (action.type) {
            case pageId + commentActionSuffix + LOADING:
                return Object.assign({}, state, {
                    loading: true,
                });
            case pageId + commentActionSuffix + LOADED:
                return Object.assign({}, state, {
                    loading: false,
                    requireRefresh: false,
                    items: action.payload.data ? action.payload.data : [],
                    formValues: "",
                });
            case pageId +
                commentActionSuffix +
                subCommentActionSuffix +
                LOADING:
                return {
                    ...state,
                    parentCommentId: "",
                };
            case pageId + commentActionSuffix + subCommentActionSuffix + LOADED:
                return {
                    ...state,
                    loading: false,
                    requireRefresh: false,
                    subItems: action.payload.data,
                    formValues: "",
                };
            case pageId +
                commentActionSuffix +
                subCommentActionSuffix +
                CREATING:
                return {
                    ...state,
                    loading: true,
                    submitting: true,
                    parentCommentId: "",
                };
            case pageId +
                commentActionSuffix +
                subCommentActionSuffix +
                CREATED:
                return {
                    ...state,
                    loading: false,
                    submitting: false,
                    requireRefresh: true,
                    parentCommentId: action.payload.data.parent,
                };
            case pageId + commentActionSuffix + CREATING:
                return Object.assign({}, state, { submitting: true });
            case pageId + commentActionSuffix + CREATED:
                return Object.assign({}, state, {
                    submitting: false,
                    requireRefresh: true,
                    formValues: "",
                });
            case pageId + commentActionSuffix + UPDATED:
                return Object.assign({}, state, {
                    submitting: false,
                    requireRefresh: true,
                    formValues: "",
                    parentCommentId: action.payload.data.parent,
                });
            case pageId + commentActionSuffix + SYNC_FORM_STATE_TO_PAGE:
                return Object.assign({}, state, {
                    loading: false,
                    formValues: action.payload.formValues,
                });
            case pageId + commentActionSuffix + DELETED:
                return Object.assign({}, state, {
                    loading: false,
                    requireRefresh: true,
                    parentCommentId: action.payload.parent,
                });
            case LOGOUT:
                return Object.assign({}, state, defaultInitialState);
            case pageId + commentActionSuffix + COMMENT_TEMPLATE_LOADED:
                return {
                    ...state,
                    comment_templates: action.payload.data["templates"],
                    totalAvailableCount:
                        action.payload.data["total_available_count"],
                };
            case pageId + commentActionSuffix + COMMENT_TEMPLATE_CREATED:
                return {
                    ...state,
                    comment_templates: action.payload.data["templates"],
                    totalAvailableCount:
                        action.payload.data["total_available_count"],
                };
            case pageId + commentActionSuffix + VALIDATION_ERROR:
                return {
                    ...state,
                    submitting: false,
                };
            default:
                return state;
        }
    };
    return Reducer;
};

export default createCommentListReducer;
