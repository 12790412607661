import React, { useEffect } from "react";
import { Button, Col, Form, InputNumber, Checkbox, Tooltip, Row } from "antd";
import {
    DeleteOutlined,
    QuestionCircleFilled,
    PlusOutlined,
} from "@ant-design/icons";
import { formatMoneyNumberString, parseMoneyNumberString } from "../../helpers";
import ExceptionalOrganizationFormItem from "./ExceptionalOrganizationFormItem/ExceptionalOrganizationFormItem";
import {
    ErrorMessages,
    Links,
    HANKAKU_NUMBER_REGEX,
    iconCustomColor,
} from "~/utils/constants";
import styles from "./MyCompanyForm.scss";
import { useRecoilState, useResetRecoilState } from "recoil";
import { ajaxItemSelect } from "~/recoil/atom";
import { TypeAjaxPageModel } from "~/models/displayTextAndForeignKeyModel";

const InputNumberWidth = 180;

const formItemLayoutLabelSpan = 6;
const formItemLayout = {
    labelCol: {
        span: formItemLayoutLabelSpan,
    },
    wrapperCol: {
        span: 24 - formItemLayoutLabelSpan,
    },
};

const TransactionTermsPane = ({ initialData, fieldErrors, tooltipMessage }) => {
    // NOTE(joshua-hashimoto): アドオンなどで追加できるように変数として切り出せる
    const exceptionalOrganizationLimits = 3;
    const [itemSelect, setItemSelect] = useRecoilState(ajaxItemSelect)

    const removeSelect = (keyNumber) => {
        const items = itemSelect.filter((f) => f.keyNumber !== keyNumber);
        setItemSelect(items);
    }

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
        }
    };

    return (
        <>
            <Form.Item
                {...formItemLayout}
                label="取引に必要な設立年数"
                className={styles.field}
                validateStatus={
                    fieldErrors.establishment_year ? "error" : undefined
                }
                help={fieldErrors.establishment_year}
                style={{
                    margin: "8px 0",
                }}>
                <Row align="middle" gutter={4}>
                    <Col>
                        <Form.Item
                            name="establishment_year"
                            initialValue={initialData.establishment_year}
                            rules={[
                                {
                                    type: "number",
                                    min: 1,
                                    message: "1以上の値を入力してください",
                                },
                                {
                                    pattern: HANKAKU_NUMBER_REGEX,
                                    message:
                                        ErrorMessages.validation.regex
                                            .onlyHankakuNumber,
                                },
                                {
                                    validator: (_, value) => {
                                        const convertedValue = String(
                                            value ?? ""
                                        );
                                        if (convertedValue.length > 3) {
                                            return Promise.reject(
                                                new Error(
                                                    ErrorMessages.validation.length.max3
                                                )
                                            );
                                        }
                                        return Promise.resolve();
                                    },
                                },
                            ]}
                            noStyle>
                            <InputNumber
                                style={{ width: InputNumberWidth }}
                                formatter={formatMoneyNumberString}
                                parser={parseMoneyNumberString}
                                step={1}
                                data-testid="establishment_year"
                                onKeyDown={handleKeyDown}
                            />
                        </Form.Item>
                    </Col>
                    <Col>
                        <span>年以上</span>
                    </Col>
                </Row>
            </Form.Item>
            <Form.Item
                {...formItemLayout}
                label="取引に必要な資本金"
                className={styles.field}
                validateStatus={
                    fieldErrors.capital_man_yen_required_for_transactions
                        ? "error"
                        : undefined
                }
                help={fieldErrors.capital_man_yen_required_for_transactions}
                style={{
                    margin: "8px 0",
                }}>
                <Row align="middle" gutter={4}>
                    <Col>
                        <Form.Item
                            name="capital_man_yen_required_for_transactions"
                            initialValue={
                                initialData.capital_man_yen_required_for_transactions
                            }
                            rules={[
                                {
                                    type: "number",
                                    min: 1,
                                },
                                {
                                    pattern: HANKAKU_NUMBER_REGEX,
                                    message:
                                        ErrorMessages.validation.regex
                                            .onlyHankakuNumber,
                                },
                                {
                                    validator: (_, value) => {
                                        const convertedValue = String(
                                            value ?? ""
                                        );
                                        if (convertedValue.length > 9) {
                                            return Promise.reject(
                                                new Error(
                                                    ErrorMessages.validation.length.max9
                                                )
                                            );
                                        }
                                        return Promise.resolve();
                                    },
                                },
                            ]}
                            noStyle>
                            <InputNumber
                                style={{ width: InputNumberWidth }}
                                formatter={formatMoneyNumberString}
                                parser={parseMoneyNumberString}
                                step={100}
                                onKeyDown={handleKeyDown}
                                data-testid="capital_man_yen_required_for_transactions"
                            />
                        </Form.Item>
                    </Col>
                    <Col>
                        <span>万円以上</span>
                    </Col>
                </Row>
            </Form.Item>
            <Form.Item
                {...formItemLayout}
                label="取引に必要な資格"
                className={styles.field}
                help={fieldErrors.p_mark_or_isms}>
                <Form.Item
                    {...formItemLayout}
                    label=" "
                    className={styles.field}
                    help={fieldErrors.p_mark_or_isms}
                    name="p_mark_or_isms"
                    initialValue={!!initialData.p_mark_or_isms}
                    valuePropName="checked"
                    noStyle>
                    <Checkbox>Pマーク／ISMS</Checkbox>
                </Form.Item>
                <Form.Item
                    {...formItemLayout}
                    label=" "
                    className={styles.field}
                    help={fieldErrors.invoice_system}
                    name="invoice_system"
                    initialValue={!!initialData.invoice_system}
                    valuePropName="checked"
                    noStyle>
                    <Checkbox>インボイス登録事業者</Checkbox>
                </Form.Item>
                <Form.Item
                    {...formItemLayout}
                    label=" "
                    className={styles.field}
                    help={fieldErrors.haken}
                    name="haken"
                    initialValue={!!initialData.haken}
                    valuePropName="checked"
                    noStyle>
                    <Checkbox>労働者派遣事業</Checkbox>
                </Form.Item>
            </Form.Item>
            <Form.Item
                {...formItemLayout}
                label={
                    <span>
                        除外取引先&nbsp;
                        <Tooltip
                            title={
                                <span>
                                    ここで選択をした取引先は、
                                    {tooltipMessage("MyCompany")}
                                    の取引条件を満たしていなくても取引対象となります。
                                    <a
                                        href={
                                            Links.helps.myCompany
                                                .exceptionalOrganizations
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        詳細
                                    </a>
                                </span>
                            }>
                            <QuestionCircleFilled
                                style={{ color: iconCustomColor }}
                            />
                        </Tooltip>
                    </span>
                }
                className={styles.field}
                help={fieldErrors.exceptional_organization}>
                <Form.List name="exceptional_organizations">
                    {(fields, { add, remove }) => {
                        return (
                            <>
                                {fields.map(
                                    ({ key, name, ...restField }, index) => (
                                        <Col key={key}>
                                            <Row>
                                                <Col flex="auto">
                                                    <ExceptionalOrganizationFormItem
                                                        {...formItemLayout}
                                                        help={
                                                            fieldErrors[
                                                                `exceptional_organization${index}`
                                                            ]
                                                        }
                                                        name={name}
                                                        keyNumber={key}
                                                        typeForm={TypeAjaxPageModel.MY_COMPANY_ORGANIZATION}
                                                        {...restField}
                                                        defaultSelect={
                                                            initialData
                                                                .exceptional_organizations[
                                                                index
                                                            ]
                                                        }
                                                    />
                                                </Col>
                                                <Col>
                                                    <Button
                                                        onClick={() => {
                                                            remove(name)
                                                            removeSelect(key)
                                                        }}
                                                        icon={
                                                            <DeleteOutlined />
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    )
                                )}
                                {fields.length <
                                exceptionalOrganizationLimits ? (
                                    <Col>
                                        <Row>
                                            <Button
                                                className={styles.button}
                                                type="dashed"
                                                onClick={() => add()}>
                                                <PlusOutlined />
                                                取引先を追加
                                            </Button>
                                        </Row>
                                    </Col>
                                ) : undefined}
                            </>
                        );
                    }}
                </Form.List>
            </Form.Item>
        </>
    );
};

export default TransactionTermsPane;
