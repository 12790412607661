"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckEmailActions = void 0;
var actionTypes_1 = require("~/actions/actionTypes");
exports.CheckEmailActions = {
    checkingAction: function (pageId) {
        return {
            type: pageId + actionTypes_1.CHECKING_EMAIL_ADDRESS,
        };
    },
    checkedAction: function (pageId, payload) {
        return {
            type: pageId + actionTypes_1.CHECKED_EMAIL_ADDRESS,
            payload: payload,
        };
    },
    errorAction: function (pageId, payload) {
        return {
            type: pageId + actionTypes_1.CHECK_ERROR_EMAIL_ADDRESS,
            payload: payload,
        };
    },
    clearAction: function (pageId) {
        return {
            type: pageId + actionTypes_1.CHECK_CLEAR_EMAIL_ADDRESS,
        };
    },
};
