// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PerGRangeStartFormItem-container-tPdSL{width:100%;text-align:left}.PerGRangeStartFormItem-userInput-UjVMz{width:100%}.PerGRangeStartFormItem-tooltip-Nq7wa{margin-left:5px}.PerGRangeStartFormItem-infoIcon-iKwyS{align-self:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/personnel/PersonnelGanttPage/PersonnelGanttSearchDrawer/PerGRangeStartFormItem/PerGRangeStartFormItem.scss"],"names":[],"mappings":"AAAA,wCACI,UAAW,CACX,eAAgB,CACnB,wCAGG,UAAW,CACd,sCAGG,eAAgB,CACnB,uCAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PerGRangeStartFormItem-container-tPdSL`,
	"userInput": `PerGRangeStartFormItem-userInput-UjVMz`,
	"tooltip": `PerGRangeStartFormItem-tooltip-Nq7wa`,
	"infoIcon": `PerGRangeStartFormItem-infoIcon-iKwyS`
};
export default ___CSS_LOADER_EXPORT___;
