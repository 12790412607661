// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CommentReplyButton-controlButton-ww98H{margin-right:5px !important}.CommentReplyButton-avatar-ciDrv{font-size:40px}.CommentReplyButton-buttonScroll-id4XZ{display:flex;justify-content:center}.CommentReplyButton-replyCommentButton-BPMt6{margin-left:5px !important;background-color:white;color:black;border:solid 1px black}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/DataDisplay/CommentList/CommentReplyButton/CommentReplyButton.scss"],"names":[],"mappings":"AAAA,wCACE,2BAA4B,CAC7B,iCAGC,cAAe,CAChB,uCAGC,YAAa,CACb,sBAAuB,CACxB,6CAEC,0BAA2B,CAC3B,sBAAuB,CACvB,WAAY,CACZ,sBAAuB","sourcesContent":[".controlButton {\n  margin-right: 5px !important;\n}\n\n.avatar {\n  font-size: 40px;\n}\n\n.buttonScroll {\n  display: flex;\n  justify-content: center;\n}\n.replyCommentButton {\n  margin-left: 5px !important;\n  background-color: white;\n  color: black;\n  border: solid 1px black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"controlButton": `CommentReplyButton-controlButton-ww98H`,
	"avatar": `CommentReplyButton-avatar-ciDrv`,
	"buttonScroll": `CommentReplyButton-buttonScroll-id4XZ`,
	"replyCommentButton": `CommentReplyButton-replyCommentButton-BPMt6`
};
export default ___CSS_LOADER_EXPORT___;
