// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboardPage-row-mwbl0{margin:24px auto}.dashboardPage-commingSoon-qjzxj{-webkit-filter:blur(2px);filter:blur(2px)}.dashboardPage-pageHeaderExtra-EaaPy{display:flex;flex-direction:column;align-items:stretch;gap:12px}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/DashboardPage/dashboardPage.scss"],"names":[],"mappings":"AAAA,yBACE,gBAAiB,CAClB,iCAGC,wBAAyB,CACzB,gBAAiB,CAClB,qCAGC,YAAa,CACb,qBAAsB,CACtB,mBAAoB,CACpB,QAAS","sourcesContent":[".row {\n  margin: 24px auto;\n}\n\n.commingSoon {\n  -webkit-filter: blur(2px);\n  filter: blur(2px);\n}\n\n.pageHeaderExtra {\n  display: flex;\n  flex-direction: column;\n  align-items: stretch;\n  gap: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `dashboardPage-row-mwbl0`,
	"commingSoon": `dashboardPage-commingSoon-qjzxj`,
	"pageHeaderExtra": `dashboardPage-pageHeaderExtra-EaaPy`
};
export default ___CSS_LOADER_EXPORT___;
