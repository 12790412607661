// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BackOfficeIncludeArchiveSwitchFromItem-container-KtzyA{width:100%;text-align:left}.BackOfficeIncludeArchiveSwitchFromItem-userInput-X3AtP{width:100%}.BackOfficeIncludeArchiveSwitchFromItem-tooltip-qUox1{margin-left:5px}.BackOfficeIncludeArchiveSwitchFromItem-infoIcon-Ulv5V{align-self:center}.BackOfficeIncludeArchiveSwitchFromItem-switchLabel-DrLuD{display:inline-block;align-self:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/backOffice/BackOfficeBoardPage/BackOfficeBoardSearchDrawer/BackOfficeIncludeArchiveSwitchFromItem/BackOfficeIncludeArchiveSwitchFromItem.scss"],"names":[],"mappings":"AAAA,wDACI,UAAW,CACX,eAAgB,CACnB,wDAGG,UAAW,CACd,sDAGG,eAAgB,CACnB,uDAGG,iBAAkB,CACrB,0DAGG,oBAAqB,CACrB,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n\n.switchLabel {\n    display: inline-block;\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `BackOfficeIncludeArchiveSwitchFromItem-container-KtzyA`,
	"userInput": `BackOfficeIncludeArchiveSwitchFromItem-userInput-X3AtP`,
	"tooltip": `BackOfficeIncludeArchiveSwitchFromItem-tooltip-qUox1`,
	"infoIcon": `BackOfficeIncludeArchiveSwitchFromItem-infoIcon-Ulv5V`,
	"switchLabel": `BackOfficeIncludeArchiveSwitchFromItem-switchLabel-DrLuD`
};
export default ___CSS_LOADER_EXPORT___;
