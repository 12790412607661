"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useContactsFetchScheduledEmailHistoryAPIQuery = void 0;
var api_1 = require("~/networking/api");
var constants_1 = require("~/utils/constants");
var useAuthorizedQuery_1 = require("../useAuthorizedQuery");
var useContactsFetchScheduledEmailHistoryAPIQuery = function (_a) {
    var deps = _a.deps, options = _a.options;
    return (0, useAuthorizedQuery_1.useAuthorizedQuery)({
        queryKey: constants_1.QueryKeys.contacts.scheduledEmailHistory,
        deps: deps,
        options: __assign({}, options),
        apiRequest: function (query) {
            var _a, _b;
            var contactId = (_a = query === null || query === void 0 ? void 0 : query.contactId) !== null && _a !== void 0 ? _a : "";
            var page = (_b = query === null || query === void 0 ? void 0 : query.page) !== null && _b !== void 0 ? _b : "";
            return api_1.contactsAPI.scheduledEmailHistory.fetch(contactId, page);
        }
    });
};
exports.useContactsFetchScheduledEmailHistoryAPIQuery = useContactsFetchScheduledEmailHistoryAPIQuery;
