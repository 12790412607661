// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RoleFormItem-container-VYT6l{width:100%;text-align:left}.RoleFormItem-tooltip-rQ3ZW{margin-left:5px}.RoleFormItem-infoIcon-Mnhr_{align-self:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Forms/UserSearchForm/RoleFormItem/RoleFormItem.scss"],"names":[],"mappings":"AAAA,8BACI,UAAW,CACX,eAAgB,CACnB,4BAEG,eAAgB,CACnB,6BAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `RoleFormItem-container-VYT6l`,
	"tooltip": `RoleFormItem-tooltip-rQ3ZW`,
	"infoIcon": `RoleFormItem-infoIcon-Mnhr_`
};
export default ___CSS_LOADER_EXPORT___;
