"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProjectBoardFormsContext = void 0;
var react_1 = __importStar(require("react"));
var antd_1 = require("antd");
var ConfirmModal_1 = require("~/components/Modals/ConfirmModal");
var board_1 = require("~/hooks/project/board");
var react_query_1 = require("react-query");
var constants_1 = require("~/utils/constants");
var utils_1 = require("~/utils/utils");
var commentActions_1 = require("~/actionCreators/commentActions");
var react_redux_1 = require("react-redux");
var converters_1 = require("~/hooks/project/board/converters");
var utils_2 = require("~/components/Pages/project/ProjectBoardPage/utils");
var store_1 = require("~/models/store");
var actionTypes_1 = require("~/actions/actionTypes");
var searchTemplate_1 = require("~/hooks/searchTemplate");
exports.ProjectBoardFormsContext = (0, react_1.createContext)(undefined);
var ProjectBoardFormsProvider = function (_a) {
    var children = _a.children;
    // NOTE(joshua-hashimoto): states
    var _b = (0, react_1.useState)(false), isModalOpen = _b[0], setIsModalOpen = _b[1];
    var _c = (0, react_1.useState)(false), isInvalid = _c[0], setIsInvalid = _c[1];
    var _d = (0, react_1.useState)(false), cardError = _d[0], setCardError = _d[1];
    var _e = (0, react_1.useState)(false), isBaseInfoError = _e[0], setIsBaseInfoError = _e[1];
    var _f = (0, react_1.useState)(false), isContractError = _f[0], setIsContractError = _f[1];
    var _g = (0, react_1.useState)(false), isEmailTemplateError = _g[0], setIsEmailTemplateError = _g[1];
    // NOTE(joshua-hashimoto): third party hooks
    var queryClient = (0, react_query_1.useQueryClient)();
    var dispatch = (0, react_redux_1.useDispatch)();
    // NOTE(joshua-hashimoto): Forms
    var cardInfoForm = antd_1.Form.useForm()[0];
    var baseInfoForm = antd_1.Form.useForm()[0];
    var contractsForm = antd_1.Form.useForm()[0];
    var scheduledEmailTemplateForm = antd_1.Form.useForm()[0];
    var copyForm = antd_1.Form.useForm()[0];
    // NOTE(joshua-hashimoto): API methods
    var _h = (0, board_1.useProjectBoardUpdateCardAPIMutation)(), updateCard = _h.mutate, isCardUpdating = _h.isLoading;
    var deleteCard = (0, board_1.useProjectBoardDeleteCardAPIMutation)().mutate;
    var copyCard = (0, board_1.useProjectBoardDuplicateCardAPIMutation)().mutate;
    var _j = (0, board_1.useProjectBoardCreateUpdateContractAPIMutation)(), createUpdateContracts = _j.mutate, isContractsUpdating = _j.isLoading;
    var _k = (0, board_1.useProjectBoardUpdateScheduledMailTemplateMutateAPIMutation)(), patchScheduledEmailTemplate = _k.mutate, isScheduledEmailTemplateUpdating = _k.isLoading;
    var createMailTemplate = (0, searchTemplate_1.useSearchMailTemplateCreateProjectBoardAPIMutation)().mutate;
    var deleteMailTemplate = (0, searchTemplate_1.useSearchMailTemplateDeleteProjectBoardAPIMutation)().mutate;
    var newCommentState = (0, store_1.useTypedSelector)(function (state) { return state.projectBoardDetailPageNewComments; });
    var editCommentState = (0, store_1.useTypedSelector)(function (state) { return state.projectBoardDetailPageEditComments; });
    var replyCommentState = (0, store_1.useTypedSelector)(function (state) { return state.projectBoardDetailPageReplyComments; });
    var onUpdate = function (cardId, onSuccess, onSettled) {
        onUpdateCard(cardId, onSuccess, onSettled);
        onUpdateContracts(cardId);
        onUpdateScheduledEmailTemplate(cardId);
    };
    var onUpdateAndContinue = function (cardId) {
        onUpdate(cardId, function (response) {
            var data = response.data;
            var id = data.id;
            if (id) {
                queryClient.invalidateQueries([
                    constants_1.QueryKeys.projectBoard.card,
                    { cardId: id },
                ]);
            }
        });
    };
    var onUpdateAndFinish = function (cardId, onAction) {
        onUpdate(cardId, function (response) {
            queryClient.invalidateQueries([
                constants_1.QueryKeys.project.board.allListCards,
            ]);
        }, function () {
            onModalClose();
            onAction();
        });
    };
    var onUpdateCard = function (cardId, onSuccess, onSettled) { return __awaiter(void 0, void 0, void 0, function () {
        var commentExists, newCommentValue, editCommentValue, replyCommentValue, cardInfoValues, baseInfoValues, postData, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    commentExists = false;
                    if (newCommentState !== undefined &&
                        typeof newCommentState === "object") {
                        newCommentValue = newCommentState.commentValue;
                        if (newCommentValue) {
                            dispatch({
                                type: actionTypes_1.PARENT_CANNOT_SUBMIT_WHEN_NEW_COMMENT_EXISTS,
                            });
                            commentExists = true;
                        }
                    }
                    if (editCommentState !== undefined &&
                        typeof editCommentState === "object") {
                        editCommentValue = editCommentState.commentValue;
                        if (editCommentValue) {
                            dispatch({
                                type: actionTypes_1.PARENT_CANNOT_SUBMIT_WHEN_EDIT_COMMENT_EXISTS,
                            });
                            commentExists = true;
                        }
                    }
                    if (replyCommentState !== undefined &&
                        typeof replyCommentState === "object") {
                        replyCommentValue = replyCommentState.commentValue;
                        if (replyCommentValue) {
                            dispatch({
                                type: actionTypes_1.PARENT_CANNOT_SUBMIT_WHEN_REPLY_COMMENT_EXISTS,
                            });
                            commentExists = true;
                        }
                    }
                    if (commentExists) {
                        return [2 /*return*/];
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, , 5]);
                    return [4 /*yield*/, cardInfoForm.validateFields()];
                case 2:
                    cardInfoValues = _a.sent();
                    return [4 /*yield*/, baseInfoForm.validateFields()];
                case 3:
                    baseInfoValues = _a.sent();
                    postData = __assign(__assign({}, cardInfoValues), baseInfoValues);
                    updateCard({
                        cardId: cardId,
                        postData: postData,
                    }, {
                        onSuccess: onSuccess,
                        onSettled: onSettled,
                    });
                    return [3 /*break*/, 5];
                case 4:
                    err_1 = _a.sent();
                    console.error(err_1);
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var onUpdateContracts = function (cardId) { return __awaiter(void 0, void 0, void 0, function () {
        var values, contracts, isCallable, personnelContracts, postData, err_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, contractsForm.validateFields()];
                case 1:
                    values = _a.sent();
                    contracts = values.contracts;
                    isCallable = !(0, utils_1.isEmpty)(values) && !!contracts;
                    if (isCallable) {
                        personnelContracts = contracts.filter(function (contract) { return !!contract.personnelContract; });
                        postData = personnelContracts.map(function (contract) {
                            return (0, converters_1.convertProjectBoardContractPersonnelContractModelToProjectBoardContractRequestModel)(contract.personnelContract);
                        });
                        createUpdateContracts({ cardId: cardId, postData: postData });
                    }
                    return [3 /*break*/, 3];
                case 2:
                    err_2 = _a.sent();
                    console.error(err_2);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var onUpdateScheduledEmailTemplate = function (cardId, onSettled) {
        var _a, _b, _c;
        try {
            // TODO(joshua-hashimoto): valuesが期待されるもの以外であればAPIを呼び出さない
            var values = scheduledEmailTemplateForm.getFieldsValue();
            if (values["jobtype"] == "dev") {
                values["jobtypeInfra"] = "";
                values["jobtypeOther"] = "";
            }
            else if (values["jobtype"] == "infra") {
                values["jobtypeDev"] = "";
                values["jobtypeOther"] = "";
            }
            else if (values["jobtype"] == "other") {
                values["jobtypeDev"] = "";
                values["jobtypeInfra"] = "";
            }
            if (values["jobtypeDev"]) {
                values["jobtypeDev"] = (0, utils_1.camelToSnakeCase)(values["jobtypeDev"]);
                values["jobtypeInfra"] = "";
                values["jobskillInfraYouken"] = false;
                values["jobskillInfraKihon"] = false;
                values["jobskillInfraSyousai"] = false;
                values["jobskillInfraKouchiku"] = false;
                values["jobskillInfraTest"] = false;
                values["jobskillInfraHosyu"] = false;
                values["jobskillInfraKanshi"] = false;
                values["jobskillInfraBeginner"] = false;
                values["jobtypeOther"] = "";
            }
            else if (values["jobtypeInfra"]) {
                values["jobtypeInfra"] = (0, utils_1.camelToSnakeCase)(values["jobtypeInfra"]);
                values["jobtypeDev"] = "";
                values["jobskillDevYouken"] = false;
                values["jobskillDevKihon"] = false;
                values["jobskillDevSyousai"] = false;
                values["jobskillDevSeizou"] = false;
                values["jobskillDevTest"] = false;
                values["jobskillDevHosyu"] = false;
                values["jobskillDevBeginner"] = false;
                values["jobtypeOther"] = "";
            }
            else if (values["jobtypeOther"]) {
                values["jobtypeOther"] = (0, utils_1.camelToSnakeCase)(values["jobtypeOther"]);
                values["jobtypeDev"] = "";
                values["jobskillDevYouken"] = false;
                values["jobskillDevKihon"] = false;
                values["jobskillDevSyousai"] = false;
                values["jobskillDevSeizou"] = false;
                values["jobskillDevTest"] = false;
                values["jobskillDevHosyu"] = false;
                values["jobskillDevBeginner"] = false;
                values["jobtypeInfra"] = "";
                values["jobskillInfraYouken"] = false;
                values["jobskillInfraKihon"] = false;
                values["jobskillInfraSyousai"] = false;
                values["jobskillInfraKouchiku"] = false;
                values["jobskillInfraTest"] = false;
                values["jobskillInfraHosyu"] = false;
                values["jobskillInfraKanshi"] = false;
                values["jobskillInfraBeginner"] = false;
            }
            var baseData = baseInfoForm.getFieldsValue();
            var distributionTo = (_a = baseData.distribution) === null || _a === void 0 ? void 0 : _a.to;
            if (!!distributionTo) {
                values.jobSyouryu = utils_2.mapJobDistribution[distributionTo];
            }
            var trainStationExists = ((_b = baseData.place) === null || _b === void 0 ? void 0 : _b.name) ? true : false;
            var isRemote = ((_c = baseData.place) === null || _c === void 0 ? void 0 : _c.remote) ? true : false;
            values["wantsLocationChubuJapan"] = (!isRemote && trainStationExists && baseData.trainStationKey === "wants_location_chubu_japan");
            values["wantsLocationChugokuJapan"] = (!isRemote && trainStationExists && baseData.trainStationKey === "wants_location_chugoku_japan");
            values["wantsLocationHokkaidoJapan"] = (!isRemote && trainStationExists && baseData.trainStationKey === "wants_location_hokkaido_japan");
            values["wantsLocationKansaiJapan"] = (!isRemote && trainStationExists && baseData.trainStationKey === "wants_location_kansai_japan");
            values["wantsLocationKantoJapan"] = (!isRemote && trainStationExists && baseData.trainStationKey === "wants_location_kanto_japan");
            values["wantsLocationKyushuJapan"] = (!isRemote && trainStationExists && baseData.trainStationKey === "wants_location_kyushu_japan");
            values["wantsLocationShikokuJapan"] = (!isRemote && trainStationExists && baseData.trainStationKey === "wants_location_shikoku_japan");
            values["wantsLocationTouhokuJapan"] = (!isRemote && trainStationExists && baseData.trainStationKey === "wants_location_touhoku_japan");
            values["wantsLocationToukaiJapan"] = (!isRemote && trainStationExists && baseData.trainStationKey === "wants_location_toukai_japan");
            values["excludeOrganizations"] = values["excludeOrganizations"].filter(function (organization) { return organization; });
            var isCallable = !(0, utils_1.isEmpty)(values);
            if (isCallable && !!values.id) {
                patchScheduledEmailTemplate({
                    cardId: cardId,
                    templateId: values.id,
                    postData: values,
                }, {
                    onSettled: onSettled,
                });
            }
        }
        catch (err) {
            console.error(err);
        }
    };
    var onCopyCard = function (values) {
        copyCard(values, {
            onSuccess: function () {
                // NOTE(joshua-hashimoto): 更新処理をした後はモーダルを閉じるので、閉じるためのsubmitを実行
            },
        });
    };
    var onDeleteCard = function (cardId, onAction) {
        (0, ConfirmModal_1.confirmModal)({
            title: "この案件カードを削除しますか？",
            content: (react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement("p", null,
                    "OK\u3092\u62BC\u3059\u3068\u3001\u524A\u9664\u304C\u5B9F\u884C\u3055\u308C\u307E\u3059\u3002",
                    react_1.default.createElement("br", null),
                    "\u5143\u306B\u306F\u623B\u305B\u307E\u305B\u3093\u3002"))),
            onOk: function () {
                deleteCard(cardId);
                onModalClose();
                onAction();
            },
            onCancel: function () { },
        });
    };
    var onSaveTemplate = function (templateName) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9;
        var formValue = scheduledEmailTemplateForm.getFieldsValue();
        var postData = {
            template_name: templateName,
            text_format: formValue.textFormat,
            subject: formValue.subject,
            text_prefix: formValue.upper,
            text_suffix: formValue.lower,
            send_copy_to_sender: formValue.sendCopyToSender,
            send_copy_to_share: formValue.sendCopyToShare,
            send_type: {
                jobtype: (_a = formValue.jobtype) !== null && _a !== void 0 ? _a : false,
                jobtypeDev: (_b = formValue.jobtypeDev) !== null && _b !== void 0 ? _b : false,
                jobtypeInfra: (_c = formValue.jobtypeInfra) !== null && _c !== void 0 ? _c : false,
                jobtypeOther: (_d = formValue.jobtypeOther) !== null && _d !== void 0 ? _d : false,
                jobtypeDevDesigner: (_e = formValue.jobtypeDevDesigner) !== null && _e !== void 0 ? _e : false,
                jobtypeDevFront: (_f = formValue.jobtypeDevFront) !== null && _f !== void 0 ? _f : false,
                jobtypeDevServer: (_g = formValue.jobtypeDevServer) !== null && _g !== void 0 ? _g : false,
                jobtypeDevPm: (_h = formValue.jobtypeDevPm) !== null && _h !== void 0 ? _h : false,
                jobtypeDevOther: (_j = formValue.jobtypeDevOther) !== null && _j !== void 0 ? _j : false,
                jobtypeInfraServer: (_k = formValue.jobtypeInfraServer) !== null && _k !== void 0 ? _k : false,
                jobtypeInfraNetwork: (_l = formValue.jobtypeInfraNetwork) !== null && _l !== void 0 ? _l : false,
                jobtypeInfraSecurity: (_m = formValue.jobtypeInfraSecurity) !== null && _m !== void 0 ? _m : false,
                jobtypeInfraDatabase: (_o = formValue.jobtypeInfraDatabase) !== null && _o !== void 0 ? _o : false,
                jobtypeInfraSys: (_p = formValue.jobtypeInfraSys) !== null && _p !== void 0 ? _p : false,
                jobtypeInfraOther: (_q = formValue.jobtypeInfraOther) !== null && _q !== void 0 ? _q : false,
                jobtypeOtherEigyo: (_r = formValue.jobtypeOtherEigyo) !== null && _r !== void 0 ? _r : false,
                jobtypeOtherKichi: (_s = formValue.jobtypeOtherKichi) !== null && _s !== void 0 ? _s : false,
                jobtypeOtherOther: (_t = formValue.jobtypeOtherOther) !== null && _t !== void 0 ? _t : false,
                jobtypeOtherSupport: (_u = formValue.jobtypeOtherSupport) !== null && _u !== void 0 ? _u : false,
                jobskillDevYouken: (_v = formValue.jobskillDevYouken) !== null && _v !== void 0 ? _v : false,
                jobskillDevKihon: (_w = formValue.jobskillDevKihon) !== null && _w !== void 0 ? _w : false,
                jobskillDevSyousai: (_x = formValue.jobskillDevSyousai) !== null && _x !== void 0 ? _x : false,
                jobskillDevSeizou: (_y = formValue.jobskillDevSeizou) !== null && _y !== void 0 ? _y : false,
                jobskillDevTest: (_z = formValue.jobskillDevTest) !== null && _z !== void 0 ? _z : false,
                jobskillDevHosyu: (_0 = formValue.jobskillDevHosyu) !== null && _0 !== void 0 ? _0 : false,
                jobskillDevBeginner: (_1 = formValue.jobskillDevBeginner) !== null && _1 !== void 0 ? _1 : false,
                jobskillInfraBeginner: (_2 = formValue.jobskillInfraBeginner) !== null && _2 !== void 0 ? _2 : false,
                jobskillInfraHosyu: (_3 = formValue.jobskillInfraHosyu) !== null && _3 !== void 0 ? _3 : false,
                jobskillInfraKanshi: (_4 = formValue.jobskillInfraKanshi) !== null && _4 !== void 0 ? _4 : false,
                jobskillInfraKihon: (_5 = formValue.jobskillInfraKihon) !== null && _5 !== void 0 ? _5 : false,
                jobskillInfraKouchiku: (_6 = formValue.jobskillInfraKouchiku) !== null && _6 !== void 0 ? _6 : false,
                jobskillInfraSyousai: (_7 = formValue.jobskillInfraSyousai) !== null && _7 !== void 0 ? _7 : false,
                jobskillInfraTest: (_8 = formValue.jobskillInfraTest) !== null && _8 !== void 0 ? _8 : false,
                jobskillInfraYouken: (_9 = formValue.jobskillInfraYouken) !== null && _9 !== void 0 ? _9 : false,
            },
            exclude_organizations: formValue.excludeOrganizations,
        };
        return new Promise(function (resolve, reject) {
            createMailTemplate(postData, {
                onSuccess: function () { return resolve(); },
                onError: function () { return reject(); },
            });
        });
    };
    var onDeleteTemplate = function (templateIndex) {
        deleteMailTemplate(templateIndex);
    };
    var removeQueries = function () {
        queryClient.removeQueries(constants_1.QueryKeys.projectBoard.card, {
            exact: false,
        });
        queryClient.removeQueries(constants_1.QueryKeys.projectBoard.contracts, {
            exact: false,
        });
        queryClient.removeQueries(constants_1.QueryKeys.project.board.scheduledEmailTemplate.fetch, { exact: false });
        queryClient.removeQueries(constants_1.QueryKeys.projectBoard.projectAttachments, {
            exact: false,
        });
        queryClient.removeQueries(constants_1.QueryKeys.projectBoard.checklist, {
            exact: false,
        });
    };
    var onClean = function () {
        cardInfoForm.resetFields();
        baseInfoForm.resetFields();
        contractsForm.resetFields();
        scheduledEmailTemplateForm.resetFields();
        removeQueries();
        copyForm.resetFields();
    };
    var onModalOpen = function () {
        setIsModalOpen(true);
    };
    var onModalClose = function () {
        dispatch(commentActions_1.CommentActions.newCommentClearAction());
        dispatch(commentActions_1.CommentActions.editCommentClearAction());
        setIsModalOpen(false);
        onClean();
    };
    var setIsArchived = function (value) {
        baseInfoForm.setFieldsValue({
            isArchived: value,
        });
    };
    var resetError = function () {
        setCardError(false);
        setIsBaseInfoError(false);
        setIsContractError(false);
        setIsEmailTemplateError(false);
    };
    var isError = cardError ||
        isBaseInfoError ||
        isContractError ||
        isEmailTemplateError;
    var isUpdating = isCardUpdating ||
        isContractsUpdating ||
        isScheduledEmailTemplateUpdating;
    return (react_1.default.createElement(exports.ProjectBoardFormsContext.Provider, { value: {
            isModalOpen: isModalOpen,
            onModalOpen: onModalOpen,
            onModalClose: onModalClose,
            setIsArchived: setIsArchived,
            cardInfoForm: cardInfoForm,
            baseInfoForm: baseInfoForm,
            contractsForm: contractsForm,
            scheduledEmailTemplateForm: scheduledEmailTemplateForm,
            copyForm: copyForm,
            onUpdateCard: onUpdateCard,
            onUpdateScheduledEmailTemplate: onUpdateScheduledEmailTemplate,
            onUpdateContracts: onUpdateContracts,
            onUpdateAndContinue: onUpdateAndContinue,
            onUpdateAndFinish: onUpdateAndFinish,
            onCopyCard: onCopyCard,
            onDeleteCard: onDeleteCard,
            onClean: onClean,
            onSaveTemplate: onSaveTemplate,
            onDeleteTemplate: onDeleteTemplate,
            isUpdating: isUpdating,
            isInvalid: isInvalid,
            setIsInvalid: setIsInvalid,
            cardError: cardError,
            setCardError: setCardError,
            isBaseInfoError: isBaseInfoError,
            setIsBaseInfoError: setIsBaseInfoError,
            isContractError: isContractError,
            setIsContractError: setIsContractError,
            isEmailTemplateError: isEmailTemplateError,
            setIsEmailTemplateError: setIsEmailTemplateError,
            resetError: resetError,
            isError: isError,
        } }, children));
};
exports.default = ProjectBoardFormsProvider;
