"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pageLandingStatus = exports.defaultPageLandingStatus = void 0;
var recoil_1 = require("recoil");
var constants_1 = require("~/utils/constants");
exports.defaultPageLandingStatus = {
    organizations: {
        hasLanded: false,
    },
    contacts: {
        hasLanded: false,
    },
    scheduledMails: {
        hasLanded: false,
    },
    sharedMails: {
        hasLanded: false,
    },
    personnel: {
        board: {
            hasLanded: false,
        },
        gantt: {
            hasLanded: false,
        },
    },
    project: {
        board: {
            hasLanded: false,
        },
        gantt: {
            hasLanded: false,
        },
    },
};
var localStoragePageLandingStatus = function (key) {
    return function (_a) {
        var setSelf = _a.setSelf, onSet = _a.onSet;
        var savedValue = localStorage.getItem(key);
        if (savedValue !== null) {
            try {
                setSelf(JSON.parse(savedValue));
            }
            catch (err) {
                setSelf(exports.defaultPageLandingStatus);
            }
        }
        onSet(function (newValue, _, isReset) {
            if (isReset) {
                localStorage.removeItem(key);
            }
            else {
                var savingValue = !!newValue
                    ? JSON.stringify(newValue)
                    : JSON.stringify(exports.defaultPageLandingStatus);
                localStorage.setItem(key, savingValue);
            }
        });
    };
};
exports.pageLandingStatus = (0, recoil_1.atom)({
    key: constants_1.QueryKeys.others.pageLanding,
    default: exports.defaultPageLandingStatus,
    effects: [
        localStoragePageLandingStatus(constants_1.QueryKeys.others.pageLanding + ".current"),
    ],
});
