import React from 'react';
import PropTypes from 'prop-types';
import { Result } from 'antd';

const ErrorScreen = (props) => {
  const { message } = props;
  return (
    <Result
      status="warning"
      title="読み込みに失敗しました"
      subTitle={message}
    />
  );
};

ErrorScreen.propTypes = {
  message: PropTypes.string.isRequired,
};

export default ErrorScreen;
