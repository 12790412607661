// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoginPage-container-YafVw{margin:auto}.LoginPage-pageHeader-IrcYP{padding:16px 0 0 0;text-align:left}.LoginPage-pageBody-jJCM2{padding:16px 0 0 0}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/LoginPage/LoginPage.scss"],"names":[],"mappings":"AAAA,2BACI,WAAY,CACf,4BAGG,kBAAmB,CACnB,eAAgB,CACnB,0BAGG,kBAAmB","sourcesContent":[".container {\n    margin: auto;\n}\n\n.pageHeader {\n    padding: 16px 0 0 0;\n    text-align: left;\n}\n\n.pageBody {\n    padding: 16px 0 0 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `LoginPage-container-YafVw`,
	"pageHeader": `LoginPage-pageHeader-IrcYP`,
	"pageBody": `LoginPage-pageBody-jJCM2`
};
export default ___CSS_LOADER_EXPORT___;
