"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var api_1 = require("~/domain/api");
var utils_1 = require("~/utils/utils");
var sharedEmail = function (client) {
    return {
        fetchDetail: function (id) {
            var url = api_1.Endpoint.sharedEmails + "/" + id;
            return client.get(url);
        },
        forward: function (id, postData) {
            var url = api_1.Endpoint.sharedEmails + "/" + id + "/forward";
            return client.post(url, postData);
        },
        delete: function (id) {
            var url = api_1.Endpoint.sharedEmails + "/" + id;
            return client.delete(url);
        },
        bulkUpdate: function (postData) {
            var url = api_1.Endpoint.sharedEmails;
            var data = (0, utils_1.convertCamelObjectToSnakeObject)(postData);
            return client.patch(url, data);
        },
    };
};
exports.default = sharedEmail;
