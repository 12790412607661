"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.salesBoardUploadEndpointsCreator = exports.salesBoardBasePath = void 0;
var api_1 = require("~/domain/api");
exports.salesBoardBasePath = "board/sales";
var salesBoardUploadEndpointsCreator = function (cardId) {
    var basePath = api_1.Endpoint.getBaseUrl() +
        "/" +
        exports.salesBoardBasePath +
        "/cards/" +
        cardId;
    var endpoints = {
        attachments: basePath + "/attachments",
    };
    return endpoints;
};
exports.salesBoardUploadEndpointsCreator = salesBoardUploadEndpointsCreator;
