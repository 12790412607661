// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SwapDisabledButton-swapButton-npjTP{margin-right:5px;background-color:#f5f5f5;border-color:#d9d9d9}.SwapDisabledButton-swapButton-npjTP:hover{background-color:#f5f5f5;border-color:#d9d9d9}.SwapDisabledButton-listHeader-PuBwr{text-align:center;color:white;background-color:#d9d9d9;margin:0px;padding:0px}.SwapDisabledButton-listItem-ninKd{cursor:pointer;text-align:left}.SwapDisabledButton-listItem-ninKd:hover{background-color:#f5f5f5}.SwapDisabledButton-popover-aR9dR .ant-popover-inner-content{padding:0}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Common/SwapDisabledButton/SwapDisabledButton.scss"],"names":[],"mappings":"AAAA,qCACI,gBAAiB,CACjB,wBAAyB,CACzB,oBAAqB,CAHzB,2CAMQ,wBAAyB,CACzB,oBAAqB,CACxB,qCAID,iBAAkB,CAClB,WAAY,CACZ,wBAAyB,CACzB,UAAW,CACX,WAAY,CACf,mCAGG,cAAe,CACf,eAAgB,CAFpB,yCAKQ,wBAAyB,CAC5B,6DAKG,SAAU","sourcesContent":[".swapButton {\n    margin-right: 5px;\n    background-color: #f5f5f5;\n    border-color: #d9d9d9;\n\n    &:hover {\n        background-color: #f5f5f5;\n        border-color: #d9d9d9;\n    }\n}\n\n.listHeader {\n    text-align: center;\n    color: white;\n    background-color: #d9d9d9;\n    margin: 0px;\n    padding: 0px;\n}\n\n.listItem {\n    cursor: pointer;\n    text-align: left;\n\n    &:hover {\n        background-color: #f5f5f5;\n    }\n}\n\n.popover{\n    :global(.ant-popover-inner-content) {\n        padding: 0;\n      }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"swapButton": `SwapDisabledButton-swapButton-npjTP`,
	"listHeader": `SwapDisabledButton-listHeader-PuBwr`,
	"listItem": `SwapDisabledButton-listItem-ninKd`,
	"popover": `SwapDisabledButton-popover-aR9dR`
};
export default ___CSS_LOADER_EXPORT___;
