// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PlanStartupScaleButton-btnToStartup-a3kKi{position:relative}.PlanStartupScaleButton-btnToStartupPending-zCyJ2{background:#d9d9d9 !important;border-color:#d9d9d9 !important;color:#ffffff !important}.PlanStartupScaleButton-textFixed-yLzI0{width:80px;background-color:#ffb340;color:#fff;text-align:center;padding:5px 0;position:absolute;z-index:1;bottom:130%;right:-5%;margin-left:-60px}.PlanStartupScaleButton-textFixed-yLzI0:after{content:"";position:absolute;top:100%;left:20%;margin-left:-5px;border-width:5px;border-style:solid;border-color:#ffb340 transparent transparent transparent}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/PlanPage/PlanCurrentUserInfo/PlanStartupScaleButton/PlanStartupScaleButton.scss"],"names":[],"mappings":"AAAA,2CACI,iBAAkB,CACrB,kDAGG,6BAA8B,CAC9B,+BAAgC,CAChC,wBAAyB,CAC5B,wCAGG,UAAW,CACX,wBAAyB,CACzB,UAAW,CACX,iBAAkB,CAClB,aAAc,CACd,iBAAkB,CAClB,SAAU,CACV,WAAY,CACZ,SAAU,CACV,iBAAkB,CAVtB,8CAaQ,UAAW,CACX,iBAAkB,CAClB,QAAS,CACT,QAAS,CACT,gBAAiB,CACjB,gBAAiB,CACjB,kBAAmB,CACnB,wDAAyD","sourcesContent":[".btnToStartup {\n    position: relative;\n}\n\n.btnToStartupPending {\n    background: #d9d9d9 !important;\n    border-color: #d9d9d9 !important;\n    color: #ffffff !important;\n}\n\n.textFixed {\n    width: 80px;\n    background-color: #ffb340;\n    color: #fff;\n    text-align: center;\n    padding: 5px 0;\n    position: absolute;\n    z-index: 1;\n    bottom: 130%;\n    right: -5%;\n    margin-left: -60px;\n\n    &:after {\n        content: \"\";\n        position: absolute;\n        top: 100%;\n        left: 20%;\n        margin-left: -5px;\n        border-width: 5px;\n        border-style: solid;\n        border-color: #ffb340 transparent transparent transparent;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btnToStartup": `PlanStartupScaleButton-btnToStartup-a3kKi`,
	"btnToStartupPending": `PlanStartupScaleButton-btnToStartupPending-zCyJ2`,
	"textFixed": `PlanStartupScaleButton-textFixed-yLzI0`
};
export default ___CSS_LOADER_EXPORT___;
