import { ORGANIZATION_SEARCH_PAGE } from "~/components/Pages/pageIds";
import createSearchPage from "~/components/Pages/Factories/createSearchPage";
import { Endpoint } from "~/domain/api";
import { organizationSearchParamToAPI } from "~/domain/data";
import getDateStr from "~/domain/date";
import OrganizationTable from "~/components/Tables/OrganizationsTable/OrganizationsTable";
import OrganizationsTableDisplaySettingModal from "~/components/Tables/OrganizationsTable/OrganizationsTableDisplaySettingModal/OrganizationsTableDisplaySettingModal";
import OrganizationCsvDownloadButton from "./OrganizationCsvDownloadButton/OrganizationCsvDownloadButton";
import OrganizationSearchDrawer from "./OrganizationSearchDrawer/OrganizationSearchDrawer";
import Paths from "~/components/Routes/Paths";

const pageId = ORGANIZATION_SEARCH_PAGE;
const resourceURL = `${Endpoint.getBaseUrl()}/${Endpoint.organizations}`;
const columnSettingURL = `${Endpoint.getBaseUrl()}/${
    Endpoint.organizationColumnSetting
}`;

const convertResponseDataEntry = (data, index) => ({
    key: index,
    id: data.id,
    corporate_number: data.corporate_number,
    name: data.name,
    settlement_month: data.settlement_month,
    address: data.address,
    domain_name: data.domain_name,
    capital_man_yen: data.capital_man_yen,
    capital_man_yen_required_for_transactions:
        data.capital_man_yen_required_for_transactions,
    establishment_year: data.establishment_year,
    country: data.country,
    category: data.category,
    employee_number: data.employee_number,
    contract: data.contract,
    score: data.score,
    created_time: getDateStr(data.created_time),
    modified_time: getDateStr(data.modified_time),
    comments: data.comments,
    is_ignored: data.is_ignored,
    establishment_date: data.establishment_date,
    has_p_mark_or_isms: data.has_p_mark_or_isms,
    has_invoice_system: data.has_invoice_system,
    has_haken: data.has_haken,
    has_distribution: data.has_distribution,
    p_mark_or_isms: data.p_mark_or_isms,
    invoice_system: data.invoice_system,
    haken: data.haken,
    building: data.building,
    tel1: data.tel1,
    tel2: data.tel2,
    tel3: data.tel3,
    fax1: data.fax1,
    fax2: data.fax2,
    fax3: data.fax3,
    branches: data.branches,
    has_outsourcing_basic_contact: data.has_outsourcing_basic_contact,
    has_nda: data.has_nda,
    has_handring_personal_information: data.has_handring_personal_information,
    tags: data.tags,
    tag_objects: data.tag_objects,
});

const columns = [
    {
        name: "法人番号",
        key: "corporate_number",
    },
    {
        name: "取引先名",
        key: "name",
    },
    {
        name: "国籍",
        key: "country",
    },
    {
        name: "設立年月",
        key: "establishment_date",
    },
    {
        name: "決算期",
        key: "settlement_month",
    },
    {
        name: "住所",
        key: "address",
    },
    {
        name: "建物",
        key: "building",
    },
    {
        name: "タグ",
        key: "tag_objects",
    },
    {
        name: "社員数",
        key: "employee_number",
    },
    {
        name: "請負",
        key: "contract",
    },
    {
        name: "URL",
        key: "domain_name",
    },
    {
        name: "取引先ステータス",
        key: "category",
    },
    {
        name: "商流",
        key: "has_distribution",
    },
    {
        name: "資本金",
        key: "capital_man_yen",
    },
    {
        name: "契約書類",
        key: "document",
    },
    {
        name: "保有資格",
        key: "license",
    },
    {
        name: "☆",
        key: "score",
    },
    {
        name: "取引に必要な設立年数",
        key: "establishment_year",
    },
    {
        name: "取引に必要な資本金",
        key: "capital_man_yen_required_for_transactions",
    },
    {
        name: "取引に必要な資格",
        key: "license_required_for_transactions",
    },
    {
        name: "作成日",
        key: "created_time",
    },
    {
        name: "更新日",
        key: "modified_time",
    },
];

const resourceName = "organizations";
const changeActiveStatusAuthorized = () => {
    return true;
};
const deleteAuthorized = (authorizedActions) => {
    return (
        authorizedActions &&
        authorizedActions[resourceName] &&
        authorizedActions[resourceName]["delete"]
    );
};
const csvAuthorized = (authorizedActions) => {
    return (
        authorizedActions &&
        authorizedActions[resourceName] &&
        authorizedActions[resourceName]["csv_upload"]
    );
};
const columnSettingAuthorized = (authorizedActions) => {
    return (
        authorizedActions &&
        authorizedActions[resourceName] &&
        authorizedActions[resourceName]["column_setting"]
    );
};
const accessAuthorized = () => {
    return true;
};
const searchTemplateAuthorized = (authorizedActions) => {
    return (
        authorizedActions &&
        authorizedActions[resourceName] &&
        authorizedActions[resourceName]["search_template"]
    );
};
const editAuthorized = (authorizedActions) => {
    return !!authorizedActions?.[resourceName]?.["update"];
};

const searchConditionSanitizer = (unshowList, targetObj) => {
    const newData = { ...targetObj };
    for (const fieldName of unshowList) {
        if (fieldName === "score") {
            newData["score"] = 3;
            newData["score_inequality"] = undefined;
        } else if (fieldName === "establishment_date") {
            newData["establishment_date"] = undefined;
            newData["establishment_date_range"] = [];
        } else if (fieldName === "tel") {
            newData["tel"] = undefined;
            newData["tel1"] = undefined;
            newData["tel2"] = undefined;
            newData["tel3"] = undefined;
        } else if (fieldName === "branch_tel") {
            newData["branch_tel"] = undefined;
            newData["branch_tel1"] = undefined;
            newData["branch_tel2"] = undefined;
            newData["branch_tel3"] = undefined;
        } else if (fieldName === "fax") {
            newData["fax1"] = undefined;
            newData["fax2"] = undefined;
            newData["fax3"] = undefined;
        } else if (fieldName === "branch_fax") {
            newData["branch_fax1"] = undefined;
            newData["branch_fax2"] = undefined;
            newData["branch_fax3"] = undefined;
        } else if (fieldName === "capital") {
            newData["capital"] = undefined;
            newData["capital_gt"] = undefined;
            newData["capital_lt"] = undefined;
        } else if (fieldName === "establishment_year") {
            newData["establishment_year"] = undefined;
            newData["establishment_year_gt"] = undefined;
            newData["establishment_year_lt"] = undefined;
        } else if (fieldName === "capital_man_yen_required_for_transactions") {
            newData["capital_man_yen_required_for_transactions"] = undefined;
            newData["capital_man_yen_required_for_transactions_gt"] = undefined;
            newData["capital_man_yen_required_for_transactions_lt"] = undefined;
        } else {
            newData[fieldName] = undefined;
        }
    }
    return newData;
};

const buildBulkEditUrl = (selectedRows) => {
    const query = new URLSearchParams();
    const organizations = selectedRows.map(({ id, name }) => ({ id, name }));
    query.set("organizations", JSON.stringify(organizations));
    return `${Paths.organizationsBulkEdit}?${query}`;
};

const OrganizationSearchPageContainer = createSearchPage(
    pageId,
    "organizationSearchPage",
    "取引先 一覧",
    OrganizationSearchDrawer,
    OrganizationTable,
    resourceURL,
    convertResponseDataEntry,
    organizationSearchParamToAPI,
    false,
    undefined,
    undefined, // csvDownloadURL,
    true,
    true,
    columns,
    columnSettingURL,
    undefined,
    undefined,
    undefined,
    changeActiveStatusAuthorized,
    deleteAuthorized,
    csvAuthorized,
    columnSettingAuthorized,
    accessAuthorized,
    undefined,
    searchTemplateAuthorized,
    resourceName,
    false,
    [OrganizationCsvDownloadButton],
    [OrganizationsTableDisplaySettingModal],
    searchConditionSanitizer,
    undefined,
    undefined,
    true,
    editAuthorized,
    buildBulkEditUrl,
    undefined,
    ""
);

export default OrganizationSearchPageContainer;
