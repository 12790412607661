// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserSlashIcon-slashIcon-xFe18{display:flex;position:absolute}.UserSlashIcon-imgIconUserContainer-KmG6x{display:flex;justify-content:center;align-items:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/icons/UserSlashIcon/UserSlashIcon.scss"],"names":[],"mappings":"AAAA,+BACE,YAAa,CACb,iBAAkB,CACnB,0CAEC,YAAa,CACb,sBAAuB,CACvB,kBAAmB","sourcesContent":[".slashIcon{\n  display: flex;\n  position: absolute;\n}\n.imgIconUserContainer{\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"slashIcon": `UserSlashIcon-slashIcon-xFe18`,
	"imgIconUserContainer": `UserSlashIcon-imgIconUserContainer-KmG6x`
};
export default ___CSS_LOADER_EXPORT___;
