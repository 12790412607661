"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DiscountStatus = void 0;
var DiscountStatus;
(function (DiscountStatus) {
    DiscountStatus[DiscountStatus["PENDING"] = 1] = "PENDING";
    DiscountStatus[DiscountStatus["APPROVE"] = 2] = "APPROVE";
    DiscountStatus[DiscountStatus["REJECT"] = 3] = "REJECT";
})(DiscountStatus = exports.DiscountStatus || (exports.DiscountStatus = {}));
;
