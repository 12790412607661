// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TagPage-paginator-FXLXH{margin:16px auto}.TagPage-tagContainer-N8Dvj{display:flex;padding-top:16px;justify-content:end}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/TagPage/TagPage.scss"],"names":[],"mappings":"AAAA,yBACI,gBAAiB,CACpB,4BAEG,YAAa,CACb,gBAAiB,CACjB,mBAAoB","sourcesContent":[".paginator {\n    margin: 16px auto;\n}\n.tagContainer{\n    display: flex;\n    padding-top: 16px;\n    justify-content: end;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paginator": `TagPage-paginator-FXLXH`,
	"tagContainer": `TagPage-tagContainer-N8Dvj`
};
export default ___CSS_LOADER_EXPORT___;
