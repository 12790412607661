"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.searchCondition = exports.selectedTemplateName = void 0;
var recoil_1 = require("recoil");
var utils_1 = require("~/components/Pages/ContactSearchPage/utils");
var constants_1 = require("~/utils/constants");
var localStorageSelectedTemplateName = function (key) {
    return function (_a) {
        var setSelf = _a.setSelf, onSet = _a.onSet;
        var savedValue = localStorage.getItem(key);
        if (savedValue !== null) {
            var settingValue = savedValue;
            if (!!settingValue && settingValue !== "undefined") {
                settingValue = JSON.parse(settingValue);
            }
            else {
                settingValue = undefined;
            }
            setSelf(settingValue);
        }
        onSet(function (newValue, _, isReset) {
            if (isReset) {
                localStorage.removeItem(key);
            }
            else {
                var savingValue = !!newValue ? JSON.stringify(newValue) : "";
                localStorage.setItem(key, savingValue);
            }
        });
    };
};
exports.selectedTemplateName = (0, recoil_1.atom)({
    key: constants_1.QueryKeys.contacts.selectedTemplateName,
    default: undefined,
    effects: [
        localStorageSelectedTemplateName(constants_1.QueryKeys.contacts.selectedTemplateName + ".current"),
    ],
});
var defaultSearchCondition = __assign({}, utils_1.emptySearchDeps);
var localStorageSearchCondition = function (key) {
    return function (_a) {
        var setSelf = _a.setSelf, onSet = _a.onSet;
        var savedValue = localStorage.getItem(key);
        if (savedValue !== null) {
            try {
                var values = JSON.parse(savedValue);
                var sanitized = (0, utils_1.sanitizeUrlValues)(values);
                setSelf(sanitized);
            }
            catch (err) {
                setSelf({});
            }
        }
        onSet(function (newValue, _, isReset) {
            if (isReset) {
                localStorage.removeItem(key);
            }
            else {
                var savingValue = !!newValue
                    ? JSON.stringify(newValue)
                    : JSON.stringify({});
                localStorage.setItem(key, savingValue);
            }
        });
    };
};
exports.searchCondition = (0, recoil_1.atom)({
    key: constants_1.QueryKeys.contacts.search,
    default: defaultSearchCondition,
    effects: [
        localStorageSearchCondition(constants_1.QueryKeys.contacts.search + ".current"),
    ],
});
