import {
    CREATING,
    CREATED,
    UPDATING,
    UPDATED,
    COMMITTED,
    VALIDATION_ERROR,
    ERROR,
    CLEAR,
    DELETED,
    SYNC_FORM_STATE_TO_PAGE,
    LOADING,
    LOADED,
    CANCELED,
    LOGOUT,
    TAG_CREATED,
    RESERVED_DATE_LOADED,
    COPIED,
    SCHEDULE_TEMPLATE_COMMITTED,
    SCHEDULE_TEMPLATE_CREATED,
    REDIRECT,
    RESET_ERROR,
} from "../../actions/actionTypes";
import { SuccessMessages } from "~/utils/constants";

export const defaultInitialState = {
    loading: false,
    created: false,
    committed: false,
    deleted: false,
    message: "",
    errorMessage: "",
    data: {},
    fieldErrors: {},
    valuesPersonnel: {},
};

const createRealtimeRegisterPageReducer = (
    pageId,
    InitialState = defaultInitialState
) => {
    const Reducer = (state = InitialState, action) => {
        switch (action.type) {
            case pageId + LOADING:
                return Object.assign({}, state, {
                    loading: true,
                    message: "",
                    errorMessage: "",
                });
            case pageId + CANCELED:
                return Object.assign({}, state, { loading: false });
            case pageId + LOADED:
                return Object.assign({}, state, {
                    loading: false,
                    message: "",
                    errorMessage: "",
                    fieldErrors: {},
                    data: action.payload.data,
                });
            case pageId + CREATING:
                return Object.assign({}, state, {
                    message: "",
                    errorMessage: "",
                    loading: true,
                });
            case pageId + UPDATING: // for real-time draft update.
                return Object.assign({}, state, {
                    message: "",
                    errorMessage: "",
                    loading: true,
                });
            case pageId + CREATED:
                return Object.assign({}, state, {
                    loading: false,
                    created: true,
                    message: SuccessMessages.generic.create,
                    errorMessage: "",
                    fieldErrors: {},
                    data: action.payload.data,
                });
            case pageId + UPDATED: // for real-time draft update.
                return Object.assign({}, state, {
                    loading: false,
                    message: SuccessMessages.generic.update,
                    errorMessage: "",
                    fieldErrors: {},
                    data: action.payload.data,
                });
            case pageId + COMMITTED: // for real-time draft update.
                return Object.assign({}, state, {
                    committed: true,
                    message: "",
                    errorMessage: "",
                });
            case pageId + DELETED:
                return Object.assign({}, state, {
                    loading: false,
                    message: SuccessMessages.generic.delete,
                    errorMessage: "",
                    deleted: true,
                });
            case pageId + ERROR:
                return Object.assign({}, state, {
                    loading: false,
                    message: "",
                    errorMessage:
                        action.payload.error.detail ||
                        action.payload.error.message,
                    errorExtraInfo: action.payload.errorExtraInfo,
                });
            case pageId + RESET_ERROR:
                return Object.assign({}, state, {
                    errorMessage: "",
                    errorExtraInfo: undefined,
                });
            case pageId + VALIDATION_ERROR:
                return Object.assign({}, state, {
                    loading: false,
                    message: "",
                    errorMessage: action.payload.error.detail,
                    fieldErrors: action.payload.error.field_errors,
                });
            case pageId + SYNC_FORM_STATE_TO_PAGE:
                return Object.assign({}, state, {
                    message: "",
                    errorMessage: "",
                    data: Object.assign(
                        {},
                        state.data,
                        action.payload.formValues
                    ), // Do not erase other form's value.
                });
            case pageId + CLEAR:
                return Object.assign({}, state, InitialState);
            case LOGOUT:
                return Object.assign({}, state, defaultInitialState);
            case pageId + TAG_CREATED:
                return Object.assign({}, state, {
                    tagResisterResult: action.payload.data,
                });
            case pageId + RESERVED_DATE_LOADED:
                return Object.assign({}, state, {
                    reservedDateData: action.payload.data,
                });
            case pageId + COPIED:
                return { ...state, message: action.payload.message };
            case pageId + SCHEDULE_TEMPLATE_CREATED:
                if (action.payload.actionStatus === "create") {
                    return {
                        ...state,
                        message: SuccessMessages.scheduledEmails.saveTemplate,
                    };
                } else if (action.payload.actionStatus === "delete") {
                    return {
                        ...state,
                        message: SuccessMessages.scheduledEmails.deleteTemplate,
                    };
                }
            case pageId + SCHEDULE_TEMPLATE_COMMITTED:
                return {
                    ...state,
                    message: "",
                };
            case pageId + REDIRECT:
                return {
                    ...state,
                    valuesPersonnel: action.payload.valuesPersonnel,
                };
            default:
                return state;
        }
    };
    return Reducer;
};

export default createRealtimeRegisterPageReducer;
