// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PBContractProjectPeriodFormItem-content-T067L{width:100%}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/personnel/PersonnelBoardPage/PersonnelBoardDetailModal/PersonnelBoardContracts/PersonnelBoardContractsRow/PBContractProjectPeriodFormItem/PBContractProjectPeriodFormItem.scss"],"names":[],"mappings":"AAAA,+CACI,UAAW","sourcesContent":[".content {\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `PBContractProjectPeriodFormItem-content-T067L`
};
export default ___CSS_LOADER_EXPORT___;
