// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PerGAssigneeFormItem-container-IGUv0{width:100%;text-align:left}.PerGAssigneeFormItem-userInput-gr2cM{width:100%}.PerGAssigneeFormItem-tooltip-_Nyx7{margin-left:5px}.PerGAssigneeFormItem-infoIcon-lOp2f{align-self:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/personnel/PersonnelGanttPage/PersonnelGanttSearchDrawer/PerGAssigneeFormItem/PerGAssigneeFormItem.scss"],"names":[],"mappings":"AAAA,sCACI,UAAW,CACX,eAAgB,CACnB,sCAGG,UAAW,CACd,oCAGG,eAAgB,CACnB,qCAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PerGAssigneeFormItem-container-IGUv0`,
	"userInput": `PerGAssigneeFormItem-userInput-gr2cM`,
	"tooltip": `PerGAssigneeFormItem-tooltip-_Nyx7`,
	"infoIcon": `PerGAssigneeFormItem-infoIcon-lOp2f`
};
export default ___CSS_LOADER_EXPORT___;
