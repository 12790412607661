import createEditPage from './Factories/createEditPage';

import Paths from '../Routes/Paths';
import { ORGANIZATION_EDIT_PAGE } from './pageIds';

import OrganizationForm from '../Forms/OrganizationForm';
import { Endpoint } from '~/domain/api';
import { convertOrganizationResponseDataEntry, convertOrganizationFormToAPI } from '~/domain/data';

const pageId = ORGANIZATION_EDIT_PAGE;
const resourceURL = `${Endpoint.getBaseUrl()}/${Endpoint.organizations}`;
const commentTemplateUrl = `${Endpoint.getBaseUrl()}/${Endpoint.commentTemplateOrganization}`;

const resourceName = 'organizations'
const commentsReducerName = 'organizationEditPageComments';
const deleteAuthorized = (authorizedActions) => { return authorizedActions && authorizedActions[resourceName] && authorizedActions[resourceName]['delete'] }
const accessAuthorized = () => { return true }

const OrganizationEditPageContainer = createEditPage(
  pageId,
  'organizationEditPage',
  '取引先 編集',
  OrganizationForm,
  resourceURL,
  '',
  Paths.organizations,
  convertOrganizationResponseDataEntry,
  convertOrganizationFormToAPI,
  commentsReducerName,
  commentTemplateUrl,
  deleteAuthorized,
  accessAuthorized,
  resourceName,
  true,
  undefined,
  true,
  Paths.organizations,
  'organizationEditPageNewComments',
  'organizationEditPageEditComments',
  'organizationEditPageReplyComments'
);

export default OrganizationEditPageContainer;
export {
  pageId,
  commentsReducerName,
  commentTemplateUrl,
};
