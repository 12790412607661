// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RecruitCommentUserFormItem-container-YSKoY{width:100%;text-align:left}.RecruitCommentUserFormItem-userInput-bzWQr{width:100%}.RecruitCommentUserFormItem-tooltip-zsQV2{margin-left:5px}.RecruitCommentUserFormItem-infoIcon-Sel3c{align-self:center}.RecruitCommentUserFormItem-marginBottom-s5KVE{margin-bottom:1%}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/recruit/RecruitBoardPage/RecruitBoardSearchDrawer/RecruitCommentUserFormItem/RecruitCommentUserFormItem.scss"],"names":[],"mappings":"AAAA,4CACE,UAAW,CACX,eAAgB,CACjB,4CAGC,UAAW,CACZ,0CAGC,eAAgB,CACjB,2CAGC,iBAAkB,CACnB,+CAGC,gBAAiB","sourcesContent":[".container {\n  width: 100%;\n  text-align: left;\n}\n\n.userInput {\n  width: 100%;\n}\n\n.tooltip {\n  margin-left: 5px;\n}\n\n.infoIcon {\n  align-self: center;\n}\n\n.marginBottom {\n  margin-bottom: 1%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `RecruitCommentUserFormItem-container-YSKoY`,
	"userInput": `RecruitCommentUserFormItem-userInput-bzWQr`,
	"tooltip": `RecruitCommentUserFormItem-tooltip-zsQV2`,
	"infoIcon": `RecruitCommentUserFormItem-infoIcon-Sel3c`,
	"marginBottom": `RecruitCommentUserFormItem-marginBottom-s5KVE`
};
export default ___CSS_LOADER_EXPORT___;
