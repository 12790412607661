// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserBulkEditPage-pageHeader-Hcqj_{padding:16px 0 0 0}.UserBulkEditPage-infoAlert-hHGue{text-align:left;margin:15px 0}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/UserBulkEditPage/UserBulkEditPage.scss"],"names":[],"mappings":"AAAA,mCACI,kBAAmB,CACtB,kCAGG,eAAgB,CAChB,aAAc","sourcesContent":[".pageHeader {\n    padding: 16px 0 0 0;\n}\n\n.infoAlert {\n    text-align: left;\n    margin: 15px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageHeader": `UserBulkEditPage-pageHeader-Hcqj_`,
	"infoAlert": `UserBulkEditPage-infoAlert-hHGue`
};
export default ___CSS_LOADER_EXPORT___;
