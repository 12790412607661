"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertDynamicRowTitlesFormModelToContentModel = exports.convertDynamicRowTitlesApiModelToFormModel = void 0;
var convertDynamicRowTitlesApiModelToFormModel = function (data) {
    return {
        dynamicRowTitles: data,
    };
};
exports.convertDynamicRowTitlesApiModelToFormModel = convertDynamicRowTitlesApiModelToFormModel;
var convertDynamicRowTitlesFormModelToContentModel = function (data) {
    return {
        dynamic_row_titles: data.dynamicRowTitles.filter(function (item) { return item.title; }),
    };
};
exports.convertDynamicRowTitlesFormModelToContentModel = convertDynamicRowTitlesFormModelToContentModel;
