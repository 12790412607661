// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BoardCardBasicInfo-rowSpacer-_P2SR{margin-top:20px}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Common/Board/BoardCardBasicInfo/BoardCardBasicInfo.scss"],"names":[],"mappings":"AAAA,oCACI,eAAgB","sourcesContent":[".rowSpacer {\n    margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rowSpacer": `BoardCardBasicInfo-rowSpacer-_P2SR`
};
export default ___CSS_LOADER_EXPORT___;
