// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TelInputFormItem-container-AX686{margin:16px auto;text-align:left}.TelInputFormItem-field-OqgUl{max-width:800px;margin:8px 0}.TelInputFormItem-button-PevN8{width:30%;margin:auto}.TelInputFormItem-alert-fZPAf{margin-top:16px;margin-bottom:16px}.TelInputFormItem-phonenumberDash-v_z7t{display:inline-block;width:5%;text-align:center;height:100%;align-self:center}.TelInputFormItem-nameSeparator-uVzxu{display:inline-block;width:4%}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Forms/TelInputFormItem/TelInputFormItem.scss"],"names":[],"mappings":"AAAA,kCACI,gBAAiB,CACjB,eAAgB,CACnB,8BAGG,eAAgB,CAChB,YAAa,CAChB,+BAGG,SAAU,CACV,WAAY,CACf,8BAGG,eAAgB,CAChB,kBAAmB,CACtB,wCAGG,oBAAqB,CACrB,QAAS,CACT,iBAAkB,CAClB,WAAY,CACZ,iBAAkB,CACrB,sCAGG,oBAAqB,CACrB,QAAS","sourcesContent":[".container {\n    margin: 16px auto;\n    text-align: left;\n}\n\n.field {\n    max-width: 800px;\n    margin: 8px 0;\n}\n\n.button {\n    width: 30%;\n    margin: auto;\n}\n\n.alert {\n    margin-top: 16px;\n    margin-bottom: 16px;\n}\n\n.phonenumberDash {\n    display: inline-block;\n    width: 5%;\n    text-align: center;\n    height: 100%;\n    align-self: center;\n}\n\n.nameSeparator {\n    display: inline-block;\n    width: 4%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `TelInputFormItem-container-AX686`,
	"field": `TelInputFormItem-field-OqgUl`,
	"button": `TelInputFormItem-button-PevN8`,
	"alert": `TelInputFormItem-alert-fZPAf`,
	"phonenumberDash": `TelInputFormItem-phonenumberDash-v_z7t`,
	"nameSeparator": `TelInputFormItem-nameSeparator-uVzxu`
};
export default ___CSS_LOADER_EXPORT___;
