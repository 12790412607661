// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ScheduledEmailSenderFormItem-container-X7AbL{width:100%;text-align:left}.ScheduledEmailSenderFormItem-userInput-WDEZk{width:100%}.ScheduledEmailSenderFormItem-tooltip-G3kzt{margin-left:5px}.ScheduledEmailSenderFormItem-infoIcon-v8IZa{align-self:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/ScheduledEmailSearchPage/ScheduledEmailSearchDrawer/ScheduledEmailSenderFormItem/ScheduledEmailSenderFormItem.scss"],"names":[],"mappings":"AAAA,8CACI,UAAW,CACX,eAAgB,CACnB,8CAGG,UAAW,CACd,4CAGG,eAAgB,CACnB,6CAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ScheduledEmailSenderFormItem-container-X7AbL`,
	"userInput": `ScheduledEmailSenderFormItem-userInput-WDEZk`,
	"tooltip": `ScheduledEmailSenderFormItem-tooltip-G3kzt`,
	"infoIcon": `ScheduledEmailSenderFormItem-infoIcon-v8IZa`
};
export default ___CSS_LOADER_EXPORT___;
