// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CorporateAddressFormItem-container-eimYb{width:100%;text-align:left}.CorporateAddressFormItem-userInput-EvbCO{width:100%}.CorporateAddressFormItem-tooltip-j6Uz8{margin-left:5px}.CorporateAddressFormItem-infoIcon-rvGP_{align-self:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/OrganizationSearchPage/OrganizationSearchDrawer/CorporateAddressFormItem/CorporateAddressFormItem.scss"],"names":[],"mappings":"AAAA,0CACI,UAAW,CACX,eAAgB,CACnB,0CAGG,UAAW,CACd,wCAGG,eAAgB,CACnB,yCAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `CorporateAddressFormItem-container-eimYb`,
	"userInput": `CorporateAddressFormItem-userInput-EvbCO`,
	"tooltip": `CorporateAddressFormItem-tooltip-j6Uz8`,
	"infoIcon": `CorporateAddressFormItem-infoIcon-rvGP_`
};
export default ___CSS_LOADER_EXPORT___;
