import React from "react";
import { SHARED_EMAIL_PAGE } from "./pageIds";
import createSearchPage from "~/components/Pages/Factories/createSearchPage";
import { Endpoint } from "~/domain/api";
import {
    sharedEmailSearchParamToAPI,
    convertSharedEmailResponseDataEntry,
} from "~/domain/data";
import SharedEmailTable from "~/components/Tables/SharedEmailsTable/SharedEmailsTable";
import SharedEmailSearchInfo from "~/components/DataDisplay/SharedEmailSearchInfo/SharedEmailSearchInfo";
import SharedEmailTableDisplaySettingModal from "../Tables/SharedEmailsTable/SharedEmailTableDisplaySettingModal/SharedEmailTableDisplaySettingModal";
import SharedEmailSearchDrawer from "./SharedEmailSearchPage/SharedEmailSearchDrawer/SharedEmailSearchDrawer";
import { useFetchSharedEmailSettingAPIQuery } from "~/hooks/sharedEmailSetting";
import NotFoundPage from "./NotFoundPage";
import SpinContainer from "../Common/SpinContainer";
import Paths from "../Routes/Paths";

const pageId = SHARED_EMAIL_PAGE;
const resourceURL = `${Endpoint.getBaseUrl()}/${Endpoint.sharedEmails}`;
const resourceName = "shared_emails";
const changeActiveStatusAuthorized = (authorizedActions) => {
    return true;
};
const deleteAuthorized = (authorizedActions) => {
    return (
        authorizedActions &&
        authorizedActions[resourceName] &&
        authorizedActions[resourceName]["delete"]
    );
};
const csvAuthorized = (authorizedActions) => {
    return true;
};
const columnSettingAuthorized = (authorizedActions) => {
    return true;
};
const accessAuthorized = (authorizedActions) => {
    return true;
};
const searchTemplateAuthorized = (authorizedActions) => {
    return true;
};
const editAuthorized = (authorizedActions) => {
    return true;
};

const searchConditionSanitizer = (unshowList, targetData) => {
    const newData = { ...targetData };
    for (const fieldName of unshowList) {
        if (fieldName === "sender") {
            newData["sender"] = undefined;
        } else if (fieldName === "email") {
            newData["email"] = undefined;
        } else if (fieldName === "subject_AND") {
            newData["subject_AND"] = undefined;
        } else if (fieldName === "text_AND") {
            newData["text_AND"] = undefined;
        } else if (fieldName === "has_attachments") {
            newData["has_attachments"] = undefined;
        } else if (fieldName === "staff") {
            newData["staff"] = undefined;
        } else if (fieldName === "date_range") {
            newData["date_range"] = undefined;
        } else if (fieldName === "comment_user") {
            newData["comment_user"] = undefined;
        } else {
            newData[fieldName] = undefined;
        }
    }
    return newData;
};

const buildBulkEditUrl = (selectedRows) => {
    const query = new URLSearchParams();
    const sharedEmails = selectedRows.map(
        ({ id, subject }) => ({ id, subject })
    );
    query.set("sharedEmails", JSON.stringify(sharedEmails));
    return `${Paths.sharedMailBulkEdit}?${query}`;
};

const SharedEmailPageContainer = createSearchPage(
    pageId,
    "sharedEmailPage",
    "マッチングメール 一覧",
    SharedEmailSearchDrawer,
    SharedEmailTable,
    resourceURL,
    convertSharedEmailResponseDataEntry,
    sharedEmailSearchParamToAPI,
    true,
    undefined,
    undefined,
    true,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    changeActiveStatusAuthorized,
    deleteAuthorized,
    csvAuthorized,
    columnSettingAuthorized,
    accessAuthorized,
    undefined,
    searchTemplateAuthorized,
    resourceName,
    false,
    [],
    [SharedEmailTableDisplaySettingModal],
    searchConditionSanitizer,
    [SharedEmailSearchInfo],
    undefined,
    true,
    editAuthorized,
    buildBulkEditUrl,
    undefined,
    ""
);

const SharedEmailPage = (props) => {
    const { data, isLoading } = useFetchSharedEmailSettingAPIQuery({});

    if (isLoading) {
        return <SpinContainer />;
    }

    if (!data?.username) {
        return <NotFoundPage />;
    }

    return <SharedEmailPageContainer {...props} />;
};

export default SharedEmailPage;
