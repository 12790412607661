// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TagAjaxSelect-tag-BsyhV{margin-top:3px;margin-bottom:3px;margin-inline-end:4.8px;padding-inline-start:4.8px;padding-inline-end:4px;white-space:normal}.TagAjaxSelect-select_option-gxyA9{text-align:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Common/TagAjaxSelect/TagAjaxSelect.scss"],"names":[],"mappings":"AAAA,yBACI,cAAe,CACf,iBAAkB,CAClB,uBAAwB,CACxB,0BAA2B,CAC3B,sBAAuB,CACvB,kBAAmB,CACtB,mCAGG,iBAAkB","sourcesContent":[".tag {\n    margin-top: 3px;\n    margin-bottom: 3px;\n    margin-inline-end: 4.8px;\n    padding-inline-start: 4.8px;\n    padding-inline-end: 4px;\n    white-space: normal;\n}\n\n.select_option {\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tag": `TagAjaxSelect-tag-BsyhV`,
	"select_option": `TagAjaxSelect-select_option-gxyA9`
};
export default ___CSS_LOADER_EXPORT___;
