// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TableDisplaySettingModal-buttonDefault-Czl6e:focus{color:#444444;border-color:#d9d9d9}.TableDisplaySettingModal-buttonDefault-Czl6e:hover{color:#A7CF69;border-color:#A7CF69}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Modals/TableDisplaySettingModal/TableDisplaySettingModal.scss"],"names":[],"mappings":"AAAA,oDACI,aAAc,CACd,oBAAqB,CACxB,oDAEG,aAAc,CACd,oBAAqB","sourcesContent":[".buttonDefault:focus{\n    color: #444444;\n    border-color: #d9d9d9;\n}\n.buttonDefault:hover{\n    color: #A7CF69;\n    border-color: #A7CF69;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonDefault": `TableDisplaySettingModal-buttonDefault-Czl6e`
};
export default ___CSS_LOADER_EXPORT___;
