// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EmailSearchIncludeTextFormItem-container-yj_S1{width:100%;text-align:left}.EmailSearchIncludeTextFormItem-userInput-ubdiT{width:100%}.EmailSearchIncludeTextFormItem-tooltip-NjW5g{margin-left:5px}.EmailSearchIncludeTextFormItem-infoIcon-sHpCS{align-self:center}.EmailSearchIncludeTextFormItem-marginBottom-GCsVt{margin-bottom:1%}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/SharedEmailSearchPage/SharedEmailSearchDrawer/EmailSearchIncludeTextFormItem/EmailSearchIncludeTextFormItem.scss"],"names":[],"mappings":"AAAA,gDACI,UAAW,CACX,eAAgB,CACnB,gDAGG,UAAW,CACd,8CAGG,eAAgB,CACnB,+CAGG,iBAAkB,CACrB,mDAGG,gBAAiB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n\n.marginBottom {\n    margin-bottom: 1%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `EmailSearchIncludeTextFormItem-container-yj_S1`,
	"userInput": `EmailSearchIncludeTextFormItem-userInput-ubdiT`,
	"tooltip": `EmailSearchIncludeTextFormItem-tooltip-NjW5g`,
	"infoIcon": `EmailSearchIncludeTextFormItem-infoIcon-sHpCS`,
	"marginBottom": `EmailSearchIncludeTextFormItem-marginBottom-GCsVt`
};
export default ___CSS_LOADER_EXPORT___;
