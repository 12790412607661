import React, { Component } from "react";
import { QueryClient } from 'react-query';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
    PageHeader,
    BackTop,
    Button,
    Tooltip,
    Row,
    Col,
    Select,
    Form,
} from "antd";
import {
    DeleteOutlined,
    DownloadOutlined,
    EditOutlined,
    StarOutlined,
    SaveOutlined,
    CopyOutlined,
    QuestionCircleFilled,
    UserOutlined,
} from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faBellSlash } from "@fortawesome/free-solid-svg-icons";
import {
    searchAction,
    bulkDeleteAction,
    bulkUpdateAction,
    bulkCopyAction,
    deleteApi,
    createApi,
    updateApi,
    fetchApi,
    fetchDisplaySettingAction,
    resetError,
} from "~/actions/data";
import { changeTemplate, clearSearchForm } from "~/actions/form";
import { downloadCsv, Endpoint } from "~/domain/api";
import { convertSearchTemplateResponseDataEntry } from "~/domain/data";
import ErrorScreen from "../../Screens/ErrorScreen";
import { isMobileDevices } from "../../helpers";
import {
    showDeleteModal,
    showSaveTemplateModal,
} from "../../Feedbacks/Modal/Modal";
import {
    SEARCH_TEMPLATE_UPDATED,
    SEARCH_TEMPLATE_COMMITTED,
    SEARCH_TEMPLATE_DELETED,
    SEARCH_TEMPLATE_CREATED,
    SEARCH_TEMPLATE_LOADED,
    SEARCH_TEMPLATE_LOADING,
    SEARCH_MENU_OPEN,
    SET_DEFAULT_TEMPLATE,
    RESET_ERROR_MESSAGE,
    UPDATE_CURRENT_PAGE,
} from "~/actions/actionTypes";
import NotFoundPage from "../NotFoundPage";
import Paths from "../../Routes/Paths";
import {
    ORGANIZATION_SEARCH_PAGE,
    SCHEDULED_MAIL_SEARCH_PAGE,
    SHARED_EMAIL_PAGE,
    SHARED_EMAIL_NOTIFICATION_SEARCH_PAGE,
    USER_SEARCH_PAGE,
    CONTACT_SEARCH_PAGE,
} from "../pageIds";
import {
    ErrorMessages,
    SuccessMessages,
    TABLE_OPERATION_SELECTABLE_LIMIT,
    TooltipMessages,
} from "~/utils/constants";
import paramsToQueryString from "~/domain/query";
import GenericModal from "~/components/Modals/GenericModal/GenericModal";
import GenericModalContent from "~/components/Modals/GenericModal/GenericModalContent/GenericModalContent";
import {
    customSuccessMessage,
    customErrorMessage,
} from "~/components/Common/AlertMessage/AlertMessage";
import UserSlashIcon from "~/components/icons/UserSlashIcon/UserSlashIcon";
import TableBulkButton from "~/components/Common/TableBulkButton/TableBulkButton";
import SwapDisabledButton from "~/components/Common/SwapDisabledButton/SwapDisabledButton";
import SwapBlockButton from "~/components/Common/SwapBlockButton/SwapBlockButton";
import SwapArchiveButton from "~/components/Common/SwapArchiveButton/SwapArchiveButton";
import { stringifyQueryString, queryClient } from "~/utils/utils";
import styles from "../page.scss";

const newSearchDrawerPages = [
    ORGANIZATION_SEARCH_PAGE,
    CONTACT_SEARCH_PAGE,
    SCHEDULED_MAIL_SEARCH_PAGE,
    SHARED_EMAIL_PAGE,
    SHARED_EMAIL_NOTIFICATION_SEARCH_PAGE,
];

const isNewSearchDrawerPage = (pageId) => {
    return newSearchDrawerPages.includes(pageId);
};

const isEmpty = (array) => array.length === 0;
const displaySettingURL = `${Endpoint.getBaseUrl()}/${
    Endpoint.userDisplaySetting
}`;
const usersActiveStatusURL = `${Endpoint.getBaseUrl()}/${
    Endpoint.usersActive
}`;
const organizationBlocklistURL = `${Endpoint.getBaseUrl()}/${
    Endpoint.organizationBlockList
}`;
const contactArchiveURL = `${Endpoint.getBaseUrl()}/${
    Endpoint.contactsArchive
}`;

/**
 * This Factory creates a page component class that has common several handlers and data loading methods.
 * @param {string} pageId - A PageId that is used to dispatch action to corresponding reducer.
 * @param {string} reducerName - A reducerName that correspond to the page and that is defined in components/reducers/pages.js.
 * @param {string} pageTitle - A page title string, that will be rendered to a page header section.
 * @param {class} FormClass - A Subclass of component/Forms/base/Baseform, that corresponds to this page's search condition.
 * @param {class} TableClass - A Class of component/Tables, that corresponds to this page's resources.
 * @param {string} resourceURL - An endpoint URL of the target resources, correspond to the backend list API.
 * @param {function} convertResponseDataEntry - A callback function that receives one object and returns new converted object.
 * @param {function} convertFormDataToAPI - A callback function that convert the form data to API Body data.
 * @param {boolean} enableCache - If true, this component will use the last fetched data when the component did mount. (if you want to refresh, do search.)
 * @param {string} linkToRegisterPage - An URL of the register page. If given, "Create" Button appears on the top of the table.
 * @param {string} csvDownloadURL - An URL of CSV API. If given, "Download CSV" Button appears on the top of the table.
 * @return {element} A React component.
 */
const createSearchPage = (
    pageId,
    reducerName,
    pageTitle,
    FormClass,
    TableClass,
    resourceURL,
    convertResponseDataEntry,
    convertFormDataToAPI,
    enableCache = false,
    linkToRegisterPage = undefined,
    csvDownloadURL = undefined,
    bulkDelete = undefined,
    columnSetting = undefined,
    columns = undefined,
    columnSettingURL = undefined,
    changeActiveStatus = undefined,
    hide_devider = undefined,
    copyURL = undefined,
    changeActiveStatusAuthorized = () => {
        return true;
    },
    deleteAuthorized = () => {
        return true;
    },
    csvAuthorized = () => {
        return true;
    },
    columnSettingAuthorized = () => {
        return true;
    },
    accessAuthorized = () => {
        return true;
    },
    searchTemplateURL = undefined,
    searchTemplateAuthorized = () => {
        return true;
    },
    resourceName = undefined,
    useDisplaySetting = false,
    extraButtons = undefined,
    tableControlButtons = undefined,
    searchConditionSanitizer = undefined,
    pageHeaderExtra = undefined,
    leftExtraButtons = undefined,
    bulkEdit = undefined,
    editAuthorized = () => {
        return true;
    },
    buildBulkEditUrl = () => {
        return "";
    },
    bulkEditSelectionLimit = undefined,
    invalidCacheKey = ""
) => {
    const Page = class extends Component {
        constructor(props) {
            super(props);
            this.state = {
                downloadingCsv: false,
                bulkDeleting: false,
                changingActiveStatus: false,
                selectedRows: [],
                currentSelectedColumnKeys: [],
                bulkCopying: false,
                selectedSearchItemKeys: [],
                isUserInactiveModalOpen: false,
                isUserActiveModalOpen: false,
                isNotificationInactiveModalOpen: false,
                isNotificationActiveModalOpen: false,
                bulkNotificationChanging: false,
            };
            this.formClass = React.createRef();
            this.isBulkActionProcessing = false;
        }

        componentDidMount() {
            // This conditional fetching prevents slipping of table data when you switch between item list and item detail pages.
            if (searchTemplateURL) {
                this.fetchSearchTemplateData();
            }
            const refreshAfterDisplaySettingFetched =
                !isNewSearchDrawerPage(pageId);
            this.fetchDisplaySettingData(refreshAfterDisplaySettingFetched);
            const { pageState } = this.props;
            const { currentPage } = pageState;
            const page = this.getPageFromUrl();
            if (!!page && page !== currentPage) {
                const { dispatch } = this.props;
                dispatch({
                    type: pageId + UPDATE_CURRENT_PAGE,
                    payload: page,
                });
            }
        }

        componentDidUpdate(prevProps) {
            // Extracting props.
            const { pageState, dispatch, history } = this.props;
            const {
                message,
                errorMessage,
                requireRefresh,
                requireResetSelectedRows,
                currentPage,
                pageSize,
                currentSearchConditions: formValues,
                sortKey,
                sortOrder,
                currentSearchTemplates,
                displaySetting,
                isDefaultTemplateAttached,
                errorExtraInfo,
            } = pageState;

            const { selectedTemplateName } = this.state;

            // Popup messages if necessary.
            if (message) {
                if (this.isBulkActionProcessing) {
                    customSuccessMessage(message);
                    this.isBulkActionProcessing = false;
                }
            }
            if (errorMessage) {
                customErrorMessage("", {
                    content: errorMessage.split(",").map((message, key) => (
                        <span key={key}>
                            {message}
                            <br />
                        </span>
                    )),
                });
                dispatch({ type: pageId + RESET_ERROR_MESSAGE });
            }

            if (errorExtraInfo) {
                if (errorExtraInfo.message) {
                    customErrorMessage(errorExtraInfo.message);
                }
                if (errorExtraInfo.redirect) {
                    history.push(errorExtraInfo.redirect);
                }
                dispatch(resetError(pageId));
            }

            if (requireRefresh) {
                const unshowDisplaySettings =
                    displaySetting &&
                    displaySetting[resourceName] &&
                    displaySetting[resourceName]["search"]
                        ? displaySetting[resourceName]["search"]
                        : [];
                const sanitizedFormValues = searchConditionSanitizer
                    ? searchConditionSanitizer(
                          unshowDisplaySettings,
                          formValues
                      )
                    : formValues;
                if (requireResetSelectedRows) {
                    this.setState({ selectedRows: [] });
                }
                this.fetchData(
                    currentPage,
                    pageSize,
                    sanitizedFormValues,
                    sortKey,
                    sortOrder
                );
            }

            if (!selectedTemplateName && isDefaultTemplateAttached) {
                let starTemplate = (currentSearchTemplates || []).find(
                    (e) => e.star
                );
                if (starTemplate) {
                    this.setState({ selectedTemplateName: starTemplate.name });
                    dispatch({
                        type: pageId + SET_DEFAULT_TEMPLATE,
                        data: starTemplate.values,
                    });
                }
            }
        }

        getPageFromUrl = () => {
            let currentPage = 1;
            const search = this.props.location.search;
            if (!!search) {
                const queryParams = new URLSearchParams(search);
                const page = queryParams.get("page");
                if (!!page) {
                    try {
                        currentPage = +page;
                    } catch (err) {
                        currentPage = 1;
                    }
                } else {
                    currentPage = undefined;
                }
            }
            return currentPage;
        };

        fetchSearchTemplateData() {
            const { token, dispatch } = this.props;
            dispatch(
                fetchApi(
                    pageId,
                    token,
                    searchTemplateURL,
                    null,
                    (status = {
                        before: SEARCH_TEMPLATE_LOADING,
                        after: SEARCH_TEMPLATE_LOADED,
                    }),
                    convertSearchTemplateResponseDataEntry
                )
            );
        }

        onTableChange = (paginationProps, filterProps, sorterProps) => {
            // A Handler for Ant Design Tables.
            const { pageState } = this.props;
            const {
                currentPage,
                pageSize,
                currentSearchConditions: formValues,
            } = pageState;
            const sortKey = sorterProps.columnKey;
            const sortOrder = sorterProps.order;
            this.fetchData(
                currentPage,
                pageSize,
                formValues,
                sortKey,
                sortOrder
            );
        };

        onPageChange = (page, pageSize) => {
            const { pageState } = this.props;
            const { currentSearchConditions } = pageState;
            const formValues = {
                ...currentSearchConditions,
                page,
            };
            this.onSearch(formValues);
        };

        onPageSizeChange = (current, size) => {
            const { pageState } = this.props;
            const {
                currentSearchConditions: formValues,
                sortKey,
                sortOrder,
            } = pageState;
            formValues["page"] = 1;
            this.syncToUrl(formValues)
            this.fetchData(1, size, formValues, sortKey, sortOrder); // currentPage must be 1 because the search result could be under pageSize.
        };

        syncToUrl = (values) => {
            const { history: router } = this.props;
            if (isEmpty(values)) {
                router.push({ search: undefined });
                return;
            }
            const paramObj = { ...values };
            for (const key of Object.keys(paramObj)) {
                const val = paramObj[key];
                if (
                    Array.isArray(val) &&
                    val.some((v) => typeof v === "object")
                ) {
                    paramObj[key] = JSON.stringify(val);
                }
            }
            router.push({
                search: stringifyQueryString(paramObj),
            });
        };

        onSearch = (formValues, setting) => {
            this.syncToUrl(formValues);
            // A Handler for Ant Design Forms.
            const { pageState } = this.props;
            const { pageSize, sortKey, sortOrder } = pageState;
            const pageSizeOnSetting = setting?.[resourceName]?.page_size;
            if (pageSizeOnSetting) {
                this.fetchData(
                    formValues.page ? formValues.page : 1,
                    pageSizeOnSetting,
                    formValues,
                    sortKey,
                    sortOrder
                );
            } else {
                this.fetchData(
                    formValues.page ? formValues.page : 1,
                    pageSize,
                    formValues,
                    sortKey,
                    sortOrder
                ); // currentPage must be 1 because the search result could be under pageSize.
            }
        };

        resetFormHandler = (initialSearchConditions = {}) => {
            const { dispatch } = this.props;
            this.setState({ selectedTemplateName: "選択してください" });
            dispatch(clearSearchForm(pageId, initialSearchConditions));
        };

        onCsvDownload = () => {
            const { token } = this.props;
            this.setState({ downloadingCsv: true });
            const fileName = csvDownloadURL.split("/").pop();
            downloadCsv(token, csvDownloadURL, fileName)
                .catch((e) => {
                    console.error(e);
                    customErrorMessage("CSVのダウンロードに失敗しました。");
                })
                .finally(() => this.setState({ downloadingCsv: false }));
        };

        onBulkEdit = () => {
            const { history: router } = this.props;
            const { selectedRows } = this.state;
            if (pageId === USER_SEARCH_PAGE) {
                if (selectedRows.some((user) => user.role === "master")) {
                    customErrorMessage(ErrorMessages.user.bulkEditMaster);
                    return;
                }
            }
            if (pageId === SCHEDULED_MAIL_SEARCH_PAGE) {
                const ngStatuses = ["sending", "sent", "error"];
                if (
                    selectedRows.some(
                        (email) => ngStatuses.includes(email.status)
                    )
                ) {
                    customErrorMessage(
                        ErrorMessages.scheduledEmail.bulkEditNgStatus
                    );
                    return;
                }
            }
            const url = buildBulkEditUrl(selectedRows);
            router.push(url);
        };

        onBulkDelete = () => {
            this.isBulkActionProcessing = true;
            const { token, dispatch, currentUserId } = this.props;
            const { selectedRows } = this.state;
            this.setState({ bulkDeleting: true });
            const selectedIds = selectedRows.map((row) => row.id);
            const currentUserInSelectedRows = selectedIds.filter(
                (item) => item === currentUserId
            );

            if (pageId === USER_SEARCH_PAGE) {
                if (currentUserInSelectedRows.length > 0) {
                    customErrorMessage(ErrorMessages.user.deleteFailed);
                } else {
                    dispatch(
                        bulkDeleteAction(
                            pageId,
                            token,
                            resourceURL,
                            selectedIds,
                            {
                                message: SuccessMessages.user.delete,
                            }
                        )
                    );
                }
            } else {
                dispatch(
                    bulkDeleteAction(pageId, token, resourceURL, selectedIds, {
                        message: SuccessMessages.generic.delete,
                    })
                );
            }
            this.setState({ bulkDeleting: false });
        };

        onChangeActiveStatus(value) {
            this.isBulkActionProcessing = true;
            const { token, dispatch, currentUserId } = this.props;
            const { selectedRows } = this.state;
            this.setState({ changingActiveStatus: true });
            const selectedIds = selectedRows.map((row) => row.id);
            const nullInformationRequiredRows = selectedRows.filter(
                (item) => !item.last_name && !item.first_name
            );
            const currentUserInSelectedRows = selectedIds.filter(
                (item) => item === currentUserId
            );

            if (pageId === USER_SEARCH_PAGE) {
                if (nullInformationRequiredRows.length > 0) {
                    customErrorMessage(ErrorMessages.user.updateStatusFailed);
                } else if (currentUserInSelectedRows.length > 0) {
                    customErrorMessage(ErrorMessages.user.cannotUpdateStatus);
                } else {
                    dispatch(
                        bulkUpdateAction(
                            pageId,
                            token,
                            resourceURL,
                            selectedIds,
                            "is_active",
                            { value },
                            {
                                message: value
                                    ? SuccessMessages.user.enable
                                    : SuccessMessages.user.disable,
                            },
                        )
                    );
                }
            } else {
                const payload = {};
                if (pageId === SHARED_EMAIL_NOTIFICATION_SEARCH_PAGE) {
                    payload.message = value
                        ? SuccessMessages.notificationSetting.enable
                        : SuccessMessages.notificationSetting.disable;
                }
                dispatch(
                    bulkUpdateAction(
                        pageId,
                        token,
                        resourceURL,
                        selectedIds,
                        "is_active",
                        { value },
                        payload,
                    )
                );
            }
            this.setState({ changingActiveStatus: false });
        }

        onChangeUserDisabled(isActive) {
            this.isBulkActionProcessing = true;
            const { token, dispatch } = this.props;
            const { selectedRows } = this.state;
            const selectedIds = selectedRows.map((row) => row.id);
            dispatch(
                bulkUpdateAction(
                    pageId,
                    token,
                    usersActiveStatusURL,
                    selectedIds,
                    "is_active",
                    { is_active: isActive },
                    {
                        message: SuccessMessages.generic.update,
                    },
                    () => {},
                    (error) => {
                        if (!error.detail){
                            return {
                                detail: ErrorMessages.generic.update,
                                errorExtraInfo: ErrorMessages.generic.update,
                            }
                        }else{
                            return error
                        }
                    }
                )
            );
        }

        onChangeOrganizationBlock(isBlacklisted) {
            this.isBulkActionProcessing = true;
            const { token, dispatch } = this.props;
            const { selectedRows } = this.state;
            const selectedIds = selectedRows.map((row) => row.id);
            dispatch(
                bulkUpdateAction(
                    pageId,
                    token,
                    organizationBlocklistURL,
                    selectedIds,
                    "is_blacklisted",
                    { is_blacklisted: isBlacklisted },
                    {
                        message: SuccessMessages.generic.update,
                    },
                    () => {},
                    (error) => {
                        if (!error.detail){
                            return {
                                detail: ErrorMessages.generic.update,
                                errorExtraInfo: ErrorMessages.generic.update,
                            }
                        }else{
                            return error
                        }
                    }
                )
            );
        }

        onChangeContactArchive(isArchived) {
            this.isBulkActionProcessing = true;
            const { token, dispatch } = this.props;
            const { selectedRows } = this.state;
            const selectedIds = selectedRows.map((row) => row.id);
            dispatch(
                bulkUpdateAction(
                    pageId,
                    token,
                    contactArchiveURL,
                    selectedIds,
                    "is_archived",
                    { is_archived: isArchived },
                    {
                        message: SuccessMessages.generic.update,
                    },
                    () => {},
                    (error) => {
                        if (!error.detail){
                            return {
                                detail: ErrorMessages.generic.update,
                                errorExtraInfo: ErrorMessages.generic.update,
                            }
                        }else{
                            return error
                        }
                    }
                )
            );
        }

        onChangeSharedMailsNotificationDisabled(isActive) {
            this.isBulkActionProcessing = true;
            const { token, dispatch } = this.props;
            const { selectedRows } = this.state;
            const selectedIds = selectedRows.map((row) => row.id);
            dispatch(
                bulkUpdateAction(
                    pageId,
                    token,
                    resourceURL,
                    selectedIds,
                    "is_active",
                    { value: isActive },
                    {
                        message: SuccessMessages.generic.update,
                    },
                    async() => {
                        if(invalidCacheKey){
                            await queryClient.invalidateQueries(invalidCacheKey);
                            await queryClient.fetchQuery(invalidCacheKey);
                        }
                    },
                    () => ({
                        detail: ErrorMessages.generic.update,
                        errorExtraInfo: ErrorMessages.generic.update,
                    })
                )
            );
        }

        onCheckColumn = (selectedRows) => {
            this.setState({ selectedRows: selectedRows });
        };

        fetchData(currentPage, pageSize, searchConditions, sortKey, sortOrder) {
            const { token, dispatch, pageState } = this.props;
            const { displaySettingLoaded } = pageState;
            if (!isNewSearchDrawerPage(pageId) && !displaySettingLoaded) {
                return;
            }
            dispatch(
                searchAction(
                    pageId,
                    token,
                    resourceURL,
                    currentPage,
                    pageSize,
                    searchConditions,
                    convertFormDataToAPI,
                    convertResponseDataEntry,
                    sortKey,
                    sortOrder,
                    Date.now()
                )
            );
        }

        fetchDisplaySettingData(requireRefresh = false) {
            const { token, dispatch } = this.props;
            dispatch(
                fetchDisplaySettingAction(
                    pageId,
                    token,
                    displaySettingURL,
                    resourceName,
                    (data) => data,
                    requireRefresh
                )
            );
        }

        onBulkCopy = () => {
            this.isBulkActionProcessing = true;
            const { token, dispatch } = this.props;
            const { selectedRows } = this.state;
            this.setState({ bulkCopying: true });
            const selectedIds = selectedRows.map((row) => row.id);
            dispatch(
                bulkCopyAction(
                    pageId,
                    token,
                    copyURL,
                    { resource_ids: selectedIds },
                    (data) => data,
                    true,
                    { message: SuccessMessages.generic.copy },
                    { message: ErrorMessages.scheduledEmail.copyFailed }
                )
            );
            this.setState({ bulkCopying: false, selectedRows: [] });
        };

        onClickDeleteSearchTemplate = () => {
            const { token, dispatch, pageState } = this.props;
            let selectedTemplateIndex =
                pageState.currentSearchTemplates.findIndex(
                    (e) => e.name === this.state.selectedTemplateName
                );
            this.onChangeTemplate("");
            if (selectedTemplateIndex >= 0) {
                dispatch(
                    deleteApi(
                        pageId,
                        token,
                        searchTemplateURL,
                        selectedTemplateIndex,
                        {
                            after: SEARCH_TEMPLATE_DELETED,
                            commit: SEARCH_TEMPLATE_COMMITTED,
                        }
                    )
                );
            }
        };

        onClickUpdateSearchTemplate = () => {
            const { token, dispatch, pageState } = this.props;
            let selectedTemplateIndex =
                pageState.currentSearchTemplates.findIndex(
                    (e) => e.name === this.state.selectedTemplateName
                );
            if (selectedTemplateIndex >= 0) {
                dispatch(
                    updateApi(
                        pageId,
                        token,
                        searchTemplateURL,
                        selectedTemplateIndex,
                        {},
                        {
                            after: SEARCH_TEMPLATE_UPDATED,
                            commit: SEARCH_TEMPLATE_COMMITTED,
                        }
                    )
                );
            }
        };

        setTemplateName = (e) => {
            this.setState({ inputTemplateName: e.target.value });
        };

        onTemplateOk = () => {
            const { token, dispatch, pageState, userName } = this.props;
            const { currentSearchConditions: formValues, pageSize } = pageState;
            const { inputTemplateName } = this.state;
            let nameExists = pageState.currentSearchTemplates.some(
                (entry) => entry.name === inputTemplateName
            );

            if (nameExists) {
                customErrorMessage(
                    `「${inputTemplateName}」 と同一名称のテンプレートが既に存在します。別のテンプレート名を入力してください。`
                );
            } else {
                this.setState({ selectedTemplateName: inputTemplateName });
                dispatch(
                    createApi(
                        pageId,
                        token,
                        searchTemplateURL,
                        {
                            templateName: inputTemplateName,
                            formValues: formValues,
                            pageSize: pageSize,
                            sortKey: "",
                            sortOrder: "",
                            selectedColumnKeys: [],
                        },
                        {
                            after: SEARCH_TEMPLATE_CREATED,
                            commit: SEARCH_TEMPLATE_COMMITTED,
                        }
                    )
                );
            }
        };

        onChangeTemplate = (value) => {
            const { dispatch, pageState } = this.props;
            this.setState({ selectedTemplateName: value });
            this.formClass.current.baseform.current.resetFields();
            let searchTemplate =
                pageState.currentSearchTemplates.find(
                    (e) => e.name === value
                ) || {};
            if (searchTemplate && !isEmpty(searchTemplate))
                dispatch(changeTemplate(pageId, searchTemplate));
        };

        onSearchMenu = () => {
            const { dispatch, pageState } = this.props;
            const { searchMenuOpen } = pageState;
            dispatch({
                type: pageId + SEARCH_MENU_OPEN,
                payload: { data: !searchMenuOpen },
            });
        };

        isOverSelectableLimit = () => {
            const { selectedRows } = this.state;
            return TABLE_OPERATION_SELECTABLE_LIMIT < selectedRows.length;
        };

        isSelectableZero = () => {
            const { selectedRows } = this.state;
            return 0 === selectedRows.length;
        };

        render() {
            const { history, pageState, authorizedActions } = this.props;
            const {
                downloadingCsv,
                bulkDeleting,
                changingActiveStatus,
                bulkCopying,
                selectedTemplateName,
                selectedRows,
                isNotificationInactiveModalOpen,
                isNotificationActiveModalOpen,
                bulkNotificationChanging,
            } = this.state;

            const {
                loading,
                errorMessage,
                pageSize,
                totalCount,
                sortKey,
                sortOrder,
                data,
                currentPage,
                currentSearchConditions,
                currentSearchTemplates,
                displaySetting,
                searchMenuOpen,
                isDefaultTemplateAttached,
            } = pageState;

            const initialLoadFailed = errorMessage && data.length === 0;

            let rightTopButtons = [];
            if (csvDownloadURL) {
                rightTopButtons = [
                    ...rightTopButtons,
                    <div className={styles.downloadButtonWrapper}>
                        <Button
                            type="primary"
                            icon={<DownloadOutlined />}
                            size="small"
                            loading={downloadingCsv}
                            onClick={this.onCsvDownload}
                            disabled={!csvAuthorized(authorizedActions)}
                        >
                            CSV 出力
                        </Button>
                    </div>,
                ];
            }

            let leftBottomButtons = [];

            if (changeActiveStatus) {
                if (this.isOverSelectableLimit()) {
                    leftBottomButtons = [
                        <Tooltip
                            title={TooltipMessages.table.overSelectableLimit}
                        >
                            <Button
                                className={styles.tableControlButton}
                                type="primary"
                                icon={<UserOutlined />}
                                size="small"
                                disabled={true}
                            />
                        </Tooltip>,
                        <Tooltip
                            title={TooltipMessages.table.overSelectableLimit}
                        >
                            <Button
                                className={styles.tableControlButton}
                                type="primary"
                                icon={<UserSlashIcon disabled={true} />}
                                size="small"
                                disabled={true}
                            />
                        </Tooltip>,
                        ...leftBottomButtons,
                    ];
                } else if (this.isSelectableZero()) {
                    leftBottomButtons = [
                        <Tooltip title={"1件以上の選択が必要です。"}>
                            <Button
                                className={styles.tableControlButton}
                                type="primary"
                                icon={<UserOutlined />}
                                size="small"
                                disabled={true}
                            />
                        </Tooltip>,
                        <Tooltip title={"1件以上の選択が必要です。"}>
                            <Button
                                className={styles.tableControlButton}
                                type="primary"
                                icon={<UserSlashIcon disabled={true} />}
                                size="small"
                                disabled={true}
                            />
                        </Tooltip>,
                        ...leftBottomButtons,
                    ];
                } else {
                    leftBottomButtons = [
                        <>
                            <Tooltip
                                title={
                                    changeActiveStatusAuthorized(
                                        authorizedActions
                                    )
                                        ? "選択行を有効"
                                        : "特定の権限で操作できます"
                                }
                            >
                                <Button
                                    className={styles.tableControlButton}
                                    type="primary"
                                    icon={<UserOutlined />}
                                    size="small"
                                    loading={changingActiveStatus}
                                    onClick={() =>
                                        this.setState({
                                            isUserActiveModalOpen: true,
                                        })
                                    }
                                    disabled={
                                        !changeActiveStatusAuthorized(
                                            authorizedActions
                                        )
                                    }
                                />
                            </Tooltip>
                            <GenericModal
                                visible={this.state.isUserActiveModalOpen}
                                title="このユーザーを有効化しますか？"
                                type="warning"
                                onOk={() => {
                                    this.onChangeActiveStatus(true);
                                    this.setState({
                                        isUserActiveModalOpen: false,
                                    });
                                }}
                                onCancel={() =>
                                    this.setState({
                                        isUserActiveModalOpen: false,
                                    })
                                }
                            >
                                <GenericModalContent>
                                    OKを押すと、有効化が実行されます。
                                    <br />
                                    <br />
                                    <span>
                                        ※ユーザー上限を超えていると実行されません。
                                    </span>
                                </GenericModalContent>
                            </GenericModal>
                        </>,
                        <>
                            <Tooltip
                                title={
                                    changeActiveStatusAuthorized(
                                        authorizedActions
                                    )
                                        ? "選択行を無効"
                                        : "特定の権限で操作できます"
                                }
                            >
                                <Button
                                    className={styles.tableControlButton}
                                    type="primary"
                                    icon={<UserSlashIcon disabled={false} />}
                                    size="small"
                                    loading={changingActiveStatus}
                                    onClick={() =>
                                        this.setState({
                                            isUserInactiveModalOpen: true,
                                        })
                                    }
                                    disabled={
                                        !changeActiveStatusAuthorized(
                                            authorizedActions
                                        )
                                    }
                                />
                            </Tooltip>
                            <GenericModal
                                visible={this.state.isUserInactiveModalOpen}
                                title="このユーザーを無効化しますか？"
                                type="warning"
                                onOk={() => {
                                    this.onChangeActiveStatus(false);
                                    this.setState({
                                        isUserInactiveModalOpen: false,
                                    });
                                }}
                                onCancel={() =>
                                    this.setState({
                                        isUserInactiveModalOpen: false,
                                    })
                                }
                            >
                                <GenericModalContent>
                                    OKを押すと、無効化が実行されます。
                                    <br />
                                    <br />
                                    <span>
                                        ※配信ステータスが「下書き」「配信待ち」「配信中」の配信メールの配信者に設定されている場合実行されません。
                                    </span>
                                    <br />
                                    <span>
                                        ※自動マッチング条件の通知先に設定されている場合実行されません。
                                    </span>
                                    <br />
                                    <span>
                                        ※ユーザー無効化後は個人保有データが削除されます。
                                    </span>
                                </GenericModalContent>
                            </GenericModal>
                        </>,
                        ...leftBottomButtons,
                    ];
                }
            }

            if (pageId === USER_SEARCH_PAGE){
                leftBottomButtons = [
                    <SwapDisabledButton
                        selectedRows={selectedRows}
                        onClickEnabled={()=>{this.onChangeUserDisabled(true);}}
                        onClickDisabled={()=>{this.onChangeUserDisabled(false);}}
                    />,
                    ...leftBottomButtons
                ]
            } else if (pageId === ORGANIZATION_SEARCH_PAGE) {
                leftBottomButtons = [
                    <SwapBlockButton
                        selectedRows={selectedRows}
                        onClickEnabled={()=>{this.onChangeOrganizationBlock(true);}}
                        onClickDisabled={()=>{this.onChangeOrganizationBlock(false);}}
                    />,
                    ...leftBottomButtons
                ]
            } else if (pageId === CONTACT_SEARCH_PAGE) {
                leftBottomButtons = [
                    <SwapArchiveButton
                        selectedRows={selectedRows}
                        onClickEnabled={()=>{this.onChangeContactArchive(true);}}
                        onClickDisabled={()=>{this.onChangeContactArchive(false);}}
                    />,
                    ...leftBottomButtons
                ]
            } else if (pageId === SHARED_EMAIL_NOTIFICATION_SEARCH_PAGE) {
                leftBottomButtons = [
                    <SwapDisabledButton
                        selectedRows={selectedRows}
                        onClickEnabled={()=>{this.onChangeSharedMailsNotificationDisabled(true);}}
                        onClickDisabled={()=>{this.onChangeSharedMailsNotificationDisabled(false);}}
                    />,
                    ...leftBottomButtons
                ]
            }

            if (bulkDelete) {
                if (this.isOverSelectableLimit()) {
                    leftBottomButtons = [
                        <Tooltip
                            title={TooltipMessages.table.overSelectableLimit}
                        >
                            <Button
                                className={styles.tableControlButton}
                                type="primary"
                                danger
                                icon={<DeleteOutlined />}
                                size="small"
                                disabled={true}
                            />
                        </Tooltip>,
                        ...leftBottomButtons,
                    ];
                } else if (this.isSelectableZero()) {
                    leftBottomButtons = [
                        <Tooltip title={"1件以上の選択が必要です。"}>
                            <Button
                                className={styles.tableControlButton}
                                type="primary"
                                danger
                                icon={<DeleteOutlined />}
                                size="small"
                                disabled={true}
                            />
                        </Tooltip>,
                        ...leftBottomButtons,
                    ];
                } else {
                    leftBottomButtons = [
                        <Tooltip
                            title={
                                deleteAuthorized(authorizedActions)
                                    ? "選択行を削除"
                                    : "特定の権限で操作できます"
                            }
                        >
                            <Button
                                className={styles.tableControlButton}
                                type="primary"
                                danger
                                icon={<DeleteOutlined />}
                                size="small"
                                loading={bulkDeleting}
                                onClick={showDeleteModal(
                                    this.onBulkDelete,
                                    reducerName
                                )}
                                disabled={!deleteAuthorized(authorizedActions)}
                            />
                        </Tooltip>,
                        ...leftBottomButtons,
                    ];
                }
            }

            if (copyURL) {
                if (this.isOverSelectableLimit()) {
                    leftBottomButtons = [
                        ...leftBottomButtons,
                        <div className={styles.tableControlButton}>
                            <Tooltip
                                title={
                                    TooltipMessages.table.overSelectableLimit
                                }
                            >
                                <Button
                                    type="primary"
                                    icon={<CopyOutlined />}
                                    size="small"
                                    disabled={true}
                                />
                            </Tooltip>
                        </div>,
                    ];
                } else if (this.isSelectableZero()) {
                    leftBottomButtons = [
                        ...leftBottomButtons,
                        <div className={styles.tableControlButton}>
                            <Tooltip title={"1件以上の選択が必要です。"}>
                                <Button
                                    type="primary"
                                    icon={<CopyOutlined />}
                                    size="small"
                                    disabled={true}
                                />
                            </Tooltip>
                        </div>,
                    ];
                } else {
                    let copyAuthorized =
                        authorizedActions &&
                        authorizedActions["scheduled_mails"] &&
                        authorizedActions["scheduled_mails"]["copy"];
                    leftBottomButtons = [
                        ...leftBottomButtons,
                        <div className={styles.tableControlButton}>
                            <Tooltip
                                title={
                                    copyAuthorized
                                        ? "選択行をコピー"
                                        : "特定の権限で操作できます"
                                }
                            >
                                <Button
                                    type="primary"
                                    icon={<CopyOutlined />}
                                    size="small"
                                    loading={bulkCopying}
                                    onClick={this.onBulkCopy}
                                    disabled={!copyAuthorized}
                                />
                            </Tooltip>
                        </div>,
                    ];
                }
            }

            if (bulkEdit) {
                leftBottomButtons = [
                    <TableBulkButton
                        type="primary"
                        icon={<EditOutlined />}
                        tooltip={TooltipMessages.table.editSelected}
                        selectedRows={selectedRows}
                        isAuthorized={editAuthorized(authorizedActions)}
                        isPlanUpgradeRequired={pageId === USER_SEARCH_PAGE}
                        selectionLimit={bulkEditSelectionLimit}
                        onClick={this.onBulkEdit}
                    />,
                    ...leftBottomButtons,
                ];
            }

            const filterType = "table";

            if (tableControlButtons) {
                leftBottomButtons = [
                    ...leftBottomButtons,
                    ...tableControlButtons.map((TableControlButton, index) => (
                        <TableControlButton key={index} />
                    )),
                ];
            }

            const contentBody = (
                <TableClass
                    tableName={resourceName}
                    data={data}
                    currentPage={currentPage}
                    pageSize={pageSize}
                    totalCount={totalCount}
                    sortKey={sortKey}
                    sortOrder={sortOrder}
                    onTableChange={this.onTableChange}
                    onPageChange={this.onPageChange}
                    onPageSizeChange={this.onPageSizeChange}
                    onCheckColumn={this.onCheckColumn}
                    selectedColumnKeys={
                        displaySetting &&
                        displaySetting[resourceName] &&
                        displaySetting[resourceName][filterType]
                            ? displaySetting[resourceName][filterType]
                            : []
                    }
                    rowSelection={{
                        selectedRowKeys: selectedRows.map((row) => row.id),
                    }}
                    loading={loading}
                    authorizedActions={authorizedActions}
                    pageId={pageId}
                    rightTopButtons={rightTopButtons}
                    leftBottomButtons={leftBottomButtons}
                    onRefetch={(selectedPageSize) => {
                        // currentPage must be 1 because selectedPageSize changed.
                        const { currentSearchConditions } = this.props.pageState;
                        const formValues = {
                            ...currentSearchConditions,
                            page: 1,
                        };
                        this.syncToUrl(formValues);
                        this.fetchData(
                            formValues.page,
                            selectedPageSize,
                            formValues,
                            sortKey,
                            sortOrder
                        );
                    }}
                    onTableActionMustBeCalled={() => {
                        this.isBulkActionProcessing = true;
                    }}
                />
            );

            const columnLayout = {
                sm: { span: 24 },
                md: { span: 12 },
            };

            let queryString = paramsToQueryString(
                convertFormDataToAPI(currentSearchConditions)
            );

            if (sortKey && sortOrder) {
                if (sortOrder == "descend") {
                    queryString = queryString + "&ordering=-" + sortKey;
                } else {
                    queryString = queryString + "&ordering=" + sortKey;
                }
            }

            if (!accessAuthorized(authorizedActions)) {
                return (
                    <div className={styles.container}>
                        <NotFoundPage {...this.props} />
                    </div>
                );
            }

            return (
                <div className={styles.container}>
                    {searchTemplateURL ? (
                        <Row>
                            <Col {...columnLayout}>
                                <Form.Item className={styles.search_template}>
                                    <Select
                                        placeholder="検索条件テンプレートを選択"
                                        style={
                                            selectedTemplateName
                                                ? { width: 300 }
                                                : {
                                                      width: 300,
                                                      color: "#bfbfbf",
                                                  }
                                        }
                                        onChange={this.onChangeTemplate}
                                        value={
                                            selectedTemplateName ||
                                            "検索条件テンプレートを選択"
                                        }
                                        allowClear={selectedTemplateName}
                                    >
                                        {(currentSearchTemplates || []).map(
                                            (entry, index) => {
                                                return (
                                                    <Select.Option
                                                        key={index}
                                                        value={entry.name}
                                                    >
                                                        {entry.display_name}
                                                    </Select.Option>
                                                );
                                            }
                                        )}
                                    </Select>
                                    {searchTemplateAuthorized(
                                        authorizedActions
                                    ) ? (
                                        <span>
                                            <Tooltip
                                                title={
                                                    "選択中の検索条件テンプレートを削除します。"
                                                }
                                            >
                                                <Button
                                                    style={{ margin: 3 }}
                                                    type="primary"
                                                    danger
                                                    icon={<DeleteOutlined />}
                                                    onClick={
                                                        this
                                                            .onClickDeleteSearchTemplate
                                                    }
                                                    disabled={
                                                        !selectedTemplateName
                                                    }
                                                />
                                            </Tooltip>
                                            <Tooltip
                                                title={
                                                    "選択中の検索条件テンプレートをデフォルトに設定／解除します。"
                                                }
                                            >
                                                <Button
                                                    style={{ margin: 3 }}
                                                    type="primary"
                                                    icon={<StarOutlined />}
                                                    onClick={
                                                        this
                                                            .onClickUpdateSearchTemplate
                                                    }
                                                    disabled={
                                                        !selectedTemplateName
                                                    }
                                                />
                                            </Tooltip>
                                            <Tooltip
                                                title={
                                                    "現在の検索条件を保存します。"
                                                }
                                            >
                                                <Button
                                                    style={{ margin: 3 }}
                                                    type="primary"
                                                    icon={<SaveOutlined />}
                                                    onClick={showSaveTemplateModal(
                                                        this.setTemplateName,
                                                        this.onTemplateOk,
                                                        () => {}
                                                    )}
                                                />
                                            </Tooltip>
                                            <Tooltip
                                                title={
                                                    <span>
                                                        テンプレートはユーザー個人ごとに保存され、他のユーザーとは共有されません。
                                                    </span>
                                                }
                                            >
                                                <QuestionCircleFilled
                                                    style={{
                                                        color: iconCustomColor,
                                                    }}
                                                />
                                            </Tooltip>
                                        </span>
                                    ) : (
                                        <span>
                                            <Tooltip
                                                title={
                                                    "特定の権限で操作できます"
                                                }
                                            >
                                                <Button
                                                    style={{ margin: 3 }}
                                                    type="primary"
                                                    icon={<DeleteOutlined />}
                                                    disabled={true}
                                                />
                                            </Tooltip>
                                            <Tooltip
                                                title={
                                                    "特定の権限で操作できます"
                                                }
                                            >
                                                <Button
                                                    style={{ margin: 3 }}
                                                    type="primary"
                                                    icon={<StarOutlined />}
                                                    disabled={true}
                                                />
                                            </Tooltip>
                                            <Tooltip
                                                title={
                                                    "特定の権限で操作できます"
                                                }
                                            >
                                                <Button
                                                    style={{ margin: 3 }}
                                                    type="primary"
                                                    icon={<SaveOutlined />}
                                                    disabled={true}
                                                />
                                            </Tooltip>
                                        </span>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col {...columnLayout}></Col>
                        </Row>
                    ) : (
                        <div />
                    )}
                    {pageId === SHARED_EMAIL_NOTIFICATION_SEARCH_PAGE && (
                        <Row>
                            <Col span={12}>
                                <PageHeader
                                    className={styles.pageHeader}
                                    title={pageTitle}
                                    style={{ paddingRight: 0 }}
                                    extra={
                                        pageHeaderExtra
                                            ? pageHeaderExtra.map(
                                                  (ExtraButton, index) => (
                                                      <ExtraButton
                                                          key={index}
                                                          authorizedActions={
                                                              authorizedActions
                                                          }
                                                      />
                                                  )
                                              )
                                            : []
                                    }
                                />
                            </Col>
                            <Col span={12}>
                                <Row
                                    style={{
                                        height: "100%",
                                        alignItems: "center",
                                        paddingTop: 16,
                                    }}
                                >
                                    <Col span={12}>
                                        <Row justify="start">
                                            {leftExtraButtons
                                                ? leftExtraButtons.map(
                                                      (ExtraButton, index) => {
                                                          return (
                                                              <Col key={index}>
                                                                  <ExtraButton
                                                                      authorizedActions={
                                                                          authorizedActions
                                                                      }
                                                                  />
                                                              </Col>
                                                          );
                                                      }
                                                  )
                                                : undefined}
                                        </Row>
                                    </Col>
                                    <Col span={12}>
                                        <Row justify="end" gutter={12}>
                                            <Col>
                                                {extraButtons
                                                    ? extraButtons.map(
                                                          (
                                                              ExtraButton,
                                                              index
                                                          ) => {
                                                              return (
                                                                  <Col
                                                                      key={
                                                                          index
                                                                      }
                                                                  >
                                                                      <ExtraButton
                                                                          authorizedActions={
                                                                              authorizedActions
                                                                          }
                                                                          queryString={
                                                                              queryString
                                                                          }
                                                                      />
                                                                  </Col>
                                                              );
                                                          }
                                                      )
                                                    : undefined}
                                            </Col>
                                            {FormClass && (
                                                !isNewSearchDrawerPage(pageId) ? (
                                                    <FormClass
                                                        tableName={resourceName}
                                                        ref={this.formClass}
                                                        initialData={
                                                            currentSearchConditions
                                                        }
                                                        submitHandler={
                                                            this.onSearch
                                                        }
                                                        resetFormHandler={
                                                            this
                                                                .resetFormHandler
                                                        }
                                                        selectedSearchItemKeys={
                                                            displaySetting &&
                                                            displaySetting[
                                                                resourceName
                                                            ] &&
                                                            displaySetting[
                                                                resourceName
                                                            ]["search"]
                                                                ? displaySetting[
                                                                    resourceName
                                                                ]["search"]
                                                                : []
                                                        }
                                                        onSearchMenu={
                                                            this.onSearchMenu
                                                        }
                                                        searchMenuOpen={
                                                            searchMenuOpen
                                                        }
                                                        isDefaultTemplateAttached={
                                                            isDefaultTemplateAttached
                                                        }
                                                        searchConditionSanitizer={
                                                            searchConditionSanitizer
                                                        }
                                                    />
                                                ) : (
                                                    <FormClass
                                                        onFilter={this.onSearch}
                                                        onFilterReset={
                                                            this
                                                                .resetFormHandler
                                                        }
                                                    />
                                                )
                                            )}
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    )}
                    {pageId !== SHARED_EMAIL_NOTIFICATION_SEARCH_PAGE && (
                        <Row>
                            <Col span={12}>
                                <PageHeader
                                    className={styles.pageHeader}
                                    title={pageTitle}
                                    style={{ paddingRight: 0 }}
                                    extra={
                                        pageHeaderExtra &&
                                        pageId !== SHARED_EMAIL_PAGE
                                            ? pageHeaderExtra.map(
                                                  (ExtraButton, index) => (
                                                      <ExtraButton
                                                          key={index}
                                                          authorizedActions={
                                                              authorizedActions
                                                          }
                                                      />
                                                  )
                                              )
                                            : []
                                    }
                                />
                            </Col>
                            <Col span={12}>
                                <Row
                                    style={{
                                        height: "100%",
                                        alignItems: "center",
                                        paddingTop: 16,
                                    }}
                                >
                                    <Col span={12}>
                                        <Row justify="start">
                                            {leftExtraButtons
                                                ? leftExtraButtons.map(
                                                      (ExtraButton, index) => {
                                                          return (
                                                              <Col key={index}>
                                                                  <ExtraButton
                                                                      authorizedActions={
                                                                          authorizedActions
                                                                      }
                                                                  />
                                                              </Col>
                                                          );
                                                      }
                                                  )
                                                : undefined}
                                        </Row>
                                    </Col>
                                    <Col span={12}>
                                        <Row justify="end" gutter={12}>
                                            <Col>
                                                {extraButtons
                                                    ? extraButtons.map(
                                                          (
                                                              ExtraButton,
                                                              index
                                                          ) => {
                                                              return (
                                                                  <Col
                                                                      key={
                                                                          index
                                                                      }
                                                                  >
                                                                      <ExtraButton
                                                                          authorizedActions={
                                                                              authorizedActions
                                                                          }
                                                                          queryString={
                                                                              queryString
                                                                          }
                                                                      />
                                                                  </Col>
                                                              );
                                                          }
                                                      )
                                                    : undefined}
                                            </Col>
                                            {FormClass && (
                                                !isNewSearchDrawerPage(pageId) ? (
                                                    <FormClass
                                                        tableName={resourceName}
                                                        ref={this.formClass}
                                                        initialData={
                                                            currentSearchConditions
                                                        }
                                                        submitHandler={
                                                            this.onSearch
                                                        }
                                                        resetFormHandler={
                                                            this
                                                                .resetFormHandler
                                                        }
                                                        selectedSearchItemKeys={
                                                            displaySetting &&
                                                            displaySetting[
                                                                resourceName
                                                            ] &&
                                                            displaySetting[
                                                                resourceName
                                                            ]["search"]
                                                                ? displaySetting[
                                                                    resourceName
                                                                ]["search"]
                                                                : []
                                                        }
                                                        onSearchMenu={
                                                            this.onSearchMenu
                                                        }
                                                        searchMenuOpen={
                                                            searchMenuOpen
                                                        }
                                                        isDefaultTemplateAttached={
                                                            isDefaultTemplateAttached
                                                        }
                                                        searchConditionSanitizer={
                                                            searchConditionSanitizer
                                                        }
                                                    />
                                                ) : (
                                                    <FormClass
                                                        onFilter={this.onSearch}
                                                        onFilterReset={
                                                            this
                                                                .resetFormHandler
                                                        }
                                                    />
                                                )
                                            )}
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    )}
                    {hide_devider ? <div /> : <div />}
                    {linkToRegisterPage &&
                    linkToRegisterPage !== Paths.tagRegister ? (
                        <div className={styles.registerPageButtonWrapper}>
                            <Button
                                type="link"
                                onClick={() => history.push(linkToRegisterPage)}
                            >
                                ＋ 追加する
                            </Button>
                        </div>
                    ) : (
                        <div />
                    )}
                    {initialLoadFailed ? (
                        <ErrorScreen message={errorMessage} />
                    ) : (
                        contentBody
                    )}
                    <BackTop
                        visibilityHeight={600}
                        hidden={!isMobileDevices()}
                    />
                </div>
            );
        }
    };

    Page.propTypes = {
        userName: PropTypes.string.isRequired,
        history: PropTypes.shape({
            goBack: PropTypes.func.isRequired,
            push: PropTypes.func.isRequired,
        }).isRequired,
        dispatch: PropTypes.func.isRequired,
        token: PropTypes.string.isRequired,
        role: PropTypes.string.isRequired,
        authorizedActions: PropTypes.object.isRequired,
        currentUserId: PropTypes.string,
        // An initial state.
        pageState: PropTypes.shape({
            loading: PropTypes.bool.isRequired,
            requireRefresh: PropTypes.bool.isRequired,
            requireResetSelectedRows: PropTypes.bool,
            message: PropTypes.string.isRequired,
            errorMessage: PropTypes.string.isRequired,
            pageSize: PropTypes.number.isRequired,
            totalCount: PropTypes.number.isRequired,
            data: PropTypes.arrayOf(PropTypes.object).isRequired, // Just passing to a child component.
            currentPage: PropTypes.number.isRequired,
            currentSearchConditions: PropTypes.object.isRequired, // Just passing to a child component.
            currentSearchTemplates: PropTypes.arrayOf(PropTypes.object),
            sortKey: PropTypes.string,
            sortOrder: PropTypes.string,
            selectedColumnKeys: PropTypes.arrayOf(PropTypes.string),
            requireRefreshTemplate: PropTypes.bool,
            requireRefreshSelectedColumnView: PropTypes.bool,
            displaySetting: PropTypes.object,
            displaySettingLoaded: PropTypes.bool,
            searchMenuOpen: PropTypes.bool.isRequired,
            isDefaultTemplateAttached: PropTypes.bool.isRequired,
            errorExtraInfo: PropTypes.shape({
                message: PropTypes.string,
                redirect: PropTypes.string,
            }),
        }).isRequired,
        extraButtons: PropTypes.array,
        invalidCacheKey: PropTypes.string,
    };

    Page.displayName = pageId;

    // Link Reducer state to Component props.
    function mapStateToProps(state) {
        return {
            token: state.login.token,
            role: state.login.role,
            authorizedActions: state.login.authorizedActions,
            userName: state.login.displayName,
            currentUserId: state.login.userId,
            pageState: state[reducerName],
        };
    }

    return connect(mapStateToProps)(Page);
};

export default createSearchPage;
