// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SearchDrawer-section-PuZpN{margin-bottom:2rem}.SearchDrawer-clearButton-ou90z{margin-right:28px;margin-bottom:8px}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Common/SearchDrawer/SearchDrawer.scss"],"names":[],"mappings":"AAAA,4BACI,kBAAmB,CACtB,gCAGG,iBAAkB,CAClB,iBAAkB","sourcesContent":[".section {\n    margin-bottom: 2rem;\n}\n\n.clearButton{\n    margin-right: 28px;\n    margin-bottom: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `SearchDrawer-section-PuZpN`,
	"clearButton": `SearchDrawer-clearButton-ou90z`
};
export default ___CSS_LOADER_EXPORT___;
