// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProjectBoardTabPaneContent-controlButtonsWrapper-bY06a{margin-top:2rem}.ProjectBoardTabPaneContent-section-mrIHY{margin-top:3rem}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/project/ProjectBoardPage/ProjectBoardDetailModal/ProjectBoardTabPaneContent/ProjectBoardTabPaneContent.scss"],"names":[],"mappings":"AAAA,wDACI,eAAgB,CACnB,0CAGG,eAAgB","sourcesContent":[".controlButtonsWrapper {\n    margin-top: 2rem;\n}\n\n.section {\n    margin-top: 3rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"controlButtonsWrapper": `ProjectBoardTabPaneContent-controlButtonsWrapper-bY06a`,
	"section": `ProjectBoardTabPaneContent-section-mrIHY`
};
export default ___CSS_LOADER_EXPORT___;
