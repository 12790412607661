// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProjectBoardBaseInfoForm-section-ngnpj{margin-top:3rem}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/project/ProjectBoardPage/ProjectBoardDetailModal/ProjectBoardBaseInfoForm/ProjectBoardBaseInfoForm.scss"],"names":[],"mappings":"AAAA,wCACI,eAAgB","sourcesContent":[".section {\n    margin-top: 3rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `ProjectBoardBaseInfoForm-section-ngnpj`
};
export default ___CSS_LOADER_EXPORT___;
