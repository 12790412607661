"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var api_1 = require("~/domain/api");
var mentions = function (client) {
    return {
        fetchMentionsList: function () {
            return client.get(api_1.Endpoint.actionMention);
        },
    };
};
exports.default = mentions;
