import React from "react";
import { Col, Form, Select, Row } from "antd";
import styles from "./CorporateContractFormItem.scss";
import {
    ToolTwoTone,
} from "@ant-design/icons";
import { iconPrimaryColor } from "~/utils/constants";

const CorporateContractFormItem = ({ disabled = false }) => {
    const items = [
        {
            value: true,
            title: "請負あり",
            icon: <ToolTwoTone twoToneColor={iconPrimaryColor} />,
        },
        {
            value: false,
            title: "請負なし",
        },
    ];

    return (
        <Col span={24} style={{ marginBottom: "1%" }}>
            <Row className={styles.container}>
                <Col span={23}>
                    <Form.Item colon={false} name="contract" noStyle>
                        <Select
                            showSearch={false}
                            className={styles.container}
                            placeholder="請負"
                            allowClear
                            disabled={disabled}
                            getPopupContainer={(trigger) => trigger.parentNode}>
                            {items.map((item) => {
                                return (
                                    <Select.Option
                                        key={item.value}
                                        className={styles.select_option}
                                        value={item.value}>
                                        {item.icon} {item.title}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        </Col>
    );
};

export default CorporateContractFormItem;
