import React from "react";
import PropTypes from "prop-types";
import { Space, Skeleton } from "antd";
import Path from "~/components/Routes/Paths";
import GenericTable from "~/components/Tables/GenericTable";
import { useSelector } from "react-redux";
import { useRouter } from "~/hooks/router";
import { useContactsTableColumns } from "~/hooks/contacts";
import styles from "./ContactsTable.scss";

const onMinify = {
    renderTitle: (item) => item.display_name,
    renderDescription: (item) => (
        <div>
            <p>{`メールアドレス: ${item.email ? item.email : "未登録"}`}</p>
            <p>{`所属取引先: ${
                item.organization__name ? item.organization__name : "未登録"
            }`}</p>
            <p>{`自社担当者: ${
                item.staff__name ? `${item.staff__name}` : "未登録"
            }`}</p>
        </div>
    ),
};

const contactTable = (props) => {
    const { loading } = props;
    const router = useRouter();
    const data = JSON.parse(JSON.stringify(props.data));
    const isDisplaySettingLoading = useSelector(
        (state) => state.displaySettingPage.isDisplaySettingLoading
    );
    const { displaySetting } = useSelector((state) => state.displaySettingPage);

    const {columns} = useContactsTableColumns();

    const onRowClick = () => {
    };

    const onColumnClick = (record) => {
        const url = `${Path.contacts}/${record.id}`;
        router.push(url);
    };

    data.forEach((e) => (e.cc_mails = e.cc_mails.join("\n")));

    return (
        <>
            {isDisplaySettingLoading ? (
                <Space className={styles.container}>
                    <Skeleton.Input
                        style={{ height: 200, width: 1200 }}
                        active
                        size="large"
                    />
                </Space>
            ) : (
                <GenericTable
                    {...props}
                    data={data}
                    columns={columns}
                    onMinify={onMinify}
                    onRowClick={onRowClick}
                    onColumnClick={onColumnClick}
                    loading={loading}
                    withSelection={true}
                    pageSize={displaySetting?.contacts?.page_size ?? 10}
                />
            )}
        </>
    );
};

contactTable.propTypes = {
    currentPage: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired,
    sortKey: PropTypes.string,
    sortOrder: PropTypes.string,
    onPageChange: PropTypes.func.isRequired,
    onPageSizeChange: PropTypes.func.isRequired,
    onTableChange: PropTypes.func.isRequired,
    onCheckColumn: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    data: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            display_name: PropTypes.string.isRequired,
            email: PropTypes.string.isRequired,
            cc_mails: PropTypes.arrayOf(PropTypes.string),
            tel: PropTypes.string,
            position: PropTypes.string,
            department: PropTypes.string,
            organization__name: PropTypes.string,
            staff__name: PropTypes.string,
            last_visit: PropTypes.string,
            score: PropTypes.number,
            created_time: PropTypes.string,
            modified_time: PropTypes.string,
            comments: PropTypes.arrayOf(
                PropTypes.shape({
                    created_user__name: PropTypes.string,
                    created_user__avatar: PropTypes.string,
                    created_time: PropTypes.string,
                    content: PropTypes.string,
                })
            ),
        })
    ),
};

contactTable.defaultProps = {
    loading: false,
    data: [],
    sortKey: undefined,
    sortOrder: undefined,
};

export default contactTable;
