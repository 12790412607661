// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ScheduledEmailSentDateFormItem-container-QKxLa{width:100%;text-align:left}.ScheduledEmailSentDateFormItem-userInput-Uoqe3{width:100%}.ScheduledEmailSentDateFormItem-tooltip-S24pw{margin-left:5px}.ScheduledEmailSentDateFormItem-infoIcon-FKMnE{align-self:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/ScheduledEmailSearchPage/ScheduledEmailSearchDrawer/ScheduledEmailSentDateFormItem/ScheduledEmailSentDateFormItem.scss"],"names":[],"mappings":"AAAA,gDACI,UAAW,CACX,eAAgB,CACnB,gDAGG,UAAW,CACd,8CAGG,eAAgB,CACnB,+CAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ScheduledEmailSentDateFormItem-container-QKxLa`,
	"userInput": `ScheduledEmailSentDateFormItem-userInput-Uoqe3`,
	"tooltip": `ScheduledEmailSentDateFormItem-tooltip-S24pw`,
	"infoIcon": `ScheduledEmailSentDateFormItem-infoIcon-FKMnE`
};
export default ___CSS_LOADER_EXPORT___;
