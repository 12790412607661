// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BoardChecklistItem-form-JqmS5{width:100%}.BoardChecklistItem-input-eThT7{padding:0}.BoardChecklistItem-inputFormItem-aTPci{padding:0 0.7em;margin:0}.BoardChecklistItem-checkboxFormItem-HOnna{padding:0;margin:0}.BoardChecklistItem-deleteBtn-FVInw{padding:0;margin:0}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Common/Board/BoardChecklist/BoardChecklistItem/BoardChecklistItem.scss"],"names":[],"mappings":"AAAA,+BACI,UAAW,CACd,gCAGG,SAAU,CACb,wCAGG,eAAgB,CAChB,QAAS,CACZ,2CAGG,SAAU,CACV,QAAS,CACZ,oCAGG,SAAU,CACV,QAAS","sourcesContent":[".form {\n    width: 100%;\n}\n\n.input {\n    padding: 0;\n}\n\n.inputFormItem {\n    padding: 0 0.7em;\n    margin: 0;\n}\n\n.checkboxFormItem {\n    padding: 0;\n    margin: 0;\n}\n\n.deleteBtn {\n    padding: 0;\n    margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `BoardChecklistItem-form-JqmS5`,
	"input": `BoardChecklistItem-input-eThT7`,
	"inputFormItem": `BoardChecklistItem-inputFormItem-aTPci`,
	"checkboxFormItem": `BoardChecklistItem-checkboxFormItem-HOnna`,
	"deleteBtn": `BoardChecklistItem-deleteBtn-FVInw`
};
export default ___CSS_LOADER_EXPORT___;
