// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MyProfileForm-container-gT04d{margin:16px auto;text-align:left}.MyProfileForm-field-z9yfA{max-width:800px;margin:8px 0}.MyProfileForm-button-g2ZRR{width:30%;margin:auto}.MyProfileForm-alert-nJQlr{margin-top:16px;margin-bottom:16px}.MyProfileForm-phonenumberDash-cKKHz{display:inline-block;width:5%;text-align:center}.MyProfileForm-nameSeparator-_MNL8{display:inline-block;width:4%}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Forms/MyProfileForm/MyProfileForm.scss"],"names":[],"mappings":"AAAA,+BACI,gBAAiB,CACjB,eAAgB,CACnB,2BAGG,eAAgB,CAChB,YAAa,CAChB,4BAGG,SAAU,CACV,WAAY,CACf,2BAGG,eAAgB,CAChB,kBAAmB,CACtB,qCAGG,oBAAqB,CACrB,QAAS,CACT,iBAAkB,CACrB,mCAGG,oBAAqB,CACrB,QAAS","sourcesContent":[".container {\n    margin: 16px auto;\n    text-align: left;\n}\n\n.field {\n    max-width: 800px;\n    margin: 8px 0;\n}\n\n.button {\n    width: 30%;\n    margin: auto;\n}\n\n.alert {\n    margin-top: 16px;\n    margin-bottom: 16px;\n}\n\n.phonenumberDash {\n    display: inline-block;\n    width: 5%;\n    text-align: center;\n}\n\n.nameSeparator {\n    display: inline-block;\n    width: 4%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `MyProfileForm-container-gT04d`,
	"field": `MyProfileForm-field-z9yfA`,
	"button": `MyProfileForm-button-g2ZRR`,
	"alert": `MyProfileForm-alert-nJQlr`,
	"phonenumberDash": `MyProfileForm-phonenumberDash-cKKHz`,
	"nameSeparator": `MyProfileForm-nameSeparator-_MNL8`
};
export default ___CSS_LOADER_EXPORT___;
