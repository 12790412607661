"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBackOfficeBoardDetailModal = void 0;
var antd_1 = require("antd");
var cards_1 = require("./cards");
var react_1 = require("react");
var utils_1 = require("./utils");
var router_1 = require("~/hooks/router");
var useBackOfficeBoardDetailModal = function () {
    var id = (0, router_1.useIdParams)().id;
    var _a = (0, react_1.useState)(false), isCopyPopoverVisible = _a[0], setIsCopyPopoverVisible = _a[1];
    var modalCancelForm = antd_1.Form.useForm()[0];
    var getTextInfo = (0, utils_1.useBackOfficeBoardUtils)().getTextInfo;
    var isUpdating = (0, cards_1.useBackOfficeBoardUpdateCardAPIMutation)().isLoading;
    var _b = (0, cards_1.useBackOfficeBoardFetchCardAPIQuery)({
        deps: {
            cardId: id,
        },
    }), data = _b.data, isFetchingLoading = _b.isLoading, isError = _b.isError;
    // if (data) {
    //     data['dynamicRows'] = undefined;
    // }
    var isLoading = isFetchingLoading ||
        isUpdating;
    return {
        data: data,
        isLoading: isLoading,
        isError: isError,
        getTextInfo: getTextInfo,
        isCopyPopoverVisible: isCopyPopoverVisible,
        setIsCopyPopoverVisible: setIsCopyPopoverVisible,
        modalCancelForm: modalCancelForm,
    };
};
exports.useBackOfficeBoardDetailModal = useBackOfficeBoardDetailModal;
