// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AboutThisData-container-yxkB9{text-align:left;font-size:small}.AboutThisData-pStyle-z99WE{margin:0;font-size:12px}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Common/AboutThisData/AboutThisData.scss"],"names":[],"mappings":"AAAA,+BACI,eAAgB,CAChB,eAAgB,CACnB,4BAGG,QAAS,CACT,cAAe","sourcesContent":[".container {\n    text-align: left;\n    font-size: small;\n}\n\n.pStyle {\n    margin: 0;\n    font-size: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `AboutThisData-container-yxkB9`,
	"pStyle": `AboutThisData-pStyle-z99WE`
};
export default ___CSS_LOADER_EXPORT___;
