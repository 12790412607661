// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BoardScheduledEmailTemplateEmailBodyFormItem-errorForm-r5bZq{border:1px solid red !important}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Common/Board/BoardScheduledEmailTemplateForm/BoardScheduledEmailTemplateEmailBodyFormItem/BoardScheduledEmailTemplateEmailBodyFormItem.scss"],"names":[],"mappings":"AAAA,8DACE,+BAAgC","sourcesContent":[".errorForm {\n  border: 1px solid red !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorForm": `BoardScheduledEmailTemplateEmailBodyFormItem-errorForm-r5bZq`
};
export default ___CSS_LOADER_EXPORT___;
