import { CONTACT_CSV_UPLOAD_PAGE } from "./pageIds";
import { Endpoint } from "~/domain/api";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Upload, message, Button, Table, List, Col, Row } from "antd";
import NotFoundPage from "./NotFoundPage";
import { DownloadOutlined, InboxOutlined } from "@ant-design/icons";
import { fetchApi } from "~/actions/data";
import {
    AUTHORIZED_ACTION_LOADING,
    AUTHORIZED_ACTION_LOADED,
} from "~/actions/actionTypes";
import { customErrorMessage, customSuccessMessage } from "~/components/Common/AlertMessage/AlertMessage";
import CustomBackToTop from "~/components/Common/CustomBackToTop/CustomBackToTop";
import BackButton from "~/components/Common/BackButton/BackButton";
import CustomProgress from "~/components/Common/CustomProgress/CustomProgress";
import Paths from "~/components/Routes/Paths";
import { Links } from "~/utils/constants";
import styles from "./page.scss";

const pageId = CONTACT_CSV_UPLOAD_PAGE;

const csvUploadURL = () =>
    `${Endpoint.getBaseUrl()}/${Endpoint.contactsCsvUpload}`;


const CsvUploadPageCreator = (pageId, pageTitle) => {
    class CsvUploadPage extends Component {
        constructor(props) {
            super(props);
            this.state = {
                errorMessages: [],
                isProgressBar: false,
                uploadProgress: 0,
                isUploadSuccess: false,
            };
        }

        onChange = (info) => {
            const { status, response, percent } = info.file;
            if (status === "uploading") {
                this.setState({
                    errorMessages: [],
                    isProgressBar: true,
                    uploadProgress: Math.min(Math.round(percent), 99),
                    isUploadSuccess: false,
                });
            } else if (status === "done") {
                if (response["register_successed"]) {
                    this.setState({
                        uploadProgress: 100,
                        isUploadSuccess: true,
                    });
                    customSuccessMessage(`${info.file.name} ファイルがアップロードされ、データの登録が完了しました。`);
                    setTimeout(() => {
                        this.setState({ isProgressBar: false });
                    }, 2000);
                } else {
                    this.setState({
                        errorMessages: response["errorMessages"],
                        isProgressBar: false,
                    });
                    customErrorMessage(`${info.file.name} のファイルがアップロードされましたが、入力エラーによりデータの登録に失敗しました。`);
                }
            } else if (status === "error") {
                this.setState({ isProgressBar: false });
                customErrorMessage(`${info.file.name} アップロードに失敗しました。`);
                if ("detail" in response) {
                    customErrorMessage(response["detail"]);
                }
            }
        };

        render() {
            const { token, authorizedActions } = this.props;
            const {
                errorMessages,
                isProgressBar,
                uploadProgress,
                isUploadSuccess,
            } = this.state;
            const columns = [
                {
                    title: "項目名",
                    dataIndex: "column_name",
                    key: "column_name",
                },
                {
                    title: "必須",
                    dataIndex: "require",
                    key: "require",
                    className: styles.nowrap,
                },
                { title: "備考", dataIndex: "description", key: "description" },
            ];
            const data = [
                {
                    column_name: "取引先担当者名(姓)",
                    require: "◯",
                    description: "",
                },
                {
                    column_name: "取引先担当者名(名)",
                    require: "",
                    description: "",
                },
                { column_name: "所属取引先", require: "◯", description: "" },
                {
                    column_name: "メールアドレス > TO",
                    require: "◯",
                    description: "",
                },
                {
                    column_name: "メールアドレス > CC",
                    require: "",
                    description: "カンマ区切りで5個まで入力可能",
                },
                {
                    column_name: "TEL",
                    require: "",
                    description: "ハイフンありで登録\n例：03-0000-0000",
                },
                { column_name: "役職", require: "", description: "" },
                { column_name: "部署", require: "", description: "" },
                {
                    column_name: "自社担当者(メールアドレス)",
                    require: "",
                    description: "",
                },
                {
                    column_name: "最終商談日",
                    require: "",
                    description: "yyyy-mm-dd(半角数字)で入力",
                },
                {
                    column_name: "タグ",
                    require: "",
                    description: "カンマ区切りで10個まで入力可能",
                },
                {
                    column_name: "相性",
                    require: "",
                    description: "0：良い 1：悪い",
                },
                {
                    column_name: "希望エリア > 北海道",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "希望エリア > 東北",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "希望エリア > 関東",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "希望エリア > 中部",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "希望エリア > 東海",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "希望エリア > 関西",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "希望エリア > 四国",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "希望エリア > 中国",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "希望エリア > 九州",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "希望エリア > その他",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "案件配信 > 開発 > 職種詳細 > デザイナー",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "案件配信 > 開発 > 職種詳細 > フロントエンド",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "案件配信 > 開発 > 職種詳細 > バックエンド",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name:
                        "案件配信 > 開発 > 職種詳細 > PM・ディレクター",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "案件配信 > 開発 > 職種詳細 > その他",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "案件配信 > 開発 > スキル詳細 > 要件定義",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "案件配信 > 開発 > スキル詳細 > 基本設計",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "案件配信 > 開発 > スキル詳細 > 詳細設計",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "案件配信 > 開発 > スキル詳細 > 製造",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "案件配信 > 開発 > スキル詳細 > テスト・検証",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "案件配信 > 開発 > スキル詳細 > 保守・運用",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "案件配信 > 開発 > スキル詳細 > 未経験",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "案件配信 > インフラ > 職種詳細 > サーバー",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name:
                        "案件配信 > インフラ > 職種詳細 > ネットワーク",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name:
                        "案件配信 > インフラ > 職種詳細 > セキュリティ",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name:
                        "案件配信 > インフラ > 職種詳細 > データベース",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name:
                        "案件配信 > インフラ > 職種詳細 > 情報システム",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "案件配信 > インフラ > 職種詳細 > その他",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "案件配信 > インフラ > スキル詳細 > 要件定義",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "案件配信 > インフラ > スキル詳細 > 基本設計",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "案件配信 > インフラ > スキル詳細 > 詳細設計",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "案件配信 > インフラ > スキル詳細 > 構築",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name:
                        "案件配信 > インフラ > スキル詳細 > テスト・検証",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name:
                        "案件配信 > インフラ > スキル詳細 > 保守・運用",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "案件配信 > インフラ > スキル詳細 > 監視",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "案件配信 > インフラ > スキル詳細 > 未経験",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "案件配信 > その他 > 職種詳細 > 営業・事務",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "案件配信 > その他 > 職種詳細 > 基地局",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name:
                        "案件配信 > その他 > 職種詳細 > コールセンター・サポートデスク",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "案件配信 > その他 > 職種詳細 > その他",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "案件配信 > 商流制限",
                    require: "",
                    description:
                        "「なし」「エンド直・元請直まで」「1次請まで」「2次請まで」のいずれかを入力",
                },
                {
                    column_name: "要員配信 > 開発 > 職種詳細 > デザイナー",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "要員配信 > 開発 > 職種詳細 > フロントエンド",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "要員配信 > 開発 > 職種詳細 > バックエンド",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name:
                        "要員配信 > 開発 > 職種詳細 > PM・ディレクター",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "要員配信 > 開発 > 職種詳細 > その他",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "要員配信 > 開発 > スキル詳細 > 要件定義",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "要員配信 > 開発 > スキル詳細 > 基本設計",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "要員配信 > 開発 > スキル詳細 > 詳細設計",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "要員配信 > 開発 > スキル詳細 > 製造",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "要員配信 > 開発 > スキル詳細 > テスト・検証",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "要員配信 > 開発 > スキル詳細 > 保守・運用",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "要員配信 > 開発 > スキル詳細 > 未経験",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "要員配信 > インフラ > 職種詳細 > サーバー",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name:
                        "要員配信 > インフラ > 職種詳細 > ネットワーク",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name:
                        "要員配信 > インフラ > 職種詳細 > セキュリティ",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name:
                        "要員配信 > インフラ > 職種詳細 > データベース",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name:
                        "要員配信 > インフラ > 職種詳細 > 情報システム",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "要員配信 > インフラ > 職種詳細 > その他",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "要員配信 > インフラ > スキル詳細 > 要件定義",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "要員配信 > インフラ > スキル詳細 > 基本設計",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "要員配信 > インフラ > スキル詳細 > 詳細設計",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "要員配信 > インフラ > スキル詳細 > 構築",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name:
                        "要員配信 > インフラ > スキル詳細 > テスト・検証",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name:
                        "要員配信 > インフラ > スキル詳細 > 保守・運用",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "要員配信 > インフラ > スキル詳細 > 監視",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "要員配信 > インフラ > スキル詳細 > 未経験",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "要員配信 > その他 > 職種詳細 > 営業・事務",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "要員配信 > その他 > 職種詳細 > 基地局",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name:
                        "要員配信 > その他 > 職種詳細 > コールセンター・サポートデスク",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "要員配信 > その他 > 職種詳細 > その他",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "要員配信 > 希望雇用形態 > プロパー",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "要員配信 > 希望雇用形態 > フリーランス",
                    require: "",
                    description:
                        "0：未 1：済 未指定の場合は「0」で登録されます",
                },
                {
                    column_name: "要員配信 > 商流制限",
                    require: "",
                    description:
                        "「なし」「自社所属まで」「1社先所属まで」「2社先所属まで」のいずれかを入力",
                },
            ];
            const errorColumns = [
                {
                    title: "行数",
                    dataIndex: "line",
                    key: "line",
                    width: "10%",
                },
                {
                    title: "項目名",
                    dataIndex: "columnName",
                    key: "columnName",
                    width: "25%",
                },
                {
                    title: "エラー内容",
                    dataIndex: "message",
                    key: "message",
                    width: "65%",
                },
            ];
            const errorRowHeight = 53.5; // actual size measured in devtool
            const maxDisplayErrorRows = 100;

            let csvAuthorized =
                authorizedActions &&
                authorizedActions["contacts"] &&
                authorizedActions["contacts"]["csv_upload"];
            if (!csvAuthorized) {
                return (
                    <div className={styles.container}>
                        <NotFoundPage {...this.props} />
                    </div>
                );
            }

            return (
                <div>
                    <CustomBackToTop />
                    <Upload
                        type="drag"
                        name="file"
                        action={csvUploadURL()}
                        headers={{ Authorization: `Token ${token}` }}
                        onChange={this.onChange}
                        showUploadList={false}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">
                            クリックまたはドラッグでCSVファイルをアップロード
                        </p>
                        <p className="ant-upload-hint">
                            ※取引先担当者情報をCSVファイルで新規登録することができます。
                        </p>
                        <p className="ant-upload-hint">
                            ※取引先担当者一覧でダウンロードできるファイルとは形式が異なるため、右下のサンプルCSVファイルをご参照ください。
                        </p>
                        <p className="ant-upload-hint">
                            （サンプルCSVファイルの列順序や列数を変更するとエラーになります。）
                        </p>
                        <p className="ant-upload-hint">
                             ※CSVファイルのヘッダー行の情報は取り込まれないため、ヘッダー行の変更は反映されません。
                        </p>
                        <p className="ant-upload-hint">
                            ※新規登録のみで更新には対応していないため、重複登録にご注意ください。
                        </p>
                        <p className="ant-upload-hint">
                            ※一度に登録できるデータ件数は300件までです。
                        </p>
                        <p className="ant-upload-hint">
                            ※入力データの前後にあるスペースは除去して登録されます。
                        </p>
                    </Upload>
                    <Row className={styles.downloadButtonWrapper}>
                       <Col>
                           <a
                               href={Links.helps.csv.upload}
                               target="_blank"
                               rel="noopener noreferrer"
                           >
                               <Button size="small">CSVアップロードヘルプ</Button>
                           </a>
                       </Col>
                        <Col>
                            <Button
                                type="primary"
                                icon={<DownloadOutlined />}
                                size="small"
                                className={styles.tableControlButton}
                                href="/static/app_staffing/contacts.csv">
                                サンプル CSV ダウンロード
                            </Button>
                        </Col>
                    </Row>
                    <div
                        hidden={errorMessages.length === 0}
                        className={styles.errorMessages}>
                        <p>
                            ▼下記のエラー内容をご確認の上、対象のデータを修正してください。
                        </p>
                        <div className={styles.tableNewLine}>
                            <Table
                                columns={errorColumns}
                                dataSource={errorMessages}
                                pagination={false}
                                scroll={{
                                    y: errorRowHeight * maxDisplayErrorRows
                                }}
                            />
                        </div>
                    </div>
                    <div className={styles.tableNewLine}>
                        <Table
                            columns={columns}
                            dataSource={data}
                            pagination={false}
                        />
                    </div>
                    <Row style={{ marginTop: "1%" }}>
                        <Col>
                            <BackButton to={Paths.contacts} />
                        </Col>
                    </Row>
                    {isProgressBar && (
                        <div style={{ textAlign: "center" }}>
                            <CustomProgress
                                percentage={uploadProgress}
                                progressCompleted={isUploadSuccess}
                            />
                        </div>
                    )}
                </div>
            );
        }
    }
    CsvUploadPage.propTypes = {
        currentUserId: PropTypes.string.isRequired,
        token: PropTypes.string.isRequired,
        authorizedActions: PropTypes.object.isRequired,
        dispatch: PropTypes.func.isRequired,
    };
    function mapStateToProps(state) {
        return {
            token: state.login.token,
            authorizedActions: state.login.authorizedActions,
            currentUserId: state.login.userId,
        };
    }
    return connect(mapStateToProps)(CsvUploadPage);
};

export const ContactCsvUploadPage = CsvUploadPageCreator(
    pageId,
    "取引先担当者 登録"
);
