"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.moveCardWithBoardOriginalParams = exports.useRecruitBoardUtils = void 0;
var useTag_1 = require("~/hooks/useTag");
var utils_1 = require("~/utils/utils");
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore: no type def provided
var react_kanban_1 = require("@asseinfo/react-kanban");
var useRecruitBoardUtils = function () {
    var splitLength = 50;
    var splitRegex = new RegExp(".{1,".concat(splitLength, "}"), "g");
    var data = (0, useTag_1.useFetchTagsAPIQuery)({
        deps: {
            page: 1,
            value: "",
            pageSize: 2000,
        },
    }).data;
    var splitText = function (value) {
        var _a;
        if (!value) {
            return undefined;
        }
        var splittedString = (_a = value.match(splitRegex)) !== null && _a !== void 0 ? _a : [];
        return splittedString.join("\n");
    };
    var splitList = function (values, joinString) {
        if (joinString === void 0) { joinString = ","; }
        if (!values || !values.length) {
            return undefined;
        }
        if (values.join(joinString).length <= splitLength) {
            return values.join(joinString);
        }
        try {
            var results_1 = [];
            var copiedValues_1 = __spreadArray([], values, true);
            var poppedList_1 = [];
            values.forEach(function (_) {
                var popped = copiedValues_1.shift();
                if (!popped) {
                    return;
                }
                poppedList_1.push(popped);
                var poppedString = poppedList_1.join(joinString);
                if (poppedString.length > 50) {
                    if (!copiedValues_1.length) {
                        poppedString += "\n";
                    }
                    results_1.push(poppedString + joinString + "\n");
                    poppedList_1 = [];
                    return;
                }
                if (!copiedValues_1.length) {
                    results_1.push(poppedString);
                }
            });
            return results_1.join("");
        }
        catch (err) {
            return undefined;
        }
    };
    var getDetail = function (values) {
        if (!values) {
            return undefined;
        }
        return splitText(values.detail);
    };
    var getTags = function (values, tags) {
        if (!values || !values.tags || !values.tags.length) {
            return undefined;
        }
        var tagIds = values.tags;
        var tagList = tagIds === null || tagIds === void 0 ? void 0 : tagIds.map(function (id) { return (tags !== null && tags !== void 0 ? tags : []).find(function (tag) { return tag.id === id; }); }).filter(utils_1.notUndefined);
        var results = tagList.map(function (item) { return item === null || item === void 0 ? void 0 : item.value; });
        return splitList(results, "、");
    };
    var convertData = function (data) {
        var value = data.value;
        if (!value) {
            return data;
        }
        if (value.length >= splitLength) {
            return {
                label: data.label + "\n",
                value: data.value + "\n",
            };
        }
        return data;
    };
    var getTextInfo = function (values) {
        var border = "========================================\n";
        var _data = [
            {
                label: "【名前】",
                value: getDetail(values),
            },
            {
                label: "【スキル】",
                value: getTags(values, data === null || data === void 0 ? void 0 : data.results),
            },
            {
                label: "【名前】",
                value: getDetail(values),
            },
        ];
        var results = _data
            .filter(function (item) { return !!item.value; })
            .map(convertData)
            .map(function (item) {
            if (!item.label) {
                return item.value;
            }
            return "".concat(item.label).concat(item.value, "\n");
        });
        var finalResults = __spreadArray(__spreadArray([border], results, true), [border], false);
        return finalResults.join("");
    };
    return {
        getTags: getTags,
        getTextInfo: getTextInfo,
    };
};
exports.useRecruitBoardUtils = useRecruitBoardUtils;
var moveCardWithBoardOriginalParams = function (boardData, from, to) {
    var _a, _b;
    // NOTE(Chikama): Do deep copy in advance.
    var updatedBoard = JSON.parse(JSON.stringify(boardData));
    var isListCardTotalAvailable = updatedBoard.columns.every(function (col) { return col.listCardTotal !== undefined; });
    var cardToBeMoved = (_b = (_a = updatedBoard.columns.find(function (col) { return col.id === from.fromColumnId; })) === null || _a === void 0 ? void 0 : _a.cards) === null || _b === void 0 ? void 0 : _b[from.fromPosition];
    if (isListCardTotalAvailable && !(cardToBeMoved === null || cardToBeMoved === void 0 ? void 0 : cardToBeMoved.isArchived)) {
        var fromColumnIndex = boardData.columns.map(function (data) { return data.id; }).indexOf(from.fromColumnId);
        var toColumnIndex = boardData.columns.map(function (data) { return data.id; }).indexOf(to.toColumnId);
        updatedBoard.columns[fromColumnIndex].listCardTotal -= 1;
        updatedBoard.columns[toColumnIndex].listCardTotal += 1;
    }
    return (0, react_kanban_1.moveCard)(updatedBoard, from, to);
};
exports.moveCardWithBoardOriginalParams = moveCardWithBoardOriginalParams;
