import {
    LOADING,
    SEARCH_LOADING,
    CANCELED,
    ERROR,
    USE_CACHE,
    SEARCH_COMPLETED,
    SELECT_ROWS,
    RESET_ROWS,
    RESET_DATA,
    CLEAR,
    CLEAR_SEARCH_FORM,
    LOAD_LAST_PAGE,
    LOGOUT,
    DELETED,
    CREATING,
    CREATED,
    TAG_CREATED,
    BULK_UPDATED,
    CHANGE_SEARCH_TEMPLATE,
    SYNC_COLUMN_KEYS,
    SEARCH_TEMPLATE_LOADING,
    SEARCH_TEMPLATE_LOADED,
    SEARCH_TEMPLATE_DELETED,
    SEARCH_TEMPLATE_CREATED,
    SEARCH_TEMPLATE_UPDATED,
    SEARCH_TEMPLATE_COMMITTED,
    CHANGED_SEARCH_TEMPLATE,
    DISPLAY_SETTING_LOADED,
    DISPLAY_SETTING_UPDATED,
    INVITED,
    VALIDATION_ERROR,
    SEARCH_MENU_OPEN,
    LOGIN,
    SET_DEFAULT_TEMPLATE,
    RESET_CURRENT_TEMPLATES,
    RESET_ERROR_MESSAGE,
    SEARCH_TEMPLATE_COMMITTED_FINISH,
    SEARCH_TEMPLATE_UPDATING,
    SEARCH_TEMPLATE_DELETING,
    COPIED,
    RESET_MESSAGE,
    FIX_CURRENT_SEARCH_CONDITIONS,
    UPDATE_SORT,
    UPDATE_CURRENT_PAGE,
    RESET_ERROR,
} from "~/actions/actionTypes";
import moment from "moment";

const usingCache =
    "前回取得時のデータを表示しています。最新の情報を取得するには再検索をしてください。";
export const defaultInitialState = {
    // An initial state.
    requireRefresh: false,
    requireResetSelectedRows: false,
    loading: false,
    message: "",
    errorMessage: "",
    data: [],
    selectedRowKeys: [],
    currentPage: 1,
    pageSize: 10,
    totalCount: 0,
    currentSearchConditions: {},
    currentSearchTemplates: [],
    searchTemplateTotalAvailableCount: 0,
    sortKey: undefined,
    sortOrder: undefined,
    displaySetting: undefined,
    displaySettingLoaded: false,
    searchMenuOpen: false,
    isDefaultTemplateAttached: true,
    requireRefreshTemplate: false,
    latestSearchTimestamp: undefined,
};

const createSearchPageReducer = (
    pageId,
    InitialState = defaultInitialState
) => {
    const Reducer = (state = InitialState, action) => {
        switch (action.type) {
            case pageId + LOADING:
                return Object.assign({}, state, {
                    loading: true,
                    requireRefresh: false,
                    message: "",
                    errorMessage: "",
                });
            case pageId + SEARCH_LOADING:
                return Object.assign({}, state, {
                    loading: true,
                    requireRefresh: false,
                    message: "",
                    errorMessage: "",
                    latestSearchTimestamp: action.payload.timestamp,
                });
            case pageId + CANCELED:
                return Object.assign({}, state, { loading: false });
            case pageId + USE_CACHE:
                return Object.assign({}, state, {
                    loading: false,
                    message: usingCache,
                });
            case pageId + SEARCH_COMPLETED:
                // NOTE: If another search is performed during this search,
                //       ignore the result of this search in order to apply only
                //       the latest result.
                if (
                    state.latestSearchTimestamp &&
                    state.latestSearchTimestamp > action.payload.timestamp
                ) {
                    return { ...state };
                }
                return {
                    ...state,
                    loading: false,
                    message: "",
                    errorMessage: "",
                    data: action.payload.data,
                    currentPage: action.payload.currentPage,
                    pageSize: action.payload.pageSize,
                    totalCount: action.payload.totalCount,
                    currentSearchConditions: action.payload.searchConditions,
                    sortKey: action.payload.sortKey,
                    sortOrder: action.payload.sortOrder,
                    requireRefresh: action.payload.requireRefresh,
                    requireResetSelectedRows:
                        action.payload.requireResetSelectedRows,
                }
            case pageId + UPDATE_CURRENT_PAGE:
                return {
                    ...state,
                    currentPage: action.payload,
                }
            case pageId + UPDATE_SORT:
                return {
                    ...state,
                    sortKey: action.key,
                    sortOrder: action.order,
                }
            case pageId + LOAD_LAST_PAGE:
                return Object.assign({}, state, {
                    requireRefresh: true,
                    currentPage: action.payload.lastPageNumber,
                });
            case pageId + SYNC_COLUMN_KEYS:
                return Object.assign({}, state, {
                    selectedColumnKeys: action.payload.selectedColumnKeys,
                });
            case pageId + SELECT_ROWS:
                return Object.assign({}, state, {
                    selectedRowKeys: action.payload.selectedRowKeys,
                });
            case pageId + RESET_ROWS:
                return Object.assign({}, state, { selectedRowKeys: [] });
            case pageId + RESET_DATA:
                return Object.assign({}, state, { data: [] });
            case pageId + CLEAR_SEARCH_FORM:
                return Object.assign({}, state, {
                    requireRefresh: true,
                    currentSearchConditions:
                        action.payload.initialSearchConditions,
                });
            case pageId + CLEAR:
                return Object.assign({}, state, InitialState);
            case pageId + ERROR:
                return Object.assign({}, state, {
                    loading: false,
                    message: "",
                    errorMessage: action.payload.error.detail,
                    errorExtraInfo: action.payload.errorExtraInfo,
                });
            case pageId + RESET_ERROR:
                return Object.assign({}, state, {
                    errorMessage: "",
                    errorExtraInfo: undefined,
                });
            case LOGOUT:
                return Object.assign({}, state, defaultInitialState);
            case pageId + DELETED:
                return {
                    ...state,
                    requireRefresh: true,
                    requireResetSelectedRows: true,
                    message: action.payload.message,
                };
            case pageId + BULK_UPDATED:
                return {
                    ...state,
                    requireRefresh: true,
                    requireResetSelectedRows: true,
                    message: action.payload.message,
                };
            case pageId + CREATING:
                return Object.assign({}, state, {
                    loading: true,
                    message: "",
                    errorMessage: "",
                });
            case pageId + CREATED:
                return Object.assign({}, state, { requireRefresh: true });
            case pageId + TAG_CREATED:
                return Object.assign({}, state, {
                    tagResisterResult: action.payload.data,
                });
            case pageId + CHANGE_SEARCH_TEMPLATE:
                return Object.assign({}, state, {
                    requireRefresh: true,
                    currentSearchConditions:
                        action.payload.initialSearchTemplate.values || {},
                });
            case pageId + CHANGED_SEARCH_TEMPLATE:
                return Object.assign({}, state, { requireRefresh: false });
            case pageId + SEARCH_TEMPLATE_LOADING:
                return Object.assign({}, state, {
                    requireRefreshTemplate: false,
                });
            case pageId + SEARCH_TEMPLATE_LOADED:
                return {
                    ...state,
                    currentSearchTemplates: action.payload.data["templates"],
                    searchTemplateTotalAvailableCount:
                        action.payload.data["total_available_count"],
                };
            case pageId + SEARCH_TEMPLATE_DELETING:
                return {
                    ...state,
                    loading: true,
                };
            case pageId + SEARCH_TEMPLATE_DELETED:
                return {
                    ...state,
                    loading: false,
                    message: "テンプレートを削除しました",
                };
            case pageId + SEARCH_TEMPLATE_CREATED:
                return {
                    ...state,
                    message: "テンプレートを作成しました。",
                    loading: false,
                };
            case pageId + SEARCH_TEMPLATE_UPDATING:
                return {
                    ...state,
                    loading: true,
                };
            case pageId + SEARCH_TEMPLATE_UPDATED:
                return {
                    ...state,
                    message: "デフォルト設定が完了しました。",
                    loading: false,
                };
            case pageId + SEARCH_TEMPLATE_COMMITTED:
                return {
                    ...state,
                    requireRefreshTemplate: true,
                    message: "",
                };
            case pageId + SEARCH_TEMPLATE_COMMITTED_FINISH:
                return {
                    ...state,
                    loading: false,
                    requireRefreshTemplate: false,
                };
            case pageId + DISPLAY_SETTING_LOADED:
                const contentHash = action.payload.data["content_hash"];
                const resourceName = action.payload.resourceName;
                const pageSize =
                    contentHash &&
                    resourceName &&
                    contentHash[action.payload.resourceName] &&
                    contentHash[action.payload.resourceName]["page_size"]
                        ? contentHash[action.payload.resourceName]["page_size"]
                        : 10;
                return Object.assign({}, state, {
                    displaySetting: contentHash,
                    displaySettingLoaded: true,
                    pageSize: pageSize,
                    requireRefresh: action.payload.requireRefresh,
                });
            case pageId + DISPLAY_SETTING_UPDATED:
                return {
                    ...state,
                    requireRefresh: false,
                    displaySetting: action.payload.data["content_hash"],
                };
            case pageId + INVITED:
                return Object.assign({}, state, {
                    loading: false,
                    created: true,
                    message: "招待メールを送信しました",
                    errorMessage: "",
                    fieldErrors: {},
                });
            case pageId + VALIDATION_ERROR:
                let messages = Object.values(action.payload.error.field_errors);
                let message = [].concat(...messages)[0];
                return Object.assign({}, state, {
                    loading: false,
                    message: "",
                    errorMessage: message,
                    fieldErrors: action.payload.error.field_errors,
                });
            case pageId + SEARCH_MENU_OPEN:
                return Object.assign({}, state, {
                    searchMenuOpen: action.payload.data,
                });
            case LOGIN:
                if (pageId === "SHARED_EMAIL_PAGE") {
                    return Object.assign({}, state, {
                        currentSearchConditions: {
                            date_range: [
                                moment().startOf("day").subtract(7, "days"),
                                moment().endOf("day"),
                            ],
                        },
                    });
                } else if (pageId === "ORGANIZATION_SEARCH_PAGE") {
                    return Object.assign({}, state, {
                        currentSearchConditions: { score: 3 },
                    });
                }
                return state;
            case pageId + SET_DEFAULT_TEMPLATE:
                return Object.assign({}, state, {
                    isDefaultTemplateAttached: false,
                    requireRefresh: false,
                    currentSearchConditions: action.data,
                });
            case pageId + RESET_CURRENT_TEMPLATES:
                return Object.assign({}, state, {
                    currentSearchTemplates: action.payload.data,
                });
            case pageId + RESET_MESSAGE:
                return {
                    ...state,
                    message: "",
                };
            case pageId + RESET_ERROR_MESSAGE:
                return Object.assign({}, state, { errorMessage: "" });
            case pageId + COPIED:
                return { ...state, message: action.payload.message };
            case pageId + FIX_CURRENT_SEARCH_CONDITIONS:
                return {
                    ...state,
                    currentSearchConditions:
                        action.payload.fixedCurrentSearchConditions,
                };
            default:
               return state;
        }
    };
    return Reducer;
};

export default createSearchPageReducer;
