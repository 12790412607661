// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProjectBoardCardContent-text-fnjK3{display:flex;justify-content:start;padding:10px 0 0 0;font-weight:550}.ProjectBoardCardContent-textLabel-yIASx{display:inline-block;width:5em;text-align:right}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/project/ProjectBoardPage/ProjectBoardCard/ProjectBoardCardContent/ProjectBoardCardContent.scss"],"names":[],"mappings":"AAAA,oCACI,YAAa,CACb,qBAAsB,CACtB,kBAAmB,CACnB,eAAgB,CACnB,yCAGG,oBAAqB,CACrB,SAAU,CACV,gBAAiB","sourcesContent":[".text {\n    display: flex;\n    justify-content: start;\n    padding: 10px 0 0 0;\n    font-weight: 550;\n}\n\n.textLabel {\n    display: inline-block;\n    width: 5em;\n    text-align: right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": `ProjectBoardCardContent-text-fnjK3`,
	"textLabel": `ProjectBoardCardContent-textLabel-yIASx`
};
export default ___CSS_LOADER_EXPORT___;
