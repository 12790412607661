import React from "react";
import { useSelector } from 'react-redux';
import { SCHEDULED_MAIL_SEARCH_PAGE } from "./pageIds";
import createSearchPage from "~/components/Pages/Factories/createSearchPage";
import { Endpoint } from "~/domain/api";
import {
    scheduledEmailSearchParamToAPI,
    convertScheduledEmailSummaryResponseDataEntry,
} from "~/domain/data";
import ScheduledEmailTableDisplaySettingModal from "../Tables/ScheduledMailsTable/ScheduledEmailTableDisplaySettingModal/ScheduledEmailTableDisplaySettingModal";
import ScheduledEmailSearchDrawer from "./ScheduledEmailSearchPage/ScheduledEmailSearchDrawer/ScheduledEmailSearchDrawer";
import ScheduledEmailTable from "./ScheduledEmailSearchPage/ScheduledEmailTable";
import { useFetchScheduledEmailSettingAPIQuery } from '~/hooks/scheduledEmailSetting';
import Paths from "~/components/Routes/Paths";
import NotFoundPage from "./NotFoundPage";
import SpinContainer from "../Common/SpinContainer";

const pageId = SCHEDULED_MAIL_SEARCH_PAGE;
const resourceURL = `${Endpoint.getBaseUrl()}/${Endpoint.scheduledEmails}`;
const columnSettingURL = `${Endpoint.getBaseUrl()}/${
    Endpoint.scheduledEmailColumnSetting
}`;
const copyURL = `${Endpoint.getBaseUrl()}/${Endpoint.scheduledEmails}/${
    Endpoint.copyActionSuffix
}`;

const columns = [
    {
        name: "配信日時",
        key: "date_to_send",
    },
    {
        name: "配信種別",
        key: "send_type",
    },
    {
        name: "件名",
        key: "subject",
    },
    {
        name: "添付",
        key: "attachments",
    },
    {
        name: "配信者",
        key: "sender__name",
    },
    {
        name: "配信ステータス",
        key: "status",
    },
    {
        name: "配信数",
        key: "send_total_count",
    },
];

const resourceName = "scheduled_mails";
const changeActiveStatusAuthorized = () => {
    return false;
};
const deleteAuthorized = (authorizedActions) => {
    return (
        authorizedActions &&
        authorizedActions[resourceName] &&
        authorizedActions[resourceName]["delete"]
    );
};
const csvAuthorized = () => {
    return false;
};
const columnSettingAuthorized = (authorizedActions) => {
    return (
        authorizedActions &&
        authorizedActions[resourceName] &&
        authorizedActions[resourceName]["column_setting"]
    );
};
const accessAuthorized = () => {
    return true;
};
const searchTemplateAuthorized = (authorizedActions) => {
    return true;
};
const editAuthorized = (authorizedActions) => {
    return (
        authorizedActions &&
        authorizedActions[resourceName] &&
        authorizedActions[resourceName]["update"]
    );
};

const searchConditionSanitizer = (unshowList, targetData) => {
    const newData = { ...targetData };
    for (const fieldName of unshowList) {
        if (fieldName === "date_to_send") {
            newData["date_range"] = undefined;
        } else if (fieldName === "send_total_count") {
            newData["send_total_count_gt"] = undefined;
            newData["send_total_count_lt"] = undefined;
        } else if (fieldName === "open_count") {
            newData["open_count_gt"] = undefined;
            newData["open_count_lt"] = undefined;
        } else if (fieldName === "sender") {
            newData["sender_id"] = undefined;
        } else {
            newData[fieldName] = undefined;
        }
    }
    return newData;
};

const buildBulkEditUrl = (selectedRows) => {
    const query = new URLSearchParams();
    const scheduledEmails = selectedRows.map(
        ({ id, subject }) => ({ id, subject })
    );
    query.set("scheduledEmails", JSON.stringify(scheduledEmails));
    return `${Paths.scheduledMailsBulkEdit}?${query}`;
};

const bulkEditSelectionLimit = 5;

const ScheduledEmailPageContainer = createSearchPage(
    pageId,
    "scheduledEmailSearchPage",
    "配信メール 一覧",
    ScheduledEmailSearchDrawer,
    ScheduledEmailTable,
    resourceURL,
    convertScheduledEmailSummaryResponseDataEntry,
    scheduledEmailSearchParamToAPI,
    false,
    undefined,
    undefined, // csvDownloadURL,
    true,
    true,
    columns,
    columnSettingURL,
    undefined,
    true,
    copyURL,
    changeActiveStatusAuthorized,
    deleteAuthorized,
    csvAuthorized,
    columnSettingAuthorized,
    accessAuthorized,
    undefined,
    searchTemplateAuthorized,
    resourceName,
    false,
    [],
    [ScheduledEmailTableDisplaySettingModal],
    searchConditionSanitizer,
    undefined,
    undefined,
    true,
    editAuthorized,
    buildBulkEditUrl,
    bulkEditSelectionLimit,
    ""
);

const ScheduledEmailPage = (props) => {
    const { data, isLoading } = useFetchScheduledEmailSettingAPIQuery({});
    const userId = useSelector((state) => state.login.userId);

    if (isLoading) {
        return <SpinContainer />;
    }

    let settingExists = false;
    if (data) {
        if (data.common_smtp_server.username) {
            settingExists = true;
        } else {
            const userSetting = data.user_smtp_servers.find((setting) =>
                setting.user_id === userId
            );
            if (userSetting && userSetting.username) {
                settingExists = true;
            }
        }
    }

    if (settingExists) {
        return <ScheduledEmailPageContainer {...props} />;
    } else {
        return <NotFoundPage />;
    }
};

export default ScheduledEmailPage;
