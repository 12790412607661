// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProjectGanttInfo-over70-L6MmO{color:#003656}.ProjectGanttInfo-between40to70-AlpAu{color:#80d6ff}.ProjectGanttInfo-under40-M2uZA{color:#d0edff}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/project/ProjectGanttPage/ProjectGanttInfo/ProjectGanttInfo.scss","webpack://./frontend/stylesheets/constants.scss"],"names":[],"mappings":"AAGA,+BACI,aCGkB,CDFrB,sCAGG,aCAyB,CDC5B,gCAGG,aCHmB","sourcesContent":["@import \"../../../../../../stylesheets/constants.scss\";\n@import \"~coreStylesheet\";\n\n.over70 {\n    color: $gantt-over70;\n}\n\n.between40to70 {\n    color: $gantt-between40to70;\n}\n\n.under40 {\n    color: $gantt-under40;\n}\n","/* アプリケーション全体 */\n$breakpoint-mobile: 768px;\n$width-small-screen: 1500px;\n$primary-color: #a7cf69;\n$komorebi-brown: #615748;\n\n/* ガントチャート関連 */\n$gantt-over70: #003656;\n$gantt-between40to70: #80d6ff;\n$gantt-under40: #d0edff;\n\n@mixin mobile-devices() {\n    @media screen and (max-width: $breakpoint-mobile) {\n        @content;\n    }\n}\n\n@mixin small-screen() {\n    @media screen and (max-width: $width-small-screen) {\n        @content;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"over70": `ProjectGanttInfo-over70-L6MmO`,
	"between40to70": `ProjectGanttInfo-between40to70-AlpAu`,
	"under40": `ProjectGanttInfo-under40-M2uZA`
};
export default ___CSS_LOADER_EXPORT___;
