// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ScheduledEmailOpenCountFormItem-container-oe3CP{width:100%;text-align:left}.ScheduledEmailOpenCountFormItem-userInput-D1_93{width:100%}.ScheduledEmailOpenCountFormItem-tooltip-ws4E8{margin-left:5px}.ScheduledEmailOpenCountFormItem-infoIcon-i8E5H{align-self:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/ScheduledEmailSearchPage/ScheduledEmailSearchDrawer/ScheduledEmailOpenCountFormItem/ScheduledEmailOpenCountFormItem.scss"],"names":[],"mappings":"AAAA,iDACI,UAAW,CACX,eAAgB,CACnB,iDAGG,UAAW,CACd,+CAGG,eAAgB,CACnB,gDAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ScheduledEmailOpenCountFormItem-container-oe3CP`,
	"userInput": `ScheduledEmailOpenCountFormItem-userInput-D1_93`,
	"tooltip": `ScheduledEmailOpenCountFormItem-tooltip-ws4E8`,
	"infoIcon": `ScheduledEmailOpenCountFormItem-infoIcon-i8E5H`
};
export default ___CSS_LOADER_EXPORT___;
