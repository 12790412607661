"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createReplyCommentReducer = exports.ReplyCommentInitialState = void 0;
var actionTypes_1 = require("../../actions/actionTypes");
var constants_1 = require("~/utils/constants");
var commentActionSuffix = "__COMMENTS";
exports.ReplyCommentInitialState = {
    commentValue: "",
    commentError: "",
    error: undefined,
    method: "",
};
var createReplyCommentReducer = function (pageId, initialState) {
    if (initialState === void 0) { initialState = exports.ReplyCommentInitialState; }
    var replyCommentReducer = function (state, action) {
        if (state === void 0) { state = initialState; }
        switch (action.type) {
            case actionTypes_1.COMMENT_NEW_CLEAR:
                return __assign(__assign({}, state), { commentValue: "", commentError: "", error: undefined, method: "" });
            case actionTypes_1.WRITING_REPLY_COMMENTS:
                return __assign(__assign({}, state), { commentValue: action.payload.commentValue, commentError: "", error: undefined, method: "" });
            case actionTypes_1.PARENT_CANNOT_SUBMIT_WHEN_REPLY_COMMENT_EXISTS:
                return __assign(__assign({}, state), { commentError: constants_1.ErrorMessages.commentError, error: undefined });
            case pageId + commentActionSuffix + actionTypes_1.CREATED:
                return __assign(__assign({}, state), { commentValue: "", commentError: "", error: undefined, method: "" });
            case pageId + commentActionSuffix + actionTypes_1.VALIDATION_ERROR:
                if (action.payload.method !== 'create') {
                    return __assign({}, state);
                }
                var error = action.payload.error;
                var errorMessage = error.message;
                if (error.field_errors && error.field_errors.detail) {
                    errorMessage = error.field_errors.detail;
                }
                else if (error.field_errors && error.field_errors.content) {
                    errorMessage = error.field_errors.content;
                }
                return __assign(__assign({}, state), { commentError: errorMessage, error: undefined, method: "" });
            default:
                return __assign({}, state);
        }
    };
    return replyCommentReducer;
};
exports.createReplyCommentReducer = createReplyCommentReducer;
exports.default = exports.createReplyCommentReducer;
