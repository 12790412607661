// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProjBContractPersonnelContractFormItem-select_option-RY9HG{text-align:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/project/ProjectBoardPage/ProjectBoardDetailModal/ProjectBoardContracts/ProjectBoardContractsRow/ProjBContractPersonnelContractFormItem/ProjBContractPersonnelContractFormItem.scss"],"names":[],"mappings":"AAAA,4DACE,iBAAkB","sourcesContent":[".select_option {\n  text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select_option": `ProjBContractPersonnelContractFormItem-select_option-RY9HG`
};
export default ___CSS_LOADER_EXPORT___;
