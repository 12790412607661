// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OrganizationDocumentFormItem-container-BDrvf{width:100%;text-align:left}.OrganizationDocumentFormItem-userInput-VCj7w{width:100%}.OrganizationDocumentFormItem-tooltip-leGUz{margin-left:5px}.OrganizationDocumentFormItem-infoIcon-pThgf{align-self:center}.OrganizationDocumentFormItem-select_option-Y5nuQ{text-align:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Forms/OrganizationSearchForm/OrganizationDocumentFormItem/OrganizationDocumentFormItem.scss"],"names":[],"mappings":"AAAA,8CACI,UAAW,CACX,eAAgB,CACnB,8CAGG,UAAW,CACd,4CAGG,eAAgB,CACnB,6CAGG,iBAAkB,CACrB,kDAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n\n.select_option {\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `OrganizationDocumentFormItem-container-BDrvf`,
	"userInput": `OrganizationDocumentFormItem-userInput-VCj7w`,
	"tooltip": `OrganizationDocumentFormItem-tooltip-leGUz`,
	"infoIcon": `OrganizationDocumentFormItem-infoIcon-pThgf`,
	"select_option": `OrganizationDocumentFormItem-select_option-Y5nuQ`
};
export default ___CSS_LOADER_EXPORT___;
