// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PersonnelCreatedUserFormItem-container-DzmRi{width:100%;text-align:left}.PersonnelCreatedUserFormItem-userInput-h_h70{width:100%}.PersonnelCreatedUserFormItem-tooltip-i0Hix{margin-left:5px}.PersonnelCreatedUserFormItem-infoIcon-tqXD2{align-self:center}.PersonnelCreatedUserFormItem-marginBottom-M9asX{margin-bottom:1%}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/personnel/PersonnelBoardPage/PersonnelBoardSearchDrawer/PersonnelCreatedUserFormItem/PersonnelCreatedUserFormItem.scss"],"names":[],"mappings":"AAAA,8CACI,UAAW,CACX,eAAgB,CACnB,8CAGG,UAAW,CACd,4CAGG,eAAgB,CACnB,6CAGG,iBAAkB,CACrB,iDAGG,gBAAiB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n\n.marginBottom {\n    margin-bottom: 1%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PersonnelCreatedUserFormItem-container-DzmRi`,
	"userInput": `PersonnelCreatedUserFormItem-userInput-h_h70`,
	"tooltip": `PersonnelCreatedUserFormItem-tooltip-i0Hix`,
	"infoIcon": `PersonnelCreatedUserFormItem-infoIcon-tqXD2`,
	"marginBottom": `PersonnelCreatedUserFormItem-marginBottom-M9asX`
};
export default ___CSS_LOADER_EXPORT___;
