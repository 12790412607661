import React from "react";
import { Col, Form, Select, Row, Tooltip } from "antd";
import {
    Links,
    ORGANIZATION_NOT_STATUS,
    ORGANIZATION_STATUS,
    iconCustomColor
} from "~/utils/constants";
import { QuestionCircleFilled } from "@ant-design/icons";
import CustomSelectTag from "~/components/Common/CustomSelectTag/CustomSelectTag";
import styles from "./CorporateCategoryFormItem.scss";

const CorporateCategoryFormItem = ({ disabled = false }) => {
    const statuses = [
        ...ORGANIZATION_STATUS,
        ...ORGANIZATION_NOT_STATUS,
    ].filter((status) => status.search);

    return (
        <Col span={24} style={{ marginBottom: "1%" }}>
            <Row className={styles.container}>
                <Col span={23}>
                    <Form.Item colon={false} name="category" noStyle>
                        <Select
                            showSearch={false}
                            className={styles.container}
                            placeholder="取引先ステータス"
                            mode="multiple"
                            allowClear
                            getPopupContainer={(trigger) => trigger.parentNode}
                            tagRender={(props) => {
                                const status = statuses.find(
                                    (status) => status.value === props.value
                                );
                                return (
                                    <CustomSelectTag
                                        color={status?.color}
                                        title={status?.title}
                                        {...props}
                                    />
                                );
                            }}
                            disabled={disabled}>
                            {statuses.map((item) => {
                                return (
                                    <Select.Option
                                        key={item.value}
                                        className={styles.select_option}
                                        value={item.value}>
                                        {item.title}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={1} className={styles.infoIcon}>
                    <Tooltip
                        title={
                            <span>
                                複数選択をすると
                                <a
                                    href={Links.helps.filter.or}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    OR検索
                                </a>
                            </span>
                        }>
                        <QuestionCircleFilled style={{ color: iconCustomColor }} className={styles.tooltip} />
                    </Tooltip>
                </Col>
            </Row>
        </Col>
    );
};

export default CorporateCategoryFormItem;
