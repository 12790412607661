// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProjGIncludeArchiveSwitchFromItem-container-n_SP0{width:100%;text-align:left}.ProjGIncludeArchiveSwitchFromItem-userInput-pplVL{width:100%}.ProjGIncludeArchiveSwitchFromItem-tooltip-MC2PJ{margin-left:5px}.ProjGIncludeArchiveSwitchFromItem-infoIcon-H3Xqt{align-self:center}.ProjGIncludeArchiveSwitchFromItem-switchLabel-wdCGq{display:inline-block;align-self:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/project/ProjectGanttPage/ProjectGanttSearchDrawer/ProjGIncludeArchiveSwitchFromItem/ProjGIncludeArchiveSwitchFromItem.scss"],"names":[],"mappings":"AAAA,mDACI,UAAW,CACX,eAAgB,CACnB,mDAGG,UAAW,CACd,iDAGG,eAAgB,CACnB,kDAGG,iBAAkB,CACrB,qDAGG,oBAAqB,CACrB,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n\n.switchLabel {\n    display: inline-block;\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ProjGIncludeArchiveSwitchFromItem-container-n_SP0`,
	"userInput": `ProjGIncludeArchiveSwitchFromItem-userInput-pplVL`,
	"tooltip": `ProjGIncludeArchiveSwitchFromItem-tooltip-MC2PJ`,
	"infoIcon": `ProjGIncludeArchiveSwitchFromItem-infoIcon-H3Xqt`,
	"switchLabel": `ProjGIncludeArchiveSwitchFromItem-switchLabel-wdCGq`
};
export default ___CSS_LOADER_EXPORT___;
