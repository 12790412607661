// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EmailFormItem-container-seLY1{width:100%;text-align:left}.EmailFormItem-userInput-SUnOx{width:100%}.EmailFormItem-tooltip-sL63j{margin-left:5px}.EmailFormItem-infoIcon-pqcQ4{align-self:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Forms/UserSearchForm/EmailFormItem/EmailFormItem.scss"],"names":[],"mappings":"AAAA,+BACI,UAAW,CACX,eAAgB,CACnB,+BAGG,UAAW,CACd,6BAGG,eAAgB,CACnB,8BAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `EmailFormItem-container-seLY1`,
	"userInput": `EmailFormItem-userInput-SUnOx`,
	"tooltip": `EmailFormItem-tooltip-sL63j`,
	"infoIcon": `EmailFormItem-infoIcon-pqcQ4`
};
export default ___CSS_LOADER_EXPORT___;
