"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTestDeliveryScheduledEmailSettingAPIMutate = exports.useUpdateScheduledEmailSettingAPIMutate = exports.useTestConnectScheduledEmailSettingAPIMutate = exports.useFetchScheduledEmailSettingAPIQuery = void 0;
var AlertMessage_1 = require("~/components/Common/AlertMessage/AlertMessage");
var api_1 = require("~/networking/api");
var constants_1 = require("~/utils/constants");
var useCustomMutation_1 = require("../useCustomMutation");
var useAuthorizedQuery_1 = require("../useAuthorizedQuery");
var converters_1 = require("./converters");
var useFetchScheduledEmailSettingAPIQuery = function (_a) {
    var _b = _a === void 0 ? {} : _a, deps = _b.deps, options = _b.options;
    return (0, useAuthorizedQuery_1.useAuthorizedQuery)({
        queryKey: constants_1.QueryKeys.scheduledEmailSetting.detail,
        deps: deps,
        options: __assign(__assign({ onError: function (err) {
                var _a;
                var errorMessage = (_a = err.response) === null || _a === void 0 ? void 0 : _a.data.detail;
                if (errorMessage) {
                    (0, AlertMessage_1.customErrorMessage)(errorMessage);
                }
            } }, options), { select: function (result) {
                return (0, converters_1.convertScheduledEmailSettingResponseModelToScheduledEmailSettingModel)(result);
            } }),
        apiRequest: api_1.scheduledEmailSettingAPI.fetchScheduledEmailSetting,
    });
};
exports.useFetchScheduledEmailSettingAPIQuery = useFetchScheduledEmailSettingAPIQuery;
var useTestConnectScheduledEmailSettingAPIMutate = function () {
    return (0, useCustomMutation_1.useCustomMutation)(api_1.scheduledEmailSettingAPI.testConnection, {
        onSuccess: function (response) {
            (0, AlertMessage_1.customSuccessMessage)(constants_1.SuccessMessages.scheduledEmailSetting.testConnect);
        },
        onError: function (err) {
            var _a;
            var errorMessage = constants_1.ErrorMessages.scheduledEmailSetting.testConnect;
            if ((_a = err.response) === null || _a === void 0 ? void 0 : _a.data.detail) {
                errorMessage = err.response.data.detail;
            }
            (0, AlertMessage_1.customErrorMessage)(errorMessage);
        },
    });
};
exports.useTestConnectScheduledEmailSettingAPIMutate = useTestConnectScheduledEmailSettingAPIMutate;
var useUpdateScheduledEmailSettingAPIMutate = function () {
    return (0, useCustomMutation_1.useCustomMutation)(api_1.scheduledEmailSettingAPI.updateScheduledEmailSetting, {
        onSuccess: function (response) {
            (0, AlertMessage_1.customSuccessMessage)(constants_1.SuccessMessages.generic.update);
        },
        onError: function (err) {
            var _a;
            var errorMessage = constants_1.ErrorMessages.generic.update;
            if ((_a = err.response) === null || _a === void 0 ? void 0 : _a.data.detail) {
                errorMessage = err.response.data.detail;
            }
            (0, AlertMessage_1.customErrorMessage)(errorMessage);
        },
    });
};
exports.useUpdateScheduledEmailSettingAPIMutate = useUpdateScheduledEmailSettingAPIMutate;
var useTestDeliveryScheduledEmailSettingAPIMutate = function () {
    return (0, useCustomMutation_1.useCustomMutation)(api_1.scheduledEmailSettingAPI.testDelivery, {
        onSuccess: function (response) {
            (0, AlertMessage_1.customSuccessMessage)(constants_1.SuccessMessages.scheduledEmailSetting.testDelivery);
        },
        onError: function (err) {
            var _a;
            var errorMessage = constants_1.ErrorMessages.scheduledEmailSetting.testConnect;
            if ((_a = err.response) === null || _a === void 0 ? void 0 : _a.data.detail) {
                errorMessage = err.response.data.detail;
            }
            (0, AlertMessage_1.customErrorMessage)(errorMessage);
        },
    });
};
exports.useTestDeliveryScheduledEmailSettingAPIMutate = useTestDeliveryScheduledEmailSettingAPIMutate;
