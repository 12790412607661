// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TagAjaxSortedSelect-tag-Mm2Iz{margin-top:3px;margin-bottom:3px;margin-inline-end:4.8px;padding-inline-start:4.8px;padding-inline-end:4px;white-space:normal}.TagAjaxSortedSelect-selectOption-yVkDB{text-align:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Common/BoardCommon/SkillSelectFormItem/TagAjaxSortedSelect/TagAjaxSortedSelect.scss"],"names":[],"mappings":"AAAA,+BACE,cAAe,CACf,iBAAkB,CAClB,uBAAwB,CACxB,0BAA2B,CAC3B,sBAAuB,CACvB,kBAAmB,CACpB,wCAGC,iBAAkB","sourcesContent":[".tag {\n  margin-top: 3px;\n  margin-bottom: 3px;\n  margin-inline-end: 4.8px;\n  padding-inline-start: 4.8px;\n  padding-inline-end: 4px;\n  white-space: normal;\n}\n\n.selectOption {\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tag": `TagAjaxSortedSelect-tag-Mm2Iz`,
	"selectOption": `TagAjaxSortedSelect-selectOption-yVkDB`
};
export default ___CSS_LOADER_EXPORT___;
