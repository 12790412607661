// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CorporateFaxFormItem-container-FvQKv{width:100%;text-align:left}.CorporateFaxFormItem-phonenumberInput-XBDL6{width:100%}.CorporateFaxFormItem-tooltip-lNU8G{margin-left:5px}.CorporateFaxFormItem-inlineField-fnTdu{display:inline-block;_display:inline}.CorporateFaxFormItem-infoIcon-fRVr9{align-self:center}.CorporateFaxFormItem-separater-EmLmP{align-self:center;text-align:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/OrganizationSearchPage/OrganizationSearchDrawer/CorporateFaxFormItem/CorporateFaxFormItem.scss"],"names":[],"mappings":"AAAA,sCACI,UAAW,CACX,eAAgB,CACnB,6CAGG,UAAW,CACd,oCAGG,eAAgB,CACnB,wCAGG,oBAAqB,EACrB,cAAgB,CACnB,qCAGG,iBAAkB,CACrB,sCAGG,iBAAkB,CAClB,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.phonenumberInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.inlineField {\n    display: inline-block;\n    _display: inline;\n}\n\n.infoIcon {\n    align-self: center;\n}\n\n.separater {\n    align-self: center;\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `CorporateFaxFormItem-container-FvQKv`,
	"phonenumberInput": `CorporateFaxFormItem-phonenumberInput-XBDL6`,
	"tooltip": `CorporateFaxFormItem-tooltip-lNU8G`,
	"inlineField": `CorporateFaxFormItem-inlineField-fnTdu`,
	"infoIcon": `CorporateFaxFormItem-infoIcon-fRVr9`,
	"separater": `CorporateFaxFormItem-separater-EmLmP`
};
export default ___CSS_LOADER_EXPORT___;
