// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PersonnelEndDateFormItem-container-OUyuY{width:100%;text-align:left}.PersonnelEndDateFormItem-userInput-JG_BX{width:100%}.PersonnelEndDateFormItem-tooltip-H8Q81{margin-left:5px}.PersonnelEndDateFormItem-infoIcon-Rs15B{align-self:center}.PersonnelEndDateFormItem-customDatePicker-EWTUy{width:100%;height:35px}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/personnel/PersonnelBoardPage/PersonnelBoardSearchDrawer/PersonnelEndDateFormItem/PersonnelEndDateFormItem.scss"],"names":[],"mappings":"AAAA,0CACE,UAAW,CACX,eAAgB,CACjB,0CAGC,UAAW,CACZ,wCAGC,eAAgB,CACjB,yCAGC,iBAAkB,CACnB,iDAGC,UAAW,CACX,WAAY","sourcesContent":[".container {\n  width: 100%;\n  text-align: left;\n}\n\n.userInput {\n  width: 100%;\n}\n\n.tooltip {\n  margin-left: 5px;\n}\n\n.infoIcon {\n  align-self: center;\n}\n\n.customDatePicker {\n  width: 100%;\n  height: 35px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PersonnelEndDateFormItem-container-OUyuY`,
	"userInput": `PersonnelEndDateFormItem-userInput-JG_BX`,
	"tooltip": `PersonnelEndDateFormItem-tooltip-H8Q81`,
	"infoIcon": `PersonnelEndDateFormItem-infoIcon-Rs15B`,
	"customDatePicker": `PersonnelEndDateFormItem-customDatePicker-EWTUy`
};
export default ___CSS_LOADER_EXPORT___;
