import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Highlighter from "react-highlight-words";
import { Button, Col, Row, Input, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { iconPrimaryColor } from "~/utils/constants";
import _ from "lodash";
import styles from "./Table.scss";

const { TextArea } = Input;

const isEmpty = (array) => array.length === 0;

const getRowSelectionProps = (
    selectedRowKeys,
    onSelectChange,
    onSelectAll
) => ({
    selectedRowKeys,
    onChange: onSelectChange,
    onSelectAll,
    renderCell: (checked, record, index, originNode) => {
        return (
            <Col key={index} span={24}>
                <Row justify="center">
                    <Col>{originNode}</Col>
                </Row>
            </Col>
        );
    },
});

const getPaginationProps = () => ({
    showTotal: (total, range) =>
        `合計${total}件中, ${range[0]}-${range[1]}を表示`,
    pageSizeOptions: ["10", "50", "100"],
    showSizeChanger: true,
});

class genericPickerTable extends Component {
    constructor(props) {
        super(props);
        this.state = { searchText: "", searchField: undefined, pageSize: 10, current: 1};
    }

    handleSearch = (selectedKeys, dataIndex, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0], searchField: dataIndex });
    };

    handleReset = (clearFilters, dataIndex, confirm) => {
        clearFilters();
        confirm();
        this.setState({ searchText: "", searchField: dataIndex });
    };

    filterOnSelectedRows = (data) => {
        const { selectedRowKeys } = this.props;
        return data.filter((x) => selectedRowKeys.includes(x.key));
    };

    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
           <div style={{ padding: 8 }}>
                <TextArea
                    autoSize={{ minRows: 1 }}
                    ref={(node) => {
                        this.searchInput = node;
                    }}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        this.handleSearch(selectedKeys, dataIndex, confirm)
                    }
                    style={{ width: 188, marginBottom: 8, display: "block" }}
                />
                <Button
                    type="primary"
                    onClick={() =>
                        this.handleSearch(selectedKeys, dataIndex, confirm)
                    }
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                    disabled={_.isEmpty(selectedKeys[0])}>
                    検索
                </Button>
                <Button
                    onClick={() =>
                        this.handleReset(clearFilters, dataIndex, confirm)
                    }
                    size="small"
                    style={{ width: 90 }}
                    disabled={_.isEmpty(selectedKeys[0])}>
                    リセット
                </Button>
            </div>
        ),

        filterIcon: (filtered) => (
            <SearchOutlined style={{ color: iconPrimaryColor }} />
        ),

        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),

        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => this.searchInput?.focus(), 100);
            }
        },

        render: (text) => {
            const { searchField, searchText } = this.state;
            if (dataIndex === searchField) {
                return (
                    <Highlighter
                        highlightStyle={{
                            backgroundColor: "#ffc069",
                            padding: 0,
                        }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text.toString()}
                    />
                );
            }
            return text;
        },
    });

    attachSearchProps(columns) {
        const { searchFields } = this.props;
        return columns.map((col) =>
            searchFields.includes(col.dataIndex)
                ? Object.assign({}, col, {
                      ...this.getColumnSearchProps(col.dataIndex),
                  })
                : col
        );
    }

    onChange = (pagination, filters, sorter, extra) => {
        const { pageSize, current } = pagination;
        const { pageSize: prevPageSize } = this.state;
        this.props.onChange(pagination, filters, sorter, extra);
        // ページサイズが変更された場合、currentPageを1にリセット
        if (pageSize !== prevPageSize) {
          this.setState({ pageSize, current: 1 });
        } else {
          this.setState({ pageSize, current });
        }
    }

    render() {
        const {
            columns,
            data,
            onRowClick,
            selectedRowKeys,
            onChange,
            onSelectChange,
            onSelectAll,
            loading,
            showSelectedRowOnly,
        } = this.props;
        const rowSelectionProps = getRowSelectionProps(
            selectedRowKeys,
            onSelectChange,
            onSelectAll
        );

        const { current } = this.state;

        const paginationProps = { ...getPaginationProps(), current };
        const filteredData = showSelectedRowOnly
            ? this.filterOnSelectedRows(data)
            : data;
        const extendedColumns = this.attachSearchProps(columns);
        return (
            <div className={styles.tableWrapper}>
                <Table
                    className={styles.table}
                    columns={extendedColumns}
                    bordered
                    dataSource={isEmpty(filteredData) ? null : filteredData}
                    hasData={!isEmpty(filteredData)}
                    pagination={paginationProps}
                    onChange={this.onChange}
                    rowSelection={rowSelectionProps}
                    onRow={(record) => ({
                        onClick: () => onRowClick(record),
                    })}
                    loading={loading}
                />
            </div>
        );
    }
}

genericPickerTable.propTypes = {
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            // A shape of AntDesign's column parameter.
            title: PropTypes.string.isRequired,
            dataIndex: PropTypes.string.isRequired,
            key: PropTypes.string.isRequired,
            render: PropTypes.func,
        })
    ).isRequired,
    searchFields: PropTypes.arrayOf(PropTypes.string),
    onRowClick: PropTypes.func,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedRowKeys: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
    ).isRequired,
    onChange: PropTypes.func.isRequired,
    onSelectChange: PropTypes.func.isRequired,
    onSelectAll: PropTypes.func,
    loading: PropTypes.bool,
    showSelectedRowOnly: PropTypes.bool,
};

genericPickerTable.defaultProps = {
    onRowClick: () => {},
    onSelectAll: () => {},
    loading: false,
    searchFields: [],
};

const WrappedGenericPickerTable = withRouter(genericPickerTable);

export default WrappedGenericPickerTable;
