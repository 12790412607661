// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BackOfficePriorityFormItem-container-QdpLo{width:100%;text-align:left}.BackOfficePriorityFormItem-userInput-MwPfz{width:100%}.BackOfficePriorityFormItem-tooltip-mlovh{margin-left:5px}.BackOfficePriorityFormItem-infoIcon-UBuaD{align-self:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/backOffice/BackOfficeGanttPage/BackOfficeGanttSearchDrawer/BackOfficePriorityFormItem/BackOfficePriorityFormItem.scss"],"names":[],"mappings":"AAAA,4CACI,UAAW,CACX,eAAgB,CACnB,4CAGG,UAAW,CACd,0CAGG,eAAgB,CACnB,2CAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `BackOfficePriorityFormItem-container-QdpLo`,
	"userInput": `BackOfficePriorityFormItem-userInput-MwPfz`,
	"tooltip": `BackOfficePriorityFormItem-tooltip-mlovh`,
	"infoIcon": `BackOfficePriorityFormItem-infoIcon-UBuaD`
};
export default ___CSS_LOADER_EXPORT___;
