// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PersonnelBoardCardContent-text-ovDJ2{display:flex;justify-content:start;padding:10px 0 0 0;font-weight:550}.PersonnelBoardCardContent-textLabel-RWw23{display:inline-block;width:3em;text-align:right}.PersonnelBoardCardContent-tag-gCmtw{margin-bottom:6px}.PersonnelBoardCardContent-tagRow-bhiMf{margin:0.5rem 0}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/personnel/PersonnelBoardPage/PersonnelBoardCard/PersonnelBoardCardContent/PersonnelBoardCardContent.scss"],"names":[],"mappings":"AAAA,sCACI,YAAa,CACb,qBAAsB,CACtB,kBAAmB,CACnB,eAAgB,CACnB,2CAGG,oBAAqB,CACrB,SAAU,CACV,gBAAiB,CACpB,qCAGG,iBAAkB,CACrB,wCAGG,eAAgB","sourcesContent":[".text {\n    display: flex;\n    justify-content: start;\n    padding: 10px 0 0 0;\n    font-weight: 550;\n}\n\n.textLabel {\n    display: inline-block;\n    width: 3em;\n    text-align: right;\n}\n\n.tag {\n    margin-bottom: 6px;\n}\n\n.tagRow {\n    margin: 0.5rem 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": `PersonnelBoardCardContent-text-ovDJ2`,
	"textLabel": `PersonnelBoardCardContent-textLabel-RWw23`,
	"tag": `PersonnelBoardCardContent-tag-gCmtw`,
	"tagRow": `PersonnelBoardCardContent-tagRow-bhiMf`
};
export default ___CSS_LOADER_EXPORT___;
