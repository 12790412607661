"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUserDisplaySettingUpdateAPIMutation = exports.useUserDisplaySettingFetchAPIQuery = void 0;
var react_query_1 = require("react-query");
var api_1 = require("~/networking/api");
var constants_1 = require("~/utils/constants");
var utils_1 = require("~/utils/utils");
var useCustomMutation_1 = require("../useCustomMutation");
var useAuthorizedQuery_1 = require("../useAuthorizedQuery");
var useUserDisplaySettingFetchAPIQuery = function (_a) {
    var _b = _a === void 0 ? {} : _a, deps = _b.deps, options = _b.options;
    return (0, useAuthorizedQuery_1.useAuthorizedQuery)({
        queryKey: constants_1.QueryKeys.userDisplaySetting,
        deps: deps,
        options: __assign(__assign({}, options), { select: function (result) { return result.content_hash; } }),
        apiRequest: api_1.userDisplaySettingAPI.fetch,
    });
};
exports.useUserDisplaySettingFetchAPIQuery = useUserDisplaySettingFetchAPIQuery;
var useUserDisplaySettingUpdateAPIMutation = function () {
    var queryClient = (0, react_query_1.useQueryClient)();
    return (0, useCustomMutation_1.useCustomMutation)(api_1.userDisplaySettingAPI.update, {
        onSuccess: function (result) {
            queryClient.invalidateQueries(constants_1.QueryKeys.userDisplaySetting);
        },
        onError: function (err) {
            (0, utils_1.apiErrorMessage)(err, constants_1.ErrorMessages.generic.update);
        },
    });
};
exports.useUserDisplaySettingUpdateAPIMutation = useUserDisplaySettingUpdateAPIMutation;
