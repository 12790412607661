import { AUTHORIZED_ACTIONS_DEFAULT_VALUE } from "~/utils/constants";
import { UPDATE_AVATAR_AFTER_CHANGE_MYPROFILE } from "../actions/actionTypes";
import { isEmpty } from "~/utils/utils";
import {
    LOGIN,
    LOGOUT,
    LOGIN_ERROR,
    AUTHORIZED_ACTION_LOADED,
    LOGGING_OUT,
} from "../actions/actionTypes";

export const LoginInitialState = {
    // An initial state.
    token: "",
    userId: undefined,
    displayName: "N/A",
    isAdminUser: false,
    avatar: "",
    message: "",
    role: "",
    authorizedActions: AUTHORIZED_ACTIONS_DEFAULT_VALUE,
    email: "",
    createdAt: "",
    threeLogin: 0,
    isLoggingOut: false,
    error: undefined,
    intercomUserHash: "",
};

const login = (
    state = LoginInitialState,
    action // Define the state transitions of the store.login
) => {
    switch (action.type) {
        case LOGIN:
            // NOTE(joshua-hashimoto): 渡された値の権限情報が不正であれば、デフォルト値を入れる。本来値が不正の状態でこれが呼び出されることはないと思うが、念の為
            let incomingLoginAuthorizedActions =
                action.payload.authorizedActions;
            if (
                !incomingLoginAuthorizedActions ||
                isEmpty(incomingLoginAuthorizedActions)
            ) {
                incomingLoginAuthorizedActions =
                    AUTHORIZED_ACTIONS_DEFAULT_VALUE;
            }
            return {
                ...state,
                token: action.payload.token,
                userId: action.payload.userId,
                displayName: action.payload.displayName,
                isAdminUser: action.payload.isUserAdmin,
                avatar: action.payload.avatar,
                message: "",
                role: action.payload.role,
                authorizedActions: incomingLoginAuthorizedActions,
                intercomUserHash: action.payload.intercomUserHash,
                threeLogin: 0,
                isLoggingOut: false,
            };
        case LOGGING_OUT:
            return {
                ...state,
                isLoggingOut: true,
            };
        case LOGOUT:
            return Object.assign({}, state, LoginInitialState);
        case LOGIN_ERROR:
            return Object.assign({}, state, {
                threeLogin: (action.payload?.threeLogin ?? 0) + 1,
                message: action.payload?.error?.detail,
                isLoggingOut: false,
            });
        case AUTHORIZED_ACTION_LOADED:
            // NOTE(joshua-hashimoto): 渡された値の権限情報が不正であれば、デフォルト値を入れる。本来値が不正の状態でこれが呼び出されることはないと思うが、念の為
            let incomingAuthorizedActions = action.payload.authorizedActions;
            if (
                !incomingAuthorizedActions ||
                isEmpty(incomingAuthorizedActions)
            ) {
                incomingAuthorizedActions = AUTHORIZED_ACTIONS_DEFAULT_VALUE;
            }
            return {
                ...state,
                authorizedActions: incomingAuthorizedActions,
            };
        case UPDATE_AVATAR_AFTER_CHANGE_MYPROFILE:
            return {
                ...state,
                avatar: action.payload.avatar,
            };
        default:
            return state;
    }
};

export default login;
