import React, { useState } from "react";
import { Col, Form, Select, Row, Tooltip } from "antd";
import { QuestionCircleFilled } from "@ant-design/icons";
import { Links, iconCustomColor } from "~/utils/constants";
import styles from "./CorporateSettlementMonthFormItem.scss";

const defaultSettlementMonth = [
    { value: 1, title: "1月" },
    { value: 2, title: "2月" },
    { value: 3, title: "3月" },
    { value: 4, title: "4月" },
    { value: 5, title: "5月" },
    { value: 6, title: "6月" },
    { value: 7, title: "7月" },
    { value: 8, title: "8月" },
    { value: 9, title: "9月" },
    { value: 10, title: "10月" },
    { value: 11, title: "11月" },
    { value: 12, title: "12月" },
];

const CorporateSettlementMonthFormItem = ({ disabled = false }) => {
    const [items, setItems] = useState(defaultSettlementMonth);

    const onSearch = (value) => {
        const newItems = defaultSettlementMonth.filter((item) => item.title.includes(value));
        setItems(newItems);
    };

    const onPopupVisibleChange = (open) => {
        if (!open) {
            setItems(defaultSettlementMonth);
        }
    };

    return (
        <Col span={24} style={{ marginBottom: "1%" }}>
            <Row className={styles.container}>
                <Col span={23}>
                    <Form.Item colon={false} name="settlement_month" noStyle>
                        <Select
                            showSearch
                            filterOption={false}
                            onSearch={onSearch}
                            onDropdownVisibleChange={onPopupVisibleChange}
                            className={styles.container}
                            placeholder="決算期"
                            mode="multiple"
                            allowClear
                            disabled={disabled}
                            getPopupContainer={(trigger) => trigger.parentNode}>
                            {items.map((item) => {
                                return (
                                    <Select.Option
                                        key={item.value}
                                        className={styles.select_option}
                                        value={item.value}>
                                        {item.title}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={1} className={styles.infoIcon}>
                    <Tooltip
                        title={
                            <span>
                                複数選択をすると
                                <a
                                    href={Links.helps.filter.or}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    OR検索
                                </a>
                            </span>
                        }>
                        <QuestionCircleFilled style={{ color: iconCustomColor }} className={styles.tooltip} />
                    </Tooltip>
                </Col>
            </Row>
        </Col>
    );
};

export default CorporateSettlementMonthFormItem;
