// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PerBIncludeArchiveSwitchFromItem-container-ZRnN2{width:100%;text-align:left}.PerBIncludeArchiveSwitchFromItem-userInput-Bysw9{width:100%}.PerBIncludeArchiveSwitchFromItem-tooltip-EDvbo{margin-left:5px}.PerBIncludeArchiveSwitchFromItem-infoIcon-XrTTm{align-self:center}.PerBIncludeArchiveSwitchFromItem-switchLabel-nsN33{display:inline-block;align-self:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/personnel/PersonnelBoardPage/PersonnelBoardSearchDrawer/PerBIncludeArchiveSwitchFromItem/PerBIncludeArchiveSwitchFromItem.scss"],"names":[],"mappings":"AAAA,kDACI,UAAW,CACX,eAAgB,CACnB,kDAGG,UAAW,CACd,gDAGG,eAAgB,CACnB,iDAGG,iBAAkB,CACrB,oDAGG,oBAAqB,CACrB,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n\n.switchLabel {\n    display: inline-block;\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PerBIncludeArchiveSwitchFromItem-container-ZRnN2`,
	"userInput": `PerBIncludeArchiveSwitchFromItem-userInput-Bysw9`,
	"tooltip": `PerBIncludeArchiveSwitchFromItem-tooltip-EDvbo`,
	"infoIcon": `PerBIncludeArchiveSwitchFromItem-infoIcon-XrTTm`,
	"switchLabel": `PerBIncludeArchiveSwitchFromItem-switchLabel-nsN33`
};
export default ___CSS_LOADER_EXPORT___;
