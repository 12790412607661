"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScheduleMailAction = void 0;
var actionTypes_1 = require("~/actions/actionTypes");
exports.ScheduleMailAction = {
    creatingTemplateAction: function (pageId) {
        return {
            type: pageId + actionTypes_1.SCHEDULE_TEMPLATE_CREATING,
            payload: undefined
        };
    },
    createdTemplateAction: function (pageId, payload) {
        return {
            type: pageId + actionTypes_1.SCHEDULE_TEMPLATE_CREATED,
            payload: payload
        };
    },
    committedTemplateAction: function (pageId) {
        return {
            type: pageId + actionTypes_1.SCHEDULE_TEMPLATE_COMMITTED,
            payload: undefined
        };
    },
};
