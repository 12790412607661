"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePersonnelBordUpdateListOrderAPIMutation = exports.usePersonnelBoardUpdateListTitleAPIMutation = exports.usePersonnelBoardDeleteListAPIMutation = exports.usePersonnelBoardCreateListAPIMutation = exports.usePersonnelBoardFetchAllListCardsAPIQuery = exports.usePersonnelBoardFetchListCardsAPIQuery = exports.usePersonnelBoardFetchListsAPIQuery = void 0;
var react_query_1 = require("react-query");
var useClient_1 = require("~/hooks/useClient");
var useAuthorizedQuery_1 = require("~/hooks/useAuthorizedQuery");
var api_1 = require("~/networking/api");
var constants_1 = require("~/utils/constants");
var useCustomMutation_1 = require("~/hooks/useCustomMutation");
// import {moveListWithBoardOriginalParams} from "~/hooks/personnel/board";
var AlertMessage_1 = require("~/components/Common/AlertMessage/AlertMessage");
var utils_1 = require("~/utils/utils");
var usePersonnelBoardFetchListsAPIQuery = function (_a) {
    var _b = _a === void 0 ? {} : _a, deps = _b.deps, options = _b.options;
    return (0, useAuthorizedQuery_1.useAuthorizedQuery)({
        queryKey: constants_1.QueryKeys.personnelBoard.lists,
        deps: deps,
        options: __assign({}, options),
        apiRequest: api_1.personnelAPI.board.fetchLists,
    });
};
exports.usePersonnelBoardFetchListsAPIQuery = usePersonnelBoardFetchListsAPIQuery;
var usePersonnelBoardFetchListCardsAPIQuery = function (_a) {
    var deps = _a.deps, options = _a.options;
    var getClientAuthorization = (0, useClient_1.useClient)().getClientAuthorization;
    return (0, useAuthorizedQuery_1.useAuthorizedQuery)({
        queryKey: constants_1.QueryKeys.personnelBoard.cards,
        deps: deps,
        options: __assign(__assign({}, options), { enabled: !!(deps === null || deps === void 0 ? void 0 : deps.listId) && !!getClientAuthorization() }),
        apiRequest: function (query) {
            return api_1.personnelAPI.board.fetchListCards(query === null || query === void 0 ? void 0 : query.listId);
        },
    });
};
exports.usePersonnelBoardFetchListCardsAPIQuery = usePersonnelBoardFetchListCardsAPIQuery;
var usePersonnelBoardFetchAllListCardsAPIQuery = function (_a) {
    var _b = _a === void 0 ? {} : _a, deps = _b.deps, options = _b.options;
    return (0, useAuthorizedQuery_1.useAuthorizedQuery)({
        queryKey: constants_1.QueryKeys.personnel.board.allListCards,
        deps: deps,
        options: __assign({}, options),
        apiRequest: api_1.personnelAPI.board.fetchAllListCards,
    });
};
exports.usePersonnelBoardFetchAllListCardsAPIQuery = usePersonnelBoardFetchAllListCardsAPIQuery;
var usePersonnelBoardCreateListAPIMutation = function () {
    var queryClient = (0, react_query_1.useQueryClient)();
    return (0, useCustomMutation_1.useCustomMutation)(api_1.personnelAPI.board.createList, {
        onSuccess: function (_response) {
            queryClient.removeQueries({ queryKey: [constants_1.QueryKeys.personnel.board.allListCards], inactive: true });
            queryClient.invalidateQueries(constants_1.QueryKeys.personnel.board.allListCards);
            (0, AlertMessage_1.customSuccessMessage)(constants_1.SuccessMessages.generic.create);
        },
        onError: (function (error) {
            (0, utils_1.apiErrorMessage)(error, constants_1.ErrorMessages.generic.create);
        }),
    });
};
exports.usePersonnelBoardCreateListAPIMutation = usePersonnelBoardCreateListAPIMutation;
var usePersonnelBoardDeleteListAPIMutation = function () {
    var queryClient = (0, react_query_1.useQueryClient)();
    return (0, useCustomMutation_1.useCustomMutation)(api_1.personnelAPI.board.deleteList, {
        onSuccess: function (_response) {
            queryClient.removeQueries({ queryKey: [constants_1.QueryKeys.personnel.board.allListCards], inactive: true });
            queryClient.invalidateQueries(constants_1.QueryKeys.personnel.board.allListCards);
            (0, AlertMessage_1.customSuccessMessage)(constants_1.SuccessMessages.generic.delete);
        },
        onError: (function (error) {
            (0, utils_1.apiErrorMessage)(error, constants_1.ErrorMessages.generic.delete);
        }),
    });
};
exports.usePersonnelBoardDeleteListAPIMutation = usePersonnelBoardDeleteListAPIMutation;
var usePersonnelBoardUpdateListTitleAPIMutation = function () {
    var queryClient = (0, react_query_1.useQueryClient)();
    var apiRequest = function (_a) {
        var listId = _a.listId, title = _a.title;
        return api_1.personnelAPI.board.updateListTitle(listId, title);
    };
    return (0, useCustomMutation_1.useCustomMutation)(apiRequest, {
        onMutate: function (_a) {
            var listId = _a.listId, title = _a.title;
            var oldData = queryClient.getQueryData(constants_1.QueryKeys.personnel.board.allListCards);
            queryClient.setQueriesData({
                queryKey: constants_1.QueryKeys.personnel.board.allListCards,
                exact: false,
            }, function (oldData) {
                if (!(oldData === null || oldData === void 0 ? void 0 : oldData.results)) {
                    return oldData;
                }
                var updatedBoardData = JSON.parse(JSON.stringify({ columns: oldData.results }));
                var updatedColumn = updatedBoardData.columns.find(function (col) { return col.id === listId; });
                if (updatedColumn) {
                    updatedColumn.title = title;
                }
                return __assign(__assign({}, oldData), { results: updatedBoardData.columns });
            });
            return { oldData: oldData };
        },
        onError: (function (error) {
            (0, utils_1.apiErrorMessage)(error, constants_1.ErrorMessages.generic.update);
        }),
    });
};
exports.usePersonnelBoardUpdateListTitleAPIMutation = usePersonnelBoardUpdateListTitleAPIMutation;
var usePersonnelBordUpdateListOrderAPIMutation = function () {
    var queryClient = (0, react_query_1.useQueryClient)();
    var apiRequest = function (_a) {
        var listId = _a.listId, to = _a.to;
        return api_1.personnelAPI.board.changeListPosition(listId, to.toPosition);
    };
    return (0, useCustomMutation_1.useCustomMutation)(apiRequest, {
        onSuccess: function () {
            queryClient.removeQueries({ queryKey: [constants_1.QueryKeys.personnel.board.allListCards], inactive: true });
            queryClient.invalidateQueries(constants_1.QueryKeys.personnel.board.allListCards);
        },
        // 新規作成したリストの order を変更後、画面遷移し戻ってくると、変更した先に index が何故か欠損する(undefinedになる)為 cache を飛ばして対応する。
        // MEMO: 欠損する根本原因は余裕ある際に調査したい
        // FYI: https://trello.com/c/QFvuENev
        // onMutate: ({from, to}) => {
        //     const oldData: AllListCardResponse = queryClient.getQueryData(QueryKeys.personnel.board.allListCards);
        //     queryClient.setQueriesData<AllListCardResponse>(
        //         {
        //             queryKey: QueryKeys.personnel.board.allListCards,
        //             exact: false,
        //         },
        //         (oldData) => {
        //             if (!oldData?.results) {
        //                 return oldData;
        //             }
        //             const updatedBoardData = moveListWithBoardOriginalParams({columns: oldData.results}, from, to);
        //             console.log(updatedBoardData)
        //             return {
        //                 ...oldData,
        //                 results: updatedBoardData.columns,
        //             };
        //         }
        //     );
        //     return {oldData};
        // },
        onError: function (_err, _params, context) {
            return void queryClient.setQueriesData({ queryKey: constants_1.QueryKeys.personnel.board.allListCards, exact: false, }, function () { return context === null || context === void 0 ? void 0 : context.oldData; });
        },
    });
};
exports.usePersonnelBordUpdateListOrderAPIMutation = usePersonnelBordUpdateListOrderAPIMutation;
