"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useLimits = void 0;
var constants_1 = require("~/utils/constants");
var useLimits = function () {
    return {
        checklist: constants_1.Limits.checklist,
        checklistItem: constants_1.Limits.checklistItem,
    };
};
exports.useLimits = useLimits;
