import React from "react";
import PropTypes from "prop-types";
import { Space, Skeleton, Tooltip, Col, Row, Rate } from "antd";
import { FileTextTwoTone, MailTwoTone } from "@ant-design/icons";
import Path from "~/components/Routes/Paths";
import GenericTable from "~/components/Tables/GenericTable";
import _ from "lodash";
import {
    iconPrimaryColor,
    LIST_COMMENT_VIEW_LIMIT_COUNT,
} from "~/utils/constants";
import moment from "moment";
import CustomPopover from "~/components/Common/CustomPopover/CustomPopover";
import { useSelector } from "react-redux";
import ListCommentsPopover from "~/components/Common/ListCommentsPopover/ListCommentsPopover";
import { useRouter } from "~/hooks/router";
import styles from "./SharedEmailsTable.scss";

const renderSharedEmailHistories = (record, columnIndex) => {
    const histories = record.histories;
    if (!histories || !histories.length) {
        return undefined;
    }
    const convertedHistories = histories.map((history, history_idx) => {
        const transferDate = moment(history.transfer_date).format(
            "YYYY/MM/DD HH:mm"
        );
        const receiverName = history.receiver;
        return (
            <React.Fragment key={columnIndex + "_" + history_idx}>
                <p style={{ color: "#ffffff" }}>
                    {transferDate} {receiverName}
                </p>
            </React.Fragment>
        );
    });

    const title = (
        <span style={{ color: "#ffffff" }}>
            転送履歴(最新の{LIST_COMMENT_VIEW_LIMIT_COUNT}
            件のみ表示)
        </span>
    );

    return (
        <CustomPopover content={convertedHistories} title={title}>
            <MailTwoTone twoToneColor={iconPrimaryColor} />
        </CustomPopover>
    );
};

const renderSharedEmailComments = (record, columnIndex) => {
    return (
        <ListCommentsPopover
            comments={record.comments}
            columnIndex={columnIndex}
        />
    );
};

const sharedMailTable = (props) => {
    const { loading } = props;
    const router = useRouter();

    const onRowClick = (record) => {};

    const onColumnClick = (record) => {
        const url = `${Path.sharedMails}/${record.id}`;
        router.push(url);
    };

    const isDisplaySettingLoading = useSelector(
        (state) => state.displaySettingPage.isDisplaySettingLoading
    );

    const { displaySetting } = useSelector((state) => state.displaySettingPage);

    const onAttachmentClick = (attachments, record) => {
        const url = `${Path.sharedMails}/${record.id}/attachement`;
        router.openNewTab(url);
    };

    const renderEmailAttachments = (attachments) => {
        if (attachments.length) {
            return (
                <div>
                    {attachments.map((attachment) => (
                        <Tooltip key={attachment.id} title={attachment.name}>
                            <span onClick={onAttachmentClick}>
                                <FileTextTwoTone
                                    twoToneColor={iconPrimaryColor}
                                />
                            </span>
                        </Tooltip>
                    ))}
                </div>
            );
        }
        return <span />;
    };

    const renderScore = (score) => {
        return(
            <div>
                <Rate value={score} disabled style={{ fontSize: 11 }}/>
            </div>
        )
    }

    const onMinify = {
        renderTitle: (item) => (
            <div>
                <span>
                    {renderEmailAttachments(item.has_attachments)}
                    {item.sender}
                </span>
                <p>{`@ ${item.sentDate}`}</p>
            </div>
        ),
        renderDescription: (item) => `${item.subject}`,
    };

    const columns = [
        {
            title: "差出人",
            dataIndex: "from_name",
            key: "from_name",
            width: 160,
            render: (_, record, columnIndex) => {
                const senderName = `${record.sender}　(${record.email})`;
                return (
                    <Row>
                        <Col className={styles.itemSpacing}>{senderName}</Col>
                        <Col className={styles.itemSpacing}>{renderSharedEmailComments(record, columnIndex)}</Col>
                        <Col className={styles.itemSpacing}>{renderSharedEmailHistories(record, columnIndex)}</Col>
                    </Row>
                );
            },
        },
        {
            title: "件名",
            dataIndex: "subject",
            key: "subject",
            width: 220,
            render: (text) => _.truncate(text, { length: 96 }),
        },
        {
            title: "添付",
            dataIndex: "attachments",
            key: "attachments",
            width: 70,
            render: (attachments, record) => {
                return renderEmailAttachments(attachments, record);
            },
        },
        {
            title: "取引先評価",
            dataIndex: "score",
            key: "score",
            width: 100,
            render: (score) => {
                return renderScore(score);
            },
        },
        {
            title: "自社担当者",
            dataIndex: "staff_in_charge__last_name",
            key: "staff_in_charge__last_name",
            width: 160,
            sorter: true,
        },
        {
            title: "受信日時",
            dataIndex: "sent_date",
            key: "sent_date",
            width: 160,
            sorter: true,
        },
    ];

    return (
        <>
            {isDisplaySettingLoading ? (
                <Space>
                    <Skeleton.Input
                        style={{ height: 200, width: 1200 }}
                        active
                        size="large"
                    />
                </Space>
            ) : (
                <GenericTable
                    {...props}
                    columns={columns}
                    onMinify={onMinify}
                    onRowClick={onRowClick}
                    onColumnClick={onColumnClick}
                    loading={loading}
                    withSelection={true}
                    pageSize={displaySetting?.shared_emails?.page_size ?? 10}
                />
            )}
        </>
    );
};

sharedMailTable.propTypes = {
    currentPage: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    onPageSizeChange: PropTypes.func.isRequired,
    onTableChange: PropTypes.func.isRequired,
    onCheckColumn: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    data: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.number.isRequired,
            id: PropTypes.string.isRequired,
            sender: PropTypes.string,
            email: PropTypes.string.isRequired,
            subject: PropTypes.string.isRequired,
            text: PropTypes.string, // Some email will have no text.
            has_attachments: PropTypes.bool.isRequired,
            category: PropTypes.string,
            sentDate: PropTypes.string.isRequired,
        })
    ),
};

sharedMailTable.defaultProps = {
    loading: false,
    data: [],
};

export default sharedMailTable;
