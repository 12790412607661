// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TableDisplaySettingForm-transfer-BvF7v span{white-space:initial !important;text-overflow:initial !important}.TableDisplaySettingForm-transfer-BvF7v .ant-transfer-list-header-dropdown{display:none !important}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Forms/DisplaySettingsForms/TableDisplaySettingForm/TableDisplaySettingForm.scss"],"names":[],"mappings":"AAAA,6CAEQ,8BAA8B,CAC9B,gCAAgC,CAHxC,2EAOQ,uBAAwB","sourcesContent":[".transfer {\n    span {\n        white-space: initial!important;\n        text-overflow: initial!important;\n    }\n\n    :global(.ant-transfer-list-header-dropdown){\n        display: none !important;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"transfer": `TableDisplaySettingForm-transfer-BvF7v`
};
export default ___CSS_LOADER_EXPORT___;
