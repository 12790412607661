// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RecruitStartDateFormItem-container-iLg5a{width:100%;text-align:left}.RecruitStartDateFormItem-userInput-ryeSX{width:100%}.RecruitStartDateFormItem-tooltip-F7gYp{margin-left:5px}.RecruitStartDateFormItem-infoIcon-kMFY2{align-self:center}.RecruitStartDateFormItem-customDatePicker-hkId_{width:100%;height:35px}.RecruitStartDateFormItem-marginBottom-SGKEy{margin-bottom:1%}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/recruit/RecruitBoardPage/RecruitBoardSearchDrawer/RecruitStartDateFormItem/RecruitStartDateFormItem.scss"],"names":[],"mappings":"AAAA,0CACE,UAAW,CACX,eAAgB,CACjB,0CAGC,UAAW,CACZ,wCAGC,eAAgB,CACjB,yCAGC,iBAAkB,CACnB,iDAGC,UAAW,CACX,WAAY,CACb,6CAGC,gBAAiB","sourcesContent":[".container {\n  width: 100%;\n  text-align: left;\n}\n\n.userInput {\n  width: 100%;\n}\n\n.tooltip {\n  margin-left: 5px;\n}\n\n.infoIcon {\n  align-self: center;\n}\n\n.customDatePicker {\n  width: 100%;\n  height: 35px;\n}\n\n.marginBottom {\n  margin-bottom: 1%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `RecruitStartDateFormItem-container-iLg5a`,
	"userInput": `RecruitStartDateFormItem-userInput-ryeSX`,
	"tooltip": `RecruitStartDateFormItem-tooltip-F7gYp`,
	"infoIcon": `RecruitStartDateFormItem-infoIcon-kMFY2`,
	"customDatePicker": `RecruitStartDateFormItem-customDatePicker-hkId_`,
	"marginBottom": `RecruitStartDateFormItem-marginBottom-SGKEy`
};
export default ___CSS_LOADER_EXPORT___;
