import React from "react";
import PropTypes from "prop-types";
import {
    Button,
    Col,
    Form,
    Tabs,
    Tooltip,
    Input,
    Row,
    Radio,
    Typography,
    InputNumber,
} from "antd";
import { QuestionCircleFilled, InfoCircleTwoTone } from "@ant-design/icons";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { LOADING, CANCELED } from "~/actions/actionTypes";
import { simpleAction } from "~/actions/data";
import { Endpoint } from "~/domain/api";
import {
    customSuccessMessage,
    customErrorMessage,
} from "~/components/Common/AlertMessage/AlertMessage";
import BackButton from "~/components/Common/BackButton/BackButton";
import BaseForm from "~/components/Forms/base/BaseForm";
import { finalized } from "~/components/Forms/helpers";
import validateJapaneseMessages from "~/components/Forms/validateMessages";
import { confirmModal } from "~/components/Modals/ConfirmModal";
import Path from "~/components/Routes/Paths";
import {
    ErrorMessages,
    infoColor,
    iconCustomColor,
    EMAIL_HOST_BLOCKED,
    HelpMessages,
    ONLY_HANKAKU_REGEX,
    RESTRICT_SPACE_REGEX,
    HANKAKU_NUMBER_REGEX,
} from "~/utils/constants";
import { Links } from "~/utils/constants";
import WarningMessages from "../WarningMessages";
import PopWarningMessage from "./PopWarningMessage";
import RelayFolderFormItem from "./RelayFolderFormItem";
import { isFormDisabled } from "~/utils/utils";
import AccountNameInput from "./AccountNameInput/AccountNameInput";
import styles from "./SharedEmailSettingForm.scss";

const { TextArea } = Input;
const { TabPane } = Tabs;

const warningToolTipMessage =
    "社名変更におけるメールアドレス変更などはお問い合わせください。";

const addonTooltipMessage =
    "アドオンを追加／削除することで自動で切り替わります。";
const relayFolderWarningMessage =
    "転送先フォルダーを指定しない場合、マッチングメール一覧に取り込まれたメールは他のメーラーでは受信がされなくなります。";

const containerLayout = {
    xs: 24,
    sm: 24,
    md: 21,
    lg: 18,
    xl: 15,
    xxl: 12,
};

const sectionLayoutLabelSpan = 6;
const sectionLayout = {
    labelCol: {
        span: sectionLayoutLabelSpan,
    },
    wrapperCol: {
        span: 24 - sectionLayoutLabelSpan,
    },
};

const formLayoutLabelSpan = 7;
const formLayout = {
    labelCol: {
        span: formLayoutLabelSpan,
    },
    wrapperCol: {
        span: 24 - formLayoutLabelSpan,
    },
};

const detailTabFormLayoutLabelSpan = 10;
const detailTabFormLayout = {
    labelCol: {
        span: detailTabFormLayoutLabelSpan,
    },
    wrapperCol: {
        span: 24 - detailTabFormLayoutLabelSpan,
    },
};

const PortNumberMapping = {
    imap: 143,
    pop3: 110,
    "imap+ssl": 993,
    "pop3+ssl": 995,
};

const REQUIRED_FIELDS = [
    "account_email_address",
    "username",
    "hostname",
    "password",
];

class SharedEmailSettingForm extends BaseForm {
    constructor(props) {
        super(props);
        this.initialViewRendered = false;
    }

    state = {
        isDomainSwitchOn: true,
        isConnectionTestSuccessed: false,
        isEdit: false,
        connectionTestFieldErrors: undefined,
        isTestHostSuccess: false,
        portDefault: 143,
        initialArchive: undefined,
        hasArchiveError: false,
    };

    componentDidUpdate() {
        const { initialData } = this.props;
        const { setFieldsValue } = this.baseform.current;
        if (!this.initialViewRendered && initialData && initialData.username) {
            const protocol = initialData.protocol;
            const initialFormData = {
                ...initialData,
                port_number: protocol ? PortNumberMapping[protocol] : 143,
            };
            setFieldsValue(initialFormData);
            this.setState({
                isDomainSwitchOn: initialData.allow_self_domain,
                isConnectionTestSuccessed: false, // 再度接続テスト行わせる
                isEdit: true,
                initialArchive: initialData.archive,
            });
            this.initialViewRendered = true;
        }
    }

    onClickConnectionTestButton = () => {
        const { dispatch, token, pageId } = this.props;
        const { getFieldValue } = this.baseform.current;
        const data = {
            username: getFieldValue("username"),
            hostname: getFieldValue("hostname"),
            port_number: getFieldValue("port_number"),
            password: getFieldValue("password"),
            protocol: getFieldValue("protocol"),
            archive: getFieldValue("archive"),
        };
        dispatch({ type: pageId + LOADING });
        simpleAction(
            pageId,
            token,
            `${Endpoint.getBaseUrl()}/${Endpoint.sharedEmailConnection}`,
            data
        )
            .then(this.onConnectionTestSuccessed)
            .catch((error) => {
                this.setState({
                    connectionTestFieldErrors: error.message,
                });
                this.onConnectionTestFailed();
            });
    };

    onConnectionTestSuccessed = () => {
        const { dispatch, pageId } = this.props;
        customSuccessMessage(
            "接続テストに成功しました。登録／更新ボタンをクリックして設定を保存してください。"
        );
        this.setState({
            isConnectionTestSuccessed: true,
            connectionTestFieldErrors: undefined,
        });
        dispatch({ type: pageId + CANCELED });
    };

    onConnectionTestFailed = () => {
        const { dispatch, pageId } = this.props;
        if (this.state.connectionTestFieldErrors) {
            customErrorMessage(this.state.connectionTestFieldErrors);
        } else {
            customErrorMessage(ErrorMessages.sharedEmails.connectionTest);
        }
        dispatch({ type: pageId + CANCELED });
    };

    onChangeConnectionValue = (e) => {
        const value = e.target.value;
        const { setFieldsValue } = this.baseform.current;
        this.setState({ isConnectionTestSuccessed: false });

        if (
            value === "imap" ||
            value === "pop3" ||
            value === "imap+ssl" ||
            value === "pop3+ssl"
        ) {
            setFieldsValue({
                port_number: PortNumberMapping[value],
            });
        }
    };

    renderConfirmRegisterContent = () => {
        return (
            <div className={styles.confirmRegisterContentContainer}>
                <p>入力した内容で登録を行います。</p>
                <p>一度登録されると本画面からは変更できません。</p>
                <p>変更が必要な場合は、お問い合わせください。</p>
            </div>
        );
    };

    onClickRegisterButton = () => {
        confirmModal({
            title: (
                <Typography.Text strong>
                    {" "}
                    マッチングメールアドレス登録{" "}
                </Typography.Text>
            ),
            content: this.renderConfirmRegisterContent(),
            onOk: this.onConfirmOk,
        });
    };

    onConfirmOk = () => {
        this.handleSubmit();
    };

    tooltipMessage = (value) => {
        if (value === "sharedMails") {
            return (
                <Link
                    to={`${Path.sharedMails}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    マッチングメール一覧
                </Link>
            );
        } else if (value === "scheduledMails") {
            return (
                <Link
                    to={`${Path.scheduledMails}/register`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    配信メール予約
                </Link>
            );
        }
    };

    render() {
        const {
            isConnectionTestSuccessed,
            isEdit,
            connectionTestFieldErrors,
            isTestHostSuccess,
            hasArchiveError,
        } = this.state;

        const { fieldErrors, initialData } = this.props;

        return (
            <Row justify="start">
                <Col {...containerLayout}>
                    <Form
                        onFinish={this.handleSubmit}
                        ref={this.baseform}
                        validateMessages={validateJapaneseMessages}
                        style={{ textAlign: "left" }}
                        labelAlign="right"
                        onValuesChange={(changedValues, allValues) => {
                            const form = this.baseform.current;
                            const protocol = changedValues.protocol;
                            if (
                                !!form &&
                                !!protocol &&
                                protocol.includes("imap")
                            ) {
                                form.setFieldsValue({
                                    archive: this.state.initialArchive,
                                });
                            } else {
                                if (this.state.hasArchiveError) {
                                    this.setState({
                                        hasArchiveError: false,
                                    });
                                }
                            }
                        }}
                    >
                        <Tabs
                            defaultActiveKey="1"
                            tabBarExtraContent={
                                <Tooltip
                                    title={warningToolTipMessage}
                                >
                                    <InfoCircleTwoTone
                                        twoToneColor={infoColor}
                                    />
                                </Tooltip>
                            }
                        >
                            <TabPane tab="基本設定" key="1">
                                <Col span={24}>
                                    <Row justify="center">
                                        <Col span={24} key="col1Span19">
                                            <WarningMessages />
                                        </Col>
                                        <Col span={22}>
                                            <Form.Item
                                                {...sectionLayout}
                                                label="受信サーバーの設定"
                                                style={{
                                                    padding: 0,
                                                    margin: 0,
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <AccountNameInput
                                    formName={"account_email_address"}
                                    formProps={{
                                        ...formLayout,
                                        className: styles.field,
                                    }}
                                    onChange={this.onChangeConnectionValue}
                                />
                                <Form.Item
                                    {...formLayout}
                                    label={
                                        <span>
                                            ユーザー名&nbsp;
                                            <Tooltip
                                                overlayStyle={{
                                                    minWidth: "360px",
                                                }}
                                                title={
                                                    <span>
                                                        受信サーバーのユーザー名を入力します。
                                                        <br />
                                                        ユーザー名はご使用のサーバーにより形式が異なります。
                                                        <br />
                                                        例：「you」または「you@example.com」
                                                    </span>
                                                }
                                            >
                                                <QuestionCircleFilled
                                                    style={{
                                                        color: iconCustomColor,
                                                    }}
                                                />
                                            </Tooltip>
                                        </span>
                                    }
                                    className={styles.field}
                                    validateStatus={
                                        fieldErrors.username ||
                                        (connectionTestFieldErrors &&
                                            connectionTestFieldErrors[
                                                "username"
                                            ])
                                            ? "error"
                                            : undefined
                                    }
                                    help={
                                        fieldErrors.username ||
                                        (connectionTestFieldErrors &&
                                            connectionTestFieldErrors[
                                                "username"
                                            ])
                                    }
                                    rules={[
                                        {
                                            pattern: ONLY_HANKAKU_REGEX,
                                            message:
                                                ErrorMessages.validation.regex
                                                    .onlyHankaku,
                                        },
                                        {
                                            pattern: RESTRICT_SPACE_REGEX,
                                            message:
                                                ErrorMessages.validation.regex
                                                    .space,
                                        },
                                        {
                                            max: 50,
                                            message:
                                                ErrorMessages.validation.length
                                                    .max50,
                                        },
                                        {
                                            required: true,
                                        },
                                    ]}
                                    name="username"
                                >
                                    <TextArea
                                        autoSize={{ minRows: 1 }}
                                        placeholder="you／you@example.com"
                                        onChange={this.onChangeConnectionValue}
                                        disabled={isEdit}
                                    />
                                </Form.Item>
                                <Form.Item
                                    {...formLayout}
                                    label={
                                        <span>
                                            サーバー名&nbsp;
                                            <Tooltip
                                                title={
                                                    <span>
                                                        受信サーバーのサーバー名（ドメイン）を入力します。
                                                        <br />
                                                        例：「example.com」
                                                    </span>
                                                }
                                            >
                                                <QuestionCircleFilled
                                                    style={{
                                                        color: iconCustomColor,
                                                    }}
                                                    className={styles.tooltip}
                                                />
                                            </Tooltip>
                                        </span>
                                    }
                                    className={styles.field}
                                    validateStatus={
                                        fieldErrors?.hostname
                                            ? "error"
                                            : undefined
                                    }
                                    help={fieldErrors?.hostname}
                                    rules={[
                                        {
                                            pattern: ONLY_HANKAKU_REGEX,
                                            message:
                                                ErrorMessages.validation.regex
                                                    .onlyHankaku,
                                        },
                                        {
                                            pattern: RESTRICT_SPACE_REGEX,
                                            message:
                                                ErrorMessages.validation.regex
                                                    .space,
                                        },
                                        {
                                            max: 50,
                                            message:
                                                ErrorMessages.validation.length
                                                    .max50,
                                        },
                                        {
                                            required: true,
                                        },
                                        {
                                            validator: (_, hostname) => {
                                                if (
                                                    EMAIL_HOST_BLOCKED.includes(
                                                        hostname
                                                    )
                                                ) {
                                                    const errorMessage =
                                                        hostname +
                                                        HelpMessages.cannotUsePrefix;
                                                    return Promise.reject(
                                                        new Error(errorMessage)
                                                    );
                                                }
                                                return Promise.resolve();
                                            },
                                        },
                                    ]}
                                    name="hostname"
                                >
                                    <TextArea
                                        onChange={this.onChangeConnectionValue}
                                        autoSize={{ minRows: 1 }}
                                        placeholder="example.com"
                                        disabled={isEdit}
                                    />
                                </Form.Item>
                                <Form.Item
                                    {...formLayout}
                                    label="ポート番号"
                                    className={styles.field}
                                    validateStatus={
                                        fieldErrors?.port_number
                                            ? "error"
                                            : undefined
                                    }
                                    help={fieldErrors?.port_number}
                                    initialValue={this.state.portDefault}
                                    rules={[
                                        {
                                            pattern: HANKAKU_NUMBER_REGEX,
                                            message:
                                                ErrorMessages.validation.regex
                                                    .onlyHankakuNumber,
                                        },
                                        {
                                            required: true,
                                        },
                                        {
                                            validator: (_, value) => {
                                                const portNumberString = String(
                                                    value ?? ""
                                                );
                                                if (
                                                    portNumberString.length > 5
                                                ) {
                                                    return Promise.reject(
                                                        new Error(
                                                            "5桁以内で入力してください。"
                                                        )
                                                    );
                                                }
                                                return Promise.resolve();
                                            },
                                        },
                                    ]}
                                    name="port_number"
                                >
                                    <InputNumber
                                        onChange={this.onChangeConnectionValue}
                                        style={{
                                            width: "100%",
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    {...formLayout}
                                    label="パスワード"
                                    className={styles.field}
                                    validateStatus={
                                        fieldErrors?.password
                                            ? "error"
                                            : undefined
                                    }
                                    help={
                                        fieldErrors.password ||
                                        (connectionTestFieldErrors &&
                                            connectionTestFieldErrors[
                                                "password"
                                            ])
                                    }
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                ErrorMessages.form.required,
                                        },
                                    ]}
                                    name="password"
                                >
                                    <Input.Password
                                        onChange={this.onChangeConnectionValue}
                                    />
                                </Form.Item>
                                <Form.Item
                                    {...formLayout}
                                    label="接続の保護"
                                    className={styles.field}
                                    name="protocol"
                                    required={true}
                                    initialValue={"imap"}
                                >
                                    <Radio.Group
                                        onChange={this.onChangeConnectionValue}
                                    >
                                        <Radio value="imap">IMAP</Radio>
                                        <Radio value="pop3">POP3</Radio>
                                        <Radio value="imap+ssl">
                                            IMAP+SSL/TLS
                                        </Radio>
                                        <Radio value="pop3+ssl">
                                            POP3+SSL/TLS
                                        </Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item shouldUpdate noStyle>
                                    {({ getFieldValue }) => {
                                        const protocol =
                                            getFieldValue("protocol");
                                        if (
                                            !protocol ||
                                            protocol.includes("imap")
                                        ) {
                                            return null;
                                        }
                                        return (
                                            <PopWarningMessage
                                                {...formLayout}
                                                type={protocol}
                                            />
                                        );
                                    }}
                                </Form.Item>
                                <RelayFolderFormItem
                                    {...formLayout}
                                    className={styles.field}
                                    onChange={this.onChangeConnectionValue}
                                    rules={[
                                        {
                                            validator: (rule, value) => {
                                                // NOTE: "value" can be either string, undefined or null.
                                                const ngs = ["(", ")"];
                                                for (const ng of ngs) {
                                                    if (
                                                        value &&
                                                        value.includes(ng)
                                                    ) {
                                                        if (
                                                            !this.state
                                                                .hasArchiveError
                                                        ) {
                                                            this.setState({
                                                                hasArchiveError: true,
                                                            });
                                                        }
                                                        return Promise.reject(
                                                            new Error(
                                                                ErrorMessages.validation.hankakuBracket
                                                            )
                                                        );
                                                    }
                                                }
                                                if (
                                                    this.state.hasArchiveError
                                                ) {
                                                    this.setState({
                                                        hasArchiveError: false,
                                                    });
                                                }
                                                return Promise.resolve();
                                            },
                                        },
                                    ]}
                                />
                                <Col>
                                    <Row justify="end">
                                        <Form.Item shouldUpdate>
                                            {() => {
                                                const isDisabled =
                                                    (!!this.baseform.current &&
                                                        isFormDisabled(
                                                            this.baseform
                                                                .current,
                                                            REQUIRED_FIELDS
                                                        )) ||
                                                    isConnectionTestSuccessed ||
                                                    isTestHostSuccess ||
                                                    hasArchiveError;
                                                return (
                                                    <Button
                                                        type="default"
                                                        htmlType="button"
                                                        onClick={
                                                            this
                                                                .onClickConnectionTestButton
                                                        }
                                                        disabled={isDisabled}
                                                    >
                                                        接続テスト
                                                    </Button>
                                                );
                                            }}
                                        </Form.Item>
                                    </Row>
                                </Col>
                            </TabPane>
                            <TabPane tab="詳細設定" key="2">
                                <Form.Item
                                    {...detailTabFormLayout}
                                    label={
                                        <span>
                                            通知先数の上限&nbsp;
                                            <Tooltip
                                                title={
                                                    <span>
                                                        <a
                                                            href={
                                                                "/sharedMailNotifications/register"
                                                            }
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            自動マッチング条件登録／編集
                                                        </a>
                                                        で追加できる通知先の上限件数が選択されています。
                                                        <br />
                                                        <a
                                                            href={"/addons"}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            アドオン
                                                        </a>
                                                        の追加回数に応じて宛先の上限件数を追加することが可能です。
                                                        <br />
                                                        <a
                                                            href={
                                                                Links.helps
                                                                    .addon
                                                                    .sharedEmails
                                                                    .assignee
                                                            }
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            詳細
                                                        </a>
                                                    </span>
                                                }
                                            >
                                                <QuestionCircleFilled
                                                    style={{
                                                        color: iconCustomColor,
                                                    }}
                                                    className={styles.tooltip}
                                                />
                                            </Tooltip>
                                        </span>
                                    }
                                    className={styles.field}
                                    name="assignee_addon_purchase_count"
                                    initialValue={0}
                                >
                                    <Radio.Group disabled>
                                        <Tooltip title={addonTooltipMessage}>
                                            <Radio value={0}>5件</Radio>
                                            <Radio value={1}>15件</Radio>
                                            <Radio value={2}>25件</Radio>
                                            <Radio value={3}>35件</Radio>
                                        </Tooltip>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item
                                    {...detailTabFormLayout}
                                    label={
                                        <span>
                                            ルール条件数の上限&nbsp;
                                            <Tooltip
                                                title={
                                                    <span>
                                                        <a
                                                            href={
                                                                "/sharedMailNotifications/register"
                                                            }
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            自動マッチング条件登録／編集
                                                        </a>
                                                        で追加できるルール条件の上限件数が選択されています。
                                                        <br />
                                                        <a
                                                            href={"/addons"}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            アドオン
                                                        </a>
                                                        の追加回数に応じて宛先の上限件数を追加することが可能です。
                                                        <br />
                                                        <a
                                                            href={
                                                                Links.helps
                                                                    .addon
                                                                    .sharedEmails
                                                                    .ruleCondition
                                                            }
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            詳細
                                                        </a>
                                                    </span>
                                                }
                                            >
                                                <QuestionCircleFilled
                                                    style={{
                                                        color: iconCustomColor,
                                                    }}
                                                    className={styles.tooltip}
                                                />
                                            </Tooltip>
                                        </span>
                                    }
                                    className={styles.field}
                                    name="rule_condition_addon_purchase_count"
                                    initialValue={0}
                                >
                                    <Radio.Group disabled>
                                        <Tooltip title={addonTooltipMessage}>
                                            <Radio value={0}>5件</Radio>
                                            <Radio value={1}>15件</Radio>
                                            <Radio value={2}>25件</Radio>
                                            <Radio value={3}>35件</Radio>
                                        </Tooltip>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item
                                    {...detailTabFormLayout}
                                    label={
                                        <span>
                                            通知時間の間隔&nbsp;
                                            <Tooltip
                                                title={
                                                    <span>
                                                        自動マッチング条件通知の通知時間の間隔が選択されています。
                                                        <br />
                                                        <a
                                                            href={"/addons"}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            アドオン
                                                        </a>
                                                        の追加回数に応じて宛先の上限件数を追加することが可能です。
                                                        <br />
                                                        <a
                                                            href={
                                                                Links.helps
                                                                    .addon
                                                                    .sharedEmails
                                                                    .notificationInterval
                                                            }
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            詳細
                                                        </a>
                                                    </span>
                                                }
                                            >
                                                <QuestionCircleFilled
                                                    style={{
                                                        color: iconCustomColor,
                                                    }}
                                                    className={styles.tooltip}
                                                />
                                            </Tooltip>
                                        </span>
                                    }
                                    className={styles.field}
                                    name="is_short_notification_interval_available"
                                    initialValue={
                                        initialData.is_short_notification_interval_available
                                    }
                                >
                                    <Radio.Group disabled>
                                        <Tooltip title={addonTooltipMessage}>
                                            <Radio value={false}>
                                                60分単位
                                            </Radio>
                                            <Radio value={true}>10分単位</Radio>
                                        </Tooltip>
                                    </Radio.Group>
                                </Form.Item>
                                {!initialData.is_short_notification_interval_available && (
                                    <Col span={24}>
                                        <Typography.Paragraph
                                            style={{
                                                textAlign: "center",
                                                marginTop: 24,
                                            }}
                                        >
                                            <InfoCircleTwoTone
                                                twoToneColor={infoColor}
                                            />
                                            <Link
                                                to="/addons"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                アドオン
                                            </Link>
                                            を購入することで通知間隔を10分にすることができます。
                                        </Typography.Paragraph>
                                    </Col>
                                )}
                            </TabPane>
                        </Tabs>
                        <br />
                        <Col span={24} style={{ marginTop: "5%" }}>
                            <Row justify="start">
                                <Col>
                                    <BackButton />
                                </Col>
                                <Col>
                                    {isEdit ? (
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            className={styles.button}
                                            disabled={
                                                !isConnectionTestSuccessed
                                            }
                                        >
                                            更新
                                        </Button>
                                    ) : (
                                        <Button
                                            type="primary"
                                            htmlType="button"
                                            className={styles.button}
                                            disabled={
                                                !isConnectionTestSuccessed
                                            }
                                            onClick={this.onClickRegisterButton}
                                        >
                                            登録
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                        </Col>
                    </Form>
                </Col>
            </Row>
        );
    }
}

SharedEmailSettingForm.propTypes = {
    dispatch: PropTypes.func.isRequired,
    resourceURL: PropTypes.string.isRequired,
    initialData: PropTypes.shape({
        // Corresponds to backend API.
        allow_self_domain: PropTypes.bool,
        is_short_notification_interval_available: PropTypes.bool,
    }), // Override in child class and use PropTypes.shape instead.
    fieldErrors: PropTypes.shape({
        allow_self_domain: PropTypes.arrayOf(PropTypes.string),
        hostname: PropTypes.string,
        password: PropTypes.string,
        port_number: PropTypes.number,
        protocol: PropTypes.number,
        username: PropTypes.string,
    }).isRequired,
    submitHandler: PropTypes.func.isRequired,
    pageId: PropTypes.string,
};

SharedEmailSettingForm.defaultProps = {
    initialData: {},
};

function mapStateToProps(state) {
    return {
        token: state.login.token,
    };
}

const _SharedEmailSettingFormWrapper = finalized(SharedEmailSettingForm);
const SharedEmailSettingFormWrapper = connect(mapStateToProps)(
    _SharedEmailSettingFormWrapper
);
export default SharedEmailSettingFormWrapper;
