// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MenuBoardSubSettingsComponent-container-H_UGO{padding:10px}.MenuBoardSubSettingsComponent-menuWrapper-VZ6hB{width:380px;background-color:white;top:-100px}.MenuBoardSubSettingsComponent-sortMenuWrapper-VhcDJ{width:380px;top:50px;background-color:white}.MenuBoardSubSettingsComponent-headerMenu-HeBgg{padding:10px;background-color:white;border-bottom:1px solid #f5f5f5}.MenuBoardSubSettingsComponent-menu-rEPuj{box-shadow:0 3px 6px -4px rgba(0,0,0,0.12),0 6px 16px 0 xrgba(0, 0, 0, 0.08),0px 0px 0px 0px rgba(0,0,0,0.05) !important;max-height:325px;overflow-y:scroll}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Common/BoardComponent/BoardBaseComponent/MenuBoardSubSettingsComponent/MenuBoardSubSettingsComponent.scss"],"names":[],"mappings":"AAAA,+CACE,YAAa,CACd,iDAGC,WAAY,CACZ,sBAAuB,CACvB,UAAW,CACZ,qDAGC,WAAY,CACZ,QAAS,CACT,sBAAuB,CACxB,gDAGC,YAAa,CACb,sBAAuB,CACvB,+BAAgC,CACjC,0CAGC,wHAA8H,CAC9H,gBAAiB,CACjB,iBAAkB","sourcesContent":[".container {\n  padding: 10px;\n}\n\n.menuWrapper {\n  width: 380px;\n  background-color: white;\n  top: -100px;\n}\n\n.sortMenuWrapper {\n  width: 380px;\n  top: 50px;\n  background-color: white;\n}\n\n.headerMenu {\n  padding: 10px;\n  background-color: white;\n  border-bottom: 1px solid #f5f5f5;\n}\n\n.menu {\n  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, .12), 0 6px 16px 0 xrgba(0, 0, 0, .08), 0px 0px 0px 0px rgba(0, 0, 0, .05) !important;\n  max-height: 325px;\n  overflow-y: scroll;\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `MenuBoardSubSettingsComponent-container-H_UGO`,
	"menuWrapper": `MenuBoardSubSettingsComponent-menuWrapper-VZ6hB`,
	"sortMenuWrapper": `MenuBoardSubSettingsComponent-sortMenuWrapper-VhcDJ`,
	"headerMenu": `MenuBoardSubSettingsComponent-headerMenu-HeBgg`,
	"menu": `MenuBoardSubSettingsComponent-menu-rEPuj`
};
export default ___CSS_LOADER_EXPORT___;
