// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SalesCreatedUserFormItem-container-x0ePm{width:100%;text-align:left}.SalesCreatedUserFormItem-userInput-WCWql{width:100%}.SalesCreatedUserFormItem-tooltip-Wmmh6{margin-left:5px}.SalesCreatedUserFormItem-infoIcon-VrutU{align-self:center}.SalesCreatedUserFormItem-marginBottom-RvrUn{margin-bottom:1%}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/sales/SalesBoardPage/SalesBoardSearchDrawer/SalesCreatedUserFormItem/SalesCreatedUserFormItem.scss"],"names":[],"mappings":"AAAA,0CACI,UAAW,CACX,eAAgB,CACnB,0CAGG,UAAW,CACd,wCAGG,eAAgB,CACnB,yCAGG,iBAAkB,CACrB,6CAGG,gBAAiB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n\n.marginBottom {\n    margin-bottom: 1%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `SalesCreatedUserFormItem-container-x0ePm`,
	"userInput": `SalesCreatedUserFormItem-userInput-WCWql`,
	"tooltip": `SalesCreatedUserFormItem-tooltip-Wmmh6`,
	"infoIcon": `SalesCreatedUserFormItem-infoIcon-VrutU`,
	"marginBottom": `SalesCreatedUserFormItem-marginBottom-RvrUn`
};
export default ___CSS_LOADER_EXPORT___;
