"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertAddonPurchaseItemResponseModelToAddonPurchaseItemModel = exports.convertAddonMasterResponseModelToAddonMasterModel = void 0;
var convertAddonMasterResponseModelToAddonMasterModel = function (data) {
    var obj = {
        id: data.id,
        description: data.description,
        expirationTime: data.expiration_time,
        isDashboard: data.is_dashboard,
        isMyCompanySetting: data.is_my_company_setting,
        isOrganizations: data.is_organizations,
        isRecommended: data.is_recommended,
        isScheduledMails: data.is_scheduled_mails,
        isSharedMails: data.is_shared_mails,
        limit: data.limit,
        parents: data.parents,
        price: data.price,
        targets: data.targets,
        title: data.title,
        helpUrl: data.help_url,
        isPersonnel: data.is_personnel,
        isProject: data.is_project,
        isRecruit: data.is_recruit,
        isSales: data.is_sales,
        isBackOffice: data.is_back_office,
    };
    return obj;
};
exports.convertAddonMasterResponseModelToAddonMasterModel = convertAddonMasterResponseModelToAddonMasterModel;
var convertAddonPurchaseItemResponseModelToAddonPurchaseItemModel = function (data) {
    var obj = {
        id: data.id,
        addonMasterId: data.addon_master_id,
        nextPaymentDate: data.next_payment_date,
    };
    return obj;
};
exports.convertAddonPurchaseItemResponseModelToAddonPurchaseItemModel = convertAddonPurchaseItemResponseModelToAddonPurchaseItemModel;
