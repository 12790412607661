import React from "react";
import PropTypes from "prop-types";
import { Route, Switch, Redirect } from "react-router-dom";
import LoginPage from "~/components/Pages/LoginPage/LoginPage";
import PasswordResetPage from "~/components/Pages/PasswordResetPage/PasswordResetPage";
import PasswordChangePage from "~/components/Pages/PasswordChangePage/PasswordChangePage";
import DashboardPage from "~/components/Pages/DashboardPage/DashboardPage";
import MyProfilePage from "~/components/Pages/MyProfilePage/MyProfilePage";
import MyCompanyPage from "~/components/Pages/MyCompanyPage";
import UserSearchPage from "~/components/Pages/UserSearchPage";
import UserRegisterPage from "~/components/Pages/UserRegisterPage";
import UserEditPage from "~/components/Pages/UserEditPage";
import SharedEmailPage from "~/components/Pages/SharedEmailPage";
import SharedEmailDetailPage from "~/components/Pages/SharedEmailDetailPage/SharedEmailDetailPage";
import SharedEmailNotificationSearchPage from "~/components/Pages/SharedEmailNotificationSearchPage";
import SharedEmailNotificationDetailPage from "~/components/Pages/SharedEmailNotificationDetailPage";
import SharedEmailNotificationRegisterPage from "~/components/Pages/SharedEmailNotificationRegisterPage";
import OrganizationSearchPage from "~/components/Pages/OrganizationSearchPage/OrganizationSearchPage";
import OrganizationRegisterPage from "~/components/Pages/OrganizationRegisterPage";
import OrganizationEditPage from "~/components/Pages/OrganizationEditPage";
import { OrganizationCsvUploadPage } from "~/components/Pages/OrganizationCsvUploadPage";
import ContactSearchPage from "~/components/Pages/ContactSearchPage/ContactSearchPage";
import ContactRegisterPage from "~/components/Pages/ContactRegisterPage";
import ContactEditPage from "~/components/Pages/ContactEditPage";
import ContactEmailPreferenceEditPage from "~/components/Pages/ContactMailPreferenceEditPage";
import { ContactCsvUploadPage } from "~/components/Pages/ContactCsvUploadPage";
import ScheduledEmailSearchPage from "~/components/Pages/ScheduledEmailSearchPage";
import {
    ScheduledEmailRegisterPage,
    ScheduledEmailEditPage,
    ScheduledEmailAttachementPage,
} from "~/components/Pages/ScheduledEmailPage";
import TagPage from "~/components/Pages/TagPage/TagPage";
import TagRegisterPage from "~/components/Pages/TagRegisterPage/TagRegisterPage";
import TagCsvUploadPage from "~/components/Pages/TagCsvUploadPage/TagCsvUploadPage";
import TagEditPage from "~/components/Pages/TagEditPage/TagEditPage";
import NotFoundPage from "~/components/Pages/NotFoundPage";
import Paths, { Suffixes } from "./Paths";
import RequiredDisplaySettingPage from "~/components/Pages/RequiredDisplaySettingPage";
import SharedEmailSettingPage from "~/components/Pages/SharedEmailSettingPage";
import ScheduledEmailSettingPage from "~/components/Pages/ScheduledEmailSettingPage/ScheduledEmailSettingPage";
import AddonsPage from "~/components/Pages/AddonsPage";
import PurchaseHistoryPage from "~/components/Pages/PurchaseHistoryPage/PurchaseHistoryPage";
import PaymentPage from "~/components/Pages/PaymentPage/PaymentPage";
import PlanPage from "~/components/Pages/PlanPage/PlanPage";
import StartupRegisterScalePage from "~/components/Pages/StartupRegisterScalePage/StartupRegisterScalePage";
import MasterOnlyRoute from "./MasterOnlyRoute/MasterOnlyRoute";
import PrivateRoute from "./PrivateRoute/PrivateRoute";
import AdminOnlyRoute from "./AdminOnlyRoute/AdminOnlyRoute";
import TenantCreatePage from "~/components/Pages/TenantCreatePage/TenantCreatePage";
import PersonnelBoardPage from "../Pages/personnel/PersonnelBoardPage/PersonnelBoardPage";
import ProjectBoardPage from "../Pages/project/ProjectBoardPage/ProjectBoardPage";
import MemberOrAboveOnlyRoute from "./MemberOrAboveOnlyRoute/MemberOrAboveOnlyRoute";
import ActionNotificationSettingsPage from "../Pages/ActionNotificationSettingsPage";
import StartupDiscountOnlyRoute from "./StartupDiscountOnlyRoute/StartupDiscountOnlyRoute";
import ScheduledEmailDownloadAttachmentPage
    from "../Pages/ScheduledEmailDownloadAttachmentPage/ScheduledEmailDownloadAttachmentPage";
import OverStandardPlanRoute from "./OverStandardPlanRoute/OverStandardPlanRoute";
import PersonnelGanttPage from "../Pages/personnel/PersonnelGanttPage/PersonnelGanttPage";
import ProjectGanttPage from "../Pages/project/ProjectGanttPage/ProjectGanttPage";
import UserInvitationPage from "../Pages/UserInvitationPage";
import TagBulkEditPage from "../Pages/TagBulkEditPage";
import UserBulkEditPage from "../Pages/UserBulkEditPage";
import SharedEmailBulkEditPage from "../Pages/SharedEmailBulkEditPage";
import OrganizationBulkEditPage from "../Pages/OrganizationBulkEditPage";
import ContactBulkEditPage from "../Pages/ContactBulkEditPage";
import ReminderSettingsPage from "../Pages/ReminderSettingsPage";
import NonGuestRoute from "./NonGuestRoute/NonGuestRoute";
import PersonnelCsvUploadPage from "../Pages/personnel/PersonnelCsvUploadPage/PersonnelCsvUploadPage";
import ProjectCsvUploadPage from "../Pages/project/ProjectCsvUploadPage/ProjectCsvUploadPage";
import RecruitBoardPage from "../Pages/recruit/RecruitBoardPage/RecruitBoardPage";
import RecruitGanttPage from "../Pages/recruit/RecruitGanttPage/RecruitGanttPage";
import PersonnelSettingsPage from "../Pages/PersonnelSettingsPage/PersonnelSettingsPage";
import ProjectSettingsPage from "../Pages/ProjectSettingsPage/ProjectSettingsPage";
import RecruitSettingsPage from "../Pages/RecruitSettingsPage/RecruitSettingsPage";
import SalesBoardPage from "../Pages/sales/SalesBoardPage/SalesBoardPage";
import SalesGanttPage from "../Pages/sales/SalesGanttPage/SalesGanttPage";
import SalesSettingsPage from "../Pages/SalesSettingsPage/SalesSettingsPage";
import BackOfficeBoardPage from "../Pages/backOffice/BackOfficeBoardPage/BackOfficeBoardPage";
import BackOfficeGanttPage from "../Pages/backOffice/BackOfficeGanttPage/BackOfficeGanttPage";
import BackOfficeSettingsPage from "../Pages/BackOfficeSettingsPage/BackOfficeSettingsPage";
import ScheduledEmailBulkEditPage from "../Pages/ScheduledEmailBulkEditPage";
import { usePlans } from "../../hooks/usePlan";

const Pages = (props) => {
    const { isLoggedIn, isAdminUser } = props;
    const { data, isPersonalPlan } = usePlans();
    const rootPage = function () {
        if (!isLoggedIn) {
            return <Redirect push to={Paths.login} />;
        }
        if (!data) {
            return <Redirect push to={Paths.index} />;
        }
        if (isPersonalPlan) {
            return <Redirect push to={Paths.organizations} />;
        }
        return <Redirect push to={Paths.dashboard} />;
    }();
    return (
        <Switch>
            <Route exact path={Paths.index} >
                {rootPage}
            </Route>
            <Route exact path={Paths.maintenance}>
                {rootPage}
            </Route>
            <Route exact path={Paths.login} component={LoginPage} />
            <Route
                isLoggedIn={isLoggedIn}
                isAdminUser={isAdminUser}
                exact
                path={Paths.passwordReset}
                component={PasswordResetPage}
            />
            <Route
                isLoggedIn={isLoggedIn}
                isAdminUser={isAdminUser}
                exact
                path={`${Paths.passwordChange}/:id`}
                component={PasswordChangePage}
            />
            <Route
                isLoggedIn={isLoggedIn}
                isAdminUser={isAdminUser}
                exact
                path={`${Paths.userRegister}/:id`}
                component={UserRegisterPage}
            />
            <Route exact path="/tenant" component={TenantCreatePage} />
            {!isPersonalPlan && <PrivateRoute
                isLoggedIn={isLoggedIn}
                exact
                path={Paths.dashboard}
                component={DashboardPage}
            />
            }
            <AdminOnlyRoute
                exact
                path={Paths.userInvite}
                component={UserInvitationPage}
            />
            <AdminOnlyRoute
                exact
                path={Paths.userBulkEdit}
                component={UserBulkEditPage}
            />
            <PrivateRoute
                isLoggedIn={isLoggedIn}
                exact
                path={`${Paths.users}/:id`}
                component={UserEditPage}
            />
            <PrivateRoute
                isLoggedIn={isLoggedIn}
                exact
                path={Paths.myProfile}
                component={MyProfilePage}
            />
            <PrivateRoute
                isLoggedIn={isLoggedIn}
                exact
                path={Paths.myCompany}
                component={MyCompanyPage}
            />
            <PrivateRoute
                isLoggedIn={isLoggedIn}
                exact
                path={Paths.contacts}
                component={ContactSearchPage}
            />
            <PrivateRoute
                isLoggedIn={isLoggedIn}
                exact
                path={Paths.contactRegister}
                component={ContactRegisterPage}
            />
            <PrivateRoute
                isLoggedIn={isLoggedIn}
                exact
                path={Paths.contactsCsvUpload}
                component={ContactCsvUploadPage}
            />
            <PrivateRoute
                isLoggedIn={isLoggedIn}
                exact
                path={Paths.contactBulkEdit}
                component={ContactBulkEditPage}
            />
            <PrivateRoute
                isLoggedIn={isLoggedIn}
                exact
                path={`${Paths.contacts}/:id`}
                component={ContactEditPage}
            />
            <PrivateRoute
                isLoggedIn={isLoggedIn}
                exact
                path={`${Paths.contacts}/:id/${Suffixes.mailProfile}`}
                component={ContactEmailPreferenceEditPage}
            />
            <OverStandardPlanRoute
                exact
                path={Paths.sharedMails}
                component={SharedEmailPage}
            />
            <OverStandardPlanRoute
                exact
                path={Paths.sharedMailBulkEdit}
                component={SharedEmailBulkEditPage}
            />
            <OverStandardPlanRoute
                exact
                path={`${Paths.sharedMails}/:id`}
                component={SharedEmailDetailPage}
            />
            <OverStandardPlanRoute
                exact
                path={Paths.sharedMailNotifications}
                component={SharedEmailNotificationSearchPage}
            />
            <OverStandardPlanRoute
                exact
                path={Paths.sharedMailNotificationRegister}
                component={SharedEmailNotificationRegisterPage}
            />
            <OverStandardPlanRoute
                exact
                path={`${Paths.sharedMailNotifications}/:id`}
                component={SharedEmailNotificationDetailPage}
            />
            <PrivateRoute
                isLoggedIn={isLoggedIn}
                exact
                path={Paths.organizations}
                component={OrganizationSearchPage}
            />
            <PrivateRoute
                isLoggedIn={isLoggedIn}
                exact
                path={Paths.organizationsRegister}
                component={OrganizationRegisterPage}
            />
            <PrivateRoute
                isLoggedIn={isLoggedIn}
                exact
                path={Paths.organizationsCsvUpload}
                component={OrganizationCsvUploadPage}
            />
            <PrivateRoute
                isLoggedIn={isLoggedIn}
                exact
                path={Paths.organizationsBulkEdit}
                component={OrganizationBulkEditPage}
            />
            <PrivateRoute
                isLoggedIn={isLoggedIn}
                exact
                path={`${Paths.organizations}/:id`}
                component={OrganizationEditPage}
            />
            <PrivateRoute
                isLoggedIn={isLoggedIn}
                exact
                path={Paths.scheduledMails}
                component={ScheduledEmailSearchPage}
            />
            <PrivateRoute
                isLoggedIn={isLoggedIn}
                exact
                path={Paths.scheduledMailsRegister}
                component={ScheduledEmailRegisterPage}
            />
            <PrivateRoute
                isLoggedIn={isLoggedIn}
                exact
                path={Paths.scheduledMailsBulkEdit}
                component={ScheduledEmailBulkEditPage}
            />
            <PrivateRoute
                isLoggedIn={isLoggedIn}
                exact
                path={`${Paths.scheduledMails}/:id/attachement`}
                component={ScheduledEmailAttachementPage}
            />
            <PrivateRoute
                isLoggedIn={isLoggedIn}
                exact
                path={`${Paths.scheduledMails}/:id`}
                component={ScheduledEmailEditPage}
            />
            <PrivateRoute
                isLoggedIn={isLoggedIn}
                exact
                path={Paths.tags}
                component={TagPage}
            />
            <PrivateRoute
                isLoggedIn={isLoggedIn}
                exact
                path={Paths.tagCsvUpload}
                component={TagCsvUploadPage}
            />
            <PrivateRoute
                isLoggedIn={isLoggedIn}
                exact
                path={Paths.tagRegister}
                component={TagRegisterPage}
            />
            <PrivateRoute
                isLoggedIn={isLoggedIn}
                exact
                path={Paths.tagBulkEdit}
                component={TagBulkEditPage}
            />
            <PrivateRoute
                isLoggedIn={isLoggedIn}
                exact
                path={`${Paths.tags}/:id`}
                component={TagEditPage}
            />
            <PrivateRoute
                isLoggedIn={isLoggedIn}
                exact
                path={Paths.displaySettings}
                component={RequiredDisplaySettingPage}
            />
            <OverStandardPlanRoute
                isLoggedIn={isLoggedIn}
                exact
                path={Paths.personnel.csvUpload}
                component={PersonnelCsvUploadPage}
            />
            <OverStandardPlanRoute
                isLoggedIn={isLoggedIn}
                exact
                path={`${Paths.personnel.board}/:id`}
                component={PersonnelBoardPage}
            />
            <OverStandardPlanRoute
                isLoggedIn={isLoggedIn}
                exact
                path={Paths.personnel.board}
                component={PersonnelBoardPage}
            />
            <OverStandardPlanRoute
                isLoggedIn={isLoggedIn}
                exact
                path={Paths.personnel.gantt}
                component={PersonnelGanttPage}
            />
            <OverStandardPlanRoute
                isLoggedIn={isLoggedIn}
                exact
                path={Paths.project.csvUpload}
                component={ProjectCsvUploadPage}
            />
            <OverStandardPlanRoute
                isLoggedIn={isLoggedIn}
                exact
                path={`${Paths.project.board}/:id`}
                component={ProjectBoardPage}
            />
            <OverStandardPlanRoute
                isLoggedIn={isLoggedIn}
                exact
                path={Paths.project.board}
                component={ProjectBoardPage}
            />
            <OverStandardPlanRoute
                isLoggedIn={isLoggedIn}
                exact
                path={Paths.project.gantt}
                component={ProjectGanttPage}
            />
            <OverStandardPlanRoute
                exact
                path={Paths.sharedEmailSettings}
                component={SharedEmailSettingPage}
            />
            <PrivateRoute
                isLoggedIn={isLoggedIn}
                exact
                path={Paths.scheduledEmailSettings}
                component={ScheduledEmailSettingPage}
            />
            {
                !isPersonalPlan && <PrivateRoute
                    isLoggedIn={isLoggedIn}
                    exact
                    path={Paths.addons}
                    component={AddonsPage}
                />

            }
            <PrivateRoute
                isLoggedIn={isLoggedIn}
                exact
                path={Paths.plan.index}
                component={PlanPage}
            />
            <StartupDiscountOnlyRoute
                exact
                path={Paths.plan.startup.application}
                component={StartupRegisterScalePage}
            />
            <Route
                exact
                path={`${Paths.scheduledMailsDownloadAttachment}/:id`}
                component={ScheduledEmailDownloadAttachmentPage}
            />
            <AdminOnlyRoute
                exact
                path={Paths.users}
                component={UserSearchPage}
            />
            <MasterOnlyRoute
                exact
                path={Paths.purchaseHistory}
                component={PurchaseHistoryPage}
            />
            <MasterOnlyRoute
                exact
                path={Paths.payment}
                component={PaymentPage}
            />
            {
                !isPersonalPlan && <MemberOrAboveOnlyRoute
                    exact
                    path={Paths.notificationSettings}
                    component={ActionNotificationSettingsPage}
                />
            }
            <NonGuestRoute
                exact
                path={Paths.reminderSettings}
                component={ReminderSettingsPage}
            />
            <OverStandardPlanRoute
                isLoggedIn={isLoggedIn}
                exact
                path={`${Paths.recruit.board}/:id`}
                component={RecruitBoardPage}
            />
            <OverStandardPlanRoute
                isLoggedIn={isLoggedIn}
                exact
                path={Paths.recruit.board}
                component={RecruitBoardPage}
            />
            <OverStandardPlanRoute
                isLoggedIn={isLoggedIn}
                exact
                path={Paths.recruit.gantt}
                component={RecruitGanttPage}
            />
            <OverStandardPlanRoute
                isLoggedIn={isLoggedIn}
                exact
                path={`${Paths.sales.board}/:id`}
                component={SalesBoardPage}
            />
            <OverStandardPlanRoute
                isLoggedIn={isLoggedIn}
                exact
                path={Paths.sales.board}
                component={SalesBoardPage}
            />
            <OverStandardPlanRoute
                isLoggedIn={isLoggedIn}
                exact
                path={Paths.sales.gantt}
                component={SalesGanttPage}
            />
            <OverStandardPlanRoute
                isLoggedIn={isLoggedIn}
                exact
                path={`${Paths.backOffice.board}/:id`}
                component={BackOfficeBoardPage}
            />
            <OverStandardPlanRoute
                isLoggedIn={isLoggedIn}
                exact
                path={Paths.backOffice.board}
                component={BackOfficeBoardPage}
            />
            <OverStandardPlanRoute
                isLoggedIn={isLoggedIn}
                exact
                path={Paths.backOffice.gantt}
                component={BackOfficeGanttPage}
            />
            <OverStandardPlanRoute
                exact
                path={Paths.personnelSettings}
                component={PersonnelSettingsPage}
            />
            <OverStandardPlanRoute
                exact
                path={Paths.projectSettings}
                component={ProjectSettingsPage}
            />
            <OverStandardPlanRoute
                exact
                path={Paths.recruitSettings}
                component={RecruitSettingsPage}
            />
            <OverStandardPlanRoute
                exact
                path={Paths.salesSettings}
                component={SalesSettingsPage}
            />
            <OverStandardPlanRoute
                exact
                path={Paths.backOfficeSettings}
                component={BackOfficeSettingsPage}
            />
            <Route component={NotFoundPage} />
        </Switch>
    );
};

Pages.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
    isAdminUser: PropTypes.bool.isRequired,
};

export default Pages;
