// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PaymentEmptyCard-box-vr_MM{height:150px;background-image:repeating-linear-gradient(-13deg, #7a7a7a, #7a7a7a 3px, transparent 3px, transparent 8px, #7a7a7a 8px),repeating-linear-gradient(77deg, #7a7a7a, #7a7a7a 3px, transparent 3px, transparent 8px, #7a7a7a 8px),repeating-linear-gradient(167deg, #7a7a7a, #7a7a7a 3px, transparent 3px, transparent 8px, #7a7a7a 8px),repeating-linear-gradient(257deg, #7a7a7a, #7a7a7a 3px, transparent 3px, transparent 8px, #7a7a7a 8px);background-size:1px 100%, 100% 1px, 1px 100%, 100% 1px;background-position:0 0, 0 0, 100% 0, 0 100%;background-repeat:no-repeat}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/PaymentPage/PaymentEmptyCard/PaymentEmptyCard.scss"],"names":[],"mappings":"AAAA,4BACI,YAAa,CACb,2aA+BK,CACL,sDAAuD,CACvD,4CAA6C,CAC7C,2BAA4B","sourcesContent":[".box {\n    height: 150px;\n    background-image: repeating-linear-gradient(\n            -13deg,\n            #7a7a7a,\n            #7a7a7a 3px,\n            transparent 3px,\n            transparent 8px,\n            #7a7a7a 8px\n        ),\n        repeating-linear-gradient(\n            77deg,\n            #7a7a7a,\n            #7a7a7a 3px,\n            transparent 3px,\n            transparent 8px,\n            #7a7a7a 8px\n        ),\n        repeating-linear-gradient(\n            167deg,\n            #7a7a7a,\n            #7a7a7a 3px,\n            transparent 3px,\n            transparent 8px,\n            #7a7a7a 8px\n        ),\n        repeating-linear-gradient(\n            257deg,\n            #7a7a7a,\n            #7a7a7a 3px,\n            transparent 3px,\n            transparent 8px,\n            #7a7a7a 8px\n        );\n    background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;\n    background-position: 0 0, 0 0, 100% 0, 0 100%;\n    background-repeat: no-repeat;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `PaymentEmptyCard-box-vr_MM`
};
export default ___CSS_LOADER_EXPORT___;
