"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useProjectBoardChangeCardPositionAPIMutation = exports.useProjectBoardDeleteProjectAttachmentAPIMutation = exports.useProjectBoardDownloadAttachmentAPIMutation = exports.useProjectBoardDuplicateCardAPIMutation = exports.useProjectBoardDeleteCardAPIMutation = exports.useProjectBoardPartialUpdateCardAPIMutation = exports.useProjectBoardUpdateCardAPIMutation = exports.useProjectBoardArchiveAllCardsAPIMutation = exports.useProjectBoardMoveAllCardsAPIMutation = exports.useProjectBoardFetchListCardsAPIMutation = exports.useProjectBoardSortListCardsAPIMutation = exports.useProjectBoardCreateCardAPIMutation = exports.useProjectBoardFetchCardAPIQuery = void 0;
var react_query_1 = require("react-query");
var recoil_1 = require("recoil");
var AlertMessage_1 = require("~/components/Common/AlertMessage/AlertMessage");
var download_1 = require("~/hooks/download");
var projectBoard_1 = require("~/hooks/projectBoard");
var useClient_1 = require("~/hooks/useClient");
var useCustomMutation_1 = require("~/hooks/useCustomMutation");
var useAuthorizedQuery_1 = require("~/hooks/useAuthorizedQuery");
var api_1 = require("~/networking/api");
var constants_1 = require("~/utils/constants");
var utils_1 = require("~/utils/utils");
var atom_1 = require("~/recoil/atom");
var board_1 = require("../../../personnel/board");
var useProjectBoardFetchCardAPIQuery = function (_a) {
    var deps = _a.deps, options = _a.options;
    var getClientAuthorization = (0, useClient_1.useClient)().getClientAuthorization;
    return (0, useAuthorizedQuery_1.useAuthorizedQuery)({
        queryKey: constants_1.QueryKeys.projectBoard.card,
        deps: deps,
        options: __assign(__assign({}, options), { select: function (result) {
                return (0, projectBoard_1.convertProjectBoardDetailResponseModelToProjectBoardDetailModel)(result);
            }, enabled: !!(deps === null || deps === void 0 ? void 0 : deps.cardId) && !!getClientAuthorization() }),
        apiRequest: function (query) { return api_1.projectAPI.board.fetchCard(query === null || query === void 0 ? void 0 : query.cardId); },
    });
};
exports.useProjectBoardFetchCardAPIQuery = useProjectBoardFetchCardAPIQuery;
var useProjectBoardCreateCardAPIMutation = function () {
    var queryClient = (0, react_query_1.useQueryClient)();
    return (0, useCustomMutation_1.useCustomMutation)(api_1.projectAPI.board.createCard, {
        onSuccess: function (response) {
            queryClient.invalidateQueries(constants_1.QueryKeys.project.board.allListCards);
            var message = constants_1.SuccessMessages.generic.create;
            (0, AlertMessage_1.customSuccessMessage)(message);
        },
        onError: function (err) {
            (0, utils_1.apiErrorMessage)(err, constants_1.ErrorMessages.project.board.create);
        },
    });
};
exports.useProjectBoardCreateCardAPIMutation = useProjectBoardCreateCardAPIMutation;
var useProjectBoardSortListCardsAPIMutation = function (_a) {
    var _b = _a === void 0 ? {} : _a, params = _b.params;
    var id = undefined;
    var queryClient = (0, react_query_1.useQueryClient)();
    var apiRequest = function (_a) {
        var listId = _a.listId, query = _a.query;
        id = listId;
        return api_1.projectAPI.board.sortAllCards(listId, query, params);
    };
    return (0, useCustomMutation_1.useCustomMutation)(apiRequest, {
        onSuccess: function (response) {
            if (!id) {
                return;
            }
            queryClient.setQueriesData({
                queryKey: constants_1.QueryKeys.project.board.allListCards,
                exact: false,
            }, function (oldData) {
                if (!oldData) {
                    return oldData;
                }
                var oldResult = oldData.results;
                var copied = __spreadArray([], oldResult, true);
                var sortedCards = response.data.results;
                var updated = copied.map(function (item) {
                    if (item.id === id) {
                        return __assign(__assign({}, item), { cards: sortedCards });
                    }
                    return item;
                });
                return __assign(__assign({}, oldData), { results: updated });
            });
        },
        onError: function (err) {
            (0, utils_1.apiErrorMessage)(err, constants_1.ErrorMessages.board.sort);
        },
        onSettled: function () {
            id = undefined;
        },
    });
};
exports.useProjectBoardSortListCardsAPIMutation = useProjectBoardSortListCardsAPIMutation;
var useProjectBoardFetchListCardsAPIMutation = function () {
    var apiRequest = function (_a) {
        var listId = _a.listId, query = _a.query;
        return api_1.projectAPI.board.sortAllCards(listId, query);
    };
    return (0, useCustomMutation_1.useCustomMutation)(apiRequest, {
        onSuccess: function (response) { },
        onError: function (err) { },
    });
};
exports.useProjectBoardFetchListCardsAPIMutation = useProjectBoardFetchListCardsAPIMutation;
var useProjectBoardMoveAllCardsAPIMutation = function () {
    var queryClient = (0, react_query_1.useQueryClient)();
    var apiRequest = function (_a) {
        var listId = _a.listId, newListId = _a.newListId;
        return api_1.projectAPI.board.moveAllCards(listId, newListId);
    };
    return (0, useCustomMutation_1.useCustomMutation)(apiRequest, {
        onSuccess: function (response) {
            queryClient.invalidateQueries(constants_1.QueryKeys.project.board.allListCards);
        },
        onError: function (err) { },
    });
};
exports.useProjectBoardMoveAllCardsAPIMutation = useProjectBoardMoveAllCardsAPIMutation;
var useProjectBoardArchiveAllCardsAPIMutation = function () {
    var queryClient = (0, react_query_1.useQueryClient)();
    return (0, useCustomMutation_1.useCustomMutation)(api_1.projectAPI.board.archiveAllCards, {
        onSuccess: function (response) {
            queryClient.invalidateQueries(constants_1.QueryKeys.project.board.allListCards);
        },
        onError: function (err) { },
    });
};
exports.useProjectBoardArchiveAllCardsAPIMutation = useProjectBoardArchiveAllCardsAPIMutation;
var useProjectBoardUpdateCardAPIMutation = function () {
    var queryClient = (0, react_query_1.useQueryClient)();
    var apiRequest = function (_a) {
        var cardId = _a.cardId, postData = _a.postData;
        var data = (0, projectBoard_1.convertProjectBoardFormModelToProjectBoardRequestModel)(postData);
        return api_1.projectAPI.board.updateCard(cardId, data);
    };
    return (0, useCustomMutation_1.useCustomMutation)(apiRequest, {
        onSuccess: function (response) {
            queryClient.removeQueries({ queryKey: [constants_1.QueryKeys.project.board.allListCards], inactive: true });
            queryClient.invalidateQueries(constants_1.QueryKeys.project.board.allListCards);
            var message = constants_1.SuccessMessages.generic.update;
            (0, AlertMessage_1.customSuccessMessage)(message);
        },
        onError: function (err) {
            (0, utils_1.apiErrorMessage)(err, constants_1.ErrorMessages.generic.update);
        },
    });
};
exports.useProjectBoardUpdateCardAPIMutation = useProjectBoardUpdateCardAPIMutation;
var useProjectBoardPartialUpdateCardAPIMutation = function (isDeleteImage) {
    var apiRequest = function (_a) {
        var cardId = _a.cardId, postData = _a.postData;
        var data = (0, projectBoard_1.convertProjectBoardFormModelToProjectBoardRequestModel)(postData);
        return api_1.projectAPI.board.updateCard(cardId, data);
    };
    return (0, useCustomMutation_1.useCustomMutation)(apiRequest);
};
exports.useProjectBoardPartialUpdateCardAPIMutation = useProjectBoardPartialUpdateCardAPIMutation;
var useProjectBoardDeleteCardAPIMutation = function () {
    var queryClient = (0, react_query_1.useQueryClient)();
    return (0, useCustomMutation_1.useCustomMutation)(api_1.projectAPI.board.deleteCard, {
        onSuccess: function (response) {
            queryClient.invalidateQueries(constants_1.QueryKeys.project.board.allListCards);
            (0, AlertMessage_1.customSuccessMessage)(constants_1.SuccessMessages.generic.delete);
        },
        onError: function (err) {
            (0, utils_1.apiErrorMessage)(err, constants_1.ErrorMessages.generic.delete);
        },
    });
};
exports.useProjectBoardDeleteCardAPIMutation = useProjectBoardDeleteCardAPIMutation;
var useProjectBoardDuplicateCardAPIMutation = function () {
    var queryClient = (0, react_query_1.useQueryClient)();
    var apiRequest = function (postData) {
        return api_1.projectAPI.board.duplicateCard(postData);
    };
    return (0, useCustomMutation_1.useCustomMutation)(apiRequest, {
        onSuccess: function () {
            queryClient.invalidateQueries(constants_1.QueryKeys.project.board.allListCards);
            (0, AlertMessage_1.customSuccessMessage)(constants_1.SuccessMessages.generic.copy);
        },
        onError: function (err) {
            (0, utils_1.apiErrorMessage)(err, constants_1.ErrorMessages.generic.copy);
        },
    });
};
exports.useProjectBoardDuplicateCardAPIMutation = useProjectBoardDuplicateCardAPIMutation;
var useProjectBoardDownloadAttachmentAPIMutation = function () {
    var downloadingFileName = undefined;
    var _a = (0, download_1.useAPIDownload)(), onSuccess = _a.onSuccess, onError = _a.onError;
    var setIsAppLoading = (0, recoil_1.useSetRecoilState)(atom_1.isAppLoading);
    var apiRequest = function (_a) {
        var attachmentId = _a.attachmentId, fileName = _a.fileName;
        downloadingFileName = fileName;
        return api_1.projectAPI.board.downloadAttachment(attachmentId);
    };
    return (0, useCustomMutation_1.useCustomMutation)(apiRequest, {
        onMutate: function () {
            setIsAppLoading(true);
        },
        onSuccess: function (result) {
            onSuccess(result.data, downloadingFileName);
        },
        onError: function (err) {
            onError(err);
        },
        onSettled: function () {
            setIsAppLoading(false);
            downloadingFileName = undefined;
        },
    });
};
exports.useProjectBoardDownloadAttachmentAPIMutation = useProjectBoardDownloadAttachmentAPIMutation;
var useProjectBoardDeleteProjectAttachmentAPIMutation = function () {
    return (0, useCustomMutation_1.useCustomMutation)(api_1.projectAPI.board.deleteAttachment, {
        onSuccess: function (response) {
            (0, AlertMessage_1.customSuccessMessage)(constants_1.SuccessMessages.files.delete);
        },
        onError: function (err) {
            (0, utils_1.apiErrorMessage)(err, constants_1.ErrorMessages.files.delete);
        },
    });
};
exports.useProjectBoardDeleteProjectAttachmentAPIMutation = useProjectBoardDeleteProjectAttachmentAPIMutation;
var useProjectBoardChangeCardPositionAPIMutation = function () {
    var queryClient = (0, react_query_1.useQueryClient)();
    var apiRequest = function (_a) {
        var cardId = _a.cardId, position = _a.position;
        var postData = {
            listId: position.to.toColumnId,
            position: position.to.toPosition,
        };
        return api_1.projectAPI.board.changeCardPosition(cardId, postData);
    };
    return (0, useCustomMutation_1.useCustomMutation)(apiRequest, {
        // NOTE(Chikama): Update cache before request to avoid resetting the state while loading
        onMutate: function (requestParams) {
            queryClient.removeQueries({ queryKey: [constants_1.QueryKeys.project.board.allListCards], inactive: true });
            var oldData = queryClient.getQueryData(constants_1.QueryKeys.project.board.allListCards);
            queryClient.setQueriesData({
                queryKey: constants_1.QueryKeys.project.board.allListCards,
                exact: false,
            }, function (oldData) {
                if (!(oldData === null || oldData === void 0 ? void 0 : oldData.results)) {
                    return oldData;
                }
                var updatedBoardData = (0, board_1.moveCardWithBoardOriginalParams)({ columns: oldData.results }, requestParams.position.from, requestParams.position.to);
                return __assign(__assign({}, oldData), { results: updatedBoardData.columns });
            });
            return { oldData: oldData };
        },
        // NOTE(Chikama): Resume original state if error happened.
        onError: function (_err, _params, context) {
            return void queryClient.setQueriesData({ queryKey: constants_1.QueryKeys.project.board.allListCards,
                exact: false, }, function () { return context === null || context === void 0 ? void 0 : context.oldData; });
        }
    });
};
exports.useProjectBoardChangeCardPositionAPIMutation = useProjectBoardChangeCardPositionAPIMutation;
