// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PersonnelInitialFormItem-container-vJWLi{width:100%;text-align:left}.PersonnelInitialFormItem-userInput-QTky_{width:50%}.PersonnelInitialFormItem-tooltip-QxQby{margin-left:5px}.PersonnelInitialFormItem-infoIcon-CkpYl{align-self:center}.PersonnelInitialFormItem-select_option-SDS2r{text-align:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/personnel/PersonnelBoardPage/PersonnelBoardSearchDrawer/PersonnelInitialFormItem/PersonnelInitialFormItem.scss"],"names":[],"mappings":"AAAA,0CACI,UAAW,CACX,eAAgB,CACnB,0CAGG,SAAU,CACb,wCAGG,eAAgB,CACnB,yCAGG,iBAAkB,CACrB,8CAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 50%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n\n.select_option {\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PersonnelInitialFormItem-container-vJWLi`,
	"userInput": `PersonnelInitialFormItem-userInput-QTky_`,
	"tooltip": `PersonnelInitialFormItem-tooltip-QxQby`,
	"infoIcon": `PersonnelInitialFormItem-infoIcon-CkpYl`,
	"select_option": `PersonnelInitialFormItem-select_option-SDS2r`
};
export default ___CSS_LOADER_EXPORT___;
