// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserSearchForm-container-bRVKg{margin:16px auto;text-align:center;border:thin solid darkgray}.UserSearchForm-searchButton-PUlVc{margin:8px auto;text-align:left}.UserSearchForm-spacer-KVTUP{margin-right:10%}.UserSearchForm-verticalSpacer-GluLP{margin:8px 0}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Forms/UserSearchForm/UserSearchForm.scss"],"names":[],"mappings":"AAAA,gCACI,gBAAiB,CACjB,iBAAkB,CAClB,0BAA2B,CAC9B,mCAGG,eAAgB,CAChB,eAAgB,CACnB,6BAGG,gBAAiB,CACpB,qCAGG,YAAa","sourcesContent":[".container {\n    margin: 16px auto;\n    text-align: center;\n    border: thin solid darkgray;\n}\n\n.searchButton {\n    margin: 8px auto;\n    text-align: left;\n}\n\n.spacer {\n    margin-right: 10%;\n}\n\n.verticalSpacer {\n    margin: 8px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `UserSearchForm-container-bRVKg`,
	"searchButton": `UserSearchForm-searchButton-PUlVc`,
	"spacer": `UserSearchForm-spacer-KVTUP`,
	"verticalSpacer": `UserSearchForm-verticalSpacer-GluLP`
};
export default ___CSS_LOADER_EXPORT___;
