// A module to communicate with backend APIs.
import axios from "axios";
import iconv from "iconv-lite";
import paramsToQueryString, { deleteEmptyValue } from "./query";

const defaultValidationErrorMessage =
    "入力内容に誤りがあります。各フィールドに表示されたエラー内容を確認し、再入力してください。";

export class Endpoint {
    // TODO:Change endpoint URL from app_staffing to apis.
    static getBaseUrl = () =>
        `${window.location.protocol}//${window.location.host}/app_staffing`;

    static None = "";

    static versionInfoPath = "version";

    static myProfilePath = "my_profile";

    static myCompanyPath = "my_company";
    static startupDiscount = "company/discount_startup";

    static users = "users";
    static usersActive = "users/active";
    static passwordResetMail = "password";
    static passwordChange = "password_change";

    static usersRegister = "users/register";

    static contacts = "contacts";

    static contactsCsvUpload = "contacts/csv_upload";

    static contactsArchive = "contacts/archive";

    static contactsCsv = "csv/contacts";

    static contactsFullCsv = "csv/contacts_full";

    static contactEmailPreferenceSuffix = "/preference";

    static personnelBoard = "board/personnel/cards";

    static projectBoard = "board/project/cards";

    static organizations = "organizations";

    static organizationsBranches = "organizations/branches";

    static organizationsCsvUpload = "organizations/csv_upload";

    static organizationBlockList = "organizations/block";

    static organizationsCsv = "csv/organizations";

    static organizationStatistics = "statistics/organizations";

    static emailCheck = "emails/check";

    static contactEmailPreferences = "contact_preference";

    static copyActionSuffix = "copy";

    static previewActionSuffix = "preview";

    static commentUrlSuffix = "comments";

    static organizationColumnSetting = "column_setting/organization";

    static contactColumnSetting = "column_setting/contact";

    static tags = "tags";

    static commentTemplateOrganization = "comment_template/organization";

    static commentTemplateContact = "comment_template/contact";

    static commentTemplateSharedEmail = "comment_template/share-mails";

    static commentTemplatePersonnelBoard = "comment_template/personnel";

    static commentTemplateProjectBoard = "comment_template/project";

    static commentTemplateRecruitBoard = "comment_template/recruit";

    static commentTemplateSalesBoard = "comment_template/sales";

    static commentTemplateBackOfficeBoard = "comment_template/back_office";
    
    static checkListTemplatePersonnelBoard = "check_list_template/personnel";

    static checkListTemplateProjectBoard = "check_list_template/project";

    static checkListTemplateRecruitBoard = "check_list_template/recruit";

    static checkListTemplateSalesBoard = "check_list_template/sales";

    static checkListTemplateBackOfficeBoard = "check_list_template/back_office";

    static organizationSearchTemplate = "search_template/organization";

    static contactSearchTemplate = "search_template/contact";

    static sharedMailSearchTemplate = "search_template/share-mails";

    static contactMailPreferenceSearchTemplate =
        "search_template/contact_mail_preference";

    static reservedDate = "reserved_date";

    static displaySetting = "display_setting";

    static userDisplaySetting = "user_display_setting";

    static sharedEmailSetting = "shared_email_setting";

    static dashboardStatistics = "statistics/dashboard";

    static sharedEmailConnection = "shared_email_connection";

    static authorizedAction = "authorized_action";

    static purchasedAddons = "addon/list";

    static purchaseAddon = "addon/purchase";

    static revokePurchasedAddon = "addon/revoke";

    static addonMaster = "addonmaster";

    static purchaseHistory = "purchase_history";

    static purchaseHistoryDownLoad = "noticed_download_payment_history";

    static systemNotifications = "system_notifications";

    static actionNotifications = "action_notifications";

    static actionNotificationSetting = "action_notification_settings";

    static reminder = "reminder";

    static payjpPayment = "payjp_payment";

    // 共有メール
    static sharedEmails = "share-mails";
    static sharedEmailNotifications = "notifications/share-emails";
    static sharedEmailAttachments = "share-mails_attachments";
    static shardEmailStatistics = "statistics/share-mails";
    static shareEmailNotificationSettingAssignees =
        "shared_email_notification_user";

    // 配信メール
    static scheduledEmails = "scheduled_mails";
    static scheduledEmailAttachments = "scheduled_mail_attachments";
    static scheduledEmailAttachmentsSuffix = "attachments";
    static scheduledEmailOpenerList = "scheduled_email_open_history";
    static scheduledEmailSetting = "scheduled_email_setting";
    static scheduledEmailConnection = "scheduled_email_connection";
    static scheduledEmailTestSending = "scheduled_email_test_sending";
    static scheduledEmailColumnSetting = "column_setting/scheduled_mail";
    static scheduledEmailSearchTemplate = "search_template/scheduled_email";
    static scheduledEmailAttachmentSizeLimit =
        "scheduled_mail_attachments/limit";
    static scheduledEmailFiles = "scheduled_mail/files";
    static scheduledEmailFilesDownload = "scheduled_mail/files/download";
    static scheduledEmailNgWords = "scheduled_mails/ng_words";
    static scheduledEmailsPreview = "scheduled_mails/base_information_preview";
    static scheduledEmailsEdit = "scheduled_mails/edit";

    // ご利用プラン関連
    static plan = "plan";
    static planMaster = "plan_master";
    static addUserCount = "add_user_count";
    static deleteUserCount = "remove_user_count";
    static planSummary = "plan_summary";

    // アカウント関連
    static account = "account";

    // テナント関連
    static tenantSitekey = "tenant/sitekey";
    static tenantRegister = "tenant/register";
    static tenantMyCompany = "tenant/my_company";
    static tenantMyProfile = "tenant/my_profile";
    static tenantPayment = "tenant/payment";
    static tenantCurrentStep = "tenant/current_step";
    static tenantFinish = "tenant/finish";

    // アバター
    static thumbnail = "users/avatar";

    static personnelSearchTemplate = "search_template/personnel";
    static personnelFilterUrl = "board/personnel/lists/cards";
    static projectSearchTemplate = "search_template/project";
    static projectFilterUrl = "board/project/lists/cards";

    static scheduledMailsTemplate = "scheduled_mails/template";
    static scheduledMailsHistory = "scheduled_mails/history";
    static actionMention = "action_mention";

    // 取引先
    static organization = {
        simple: "organizations/names",
    };

    // 取引先担当者
    static contact = {
        simple: "contacts/names",
    };

    // ユーザー
    static user = {
        simple: "users/names",
    };

    // タグ
    static tag = {
        simple: "tags/names",
        csvUpload: "tags/csv_upload",
    };

    // 検索テンプレート
    static searchTemplate = {
        customer: {
            organization: "search_template/organization",
            contact: "search_template/contact",
        },
        scheduledEmail: {
            list: "search_template/scheduled_email",
        },
        sharedEmail: {
            list: "search_template/share-mails",
        },
        matching: {
            sharedEmailNotification: "search_template/shared-mail-notification",
        },
        personnel: {
            board: "search_template/personnel",
            gantt: "search_template/personnel_gantt",
            email: "board_mail_template/personnel",
        },
        project: {
            board: "search_template/project",
            gantt: "search_template/project_gantt",
            email: "board_mail_template/project",
        },
        recruit: {
            board: "search_template/recruit",
            gantt: "search_template/recruit_gantt",
        },
        sales: {
            board: "search_template/sales",
            gantt: "search_template/sales_gantt",
        },
        backOffice: {
            board: "search_template/back_office",
            gantt: "search_template/back_office_gantt",
        },
    };

    // 表示テンプレート
    // NOTE: 検索テンプレートAPIを流用
    //       経緯については https://trello.com/c/nim2lGmu/ を参照
    static displayTemplate = {
        dashboard: {
            list: "search_template/dashboard-display"
        },
    };

    // 要員
    static personnel = {
        board: "board/personnel",
        gantt: "board/personnel/gantt",
        csvUpload: "board/personnel/csv_upload",
        dynamicRowTitles: "board/personnel/dynamic_row_titles",
    };

    // 案件
    static project = {
        board: "board/project",
        gantt: "board/project/gantt",
        csvUpload: "board/project/csv_upload",
        dynamicRowTitles: "board/project/dynamic_row_titles",
    };

    // 採用
    static recruitBoard = "board/recruit/cards";
    static recruit = {
        board: "board/recruit",
        gantt: "board/recruit/gantt",
        csvUpload: "board/recruit/csv_upload",
        dynamicRowTitles: "board/recruit/dynamic_row_titles",
    };

    // 営業
    static salesBoard = "board/sales/cards";
    static sales = {
        board: "board/sales",
        gantt: "board/sales/gantt",
        csvUpload: "board/sales/csv_upload",
        dynamicRowTitles: "board/sales/dynamic_row_titles",
    };

    // BO
    static backOfficeBoard = "board/back_office/cards";
    static backOffice = {
        board: "board/back_office",
        gantt: "board/back_office/gantt",
        csvUpload: "board/back_office/csv_upload",
        dynamicRowTitles: "board/back_office/dynamic_row_titles",
    };

    // 添付ファイルダウンロード
    static attachmentDownloadHistory =
        "scheduled_email_attachment_download_history";
}

export class BadRequestError extends Error {
    constructor(response, responseDataConverter) {
        super();
        this.detail = response.data.detail || undefined;
        this.field_errors = response.data
            ? responseDataConverter(response.data)
            : [];
        this.message = this.detail
            ? this.detail.toString()
            : defaultValidationErrorMessage;
        this.code = "ERR_400";
    }
}

export class AuthorizationError extends Error {
    constructor(response) {
        super();
        this.message = `認証に失敗しました。ユーザー情報をお確かめください。`;
        this.code = "ERR_401";
    }
}

export class NotFoundError extends Error {
    constructor(response) {
        super();
        this.message =
            "ページが見つかりませんでした。削除された可能性があります。";
        this.code = "ERR_404";
        this.lastPageNumber = response.data.lastPageNumber; // Valid if pagination cause out of page error.
    }
}

export class ServerError extends Error {
    constructor(response) {
        super();
        this.message = `サーバーエラーが発生しました。しばらく時間を置いてから再度お試しいただくか、サポートまでお問い合わせください。`;
        this.code = "ERR_500";
    }
}

export class TemporallyUnavailableError extends Error {
    constructor(response) {
        super();
        this.message = `一時的にサービスが停止しています。申し訳ございませんが、復旧をお待ちください。`;
        this.code = "ERR_503";
    }
}

export class RequestError extends Error {
    constructor(response) {
        super();
        this.message = `操作を実行することができません。: ${response.data.detail}`;
        this.code = "ERR_4XX";
    }
}

export class ConnectionError extends Error {
    constructor() {
        super();
        this.message =
            "サーバーに接続できませんでした。通信が可能な環境かどうか確認してください。";
        this.code = "ERR_600";
    }
}

export class ClientError extends Error {
    constructor() {
        super();
        this.message =
            "リクエストの生成自体に失敗しました。開発者にお問い合わせください。";
        this.code = "ERR_700";
    }
}

const defaultResponseDataConverter = (data) => data;

const orderStringToSymbol = (sortOrder) => {
    if (sortOrder === "ascend") {
        return "";
    }
    if (sortOrder === "descend") {
        return "-";
    }
    throw Error(
        "渡されたソートのパラメータが不正です。(内部バグ) 開発者にお問い合わせください。"
    );
};

const createSortParamsForApi = (sortParams) => {
    // Note: Only the first element of the dictionary will be used.
    const sortKey = Object.keys(sortParams)[0];
    const sortOrder = sortParams[sortKey];
    const orderingSymbol = orderStringToSymbol(sortOrder);
    return { ordering: `${orderingSymbol}${sortKey}` };
};

export class MyAPI {
    handleError = (error) => {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error(error.message, error.response.data);
            if (error.response.status === 400) {
                return Promise.reject(
                    new BadRequestError(
                        error.response,
                        this.responseDataConverter
                    )
                );
            }
            if (error.response.status === 401) {
                return Promise.reject(new AuthorizationError(error.response));
            }
            if (error.response.status === 404) {
                return Promise.reject(new NotFoundError(error.response));
            }
            if (error.response.status > 400 && error.response.status < 500) {
                return Promise.reject(new RequestError(error.response));
            }
            if (error.response.status >= 502 && error.response.status <= 504) {
                return Promise.reject(
                    new TemporallyUnavailableError(error.response)
                );
            }
            return Promise.reject(new ServerError(error.response));
        }
        if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.error(error.message, error.request);
            return Promise.reject(new ConnectionError());
        }
        // Something happened in setting up the request that triggered an Error
        console.error(error.message, {});
        return Promise.reject(new ClientError());
    };

    createListResult = (response) => ({
        total: response.data.count,
        data: this.responseDataConverter(response.data.results),
    });

    createSingleResult = (response) => ({
        data: this.responseDataConverter(response.data),
    });

    getDefaultConfig = (BaseUrl, token) => ({
        baseURL: BaseUrl,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
        },
    });

    constructor(
        baseUrl,
        token,
        responseDataConverter = defaultResponseDataConverter
    ) {
        this.baseUrl = baseUrl;
        this.client = axios.create(this.getDefaultConfig(this.baseUrl, token));
        this.responseDataConverter = (data) =>
            Array.isArray(data)
                ? data.map(responseDataConverter)
                : responseDataConverter(data);
    }

    /**
     *
     * @param {object} params: A Dictionary object that represent query params key-value pairs.
     * @param {function(object):object} keyConverter: A function that convert form/table-sorter params to API params.
     * @param {number} pageNumber: A page number to fetch.
     * @param {number} pageSize: A number how many entries to fetch at a time.
     * @param {string} sortKey: A key name that will be used to sort.
     * @param {string} sortOrder: A sort order, either of 'descend' / 'ascend'
     * @returns {Promise<{total: *, data: *} | never>}
     */
    search = (
        params,
        keyConverter = (obj) => obj,
        pageNumber,
        pageSize,
        sortKey,
        sortOrder
    ) => {
        const searchQueryString = paramsToQueryString(keyConverter(params));
        const pagenationQueryString = paramsToQueryString({
            page: pageNumber,
            page_size: pageSize,
        });

        const sortParams =
            sortKey && sortOrder ? { [sortKey]: sortOrder } : undefined;
        const sortParamsForApi =
            sortParams && Object.keys(sortParams).length
                ? createSortParamsForApi(sortParams)
                : undefined;
        const sortQueryString = sortParamsForApi
            ? `&${paramsToQueryString(sortParamsForApi)}`
            : "";

        const endpoint = `${this.baseUrl}?${searchQueryString}&${pagenationQueryString}${sortQueryString}`;
        return this.client.get(endpoint).then(
            (response) => Promise.resolve(this.createListResult(response)),
            (error) => {
                this.handleError(error);
                throw new Error("");
            }
        );
    };

    list = (pageNumber, pageSize) => {
        const endpoint = `${this.baseUrl}?page=${pageNumber}&page_size=${pageSize}`;

        return this.client.get(endpoint).then(
            (response) => Promise.resolve(this.createListResult(response)),
            (error) => this.handleError(error)
        );
    };

    get = (resourceId) => {
        const endpoint = resourceId
            ? `${this.baseUrl}/${resourceId}`
            : this.baseUrl;
        return this.client.get(endpoint).then(
            (response) => Promise.resolve(this.createSingleResult(response)),
            (error) => this.handleError(error)
        );
    };

    getByParam = (resourceId, paramSearch) => {
        const searchQueryString = paramsToQueryString(paramSearch);
        const endpoint = resourceId
            ? `${this.baseUrl}/${resourceId}${"?" + searchQueryString}`
            : this.baseUrl;
        return this.client.get(endpoint).then(
            (response) => Promise.resolve(this.createSingleResult(response)),
            (error) => this.handleError(error)
        );
    };

    post = (payload) => {
        const endpoint = this.baseUrl;
        return this.client.post(endpoint, payload).then(
            (response) => Promise.resolve(this.createSingleResult(response)),
            (error) => this.handleError(error)
        );
    };

    patch = (resourceId, payload) => {
        const endpoint = `${this.baseUrl}/${resourceId}`.replace(
            /(\/|\/undefined)$/,
            ""
        );
        return this.client.patch(endpoint, payload).then(
            (response) => Promise.resolve(this.createSingleResult(response)),
            (error) => this.handleError(error)
        );
    };

    delete = (resourceId) => {
        const endpoint = `${this.baseUrl}/${resourceId}`.replace(
            /(\/|\/undefined)$/,
            ""
        );
        return this.client.delete(endpoint).then(
            () => null,
            (error) => this.handleError(error)
        );
    };

    objectDelete = (postData) => {
        const endpoint = this.baseUrl;
        return this.client.delete(endpoint, { data: { ...postData } }).then(
            (response) => response,
            (error) => this.handleError(error)
        );
    };

    bulkDelete = (data) => {
        const endpoint = this.baseUrl;
        return this.client.delete(endpoint, { data: { source: data } }).then(
            () => null,
            (error) => this.handleError(error)
        );
    };

    bulkUpdate = (data, column, value) => {
        const endpoint = this.baseUrl;
        return this.client
            .patch(endpoint, { source: data, column: column, ...value })
            .then(
                () => null,
                (error) => this.handleError(error)
            );
    };
}

export const openFileDialogue = (blob, fileName) => {
    const internalUrl = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = internalUrl;
    link.setAttribute("download", fileName);
    link.click();
};

export const downloadFile = (authToken, url, filename) =>
    axios({
        url,
        headers: {
            Accept: "application/octet-stream",
            Authorization: `Token ${authToken}`,
        },
        method: "GET",
        responseType: "blob",
    }).then((response) => openFileDialogue(response.data, filename));

export const downloadCsv = (authToken, url, filename) =>
    axios({
        url,
        headers: { Accept: "text/csv", Authorization: `Token ${authToken}` },
        method: "GET",
        responseType: "text",
    }).then((response) => {
        // Convert charset to Shift-JIS to optimize for Microsoft Excel.
        const convertedText = iconv.encode(response.data, "SJIS");
        const newBlob = new Blob([convertedText], { type: "text/csv" });
        openFileDialogue(newBlob, filename);
    });

export const downloadZipFile = (authToken, url, filename) =>
    axios({
        url,
        headers: { Authorization: `Token ${authToken}` },
        method: "POST",
    }).then((response) => {
        openFileDialogue(newBlob, filename);
    });

export const getRevisionNumber = () => {
    const url = `${Endpoint.getBaseUrl()}/${Endpoint.versionInfoPath}`;
    return axios
        .get(url)
        .then((response) => response.data.revision)
        .catch(() => 0);
};
