// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ScheduledEmailDownloadAttachmentPage-tableContainer-ONVY2 div{text-align:left;margin:10px 0}.ScheduledEmailDownloadAttachmentPage-buttonDownloadCSV-w6MhX{margin-top:10px}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/ScheduledEmailDownloadAttachmentPage/ScheduledEmailDownloadAttachmentPage.scss"],"names":[],"mappings":"AACA,+DACI,eAAgB,CAChB,aAAc,CACjB,8DAEG,eACJ","sourcesContent":["\n.tableContainer div{\n    text-align: left;\n    margin: 10px 0;\n}\n.buttonDownloadCSV{\n    margin-top: 10px\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableContainer": `ScheduledEmailDownloadAttachmentPage-tableContainer-ONVY2`,
	"buttonDownloadCSV": `ScheduledEmailDownloadAttachmentPage-buttonDownloadCSV-w6MhX`
};
export default ___CSS_LOADER_EXPORT___;
