// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ScheduledMailTemplateFormModal-container-K1a9L{margin:16px auto;text-align:left;width:100%}.ScheduledMailTemplateFormModal-row-qpePF{padding-right:10%}.ScheduledMailTemplateFormModal-field-KGuW8{margin:24px auto}.ScheduledMailTemplateFormModal-contentField-cIYQP{margin:24px auto 2px}.ScheduledMailTemplateFormModal-fieldTemplate-GZWFB div:nth-child(1) label{visibility:hidden}.ScheduledMailTemplateFormModal-controlButton-DhADL{margin-right:5px}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/DataDisplay/ScheduledMail/ScheduledMailTemplateFormModal/ScheduledMailTemplateFormModal.scss"],"names":[],"mappings":"AAAA,gDACE,gBAAiB,CACjB,eAAgB,CAChB,UAAW,CACZ,0CAGC,iBAAkB,CACnB,4CAGC,gBAAiB,CAClB,mDAGC,oBAAqB,CACtB,2EAKK,iBAAkB,CACnB,oDAKH,gBAAiB","sourcesContent":[".container {\n  margin: 16px auto;\n  text-align: left;\n  width: 100%;\n}\n\n.row {\n  padding-right: 10%;\n}\n\n.field {\n  margin: 24px auto;\n}\n\n.contentField {\n  margin: 24px auto 2px;\n}\n\n.fieldTemplate {\n  div:nth-child(1) {\n    label {\n      visibility: hidden;\n    }\n  }\n}\n\n.controlButton {\n  margin-right: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ScheduledMailTemplateFormModal-container-K1a9L`,
	"row": `ScheduledMailTemplateFormModal-row-qpePF`,
	"field": `ScheduledMailTemplateFormModal-field-KGuW8`,
	"contentField": `ScheduledMailTemplateFormModal-contentField-cIYQP`,
	"fieldTemplate": `ScheduledMailTemplateFormModal-fieldTemplate-GZWFB`,
	"controlButton": `ScheduledMailTemplateFormModal-controlButton-DhADL`
};
export default ___CSS_LOADER_EXPORT___;
