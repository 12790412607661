// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PerGNameFormItem-container-hucFm{width:100%;text-align:left}.PerGNameFormItem-userInput-rzKul{width:100%}.PerGNameFormItem-tooltip-PPgK_{margin-left:5px}.PerGNameFormItem-infoIcon-mao4W{align-self:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/personnel/PersonnelGanttPage/PersonnelGanttSearchDrawer/PerGNameFormItem/PerGNameFormItem.scss"],"names":[],"mappings":"AAAA,kCACI,UAAW,CACX,eAAgB,CACnB,kCAGG,UAAW,CACd,gCAGG,eAAgB,CACnB,iCAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PerGNameFormItem-container-hucFm`,
	"userInput": `PerGNameFormItem-userInput-rzKul`,
	"tooltip": `PerGNameFormItem-tooltip-PPgK_`,
	"infoIcon": `PerGNameFormItem-infoIcon-mao4W`
};
export default ___CSS_LOADER_EXPORT___;
