// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InfoTooltip-tooltip-WwHet{margin-left:5px}.InfoTooltip-infoIcon-rEsux{align-self:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/project/ProjectBoardPage/ProjectBoardSearchDrawer/ProjectPriceFormItem/InfoTooltip/InfoTooltip.scss"],"names":[],"mappings":"AAAA,2BACI,eAAgB,CACnB,4BAGG,iBAAkB","sourcesContent":[".tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": `InfoTooltip-tooltip-WwHet`,
	"infoIcon": `InfoTooltip-infoIcon-rEsux`
};
export default ___CSS_LOADER_EXPORT___;
