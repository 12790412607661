// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProjectEndWorkingHourFormItem-container-fPvVm{width:100%;text-align:left}.ProjectEndWorkingHourFormItem-userInput-M3Uz5{width:100%}.ProjectEndWorkingHourFormItem-tooltip-OYr6u{margin-left:5px}.ProjectEndWorkingHourFormItem-infoIcon-gKTD0{align-self:center}.ProjectEndWorkingHourFormItem-customDatePicker-O03WY{width:100%;height:35px}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/project/ProjectBoardPage/ProjectBoardSearchDrawer/ProjectEndWorkingHourFormItem/ProjectEndWorkingHourFormItem.scss"],"names":[],"mappings":"AAAA,+CACE,UAAW,CACX,eAAgB,CACjB,+CAGC,UAAW,CACZ,6CAGC,eAAgB,CACjB,8CAGC,iBAAkB,CACnB,sDAGC,UAAW,CACX,WAAY","sourcesContent":[".container {\n  width: 100%;\n  text-align: left;\n}\n\n.userInput {\n  width: 100%;\n}\n\n.tooltip {\n  margin-left: 5px;\n}\n\n.infoIcon {\n  align-self: center;\n}\n\n.customDatePicker {\n  width: 100%;\n  height: 35px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ProjectEndWorkingHourFormItem-container-fPvVm`,
	"userInput": `ProjectEndWorkingHourFormItem-userInput-M3Uz5`,
	"tooltip": `ProjectEndWorkingHourFormItem-tooltip-OYr6u`,
	"infoIcon": `ProjectEndWorkingHourFormItem-infoIcon-gKTD0`,
	"customDatePicker": `ProjectEndWorkingHourFormItem-customDatePicker-O03WY`
};
export default ___CSS_LOADER_EXPORT___;
