// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NotificationPopover-unreadBadge-YMEnk{padding-right:10px;color:#e1585a}.NotificationPopover-noticeScroll-LF4RV{height:250px;width:300px;overflow-y:auto;overflow-x:hidden}.NotificationPopover-listItemNotice-v03dr{margin-top:0;padding-top:0;overflow-x:hidden}.NotificationPopover-actionNoticeDescripitonWrapper-HsiDB{border-left-style:solid;border-left-width:1px;border-left-color:lightgray;margin-left:5px}.NotificationPopover-actionNoticeUserMention-OOOrD{font-size:12px;font-weight:bold}.NotificationPopover-actionNoticeDescripiton-SwudX{padding-right:5px}.NotificationPopover-actionNoticeFooter-GUUJ7{display:flex;justify-content:space-between}.NotificationPopover-actionItem-XUnjT .ant-row{width:100%}.NotificationPopover-linkIcon-DMbJR{display:flex;align-items:center;font-size:12px}.NotificationPopover-text-cZJDP{font-size:12;font-weight:bold}.NotificationPopover-fontSizeText-_msbw{font-size:12}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/DataDisplay/NotificationPopover/NotificationPopover.scss"],"names":[],"mappings":"AAAA,uCACI,kBAAmB,CACnB,aAAc,CACjB,wCAGC,YAAa,CACb,WAAY,CACZ,eAAgB,CAChB,iBAAkB,CACnB,0CAGC,YAAa,CACb,aAAc,CACd,iBAAkB,CACnB,0DAGC,uBAAwB,CACxB,qBAAsB,CACtB,2BAA4B,CAC5B,eAAgB,CACjB,mDAGC,cAAe,CACf,gBAAiB,CAClB,mDAGC,iBAAkB,CACnB,8CAGC,YAAa,CACb,6BAA8B,CAC/B,+CAKK,UACF,CAAC,oCAID,YAAa,CACb,kBAAmB,CACnB,cAAe,CAClB,gCAGC,YAAa,CACb,gBACF,CAAC,wCAGC,YAAa","sourcesContent":[".unreadBadge {\n    padding-right: 10px;\n    color: #e1585a;\n}\n\n.noticeScroll {\n  height: 250px;\n  width: 300px;\n  overflow-y: auto;\n  overflow-x: hidden;\n}\n\n.listItemNotice {\n  margin-top: 0;\n  padding-top: 0;\n  overflow-x: hidden;\n}\n\n.actionNoticeDescripitonWrapper {\n  border-left-style: solid;\n  border-left-width: 1px;\n  border-left-color: lightgray;\n  margin-left: 5px;\n}\n\n.actionNoticeUserMention {\n  font-size: 12px;\n  font-weight: bold;\n}\n\n.actionNoticeDescripiton {\n  padding-right: 5px;\n}\n\n.actionNoticeFooter {\n  display: flex;\n  justify-content: space-between;\n}\n\n.actionItem {\n  :global(.ant-row)\n    {\n      width: 100%\n    }\n}\n\n.linkIcon {\n    display: flex;\n    align-items: center;\n    font-size: 12px;\n}\n\n.text{\n  font-size: 12;\n  font-weight: bold\n}\n\n.fontSizeText {\n  font-size: 12;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"unreadBadge": `NotificationPopover-unreadBadge-YMEnk`,
	"noticeScroll": `NotificationPopover-noticeScroll-LF4RV`,
	"listItemNotice": `NotificationPopover-listItemNotice-v03dr`,
	"actionNoticeDescripitonWrapper": `NotificationPopover-actionNoticeDescripitonWrapper-HsiDB`,
	"actionNoticeUserMention": `NotificationPopover-actionNoticeUserMention-OOOrD`,
	"actionNoticeDescripiton": `NotificationPopover-actionNoticeDescripiton-SwudX`,
	"actionNoticeFooter": `NotificationPopover-actionNoticeFooter-GUUJ7`,
	"actionItem": `NotificationPopover-actionItem-XUnjT`,
	"linkIcon": `NotificationPopover-linkIcon-DMbJR`,
	"text": `NotificationPopover-text-cZJDP`,
	"fontSizeText": `NotificationPopover-fontSizeText-_msbw`
};
export default ___CSS_LOADER_EXPORT___;
