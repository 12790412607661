"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PersonnelBoardFormsContext = void 0;
var react_1 = __importStar(require("react"));
var antd_1 = require("antd");
var ConfirmModal_1 = require("~/components/Modals/ConfirmModal");
var board_1 = require("~/hooks/personnel/board");
var react_query_1 = require("react-query");
var constants_1 = require("~/utils/constants");
var utils_1 = require("~/utils/utils");
var commentActions_1 = require("~/actionCreators/commentActions");
var react_redux_1 = require("react-redux");
var utils_2 = require("~/components/Pages/personnel/PersonnelBoardPage/utils");
var store_1 = require("~/models/store");
var actionTypes_1 = require("~/actions/actionTypes");
var AlertMessage_1 = require("~/components/Common/AlertMessage/AlertMessage");
var searchTemplate_1 = require("~/hooks/searchTemplate");
exports.PersonnelBoardFormsContext = (0, react_1.createContext)(undefined);
var PersonnelBoardFormsProvider = function (_a) {
    var children = _a.children;
    // NOTE(joshua-hashimoto): states
    var _b = (0, react_1.useState)(false), isModalOpen = _b[0], setIsModalOpen = _b[1];
    var _c = (0, react_1.useState)(false), isInvalid = _c[0], setIsInvalid = _c[1];
    var _d = (0, react_1.useState)(false), cardError = _d[0], setCardError = _d[1];
    var _e = (0, react_1.useState)(false), isBaseInfoError = _e[0], setIsBaseInfoError = _e[1];
    var _f = (0, react_1.useState)(false), isContractError = _f[0], setIsContractError = _f[1];
    var _g = (0, react_1.useState)(false), isEmailTemplateError = _g[0], setIsEmailTemplateError = _g[1];
    // NOTE(joshua-hashimoto): third party hooks
    var queryClient = (0, react_query_1.useQueryClient)();
    var dispatch = (0, react_redux_1.useDispatch)();
    // NOTE(joshua-hashimoto): Forms
    var cardInfoForm = antd_1.Form.useForm()[0];
    var baseInfoForm = antd_1.Form.useForm()[0];
    var contractsForm = antd_1.Form.useForm()[0];
    var scheduledEmailTemplateForm = antd_1.Form.useForm()[0];
    var copyForm = antd_1.Form.useForm()[0];
    // NOTE(joshua-hashimoto): API methods
    var _h = (0, board_1.usePersonnelBoardUpdateCardAPIMutation)(), updateCard = _h.mutate, isCardUpdating = _h.isLoading;
    var deleteCard = (0, board_1.usePersonnelBoardDeleteCardAPIMutation)().mutate;
    var copyCard = (0, board_1.usePersonnelBoardDuplicateCardAPIMutation)().mutate;
    var _j = (0, board_1.usePersonnelBoardCreateUpdateContractAPIMutation)(), createUpdateContracts = _j.mutate, isContractsUpdating = _j.isLoading;
    var _k = (0, board_1.usePersonnelBoardUpdateScheduledMailTemplateMutateAPIMutation)(), patchScheduledEmailTemplate = _k.mutate, isScheduledEmailTemplateUpdating = _k.isLoading;
    var createMailTemplate = (0, searchTemplate_1.useSearchMailTemplateCreatePersonnelBoardAPIMutation)().mutate;
    var deleteMailTemplate = (0, searchTemplate_1.useSearchMailTemplateDeletePersonnelBoardAPIMutation)().mutate;
    var newCommentState = (0, store_1.useTypedSelector)(function (state) { return state.personnelBoardDetailPageNewComments; });
    var editCommentState = (0, store_1.useTypedSelector)(function (state) { return state.personnelBoardDetailPageEditComments; });
    var replyCommentState = (0, store_1.useTypedSelector)(function (state) {
        return state.personnelBoardDetailPageReplyComments;
    });
    var onUpdate = function (cardId, onSuccess, onSettled) {
        var contractsFormValues = contractsForm.getFieldsValue();
        if (!(0, utils_1.isEmpty)(contractsFormValues) &&
            !!contractsFormValues.contracts &&
            contractsFormValues.contracts.some(function (contract) { return !contract.detail; })) {
            (0, AlertMessage_1.customErrorMessage)(constants_1.ErrorMessages.personnel.board.detailEmpty);
            return;
        }
        onUpdateCard(cardId, onSuccess, onSettled);
        onUpdateContracts(cardId);
        onUpdateScheduledEmailTemplate(cardId);
    };
    var onUpdateAndContinue = function (cardId) {
        onUpdate(cardId, function (response) {
            var data = response.data;
            var id = data.id;
            if (id) {
                queryClient.invalidateQueries([
                    constants_1.QueryKeys.personnelBoard.card,
                    { cardId: id },
                ]);
            }
        });
    };
    var onUpdateAndFinish = function (cardId, onAction) {
        onUpdate(cardId, function (response) {
            queryClient.invalidateQueries([
                constants_1.QueryKeys.personnel.board.allListCards,
            ]);
        }, function () {
            onModalClose();
            onAction();
        });
    };
    var onUpdateCard = function (cardId, onSuccess, onSettled) { return __awaiter(void 0, void 0, void 0, function () {
        var commentExists, newCommentValue, editCommentValue, replyCommentValue, cardInfoValues, baseInfoValues, postData, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    commentExists = false;
                    if (newCommentState !== undefined &&
                        typeof newCommentState === "object") {
                        newCommentValue = newCommentState.commentValue;
                        if (newCommentValue) {
                            dispatch({
                                type: actionTypes_1.PARENT_CANNOT_SUBMIT_WHEN_NEW_COMMENT_EXISTS,
                            });
                            commentExists = true;
                        }
                    }
                    if (editCommentState !== undefined &&
                        typeof editCommentState === "object") {
                        editCommentValue = editCommentState.commentValue;
                        if (editCommentValue) {
                            dispatch({
                                type: actionTypes_1.PARENT_CANNOT_SUBMIT_WHEN_EDIT_COMMENT_EXISTS,
                            });
                            commentExists = true;
                        }
                    }
                    if (replyCommentState !== undefined &&
                        typeof replyCommentState === "object") {
                        replyCommentValue = replyCommentState.commentValue;
                        if (replyCommentValue) {
                            dispatch({
                                type: actionTypes_1.PARENT_CANNOT_SUBMIT_WHEN_REPLY_COMMENT_EXISTS,
                            });
                            commentExists = true;
                        }
                    }
                    if (commentExists) {
                        return [2 /*return*/];
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, , 5]);
                    return [4 /*yield*/, cardInfoForm.validateFields()];
                case 2:
                    cardInfoValues = _a.sent();
                    return [4 /*yield*/, baseInfoForm.validateFields()];
                case 3:
                    baseInfoValues = _a.sent();
                    postData = __assign(__assign({}, cardInfoValues), baseInfoValues);
                    updateCard({
                        cardId: cardId,
                        postData: postData,
                    }, {
                        onSuccess: onSuccess,
                        onSettled: onSettled,
                    });
                    return [3 /*break*/, 5];
                case 4:
                    err_1 = _a.sent();
                    console.error(err_1);
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var onUpdateContracts = function (cardId, onSettled) {
        var values = contractsForm.getFieldsValue();
        var isCallable = !(0, utils_1.isEmpty)(values) && !!values.contracts;
        if (isCallable) {
            createUpdateContracts({ cardId: cardId, postData: values.contracts }, { onSettled: onSettled });
        }
    };
    var onUpdateScheduledEmailTemplate = function (cardId, onSettled) {
        try {
            var values = scheduledEmailTemplateForm.getFieldsValue();
            if (!values["personneltypeDev"]) {
                values["personneltypeDevDesigner"] = false;
                values["personneltypeDevFront"] = false;
                values["personneltypeDevServer"] = false;
                values["personneltypeDevPm"] = false;
                values["personneltypeDevOther"] = false;
                values["personnelskillDevYouken"] = false;
                values["personnelskillDevKihon"] = false;
                values["personnelskillDevSyousai"] = false;
                values["personnelskillDevSeizou"] = false;
                values["personnelskillDevTest"] = false;
                values["personnelskillDevHosyu"] = false;
                values["personnelskillDevBeginner"] = false;
            }
            else if (!values["personneltypeInfra"]) {
                values["personneltypeInfraServer"] = false;
                values["personneltypeInfraNetwork"] = false;
                values["personneltypeInfraSecurity"] = false;
                values["personneltypeInfraDatabase"] = false;
                values["personneltypeInfraSys"] = false;
                values["personneltypeInfraOther"] = false;
                values["personnelskillInfraYouken"] = false;
                values["personnelskillInfraKihon"] = false;
                values["personnelskillInfraSyousai"] = false;
                values["personnelskillInfraKouchiku"] = false;
                values["personnelskillInfraTest"] = false;
                values["personnelskillInfraHosyu"] = false;
                values["personnelskillInfraKanshi"] = false;
                values["personnelskillInfraBeginner"] = false;
            }
            else if (!values["personneltypeOther"]) {
                values["personneltypeOtherEigyo"] = false;
                values["personneltypeOtherKichi"] = false;
                values["personneltypeOtherSupport"] = false;
                values["personneltypeOtherOther"] = false;
            }
            if (!values["personneltypeDevDesigner"] &&
                !values["personneltypeDevFront"] &&
                !values["personneltypeDevServer"] &&
                !values["personneltypeDevPm"] &&
                !values["personneltypeDevOther"] &&
                !values["personnelskillDevYouken"] &&
                !values["personnelskillDevKihon"] &&
                !values["personnelskillDevSyousai"] &&
                !values["personnelskillDevSeizou"] &&
                !values["personnelskillDevTest"] &&
                !values["personnelskillDevHosyu"] &&
                !values["personnelskillDevBeginner"]) {
                values["personneltypeDev"] = false;
            }
            if (!values["personneltypeInfraServer"] &&
                !values["personneltypeInfraNetwork"] &&
                !values["personneltypeInfraSecurity"] &&
                !values["personneltypeInfraDatabase"] &&
                !values["personneltypeInfraSys"] &&
                !values["personneltypeInfraOther"] &&
                !values["personnelskillInfraYouken"] &&
                !values["personnelskillInfraKihon"] &&
                !values["personnelskillInfraSyousai"] &&
                !values["personnelskillInfraKouchiku"] &&
                !values["personnelskillInfraTest"] &&
                !values["personnelskillInfraHosyu"] &&
                !values["personnelskillInfraKanshi"] &&
                !values["personnelskillInfraBeginner"]) {
                values["personneltypeInfra"] = false;
            }
            if (!values["personneltypeOtherEigyo"] &&
                !values["personneltypeOtherKichi"] &&
                !values["personneltypeOtherSupport"] &&
                !values["personneltypeOtherOther"]) {
                values["personneltypeOther"] = false;
            }
            var baseData = baseInfoForm.getFieldsValue();
            var affiliation = baseData.affiliation;
            if (!!affiliation) {
                values.jobKoyou = utils_2.mapJobKoyou[affiliation];
                values.personnelSyouryu = utils_2.mapPersonnelDistribution[affiliation];
            }
            var trainStationExists = baseData.trainStation ? true : false;
            values["wantsLocationChubuJapan"] = (trainStationExists && baseData.trainStationKey === "wants_location_chubu_japan");
            values["wantsLocationChugokuJapan"] = (trainStationExists && baseData.trainStationKey === "wants_location_chugoku_japan");
            values["wantsLocationHokkaidoJapan"] = (trainStationExists && baseData.trainStationKey === "wants_location_hokkaido_japan");
            values["wantsLocationKansaiJapan"] = (trainStationExists && baseData.trainStationKey === "wants_location_kansai_japan");
            values["wantsLocationKantoJapan"] = (trainStationExists && baseData.trainStationKey === "wants_location_kanto_japan");
            values["wantsLocationKyushuJapan"] = (trainStationExists && baseData.trainStationKey === "wants_location_kyushu_japan");
            values["wantsLocationShikokuJapan"] = (trainStationExists && baseData.trainStationKey === "wants_location_shikoku_japan");
            values["wantsLocationTouhokuJapan"] = (trainStationExists && baseData.trainStationKey === "wants_location_touhoku_japan");
            values["wantsLocationToukaiJapan"] = (trainStationExists && baseData.trainStationKey === "wants_location_toukai_japan");
            values["excludeOrganizations"] = values["excludeOrganizations"].filter(function (organization) { return organization; });
            var isCallable = !(0, utils_1.isEmpty)(values);
            if (isCallable && !!values.id) {
                patchScheduledEmailTemplate({
                    cardId: cardId,
                    templateId: values.id,
                    postData: values,
                }, {
                    onSettled: onSettled,
                });
            }
        }
        catch (err) {
            console.error(err);
        }
    };
    var onCopyCard = function (values) {
        copyCard(values, {
            onSuccess: function () {
                // NOTE(joshua-hashimoto): 更新処理をした後はモーダルを閉じるので、閉じるためのsubmitを実行
            },
        });
    };
    var onDeleteCard = function (cardId, onAction) {
        (0, ConfirmModal_1.confirmModal)({
            title: "この要員カードを削除しますか？",
            content: (react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement("p", null,
                    "OK\u3092\u62BC\u3059\u3068\u3001\u524A\u9664\u304C\u5B9F\u884C\u3055\u308C\u307E\u3059\u3002",
                    react_1.default.createElement("br", null),
                    "\u5143\u306B\u306F\u623B\u305B\u307E\u305B\u3093\u3002"),
                react_1.default.createElement("p", null,
                    "\u203B\u6848\u4EF6\u30AB\u30FC\u30C9\u306E\u5951\u7D04\u60C5\u5831\u306B\u3053\u306E\u8981\u54E1\u304C\u8FFD\u52A0\u3055\u308C\u3066\u3044\u308B\u5834\u5408\u306F\u5B9F\u884C\u3055\u308C\u307E\u305B\u3093\u3002",
                    react_1.default.createElement("br", null),
                    "\u524A\u9664\u3092\u5B9F\u884C\u3059\u308B\u306B\u306F\u6848\u4EF6\u30AB\u30FC\u30C9\u306E\u5951\u7D04\u60C5\u5831\u304B\u3089\u3053\u306E\u8981\u54E1\u3092\u524A\u9664\u3057\u3066\u304F\u3060\u3055\u3044\u3002"),
                react_1.default.createElement("p", null,
                    "\u203B\u6848\u4EF6\u30AB\u30FC\u30C9\u304B\u3089\u8981\u54E1\u3092\u524A\u9664\u3059\u308B\u65B9\u6CD5\u306B\u3064\u3044\u3066\u8A73\u3057\u304F\u306F",
                    react_1.default.createElement("a", { href: constants_1.Links.helps.personnelBoard.deletePersonnelCard, target: "_blank", rel: "noopener noreferrer" }, "\u3053\u3061\u3089"),
                    "\u3092\u3054\u89A7\u304F\u3060\u3055\u3044\u3002"))),
            onOk: function () {
                deleteCard(cardId);
                onModalClose();
                onAction();
            },
            onCancel: function () { },
        });
    };
    var onSaveTemplate = function (templateName) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9;
        var formValue = scheduledEmailTemplateForm.getFieldsValue();
        var postData = {
            template_name: templateName,
            text_format: formValue.textFormat,
            subject: formValue.subject,
            text_prefix: formValue.upper,
            text_suffix: formValue.lower,
            send_copy_to_sender: formValue.sendCopyToSender,
            send_copy_to_share: formValue.sendCopyToShare,
            send_type: {
                personneltype: (_a = formValue.personneltype) !== null && _a !== void 0 ? _a : false,
                personneltypeDev: (_b = formValue.personneltypeDev) !== null && _b !== void 0 ? _b : false,
                personneltypeInfra: (_c = formValue.personneltypeInfra) !== null && _c !== void 0 ? _c : false,
                personneltypeOther: (_d = formValue.personneltypeOther) !== null && _d !== void 0 ? _d : false,
                personneltypeDevDesigner: (_e = formValue.personneltypeDevDesigner) !== null && _e !== void 0 ? _e : false,
                personneltypeDevFront: (_f = formValue.personneltypeDevFront) !== null && _f !== void 0 ? _f : false,
                personneltypeDevServer: (_g = formValue.personneltypeDevServer) !== null && _g !== void 0 ? _g : false,
                personneltypeDevPm: (_h = formValue.personneltypeDevPm) !== null && _h !== void 0 ? _h : false,
                personneltypeDevOther: (_j = formValue.personneltypeDevOther) !== null && _j !== void 0 ? _j : false,
                personneltypeInfraServer: (_k = formValue.personneltypeInfraServer) !== null && _k !== void 0 ? _k : false,
                personneltypeInfraNetwork: (_l = formValue.personneltypeInfraNetwork) !== null && _l !== void 0 ? _l : false,
                personneltypeInfraSecurity: (_m = formValue.personneltypeInfraSecurity) !== null && _m !== void 0 ? _m : false,
                personneltypeInfraDatabase: (_o = formValue.personneltypeInfraDatabase) !== null && _o !== void 0 ? _o : false,
                personneltypeInfraSys: (_p = formValue.personneltypeInfraSys) !== null && _p !== void 0 ? _p : false,
                personneltypeInfraOther: (_q = formValue.personneltypeInfraOther) !== null && _q !== void 0 ? _q : false,
                personneltypeOtherEigyo: (_r = formValue.personneltypeOtherEigyo) !== null && _r !== void 0 ? _r : false,
                personneltypeOtherKichi: (_s = formValue.personneltypeOtherKichi) !== null && _s !== void 0 ? _s : false,
                personneltypeOtherOther: (_t = formValue.personneltypeOtherOther) !== null && _t !== void 0 ? _t : false,
                personneltypeOtherSupport: (_u = formValue.personneltypeOtherSupport) !== null && _u !== void 0 ? _u : false,
                personnelskillDevYouken: (_v = formValue.personnelskillDevYouken) !== null && _v !== void 0 ? _v : false,
                personnelskillDevKihon: (_w = formValue.personnelskillDevKihon) !== null && _w !== void 0 ? _w : false,
                personnelskillDevSyousai: (_x = formValue.personnelskillDevSyousai) !== null && _x !== void 0 ? _x : false,
                personnelskillDevSeizou: (_y = formValue.personnelskillDevSeizou) !== null && _y !== void 0 ? _y : false,
                personnelskillDevTest: (_z = formValue.personnelskillDevTest) !== null && _z !== void 0 ? _z : false,
                personnelskillDevHosyu: (_0 = formValue.personnelskillDevHosyu) !== null && _0 !== void 0 ? _0 : false,
                personnelskillDevBeginner: (_1 = formValue.personnelskillDevBeginner) !== null && _1 !== void 0 ? _1 : false,
                personnelskillInfraBeginner: (_2 = formValue.personnelskillInfraBeginner) !== null && _2 !== void 0 ? _2 : false,
                personnelskillInfraHosyu: (_3 = formValue.personnelskillInfraHosyu) !== null && _3 !== void 0 ? _3 : false,
                personnelskillInfraKanshi: (_4 = formValue.personnelskillInfraKanshi) !== null && _4 !== void 0 ? _4 : false,
                personnelskillInfraKihon: (_5 = formValue.personnelskillInfraKihon) !== null && _5 !== void 0 ? _5 : false,
                personnelskillInfraKouchiku: (_6 = formValue.personnelskillInfraKouchiku) !== null && _6 !== void 0 ? _6 : false,
                personnelskillInfraSyousai: (_7 = formValue.personnelskillInfraSyousai) !== null && _7 !== void 0 ? _7 : false,
                personnelskillInfraTest: (_8 = formValue.personnelskillInfraTest) !== null && _8 !== void 0 ? _8 : false,
                personnelskillInfraYouken: (_9 = formValue.personnelskillInfraYouken) !== null && _9 !== void 0 ? _9 : false,
            },
            exclude_organizations: formValue.excludeOrganizations,
        };
        return new Promise(function (resolve, reject) {
            createMailTemplate(postData, {
                onSuccess: function () { return resolve(); },
                onError: function () { return reject(); },
            });
        });
    };
    var onDeleteTemplate = function (templateIndex) {
        deleteMailTemplate(templateIndex);
    };
    var removeQueries = function () {
        queryClient.removeQueries(constants_1.QueryKeys.personnelBoard.card, {
            exact: false,
        });
        queryClient.removeQueries(constants_1.QueryKeys.personnelBoard.contracts, {
            exact: false,
        });
        queryClient.removeQueries(constants_1.QueryKeys.personnel.board.scheduledEmailTemplate.fetch, { exact: false });
        queryClient.removeQueries(constants_1.QueryKeys.personnelBoard.skillSheet, {
            exact: false,
        });
        queryClient.removeQueries(constants_1.QueryKeys.personnelBoard.checklist, {
            exact: false,
        });
    };
    var onClean = function () {
        cardInfoForm.resetFields();
        baseInfoForm.resetFields();
        contractsForm.resetFields();
        scheduledEmailTemplateForm.resetFields();
        removeQueries();
        copyForm.resetFields();
    };
    var onModalOpen = function () {
        setIsModalOpen(true);
    };
    var onModalClose = function () {
        dispatch(commentActions_1.CommentActions.newCommentClearAction());
        dispatch(commentActions_1.CommentActions.editCommentClearAction());
        setIsModalOpen(false);
        onClean();
    };
    var setIsArchived = function (value) {
        baseInfoForm.setFieldsValue({
            isArchived: value,
        });
    };
    var resetError = function () {
        setCardError(false);
        setIsBaseInfoError(false);
        setIsContractError(false);
        setIsEmailTemplateError(false);
    };
    var isError = cardError ||
        isBaseInfoError ||
        isContractError ||
        isEmailTemplateError;
    var isUpdating = isCardUpdating ||
        isContractsUpdating ||
        isScheduledEmailTemplateUpdating;
    return (react_1.default.createElement(exports.PersonnelBoardFormsContext.Provider, { value: {
            isModalOpen: isModalOpen,
            onModalOpen: onModalOpen,
            onModalClose: onModalClose,
            setIsArchived: setIsArchived,
            cardInfoForm: cardInfoForm,
            baseInfoForm: baseInfoForm,
            contractsForm: contractsForm,
            scheduledEmailTemplateForm: scheduledEmailTemplateForm,
            copyForm: copyForm,
            onUpdateCard: onUpdateCard,
            onUpdateScheduledEmailTemplate: onUpdateScheduledEmailTemplate,
            onUpdateContracts: onUpdateContracts,
            onUpdateAndContinue: onUpdateAndContinue,
            onUpdateAndFinish: onUpdateAndFinish,
            onCopyCard: onCopyCard,
            onDeleteCard: onDeleteCard,
            onClean: onClean,
            onSaveTemplate: onSaveTemplate,
            onDeleteTemplate: onDeleteTemplate,
            isUpdating: isUpdating,
            isInvalid: isInvalid,
            setIsInvalid: setIsInvalid,
            cardError: cardError,
            setCardError: setCardError,
            isBaseInfoError: isBaseInfoError,
            setIsBaseInfoError: setIsBaseInfoError,
            isContractError: isContractError,
            setIsContractError: setIsContractError,
            isEmailTemplateError: isEmailTemplateError,
            setIsEmailTemplateError: setIsEmailTemplateError,
            resetError: resetError,
            isError: isError,
        } }, children));
};
exports.default = PersonnelBoardFormsProvider;
