// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BoardSearchTemplateSelect-templateSelect-aij3h{width:300px;text-align:left}.BoardSearchTemplateSelect-selectOption-_UEN_{text-align:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Common/Board/BoardSearchTemplateSelect/BoardSearchTemplateSelect.scss"],"names":[],"mappings":"AAAA,gDACI,WAAW,CACX,eAAgB,CACnB,8CAGG,iBAAkB","sourcesContent":[".templateSelect{\n    width:300px;\n    text-align: left;\n}\n\n.selectOption {\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"templateSelect": `BoardSearchTemplateSelect-templateSelect-aij3h`,
	"selectOption": `BoardSearchTemplateSelect-selectOption-_UEN_`
};
export default ___CSS_LOADER_EXPORT___;
