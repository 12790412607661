// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PersonnelParallelFormItem-container-xVuAM{width:100%;text-align:left}.PersonnelParallelFormItem-userInput-aOyfH{width:100%}.PersonnelParallelFormItem-tooltip-IajzT{margin-left:5px}.PersonnelParallelFormItem-infoIcon-RrW67{align-self:center}.PersonnelParallelFormItem-select_option-spJzf{text-align:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/personnel/PersonnelBoardPage/PersonnelBoardSearchDrawer/PersonnelParallelFormItem/PersonnelParallelFormItem.scss"],"names":[],"mappings":"AAAA,2CACI,UAAW,CACX,eAAgB,CACnB,2CAGG,UAAW,CACd,yCAGG,eAAgB,CACnB,0CAGG,iBAAkB,CACrB,+CAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n\n.select_option {\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PersonnelParallelFormItem-container-xVuAM`,
	"userInput": `PersonnelParallelFormItem-userInput-aOyfH`,
	"tooltip": `PersonnelParallelFormItem-tooltip-IajzT`,
	"infoIcon": `PersonnelParallelFormItem-infoIcon-RrW67`,
	"select_option": `PersonnelParallelFormItem-select_option-spJzf`
};
export default ___CSS_LOADER_EXPORT___;
