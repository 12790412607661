import CreateRegisterPage from './Factories/createRegisterPage';

import { ORGANIZATION_REGISTER_PAGE } from './pageIds';

import { Endpoint } from '../../domain/api';
import Path from '../Routes/Paths';
import OrganizationForm from '../Forms/OrganizationForm';
import { convertOrganizationResponseDataEntry, convertOrganizationFormToAPI } from '../../domain/data';

const pageId = ORGANIZATION_REGISTER_PAGE;
const resourceURL = `${Endpoint.getBaseUrl()}/${Endpoint.organizations}`;
const resourceName = 'organizations'
const accessAuthorized = (authorizedActions) => { return authorizedActions && authorizedActions[resourceName] && authorizedActions[resourceName]['create'] }
const reducerName = "organizationRegisterPage";

const OrganizationRegisterPageContainer = CreateRegisterPage(
  pageId,
  reducerName,
  '取引先 登録',
  OrganizationForm,
  resourceURL,
  Path.contactRegister,
  convertOrganizationResponseDataEntry,
  convertOrganizationFormToAPI,
  resourceName,
  accessAuthorized
);

export default OrganizationRegisterPageContainer;
