import React from "react";
import { Col, Form, Row, Tooltip, InputNumber } from "antd";
import { QuestionCircleFilled } from "@ant-design/icons";
import { iconCustomColor } from "~/utils/constants";
import styles from "./ScheduledEmailOpenCountFormItem.scss";

const ScheduledEmailOpenCountFormItem = ({ disabled = false }) => {
    const maxOpenCountFieldName = "open_count_gt";
    const minOpenCountFieldName = "open_count_lt";

    return (
        <Col
            span={24}
            style={{
                marginBottom: "1%",
                padding: 0,
            }}>
            <Form.Item colon={false} name="open_count" noStyle>
                <Row className={styles.container} align="middle">
                    <Col span={11}>
                        <Form.Item
                            colon={false}
                            name={maxOpenCountFieldName}
                            noStyle>
                            <InputNumber
                                className={styles.userInput}
                                placeholder="開封数(下限)"
                                step={10}
                                disabled={disabled}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={1}>
                        <Row justify="center">
                            <span>〜</span>
                        </Row>
                    </Col>
                    <Col span={11}>
                        <Form.Item
                            colon={false}
                            name={minOpenCountFieldName}
                            noStyle>
                            <InputNumber
                                className={styles.userInput}
                                placeholder="開封数(上限)"
                                step={10}
                                disabled={disabled}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={1} className={styles.infoIcon}>
                        <Tooltip
                            title={
                                <span>
                                    左の入力欄には下限値を入力します。
                                    <br />
                                    例：「50」を入力した場合、開封数50件以上が対象となります。
                                    <br />
                                    右の入力欄には上限値を入力します。
                                    <br />
                                    例：「50」を入力した場合、開封数50件以下が対象となります。
                                </span>
                            }>
                            <QuestionCircleFilled
                                style={{ color: iconCustomColor }}
                                className={styles.tooltip}
                            />
                        </Tooltip>
                    </Col>
                </Row>
            </Form.Item>
        </Col>
    );
};

export default ScheduledEmailOpenCountFormItem;
