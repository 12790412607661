// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SaveSearchTemplateModal-field-kFZpG{max-width:800px;margin:8px 0}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Common/SearchTemplateSelect/SaveSearchTemplateModal/SaveSearchTemplateModal.scss"],"names":[],"mappings":"AAAA,qCACI,eAAgB,CAChB,YAAa","sourcesContent":[".field {\n    max-width: 800px;\n    margin: 8px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"field": `SaveSearchTemplateModal-field-kFZpG`
};
export default ___CSS_LOADER_EXPORT___;
