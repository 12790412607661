import React from "react";
import { Col, Row, Form, Tooltip } from "antd";
import { QuestionCircleFilled } from "@ant-design/icons";
import TextualNumberInput from "~/components/Common/TextualNumberInput";
import { Links, iconCustomColor } from "~/utils/constants";
import styles from "./CorporateTelFormItem.scss";

const CorporateTelFormItem = ({ disabled = false }) => {
    const tel1FieldName = "tel1";
    const tel2FieldName = "tel2";
    const tel3FieldName = "tel3";

    return (
        <Col span={24} style={{ marginBottom: "1%" }}>
            <Form.Item colon={false} noStyle>
                <Row className={styles.container}>
                    <Col span={7}>
                        <Form.Item
                            label=" "
                            className={styles.inlineField}
                            colon={false}
                            name={tel1FieldName}
                            noStyle>
                            <TextualNumberInput
                                className={styles.phonenumberInput}
                                placeholder="TEL"
                                allowClear
                                disabled={disabled}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={1} className={styles.separater}>
                        <span>&nbsp;-&nbsp;</span>
                    </Col>
                    <Col span={7}>
                        <Form.Item
                            label=" "
                            className={styles.inlineField}
                            colon={false}
                            name={tel2FieldName}
                            noStyle>
                            <TextualNumberInput
                                className={styles.phonenumberInput}
                                allowClear
                                disabled={disabled}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={1} className={styles.separater}>
                        <span>&nbsp;-&nbsp;</span>
                    </Col>
                    <Col span={7}>
                        <Form.Item
                            label=" "
                            className={styles.inlineField}
                            colon={false}
                            name={tel3FieldName}
                            noStyle>
                            <TextualNumberInput
                                className={styles.phonenumberInput}
                                allowClear
                                disabled={disabled}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={1} className={styles.infoIcon}>
                        <Tooltip
                            title={
                                <span>
                                    <a
                                        href={Links.helps.filter.partialMatch}
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        部分一致検索
                                    </a>
                                </span>
                            }>
                            <QuestionCircleFilled
                                style={{ color: iconCustomColor }}
                                className={styles.tooltip}
                            />
                        </Tooltip>
                    </Col>
                </Row>
            </Form.Item>
        </Col>
    );
};

export default CorporateTelFormItem;
