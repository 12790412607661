"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createTemplateCommentReducer = exports.defaultInitialState = void 0;
var actionTypes_1 = require("../../actions/actionTypes");
var commentActionSuffix = "__COMMENTS";
exports.defaultInitialState = {
    loading: false,
    message: "",
    errorMessage: "",
    data: {},
    fieldErrors: {},
    dataTemplate: {}
};
var createTemplateCommentReducer = function (pageId, initialState) {
    if (initialState === void 0) { initialState = exports.defaultInitialState; }
    var templateScheduledReducer = function (state, action) {
        if (state === void 0) { state = initialState; }
        switch (action.type) {
            case pageId + actionTypes_1.SCHEDULED_TEMPLATE_LOADING:
                return __assign(__assign({}, state), { loading: true, message: "", errorMessage: "" });
            case pageId + actionTypes_1.SCHEDULED_TEMPLATE_LOADED:
                return __assign(__assign({}, state), { loading: false, message: "", errorMessage: "", fieldErrors: {}, dataTemplate: action.payload.data });
            case pageId + actionTypes_1.SCHEDULE_TEMPLATE_CREATING:
                return __assign(__assign({}, state), { message: "", errorMessage: "" });
            case pageId + actionTypes_1.SCHEDULE_TEMPLATE_CREATED:
                return __assign(__assign({}, state), { dataTemplate: action.payload.data });
            default:
                return __assign({}, state);
        }
    };
    return templateScheduledReducer;
};
exports.createTemplateCommentReducer = createTemplateCommentReducer;
exports.default = exports.createTemplateCommentReducer;
