// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CommentTemplateListItem-contaienr-VVzJo{width:100%}.CommentTemplateListItem-title-qQh0s{text-align:left}.CommentTemplateListItem-editActionButton-pF2bU{margin-left:5px}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/DataDisplay/CommentList/CommentTemplateListItem/CommentTemplateListItem.scss"],"names":[],"mappings":"AAAA,yCACI,UAAW,CACd,qCAGG,eAAgB,CACnB,gDAGG,eAAgB","sourcesContent":[".contaienr {\n    width: 100%;\n}\n\n.title {\n    text-align: left;\n}\n\n.editActionButton {\n    margin-left: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contaienr": `CommentTemplateListItem-contaienr-VVzJo`,
	"title": `CommentTemplateListItem-title-qQh0s`,
	"editActionButton": `CommentTemplateListItem-editActionButton-pF2bU`
};
export default ___CSS_LOADER_EXPORT___;
