// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RecruitPriorityFormItem-container-Esyk6{width:100%;text-align:left}.RecruitPriorityFormItem-userInput-DG9fY{width:100%}.RecruitPriorityFormItem-tooltip-zHlFR{margin-left:5px}.RecruitPriorityFormItem-infoIcon-uqL6R{align-self:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/recruit/RecruitGanttPage/RecruitGanttSearchDrawer/RecruitPriorityFormItem/RecruitPriorityFormItem.scss"],"names":[],"mappings":"AAAA,yCACI,UAAW,CACX,eAAgB,CACnB,yCAGG,UAAW,CACd,uCAGG,eAAgB,CACnB,wCAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `RecruitPriorityFormItem-container-Esyk6`,
	"userInput": `RecruitPriorityFormItem-userInput-DG9fY`,
	"tooltip": `RecruitPriorityFormItem-tooltip-zHlFR`,
	"infoIcon": `RecruitPriorityFormItem-infoIcon-uqL6R`
};
export default ___CSS_LOADER_EXPORT___;
