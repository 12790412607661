// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SalesBoardBaseInfoForm-section-WTqhR{margin-top:3rem}.SalesBoardBaseInfoForm-tagSelect-eXC3U{max-width:none;margin:8px 0 24px}@media screen and (max-width: 768px){.SalesBoardBaseInfoForm-tagSelect-eXC3U{margin:8px auto;width:100%}}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/sales/SalesBoardPage/SalesBoardDetailModal/SalesBoardBaseInfoForm/SalesBoardBaseInfoForm.scss"],"names":[],"mappings":"AAEA,sCACI,eAAgB,CACnB,wCAGG,cAAe,CACf,iBAAkB,CAKrB,qCAPD,wCAIQ,eAAgB,CAChB,UAAW,CAElB","sourcesContent":["@import \"../../../../../../../stylesheets/constants.scss\";\n\n.section {\n    margin-top: 3rem;\n}\n\n.tagSelect {\n    max-width: none;\n    margin: 8px 0 24px;\n    @include mobile-devices {\n        margin: 8px auto;\n        width: 100%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `SalesBoardBaseInfoForm-section-WTqhR`,
	"tagSelect": `SalesBoardBaseInfoForm-tagSelect-eXC3U`
};
export default ___CSS_LOADER_EXPORT___;
