// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContactCreatedUserFormItem-container-mGAqp{width:100%;text-align:left}.ContactCreatedUserFormItem-userInput-GavNX{width:100%}.ContactCreatedUserFormItem-tooltip-zZ1dq{margin-left:5px}.ContactCreatedUserFormItem-infoIcon-M30zz{align-self:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/ContactSearchPage/ContactSearchDrawer/ContactCreatedUserFormItem/ContactCreatedUserFormItem.scss"],"names":[],"mappings":"AAAA,4CACI,UAAW,CACX,eAAgB,CACnB,4CAGG,UAAW,CACd,0CAGG,eAAgB,CACnB,2CAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ContactCreatedUserFormItem-container-mGAqp`,
	"userInput": `ContactCreatedUserFormItem-userInput-GavNX`,
	"tooltip": `ContactCreatedUserFormItem-tooltip-zZ1dq`,
	"infoIcon": `ContactCreatedUserFormItem-infoIcon-M30zz`
};
export default ___CSS_LOADER_EXPORT___;
