import createEditPage from '~/components/Pages/Factories/createEditPage';
import Paths from '~/components/Routes/Paths';
import { USER_EDIT_PAGE } from '~/components/Pages/pageIds';
import UserForm from '~/components/Forms/UserForm/UserForm';
import { Endpoint } from '~/domain/api';
import { convertUserDataEntry } from '~/domain/data';

const pageId = USER_EDIT_PAGE;
const resourceURL = `${Endpoint.getBaseUrl()}/${Endpoint.users}`;

const resourceName = 'users'
const deleteAuthorized = (authorizedActions, data) => { return authorizedActions && authorizedActions['_editable_roles'] && data && data.role && authorizedActions['_editable_roles'].includes(data.role) }
const accessAuthorized = (authorizedActions, data) => { return authorizedActions && authorizedActions[resourceName] && authorizedActions[resourceName]['_all'] }

const UserEditPageContainer = createEditPage(
  pageId,
  'userEditPage',
  'ユーザー 編集',
  UserForm,
  resourceURL,
  '',
  Paths.users,
  convertUserDataEntry,
  data => data,
  undefined,
  undefined,
  deleteAuthorized,
  accessAuthorized,
);

export default UserEditPageContainer;
