// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PersonnelBoardListFormItem-container-Gr9ht{width:100%;text-align:left}.PersonnelBoardListFormItem-userInput-qyjTd{width:100%}.PersonnelBoardListFormItem-tooltip-Tit6V{margin-left:5px}.PersonnelBoardListFormItem-infoIcon-nO8Du{align-self:center}.PersonnelBoardListFormItem-select_option-O6Wsr{text-align:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/personnel/PersonnelBoardPage/PersonnelBoardSearchDrawer/PersonnelBoardListFormItem/PersonnelBoardListFormItem.scss"],"names":[],"mappings":"AAAA,4CACI,UAAW,CACX,eAAgB,CACnB,4CAGG,UAAW,CACd,0CAGG,eAAgB,CACnB,2CAGG,iBAAkB,CACrB,gDAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n\n.select_option {\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PersonnelBoardListFormItem-container-Gr9ht`,
	"userInput": `PersonnelBoardListFormItem-userInput-qyjTd`,
	"tooltip": `PersonnelBoardListFormItem-tooltip-Tit6V`,
	"infoIcon": `PersonnelBoardListFormItem-infoIcon-nO8Du`,
	"select_option": `PersonnelBoardListFormItem-select_option-O6Wsr`
};
export default ___CSS_LOADER_EXPORT___;
