// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomTypographyLink-CustomTypographyLink-BIK8H{color:#262626 !important;transition:color 0.3s}.CustomTypographyLink-CustomTypographyLink-BIK8H:hover{color:#a7cf69 !important}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Common/CustomTypographyLink/CustomTypographyLink.scss"],"names":[],"mappings":"AAEA,iDACI,wBAAyB,CACzB,qBAAsB,CAF1B,uDAKQ,wBAAgC","sourcesContent":["@import \"../../../../stylesheets//constants.scss\";\n\n.CustomTypographyLink {\n    color: #262626 !important;\n    transition: color 0.3s;\n\n    &:hover {\n        color: $primary-color !important;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CustomTypographyLink": `CustomTypographyLink-CustomTypographyLink-BIK8H`
};
export default ___CSS_LOADER_EXPORT___;
