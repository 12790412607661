// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PopoverMenuItem-menuItem-zMyen{margin-top:8px;margin-bottom:8px;width:150px}.PopoverMenuItem-menuItemWithRibbon-DYK5p{margin-top:8px;margin-bottom:8px;width:250px}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Common/PopoverMenuItem/PopoverMenuItem.scss"],"names":[],"mappings":"AAAA,gCACE,cAAe,CACf,iBAAkB,CAClB,WAAY,CACb,0CAEC,cAAe,CACf,iBAAkB,CAClB,WAAY","sourcesContent":[".menuItem {\n  margin-top: 8px;\n  margin-bottom: 8px;\n  width: 150px;\n}\n.menuItemWithRibbon {\n  margin-top: 8px;\n  margin-bottom: 8px;\n  width: 250px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuItem": `PopoverMenuItem-menuItem-zMyen`,
	"menuItemWithRibbon": `PopoverMenuItem-menuItemWithRibbon-DYK5p`
};
export default ___CSS_LOADER_EXPORT___;
