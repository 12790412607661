// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContactPreferenceFormItem-container-nqyM6{width:100%;text-align:left}.ContactPreferenceFormItem-userInput-KfXw7{width:100%}.ContactPreferenceFormItem-tooltip-DrKAy{margin-left:5px}.ContactPreferenceFormItem-infoIcon-vascF{align-self:center}.ContactPreferenceFormItem-select_option-rUYHr{text-align:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/ContactSearchPage/ContactSearchDrawer/ContactPreferenceFormItem/ContactPreferenceFormItem.scss"],"names":[],"mappings":"AAAA,2CACI,UAAW,CACX,eAAgB,CACnB,2CAGG,UAAW,CACd,yCAGG,eAAgB,CACnB,0CAGG,iBAAkB,CACrB,+CAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n\n.select_option {\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ContactPreferenceFormItem-container-nqyM6`,
	"userInput": `ContactPreferenceFormItem-userInput-KfXw7`,
	"tooltip": `ContactPreferenceFormItem-tooltip-DrKAy`,
	"infoIcon": `ContactPreferenceFormItem-infoIcon-vascF`,
	"select_option": `ContactPreferenceFormItem-select_option-rUYHr`
};
export default ___CSS_LOADER_EXPORT___;
