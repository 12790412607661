// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Modal-firstMessage-FpG1Q{margin-bottom:2px}.Modal-colorFocus-q0SCc:focus{border-color:#A7CF69;background-color:#A7CF69}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Feedbacks/Modal/Modal.scss"],"names":[],"mappings":"AAAA,0BACI,iBAAkB,CACrB,8BAGO,oBAAqB,CACrB,wBAAyB","sourcesContent":[".firstMessage {\n    margin-bottom: 2px;\n}\n.colorFocus{\n    &:focus{\n        border-color: #A7CF69;\n        background-color: #A7CF69;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"firstMessage": `Modal-firstMessage-FpG1Q`,
	"colorFocus": `Modal-colorFocus-q0SCc`
};
export default ___CSS_LOADER_EXPORT___;
