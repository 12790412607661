// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProjectContractPersonnelNameFormItem-container-xcxZe{width:100%;text-align:left}.ProjectContractPersonnelNameFormItem-userInput-iTjp6{width:50%}.ProjectContractPersonnelNameFormItem-tooltip-ID7wP{margin-left:5px}.ProjectContractPersonnelNameFormItem-infoIcon-Z0jAn{align-self:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/project/ProjectBoardPage/ProjectBoardSearchDrawer/ProjectContractPersonnelNameFormItem/ProjectContractPersonnelNameFormItem.scss"],"names":[],"mappings":"AAAA,sDACI,UAAW,CACX,eAAgB,CACnB,sDAGG,SAAU,CACb,oDAGG,eAAgB,CACnB,qDAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 50%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ProjectContractPersonnelNameFormItem-container-xcxZe`,
	"userInput": `ProjectContractPersonnelNameFormItem-userInput-iTjp6`,
	"tooltip": `ProjectContractPersonnelNameFormItem-tooltip-ID7wP`,
	"infoIcon": `ProjectContractPersonnelNameFormItem-infoIcon-Z0jAn`
};
export default ___CSS_LOADER_EXPORT___;
