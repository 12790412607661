"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.apiResponseToAppModel = void 0;
var apiResponseToAppModel = function (data) {
    return {
        id: data.id,
        contact: data.contact,
        organization: data.organization,
        downloadedTime: data.downloaded_time,
        attachment: data.attachment,
    };
};
exports.apiResponseToAppModel = apiResponseToAppModel;
