// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NameFormItem-wrapper-otRsR{margin-bottom:1%}.NameFormItem-container-p_MrK{width:100%;text-align:left}.NameFormItem-userInput-LYDqf{width:100%}.NameFormItem-tooltip-JkLrD{margin-left:5px;color:#615748}.NameFormItem-infoIcon-gMb8_{align-self:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/SharedEmailNotificationSearchPage/SharedEmailNotificationSearchDrawer/NameFormItem/NameFormItem.scss","webpack://./frontend/stylesheets/constants.scss"],"names":[],"mappings":"AAEA,4BACI,gBAAiB,CACpB,8BAGG,UAAW,CACX,eAAgB,CACnB,8BAGG,UAAW,CACd,4BAGG,eAAgB,CAChB,aCboB,CDcvB,6BAGG,iBAAkB","sourcesContent":["@import \"~coreStylesheet\";\n\n.wrapper {\n    margin-bottom: 1%;\n}\n\n.container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n    color: $komorebi-brown;\n}\n\n.infoIcon {\n    align-self: center;\n}\n","/* アプリケーション全体 */\n$breakpoint-mobile: 768px;\n$width-small-screen: 1500px;\n$primary-color: #a7cf69;\n$komorebi-brown: #615748;\n\n/* ガントチャート関連 */\n$gantt-over70: #003656;\n$gantt-between40to70: #80d6ff;\n$gantt-under40: #d0edff;\n\n@mixin mobile-devices() {\n    @media screen and (max-width: $breakpoint-mobile) {\n        @content;\n    }\n}\n\n@mixin small-screen() {\n    @media screen and (max-width: $width-small-screen) {\n        @content;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `NameFormItem-wrapper-otRsR`,
	"container": `NameFormItem-container-p_MrK`,
	"userInput": `NameFormItem-userInput-LYDqf`,
	"tooltip": `NameFormItem-tooltip-JkLrD`,
	"infoIcon": `NameFormItem-infoIcon-gMb8_`
};
export default ___CSS_LOADER_EXPORT___;
