// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CorporateEstablishmentYearFormItem-container-SwRXJ{width:100%;text-align:left}.CorporateEstablishmentYearFormItem-userInput-pvfFG{width:100%}.CorporateEstablishmentYearFormItem-tooltip-nFo1A{margin-left:5px}.CorporateEstablishmentYearFormItem-infoIcon-cj8sa{align-self:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/OrganizationSearchPage/OrganizationSearchDrawer/CorporateEstablishmentYearFormItem/CorporateEstablishmentYearFormItem.scss"],"names":[],"mappings":"AAAA,oDACI,UAAW,CACX,eAAgB,CACnB,oDAGG,UAAW,CACd,kDAGG,eAAgB,CACnB,mDAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `CorporateEstablishmentYearFormItem-container-SwRXJ`,
	"userInput": `CorporateEstablishmentYearFormItem-userInput-pvfFG`,
	"tooltip": `CorporateEstablishmentYearFormItem-tooltip-nFo1A`,
	"infoIcon": `CorporateEstablishmentYearFormItem-infoIcon-cj8sa`
};
export default ___CSS_LOADER_EXPORT___;
