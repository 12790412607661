import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Select, Form, Button, Tooltip } from "antd";
import { DeleteOutlined, StarOutlined, SaveOutlined, ReloadOutlined } from "@ant-design/icons";
import SaveSearchTemplateModal from "~/components/Feedbacks/Modal/SaveSearchTemplateModal/SaveSearchTemplateModal";
import Paths from "~/components/Routes/Paths";
import { TooltipMessages, InfoMessages, PlanMasterId, ENHANCED_SEARCH_TEMPLATE_LIMIT_ADDONS_ID, Links } from "~/utils/constants";
import { connect } from "react-redux";
import { TemplateInfoIcon } from "../ScheduledEmailForm/tooltips";

class SearchTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSaveTemplateModalVisible: false,
        };
    }

    onOk = ({ newTemplateName }) => {
        this.props.onClickCreate(newTemplateName).then(() => {
            this.setState({
                isSaveTemplateModalVisible: false,
            });
        });
    };

    onCancel = () => {
        this.setState({
            isSaveTemplateModalVisible: false,
        });
    };

    getTemplateSaveButtonLabel = () => {
        const { initialValues, totalAvailableCount, purchasedAddons, planId } = this.props;
        const searchTemplateAddon = purchasedAddons.filter((addon) => addon.addonMasterId === ENHANCED_SEARCH_TEMPLATE_LIMIT_ADDONS_ID);
        if (!initialValues || !totalAvailableCount || totalAvailableCount > initialValues.length) {
            return TooltipMessages.searchTemplate.save;
        }
        if (planId === PlanMasterId.PERSONAL || searchTemplateAddon.length === 3) {
            return InfoMessages.searchTemplate.saveButtonAtMaximum;
        }
        
        return (
            <span>
                {InfoMessages.searchTemplate.saveButtonAtMaximum}
                <br />
                「検索条件テンプレート登録数」の
                    <a 
                        href={Paths.addons}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        アドオン
                    </a>
                を購入すると、検索条件テンプレートの登録可能数を+5件することが可能です。
                <br />
                <a
                    href={Links.helps.addon.searchDrowers.templateLimit}
                    target="_blank"
                    rel="noopener noreferrer">
                        詳細
                </a>
            </span>
        )
    };

    render() {
        const { isSaveTemplateModalVisible } = this.state;
        const {
            selectedValue,
            scheduledEmailEditPageContactSearchFormState,
            scheduledEmailRegisterPageContactSearchFormState,
            onChangeSearchTemplate,
            initialValues,
            onClickReset,
            onClickDelete,
            onClickUpdate,
            totalAvailableCount,
            templateReducerName,
            removeStarButton = false,
            planId,
        } = this.props;
        const { loading: editLoading } =
            scheduledEmailEditPageContactSearchFormState;
        const { loading: registerLoading } =
            scheduledEmailRegisterPageContactSearchFormState;
        const canUseSearchTemplate = planId !== PlanMasterId.PERSONAL;
        return (
            <Col span={24} style={{ textAlign: "left" }}>
                <Row justify="end">
                    <Col>
                        <Form.Item noStyle>
                            <Select
                                placeholder="検索条件テンプレートを選択"
                                style={
                                    selectedValue
                                        ? { width: 300 }
                                        : { width: 300, color: "#bfbfbf" }
                                }
                                onChange={onChangeSearchTemplate}
                                value={
                                    selectedValue ||
                                    "検索条件テンプレートを選択"
                                }
                                disabled={!canUseSearchTemplate}
                            >
                                {(initialValues || []).map((entry, index) => {
                                    return (
                                        <Select.Option
                                            key={index}
                                            value={entry.name}
                                        >
                                            {entry.display_name}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                            <Tooltip title={TooltipMessages.searchTemplate.clear}>
                                <Button
                                    style={{ margin: 3 }}
                                    icon={<ReloadOutlined />}
                                    onClick={onClickReset}
                                    type="default" />
                            </Tooltip>
                            <Tooltip
                                title={
                                    canUseSearchTemplate
                                        ? "選択中の検索条件テンプレートを削除します。"
                                        : null
                                }
                            >
                                <Button
                                    style={{ margin: 3 }}
                                    type="primary"
                                    danger
                                    icon={<DeleteOutlined />}
                                    onClick={onClickDelete}
                                    disabled={
                                        !selectedValue ||
                                        editLoading ||
                                        registerLoading ||
                                        !canUseSearchTemplate
                                    }
                                    data-testid="search-template-delete-button"
                                />
                            </Tooltip>
                            {!removeStarButton && (
                                <Tooltip
                                    title={
                                        canUseSearchTemplate
                                            ? "選択中の検索条件テンプレートをデフォルトに設定／解除します。"
                                            : null
                                    }
                                >
                                    <Button
                                        style={{ margin: 3 }}
                                        type="primary"
                                        icon={<StarOutlined />}
                                        onClick={onClickUpdate}
                                        disabled={
                                            !selectedValue ||
                                            editLoading ||
                                            registerLoading ||
                                            !canUseSearchTemplate
                                        }
                                        data-testid="search-template-star-button"
                                    />
                                </Tooltip>
                            )}
                            <Tooltip title={this.getTemplateSaveButtonLabel()}>
                                <Button
                                    style={{ margin: 3 }}
                                    type="primary"
                                    icon={<SaveOutlined />}
                                    onClick={(_) =>
                                        this.setState({
                                            isSaveTemplateModalVisible: true,
                                        })
                                    }
                                    disabled={
                                        editLoading ||
                                        registerLoading ||
                                        (initialValues &&
                                            totalAvailableCount &&
                                            totalAvailableCount <=
                                                initialValues.length) ||
                                        !canUseSearchTemplate
                                    }
                                    data-testid="search-template-save-button"
                                />
                            </Tooltip>
                            <TemplateInfoIcon
                                isPersonalPlan={
                                    planId === PlanMasterId.PERSONAL
                                }
                            />
                            <SaveSearchTemplateModal
                                templateReducerName={templateReducerName}
                                isSaveTemplateModalVisible={
                                    isSaveTemplateModalVisible
                                }
                                onCancel={this.onCancel}
                                onOk={this.onOk}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Col>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        scheduledEmailRegisterPageContactSearchFormState:
            state.scheduledEmailRegisterPageContactSearchForm,
        scheduledEmailEditPageContactSearchFormState:
            state.scheduledEmailEditPageContactSearchForm,
    };
};

SearchTemplate.propTypes = {
    // will be used in child class.
    purchasedAddons: PropTypes.arrayOf(PropTypes.object),
    templateReducerName: PropTypes.string.isRequired,
    onClickReset: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
    onClickUpdate: PropTypes.func.isRequired,
    onClickCreate: PropTypes.func.isRequired,
    onChangeSearchTemplate: PropTypes.func.isRequired,
    selectedValue: PropTypes.string,
    initialValues: PropTypes.arrayOf(PropTypes.object),
    totalAvailableCount: PropTypes.number,
    removeStarButton: PropTypes.bool,
    planId: PropTypes.number,
};

export default connect(mapStateToProps)(SearchTemplate);
