// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContactCommentCreateDateFormItem-container-usvSc{width:100%;text-align:left}.ContactCommentCreateDateFormItem-userInput-gfIky{width:100%}.ContactCommentCreateDateFormItem-tooltip-g5gnD{margin-left:5px}.ContactCommentCreateDateFormItem-infoIcon-gGyIW{align-self:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/ContactSearchPage/ContactSearchDrawer/ContactCommentCreateDateFormItem/ContactCommentCreateDateFormItem.scss"],"names":[],"mappings":"AAAA,kDACE,UAAW,CACX,eAAgB,CACjB,kDAGC,UAAW,CACZ,gDAGC,eAAgB,CACjB,iDAGC,iBAAkB","sourcesContent":[".container {\n  width: 100%;\n  text-align: left;\n}\n\n.userInput {\n  width: 100%;\n}\n\n.tooltip {\n  margin-left: 5px;\n}\n\n.infoIcon {\n  align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ContactCommentCreateDateFormItem-container-usvSc`,
	"userInput": `ContactCommentCreateDateFormItem-userInput-gfIky`,
	"tooltip": `ContactCommentCreateDateFormItem-tooltip-g5gnD`,
	"infoIcon": `ContactCommentCreateDateFormItem-infoIcon-gGyIW`
};
export default ___CSS_LOADER_EXPORT___;
