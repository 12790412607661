"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommentActions = void 0;
var actionTypes_1 = require("~/actions/actionTypes");
exports.CommentActions = {
    replyAction: function (payload) {
        return {
            type: actionTypes_1.WRITING_REPLY_COMMENTS,
            payload: payload,
        };
    },
    newCommentExistAction: function () {
        return {
            type: actionTypes_1.PARENT_CANNOT_SUBMIT_WHEN_NEW_COMMENT_EXISTS,
            payload: undefined,
        };
    },
    newCommentClearAction: function () {
        return {
            type: actionTypes_1.COMMENT_NEW_CLEAR,
            payload: undefined,
        };
    },
    editCommentExistAction: function () {
        return {
            type: actionTypes_1.PARENT_CANNOT_SUBMIT_WHEN_EDIT_COMMENT_EXISTS,
            payload: undefined,
        };
    },
    editCommentClearAction: function () {
        return {
            type: actionTypes_1.COMMENT_EDIT_CLEAR,
            payload: undefined,
        };
    },
};
