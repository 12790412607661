// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProjectBoardContracts-container-YedeN{width:100%;text-align:left}.ProjectBoardContracts-spacer-Y4x16{margin-bottom:5%}.ProjectBoardContracts-addBtn-MM4OJ{margin-bottom:3%}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/project/ProjectBoardPage/ProjectBoardDetailModal/ProjectBoardContracts/ProjectBoardContracts.scss"],"names":[],"mappings":"AAAA,uCACI,UAAW,CACX,eAAgB,CACnB,oCAGG,gBAAiB,CACpB,oCAGG,gBAAiB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.spacer {\n    margin-bottom: 5%;\n}\n\n.addBtn {\n    margin-bottom: 3%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ProjectBoardContracts-container-YedeN`,
	"spacer": `ProjectBoardContracts-spacer-Y4x16`,
	"addBtn": `ProjectBoardContracts-addBtn-MM4OJ`
};
export default ___CSS_LOADER_EXPORT___;
