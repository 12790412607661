// Helper modules that convert data format between frontend components and backend API.
// Note: You also need to include keys that will be used in sorting.
import moment from "moment";
import { remapParameters } from "~/utils/utils";
import getDateStr, {
    DateStrToMoment,
    getDateStrByDateFormat,
    getDateStrByEstablishmentDateFormat,
} from "./date";

export const sharedEmailSearchParamToAPI = (params) => {
    return {
        from_name: params.sender,
        from_address: params.email,
        subject_AND: params.subject,
        text_AND: params.text,
        text_include: params.text_include,
        text_exclude: params.text_exclude,
        estimated_category: params.category,
        has_attachments: params.has_attachments,
        sent_date_gte:
            params.date_range && params.date_range[0]
                ? getDateStrByDateFormat(moment(params.date_range[0]))
                : undefined,
        sent_date_lte:
            params.date_range && params.date_range[1]
                ? getDateStrByDateFormat(moment(params.date_range[1]))
                : undefined,
        staff: params.staff,
        ignore_filter: params.ignore_filter ? "ignore_filter" : "use_filter",
        comment_user: params.comment_user,
        score: params.score,
        comment_created_time_gte:
            params.comment_date_range && params.comment_date_range[0]
                ? getDateStrByDateFormat(moment(params.comment_date_range[0]))
                : undefined,
        comment_created_time_lte:
            params.comment_date_range && params.comment_date_range[1]
                ? getDateStrByDateFormat(moment(params.comment_date_range[1]))
                : undefined,
    };
};

export const convertSharedEmailResponseDataEntry = (data, index) => ({
    key: index,
    id: data.id,
    sender: data.from_name,
    email: data.from_address,
    subject: data.subject,
    text: data.text,
    has_attachments: data.has_attachments,
    attachments: data.attachments,
    category: data.estimated_category,
    sentDate: getDateStr(data.sent_date),
    staff_in_charge__name: data.staff_in_charge__name,
    staff_in_charge__id: data.staff_in_charge__id,
    sent_date: getDateStr(data.sent_date),
    staff_in_charge__last_name: data.staff_in_charge__name,
    from_name: data.from_name,
    comments: data.comments,
    histories: data.histories,
    score: data.score
});

export const convertSharedEmailStatsDataEntry = (data) => ({
    trend: {
        labels: data.trend.labels,
        values: {
            personnelEmail: data.trend.personnel_email_counts,
            jobEmail: data.trend.job_email_counts,
        },
    },
    ranking: {
        monthly: {
            labels: data.ranking.monthly.from_headers,
            values: data.ranking.monthly.counts,
        },
    },
    reply: {
        labels: data.reply.labels,
        values: data.reply.counts,
    },
});

export const convertSharedEmailNotificationResponseDataEntry = (
    data,
    index
) => ({
    key: index,
    id: data.id,
    name: data.name,
    master_rule: data.master_rule,
    is_active: data.is_active,
    rules:
        data.conditions &&
        Array.isArray(data.conditions) &&
        data.conditions.map((c, idx) => ({
            key: idx,
            id: data.id,
            name: data.name,
            type: c.extraction_type,
            value: c.value,
            target: c.target_type,
            condition: c.condition_type,
        })),
    children:
        data.conditions &&
        Array.isArray(data.conditions) &&
        _.slice(data.conditions, 1, data.conditions.length).map(
            (callback, index) => {
                return {
                    key: data.id + ":" + index,
                    id: data.id,
                    type: callback.extraction_type,
                    value: callback.value,
                    target: callback.target_type,
                    condition: callback.condition_type,
                };
            }
        ),
    assignees:
        data.assignees?.map((data) => ({
            user: data.user,
            displayName: data.display_name,
        })) || [],
    created_user: data.created_user__name,
    created_time: getDateStr(data.created_time),
    modified_user: data.modified_user__name,
    modified_time: getDateStr(data.modified_time),
    type:
        data.conditions && Array.isArray(data.conditions)
            ? data.conditions[0].extraction_type
            : undefined,
    value:
        data.conditions && Array.isArray(data.conditions)
            ? data.conditions[0].value
            : undefined,
    target:
        data.conditions && Array.isArray(data.conditions)
            ? data.conditions[0].target_type
            : undefined,
    condition:
        data.conditions && Array.isArray(data.conditions)
            ? data.conditions[0].condition_type
            : undefined,
    conditions: data.conditions,
});

export const SharedEmailNotificationFormToAPI = (params) => ({
    name: params.name,
    master_rule: params.master_rule,
    is_active: params.is_active,
    conditions: params.rules.map((r) => ({
        extraction_type: "keyword",
        target_type: r.target,
        condition_type: r.condition,
        value: r.target === "attachment" ? "0" : r.value,
    })),
    assignees: params.assignees?.map((userId) => ({ user: userId })),
});

export const organizationSearchParamToAPI = (params) => ({
    name: params.name,
    settlement_month: params.settlement_month,
    address: params.address,
    domain_name: params.domain_name,
    corporate_number: params.corporate_number,
    country: params.country,
    category: params.category,
    employee_number: params.employee_number,
    contract: params.contract,
    capital_man_yen_gt: params.capital_gt,
    capital_man_yen_lt: params.capital_lt,
    capital_man_yen_required_for_transactions_gt:
        params.capital_man_yen_required_for_transactions_gt,
    capital_man_yen_required_for_transactions_lt:
        params.capital_man_yen_required_for_transactions_lt,
    establishment_year_gt: params.establishment_year_gt,
    establishment_year_lt: params.establishment_year_lt,
    score_gte: params.score_inequality == "ge" ? params.score : undefined,
    score_eq: params.score_inequality == "eq" ? params.score : undefined,
    created_time: params.created_time,
    modified_time: params.modified_time,
    ignore_filter: params.ignore_filter ? "ignore_filter" : "use_filter",
    ignore_blocklist_filter: params.ignore_blocklist_filter
        ? "ignore_blocklist_filter"
        : "use_blocklist_filter",
    establishment_date_gte:
        params.establishment_date_range && params.establishment_date_range[0]
            ? getDateStrByEstablishmentDateFormat(
                  moment(params.establishment_date_range[0])
              )
            : undefined,
    establishment_date_lte:
        params.establishment_date_range && params.establishment_date_range[1]
            ? getDateStrByEstablishmentDateFormat(
                  moment(params.establishment_date_range[1])
              )
            : undefined,
    has_distribution: params.has_distribution,
    document: params.document,
    license: params.license,
    license_required_for_transactions: params.license_required_for_transactions,
    tel1: params.tel1,
    tel2: params.tel2,
    tel3: params.tel3,
    fax1: params.fax1,
    fax2: params.fax2,
    fax3: params.fax3,
    comment_user: params.comment_user,
    comment_created_time_gte:
        params.comment_date_range && params.comment_date_range[0]
            ? getDateStrByDateFormat(
                moment(params.comment_date_range[0])
            )
            : undefined,
    comment_created_time_lte:
        params.comment_date_range && params.comment_date_range[1]
            ? getDateStrByDateFormat(
                moment(params.comment_date_range[1])
            )
            : undefined,
    created_user: params.created_user,
    modified_user: params.modified_user,
    branch_name: params.branch_name,
    branch_address: params.branch_address,
    branch_tel1: params.branch_tel1,
    branch_tel2: params.branch_tel2,
    branch_tel3: params.branch_tel3,
    branch_fax1: params.branch_fax1,
    branch_fax2: params.branch_fax2,
    branch_fax3: params.branch_fax3,
    tags_and:
        params.tags &&
        params.tags != "" &&
        (params.tags__suffix == "and" || !params.tags__suffix)
            ? params.tags
            : undefined,
    tags_or:
        params.tags && params.tags != "" && params.tags__suffix == "or"
            ? params.tags
            : undefined,
    tags_not_and:
        params.tags && params.tags !== "" && params.tags__suffix === "not_and"
            ? params.tags
            : undefined,
    tags_not_or:
        params.tags && params.tags !== "" && params.tags__suffix === "not_or"
            ? params.tags
            : undefined,
});

export const convertOrganizationFormToAPI = (params) => ({
    name: params.name,
    settlement_month: params.settlement_month,
    address: params.address,
    domain_name: params.domain_name,
    building: params.building,
    corporate_number: params.corporate_number ? params.corporate_number : null,
    country: params.country,
    category: params.category,
    employee_number: params.employee_number,
    contract: params.contract,
    capital_man_yen: params.capital_man_yen,
    capital_man_yen_required_for_transactions:
        params.capital_man_yen_required_for_transactions,
    score: params.score === 0 ? null : params.score, // null -> clear rate.
    is_blacklisted: params.is_blacklisted,
    establishment_year: params.establishment_year,
    p_mark_or_isms: params.p_mark_or_isms,
    invoice_system: params.invoice_system,
    haken: params.haken,
    establishment_date: params.establishment_date
        ? getDateStrByEstablishmentDateFormat(params.establishment_date)
        : null,
    has_p_mark_or_isms: params.has_p_mark_or_isms,
    has_invoice_system: params.has_invoice_system,
    has_haken: params.has_haken,
    has_distribution: params.has_distribution,
    initial_comment: params.comment,
    tel1: params.tel1,
    tel2: params.tel2,
    tel3: params.tel3,
    fax1: params.fax1,
    fax2: params.fax2,
    fax3: params.fax3,
    branches: params.branches ? params.branches : [],
    has_outsourcing_basic_contact: params.has_outsourcing_basic_contact,
    has_nda: params.has_nda,
    has_handring_personal_information: params.has_handring_personal_information,
    tags: params.tags,
});

export const userSearchParamToAPI = (params) => ({
    first_name: params.first_name,
    last_name: params.last_name,
    user_service_id: params.user_service_id,
    email: params.email,
    tel: params.tel,
    tel1: params.tel1,
    tel2: params.tel2,
    tel3: params.tel3,
    is_active: params.is_active,
    date_joined: params.date_joined,
    role: params.role,
    inactive_filter: params.inactive_filter ? "ignore_filter" : "use_filter",
    last_login_gte:
        params.last_login && params.last_login[0]
            ? getDateStrByDateFormat(moment(params.last_login[0]))
            : undefined,
    last_login_lte:
        params.last_login && params.last_login[1]
            ? getDateStrByDateFormat(moment(params.last_login[1]))
            : undefined,
});

export const contactSearchParamToAPI = (params) => ({
    last_name: params.last_name,
    first_name: params.first_name,
    email: params.email,
    cc_addresses__email: params.cc_mails,
    organization__name: params.organization__name,
    open_rate__rank: params.open_rate__rank,
    staff: params.staff,
    staff__name: params.staff__name,
    tel: params.tel,
    tel1: params.tel1,
    tel2: params.tel2,
    tel3: params.tel3,
    position: params.position,
    department: params.department,
    last_visit_gte:
        params.date_range && params.date_range[0]
            ? getDateStrByDateFormat(moment(params.date_range[0]))
            : undefined,
    last_visit_lte:
        params.date_range && params.date_range[1]
            ? getDateStrByDateFormat(moment(params.date_range[1]))
            : undefined,
    created_time: params.created_time,
    modified_time: params.modified_time,
    score_gte: params.score_inequality === "ge" ? params.score : undefined,
    score_eq: params.score_inequality === "eq" ? params.score : undefined,
    tags_and:
        params.tags &&
        params.tags !== "" &&
        (params.tags__suffix === "and" || !params.tags__suffix)
            ? params.tags
            : undefined,
    tags_or:
        params.tags && params.tags !== "" && params.tags__suffix === "or"
            ? params.tags
            : undefined,
    tags_not_and:
        params.tags && params.tags !== "" && params.tags__suffix === "not_and"
            ? params.tags
            : undefined,
    tags_not_or:
        params.tags && params.tags !== "" && params.tags__suffix === "not_or"
            ? params.tags
            : undefined,
    preference: params.contact_preference,
    category_eq:
        params.category &&
        (params.category_inequality === "eq" || !params.category_inequality)
            ? params.category
            : undefined,
    category_not_eq:
        params.category && params.category_inequality === "not_eq"
            ? params.category
            : undefined,
    wants_location: params.wants_location,
    ignore_filter: params.ignore_filter ? "ignore_filter" : "use_filter",
    ignore_blocklist_filter: params.ignore_blocklist_filter
        ? "ignore_blocklist_filter"
        : "use_blocklist_filter",
    comment_user: params.comment_user,
    comment_created_time_gte:
        params.comment_date_range && params.comment_date_range[0]
            ? getDateStrByDateFormat(
                    moment(params.comment_date_range[0])
                )
            : undefined,
    comment_created_time_lte:
        params.comment_date_range && params.comment_date_range[1]
            ? getDateStrByDateFormat(
                    moment(params.comment_date_range[1])
                )
            : undefined,
    created_user: params.created_user,
    modified_user: params.modified_user,
    ignore_archive_filter: params.ignore_archive_filter
        ? "ignore_archive_filter"
        : "use_archive_filter",
});

export const scheduledEmailSearchParamToAPI = (params) => ({
    sender_id: params.sender_id,
    subject: params.subject,
    status: params.status,
    date_to_send_gte:
        params.date_range && params.date_range[0]
            ? getDateStrByDateFormat(moment(params.date_range[0]))
            : undefined,
    date_to_send_lte:
        params.date_range && params.date_range[1]
            ? getDateStrByDateFormat(moment(params.date_range[1]))
            : undefined,
    send_type: params.send_type,
    search_conditions: params.search_conditions,
    attachments: params.attachments,
    sent_date_gte:
        params.sent_date && params.sent_date[0]
            ? getDateStrByDateFormat(moment(params.sent_date[0]))
            : undefined,
    sent_date_lte:
        params.sent_date && params.sent_date[1]
            ? getDateStrByDateFormat(moment(params.sent_date[1]))
            : undefined,
    text_format: params.text_format,
    text: params.text,
    send_total_count_gt: params.send_total_count_gt,
    send_total_count_lt: params.send_total_count_lt,
    open_count_gt: params.open_count_gt,
    open_count_lt: params.open_count_lt,
    download_count_gte: params.download_count_gte,
    download_count_lte: params.download_count_lte,
    created_user: params.created_user,
    modified_user: params.modified_user,
});

export const contactFormToAPI = (params) => ({
    last_name: params.last_name,
    first_name: params.first_name,
    organization: params.organization.split("/")[0],
    organization_branch: params.organization.split("/")[1],
    email: params.email,
    cc_mails: [
        params.ccEmail1,
        params.ccEmail2,
        params.ccEmail3,
        params.ccEmails,
    ].filter((v) => v),
    staff: !!params.staff ? params.staff : null,
    tel1: params.tel1,
    tel2: params.tel2,
    tel3: params.tel3,
    position: params.position,
    department: params.department,
    last_visit: params.last_visit
        ? getDateStrByDateFormat(params.last_visit)
        : null,
    score: params.score === 0 ? null : params.score, // null -> clear rate.
    category: params.category ? params.category : null,
    tags: params.tags,
    initial_comment: params.comment,
    jobtypepreference: {
        dev_designer: params.jobtype_dev_designer,
        dev_front: params.jobtype_dev_front,
        dev_server: params.jobtype_dev_server,
        dev_pm: params.jobtype_dev_pm,
        dev_other: params.jobtype_dev_other,
        infra_server: params.jobtype_infra_server,
        infra_network: params.jobtype_infra_network,
        infra_security: params.jobtype_infra_security,
        infra_database: params.jobtype_infra_database,
        infra_sys: params.jobtype_infra_sys,
        infra_other: params.jobtype_infra_other,
        other_eigyo: params.jobtype_other_eigyo,
        other_kichi: params.jobtype_other_kichi,
        other_support: params.jobtype_other_support,
        other_other: params.jobtype_other_other,
    },
    jobskillpreference: {
        dev_youken: params.jobskill_dev_youken,
        dev_kihon: params.jobskill_dev_kihon,
        dev_syousai: params.jobskill_dev_syousai,
        dev_seizou: params.jobskill_dev_seizou,
        dev_test: params.jobskill_dev_test,
        dev_hosyu: params.jobskill_dev_hosyu,
        dev_beginner: params.jobskill_dev_beginner,
        infra_youken: params.jobskill_infra_youken,
        infra_kihon: params.jobskill_infra_kihon,
        infra_syousai: params.jobskill_infra_syousai,
        infra_kouchiku: params.jobskill_infra_kouchiku,
        infra_test: params.jobskill_infra_test,
        infra_hosyu: params.jobskill_infra_hosyu,
        infra_kanshi: params.jobskill_infra_kanshi,
        infra_beginner: params.jobskill_infra_beginner,
    },
    personneltypepreference: {
        dev_designer: params.personneltype_dev_designer,
        dev_front: params.personneltype_dev_front,
        dev_server: params.personneltype_dev_server,
        dev_pm: params.personneltype_dev_pm,
        dev_other: params.personneltype_dev_other,
        infra_server: params.personneltype_infra_server,
        infra_network: params.personneltype_infra_network,
        infra_security: params.personneltype_infra_security,
        infra_database: params.personneltype_infra_database,
        infra_sys: params.personneltype_infra_sys,
        infra_other: params.personneltype_infra_other,
        other_eigyo: params.personneltype_other_eigyo,
        other_kichi: params.personneltype_other_kichi,
        other_support: params.personneltype_other_support,
        other_other: params.personneltype_other_other,
    },
    personnelskillpreference: {
        dev_youken: params.personnelskill_dev_youken,
        dev_kihon: params.personnelskill_dev_kihon,
        dev_syousai: params.personnelskill_dev_syousai,
        dev_seizou: params.personnelskill_dev_seizou,
        dev_test: params.personnelskill_dev_test,
        dev_hosyu: params.personnelskill_dev_hosyu,
        dev_beginner: params.personnelskill_dev_beginner,
        infra_youken: params.personnelskill_infra_youken,
        infra_kihon: params.personnelskill_infra_kihon,
        infra_syousai: params.personnelskill_infra_syousai,
        infra_kouchiku: params.personnelskill_infra_kouchiku,
        infra_test: params.personnelskill_infra_test,
        infra_hosyu: params.personnelskill_infra_hosyu,
        infra_kanshi: params.personnelskill_infra_kanshi,
        infra_beginner: params.personnelskill_infra_beginner,
    },
    preference: {
        // 希望エリア
        wants_location_hokkaido_japan: params.wants_location_hokkaido_japan,
        wants_location_touhoku_japan: params.wants_location_touhoku_japan,
        wants_location_kanto_japan: params.wants_location_kanto_japan,
        wants_location_kansai_japan: params.wants_location_kansai_japan,
        wants_location_chubu_japan: params.wants_location_chubu_japan,
        has_send_guide: params.has_send_guide,
        wants_location_kyushu_japan: params.wants_location_kyushu_japan,
        wants_location_other_japan: params.wants_location_other_japan,
        wants_location_chugoku_japan: params.wants_location_chugoku_japan,
        wants_location_shikoku_japan: params.wants_location_shikoku_japan,
        wants_location_toukai_japan: params.wants_location_toukai_japan,
        // 案件
        job_koyou_proper: params.job_koyou_proper,
        job_koyou_free: params.job_koyou_free,
        job_syouryu: params.job_syouryu ? params.job_syouryu : undefined,
        // 要員
        personnel_syouryu: params.personnel_syouryu
            ? params.personnel_syouryu
            : undefined,
        personnel_country_japan: params.personnel_country_japan,
        personnel_country_other: params.personnel_country_other,
    },
    is_archived: params.is_archived,
});

export const contactProfileSearchFormToAPI = (params) => ({
    /* In order to filter target contact correctly, we must not send false when a checkbox is unchecked. */
    searchtype: params.searchtype,
    personneltype: params.personneltype,
    personneltype_dev: params.personneltype_dev ? true : undefined,
    personneltype_infra: params.personneltype_infra ? true : undefined,
    personneltype_other: params.personneltype_other ? true : undefined,

    wants_location_hokkaido_japan: params.wants_location_hokkaido_japan
        ? true
        : undefined,
    wants_location_touhoku_japan: params.wants_location_touhoku_japan
        ? true
        : undefined,
    wants_location_kanto_japan: params.wants_location_kanto_japan
        ? true
        : undefined,
    wants_location_kansai_japan: params.wants_location_kansai_japan
        ? true
        : undefined,
    wants_location_chubu_japan: params.wants_location_chubu_japan
        ? true
        : undefined,
    wants_location_kyushu_japan: params.wants_location_kyushu_japan
        ? true
        : undefined,
    wants_location_other_japan: params.wants_location_other_japan
        ? true
        : undefined,
    wants_location_chugoku_japan: params.wants_location_chugoku_japan
        ? true
        : undefined,
    wants_location_shikoku_japan: params.wants_location_shikoku_japan
        ? true
        : undefined,
    wants_location_toukai_japan: params.wants_location_toukai_japan
        ? true
        : undefined,
    job_koyou_proper: params.job_koyou == "proper" ? true : undefined,
    job_koyou_free: params.job_koyou == "free" ? true : undefined,
    job_syouryu: params.job_syouryu,
    personnel_syouryu: params.personnel_syouryu,
    personnel_country_japan:
        params.personnel_country == "japan" ? true : undefined,
    personnel_country_other:
        params.personnel_country == "other" ? true : undefined,
    jobtype: params.jobtype ? params.jobtype : undefined,
    jobtype_dev_designer:
        params.jobtype_dev == "jobtype_dev_designer" ? true : undefined,
    jobtype_dev_front:
        params.jobtype_dev == "jobtype_dev_front" ? true : undefined,
    jobtype_dev_server:
        params.jobtype_dev == "jobtype_dev_server" ? true : undefined,
    jobtype_dev_pm: params.jobtype_dev == "jobtype_dev_pm" ? true : undefined,
    jobtype_dev_other:
        params.jobtype_dev == "jobtype_dev_other" ? true : undefined,
    jobtype_infra_server:
        params.jobtype_infra == "jobtype_infra_server" ? true : undefined,
    jobtype_infra_network:
        params.jobtype_infra == "jobtype_infra_network" ? true : undefined,
    jobtype_infra_security:
        params.jobtype_infra == "jobtype_infra_security" ? true : undefined,
    jobtype_infra_database:
        params.jobtype_infra == "jobtype_infra_database" ? true : undefined,
    jobtype_infra_sys:
        params.jobtype_infra == "jobtype_infra_sys" ? true : undefined,
    jobtype_infra_other:
        params.jobtype_infra == "jobtype_infra_other" ? true : undefined,
    jobtype_other_eigyo:
        params.jobtype_other == "jobtype_other_eigyo" ? true : undefined,
    jobtype_other_kichi:
        params.jobtype_other == "jobtype_other_kichi" ? true : undefined,
    jobtype_other_support:
        params.jobtype_other == "jobtype_other_support" ? true : undefined,
    jobtype_other_other:
        params.jobtype_other == "jobtype_other_other" ? true : undefined,
    jobskill_dev_youken: params.jobskill_dev_youken ? true : undefined,
    jobskill_dev_kihon: params.jobskill_dev_kihon ? true : undefined,
    jobskill_dev_syousai: params.jobskill_dev_syousai ? true : undefined,
    jobskill_dev_seizou: params.jobskill_dev_seizou ? true : undefined,
    jobskill_dev_test: params.jobskill_dev_test ? true : undefined,
    jobskill_dev_hosyu: params.jobskill_dev_hosyu ? true : undefined,
    jobskill_dev_beginner: params.jobskill_dev_beginner ? true : undefined,
    jobskill_infra_youken: params.jobskill_infra_youken ? true : undefined,
    jobskill_infra_kihon: params.jobskill_infra_kihon ? true : undefined,
    jobskill_infra_syousai: params.jobskill_infra_syousai ? true : undefined,
    jobskill_infra_kouchiku: params.jobskill_infra_kouchiku ? true : undefined,
    jobskill_infra_test: params.jobskill_infra_test ? true : undefined,
    jobskill_infra_hosyu: params.jobskill_infra_hosyu ? true : undefined,
    jobskill_infra_kanshi: params.jobskill_infra_kanshi ? true : undefined,
    jobskill_infra_beginner: params.jobskill_infra_beginner ? true : undefined,

    personneltype_dev_designer: params.personneltype_dev_designer
        ? true
        : undefined,
    personneltype_dev_front: params.personneltype_dev_front ? true : undefined,
    personneltype_dev_server: params.personneltype_dev_server
        ? true
        : undefined,
    personneltype_dev_pm: params.personneltype_dev_pm ? true : undefined,
    personneltype_dev_other: params.personneltype_dev_other ? true : undefined,
    personneltype_infra_server: params.personneltype_infra_server
        ? true
        : undefined,
    personneltype_infra_network: params.personneltype_infra_network
        ? true
        : undefined,
    personneltype_infra_security: params.personneltype_infra_security
        ? true
        : undefined,
    personneltype_infra_database: params.personneltype_infra_database
        ? true
        : undefined,
    personneltype_infra_sys: params.personneltype_infra_sys ? true : undefined,
    personneltype_infra_other: params.personneltype_infra_other
        ? true
        : undefined,
    personneltype_other_eigyo: params.personneltype_other_eigyo
        ? true
        : undefined,
    personneltype_other_kichi: params.personneltype_other_kichi
        ? true
        : undefined,
    personneltype_other_support: params.personneltype_other_support
        ? true
        : undefined,
    personneltype_other_other: params.personneltype_other_other
        ? true
        : undefined,
    personnelskill_dev_youken: params.personnelskill_dev_youken
        ? true
        : undefined,
    personnelskill_dev_kihon: params.personnelskill_dev_kihon
        ? true
        : undefined,
    personnelskill_dev_syousai: params.personnelskill_dev_syousai
        ? true
        : undefined,
    personnelskill_dev_seizou: params.personnelskill_dev_seizou
        ? true
        : undefined,
    personnelskill_dev_test: params.personnelskill_dev_test ? true : undefined,
    personnelskill_dev_hosyu: params.personnelskill_dev_hosyu
        ? true
        : undefined,
    personnelskill_dev_beginner: params.personnelskill_dev_beginner
        ? true
        : undefined,
    personnelskill_infra_youken: params.personnelskill_infra_youken
        ? true
        : undefined,
    personnelskill_infra_kihon: params.personnelskill_infra_kihon
        ? true
        : undefined,
    personnelskill_infra_syousai: params.personnelskill_infra_syousai
        ? true
        : undefined,
    personnelskill_infra_kouchiku: params.personnelskill_infra_kouchiku
        ? true
        : undefined,
    personnelskill_infra_test: params.personnelskill_infra_test
        ? true
        : undefined,
    personnelskill_infra_hosyu: params.personnelskill_infra_hosyu
        ? true
        : undefined,
    personnelskill_infra_kanshi: params.personnelskill_infra_kanshi
        ? true
        : undefined,
    personnelskill_infra_beginner: params.personnelskill_infra_beginner
        ? true
        : undefined,

    contact__score_gte:
        params.contact__score === 0 ? undefined : params.contact__score,
    contact__category_eq:
        params.contact__category &&
        (params.category_inequality == "eq" || !params.category_inequality)
            ? params.contact__category
            : undefined,
    contact__category_not_eq:
        params.contact__category && params.category_inequality == "not_eq"
            ? params.contact__category
            : undefined,
    contact__tags_and:
        params.contact__tags &&
        params.contact__tags != "" &&
        (params.contact__tags__suffix == "and" || !params.contact__tags__suffix)
            ? params.contact__tags
            : undefined,
    contact__tags_or:
        params.contact__tags &&
        params.contact__tags != "" &&
        params.contact__tags__suffix == "or"
            ? params.contact__tags
            : undefined,
    contact__tags_not_and:
        params.contact__tags &&
        params.contact__tags !== "" &&
        (params.contact__tags__suffix === "not_and" ||
            !params.contact__tags__suffix)
            ? params.contact__tags
            : undefined,
    contact__tags_not_or:
        params.contact__tags &&
        params.contact__tags !== "" &&
        params.contact__tags__suffix === "not_or"
            ? params.contact__tags
            : undefined,
    contact__organization__tags_and:
        params.contact__organization__tags &&
        params.contact__organization__tags != "" &&
        (params.contact__organization__tags__suffix == "and" ||
            !params.contact__organization__tags__suffix)
            ? params.contact__organization__tags
            : undefined,
    contact__organization__tags_or:
        params.contact__organization__tags &&
        params.contact__organization__tags != "" &&
        params.contact__organization__tags__suffix == "or"
            ? params.contact__organization__tags
            : undefined,
    contact__organization__tags_not_and:
        params.contact__organization__tags &&
        params.contact__organization__tags !== "" &&
        (params.contact__organization__tags__suffix === "not_and" ||
            !params.contact__organization__tags__suffix)
            ? params.contact__organization__tags
            : undefined,
    contact__organization__tags_not_or:
        params.contact__organization__tags &&
        params.contact__organization__tags !== "" &&
        params.contact__organization__tags__suffix === "not_or"
            ? params.contact__organization__tags
            : undefined,
    contact__staff: params.contact__staff,
    contact__organization__category_prospective:
        params.contact__organization__category_prospective ? true : undefined,
    contact__organization__category_approached:
        params.contact__organization__category_approached ? true : undefined,
    contact__organization__category_exchanged:
        params.contact__organization__category_exchanged ? true : undefined,
    contact__organization__category_client:
        params.contact__organization__category_client ? true : undefined,
    contact__organization__organization_country_jp:
        params.contact__organization__organization_country_jp
            ? true
            : undefined,
    contact__organization__organization_country_kr:
        params.contact__organization__organization_country_kr
            ? true
            : undefined,
    contact__organization__organization_country_cn:
        params.contact__organization__organization_country_cn
            ? true
            : undefined,
    contact__organization__organization_country_other:
        params.contact__organization__organization_country_other
            ? true
            : undefined,
    contact__organization__contract: params.contact__organization__contract,
    contact__organization__has_outsourcing_basic_contact:
        params.contact__organization__has_outsourcing_basic_contact
            ? true
            : undefined,
    contact__organization__has_nda: params.contact__organization__has_nda
        ? true
        : undefined,
    contact__organization__has_handring_personal_information:
        params.contact__organization__has_handring_personal_information
            ? true
            : undefined,

    exclude_organizations: Array.isArray(params.exclude_organizations)
        ? params.exclude_organizations.join(",")
        : params.exclude_organizations,
    ..._convertOpenRankConditionToAPI(params),
});

const _convertOpenRankConditionToAPI = (params) => {
    return Object.keys(params ?? {})
        .filter((key) => key.startsWith("contact__open_rate_"))
        .reduce(
            (accm, cur) => ({
                [cur]: params?.[cur] === true ? true : undefined,
                ...accm,
            }),
            {}
        );
};

export const convertContactListResponseDataEntry = (data, index) => ({
    key: index,
    id: data.id,
    last_name: data.last_name,
    first_name: data.first_name,
    display_name: data.display_name,
    email: data.email,
    cc_mails: data.cc_mails,
    cc_addresses__email: data.cc_mails ? data.cc_mails.join() : "",
    tel: data.tel,
    position: data.position,
    department: data.department,
    organization__name: data.organization__name,
    staff__name: data.staff__name,
    staff__last_name: data.staff__name,
    last_visit: getDateStrByDateFormat(data.last_visit),
    score: data.score,
    open_rate__rank: data.open_rate__rank,
    created_time: getDateStr(data.created_time),
    modified_time: getDateStr(data.modified_time),
    comments: data.comments,
    tel1: data.tel1,
    tel2: data.tel2,
    tel3: data.tel3,
    contactjobtypepreferences: data.contactjobtypepreferences,
    contactjobskillpreferences: data.contactjobskillpreferences,
    contactpersonneltypepreferences: data.contactpersonneltypepreferences,
    contactpersonnelskillpreferences: data.contactpersonnelskillpreferences,
    tags: data.tags,
    tag_objects: data.tag_objects,
    category: data.category,
    contactpreference: data.contactpreference,
    is_ignored: data.is_ignored,
});

export const convertContactResponseDataEntry = (data) => ({
    id: data.id,
    last_name: data.last_name,
    first_name: data.first_name,
    email: data.email,
    ccEmail1:
        data.cc_mails && Array.isArray(data.cc_mails)
            ? data.cc_mails[0]
            : undefined,
    ccEmail2:
        data.cc_mails && Array.isArray(data.cc_mails)
            ? data.cc_mails[1]
            : undefined,
    ccEmail3:
        data.cc_mails && Array.isArray(data.cc_mails)
            ? data.cc_mails[2]
            : undefined,
    ccEmails:
        data.cc_mails && Array.isArray(data.cc_mails)
            ? data.cc_mails.join(",")
            : undefined,
    tel: data.tel,
    tel1: data.tel1,
    tel2: data.tel2,
    tel3: data.tel3,
    position: data.position,
    department: data.department,
    organization: data.organization,
    organization__name: data.organization__name,
    organization_branch: data.organization_branch,
    organization_branch__name: data.organization_branch__name,
    staff: data.staff,
    staff__name: data.staff__name,
    last_visit: DateStrToMoment(data.last_visit),
    score: data.score,
    open_rate__rank: data.open_rate__rank,
    created_user: data.created_user__name,
    created_time: getDateStr(data.created_time),
    modified_user: data.modified_user__name,
    modified_time: getDateStr(data.modified_time),
    tags: data.tags,
    tag_objects: data.tag_objects,
    tag_id0: data.tags && data.tags[0] ? data.tags[0] : "",
    tag_name0:
        data.tag_objects && data.tag_objects[0] ? data.tag_objects[0] : "",
    tag_id1: data.tags && data.tags[1] ? data.tags[1] : "",
    tag_name1:
        data.tag_objects && data.tag_objects[1] ? data.tag_objects[1] : "",
    tag_id2: data.tags && data.tags[2] ? data.tags[2] : "",
    tag_name2:
        data.tag_objects && data.tag_objects[2] ? data.tag_objects[2] : "",
    tag_id3: data.tags && data.tags[3] ? data.tags[3] : "",
    tag_name3:
        data.tag_objects && data.tag_objects[3] ? data.tag_objects[3] : "",
    tag_id4: data.tags && data.tags[4] ? data.tags[4] : "",
    tag_name4:
        data.tag_objects && data.tag_objects[4] ? data.tag_objects[4] : "",
    contactjobtypepreferences: data.contactjobtypepreferences,
    contactjobskillpreferences: data.contactjobskillpreferences,
    contactpersonneltypepreferences: data.contactpersonneltypepreferences,
    contactpersonnelskillpreferences: data.contactpersonnelskillpreferences,
    has_send_guide: data.contactpreference
        ? data.contactpreference.has_send_guide
        : true,
    wants_location_hokkaido_japan: data.contactpreference
        ? data.contactpreference.wants_location_hokkaido_japan
        : undefined,
    wants_location_touhoku_japan: data.contactpreference
        ? data.contactpreference.wants_location_touhoku_japan
        : undefined,
    wants_location_kanto_japan: data.contactpreference
        ? data.contactpreference.wants_location_kanto_japan
        : undefined,
    wants_location_kansai_japan: data.contactpreference
        ? data.contactpreference.wants_location_kansai_japan
        : undefined,
    wants_location_chubu_japan: data.contactpreference
        ? data.contactpreference.wants_location_chubu_japan
        : undefined,
    wants_location_kyushu_japan: data.contactpreference
        ? data.contactpreference.wants_location_kyushu_japan
        : undefined,
    wants_location_other_japan: data.contactpreference
        ? data.contactpreference.wants_location_other_japan
        : undefined,
    wants_location_chugoku_japan: data.contactpreference
        ? data.contactpreference.wants_location_chugoku_japan
        : undefined,
    wants_location_shikoku_japan: data.contactpreference
        ? data.contactpreference.wants_location_shikoku_japan
        : undefined,
    wants_location_toukai_japan: data.contactpreference
        ? data.contactpreference.wants_location_toukai_japan
        : undefined,
    job_koyou_proper: data.contactpreference
        ? data.contactpreference.job_koyou_proper
        : undefined,
    job_koyou_free: data.contactpreference
        ? data.contactpreference.job_koyou_free
        : undefined,
    job_syouryu: data.contactpreference
        ? data.contactpreference.job_syouryu
        : undefined,
    personnel_syouryu: data.contactpreference
        ? data.contactpreference.personnel_syouryu
        : undefined,
    personnel_country_japan: data.contactpreference
        ? data.contactpreference.personnel_country_japan
        : undefined,
    personnel_country_other: data.contactpreference
        ? data.contactpreference.personnel_country_other
        : undefined,
    category: data.category,
    wants_location: data.wants_location,
    cc_mails: data.cc_mails,
    name: data.name,
    is_archived: data.is_archived,
});

export const convertContactPreferenceSummaryResponseDataEntry = (data) => ({
    key: data.id, // Use contact ID as a table item keys.
    contact__id: data.id,
    contact__display_name: data.name,
    contact__organization__name: data.org_name,
    contact__email: data.email,
});

export const convertContactPreferenceResponseDataEntry = (data) => ({
    contact__organization__is_blacklisted:
        data.contact__organization__is_blacklisted,
    wants_location_kanto_japan: data.wants_location_kanto_japan,
    wants_location_kansai_japan: data.wants_location_kansai_japan,
    created_user: data.created_user__name,
    created_time: getDateStr(data.created_time),
    modified_user: data.modified_user__name,
    modified_time: getDateStr(data.modified_time),
});

export const convertOrganizationResponseDataEntry = (data) => ({
    id: data.id,
    corporate_number: data.corporate_number,
    name: data.name,
    settlement_month: data.settlement_month,
    address: data.address,
    domain_name: data.domain_name,
    building: data.building,
    capital_man_yen: data.capital_man_yen,
    capital_man_yen_required_for_transactions:
        data.capital_man_yen_required_for_transactions,
    country: data.country,
    category: data.category,
    employee_number: data.employee_number,
    contract: data.contract,
    establishment_year: data.establishment_year,
    score: data.score,
    p_mark_or_isms: data.p_mark_or_isms,
    invoice_system: data.invoice_system,
    is_blacklisted: data.is_blacklisted,
    created_user: data.created_user__name,
    created_time: getDateStr(data.created_time),
    modified_user: data.modified_user__name,
    modified_time: getDateStr(data.modified_time),
    related_contacts: data.related_contacts,
    has_p_mark_or_isms: data.has_p_mark_or_isms,
    has_invoice_system: data.has_invoice_system,
    establishment_date: DateStrToMoment(data.establishment_date),
    has_haken: data.has_haken,
    has_distribution: data.has_distribution,
    haken: data.haken,
    tel: data.tel,
    tel1: data.tel1,
    tel2: data.tel2,
    tel3: data.tel3,
    fax: data.fax,
    fax1: data.fax1,
    fax2: data.fax2,
    fax3: data.fax3,
    branches: data.organization_branches ? data.organization_branches : [],
    has_outsourcing_basic_contact: data.has_outsourcing_basic_contact,
    has_nda: data.has_nda,
    has_handring_personal_information: data.has_handring_personal_information,
    tags: data.tags,
    tag_objects: data.tag_objects,
});

export const convertOrganizationStatsDataEntry = (data) => ({
    trend: {
        register: {
            labels: data.trend.register.labels,
            values: data.trend.register.counts,
        },
    },
});

export const convertCommentResponseDataEntry = (data) => ({
    id: data.id,
    content: data.content,
    created_user: data.created_user ?? data.createdUser,
    created_user__name: data.created_user__name ?? data.createdUser_Name,
    created_user__avatar: data.created_user__avatar ?? data.createdUser_Avatar,
    created_time: DateStrToMoment(data.created_time ?? data.createdTime),
    is_important: data.is_important ?? data.isImportant,
    total_sub_comment: data.total_sub_comment ?? data.totalSubComment,
    has_subcomment: data.has_subcomment ?? data.hasSubcomment,
    parent: data.parent,
    parent_content: data.parent_content ?? data.parentContent,
    parent_created_user_name:
        data.parent_created_user_name ?? data.parentCreatedUserName,
    parent_created_time: data.parent_created_time ?? data.parentCreatedTime,
    deleted_at: data.deleted_at ?? data.deletedAt,
    edited:
        DateStrToMoment(data.modified_time ?? data.modifiedTime) -
            DateStrToMoment(data.created_time ?? data.createdTime) >
        0,
});

/**
 * This function convert API's attachment representation to Ant Design's representation (Upload component)
 * @param attachment {Object} - A object that correspond to the response data of the Backend file upload API.
 */
const convertAttachmentData = (attachment) => ({
    attached_mail_id: attachment.attached_mail_id,
    file_type: attachment.file_type,
    gcp_link: attachment.gcp_link,
    uid: attachment.id,
    name: attachment.name,
    status: "done",
});

export const convertScheduledEmailSummaryResponseDataEntry = (data, index) => ({
    key: index,
    id: data.id,
    sender__name: data.sender__name,
    sender__last_name: data.sender__name,
    subject: data.subject,
    status: data.status,
    date_to_send: getDateStr(data.date_to_send),
    attachments: data.attachments
        ? data.attachments.map(convertAttachmentData)
        : [],
    send_total_count: data.send_total_count,
    send_type: data.send_type,
    sent_date: getDateStr(data.sent_date),
    text_format: data.text_format,
    open_count: data.open_count,
    open_ratio: data.open_ratio,
    download_count: data.download_count,
    download_ratio: data.download_ratio,
    created_time: getDateStr(data.created_time),
    modified_time: getDateStr(data.modified_time),
    password: data.password,
});

export const convertScheduledEmailResponseDataEntry = (data) => ({
    id: data.id,
    sender: data.sender,
    sender__name: data.sender__name,
    subject: data.subject,
    text: data.text,
    status: data.status,
    date_to_send: DateStrToMoment(data.date_to_send),
    send_copy_to_sender: data.send_copy_to_sender,
    sent_date: getDateStr(data.sent_date),
    attachments: data.attachments
        ? data.attachments.map(convertAttachmentData)
        : [],
    target_contacts: data.target_contacts,
    created_time: getDateStr(data.created_time),
    created_time_ts: data.created_time, // for high-precision usage. e.g., detect resource version change.
    created_user: data.created_user__name,
    modified_time: getDateStr(data.modified_time),
    modified_time_ts: data.modified_time, // for high-precision usage. e.g., detect resource version change.
    modified_user: data.modified_user__name,
    search_condition: data.search_condition,
    send_type: data.send_type,
    text_format: data.text_format,
    send_copy_to_share: data.send_copy_to_share,
    send_limit: data.send_limit,
    exclude_organizations: data.exclude_organizations,
    promotion_id: data.promotion_id,
});

export const convertScheduledEmailPreviewResponseDataEntry = (data) => ({
    sender_email: data.sender_email,
    subject: data.subject,
    body: data.body,
    date_to_send: getDateStr(data.date_to_send),
    text_format: data.text_format,
    over_max_byte_size: data.over_max_byte_size,
    file_type: data.file_type,
    password: data.password,
});

export const convertCompanyParamToAPI = (params) => ({
    name: params.name,
    domain_name: params.domain_name,
    address: params.address,
    building: params.building,
    capital_man_yen: params.capital_man_yen,
    establishment_date: params.establishment_date
        ? getDateStrByEstablishmentDateFormat(params.establishment_date)
        : null,
    has_p_mark_or_isms: params.has_p_mark_or_isms,
    has_invoice_system: params.has_invoice_system,
    has_haken: params.has_haken,
    has_distribution: params.has_distribution,
    capital_man_yen_required_for_transactions:
        params.capital_man_yen_required_for_transactions,
    establishment_year: params.establishment_year,
    p_mark_or_isms: params.p_mark_or_isms,
    invoice_system: params.invoice_system,
    haken: params.haken,
    exceptional_organizations: params.exceptional_organizations,
    settlement_month: params.settlement_month,
});

export const convertCompanyResponseDataEntry = (data) => ({
    id: data.id,
    name: data.name,
    domain_name: data.domain_name,
    address: data.address,
    building: data.building,
    capital_man_yen: data.capital_man_yen,
    establishment_date: DateStrToMoment(data.establishment_date),
    has_p_mark_or_isms: data.has_p_mark_or_isms,
    has_invoice_system: data.has_invoice_system,
    has_haken: data.has_haken,
    has_distribution: data.has_distribution,
    capital_man_yen_required_for_transactions:
        data.capital_man_yen_required_for_transactions,
    establishment_year: data.establishment_year,
    score: data.score,
    p_mark_or_isms: data.p_mark_or_isms,
    invoice_system: data.invoice_system,
    haken: data.haken,
    exceptional_organizations: !!data.exceptional_organizations.length
        ? data.exceptional_organizations
        : [undefined], // NOTE(joshua-hashimoto): 除外企業が1つもない場合は、デフォルトで選択欄を1つ表示するためにundefinedを入れた配列を渡す
    modified_time: getDateStr(data.modified_time),
    modified_user: data.modified_user__name,
    exceptional_organization_names: data.exceptional_organization_names,
    settlement_month: data.settlement_month,
});

export const convertTagResponseDataEntry = (data) => ({
    id: data.id,
    value: data.value,
    created_time: getDateStr(data.created_time),
    created_user: data.created_user__name,
    modified_time: getDateStr(data.modified_time),
    modified_user: data.modified_user__name,
});

export const TagFormToAPI = (params) => ({
    value: params.value,
    isSkill: params.isSkill,
});

export const convertSearchTemplateResponseDataEntry = (data) => ({
    templates: data.templates,
    total_available_count: data.total_available_count,
});

export const sharedEmailNotificationSearchParamToAPI = (params) => {
    const filteredRules = params?.rules?.filter(
        (rule) =>
            !!rule.target_type &&
            !!rule.condition_type &&
            (rule.target_type === "attachment" || !!rule.value)
    );
    const assignees = params.assignees;
    let userAssignees = [];
    if (Array.isArray(assignees)) {
        userAssignees = [...assignees];
    }
    if (typeof assignees === "string") {
        userAssignees = [assignees];
    }
    const createdUser = params.created_user;
    let createdPageUsers = [];
    if (Array.isArray(createdUser)) {
        createdPageUsers = [...createdUser];
    }
    if (typeof createdUser === "string") {
        createdPageUsers = [createdUser];
    }
    const modifiedUser = params.modified_user;
    let modifiedPageUsers = [];
    if (Array.isArray(modifiedUser)) {
        modifiedPageUsers = [...modifiedUser];
    }
    if (typeof modifiedUser === "string") {
        modifiedPageUsers = [modifiedUser];
    }
    return {
        name: params.name,
        master_rule: params.master_rule,
        rules: !!filteredRules?.length
            ? JSON.stringify(
                  params.rules.map((r) => ({
                      target_type: r.target_type,
                      condition_type: r.condition_type,
                      value: r.target_type === "attachment" ? "0" : r.value,
                  }))
              )
            : undefined,
        user_assignees: !!userAssignees.length
            ? userAssignees.join()
            : undefined,
        inactive_filter: params.inactive_filter
            ? "ignore_filter"
            : "use_filter",
        created_user: !!createdPageUsers.length
            ? createdPageUsers.join()
            : undefined,
        modified_user: !!modifiedPageUsers.length
            ? modifiedPageUsers.join()
            : undefined,
    };
};

export const convertDashStatsDataEntry = (data) => {
    return {
        contactStats: data.contact_stats,
        organizationStats: data.organization_stats,
        scheduledEmailStats: data.scheduled_email_stats,
        sharedEmailStats: data.shared_email_stats,
        receiveEmailStats: data.receive_email_stats,
        dashboardTime: data.dashboard_time,
        topUsersStats: data.staff_in_charge_stats,
        scheduledEmailSenderStats: data.scheduled_email_sender_stats,
        sharedEmailAttachmentStats: data.shared_email_attachment_stats,
        personnelPriceStats: data.personnel_price_stats,
        personnelAffiliationStats: data.personnel_affiliation_stats,
        projectPriceStats: data.project_price_stats,
        projectCountStats: data.project_count_stats,
        recruitAssigneeStats: data.recruit_assignee_stats,
        salesAssigneeStats: data.sales_assignee_stats,
        backOfficeAssigneeStats: data.back_office_assignee_stats,
    };
};

export const convertScheduledEmailOpenerListResponseFromAPI = (data) => data;

export const convertScheduledEmailErrorListResponseFromAPI = (data) => data;

export const convertPurchaseHistoryResponseFromAPI = (data) => data;

export const convertSharedEmailSettingDataEntry = (data) => ({
    allow_self_domain: data.allow_self_domain,
    company: data.company,
    from_email: data.from_email,
    modified_time: getDateStr(data.modified_time),
    modified_user: data.modified_user__name,
    password: data.password,
    protocol: data.protocol,
    hostname: data.hostname,
    username: data.username,
    account_email_address: data.account_email_address,
    is_short_notification_interval_available:
        data.is_short_notification_interval_available,
    rule_condition_addon_purchase_count:
        data.rule_condition_addon_purchase_count,
    assignee_addon_purchase_count: data.assignee_addon_purchase_count,
    archive: data.archive,
});

export const convertDisplaySettingDataEntry = (data) => ({
    company: data.company,
    content_hash: data.content_hash,
    modified_time: getDateStr(data.modified_time),
    modified_user: data.modified_user__name,
});

export const convertUserDataEntry = (data) => ({
    avatar: data.avatar,
    display_name: data.display_name,
    editable: data.editable,
    email: data.email,
    email_signature: data.email_signature,
    first_name: data.first_name,
    user_service_id: data.user_service_id,
    id: data.id,
    is_active: data.is_active,
    is_user_admin: data.is_user_admin,
    name: data.name,
    last_login: data.last_login,
    last_name: data.last_name,
    modified_time: getDateStr(data.modified_time),
    modified_user: data.modified_user__name,
    old_id: data.old_id,
    registed_at: data.registed_at,
    role: data.role,
    tel: data.tel,
    tel1: data.tel1,
    tel2: data.tel2,
    tel3: data.tel3,
    username: data.username,
    password: data.password,
    settlement_month: data.settlement_month,
});

export const convertMyProfileDataEntry = (data) => ({
    email: data.email,
    email_signature: data.email_signature,
    name: data.name,
    first_name: data.first_name,
    user_service_id: data.user_service_id,
    last_name: data.last_name,
    modified_time: getDateStr(data.modified_time),
    modified_user: data.modified_user__name,
    role: data.role,
    tel: data.tel,
    tel1: data.tel1,
    tel2: data.tel2,
    tel3: data.tel3,
    password: data.password,
});

export const convertAddonMasterDataEntry = (data) => {
    return {
        id: data.id,
        description: data.description,
        expirationTime: data.expiration_time,
        isDashboard: data.is_dashboard,
        isMyCompanySetting: data.is_my_company_setting,
        isOrganizations: data.is_organizations,
        isRecommended: data.is_recommended,
        isScheduledMails: data.is_scheduled_mails,
        isSharedMails: data.is_shared_mails,
        limit: data.limit,
        parents: data.parents,
        price: data.price,
        targets: data.targets,
        title: data.title,
        helpUrl: data.help_url,
    };
};

export const convertPurchasedAddonsDataEntry = (data) => {
    return {
        id: data.id,
        addonMasterId: data.addon_master_id,
        expirationTime: data.expiration_time,
    };
};

export const convertPurchaseAddonFromDataToAPI = (data) => {
    return {
        addonMasterId: data.addon_master_id,
        expirationTime: data.expiration_time,
    };
};
export const handleCardListIdParams = (ids) => {
    let cardListId = [],
        notCardListId = [];
    if (ids && Array.isArray(ids)) {
        ids?.forEach((item) => {
            item.includes("not:")
                ? notCardListId.push(item.slice(4))
                : cardListId.push(item);
        });
    } else if (ids) {
        cardListId?.includes("not:")
            ? (notCardListId = ids.slice(4))
            : (cardListId = ids);
    } else {
        cardListId = undefined;
        notCardListId = undefined;
    }
    return [cardListId, notCardListId];
};
export const personnelSearchParamToAPI = (params = {}) => {
    const [cardListId, notCardListId] = handleCardListIdParams(
        params?.cardListId
    );
    const _params = {
        isArchived: params?.isArchived,
        assigneeId: params?.assigneeId,
        lastName: params?.lastName,
        firstName: params?.firstName,
        lastNameInitial: params?.lastNameInitial,
        firstNameInitial: params?.firstNameInitial,
        gender: params?.gender,
        trainStation: params?.trainStation,
        priceLte: params?.priceLte,
        priceGte: params?.priceGte,
        isNegotiable: params?.isNegotiable,
        request: params?.request,
        dynamicRows: params?.dynamicRows,
        image: params?.image,
        skillSheet: params?.skillSheet,
        description: params?.projectDescription,
        organizationHigher: params?.organizationHigher,
        organizationLower: params?.organizationLower,
        contactHigherId: params?.contactHigherId,
        contactLowerId: params?.contactLowerId,
        ageLte: params?.ageLte,
        ageGte: params?.ageGte,
        contractPriceLte: params?.contractPriceLte,
        contractPriceGte: params?.contractPriceGte,
        cardListId: cardListId,
        notCardListId: notCardListId,
        startGte: params?.startGte,
        startLte: params?.startLte,
        endGte: params?.endGte,
        endLte: params?.endLte,
        createdUser: params?.createdUser,
        modifiedUser: params?.modifiedUser,
    };
    if (_params["notCardListId"] && _params["notCardListId"].length === 0) {
        delete _params["notCardListId"];
    }

    if (_params["cardListId"] && _params["cardListId"].length === 0) {
        delete _params["cardListId"];
    }

    if (params && params["contract"]) {
        _params["contractStart"] = getDateStrByDateFormat(
            params["contract"][0]
        );
        _params["contractEnd"] = getDateStrByDateFormat(params["contract"][1]);
    }

    return remapParameters(
        _params,
        params,
        ["affiliation", "priority", "skill", "parallel"],
        [
            "assigneeId",
            "cardListId",
            "notCardListId",
            "gender",
            "organizationHigher",
            "organizationLower",
            "contactHigherId",
            "contactLowerId",
        ],
        ["start", "end", "operatePeriod"]
    );
};

export const convertScheduledEmailCardResponseDataEntry = (data) => ({
    subject: data?.scheduled_email_template?.subject,
    text:
        data?.scheduled_email_template.body?.upper +
        "\n" +
        data?.scheduled_email_template.body?.middle +
        "\n" +
        data?.scheduled_email_template.body?.lower,
    status: true,
    send_copy_to_sender: data?.scheduled_email_template?.send_copy_to_sender,
    search_condition: data?.scheduled_email_template?.condition,
    text_format: data?.scheduled_email_template?.text_format,
    send_copy_to_share: data?.scheduled_email_template?.send_copy_to_share,
    exclude_organizations: data?.exclude_organizations,
});

export const projectSearchParamToAPI = (params = {}) => {
    const [cardListId, notCardListId] = handleCardListIdParams(
        params?.cardListId
    );
    const _params = {
        isArchived: params?.isArchived,
        assigneeId: params?.assigneeId,
        startGte: params?.startGte,
        startLte: params?.startLte,
        endGte: params?.endGte,
        endLte: params?.endLte,
        detail: params?.detail,
        description: params?.description,
        skillRequired: params?.skillRequired,
        priceGte: params?.priceGte,
        priceLte: params?.priceLte,
        place: params?.place,
        peopleGte: params?.peopleGte,
        peopleLte: params?.peopleLte,
        settleWidthStartGte: params?.settleWidthStartGte,
        settleWidthStartLte: params?.settleWidthStartLte,
        settleWidthEndGte: params?.settleWidthEndGte,
        settleWidthEndLte: params?.settleWidthEndLte,
        settleMethod: params?.settleMethod,
        settleIncrement: params?.settleIncrement,
        paymentGte: params?.paymentGte,
        paymentLte: params?.paymentLte,
        interviewGte: params?.interviewGte,
        interviewLte: params?.interviewLte,
        dynamicRows: params?.dynamicRows,
        projectAttachment: params?.projectAttachment,
        personnelName: params?.personnelName,
        organizationHigher: params?.organizationHigher,
        contactHigherId: params?.contactHigherId,
        organizationLower: params?.organizationLower,
        contactLowerId: params?.contactLowerId,
        skillWelcomed: params?.skillWelcomed,
        cardListId: cardListId,
        notCardListId: notCardListId,
        createdUser: params?.createdUser,
        modifiedUser: params?.modifiedUser,
        personnelContractPriceGte: params?.personnelContractPriceGte,
        personnelContractPriceLte: params?.personnelContractPriceLte,
        personnelLastName: params?.personnelLastName,
        personnelFirstName: params?.personnelFirstName,
        personnelLastNameInitial: params?.personnelLastNameInitial,
        personnelFirstNameInitial: params?.personnelFirstNameInitial,
        projectPeriodImmediate: params?.projectPeriodImmediate,
        projectPeriodLongTerm: params?.projectPeriodLongTerm,
        trainStationRemote: params?.trainStationRemote,
        peopleIsMultiple: params?.peopleIsMultiple,
        peopleIsSetProposalWelcome: params?.peopleIsSetProposalWelcome,
        priceIsSkillAssessment: params?.priceIsSkillAssessment,
        settleWidthIsFixed: params?.settleWidthIsFixed,
        interviewIsJoin: params?.interviewIsJoin,
    };
    if (_params["notCardListId"] && _params["notCardListId"].length === 0) {
        delete _params["notCardListId"];
    }
    if (_params["cardListId"] && _params["cardListId"].length === 0) {
        delete _params["cardListId"];
    }
    if (params && params["personnelContract"]) {
        _params["personnelContractStart"] = getDateStrByDateFormat(
            params["personnelContract"][0]
        );
        _params["personnelContractEnd"] = getDateStrByDateFormat(
            params["personnelContract"][1]
        );
    }
    return remapParameters(
        _params,
        params,
        ["distributionTo", "priority"],
        [
            "assigneeId",
            "cardListId",
            "notCardListId",
            "organizationHigher",
            "organizationLower",
            "contactHigherId",
            "contactLowerId",
        ],
        ["start", "end", "projectPeriod"],
        ["startWorkingHour", "endWorkingHour"]
    );
};
