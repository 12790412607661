"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.emptyDisplaySetting = exports.sanitizeValues = exports.renderLabel = void 0;
var renderLabel = function (label) {
    if (label === null) {
        return "unknown";
    }
    else if (label === "other") {
        return "その他";
    }
    else {
        return label;
    }
};
exports.renderLabel = renderLabel;
var sanitizeValues = function (values) {
    var result = {};
    for (var key in values) {
        if (values[key] !== undefined) {
            result[key] = values[key];
        }
    }
    return result;
};
exports.sanitizeValues = sanitizeValues;
exports.emptyDisplaySetting = {};
