// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RecruitIncludeArchiveSwitchFromItem-container-zgrlp{width:100%;text-align:left}.RecruitIncludeArchiveSwitchFromItem-userInput-gECMn{width:100%}.RecruitIncludeArchiveSwitchFromItem-tooltip-fSv3k{margin-left:5px}.RecruitIncludeArchiveSwitchFromItem-infoIcon-z_kIl{align-self:center}.RecruitIncludeArchiveSwitchFromItem-switchLabel-mRZVd{display:inline-block;align-self:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/recruit/RecruitGanttPage/RecruitGanttSearchDrawer/RecruitIncludeArchiveSwitchFromItem/RecruitIncludeArchiveSwitchFromItem.scss"],"names":[],"mappings":"AAAA,qDACI,UAAW,CACX,eAAgB,CACnB,qDAGG,UAAW,CACd,mDAGG,eAAgB,CACnB,oDAGG,iBAAkB,CACrB,uDAGG,oBAAqB,CACrB,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n\n.switchLabel {\n    display: inline-block;\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `RecruitIncludeArchiveSwitchFromItem-container-zgrlp`,
	"userInput": `RecruitIncludeArchiveSwitchFromItem-userInput-gECMn`,
	"tooltip": `RecruitIncludeArchiveSwitchFromItem-tooltip-fSv3k`,
	"infoIcon": `RecruitIncludeArchiveSwitchFromItem-infoIcon-z_kIl`,
	"switchLabel": `RecruitIncludeArchiveSwitchFromItem-switchLabel-mRZVd`
};
export default ___CSS_LOADER_EXPORT___;
