import React, { useState } from "react";
import { Form, Input, InputNumber, Radio, Tooltip, Col, Row, Select } from "antd";
import { QuestionCircleFilled } from "@ant-design/icons";
import CustomMonthPicker from "~/components/Common/CustomMonthPicker/CustomMonthPicker";
import {
    ErrorMessages,
    Links,
    ONLY_HANKAKU_REGEX,
    HANKAKU_NUMBER_REGEX,
    RESTRICT_SPACE_REGEX,
    NO_ONLY_SPACES_REGEX,
    iconCustomColor,
} from "~/utils/constants";
import { disabledFutureDates } from "~/utils/utils";
import AddressInputFormItem from "../AddressInputFormItem/AddressInputFormItem";
import {
    formatMoneyNumberString,
    parseMoneyNumberString,
} from "~/components/helpers";
import styles from "./MyCompanyForm.scss";

const { TextArea } = Input;

const formItemLayout = {
    labelCol: {
        xs: { span: 4 },
        sm: { span: 4 },
    },
    wrapperCol: {
        span: 14,
    },
};

const certificateItemLayoutLabelColSpan = 7;
const certificateItemLayoutWrapperColSpan = 7;
const certificateItemLayout = {
    labelCol: {
        xs: { span: certificateItemLayoutLabelColSpan },
        sm: { span: certificateItemLayoutLabelColSpan },
    },
    wrapperCol: {
        xs: { span: certificateItemLayoutWrapperColSpan },
        sm: { span: certificateItemLayoutWrapperColSpan },
    },
};

const addressFormItemStyle = {
    marginBottom: 0,
};

const monthOption = [
    { label: "1月", value: 1 },
    { label: "2月", value: 2 },
    { label: "3月", value: 3 },
    { label: "4月", value: 4 },
    { label: "5月", value: 5 },
    { label: "6月", value: 6 },
    { label: "7月", value: 7 },
    { label: "8月", value: 8 },
    { label: "9月", value: 9 },
    { label: "10月", value: 10 },
    { label: "11月", value: 11 },
    { label: "12月", value: 12 },
]

const CompanyInfoPane = ({
    initialData,
    fieldErrors,
    form,
    tooltipMessage,
}) => {
    const [settleMonth,setSettleMonth]=useState(monthOption)
    const getCurrentFieldError = (name) => {
        return form && form.getFieldError(name).length > 0;
    };

    const onSettlementMonthSearch = (value) => {
        if (value === ""){
            setSettleMonth(monthOption);
            return;
        }
        const newSettleMonth = [...monthOption].filter((item) =>
            item.label.includes(value)
        );
        setSettleMonth(newSettleMonth);
    }

    const onPopupVisibleChange = (open) => {
        if (open) setSettleMonth(monthOption);
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
        }
    };

    return (
        <>
            <Form.Item
                {...formItemLayout}
                label="自社名"
                className={styles.field}
                validateStatus={fieldErrors.name ? "error" : undefined}
                help={fieldErrors.name}
                name="name"
                initialValue={initialData.name}
                rules={[
                    { required: true },
                    {
                        pattern: NO_ONLY_SPACES_REGEX,
                        message: ErrorMessages.validation.regex.space,
                    },
                    {
                        max: 100,
                        message: ErrorMessages.validation.length.max100,
                    },
                ]}>
                <TextArea autoSize={{minRows:1}} placeholder="サンプル株式会社" />
            </Form.Item>
            <Form.Item
                {...formItemLayout}
                label="設立年月"
                className={styles.field}
                help={fieldErrors.establishment_date}
                name="establishment_date"
                initialValue={initialData.establishment_date}
                rules={[{ required: true }]}>
                <CustomMonthPicker
                    allowClear={false}
                    inputReadOnly
                    disabledDate={disabledFutureDates}
                />
            </Form.Item>
            <Form.Item
                {...formItemLayout}
                label="決算期"
                className={styles.field}
                validateStatus={fieldErrors.settlement_month ? "error" : undefined}
                help={fieldErrors.settlement_month}
                name="settlement_month"
                initialValue={initialData.settlement_month}
                rules={[{ required: true }]}>
                <Select
                    style={{ width: 120 }}
                    placeholder="月を選択"
                    showSearch
                    filterOption={false}
                    onSearch={onSettlementMonthSearch}
                    onDropdownVisibleChange={onPopupVisibleChange}
                    allowClear={false}>
                    {settleMonth.map((item) => (
                        <Select.Option key={item.value} value={item.value}>
                            {item.label}
                        </Select.Option>))
                    }
                </Select>
            </Form.Item>
            <AddressInputFormItem
                {...formItemLayout}
                required
                help={fieldErrors.address}
                className={styles.field}
                addressProps={{
                    validateStatus:
                        fieldErrors.address ||
                        fieldErrors.building ||
                        getCurrentFieldError("address")
                            ? "error"
                            : "success",
                    help: fieldErrors.address,
                    rules: [{ required: true }],
                    initialValue: initialData.address,
                }}
                buildingProps={{
                    validateStatus: fieldErrors.building ? "error" : "success",
                    help: fieldErrors.building,
                    initialValue: initialData.building,
                }}
            />
            <Form.Item
                {...formItemLayout}
                label="URL"
                className={styles.field}
                validateStatus={fieldErrors.domain_name ? "error" : undefined}
                help={fieldErrors.domain_name}
                name="domain_name"
                initialValue={initialData.domain_name}
                rules={[
                    { required: true },
                    {
                        pattern: ONLY_HANKAKU_REGEX,
                        message: ErrorMessages.validation.regex.onlyHankaku,
                    },
                    {
                        pattern: RESTRICT_SPACE_REGEX,
                        message: ErrorMessages.validation.regex.space,
                    },
                    {
                        max: 50,
                        message: ErrorMessages.validation.length.max50,
                    },
                ]}>
                <TextArea autoSize={{minRows:1}} placeholder="https://" />
            </Form.Item>
            <Form.Item
                {...formItemLayout}
                label={
                    <span>
                        商流&nbsp;
                        <Tooltip
                            title={
                                <span>
                                    商流を抜けない：取引条件を満たさないと取引先対象外になる。<br />
                                    商流を抜ける：取引条件を満たさなくても取引先対象外にならない。
                                    <br />
                                    <a
                                        href={
                                            Links.helps.commercialDistribution
                                                .about
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        詳細
                                    </a>
                                </span>
                            }>
                            <QuestionCircleFilled
                                style={{ color: iconCustomColor }}
                            />
                        </Tooltip>
                    </span>
                }
                className={styles.field}
                validateStatus={
                    fieldErrors.has_distribution ? "error" : undefined
                }
                help={fieldErrors.has_distribution}
                required
                name="has_distribution"
                initialValue={initialData.has_distribution}>
                <Radio.Group>
                    <Radio value={false}>抜けない</Radio>
                    <Radio value={true}>抜ける</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item
                {...formItemLayout}
                label="資本金"
                className={styles.field}
                validateStatus={
                    fieldErrors.capital_man_yen ? "error" : undefined
                }
                help={fieldErrors.capital_man_yen}
                required>
                <Row align="middle" gutter={4}>
                    <Col>
                        <Form.Item
                            name="capital_man_yen"
                            initialValue={initialData.capital_man_yen}
                            rules={[
                                { required: true },
                                {
                                    type: "number",
                                    min: 1,
                                    message: "1以上の値を入力してください",
                                },
                                {
                                    pattern: HANKAKU_NUMBER_REGEX,
                                    message:
                                        ErrorMessages.validation.regex
                                            .onlyHankakuNumber,
                                },
                                {
                                    validator: (_, value) => {
                                        const convertedValue = String(
                                            value ?? ""
                                        );
                                        if (convertedValue.length > 9) {
                                            return Promise.reject(
                                                new Error(
                                                    ErrorMessages.validation.length.max9
                                                )
                                            );
                                        }
                                        return Promise.resolve();
                                    },
                                },
                            ]}
                            noStyle>
                            <InputNumber
                                formatter={formatMoneyNumberString}
                                parser={parseMoneyNumberString}
                                step={100}
                                onKeyDown={handleKeyDown}
                            />
                        </Form.Item>
                    </Col>
                    <Col>
                        <span>万円</span>
                    </Col>
                </Row>
            </Form.Item>
            <Form.Item
                {...formItemLayout}
                label="保有資格"
                className={styles.field}
                style={{ marginBottom: "0" }}></Form.Item>
            <Form.Item>
                <div className={styles.certificateSelection}>
                    <Form.Item
                        {...certificateItemLayout}
                        className={styles.certificateField}
                        label="Pマーク／ISMS"
                        help={fieldErrors.has_p_mark_or_isms}
                        name="has_p_mark_or_isms"
                        initialValue={initialData.has_p_mark_or_isms}
                        rules={[{ required: true }]}>
                        <Radio.Group>
                            <Radio
                                value={false}
                                data-testid="has_p_mark_or_isms_false">
                                なし
                            </Radio>
                            <Radio
                                value={true}
                                data-testid="has_p_mark_or_isms_true">
                                あり
                            </Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        {...certificateItemLayout}
                        className={styles.certificateField}
                        label="インボイス登録事業者"
                        help={fieldErrors.has_invoice_system}
                        name="has_invoice_system"
                        initialValue={initialData.has_invoice_system}
                        rules={[{ required: true }]}>
                        <Radio.Group>
                            <Radio
                                value={false}
                                data-testid="has_invoice_system_false">
                                なし
                            </Radio>
                            <Radio
                                value={true}
                                data-testid="has_invoice_system_true">
                                あり
                            </Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        {...certificateItemLayout}
                        className={styles.certificateField}
                        label="労働者派遣事業"
                        help={fieldErrors.has_haken}
                        name="has_haken"
                        initialValue={initialData.has_haken}
                        rules={[{ required: true }]}>
                        <Radio.Group>
                            <Radio value={false} data-testid="has_haken_false">
                                なし
                            </Radio>
                            <Radio value={true} data-testid="has_haken_true">
                                あり
                            </Radio>
                        </Radio.Group>
                    </Form.Item>
                </div>
            </Form.Item>
        </>
    );
};

export default CompanyInfoPane;
