"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var utils_1 = require("~/utils/utils");
var api_1 = require("~/domain/api");
var scheduledEmailHistory = function (client) {
    return {
        fetch: function (contactId, page, pageSize) {
            var expectedParamsObj = {
                page: page !== null && page !== void 0 ? page : "",
                pageSize: pageSize !== null && pageSize !== void 0 ? pageSize : 10
            };
            var queryStringParams = (0, utils_1.createQueryString)(expectedParamsObj);
            var url = api_1.Endpoint.contacts + '/' + contactId + '/' + api_1.Endpoint.scheduledMailsHistory + '?' + queryStringParams;
            return client.get(url);
        },
    };
};
exports.default = scheduledEmailHistory;
