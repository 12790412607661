// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Description-description-zLGRt{display:inline-flex;flex-direction:row;justify-content:flex-start;align-items:center}.Description-term-Fm0f0{text-align:left}.Description-detail-L_ZCw{margin:0 16px;text-align:left}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Navigations/Description.scss"],"names":[],"mappings":"AAAA,+BACE,mBAAoB,CACpB,kBAAmB,CACnB,0BAA2B,CAC3B,kBAAmB,CACpB,wBAGC,eAAgB,CACjB,0BAGC,aAAc,CACd,eAAgB","sourcesContent":[".description {\n  display: inline-flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: center;\n}\n\n.term {\n  text-align: left;\n}\n\n.detail {\n  margin: 0 16px;\n  text-align: left;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"description": `Description-description-zLGRt`,
	"term": `Description-term-Fm0f0`,
	"detail": `Description-detail-L_ZCw`
};
export default ___CSS_LOADER_EXPORT___;
