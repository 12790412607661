import CreateRegisterPage from './Factories/createRegisterPage';

import { SHARED_EMAIL_NOTIFICATION_REGISTER_PAGE } from './pageIds';

import { Endpoint } from '../../domain/api';
import { convertSharedEmailNotificationResponseDataEntry, SharedEmailNotificationFormToAPI } from '../../domain/data';

import Path from '../Routes/Paths';
import SharedEmailNotificationFormWrapper from '../Forms/SharedEmailNotificationForm/SharedEmailNotificationFormWrapper';

const resourceName = 'shared_email_notification';
const accessAuthorized = (authorizedActions) => { return authorizedActions && authorizedActions[resourceName] && authorizedActions[resourceName]['create'] };

const pageId = SHARED_EMAIL_NOTIFICATION_REGISTER_PAGE;
const resourceURL = `${Endpoint.getBaseUrl()}/${Endpoint.sharedEmailNotifications}`;

const SharedEmailNotificationRegisterPageContainer = CreateRegisterPage(
  pageId,
  'sharedEmailNotificationRegisterPage',
  '自動マッチング条件 登録',
  SharedEmailNotificationFormWrapper,
  resourceURL,
  Path.sharedMailNotifications,
  convertSharedEmailNotificationResponseDataEntry,
  SharedEmailNotificationFormToAPI,
  undefined,
  accessAuthorized,
);

export default SharedEmailNotificationRegisterPageContainer;
