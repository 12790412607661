import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import reducers from "./reducers";
import { LoginTransform } from "./redux/transforms/loginTransform";

const persistConfig = {
    // A config for redux persist.
    key: "root",
    storage,
    whitelist: [
        "login",
        "organizationSearchPage",
        "userSearchPage",
        "contactSearchPage",
        "scheduledEmailSearchPage",
        "sharedEmailNotificationSearchPage",
        "tagPage",
        "sharedEmailPage",
        "personnelBoardPage",
        "projectBoardPage",
        "sharedMailSearchPage",
        "recruitBoardPage",
        "salesBoardPage",
        "backOfficeBoardPage",
    ],
    transforms: [LoginTransform],
};

const persistedReducers = persistReducer(persistConfig, reducers);

const store = createStore(
    persistedReducers,
    composeWithDevTools(applyMiddleware(thunk))
);

export const persistor = persistStore(store);
export default store;
