// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProjectSkillFormItem-container-BdzdU{width:100%;text-align:left}.ProjectSkillFormItem-userInput-qHLJN{width:100%}.ProjectSkillFormItem-tooltip-rzWnI{margin-left:5px}.ProjectSkillFormItem-infoIcon-jPWMC{align-self:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/project/ProjectBoardPage/ProjectBoardSearchDrawer/ProjectSkillFormItem/ProjectSkillFormItem.scss"],"names":[],"mappings":"AAAA,sCACI,UAAW,CACX,eAAgB,CACnB,sCAGG,UAAW,CACd,oCAGG,eAAgB,CACnB,qCAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ProjectSkillFormItem-container-BdzdU`,
	"userInput": `ProjectSkillFormItem-userInput-qHLJN`,
	"tooltip": `ProjectSkillFormItem-tooltip-rzWnI`,
	"infoIcon": `ProjectSkillFormItem-infoIcon-jPWMC`
};
export default ___CSS_LOADER_EXPORT___;
