"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.recruitBoardUploadEndpointsCreator = exports.recruitBoardBasePath = void 0;
var api_1 = require("~/domain/api");
exports.recruitBoardBasePath = "board/recruit";
var recruitBoardUploadEndpointsCreator = function (cardId) {
    var basePath = api_1.Endpoint.getBaseUrl() +
        "/" +
        exports.recruitBoardBasePath +
        "/cards/" +
        cardId;
    var endpoints = {
        attachments: basePath + "/attachments",
    };
    return endpoints;
};
exports.recruitBoardUploadEndpointsCreator = recruitBoardUploadEndpointsCreator;
