"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSearchSync = void 0;
var react_router_dom_1 = require("react-router-dom");
var utils_1 = require("~/utils/utils");
var useQueryString_1 = require("./useQueryString");
var useSearchSync = function (options) {
    var defaultOptions = {
        parseNumbers: false,
    };
    var router = (0, react_router_dom_1.useHistory)();
    var queryParamsToObj = (0, useQueryString_1.useQueryString)(options ? __assign(__assign({}, defaultOptions), options) : defaultOptions).queryParamsToObj;
    // NOTE(joshua-hashimoto): 念の為ジェネリック型で定義。必要ないと判断されれば削除する
    var syncToUrl = function (values) {
        if ((0, utils_1.isEmpty)(values)) {
            router.push({ search: undefined });
            return;
        }
        var paramObj = __assign({}, values);
        for (var _i = 0, _a = Object.keys(paramObj); _i < _a.length; _i++) {
            var key = _a[_i];
            var val = paramObj[key];
            if (Array.isArray(val) && val.some(function (v) { return typeof v === "object"; })) {
                paramObj[key] = JSON.stringify(val);
            }
        }
        router.push({
            search: (0, utils_1.stringifyQueryString)(paramObj),
        });
    };
    return {
        syncToUrl: syncToUrl,
        queryParamsToObj: queryParamsToObj,
    };
};
exports.useSearchSync = useSearchSync;
