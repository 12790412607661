// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SalesBoardTabPaneContent-controlButtonsWrapper-epoPW{margin-top:2rem}.SalesBoardTabPaneContent-section-NqXPi{margin-top:3rem}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/sales/SalesBoardPage/SalesBoardDetailModal/SalesBoardTabPaneContent/SalesBoardTabPaneContent.scss"],"names":[],"mappings":"AAAA,sDACI,eAAgB,CACnB,wCAGG,eAAgB","sourcesContent":[".controlButtonsWrapper {\n    margin-top: 2rem;\n}\n\n.section {\n    margin-top: 3rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"controlButtonsWrapper": `SalesBoardTabPaneContent-controlButtonsWrapper-epoPW`,
	"section": `SalesBoardTabPaneContent-section-NqXPi`
};
export default ___CSS_LOADER_EXPORT___;
