// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PersonnelBoardContracts-container-ir7kW{width:100%;text-align:left}.PersonnelBoardContracts-spacer-NyS5l{margin-bottom:5%}.PersonnelBoardContracts-addBtn-KRcH3{margin-bottom:3%}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/personnel/PersonnelBoardPage/PersonnelBoardDetailModal/PersonnelBoardContracts/PersonnelBoardContracts.scss"],"names":[],"mappings":"AAAA,yCACI,UAAW,CACX,eAAgB,CACnB,sCAGG,gBAAiB,CACpB,sCAGG,gBAAiB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.spacer {\n    margin-bottom: 5%;\n}\n\n.addBtn {\n    margin-bottom: 3%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PersonnelBoardContracts-container-ir7kW`,
	"spacer": `PersonnelBoardContracts-spacer-NyS5l`,
	"addBtn": `PersonnelBoardContracts-addBtn-KRcH3`
};
export default ___CSS_LOADER_EXPORT___;
