// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SESBSFDeliveryTestButton-field-EH_uh{max-width:800px;margin:8px 0}@media screen and (max-width: 768px){.SESBSFDeliveryTestButton-field-EH_uh{margin:8px auto;width:100%}}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/ScheduledEmailSettingPage/ScheduledEmailSettingForm/ScheduledEmailSettingBaseSettingsForm/SESBSFDeliveryTestButton/SESBSFDeliveryTestButton.scss"],"names":[],"mappings":"AAEA,sCACI,eAAgB,CAChB,YAAa,CAKhB,qCAPD,sCAIQ,eAAgB,CAChB,UAAW,CAElB","sourcesContent":["@import \"~coreStylesheet\";\n\n.field {\n    max-width: 800px;\n    margin: 8px 0;\n    @include mobile-devices {\n        margin: 8px auto;\n        width: 100%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"field": `SESBSFDeliveryTestButton-field-EH_uh`
};
export default ___CSS_LOADER_EXPORT___;
