import createEditPage from "./Factories/createEditPage";
import { DISPLAY_SETTING_PAGE } from "./pageIds";
import { Endpoint } from "../../domain/api";
import Paths from "../Routes/Paths";
import RequiredDisplaySettingForm from "../Forms/RequiredDisplaySettingForm/RequiredDisplaySettingForm";

import { convertDisplaySettingDataEntry } from "../../domain/data";

const pageId = DISPLAY_SETTING_PAGE;
const pageTitle = "必須項目設定";
const resourceURL = `${Endpoint.getBaseUrl()}/${Endpoint.displaySetting}`;

const resourceName = "display_settings";
const accessAuthorized = (authorizedActions) => {
    return (
        authorizedActions &&
        authorizedActions[resourceName] &&
        authorizedActions[resourceName]["_all"]
    );
};

const RequiredDisplaySettingPage = createEditPage(
    pageId,
    "displaySettingPage",
    pageTitle,
    RequiredDisplaySettingForm,
    resourceURL,
    "",
    Paths.index,
    convertDisplaySettingDataEntry,
    (data) => data,
    undefined,
    undefined,
    () => true,
    accessAuthorized,
    undefined,
    false,
    () => pageTitle,
    true,
    Paths.index,
    undefined,
    undefined
);
export default RequiredDisplaySettingPage;
