import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Col, Button, Form, Input, Row, Select } from "antd";
import BaseForm from "./base/BaseForm";
import { finalized } from "./helpers";
import validateJapaneseMessages from "./validateMessages";
import { INVITED } from "../../actions/actionTypes";
import { createApi } from "../../actions/data";
import { Endpoint } from "../../domain/api";
import BackButton from "../Common/BackButton/BackButton";
import Paths from "../Routes/Paths";
import {
    ErrorMessages,
    ONLY_HANKAKU_REGEX,
    RESTRICT_SPACE_REGEX,
    ROLES,
} from "~/utils/constants";
import styles from "./EditForm.scss";

const { TextArea } = Input;

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
    },
};

class UserInviteForm extends BaseForm {
    constructor(props) {
        super(props);
        this.initialViewRendered = false;
    }

    state = {
        errorFields: [],
    };

    componentDidUpdate() {
        const { initialData } = this.props;
        if (!this.initialViewRendered && initialData && initialData.email) {
            this.baseform.current.setFieldsValue(initialData);
            this.initialViewRendered = true;
        }
    }

    getCurrentFieldError = (name) => {
        return (
            this.baseform.current &&
            this.baseform.current.getFieldError(name).length > 0
        );
    };

    handleSubmitError = ({ values, errorFields, outOfDate }) => {
        if (errorFields) {
            let errorFieldNames = errorFields.map((field) => {
                return field["name"][0];
            });
            this.setState({ errorFields: errorFieldNames }); // stateに変更を入れないとエラーが画面に反映されないため
        }
    };

    invite = (values) => {
        const { token, dispatch, pageId } = this.props;
        const resourceURL = `${Endpoint.getBaseUrl()}/${Endpoint.users}`;
        dispatch(
            createApi(pageId, token, resourceURL, values, { after: INVITED })
        );
    };

    handleSubmit = (event) => {
        // A callback for form.
        if (event && typeof event.preventDefault === "function") {
            event.preventDefault();
        }
        this.baseform.current.validateFields().then((values) => {
            this.invite(values);
        });
    };

    render() {
        const {
            initialData,
            fieldErrors,
            created,
            editable,
            authorizedActions,
        } = this.props;

        let editAuthorized =
            initialData.role === undefined ||
            (authorizedActions &&
                authorizedActions["_editable_roles"] &&
                initialData.role &&
                authorizedActions["_editable_roles"].includes(
                    initialData.role
                ));
        const requiredFields = ["email", "role"]
        return (
            <Form
                onFinish={this.handleSubmit}
                onFinishFailed={this.handleSubmitError}
                className={styles.container}
                ref={this.baseform}
                validateMessages={validateJapaneseMessages}>
                <Form.Item
                    {...formItemLayout}
                    label="メールアドレス"
                    className={styles.field}
                    validateStatus={fieldErrors.email ? "error" : undefined}
                    help={fieldErrors.email || fieldErrors.username}
                    name="email"
                    initialValue={initialData.email}
                    rules={[
                        { required: true },
                        {
                            pattern: ONLY_HANKAKU_REGEX,
                            message: ErrorMessages.validation.regex.onlyHankaku,
                        },
                        {
                            pattern: RESTRICT_SPACE_REGEX,
                            message: ErrorMessages.validation.regex.space,
                        },
                        {
                            max: 100,
                            message: ErrorMessages.validation.length.max100,
                        },
                    ]}>
                    <TextArea autoSize={{minRows:1}} placeholder="user@example.com" />
                </Form.Item>

                <Form.Item
                    {...formItemLayout}
                    label="権限"
                    className={styles.field}
                    help={fieldErrors.role}
                    name="role"
                    initialValue={initialData.role}
                    rules={[{ required: true }]}>
                    <Select allowClear={false} placeholder="クリックして選択">
                        {ROLES.filter((role) => role.value !== "master").map(
                            (role) => {
                                return (
                                    <Select.Option key={role.value} value={role.value}>
                                        {role.title}
                                    </Select.Option>
                                );
                            }
                        )}
                    </Select>
                </Form.Item>
                <Form.Item >
                    <Col span={24} style={{ marginTop: "5%" }}>
                        <Row justify="start">
                            <BackButton to={Paths.users} />
                            <Form.Item shouldUpdate>
                                {() => (
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        className={styles.button}
                                        disabled={
                                            created ||
                                            !editable ||
                                            !editAuthorized ||
                                            (
                                                this.baseform.current &&
                                                (!!this.baseform.current.getFieldsError().filter(({ errors }) => errors.length).length ||
                                                    !this.baseform.current.isFieldsTouched(requiredFields, true))
                                            )
                                        }>
                                        招待
                                    </Button>
                                )}
                            </Form.Item>
                        </Row>
                    </Col>
                </Form.Item>
            </Form>
        );
    }
}

UserInviteForm.propTypes = {
    resourceURL: PropTypes.string.isRequired,
    editable: PropTypes.bool,
    initialData: PropTypes.shape({
        // Corresponds to backend API.
        email: PropTypes.string,
    }), // Override in child class and use PropTypes.shape instead.
    fieldErrors: PropTypes.shape({
        email: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    submitHandler: PropTypes.func.isRequired,
    created: PropTypes.bool,
    authorizedActions: PropTypes.object,
    userId: PropTypes.string,
    pageId: PropTypes.string,
    dispatch: PropTypes.func.isRequired,
};

UserInviteForm.defaultProps = {
    initialData: {},
    created: false,
    editable: true,
};

function mapStateToProps(state) {
    return {
        token: state.login.token,
    };
}

const _UserInviteFormWrapper = finalized(UserInviteForm);
const UserInviteFormWrapper = connect(mapStateToProps)(_UserInviteFormWrapper);
export default UserInviteFormWrapper;
