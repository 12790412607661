"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.backOfficeBoardUploadEndpointsCreator = exports.backOfficeBoardBasePath = void 0;
var api_1 = require("~/domain/api");
exports.backOfficeBoardBasePath = "board/back_office";
var backOfficeBoardUploadEndpointsCreator = function (cardId) {
    var basePath = api_1.Endpoint.getBaseUrl() +
        "/" +
        exports.backOfficeBoardBasePath +
        "/cards/" +
        cardId;
    var endpoints = {
        attachments: basePath + "/attachments",
    };
    return endpoints;
};
exports.backOfficeBoardUploadEndpointsCreator = backOfficeBoardUploadEndpointsCreator;
