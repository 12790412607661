// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContactLastVisitFormItem-container-nlfB8{width:100%;text-align:left}.ContactLastVisitFormItem-userInput-LF_3F{width:100%}.ContactLastVisitFormItem-tooltip-mEAFR{margin-left:5px}.ContactLastVisitFormItem-infoIcon-y8dfE{align-self:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/ContactSearchPage/ContactSearchDrawer/ContactLastVisitFormItem/ContactLastVisitFormItem.scss"],"names":[],"mappings":"AAAA,0CACI,UAAW,CACX,eAAgB,CACnB,0CAGG,UAAW,CACd,wCAGG,eAAgB,CACnB,yCAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ContactLastVisitFormItem-container-nlfB8`,
	"userInput": `ContactLastVisitFormItem-userInput-LF_3F`,
	"tooltip": `ContactLastVisitFormItem-tooltip-mEAFR`,
	"infoIcon": `ContactLastVisitFormItem-infoIcon-y8dfE`
};
export default ___CSS_LOADER_EXPORT___;
