// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProjectStartDateFormItem-container-_g4z1{width:100%;text-align:left}.ProjectStartDateFormItem-userInput-PxgnX{width:100%}.ProjectStartDateFormItem-tooltip-WyBcf{margin-left:5px}.ProjectStartDateFormItem-infoIcon-mrX3R{align-self:center}.ProjectStartDateFormItem-customDatePicker-d8nju{width:100%;height:35px}.ProjectStartDateFormItem-marginBottom-xkyC3{margin-bottom:1%}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/project/ProjectBoardPage/ProjectBoardSearchDrawer/ProjectStartDateFormItem/ProjectStartDateFormItem.scss"],"names":[],"mappings":"AAAA,0CACE,UAAW,CACX,eAAgB,CACjB,0CAGC,UAAW,CACZ,wCAGC,eAAgB,CACjB,yCAGC,iBAAkB,CACnB,iDAGC,UAAW,CACX,WAAY,CACb,6CAGC,gBAAiB","sourcesContent":[".container {\n  width: 100%;\n  text-align: left;\n}\n\n.userInput {\n  width: 100%;\n}\n\n.tooltip {\n  margin-left: 5px;\n}\n\n.infoIcon {\n  align-self: center;\n}\n\n.customDatePicker {\n  width: 100%;\n  height: 35px;\n}\n\n.marginBottom {\n  margin-bottom: 1%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ProjectStartDateFormItem-container-_g4z1`,
	"userInput": `ProjectStartDateFormItem-userInput-PxgnX`,
	"tooltip": `ProjectStartDateFormItem-tooltip-WyBcf`,
	"infoIcon": `ProjectStartDateFormItem-infoIcon-mrX3R`,
	"customDatePicker": `ProjectStartDateFormItem-customDatePicker-d8nju`,
	"marginBottom": `ProjectStartDateFormItem-marginBottom-xkyC3`
};
export default ___CSS_LOADER_EXPORT___;
