// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BackOfficeBoardCreatePopover-container-ZeeXP{width:300px}.BackOfficeBoardCreatePopover-formWrapper-d5U57{width:100%;display:flex;flex-direction:column;align-items:center;justify-content:center}.BackOfficeBoardCreatePopover-wrapper-k4gBI{width:150px;margin-top:10px;margin-bottom:10px}.BackOfficeBoardCreatePopover-input-wvGl2{width:260px}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/backOffice/BackOfficeBoardPage/BackOfficeBoardCreatePopover/BackOfficeBoardCreatePopover.scss"],"names":[],"mappings":"AAAA,8CACI,WAAY,CACf,gDAGG,UAAW,CACX,YAAa,CACb,qBAAsB,CACtB,kBAAmB,CACnB,sBAAuB,CAE1B,4CAGG,WAAY,CACZ,eAAgB,CAChB,kBAAmB,CACtB,0CAGG,WAAY","sourcesContent":[".container {\n    width: 300px;\n}\n\n.formWrapper {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n\n}\n\n.wrapper {\n    width: 150px;\n    margin-top: 10px;\n    margin-bottom: 10px;\n}\n\n.input{\n    width: 260px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `BackOfficeBoardCreatePopover-container-ZeeXP`,
	"formWrapper": `BackOfficeBoardCreatePopover-formWrapper-d5U57`,
	"wrapper": `BackOfficeBoardCreatePopover-wrapper-k4gBI`,
	"input": `BackOfficeBoardCreatePopover-input-wvGl2`
};
export default ___CSS_LOADER_EXPORT___;
