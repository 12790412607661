// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProjGPriceFormItem-container-Hy5Aq{width:100%;text-align:left}.ProjGPriceFormItem-userInput-AXoDS{width:100%}.ProjGPriceFormItem-tooltip-UBYfA{margin-left:5px}.ProjGPriceFormItem-infoIcon-HAsdU{align-self:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/project/ProjectGanttPage/ProjectGanttSearchDrawer/ProjGPriceFormItem/ProjGPriceFormItem.scss"],"names":[],"mappings":"AAAA,oCACI,UAAW,CACX,eAAgB,CACnB,oCAGG,UAAW,CACd,kCAGG,eAAgB,CACnB,mCAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ProjGPriceFormItem-container-Hy5Aq`,
	"userInput": `ProjGPriceFormItem-userInput-AXoDS`,
	"tooltip": `ProjGPriceFormItem-tooltip-UBYfA`,
	"infoIcon": `ProjGPriceFormItem-infoIcon-HAsdU`
};
export default ___CSS_LOADER_EXPORT___;
