// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BoardScheduledEmailTemplateForm-redirectBtn-bvzZm{max-width:230px}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Common/Board/BoardScheduledEmailTemplateForm/BoardScheduledEmailTemplateForm.scss"],"names":[],"mappings":"AAAA,mDACI,eAAgB","sourcesContent":[".redirectBtn {\n    max-width: 230px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"redirectBtn": `BoardScheduledEmailTemplateForm-redirectBtn-bvzZm`
};
export default ___CSS_LOADER_EXPORT___;
