// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ScheduleMailTemplateListItem-contaienr-CVnhP{width:100%}.ScheduleMailTemplateListItem-title-WbfEo{text-align:left}.ScheduleMailTemplateListItem-editActionButton-pxeNP{margin-left:5px}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/DataDisplay/ScheduledMail/ScheduleMailTemplateListItem/ScheduleMailTemplateListItem.scss"],"names":[],"mappings":"AAAA,8CACE,UAAW,CACZ,0CAGC,eAAgB,CACjB,qDAGC,eAAgB","sourcesContent":[".contaienr {\n  width: 100%;\n}\n\n.title {\n  text-align: left;\n}\n\n.editActionButton {\n  margin-left: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contaienr": `ScheduleMailTemplateListItem-contaienr-CVnhP`,
	"title": `ScheduleMailTemplateListItem-title-WbfEo`,
	"editActionButton": `ScheduleMailTemplateListItem-editActionButton-pxeNP`
};
export default ___CSS_LOADER_EXPORT___;
