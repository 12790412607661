// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PlanTable-title-amjRJ{font-size:0.8rem;font-weight:bold;margin-bottom:0.3rem}.PlanTable-divider-GoZYX{height:100%}.PlanTable-planName-gKl_N{width:130px}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/PlanPage/PlanSummaryCard/PlanSwitchModal/PlanTable/PlanTable.scss"],"names":[],"mappings":"AAAA,uBACI,gBAAiB,CACjB,gBAAiB,CACjB,oBAAqB,CACxB,yBAGG,WAAY,CACf,0BAGG,WAAY","sourcesContent":[".title {\n    font-size: 0.8rem;\n    font-weight: bold;\n    margin-bottom: 0.3rem;\n}\n\n.divider {\n    height: 100%;\n}\n\n.planName {\n    width: 130px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `PlanTable-title-amjRJ`,
	"divider": `PlanTable-divider-GoZYX`,
	"planName": `PlanTable-planName-gKl_N`
};
export default ___CSS_LOADER_EXPORT___;
