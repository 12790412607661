// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BackOfficeAttachmentFormItem-container-C8txL{width:100%;text-align:left}.BackOfficeAttachmentFormItem-userInput-o4HxI{width:100%}.BackOfficeAttachmentFormItem-tooltip-Q2fw2{margin-left:5px}.BackOfficeAttachmentFormItem-infoIcon-OafnD{align-self:center}.BackOfficeAttachmentFormItem-select_option-NRGfg{text-align:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/backOffice/BackOfficeBoardPage/BackOfficeBoardSearchDrawer/BackOfficeAttachmentFormItem/BackOfficeAttachmentFormItem.scss"],"names":[],"mappings":"AAAA,8CACI,UAAW,CACX,eAAgB,CACnB,8CAGG,UAAW,CACd,4CAGG,eAAgB,CACnB,6CAGG,iBAAkB,CACrB,kDAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n\n.select_option {\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `BackOfficeAttachmentFormItem-container-C8txL`,
	"userInput": `BackOfficeAttachmentFormItem-userInput-o4HxI`,
	"tooltip": `BackOfficeAttachmentFormItem-tooltip-Q2fw2`,
	"infoIcon": `BackOfficeAttachmentFormItem-infoIcon-OafnD`,
	"select_option": `BackOfficeAttachmentFormItem-select_option-NRGfg`
};
export default ___CSS_LOADER_EXPORT___;
