// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TemplateControlButton-container-QGDeF{margin:3px}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Forms/SearchTemplateSelectFormItem/TemplateControlButton/TemplateControlButton.scss"],"names":[],"mappings":"AAAA,uCACI,UAAW","sourcesContent":[".container {\n    margin: 3px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `TemplateControlButton-container-QGDeF`
};
export default ___CSS_LOADER_EXPORT___;
