import {
    WRITING_NEW_COMMENTS,
    PARENT_CANNOT_SUBMIT_WHEN_NEW_COMMENT_EXISTS,
    COMMENT_NEW_CLEAR,
    CREATED,
    VALIDATION_ERROR,
} from "../../actions/actionTypes";

const commentActionSuffix = "__COMMENTS";

export const NewCommentInitialState = {
    commentValue: "",
    commentError: "",
    error: undefined,
    method: "",
};

const createNewCommentReducer = (
    pageId,
    initialState = NewCommentInitialState
) => {
    const newCommentReducer = (
        state = initialState,
        action
    ) => {
        switch (action.type) {
            case COMMENT_NEW_CLEAR:
                return {
                    commentValue: "",
                    commentError: "",
                    error: undefined,
                    method: "",
                };
            case WRITING_NEW_COMMENTS:
                return {
                    commentValue: action.payload.commentValue,
                    commentError: "",
                    error: undefined,
                    method: "",
                };
            case PARENT_CANNOT_SUBMIT_WHEN_NEW_COMMENT_EXISTS:
                return {
                    ...state,
                    commentError: "コメント欄は空にしてください。",
                    error: undefined,
                };
            case pageId + commentActionSuffix + CREATED:
                return {
                    commentValue: "",
                    commentError: "",
                    error: undefined,
                    method: "",
                };
            case pageId + commentActionSuffix + VALIDATION_ERROR:
                if (action.payload.method !== 'create') {
                    return {
                        ...state,
                    }
                }
                const error = action.payload.error;
                let errorMessage = error.message
                if (error.field_errors && error.field_errors.detail) {
                    errorMessage = error.field_errors.detail
                } else if(error.field_errors && error.field_errors.content) {
                    errorMessage = error.field_errors.content
                }
                return {
                    ...state,
                    commentError: errorMessage,
                    error: undefined,
                    method: "",
                }
            default:
                return { ...state };
        }
    };
    return newCommentReducer;
};

export default createNewCommentReducer;
