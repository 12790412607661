// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SalesStartDateFormItem-container-PmHsS{width:100%;text-align:left}.SalesStartDateFormItem-userInput-dQIbg{width:100%}.SalesStartDateFormItem-tooltip-lIfsc{margin-left:5px}.SalesStartDateFormItem-infoIcon-wZe1c{align-self:center}.SalesStartDateFormItem-customDatePicker-_dcub{width:100%;height:35px}.SalesStartDateFormItem-marginBottom-Hs7fN{margin-bottom:1%}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/sales/SalesGanttPage/SalesGanttSearchDrawer/SalesStartDateFormItem/SalesStartDateFormItem.scss"],"names":[],"mappings":"AAAA,wCACE,UAAW,CACX,eAAgB,CACjB,wCAGC,UAAW,CACZ,sCAGC,eAAgB,CACjB,uCAGC,iBAAkB,CACnB,+CAGC,UAAW,CACX,WAAY,CACb,2CAGC,gBAAiB","sourcesContent":[".container {\n  width: 100%;\n  text-align: left;\n}\n\n.userInput {\n  width: 100%;\n}\n\n.tooltip {\n  margin-left: 5px;\n}\n\n.infoIcon {\n  align-self: center;\n}\n\n.customDatePicker {\n  width: 100%;\n  height: 35px;\n}\n\n.marginBottom {\n  margin-bottom: 1%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `SalesStartDateFormItem-container-PmHsS`,
	"userInput": `SalesStartDateFormItem-userInput-dQIbg`,
	"tooltip": `SalesStartDateFormItem-tooltip-lIfsc`,
	"infoIcon": `SalesStartDateFormItem-infoIcon-wZe1c`,
	"customDatePicker": `SalesStartDateFormItem-customDatePicker-_dcub`,
	"marginBottom": `SalesStartDateFormItem-marginBottom-Hs7fN`
};
export default ___CSS_LOADER_EXPORT___;
