// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Select-wrapper-v1BaS{width:100%;text-align:left}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Common/Select/Select.scss"],"names":[],"mappings":"AAAA,sBACI,UAAW,CACX,eAAgB","sourcesContent":[".wrapper {\n    width: 100%;\n    text-align: left;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Select-wrapper-v1BaS`
};
export default ___CSS_LOADER_EXPORT___;
