// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContactEmailToFormItem-container-wh0TH{width:100%;text-align:left}.ContactEmailToFormItem-userInput-L0M0z{width:100%}.ContactEmailToFormItem-tooltip-HCZW2{margin-left:5px}.ContactEmailToFormItem-infoIcon-T2TEB{align-self:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/ContactSearchPage/ContactSearchDrawer/ContactEmailToFormItem/ContactEmailToFormItem.scss"],"names":[],"mappings":"AAAA,wCACI,UAAW,CACX,eAAgB,CACnB,wCAGG,UAAW,CACd,sCAGG,eAAgB,CACnB,uCAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ContactEmailToFormItem-container-wh0TH`,
	"userInput": `ContactEmailToFormItem-userInput-L0M0z`,
	"tooltip": `ContactEmailToFormItem-tooltip-HCZW2`,
	"infoIcon": `ContactEmailToFormItem-infoIcon-T2TEB`
};
export default ___CSS_LOADER_EXPORT___;
