// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomBackToTop-toTop-EuJw_{height:40px;width:40px;line-height:40px;border-radius:20px;background-color:#a7cf69;color:#ffffff !important;text-align:center;font-size:14px !important}.CustomBackToTop-content-YvmSt{display:flex;height:100%;justify-content:center;align-items:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Common/CustomBackToTop/CustomBackToTop.scss","webpack://./frontend/stylesheets/constants.scss"],"names":[],"mappings":"AAEA,6BACI,WAAY,CACZ,UAAW,CACX,gBAAiB,CACjB,kBAAmB,CACnB,wBCJmB,CDKnB,wBAAyB,CACzB,iBAAkB,CAClB,yBAA0B,CAC7B,+BAGG,YAAa,CACb,WAAY,CACZ,sBAAuB,CACvB,kBAAmB","sourcesContent":["@import \"~coreStylesheet\";\n\n.toTop {\n    height: 40px;\n    width: 40px;\n    line-height: 40px;\n    border-radius: 20px;\n    background-color: $primary-color;\n    color: #ffffff !important;\n    text-align: center;\n    font-size: 14px !important;\n}\n\n.content {\n    display: flex;\n    height: 100%;\n    justify-content: center;\n    align-items: center;\n}\n","/* アプリケーション全体 */\n$breakpoint-mobile: 768px;\n$width-small-screen: 1500px;\n$primary-color: #a7cf69;\n$komorebi-brown: #615748;\n\n/* ガントチャート関連 */\n$gantt-over70: #003656;\n$gantt-between40to70: #80d6ff;\n$gantt-under40: #d0edff;\n\n@mixin mobile-devices() {\n    @media screen and (max-width: $breakpoint-mobile) {\n        @content;\n    }\n}\n\n@mixin small-screen() {\n    @media screen and (max-width: $width-small-screen) {\n        @content;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toTop": `CustomBackToTop-toTop-EuJw_`,
	"content": `CustomBackToTop-content-YvmSt`
};
export default ___CSS_LOADER_EXPORT___;
