import moment from "moment";

import {
    DELETED,
    ERROR,
    INVALID_TOKEN,
    LOADED,
    LOADING,
    LOGOUT,
    RESET_ERROR_MESSAGE,
    UPDATED,
    VALIDATED_TOKEN,
    VALIDATION_ERROR,
} from "~/actions/actionTypes";

import {
    CONTACT_CSV_UPLOAD_PAGE,
    CONTACT_EDIT_PAGE,
    CONTACT_EMAIL_PREFERENCE_EDIT_PAGE,
    CONTACT_REGISTER_PAGE,
    CONTACT_SEARCH_PAGE,
    DASHBOARD_STATS,
    DISPLAY_SETTING_PAGE,
    EMAIL_STATS,
    MY_COMPANY_PAGE,
    MY_PROFILE_PAGE,
    ORGANIZATION_CSV_UPLOAD_PAGE,
    ORGANIZATION_EDIT_PAGE,
    ORGANIZATION_REGISTER_PAGE,
    ORGANIZATION_SEARCH_PAGE,
    ORGANIZATION_STATS,
    PASSWORD_CHANGE_PAGE,
    PERSONNEL_BOARD_PAGE,
    PERSONNEL_DETAIL_BOARD_PAGE,
    PROJECT_BOARD_PAGE,
    PROJECT_DETAIL_BOARD_PAGE,
    SCHEDULED_MAIL_EDIT_PAGE,
    SCHEDULED_MAIL_EDIT_PAGE_CONTACT_PICKER_FORM,
    SCHEDULED_MAIL_REGISTER_PAGE,
    SCHEDULED_MAIL_REGISTER_PAGE_CONTACT_PICKER_FORM,
    SCHEDULED_MAIL_SEARCH_PAGE,
    SHARED_EMAIL_DETAIL_PAGE,
    SHARED_EMAIL_NOTIFICATION_EDIT_PAGE,
    SHARED_EMAIL_NOTIFICATION_REGISTER_PAGE,
    SHARED_EMAIL_NOTIFICATION_SEARCH_PAGE,
    SHARED_EMAIL_PAGE,
    SHARED_EMAIL_SETTING_PAGE,
    USER_EDIT_PAGE,
    USER_INVITE_PAGE,
    USER_REGISTER_PAGE,
    USER_SEARCH_PAGE,
    RECRUIT_BOARD_PAGE,
    RECRUIT_DETAIL_BOARD_PAGE,
    SALES_BOARD_PAGE,
    SALES_DETAIL_BOARD_PAGE,
    BACK_OFFICE_BOARD_PAGE,
    BACK_OFFICE_DETAIL_BOARD_PAGE,
} from "~/components/Pages/pageIds";

import createReadOnlyPageReducer from "./Factories/readOnlyPage";
import createSearchPageReducer, {defaultInitialState as defaultSearchPageState,} from "./Factories/searchPage";
import createRegisterPageReducer from "./Factories/registerPage";
import createRealtimeRegisterPageReducer from "./Factories/realtimeRegisterPage";
import createEditPageReducer, {defaultInitialStateOfUndeletableResource,} from "./Factories/editPage";
import createCommentListReducer from "./Factories/commentList";
import createNewCommentReducer from "./Factories/newCommentReducer";
import createEditCommentReducer from "./Factories/editCommentReducer";
import createReplyCommentReducer from "./Factories/replyCommentReducer";
import createTemplateCommentReducer from "./Factories/templateScheduledReducer";
import { createValidateTimeReducer } from "./Factories/mailValidReducer";
import {checkEmailReducer} from "./checkEmailReducer";
import creatCheckListReducer from "./Factories/checkListReducer";

const commentActionSuffix = "__COMMENTS";

export const myProfilePage = createEditPageReducer(
    MY_PROFILE_PAGE,
    defaultInitialStateOfUndeletableResource
);

export const myCompanyPage = createEditPageReducer(
    MY_COMPANY_PAGE,
    defaultInitialStateOfUndeletableResource
);

export const displaySettingPage = createEditPageReducer(
    DISPLAY_SETTING_PAGE,
    defaultInitialStateOfUndeletableResource
);

const defaultSharedEmailSearchRange = [
    moment().startOf("day").subtract(7, "days"),
    moment().endOf("day"),
];

const sharedEmailPageDefaultState = Object.assign({}, defaultSearchPageState, {
    currentSearchConditions: { date_range: defaultSharedEmailSearchRange },
});

export const sharedEmailPage = createSearchPageReducer(
    SHARED_EMAIL_PAGE,
    sharedEmailPageDefaultState
);

export const sharedEmailDetailInitialState = {
    loading: true,
    message: "",
    errorMessage: "",
    data: {},
    canNotDelete: false,
    deleted: false,
};

export const sharedEmailDetailPage = (
    state = sharedEmailDetailInitialState,
    action
) => {
    switch (action.type) {
        case SHARED_EMAIL_DETAIL_PAGE + LOADING:
            return Object.assign({}, state, sharedEmailDetailInitialState);
        case SHARED_EMAIL_DETAIL_PAGE + LOADED:
            return Object.assign({}, state, {
                loading: false,
                message: "",
                errorMessage: "",
                data: action.payload.data,
            });
        case SHARED_EMAIL_DETAIL_PAGE + ERROR:
            return Object.assign({}, state, {
                loading: false,
                message: "",
                errorMessage: action.payload.error.detail,
            });
        case SHARED_EMAIL_DETAIL_PAGE + DELETED:
            return {
                ...state,
                deleted: true,
            };
        case LOGOUT:
            return Object.assign({}, state, sharedEmailDetailInitialState);
        case SHARED_EMAIL_DETAIL_PAGE + commentActionSuffix + VALIDATION_ERROR:
            return Object.assign({}, state, {
                loading: false,
                message: "",
                errorMessage: action.payload.error.detail,
            });
        case SHARED_EMAIL_DETAIL_PAGE + RESET_ERROR_MESSAGE:
            return {
                ...state,
                errorMessage: ""
            };
        default:
            return state;
    }
};

export const passwordChangeInitialState = {
    loading: true,
    message: "",
    errorMessage: "",
    data: {},
    showPage: true,
};
export const passwordChangePage = (
    state = passwordChangeInitialState,
    action
) => {
    let pageId = PASSWORD_CHANGE_PAGE;
    switch (action.type) {
        case pageId + VALIDATED_TOKEN:
            return Object.assign({}, state, {
                loading: false,
                showPage: true,
            });
        case pageId + INVALID_TOKEN:
            return Object.assign({}, state, {
                loading: false,
                showPage: false,
            });
        case pageId + UPDATED:
            return Object.assign({}, state, {
                loading: false,
                message: "パスワードを変更しました",
                errorMessage: "",
            });
        case pageId + VALIDATION_ERROR:
            return Object.assign({}, state, {
                loading: false,
                errorMessage:
                    "パスワードが一致していません。パスワードを正しく入力してください。",
            });
        case pageId + ERROR:
            return Object.assign({}, state, {
                loading: false,
                errorMessage: "パスワードを変更に失敗しました",
            });
        default:
            return state;
    }
};

export const sharedEmailDetailPageComments = createCommentListReducer(
    SHARED_EMAIL_DETAIL_PAGE
);

// FIXME(Chikama): This is temporal solution. Fix me to use comment state instead of page state.
const createCommentTemplateErrorOnlyReducer = (pageId) => {
    return (state = {errorMessage: ""}, action) => {
        switch (action.type) {
            case pageId + commentActionSuffix + VALIDATION_ERROR:
                return Object.assign({}, state, {
                    errorMessage: action.payload.error.detail,
                });
            case pageId + RESET_ERROR_MESSAGE:
                return {
                    errorMessage: ""
                }
            default:
                return state;
        }
    }
}
export const personnelDetailBoardPage = createCommentTemplateErrorOnlyReducer(PERSONNEL_DETAIL_BOARD_PAGE);
export const projectDetailBoardPage = createCommentTemplateErrorOnlyReducer(PROJECT_DETAIL_BOARD_PAGE);
export const recruitDetailBoardPage = createCommentTemplateErrorOnlyReducer(RECRUIT_DETAIL_BOARD_PAGE);
export const salesDetailBoardPage = createCommentTemplateErrorOnlyReducer(SALES_DETAIL_BOARD_PAGE);
export const backOfficeDetailBoardPage = createCommentTemplateErrorOnlyReducer(BACK_OFFICE_DETAIL_BOARD_PAGE);

export const personnelBoardDetailPageComments = createCommentListReducer(
    PERSONNEL_DETAIL_BOARD_PAGE
);

export const personnelBoardDetailPageNewComments = createNewCommentReducer(
    PERSONNEL_DETAIL_BOARD_PAGE
);

export const personnelBoardDetailPageEditComments = createEditCommentReducer(
    PERSONNEL_DETAIL_BOARD_PAGE
);

export const personnelBoardDetailPageReplyComments = createReplyCommentReducer(
    PERSONNEL_DETAIL_BOARD_PAGE
);

export const projectBoardDetailPageComments = createCommentListReducer(
    PROJECT_DETAIL_BOARD_PAGE
);

export const projectBoardDetailPageNewComments = createNewCommentReducer(
    PROJECT_DETAIL_BOARD_PAGE
);

export const projectBoardDetailPageEditComments = createEditCommentReducer(
    PROJECT_DETAIL_BOARD_PAGE
);

export const projectBoardDetailPageReplyComments = createReplyCommentReducer(
    PROJECT_DETAIL_BOARD_PAGE
);

export const recruitBoardDetailPageComments = createCommentListReducer(
    RECRUIT_DETAIL_BOARD_PAGE
);

export const recruitBoardDetailPageNewComments = createNewCommentReducer(
    RECRUIT_DETAIL_BOARD_PAGE
);

export const recruitBoardDetailPageEditComments = createEditCommentReducer(
    RECRUIT_DETAIL_BOARD_PAGE
);

export const recruitBoardDetailPageReplyComments = createReplyCommentReducer(
    RECRUIT_DETAIL_BOARD_PAGE
);

export const salesBoardDetailPageComments = createCommentListReducer(
    SALES_DETAIL_BOARD_PAGE
);

export const salesBoardDetailPageNewComments = createNewCommentReducer(
    SALES_DETAIL_BOARD_PAGE
);

export const salesBoardDetailPageEditComments = createEditCommentReducer(
    SALES_DETAIL_BOARD_PAGE
);

export const salesBoardDetailPageReplyComments = createReplyCommentReducer(
    SALES_DETAIL_BOARD_PAGE
);

export const backOfficeBoardDetailPageComments = createCommentListReducer(
    BACK_OFFICE_DETAIL_BOARD_PAGE
);

export const backOfficeBoardDetailPageNewComments = createNewCommentReducer(
    BACK_OFFICE_DETAIL_BOARD_PAGE
);

export const backOfficeBoardDetailPageEditComments = createEditCommentReducer(
    BACK_OFFICE_DETAIL_BOARD_PAGE
);

export const backOfficeBoardDetailPageReplyComments = createReplyCommentReducer(
    BACK_OFFICE_DETAIL_BOARD_PAGE
);

export const organizationSearchPage = createSearchPageReducer(
    ORGANIZATION_SEARCH_PAGE
);

export const organizationRegisterPage = createRegisterPageReducer(
    ORGANIZATION_REGISTER_PAGE
);

export const organizationCsvUploadPage = createRegisterPageReducer(
    ORGANIZATION_CSV_UPLOAD_PAGE
);

export const organizationEditPage = createEditPageReducer(
    ORGANIZATION_EDIT_PAGE
);

export const organizationEditPageComments = createCommentListReducer(
    ORGANIZATION_EDIT_PAGE
);

export const userSearchPage = createSearchPageReducer(USER_SEARCH_PAGE);

export const userRegisterPage = createEditPageReducer(USER_REGISTER_PAGE);

export const sharedMailSearchPage = createSearchPageReducer(SHARED_EMAIL_PAGE);

export const userInvitePage = createRegisterPageReducer(USER_INVITE_PAGE);

export const userEditPage = createEditPageReducer(USER_EDIT_PAGE);

export const contactSearchPage = createSearchPageReducer(CONTACT_SEARCH_PAGE);

export const contactRegisterPage = createRegisterPageReducer(
    CONTACT_REGISTER_PAGE
);

export const contactCsvUploadPage = createRegisterPageReducer(
    CONTACT_CSV_UPLOAD_PAGE
);

export const contactEditPage = createEditPageReducer(CONTACT_EDIT_PAGE);

export const contactEditPageComments =
    createCommentListReducer(CONTACT_EDIT_PAGE);

export const contactEmailPreferenceEditPage = createEditPageReducer(
    CONTACT_EMAIL_PREFERENCE_EDIT_PAGE,
    defaultInitialStateOfUndeletableResource
);

export const scheduledEmailSearchPage = createSearchPageReducer(
    SCHEDULED_MAIL_SEARCH_PAGE
);

export const scheduledEmailRegisterPage = createRealtimeRegisterPageReducer(
    SCHEDULED_MAIL_REGISTER_PAGE
);

export const scheduledEmailRegisterPageContactSearchForm =
    createSearchPageReducer(SCHEDULED_MAIL_REGISTER_PAGE_CONTACT_PICKER_FORM);

export const scheduledEmailEditPage = createRealtimeRegisterPageReducer(
    SCHEDULED_MAIL_EDIT_PAGE
);

export const scheduledEmailPageTemplates = createTemplateCommentReducer(
  SCHEDULED_MAIL_REGISTER_PAGE
  );

  export const scheduledEmailEditPageTemplates = createTemplateCommentReducer(
    SCHEDULED_MAIL_EDIT_PAGE
    );

export const scheduledEmailEditPageContactSearchForm = createSearchPageReducer(
    SCHEDULED_MAIL_EDIT_PAGE_CONTACT_PICKER_FORM
);

export const sharedEmailNotificationSearchPage = createSearchPageReducer(
    SHARED_EMAIL_NOTIFICATION_SEARCH_PAGE
);

export const sharedEmailNotificationRegisterPage = createRegisterPageReducer(
    SHARED_EMAIL_NOTIFICATION_REGISTER_PAGE
);

export const sharedEmailNotificationEditPage = createEditPageReducer(
    SHARED_EMAIL_NOTIFICATION_EDIT_PAGE
);

export const emailStatsContainer = createReadOnlyPageReducer(EMAIL_STATS);

export const organizationStatsContainer =
    createReadOnlyPageReducer(ORGANIZATION_STATS);

export const dashboardStatsContainer =
    createReadOnlyPageReducer(DASHBOARD_STATS);

export const sharedEmailSettingPage = createEditPageReducer(
    SHARED_EMAIL_SETTING_PAGE,
    defaultInitialStateOfUndeletableResource
);

export const sharedEmailDetailPageNewComments = createNewCommentReducer(
    SHARED_EMAIL_DETAIL_PAGE
);

export const sharedEmailDetailPageReplyComments = createReplyCommentReducer(
    SHARED_EMAIL_DETAIL_PAGE
);

export const organizationEditPageNewComments = createNewCommentReducer(
    ORGANIZATION_EDIT_PAGE
);
export const contactEditPageNewComments =
    createNewCommentReducer(CONTACT_EDIT_PAGE);

export const sharedEmailDetailPageEditComments = createEditCommentReducer(
    SHARED_EMAIL_DETAIL_PAGE
);
export const organizationEditPageEditComments = createEditCommentReducer(
    ORGANIZATION_EDIT_PAGE
);
export const contactEditPageEditComments =
    createEditCommentReducer(CONTACT_EDIT_PAGE);

export const organizationEditPageReplyComments = createReplyCommentReducer(
  ORGANIZATION_EDIT_PAGE
);

export const contactEditPageReplyComments = createReplyCommentReducer(
  CONTACT_EDIT_PAGE
);

export const emailValidateTime = createValidateTimeReducer()
export const checkEmailExistRegister = checkEmailReducer(CONTACT_REGISTER_PAGE)

export const checkEmailExistEdit = checkEmailReducer(CONTACT_EDIT_PAGE)

export const personnelBoardPage = createSearchPageReducer(PERSONNEL_BOARD_PAGE);
export const projectBoardPage = createSearchPageReducer(PROJECT_BOARD_PAGE);
export const recruitBoardPage = createSearchPageReducer(RECRUIT_BOARD_PAGE);
export const salesBoardPage = createSearchPageReducer(SALES_BOARD_PAGE);
export const backOfficeBoardPage = createSearchPageReducer(BACK_OFFICE_BOARD_PAGE);

export const personnelBoardPageCheckList = creatCheckListReducer(PERSONNEL_BOARD_PAGE);
export const projectBoardPageCheckList = creatCheckListReducer(PROJECT_BOARD_PAGE);
export const recruitBoardPageCheckList = creatCheckListReducer(RECRUIT_BOARD_PAGE);
export const salesBoardPageCheckList = creatCheckListReducer(SALES_BOARD_PAGE);
export const backOfficeBoardPageCheckList = creatCheckListReducer(BACK_OFFICE_BOARD_PAGE);
