// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProjectContractStartFormItem-container-XwA83{width:100%;text-align:left}.ProjectContractStartFormItem-userInput-XnHyq{width:100%}.ProjectContractStartFormItem-tooltip-k52_k{margin-left:5px}.ProjectContractStartFormItem-infoIcon-jviac{align-self:center}.ProjectContractStartFormItem-customDatePicker-lJOom{width:100%;height:35px}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/project/ProjectBoardPage/ProjectBoardSearchDrawer/ProjectContractStartFormItem/ProjectContractStartFormItem.scss"],"names":[],"mappings":"AAAA,8CACE,UAAW,CACX,eAAgB,CACjB,8CAGC,UAAW,CACZ,4CAGC,eAAgB,CACjB,6CAGC,iBAAkB,CACnB,qDAGC,UAAW,CACX,WAAY","sourcesContent":[".container {\n  width: 100%;\n  text-align: left;\n}\n\n.userInput {\n  width: 100%;\n}\n\n.tooltip {\n  margin-left: 5px;\n}\n\n.infoIcon {\n  align-self: center;\n}\n\n.customDatePicker {\n  width: 100%;\n  height: 35px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ProjectContractStartFormItem-container-XwA83`,
	"userInput": `ProjectContractStartFormItem-userInput-XnHyq`,
	"tooltip": `ProjectContractStartFormItem-tooltip-k52_k`,
	"infoIcon": `ProjectContractStartFormItem-infoIcon-jviac`,
	"customDatePicker": `ProjectContractStartFormItem-customDatePicker-lJOom`
};
export default ___CSS_LOADER_EXPORT___;
