// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SalesBoardCreatePopover-container-nEjI3{width:300px}.SalesBoardCreatePopover-formWrapper-L7TBf{width:100%;display:flex;flex-direction:column;align-items:center;justify-content:center}.SalesBoardCreatePopover-wrapper-NQNQ7{width:150px;margin-top:10px;margin-bottom:10px}.SalesBoardCreatePopover-input-AbnyD{width:260px}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/sales/SalesBoardPage/SalesBoardCreatePopover/SalesBoardCreatePopover.scss"],"names":[],"mappings":"AAAA,yCACI,WAAY,CACf,2CAGG,UAAW,CACX,YAAa,CACb,qBAAsB,CACtB,kBAAmB,CACnB,sBAAuB,CAE1B,uCAGG,WAAY,CACZ,eAAgB,CAChB,kBAAmB,CACtB,qCAGG,WAAY","sourcesContent":[".container {\n    width: 300px;\n}\n\n.formWrapper {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n\n}\n\n.wrapper {\n    width: 150px;\n    margin-top: 10px;\n    margin-bottom: 10px;\n}\n\n.input{\n    width: 260px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `SalesBoardCreatePopover-container-nEjI3`,
	"formWrapper": `SalesBoardCreatePopover-formWrapper-L7TBf`,
	"wrapper": `SalesBoardCreatePopover-wrapper-NQNQ7`,
	"input": `SalesBoardCreatePopover-input-AbnyD`
};
export default ___CSS_LOADER_EXPORT___;
