// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserSelectFormItem-wrapper-PcJHc{margin-bottom:1%}.UserSelectFormItem-container-ukBy6{width:100%;text-align:left}.UserSelectFormItem-infoIcon-KyTOx{align-self:center}.UserSelectFormItem-tooltip-u0R5S{margin-left:5px;color:#615748}.UserSelectFormItem-select-GLdl5{width:100%}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/SharedEmailNotificationSearchPage/SharedEmailNotificationSearchDrawer/UserSelectFormItem/UserSelectFormItem.scss","webpack://./frontend/stylesheets/constants.scss"],"names":[],"mappings":"AAEA,kCACE,gBAAiB,CAClB,oCAGC,UAAW,CACX,eAAgB,CACjB,mCAGC,iBAAkB,CACnB,kCAGC,eAAgB,CAChB,aCbsB,CDcvB,iCAGC,UAAW","sourcesContent":["@import \"~coreStylesheet\";\n\n.wrapper {\n  margin-bottom: 1%;\n}\n\n.container {\n  width: 100%;\n  text-align: left;\n}\n\n.infoIcon {\n  align-self: center;\n}\n\n.tooltip {\n  margin-left: 5px;\n  color: $komorebi-brown;\n}\n\n.select {\n  width: 100%;\n}\n","/* アプリケーション全体 */\n$breakpoint-mobile: 768px;\n$width-small-screen: 1500px;\n$primary-color: #a7cf69;\n$komorebi-brown: #615748;\n\n/* ガントチャート関連 */\n$gantt-over70: #003656;\n$gantt-between40to70: #80d6ff;\n$gantt-under40: #d0edff;\n\n@mixin mobile-devices() {\n    @media screen and (max-width: $breakpoint-mobile) {\n        @content;\n    }\n}\n\n@mixin small-screen() {\n    @media screen and (max-width: $width-small-screen) {\n        @content;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `UserSelectFormItem-wrapper-PcJHc`,
	"container": `UserSelectFormItem-container-ukBy6`,
	"infoIcon": `UserSelectFormItem-infoIcon-KyTOx`,
	"tooltip": `UserSelectFormItem-tooltip-u0R5S`,
	"select": `UserSelectFormItem-select-GLdl5`
};
export default ___CSS_LOADER_EXPORT___;
