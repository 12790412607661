// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProjBSkillsFormItem-marginBottom0-C5Esh{margin-bottom:0px}.ProjBSkillsFormItem-marginBottom10-qbPU5{margin-bottom:10px}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/project/ProjectBoardPage/ProjectBoardDetailModal/ProjectBoardBaseInfoForm/ProjBSkillsFormItem/ProjBSkillsFormItem.scss"],"names":[],"mappings":"AAAA,yCACI,iBAAkB,CACrB,0CAGG,kBAAmB","sourcesContent":[".marginBottom0 {\n    margin-bottom: 0px;\n}\n\n.marginBottom10 {\n    margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"marginBottom0": `ProjBSkillsFormItem-marginBottom0-C5Esh`,
	"marginBottom10": `ProjBSkillsFormItem-marginBottom10-qbPU5`
};
export default ___CSS_LOADER_EXPORT___;
