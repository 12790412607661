// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CorporateBranchFaxFormItem-container-O3uAc{width:100%;text-align:left}.CorporateBranchFaxFormItem-phonenumberInput-RdgEb{width:100%}.CorporateBranchFaxFormItem-tooltip-RFpj8{margin-left:5px}.CorporateBranchFaxFormItem-inlineField-Uf1RV{display:inline-block;_display:inline}.CorporateBranchFaxFormItem-infoIcon-F_TuM{align-self:center}.CorporateBranchFaxFormItem-separater-gWaMl{align-self:center;text-align:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/OrganizationSearchPage/OrganizationSearchDrawer/CorporateBranchFaxFormItem/CorporateBranchFaxFormItem.scss"],"names":[],"mappings":"AAAA,4CACI,UAAW,CACX,eAAgB,CACnB,mDAGG,UAAW,CACd,0CAGG,eAAgB,CACnB,8CAGG,oBAAqB,EACrB,cAAgB,CACnB,2CAGG,iBAAkB,CACrB,4CAGG,iBAAkB,CAClB,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.phonenumberInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.inlineField {\n    display: inline-block;\n    _display: inline;\n}\n\n.infoIcon {\n    align-self: center;\n}\n\n.separater {\n    align-self: center;\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `CorporateBranchFaxFormItem-container-O3uAc`,
	"phonenumberInput": `CorporateBranchFaxFormItem-phonenumberInput-RdgEb`,
	"tooltip": `CorporateBranchFaxFormItem-tooltip-RFpj8`,
	"inlineField": `CorporateBranchFaxFormItem-inlineField-Uf1RV`,
	"infoIcon": `CorporateBranchFaxFormItem-infoIcon-F_TuM`,
	"separater": `CorporateBranchFaxFormItem-separater-gWaMl`
};
export default ___CSS_LOADER_EXPORT___;
