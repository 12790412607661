"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertBackOfficeBoardDetailResponseModelToBackOfficeBoardDetailModel = exports.convertBackOfficeBoardFormModelToBackOfficeBoardRequestModel = exports.convertBackOfficeBoardDetailModelToBackOfficeBoardFormModel = void 0;
var constants_1 = require("~/utils/constants");
var boardCommon_1 = require("../boardCommon");
var convertBackOfficeBoardDetailModelToBackOfficeBoardFormModel = function (data) {
    var _a, _b;
    var obj = {
        assignees: data.assignees,
        isArchived: data.isArchived,
        listId: data.listId,
        order: data.order,
        period: data.period,
        priority: (_a = data === null || data === void 0 ? void 0 : data.priority) !== null && _a !== void 0 ? _a : undefined,
        detail: data.detail,
        description: data.description,
        tags: data.tags,
        dynamicRows: (_b = data.dynamicRows) === null || _b === void 0 ? void 0 : _b.map(function (item) { return ({
            id: item.id,
            rowTitle: item.title && item.titleId
                ? { label: item.title, value: item.titleId }
                : undefined,
            content: item.content,
        }); }),
    };
    return obj;
};
exports.convertBackOfficeBoardDetailModelToBackOfficeBoardFormModel = convertBackOfficeBoardDetailModelToBackOfficeBoardFormModel;
var convertBackOfficeBoardFormModelToBackOfficeBoardRequestModel = function (data) {
    var _a, _b, _c, _d;
    var priority = data.priority ? (data.priority.value ? data.priority.value : data.priority) : null;
    var obj = {
        id: data.id,
        order: data.order,
        listId: data.listId,
        assignees: data.assignees,
        priority: priority,
        period: "period" in data ? {
            start: ((_a = data.period) === null || _a === void 0 ? void 0 : _a.start)
                ? data.period.start.format(constants_1.pickerLocaleConfig.dateFormat)
                : null,
            end: ((_b = data.period) === null || _b === void 0 ? void 0 : _b.end)
                ? data.period.end.format(constants_1.pickerLocaleConfig.dateTimeFormat)
                : null,
            isFinished: !!((_c = data.period) === null || _c === void 0 ? void 0 : _c.isFinished),
        } : undefined,
        detail: data.detail,
        description: data.description,
        tags: data.tags,
        isArchived: data.isArchived,
        dynamicRows: (_d = data.dynamicRows) === null || _d === void 0 ? void 0 : _d.map(function (item) {
            var _a;
            return ({
                id: item.id,
                title_id: (_a = item.rowTitle) === null || _a === void 0 ? void 0 : _a.value,
                content: item.content,
            });
        }),
    };
    return obj;
};
exports.convertBackOfficeBoardFormModelToBackOfficeBoardRequestModel = convertBackOfficeBoardFormModelToBackOfficeBoardRequestModel;
var convertBackOfficeBoardDetailResponseModelToBackOfficeBoardDetailModel = function (result) {
    return __assign(__assign({}, result), { period: (0, boardCommon_1.convertBoardPeriodModelToBoardPeriodFormModel)(result === null || result === void 0 ? void 0 : result.period) });
};
exports.convertBackOfficeBoardDetailResponseModelToBackOfficeBoardDetailModel = convertBackOfficeBoardDetailResponseModelToBackOfficeBoardDetailModel;
