"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkEmailReducer = exports.CheckEmailInitialState = void 0;
var actionTypes_1 = require("~/actions/actionTypes");
exports.CheckEmailInitialState = {
    loading: false,
    message: "",
    errorMessage: "",
};
var checkEmailReducer = function (pageId) {
    var Reducer = function (state, action) {
        if (state === void 0) { state = exports.CheckEmailInitialState; }
        switch (action.type) {
            case pageId + actionTypes_1.CHECKING_EMAIL_ADDRESS:
                return __assign(__assign({}, state), { loading: true, message: "", errorMessage: "" });
            case pageId + actionTypes_1.CHECKED_EMAIL_ADDRESS:
                return __assign(__assign({}, state), { loading: false, message: action.payload.message, errorMessage: "" });
            case pageId + actionTypes_1.CHECK_ERROR_EMAIL_ADDRESS:
                return __assign(__assign({}, state), { loading: false, message: "", errorMessage: action.payload.errorMessage });
            case pageId + actionTypes_1.CHECK_CLEAR_EMAIL_ADDRESS:
                return __assign(__assign({}, state), { loading: false, message: "", errorMessage: "" });
            default:
                return __assign({}, state);
        }
    };
    return Reducer;
};
exports.checkEmailReducer = checkEmailReducer;
