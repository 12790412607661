// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PlanTableColumn-title-oMOWd{font-size:0.75rem}.PlanTableColumn-content-rURvk{font-size:0.875rem}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/PlanPage/PlanSummaryCard/PlanSwitchModal/PlanTable/PlanTableColumn/PlanTableColumn.scss"],"names":[],"mappings":"AAAA,6BACI,iBAAkB,CACrB,+BAGG,kBAAmB","sourcesContent":[".title {\n    font-size: 0.75rem;\n}\n\n.content {\n    font-size: 0.875rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `PlanTableColumn-title-oMOWd`,
	"content": `PlanTableColumn-content-rURvk`
};
export default ___CSS_LOADER_EXPORT___;
