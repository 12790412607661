"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoginTransform = void 0;
var redux_persist_1 = require("redux-persist");
var constants_1 = require("~/utils/constants");
var utils_1 = require("~/utils/utils");
exports.LoginTransform = (0, redux_persist_1.createTransform)(function (inboundState, key) {
    var temp = __assign({}, inboundState);
    var authorizedActions = temp.authorizedActions;
    if (!!temp.token &&
        (!authorizedActions || (0, utils_1.isEmpty)(authorizedActions))) {
        temp.authorizedActions = constants_1.AUTHORIZED_ACTIONS_DEFAULT_VALUE;
    }
    return __assign({}, temp);
}, function (outboundState, key) {
    var _a, _b;
    // NOTE(joshua-hashimoto): redux-persistが値を復帰するとき、tokenは存在するが権限情報が不正な値であればauthorizedActionsにデフォルト値を入れる。
    var temp = __assign({}, outboundState);
    var authorizedActions = temp.authorizedActions;
    if (!!temp.token &&
        (!authorizedActions || (0, utils_1.isEmpty)(authorizedActions))) {
        temp.authorizedActions = constants_1.AUTHORIZED_ACTIONS_DEFAULT_VALUE;
    }
    // TODO(joshua-hashimoto): 全てのプロパティの存在を確認するコードに変更すること
    // NOTE(joshua-hashimoto): 共有メール通知条件の権限が存在しない場合はデフォルト値を入れるように修正
    if (!authorizedActions.shared_email_notification ||
        (0, utils_1.isEmpty)(authorizedActions.shared_email_notification)) {
        temp.authorizedActions.shared_email_notification =
            constants_1.AUTHORIZED_ACTIONS_DEFAULT_VALUE.shared_email_notification;
    }
    // NOTE(joshua-hashimoto): 要員ボードの権限が存在しない場合はデフォルト値を入れるように修正
    var personnelBoardAuthorizedActions = (_a = authorizedActions.personnel) === null || _a === void 0 ? void 0 : _a.board;
    if (!personnelBoardAuthorizedActions ||
        (0, utils_1.isEmpty)(personnelBoardAuthorizedActions)) {
        temp.authorizedActions.personnel.board =
            constants_1.AUTHORIZED_ACTIONS_DEFAULT_VALUE.personnel.board;
    }
    // NOTE(joshua-hashimoto): 案件ボードの権限が存在しない場合はデフォルト値を入れるように修正
    var projectBoardAuthorizedActions = (_b = authorizedActions.project) === null || _b === void 0 ? void 0 : _b.board;
    if (!projectBoardAuthorizedActions ||
        (0, utils_1.isEmpty)(projectBoardAuthorizedActions)) {
        temp.authorizedActions.project.board =
            constants_1.AUTHORIZED_ACTIONS_DEFAULT_VALUE.project.board;
    }
    // NOTE(joshua-hashimoto): 自動マッチングメールの通知先権限が存在しない場合はデフォルト値を入れるように修正
    var autoMatchingAuthorizedActions = authorizedActions.shared_email_notification;
    if (autoMatchingAuthorizedActions &&
        autoMatchingAuthorizedActions.notify_to_other === undefined) {
        autoMatchingAuthorizedActions.notify_to_other = false;
    }
    return __assign({}, temp);
}, {
    whitelist: ["login"],
});
