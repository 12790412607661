"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var utils_1 = require("./utils");
var contracts = function (client) {
    var path = function (dynamicPath) {
        if (dynamicPath === void 0) { dynamicPath = ""; }
        return utils_1.personnelBoardBasePath + "/contracts" + dynamicPath;
    };
    return {
        fetchContracts: function (cardId) {
            var url = path("?cardId=" + cardId);
            return client.get(url);
        },
        createContract: function (postData) {
            var url = path();
            return client.post(url, postData);
        },
        fetchContract: function (contractId) {
            var url = path("/" + contractId);
            return client.get(url);
        },
        updateContract: function (contractId, postData) {
            var url = path("/" + contractId);
            return client.patch(url, postData);
        },
        createUpdateContract: function (cardId, postData) {
            var url = path("/batch-update?cardId=".concat(cardId));
            return client.patch(url, postData);
        },
        deleteContract: function (contractId) {
            var url = path("/" + contractId);
            return client.delete(url);
        },
        fetchContacts: function () {
            var url = "/contacts";
            return client.get(url);
        },
    };
};
exports.default = contracts;
