// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SearchTemplateSelect-select-evFS4{width:100%;text-align:left}.SearchTemplateSelect-select_option-Bk_fi{text-align:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Common/SearchTemplateSelect/SearchTemplateSelect.scss"],"names":[],"mappings":"AAAA,mCACI,UAAW,CACX,eAAgB,CACnB,0CAGG,iBAAkB","sourcesContent":[".select {\n    width: 100%;\n    text-align: left;\n}\n\n.select_option {\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select": `SearchTemplateSelect-select-evFS4`,
	"select_option": `SearchTemplateSelect-select_option-Bk_fi`
};
export default ___CSS_LOADER_EXPORT___;
