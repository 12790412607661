// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProjectAttachmentFormItem-container-v8PXw{width:100%;text-align:left}.ProjectAttachmentFormItem-userInput-DN28C{width:100%}.ProjectAttachmentFormItem-tooltip-gy_YH{margin-left:5px}.ProjectAttachmentFormItem-infoIcon-i1VhW{align-self:center}.ProjectAttachmentFormItem-select_option-R_pxA{text-align:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/project/ProjectBoardPage/ProjectBoardSearchDrawer/ProjectAttachmentFormItem/ProjectAttachmentFormItem.scss"],"names":[],"mappings":"AAAA,2CACI,UAAW,CACX,eAAgB,CACnB,2CAGG,UAAW,CACd,yCAGG,eAAgB,CACnB,0CAGG,iBAAkB,CACrB,+CAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n\n.select_option {\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ProjectAttachmentFormItem-container-v8PXw`,
	"userInput": `ProjectAttachmentFormItem-userInput-DN28C`,
	"tooltip": `ProjectAttachmentFormItem-tooltip-gy_YH`,
	"infoIcon": `ProjectAttachmentFormItem-infoIcon-i1VhW`,
	"select_option": `ProjectAttachmentFormItem-select_option-R_pxA`
};
export default ___CSS_LOADER_EXPORT___;
