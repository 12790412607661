"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sanitizeFilterValues = exports.sanitizeUrlValues = exports.emptySearchDeps = exports.emptyRule = exports.emptyPaginationRequestDeps = void 0;
exports.emptyPaginationRequestDeps = {
    page: 1,
    pageSize: 10,
    value: undefined,
};
exports.emptyRule = {
    target_type: undefined,
    condition_type: undefined,
    value: undefined,
};
exports.emptySearchDeps = {
    inactive_filter: false,
    name: undefined,
    assignees: [],
    master_rule: undefined,
    rules: [exports.emptyRule],
    created_user: [],
    modified_user: [],
};
var sanitizeUrlValues = function (data) {
    return data;
};
exports.sanitizeUrlValues = sanitizeUrlValues;
var sanitizeFilterValues = function (data) {
    return data;
};
exports.sanitizeFilterValues = sanitizeFilterValues;
