import React, { useState } from "react";
import { Col, Row, Form, Switch, Tooltip } from "antd";
import {
    QuestionCircleFilled,
    CheckOutlined,
    CloseOutlined,
} from "@ant-design/icons";
import { TooltipContentLink } from "~/components/Common/TooltipContentLink/TooltipContentLink";
import { Links, iconCustomColor } from "~/utils/constants";
import Paths from "~/components/Routes/Paths";
import styles from "./IncludeInvalidSwitchFormItem.scss";

const IncludeInvalidSwitchFormItem = ({}) => {
    return (
        <Col span={24} style={{ marginBottom: "1%", padding: 0 }}>
            <Row className={styles.container}>
                <Col span={23}>
                    <Row gutter={6} justify="end">
                        <Col>
                            <label className={styles.switchLabel}>
                                自社取引条件対象外含
                            </label>
                        </Col>
                        <Col>
                            <Form.Item
                                labelCol={{}}
                                wrapperCol={{}}
                                name="ignore_filter"
                                valuePropName="checked"
                                noStyle>
                                <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
                <Col span={1} className={styles.infoIcon}>
                    <Tooltip
                        title={
                            <span>
                                <TooltipContentLink
                                    to={`${Paths.myCompany}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    title="自社プロフィール"
                                />
                                の取引条件を満たしていない所属取引先の取引先担当者を表示させる場合は有効にしてください。
                                <br />
                                <a
                                    href={
                                        Links.helps.filter
                                            .includingOutOfTradingCondition
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    詳細
                                </a>
                            </span>
                        }>
                        <QuestionCircleFilled
                            style={{ color: iconCustomColor }}
                            className={styles.tooltip}
                        />
                    </Tooltip>
                </Col>
            </Row>
        </Col>
    );
};

export default IncludeInvalidSwitchFormItem;
