"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePasswordResetAPIMutation = exports.useAccountDeleteAPIMutation = void 0;
var api_1 = require("~/networking/api");
var constants_1 = require("~/utils/constants");
var useAuth_1 = require("./useAuth");
var useCustomMutation_1 = require("./useCustomMutation");
var AlertMessage_1 = require("~/components/Common/AlertMessage/AlertMessage");
var useAccountDeleteAPIMutation = function () {
    var logout = (0, useAuth_1.useLogoutAPIMutation)().mutate;
    return (0, useCustomMutation_1.useCustomMutation)(api_1.accountAPI.deleteAccount, {
        onSuccess: function () {
            (0, AlertMessage_1.customSuccessMessage)(constants_1.SuccessMessages.account.disable);
            logout();
        },
        onError: function (err) {
            var _a;
            var errorMessage = constants_1.ErrorMessages.account.disable;
            if ((_a = err.response) === null || _a === void 0 ? void 0 : _a.data.detail) {
                errorMessage = err.response.data.detail;
            }
            (0, AlertMessage_1.customErrorMessage)(errorMessage);
        },
    });
};
exports.useAccountDeleteAPIMutation = useAccountDeleteAPIMutation;
var usePasswordResetAPIMutation = function () {
    return (0, useCustomMutation_1.useCustomMutation)(api_1.accountAPI.passwordReset, {
        onSuccess: function (response) {
            // NOTE(joshua-hashimoto): エラーでもstatusは200で返ってくるので、onSuccessでエラーを処理
            var result = response.data.result;
            if (result === "AccountDoesNotExist") {
                (0, AlertMessage_1.customErrorMessage)(constants_1.ErrorMessages.account.accountNotFound);
            }
            else {
                (0, AlertMessage_1.customSuccessMessage)(constants_1.SuccessMessages.account.passwordReset);
            }
        },
    });
};
exports.usePasswordResetAPIMutation = usePasswordResetAPIMutation;
