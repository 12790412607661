// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RecruitGanttInfo-over-ILdUA{color:#EB5A46}.RecruitGanttInfo-today-FCnrT{color:#FFAB4A}.RecruitGanttInfo-prevDay-k_5Tf{color:#1890FF}.RecruitGanttInfo-completed-vF5Rq{color:#A7CF69}.RecruitGanttInfo-default-hrUiW{color:#BFBFBF}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/recruit/RecruitGanttPage/RecruitGanttInfo/RecruitGanttInfo.scss"],"names":[],"mappings":"AAEA,6BACI,aAAc,CACjB,8BAGG,aAAc,CACjB,gCAGG,aAAc,CACjB,kCAGG,aAAc,CACjB,gCAGG,aAAc","sourcesContent":["@import \"~coreStylesheet\";\n\n.over {\n    color: #EB5A46;\n}\n\n.today {\n    color: #FFAB4A;\n}\n\n.prevDay {\n    color: #1890FF;\n}\n\n.completed {\n    color: #A7CF69;\n}\n\n.default {\n    color: #BFBFBF;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"over": `RecruitGanttInfo-over-ILdUA`,
	"today": `RecruitGanttInfo-today-FCnrT`,
	"prevDay": `RecruitGanttInfo-prevDay-k_5Tf`,
	"completed": `RecruitGanttInfo-completed-vF5Rq`,
	"default": `RecruitGanttInfo-default-hrUiW`
};
export default ___CSS_LOADER_EXPORT___;
