"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var utils_1 = require("./utils");
var checklists = function (client) {
    var path = function (dynamicPath) {
        if (dynamicPath === void 0) { dynamicPath = ""; }
        return utils_1.salesBoardBasePath + "/checklists" + dynamicPath;
    };
    return {
        fetchChecklists: function (cardId) {
            var url = path("?cardId=" + cardId);
            return client.get(url);
        },
        createChecklist: function (postData) {
            var url = path();
            return client.post(url, postData);
        },
        fetchChecklist: function (checklistId) {
            var url = path("/" + checklistId);
            return client.get(url);
        },
        updateChecklist: function (checklistId, postData) {
            var url = path("/" + checklistId);
            return client.patch(url, postData);
        },
        deleteChecklist: function (checklistId) {
            var url = path("/" + checklistId);
            return client.delete(url);
        },
        fetchChecklistItems: function (checklistId) {
            var url = path("/" + checklistId + "/items");
            return client.get(url);
        },
        createChecklistItem: function (checklistId, postData) {
            var url = path("/" + checklistId + "/items");
            return client.post(url, postData);
        },
        fetchChecklistItem: function (checklistId, itemId) {
            var url = path("/" + checklistId + "items" + itemId);
            return client.get(url);
        },
        updateChecklistItem: function (checklistId, itemId, postData) {
            var url = path("/" + checklistId + "/items/" + itemId);
            return client.patch(url, postData);
        },
        deleteChecklistItem: function (checklistId, itemId) {
            var url = path("/" + checklistId + "/items/" + itemId);
            return client.delete(url);
        },
    };
};
exports.default = checklists;
