"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var api_1 = require("~/domain/api");
var utils_1 = require("~/utils/utils");
var scheduledEmail = function (client) {
    return {
        fetchOpenerList: function (scheduledEmailId) {
            var url = api_1.Endpoint.scheduledEmailOpenerList + "/" + scheduledEmailId;
            return client.get(url);
        },
        searchScheduledEmails: function (params) {
            var url = api_1.Endpoint.scheduledEmails +
                "?" +
                (0, utils_1.createQueryString)(params !== null && params !== void 0 ? params : {});
            return client.get(url);
        },
        previewScheduledEmails: function (postData) {
            var url = api_1.Endpoint.scheduledEmailsPreview;
            return client.post(url, postData);
        },
        scheduledEmailAttachmentSizeExtended: function () {
            return client.get(api_1.Endpoint.scheduledEmailAttachmentSizeLimit);
        },
        authorizeDownload: function (id, password) {
            var url = "".concat(api_1.Endpoint.scheduledEmailFiles, "/").concat(id, "?password=").concat(password);
            return client.get(url);
        },
        downloadFiles: function (postData) {
            var url = api_1.Endpoint.scheduledEmailFilesDownload;
            return client.post(url, postData);
        },
        fetchNgWords: function () {
            var url = api_1.Endpoint.scheduledEmailNgWords;
            return client.get(url);
        },
        bulkUpdate: function (postData) {
            var url = api_1.Endpoint.scheduledEmailsEdit;
            var data = (0, utils_1.convertCamelObjectToSnakeObject)(postData);
            var formData = new FormData();
            for (var _i = 0, _a = Object.entries(data); _i < _a.length; _i++) {
                var _b = _a[_i], key = _b[0], value = _b[1];
                if (Array.isArray(value)) {
                    for (var _c = 0, value_1 = value; _c < value_1.length; _c++) {
                        var item = value_1[_c];
                        formData.append(key, item);
                    }
                }
                else if (typeof value === 'boolean') {
                    formData.append(key, value ? 'True' : 'False');
                }
                else if (value !== undefined) {
                    formData.append(key, value);
                }
            }
            return client.patch(url, formData);
        },
    };
};
exports.default = scheduledEmail;
