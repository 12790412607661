// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SkillSelectFormItem-field-xDd2V{margin-bottom:20px}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Common/BoardCommon/SkillSelectFormItem/SkillSelectFormItem.scss"],"names":[],"mappings":"AAAA,iCACI,kBAAmB","sourcesContent":[".field {\n    margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"field": `SkillSelectFormItem-field-xDd2V`
};
export default ___CSS_LOADER_EXPORT___;
