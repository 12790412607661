import { Component } from 'react';
import React from 'react';
import PropTypes from 'prop-types';

// Component Definition
class BaseForm extends Component {
  constructor(props) {
    super(props);
    this.submitHandler = props.submitHandler;
  }

  baseform = React.createRef();

  handleReset = () => {
    const { resetFormHandler } = this.props;
    this.baseform.current.resetFields();
    resetFormHandler();
  };

  handleSubmit = (event) => { // A callback for form.
    if (event && typeof event.preventDefault === "function") {
      event.preventDefault();
    }
    this.baseform.current.validateFields().then((values) => {
      this.submitHandler(values);
    });
  }
}


BaseForm.propTypes = {// will be used in child class.
  form: PropTypes.object, // Automatically delivered by Ant design form in a from instance.
  submitHandler: PropTypes.func.isRequired,
  resetFormHandler: PropTypes.func.isRequired,
};

export default BaseForm;
