import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button, Tag, Tooltip, Space, Skeleton, Popover } from "antd";
import Path from "~/components/Routes/Paths";
import GenericTable from "~/components/Tables/GenericTable";
import TooltipCopy from "~/components/Common/TooltipCopy/TooltipCopy";
import {
    DownloadOutlined,
    FileTextTwoTone,
    LinkOutlined,
    MailTwoTone,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import {
    iconCustomColor,
    iconPrimaryColor,
    SCHEDULED_EMAIL_SEND_TYPE,
    SCHEDULED_EMAIL_STATUS,
    SCHEDULED_EMAIL_TEXT_FORMAT,
} from "~/utils/constants";
import ScheduledEmailOpenerListModal from "./ScheduledEmailOpenerListModal/ScheduledEmailOpenerListModal";
import { stringBreak } from "~/utils/utils";
import Paths from "~/components/Routes/Paths";
import { useRouter } from "~/hooks/router";
import styles from "./ScheduledMailsTable.scss";
import AttachmentDownloaderModal from "~/components/Common/AttachmentDownloaderModal";
import DLPurchaseAddonTooltip from "./DLPurchaseAddonTooltip";
import OpenCountAddonTooltip from "~/components/Common/OpenCountAddonTooltip";

const SendTypeTag = [
    ...SCHEDULED_EMAIL_SEND_TYPE,
    {
        title: null,
        color: null,
        value: null,
    },
];

const renderStatus = (value) => {
    const displayTag = SCHEDULED_EMAIL_STATUS.find(
        (status) => status.value === value
    );
    if (displayTag && displayTag.value.includes("draft")) {
        return <Tag>{displayTag?.title}</Tag>;
    }
    return <Tag color={displayTag?.color}>{displayTag?.title}</Tag>;
};

const renderSendType = (send_type) => {
    const displayTag = SendTypeTag.find(
        (sendTypeTag) => sendTypeTag.value === send_type
    );
    return <Tag color={displayTag?.color}>{displayTag?.title}</Tag>;
};

const renderTextFormat = (text_format) => {
    const displayTag = SCHEDULED_EMAIL_TEXT_FORMAT.find(
        (textFormat) => textFormat.value === text_format
    );
    return <Tag color={displayTag.color}>{displayTag.title}</Tag>;
};

const onMinify = {
    renderTitle: (item) => item.subject,
    renderDescription: (item) => (
        <div>
            <p>{`配信時刻: ${
                item.date_to_send ? item.date_to_send : "未設定"
            }`}</p>
            <p>{`配信者: ${item.sender__name}`}</p>
            <span>
                配信ステータス:
                <span style={{ paddingLeft: 8 }}>
                    {renderStatus(item.status)}
                </span>
            </span>
        </div>
    ),
};

const scheduledMailTable = ({
    loading,
    isAttachmentDownloadAddonPurchased,
    isOpenCountAddonPurchased,
    isOpenCountExtraPeriodAvailable,
    ...props
}) => {
    const router = useRouter();

    const onRowClick = (record) => {};
    const [isOpenModalList, setIsOpenModalList] = useState(false);
    const [selectedAttachments, setSelectedAttachments] = useState([]);
    const [
        isAttachmentDownloaderModalOpen,
        setIsAttachmentDownloaderModalOpen,
    ] = useState(false);
    const [recordId, setRecordId] = useState(null);
    const [recordCheckVisiblePopover, setRecordCheckVisiblePopover] =
        useState(null);
    const [attachmentId, setAttachmentId] = useState(null);

    const onColumnClick = (record) => {
        const url = `${Path.scheduledMails}/${record.id}`;
        router.push(url);
    };

    const onAttachemntClick = (record) => {
        const url = `${Path.scheduledMails}/${record.id}?step=1`;
        router.openNewTab(url);
    };

    const isDisplaySettingLoading = useSelector(
        (state) => state.displaySettingPage.isDisplaySettingLoading
    );

    const { displaySetting } = useSelector((state) => state.displaySettingPage);

    const columns = (onCellClick, onAttachemntClick) => {
        return [
            {
                title: "配信種別",
                key: "scheduled_email_send_type__order",
                sorter: true,
                dataIndex: "scheduled_email_send_type__order",
                render: (send_type, record) => {
                    if (record.send_type) {
                        return renderSendType(record.send_type);
                    } else {
                        return "";
                    }
                },
                onCell: (record) => ({
                    onClick: () => onCellClick(record),
                }),
                width: 110,
                ellipsis: true,
            },
            {
                title: "件名",
                dataIndex: "subject",
                key: "subject",
                onCell: (record) => ({
                    onClick: () => onCellClick(record),
                }),
                width: 300,
                ellipsis: true,
                className: styles.tooltipCopy,
                render: (subject, record) => {
                    const breakNumber = 20;
                    const value = subject ?? "";
                    if (value.length > breakNumber) {
                        const valueList = stringBreak(value, breakNumber);
                        return (
                            <div>
                                {valueList.map((val, idx) => {
                                    if (idx + 1 === valueList.length) {
                                        return val;
                                    }
                                    return (
                                        <>
                                            {val}
                                            <br />
                                        </>
                                    );
                                })}
                                <TooltipCopy copyContent={value} />
                            </div>
                        );
                    }
                    return (
                        <div
                            style={{
                                wordWrap: "break-word",
                                wordBreak: "break-word",
                            }}>
                            {value}
                            <TooltipCopy copyContent={value} />
                        </div>
                    );
                },
            },
            {
                title: "ファイル形式",
                key: "attachments",
                dataIndex: "attachments",
                render: (attachments, record) => {
                    const URLAttachment = attachments.length
                        ? attachments.filter(
                              (item) => item.file_type === 1 && item.gcp_link
                          )[0]
                        : null;
                    const fileAttachment = attachments.length
                        ? attachments.filter((item) => !item.gcp_link)
                        : [];
                    return (
                        <React.Fragment>
                            {URLAttachment ? (
                                <Tooltip
                                    className={styles.attachmentIcon}
                                    title={
                                        <span>
                                            ダウンロードURL:
                                            <br />
                                            <Link
                                                to={
                                                    Paths.scheduledMailsDownloadAttachment +
                                                    "/" +
                                                    URLAttachment.attached_mail_id
                                                }
                                                target="_blank">
                                                {window.location.protocol}/
                                                {window.location.host}
                                                {
                                                    Paths.scheduledMailsDownloadAttachment
                                                }
                                                /
                                                {URLAttachment.attached_mail_id}
                                            </Link>
                                            <br />
                                            パスワード:
                                            <br />
                                            {record.password}
                                        </span>
                                    }>
                                    <span>
                                        <LinkOutlined
                                            style={{ color: iconPrimaryColor }}
                                        />
                                    </span>
                                </Tooltip>
                            ) : (
                                ""
                            )}
                            {fileAttachment.length
                                ? fileAttachment.map((attachment) => (
                                      <Tooltip
                                          className={styles.attachmentIcon}
                                          key={record.uid}
                                          title={attachment.name}>
                                          <span
                                              onClick={() =>
                                                  onAttachemntClick(record)
                                              }>
                                              <FileTextTwoTone
                                                  twoToneColor={
                                                      iconPrimaryColor
                                                  }
                                              />
                                          </span>
                                      </Tooltip>
                                  ))
                                : ""}
                        </React.Fragment>
                    );
                },
                width: 150,
                ellipsis: true,
            },
            {
                title: "配信ステータス",
                dataIndex: "scheduled_email_status__order",
                sorter: true,
                key: "scheduled_email_status__order",
                render: (status, record) => renderStatus(record.status),
                onCell: (record) => ({
                    onClick: () => onCellClick(record),
                }),
                width: 150,
                ellipsis: true,
            },
            {
                title: "配信者",
                dataIndex: "sender__last_name",
                sorter: true,
                key: "sender__last_name",
                onCell: (record) => ({
                    onClick: () => onCellClick(record),
                }),
                width: 160,
                ellipsis: true,
            },
            {
                title: "配信予定日時",
                key: "date_to_send",
                dataIndex: "date_to_send",
                sorter: true,
                onCell: (record) => ({
                    onClick: () => onCellClick(record),
                }),
                width: 160,
                ellipsis: true,
            },
            {
                title: "配信完了日時",
                dataIndex: "sent_date",
                sorter: true,
                key: "sent_date",
                onCell: (record) => ({
                    onClick: () => onCellClick(record),
                }),
                width: 160,
                ellipsis: true,
            },
            {
                title: "フォーマット",
                dataIndex: "text_format",
                key: "text_format",
                render: (text_format, record) => {
                    if (record.text_format) {
                        return renderTextFormat(record.text_format);
                    } else {
                        return "";
                    }
                },
                onCell: (record) => ({
                    onClick: () => onCellClick(record),
                }),
                width: 140,
                ellipsis: true,
            },
            {
                title: "配信数",
                dataIndex: "send_total_count",
                key: "send_total_count",
                onCell: (record) => ({
                    onClick: () => onCellClick(record),
                }),
                width: 100,
                ellipsis: true,
                sorter: true,
            },
            {
                title: () => {
                    return (
                        <>
                            開封数&nbsp;
                            <OpenCountAddonTooltip
                                isAddonPurchased={isOpenCountAddonPurchased}
                            />
                        </>
                    );
                },
                dataIndex: "open_count",
                key: "open_count_format",
                width: 100,
                className: `${styles.openCountFormat}`,
                ellipsis: true,
                render: (text, record, index) => {
                    if (
                        record.text_format == "text" ||
                        record.status == "draft" ||
                        record.status == "queued"
                    ) {
                        return "";
                    }
                    if (record.open_count > 0) {
                        return (
                            <div
                                className={styles.openCount}
                                key={record?.id}
                                onMouseEnter={() =>
                                    setRecordCheckVisiblePopover((current) => {
                                        return record?.id;
                                    })
                                }
                                onMouseLeave={() =>
                                    setRecordCheckVisiblePopover((current) => {
                                        return null;
                                    })
                                }>
                                {/** NOTE(shintaro-suzuki): スタイリングのためスペースを入れている */}
                                {text}{" "}
                                <Tooltip
                                    title="アイコンをクリックすると開封者の一覧が表示されます。"
                                    visible={
                                        recordCheckVisiblePopover ===
                                            record?.id && !isOpenModalList
                                    }
                                    onClick={() => {
                                        setIsOpenModalList((current) => {
                                            return true;
                                        });
                                        setRecordId((current) => {
                                            return record?.id;
                                        });
                                    }}>
                                    <MailTwoTone
                                        twoToneColor={
                                            iconPrimaryColor
                                        }></MailTwoTone>
                                </Tooltip>
                            </div>
                        );
                    } else {
                        return (
                            <div className={styles.openCount}>
                                {record.open_count}
                            </div>
                        );
                    }
                },
            },
            {
                title: () => {
                    return (
                        <>
                            開封率&nbsp;
                            <OpenCountAddonTooltip
                                isAddonPurchased={isOpenCountAddonPurchased}
                            />
                        </>
                    );
                },
                dataIndex: "open_ratio",
                key: "open_ratio",
                render: (_, record) =>
                    !record.open_ratio && record.open_ratio !== 0
                        ? ""
                        : `${record.open_ratio}%`,
                width: 100,
            },
            {
                title: () => {
                    return (
                        <>
                            DL数&nbsp;
                            <DLPurchaseAddonTooltip
                                isAttachmentDownloadAddonPurchased={
                                    isAttachmentDownloadAddonPurchased
                                }
                            />
                        </>
                    );
                },
                dataIndex: "download_count",
                key: "download_count",
                width: 100,
                ellipsis: true,
                render: (text, record, index) => {
                    const attachments = record.attachments ?? [];
                    const isContainingDownloadType = attachments.some(
                        (value) => value.file_type === 1
                    );
                    if (!isContainingDownloadType) {
                        return "";
                    }
                    if (record.status == "draft" || record.status == "queued") {
                        return "";
                    }
                    if (record.download_count > 0) {
                        return (
                            <div
                                key={record?.id}
                                onMouseEnter={() =>
                                    setAttachmentId((current) => {
                                        return record?.id;
                                    })
                                }
                                onMouseLeave={() =>
                                    setAttachmentId((current) => {
                                        return null;
                                    })
                                }>
                                {/** NOTE(shintaro-suzuki): スタイリングのためスペースを入れている */}
                                {text}{" "}
                                <Tooltip
                                    title="アイコンをクリックするとDL者の一覧が表示されます。"
                                    visible={
                                        attachmentId === record?.id &&
                                        !isAttachmentDownloaderModalOpen
                                    }
                                    onClick={() => {
                                        setIsAttachmentDownloaderModalOpen(
                                            (current) => {
                                                return true;
                                            }
                                        );
                                        setSelectedAttachments((current) => {
                                            return record.attachments;
                                        });
                                        setAttachmentId((current) => {
                                            return record?.id;
                                        });
                                    }}>
                                    <DownloadOutlined
                                        style={{ color: iconPrimaryColor }}
                                    />
                                </Tooltip>
                            </div>
                        );
                    } else {
                        return record.download_count;
                    }
                },
            },
            {
                title: () => {
                    return (
                        <>
                            DL率&nbsp;
                            <DLPurchaseAddonTooltip
                                isAttachmentDownloadAddonPurchased={
                                    isAttachmentDownloadAddonPurchased
                                }
                            />
                        </>
                    );
                },
                dataIndex: "download_ratio",
                key: "download_ratio",
                render: (_, record) => {
                    const attachments = record.attachments ?? [];
                    const isContainingDownloadType = attachments.some(
                        (value) => value.file_type === 1
                    );
                    if (!isContainingDownloadType) {
                        return "";
                    }
                    if (
                        !record.download_ratio &&
                        +record.download_ratio !== 0
                    ) {
                        return "";
                    }
                    return `${record.download_ratio}%`;
                },
                width: 100,
            },
            {
                title: "作成日時",
                dataIndex: "created_time",
                key: "created_time",
                sorter: true,
                width: 160,
            },
            {
                title: "更新日時",
                dataIndex: "modified_time",
                key: "modified_time",
                sorter: true,
                width: 160,
            },
        ];
    };

    return (
        <>
            {isDisplaySettingLoading ? (
                <Space className={styles.container}>
                    <Skeleton.Input
                        style={{ height: 200, width: 1200 }}
                        active
                        size="large"
                    />
                </Space>
            ) : (
                <React.Fragment>
                    {isOpenModalList && (
                        <ScheduledEmailOpenerListModal
                            scheduledEmailId={recordId}
                            isOpenModalList={isOpenModalList}
                            setVisible={
                                setIsOpenModalList
                            }></ScheduledEmailOpenerListModal>
                    )}
                    {isAttachmentDownloaderModalOpen && (
                        <AttachmentDownloaderModal
                            visible={isAttachmentDownloaderModalOpen}
                            onCancel={() => {
                                setIsAttachmentDownloaderModalOpen(false);
                                setSelectedAttachments([]);
                            }}
                            attachments={selectedAttachments.map(
                                (attachment) => ({
                                    ...attachment,
                                    id: attachment.id
                                        ? attachment.id
                                        : attachment.uid,
                                })
                            )}
                            isOpenCountExtraPeriodAvailable={
                                isOpenCountExtraPeriodAvailable
                            }
                        />
                    )}
                    <GenericTable
                        {...props}
                        columns={columns(onColumnClick, onAttachemntClick)}
                        onMinify={onMinify}
                        onRowClick={onRowClick}
                        loading={loading}
                        withSelection={true}
                        pageSize={
                            displaySetting?.scheduled_mails?.page_size ?? 10
                        }
                    />
                </React.Fragment>
            )}
        </>
    );
};

scheduledMailTable.propTypes = {
    currentPage: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired,
    sortKey: PropTypes.string,
    sortOrder: PropTypes.string,
    onPageChange: PropTypes.func.isRequired,
    onPageSizeChange: PropTypes.func.isRequired,
    onTableChange: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    data: PropTypes.arrayOf(
        PropTypes.shape({
            subject: PropTypes.string,
            status: PropTypes.string.isRequired,
            sender__name: PropTypes.string,
            date_to_send: PropTypes.string,
            password: PropTypes.string,
            attachments: PropTypes.arrayOf(
                PropTypes.shape({
                    uid: PropTypes.string.isRequired,
                    name: PropTypes.string.isRequired,
                    attached_mail_id: PropTypes.string,
                    gcp_link: PropTypes.string,
                    file_type: PropTypes.number,
                    created_time: PropTypes.string,
                    modified_time: PropTypes.string,
                })
            ),
            open_ratio: PropTypes.number,
            send_type: PropTypes.string,
        })
    ),
    isAttachmentDownloadAddonPurchased: PropTypes.bool,
};

scheduledMailTable.defaultProps = {
    loading: PropTypes.bool,
    data: [],
    sortKey: undefined,
    sortOrder: undefined,
    isAttachmentDownloadAddonPurchased: false,
};

export default scheduledMailTable;
