// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DatePickerFormItem-container-w_wIg{width:100%;text-align:left}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/project/ProjectBoardPage/ProjectBoardSearchDrawer/ProjectPeriodFormItem/DatePickerFormItem/DatePickerFormItem.scss"],"names":[],"mappings":"AAAA,oCACI,UAAW,CACX,eAAgB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `DatePickerFormItem-container-w_wIg`
};
export default ___CSS_LOADER_EXPORT___;
