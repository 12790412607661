// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PersonnelAgeFormItem-container-USApp{width:100%;text-align:left}.PersonnelAgeFormItem-userInput-BMyoF{width:100%}.PersonnelAgeFormItem-tooltip-cdq30{margin-left:5px}.PersonnelAgeFormItem-infoIcon-XAubo{align-self:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/personnel/PersonnelBoardPage/PersonnelBoardSearchDrawer/PersonnelAgeFormItem/PersonnelAgeFormItem.scss"],"names":[],"mappings":"AAAA,sCACI,UAAW,CACX,eAAgB,CACnB,sCAGG,UAAW,CACd,oCAGG,eAAgB,CACnB,qCAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PersonnelAgeFormItem-container-USApp`,
	"userInput": `PersonnelAgeFormItem-userInput-BMyoF`,
	"tooltip": `PersonnelAgeFormItem-tooltip-cdq30`,
	"infoIcon": `PersonnelAgeFormItem-infoIcon-XAubo`
};
export default ___CSS_LOADER_EXPORT___;
