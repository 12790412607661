// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContactBulkEditPage-pageHeader-PTvPd{padding:16px 0 0 0}.ContactBulkEditPage-infoAlert-M9cmd{text-align:left;margin:15px 0}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/ContactBulkEditPage/ContactBulkEditPage.scss"],"names":[],"mappings":"AAAA,sCACI,kBAAmB,CACtB,qCAGG,eAAgB,CAChB,aAAc","sourcesContent":[".pageHeader {\n    padding: 16px 0 0 0;\n}\n\n.infoAlert {\n    text-align: left;\n    margin: 15px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageHeader": `ContactBulkEditPage-pageHeader-PTvPd`,
	"infoAlert": `ContactBulkEditPage-infoAlert-M9cmd`
};
export default ___CSS_LOADER_EXPORT___;
