// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ListCommentsPopover-commentContainer-LZgj7{border-bottom:1px solid #b8b8b8}.ListCommentsPopover-headerToolTip-hS0jw{border-bottom:1px solid #ffffff;color:#ffffff;padding:5px 10px}.ListCommentsPopover-commentContent-QfpWV{padding:5px 10px;color:#ffffff}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Common/ListCommentsPopover/ListCommentsPopover.scss"],"names":[],"mappings":"AAAA,4CACE,+BAAgC,CACjC,yCAEC,+BAAgC,CAChC,aAAc,CACd,gBAAiB,CAClB,0CAGC,gBAAiB,CACjB,aAAc","sourcesContent":[".commentContainer {\n  border-bottom: 1px solid #b8b8b8;\n}\n.headerToolTip {\n  border-bottom: 1px solid #ffffff;\n  color: #ffffff;\n  padding: 5px 10px;\n}\n\n.commentContent {\n  padding: 5px 10px;\n  color: #ffffff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"commentContainer": `ListCommentsPopover-commentContainer-LZgj7`,
	"headerToolTip": `ListCommentsPopover-headerToolTip-hS0jw`,
	"commentContent": `ListCommentsPopover-commentContent-QfpWV`
};
export default ___CSS_LOADER_EXPORT___;
