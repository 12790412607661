// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SwapBlockButton-swapButton-NfdI0{margin-right:5px;background-color:#f5f5f5;border-color:#d9d9d9}.SwapBlockButton-swapButton-NfdI0:hover{background-color:#f5f5f5;border-color:#d9d9d9}.SwapBlockButton-listHeader-F_OFO{text-align:center;color:white;background-color:#d9d9d9;margin:0px;padding:0px}.SwapBlockButton-listItem-Ylcrx{cursor:pointer;text-align:left}.SwapBlockButton-listItem-Ylcrx:hover{background-color:#f5f5f5}.SwapBlockButton-popover-LQzsm .ant-popover-inner-content{padding:0}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Common/SwapBlockButton/SwapBlockButton.scss"],"names":[],"mappings":"AAAA,kCACI,gBAAiB,CACjB,wBAAyB,CACzB,oBAAqB,CAHzB,wCAMQ,wBAAyB,CACzB,oBAAqB,CACxB,kCAID,iBAAkB,CAClB,WAAY,CACZ,wBAAyB,CACzB,UAAW,CACX,WAAY,CACf,gCAGG,cAAe,CACf,eAAgB,CAFpB,sCAKQ,wBAAyB,CAC5B,0DAKG,SAAU","sourcesContent":[".swapButton {\n    margin-right: 5px;\n    background-color: #f5f5f5;\n    border-color: #d9d9d9;\n\n    &:hover {\n        background-color: #f5f5f5;\n        border-color: #d9d9d9;\n    }\n}\n\n.listHeader {\n    text-align: center;\n    color: white;\n    background-color: #d9d9d9;\n    margin: 0px;\n    padding: 0px;\n}\n\n.listItem {\n    cursor: pointer;\n    text-align: left;\n\n    &:hover {\n        background-color: #f5f5f5;\n    }\n}\n\n.popover{\n    :global(.ant-popover-inner-content) {\n        padding: 0;\n      }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"swapButton": `SwapBlockButton-swapButton-NfdI0`,
	"listHeader": `SwapBlockButton-listHeader-F_OFO`,
	"listItem": `SwapBlockButton-listItem-Ylcrx`,
	"popover": `SwapBlockButton-popover-LQzsm`
};
export default ___CSS_LOADER_EXPORT___;
