"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var antd_1 = require("antd");
var react_1 = __importStar(require("react"));
var constants_1 = require("~/utils/constants");
var Option = antd_1.Select.Option;
var _options = constants_1.BOARD_PRIORITY_OPTIONS.filter(function (item) { return !item.value.includes("not:"); });
var BoardPrioritySelectFormItem = function (_a) {
    var initialValue = _a.initialValue, props = __rest(_a, ["initialValue"]);
    var _b = (0, react_1.useState)(""), colorSelected = _b[0], setColorSelected = _b[1];
    var updatePriorityColor = function (value) {
        switch (value) {
            case "urgent":
                setColorSelected("#EB5A46");
                break;
            case "important":
                setColorSelected("#FFAB4A");
                break;
            case "high":
                setColorSelected("#F2D600");
                break;
            case "medium":
                setColorSelected("#61BD4F");
                break;
            default:
                setColorSelected("#0079BF");
                break;
        }
    };
    var onChangeSelect = function (value) {
        updatePriorityColor(value);
    };
    (0, react_1.useEffect)(function () {
        var _a;
        if (initialValue) {
            var value = (_a = initialValue.value) !== null && _a !== void 0 ? _a : initialValue;
            updatePriorityColor(value);
        }
    }, [initialValue]);
    return (react_1.default.createElement(antd_1.Form.Item, __assign({ name: "priority" }, props, { initialValue: initialValue, label: "\u512A\u5148\u5EA6", colon: false }),
        react_1.default.createElement(antd_1.Select, { onChange: onChangeSelect, "data-testid": "board-priority-select", style: {
                color: colorSelected,
            }, allowClear: true, placeholder: "\u30AF\u30EA\u30C3\u30AF\u3057\u3066\u9078\u629E", getPopupContainer: function (trigger) { return trigger.parentNode; } }, _options.map(function (priority, _) { return (react_1.default.createElement(Option, { key: priority.value, value: priority.value, style: {
                background: priority.color,
                color: "white",
                width: 120,
                margin: "5px auto",
                textAlign: "center",
            } }, priority.title)); }))));
};
exports.default = BoardPrioritySelectFormItem;
