"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TYPE_CARD_BOARD = exports.TYPE_SELECT = void 0;
var TYPE_SELECT;
(function (TYPE_SELECT) {
    TYPE_SELECT["SKILL"] = "SKILL";
    TYPE_SELECT["TAG"] = "TAG";
})(TYPE_SELECT = exports.TYPE_SELECT || (exports.TYPE_SELECT = {}));
var TYPE_CARD_BOARD;
(function (TYPE_CARD_BOARD) {
    TYPE_CARD_BOARD["PROJECT"] = "project";
    TYPE_CARD_BOARD["PERSONNEL"] = "personnel";
    TYPE_CARD_BOARD["RECRUIT"] = "recruit";
    TYPE_CARD_BOARD["SALES"] = "sales";
    TYPE_CARD_BOARD["BACK_OFFICE"] = "back_office";
})(TYPE_CARD_BOARD = exports.TYPE_CARD_BOARD || (exports.TYPE_CARD_BOARD = {}));
