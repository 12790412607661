// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SalesCommentUserFormItem-container-nRMRD{width:100%;text-align:left}.SalesCommentUserFormItem-userInput-A4mLY{width:100%}.SalesCommentUserFormItem-tooltip-ftBiX{margin-left:5px}.SalesCommentUserFormItem-infoIcon-u9yiL{align-self:center}.SalesCommentUserFormItem-marginBottom-Ni8Op{margin-bottom:1%}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/sales/SalesBoardPage/SalesBoardSearchDrawer/SalesCommentUserFormItem/SalesCommentUserFormItem.scss"],"names":[],"mappings":"AAAA,0CACE,UAAW,CACX,eAAgB,CACjB,0CAGC,UAAW,CACZ,wCAGC,eAAgB,CACjB,yCAGC,iBAAkB,CACnB,6CAGC,gBAAiB","sourcesContent":[".container {\n  width: 100%;\n  text-align: left;\n}\n\n.userInput {\n  width: 100%;\n}\n\n.tooltip {\n  margin-left: 5px;\n}\n\n.infoIcon {\n  align-self: center;\n}\n\n.marginBottom {\n  margin-bottom: 1%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `SalesCommentUserFormItem-container-nRMRD`,
	"userInput": `SalesCommentUserFormItem-userInput-A4mLY`,
	"tooltip": `SalesCommentUserFormItem-tooltip-ftBiX`,
	"infoIcon": `SalesCommentUserFormItem-infoIcon-u9yiL`,
	"marginBottom": `SalesCommentUserFormItem-marginBottom-Ni8Op`
};
export default ___CSS_LOADER_EXPORT___;
