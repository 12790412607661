// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BackOfficeCreatedUserFormItem-container-U3DAY{width:100%;text-align:left}.BackOfficeCreatedUserFormItem-userInput-GiQ1E{width:100%}.BackOfficeCreatedUserFormItem-tooltip-HFLKt{margin-left:5px}.BackOfficeCreatedUserFormItem-infoIcon-cvT39{align-self:center}.BackOfficeCreatedUserFormItem-marginBottom-QSbSM{margin-bottom:1%}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/backOffice/BackOfficeBoardPage/BackOfficeBoardSearchDrawer/BackOfficeCreatedUserFormItem/BackOfficeCreatedUserFormItem.scss"],"names":[],"mappings":"AAAA,+CACI,UAAW,CACX,eAAgB,CACnB,+CAGG,UAAW,CACd,6CAGG,eAAgB,CACnB,8CAGG,iBAAkB,CACrB,kDAGG,gBAAiB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n\n.marginBottom {\n    margin-bottom: 1%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `BackOfficeCreatedUserFormItem-container-U3DAY`,
	"userInput": `BackOfficeCreatedUserFormItem-userInput-GiQ1E`,
	"tooltip": `BackOfficeCreatedUserFormItem-tooltip-HFLKt`,
	"infoIcon": `BackOfficeCreatedUserFormItem-infoIcon-cvT39`,
	"marginBottom": `BackOfficeCreatedUserFormItem-marginBottom-QSbSM`
};
export default ___CSS_LOADER_EXPORT___;
