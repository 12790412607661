import {
    UPDATING,
    UPDATED,
    VALIDATION_ERROR,
    ERROR,
    LOADING,
    LOADED,
    DELETED,
    CLEAR,
    CANCELED,
    LOGOUT,
    TAG_CREATED,
    DISPLAY_SETTING_LOADED,
    DISPLAY_SETTING_UPDATED,
    REGISTED,
    RESET_ERROR_MESSAGE,
    CONTACT_FORM,
} from "../../actions/actionTypes";
import {SuccessMessages} from "~/utils/constants";

const commentActionSuffix = "__COMMENTS";

export const defaultInitialState = {
    canNotDelete: false,
    loading: false,
    updated: false,
    deleted: false,
    message: "",
    errorMessage: "",
    data: {},
    fieldErrors: {},
    displaySetting: undefined,
    isDisplaySettingLoading: true,
    initialData: {}
};

export const defaultInitialStateOfUndeletableResource = Object.assign(
    {},
    defaultInitialState,
    { canNotDelete: true }
);

const createEditPageReducer = (pageId, InitialState = defaultInitialState) => {
    const Reducer = (state = InitialState, action) => {
        switch (action.type) {
            case pageId + CONTACT_FORM:
                return {
                    ...state,
                  loading: false,
                  message: "",
                  errorMessage: "",
                  initialData: action.initialData
              };
            case pageId + LOADING:
                return Object.assign({}, state, {
                    loading: true,
                    message: "",
                    errorMessage: "",
                });
            case pageId + CANCELED:
                return Object.assign({}, state, { loading: false });
            case pageId + LOADED:
                return Object.assign({}, state, {
                    loading: false,
                    message: "",
                    errorMessage: "",
                    fieldErrors: {},
                    data: action.payload.data,
                });
            case pageId + UPDATING:
                return Object.assign({}, state, {
                    loading: true,
                    message: "",
                    errorMessage: "",
                });
            case pageId + UPDATED:
                return Object.assign({}, state, {
                    loading: false,
                    updated: true,
                    message: SuccessMessages.generic.update,
                    errorMessage: "",
                    fieldErrors: {},
                    data: action.payload.data,
                });
            case pageId + REGISTED:
                return Object.assign({}, state, {
                    loading: false,
                    updated: true,
                    message: SuccessMessages.user.register,
                    errorMessage: "",
                    fieldErrors: {},
                    data: action.payload.data,
                });
            case pageId + DELETED:
                const newState = {
                    ...state,
                    loading: false,
                    message: action.payload.message,
                    deleted: true,
                }
                return newState;
            case pageId + ERROR:
                return Object.assign({}, state, {
                    loading: false,
                    message: "",
                    errorMessage: action.payload.error.detail,
                });
            case pageId + VALIDATION_ERROR:
                return Object.assign({}, state, {
                    loading: false,
                    message: "",
                    errorMessage: action.payload.error.detail,
                    fieldErrors: action.payload.error.field_errors,
                });
            case pageId + CLEAR:
                return Object.assign({}, state, InitialState);
            case LOGOUT:
                return Object.assign({}, state, defaultInitialState);
            case pageId + TAG_CREATED:
                return Object.assign({}, state, {
                    tagResisterResult: action.payload.data,
                });
            case pageId + DISPLAY_SETTING_LOADED:
                return Object.assign({}, state, {
                    requireRefresh: false,
                    displaySetting: action.payload.data["content_hash"],
                    isDisplaySettingLoading: false,
                });
            case pageId + DISPLAY_SETTING_UPDATED:
                return {
                    ...state,
                    requireRefresh: false,
                    displaySetting: action.payload.data["content_hash"],
                    isDisplaySettingLoading: false,
                };
            case pageId + commentActionSuffix + VALIDATION_ERROR:
                return Object.assign({}, state, {
                    loading: false,
                    message: "",
                    errorMessage: action.payload.error.detail,
                });
            case pageId + RESET_ERROR_MESSAGE:
                return {
                    ...state,
                    errorMessage: "",
                };
            default:
                return state;
        }
    };
    return Reducer;
};

export default createEditPageReducer;
