// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProjGDetailFormItem-container-BQ041{width:100%;text-align:left}.ProjGDetailFormItem-userInput-Wfxkc{width:100%}.ProjGDetailFormItem-tooltip-HtN83{margin-left:5px}.ProjGDetailFormItem-infoIcon-xkguR{align-self:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/project/ProjectGanttPage/ProjectGanttSearchDrawer/ProjGDetailFormItem/ProjGDetailFormItem.scss"],"names":[],"mappings":"AAAA,qCACI,UAAW,CACX,eAAgB,CACnB,qCAGG,UAAW,CACd,mCAGG,eAAgB,CACnB,oCAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ProjGDetailFormItem-container-BQ041`,
	"userInput": `ProjGDetailFormItem-userInput-Wfxkc`,
	"tooltip": `ProjGDetailFormItem-tooltip-HtN83`,
	"infoIcon": `ProjGDetailFormItem-infoIcon-xkguR`
};
export default ___CSS_LOADER_EXPORT___;
