// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProjectContractPersonnelInitialFormItem-container-BSplo{width:100%;text-align:left}.ProjectContractPersonnelInitialFormItem-userInput-_qS4j{width:50%}.ProjectContractPersonnelInitialFormItem-tooltip-gChw3{margin-left:5px}.ProjectContractPersonnelInitialFormItem-infoIcon-p_lf0{align-self:center}.ProjectContractPersonnelInitialFormItem-select_option-kfHkJ{text-align:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/project/ProjectBoardPage/ProjectBoardSearchDrawer/ProjectContractPersonnelInitialFormItem/ProjectContractPersonnelInitialFormItem.scss"],"names":[],"mappings":"AAAA,yDACI,UAAW,CACX,eAAgB,CACnB,yDAGG,SAAU,CACb,uDAGG,eAAgB,CACnB,wDAGG,iBAAkB,CACrB,6DAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 50%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n\n.select_option {\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ProjectContractPersonnelInitialFormItem-container-BSplo`,
	"userInput": `ProjectContractPersonnelInitialFormItem-userInput-_qS4j`,
	"tooltip": `ProjectContractPersonnelInitialFormItem-tooltip-gChw3`,
	"infoIcon": `ProjectContractPersonnelInitialFormItem-infoIcon-p_lf0`,
	"select_option": `ProjectContractPersonnelInitialFormItem-select_option-kfHkJ`
};
export default ___CSS_LOADER_EXPORT___;
