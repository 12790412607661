import {
  CLEAR_SEARCH_FORM,
  SYNC_FORM_STATE_TO_PAGE,
  CHANGE_SEARCH_TEMPLATE,
  CHANGED_SEARCH_TEMPLATE,
} from './actionTypes';

export const clearSearchForm = (pageId, initialSearchConditions = {}) => (dispatch) => {
  dispatch({
    type: pageId + CLEAR_SEARCH_FORM,
    payload: { initialSearchConditions },
  });
};

export const changeTemplate = (pageId, initialSearchTemplate = {}) => (dispatch) => {
  dispatch({
    type: pageId + CHANGE_SEARCH_TEMPLATE,
    payload: { initialSearchTemplate },
  });
};

export const finishChangingTemplate = (pageId) => (dispatch) => {
  dispatch({ type: pageId + CHANGED_SEARCH_TEMPLATE });
};

export const syncToPageState = (pageId, formValues) => (dispatch) => {
  dispatch({
    type: pageId + SYNC_FORM_STATE_TO_PAGE,
    payload: { formValues },
  });
};
