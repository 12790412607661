import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
    Button,
    Card,
    Checkbox,
    Col,
    Form,
    Row,
    Select,
    Radio,
    Tooltip,
    Modal,
    Input,
    Switch,
    Typography,
} from "antd";
import {
    QuestionCircleFilled,
    CheckOutlined,
    CloseOutlined,
    HeartTwoTone,
} from "@ant-design/icons";
import { svgColorIconLight } from "~/utils/constants";
import HeartBrokenIcon from "~/components/icons/HeartBrokenIcon";
import { connect } from "react-redux";
import BaseForm from "~/components/Forms/base/BaseForm";
import Path from "~/components/Routes/Paths";
import { finalized } from "~/components/Forms/helpers";
import { createTagAction } from "~/actions/data";
import validateJapaneseMessages from "~/components/Forms/validateMessages";
import { Endpoint } from "~/domain/api";
import { ErrorMessages, Links, iconCustomColor } from "~/utils/constants";
import OrganizationTagFormItem from "./OrganizationTagFormItem";
import ContactTagFormItem from "./ContactTagFormItem";
import UserAjaxSelect from "~/components/Common/UserAjaxSelect/UserAjaxSelect";
import styles from "./ContactEmailPreferenceSearchForm.scss";
import OpenRankFormItem from "./OpenRankFormItem/OpenRankFormItem";

const { Title } = Typography;

const topLevelLabelColSpan = 5;
const topLevelFormLayout = {
    labelCol: {
        span: topLevelLabelColSpan,
    },
    wrapperCol: {
        span: 24 - topLevelLabelColSpan,
    },
};

const secondLevelLabelColSpan = topLevelLabelColSpan + 2;
const secondLevelFormLayout = {
    labelCol: {
        span: secondLevelLabelColSpan,
    },
    wrapperCol: {
        span: 24 - secondLevelLabelColSpan,
    },
};

const thirdLevelLabelColSpan = secondLevelLabelColSpan + 2;

const cardStyle = {
    headStyle: { backgroundColor: "#FFFFCC" },
    bodyStyle: { backgroundColor: "#ffffff" },
};

const maxTagsForSearch = 5;

const toolTipTextJDL =
    "他社案件の場合、他社案件を不可とする宛先が検索で除外されます。";
const toolTipTextPDL = (
    <div>
        <div>・自社要員には、自社と契約中の個人事業主を含みます。</div>
        <div>
            ・他社要員の場合、他社要員を不可とする宛先が検索で除外されます。
        </div>
    </div>
);

const emptyTemplateData = {
    job_koyou: "",
    personnel_syouryu: undefined,
    personnelskill_dev_beginner: false,
    personnelskill_dev_hosyu: false,
    personnelskill_dev_kihon: false,
    personnelskill_dev_seizou: false,
    personnelskill_dev_syousai: false,
    personnelskill_dev_test: false,
    personnelskill_dev_youken: false,
    personnelskill_infra_beginner: false,
    personnelskill_infra_hosyu: false,
    personnelskill_infra_kanshi: false,
    personnelskill_infra_kihon: false,
    personnelskill_infra_kouchiku: false,
    personnelskill_infra_syousai: false,
    personnelskill_infra_test: false,
    personnelskill_infra_youken: false,
    personneltype_dev: false,
    personneltype_dev_designer: false,
    personneltype_dev_front: false,
    personneltype_dev_other: false,
    personneltype_dev_pm: false,
    personneltype_dev_server: false,
    personneltype_infra: false,
    personneltype_infra_database: false,
    personneltype_infra_network: false,
    personneltype_infra_other: false,
    personneltype_infra_security: false,
    personneltype_infra_server: false,
    personneltype_infra_sys: false,
    personneltype_other: false,
    personneltype_other_eigyo: false,
    personneltype_other_kichi: false,
    personneltype_other_other: false,
    personneltype_other_support: false,
    personneltype: "",
    searchtype: "",
    wants_location_chubu_japan: false,
    wants_location_chugoku_japan: false,
    wants_location_hokkaido_japan: false,
    wants_location_kansai_japan: false,
    wants_location_kanto_japan: false,
    wants_location_kyushu_japan: false,
    wants_location_other_japan: false,
    wants_location_shikoku_japan: false,
    wants_location_touhoku_japan: false,
    wants_location_toukai_japan: false,
    job_syouryu: undefined,
    jobskill_dev_beginner: false,
    jobskill_dev_hosyu: false,
    jobskill_dev_kihon: false,
    jobskill_dev_seizou: false,
    jobskill_dev_syousai: false,
    jobskill_dev_test: false,
    jobskill_dev_youken: false,
    jobtype: "",
    jobtype_dev: false,
    jobtype_dev_designer: false,
    jobtype_dev_front: false,
    jobtype_dev_server: false,
    jobtype_dev_pm: false,
    jobtype_dev_other: false,
    jobtype_infra: false,
    jobtype_infra_server: false,
    jobtype_infra_network: false,
    jobtype_infra_security: false,
    jobtype_infra_database: false,
    jobtype_infra_sys: false,
    jobtype_infra_other: false,
    jobtype_other: false,
    jobtype_other_eigyo: false,
    jobtype_other_kichi: false,
    jobtype_other_support: false,
    jobtype_other_other: false,
    jobskill_infra_youken: false,
    jobskill_infra_kihon: false,
    jobskill_infra_syousai: false,
    jobskill_infra_kouchiku: false,
    jobskill_infra_test: false,
    jobskill_infra_hosyu: false,
    jobskill_infra_kanshi: false,
    jobskill_infra_beginner: false,
    category_inequality: "eq",
    contact__organization__tags__suffix: "and",
    contact__tags__suffix: "and",
};

const EMPTY_STATE = {
    registerVisible: false,
    tagValue: undefined,
    selectedSearchType: undefined,
    selectedJobType: undefined,
    personnelTypeDevVisible: false,
    personnelTypeInfraVisible: false,
    personnelTypeOtherVisible: false,
    jobNotSelected: false,
    personnelNotSelected: false,
    jobTypeNotSelected: false,
    jobSkillNotSelected: false,
    personnelTypeDevNotSelected: false,
    personnelTypeInfraNotSelected: false,
    personnelTypeOtherNotSelected: false,
    personnelSkillDevNotSelected: false,
    personnelSkillInfraNotSelected: false,
    jobSyouryuNotSelected: false,
    personnelSyouryuNotSelected: false,
    koyouNotSelected: false,
    locationNotSelected: false,
    categoryVisible: false,
    organizationTagVisible: false,
    contactTagVisible: false,
    organizationStatusVisible: false,
    countryVisible: false,
    staffVisible: false,
    categoryNotSelected: false,
    organizationTagNotSelected: false,
    contactTagNotSelected: false,
    organizationStatusNotSelected: false,
    staffNotSelected: false,
    contactStatusVisible: false,
    contactStatusNotSelected: false,
    countryStatusNotSelected: false,
    countryStatusVisible: false,
    contractStatusVisible: false,
    documentVisible: false,
    documentNotSelected: false,
    openRankVisible: false,
    openRankNotSelected: false,
};

class ContactEmailPreferenceSearchForm extends BaseForm {
    constructor(props) {
        super(props);
        this.initialViewRendered = false;
    }

    state = EMPTY_STATE;
    componentDidMount() {
        this.props.setRef(this.baseform.current);
    }

    showRegister = () => {
        this.setState({ register_visible: true });
    };

    registerOnOk = () => {
        const { dispatch, token, pageId } = this.props;
        const { tagValue } = this.state;
        if (tagValue) {
            dispatch(
                createTagAction(
                    pageId,
                    token,
                    `${Endpoint.getBaseUrl()}/${Endpoint.tags}`,
                    { value: tagValue }
                )
            );
            this.setState({ register_visible: false });
        }
    };

    registerOnCancel = () => {
        this.setState({ register_visible: false });
    };

    setNewTag = (e) => {
        this.setState({ tagValue: e.target.value });
    };

    onChangeSearchType = (e) => {
        this.resetSearchType();
        this.resetJobType();
        this.resetJobSyouryu();
        this.resetPersonnelTypeDev();
        this.resetPersonnelTypeInfra();
        this.resetPersonnelTypeOther();
        this.resetPersonnelSyouryu();
        this.resetLocation();
        this.setState({ selectedSearchType: e.target.value });
    };

    onChangeJobType = (e) => {
        this.resetJobType();
        this.setState({ selectedJobType: e.target.value });
    };

    onChangePersonnelTypeDev = (e) => {
        const { personnelTypeDevVisible } = this.state;
        this.resetPersonnelTypeDev();
        this.setState({ personnelTypeDevVisible: !personnelTypeDevVisible });
    };

    onChangePersonnelTypeInfra = (e) => {
        const { personnelTypeInfraVisible } = this.state;
        this.resetPersonnelTypeInfra();
        this.setState({
            personnelTypeInfraVisible: !personnelTypeInfraVisible,
        });
    };

    onChangePersonnelTypeOther = (e) => {
        const { personnelTypeOtherVisible } = this.state;
        this.resetPersonnelTypeOther();
        this.setState({
            personnelTypeOtherVisible: !personnelTypeOtherVisible,
        });
    };

    resetAllState = () => {
        this.setState(EMPTY_STATE);
    };

    resetSearchType = () => {
        const { setFieldsValue } = this.baseform.current;
        setFieldsValue({
            personneltype: undefined,
            personneltype_dev: undefined,
            personneltype_infra: undefined,
            personneltype_other: undefined,
        });
        this.setState({ personnelTypeDevVisible: false });
        this.setState({ personnelTypeInfraVisible: false });
        this.setState({ personnelTypeOtherVisible: false });
    };

    resetJobType = () => {
        const { setFieldsValue } = this.baseform.current;
        setFieldsValue({
            jobtype_dev: undefined,
            jobskill_dev_youken: undefined,
            jobskill_dev_kihon: undefined,
            jobskill_dev_syousai: undefined,
            jobskill_dev_seizou: undefined,
            jobskill_dev_test: undefined,
            jobskill_dev_hosyu: undefined,
            jobskill_dev_beginner: undefined,
            jobtype_infra: undefined,
            jobskill_infra_youken: undefined,
            jobskill_infra_kihon: undefined,
            jobskill_infra_syousai: undefined,
            jobskill_infra_kouchiku: undefined,
            jobskill_infra_test: undefined,
            jobskill_infra_hosyu: undefined,
            jobskill_infra_kanshi: undefined,
            jobskill_infra_beginner: undefined,
            jobtype_other: undefined,
        });
    };

    resetJobSyouryu = () => {
        const { setFieldsValue } = this.baseform.current;
        setFieldsValue({
            job_syouryu: undefined,
        });
    };

    resetPersonnelTypeDev = () => {
        const { setFieldsValue } = this.baseform.current;
        setFieldsValue({
            personneltype_dev_designer: undefined,
            personneltype_dev_front: undefined,
            personneltype_dev_server: undefined,
            personneltype_dev_pm: undefined,
            personneltype_dev_other: undefined,
            personnelskill_dev_youken: undefined,
            personnelskill_dev_kihon: undefined,
            personnelskill_dev_syousai: undefined,
            personnelskill_dev_seizou: undefined,
            personnelskill_dev_test: undefined,
            personnelskill_dev_hosyu: undefined,
            personnelskill_dev_beginner: undefined,
        });
    };

    resetPersonnelTypeInfra = () => {
        const { setFieldsValue } = this.baseform.current;
        setFieldsValue({
            personneltype_infra_server: undefined,
            personneltype_infra_network: undefined,
            personneltype_infra_database: undefined,
            personneltype_infra_security: undefined,
            personneltype_infra_sys: undefined,
            personneltype_infra_other: undefined,
            personnelskill_infra_youken: undefined,
            personnelskill_infra_kihon: undefined,
            personnelskill_infra_syousai: undefined,
            personnelskill_infra_kouchiku: undefined,
            personnelskill_infra_test: undefined,
            personnelskill_infra_hosyu: undefined,
            personnelskill_infra_kanshi: undefined,
            personnelskill_infra_beginner: undefined,
        });
    };

    resetPersonnelTypeOther = () => {
        const { setFieldsValue } = this.baseform.current;
        setFieldsValue({
            personneltype_other_eigyo: undefined,
            personneltype_other_kichi: undefined,
            personneltype_other_support: undefined,
            personneltype_other_other: undefined,
        });
    };

    resetPersonnelSyouryu = () => {
        const { setFieldsValue } = this.baseform.current;
        setFieldsValue({
            job_koyou: undefined,
            personnel_syouryu: undefined,
        });
    };

    resetLocation = () => {
        const { setFieldsValue } = this.baseform.current;

        setFieldsValue({
            wants_location_hokkaido_japan: undefined,
            wants_location_touhoku_japan: undefined,
            wants_location_kanto_japan: undefined,
            wants_location_kansai_japan: undefined,
            wants_location_chubu_japan: undefined,
            wants_location_kyushu_japan: undefined,
            wants_location_other_japan: undefined,
            wants_location_chugoku_japan: undefined,
            wants_location_shikoku_japan: undefined,
            wants_location_toukai_japan: undefined,
        });
    };

    handleSubmitWithCheck = (event) => {
        if (typeof event.preventDefault === "function") {
            event.preventDefault();
        }
        const jobcheckresult = this.checkJobSelected();
        const personnelcheckresult = this.checkPersonnelSelected();
        const jobtypecheckresult = this.checkJobTypeSelected();
        const jobskillcheckresult = this.checkJobSkillSelected();
        const personneltypedevcheckresult = this.checkPersonnelTypeDevSelected();
        const personneltypeinfracheckresult =
            this.checkPersonnelTypeInfraSelected();
        const personneltypeothercheckresult =
            this.checkPersonnelTypeOtherSelected();
        const personnelskilldevcheckresult =
            this.checkPersonnelSkillDevSelected();
        const personnelskillinfracheckresult =
            this.checkPersonnelSkillInfraSelected();
        const jobsyouryucheckresult = this.checkJobSyouryuSelected();
        const personnelsyouryucheckresult = this.checkPersonnelSyouryuSelected();
        const koyoucheckresult = this.checkKoyouSelected();
        const locationcheckresult = this.checkLocationSelected();
        const categorycheckresult = this.checkCategorySelected();
        const organizationTagCheckResult = this.checkOrganizationTagSelected();
        const contactTagCheckResult = this.checkContactTagSelected();
        const organizationstatuscheckresult =
            this.checkOrganizationStatusSelected();
        const staffcheckresult = this.checkStaffSelected();
        const contactstatuscheckresult = this.checkContactStatusSelected();
        const countrystatuscheckresult = this.checkCountryStatusSelected();
        const documentcheckresult = this.checkDocumentSelected();
        const openRankSelected = this.checkOpenRankSelected();

        this.baseform.current.validateFields().then((values) => {
            if (
                jobcheckresult &&
                personnelcheckresult &&
                jobtypecheckresult &&
                jobskillcheckresult &&
                jobsyouryucheckresult &&
                personneltypedevcheckresult &&
                personneltypeinfracheckresult &&
                personneltypeothercheckresult &&
                personnelskilldevcheckresult &&
                personnelskillinfracheckresult &&
                personnelsyouryucheckresult &&
                koyoucheckresult &&
                locationcheckresult &&
                categorycheckresult &&
                organizationTagCheckResult &&
                contactTagCheckResult &&
                organizationstatuscheckresult &&
                staffcheckresult &&
                contactstatuscheckresult &&
                countrystatuscheckresult &&
                documentcheckresult &&
                openRankSelected
            ) {
                this.submitHandler(values);
            }
        });
    };

    checkJobSelected = () => {
        const { getFieldValue } = this.baseform.current;
        if (getFieldValue("searchtype") === "job" && !getFieldValue("jobtype")) {
            this.setState({ jobNotSelected: true });
            return false;
        } else {
            this.setState({ jobNotSelected: false });
            return true;
        }
    };

    checkJobTypeSelected = () => {
        const { getFieldValue } = this.baseform.current;
        if (
            getFieldValue("searchtype") === "job" &&
            !getFieldValue("jobtype_dev") &&
            !getFieldValue("jobtype_infra") &&
            !getFieldValue("jobtype_other")
        ) {
            this.setState({ jobTypeNotSelected: true });
            return false;
        } else {
            this.setState({ jobTypeNotSelected: false });
            return true;
        }
    };

    checkJobSkillSelected = () => {
        const { getFieldValue } = this.baseform.current;
        if (
            getFieldValue("searchtype") === "job" &&
            getFieldValue("jobtype") !== "other" &&
            !getFieldValue("jobskill_dev_youken") &&
            !getFieldValue("jobskill_dev_kihon") &&
            !getFieldValue("jobskill_dev_syousai") &&
            !getFieldValue("jobskill_dev_seizou") &&
            !getFieldValue("jobskill_dev_test") &&
            !getFieldValue("jobskill_dev_hosyu") &&
            !getFieldValue("jobskill_dev_beginner") &&
            !getFieldValue("jobskill_infra_youken") &&
            !getFieldValue("jobskill_infra_kihon") &&
            !getFieldValue("jobskill_infra_syousai") &&
            !getFieldValue("jobskill_infra_kouchiku") &&
            !getFieldValue("jobskill_infra_test") &&
            !getFieldValue("jobskill_infra_hosyu") &&
            !getFieldValue("jobskill_infra_kanshi") &&
            !getFieldValue("jobskill_infra_beginner")
        ) {
            this.setState({ jobSkillNotSelected: true });
            return false;
        } else {
            this.setState({ jobSkillNotSelected: false });

            return true;
        }
    };

    checkPersonnelSelected = () => {
        const { getFieldValue } = this.baseform.current;
        if (
            getFieldValue("searchtype") === "personnel" &&
            !getFieldValue("personneltype_dev") &&
            !getFieldValue("personneltype_infra") &&
            !getFieldValue("personneltype_other")
        ) {
            this.setState({ personnelNotSelected: true });
            return false;
        } else {
            this.setState({ personnelNotSelected: false });
            return true;
        }
    };

    checkPersonnelTypeDevSelected = () => {
        const { getFieldValue } = this.baseform.current;
        if (
            getFieldValue("searchtype") === "personnel" &&
            getFieldValue("personneltype_dev") &&
            !getFieldValue("personneltype_dev_designer") &&
            !getFieldValue("personneltype_dev_front") &&
            !getFieldValue("personneltype_dev_server") &&
            !getFieldValue("personneltype_dev_pm") &&
            !getFieldValue("personneltype_dev_other")
        ) {
            this.setState({ personnelTypeDevNotSelected: true });
            return false;
        } else {
            this.setState({ personnelTypeDevNotSelected: false });
            return true;
        }
    };

    checkPersonnelTypeInfraSelected = () => {
        const { getFieldValue } = this.baseform.current;
        if (
            getFieldValue("searchtype") === "personnel" &&
            getFieldValue("personneltype_infra") &&
            !getFieldValue("personneltype_infra_server") &&
            !getFieldValue("personneltype_infra_network") &&
            !getFieldValue("personneltype_infra_database") &&
            !getFieldValue("personneltype_infra_security") &&
            !getFieldValue("personneltype_infra_sys") &&
            !getFieldValue("personneltype_infra_other")
        ) {
            this.setState({ personnelTypeInfraNotSelected: true });
            return false;
        } else {
            this.setState({ personnelTypeInfraNotSelected: false });
            return true;
        }
    };

    checkPersonnelTypeOtherSelected = () => {
        const { getFieldValue } = this.baseform.current;
        if (
            getFieldValue("searchtype") === "personnel" &&
            getFieldValue("personneltype_other") &&
            !getFieldValue("personneltype_other_eigyo") &&
            !getFieldValue("personneltype_other_kichi") &&
            !getFieldValue("personneltype_other_support") &&
            !getFieldValue("personneltype_other_other")
        ) {
            this.setState({ personnelTypeOtherNotSelected: true });
            return false;
        } else {
            this.setState({ personnelTypeOtherNotSelected: false });
            return true;
        }
    };

    checkPersonnelSkillDevSelected = () => {
        const { getFieldValue } = this.baseform.current;
        if (
            getFieldValue("searchtype") === "personnel" &&
            getFieldValue("personneltype_dev") &&
            !getFieldValue("personnelskill_dev_youken") &&
            !getFieldValue("personnelskill_dev_kihon") &&
            !getFieldValue("personnelskill_dev_syousai") &&
            !getFieldValue("personnelskill_dev_seizou") &&
            !getFieldValue("personnelskill_dev_test") &&
            !getFieldValue("personnelskill_dev_hosyu") &&
            !getFieldValue("personnelskill_dev_beginner")
        ) {
            this.setState({ personnelSkillDevNotSelected: true });
            return false;
        } else {
            this.setState({ personnelSkillDevNotSelected: false });
            return true;
        }
    };

    checkPersonnelSkillInfraSelected = () => {
        const { getFieldValue } = this.baseform.current;
        if (
            getFieldValue("searchtype") === "personnel" &&
            getFieldValue("personneltype_infra") &&
            !getFieldValue("personnelskill_infra_youken") &&
            !getFieldValue("personnelskill_infra_kihon") &&
            !getFieldValue("personnelskill_infra_syousai") &&
            !getFieldValue("personnelskill_infra_kouchiku") &&
            !getFieldValue("personnelskill_infra_test") &&
            !getFieldValue("personnelskill_infra_hosyu") &&
            !getFieldValue("personnelskill_infra_kanshi") &&
            !getFieldValue("personnelskill_infra_beginner")
        ) {
            this.setState({ personnelSkillInfraNotSelected: true });
            return false;
        } else {
            this.setState({ personnelSkillInfraNotSelected: false });
            return true;
        }
    };

    checkJobSyouryuSelected = () => {
        const { getFieldValue } = this.baseform.current;
        if (
            getFieldValue("searchtype") === "job" &&
            !getFieldValue("job_syouryu")
        ) {
            this.setState({ jobSyouryuNotSelected: true });
            return false;
        } else {
            this.setState({ jobSyouryuNotSelected: false });
            return true;
        }
    };

    checkPersonnelSyouryuSelected = () => {
        const { getFieldValue } = this.baseform.current;
        if (
            getFieldValue("searchtype") === "personnel" &&
            !getFieldValue("personnel_syouryu")
        ) {
            this.setState({ personnelSyouryuNotSelected: true });
            return false;
        } else {
            this.setState({ personnelSyouryuNotSelected: false });
            return true;
        }
    };

    checkKoyouSelected = () => {
        const { getFieldValue } = this.baseform.current;
        if (
            getFieldValue("searchtype") === "personnel" &&
            !getFieldValue("job_koyou")
        ) {
            this.setState({ koyouNotSelected: true });
            return false;
        } else {
            this.setState({ koyouNotSelected: false });
            return true;
        }
    };

    checkLocationSelected = () => {
        const { getFieldValue } = this.baseform.current;
        if (
            getFieldValue("searchtype") !== "other" &&
            !getFieldValue("wants_location_hokkaido_japan") &&
            !getFieldValue("wants_location_touhoku_japan") &&
            !getFieldValue("wants_location_kanto_japan") &&
            !getFieldValue("wants_location_kansai_japan") &&
            !getFieldValue("wants_location_chubu_japan") &&
            !getFieldValue("wants_location_kyushu_japan") &&
            !getFieldValue("wants_location_other_japan") &&
            !getFieldValue("wants_location_chugoku_japan") &&
            !getFieldValue("wants_location_shikoku_japan") &&
            !getFieldValue("wants_location_toukai_japan")
        ) {
            this.setState({ locationNotSelected: true });
            return false;
        } else {
            this.setState({ locationNotSelected: false });
            return true;
        }
    };

    onChangeCategorySwitch = () => {
        const { categoryVisible } = this.state;
        const { setFieldsValue } = this.baseform.current;
        setFieldsValue({
            contact__category: undefined,
        });
        this.setState({ categoryVisible: !categoryVisible });
    };

    onChangeOrganizationTagSwitch = () => {
        const { organizationTagVisible } = this.state;
        const { setFieldsValue } = this.baseform.current;
        setFieldsValue({
            contact__organization__tags: undefined,
        });
        this.setState({ organizationTagVisible: !organizationTagVisible });
    };

    onChangeContactTagSwitch = () => {
        const { contactTagVisible } = this.state;
        const { setFieldsValue } = this.baseform.current;
        setFieldsValue({
            contact__tags: undefined,
        });
        this.setState({ contactTagVisible: !contactTagVisible });
    };

    onChangeOrganizationStatusSwitch = () => {
        const { organizationStatusVisible } = this.state;
        const { setFieldsValue } = this.baseform.current;
        setFieldsValue({
            contact__organization__category_prospective: undefined,
            contact__organization__category_approached: undefined,
            contact__organization__category_exchanged: undefined,
            contact__organization__category_client: undefined,
        });
        this.setState({
            organizationStatusVisible: !organizationStatusVisible,
        });
    };

    onChangeDocumentSwitch = () => {
        const { documentVisible } = this.state;
        const { setFieldsValue } = this.baseform.current;
        setFieldsValue({
            contact__organization__has_outsourcing_basic_contact: undefined,
            contact__organization__has_nda: undefined,
            contact__organization__has_handring_personal_information: undefined,
        });
        this.setState({
            documentVisible: !documentVisible,
        });
    };

    onChangeCountryStatusSwitch = () => {
        const { countryStatusVisible } = this.state;
        const { setFieldsValue } = this.baseform.current;
        setFieldsValue({
            contact__organization__organization_country_jp: undefined,
            contact__organization__organization_country_kr: undefined,
            contact__organization__organization_country_cn: undefined,
            contact__organization__organization_country_other: undefined,
        });
        this.setState({
            countryStatusVisible: !countryStatusVisible,
        });
    };

    onChangeContractStatusSwitch = () => {
        const { contractStatusVisible } = this.state;
        const { setFieldsValue } = this.baseform.current;
        setFieldsValue({
            contact__organization__contract: undefined,
        });
        this.setState({
            contractStatusVisible: !contractStatusVisible,
        });
    };

    onChangeStaffSwitch = () => {
        const { staffVisible } = this.state;
        const { setFieldsValue } = this.baseform.current;
        setFieldsValue({
            contact__staff: undefined,
        });
        this.setState({ staffVisible: !staffVisible });
    };

    onOpenRankSwitch = () => {
        const { openRankVisible } = this.state;
        this.setState({ openRankVisible: !openRankVisible });
    };

    checkCategorySelected = () => {
        const { getFieldValue } = this.baseform.current;
        const { categoryVisible } = this.state;
        if (categoryVisible && !getFieldValue("contact__category")) {
            this.setState({ categoryNotSelected: true });
            return false;
        } else {
            this.setState({ categoryNotSelected: false });
            return true;
        }
    };

    checkOrganizationTagSelected = () => {
        const { getFieldValue } = this.baseform.current;
        const { organizationTagVisible } = this.state;
        if (
            organizationTagVisible &&
            !getFieldValue("contact__organization__tags")
        ) {
            this.setState({ organizationTagNotSelected: true });
            return false;
        } else {
            this.setState({ organizationTagNotSelected: false });
            return true;
        }
    };

    checkContactTagSelected = () => {
        const { getFieldValue } = this.baseform.current;
        const { contactTagVisible } = this.state;
        if (contactTagVisible && !getFieldValue("contact__tags")) {
            this.setState({ contactTagNotSelected: true });
            return false;
        } else {
            this.setState({ contactTagNotSelected: false });
            return true;
        }
    };

    checkOrganizationStatusSelected = () => {
        const { getFieldValue } = this.baseform.current;
        const { organizationStatusVisible } = this.state;
        if (
            organizationStatusVisible &&
            !getFieldValue("contact__organization__category_prospective") &&
            !getFieldValue("contact__organization__category_approached") &&
            !getFieldValue("contact__organization__category_exchanged") &&
            !getFieldValue("contact__organization__category_client")
        ) {
            this.setState({ organizationStatusNotSelected: true });
            return false;
        } else {
            this.setState({ organizationStatusNotSelected: false });
            return true;
        }
    };

    checkDocumentSelected = () => {
        const { getFieldValue } = this.baseform.current;
        const { documentVisible } = this.state;
        if (
            documentVisible &&
            !getFieldValue(
                "contact__organization__has_outsourcing_basic_contact"
            ) &&
            !getFieldValue("contact__organization__has_nda") &&
            !getFieldValue(
                "contact__organization__has_handring_personal_information"
            )
        ) {
            this.setState({ documentNotSelected: true });
            return false;
        } else {
            this.setState({ documentNotSelected: false });
            return true;
        }
    };

    checkOpenRankSelected = () => {
        const { openRankVisible } = this.state;
        const { getFieldsValue } = this.baseform.current;
        if (!openRankVisible) {
            this.setState({ openRankNotSelected: false});
            return true;
        }
        const allValue = getFieldsValue();
        this.setState({openRankNotSelected: !this.isRankSelected(allValue)});
        return this.isRankSelected(allValue);
    }

    checkContactStatusSelected = () => {
        const { getFieldValue } = this.baseform.current;
        const { contractStatusVisible } = this.state;
        if (
            contractStatusVisible &&
            !getFieldValue("contact__organization__contract")
        ) {
            this.setState({ contactStatusNotSelected: true });
            return false;
        } else {
            this.setState({ contactStatusNotSelected: false });
            return true;
        }
    };

    checkCountryStatusSelected = () => {
        const { getFieldValue } = this.baseform.current;
        const { countryStatusVisible } = this.state;
        if (
            countryStatusVisible &&
            !getFieldValue("contact__organization__organization_country_jp") &&
            !getFieldValue("contact__organization__organization_country_kr") &&
            !getFieldValue("contact__organization__organization_country_cn") &&
            !getFieldValue("contact__organization__organization_country_other")
        ) {
            this.setState({ countryStatusNotSelected: true });
            return false;
        } else {
            this.setState({ countryStatusNotSelected: false });
            return true;
        }
    };

    checkStaffSelected = () => {
        const { getFieldValue } = this.baseform.current;
        const { staffVisible } = this.state;
        if (staffVisible && !getFieldValue("contact__staff")) {
            this.setState({ staffNotSelected: true });
            return false;
        } else {
            this.setState({ staffNotSelected: false });
            return true;
        }
    };

    componentDidUpdate() {
        const { initialData, requireRefresh } = this.props;

        if (
            (!this.initialViewRendered &&
                initialData &&
                initialData.searchtype) ||
            requireRefresh
        ) {
            this.baseform.current.setFieldsValue({
                ...emptyTemplateData,
                ...initialData,
                category_inequality: initialData.category_inequality ?? "eq",
                contact__organization__tags__suffix:
                    initialData.contact__organization__tags__suffix ?? "and",
                contact__tags__suffix:
                    initialData.contact__tags__suffix ?? "and",
            });
            this.setState({
                selectedSearchType: initialData.searchtype,
                selectedJobType: initialData.jobtype,
                personnelTypeDevVisible: initialData.personneltype_dev,
                personnelTypeInfraVisible: initialData.personneltype_infra,
                personnelTypeOtherVisible: initialData.personneltype_other,
                categoryVisible: !!initialData.contact__category,
                organizationTagVisible: !!initialData.contact__organization__tags,
                contactTagVisible: !!initialData.contact__tags,
                organizationStatusVisible:
                    initialData.contact__organization__category_prospective ||
                    initialData.contact__organization__category_approached ||
                    initialData.contact__organization__category_exchanged ||
                    initialData.contact__organization__category_client,
                staffVisible: !!initialData.contact__staff,
                documentVisible:
                    initialData.contact__organization__has_outsourcing_basic_contact ||
                    initialData.contact__organization__has_nda ||
                    initialData.contact__organization__has_handring_personal_information,
                countryStatusVisible:
                    initialData.contact__organization__organization_country_cn ||
                    initialData.contact__organization__organization_country_jp ||
                    initialData.contact__organization__organization_country_kr ||
                    initialData.contact__organization__organization_country_other,
                contractStatusVisible:
                    !!initialData.contact__organization__contract,
                openRankVisible: this.isRankSelected(initialData),
            });
            this.initialViewRendered = true;
        }
    }

    isRankSelected= (data) => {
        return Object.keys(data ?? {})
          .filter(key => key.startsWith("contact__open_rate_"))
          .some(key => data?.[key] === true);
    }

    onSearchReset = () => {
        this.resetSearchType();
        this.resetJobType();
        this.resetJobSyouryu();
        this.resetPersonnelTypeDev();
        this.resetPersonnelTypeInfra();
        this.resetPersonnelTypeOther();
        this.resetPersonnelSyouryu();
        this.resetLocation();
        this.resetAllState();
        this.props.onResetSearchAllValue();
        const {  resetFields } = this.baseform.current;
        resetFields();
    };

    tooltipMessage = (value) => {
        if (value === "Organizations") {
            return (
                <Link
                    to={`${Path.organizations}/register`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    取引先登録／編集
                </Link>
            );
        } else if (value === "Contacts") {
            return (
                <Link
                    to={`${Path.contacts}/register`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    取引先担当者登録／編集
                </Link>
            );
        } else if (value === "Country" || value === "Contract") {
            return (
                <Link
                    to={`${Path.organizations}/register`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    取引先登録／編集
                </Link>
            );
        }
    };

    render() {
        const { initialData } = this.props;

        const {
            selectedSearchType,
            selectedJobType,
            personnelTypeDevVisible,
            personnelTypeInfraVisible,
            personnelTypeOtherVisible,
            categoryVisible,
            organizationStatusVisible,
            organizationTagVisible,
            organizationTagNotSelected,
            contactTagVisible,
            contactTagNotSelected,
            countryStatusVisible,
            contractStatusVisible,
            staffVisible,
            categoryNotSelected,
            organizationStatusNotSelected,
            staffNotSelected,
            contactStatusNotSelected,
            countryStatusNotSelected,
            documentVisible,
            documentNotSelected,
            openRankVisible,
            openRankNotSelected,
        } = this.state;

        const isAreaVisible =
            selectedSearchType !== undefined && selectedSearchType !== "other";

        return (
            <Col span={24}>
                <Row>
                    <Col span={24}>
                        <Card
                            title="配信先抽出"
                            size="small"
                            className={styles.container}
                            headStyle={cardStyle.headStyle}
                            bodyStyle={cardStyle.bodyStyle}
                        >
                            <Form
                                layout="horizontal"
                                onFinish={this.handleSubmitWithCheck}
                                ref={this.baseform}
                                validateMessages={validateJapaneseMessages}
                                style={{ textAlign: "left" }}
                                labelAlign="right"
                            >
                                <Title level={5}>配信情報</Title>
                                <Form.Item
                                    {...topLevelFormLayout}
                                    label={
                                        <span>
                                            配信種別&nbsp;
                                            <Tooltip
                                                title={
                                                    <span>
                                                        配信内容に該当する配信種別を選択します。
                                                        <br />
                                                        {this.tooltipMessage(
                                                                    "Contacts"
                                                                )}
                                                        の配信条件で設定している配信条件をもとに宛先の一覧が表示されます。
                                                    </span>
                                                }
                                            >
                                                <QuestionCircleFilled
                                                    data-testid="searchtype-label-tooltip"
                                                    style={{
                                                        color: iconCustomColor,
                                                    }}
                                                />
                                            </Tooltip>
                                        </span>
                                    }
                                    required={true}
                                    name="searchtype"
                                    initialValue={initialData.searchtype}
                                >
                                    <Radio.Group
                                        onChange={this.onChangeSearchType}
                                    >
                                        <Radio value={"personnel"}>
                                            要員を配信する
                                        </Radio>
                                        <Radio value={"job"}>
                                            案件を配信する
                                        </Radio>
                                        <Radio value={"other"}>
                                            ご案内を配信する
                                        </Radio>
                                    </Radio.Group>
                                </Form.Item>
                                {isAreaVisible && (
                                    <Form.Item
                                        {...topLevelFormLayout}
                                        label={
                                            <span>
                                                配信エリア&nbsp;
                                                <Tooltip
                                                    title={
                                                        <span>
                                                            配信内容に該当する配信エリアを選択します。
                                                            <br />
                                                            例）関東エリアの情報を配信する場合、「関東」を選択
                                                        </span>
                                                    }
                                                >
                                                    <QuestionCircleFilled
                                                        data-testid="sending-area-label-tooltip"
                                                        style={{
                                                            color: iconCustomColor,
                                                        }}
                                                    />
                                                </Tooltip>
                                            </span>
                                        }
                                        className={styles.field}
                                        validateStatus={
                                            this.state.locationNotSelected
                                                ? "error"
                                                : "success"
                                        }
                                        help={
                                            this.state.locationNotSelected
                                                ? "必ず1つ選択してください"
                                                : undefined
                                        }
                                        required={true}
                                    >
                                        <Form.Item
                                            name="wants_location_hokkaido_japan"
                                            initialValue={
                                                initialData.wants_location_hokkaido_japan
                                            }
                                            valuePropName="checked"
                                            noStyle
                                        >
                                            <Checkbox
                                                className={
                                                    styles.horizontalCheckboxStyle
                                                }
                                            >
                                                北海道
                                            </Checkbox>
                                        </Form.Item>
                                        <Form.Item
                                            name="wants_location_touhoku_japan"
                                            initialValue={
                                                initialData.wants_location_touhoku_japan
                                            }
                                            valuePropName="checked"
                                            noStyle
                                        >
                                            <Checkbox
                                                className={
                                                    styles.horizontalCheckboxStyle
                                                }
                                            >
                                                東北
                                            </Checkbox>
                                        </Form.Item>
                                        <Form.Item
                                            name="wants_location_kanto_japan"
                                            initialValue={
                                                initialData.wants_location_kanto_japan
                                            }
                                            valuePropName="checked"
                                            noStyle
                                        >
                                            <Checkbox
                                                className={
                                                    styles.horizontalCheckboxStyle
                                                }
                                            >
                                                関東
                                            </Checkbox>
                                        </Form.Item>
                                        <Form.Item
                                            name="wants_location_chubu_japan"
                                            initialValue={
                                                initialData.wants_location_chubu_japan
                                            }
                                            valuePropName="checked"
                                            noStyle
                                        >
                                            <Checkbox
                                                className={
                                                    styles.horizontalCheckboxStyle
                                                }
                                            >
                                                中部
                                            </Checkbox>
                                        </Form.Item>
                                        <Form.Item
                                            name="wants_location_toukai_japan"
                                            initialValue={
                                                initialData.wants_location_toukai_japan
                                            }
                                            valuePropName="checked"
                                            noStyle
                                        >
                                            <Checkbox
                                                className={
                                                    styles.horizontalCheckboxStyle
                                                }
                                            >
                                                東海
                                            </Checkbox>
                                        </Form.Item>
                                        <Form.Item
                                            name="wants_location_kansai_japan"
                                            initialValue={
                                                initialData.wants_location_kansai_japan
                                            }
                                            valuePropName="checked"
                                            noStyle
                                        >
                                            <Checkbox
                                                className={
                                                    styles.horizontalCheckboxStyle
                                                }
                                            >
                                                関西
                                            </Checkbox>
                                        </Form.Item>
                                        <Form.Item
                                            name="wants_location_shikoku_japan"
                                            initialValue={
                                                initialData.wants_location_shikoku_japan
                                            }
                                            valuePropName="checked"
                                            noStyle
                                        >
                                            <Checkbox
                                                className={
                                                    styles.horizontalCheckboxStyle
                                                }
                                            >
                                                四国
                                            </Checkbox>
                                        </Form.Item>
                                        <Form.Item
                                            name="wants_location_chugoku_japan"
                                            initialValue={
                                                initialData.wants_location_chugoku_japan
                                            }
                                            valuePropName="checked"
                                            noStyle
                                        >
                                            <Checkbox
                                                data-testid="sending-area-china"
                                                className={
                                                    styles.horizontalCheckboxStyle
                                                }
                                            >
                                                中国
                                            </Checkbox>
                                        </Form.Item>
                                        <Form.Item
                                            name="wants_location_kyushu_japan"
                                            initialValue={
                                                initialData.wants_location_kyushu_japan
                                            }
                                            valuePropName="checked"
                                            noStyle
                                        >
                                            <Checkbox
                                                className={
                                                    styles.horizontalCheckboxStyle
                                                }
                                            >
                                                九州
                                            </Checkbox>
                                        </Form.Item>
                                        <Form.Item
                                            name="wants_location_other_japan"
                                            initialValue={
                                                initialData.wants_location_other_japan
                                            }
                                            valuePropName="checked"
                                            noStyle
                                        >
                                            <Checkbox
                                                data-testid="sending-area-other"
                                                className={
                                                    styles.horizontalCheckboxStyle
                                                }
                                            >
                                                その他
                                            </Checkbox>
                                        </Form.Item>
                                    </Form.Item>
                                )}
                                {selectedSearchType === "job" && (
                                    <div>
                                        <Form.Item
                                            {...topLevelFormLayout}
                                            className={styles.field}
                                            label={
                                                <span>
                                                    配信職種&nbsp;
                                                    <Tooltip
                                                        title={
                                                            <span>
                                                                配信内容に該当する職種を選択します。
                                                            </span>
                                                        }
                                                    >
                                                        <QuestionCircleFilled
                                                            data-testid="jobtype-label-tooltip"
                                                            style={{
                                                                color: iconCustomColor,
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </span>
                                            }
                                            validateStatus={
                                                this.state.jobNotSelected
                                                    ? "error"
                                                    : "success"
                                            }
                                            help={
                                                this.state.jobNotSelected
                                                    ? "必ず1つ選択してください"
                                                    : undefined
                                            }
                                            required={true}
                                            name="jobtype"
                                            initialValue={initialData.jobtype}
                                        >
                                            <Radio.Group
                                                onChange={this.onChangeJobType}
                                            >
                                                <Radio value={"dev"}>
                                                    開発
                                                </Radio>
                                                <Radio value={"infra"}>
                                                    インフラ
                                                </Radio>
                                                <Radio
                                                    value={"other"}
                                                    data-testid="jobtype-other-radio"
                                                >
                                                    その他
                                                </Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                        {selectedJobType === "dev" && (
                                            <div>
                                                <Form.Item
                                                    {...secondLevelFormLayout}
                                                    label={
                                                        <span>
                                                            配信職種詳細&nbsp;
                                                            <Tooltip
                                                                title={
                                                                    <span>
                                                                        配信内容に該当する職種詳細を選択します。
                                                                    </span>
                                                                }
                                                            >
                                                                <QuestionCircleFilled
                                                                    data-testid="jobtype-dev-label-tooltip"
                                                                    style={{
                                                                        color: iconCustomColor,
                                                                    }}
                                                                />
                                                            </Tooltip>
                                                        </span>
                                                    }
                                                    className={styles.field}
                                                    validateStatus={
                                                        this.state
                                                            .jobTypeNotSelected
                                                            ? "error"
                                                            : "success"
                                                    }
                                                    help={
                                                        this.state
                                                            .jobTypeNotSelected
                                                            ? "必ず1つ選択してください"
                                                            : undefined
                                                    }
                                                    required={true}
                                                    name="jobtype_dev"
                                                    initialValue={
                                                        initialData.jobtype_dev
                                                    }
                                                >
                                                    <Radio.Group>
                                                        <Radio
                                                            value={
                                                                "jobtype_dev_designer"
                                                            }
                                                        >
                                                            デザイナー
                                                        </Radio>
                                                        <Radio
                                                            value={
                                                                "jobtype_dev_front"
                                                            }
                                                        >
                                                            フロントエンド
                                                        </Radio>
                                                        <Radio
                                                            value={
                                                                "jobtype_dev_server"
                                                            }
                                                        >
                                                            バックエンド
                                                        </Radio>
                                                        <Radio
                                                            value={
                                                                "jobtype_dev_pm"
                                                            }
                                                        >
                                                            PM・ディレクター
                                                        </Radio>
                                                        <Radio
                                                            value={
                                                                "jobtype_dev_other"
                                                            }
                                                            data-testid="jobTypeDev-other-radio"
                                                        >
                                                            その他
                                                        </Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                                <Form.Item
                                                    {...secondLevelFormLayout}
                                                    label={
                                                        <span>
                                                            配信スキル詳細&nbsp;
                                                            <Tooltip
                                                                title={
                                                                    <span>
                                                                        配信内容に該当するスキル詳細を選択します。
                                                                    </span>
                                                                }
                                                            >
                                                                <QuestionCircleFilled
                                                                    data-testid="jobskill-dev-label-tooltip"
                                                                    style={{
                                                                        color: iconCustomColor,
                                                                    }}
                                                                />
                                                            </Tooltip>
                                                        </span>
                                                    }
                                                    className={styles.field}
                                                    validateStatus={
                                                        this.state
                                                            .jobSkillNotSelected
                                                            ? "error"
                                                            : "success"
                                                    }
                                                    help={
                                                        this.state
                                                            .jobSkillNotSelected
                                                            ? "必ず1つ選択してください"
                                                            : undefined
                                                    }
                                                    required={true}
                                                >
                                                    <Form.Item
                                                        name="jobskill_dev_youken"
                                                        initialValue={
                                                            initialData.jobskill_dev_youken
                                                        }
                                                        valuePropName="checked"
                                                        noStyle
                                                    >
                                                        <Checkbox
                                                            className={
                                                                styles.horizontalCheckboxStyle
                                                            }
                                                        >
                                                            要件定義
                                                        </Checkbox>
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="jobskill_dev_kihon"
                                                        initialValue={
                                                            initialData.jobskill_dev_kihon
                                                        }
                                                        valuePropName="checked"
                                                        noStyle
                                                    >
                                                        <Checkbox
                                                            className={
                                                                styles.horizontalCheckboxStyle
                                                            }
                                                        >
                                                            基本設計
                                                        </Checkbox>
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="jobskill_dev_syousai"
                                                        initialValue={
                                                            initialData.jobskill_dev_syousai
                                                        }
                                                        valuePropName="checked"
                                                        noStyle
                                                    >
                                                        <Checkbox
                                                            className={
                                                                styles.horizontalCheckboxStyle
                                                            }
                                                        >
                                                            詳細設計
                                                        </Checkbox>
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="jobskill_dev_seizou"
                                                        initialValue={
                                                            initialData.jobskill_dev_seizou
                                                        }
                                                        valuePropName="checked"
                                                        noStyle
                                                    >
                                                        <Checkbox
                                                            className={
                                                                styles.horizontalCheckboxStyle
                                                            }
                                                        >
                                                            製造
                                                        </Checkbox>
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="jobskill_dev_test"
                                                        initialValue={
                                                            initialData.jobskill_dev_test
                                                        }
                                                        valuePropName="checked"
                                                        noStyle
                                                    >
                                                        <Checkbox
                                                            className={
                                                                styles.horizontalCheckboxStyle
                                                            }
                                                        >
                                                            テスト・検証
                                                        </Checkbox>
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="jobskill_dev_hosyu"
                                                        initialValue={
                                                            initialData.jobskill_dev_hosyu
                                                        }
                                                        valuePropName="checked"
                                                        noStyle
                                                    >
                                                        <Checkbox
                                                            className={
                                                                styles.horizontalCheckboxStyle
                                                            }
                                                        >
                                                            保守・運用
                                                        </Checkbox>
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="jobskill_dev_beginner"
                                                        initialValue={
                                                            initialData.jobskill_dev_beginner
                                                        }
                                                        valuePropName="checked"
                                                        noStyle
                                                    >
                                                        <Checkbox
                                                            className={
                                                                styles.horizontalCheckboxStyle
                                                            }
                                                        >
                                                            未経験
                                                        </Checkbox>
                                                    </Form.Item>
                                                </Form.Item>
                                            </div>
                                        )}
                                        {selectedJobType === "infra" && (
                                            <div>
                                                <Form.Item
                                                    {...secondLevelFormLayout}
                                                    label={
                                                        <span>
                                                            配信職種詳細&nbsp;
                                                            <Tooltip
                                                                title={
                                                                    <span>
                                                                        配信内容に該当する職種詳細を選択します。
                                                                    </span>
                                                                }
                                                            >
                                                                <QuestionCircleFilled
                                                                    data-testid="jobtype-infra-label-tooltip"
                                                                    style={{
                                                                        color: iconCustomColor,
                                                                    }}
                                                                />
                                                            </Tooltip>
                                                        </span>
                                                    }
                                                    className={styles.field}
                                                    validateStatus={
                                                        this.state
                                                            .jobTypeNotSelected
                                                            ? "error"
                                                            : "success"
                                                    }
                                                    help={
                                                        this.state
                                                            .jobTypeNotSelected
                                                            ? "必ず1つ選択してください"
                                                            : undefined
                                                    }
                                                    required={true}
                                                    name="jobtype_infra"
                                                    initialValue={
                                                        initialData.jobtype_infra
                                                    }
                                                >
                                                    <Radio.Group>
                                                        <Radio
                                                            value={
                                                                "jobtype_infra_server"
                                                            }
                                                        >
                                                            サーバー
                                                        </Radio>
                                                        <Radio
                                                            value={
                                                                "jobtype_infra_network"
                                                            }
                                                        >
                                                            ネットワーク
                                                        </Radio>
                                                        <Radio
                                                            value={
                                                                "jobtype_infra_security"
                                                            }
                                                        >
                                                            セキュリティ
                                                        </Radio>
                                                        <Radio
                                                            value={
                                                                "jobtype_infra_database"
                                                            }
                                                        >
                                                            データベース
                                                        </Radio>
                                                        <Radio
                                                            value={
                                                                "jobtype_infra_sys"
                                                            }
                                                        >
                                                            情報システム
                                                        </Radio>
                                                        <Radio
                                                            value={
                                                                "jobtype_infra_other"
                                                            }
                                                            data-testid="jobTypeInfra-other-radio"
                                                        >
                                                            その他
                                                        </Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                                <Form.Item
                                                    {...secondLevelFormLayout}
                                                    label={
                                                        <span>
                                                            配信スキル詳細&nbsp;
                                                            <Tooltip
                                                                title={
                                                                    <span>
                                                                        配信内容に該当するスキル詳細を選択します。
                                                                    </span>
                                                                }
                                                            >
                                                                <QuestionCircleFilled
                                                                    data-testid="jobskill-infra-label-tooltip"
                                                                    style={{
                                                                        color: iconCustomColor,
                                                                    }}
                                                                />
                                                            </Tooltip>
                                                        </span>
                                                    }
                                                    className={styles.field}
                                                    validateStatus={
                                                        this.state
                                                            .jobSkillNotSelected
                                                            ? "error"
                                                            : "success"
                                                    }
                                                    help={
                                                        this.state
                                                            .jobSkillNotSelected
                                                            ? "必ず1つ選択してください"
                                                            : undefined
                                                    }
                                                    required={true}
                                                >
                                                    <Form.Item
                                                        name="jobskill_infra_youken"
                                                        initialValue={
                                                            initialData.jobskill_infra_youken
                                                        }
                                                        valuePropName="checked"
                                                        noStyle
                                                    >
                                                        <Checkbox
                                                            className={
                                                                styles.horizontalCheckboxStyle
                                                            }
                                                        >
                                                            要件定義
                                                        </Checkbox>
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="jobskill_infra_kihon"
                                                        initialValue={
                                                            initialData.jobskill_infra_kihon
                                                        }
                                                        valuePropName="checked"
                                                        noStyle
                                                    >
                                                        <Checkbox
                                                            className={
                                                                styles.horizontalCheckboxStyle
                                                            }
                                                        >
                                                            基本設計
                                                        </Checkbox>
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="jobskill_infra_syousai"
                                                        initialValue={
                                                            initialData.jobskill_infra_syousai
                                                        }
                                                        valuePropName="checked"
                                                        noStyle
                                                    >
                                                        <Checkbox
                                                            className={
                                                                styles.horizontalCheckboxStyle
                                                            }
                                                        >
                                                            詳細設計
                                                        </Checkbox>
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="jobskill_infra_kouchiku"
                                                        initialValue={
                                                            initialData.jobskill_infra_kouchiku
                                                        }
                                                        valuePropName="checked"
                                                        noStyle
                                                    >
                                                        <Checkbox
                                                            className={
                                                                styles.horizontalCheckboxStyle
                                                            }
                                                        >
                                                            構築
                                                        </Checkbox>
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="jobskill_infra_test"
                                                        initialValue={
                                                            initialData.jobskill_infra_test
                                                        }
                                                        valuePropName="checked"
                                                        noStyle
                                                    >
                                                        <Checkbox
                                                            className={
                                                                styles.horizontalCheckboxStyle
                                                            }
                                                        >
                                                            テスト・検証
                                                        </Checkbox>
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="jobskill_infra_hosyu"
                                                        initialValue={
                                                            initialData.jobskill_infra_hosyu
                                                        }
                                                        valuePropName="checked"
                                                        noStyle
                                                    >
                                                        <Checkbox
                                                            className={
                                                                styles.horizontalCheckboxStyle
                                                            }
                                                        >
                                                            保守・運用
                                                        </Checkbox>
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="jobskill_infra_kanshi"
                                                        initialValue={
                                                            initialData.jobskill_infra_kanshi
                                                        }
                                                        valuePropName="checked"
                                                        noStyle
                                                    >
                                                        <Checkbox
                                                            className={
                                                                styles.horizontalCheckboxStyle
                                                            }
                                                        >
                                                            監視
                                                        </Checkbox>
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="jobskill_infra_beginner"
                                                        initialValue={
                                                            initialData.jobskill_infra_beginner
                                                        }
                                                        valuePropName="checked"
                                                        noStyle
                                                    >
                                                        <Checkbox
                                                            className={
                                                                styles.horizontalCheckboxStyle
                                                            }
                                                        >
                                                            未経験
                                                        </Checkbox>
                                                    </Form.Item>
                                                </Form.Item>
                                            </div>
                                        )}
                                        {selectedJobType === "other" && (
                                            <div>
                                                <Form.Item
                                                    {...secondLevelFormLayout}
                                                    label={
                                                        <span>
                                                            配信職種詳細&nbsp;
                                                            <Tooltip
                                                                title={
                                                                    <span>
                                                                        配信内容に該当する職種詳細を選択します。
                                                                    </span>
                                                                }
                                                            >
                                                                <QuestionCircleFilled
                                                                    data-testid="jobtype-other-label-tooltip"
                                                                    style={{
                                                                        color: iconCustomColor,
                                                                    }}
                                                                />
                                                            </Tooltip>
                                                        </span>
                                                    }
                                                    className={styles.field}
                                                    validateStatus={
                                                        this.state
                                                            .jobTypeNotSelected
                                                            ? "error"
                                                            : "success"
                                                    }
                                                    help={
                                                        this.state
                                                            .jobTypeNotSelected
                                                            ? "必ず1つ選択してください"
                                                            : undefined
                                                    }
                                                    required={true}
                                                    name="jobtype_other"
                                                    initialValue={
                                                        initialData.jobtype_other
                                                    }
                                                >
                                                    <Radio.Group>
                                                        <Radio
                                                            value={
                                                                "jobtype_other_eigyo"
                                                            }
                                                        >
                                                            営業・事務
                                                        </Radio>
                                                        <Radio
                                                            value={
                                                                "jobtype_other_kichi"
                                                            }
                                                        >
                                                            基地局
                                                        </Radio>
                                                        <Radio
                                                            value={
                                                                "jobtype_other_support"
                                                            }
                                                        >
                                                            コールセンター・サポートデスク
                                                        </Radio>
                                                        <Radio
                                                            value={
                                                                "jobtype_other_other"
                                                            }
                                                            data-testid="jobTypeOther-other-radio"
                                                        >
                                                            その他
                                                        </Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </div>
                                        )}
                                        <Form.Item
                                            {...topLevelFormLayout}
                                            label={
                                                <span>
                                                    配信商流&nbsp;
                                                    <Tooltip
                                                        title={
                                                            <span>
                                                                配信内容に該当する商流を選択します。
                                                                <br />
                                                                <a
                                                                    href={
                                                                        Links
                                                                            .helps
                                                                            .scheduledEmails
                                                                            .deliveryCommercialDistribution
                                                                    }
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    data-testid="jobshouryu-tooltip-link"
                                                                >
                                                                    詳細
                                                                </a>
                                                            </span>
                                                        }
                                                    >
                                                        <QuestionCircleFilled
                                                            data-testid="jobsyouryu-label-tooltip"
                                                            style={{
                                                                color: iconCustomColor,
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </span>
                                            }
                                            className={styles.field}
                                            validateStatus={
                                                this.state.jobSyouryuNotSelected
                                                    ? "error"
                                                    : "success"
                                            }
                                            help={
                                                this.state.jobSyouryuNotSelected
                                                    ? "必ず1つ選択してください"
                                                    : undefined
                                            }
                                            required={true}
                                            name="job_syouryu"
                                            initialValue={
                                                initialData.job_syouryu
                                            }
                                        >
                                            <Radio.Group>
                                                <Radio value={4}>
                                                    エンド直・元請直
                                                </Radio>
                                                <Radio value={3}>1次請</Radio>
                                                <Radio value={2}>2次請</Radio>
                                                <Radio value={1}>
                                                    3次請・不明
                                                </Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </div>
                                )}
                                {selectedSearchType === "personnel" && (
                                    <div>
                                        <Form.Item
                                            {...topLevelFormLayout}
                                            label={
                                                <span>
                                                    配信職種&nbsp;
                                                    <Tooltip
                                                        title={
                                                            <span>
                                                                配信内容に該当する職種を選択します。
                                                            </span>
                                                        }
                                                    >
                                                        <QuestionCircleFilled
                                                            data-testid="personneltype-label-tooltip"
                                                            style={{
                                                                color: iconCustomColor,
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </span>
                                            }
                                            validateStatus={
                                                this.state.personnelNotSelected
                                                    ? "error"
                                                    : "success"
                                            }
                                            help={
                                                this.state.personnelNotSelected
                                                    ? "必ず1つ選択してください"
                                                    : undefined
                                            }
                                            required={true}
                                            style={{ marginBottom: "10px" }}
                                        >
                                            <Row>
                                                <Form.Item
                                                    name="personneltype_dev"
                                                    initialValue={
                                                        initialData.personneltype_dev
                                                    }
                                                    valuePropName="checked"
                                                    noStyle
                                                >
                                                    <Checkbox
                                                        style={
                                                            personnelTypeDevVisible
                                                                ? {
                                                                      marginTop:
                                                                          "5px",
                                                                  }
                                                                : {}
                                                        }
                                                        onChange={
                                                            this
                                                                .onChangePersonnelTypeDev
                                                        }
                                                    >
                                                        開発
                                                    </Checkbox>
                                                </Form.Item>
                                            </Row>
                                            {personnelTypeDevVisible && (
                                                <Col pull={4}>
                                                    <Form.Item
                                                        {...secondLevelFormLayout}
                                                        label={
                                                            <span>
                                                                配信職種詳細&nbsp;
                                                                <Tooltip
                                                                    title={
                                                                        <span>
                                                                            配信内容に該当する職種詳細を選択します。
                                                                        </span>
                                                                    }
                                                                >
                                                                    <QuestionCircleFilled
                                                                        data-testid="personneltype-dev-jobDetail-label-tooltip"
                                                                        style={{
                                                                            color: iconCustomColor,
                                                                        }}
                                                                    />
                                                                </Tooltip>
                                                            </span>
                                                        }
                                                        validateStatus={
                                                            this.state
                                                                .personnelTypeDevNotSelected
                                                                ? "error"
                                                                : "success"
                                                        }
                                                        help={
                                                            this.state
                                                                .personnelTypeDevNotSelected
                                                                ? "必ず1つ選択してください"
                                                                : undefined
                                                        }
                                                        required={true}
                                                        style={{
                                                            marginTop: "5px",
                                                            marginBottom:
                                                                "10px",
                                                        }}
                                                    >
                                                        <Row
                                                            justify="start"
                                                            style={{
                                                                marginTop:
                                                                    "5px",
                                                            }}
                                                        >
                                                            <Form.Item
                                                                name="personneltype_dev_designer"
                                                                valuePropName="checked"
                                                                initialValue={
                                                                    initialData.personneltype_dev_other
                                                                }
                                                                noStyle
                                                            >
                                                                <Checkbox
                                                                    className={
                                                                        styles.horizontalCheckboxStyle
                                                                    }
                                                                >
                                                                    デザイナー
                                                                </Checkbox>
                                                            </Form.Item>
                                                            <Form.Item
                                                                name="personneltype_dev_front"
                                                                valuePropName="checked"
                                                                initialValue={
                                                                    initialData.personneltype_dev_other
                                                                }
                                                                noStyle
                                                            >
                                                                <Checkbox
                                                                    className={
                                                                        styles.horizontalCheckboxStyle
                                                                    }
                                                                >
                                                                    フロントエンド
                                                                </Checkbox>
                                                            </Form.Item>
                                                            <Form.Item
                                                                name="personneltype_dev_server"
                                                                valuePropName="checked"
                                                                initialValue={
                                                                    initialData.personneltype_dev_other
                                                                }
                                                                noStyle
                                                            >
                                                                <Checkbox
                                                                    className={
                                                                        styles.horizontalCheckboxStyle
                                                                    }
                                                                >
                                                                    バックエンド
                                                                </Checkbox>
                                                            </Form.Item>
                                                            <Form.Item
                                                                name="personneltype_dev_pm"
                                                                valuePropName="checked"
                                                                initialValue={
                                                                    initialData.personneltype_dev_other
                                                                }
                                                                noStyle
                                                            >
                                                                <Checkbox
                                                                    className={
                                                                        styles.horizontalCheckboxStyle
                                                                    }
                                                                >
                                                                    PM・ディレクター
                                                                </Checkbox>
                                                            </Form.Item>
                                                            <Form.Item
                                                                name="personneltype_dev_other"
                                                                valuePropName="checked"
                                                                initialValue={
                                                                    initialData.personneltype_dev_other
                                                                }
                                                                noStyle
                                                            >
                                                                <Checkbox
                                                                    data-testid="personneltype-dev-other-checkbox"
                                                                    className={
                                                                        styles.horizontalCheckboxStyle
                                                                    }
                                                                >
                                                                    その他
                                                                </Checkbox>
                                                            </Form.Item>
                                                        </Row>
                                                    </Form.Item>
                                                    <Form.Item
                                                        {...secondLevelFormLayout}
                                                        label={
                                                            <span>
                                                                配信スキル詳細&nbsp;
                                                                <Tooltip
                                                                    title={
                                                                        <span>
                                                                            配信内容に該当するスキル詳細を選択します。
                                                                        </span>
                                                                    }
                                                                >
                                                                    <QuestionCircleFilled
                                                                        data-testid="personneltype-dev-jobSkill-label-tooltip"
                                                                        style={{
                                                                            color: iconCustomColor,
                                                                        }}
                                                                    />
                                                                </Tooltip>
                                                            </span>
                                                        }
                                                        validateStatus={
                                                            this.state
                                                                .personnelSkillDevNotSelected
                                                                ? "error"
                                                                : "success"
                                                        }
                                                        help={
                                                            this.state
                                                                .personnelSkillDevNotSelected
                                                                ? "必ず1つ選択してください"
                                                                : undefined
                                                        }
                                                        required={true}
                                                    >
                                                        <Row
                                                            justify="start"
                                                            style={{
                                                                marginTop:
                                                                    "5px",
                                                            }}
                                                        >
                                                            <Form.Item
                                                                name="personnelskill_dev_youken"
                                                                initialValue={
                                                                    initialData.personnelskill_dev_youken
                                                                }
                                                                valuePropName="checked"
                                                                noStyle
                                                            >
                                                                <Checkbox
                                                                    className={
                                                                        styles.horizontalCheckboxStyle
                                                                    }
                                                                >
                                                                    要件定義
                                                                </Checkbox>
                                                            </Form.Item>
                                                            <Form.Item
                                                                name="personnelskill_dev_kihon"
                                                                initialValue={
                                                                    initialData.personnelskill_dev_kihon
                                                                }
                                                                valuePropName="checked"
                                                                noStyle
                                                            >
                                                                <Checkbox
                                                                    className={
                                                                        styles.horizontalCheckboxStyle
                                                                    }
                                                                >
                                                                    基本設計
                                                                </Checkbox>
                                                            </Form.Item>
                                                            <Form.Item
                                                                name="personnelskill_dev_syousai"
                                                                initialValue={
                                                                    initialData.personnelskill_dev_syousai
                                                                }
                                                                valuePropName="checked"
                                                                noStyle
                                                            >
                                                                <Checkbox
                                                                    className={
                                                                        styles.horizontalCheckboxStyle
                                                                    }
                                                                >
                                                                    詳細設計
                                                                </Checkbox>
                                                            </Form.Item>
                                                            <Form.Item
                                                                name="personnelskill_dev_seizou"
                                                                initialValue={
                                                                    initialData.personnelskill_dev_seizou
                                                                }
                                                                valuePropName="checked"
                                                                noStyle
                                                            >
                                                                <Checkbox
                                                                    className={
                                                                        styles.horizontalCheckboxStyle
                                                                    }
                                                                >
                                                                    製造
                                                                </Checkbox>
                                                            </Form.Item>
                                                            <Form.Item
                                                                name="personnelskill_dev_test"
                                                                initialValue={
                                                                    initialData.personnelskill_dev_test
                                                                }
                                                                valuePropName="checked"
                                                                noStyle
                                                            >
                                                                <Checkbox
                                                                    className={
                                                                        styles.horizontalCheckboxStyle
                                                                    }
                                                                >
                                                                    テスト・検証
                                                                </Checkbox>
                                                            </Form.Item>
                                                            <Form.Item
                                                                name="personnelskill_dev_hosyu"
                                                                initialValue={
                                                                    initialData.personnelskill_dev_hosyu
                                                                }
                                                                valuePropName="checked"
                                                                noStyle
                                                            >
                                                                <Checkbox
                                                                    className={
                                                                        styles.horizontalCheckboxStyle
                                                                    }
                                                                >
                                                                    保守・運用
                                                                </Checkbox>
                                                            </Form.Item>
                                                            <Form.Item
                                                                name="personnelskill_dev_beginner"
                                                                initialValue={
                                                                    initialData.personnelskill_dev_beginner
                                                                }
                                                                valuePropName="checked"
                                                                noStyle
                                                            >
                                                                <Checkbox
                                                                    className={
                                                                        styles.horizontalCheckboxStyle
                                                                    }
                                                                >
                                                                    未経験
                                                                </Checkbox>
                                                            </Form.Item>
                                                        </Row>
                                                    </Form.Item>
                                                </Col>
                                            )}
                                            <Row>
                                                <Form.Item
                                                    name="personneltype_infra"
                                                    initialValue={
                                                        initialData.personneltype_infra
                                                    }
                                                    valuePropName="checked"
                                                    noStyle
                                                >
                                                    <Checkbox
                                                        onChange={
                                                            this
                                                                .onChangePersonnelTypeInfra
                                                        }
                                                    >
                                                        インフラ
                                                    </Checkbox>
                                                </Form.Item>
                                            </Row>
                                            {personnelTypeInfraVisible && (
                                                <Col pull={4}>
                                                    <Form.Item
                                                        {...secondLevelFormLayout}
                                                        label={
                                                            <span>
                                                                配信職種詳細&nbsp;
                                                                <Tooltip
                                                                    title={
                                                                        <span>
                                                                            配信内容に該当する職種詳細を選択します。
                                                                        </span>
                                                                    }
                                                                >
                                                                    <QuestionCircleFilled
                                                                        data-testid="personneltype-infra-jobDetail-label-tooltip"
                                                                        style={{
                                                                            color: iconCustomColor,
                                                                        }}
                                                                    />
                                                                </Tooltip>
                                                            </span>
                                                        }
                                                        validateStatus={
                                                            this.state
                                                                .personnelTypeInfraNotSelected
                                                                ? "error"
                                                                : "success"
                                                        }
                                                        help={
                                                            this.state
                                                                .personnelTypeInfraNotSelected
                                                                ? "必ず1つ選択してください"
                                                                : undefined
                                                        }
                                                        required={true}
                                                        style={{
                                                            marginTop: "5px",
                                                            marginBottom:
                                                                "10px",
                                                        }}
                                                    >
                                                        <Form.Item
                                                            name="personneltype_infra_server"
                                                            initialValue={
                                                                initialData.personneltype_infra_server
                                                            }
                                                            valuePropName="checked"
                                                            noStyle
                                                        >
                                                            <Checkbox
                                                                className={
                                                                    styles.horizontalCheckboxStyle
                                                                }
                                                            >
                                                                サーバー
                                                            </Checkbox>
                                                        </Form.Item>
                                                        <Form.Item
                                                            name="personneltype_infra_network"
                                                            initialValue={
                                                                initialData.personneltype_infra_network
                                                            }
                                                            valuePropName="checked"
                                                            noStyle
                                                        >
                                                            <Checkbox
                                                                className={
                                                                    styles.horizontalCheckboxStyle
                                                                }
                                                            >
                                                                ネットワーク
                                                            </Checkbox>
                                                        </Form.Item>
                                                        <Form.Item
                                                            name="personneltype_infra_security"
                                                            initialValue={
                                                                initialData.personneltype_infra_security
                                                            }
                                                            valuePropName="checked"
                                                            noStyle
                                                        >
                                                            <Checkbox
                                                                className={
                                                                    styles.horizontalCheckboxStyle
                                                                }
                                                            >
                                                                セキュリティ
                                                            </Checkbox>
                                                        </Form.Item>
                                                        <Form.Item
                                                            name="personneltype_infra_database"
                                                            initialValue={
                                                                initialData.personneltype_infra_database
                                                            }
                                                            valuePropName="checked"
                                                            noStyle
                                                        >
                                                            <Checkbox
                                                                className={
                                                                    styles.horizontalCheckboxStyle
                                                                }
                                                            >
                                                                データベース
                                                            </Checkbox>
                                                        </Form.Item>
                                                        <Form.Item
                                                            name="personneltype_infra_sys"
                                                            initialValue={
                                                                initialData.personneltype_infra_sys
                                                            }
                                                            valuePropName="checked"
                                                            noStyle
                                                        >
                                                            <Checkbox
                                                                className={
                                                                    styles.horizontalCheckboxStyle
                                                                }
                                                            >
                                                                情報システム
                                                            </Checkbox>
                                                        </Form.Item>
                                                        <Form.Item
                                                            name="personneltype_infra_other"
                                                            initialValue={
                                                                initialData.personneltype_infra_other
                                                            }
                                                            valuePropName="checked"
                                                            noStyle
                                                        >
                                                            <Checkbox
                                                                className={
                                                                    styles.horizontalCheckboxStyle
                                                                }
                                                                data-testid="personneltype-infra-other-checkbox"
                                                            >
                                                                その他
                                                            </Checkbox>
                                                        </Form.Item>
                                                    </Form.Item>
                                                    <Form.Item
                                                        {...secondLevelFormLayout}
                                                        label={
                                                            <span>
                                                                配信スキル詳細&nbsp;
                                                                <Tooltip
                                                                    title={
                                                                        <span>
                                                                            配信内容に該当するスキル詳細を選択します。
                                                                        </span>
                                                                    }
                                                                >
                                                                    <QuestionCircleFilled
                                                                        data-testid="personneltype-infra-jobSkill-label-tooltip"
                                                                        style={{
                                                                            color: iconCustomColor,
                                                                        }}
                                                                    />
                                                                </Tooltip>
                                                            </span>
                                                        }
                                                        validateStatus={
                                                            this.state
                                                                .personnelSkillInfraNotSelected
                                                                ? "error"
                                                                : "success"
                                                        }
                                                        help={
                                                            this.state
                                                                .personnelSkillInfraNotSelected
                                                                ? "必ず1つ選択してください"
                                                                : undefined
                                                        }
                                                        required={true}
                                                    >
                                                        <Form.Item
                                                            name="personnelskill_infra_youken"
                                                            initialValue={
                                                                initialData.personnelskill_infra_youken
                                                            }
                                                            valuePropName="checked"
                                                            noStyle
                                                        >
                                                            <Checkbox
                                                                className={
                                                                    styles.horizontalCheckboxStyle
                                                                }
                                                            >
                                                                要件定義
                                                            </Checkbox>
                                                        </Form.Item>
                                                        <Form.Item
                                                            name="personnelskill_infra_kihon"
                                                            initialValue={
                                                                initialData.personnelskill_infra_kihon
                                                            }
                                                            valuePropName="checked"
                                                            noStyle
                                                        >
                                                            <Checkbox
                                                                className={
                                                                    styles.horizontalCheckboxStyle
                                                                }
                                                            >
                                                                基本設計
                                                            </Checkbox>
                                                        </Form.Item>
                                                        <Form.Item
                                                            name="personnelskill_infra_syousai"
                                                            initialValue={
                                                                initialData.personnelskill_infra_syousai
                                                            }
                                                            valuePropName="checked"
                                                            noStyle
                                                        >
                                                            <Checkbox
                                                                className={
                                                                    styles.horizontalCheckboxStyle
                                                                }
                                                            >
                                                                詳細設計
                                                            </Checkbox>
                                                        </Form.Item>
                                                        <Form.Item
                                                            name="personnelskill_infra_kouchiku"
                                                            initialValue={
                                                                initialData.personnelskill_infra_kouchiku
                                                            }
                                                            valuePropName="checked"
                                                            noStyle
                                                        >
                                                            <Checkbox
                                                                className={
                                                                    styles.horizontalCheckboxStyle
                                                                }
                                                            >
                                                                構築
                                                            </Checkbox>
                                                        </Form.Item>
                                                        <Form.Item
                                                            name="personnelskill_infra_test"
                                                            initialValue={
                                                                initialData.personnelskill_infra_test
                                                            }
                                                            valuePropName="checked"
                                                            noStyle
                                                        >
                                                            <Checkbox
                                                                className={
                                                                    styles.horizontalCheckboxStyle
                                                                }
                                                            >
                                                                テスト・検証
                                                            </Checkbox>
                                                        </Form.Item>
                                                        <Form.Item
                                                            name="personnelskill_infra_hosyu"
                                                            initialValue={
                                                                initialData.personnelskill_infra_hosyu
                                                            }
                                                            valuePropName="checked"
                                                            noStyle
                                                        >
                                                            <Checkbox
                                                                className={
                                                                    styles.horizontalCheckboxStyle
                                                                }
                                                            >
                                                                保守・運用
                                                            </Checkbox>
                                                        </Form.Item>
                                                        <Form.Item
                                                            name="personnelskill_infra_kanshi"
                                                            initialValue={
                                                                initialData.personnelskill_infra_kanshi
                                                            }
                                                            valuePropName="checked"
                                                            noStyle
                                                        >
                                                            <Checkbox
                                                                className={
                                                                    styles.horizontalCheckboxStyle
                                                                }
                                                            >
                                                                監視
                                                            </Checkbox>
                                                        </Form.Item>
                                                        <Form.Item
                                                            name="personnelskill_infra_beginner"
                                                            initialValue={
                                                                initialData.personnelskill_infra_beginner
                                                            }
                                                            valuePropName="checked"
                                                            noStyle
                                                        >
                                                            <Checkbox
                                                                className={
                                                                    styles.horizontalCheckboxStyle
                                                                }
                                                                x
                                                            >
                                                                未経験
                                                            </Checkbox>
                                                        </Form.Item>
                                                    </Form.Item>
                                                </Col>
                                            )}
                                            <Row>
                                                <Form.Item
                                                    name="personneltype_other"
                                                    initialValue={
                                                        initialData.personneltype_other
                                                    }
                                                    valuePropName="checked"
                                                    noStyle
                                                >
                                                    <Checkbox
                                                        onChange={
                                                            this
                                                                .onChangePersonnelTypeOther
                                                        }
                                                        data-testid="personneltype-other-checkbox"
                                                    >
                                                        その他
                                                    </Checkbox>
                                                </Form.Item>
                                            </Row>
                                            {personnelTypeOtherVisible && (
                                                <Col pull={4}>
                                                    <Form.Item
                                                        {...secondLevelFormLayout}
                                                        label={
                                                            <span>
                                                                配信職種詳細&nbsp;
                                                                <Tooltip
                                                                    title={
                                                                        <span>
                                                                            配信内容に該当する職種詳細を選択します。
                                                                        </span>
                                                                    }
                                                                >
                                                                    <QuestionCircleFilled
                                                                        data-testid="personneltype-other-label-tooltip"
                                                                        style={{
                                                                            color: iconCustomColor,
                                                                        }}
                                                                    />
                                                                </Tooltip>
                                                            </span>
                                                        }
                                                        validateStatus={
                                                            this.state
                                                                .personnelTypeOtherNotSelected
                                                                ? "error"
                                                                : "success"
                                                        }
                                                        help={
                                                            this.state
                                                                .personnelTypeOtherNotSelected
                                                                ? "必ず1つ選択してください"
                                                                : undefined
                                                        }
                                                        required={true}
                                                    >
                                                        <Form.Item
                                                            name="personneltype_other_eigyo"
                                                            initialValue={
                                                                initialData.personneltype_other_eigyo
                                                            }
                                                            valuePropName="checked"
                                                            noStyle
                                                        >
                                                            <Checkbox
                                                                className={
                                                                    styles.horizontalCheckboxStyle
                                                                }
                                                            >
                                                                営業・事務
                                                            </Checkbox>
                                                        </Form.Item>
                                                        <Form.Item
                                                            name="personneltype_other_kichi"
                                                            initialValue={
                                                                initialData.personneltype_other_kichi
                                                            }
                                                            valuePropName="checked"
                                                            noStyle
                                                        >
                                                            <Checkbox
                                                                className={
                                                                    styles.horizontalCheckboxStyle
                                                                }
                                                            >
                                                                基地局
                                                            </Checkbox>
                                                        </Form.Item>
                                                        <Form.Item
                                                            name="personneltype_other_support"
                                                            initialValue={
                                                                initialData.personneltype_other_support
                                                            }
                                                            valuePropName="checked"
                                                            noStyle
                                                        >
                                                            <Checkbox
                                                                className={
                                                                    styles.horizontalCheckboxStyle
                                                                }
                                                            >
                                                                コールセンター・サポートデスク
                                                            </Checkbox>
                                                        </Form.Item>
                                                        <Form.Item
                                                            name="personneltype_other_other"
                                                            initialValue={
                                                                initialData.personneltype_other_other
                                                            }
                                                            valuePropName="checked"
                                                            noStyle
                                                        >
                                                            <Checkbox
                                                                data-testid="personneltype-other-other-checkbox"
                                                                className={
                                                                    styles.horizontalCheckboxStyle
                                                                }
                                                            >
                                                                その他
                                                            </Checkbox>
                                                        </Form.Item>
                                                    </Form.Item>
                                                </Col>
                                            )}
                                        </Form.Item>
                                        <Form.Item
                                            {...topLevelFormLayout}
                                            label={
                                                <span>
                                                    配信雇用形態&nbsp;
                                                    <Tooltip
                                                        title={
                                                            <span>
                                                                配信内容に該当する雇用形態を選択します。
                                                            </span>
                                                        }
                                                    >
                                                        <QuestionCircleFilled
                                                            data-testid="personnel-job-koyou-label-tooltip"
                                                            style={{
                                                                color: iconCustomColor,
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </span>
                                            }
                                            className={styles.field}
                                            validateStatus={
                                                this.state.koyouNotSelected
                                                    ? "error"
                                                    : "success"
                                            }
                                            help={
                                                this.state.koyouNotSelected
                                                    ? "必ず1つ選択してください"
                                                    : undefined
                                            }
                                            required={true}
                                            name="job_koyou"
                                            initialValue={initialData.job_koyou}
                                        >
                                            <Radio.Group>
                                                <Radio value={"proper"}>
                                                    プロパー
                                                </Radio>
                                                <Radio value={"free"}>
                                                    フリーランス
                                                </Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                        <Form.Item
                                            {...topLevelFormLayout}
                                            label={
                                                <span>
                                                    配信商流&nbsp;
                                                    <Tooltip
                                                        title={
                                                            <span>
                                                                配信内容に該当する商流を選択します。
                                                                <br />
                                                                <a
                                                                    href={
                                                                        Links
                                                                            .helps
                                                                            .scheduledEmails
                                                                            .deliveryCommercialDistribution
                                                                    }
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    data-testid="personnel-syouryu-tooltip-link"
                                                                >
                                                                    詳細
                                                                </a>
                                                            </span>
                                                        }
                                                    >
                                                        <QuestionCircleFilled
                                                            data-testid="personnel-syouryu-label-tooltip"
                                                            style={{
                                                                color: iconCustomColor,
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </span>
                                            }
                                            className={styles.field}
                                            validateStatus={
                                                this.state
                                                    .personnelSyouryuNotSelected
                                                    ? "error"
                                                    : "success"
                                            }
                                            help={
                                                this.state
                                                    .personnelSyouryuNotSelected
                                                    ? "必ず1つ選択してください"
                                                    : undefined
                                            }
                                            required={true}
                                            name="personnel_syouryu"
                                            initialValue={
                                                initialData.personnel_syouryu
                                            }
                                        >
                                            <Radio.Group>
                                                <Radio value={4}>
                                                    自社所属
                                                </Radio>
                                                <Radio value={3}>
                                                    1社先所属
                                                </Radio>
                                                <Radio value={2}>
                                                    2社先所属
                                                </Radio>
                                                <Radio value={1}>
                                                    3社先以上所属・不明
                                                </Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </div>
                                )}
                                {selectedSearchType && (
                                    <div>
                                        <Title
                                            level={5}
                                            style={{ marginTop: "7%" }}
                                        >
                                            こだわり
                                        </Title>
                                        <Form.Item
                                            {...topLevelFormLayout}
                                            label={
                                                <span>
                                                    取引先ステータス&nbsp;
                                                    <Tooltip
                                                        title={
                                                            <span>
                                                                {this.tooltipMessage(
                                                                    "Organizations"
                                                                )}
                                                                にて登録をした「取引先ステータス」を対象とし、ここで選択をした取引先ステータスに該当する宛先が抽出されます。
                                                            </span>
                                                        }
                                                    >
                                                        <QuestionCircleFilled
                                                            data-testid="kodawari-category-prospective-tooltip"
                                                            style={{
                                                                color: iconCustomColor,
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </span>
                                            }
                                            className={styles.field}
                                            required={organizationStatusVisible}
                                            help={
                                                organizationStatusVisible &&
                                                organizationStatusNotSelected ? (
                                                    <div
                                                        style={{
                                                            color: "#ff0000",
                                                        }}
                                                    >
                                                        {
                                                            ErrorMessages
                                                                .generic
                                                                .selectRequired
                                                        }
                                                    </div>
                                                ) : undefined
                                            }
                                        >
                                            <Col span={24}>
                                                <Row align="middle">
                                                    <Switch
                                                        checkedChildren={
                                                            <CheckOutlined />
                                                        }
                                                        unCheckedChildren={
                                                            <CloseOutlined />
                                                        }
                                                        onChange={
                                                            this
                                                                .onChangeOrganizationStatusSwitch
                                                        }
                                                        checked={
                                                            organizationStatusVisible
                                                        }
                                                        data-testid="kodawari-status-switch"
                                                    />
                                                    {organizationStatusVisible && (
                                                        <div
                                                            style={{
                                                                marginLeft:
                                                                    "3%",
                                                            }}
                                                        >
                                                            <Form.Item
                                                                name="contact__organization__category_prospective"
                                                                initialValue={
                                                                    initialData.contact__organization__category_prospective
                                                                }
                                                                valuePropName="checked"
                                                                noStyle
                                                            >
                                                                <Checkbox>
                                                                    見込み客
                                                                </Checkbox>
                                                            </Form.Item>
                                                            <Form.Item
                                                                name="contact__organization__category_approached"
                                                                initialValue={
                                                                    initialData.contact__organization__category_approached
                                                                }
                                                                valuePropName="checked"
                                                                noStyle
                                                            >
                                                                <Checkbox>
                                                                    アプローチ済
                                                                </Checkbox>
                                                            </Form.Item>
                                                            <Form.Item
                                                                name="contact__organization__category_exchanged"
                                                                initialValue={
                                                                    initialData.contact__organization__category_exchanged
                                                                }
                                                                valuePropName="checked"
                                                                noStyle
                                                            >
                                                                <Checkbox>
                                                                    情報交換済
                                                                </Checkbox>
                                                            </Form.Item>
                                                            <Form.Item
                                                                name="contact__organization__category_client"
                                                                initialValue={
                                                                    initialData.contact__organization__category_client
                                                                }
                                                                valuePropName="checked"
                                                                noStyle
                                                            >
                                                                <Checkbox>
                                                                    契約実績有
                                                                </Checkbox>
                                                            </Form.Item>
                                                        </div>
                                                    )}
                                                </Row>
                                            </Col>
                                        </Form.Item>
                                        <Form.Item
                                            {...topLevelFormLayout}
                                            label={
                                                <span>
                                                    国籍&nbsp;
                                                    <Tooltip
                                                        title={
                                                            <span>
                                                                {this.tooltipMessage(
                                                                    "Country"
                                                                )}
                                                                「国籍」を対象とし、ここで選択をした国籍に該当する宛先が抽出されます
                                                            </span>
                                                        }
                                                    >
                                                        <QuestionCircleFilled
                                                            data-testid="kodawari-country-tooltip"
                                                            style={{
                                                                color: iconCustomColor,
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </span>
                                            }
                                            className={styles.field}
                                            required={countryStatusVisible}
                                            help={
                                                countryStatusVisible &&
                                                countryStatusNotSelected ? (
                                                    <div
                                                        style={{
                                                            color: "#ff0000",
                                                        }}
                                                    >
                                                        {
                                                            ErrorMessages
                                                                .generic
                                                                .selectRequired
                                                        }
                                                    </div>
                                                ) : undefined
                                            }
                                        >
                                            <Col span={24}>
                                                <Row align="middle">
                                                    <Switch
                                                        checkedChildren={
                                                            <CheckOutlined />
                                                        }
                                                        unCheckedChildren={
                                                            <CloseOutlined />
                                                        }
                                                        onChange={
                                                            this
                                                                .onChangeCountryStatusSwitch
                                                        }
                                                        checked={
                                                            countryStatusVisible
                                                        }
                                                        data-testid="kodawari-country-switch"
                                                    />
                                                    {countryStatusVisible && (
                                                        <div
                                                            style={{
                                                                marginLeft:
                                                                    "3%",
                                                            }}
                                                        >
                                                            <Form.Item
                                                                name="contact__organization__organization_country_jp"
                                                                initialValue={
                                                                    initialData.contact__organization__organization_country_jp
                                                                }
                                                                valuePropName="checked"
                                                                noStyle
                                                            >
                                                                <Checkbox>
                                                                    日本
                                                                </Checkbox>
                                                            </Form.Item>
                                                            <Form.Item
                                                                name="contact__organization__organization_country_kr"
                                                                initialValue={
                                                                    initialData.contact__organization__organization_country_kr
                                                                }
                                                                valuePropName="checked"
                                                                noStyle
                                                            >
                                                                <Checkbox>
                                                                    韓国
                                                                </Checkbox>
                                                            </Form.Item>
                                                            <Form.Item
                                                                name="contact__organization__organization_country_cn"
                                                                initialValue={
                                                                    initialData.contact__organization__organization_country_cn
                                                                }
                                                                valuePropName="checked"
                                                                noStyle
                                                            >
                                                                <Checkbox data-testid="kodawari-country-china-checkbox">
                                                                    中国
                                                                </Checkbox>
                                                            </Form.Item>
                                                            <Form.Item
                                                                name="contact__organization__organization_country_other"
                                                                initialValue={
                                                                    initialData.contact__organization__organization_country_other
                                                                }
                                                                valuePropName="checked"
                                                                noStyle
                                                            >
                                                                <Checkbox data-testid="kodawari-country-other-checkbox">
                                                                    その他
                                                                </Checkbox>
                                                            </Form.Item>
                                                        </div>
                                                    )}
                                                </Row>
                                            </Col>
                                        </Form.Item>
                                        <Form.Item
                                            {...topLevelFormLayout}
                                            label={
                                                <span>
                                                    請負&nbsp;
                                                    <Tooltip
                                                        title={
                                                            <span>
                                                                {this.tooltipMessage(
                                                                    "Contract"
                                                                )}
                                                                「請負」を対象とし、ここで選択をした請負に該当する宛先が抽出されます。
                                                            </span>
                                                        }
                                                    >
                                                        <QuestionCircleFilled
                                                            data-testid="kodawari-contract-tooltip"
                                                            style={{
                                                                color: iconCustomColor,
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </span>
                                            }
                                            name="contact__organization__contract"
                                            initialValue={
                                                initialData.contact__organization__contract
                                            }
                                            className={styles.field}
                                            required={contractStatusVisible}
                                            help={
                                                contractStatusVisible &&
                                                contactStatusNotSelected ? (
                                                    <div
                                                        style={{
                                                            color: "#ff0000",
                                                        }}
                                                    >
                                                        {
                                                            ErrorMessages
                                                                .generic
                                                                .selectRequired
                                                        }
                                                    </div>
                                                ) : undefined
                                            }
                                        >
                                            <Col span={24}>
                                                <Row align="middle">
                                                    <Switch
                                                        checkedChildren={
                                                            <CheckOutlined />
                                                        }
                                                        unCheckedChildren={
                                                            <CloseOutlined />
                                                        }
                                                        onChange={
                                                            this
                                                                .onChangeContractStatusSwitch
                                                        }
                                                        checked={
                                                            contractStatusVisible
                                                        }
                                                        data-testid="kodawari-contract-switch"
                                                    />
                                                    {contractStatusVisible && (
                                                        <div
                                                            style={{
                                                                marginLeft:
                                                                    "3%",
                                                            }}
                                                        >
                                                            <Form.Item
                                                                name="contact__organization__contract"
                                                                initialValue={
                                                                    initialData.contact__organization__contract
                                                                }
                                                                style={{
                                                                    marginBottom: 0,
                                                                }}
                                                            >
                                                                {contractStatusVisible && (
                                                                    <Radio.Group>
                                                                        <Radio
                                                                            value={
                                                                                "0"
                                                                            }
                                                                        >
                                                                            なし
                                                                        </Radio>
                                                                        <Radio
                                                                            value={
                                                                                "1"
                                                                            }
                                                                        >
                                                                            あり
                                                                        </Radio>
                                                                    </Radio.Group>
                                                                )}
                                                            </Form.Item>
                                                        </div>
                                                    )}
                                                </Row>
                                            </Col>
                                        </Form.Item>
                                        <Form.Item
                                            {...topLevelFormLayout}
                                            label={
                                                <span>
                                                    契約書類&nbsp;
                                                    <Tooltip
                                                        title={
                                                            <span>
                                                                {this.tooltipMessage(
                                                                    "Organizations"
                                                                )}
                                                                「契約書類」を対象とし、ここで選択をした契約書類に該当する宛先が抽出されます。
                                                            </span>
                                                        }
                                                    >
                                                        <QuestionCircleFilled
                                                            data-testid="kodawari-contract-document-tooltip"
                                                            style={{
                                                                color: iconCustomColor,
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </span>
                                            }
                                            className={styles.field}
                                            required={documentVisible}
                                            help={
                                                documentVisible &&
                                                documentNotSelected ? (
                                                    <div
                                                        style={{
                                                            color: "#ff0000",
                                                        }}
                                                    >
                                                        {
                                                            ErrorMessages
                                                                .generic
                                                                .selectRequired
                                                        }
                                                    </div>
                                                ) : undefined
                                            }
                                        >
                                            <Col span={24}>
                                                <Row align="middle">
                                                    <Switch
                                                        checkedChildren={
                                                            <CheckOutlined />
                                                        }
                                                        unCheckedChildren={
                                                            <CloseOutlined />
                                                        }
                                                        onChange={
                                                            this
                                                                .onChangeDocumentSwitch
                                                        }
                                                        checked={
                                                            documentVisible
                                                        }
                                                        data-testid="kodawari-contract-document-switch"
                                                    />
                                                    {documentVisible && (
                                                        <div
                                                            style={{
                                                                marginLeft:
                                                                    "3%",
                                                            }}
                                                        >
                                                            <Form.Item
                                                                name="contact__organization__has_outsourcing_basic_contact"
                                                                initialValue={
                                                                    initialData.contact__organization__has_outsourcing_basic_contact
                                                                }
                                                                valuePropName="checked"
                                                                noStyle
                                                            >
                                                                <Checkbox>
                                                                    業務委託基本契約
                                                                </Checkbox>
                                                            </Form.Item>
                                                            <Form.Item
                                                                name="contact__organization__has_nda"
                                                                initialValue={
                                                                    initialData.contact__organization__has_nda
                                                                }
                                                                valuePropName="checked"
                                                                noStyle
                                                            >
                                                                <Checkbox>
                                                                    NDA
                                                                </Checkbox>
                                                            </Form.Item>
                                                            <Form.Item
                                                                name="contact__organization__has_handring_personal_information"
                                                                initialValue={
                                                                    initialData.contact__organization__has_handring_personal_information
                                                                }
                                                                valuePropName="checked"
                                                                noStyle
                                                            >
                                                                <Checkbox>
                                                                    個人情報の取り扱い
                                                                </Checkbox>
                                                            </Form.Item>
                                                        </div>
                                                    )}
                                                </Row>
                                            </Col>
                                        </Form.Item>
                                        <Form.Item
                                            {...topLevelFormLayout}
                                            label={
                                                <span>
                                                    自社担当者&nbsp;
                                                    <Tooltip
                                                        title={
                                                            <span>
                                                                {this.tooltipMessage(
                                                                    "Contacts"
                                                                )}
                                                                にて登録をした「自社担当者」を対象とし、
                                                                ここで選択をした自社担当者に該当する宛先が抽出されます。
                                                                <br />
                                                                なお、無効化されているユーザーは選択表示されません。
                                                                <br />
                                                                <a
                                                                    href={
                                                                        Links
                                                                            .helps
                                                                            .users
                                                                            .activeToggle
                                                                    }
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    data-testid="kodawari-staff-tooltip-detail-link"
                                                                >
                                                                    詳細
                                                                </a>
                                                            </span>
                                                        }
                                                    >
                                                        <QuestionCircleFilled
                                                            data-testid="kodawari-staff-tooltip"
                                                            style={{
                                                                color: iconCustomColor,
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </span>
                                            }
                                            className={styles.field}
                                            required={staffVisible}
                                            help={
                                                staffVisible &&
                                                staffNotSelected ? (
                                                    <div
                                                        style={{
                                                            color: "#ff0000",
                                                        }}
                                                    >
                                                        {
                                                            ErrorMessages
                                                                .generic
                                                                .selectRequired
                                                        }
                                                    </div>
                                                ) : undefined
                                            }
                                        >
                                            <Col span={24}>
                                                <Row align="middle">
                                                    <Col>
                                                        <Switch
                                                            checkedChildren={
                                                                <CheckOutlined />
                                                            }
                                                            unCheckedChildren={
                                                                <CloseOutlined />
                                                            }
                                                            onChange={
                                                                this
                                                                    .onChangeStaffSwitch
                                                            }
                                                            checked={
                                                                staffVisible
                                                            }
                                                            data-testid="kodawari-staff-switch"
                                                        />
                                                    </Col>
                                                    <Col
                                                        span={8}
                                                        style={{
                                                            marginLeft: "3%",
                                                        }}
                                                    >
                                                        {staffVisible && (
                                                            <Form.Item
                                                                name="contact__staff"
                                                                initialValue={
                                                                    initialData.contact__staff
                                                                }
                                                                noStyle
                                                            >
                                                                <UserAjaxSelect
                                                                    disabled={
                                                                        !staffVisible
                                                                    }
                                                                />
                                                            </Form.Item>
                                                        )}
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Form.Item>
                                        <Form.Item
                                            {...topLevelFormLayout}
                                            label={
                                                <span>
                                                    相性&nbsp;
                                                    <Tooltip
                                                        title={
                                                            <span>
                                                                {this.tooltipMessage(
                                                                    "Contacts"
                                                                )}
                                                                にて登録をした「相性」を対象とし、ここで選択をした相性に該当する宛先が抽出されます。
                                                            </span>
                                                        }
                                                    >
                                                        <QuestionCircleFilled
                                                            data-testid="kodawari-category-tooltip"
                                                            style={{
                                                                color: iconCustomColor,
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </span>
                                            }
                                            className={styles.field}
                                            required={categoryVisible}
                                            help={
                                                categoryVisible &&
                                                categoryNotSelected ? (
                                                    <div
                                                        style={{
                                                            color: "#ff0000",
                                                        }}
                                                    >
                                                        {
                                                            ErrorMessages
                                                                .generic
                                                                .selectRequired
                                                        }
                                                    </div>
                                                ) : undefined
                                            }
                                        >
                                            <Col span={24}>
                                                <Row align="middle">
                                                    <Switch
                                                        checkedChildren={
                                                            <CheckOutlined />
                                                        }
                                                        unCheckedChildren={
                                                            <CloseOutlined />
                                                        }
                                                        onChange={
                                                            this
                                                                .onChangeCategorySwitch
                                                        }
                                                        checked={
                                                            categoryVisible
                                                        }
                                                        data-testid="kodawari-category-switch"
                                                    />
                                                    {categoryVisible && (
                                                        <div
                                                            style={{
                                                                whiteSpace:
                                                                    "nowrap",
                                                                textAlign:
                                                                    "left",
                                                                marginLeft:
                                                                    "3%",
                                                            }}
                                                        >
                                                            <Form.Item
                                                                name="contact__category"
                                                                initialValue={
                                                                    initialData.contact__category
                                                                }
                                                                noStyle
                                                            >
                                                                <Select
                                                                    style={{
                                                                        width: 160,
                                                                    }}
                                                                    placeholder="相性"
                                                                    data-testid="kodawari-category-select"
                                                                >
                                                                    <Select.Option value="heart">
                                                                        <HeartTwoTone twoToneColor="#eb2f96" />
                                                                    </Select.Option>
                                                                    <Select.Option value="frown">
                                                                        <HeartBrokenIcon style={{ color: svgColorIconLight }}/>
                                                                    </Select.Option>
                                                                </Select>
                                                            </Form.Item>
                                                            &nbsp;
                                                            <Form.Item
                                                                name="category_inequality"
                                                                initialValue={
                                                                    initialData.category_inequality
                                                                        ? initialData.category_inequality
                                                                        : "eq"
                                                                }
                                                                noStyle
                                                            >
                                                                <Select
                                                                    style={{
                                                                        width: 160,
                                                                    }}
                                                                    placeholder="条件"
                                                                >
                                                                    <Select.Option value="eq">
                                                                        と一致する
                                                                    </Select.Option>
                                                                    <Select.Option value="not_eq">
                                                                        と一致しない
                                                                    </Select.Option>
                                                                </Select>
                                                            </Form.Item>
                                                        </div>
                                                    )}
                                                </Row>
                                            </Col>
                                        </Form.Item>
                                        <OrganizationTagFormItem
                                            className={styles.field}
                                            {...topLevelFormLayout}
                                            tagVisible={organizationTagVisible}
                                            tagNotSelected={
                                                organizationTagNotSelected
                                            }
                                            initialData={initialData}
                                            onChangeTagSwitch={
                                                this
                                                    .onChangeOrganizationTagSwitch
                                            }
                                        />
                                        <ContactTagFormItem
                                            className={styles.field}
                                            {...topLevelFormLayout}
                                            tagVisible={contactTagVisible}
                                            tagNotSelected={
                                                contactTagNotSelected
                                            }
                                            initialData={initialData}
                                            onChangeTagSwitch={
                                                this.onChangeContactTagSwitch
                                            }
                                        />
                                        <OpenRankFormItem
                                          className={styles.field}
                                          {...topLevelFormLayout}
                                          initialData={initialData}
                                          rankSelectVisible={openRankVisible}
                                          rankNotSelected={openRankNotSelected}
                                          onChangeRankSwitch={this.onOpenRankSwitch}
                                        />
                                        <Modal
                                            title="タグ新規作成"
                                            visible={
                                                this.state.register_visible
                                            }
                                            onOk={this.registerOnOk}
                                            onCancel={this.registerOnCancel}
                                            zIndex={200}
                                            destroyOnClose={true}
                                        >
                                            <div>
                                                <Input
                                                    placeholder={"タグ名"}
                                                    onChange={this.setNewTag}
                                                />
                                            </div>
                                        </Modal>
                                        <Col
                                            span={24}
                                            style={{ marginTop: "5%" }}
                                        >
                                            <Row justify="center" gutter={5}>
                                                <Col span={12}>
                                                    <Row justify="end">
                                                        <Button
                                                            type="primary"
                                                            htmlType="submit"
                                                            style={{
                                                                width: "35%",
                                                            }}
                                                        >
                                                            検索
                                                        </Button>
                                                    </Row>
                                                </Col>
                                                <Col span={12}>
                                                    <Row justify="start">
                                                        <Button
                                                            type="default"
                                                            onClick={
                                                                this
                                                                    .onSearchReset
                                                            }
                                                        >
                                                            検索条件をリセット
                                                        </Button>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </div>
                                )}
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Col>
        );
    }
}

ContactEmailPreferenceSearchForm.propTypes = {
    initialData: PropTypes.shape({
        wants_location_kanto_japan: PropTypes.bool,
        wants_location_kansai_japan: PropTypes.bool,
        contact__tags: PropTypes.arrayOf(PropTypes.string),
        is_marketing_target: PropTypes.bool,
    }),
    submitHandler: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    pageId: PropTypes.string,
    tagResisterResult: PropTypes.string,
    requireRefresh: PropTypes.bool,
    setRef: PropTypes.func.isRequired,
    onResetSearchAllValue: PropTypes.func.isRequired,
};

ContactEmailPreferenceSearchForm.defaultProps = {
    initialData: {},
    tagResisterResult: undefined,
};

function mapStateToProps(state) {
    return {
        token: state.login.token,
    };
}

const _SearchFormWrapper = finalized(ContactEmailPreferenceSearchForm);
const SearchFormWrapper = connect(mapStateToProps)(_SearchFormWrapper);

export default SearchFormWrapper;
