// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PlanSwitchModal-checkboxGroup-ts5MT{text-align:left}.PlanSwitchModal-modal-so2NP{text-align:left}.PlanSwitchModal-tableRow-bvAOP{margin:1rem 0}.PlanSwitchModal-tableDivider-GZYRS{margin:0.6rem 0}.PlanSwitchModal-text-rumhz p{margin-bottom:0.3rem}.PlanSwitchModal-text-rumhz ul{list-style:disc;padding:0.5rem 1rem;margin-bottom:0}.PlanSwitchModal-text-rumhz .PlanSwitchModal-genericText-vfE12{margin-bottom:0.2rem}.PlanSwitchModal-notion-BW8Pb{margin-bottom:1rem}.PlanSwitchModal-notion-BW8Pb p{margin-bottom:0.3rem}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/PlanPage/PlanSummaryCard/PlanSwitchModal/PlanSwitchModal.scss"],"names":[],"mappings":"AAAA,qCACI,eAAgB,CACnB,6BAGG,eAAgB,CACnB,gCAGG,aAAc,CACjB,oCAGG,eAAgB,CACnB,8BAIO,oBAAqB,CAF7B,+BAMQ,eAAgB,CAChB,mBAAoB,CACpB,eAAgB,CARxB,+DAYQ,oBAAqB,CACxB,8BAID,kBAAmB,CADvB,gCAIQ,oBAAqB","sourcesContent":[".checkboxGroup {\n    text-align: left;\n}\n\n.modal {\n    text-align: left;\n}\n\n.tableRow {\n    margin: 1rem 0;\n}\n\n.tableDivider {\n    margin: 0.6rem 0;\n}\n\n.text {\n    p {\n        margin-bottom: 0.3rem;\n    }\n\n    ul {\n        list-style: disc;\n        padding: 0.5rem 1rem;\n        margin-bottom: 0;\n    }\n\n    .genericText {\n        margin-bottom: 0.2rem;\n    }\n}\n\n.notion {\n    margin-bottom: 1rem;\n\n    p {\n        margin-bottom: 0.3rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkboxGroup": `PlanSwitchModal-checkboxGroup-ts5MT`,
	"modal": `PlanSwitchModal-modal-so2NP`,
	"tableRow": `PlanSwitchModal-tableRow-bvAOP`,
	"tableDivider": `PlanSwitchModal-tableDivider-GZYRS`,
	"text": `PlanSwitchModal-text-rumhz`,
	"genericText": `PlanSwitchModal-genericText-vfE12`,
	"notion": `PlanSwitchModal-notion-BW8Pb`
};
export default ___CSS_LOADER_EXPORT___;
