// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LastLoginFormItem-container-n5VT1{width:100%;text-align:left}.LastLoginFormItem-userInput-HpVAw{width:100%}.LastLoginFormItem-tooltip-eDNe2{margin-left:5px}.LastLoginFormItem-infoIcon-ylu9f{align-self:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Forms/UserSearchForm/LastLoginFormItem/LastLoginFormItem.scss"],"names":[],"mappings":"AAAA,mCACI,UAAW,CACX,eAAgB,CACnB,mCAGG,UAAW,CACd,iCAGG,eAAgB,CACnB,kCAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `LastLoginFormItem-container-n5VT1`,
	"userInput": `LastLoginFormItem-userInput-HpVAw`,
	"tooltip": `LastLoginFormItem-tooltip-eDNe2`,
	"infoIcon": `LastLoginFormItem-infoIcon-ylu9f`
};
export default ___CSS_LOADER_EXPORT___;
