// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SalesIncludeArchiveSwitchFromItem-container-YyIBe{width:100%;text-align:left}.SalesIncludeArchiveSwitchFromItem-userInput-j8ecw{width:100%}.SalesIncludeArchiveSwitchFromItem-tooltip-jRwFc{margin-left:5px}.SalesIncludeArchiveSwitchFromItem-infoIcon-KyN4n{align-self:center}.SalesIncludeArchiveSwitchFromItem-switchLabel-Fu7QB{display:inline-block;align-self:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/sales/SalesBoardPage/SalesBoardSearchDrawer/SalesIncludeArchiveSwitchFromItem/SalesIncludeArchiveSwitchFromItem.scss"],"names":[],"mappings":"AAAA,mDACI,UAAW,CACX,eAAgB,CACnB,mDAGG,UAAW,CACd,iDAGG,eAAgB,CACnB,kDAGG,iBAAkB,CACrB,qDAGG,oBAAqB,CACrB,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n\n.switchLabel {\n    display: inline-block;\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `SalesIncludeArchiveSwitchFromItem-container-YyIBe`,
	"userInput": `SalesIncludeArchiveSwitchFromItem-userInput-j8ecw`,
	"tooltip": `SalesIncludeArchiveSwitchFromItem-tooltip-jRwFc`,
	"infoIcon": `SalesIncludeArchiveSwitchFromItem-infoIcon-KyN4n`,
	"switchLabel": `SalesIncludeArchiveSwitchFromItem-switchLabel-Fu7QB`
};
export default ___CSS_LOADER_EXPORT___;
