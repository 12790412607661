import React from "react";
import { Col, Form, Row, Tooltip } from "antd";
import CustomRangePicker from "~/components/Common/CustomRangePicker/CustomRangePicker";
import { QuestionCircleFilled } from "@ant-design/icons";
import { disabledFutureDates } from "~/utils/utils";
import { iconCustomColor } from "~/utils/constants";
import styles from "./CorporateEstablishmentDateFormItem.scss";

const CorporateEstablishmentDateFormItem = ({ disabled = false }) => {
    return (
        <Col span={24} style={{ marginBottom: "1%" }}>
            <Row className={styles.container}>
                <Col span={23}>
                    <Form.Item
                        colon={false}
                        name="establishment_date_range"
                        noStyle>
                        <CustomRangePicker
                            className={styles.container}
                            placeholder={["設立年月(始点)", "設立年月(終点)"]}
                            picker="month"
                            disabled={disabled}
                            inputReadOnly
                            disabledDate={disabledFutureDates}
                            disablePopupContainer
                        />
                    </Form.Item>
                </Col>
                <Col span={1} className={styles.infoIcon}>
                    <Tooltip
                        title={
                            <span>
                                左の入力欄には設立年月の範囲始点を入力します。
                                <br />
                                例：「2020-02」を入力した場合、2020年2月以降に設立された取引先が対象となります。
                                <br />
                                右の入力欄には設立年月の範囲終点を入力します。
                                <br />
                                例：「2020-02」を入力した場合、2020年2月以前に設立された取引先が対象となります。
                                <br />
                                ※未来年月を選択することはできません。
                            </span>
                        }>
                        <QuestionCircleFilled style={{ color: iconCustomColor }} className={styles.tooltip} />
                    </Tooltip>
                </Col>
            </Row>
        </Col>
    );
};

export default CorporateEstablishmentDateFormItem;
