// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RequiredDisplaySettingForm-container-Wp8pr{margin:16px auto;text-align:left}.RequiredDisplaySettingForm-card-b7pwJ{width:80%;margin:32px auto}.RequiredDisplaySettingForm-field-PASpD{max-width:800px;margin:8px 0}@media screen and (max-width: 768px){.RequiredDisplaySettingForm-field-PASpD{margin:8px auto;width:100%}}.RequiredDisplaySettingForm-field-PASpD .ant-transfer-list-header-dropdown{display:none !important}@media screen and (max-width: 768px){.RequiredDisplaySettingForm-button-Ng1eG{margin:0 16px 0 0;width:96px}}.RequiredDisplaySettingForm-relatedObjectsWrapper-gaR9_{text-align:center;margin:0 64px}@media screen and (max-width: 768px){.RequiredDisplaySettingForm-relatedObjectsWrapper-gaR9_{margin:auto;display:flex;flex-direction:column}}.RequiredDisplaySettingForm-relatedObject-TjvVS{margin:8px 64px}@media screen and (max-width: 768px){.RequiredDisplaySettingForm-relatedObject-TjvVS{margin:auto}}.RequiredDisplaySettingForm-alert-cC6YP{text-align:center;margin:32px auto}.RequiredDisplaySettingForm-phonenumberDash-oFswh{display:inline-block;width:5%;text-align:center}.RequiredDisplaySettingForm-nameSeparator-snV9I{display:inline-block;width:4%}.RequiredDisplaySettingForm-row-DJ5F1{width:80%}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Forms/RequiredDisplaySettingForm/RequiredDisplaySettingForm.scss"],"names":[],"mappings":"AAEA,4CACI,gBAAiB,CACjB,eAAgB,CACnB,uCAGG,SAAU,CACV,gBAAiB,CACpB,wCAGG,eAAgB,CAChB,YAAa,CAShB,qCAXD,wCAIQ,eAAgB,CAChB,UAAW,CAMlB,CAXD,2EASQ,uBAAwB,CAC3B,qCAGL,yCAEQ,iBAAkB,CAClB,UAAW,CAElB,CAED,wDACI,iBAAkB,CAClB,aAAc,CAMjB,qCARD,wDAIQ,WAAY,CACZ,YAAa,CACb,qBAAsB,CAE7B,CAED,gDACI,eAAgB,CAInB,qCALD,gDAGQ,WAAY,CAEnB,CAED,wCACI,iBAAkB,CAClB,gBAAiB,CACpB,kDAGG,oBAAqB,CACrB,QAAS,CACT,iBAAkB,CACrB,gDAGG,oBAAqB,CACrB,QAAS,CACZ,sCAGG,SAAU","sourcesContent":["@import \"../../../../stylesheets/constants\";\n\n.container {\n    margin: 16px auto;\n    text-align: left;\n}\n\n.card {\n    width: 80%;\n    margin: 32px auto;\n}\n\n.field {\n    max-width: 800px;\n    margin: 8px 0;\n    @include mobile-devices {\n        margin: 8px auto;\n        width: 100%;\n    }\n\n    :global(.ant-transfer-list-header-dropdown){\n        display: none !important;\n    }\n}\n\n.button {\n    @include mobile-devices {\n        margin: 0 16px 0 0;\n        width: 96px;\n    }\n}\n\n.relatedObjectsWrapper {\n    text-align: center;\n    margin: 0 64px;\n    @include mobile-devices {\n        margin: auto;\n        display: flex;\n        flex-direction: column;\n    }\n}\n\n.relatedObject {\n    margin: 8px 64px;\n    @include mobile-devices {\n        margin: auto;\n    }\n}\n\n.alert {\n    text-align: center;\n    margin: 32px auto;\n}\n\n.phonenumberDash {\n    display: inline-block;\n    width: 5%;\n    text-align: center;\n}\n\n.nameSeparator {\n    display: inline-block;\n    width: 4%;\n}\n\n.row {\n    width: 80%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `RequiredDisplaySettingForm-container-Wp8pr`,
	"card": `RequiredDisplaySettingForm-card-b7pwJ`,
	"field": `RequiredDisplaySettingForm-field-PASpD`,
	"button": `RequiredDisplaySettingForm-button-Ng1eG`,
	"relatedObjectsWrapper": `RequiredDisplaySettingForm-relatedObjectsWrapper-gaR9_`,
	"relatedObject": `RequiredDisplaySettingForm-relatedObject-TjvVS`,
	"alert": `RequiredDisplaySettingForm-alert-cC6YP`,
	"phonenumberDash": `RequiredDisplaySettingForm-phonenumberDash-oFswh`,
	"nameSeparator": `RequiredDisplaySettingForm-nameSeparator-snV9I`,
	"row": `RequiredDisplaySettingForm-row-DJ5F1`
};
export default ___CSS_LOADER_EXPORT___;
