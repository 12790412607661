"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePaymentRedux = exports.usePaymentAPI = void 0;
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var actionTypes_1 = require("~/actions/actionTypes");
var data_1 = require("~/actions/data");
var api_1 = require("~/domain/api");
var AlertMessage_1 = require("~/components/Common/AlertMessage/AlertMessage");
var usePaymentAPI = function () {
    var token = (0, react_redux_1.useSelector)(function (state) { return state.login.token; });
    var dispatch = (0, react_redux_1.useDispatch)();
    var baseURL = api_1.Endpoint.getBaseUrl();
    var fetchPayment = function () {
        var url = "".concat(baseURL, "/").concat(api_1.Endpoint.payjpPayment);
        dispatch((0, data_1.fetchPaymentInfoAction)(actionTypes_1.PAYMENT_FETCH, token, url));
    };
    var createPayment = function (postData) {
        var url = "".concat(baseURL, "/").concat(api_1.Endpoint.payjpPayment);
        dispatch((0, data_1.createPaymentInfoAction)(actionTypes_1.PAYMENT_FORM, token, url, postData));
    };
    var deletePayment = function (postData) {
        var url = "".concat(baseURL, "/").concat(api_1.Endpoint.payjpPayment);
        dispatch((0, data_1.deletePaymentInfoAction)(actionTypes_1.PAYMENT_DELETE, token, url, postData));
    };
    var updatePayment = function (postData) {
        var url = "".concat(baseURL, "/").concat(api_1.Endpoint.payjpPayment);
        dispatch((0, data_1.updatePaymentInfoAction)(actionTypes_1.PAYMENT_UPDATE, token, url, postData));
    };
    return {
        fetchPayment: fetchPayment,
        createPayment: createPayment,
        deletePayment: deletePayment,
        updatePayment: updatePayment,
    };
};
exports.usePaymentAPI = usePaymentAPI;
var usePaymentRedux = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var _a = (0, react_redux_1.useSelector)(function (state) { return state.paymentInfo; }), isPaymentInfoLoading = _a.isLoading, paymentInfo = _a.paymentInfo, paymentInfoErrorMessage = _a.errorMessage;
    var _b = (0, react_redux_1.useSelector)(function (state) { return state.paymentForm; }), paymentFormSuccessMessage = _b.successMessage, paymentFormErrorMessage = _b.errorMessage;
    (0, react_1.useEffect)(function () {
        if (paymentFormSuccessMessage) {
            (0, AlertMessage_1.customSuccessMessage)("", {
                content: paymentFormSuccessMessage,
                onClose: function () {
                    dispatch({ type: actionTypes_1.PAYMENT_FORM + actionTypes_1.CLEAR_SUCCESS });
                },
            });
        }
    }, [paymentFormSuccessMessage]);
    (0, react_1.useEffect)(function () {
        if (paymentFormErrorMessage) {
            (0, AlertMessage_1.customErrorMessage)("", {
                content: paymentFormErrorMessage,
                onClose: function () {
                    dispatch({ type: actionTypes_1.PAYMENT_FORM + actionTypes_1.CLEAR_ERROR });
                },
            });
        }
    }, [paymentFormErrorMessage]);
    (0, react_1.useEffect)(function () {
        if (paymentInfoErrorMessage) {
            (0, AlertMessage_1.customErrorMessage)("", {
                content: paymentInfoErrorMessage,
                onClose: function () {
                    dispatch({ type: actionTypes_1.PAYMENT_FETCH + actionTypes_1.CLEAR_ERROR });
                },
            });
        }
    }, [paymentInfoErrorMessage]);
    return {
        isPaymentInfoLoading: isPaymentInfoLoading,
        paymentInfo: paymentInfo,
        paymentInfoErrorMessage: paymentInfoErrorMessage,
    };
};
exports.usePaymentRedux = usePaymentRedux;
