import { LOADING, LOADED, ERROR, CLEAR, LOGOUT } from "../actions/actionTypes";

import { SCHEDULED_MAIL_PREVIEW_CONTAINER } from "../components/Pages/pageIds";

export const scheduledEmailPreviewInitialState = {
    loading: false,
    data: {
        sender_email: "取得中...",
        subject: "取得中...",
        body: "取得中...",
        date_to_send: "取得中...",
        file_type: 2,
    },
    errorMessage: "",
};

export const scheduledEmailPreviewContainer = (
    state = scheduledEmailPreviewInitialState,
    action
) => {
    switch (action.type) {
        case SCHEDULED_MAIL_PREVIEW_CONTAINER + LOADING:
            return Object.assign({}, state, {
                loading: true,
                errorMessage: "",
            });
        case SCHEDULED_MAIL_PREVIEW_CONTAINER + LOADED:
            return Object.assign({}, state, {
                loading: false,
                data: action.payload.data,
            });
        case SCHEDULED_MAIL_PREVIEW_CONTAINER + ERROR:
            return Object.assign({}, state, {
                loading: false,
                errorMessage:
                    action.payload.error.detail || action.payload.error.message,
            });
        case SCHEDULED_MAIL_PREVIEW_CONTAINER + CLEAR:
            return Object.assign({}, state, scheduledEmailPreviewInitialState);
        case LOGOUT:
            return Object.assign({}, state, scheduledEmailPreviewInitialState);
        default:
            return state;
    }
};
