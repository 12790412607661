// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProjectCommentCreateDateFormItem-container-dKJfC{width:100%;text-align:left}.ProjectCommentCreateDateFormItem-userInput-rd86J{width:100%}.ProjectCommentCreateDateFormItem-tooltip-cGpoK{margin-left:5px}.ProjectCommentCreateDateFormItem-infoIcon-FxZ8n{align-self:center}.ProjectCommentCreateDateFormItem-marginBottom-vKEMK{margin-bottom:1%}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/project/ProjectBoardPage/ProjectBoardSearchDrawer/ProjectCommentCreateDateFormItem/ProjectCommentCreateDateFormItem.scss"],"names":[],"mappings":"AAAA,kDACE,UAAW,CACX,eAAgB,CACjB,kDAGC,UAAW,CACZ,gDAGC,eAAgB,CACjB,iDAGC,iBAAkB,CACnB,qDAGC,gBAAiB","sourcesContent":[".container {\n  width: 100%;\n  text-align: left;\n}\n\n.userInput {\n  width: 100%;\n}\n\n.tooltip {\n  margin-left: 5px;\n}\n\n.infoIcon {\n  align-self: center;\n}\n\n.marginBottom {\n  margin-bottom: 1%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ProjectCommentCreateDateFormItem-container-dKJfC`,
	"userInput": `ProjectCommentCreateDateFormItem-userInput-rd86J`,
	"tooltip": `ProjectCommentCreateDateFormItem-tooltip-cGpoK`,
	"infoIcon": `ProjectCommentCreateDateFormItem-infoIcon-FxZ8n`,
	"marginBottom": `ProjectCommentCreateDateFormItem-marginBottom-vKEMK`
};
export default ___CSS_LOADER_EXPORT___;
