// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BackOfficeModifiedUserFormItem-container-CD_fa{width:100%;text-align:left}.BackOfficeModifiedUserFormItem-userInput-FWZdi{width:100%}.BackOfficeModifiedUserFormItem-tooltip-ba3hT{margin-left:5px}.BackOfficeModifiedUserFormItem-infoIcon-jxJFV{align-self:center}.BackOfficeModifiedUserFormItem-marginBottom-f2QED{margin-bottom:1%}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/backOffice/BackOfficeBoardPage/BackOfficeBoardSearchDrawer/BackOfficeModifiedUserFormItem/BackOfficeModifiedUserFormItem.scss"],"names":[],"mappings":"AAAA,gDACI,UAAW,CACX,eAAgB,CACnB,gDAGG,UAAW,CACd,8CAGG,eAAgB,CACnB,+CAGG,iBAAkB,CACrB,mDAGG,gBAAiB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n\n.marginBottom {\n    margin-bottom: 1%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `BackOfficeModifiedUserFormItem-container-CD_fa`,
	"userInput": `BackOfficeModifiedUserFormItem-userInput-FWZdi`,
	"tooltip": `BackOfficeModifiedUserFormItem-tooltip-ba3hT`,
	"infoIcon": `BackOfficeModifiedUserFormItem-infoIcon-jxJFV`,
	"marginBottom": `BackOfficeModifiedUserFormItem-marginBottom-f2QED`
};
export default ___CSS_LOADER_EXPORT___;
