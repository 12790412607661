"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRequestTimeValidation = void 0;
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var actionTypes_1 = require("~/actions/actionTypes");
var store_1 = require("~/models/store");
var requestTime = 10000;
var useRequestTimeValidation = function () {
    var time = (0, store_1.useTypedSelector)(function (state) { return state.emailValidateTime; }).time;
    var dispatch = (0, react_redux_1.useDispatch)();
    (0, react_1.useEffect)(function () {
        dispatch({ type: actionTypes_1.CLEAR_TIME });
    }, []);
    var checkTime = function () {
        var currentTimestamp = Date.now();
        if (currentTimestamp - time < requestTime) {
            return false;
        }
        dispatch({ type: actionTypes_1.UPDATE_TIME, payload: { time: currentTimestamp } });
        return true;
    };
    return { checkTime: checkTime };
};
exports.useRequestTimeValidation = useRequestTimeValidation;
