// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SalesPriorityFormItem-container-GpX_V{width:100%;text-align:left}.SalesPriorityFormItem-userInput-xZ3J4{width:100%}.SalesPriorityFormItem-tooltip-kGvjY{margin-left:5px}.SalesPriorityFormItem-infoIcon-jJOZE{align-self:center}.SalesPriorityFormItem-select_option-gAq52{text-align:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/sales/SalesBoardPage/SalesBoardSearchDrawer/SalesPriorityFormItem/SalesPriorityFormItem.scss"],"names":[],"mappings":"AAAA,uCACI,UAAW,CACX,eAAgB,CACnB,uCAGG,UAAW,CACd,qCAGG,eAAgB,CACnB,sCAGG,iBAAkB,CACrB,2CAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n\n.select_option {\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `SalesPriorityFormItem-container-GpX_V`,
	"userInput": `SalesPriorityFormItem-userInput-xZ3J4`,
	"tooltip": `SalesPriorityFormItem-tooltip-kGvjY`,
	"infoIcon": `SalesPriorityFormItem-infoIcon-jJOZE`,
	"select_option": `SalesPriorityFormItem-select_option-gAq52`
};
export default ___CSS_LOADER_EXPORT___;
