// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WarningMessages-alertContainer-IThXI{text-align:left}.WarningMessages-alertParagraphContainer-kJAK5{list-style-type:disc !important;text-align:left !important}.WarningMessages-alertParagraph-NhSyE{margin-bottom:5px}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/SharedEmailSettingPage/components/WarningMessages/WarningMessages.scss"],"names":[],"mappings":"AAAA,sCACI,eAAgB,CACnB,+CAGG,+BAAgC,CAChC,0BAA2B,CAC9B,sCAGG,iBAAkB","sourcesContent":[".alertContainer {\n    text-align: left;\n}\n\n.alertParagraphContainer {\n    list-style-type: disc !important;\n    text-align: left !important;\n}\n\n.alertParagraph {\n    margin-bottom: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alertContainer": `WarningMessages-alertContainer-IThXI`,
	"alertParagraphContainer": `WarningMessages-alertParagraphContainer-kJAK5`,
	"alertParagraph": `WarningMessages-alertParagraph-NhSyE`
};
export default ___CSS_LOADER_EXPORT___;
