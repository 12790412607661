// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AppContainer-sider-SNtnd{background:#f2f2f2;border-right-style:solid;border-right-color:#eee;margin-top:64px}.AppContainer-content-BtFVr{background:#fff;padding:24px;margin:0;min-height:640px;margin-top:64px}@media screen and (max-width: 768px){.AppContainer-content-BtFVr{padding:2px}}.AppContainer-collapse-ZtpkL{margin:-20px -30px;float:left;z-index:1;background:#e1e1e1;border-color:#e1e1e1}.AppContainer-collapse-ZtpkL:focus{background:#e1e1e1;border-color:#e1e1e1}.AppContainer-collapse-ZtpkL:hover{background:#a7cf69}.AppContainer-customLink-HOpk1{color:#262626 !important;transition:color 0.3s}.AppContainer-customLink-HOpk1:hover{color:#a7cf69 !important}.AppContainer-mention-Fu1vb{display:none}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/AppContainer.scss","webpack://./frontend/stylesheets/constants.scss"],"names":[],"mappings":"AAKA,0BACI,kBAAmB,CACnB,wBAAyB,CACzB,uBAAwB,CACxB,eAPc,CAQjB,4BAGG,eAAgB,CAChB,YAAa,CACb,QAAS,CACT,gBAAiB,CACjB,eAfc,CAoBjB,qCAVD,4BAQQ,WAAY,CAEnB,CAED,6BACI,kBAAmB,CACnB,UAAW,CACX,SAAU,CACV,kBAzBmB,CA0BnB,oBA1BmB,CAqBvB,mCAQQ,kBA7Be,CA8Bf,oBA9Be,CAqBvB,mCAaQ,kBClCe,CDmClB,+BAID,wBAAyB,CACzB,qBAAsB,CAF1B,qCAKQ,wBAAgC,CACnC,4BAID,YAAa","sourcesContent":["@import \"../../stylesheets/constants\";\n\n$headerHeigh: 64px;\n$inactiveColor: #e1e1e1;\n\n.sider {\n    background: #f2f2f2;\n    border-right-style: solid;\n    border-right-color: #eee;\n    margin-top: $headerHeigh;\n}\n\n.content {\n    background: #fff;\n    padding: 24px;\n    margin: 0;\n    min-height: 640px;\n    margin-top: $headerHeigh;\n\n    @include mobile-devices {\n        padding: 2px;\n    }\n}\n\n.collapse {\n    margin: -20px -30px;\n    float: left;\n    z-index: 1;\n    background: $inactiveColor;\n    border-color: $inactiveColor;\n\n    &:focus {\n        background: $inactiveColor;\n        border-color: $inactiveColor;\n    }\n\n    &:hover {\n        background: $primary-color;\n    }\n}\n\n.customLink {\n    color: #262626 !important;\n    transition: color 0.3s;\n\n    &:hover {\n        color: $primary-color !important;\n    }\n}\n\n.mention{\n    display: none;\n}\n","/* アプリケーション全体 */\n$breakpoint-mobile: 768px;\n$width-small-screen: 1500px;\n$primary-color: #a7cf69;\n$komorebi-brown: #615748;\n\n/* ガントチャート関連 */\n$gantt-over70: #003656;\n$gantt-between40to70: #80d6ff;\n$gantt-under40: #d0edff;\n\n@mixin mobile-devices() {\n    @media screen and (max-width: $breakpoint-mobile) {\n        @content;\n    }\n}\n\n@mixin small-screen() {\n    @media screen and (max-width: $width-small-screen) {\n        @content;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sider": `AppContainer-sider-SNtnd`,
	"content": `AppContainer-content-BtFVr`,
	"collapse": `AppContainer-collapse-ZtpkL`,
	"customLink": `AppContainer-customLink-HOpk1`,
	"mention": `AppContainer-mention-Fu1vb`
};
export default ___CSS_LOADER_EXPORT___;
