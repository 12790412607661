// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PopWarningMessage-wrapper-mrAHu p{margin-bottom:2px;color:#e1585a}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/SharedEmailSettingPage/components/SharedEmailSettingForm/PopWarningMessage/PopWarningMessage.scss"],"names":[],"mappings":"AAAA,mCAEQ,iBAAkB,CAClB,aAAc","sourcesContent":[".wrapper {\n    p {\n        margin-bottom: 2px;\n        color: #e1585a;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `PopWarningMessage-wrapper-mrAHu`
};
export default ___CSS_LOADER_EXPORT___;
