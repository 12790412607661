import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Tooltip, Icon } from 'antd';

import Path from '../Routes/Paths';
import GenericPickerTable from './GenericPickerTable';


const onRowClick = (record) => {};

const onColumnClick = (record) => {
  const { history } = global;
  const Url = `${Path.contacts}/${record.contact__id}`;
  history.pushState({}, '', Url);
  window.open(Url);
};

export class contactPickerTable extends Component {

  render = () => {
    const {
      selectedRowKeys, onChange, onSelectChange, loading,
    } = this.props;

    const columns = [
      {
        title: '取引先担当者名',
        dataIndex: 'contact__display_name',
        key: 'contact__display_name',
        onCell: (record) => ({
          onClick: () => onColumnClick(record),
        }),
      }, {
        title: 'メールアドレス',
        dataIndex: 'contact__email',
        key: 'contact__email',
        onCell: (record) => ({
          onClick: () => onColumnClick(record),
        }),
      }, {
        title: '所属取引先',
        dataIndex: 'contact__organization__name',
        key: 'contact__organization_name',
        onCell: (record) => ({
          onClick: () => onColumnClick(record),
        }),
      },
    ];

    const searchFields = ['contact__display_name', 'contact__email', 'contact__organization__name'];

    return (
      <GenericPickerTable
        {...this.props}
        columns={columns}
        searchFields={searchFields}
        onRowClick={onRowClick}
        selectedRowKeys={selectedRowKeys}
        onChange={onChange}
        onSelectChange={onSelectChange}
        loading={loading}
      />
    );
  };
}


contactPickerTable.propTypes = {
  selectedRowKeys: PropTypes.arrayOf(
    PropTypes.string.isRequired,
  ).isRequired,
  onChange: PropTypes.func,
  onSelectChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      contact__id: PropTypes.string.isRequired,
      contact__display_name: PropTypes.string.isRequired,
      contact__organization__name: PropTypes.string.isRequired,
      contact__score: PropTypes.number,
      contact__organization__is_blacklisted: PropTypes.bool,
      contact__organization__capital_required_for_transactions_not_qualified: PropTypes.bool,
    }),
  ),
  showSelectedRowOnly: PropTypes.bool,
};

contactPickerTable.defaultProps = {
  data: [],
  loading: false,
  onChange: () => {},
};

export default withRouter(contactPickerTable);
