// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EmailHead-container-bFOCI{text-align:left}.EmailHead-divider-pBheI{height:1px !important;margin:8px 0 !important}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/EmailComponents/EmailHead.scss"],"names":[],"mappings":"AAAA,2BACE,eAAgB,CACjB,yBAWC,qBAAsB,CACtB,uBAAwB","sourcesContent":[".container {\n  text-align: left;\n}\n\n.attachments {\n\n}\n\n.attachment {\n\n}\n\n.divider {\n  height: 1px !important; // Line width\n  margin: 8px 0 !important;\n};\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `EmailHead-container-bFOCI`,
	"divider": `EmailHead-divider-pBheI`
};
export default ___CSS_LOADER_EXPORT___;
