import createEditPage from '~/components/Pages/Factories/createEditPage';
import Paths from '~/components/Routes/Paths';
import { USER_REGISTER_PAGE } from '~/components/Pages/pageIds';
import UserRegisterForm from '~/components/Forms/UserRegisterForm/UserRegisterForm';
import { Endpoint } from '~/domain/api';

const pageId = USER_REGISTER_PAGE;
const resourceURL = `${Endpoint.getBaseUrl()}/${Endpoint.usersRegister}`;

const UserRegisterPageContainer = createEditPage(
  pageId,
  'userRegisterPage',
  'ユーザー 登録',
  UserRegisterForm,
  resourceURL,
  '',
  Paths.index,
  data => data,
  data => data,
  undefined,
  undefined,
  () => { return true },
  () => { return true },
  undefined,
  true,
  () => { return 'ユーザー 登録' },
  false
);


export default UserRegisterPageContainer;
