// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FaxInputFormItem-container-EuuUE{margin:16px auto;text-align:left}.FaxInputFormItem-field-K4dzd{max-width:800px;margin:8px 0}.FaxInputFormItem-button-b_q46{width:30%;margin:auto}.FaxInputFormItem-alert-dpM9R{margin-top:16px;margin-bottom:16px}.FaxInputFormItem-phonenumberDash-jKvjb{display:inline-block;width:5%;text-align:center;height:100%;align-self:center}.FaxInputFormItem-nameSeparator-ZEk1H{display:inline-block;width:4%}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Forms/FaxInputFormItem/FaxInputFormItem.scss"],"names":[],"mappings":"AAAA,kCACI,gBAAiB,CACjB,eAAgB,CACnB,8BAGG,eAAgB,CAChB,YAAa,CAChB,+BAGG,SAAU,CACV,WAAY,CACf,8BAGG,eAAgB,CAChB,kBAAmB,CACtB,wCAGG,oBAAqB,CACrB,QAAS,CACT,iBAAkB,CAClB,WAAY,CACZ,iBAAkB,CACrB,sCAGG,oBAAqB,CACrB,QAAS","sourcesContent":[".container {\n    margin: 16px auto;\n    text-align: left;\n}\n\n.field {\n    max-width: 800px;\n    margin: 8px 0;\n}\n\n.button {\n    width: 30%;\n    margin: auto;\n}\n\n.alert {\n    margin-top: 16px;\n    margin-bottom: 16px;\n}\n\n.phonenumberDash {\n    display: inline-block;\n    width: 5%;\n    text-align: center;\n    height: 100%;\n    align-self: center;\n}\n\n.nameSeparator {\n    display: inline-block;\n    width: 4%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `FaxInputFormItem-container-EuuUE`,
	"field": `FaxInputFormItem-field-K4dzd`,
	"button": `FaxInputFormItem-button-b_q46`,
	"alert": `FaxInputFormItem-alert-dpM9R`,
	"phonenumberDash": `FaxInputFormItem-phonenumberDash-jKvjb`,
	"nameSeparator": `FaxInputFormItem-nameSeparator-ZEk1H`
};
export default ___CSS_LOADER_EXPORT___;
