// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContactPositionFormItem-container-F6JGw{width:100%;text-align:left}.ContactPositionFormItem-userInput-pUL4V{width:100%}.ContactPositionFormItem-tooltip-jV0Vv{margin-left:5px}.ContactPositionFormItem-infoIcon-POQro{align-self:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/ContactSearchPage/ContactSearchDrawer/ContactPositionFormItem/ContactPositionFormItem.scss"],"names":[],"mappings":"AAAA,yCACI,UAAW,CACX,eAAgB,CACnB,yCAGG,UAAW,CACd,uCAGG,eAAgB,CACnB,wCAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ContactPositionFormItem-container-F6JGw`,
	"userInput": `ContactPositionFormItem-userInput-pUL4V`,
	"tooltip": `ContactPositionFormItem-tooltip-jV0Vv`,
	"infoIcon": `ContactPositionFormItem-infoIcon-POQro`
};
export default ___CSS_LOADER_EXPORT___;
