"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRole = void 0;
var react_redux_1 = require("react-redux");
var useRole = function () {
    var _a = (0, react_redux_1.useSelector)(function (state) { return state.login; }), role = _a.role, isAdminUser = _a.isAdminUser;
    var isMasterRole = role === "master";
    return {
        role: role,
        isMasterRole: isMasterRole,
        isAdminUser: isAdminUser,
    };
};
exports.useRole = useRole;
