// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BackOfficeEndDateFormItem-container-XEvR5{width:100%;text-align:left}.BackOfficeEndDateFormItem-userInput-MYVz4{width:100%}.BackOfficeEndDateFormItem-tooltip-R43OF{margin-left:5px}.BackOfficeEndDateFormItem-infoIcon-VdKxx{align-self:center}.BackOfficeEndDateFormItem-customDatePicker-SZJwC{width:100%;height:35px}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/backOffice/BackOfficeBoardPage/BackOfficeBoardSearchDrawer/BackOfficeEndDateFormItem/BackOfficeEndDateFormItem.scss"],"names":[],"mappings":"AAAA,2CACE,UAAW,CACX,eAAgB,CACjB,2CAGC,UAAW,CACZ,yCAGC,eAAgB,CACjB,0CAGC,iBAAkB,CACnB,kDAGC,UAAW,CACX,WAAY","sourcesContent":[".container {\n  width: 100%;\n  text-align: left;\n}\n\n.userInput {\n  width: 100%;\n}\n\n.tooltip {\n  margin-left: 5px;\n}\n\n.infoIcon {\n  align-self: center;\n}\n\n.customDatePicker {\n  width: 100%;\n  height: 35px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `BackOfficeEndDateFormItem-container-XEvR5`,
	"userInput": `BackOfficeEndDateFormItem-userInput-MYVz4`,
	"tooltip": `BackOfficeEndDateFormItem-tooltip-R43OF`,
	"infoIcon": `BackOfficeEndDateFormItem-infoIcon-VdKxx`,
	"customDatePicker": `BackOfficeEndDateFormItem-customDatePicker-SZJwC`
};
export default ___CSS_LOADER_EXPORT___;
