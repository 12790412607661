// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PersonnelBoardTabPaneContent-controlButtonsWrapper-yioRK{margin-top:2rem}.PersonnelBoardTabPaneContent-section-DUovu{margin-top:3rem}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/personnel/PersonnelBoardPage/PersonnelBoardDetailModal/PersonnelBoardTabPaneContent/PersonnelBoardTabPaneContent.scss"],"names":[],"mappings":"AAAA,0DACI,eAAgB,CACnB,4CAGG,eAAgB","sourcesContent":[".controlButtonsWrapper {\n    margin-top: 2rem;\n}\n\n.section {\n    margin-top: 3rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"controlButtonsWrapper": `PersonnelBoardTabPaneContent-controlButtonsWrapper-yioRK`,
	"section": `PersonnelBoardTabPaneContent-section-DUovu`
};
export default ___CSS_LOADER_EXPORT___;
