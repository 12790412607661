// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EmailSearchHasAttachmentsFormItem-container-NDcTd{width:100%;text-align:left}.EmailSearchHasAttachmentsFormItem-userInput-C3bWw{width:100%}.EmailSearchHasAttachmentsFormItem-tooltip-f95zT{margin-left:5px}.EmailSearchHasAttachmentsFormItem-infoIcon-G1o3K{align-self:center}.EmailSearchHasAttachmentsFormItem-marginBottom-hz7Gl{margin-bottom:1%}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/SharedEmailSearchPage/SharedEmailSearchDrawer/EmailSearchHasAttachmentsFormItem/EmailSearchHasAttachmentsFormItem.scss"],"names":[],"mappings":"AAAA,mDACI,UAAW,CACX,eAAgB,CACnB,mDAGG,UAAW,CACd,iDAGG,eAAgB,CACnB,kDAGG,iBAAkB,CACrB,sDAGG,gBAAiB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n\n.marginBottom {\n    margin-bottom: 1%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `EmailSearchHasAttachmentsFormItem-container-NDcTd`,
	"userInput": `EmailSearchHasAttachmentsFormItem-userInput-C3bWw`,
	"tooltip": `EmailSearchHasAttachmentsFormItem-tooltip-f95zT`,
	"infoIcon": `EmailSearchHasAttachmentsFormItem-infoIcon-G1o3K`,
	"marginBottom": `EmailSearchHasAttachmentsFormItem-marginBottom-hz7Gl`
};
export default ___CSS_LOADER_EXPORT___;
