"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var utils_1 = require("~/utils/utils");
var utils_2 = require("./utils");
var cards = function (client) {
    var path = function (dynamicPath) {
        if (dynamicPath === void 0) { dynamicPath = ""; }
        return utils_2.projectBoardBasePath + "/cards" + dynamicPath;
    };
    return {
        createCard: function (postData) {
            var url = path();
            return client.post(url, postData);
        },
        fetchCard: function (cardId) {
            var url = path("/" + cardId);
            return client.get(url);
        },
        updateCard: function (cardId, postData) {
            var url = path("/" + cardId);
            return client.patch(url, postData);
        },
        deleteCard: function (cardId) {
            var url = path("/" + cardId);
            return client.delete(url);
        },
        changeCardPosition: function (cardId, postData) {
            var url = path("/" + cardId + "/position");
            return client.patch(url, postData);
        },
        duplicateCard: function (body) {
            var url = path("/copy");
            return client.post(url, body);
        },
        fetchAttachments: function (cardId) {
            var url = path("/" + cardId + "/attachments");
            return client.get(url);
        },
        registerAttachments: function (cardId, postData) {
            var url = path("/" + cardId + "/attachments");
            var data = (0, utils_1.convertObjectToFormData)(postData);
            return client.post(url, data);
        },
    };
};
exports.default = cards;
