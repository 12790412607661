"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateAddress = void 0;
var constants_1 = require("~/utils/constants");
/**
 * Function to validate input for address and building at once.
 * @param targetPath field path to be validated on change.
 * @param oppositePath filed path of opposite one, if target is address, this param is building.
 */
var validateAddress = function (targetPath, oppositePath) {
    return function (_a) {
        var getFieldValue = _a.getFieldValue, getFieldError = _a.getFieldError, validateFields = _a.validateFields, isFieldValidating = _a.isFieldValidating;
        return ({
            validator: function (_rule, _value) { return __awaiter(void 0, void 0, void 0, function () {
                var targetValue, oppositeValue, errorMessage, buildingError;
                return __generator(this, function (_a) {
                    targetValue = getFieldValue(targetPath);
                    oppositeValue = getFieldValue(oppositePath);
                    errorMessage = validateAddressInput(targetValue, oppositeValue);
                    buildingError = getFieldError(oppositePath);
                    if (errorMessage) {
                        // NOTE(Chikama): to avoid showing message twice, if already error exists on other side, return empty message.
                        throw new Error(buildingError.includes(errorMessage) ? "" : errorMessage);
                    }
                    if (!isFieldValidating(oppositePath)) {
                        validateFields([oppositePath])
                            .catch(function (error) { return error.errorFields[0].errors[0]; });
                    }
                    return [2 /*return*/, Promise.resolve()];
                });
            }); },
        });
    };
};
exports.validateAddress = validateAddress;
var validateAddressInput = function (targetFieldText, oppositeFieldText) {
    if (!constants_1.RESTRICT_SPACE_REGEX.test(targetFieldText)) {
        return constants_1.ErrorMessages.validation.regex.space;
    }
    var totalTextLength = ((targetFieldText !== null && targetFieldText !== void 0 ? targetFieldText : "") + (oppositeFieldText !== null && oppositeFieldText !== void 0 ? oppositeFieldText : "")).length;
    if (totalTextLength > 100) {
        return "建物名を合わせて" + constants_1.ErrorMessages.validation.length.max100;
    }
    return null;
};
