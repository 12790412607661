"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFetchSharedEmailSettingAPIQuery = void 0;
var AlertMessage_1 = require("~/components/Common/AlertMessage/AlertMessage");
var api_1 = require("~/networking/api");
var constants_1 = require("~/utils/constants");
var useAuthorizedQuery_1 = require("../useAuthorizedQuery");
var converters_1 = require("./converters");
var useFetchSharedEmailSettingAPIQuery = function (_a) {
    var deps = _a.deps, options = _a.options;
    return (0, useAuthorizedQuery_1.useAuthorizedQuery)({
        queryKey: constants_1.QueryKeys.sharedEmailSetting.detail,
        deps: deps,
        options: __assign(__assign({ onError: function (err) {
                var _a;
                var errorMessage = (_a = err.response) === null || _a === void 0 ? void 0 : _a.data.detail;
                if (errorMessage) {
                    (0, AlertMessage_1.customErrorMessage)(errorMessage);
                }
            } }, options), { select: function (result) {
                return (0, converters_1.convertSharedEmailSettingResponseModelToSharedEmailSettingModel)(result);
            } }),
        apiRequest: api_1.sharedEmailSettingAPI.fetchSharedEmailSetting,
    });
};
exports.useFetchSharedEmailSettingAPIQuery = useFetchSharedEmailSettingAPIQuery;
