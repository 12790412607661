// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PasswordChangeForm-container-zAxB2{max-width:300px}.PasswordChangeForm-button-ZOfM7{margin:32px auto;width:100%}.PasswordChangeForm-alert-aGQzK{margin-top:16px;margin-bottom:16px}.PasswordChangeForm-validationAlign-HYV7P{text-align:left}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/PasswordChangePage/PasswordChangeForm/PasswordChangeForm.scss"],"names":[],"mappings":"AAAA,oCACI,eAAgB,CACnB,iCAGG,gBAAiB,CACjB,UAAW,CACd,gCAGG,eAAgB,CAChB,kBAAmB,CACtB,0CAGG,eAAgB","sourcesContent":[".container {\n    max-width: 300px;\n}\n\n.button {\n    margin: 32px auto;\n    width: 100%;\n}\n\n.alert {\n    margin-top: 16px;\n    margin-bottom: 16px;\n}\n\n.validationAlign {\n    text-align: left;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PasswordChangeForm-container-zAxB2`,
	"button": `PasswordChangeForm-button-ZOfM7`,
	"alert": `PasswordChangeForm-alert-aGQzK`,
	"validationAlign": `PasswordChangeForm-validationAlign-HYV7P`
};
export default ___CSS_LOADER_EXPORT___;
