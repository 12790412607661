// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PersonnelPriorityFormItem-container-LYtms{width:100%;text-align:left}.PersonnelPriorityFormItem-userInput-fHHmv{width:100%}.PersonnelPriorityFormItem-tooltip-kl2WZ{margin-left:5px}.PersonnelPriorityFormItem-infoIcon-cmdn0{align-self:center}.PersonnelPriorityFormItem-select_option-HNAFX{text-align:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/personnel/PersonnelBoardPage/PersonnelBoardSearchDrawer/PersonnelPriorityFormItem/PersonnelPriorityFormItem.scss"],"names":[],"mappings":"AAAA,2CACI,UAAW,CACX,eAAgB,CACnB,2CAGG,UAAW,CACd,yCAGG,eAAgB,CACnB,0CAGG,iBAAkB,CACrB,+CAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n\n.select_option {\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PersonnelPriorityFormItem-container-LYtms`,
	"userInput": `PersonnelPriorityFormItem-userInput-fHHmv`,
	"tooltip": `PersonnelPriorityFormItem-tooltip-kl2WZ`,
	"infoIcon": `PersonnelPriorityFormItem-infoIcon-cmdn0`,
	"select_option": `PersonnelPriorityFormItem-select_option-HNAFX`
};
export default ___CSS_LOADER_EXPORT___;
