// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CommentPinButton-controlButton-YbsYh{margin-right:5px !important}.CommentPinButton-colorFocus-ddCh9{margin-right:5px}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/DataDisplay/CommentList/CommentPinButton/CommentPinButton.scss"],"names":[],"mappings":"AAAA,sCACI,2BAA4B,CAC/B,mCAEG,gBAAiB","sourcesContent":[".controlButton {\n    margin-right: 5px !important;\n}\n.colorFocus{\n    margin-right: 5px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"controlButton": `CommentPinButton-controlButton-YbsYh`,
	"colorFocus": `CommentPinButton-colorFocus-ddCh9`
};
export default ___CSS_LOADER_EXPORT___;
