// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PasswordChangePage-container-WMiQZ{margin:auto}.PasswordChangePage-pageHeader-K11XC{padding:16px 0 0 0;text-align:left}.PasswordChangePage-pageBody-g4l34{padding:16px 0 0 0}.PasswordChangePage-buttonWrapper-acOc2{text-align:center;margin:auto;display:flex;justify-content:center}.PasswordChangePage-button-seKre{margin:16px 32px;width:128px}.PasswordChangePage-registerPageButtonWrapper-CPXHf{text-align:left;margin:16px auto}.PasswordChangePage-downloadButtonWrapper-piflu{text-align:right}.PasswordChangePage-search_template-cPREs{margin:8px auto;text-align:left}.PasswordChangePage-tableControlButton-UiAmR{margin-right:5px}.PasswordChangePage-tableSettingButton-Y_pVR{margin-left:1%}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/PasswordChangePage/PasswordChangePage.scss"],"names":[],"mappings":"AAAA,oCACI,WAAY,CACf,qCAGG,kBAAmB,CACnB,eAAgB,CACnB,mCAGG,kBAAmB,CACtB,wCAGG,iBAAkB,CAClB,WAAY,CACZ,YAAa,CACb,sBAAuB,CAC1B,iCAGG,gBAAiB,CACjB,WAAY,CACf,oDAGG,eAAgB,CAChB,gBAAiB,CACpB,gDAGG,gBAAiB,CACpB,0CAGG,eAAgB,CAChB,eAAgB,CACnB,6CAGG,gBAAiB,CACpB,6CAGG,cAAe","sourcesContent":[".container {\n    margin: auto;\n}\n\n.pageHeader {\n    padding: 16px 0 0 0;\n    text-align: left;\n}\n\n.pageBody {\n    padding: 16px 0 0 0;\n}\n\n.buttonWrapper {\n    text-align: center;\n    margin: auto;\n    display: flex;\n    justify-content: center;\n}\n\n.button {\n    margin: 16px 32px;\n    width: 128px;\n}\n\n.registerPageButtonWrapper {\n    text-align: left;\n    margin: 16px auto;\n}\n\n.downloadButtonWrapper {\n    text-align: right;\n}\n\n.search_template {\n    margin: 8px auto;\n    text-align: left;\n}\n\n.tableControlButton {\n    margin-right: 5px;\n}\n\n.tableSettingButton {\n    margin-left: 1%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PasswordChangePage-container-WMiQZ`,
	"pageHeader": `PasswordChangePage-pageHeader-K11XC`,
	"pageBody": `PasswordChangePage-pageBody-g4l34`,
	"buttonWrapper": `PasswordChangePage-buttonWrapper-acOc2`,
	"button": `PasswordChangePage-button-seKre`,
	"registerPageButtonWrapper": `PasswordChangePage-registerPageButtonWrapper-CPXHf`,
	"downloadButtonWrapper": `PasswordChangePage-downloadButtonWrapper-piflu`,
	"search_template": `PasswordChangePage-search_template-cPREs`,
	"tableControlButton": `PasswordChangePage-tableControlButton-UiAmR`,
	"tableSettingButton": `PasswordChangePage-tableSettingButton-Y_pVR`
};
export default ___CSS_LOADER_EXPORT___;
