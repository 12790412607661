export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const LOGGING_OUT = "LOGGING_OUT"
export const LOGIN_ERROR = "LOGIN_ERROR";
export const UPDATE_AVATAR_AFTER_CHANGE_MYPROFILE = "UPDATE_AVATAR_AFTER_CHANGE_MYPROFILE";


export const LOADING = "__LOADING";
export const SEARCH_LOADING = "__SEARCH_LOADING";
export const LOADED = "__LOADED";
export const CANCELED = "__CANCELED";
export const LOAD_LAST_PAGE = "__LOAD_LAST_PAGE";

export const USE_CACHE = "__USE_CACHE";
export const SEARCH_COMPLETED = "__SEARCH_COMPLETED";
export const UPDATE_CURRENT_PAGE = "__UPDATE_CURRENT_PAGE";

export const RESET_ROWS = "__RESET_ROWS";
export const SELECT_ROWS = "__SELECT_ROWS";

export const RESET_DATA = "__RESET_DATA";

export const UPDATING = "__UPDATING";
export const UPDATED = "__UPDATED";
export const BULK_UPDATED = "__BULK_UPDATED";

export const COMMITTED = "__COMMITTED"; // for realtime draft saving.

export const SYNC_FORM_STATE_TO_PAGE = "__SYNC_FORM_STATE_TO_PAGE";

export const CREATING = "__CREATING";
export const CREATED = "__CREATED";
export const COPIED = "__COPIED";
export const REDIRECT = "__REDIRECT";

export const INVITED = "__INVITED";
export const REGISTED = "__REGISTED";

export const CLEAR = "__CLEAR";
export const CLEAR_SEARCH_FORM = "__CLEAR_SEARCH_FORM";
export const CLEAR_ERROR = "__CLEAR_ERROR";
export const CLEAR_SUCCESS = "__CLEAR_SUCCESS";

export const DELETING = "__DELETING";
export const DELETED = "__DELETED";

export const ERROR = "__ERROR";
export const VALIDATION_ERROR = "__VALIDATION_ERROR";
export const RESET_ERROR = "__RESET_ERROR";

export const DISPLAY_SETTING_LOADING = "__DISPLAY_SETTING_LOADING";
export const DISPLAY_SETTING_LOADED = "__DISPLAY_SETTING_LOADED";
export const DISPLAY_SETTING_UPDATED = "__DISPLAY_SETTING_UPDATED";

export const TAG_CREATING = "__TAG_CREATING";
export const TAG_CREATED = "__TAG_CREATED";
export const TAG_COMMITTED = "__TAG_COMMITTED";

export const COMMENT_TEMPLATE_LOADING = "__COMMENT_TEMPLATE_LOADING";
export const COMMENT_TEMPLATE_LOADED = "__COMMENT_TEMPLATE_LOADED";
export const COMMENT_TEMPLATE_CREATING = "__COMMENT_TEMPLATE_CREATING";
export const COMMENT_TEMPLATE_CREATED = "__COMMENT_TEMPLATE_CREATED";
export const COMMENT_TEMPLATE_COMMITTED = "__COMMENT_TEMPLATE_COMMITTED";
export const COLUMN_SETTING_CREATING = "__COLUMN_SETTING_CREATING";
export const COLUMN_SETTING_CREATED = "__COLUMN_SETTING_CREATED";
export const COLUMN_SETTING_COMMITTED = "__COLUMN_SETTING_COMMITTED";
export const COMMENT_NEW_CLEAR = "__COMMENT_NEW_CLEAR";
export const WRITING_NEW_COMMENTS = "__WRITING_NEW_COMMENTS";
export const PARENT_CANNOT_SUBMIT_WHEN_NEW_COMMENT_EXISTS =
    "__PARENT_CANNOT_SUBMIT_WHEN_NEW_COMMENT_EXISTS";
export const COMMENT_EDIT_CLEAR = "__COMMENT_EDIT_CLEAR";
export const WRITING_EDIT_COMMENTS = "__WRITING_EDIT_COMMENTS";
export const WRITING_REPLY_COMMENTS = "__WRITING_REPLY_COMMENTS";
export const PARENT_CANNOT_SUBMIT_WHEN_EDIT_COMMENT_EXISTS =
    "__PARENT_CANNOT_SUBMIT_WHEN_EDIT_COMMENT_EXISTS";
export const PARENT_CANNOT_SUBMIT_WHEN_REPLY_COMMENT_EXISTS =
    "__PARENT_CANNOT_SUBMIT_WHEN_REPLY_COMMENT_EXISTS";

export const SEARCH_TEMPLATE_LOADING = "__SEARCH_TEMPLATE_LOADING";
export const SEARCH_TEMPLATE_LOADED = "__SEARCH_TEMPLATE_LOADED";
export const SEARCH_TEMPLATE_UPDATING = "__SEARCH_TEMPLATE_UPDATING";
export const SEARCH_TEMPLATE_UPDATED = "__SEARCH_TEMPLATE_UPDATED";
export const SEARCH_TEMPLATE_SELECTED = "__SEARCH_TEMPLATE_SELECTED";
export const SEARCH_TEMPLATE_DELETING = "__SEARCH_TEMPLATE_DELETING";
export const SEARCH_TEMPLATE_DELETED = "__SEARCH_TEMPLATE_DELETED";
export const SEARCH_TEMPLATE_CREATING = "__SEARCH_TEMPLATE_CREATING";
export const SEARCH_TEMPLATE_CREATED = "__SEARCH_TEMPLATE_CREATED";
export const SEARCH_TEMPLATE_COMMITTED = "__SEARCH_TEMPLATE_COMMITTED";
export const SCHEDULED_TEMPLATE_LOADING = "__SCHEDULED_TEMPLATE_LOADING";
export const SCHEDULED_TEMPLATE_LOADED = "__SCHEDULED_TEMPLATE_LOADED";
export const SCHEDULE_TEMPLATE_CREATING = "__SCHEDULE_TEMPLATE_CREATING";
export const SCHEDULE_TEMPLATE_CREATED = "__SCHEDULE_TEMPLATE_CREATED";
export const SCHEDULE_TEMPLATE_COMMITTED = "__SCHEDULE_TEMPLATE_COMMITTED";
export const SEARCH_TEMPLATE_COMMITTED_FINISH =
    "__SEARCH_TEMPLATE_COMMITTED_FINISH";
export const CHANGE_SEARCH_TEMPLATE = "__CHANGE_SEARCH_TEMPLATE";
export const CHANGED_SEARCH_TEMPLATE = "__CHANGED_SEARCH_TEMPLATE";
export const FIX_CURRENT_SEARCH_CONDITIONS = "__FIX_CURRENT_SEARCH_CONDITIONS"

export const SELECTED_COLUMN_REFLESHED = "__SELECTED_COLUMN_REFLESHED";

export const SYNC_COLUMN_KEYS = "__SYNC_COLUMN_KEYS";

export const RESERVED_DATE_LOADING = "__RESERVED_DATE_LOADING";
export const RESERVED_DATE_LOADED = "__RESERVED_DATE_LOADED";

export const SEARCH_MENU_OPEN = "__SEARCH_MENU_OPEN";
export const SET_DEFAULT_TEMPLATE = "__SET_DEFAULT_TEMPLATE";
export const RESET_CURRENT_TEMPLATES = "__RESET_CURRENT_TEMPLATES";
export const RESET_MESSAGE = "__RESET_MESSAGE";
export const RESET_ERROR_MESSAGE = "__RESET_ERROR_MESSAGE";

export const AUTHORIZED_ACTION_LOADING = "__AUTHORIZED_ACTION_LOADING";
export const AUTHORIZED_ACTION_LOADED = "__AUTHORIZED_ACTION_LOADED";

export const SHARED_EMAIL_SETTING_LOADING = "__SHARED_EMAIL_SETTING_LOADING";
export const SHARED_EMAIL_SETTING_LOADED = "__SHARED_EMAIL_SETTING_LOADED";

export const SHARED_EMAIL_CONNECTION_LOADING =
    "__SHARED_EMAIL_CONNECTION_LOADING";
export const SHARED_EMAIL_CONNECTION_LOADED =
    "__SHARED_EMAIL_CONNECTION_LOADED";

export const CHECKING_EMAIL_ADDRESS ="__CHECKING_EMAIL_ADDRESS";
export const CHECKED_EMAIL_ADDRESS ="__CHECKED_EMAIL_ADDRESS";
export const CHECK_ERROR_EMAIL_ADDRESS ="__CHECK_ERROR_EMAIL_ADDRESS";
export const CHECK_CLEAR_EMAIL_ADDRESS ="__CHECK_CLEAR_EMAIL_ADDRESS";

export const VALIDATED_TOKEN = "__VALIDATED_TOKEN";
export const INVALID_TOKEN = "__INVALID_TOKEN";

// Scheduled Emails
export const SCHEDULED_EMAIL_FAILURE_LIST = "__SCHEDULED_EMAIL_FAILURE_LIST";

// System Notifications
export const SYSTEM_NOTIFICATIONS = "__SYSTEM_NOTIFICATIONS";
export const ACTION_NOTIFICATIONS = "__ACTION_NOTIFICATIONS";
export const UNREAD = "__UNREAD";
export const RESET_COUNT = "__RESET_COUNT";

// Payment
export const PAYMENT_FETCH = "__PAYMENT_FETCH";
export const PAYMENT_FORM = "__PAYMENT_FORM";
export const PAYMENT_DELETE = "__PAYMENT_DELETE";
export const PAYMENT_UPDATE = "__PAYMENT_UPDATE";

// Plan
export const PLAN_FETCHING = "PLAN_FETCHING";
export const PLAN_FETCHED = "PLAN_FETCHED";

// Contact
export const CONTACT_FORM = "__CONTACT_FORM";

export const UPDATE_SORT = "__UPDATE_SORT"

// CheckList
export const CHECKLIST_TEMPLATE_LOADED = "__CHECKLIST_TEMPLATE_LOADED";
export const CHECKLIST_TEMPLATE_LOADING = "__CHECKLIST_TEMPLATE_LOADING";
export const CHECKLIST_TEMPLATE_CREATING = "__CHECKLIST_TEMPLATE_CREATING";
export const CHECKLIST_TEMPLATE_CREATED = "__CHECKLIST_TEMPLATE_CREATED";
export const CHECKLIST_TEMPLATE_COMMITTED = "__CHECKLIST_TEMPLATE_COMMITTED";

// Email Validation
export const UPDATE_TIME = "__UPDATE_TIME";
export const CLEAR_TIME = "__CLEAR_TIME";
