import React from "react";
import { Col, Form, Select, Row, Tooltip } from "antd";
import styles from "./CorporateHasDistributionFormItem.scss";
import { BranchesOutlined } from "@ant-design/icons";
import { iconPrimaryColor } from "~/utils/constants";

const CorporateHasDistributionFormItem = ({ disabled = false }) => {
    const items = [
        {
            value: true,
            title: "商流を抜ける",
            icon: <BranchesOutlined style={{ color: iconPrimaryColor }} />,
        },
        {
            value: false,
            title: "商流を抜けない",
        },
    ];

    return (
        <Col span={24} style={{ marginBottom: "1%" }}>
            <Row className={styles.container}>
                <Col span={23}>
                    <Form.Item colon={false} name="has_distribution" noStyle>
                        <Select
                            showSearch={false}
                            className={styles.container}
                            placeholder="商流"
                            allowClear
                            disabled={disabled}
                            getPopupContainer={(trigger) => trigger.parentNode}>
                            {items.map((item) => {
                                return (
                                    <Select.Option
                                        key={item.value}
                                        className={styles.select_option}
                                        value={item.value}>
                                        {item.icon} {item.title}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        </Col>
    );
};

export default CorporateHasDistributionFormItem;
