// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BackOfficeStartDateFormItem-container-AsuDC{width:100%;text-align:left}.BackOfficeStartDateFormItem-userInput-hcVoM{width:100%}.BackOfficeStartDateFormItem-tooltip-KNHGX{margin-left:5px}.BackOfficeStartDateFormItem-infoIcon-gjt5i{align-self:center}.BackOfficeStartDateFormItem-customDatePicker-V3iP0{width:100%;height:35px}.BackOfficeStartDateFormItem-marginBottom-PIQoC{margin-bottom:1%}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/backOffice/BackOfficeGanttPage/BackOfficeGanttSearchDrawer/BackOfficeStartDateFormItem/BackOfficeStartDateFormItem.scss"],"names":[],"mappings":"AAAA,6CACE,UAAW,CACX,eAAgB,CACjB,6CAGC,UAAW,CACZ,2CAGC,eAAgB,CACjB,4CAGC,iBAAkB,CACnB,oDAGC,UAAW,CACX,WAAY,CACb,gDAGC,gBAAiB","sourcesContent":[".container {\n  width: 100%;\n  text-align: left;\n}\n\n.userInput {\n  width: 100%;\n}\n\n.tooltip {\n  margin-left: 5px;\n}\n\n.infoIcon {\n  align-self: center;\n}\n\n.customDatePicker {\n  width: 100%;\n  height: 35px;\n}\n\n.marginBottom {\n  margin-bottom: 1%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `BackOfficeStartDateFormItem-container-AsuDC`,
	"userInput": `BackOfficeStartDateFormItem-userInput-hcVoM`,
	"tooltip": `BackOfficeStartDateFormItem-tooltip-KNHGX`,
	"infoIcon": `BackOfficeStartDateFormItem-infoIcon-gjt5i`,
	"customDatePicker": `BackOfficeStartDateFormItem-customDatePicker-V3iP0`,
	"marginBottom": `BackOfficeStartDateFormItem-marginBottom-PIQoC`
};
export default ___CSS_LOADER_EXPORT___;
