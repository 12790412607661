"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var api_1 = require("~/domain/api");
var utils_1 = require("~/utils/utils");
var tag = function (client) {
    return {
        fetchTags: function (query) {
            var queryString = (0, utils_1.createQueryString)(query);
            var url = api_1.Endpoint.tags + "?" + queryString;
            return client.get(url);
        },
        bulkDelete: function (postData) {
            var url = api_1.Endpoint.tags;
            return client.delete(url, { data: postData });
        },
        fetchTag: function (id) {
            var url = api_1.Endpoint.tags + "/" + id;
            return client.get(url);
        },
        create: function (postData) {
            var url = api_1.Endpoint.tags;
            var data = (0, utils_1.convertCamelObjectToSnakeObject)(postData);
            return client.post(url, data);
        },
        update: function (_a) {
            var id = _a.id, postData = _a.postData;
            var url = api_1.Endpoint.tags + "/" + id;
            var data = (0, utils_1.convertCamelObjectToSnakeObject)(postData);
            return client.patch(url, data);
        },
        delete: function (id) {
            var url = api_1.Endpoint.tags + "/" + id;
            return client.delete(url);
        },
        simple: function (query) {
            var url = api_1.Endpoint.tag.simple;
            if (query) {
                url += "?" + (0, utils_1.createQueryString)(query);
            }
            return client.get(url);
        },
        bulkUpdate: function (postData) {
            var url = api_1.Endpoint.tags;
            var data = (0, utils_1.convertCamelObjectToSnakeObject)(postData);
            return client.patch(url, data);
        },
    };
};
exports.default = tag;
