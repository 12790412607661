// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BackOfficeBoardCardContent-text-MVEwA{display:flex;justify-content:start;padding:10px 0 0 0;font-weight:550}.BackOfficeBoardCardContent-textLabel-Qpvbx{display:inline-block;width:3em;text-align:right}.BackOfficeBoardCardContent-tag-MEqZ7{margin-bottom:6px}.BackOfficeBoardCardContent-tagRow-VFzJ1{margin:0.5rem 0}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/backOffice/BackOfficeBoardPage/BackOfficeBoardCard/BackOfficeBoardCardContent/BackOfficeBoardCardContent.scss"],"names":[],"mappings":"AAAA,uCACI,YAAa,CACb,qBAAsB,CACtB,kBAAmB,CACnB,eAAgB,CACnB,4CAGG,oBAAqB,CACrB,SAAU,CACV,gBAAiB,CACpB,sCAGG,iBAAkB,CACrB,yCAGG,eAAgB","sourcesContent":[".text {\n    display: flex;\n    justify-content: start;\n    padding: 10px 0 0 0;\n    font-weight: 550;\n}\n\n.textLabel {\n    display: inline-block;\n    width: 3em;\n    text-align: right;\n}\n\n.tag {\n    margin-bottom: 6px;\n}\n\n.tagRow {\n    margin: 0.5rem 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": `BackOfficeBoardCardContent-text-MVEwA`,
	"textLabel": `BackOfficeBoardCardContent-textLabel-Qpvbx`,
	"tag": `BackOfficeBoardCardContent-tag-MEqZ7`,
	"tagRow": `BackOfficeBoardCardContent-tagRow-VFzJ1`
};
export default ___CSS_LOADER_EXPORT___;
