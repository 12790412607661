// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EmailSearchSenderFormItem-container-OTCJm{width:100%;text-align:left}.EmailSearchSenderFormItem-userInput-ZdybH{width:50%}.EmailSearchSenderFormItem-tooltip-b6cp2{margin-left:5px}.EmailSearchSenderFormItem-infoIcon-YFkXr{align-self:center}.EmailSearchSenderFormItem-marginBottom-aHeOE{margin-bottom:1%}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/SharedEmailSearchPage/SharedEmailSearchDrawer/EmailSearchSenderFormItem/EmailSearchSenderFormItem.scss"],"names":[],"mappings":"AAAA,2CACI,UAAW,CACX,eAAgB,CACnB,2CAGG,SAAU,CACb,yCAGG,eAAgB,CACnB,0CAGG,iBAAkB,CACrB,8CAGG,gBAAiB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 50%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n\n.marginBottom {\n    margin-bottom: 1%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `EmailSearchSenderFormItem-container-OTCJm`,
	"userInput": `EmailSearchSenderFormItem-userInput-ZdybH`,
	"tooltip": `EmailSearchSenderFormItem-tooltip-b6cp2`,
	"infoIcon": `EmailSearchSenderFormItem-infoIcon-YFkXr`,
	"marginBottom": `EmailSearchSenderFormItem-marginBottom-aHeOE`
};
export default ___CSS_LOADER_EXPORT___;
