"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBackOfficeBoardMutateListAPIMutation = exports.useDeleteBackOfficeCardBoard = exports.useDuplicateBackOfficeCardBoard = exports.useSearchScheduledEmail = exports.useBackOfficeBoardDetailCard = exports.useBackOfficeBoardFetchSubCommentsAPIQuery = exports.useBackOfficeBoardDeleteCommentAPIMutation = exports.useBackOfficeBoardUpdateCommentAPIMutation = exports.useBackOfficeBoardFetchCommentAPIQuery = exports.useBackOfficeBoardCreateCommentAPIMutation = exports.useBackOfficeBoardFetchCommentsAPIQuery = exports.useBackOfficeBoardFetchChecklistItemsAPIQuery = exports.useBackOfficeBoardChangeCardPositionAPIMutation = exports.useBackOfficeBoardDeleteCardAPIMutation = exports.useBackOfficeBoardDuplicateCardAPIMutation = exports.useBackOfficeBoardFetchListCardsAPIQuery = exports.useBackOfficeBoardFetchListsAPIQuery = void 0;
var react_1 = require("react");
var recoil_1 = require("recoil");
var AlertMessage_1 = require("~/components/Common/AlertMessage/AlertMessage");
var api_1 = require("~/networking/api");
var atom_1 = require("~/recoil/atom");
var constants_1 = require("~/utils/constants");
var board_1 = require("./backOffice/board");
var useCustomMutation_1 = require("./useCustomMutation");
var useCustomQuery_1 = require("./useCustomQuery");
var useScheduledEmail_1 = require("./useScheduledEmail");
var defaultDataCardDetail = {
    id: "",
    order: 0,
    listId: "",
    assignees: [],
    priority: {
        title: "中",
        value: "urgent",
    },
    period: {
        end: undefined,
        start: undefined,
        isFinished: false,
    },
    detail: "",
    tags: [],
    description: "",
    // dynamicRows: [],
    checklist: [],
    comments: [],
    attachments: [],
    isArchived: false,
};
var useBackOfficeBoardFetchListsAPIQuery = function (_a) {
    var deps = _a.deps, options = _a.options;
    return (0, useCustomQuery_1.useCustomQuery)({
        queryKey: constants_1.QueryKeys.backOfficeBoard.lists,
        deps: deps,
        options: __assign({}, options),
        apiRequest: api_1.backOfficeAPI.board.fetchLists,
    });
};
exports.useBackOfficeBoardFetchListsAPIQuery = useBackOfficeBoardFetchListsAPIQuery;
var useBackOfficeBoardFetchListCardsAPIQuery = function (_a) {
    var deps = _a.deps, options = _a.options;
    return (0, useCustomQuery_1.useCustomQuery)({
        queryKey: constants_1.QueryKeys.backOfficeBoard.cards,
        deps: deps,
        options: __assign({}, options),
        apiRequest: function (query) {
            return api_1.backOfficeAPI.board.fetchListCards(query === null || query === void 0 ? void 0 : query.listId);
        },
    });
};
exports.useBackOfficeBoardFetchListCardsAPIQuery = useBackOfficeBoardFetchListCardsAPIQuery;
var useBackOfficeBoardDuplicateCardAPIMutation = function () {
    var apiRequest = function (postData) {
        return api_1.backOfficeAPI.board.duplicateCard(postData);
    };
    return (0, useCustomMutation_1.useCustomMutation)(apiRequest, {
        onSuccess: function () {
            (0, AlertMessage_1.customSuccessMessage)(constants_1.SuccessMessages.generic.copy);
        },
        onError: function () {
            (0, AlertMessage_1.customErrorMessage)(constants_1.ErrorMessages.generic.copy);
        },
    });
};
exports.useBackOfficeBoardDuplicateCardAPIMutation = useBackOfficeBoardDuplicateCardAPIMutation;
var useBackOfficeBoardDeleteCardAPIMutation = function () {
    return (0, useCustomMutation_1.useCustomMutation)(api_1.backOfficeAPI.board.deleteCard, {
        onSuccess: function (response) {
            (0, AlertMessage_1.customSuccessMessage)(constants_1.SuccessMessages.generic.delete);
        },
        onError: function (err) {
            (0, AlertMessage_1.customErrorMessage)(constants_1.ErrorMessages.generic.delete);
        },
    });
};
exports.useBackOfficeBoardDeleteCardAPIMutation = useBackOfficeBoardDeleteCardAPIMutation;
var useBackOfficeBoardChangeCardPositionAPIMutation = function () {
    var apiRequest = function (_a) {
        var cardId = _a.cardId, postData = _a.postData;
        return api_1.backOfficeAPI.board.changeCardPosition(cardId, postData);
    };
    return (0, useCustomMutation_1.useCustomMutation)(apiRequest, {
        onSuccess: function (response) { },
        onError: function (err) { },
    });
};
exports.useBackOfficeBoardChangeCardPositionAPIMutation = useBackOfficeBoardChangeCardPositionAPIMutation;
var useBackOfficeBoardFetchChecklistItemsAPIQuery = function (_a) {
    var deps = _a.deps, options = _a.options;
    return (0, useCustomQuery_1.useCustomQuery)({
        queryKey: constants_1.QueryKeys.backOfficeBoard.checklistItems,
        deps: deps,
        options: __assign({}, options),
        apiRequest: function (query) {
            return api_1.backOfficeAPI.board.fetchChecklistItems(query === null || query === void 0 ? void 0 : query.checklistId);
        },
    });
};
exports.useBackOfficeBoardFetchChecklistItemsAPIQuery = useBackOfficeBoardFetchChecklistItemsAPIQuery;
var useBackOfficeBoardFetchCommentsAPIQuery = function (_a) {
    var deps = _a.deps, options = _a.options;
    return (0, useCustomQuery_1.useCustomQuery)({
        queryKey: constants_1.QueryKeys.backOfficeBoard.comments,
        deps: deps,
        options: __assign({}, options),
        apiRequest: function (query) { return api_1.backOfficeAPI.board.fetchComments(query === null || query === void 0 ? void 0 : query.cardId); },
    });
};
exports.useBackOfficeBoardFetchCommentsAPIQuery = useBackOfficeBoardFetchCommentsAPIQuery;
var useBackOfficeBoardCreateCommentAPIMutation = function () {
    return (0, useCustomMutation_1.useCustomMutation)(api_1.backOfficeAPI.board.createComment, {
        onSuccess: function (response) { },
        onError: function (err) { },
    });
};
exports.useBackOfficeBoardCreateCommentAPIMutation = useBackOfficeBoardCreateCommentAPIMutation;
var useBackOfficeBoardFetchCommentAPIQuery = function (_a) {
    var deps = _a.deps, options = _a.options;
    return (0, useCustomQuery_1.useCustomQuery)({
        queryKey: constants_1.QueryKeys.backOfficeBoard.comment,
        deps: deps,
        options: __assign({}, options),
        apiRequest: function (query) {
            return api_1.backOfficeAPI.board.fetchComment(query === null || query === void 0 ? void 0 : query.commentId);
        },
    });
};
exports.useBackOfficeBoardFetchCommentAPIQuery = useBackOfficeBoardFetchCommentAPIQuery;
var useBackOfficeBoardUpdateCommentAPIMutation = function () {
    var apiRequest = function (_a) {
        var commentId = _a.commentId, postData = _a.postData;
        return api_1.backOfficeAPI.board.updateComment(commentId, postData);
    };
    return (0, useCustomMutation_1.useCustomMutation)(apiRequest, {
        onSuccess: function (response) { },
        onError: function (err) { },
    });
};
exports.useBackOfficeBoardUpdateCommentAPIMutation = useBackOfficeBoardUpdateCommentAPIMutation;
var useBackOfficeBoardDeleteCommentAPIMutation = function () {
    return (0, useCustomMutation_1.useCustomMutation)(api_1.backOfficeAPI.board.deleteComment, {
        onSuccess: function (response) { },
        onError: function (err) { },
    });
};
exports.useBackOfficeBoardDeleteCommentAPIMutation = useBackOfficeBoardDeleteCommentAPIMutation;
var useBackOfficeBoardFetchSubCommentsAPIQuery = function (_a) {
    var deps = _a.deps, options = _a.options;
    return (0, useCustomQuery_1.useCustomQuery)({
        queryKey: constants_1.QueryKeys.backOfficeBoard.subComments,
        deps: deps,
        options: __assign({}, options),
        apiRequest: function (query) {
            return api_1.backOfficeAPI.board.fetchSubComments(query === null || query === void 0 ? void 0 : query.commentId);
        },
    });
};
exports.useBackOfficeBoardFetchSubCommentsAPIQuery = useBackOfficeBoardFetchSubCommentsAPIQuery;
var useBackOfficeBoardDetailCard = function (cardId) {
    var _a = (0, react_1.useState)({
        cardId: cardId,
    }), deps = _a[0], setDeps = _a[1];
    var _b = (0, recoil_1.useRecoilState)(atom_1.backOfficeBoard), backOfficeBoardState = _b[0], setBackOfficeBoardState = _b[1];
    var _c = (0, board_1.useBackOfficeBoardFetchCardAPIQuery)({
        deps: deps,
        options: {
            enabled: !!deps.cardId,
        },
    }), isLoading = _c.isLoading, isSuccess = _c.isSuccess, dataDetail = _c.data, refetchFetchCard = _c.refetch;
    var updateCardOrderMutate = (0, board_1.useBackOfficeBoardUpdateCardOrderAPIMutation)().mutate;
    (0, react_1.useEffect)(function () {
        if (deps && deps.cardId) {
            refetchFetchCard();
        }
    }, [deps, deps.cardId]);
    (0, react_1.useEffect)(function () {
        if (backOfficeBoardState && backOfficeBoardState.backOfficeId) {
            setDeps({ cardId: backOfficeBoardState.backOfficeId });
        }
    }, [backOfficeBoardState, backOfficeBoardState.backOfficeId]);
    return {
        isLoading: isLoading,
        isSuccess: isSuccess,
        dataDetail: dataDetail,
        defaultDataCardDetail: defaultDataCardDetail,
        refetchFetchCard: refetchFetchCard,
    };
};
exports.useBackOfficeBoardDetailCard = useBackOfficeBoardDetailCard;
var useSearchScheduledEmail = function (params) {
    var _a = (0, useScheduledEmail_1.useFetchScheduledEmailSearchAPIQuery)({
        deps: {
            params: params,
        },
        options: {
            enabled: !!params,
        },
    }), listsScheduledMails = _a.data, isLoading = _a.isLoading;
    return {
        isLoading: isLoading,
        listsScheduledMails: listsScheduledMails,
    };
};
exports.useSearchScheduledEmail = useSearchScheduledEmail;
var useDuplicateBackOfficeCardBoard = function () {
    var _a = (0, exports.useBackOfficeBoardDuplicateCardAPIMutation)(), dupliateCardMutate = _a.mutate, dupliateCardData = _a.data;
    var duplicateBackOfficeCardBoard = function (cardData) {
        dupliateCardMutate(cardData);
    };
    return {
        duplicateBackOfficeCardBoard: duplicateBackOfficeCardBoard,
        dupliateCardData: dupliateCardData,
    };
};
exports.useDuplicateBackOfficeCardBoard = useDuplicateBackOfficeCardBoard;
var useDeleteBackOfficeCardBoard = function () {
    var _a = (0, exports.useBackOfficeBoardDeleteCardAPIMutation)(), deleteCardMutate = _a.mutate, deleteCardData = _a.data;
    var deleteBackOfficeCardBoard = function (cardId) {
        deleteCardMutate(cardId);
    };
    return {
        deleteBackOfficeCardBoard: deleteBackOfficeCardBoard,
        deleteCardData: deleteCardData,
    };
};
exports.useDeleteBackOfficeCardBoard = useDeleteBackOfficeCardBoard;
var useBackOfficeBoardMutateListAPIMutation = function () {
    return (0, useCustomMutation_1.useCustomMutation)(api_1.backOfficeAPI.board.fetchLists);
};
exports.useBackOfficeBoardMutateListAPIMutation = useBackOfficeBoardMutateListAPIMutation;
