// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProjectListFormItem-container-h3OiY{width:100%;text-align:left}.ProjectListFormItem-userInput-HVGIf{width:100%}.ProjectListFormItem-tooltip-I1aaZ{margin-left:5px}.ProjectListFormItem-infoIcon-tKxjx{align-self:center}.ProjectListFormItem-select_option-BUxet{text-align:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/project/ProjectBoardPage/ProjectBoardSearchDrawer/ProjectListFormItem/ProjectListFormItem.scss"],"names":[],"mappings":"AAAA,qCACI,UAAW,CACX,eAAgB,CACnB,qCAGG,UAAW,CACd,mCAGG,eAAgB,CACnB,oCAGG,iBAAkB,CACrB,yCAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n\n.select_option {\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ProjectListFormItem-container-h3OiY`,
	"userInput": `ProjectListFormItem-userInput-HVGIf`,
	"tooltip": `ProjectListFormItem-tooltip-I1aaZ`,
	"infoIcon": `ProjectListFormItem-infoIcon-tKxjx`,
	"select_option": `ProjectListFormItem-select_option-BUxet`
};
export default ___CSS_LOADER_EXPORT___;
