// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StartupScaleLeftContent-titleForm-9fNnr{font-size:24px !important;font-weight:normal !important;text-align:center}@media screen and (min-width: 1920px){.StartupScaleLeftContent-contentLeft-LsdJw{margin:0 35px}}.StartupScaleLeftContent-contentLeft-LsdJw .StartupScaleLeftContent-alert-dV8VR{text-align:left}.StartupScaleLeftContent-contentLeft-LsdJw .StartupScaleLeftContent-hightLightRed-TPeb3{color:red;background-color:transparent}.StartupScaleLeftContent-contentLeft-LsdJw .StartupScaleLeftContent-formContainer-m2Unn{margin:25px}.StartupScaleLeftContent-contentLeft-LsdJw .StartupScaleLeftContent-formContainer-m2Unn .StartupScaleLeftContent-formField-sdYfl{text-align:left;width:100%}.StartupScaleLeftContent-contentLeft-LsdJw .StartupScaleLeftContent-formContainer-m2Unn .StartupScaleLeftContent-centerItem-Wp7Ff{text-align:center}.StartupScaleLeftContent-contentLeft-LsdJw .StartupScaleLeftContent-formContainer-m2Unn .StartupScaleLeftContent-agreementCheckbox-i0kJJ{color:#A7CF69}.StartupScaleLeftContent-contentLeft-LsdJw .StartupScaleLeftContent-formContainer-m2Unn .StartupScaleLeftContent-agreementCheckbox-i0kJJ:hover{text-decoration:underline}.StartupScaleLeftContent-contentLeft-LsdJw .StartupScaleLeftContent-formContainer-m2Unn .StartupScaleLeftContent-btnRegister-oc4U5{padding:5px 20px}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/StartupRegisterScalePage/StartupScaleLeftContent/StartupScaleLeftContent.scss"],"names":[],"mappings":"AAAA,yCACI,yBAA0B,CAC1B,6BAA8B,CAC9B,iBAAkB,CACrB,sCACD,2CAEQ,aAAc,CA6BrB,CA/BD,gFAKQ,eAAgB,CALxB,wFAQQ,SAAU,CACV,4BAA6B,CATrC,wFAYQ,WAAY,CAZpB,iIAcY,eAAgB,CAChB,UAAW,CAfvB,kIAkBY,iBAAkB,CAlB9B,yIAqBY,aAAc,CArB1B,+IAuBgB,yBAA0B,CAvB1C,mIA4BY,gBAAiB","sourcesContent":[".titleForm {\n    font-size: 24px !important;\n    font-weight: normal !important;\n    text-align: center;\n}\n.contentLeft {\n    @media screen and (min-width: 1920px) {\n        margin: 0 35px;\n    }\n    .alert {\n        text-align: left;\n    }\n    .hightLightRed {\n        color: red;\n        background-color: transparent;\n    }\n    .formContainer {\n        margin: 25px;\n        .formField {\n            text-align: left;\n            width: 100%;\n        }\n        .centerItem {\n            text-align: center;\n        }\n        .agreementCheckbox {\n            color: #A7CF69;\n            &:hover {\n                text-decoration: underline;\n            }\n        }\n\n        .btnRegister {\n            padding: 5px 20px;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleForm": `StartupScaleLeftContent-titleForm-9fNnr`,
	"contentLeft": `StartupScaleLeftContent-contentLeft-LsdJw`,
	"alert": `StartupScaleLeftContent-alert-dV8VR`,
	"hightLightRed": `StartupScaleLeftContent-hightLightRed-TPeb3`,
	"formContainer": `StartupScaleLeftContent-formContainer-m2Unn`,
	"formField": `StartupScaleLeftContent-formField-sdYfl`,
	"centerItem": `StartupScaleLeftContent-centerItem-Wp7Ff`,
	"agreementCheckbox": `StartupScaleLeftContent-agreementCheckbox-i0kJJ`,
	"btnRegister": `StartupScaleLeftContent-btnRegister-oc4U5`
};
export default ___CSS_LOADER_EXPORT___;
