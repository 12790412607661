// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RecruitAssigneeFormItem-container-VdRNp{width:100%;text-align:left}.RecruitAssigneeFormItem-userInput-WlTqu{width:100%}.RecruitAssigneeFormItem-tooltip-fNuRo{margin-left:5px}.RecruitAssigneeFormItem-infoIcon-w0Fp9{align-self:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/recruit/RecruitGanttPage/RecruitGanttSearchDrawer/RecruitAssigneeFormItem/RecruitAssigneeFormItem.scss"],"names":[],"mappings":"AAAA,yCACI,UAAW,CACX,eAAgB,CACnB,yCAGG,UAAW,CACd,uCAGG,eAAgB,CACnB,wCAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `RecruitAssigneeFormItem-container-VdRNp`,
	"userInput": `RecruitAssigneeFormItem-userInput-WlTqu`,
	"tooltip": `RecruitAssigneeFormItem-tooltip-fNuRo`,
	"infoIcon": `RecruitAssigneeFormItem-infoIcon-w0Fp9`
};
export default ___CSS_LOADER_EXPORT___;
