// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PerGIncludeArchiveSwitchFromItem-container-qQZ_h{width:100%;text-align:left}.PerGIncludeArchiveSwitchFromItem-userInput-QuS2b{width:100%}.PerGIncludeArchiveSwitchFromItem-tooltip-JTrGG{margin-left:5px}.PerGIncludeArchiveSwitchFromItem-infoIcon-JX42C{align-self:center}.PerGIncludeArchiveSwitchFromItem-switchLabel-Eg8ra{display:inline-block;align-self:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/personnel/PersonnelGanttPage/PersonnelGanttSearchDrawer/PerGIncludeArchiveSwitchFromItem/PerGIncludeArchiveSwitchFromItem.scss"],"names":[],"mappings":"AAAA,kDACI,UAAW,CACX,eAAgB,CACnB,kDAGG,UAAW,CACd,gDAGG,eAAgB,CACnB,iDAGG,iBAAkB,CACrB,oDAGG,oBAAqB,CACrB,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n\n.switchLabel {\n    display: inline-block;\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PerGIncludeArchiveSwitchFromItem-container-qQZ_h`,
	"userInput": `PerGIncludeArchiveSwitchFromItem-userInput-QuS2b`,
	"tooltip": `PerGIncludeArchiveSwitchFromItem-tooltip-JTrGG`,
	"infoIcon": `PerGIncludeArchiveSwitchFromItem-infoIcon-JX42C`,
	"switchLabel": `PerGIncludeArchiveSwitchFromItem-switchLabel-Eg8ra`
};
export default ___CSS_LOADER_EXPORT___;
