import React from "react";
import { Col, Form, Select, Row, Tooltip } from "antd";
import { QuestionCircleFilled } from "@ant-design/icons";
import { Links, iconCustomColor } from "~/utils/constants";
import styles from "./CorporateEmployeeNumberFormItem.scss";

const CorporateEmployeeNumberFormItem = ({ disabled = false }) => {
    const items = [
        { value: "very_low", title: "~10名" },
        { value: "low", title: "11~30名" },
        { value: "middle", title: "31~50名" },
        { value: "semi_middle", title: "51~100名" },
        { value: "high", title: "101~300名" },
        { value: "very_high", title: "301名~" },
    ];

    return (
        <Col span={24} style={{ marginBottom: "1%" }}>
            <Row className={styles.container}>
                <Col span={23}>
                    <Form.Item colon={false} name="employee_number" noStyle>
                        <Select
                            showSearch={false}
                            className={styles.container}
                            placeholder="社員数"
                            mode="multiple"
                            allowClear
                            disabled={disabled}
                            getPopupContainer={(trigger) => trigger.parentNode}>
                            {items.map((item) => {
                                return (
                                    <Select.Option
                                        key={item.value}
                                        className={styles.select_option}
                                        value={item.value}>
                                        {item.title}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={1} className={styles.infoIcon}>
                    <Tooltip
                        title={
                            <span>
                                複数選択をすると
                                <a
                                    href={Links.helps.filter.or}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    OR検索
                                </a>
                            </span>
                        }>
                        <QuestionCircleFilled style={{ color: iconCustomColor }} className={styles.tooltip} />
                    </Tooltip>
                </Col>
            </Row>
        </Col>
    );
};

export default CorporateEmployeeNumberFormItem;
