"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlanSummaryActions = void 0;
var actionTypes_1 = require("~/actions/actionTypes");
exports.PlanSummaryActions = {
    startFetchingPlan: function () {
        return {
            type: actionTypes_1.PLAN_FETCHING
        };
    },
    planFetched: function (data) {
        return {
            type: actionTypes_1.PLAN_FETCHED,
            payload: data,
        };
    },
};
