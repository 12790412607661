import createEditPage from './Factories/createEditPage';

import Paths from '../Routes/Paths';
import { CONTACT_EMAIL_PREFERENCE_EDIT_PAGE } from './pageIds';

import ContactEmailPreferenceForm from '../Forms/ContactEmailPreferenceForm';

import { Endpoint } from '../../domain/api';
import { convertContactPreferenceResponseDataEntry } from '../../domain/data';

const pageId = CONTACT_EMAIL_PREFERENCE_EDIT_PAGE;
const resourceURL = `${Endpoint.getBaseUrl()}/${Endpoint.contacts}`;
const defaultFormConverter = data => data;
const searchTemplateURL = `${Endpoint.getBaseUrl()}/${Endpoint.contactMailPreferenceSearchTemplate}`;

const ContactMailPreferenceEditPageContainer = createEditPage(
  pageId,
  'contactEmailPreferenceEditPage',
  'メール配信プロファイル 編集',
  ContactEmailPreferenceForm,
  resourceURL,
  Endpoint.contactEmailPreferenceSuffix,
  Paths.contacts,
  convertContactPreferenceResponseDataEntry,
  defaultFormConverter,
  undefined,
  undefined,
  () => { return true },
  () => { return true },
  searchTemplateURL
);

export default ContactMailPreferenceEditPageContainer;
