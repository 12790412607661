// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IdFormItem-container-lvaCc{width:100%;text-align:left}.IdFormItem-userInput-IZeNj{width:100%}.IdFormItem-tooltip-CQWQY{margin-left:5px}.IdFormItem-infoIcon-FZI_1{align-self:center}.IdFormItem-marginBottom-S8o4X{margin-bottom:1%}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Forms/UserSearchForm/IdFormItem/IdFormItem.scss"],"names":[],"mappings":"AAAA,4BACI,UAAW,CACX,eAAgB,CACnB,4BAGG,UAAW,CACd,0BAGG,eAAgB,CACnB,2BAGG,iBAAkB,CACrB,+BAGG,gBAAiB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n\n.marginBottom {\n    margin-bottom: 1%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `IdFormItem-container-lvaCc`,
	"userInput": `IdFormItem-userInput-IZeNj`,
	"tooltip": `IdFormItem-tooltip-CQWQY`,
	"infoIcon": `IdFormItem-infoIcon-FZI_1`,
	"marginBottom": `IdFormItem-marginBottom-S8o4X`
};
export default ___CSS_LOADER_EXPORT___;
