// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ScheduledEmailTextFormatFormItem-container-sARen{width:100%;text-align:left}.ScheduledEmailTextFormatFormItem-userInput-JGGy5{width:100%}.ScheduledEmailTextFormatFormItem-tooltip-Yv1Hn{margin-left:5px}.ScheduledEmailTextFormatFormItem-infoIcon-mK_6Z{align-self:center}.ScheduledEmailTextFormatFormItem-select_option-hZLAl{text-align:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/ScheduledEmailSearchPage/ScheduledEmailSearchDrawer/ScheduledEmailTextFormatFormItem/ScheduledEmailTextFormatFormItem.scss"],"names":[],"mappings":"AAAA,kDACI,UAAW,CACX,eAAgB,CACnB,kDAGG,UAAW,CACd,gDAGG,eAAgB,CACnB,iDAGG,iBAAkB,CACrB,sDAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n\n.select_option {\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ScheduledEmailTextFormatFormItem-container-sARen`,
	"userInput": `ScheduledEmailTextFormatFormItem-userInput-JGGy5`,
	"tooltip": `ScheduledEmailTextFormatFormItem-tooltip-Yv1Hn`,
	"infoIcon": `ScheduledEmailTextFormatFormItem-infoIcon-mK_6Z`,
	"select_option": `ScheduledEmailTextFormatFormItem-select_option-hZLAl`
};
export default ___CSS_LOADER_EXPORT___;
