import React from "react";
import { useFetchScheduledEmailSettingAPIQuery } from "~/hooks/scheduledEmailSetting";
import ScheduledEmailsRawTable from "~/components/Tables/ScheduledMailsTable/ScheduledMailsTable";
import styles from "./ScheduledEmailTable.scss";

const ScheduledEmailTable = ({ ...props }) => {
    const { data, isLoading } = useFetchScheduledEmailSettingAPIQuery();

    return (
        <ScheduledEmailsRawTable
            isAttachmentDownloadAddonPurchased={
                data?.is_download_count_available
            }
            isOpenCountAddonPurchased={data?.is_open_count_available}
            isOpenCountExtraPeriodAvailable={
                data?.is_open_count_extra_period_available
            }
            {...props}
        />
    );
};

export default ScheduledEmailTable;
