// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CorporateContractFormItem-container-fwraf{width:100%;text-align:left}.CorporateContractFormItem-userInput-rLkfd{width:100%}.CorporateContractFormItem-tooltip-zm5YV{margin-left:5px}.CorporateContractFormItem-infoIcon-o95qq{align-self:center}.CorporateContractFormItem-select_option-IbiFn{text-align:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/OrganizationSearchPage/OrganizationSearchDrawer/CorporateContractFormItem/CorporateContractFormItem.scss"],"names":[],"mappings":"AAAA,2CACI,UAAW,CACX,eAAgB,CACnB,2CAGG,UAAW,CACd,yCAGG,eAAgB,CACnB,0CAGG,iBAAkB,CACrB,+CAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n\n.select_option {\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `CorporateContractFormItem-container-fwraf`,
	"userInput": `CorporateContractFormItem-userInput-rLkfd`,
	"tooltip": `CorporateContractFormItem-tooltip-zm5YV`,
	"infoIcon": `CorporateContractFormItem-infoIcon-o95qq`,
	"select_option": `CorporateContractFormItem-select_option-IbiFn`
};
export default ___CSS_LOADER_EXPORT___;
