// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RecruitModifiedUserFormItem-container-Yh7vO{width:100%;text-align:left}.RecruitModifiedUserFormItem-userInput-co7dw{width:100%}.RecruitModifiedUserFormItem-tooltip-er892{margin-left:5px}.RecruitModifiedUserFormItem-infoIcon-B8cMO{align-self:center}.RecruitModifiedUserFormItem-marginBottom-cOPqF{margin-bottom:1%}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/recruit/RecruitBoardPage/RecruitBoardSearchDrawer/RecruitModifiedUserFormItem/RecruitModifiedUserFormItem.scss"],"names":[],"mappings":"AAAA,6CACI,UAAW,CACX,eAAgB,CACnB,6CAGG,UAAW,CACd,2CAGG,eAAgB,CACnB,4CAGG,iBAAkB,CACrB,gDAGG,gBAAiB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n\n.marginBottom {\n    margin-bottom: 1%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `RecruitModifiedUserFormItem-container-Yh7vO`,
	"userInput": `RecruitModifiedUserFormItem-userInput-co7dw`,
	"tooltip": `RecruitModifiedUserFormItem-tooltip-er892`,
	"infoIcon": `RecruitModifiedUserFormItem-infoIcon-B8cMO`,
	"marginBottom": `RecruitModifiedUserFormItem-marginBottom-cOPqF`
};
export default ___CSS_LOADER_EXPORT___;
