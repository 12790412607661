// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MyCompanyForm-container-FlwKt{max-width:600px}.MyCompanyForm-field-MjChE{width:100%;text-align:left;margin:8px 0}.MyCompanyForm-alert-k2v46{margin-top:16px;margin-bottom:16px}.MyCompanyForm-buttons-j25M5{text-align:start}.MyCompanyForm-certificateSelection-sDu19{margin-left:5%}.MyCompanyForm-certificateField-pFGoY{margin-bottom:0}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Forms/MyCompanyForm/MyCompanyForm.scss"],"names":[],"mappings":"AAAA,+BACI,eAAgB,CACnB,2BAGG,UAAW,CACX,eAAgB,CAChB,YAAa,CAChB,2BAGG,eAAgB,CAChB,kBAAmB,CACtB,6BAGG,gBAAiB,CACpB,0CAGG,cAAe,CAClB,sCAGG,eAAgB","sourcesContent":[".container {\n    max-width: 600px;\n}\n\n.field {\n    width: 100%;\n    text-align: left;\n    margin: 8px 0;\n}\n\n.alert {\n    margin-top: 16px;\n    margin-bottom: 16px;\n}\n\n.buttons {\n    text-align: start;\n}\n\n.certificateSelection {\n    margin-left: 5%;\n}\n\n.certificateField {\n    margin-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `MyCompanyForm-container-FlwKt`,
	"field": `MyCompanyForm-field-MjChE`,
	"alert": `MyCompanyForm-alert-k2v46`,
	"buttons": `MyCompanyForm-buttons-j25M5`,
	"certificateSelection": `MyCompanyForm-certificateSelection-sDu19`,
	"certificateField": `MyCompanyForm-certificateField-pFGoY`
};
export default ___CSS_LOADER_EXPORT___;
