import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { List, Comment, Pagination } from "antd";
import {
    createAction,
    deleteAction,
    fetchAction,
    patchAction,
    fetchCommentTemplateAction,
    createCommentTemplateAction,
} from "~/actions/data";
import { convertCommentResponseDataEntry } from "~/domain/data";
import getDateStr from "~/domain/date";
import { syncToPageState } from "~/actions/form";
import { isMobileDevices } from "~/components/helpers";
import { SmileOutlined } from "@ant-design/icons";
import CommentTemplateCreateModal from "~/components/DataDisplay/CommentList/CommentTemplateCreateModal/CommentTemplateCreateModal";
import CommentTemplateUpdateModal from "~/components/DataDisplay/CommentList/CommentTemplateUpdateModal/CommentTemplateUpdateModal";
import SubCommentHidingBarModel from "~/components/DataDisplay/CommentList/SubCommentHidingBarModel/SubCommentHidingBarModel";
import CommentAddForm from "~/components/Forms/CommentAddForm/CommentAddForm";
import CommentUpdateForm from "~/components/Forms/CommentUpdateForm/CommentUpdateForm";
import CommentReplyForm from "~/components/Forms/CommentReplyForm/CommentReplyForm";
import {
    WRITING_NEW_COMMENTS,
    WRITING_EDIT_COMMENTS,
    WRITING_REPLY_COMMENTS,
} from "~/actions/actionTypes";
import CommentTemplateModal from "~/components/Modals/CommentTemplateModal/CommentTemplateModal";
import CommentDeleteButton from "./CommentDeleteButton/CommentDeleteButton";
import CommentUpdateButton from "./CommentUpdateButton/CommentUpdateButton";
import CommentReplyButton from "./CommentReplyButton/CommentReplyButton";
import CommentPinButton from "./CommentPinButton/CommentPinButton";
import { confirmModal } from "~/components/Modals/ConfirmModal";
import { CommentActions } from "~/actionCreators/commentActions";
import ProfileAvatar from "~/components/Common/ProfileAvatar/ProfileAvatar";
import { customErrorMessage, customSuccessMessage } from "~/components/Common/AlertMessage/AlertMessage";
import { SuccessMessages } from "~/utils/constants";
import styles from "./CommentList.scss";

export const CommentListCreator = (
    pageId,
    reducerName,
    commentTemplateUrl,
    newCommentReducerName,
    editCommentReducerName,
    replyCommentReducerName
) => {
    const actionSuffix = "__COMMENTS";
    const commentPageId = pageId + actionSuffix;

    const getPaginationProps = (
        currentPage,
        pageSize,
        totalCount,
        onPageChange,
        onPageSizeChange
    ) => ({
        simple: isMobileDevices(),
        current: currentPage,
        defaultCurrent: currentPage,
        pageSize,
        defaultPageSize: pageSize,
        showSizeChanger: true,
        showTotal: (total, range) =>
            `合計${total}件中, ${range[0]}-${range[1]}を表示`,
        total: totalCount,
        pageSizeOptions: ["10", "50", "100"],
        onChange: onPageChange,
        onShowSizeChange: onPageSizeChange,
    });

    const iconStyle = {
        fontSize: 16,
        width: 40,
        height: 40,
    };

    class _CommentList extends Component {
        state = {
            checked: false,
            updateResourceId: "",
            replyResourceId: "",
            updateText: "",
            updateChecked: false,
            commentTemplateEditing: false,
            newTitle: undefined,
            newContent: undefined,
            selectedIndex: undefined,
            listVisible: false,
            registerVisible: false,
            updateVisible: false,
            deleteVisible: false,
            currentPage: 1,
            pageSize: 10,
            isTitleValidationError: false,
            isContentValidationError: false,
            showChildCommentId: "",
        };

        onChangeCheck = () => {
            const { checked } = this.state;
            this.setState({ checked: !checked });
        };

        onChangeUpdateCheck = (e) => {
            const { updateChecked } = this.state;
            this.setState({ updateChecked: !updateChecked });
        };

        setUpdateState = (resourceId, text, checked) => {
            const { dispatch } = this.props;
            dispatch({
                type: WRITING_EDIT_COMMENTS,
                payload: {
                    commentValue: text,
                },
            });
            this.setState({
                updateResourceId: resourceId,
                updateText: text,
                updateChecked: checked,
            });
        };

        setReplyState = (resourceId, text, checked) => {
            const { dispatch } = this.props;
            if (this.state.replyResourceId) {
                this.setState({ replyResourceId: "" });
                return;
            }
            dispatch(
                CommentActions.replyAction({
                    commentValue: "",
                })
            );
            this.setState({
                replyResourceId: resourceId,
                updateText: text,
                updateChecked: checked,
            });
        };

        resetUpdateState = () => {
            this.setUpdateState("", "", false);
        };

        componentDidMount() {
            this.fetchItems();
            this.fetchComments();
        }

        componentDidUpdate(prevProps, prevState, snapshot) {
            const { commentState } = this.props;
            const { loading, submitting, requireRefresh, parentCommentId } =
                commentState;
            if (requireRefresh && !loading && !submitting) {
                this.fetchItems();
                this.resetUpdateState();
            }

            if (parentCommentId) {
                this.getCommentChildrenData(parentCommentId);
                this.setState({ replyResourceId: "" });
            }
        }

        fetchItems = () => {
            const { dispatch, token, resourceUrl } = this.props;
            dispatch(
                fetchAction(
                    commentPageId,
                    token,
                    resourceUrl,
                    null,
                    convertCommentResponseDataEntry
                )
            );
        };

        onItemDelete = (resource) => {
            const { dispatch, token, resourceUrl } = this.props;
            dispatch(
                deleteAction(
                    commentPageId,
                    token,
                    resourceUrl,
                    resource.id,
                    resource
                )
            );
        };

        onNewCommentUpdate = (value) => {
            const { dispatch } = this.props;
            dispatch({
                type: WRITING_NEW_COMMENTS,
                payload: {
                    commentValue: value,
                },
            });
        };

        onReplyComment = (value) => {
            const { dispatch } = this.props;
            dispatch(
                CommentActions.replyAction({
                    commentValue: value,
                })
            );
        };

        onEditCommentUpdate = (value) => {
            this.setState({ updateText: value });
            const { dispatch } = this.props;
            dispatch({
                type: WRITING_EDIT_COMMENTS,
                payload: {
                    commentValue: value,
                },
            });
        };

        onSubmit = () => {
            const { dispatch, token, resourceUrl, newCommentState } =
                this.props;
            const { commentValue } = newCommentState;
            const data = {
                content: commentValue,
                is_important: this.state.checked,
            };
            dispatch(createAction(commentPageId, token, resourceUrl, data));
            this.setState({
                checked: false,
            });
        };

        onReplySubmit = (id) => {
            const { dispatch, token, resourceUrl, replyCommentState } =
                this.props;
            const resourceURLSub = `${resourceUrl}/${id}/sub-comments`;
            const subCommentPageId = commentPageId + "__SUBCOMMENTS";
            const { commentValue } = replyCommentState;
            const data = {
                content: commentValue,
                is_important: this.state.updateChecked,
            };
            dispatch(
                createAction(subCommentPageId, token, resourceURLSub, data)
            );
        };

        onUpdate = () => {
            const { dispatch, token, resourceUrl, editCommentState } =
                this.props;
            const { commentValue } = editCommentState;
            const pattern = /<a[^>]+href="([^"]+)"[^>]*>([^<]+)<\/a>/g;
            const content = commentValue.replace(pattern, (match, url, textContent) => textContent);
            const data = {
                id: this.state.updateResourceId,
                content,
                is_important: this.state.updateChecked,
            };
            dispatch(
                patchAction(
                    commentPageId,
                    token,
                    resourceUrl,
                    this.state.updateResourceId,
                    data,
                    convertCommentResponseDataEntry,
                    true
                )
            );
        };

        onUpdateCheck = (item) => {
            const { dispatch, token, resourceUrl } = this.props;
            const data = { id: item.id, is_important: !item.is_important };
            dispatch(
                patchAction(
                    commentPageId,
                    token,
                    resourceUrl,
                    item.id,
                    data,
                    convertCommentResponseDataEntry,
                    true
                )
            );
        };

        scrollToComment = (idParent) => {
            document
                .getElementById(idParent)
                .scrollIntoView({ block: "start", behavior: "smooth" });
        };

        getDeleteLink = (item, isMyComment) => {
            return (
                <CommentDeleteButton
                    isUserComment={isMyComment}
                    onDelete={() => this.onItemDelete(item)}
                    commentData={item}
                />
            );
        };

        getUpdateLink = (item, isMyComment) => {
            return (
                <CommentUpdateButton
                    isUserComment={isMyComment}
                    onUpdate={() =>
                        this.setUpdateState(
                            item.id,
                            item.content,
                            item.is_important
                        )
                    }
                />
            );
        };

        getReplyComment = (item, isMyComment) => {
            const isReplying = this.state.replyResourceId === item.id;
            return (
                <CommentReplyButton
                    isReplying={isReplying}
                    isUserComment={isMyComment}
                    onReply={() => this.setReplyState(item.id, item.content)}
                />
            );
        };

        getUpdateCheckLink = (item, isMyComment) => {
            return (
                <CommentPinButton
                    isUserComment={isMyComment}
                    isPinned={item.is_important}
                    onPin={() => this.onUpdateCheck(item)}
                />
            );
        };

        getComment = (item, currentUserId) => {
            const isMyComment = item.created_user === currentUserId;
            let datetime_str = undefined;
            if (item.created_time) {
                datetime_str = getDateStr(item.created_time);
            }
            let created_time_str = undefined;
            if (item.created_time) {
                created_time_str = getDateStr(item.created_time);
            }

            if (datetime_str && item.edited) {
                datetime_str = datetime_str + " (編集済)";
            }
            return (
                <div id={item.id}>
                    <Comment
                        className={
                            item.is_important
                                ? styles.importantComment
                                : styles.comment
                        }
                        avatar={
                            !item.deleted_at ? (
                                item.created_user__avatar &&
                                item.created_user__avatar !== "" ? (
                                    <div className={styles.avatarImageWrapper}>
                                        <ProfileAvatar
                                            style={iconStyle}
                                            avatar={item.created_user__avatar}
                                        />
                                    </div>
                                ) : (
                                    <SmileOutlined className={styles.avatar} />
                                )
                            ) : null
                        }
                        actions={
                            !item.deleted_at
                                ? [
                                      this.getUpdateCheckLink(
                                          item,
                                          isMyComment
                                      ),
                                      this.getUpdateLink(item, isMyComment),
                                      this.getReplyComment(item, isMyComment),
                                      this.getDeleteLink(item, isMyComment),
                                  ]
                                : null
                        }
                        author={
                            !item.deleted_at ? item.created_user__name : null
                        }
                        content={
                            !item.deleted_at ? (
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: item.content,
                                    }}
                                    style={{ wordWrap:"break-word" }}
                                ></p>
                            ) : (
                                <p>このコメントは削除されました</p>
                            )
                        }
                        datetime={!item.deleted_at ? datetime_str : created_time_str}
                        children={
                            this.state.showChildCommentId === item.id
                                ? this.renderChildComment(currentUserId)
                                : this.textShowComment(item)
                        }
                    />
                    {this.state.showChildCommentId === item.id
                        ? this.textHideComment()
                        : null}
                    {this.state.replyResourceId === item.id &&
                        this.getCommentReply(item.id)}
                </div>
            );
        };

        textHideComment = () => {
            return (
                <a
                    className={styles.textHideStyle}
                    onClick={() => {
                        this.setState({ showChildCommentId: "" });
                        this.fetchItems();
                    }}>
                    返信を非表示
                </a>
            );
        };

        getCommentChildrenData = (id) => {
            const { dispatch, token, resourceUrl } = this.props;
            const resourceURLSub = `${resourceUrl}/${id}/sub-comments`;
            const subCommentPageId = commentPageId + "__SUBCOMMENTS";
            dispatch(
                fetchAction(
                    subCommentPageId,
                    token,
                    resourceURLSub,
                    null,
                    convertCommentResponseDataEntry
                )
            );

            this.setState({ showChildCommentId: id });
        };

        textShowComment = (commentParent) => {
            return commentParent.total_sub_comment > 0 ? (
                <div className={styles.commentItem}>
                    <SubCommentHidingBarModel
                        subCommentUsersAvatar={
                            commentParent.sub_comment_users_avatar
                        }
                    />
                    <a
                        onClick={() => {
                            this.getCommentChildrenData(commentParent.id);
                        }}>
                        {commentParent.total_sub_comment}件の返信を表示
                    </a>
                </div>
            ) : null;
        };

        getCommentChildren = (item, currentUserId) => {
            const isMyComment = item.created_user === currentUserId;
            let datetime_str = undefined;
            if (item.created_time) {
                datetime_str = getDateStr(item.created_time);
            }

            if (datetime_str && item.edited) {
                datetime_str = datetime_str + " (編集済)";
            }
            return (
                    <Comment
                        className={
                            item.is_important
                                ? styles.importantComment
                                : styles.comment
                        }
                        avatar={
                            item.created_user__avatar &&
                            item.created_user__avatar !== "" ? (
                                <div className={styles.avatarImageWrapper}>
                                    <ProfileAvatar
                                        style={iconStyle}
                                        avatar={item.created_user__avatar}
                                    />
                                </div>
                            ) : (
                                <SmileOutlined className={styles.avatar} />
                            )
                        }
                        actions={[
                            this.getUpdateLink(item, isMyComment),
                            this.getDeleteLink(item, isMyComment),
                        ]}
                        author={item.created_user__name}
                        content={
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: item.content,
                                }}
                                style={{ wordWrap:"break-word" }}
                            ></p>
                        }
                        datetime={datetime_str}
                    />
            );
        };

        renderChildComment = (currentUserId) => {
            const displayItemsChild = this.getItemsChildState();
            const dataSource = displayItemsChild?.map(item=>(item = {
                ...item,
                content: convertHyperlink(item.content),
            }));
            return (
                <List
                    size="small"
                    dataSource={dataSource}
                    renderItem={(item) =>
                        this.state.updateResourceId == item.id
                            ? this.getCommentUpdate(item.id)
                            : this.getCommentChildren(item, currentUserId)
                    }
                />
            );
        };

        getCommentReply = (id) => {
            const { replyCommentState } = this.props;
            const { commentValue, commentError } = replyCommentState;
            return (
                <CommentReplyForm
                    value={commentValue}
                    onInputChange={this.onReplyComment}
                    onReply={() => {
                        this.onReplySubmit(id);
                    }}
                    isPinned={this.state.updateChecked}
                    onPinnedChange={this.onChangeUpdateCheck}
                    onSelectCommentTemplate={this.showList}
                    commentError={commentError}
                />
            );
        };

        getCommentUpdate = (childrenId) => {
            const { editCommentState } = this.props;
            const { commentValue, commentError } = editCommentState;
            return (
                <CommentUpdateForm
                    value={commentValue}
                    onInputChange={this.onEditCommentUpdate}
                    onUpdate={this.onUpdate}
                    onCancel={this.resetUpdateState}
                    isPinned={this.state.updateChecked}
                    onPinnedChange={this.onChangeUpdateCheck}
                    onSelectCommentTemplate={this.showList}
                    commentError={commentError}
                    childrenId={childrenId}
                />
            );
        };

        getRenderItem = (item, currentUserId, isAdminUser) => {
            return this.state.updateResourceId == item.id
                ? this.getCommentUpdate()
                : this.getComment(item, currentUserId, isAdminUser);
        };

        fetchComments = () => {
            const { dispatch, token } = this.props;
            dispatch(
                fetchCommentTemplateAction(
                    commentPageId,
                    token,
                    commentTemplateUrl
                )
            );
        };

        createComments = (comment_templates) => {
            const { dispatch, token } = this.props;
            dispatch(
                createCommentTemplateAction(
                    commentPageId,
                    token,
                    commentTemplateUrl,
                    { templates: comment_templates }
                )
            );
        };

        // list
        showList = () => {
            this.setState({ listVisible: true });
        };

        // register
        showRegister = () => {
            this.setState({
                registerVisible: true,
                newTitle: undefined,
                newContent: undefined,
            });
        };

        registerOnOk = ({ newTitle, newContent }) => {
            const { commentState } = this.props;
            const { comment_templates } = commentState;
            if (newTitle && newContent) {
                const index = comment_templates.findIndex(
                    (item) => item.title === newTitle
                );
                if (index >= 0) {
                    customErrorMessage(
                        `「${newTitle}」と同一名称のテンプレートが既に存在します。別のテンプレート名を入力してください。`
                    );
                    return;
                }
                const newTemplate = {
                    title: newTitle,
                    content: newContent,
                };
                this.createComments([...comment_templates, newTemplate]);
                this.setState({ registerVisible: false });
                if (
                    comment_templates
                        .map((template) => template.title)
                        .includes(newTitle)
                ) {
                    return;
                }
                comment_templates.push(newTemplate);
                customSuccessMessage(SuccessMessages.generic.create);
            }
        };

        registerOnCancel = () => {
            this.setState({
                registerVisible: false,
                isTitleValidationError: false,
                isContentValidationError: false,
            });
        };

        // update
        showUpdate = (index) => {
            this.setState({
                updateVisible: true,
                selectedIndex: index,
                newTitle: undefined,
                newContent: undefined,
            });
        };

        updateOnOk = ({
            newTitle: updatedTitle,
            newContent: updatedContent,
        }) => {
            const { selectedIndex } = this.state;
            const { commentState } = this.props;
            const { comment_templates } = commentState;
            var updated_templates = [];
            comment_templates.map((template, index) => {
                if (index == selectedIndex) {
                    var title = updatedTitle ? updatedTitle : template.title;
                    var content = updatedContent
                        ? updatedContent
                        : template.content;
                    updated_templates.push({ title: title, content: content });
                } else {
                    updated_templates.push(template);
                }
            });
            this.setState({ updateVisible: false });
            this.createComments(updated_templates);
        };

        updateOnCancel = () => {
            this.setState({ updateVisible: false, selectedIndex: undefined });
        };

        // delete
        showDelete = (index) => {
            this.setState({ deleteVisible: true, selectedIndex: index });
            confirmModal({
                title: "このテンプレートを削除しますか？",
                content: (
                    <div>
                        <p>OKを押すと、削除が実行されます。</p>
                        <p>元には戻せません。</p>
                    </div>
                ),
                onOk: this.deleteOnOk,
                onCancel: this.deleteOnCancel,
            });
        };

        deleteOnOk = () => {
            const { selectedIndex } = this.state;
            const { commentState } = this.props;
            const { comment_templates } = commentState;
            var not_deleted_templates = [];
            comment_templates.map((template, index) => {
                if (index != selectedIndex) {
                    not_deleted_templates.push(template);
                }
            });
            this.setState({
                deleteVisible: false,
                comment_templates: not_deleted_templates,
            });
            this.createComments(not_deleted_templates);
        };

        deleteOnCancel = () => {
            this.setState({ deleteVisible: false, selectedIndex: undefined });
        };

        setTemplateValueToCommentForm = (index) => {
            const { dispatch } = this.props;
            const { commentState } = this.props;
            const { comment_templates } = commentState;
            if (
                this.state.updateResourceId === "" &&
                this.state.replyResourceId === ""
            ) {
                dispatch(
                    syncToPageState(
                        commentPageId,
                        comment_templates[index].content
                    )
                );
                dispatch({
                    type: WRITING_NEW_COMMENTS,
                    payload: {
                        commentValue: comment_templates[index].content,
                    },
                });
            } else if (
                this.state.replyResourceId !== "" &&
                this.state.updateResourceId === ""
            ) {
                dispatch({
                    type: WRITING_REPLY_COMMENTS,
                    payload: {
                        commentValue: comment_templates[index].content,
                    },
                });
            } else {
                var text_before = this.state.updateText;
                this.setState({
                    updateText: text_before + comment_templates[index].content,
                });
                dispatch({
                    type: WRITING_EDIT_COMMENTS,
                    payload: {
                        commentValue:
                            text_before + comment_templates[index].content,
                    },
                });
            }
        };

        onPageChange = (page, pageSize) => {
            this.setState({ currentPage: page, pageSize: pageSize });
        };

        onPageSizeChange = (current, size) => {
            this.setState({ pageSize: size });
        };

        getItemsByPageState = () => {
            const { commentState } = this.props;
            const { items } = commentState;
            const { currentPage, pageSize } = this.state;

            return items.slice(
                (currentPage - 1) * pageSize,
                currentPage * pageSize
            );
        };

        getItemsChildState = () => {
            const { commentState } = this.props;
            const { subItems } = commentState;
            return subItems;
        };

        render() {
            const {
                currentUserId,
                commentState,
                isAdminUser,
                newCommentState,
            } = this.props;
            const {
                submitting,
                items,
                comment_templates,
            } = commentState;
            const { commentValue, commentError } = newCommentState;
            const {
                selectedIndex,
                currentPage,
                pageSize,
            } = this.state;

            const paginationProps = getPaginationProps(
                currentPage,
                pageSize,
                items.length,
                this.onPageChange,
                this.onPageSizeChange
            );

            const displayItems = this.getItemsByPageState();

            const dataSource = displayItems.map((item) => ({
                ...item,
                content: convertHyperlink(item.content),
            }));

            return (
                <div>
                    <List
                        size="small"
                        dataSource={dataSource}
                        renderItem={(item) =>
                            this.getRenderItem(item, currentUserId, isAdminUser)
                        }
                    />
                    <Pagination
                        {...paginationProps}
                        className={styles.paginator}
                        size="small"
                    />
                    <CommentAddForm
                        value={commentValue}
                        onInputChange={this.onNewCommentUpdate}
                        isPinned={this.state.checked}
                        onPinnedChange={this.onChangeCheck}
                        isSubmitting={submitting}
                        onSubmit={this.onSubmit}
                        onSelectCommentTemplate={this.showList}
                        commentError={commentError}
                    />

                    <CommentTemplateModal
                        reducerId={reducerName}
                        isModalVisible={this.state.listVisible}
                        onModalClose={() =>
                            this.setState({ listVisible: false })
                        }
                        onCreateTemplate={this.showRegister}
                        onInsertTemplate={this.setTemplateValueToCommentForm}
                        onEditTemplate={this.showUpdate}
                        onDeleteTemplate={this.showDelete}
                    />
                    <CommentTemplateCreateModal
                        isOpen={this.state.registerVisible}
                        onOk={this.registerOnOk}
                        onCancel={this.registerOnCancel}
                    />
                    <CommentTemplateUpdateModal
                        initialData={
                            comment_templates[selectedIndex] &&
                            comment_templates[selectedIndex].title &&
                            comment_templates[selectedIndex].content
                                ? {
                                      newTitle:
                                          comment_templates[selectedIndex]
                                              .title,
                                      newContent:
                                          comment_templates[selectedIndex]
                                              .content,
                                  }
                                : { newTitle: "", newContent: "" }
                        }
                        isOpen={this.state.updateVisible}
                        onOk={this.updateOnOk}
                        onCancel={this.updateOnCancel}
                    />
                </div>
            );
        }
    }

    _CommentList.propTypes = {
        dispatch: PropTypes.func.isRequired,
        resourceUrl: PropTypes.string.isRequired,
        currentUserId: PropTypes.string.isRequired, // followings are reducer state.
        token: PropTypes.string.isRequired,
        commentState: PropTypes.shape({
            loading: PropTypes.bool.isRequired,
            submitting: PropTypes.bool.isRequired,
            requireRefresh: PropTypes.bool.isRequired,
            formValues: PropTypes.string.isRequired,
            parentCommentId: PropTypes.string.isRequired,
            items: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.string.isRequired,
                    content: PropTypes.string.isRequired,
                    created_user: PropTypes.string.isRequired,
                    created_user__name: PropTypes.string.isRequired,
                    created_user__avatar: PropTypes.string.isRequired,
                    is_important: PropTypes.bool.isRequired,
                })
            ).isRequired,
            subItems: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.string.isRequired,
                    content: PropTypes.string.isRequired,
                    created_user: PropTypes.string.isRequired,
                    created_user__name: PropTypes.string.isRequired,
                    created_user__avatar: PropTypes.string.isRequired,
                    is_important: PropTypes.bool.isRequired,
                })
            ).isRequired,
            comment_templates: PropTypes.arrayOf(
                PropTypes.shape({
                    title: PropTypes.string.isRequired,
                    content: PropTypes.string.isRequired,
                })
            ).isRequired,
            totalAvailableCount: PropTypes.number.isRequired,
        }).isRequired,
        isAdminUser: PropTypes.bool.isRequired,
        authorizedActions: PropTypes.object.isRequired,
    };

    function mapStateToProps(state) {
        return {
            token: state.login.token,
            currentUserId: state.login.userId,
            commentState: state[reducerName],
            newCommentState: state[newCommentReducerName],
            editCommentState: state[editCommentReducerName],
            replyCommentState: state[replyCommentReducerName],
            isAdminUser: state.login.isAdminUser,
            authorizedActions: state.login.authorizedActions,
        };
    }

    return connect(mapStateToProps)(_CommentList);
};

export default CommentListCreator;

export const convertHyperlink = (inputText) => {
    let replacedText = inputText.replace(
        /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim,
        '<a href="$1" target="_blank">$1</a>'
    );
    replacedText = replacedText.replace(
        /(^|[^\/])(www\.[\S]+(\b|$))/gim,
        '$1<a href="http://$2" target="_blank">$2</a>'
    );
    replacedText = replacedText.replace(
        /(([a-zA-Z0-9\-._])+@[a-zA-Z0-9\-.]+\.[a-zA-Z0-9]{2,5})/gim,
        '<a href="mailto:$1">$1</a>'
    );
    return replacedText;
};
