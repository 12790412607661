import React from "react";
import PropTypes from "prop-types";
import { Tag, Space, Skeleton } from "antd";
import Path from "../../Routes/Paths";
import GenericTable from "../GenericTable";
import TooltipCopy from "~/components/Common/TooltipCopy/TooltipCopy";
import { INVITED, RESET_MESSAGE } from "~/actions/actionTypes";
import { createApi } from "~/actions/data";
import { Endpoint } from "~/domain/api";
import { useDispatch, useSelector } from "react-redux";
import { ROLES } from "~/utils/constants";
import { useAuthorizedActions } from "~/hooks/useAuthorizedActions";
import { useRouter } from "~/hooks/router";
import styles from "./UsersTable.scss";

const statusTag = {
    active: {
        displayName: "有効",
        color: "green",
    },
    inactive: {
        displayName: "無効",
        color: "volcano",
    },
};

const renderRoleTag = (record) => {
    const status = ROLES.find((status) => status.value === record.role);
    if (typeof record.role !== "string" || status === undefined) {
        return <span />;
    }

    return (
        <span>
            <Tag color={status?.color} key={record.role}>
                {status?.title}
            </Tag>
        </span>
    );
};

const renderStatusTag = (isActive) => {
    const displayTag = isActive ? statusTag.active : statusTag.inactive;
    return (
        <span>
            <Tag color={displayTag.color}>{displayTag.displayName}</Tag>
        </span>
    );
};

const columns = (onCellClick, inviteMail) => {
    return [
        {
            title: "ユーザー名",
            dataIndex: "name",
            key: "name",
            width: 160,
            className: styles.tooltipCopy,
            render: (_, record) => {
                return (
                    <>
                        {record.display_name}
                        <TooltipCopy copyContent={record.display_name} />
                    </>
                );
            },
            onCell: (record) => ({
                onClick: () => onCellClick(record),
            }),
        },
        {
            title: "ユーザーID",
            dataIndex: "user_service_id",
            key: "user_service_id",
            width: 280,
            className: styles.tooltipCopy,
            render: (_, record) => {
                return (
                    <>
                        {record.user_service_id}
                        <TooltipCopy copyContent={record.user_service_id} />
                    </>
                );
            },
            onCell: (record) => ({
                onClick: () => onCellClick(record),
            }),
        },
        {
            title: "メールアドレス",
            dataIndex: "email",
            key: "email",
            width: 280,
            className: styles.tooltipCopy,
            render: (email, record, column_index) => {
                return (
                    <>
                        {email}
                        <TooltipCopy copyContent={email} />
                    </>
                );
            },
            onCell: (record) => ({
                onClick: () => onCellClick(record),
            }),
        },
        {
            title: "権限",
            key: "user_role__order",
            dataIndex: "user_role__order",
            width: 110,
            sorter: true,
            render: (tag, record) => {
                const status = ROLES.find(
                    (status) => status.value === record.role
                );
                if (typeof record.role !== "string" || status === undefined) {
                    return <span />;
                }

                return (
                    <span>
                        <Tag color={status?.color} key={record.role}>
                            {status?.title}
                        </Tag>
                    </span>
                );
            },
        },
        {
            title: "TEL",
            dataIndex: "tel1",
            key: "tel",
            width: 140,
            className: styles.tooltipCopy,
            render: (tel1, record, column_index) => {
                return (
                    <>
                        {record.tel1 && record.tel2 && record.tel3 && (
                            <>
                                {record.tel1 +
                                    "-" +
                                    record.tel2 +
                                    "-" +
                                    record.tel3}
                                <TooltipCopy
                                    copyContent={
                                        record.tel1 +
                                        "-" +
                                        record.tel2 +
                                        "-" +
                                        record.tel3
                                    }
                                />
                            </>
                        )}
                    </>
                );
            },
            onCell: (record) => ({
                onClick: () => onCellClick(record),
            }),
        },
        {
            title: "最終ログイン",
            dataIndex: "last_login",
            key: "last_login",
            width: 160,
            render: (last_login, record, column_index) => {
                if (!last_login && record.is_active) return;
                if (last_login || record.registed_at) {
                    return record.last_login;
                } else {
                    return (
                        <div>
                            <a onClick={(e) => inviteMail(record)}>
                                招待を再送
                            </a>
                        </div>
                    );
                }
            },
            onCell: (record) => ({
                onClick: (e) => {
                    if (e.target.tagName === "A") {
                        return;
                    } else {
                        onCellClick(record);
                    }
                },
            }),
            sorter: true,
        },
    ];
};

const onMinify = {
    renderTitle: (item) => item.display_name,
    renderDescription: (item) => (
        <div>
            <p>{`メールアドレス: ${item.email ? item.email : "未登録"}`}</p>
            <p>{`TEL: ${item.tel ? item.tel : "未登録"}`}</p>
            <span>
                権限:
                {renderRoleTag(item.role)}
            </span>
            <span>
                状態:
                {renderStatusTag(item.is_active)}
            </span>
        </div>
    ),
};

const UsersTable = ({ pageId, loading, ...props }) => {
    const router = useRouter();
    const dispatch = useDispatch();
    const token = useSelector((state) => state.login.token);
    const editableRoles = useAuthorizedActions("_editable_roles");
    const isDisplaySettingLoading = useSelector(
        (state) => state.displaySettingPage.isDisplaySettingLoading
    );
    const { displaySetting } = useSelector((state) => state.displaySettingPage);

    const onRowClick = (record) => {};

    const onColumnClick = (record) => {
        const url = `${Path.users}/${record.id}`;
        router.push(url);
    };

    const getCheckboxProps = (record) => {
        return {
            disabled: !editableRoles.includes(record.role),
        };
    };

    const inviteMail = (record) => {
        const { onTableActionMustBeCalled } = props;
        if (onTableActionMustBeCalled) {
            onTableActionMustBeCalled();
        }
        const resourceURL = `${Endpoint.getBaseUrl()}/${Endpoint.users}`;

        let values = {
            email: record.email,
            role: record.role,
            sendAgain: true,
        };
        dispatch(
            createApi(pageId, token, resourceURL, values, {
                after: INVITED,
                afterCommit: RESET_MESSAGE,
            })
        );
    };

    return (
        <>
            {isDisplaySettingLoading ? (
                <Space className={styles.container}>
                    <Skeleton.Input
                        style={{ height: 200, width: 1200 }}
                        active
                        size="large"
                    />
                </Space>
            ) : (
                <GenericTable
                    {...props}
                    columns={columns(onColumnClick, inviteMail)}
                    onMinify={onMinify}
                    onRowClick={onRowClick}
                    loading={loading}
                    withSelection={true}
                    getCheckboxProps={getCheckboxProps}
                    pageSize={displaySetting?.users?.page_size ?? 10}
                />
            )}
        </>
    );
};

UsersTable.propTypes = {
    currentPage: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired,
    sortKey: PropTypes.string,
    sortOrder: PropTypes.string,
    onPageChange: PropTypes.func.isRequired,
    onPageSizeChange: PropTypes.func.isRequired,
    onTableChange: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    data: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.number.isRequired,
            id: PropTypes.string.isRequired,
            display_name: PropTypes.string,
            user_service_id: PropTypes.string.isRequired,
            email: PropTypes.string,
            tel: PropTypes.string,
            last_login: PropTypes.string,
            is_active: PropTypes.bool,
            modified_date: PropTypes.string,
        })
    ),
    leftBottomButtons: PropTypes.array,
    pageId: PropTypes.string,
    onTableActionMustBeCalled: PropTypes.func,
};

UsersTable.defaultProps = {
    loading: false,
    data: [],
    sortKey: undefined,
    sortOrder: undefined,
};

export default UsersTable;
