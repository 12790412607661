"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSearchTemplateUtils = void 0;
var useSearchTemplateUtils = function () {
    var removeSearchConditions = function () {
        var prefixURLSearchDashboard = "dashboard";
        var prefixURLSearchContact = "contacts";
        var prefixURLSearchOrganizations = "organizations";
        var prefixURLSearchScheduledMails = "scheduledMails";
        var prefixURLSearchSharedMails = "sharedMails";
        var prefixURLSearchSharedEmailNotifications = "sharedMailNotifications";
        var targetPrefixes = [
            prefixURLSearchDashboard,
            prefixURLSearchContact,
            prefixURLSearchOrganizations,
            prefixURLSearchScheduledMails,
            prefixURLSearchSharedMails,
            prefixURLSearchSharedEmailNotifications,
        ];
        for (var _i = 0, _a = Object.entries(localStorage); _i < _a.length; _i++) {
            var _b = _a[_i], key = _b[0], value = _b[1];
            var targetKey = /^(?!str_.*_selected).+$/;
            for (var _c = 0, targetPrefixes_1 = targetPrefixes; _c < targetPrefixes_1.length; _c++) {
                var prefix = targetPrefixes_1[_c];
                if (key.includes(prefix + "_") && key.match(targetKey)) {
                    localStorage.removeItem(key);
                }
            }
        }
    };
    var saveCurrentSearchCondition = function (target, keyName, values) {
        var savingValues = JSON.stringify(values);
        var savingKey = target + "_default";
        if (keyName) {
            savingKey = target + "_" + keyName;
        }
        localStorage.setItem(savingKey, savingValues);
    };
    return {
        removeSearchConditions: removeSearchConditions,
        saveCurrentSearchCondition: saveCurrentSearchCondition,
    };
};
exports.useSearchTemplateUtils = useSearchTemplateUtils;
