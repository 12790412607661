// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProjectSettleIncrementFormItem-container-VJ91M{width:100%;text-align:left}.ProjectSettleIncrementFormItem-userInput-lc93w{width:100%}.ProjectSettleIncrementFormItem-tooltip-cbZF3{margin-left:5px}.ProjectSettleIncrementFormItem-infoIcon-yaRhF{align-self:center}.ProjectSettleIncrementFormItem-select_option-K12Tg{text-align:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/project/ProjectBoardPage/ProjectBoardSearchDrawer/ProjectSettleIncrementFormItem/ProjectSettleIncrementFormItem.scss"],"names":[],"mappings":"AAAA,gDACI,UAAW,CACX,eAAgB,CACnB,gDAGG,UAAW,CACd,8CAGG,eAAgB,CACnB,+CAGG,iBAAkB,CACrB,oDAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n\n.select_option {\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ProjectSettleIncrementFormItem-container-VJ91M`,
	"userInput": `ProjectSettleIncrementFormItem-userInput-lc93w`,
	"tooltip": `ProjectSettleIncrementFormItem-tooltip-cbZF3`,
	"infoIcon": `ProjectSettleIncrementFormItem-infoIcon-yaRhF`,
	"select_option": `ProjectSettleIncrementFormItem-select_option-K12Tg`
};
export default ___CSS_LOADER_EXPORT___;
