"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectedTemplateName = exports.currentDisplaySetting = exports.localStorageDashboard = void 0;
var recoil_1 = require("recoil");
var utils_1 = require("~/components/Pages/DashboardPage/utils");
var constants_1 = require("~/utils/constants");
var localStorageDashboard = function () {
    var savedValue = localStorage.getItem("".concat(constants_1.QueryKeys.dashboard.setting, ".current"));
    if (savedValue === null) {
        return undefined;
    }
    try {
        var values = JSON.parse(savedValue);
        return values;
    }
    catch (err) {
        return undefined;
    }
};
exports.localStorageDashboard = localStorageDashboard;
var localStorageCurrentDisplaySetting = function (key) {
    return function (_a) {
        var setSelf = _a.setSelf, onSet = _a.onSet;
        var savedValue = (0, exports.localStorageDashboard)();
        if (savedValue !== undefined) {
            setSelf(savedValue);
        }
        else {
            setSelf(utils_1.emptyDisplaySetting);
        }
        onSet(function (newValue, _, isReset) {
            if (isReset) {
                localStorage.removeItem(key);
            }
            else {
                var savingValue = newValue
                    ? JSON.stringify(newValue)
                    : JSON.stringify(utils_1.emptyDisplaySetting);
                localStorage.setItem(key, savingValue);
            }
        });
    };
};
exports.currentDisplaySetting = (0, recoil_1.atom)({
    key: constants_1.QueryKeys.dashboard.setting,
    default: utils_1.emptyDisplaySetting,
    effects: [
        localStorageCurrentDisplaySetting(constants_1.QueryKeys.dashboard.setting + ".current")
    ]
});
var localStorageSelectedTemplateName = function (key) {
    return function (_a) {
        var setSelf = _a.setSelf, onSet = _a.onSet;
        var savedValue = localStorage.getItem(key);
        if (savedValue !== null) {
            var settingValue = savedValue;
            if (!!settingValue && settingValue !== "undefined") {
                settingValue = JSON.parse(settingValue);
            }
            else {
                settingValue = undefined;
            }
            setSelf(settingValue);
        }
        onSet(function (newValue, _, isReset) {
            if (isReset) {
                localStorage.removeItem(key);
            }
            else {
                var savingValue = newValue ? JSON.stringify(newValue) : "";
                localStorage.setItem(key, savingValue);
            }
        });
    };
};
exports.selectedTemplateName = (0, recoil_1.atom)({
    key: constants_1.QueryKeys.dashboard.selectedTemplateName,
    default: undefined,
    effects: [
        localStorageSelectedTemplateName(constants_1.QueryKeys.dashboard.selectedTemplateName + ".current"),
    ],
});
