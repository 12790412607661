"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var utils_1 = require("~/utils/utils");
var utils_2 = require("./utils");
var lists = function (client) {
    var path = function (dynamicPath) {
        if (dynamicPath === void 0) { dynamicPath = ""; }
        return utils_2.recruitBoardBasePath + "/lists" + dynamicPath;
    };
    return {
        createList: function (title) {
            var url = path();
            return client.post(url, { title: title });
        },
        deleteList: function (listId) {
            var url = path("/" + listId + "/operation");
            return client.delete(url);
        },
        updateListTitle: function (listId, title) {
            var url = path("/" + listId + "/operation");
            return client.patch(url, { title: title });
        },
        fetchLists: function () {
            var url = path();
            return client.get(url);
        },
        changeListPosition: function (listId, position) {
            var url = path("/" + listId + "/position");
            return client.patch(url, { position: position });
        },
        fetchListCards: function (listId) {
            var url = path("/" + listId);
            return client.get(url);
        },
        fetchAllListCards: function (params) {
            var url = path("/cards");
            if (!!params) {
                var queryString = (0, utils_1.createQueryString)(params, true);
                url += "?" + queryString;
            }
            return client.get(url);
        },
        sortAllCards: function (listId, query, params) {
            var url = path("/" + listId);
            if (query) {
                url += "?" + (0, utils_1.createQueryString)(query);
            }
            if (params) {
                var separator = url.includes("?") ? "&" : "?";
                url += separator + (0, utils_1.createQueryString)(params, true);
            }
            return client.get(url);
        },
        moveAllCards: function (listId, newListId) {
            var url = path("/" + listId + "/move");
            return client.patch(url, { listId: newListId });
        },
        archiveAllCards: function (listId) {
            var url = path("/" + listId + "/archive");
            return client.patch(url);
        },
    };
};
exports.default = lists;
