import { CONTACT_SEARCH_PAGE } from "../pageIds";
import createSearchPage from "~/components/Pages/Factories/createSearchPage";
import { Endpoint } from "~/domain/api";
import {
    contactSearchParamToAPI,
    convertContactListResponseDataEntry,
} from "~/domain/data";
import ContactsTable from "~/components/Tables/ContactsTable/ContactsTable";
import ContactTableDisplaySettingModal from "~/components/Tables/ContactsTable/ContactTableDisplaySettingModal/ContactTableDisplaySettingModal";
import ContactCsvDownloadButton from "./ContactCsvDownloadButton/ContactCsvDownloadButton";
import ContactSearchDrawer from "./ContactSearchDrawer/ContactSearchDrawer";
import Paths from "~/components/Routes/Paths";

const pageId = CONTACT_SEARCH_PAGE;
const resourceURL = `${Endpoint.getBaseUrl()}/${Endpoint.contacts}`;
const columnSettingURL = `${Endpoint.getBaseUrl()}/${
    Endpoint.contactColumnSetting
}`;

const columns = [
    {
        name: "取引先担当者名",
        key: "last_name",
    },
    {
        name: "メールアドレス(TO)",
        key: "email",
    },
    {
        name: "メールアドレス(CC)",
        key: "cc_mails",
    },
    {
        name: "所属取引先",
        key: "organization__name",
    },
    {
        name: "自社担当者",
        key: "staff__name",
    },
    {
        name: "☆",
        key: "score",
    },
    {
        name: "作成日",
        key: "created_time",
    },
    {
        name: "更新日",
        key: "modified_time",
    },
];

const resourceName = "contacts";
const changeActiveStatusAuthorized = () => {
    return true;
};
const deleteAuthorized = (authorizedActions) => {
    return (
        authorizedActions &&
        authorizedActions[resourceName] &&
        authorizedActions[resourceName]["delete"]
    );
};
const csvAuthorized = (authorizedActions) => {
    return (
        authorizedActions &&
        authorizedActions[resourceName] &&
        authorizedActions[resourceName]["csv_upload"]
    );
};
const columnSettingAuthorized = (authorizedActions) => {
    return (
        authorizedActions &&
        authorizedActions[resourceName] &&
        authorizedActions[resourceName]["column_setting"]
    );
};
const accessAuthorized = () => {
    return true;
};
const searchTemplateAuthorized = (authorizedActions) => {
    return (
        authorizedActions &&
        authorizedActions[resourceName] &&
        authorizedActions[resourceName]["search_template"]
    );
};
const editAuthorized = (authorizedActions) => {
    return !!authorizedActions?.[resourceName]?.["update"];
};

export const searchConditionSanitizer = (unshowList, targetObj) => {
    const newData = { ...targetObj };
    for (const fieldName of unshowList) {
        if (fieldName === "name") {
            newData["first_name"] = undefined;
            newData["last_name"] = undefined;
        } else if (fieldName === "organizations") {
            newData["organization__name"] = undefined;
        } else if (fieldName === "tel") {
            newData["tel1"] = undefined;
            newData["tel2"] = undefined;
            newData["tel3"] = undefined;
        } else if (fieldName === "tag") {
            newData["tags__suffix"] = undefined;
            newData["tags"] = undefined;
        } else if (fieldName === "category") {
            newData["category_inequality"] = undefined;
            newData["category"] = undefined;
        } else if (fieldName === "email_to") {
            newData["email"] = undefined;
        } else if (fieldName === "email_cc") {
            newData["cc_mails"] = undefined;
        } else if (fieldName === "last_visit") {
            newData["date_range"] = undefined;
        } else if (fieldName === "preference") {
            newData["contact_preference"] = undefined;
        } else {
            newData[fieldName] = undefined;
        }
    }
    return newData;
};

const buildBulkEditUrl = (selectedRows) => {
    const query = new URLSearchParams();
    const contacts = selectedRows.map(
        ({ id, display_name }) => ({ id, name: display_name })
    );
    query.set("contacts", JSON.stringify(contacts));
    return `${Paths.contactBulkEdit}?${query}`;
};

const ContactSearchPageContainer = createSearchPage(
    pageId,
    "contactSearchPage",
    "取引先担当者 一覧",
    ContactSearchDrawer,
    ContactsTable,
    resourceURL,
    convertContactListResponseDataEntry,
    contactSearchParamToAPI,
    false,
    undefined,
    undefined, // csvDownloadURL,
    true,
    true,
    columns,
    columnSettingURL,
    undefined,
    undefined,
    undefined,
    changeActiveStatusAuthorized,
    deleteAuthorized,
    csvAuthorized,
    columnSettingAuthorized,
    accessAuthorized,
    undefined,
    searchTemplateAuthorized,
    resourceName,
    false,
    [ContactCsvDownloadButton],
    [ContactTableDisplaySettingModal],
    searchConditionSanitizer,
    undefined,
    undefined,
    true,
    editAuthorized,
    buildBulkEditUrl,
    undefined,
    ""
);

export default ContactSearchPageContainer;
