// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BoardCardControlButtons-spacer-DFcQO{margin-top:8px}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Common/Board/BoardCardControlButtons/BoardCardControlButtons.scss"],"names":[],"mappings":"AAAA,sCACI,cAAe","sourcesContent":[".spacer {\n    margin-top: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spacer": `BoardCardControlButtons-spacer-DFcQO`
};
export default ___CSS_LOADER_EXPORT___;
