import React, { useLayoutEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Col, Pagination, Row, Table, Tag } from "antd";
import { useRouter } from "~/hooks/router";
import { stringBreakByFullWidth } from "~/utils/utils";
import Path from "~/components/Routes/Paths";
import { getPaginationProps, setSortParams } from "../GenericTable";
import SharedEmailNotificationAssigneeHeader from "./SharedEmailNotificationAssigneeHeader";
import tableStyles from "../Table.scss";
import styles from "./SharedEmailNotificationsTable.scss";

const renderAssignedUserTag = (data, record) => {
    const userNames = data?.map((user) => ({
        user: user.user,
        displayName: stringBreakByFullWidth(user.displayName, 18).map(
            (name, index, arr) => {
                const isLastElement = index === arr.length - 1;
                return isLastElement ? (
                    <React.Fragment key={`${user.user}-${index}`}>
                        {name}
                    </React.Fragment>
                ) : (
                    <React.Fragment key={`${user.user}-${index}`}>
                        {name}
                        <br />
                    </React.Fragment>
                );
            }
        ),
    }));
    return (
        <React.Fragment key={record.id}>
            {userNames?.map((user) => (
                <Tag className={styles.assigneeTab} key={user.user}>
                    {user.displayName}
                </Tag>
            ))}
        </React.Fragment>
    );
};

export const TABLE_NAME = "shared_email_notifications";

// Declare as array to support other target type easily in the future.
const TARGET_TYPE_WITHOUT_VALUE = ["attachment"];

const masterRuleDisplayName = {
    all: "全ての条件に一致",
    any: "いずれかの条件に一致",
};

const targetDisplayNames = {
    from_address: "差出人",
    subject: "件名",
    content: "本文",
    attachment: "添付",
};

const conditionDisplayNames = {
    include: "に次を含む",
    exclude: "に次を含まない",
    equal: "が次と一致する",
    not_equal: "が次と異なる",
    start_with: "が次で始まる",
    end_with: "が次で終わる",
    exists: "あり",
    not_exists: "なし",
};

const columns = [
    {
        title: "ルール名",
        dataIndex: "name",
        key: "name",
        width: 300,
    },
    {
        title: SharedEmailNotificationAssigneeHeader,
        dataIndex: "assignees",
        key: "assignees",
        render: renderAssignedUserTag,
        width: 250,
    },
    {
        title: "ルール条件",
        dataIndex: "master_rule",
        key: "master_rule",
        render: (value) => masterRuleDisplayName[value],
        width: 200,
    },
    {
        title: "対象",
        dataIndex: "target",
        key: "target",
        render: (value) => targetDisplayNames[value],
        width: 200,
    },
    {
        title: "条件",
        dataIndex: "condition",
        key: "condition",
        render: (value) => conditionDisplayNames[value],
        width: 200,
    },
    {
        title: "値",
        dataIndex: "value",
        key: "value",
        width: 200,
    },
    {
        title: "作成日時",
        dataIndex: "created_time",
        key: "created_time",
        sorter: true,
        width: 200,
    },
    {
        title: "更新日時",
        dataIndex: "modified_time",
        key: "modified_time",
        sorter: true,
        width: 200,
    },
];

const dropUnnecessaryValueFromItem = (item) => {
    const purifiedRules = item?.rules?.map(dropUnnecessaryValueByTargetType);
    const purifiedChildren = item?.children?.map(
        dropUnnecessaryValueByTargetType
    );
    const purifiedConditions = item?.conditions?.map(
        dropUnnecessaryValueByTargetType
    );

    const purifiedData = {
        ...item,
        rules: purifiedRules,
        conditions: purifiedConditions,
        children: purifiedChildren,
    };
    return TARGET_TYPE_WITHOUT_VALUE.includes(item.target)
        ? { ...purifiedData, value: null }
        : purifiedData;
};

const dropUnnecessaryValueByTargetType = (data) => {
    if (
        !data?.hasOwnProperty("target") &&
        !data?.hasOwnProperty("target_type")
    ) {
        return data;
    }
    if (TARGET_TYPE_WITHOUT_VALUE.includes(data.target)) {
        return { ...data, value: null };
    }
    if (TARGET_TYPE_WITHOUT_VALUE.includes(data.target_type)) {
        return { ...data, value: null };
    }
    return data;
};

const findExpandColumnIndex = (hiddenColumnNames) => {
    const availableColumns = columns.filter(
        (col) => !hiddenColumnNames.includes(col.key)
    );
    const ruleRelatedFieldIndex = availableColumns
        .map((col) => col.key)
        // NOTE(@chikama):As or condition checked from left to right
        .findIndex(
            (key) => key === "target" || key === "condition" || key === "value"
        );
    const prefixForSelectColumn = 1;
    // NOTE(@chikama): because index column starts with 1
    const indexAdjustment = 1;
    // NOTE(@chikama): -1 is not found.
    return ruleRelatedFieldIndex === -1
        ? undefined
        : ruleRelatedFieldIndex + prefixForSelectColumn + indexAdjustment;
};

const SharedEmailNotificationsTable = ({
    data,
    sortKey,
    sortOrder,
    currentPage,
    totalCount,
    onPageChange,
    onPageSizeChange,
    onTableChange,
    loading,
    onCheckColumn,
    leftTopButtons,
    rightTopButtons,
    leftBottomButtons,
    rightBottomButtons,
    onRefetch,
    rowSelection = {},
}) => {
    const router = useRouter();
    const { displaySetting } = useSelector((state) => state.displaySettingPage);
    const firstRender = useRef(true);

    const hiddenColumnNames = displaySetting?.[TABLE_NAME]?.table ?? [];
    const pageSize = displaySetting?.[TABLE_NAME]?.page_size ?? 10;
    const paginationProps = getPaginationProps(
        currentPage,
        pageSize,
        totalCount,
        onPageChange,
        onPageSizeChange
    );
    const columnsWithSortParams = setSortParams(sortKey, sortOrder, columns);
    const visibleColumns = columnsWithSortParams.filter((column) => {
        return !hiddenColumnNames.includes(column.key);
    });
    const expandColumnIndex = findExpandColumnIndex(hiddenColumnNames);
    const dataSource = useMemo(() => {
        if (data) {
            const purifiedData = data
                .map(dropUnnecessaryValueFromItem)
                .map((item) => {
                    if (!item.children?.length || !expandColumnIndex) {
                        delete item.children;
                        return item;
                    }
                    return item;
                });
            return purifiedData;
        } else {
            return [];
        }
    }, [data, expandColumnIndex]);
    const selectedRowKeys = !!rowSelection.selectedRowKeys
        ? rowSelection.selectedRowKeys.map(
              (id) => dataSource.find((item) => item.id === id)?.key
          )
        : undefined;

    const onRowClick = (record) => {};

    const onColumnClick = (record) => {
        const url = `${Path.sharedMailNotifications}/${record.id}`;
        router.push(url);
    };

    const visibleColumnsWithHandler = useMemo(() => {
        return visibleColumns.map((column) => ({
            ...column,
            onCell: (record) => ({
                onClick: () => onColumnClick(record),
            }),
        }));
    }, [visibleColumns]);

    useLayoutEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        onRefetch(pageSize);
    }, [pageSize]);

    return (
        <Row span={24}>
            <Col span={24}>
                <Row span={24}>
                    <Col span={12}>
                        <Row justify="start">
                            {!!leftTopButtons && (
                                <>
                                    {leftTopButtons.map((button, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                {button}
                                            </React.Fragment>
                                        );
                                    })}
                                </>
                            )}
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row justify="end">
                            {!!rightTopButtons && (
                                <>
                                    {rightTopButtons.map((button, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                {button}
                                            </React.Fragment>
                                        );
                                    })}
                                </>
                            )}
                        </Row>
                    </Col>
                </Row>
                <Row span={24} style={{ marginTop: "1%", marginBottom: "1%" }}>
                    <Table
                        className={tableStyles.genericTable}
                        columns={visibleColumnsWithHandler}
                        dataSource={dataSource.length === 0 ? null : dataSource}
                        hasData={dataSource.length > 0}
                        bordered
                        total={totalCount}
                        onChange={onTableChange}
                        onRow={(record) => ({
                            onClick: () => onRowClick(record),
                        })}
                        loading={loading}
                        pagination={{
                            position: [],
                            pageSize,
                        }}
                        expandable={{
                            expandIconColumnIndex: expandColumnIndex,
                        }}
                        indentSize={!!expandColumnIndex ? 0 : 15}
                        rowSelection={{
                            type: "checkbox",
                            fixed: "left",
                            columnWidth: 35,
                            selectedRowKeys,
                            renderCell: (
                                checked,
                                record,
                                index,
                                originNode
                            ) => {
                                const key = record.key.toString();
                                return key.includes(":") ? null : (
                                    <Col key={record.id} span={24}>
                                        <Row justify="center">
                                            <Col>{originNode}</Col>
                                        </Row>
                                    </Col>
                                );
                            },
                            getCheckboxProps: (record) => ({
                                disabled: record.key.toString().includes(":")
                            }),
                            onChange: (_, selectedRows) => {
                                onCheckColumn(selectedRows);
                            },
                        }}
                        rowClassName={(record, _index) => {
                            if (
                                record.is_ignored === true ||
                                record.is_active === false
                            ) {
                                return tableStyles.ignored;
                            }
                        }}
                        scroll={{ y: 600, x: "100%" }}
                    />
                </Row>
                <Row span={24}>
                    <Col span={12}>
                        <Row justify="start">
                            {!!leftBottomButtons && (
                                <>
                                    {leftBottomButtons.map((button, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                {button}
                                            </React.Fragment>
                                        );
                                    })}
                                </>
                            )}
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row justify="end">
                            {!!rightBottomButtons && (
                                <>
                                    {rightBottomButtons.map((button, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                {button}
                                            </React.Fragment>
                                        );
                                    })}
                                </>
                            )}
                        </Row>
                    </Col>
                </Row>
                <Row span={24} justify="end">
                    <Col>
                        <Pagination
                            {...paginationProps}
                            className={tableStyles.paginator}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

SharedEmailNotificationsTable.propTypes = {
    currentPage: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired,
    sortKey: PropTypes.string,
    sortOrder: PropTypes.string,
    onPageChange: PropTypes.func.isRequired,
    onPageSizeChange: PropTypes.func.isRequired,
    onTableChange: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    data: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.number.isRequired,
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            rules: PropTypes.arrayOf(
                PropTypes.shape({
                    key: PropTypes.number.isRequired,
                    type: PropTypes.string.isRequired,
                    value: PropTypes.string.isRequired,
                    target: PropTypes.string.isRequired,
                    condition: PropTypes.string.isRequired,
                })
            ).isRequired,
        })
    ),
    onCheckColumn: PropTypes.func,
    rowSelection: PropTypes.object,
};

SharedEmailNotificationsTable.defaultProps = {
    data: [],
    sortKey: undefined,
    sortOrder: undefined,
};

export default SharedEmailNotificationsTable;
