import React from "react";
import { Col, Form, Select, Row, Tooltip } from "antd";
import {
    IdcardTwoTone,
    SafetyCertificateTwoTone,
    QuestionCircleFilled,
    AccountBookTwoTone,
} from "@ant-design/icons";
import { iconPrimaryColor, Links, iconCustomColor } from "~/utils/constants";
import styles from "./CorporateLicenseFormItem.scss";

const CorporateLicenseFormItem = ({ disabled = false }) => {
    const items = [
        {
            value: "has_p_mark_or_isms",
            title: "Pマーク／ISMS",
            icon: <SafetyCertificateTwoTone twoToneColor={iconPrimaryColor} />,
        },
        {
            value: "has_invoice_system",
            title: "インボイス登録事業者",
            icon: <AccountBookTwoTone twoToneColor={iconPrimaryColor} />,
        },
        {
            value: "has_haken",
            title: "労働者派遣事業",
            icon: <IdcardTwoTone twoToneColor={iconPrimaryColor} />,
        },
    ];

    return (
        <Col span={24} style={{ marginBottom: "1%" }}>
            <Row className={styles.container}>
                <Col span={23}>
                    <Form.Item colon={false} name="license" noStyle>
                        <Select
                            showSearch={false}
                            className={styles.container}
                            placeholder="保有資格"
                            mode="multiple"
                            allowClear
                            disabled={disabled}
                            getPopupContainer={(trigger) => trigger.parentNode}>
                            {items.map((item) => {
                                return (
                                    <Select.Option
                                        key={item.value}
                                        className={styles.select_option}
                                        value={item.value}>
                                        {item.icon} {item.title}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={1} className={styles.infoIcon}>
                    <Tooltip
                        title={
                            <span>
                                複数選択をすると
                                <a
                                    href={Links.helps.filter.or}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    OR検索
                                </a>
                            </span>
                        }>
                        <QuestionCircleFilled style={{ color: iconCustomColor }} className={styles.tooltip} />
                    </Tooltip>
                </Col>
            </Row>
        </Col>
    );
};

export default CorporateLicenseFormItem;
