// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ScheduledEmailAttachmentsFormItem-container-w3v1o{width:100%;text-align:left}.ScheduledEmailAttachmentsFormItem-userInput-K9VdO{width:100%}.ScheduledEmailAttachmentsFormItem-tooltip-QiSvo{margin-left:5px}.ScheduledEmailAttachmentsFormItem-infoIcon-XsMex{align-self:center}.ScheduledEmailAttachmentsFormItem-select_option-KmucB{text-align:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/ScheduledEmailSearchPage/ScheduledEmailSearchDrawer/ScheduledEmailAttachmentsFormItem/ScheduledEmailAttachmentsFormItem.scss"],"names":[],"mappings":"AAAA,mDACI,UAAW,CACX,eAAgB,CACnB,mDAGG,UAAW,CACd,iDAGG,eAAgB,CACnB,kDAGG,iBAAkB,CACrB,uDAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n\n.select_option {\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ScheduledEmailAttachmentsFormItem-container-w3v1o`,
	"userInput": `ScheduledEmailAttachmentsFormItem-userInput-K9VdO`,
	"tooltip": `ScheduledEmailAttachmentsFormItem-tooltip-QiSvo`,
	"infoIcon": `ScheduledEmailAttachmentsFormItem-infoIcon-XsMex`,
	"select_option": `ScheduledEmailAttachmentsFormItem-select_option-KmucB`
};
export default ___CSS_LOADER_EXPORT___;
