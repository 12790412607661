import CreateRegisterPage from './Factories/createRegisterPage';

import { CONTACT_REGISTER_PAGE } from './pageIds';

import ContactForm from '../Forms/ContactForm/ContactForm';

import { Endpoint } from '../../domain/api';
import Path, { Suffixes } from '../Routes/Paths';
import { convertContactResponseDataEntry, contactFormToAPI } from '../../domain/data';

const pageId = CONTACT_REGISTER_PAGE;
const resourceURL = `${Endpoint.getBaseUrl()}/${Endpoint.contacts}`;
const redirectAfterCreate = `${Path.contacts}`;
const resourceName = 'contacts'
const accessAuthorized = (authorizedActions) => { return authorizedActions && authorizedActions[resourceName] && authorizedActions[resourceName]['create'] }
const reducerName = 'contactRegisterPage';

const UserRegisterPageContainer = CreateRegisterPage(
  pageId,
  reducerName,
  '取引先担当者 登録',
  ContactForm,
  resourceURL,
  redirectAfterCreate,
  convertContactResponseDataEntry,
  contactFormToAPI,
  resourceName,
  accessAuthorized
);

export default UserRegisterPageContainer;
