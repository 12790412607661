"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var api_1 = require("~/domain/api");
var myCompany = function (client) {
    return {
        fetchCompanyInfo: function () {
            var url = api_1.Endpoint.myCompanyPath;
            return client.get(url);
        },
    };
};
exports.default = myCompany;
