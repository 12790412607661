// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CommentList-comment-zA4tT{border-top:dashed 1px lightgrey;border-bottom:dashed 1px lightgrey;margin:16px 0;text-align:left;padding:11px 10px 8px}.CommentList-importantComment-qY96Q{border-top:dashed 1px lightgrey;border-bottom:dashed 1px lightgrey;margin:16px 0;text-align:left;padding:11px 10px 8px;background-color:#fff0f5}.CommentList-avatar-ruSgt{font-size:40px}.CommentList-avatarChild-Wa0sr{margin-left:20px;margin-right:10px;font-size:20px}.CommentList-textHideStyle-UKYS2{display:flex;margin-left:50px}.CommentList-button-MYbsi{padding:0}.CommentList-editor-dH8G7{margin:auto;padding:16px 0;text-align:left}.CommentList-editorTextArea-AUWBP{padding:auto 16px}.CommentList-paginator-TuUV5{margin:16px auto;text-align:end}.CommentList-controlButton-uYdpC{margin-right:5px}.CommentList-listContainer-r2JTJ li div{width:100%}.CommentList-modalButton-PhDSi{width:100%}.CommentList-modalActions-pn4_K{margin-top:2%}.CommentList-editCancelButton-XQVzx{margin-left:8px}.CommentList-avatarImageWrapper-78JZB{width:40px;height:40px}.CommentList-avatarImage-iTGce{width:40px !important;height:40px !important;object-fit:cover;max-width:100%;max-height:100%}.CommentList-commentItem-NS4tZ{display:flex;align-items:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/DataDisplay/CommentList/CommentList.scss"],"names":[],"mappings":"AAQA,2BAPE,+BAAgC,CAChC,kCAAmC,CACnC,aAAc,CACd,eAAgB,CAChB,qBAAsB,CAKvB,oCATC,+BAAgC,CAChC,kCAAmC,CACnC,aAAc,CACd,eAAgB,CAChB,qBAAsB,CAStB,wBAAyB,CAC1B,0BAGC,cAAe,CAChB,+BAGC,gBAAiB,CACjB,iBAAkB,CAClB,cAAe,CAChB,iCAEC,YAAa,CACb,gBAAiB,CAClB,0BAGC,SAAU,CACX,0BAGC,WAAY,CACZ,cAAe,CACf,eAAgB,CACjB,kCAGC,iBAAkB,CACnB,6BAGC,gBAAiB,CACjB,cAAe,CAChB,iCAGC,gBAAiB,CAClB,wCAKK,UAAW,CACZ,+BAKH,UAAW,CACZ,gCAGC,aAAc,CACf,oCAGC,eAAgB,CACjB,sCAGG,UAAW,CACX,WAAY,CACf,+BAGG,qBAAsB,CACtB,sBAAuB,CACvB,gBAAiB,CACjB,cAAe,CACf,eAAgB,CACnB,+BAGG,YAAa,CACb,kBAAmB","sourcesContent":["@mixin commentBase {\n  border-top: dashed 1px lightgrey;\n  border-bottom: dashed 1px lightgrey;\n  margin: 16px 0;\n  text-align: left;\n  padding: 11px 10px 8px;\n}\n\n.comment {\n  @include commentBase();\n}\n\n.importantComment {\n  @include commentBase();\n  background-color: #fff0f5;\n}\n\n.avatar {\n  font-size: 40px;\n}\n\n.avatarChild {\n  margin-left: 20px;\n  margin-right: 10px;\n  font-size: 20px;\n}\n.textHideStyle {\n  display: flex;\n  margin-left: 50px;\n}\n\n.button {\n  padding: 0;\n}\n\n.editor {\n  margin: auto;\n  padding: 16px 0;\n  text-align: left;\n}\n\n.editorTextArea {\n  padding: auto 16px;\n}\n\n.paginator {\n  margin: 16px auto;\n  text-align: end;\n}\n\n.controlButton {\n  margin-right: 5px;\n}\n\n.listContainer {\n  li {\n    div {\n      width: 100%;\n    }\n  }\n}\n\n.modalButton {\n  width: 100%;\n}\n\n.modalActions {\n  margin-top: 2%;\n}\n\n.editCancelButton {\n  margin-left: 8px;\n}\n\n.avatarImageWrapper {\n    width: 40px;\n    height: 40px;\n}\n\n.avatarImage {\n    width: 40px !important;\n    height: 40px !important;\n    object-fit: cover;\n    max-width: 100%;\n    max-height: 100%;\n}\n\n.commentItem{\n    display: flex;\n    align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"comment": `CommentList-comment-zA4tT`,
	"importantComment": `CommentList-importantComment-qY96Q`,
	"avatar": `CommentList-avatar-ruSgt`,
	"avatarChild": `CommentList-avatarChild-Wa0sr`,
	"textHideStyle": `CommentList-textHideStyle-UKYS2`,
	"button": `CommentList-button-MYbsi`,
	"editor": `CommentList-editor-dH8G7`,
	"editorTextArea": `CommentList-editorTextArea-AUWBP`,
	"paginator": `CommentList-paginator-TuUV5`,
	"controlButton": `CommentList-controlButton-uYdpC`,
	"listContainer": `CommentList-listContainer-r2JTJ`,
	"modalButton": `CommentList-modalButton-PhDSi`,
	"modalActions": `CommentList-modalActions-pn4_K`,
	"editCancelButton": `CommentList-editCancelButton-XQVzx`,
	"avatarImageWrapper": `CommentList-avatarImageWrapper-78JZB`,
	"avatarImage": `CommentList-avatarImage-iTGce`,
	"commentItem": `CommentList-commentItem-NS4tZ`
};
export default ___CSS_LOADER_EXPORT___;
