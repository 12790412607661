"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var api_1 = require("~/domain/api");
var useUser_1 = require("~/hooks/useUser");
var utils_1 = require("~/utils/utils");
var user = function (client) {
    return {
        fetchUsers: function (params) {
            var url = api_1.Endpoint.users;
            if (params) {
                var queryParams = (0, utils_1.createQueryString)(params);
                url += "?" + queryParams;
            }
            return client.get(url);
        },
        register: function (_a) {
            var registerToken = _a.registerToken, data = _a.data;
            var url = api_1.Endpoint.usersRegister + "/" + registerToken;
            var postData = (0, useUser_1.convertUserRegisterFormModelToUserRegisterRequestDataModel)(data);
            if (postData.avatar) {
                postData = (0, utils_1.convertObjectToFormData)(postData);
            }
            return client.patch(url, postData);
        },
        update: function (_a) {
            var userId = _a.userId, data = _a.data;
            var url = api_1.Endpoint.users + "/" + userId;
            var postData = (0, useUser_1.convertUserFormModelToUserUpdateRequestDataModel)(data);
            if (postData.avatar) {
                postData = (0, utils_1.convertObjectToFormData)(postData);
            }
            return client.patch(url, postData);
        },
        simple: function (query) {
            var url = api_1.Endpoint.user.simple;
            if (query) {
                url += "?" + (0, utils_1.createQueryString)(query);
            }
            return client.get(url);
        },
        bulkUpdate: function (postData) {
            var url = api_1.Endpoint.users;
            var data = (0, utils_1.convertCamelObjectToSnakeObject)(postData);
            return client.patch(url, data);
        },
    };
};
exports.default = user;
