// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PlanCurrentUserCountInfo-sectionTitle-WNqaN{font-size:14px}.PlanCurrentUserCountInfo-sectionContent-NYVs1{font-size:24px}.PlanCurrentUserCountInfo-titleHelp-Y1Wky{font-size:0.625rem}.PlanCurrentUserCountInfo-titleContainer-QzKvr{margin-bottom:0.45rem}.PlanCurrentUserCountInfo-content-TEzWJ{font-size:1.5rem;line-height:1}.PlanCurrentUserCountInfo-contentHelp-wATbw{font-size:0.5rem}.PlanCurrentUserCountInfo-buttonsContainer-BatVl{margin-top:0.45rem}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/PlanPage/PlanCurrentUserInfo/PlanCurrentUserInfo.scss","webpack://./frontend/src/components/Pages/PlanPage/PlanCurrentUserInfo/PlanCurrentUserCountInfo/PlanCurrentUserCountInfo.scss"],"names":[],"mappings":"AAAA,6CACI,cAAe,CAClB,+CAGG,cAAe,CCHnB,0CACI,kBAAmB,CACtB,+CAGG,qBAAsB,CACzB,wCAGG,gBAAiB,CACjB,aAAc,CACjB,4CAGG,gBAAiB,CACpB,iDAGG,kBAAmB","sourcesContent":[".sectionTitle {\n    font-size: 14px;\n}\n\n.sectionContent {\n    font-size: 24px;\n}\n","@import \"../PlanCurrentUserInfo.scss\";\n\n.titleHelp {\n    font-size: 0.625rem;\n}\n\n.titleContainer {\n    margin-bottom: 0.45rem;\n}\n\n.content {\n    font-size: 1.5rem;\n    line-height: 1;\n}\n\n.contentHelp {\n    font-size: 0.5rem;\n}\n\n.buttonsContainer {\n    margin-top: 0.45rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sectionTitle": `PlanCurrentUserCountInfo-sectionTitle-WNqaN`,
	"sectionContent": `PlanCurrentUserCountInfo-sectionContent-NYVs1`,
	"titleHelp": `PlanCurrentUserCountInfo-titleHelp-Y1Wky`,
	"titleContainer": `PlanCurrentUserCountInfo-titleContainer-QzKvr`,
	"content": `PlanCurrentUserCountInfo-content-TEzWJ`,
	"contentHelp": `PlanCurrentUserCountInfo-contentHelp-wATbw`,
	"buttonsContainer": `PlanCurrentUserCountInfo-buttonsContainer-BatVl`
};
export default ___CSS_LOADER_EXPORT___;
