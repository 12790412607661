// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Table-paginator-KAF7h{margin:16px auto}.Table-tableWrapper-m0xAV{text-align:left}.Table-table-YyuTU{margin:16px auto}.Table-ignored-EWkIW{color:silver}.Table-genericTable-aOId9{word-break:break-all}.Table-newLine-qOuHi{white-space:pre-wrap}.Table-container-Jp9Az{margin-top:4%}.Table-tableSpacer-ucHDm{margin:7px 0}.Table-tooltipCopy-ICSJp:hover span{display:initial}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Tables/Table.scss"],"names":[],"mappings":"AAAA,uBACE,gBAAiB,CAClB,0BAGC,eAAgB,CACjB,mBAGC,gBAAiB,CAClB,qBAGC,YAAa,CACd,0BAGC,oBAAqB,CACtB,qBAGC,oBAAqB,CACtB,uBAGC,aAAc,CACf,yBAGC,YAAa,CACd,oCAKO,eAAgB","sourcesContent":[".paginator {\n  margin: 16px auto;\n}\n\n.tableWrapper {\n  text-align: left;\n}\n\n.table {\n  margin: 16px auto;\n}\n\n.ignored {\n  color: silver;\n}\n\n.genericTable {\n  word-break: break-all;\n}\n\n.newLine {\n  white-space: pre-wrap;\n}\n\n.container {\n  margin-top: 4%;\n}\n\n.tableSpacer {\n  margin: 7px 0;\n}\n\n.tooltipCopy {\n  &:hover {\n      span {\n        display: initial;\n      }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paginator": `Table-paginator-KAF7h`,
	"tableWrapper": `Table-tableWrapper-m0xAV`,
	"table": `Table-table-YyuTU`,
	"ignored": `Table-ignored-EWkIW`,
	"genericTable": `Table-genericTable-aOId9`,
	"newLine": `Table-newLine-qOuHi`,
	"container": `Table-container-Jp9Az`,
	"tableSpacer": `Table-tableSpacer-ucHDm`,
	"tooltipCopy": `Table-tooltipCopy-ICSJp`
};
export default ___CSS_LOADER_EXPORT___;
