"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCustomDebouncedCallback = void 0;
var use_debounce_1 = require("use-debounce");
var useCustomDebouncedCallback = function (callback, wait) {
    if (wait === void 0) { wait = 600; }
    return (0, use_debounce_1.useDebouncedCallback)(function (values) {
        callback(values);
    }, wait);
};
exports.useCustomDebouncedCallback = useCustomDebouncedCallback;
