"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useToggleableField = void 0;
var react_1 = require("react");
/**
 * Hook to manage states of a field which can be toggled enabled or disabled.
 * @param form FormInstance the field belongs to
 * @param name Field name
 * @param initialValue Value set when field is enabled
 * @param resetValue Value set when field is disabled
 */
var useToggleableField = function (form, name, initialValue, resetValue) {
    if (resetValue === void 0) { resetValue = undefined; }
    var _a = (0, react_1.useState)(true), disabled = _a[0], setDisabled = _a[1];
    var onDisabledChange = (0, react_1.useCallback)(function (e) {
        var _a, _b;
        var newDisabled = typeof e === "boolean" ? e : e.target.checked;
        setDisabled(newDisabled);
        if (newDisabled) {
            form.setFieldsValue((_a = {}, _a[name] = resetValue, _a));
        }
        else {
            form.setFieldsValue((_b = {}, _b[name] = initialValue, _b));
        }
    }, [form, name, JSON.stringify(initialValue)]);
    return [
        disabled,
        onDisabledChange,
    ];
};
exports.useToggleableField = useToggleableField;
