// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BoardChecklistPopoverContent-wrapper-mYOS8{width:100%}.BoardChecklistPopoverContent-form-Yk_fN{width:100%}.BoardChecklistPopoverContent-btn-Vz8yZ{width:100%;margin-top:10px;margin-bottom:10px}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Common/Board/BoardChecklistPopover/BoardChecklistPopoverContent/BoardChecklistPopoverContent.scss"],"names":[],"mappings":"AAAA,4CACI,UAAW,CACd,yCAGG,UAAW,CACd,wCAGG,UAAW,CACX,eAAgB,CAChB,kBAAmB","sourcesContent":[".wrapper {\n    width: 100%;\n}\n\n.form {\n    width: 100%;\n}\n\n.btn {\n    width: 100%;\n    margin-top: 10px;\n    margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `BoardChecklistPopoverContent-wrapper-mYOS8`,
	"form": `BoardChecklistPopoverContent-form-Yk_fN`,
	"btn": `BoardChecklistPopoverContent-btn-Vz8yZ`
};
export default ___CSS_LOADER_EXPORT___;
