"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthActions = void 0;
var actionTypes_1 = require("../actions/actionTypes");
var actionTypes_2 = require("~/actions/actionTypes");
exports.AuthActions = {
    updateAuthorizedActions: function (payload) {
        return {
            type: actionTypes_1.AUTHORIZED_ACTION_LOADED,
            payload: payload,
        };
    },
    loginAction: function (payload) {
        return {
            type: actionTypes_2.LOGIN,
            payload: payload,
            error: false,
        };
    },
    logoutAction: function () {
        return {
            type: actionTypes_2.LOGOUT,
            payload: undefined,
            error: false,
        };
    },
    loggingOutAction: function () {
        return {
            type: actionTypes_1.LOGGING_OUT,
            payload: undefined,
            error: false,
        };
    },
    loginErrorAction: function (payload) {
        return {
            type: actionTypes_2.LOGIN_ERROR,
            payload: payload,
            error: true,
        };
    },
    updateAvatarAfterChangeMyProfile: function (payload) {
        return {
            type: actionTypes_1.UPDATE_AVATAR_AFTER_CHANGE_MYPROFILE,
            payload: payload,
            error: true,
        };
    },
};
