// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SalesPriorityFormItem-container-FG3MG{width:100%;text-align:left}.SalesPriorityFormItem-userInput-bW0XX{width:100%}.SalesPriorityFormItem-tooltip-f8Vkr{margin-left:5px}.SalesPriorityFormItem-infoIcon-VhUyT{align-self:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/sales/SalesGanttPage/SalesGanttSearchDrawer/SalesPriorityFormItem/SalesPriorityFormItem.scss"],"names":[],"mappings":"AAAA,uCACI,UAAW,CACX,eAAgB,CACnB,uCAGG,UAAW,CACd,qCAGG,eAAgB,CACnB,sCAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `SalesPriorityFormItem-container-FG3MG`,
	"userInput": `SalesPriorityFormItem-userInput-bW0XX`,
	"tooltip": `SalesPriorityFormItem-tooltip-f8Vkr`,
	"infoIcon": `SalesPriorityFormItem-infoIcon-VhUyT`
};
export default ___CSS_LOADER_EXPORT___;
