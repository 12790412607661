// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CommentTemplateModal-modalButton-cPa1y{width:100%}.CommentTemplateModal-listContainer-Q0KfH{max-height:35vh;overflow-x:scroll}.CommentTemplateModal-listContainer-Q0KfH li div{width:100%}.CommentTemplateModal-modalActions-nNoXw{margin-top:2%}.CommentTemplateModal-editCancelButton-Sxszg{margin-left:8px}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Modals/CommentTemplateModal/CommentTemplateModal.scss"],"names":[],"mappings":"AAAA,wCACI,UAAW,CACd,0CAGG,eAAgB,CAChB,iBAAkB,CAFtB,iDAKY,UAAW,CACd,yCAKL,aAAc,CACjB,6CAGG,eAAgB","sourcesContent":[".modalButton {\n    width: 100%;\n}\n\n.listContainer {\n    max-height: 35vh;\n    overflow-x: scroll;\n    li {\n        div {\n            width: 100%;\n        }\n    }\n}\n\n.modalActions {\n    margin-top: 2%;\n}\n\n.editCancelButton {\n    margin-left: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalButton": `CommentTemplateModal-modalButton-cPa1y`,
	"listContainer": `CommentTemplateModal-listContainer-Q0KfH`,
	"modalActions": `CommentTemplateModal-modalActions-nNoXw`,
	"editCancelButton": `CommentTemplateModal-editCancelButton-Sxszg`
};
export default ___CSS_LOADER_EXPORT___;
