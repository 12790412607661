"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var api_1 = require("~/domain/api");
var utils_1 = require("~/utils/utils");
var contacts = function (client) {
    return {
        simple: function (query) {
            var url = api_1.Endpoint.contact.simple;
            if (query) {
                var organizationId = query.organizationId, rest = __rest(query, ["organizationId"]);
                var queryString = (0, utils_1.createQueryString)(rest);
                url += "?" + queryString;
                if (organizationId) {
                    url += "&organization=" + organizationId;
                }
            }
            return client.get(url);
        },
        bulkUpdate: function (postData) {
            var url = api_1.Endpoint.contacts;
            var data = (0, utils_1.convertCamelObjectToSnakeObject)(postData);
            return client.patch(url, data);
        },
    };
};
exports.default = contacts;
