// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CorporateBranchAddressFormItem-container-IGVFO{width:100%;text-align:left}.CorporateBranchAddressFormItem-userInput-ynkIm{width:100%}.CorporateBranchAddressFormItem-tooltip-A8T5_{margin-left:5px}.CorporateBranchAddressFormItem-infoIcon-E9hLs{align-self:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/OrganizationSearchPage/OrganizationSearchDrawer/CorporateBranchAddressFormItem/CorporateBranchAddressFormItem.scss"],"names":[],"mappings":"AAAA,gDACI,UAAW,CACX,eAAgB,CACnB,gDAGG,UAAW,CACd,8CAGG,eAAgB,CACnB,+CAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `CorporateBranchAddressFormItem-container-IGVFO`,
	"userInput": `CorporateBranchAddressFormItem-userInput-ynkIm`,
	"tooltip": `CorporateBranchAddressFormItem-tooltip-A8T5_`,
	"infoIcon": `CorporateBranchAddressFormItem-infoIcon-E9hLs`
};
export default ___CSS_LOADER_EXPORT___;
