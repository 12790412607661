// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PersonnelProjectDescriptionFormItem-container-Rvw2r{width:100%;text-align:left}.PersonnelProjectDescriptionFormItem-userInput-XLxfm{width:100%}.PersonnelProjectDescriptionFormItem-tooltip-WsLGV{margin-left:5px}.PersonnelProjectDescriptionFormItem-infoIcon-VAaT6{align-self:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/personnel/PersonnelBoardPage/PersonnelBoardSearchDrawer/PersonnelProjectDescriptionFormItem/PersonnelProjectDescriptionFormItem.scss"],"names":[],"mappings":"AAAA,qDACI,UAAW,CACX,eAAgB,CACnB,qDAGG,UAAW,CACd,mDAGG,eAAgB,CACnB,oDAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PersonnelProjectDescriptionFormItem-container-Rvw2r`,
	"userInput": `PersonnelProjectDescriptionFormItem-userInput-XLxfm`,
	"tooltip": `PersonnelProjectDescriptionFormItem-tooltip-WsLGV`,
	"infoIcon": `PersonnelProjectDescriptionFormItem-infoIcon-VAaT6`
};
export default ___CSS_LOADER_EXPORT___;
