// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.validate-textarea .ant-form-item-explain-connected{display:none !important;margin-bottom:0px !important}.validate-textarea.ant-form-item{margin-bottom:0px !important}.validate-textarea-error{display:flex;justify-content:space-between}.field{max-width:475px;margin:8px 0}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Common/ValidateTextArea/ValidateTextArea.scss"],"names":[],"mappings":"AAAA,oDAEQ,uBAAwB,CACxB,4BAA6B,CAHrC,iCAMQ,4BAA6B,CANrC,yBASQ,YAAa,CACb,6BAA8B,CAVtC,OAaQ,eAAgB,CAChB,YAAa","sourcesContent":[":global{\n    .validate-textarea .ant-form-item-explain-connected{\n        display: none !important;\n        margin-bottom: 0px !important;\n    }\n    .validate-textarea.ant-form-item{\n        margin-bottom: 0px !important;\n    }\n    .validate-textarea-error{\n        display: flex;\n        justify-content: space-between;\n    }\n    .field{\n        max-width: 475px;\n        margin: 8px 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
