// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PlanFunctionTable-personalPlanText-OKRa1{display:block;font-size:14px}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/PlanPage/PlanFunctionTable/PlanFunctionTable.scss"],"names":[],"mappings":"AAAA,0CACI,aAAc,CACd,cAAe","sourcesContent":[".personalPlanText {\n    display: block;\n    font-size: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"personalPlanText": `PlanFunctionTable-personalPlanText-OKRa1`
};
export default ___CSS_LOADER_EXPORT___;
