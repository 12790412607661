"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultInitalState = void 0;
var actionTypes_1 = require("~/actions/actionTypes");
exports.defaultInitalState = {
    loading: false,
    requireRefresh: true,
    templates: [],
    total_available_count: 0,
};
var creatCheckListReducer = function (pageId, initialState) {
    if (initialState === void 0) { initialState = exports.defaultInitalState; }
    var reducer = function (state, action) {
        if (state === void 0) { state = initialState; }
        switch (action.type) {
            case pageId + actionTypes_1.CHECKLIST_TEMPLATE_LOADING:
                return __assign(__assign({}, state), { loading: true, requireRefresh: false });
            case pageId + actionTypes_1.CHECKLIST_TEMPLATE_LOADED:
                return __assign(__assign({}, state), { loading: false, templates: action.payload.templates, total_available_count: action.payload.total_available_count });
            case pageId + actionTypes_1.CHECKLIST_TEMPLATE_CREATED:
                return __assign(__assign({}, state), { templates: action.payload.templates, total_available_count: action.payload.total_available_count });
            case pageId + actionTypes_1.CHECKLIST_TEMPLATE_COMMITTED:
                return __assign(__assign({}, state), { requireRefresh: true });
            default:
                return state;
        }
    };
    return reducer;
};
exports.default = creatCheckListReducer;
