// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CheckListTemplateModal-modalButton-EmqO9{width:100%}.CheckListTemplateModal-listContainer-Qcyn3{max-height:35vh;overflow-x:scroll}.CheckListTemplateModal-listContainer-Qcyn3 li div{width:100%}.CheckListTemplateModal-modalActions-Yn6V2{margin-top:2%}.CheckListTemplateModal-editCancelButton-OA4sQ{margin-left:8px}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Modals/CheckListTemplateModal/CheckListTemplateModal.scss"],"names":[],"mappings":"AAAA,0CACI,UAAW,CACd,4CAGG,eAAgB,CAChB,iBAAkB,CAFtB,mDAKY,UAAW,CACd,2CAKL,aAAc,CACjB,+CAGG,eAAgB","sourcesContent":[".modalButton {\n    width: 100%;\n}\n\n.listContainer {\n    max-height: 35vh;\n    overflow-x: scroll;\n    li {\n        div {\n            width: 100%;\n        }\n    }\n}\n\n.modalActions {\n    margin-top: 2%;\n}\n\n.editCancelButton {\n    margin-left: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalButton": `CheckListTemplateModal-modalButton-EmqO9`,
	"listContainer": `CheckListTemplateModal-listContainer-Qcyn3`,
	"modalActions": `CheckListTemplateModal-modalActions-Yn6V2`,
	"editCancelButton": `CheckListTemplateModal-editCancelButton-OA4sQ`
};
export default ___CSS_LOADER_EXPORT___;
