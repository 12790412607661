// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CorporateHasDistributionFormItem-container-ljk_D{width:100%;text-align:left}.CorporateHasDistributionFormItem-userInput-ixOHe{width:100%}.CorporateHasDistributionFormItem-tooltip-ZJysj{margin-left:5px}.CorporateHasDistributionFormItem-infoIcon-xzqdr{align-self:center}.CorporateHasDistributionFormItem-select_option-Xfn4N{text-align:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/OrganizationSearchPage/OrganizationSearchDrawer/CorporateHasDistributionFormItem/CorporateHasDistributionFormItem.scss"],"names":[],"mappings":"AAAA,kDACI,UAAW,CACX,eAAgB,CACnB,kDAGG,UAAW,CACd,gDAGG,eAAgB,CACnB,iDAGG,iBAAkB,CACrB,sDAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n\n.select_option {\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `CorporateHasDistributionFormItem-container-ljk_D`,
	"userInput": `CorporateHasDistributionFormItem-userInput-ixOHe`,
	"tooltip": `CorporateHasDistributionFormItem-tooltip-ZJysj`,
	"infoIcon": `CorporateHasDistributionFormItem-infoIcon-xzqdr`,
	"select_option": `CorporateHasDistributionFormItem-select_option-Xfn4N`
};
export default ___CSS_LOADER_EXPORT___;
