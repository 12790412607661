// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TagSearchForm-container-Fhb6X{width:100%;text-align:left;align-items:center}.TagSearchForm-marginTop-c5n28{margin-top:1%}.TagSearchForm-isSkillIcon-N8MKd{margin-right:0.25rem;color:#a7cf69}.TagSearchForm-tag-W40ou{margin-top:3px;margin-bottom:3px;margin-inline-end:4.8px;padding-inline-start:4px;padding-inline-end:4px;white-space:normal}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/TagPage/TagSearchForm/TagSearchForm.scss","webpack://./frontend/stylesheets/constants.scss"],"names":[],"mappings":"AAEA,+BACI,UAAW,CACX,eAAgB,CAChB,kBAAmB,CACtB,+BAGG,aAAc,CACjB,iCAGG,oBAAqB,CACrB,aCXmB,CDYtB,yBAGG,cAAe,CACf,iBAAkB,CAClB,uBAAwB,CACxB,wBAAyB,CACzB,sBAAuB,CACvB,kBAAmB","sourcesContent":["@import \"~coreStylesheet\";\n\n.container {\n    width: 100%;\n    text-align: left;\n    align-items: center;\n}\n\n.marginTop {\n    margin-top: 1%;\n}\n\n.isSkillIcon {\n    margin-right: 0.25rem;\n    color: $primary-color;\n}\n\n.tag {\n    margin-top: 3px;\n    margin-bottom: 3px;\n    margin-inline-end: 4.8px;\n    padding-inline-start: 4px;\n    padding-inline-end: 4px;\n    white-space: normal;\n}\n","/* アプリケーション全体 */\n$breakpoint-mobile: 768px;\n$width-small-screen: 1500px;\n$primary-color: #a7cf69;\n$komorebi-brown: #615748;\n\n/* ガントチャート関連 */\n$gantt-over70: #003656;\n$gantt-between40to70: #80d6ff;\n$gantt-under40: #d0edff;\n\n@mixin mobile-devices() {\n    @media screen and (max-width: $breakpoint-mobile) {\n        @content;\n    }\n}\n\n@mixin small-screen() {\n    @media screen and (max-width: $width-small-screen) {\n        @content;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `TagSearchForm-container-Fhb6X`,
	"marginTop": `TagSearchForm-marginTop-c5n28`,
	"isSkillIcon": `TagSearchForm-isSkillIcon-N8MKd`,
	"tag": `TagSearchForm-tag-W40ou`
};
export default ___CSS_LOADER_EXPORT___;
