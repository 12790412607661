"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var actionTypes_1 = require("~/actions/actionTypes");
var DEFAULT_STATE = {
    isLoading: false,
    data: undefined,
    error: undefined,
};
var planSummaryReducer = function (state, action) {
    if (state === void 0) { state = DEFAULT_STATE; }
    if (!(action === null || action === void 0 ? void 0 : action.type)) {
        return state;
    }
    switch (action.type) {
        case actionTypes_1.PLAN_FETCHING:
            return __assign(__assign({}, state), { isLoading: true });
        case actionTypes_1.PLAN_FETCHED:
            return {
                isLoading: false,
                data: action.payload
            };
        default:
            return state;
    }
};
exports.default = {
    planSummaryReducer: planSummaryReducer
};
