// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CommentUpdateButton-controlButton-noW2U{margin-right:5px !important;border-color:#82b435}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/DataDisplay/CommentList/CommentUpdateButton/CommentUpdateButton.scss"],"names":[],"mappings":"AAAA,yCACI,2BAA4B,CAC5B,oBAAqB","sourcesContent":[".controlButton {\n    margin-right: 5px !important;\n    border-color: #82b435;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"controlButton": `CommentUpdateButton-controlButton-noW2U`
};
export default ___CSS_LOADER_EXPORT___;
