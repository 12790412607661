// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PerGPriceFormItem-container-ZZTCD{width:100%;text-align:left}.PerGPriceFormItem-userInput-Bs_O6{width:100%}.PerGPriceFormItem-tooltip-Vvcee{margin-left:5px}.PerGPriceFormItem-infoIcon-wbUjo{align-self:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/personnel/PersonnelGanttPage/PersonnelGanttSearchDrawer/PerGPriceFormItem/PerGPriceFormItem.scss"],"names":[],"mappings":"AAAA,mCACI,UAAW,CACX,eAAgB,CACnB,mCAGG,UAAW,CACd,iCAGG,eAAgB,CACnB,kCAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PerGPriceFormItem-container-ZZTCD`,
	"userInput": `PerGPriceFormItem-userInput-Bs_O6`,
	"tooltip": `PerGPriceFormItem-tooltip-Vvcee`,
	"infoIcon": `PerGPriceFormItem-infoIcon-wbUjo`
};
export default ___CSS_LOADER_EXPORT___;
