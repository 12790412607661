import { combineReducers } from "redux";

import login from "./login";
import {
    myProfilePage,
    myCompanyPage,
    userSearchPage,
    userRegisterPage,
    userInvitePage,
    userEditPage,
    contactSearchPage,
    contactRegisterPage,
    contactCsvUploadPage,
    contactEditPage,
    contactEditPageComments,
    contactEmailPreferenceEditPage,
    sharedEmailPage,
    sharedEmailDetailPage,
    sharedEmailDetailPageComments,
    personnelBoardDetailPageComments,
    personnelBoardDetailPageNewComments,
    personnelBoardDetailPageEditComments,
    personnelBoardDetailPageReplyComments,
    projectBoardDetailPageComments,
    projectBoardDetailPageNewComments,
    projectBoardDetailPageEditComments,
    projectBoardDetailPageReplyComments,
    organizationSearchPage,
    organizationRegisterPage,
    organizationCsvUploadPage,
    organizationEditPage,
    organizationEditPageComments,
    scheduledEmailSearchPage,
    scheduledEmailRegisterPage,
    scheduledEmailPageTemplates,
    scheduledEmailEditPageTemplates,
    scheduledEmailRegisterPageContactSearchForm,
    scheduledEmailEditPage,
    scheduledEmailEditPageContactSearchForm,
    sharedEmailNotificationSearchPage,
    sharedEmailNotificationRegisterPage,
    sharedEmailNotificationEditPage,
    emailStatsContainer,
    organizationStatsContainer,
    dashboardStatsContainer,
    displaySettingPage,
    sharedEmailSettingPage,
    passwordChangePage,
    sharedEmailDetailPageNewComments,
    organizationEditPageNewComments,
    contactEditPageNewComments,
    sharedEmailDetailPageEditComments,
    sharedEmailDetailPageReplyComments,
    organizationEditPageEditComments,
    contactEditPageEditComments,
    contactEditPageReplyComments,
    organizationEditPageReplyComments,
    checkEmailExistRegister,
    checkEmailExistEdit,
    personnelBoardPage,
    projectBoardPage,
    sharedMailSearchPage,
    personnelDetailBoardPage,
    projectDetailBoardPage,
    recruitBoardDetailPageComments,
    recruitBoardDetailPageNewComments,
    recruitBoardDetailPageEditComments,
    recruitBoardDetailPageReplyComments,
    recruitBoardPage,
    recruitDetailBoardPage,
    salesBoardDetailPageComments,
    salesBoardDetailPageNewComments,
    salesBoardDetailPageEditComments,
    salesBoardDetailPageReplyComments,
    salesBoardPage,
    salesDetailBoardPage,
    backOfficeBoardDetailPageComments,
    backOfficeBoardDetailPageNewComments,
    backOfficeBoardDetailPageEditComments,
    backOfficeBoardDetailPageReplyComments,
    backOfficeBoardPage,
    backOfficeDetailBoardPage,
    personnelBoardPageCheckList,
    projectBoardPageCheckList,
    recruitBoardPageCheckList,
    salesBoardPageCheckList,
    backOfficeBoardPageCheckList,
    emailValidateTime,
} from "./pages";
import { systemNotificationReducer } from "./notificationReducer";
import { paymentInfo, paymentForm, paymentDelete, paymentUpdate } from "./paymentReducer";

import { scheduledEmailPreviewContainer } from "./containers";
import PlanReducer from "./planReducer";

export default combineReducers({
    login,
    myProfilePage,
    myCompanyPage,
    userSearchPage,
    userRegisterPage,
    userInvitePage,
    userEditPage,
    contactEmailPreferenceEditPage,
    contactSearchPage,
    contactRegisterPage,
    contactCsvUploadPage,
    contactEditPage,
    contactEditPageComments,
    sharedEmailPage,
    sharedEmailDetailPage,
    sharedEmailDetailPageComments,
    personnelBoardDetailPageComments,
    personnelBoardDetailPageNewComments,
    personnelBoardDetailPageEditComments,
    personnelBoardDetailPageReplyComments,
    projectBoardDetailPageComments,
    projectBoardDetailPageNewComments,
    projectBoardDetailPageEditComments,
    projectBoardDetailPageReplyComments,
    organizationSearchPage,
    organizationRegisterPage,
    organizationCsvUploadPage,
    organizationEditPage,
    organizationEditPageComments,
    scheduledEmailSearchPage,
    scheduledEmailRegisterPage,
    scheduledEmailPageTemplates,
    scheduledEmailEditPageTemplates,
    scheduledEmailRegisterPageContactSearchForm,
    scheduledEmailEditPage,
    scheduledEmailEditPageContactSearchForm,
    scheduledEmailPreviewContainer,
    sharedEmailNotificationSearchPage,
    sharedEmailNotificationRegisterPage,
    sharedEmailNotificationEditPage,
    emailStatsContainer,
    organizationStatsContainer,
    dashboardStatsContainer,
    displaySettingPage,
    sharedEmailSettingPage,
    passwordChangePage,
    systemNotificationReducer,
    sharedEmailDetailPageNewComments,
    sharedEmailDetailPageReplyComments,
    organizationEditPageNewComments,
    contactEditPageNewComments,
    sharedEmailDetailPageEditComments,
    organizationEditPageEditComments,
    contactEditPageEditComments,
    contactEditPageReplyComments,
    organizationEditPageReplyComments,
    paymentInfo,
    paymentForm,
    paymentDelete,
    paymentUpdate,
    checkEmailExistRegister,
    checkEmailExistEdit,
    personnelBoardPage,
    personnelDetailBoardPage,
    projectBoardPage,
    projectDetailBoardPage,
    sharedMailSearchPage,
    planSummary: PlanReducer.planSummaryReducer,
    recruitBoardDetailPageComments,
    recruitBoardDetailPageNewComments,
    recruitBoardDetailPageEditComments,
    recruitBoardDetailPageReplyComments,
    recruitBoardPage,
    recruitDetailBoardPage,
    salesBoardDetailPageComments,
    salesBoardDetailPageNewComments,
    salesBoardDetailPageEditComments,
    salesBoardDetailPageReplyComments,
    salesBoardPage,
    salesDetailBoardPage,
    backOfficeBoardDetailPageComments,
    backOfficeBoardDetailPageNewComments,
    backOfficeBoardDetailPageEditComments,
    backOfficeBoardDetailPageReplyComments,
    backOfficeBoardPage,
    backOfficeDetailBoardPage,
    personnelBoardPageCheckList,
    projectBoardPageCheckList,
    recruitBoardPageCheckList,
    salesBoardPageCheckList,
    backOfficeBoardPageCheckList,
    emailValidateTime,
});
