import createEditPage from './Factories/createEditPage';

import Paths from '../Routes/Paths';
import { SHARED_EMAIL_NOTIFICATION_EDIT_PAGE } from './pageIds';

import SharedEmailNotificationFormWrapper from '../Forms/SharedEmailNotificationForm/SharedEmailNotificationFormWrapper';
import { Endpoint } from '../../domain/api';
import { convertSharedEmailNotificationResponseDataEntry, SharedEmailNotificationFormToAPI } from '../../domain/data';

const pageId = SHARED_EMAIL_NOTIFICATION_EDIT_PAGE;
const resourceURL = `${Endpoint.getBaseUrl()}/${Endpoint.sharedEmailNotifications}`;

const resourceName = 'shared_email_notification'
const accessAuthorized = (authorizedActions) => { return authorizedActions && authorizedActions[resourceName] && authorizedActions[resourceName]['read'] }
const deleteAuthorized = (authorizedActions) => { return authorizedActions && authorizedActions[resourceName] && authorizedActions[resourceName]['delete'] }

const SharedEmailNotificationEditPageContainer = createEditPage(
  pageId,
  'sharedEmailNotificationEditPage',
  '自動マッチング条件 編集',
  SharedEmailNotificationFormWrapper,
  resourceURL,
  '',
  Paths.sharedMailNotifications,
  convertSharedEmailNotificationResponseDataEntry,
  SharedEmailNotificationFormToAPI,
  undefined,
  undefined,
  deleteAuthorized,
  accessAuthorized,
    undefined,
    true,
    () => '自動マッチング条件 編集',
    true,
    "",
    undefined,
    undefined,
    undefined,
);

export default SharedEmailNotificationEditPageContainer;
