// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PersonnelAffiliationFormItem-container-LznPO{width:100%;text-align:left}.PersonnelAffiliationFormItem-userInput-IDuTe{width:100%}.PersonnelAffiliationFormItem-tooltip-gbi1L{margin-left:5px}.PersonnelAffiliationFormItem-infoIcon-K5NXs{align-self:center}.PersonnelAffiliationFormItem-select_option-X2Xg1{text-align:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/personnel/PersonnelBoardPage/PersonnelBoardSearchDrawer/PersonnelAffiliationFormItem/PersonnelAffiliationFormItem.scss"],"names":[],"mappings":"AAAA,8CACI,UAAW,CACX,eAAgB,CACnB,8CAGG,UAAW,CACd,4CAGG,eAAgB,CACnB,6CAGG,iBAAkB,CACrB,kDAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n\n.select_option {\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PersonnelAffiliationFormItem-container-LznPO`,
	"userInput": `PersonnelAffiliationFormItem-userInput-IDuTe`,
	"tooltip": `PersonnelAffiliationFormItem-tooltip-gbi1L`,
	"infoIcon": `PersonnelAffiliationFormItem-infoIcon-K5NXs`,
	"select_option": `PersonnelAffiliationFormItem-select_option-X2Xg1`
};
export default ___CSS_LOADER_EXPORT___;
