// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PersonnelHigherOrganizationFormItem-container-RPg5B{width:100%;text-align:left;margin-bottom:1%}.PersonnelHigherOrganizationFormItem-userInput-RV_Ic{width:100%}.PersonnelHigherOrganizationFormItem-tooltip-najyq{margin-left:5px}.PersonnelHigherOrganizationFormItem-infoIcon-_qsn1{align-self:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/personnel/PersonnelBoardPage/PersonnelBoardSearchDrawer/PersonnelHigherOrganizationFormItem/PersonnelHigherOrganizationFormItem.scss"],"names":[],"mappings":"AAAA,qDACI,UAAW,CACX,eAAgB,CAChB,gBAAiB,CACpB,qDAGG,UAAW,CACd,mDAGG,eAAgB,CACnB,oDAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n    margin-bottom: 1%;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PersonnelHigherOrganizationFormItem-container-RPg5B`,
	"userInput": `PersonnelHigherOrganizationFormItem-userInput-RV_Ic`,
	"tooltip": `PersonnelHigherOrganizationFormItem-tooltip-najyq`,
	"infoIcon": `PersonnelHigherOrganizationFormItem-infoIcon-_qsn1`
};
export default ___CSS_LOADER_EXPORT___;
