// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BackOfficeDescriptionFormItem-container-ohZKt{width:100%;text-align:left}.BackOfficeDescriptionFormItem-userInput-N7Tlc{width:100%}.BackOfficeDescriptionFormItem-tooltip-gHPnt{margin-left:5px}.BackOfficeDescriptionFormItem-infoIcon-jdQM6{align-self:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/backOffice/BackOfficeBoardPage/BackOfficeBoardSearchDrawer/BackOfficeDescriptionFormItem/BackOfficeDescriptionFormItem.scss"],"names":[],"mappings":"AAAA,+CACI,UAAW,CACX,eAAgB,CACnB,+CAGG,UAAW,CACd,6CAGG,eAAgB,CACnB,8CAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `BackOfficeDescriptionFormItem-container-ohZKt`,
	"userInput": `BackOfficeDescriptionFormItem-userInput-N7Tlc`,
	"tooltip": `BackOfficeDescriptionFormItem-tooltip-gHPnt`,
	"infoIcon": `BackOfficeDescriptionFormItem-infoIcon-jdQM6`
};
export default ___CSS_LOADER_EXPORT___;
