// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContactDepartmentFormItem-container-Hr_Xd{width:100%;text-align:left}.ContactDepartmentFormItem-userInput-_U778{width:100%}.ContactDepartmentFormItem-tooltip-WMC7G{margin-left:5px}.ContactDepartmentFormItem-infoIcon-k6Oz5{align-self:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/ContactSearchPage/ContactSearchDrawer/ContactDepartmentFormItem/ContactDepartmentFormItem.scss"],"names":[],"mappings":"AAAA,2CACI,UAAW,CACX,eAAgB,CACnB,2CAGG,UAAW,CACd,yCAGG,eAAgB,CACnB,0CAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ContactDepartmentFormItem-container-Hr_Xd`,
	"userInput": `ContactDepartmentFormItem-userInput-_U778`,
	"tooltip": `ContactDepartmentFormItem-tooltip-WMC7G`,
	"infoIcon": `ContactDepartmentFormItem-infoIcon-k6Oz5`
};
export default ___CSS_LOADER_EXPORT___;
