// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContactScheduledEmailTable-nowrap-NYiFy{white-space:nowrap}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/ContactDetailPage/ContactScheduledEmailTable/ContactScheduledEmailTable.scss"],"names":[],"mappings":"AAAA,yCACI,kBAAmB","sourcesContent":[".nowrap {\n    white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nowrap": `ContactScheduledEmailTable-nowrap-NYiFy`
};
export default ___CSS_LOADER_EXPORT___;
