"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createValidateTimeReducer = exports.validateTimeState = void 0;
var actionTypes_1 = require("~/actions/actionTypes");
exports.validateTimeState = {
    time: 0
};
var createValidateTimeReducer = function (initialState) {
    if (initialState === void 0) { initialState = exports.validateTimeState; }
    var validateTimeReducer = function (state, action) {
        if (state === void 0) { state = initialState; }
        switch (action.type) {
            case actionTypes_1.UPDATE_TIME:
                return { time: action.payload.time };
            case actionTypes_1.CLEAR_TIME:
                return { time: 0 };
            default:
                return __assign({}, state);
        }
    };
    return validateTimeReducer;
};
exports.createValidateTimeReducer = createValidateTimeReducer;
