// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RecruitBoardBaseInfoForm-section-HPjL2{margin-top:3rem}.RecruitBoardBaseInfoForm-tagSelect-JJ75j{max-width:none;margin:8px 0 24px}@media screen and (max-width: 768px){.RecruitBoardBaseInfoForm-tagSelect-JJ75j{margin:8px auto;width:100%}}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/recruit/RecruitBoardPage/RecruitBoardDetailModal/RecruitBoardBaseInfoForm/RecruitBoardBaseInfoForm.scss"],"names":[],"mappings":"AAEA,wCACI,eAAgB,CACnB,0CAGG,cAAe,CACf,iBAAkB,CAKrB,qCAPD,0CAIQ,eAAgB,CAChB,UAAW,CAElB","sourcesContent":["@import \"../../../../../../../stylesheets/constants.scss\";\n\n.section {\n    margin-top: 3rem;\n}\n\n.tagSelect {\n    max-width: none;\n    margin: 8px 0 24px;\n    @include mobile-devices {\n        margin: 8px auto;\n        width: 100%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `RecruitBoardBaseInfoForm-section-HPjL2`,
	"tagSelect": `RecruitBoardBaseInfoForm-tagSelect-JJ75j`
};
export default ___CSS_LOADER_EXPORT___;
