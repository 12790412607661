import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button } from 'antd';

import { downloadFile } from '../../domain/api';

class _DownloadLink extends Component {
  onClick = () => {
    const {
      token, resourceBaseUrl, resourceId, fileName,
    } = this.props;
    const resourceUrl = `${resourceBaseUrl}/${resourceId}`;
    downloadFile(token, resourceUrl, fileName);
  };

  render() {
    const { fileName } = this.props;
    return (
      <Button type="link" block onClick={this.onClick} style={{textAlign: "left"}}>
        {fileName}
      </Button>
    );
  }
}

_DownloadLink.propTypes = {
  token: PropTypes.string.isRequired,
  resourceBaseUrl: PropTypes.string.isRequired,
  resourceId: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
};

// Link Reducer state to obtain access token for ajax request.
function mapStateToProps(state) {
  return {
    token: state.login.token,
  };
}

const DownloadLink = connect(mapStateToProps)(_DownloadLink);
export default DownloadLink;
