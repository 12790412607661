// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContactStaffFormItem-container-rcwFR{width:100%;text-align:left}.ContactStaffFormItem-userInput-xofWg{width:100%}.ContactStaffFormItem-tooltip-wvlfj{margin-left:5px}.ContactStaffFormItem-infoIcon-gMADE{align-self:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/ContactSearchPage/ContactSearchDrawer/ContactStaffFormItem/ContactStaffFormItem.scss"],"names":[],"mappings":"AAAA,sCACI,UAAW,CACX,eAAgB,CACnB,sCAGG,UAAW,CACd,oCAGG,eAAgB,CACnB,qCAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ContactStaffFormItem-container-rcwFR`,
	"userInput": `ContactStaffFormItem-userInput-xofWg`,
	"tooltip": `ContactStaffFormItem-tooltip-wvlfj`,
	"infoIcon": `ContactStaffFormItem-infoIcon-gMADE`
};
export default ___CSS_LOADER_EXPORT___;
