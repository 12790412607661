import React from "react";
import { Col, Form, Select, Row, Tooltip } from "antd";
import { QuestionCircleFilled } from "@ant-design/icons";
import { Links, iconCustomColor } from "~/utils/constants";
import styles from "./CorporateCountryFormItem.scss";

const CorporateCountryFormItem = ({ disabled = false }) => {
    const items = [
        { value: "JP", flag: "🇯🇵", name: "日本" },
        { value: "KR", flag: "🇰🇷", name: "韓国" },
        { value: "CN", flag: "🇨🇳", name: "中国" },
        { value: "OTHER", flag: "🏳️", name: "その他" },
    ];

    const notItems = [
        { value: "not:JP", flag: "not:🇯🇵", name: "日本" },
        { value: "not:KR", flag: "not:🇰🇷", name: "韓国" },
        { value: "not:CN", flag: "not:🇨🇳", name: "中国" },
        { value: "not:OTHER", flag: "not:🏳️", name: "その他" },
    ];

    return (
        <Col span={24} style={{ marginBottom: "1%" }}>
            <Row className={styles.container}>
                <Col span={23}>
                    <Form.Item colon={false} name="country" noStyle>
                        <Select
                            showSearch={false}
                            className={styles.container}
                            placeholder="国籍"
                            mode="multiple"
                            allowClear
                            disabled={disabled}
                            getPopupContainer={(trigger) => trigger.parentNode}>
                            {[...items, ...notItems].map((item) => {
                                return (
                                    <Select.Option
                                        key={item.value}
                                        className={styles.select_option}
                                        value={item.value}>
                                        <span role="img">{item.flag}</span>
                                        {item.name}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={1} className={styles.infoIcon}>
                    <Tooltip
                        title={
                            <span>
                                複数選択をすると
                                <a
                                    href={Links.helps.filter.or}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    OR検索
                                </a>
                            </span>
                        }>
                        <QuestionCircleFilled style={{ color: iconCustomColor }} className={styles.tooltip} />
                    </Tooltip>
                </Col>
            </Row>
        </Col>
    );
};

export default CorporateCountryFormItem;
