// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DynamicRowFormItem-wrapper-xY1Ap{margin-bottom:10px}.DynamicRowFormItem-delimiter-J7EU0{display:inline-block;margin-right:8px}.DynamicRowFormItem-titleSelect-gXz2a .ant-select-selection-item,.DynamicRowFormItem-titleSelect-gXz2a .ant-select-selection-placeholder{width:100px}.DynamicRowFormItem-titleSelect-gXz2a:not(.ant-select-loading) .ant-select-selection-item{white-space:initial;text-overflow:initial}.DynamicRowFormItem-select_option-sFwI3{text-align:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Common/BoardCommon/DynamicRowFormItem/DynamicRowFormItem.scss"],"names":[],"mappings":"AAAA,kCACI,kBAAmB,CACtB,oCAGG,oBAAqB,CACrB,gBAAiB,CACpB,yIAKO,WAAY,CAHpB,0FAOQ,mBAAoB,CACpB,qBAAsB,CACzB,wCAID,iBAAkB","sourcesContent":[".wrapper {\n    margin-bottom: 10px;\n}\n\n.delimiter {\n    display: inline-block;\n    margin-right: 8px;\n}\n\n.titleSelect {\n    :global(.ant-select-selection-item),\n    :global(.ant-select-selection-placeholder) {\n        width: 100px;\n    }\n\n    &:not(:global(.ant-select-loading)) :global(.ant-select-selection-item) {\n        white-space: initial;\n        text-overflow: initial;\n    }\n}\n\n.select_option {\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `DynamicRowFormItem-wrapper-xY1Ap`,
	"delimiter": `DynamicRowFormItem-delimiter-J7EU0`,
	"titleSelect": `DynamicRowFormItem-titleSelect-gXz2a`,
	"select_option": `DynamicRowFormItem-select_option-sFwI3`
};
export default ___CSS_LOADER_EXPORT___;
