"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var utils_1 = require("./utils");
var templates = function (client) {
    var path = function (cardId, dynamicPath) {
        if (dynamicPath === void 0) { dynamicPath = ""; }
        return (utils_1.projectBoardBasePath +
            "/cards/" +
            cardId +
            "/templates" +
            dynamicPath);
    };
    return {
        fetchScheduledEmailTemplate: function (cardId) {
            var url = path(cardId);
            return client.get(url);
        },
        patchScheduledEmailCard: function (cardId, postData, templateId) {
            var url = path(cardId, "/" + templateId);
            return client.patch(url, postData);
        },
    };
};
exports.default = templates;
