// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SubCommentHidingBarModel-hidingBarWrapper-jXJ6V{flex-direction:column;justify-content:center;margin-right:8px}.SubCommentHidingBarModel-avatarItem-rhMJk{width:32px;height:32px;margin-right:4px;border-radius:50%;overflow:hidden}.SubCommentHidingBarModel-hiddenItemCount-PmVf5{position:absolute;top:0;left:0;width:100%;height:100%;text-align:center;padding-top:4px;font-size:16px;font-weight:bold;color:white}.SubCommentHidingBarModel-avatarList-QZP93{display:flex}.SubCommentHidingBarModel-lastImageWrapper-WHVTY{position:relative;border-radius:50%;overflow:hidden;width:32px}.SubCommentHidingBarModel-lastImageBlur-leP2e{width:32px;height:32px;margin-right:4px;background-color:#8f8d8d}.SubCommentHidingBarModel-defaultAvatarIcon-k0QOi{font-size:32px;margin-right:4px}.SubCommentHidingBarModel-lastIconBlur-TlYcj{font-size:32px;margin-right:4px;background-color:#8f8d8d}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/DataDisplay/CommentList/SubCommentHidingBarModel/SubCommentHidingBarModel.scss"],"names":[],"mappings":"AAAA,iDACI,qBAAsB,CACtB,sBAAuB,CACvB,gBAAiB,CACpB,2CAGG,UAAW,CACX,WAAY,CACZ,gBAAiB,CACjB,iBAAkB,CAClB,eAAgB,CACnB,gDAGG,iBAAkB,CAClB,KAAM,CACN,MAAO,CACP,UAAW,CACX,WAAY,CACZ,iBAAkB,CAClB,eAAgB,CAChB,cAAe,CACf,gBAAiB,CACjB,WAAY,CACf,2CAGG,YAAa,CAChB,iDAGG,iBAAkB,CAClB,iBAAkB,CAClB,eAAgB,CAChB,UAAW,CACd,8CAGG,UAAW,CACX,WAAY,CACZ,gBAAiB,CACjB,wBAAyB,CAC5B,kDAGG,cAAe,CACf,gBAAiB,CACpB,6CAGG,cAAe,CACf,gBAAiB,CACjB,wBAAyB","sourcesContent":[".hidingBarWrapper {\n    flex-direction: column;\n    justify-content: center;\n    margin-right: 8px;\n}\n\n.avatarItem {\n    width: 32px;\n    height: 32px;\n    margin-right: 4px;\n    border-radius: 50%;\n    overflow: hidden;\n}\n\n.hiddenItemCount {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    text-align: center;\n    padding-top: 4px;\n    font-size: 16px;\n    font-weight: bold;\n    color: white;\n}\n\n.avatarList {\n    display: flex;\n}\n\n.lastImageWrapper {\n    position: relative;\n    border-radius: 50%;\n    overflow: hidden;\n    width: 32px;\n}\n\n.lastImageBlur {\n    width: 32px;\n    height: 32px;\n    margin-right: 4px;\n    background-color: #8f8d8d;\n}\n\n.defaultAvatarIcon {\n    font-size: 32px;\n    margin-right: 4px;\n}\n\n.lastIconBlur {\n    font-size: 32px;\n    margin-right: 4px;\n    background-color: #8f8d8d;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hidingBarWrapper": `SubCommentHidingBarModel-hidingBarWrapper-jXJ6V`,
	"avatarItem": `SubCommentHidingBarModel-avatarItem-rhMJk`,
	"hiddenItemCount": `SubCommentHidingBarModel-hiddenItemCount-PmVf5`,
	"avatarList": `SubCommentHidingBarModel-avatarList-QZP93`,
	"lastImageWrapper": `SubCommentHidingBarModel-lastImageWrapper-WHVTY`,
	"lastImageBlur": `SubCommentHidingBarModel-lastImageBlur-leP2e`,
	"defaultAvatarIcon": `SubCommentHidingBarModel-defaultAvatarIcon-k0QOi`,
	"lastIconBlur": `SubCommentHidingBarModel-lastIconBlur-TlYcj`
};
export default ___CSS_LOADER_EXPORT___;
