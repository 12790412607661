// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SharedEmailNotificationAssigneeHeader-listItem-g6zXf{padding:8px 12px}.SharedEmailNotificationAssigneeHeader-listItem-g6zXf .SharedEmailNotificationAssigneeHeader-row-Yk1Ca{width:100%}.SharedEmailNotificationAssigneeHeader-listItem-g6zXf .SharedEmailNotificationAssigneeHeader-row-Yk1Ca .SharedEmailNotificationAssigneeHeader-assignedRuleCol-bvceY{align-self:center}.SharedEmailNotificationAssigneeHeader-listHeader-HXdB7{width:100%;padding:8px 16px;text-align:center;border-bottom:1px solid #d9d9d9}.SharedEmailNotificationAssigneeHeader-listContent-aY9oh{max-height:180px;overflow:scroll;margin-top:5px}.SharedEmailNotificationAssigneeHeader-popOverContainer-ijYla{width:250px}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Tables/SharedEmailNotificationsTable/SharedEmailNotificationAssigneeHeader.scss"],"names":[],"mappings":"AAAA,sDACE,gBAAiB,CADnB,uGAGI,UAAW,CAHf,oKAKM,iBAAkB,CACnB,wDAKH,UAAW,CACX,gBAAiB,CACjB,iBAAkB,CAClB,+BAAgC,CACjC,yDAGC,gBAAiB,CACjB,eAAgB,CAChB,cAAe,CAChB,8DAGC,WAAY","sourcesContent":[".listItem {\n  padding: 8px 12px;\n  .row {\n    width: 100%;\n    .assignedRuleCol {\n      align-self: center;\n    }\n  }\n}\n\n.listHeader {\n  width: 100%;\n  padding: 8px 16px;\n  text-align: center;\n  border-bottom: 1px solid #d9d9d9;\n}\n\n.listContent {\n  max-height: 180px;\n  overflow: scroll;\n  margin-top: 5px;\n}\n\n.popOverContainer {\n  width: 250px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listItem": `SharedEmailNotificationAssigneeHeader-listItem-g6zXf`,
	"row": `SharedEmailNotificationAssigneeHeader-row-Yk1Ca`,
	"assignedRuleCol": `SharedEmailNotificationAssigneeHeader-assignedRuleCol-bvceY`,
	"listHeader": `SharedEmailNotificationAssigneeHeader-listHeader-HXdB7`,
	"listContent": `SharedEmailNotificationAssigneeHeader-listContent-aY9oh`,
	"popOverContainer": `SharedEmailNotificationAssigneeHeader-popOverContainer-ijYla`
};
export default ___CSS_LOADER_EXPORT___;
