// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProfileAvatar-avatarIcon-zGJD8{width:32px;height:32px;border-radius:50%}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Common/ProfileAvatar/ProfileAvatar.scss"],"names":[],"mappings":"AAAA,gCACE,UAAW,CACX,WAAY,CACZ,iBAAkB","sourcesContent":[".avatarIcon {\n  width: 32px;\n  height: 32px;\n  border-radius: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"avatarIcon": `ProfileAvatar-avatarIcon-zGJD8`
};
export default ___CSS_LOADER_EXPORT___;
