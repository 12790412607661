// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ConfirmPasswordForm-container-Q2Yqz{max-width:300px}.ConfirmPasswordForm-button-eHR1v{margin:32px auto;width:100%}.ConfirmPasswordForm-alert-cor5z{margin-top:16px;margin-bottom:16px}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/ScheduledEmailDownloadAttachmentPage/ConfirmPasswordForm/ConfirmPasswordForm.scss"],"names":[],"mappings":"AAAA,qCACI,eAAgB,CACnB,kCAGG,gBAAiB,CACjB,UAAW,CACd,iCAGG,eAAgB,CAChB,kBAAmB","sourcesContent":[".container {\n    max-width: 300px;\n}\n\n.button {\n    margin: 32px auto;\n    width: 100%;\n}\n\n.alert {\n    margin-top: 16px;\n    margin-bottom: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ConfirmPasswordForm-container-Q2Yqz`,
	"button": `ConfirmPasswordForm-button-eHR1v`,
	"alert": `ConfirmPasswordForm-alert-cor5z`
};
export default ___CSS_LOADER_EXPORT___;
