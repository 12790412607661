// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OrganizationBranchField-field-ovCZp{max-width:800px;margin:8px 0}@media screen and (max-width: 768px){.OrganizationBranchField-field-ovCZp{margin:8px auto;width:100%}}.OrganizationBranchField-phonenumberDash-vGzK4{display:inline-block;width:5%;text-align:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Forms/OrganizationForm/OrganizationBranchTabPaneContent/OrganizationBranchField/OrganizationBranchField.scss"],"names":[],"mappings":"AAEA,qCACI,eAAgB,CAChB,YAAa,CAKhB,qCAPD,qCAIQ,eAAgB,CAChB,UAAW,CAElB,CAED,+CACI,oBAAqB,CACrB,QAAS,CACT,iBAAkB","sourcesContent":["@import \"../../../../../../stylesheets/constants.scss\";\n\n.field {\n    max-width: 800px;\n    margin: 8px 0;\n    @include mobile-devices {\n        margin: 8px auto;\n        width: 100%;\n    }\n}\n\n.phonenumberDash {\n    display: inline-block;\n    width: 5%;\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"field": `OrganizationBranchField-field-ovCZp`,
	"phonenumberDash": `OrganizationBranchField-phonenumberDash-vGzK4`
};
export default ___CSS_LOADER_EXPORT___;
