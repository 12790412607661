// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PersonnelContractStartFormItem-container-COm5J{width:100%;text-align:left}.PersonnelContractStartFormItem-userInput-J6aRK{width:100%}.PersonnelContractStartFormItem-tooltip-SjGyn{margin-left:5px}.PersonnelContractStartFormItem-infoIcon-qgoF5{align-self:center}.PersonnelContractStartFormItem-customDatePicker-qkVnf{width:100%;height:35px}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/personnel/PersonnelBoardPage/PersonnelBoardSearchDrawer/PersonnelContractStartFormItem/PersonnelContractStartFormItem.scss"],"names":[],"mappings":"AAAA,gDACE,UAAW,CACX,eAAgB,CACjB,gDAGC,UAAW,CACZ,8CAGC,eAAgB,CACjB,+CAGC,iBAAkB,CACnB,uDAGC,UAAW,CACX,WAAY","sourcesContent":[".container {\n  width: 100%;\n  text-align: left;\n}\n\n.userInput {\n  width: 100%;\n}\n\n.tooltip {\n  margin-left: 5px;\n}\n\n.infoIcon {\n  align-self: center;\n}\n\n.customDatePicker {\n  width: 100%;\n  height: 35px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PersonnelContractStartFormItem-container-COm5J`,
	"userInput": `PersonnelContractStartFormItem-userInput-J6aRK`,
	"tooltip": `PersonnelContractStartFormItem-tooltip-SjGyn`,
	"infoIcon": `PersonnelContractStartFormItem-infoIcon-qgoF5`,
	"customDatePicker": `PersonnelContractStartFormItem-customDatePicker-qkVnf`
};
export default ___CSS_LOADER_EXPORT___;
