// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PersonnelBoardCreatePopover-colInput-pi9Bt{width:48%}.PersonnelBoardCreatePopover-form-XETmh{width:80%}.PersonnelBoardCreatePopover-wrapper-ovhom{width:100%;margin-top:10px;margin-bottom:10px}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/personnel/PersonnelBoardPage/PersonnelBoardCreatePopover/PersonnelBoardCreatePopover.scss"],"names":[],"mappings":"AAAA,4CACI,SAAU,CACb,wCAGG,SAAU,CACb,2CAGG,UAAW,CACX,eAAgB,CAChB,kBAAmB","sourcesContent":[".colInput {\n    width: 48%;\n}\n\n.form {\n    width: 80%;\n}\n\n.wrapper {\n    width: 100%;\n    margin-top: 10px;\n    margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colInput": `PersonnelBoardCreatePopover-colInput-pi9Bt`,
	"form": `PersonnelBoardCreatePopover-form-XETmh`,
	"wrapper": `PersonnelBoardCreatePopover-wrapper-ovhom`
};
export default ___CSS_LOADER_EXPORT___;
