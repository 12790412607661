"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var api_1 = require("~/domain/api");
var auth = function (client) {
    return {
        login: function (postData) {
            var url = "api-token-auth/";
            return client.post(url, postData, {
                baseURL: "".concat(window.location.protocol, "//").concat(window.location.host),
            });
        },
        logout: function () {
            var url = "api-token-auth/logout";
            return client.get(url, {
                baseURL: "".concat(window.location.protocol, "//").concat(window.location.host),
            });
        },
        fetchAuthorizedActions: function () {
            var url = api_1.Endpoint.authorizedAction;
            return client.get(url);
        },
    };
};
exports.default = auth;
