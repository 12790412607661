"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var antd_1 = require("antd");
var data_1 = require("../../../actions/data");
var displayTextAndForeignKeyModel_1 = require("~/models/displayTextAndForeignKeyModel");
var react_redux_1 = require("react-redux");
var useCustomDebouncedCallback_1 = require("~/hooks/useCustomDebouncedCallback");
var recoil_1 = require("recoil");
var atom_1 = require("~/recoil/atom");
var Option = antd_1.Select.Option;
var AjaxSelect = function (_a) {
    var _b = _a.pageSize, pageSize = _b === void 0 ? 100 : _b, resourceUrl = _a.resourceUrl, displayKey = _a.displayKey, itemUpdateKey = _a.itemUpdateKey, defaultSelect = _a.defaultSelect, rebuildItems = _a.rebuildItems, searchParam = _a.searchParam, _c = _a.value, value = _c === void 0 ? undefined : _c, keyNumber = _a.keyNumber, typeForm = _a.typeForm, _d = _a.placeholder, placeholder = _d === void 0 ? "クリックして選択" : _d, disabled = _a.disabled, props = __rest(_a, ["pageSize", "resourceUrl", "displayKey", "itemUpdateKey", "defaultSelect", "rebuildItems", "searchParam", "value", "keyNumber", "typeForm", "placeholder", "disabled"]);
    var token = (0, react_redux_1.useSelector)(function (state) { return state.login.token; });
    var _e = (0, react_1.useState)([]), items = _e[0], setItems = _e[1];
    var _f = (0, react_1.useState)([]), itemAlls = _f[0], setItemAlls = _f[1];
    var _g = (0, recoil_1.useRecoilState)(atom_1.ajaxItemSelect), itemSelect = _g[0], setItemSelect = _g[1];
    var _h = (0, react_1.useState)(true), isFetching = _h[0], setIsFetching = _h[1];
    var _j = (0, react_1.useState)(""), searchValue = _j[0], setSearchValue = _j[1];
    var currentPage = function (items, defaultSelect) {
        var easyPage = 0;
        if (defaultSelect && defaultSelect.constructor.name === "Array") {
            var defaultModels = defaultSelect;
            easyPage = (items.length - defaultModels.length) / pageSize;
        }
        else if (defaultSelect &&
            defaultSelect.constructor.name === "Object") {
            easyPage = (items.length - 1) / pageSize;
        }
        else {
            easyPage = items.length / pageSize;
        }
        if (!easyPage) {
            return 1;
        }
        var page = Math.ceil(easyPage) + 1;
        return page;
    };
    var fetchData = function (page, value) {
        if (value === void 0) { value = ""; }
        return __awaiter(void 0, void 0, void 0, function () {
            var url, response, data, apiDatas, rebuildedItems, isSearchChanged, newItems, apiValues_1, val, validVals, filtered, val, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        url = resourceUrl;
                        if (url.includes("?")) {
                            url += "&page_size=".concat(pageSize, "&page=").concat(page);
                        }
                        else {
                            url += "?page_size=".concat(pageSize, "&page=").concat(page);
                        }
                        if (searchParam) {
                            url += "&".concat(searchParam, "=").concat(value);
                        }
                        return [4 /*yield*/, (0, data_1.simpleFetch)(token, url)];
                    case 1:
                        response = _a.sent();
                        data = response.results;
                        apiDatas = data.map(function (entry) { return ({
                            displayText: entry[displayKey],
                            foreignKey: entry.id,
                        }); });
                        rebuildedItems = rebuildItems
                            ? rebuildItems(apiDatas)
                            : apiDatas;
                        isSearchChanged = searchValue !== value;
                        newItems = [];
                        if (isSearchChanged) {
                            newItems = __spreadArray([], rebuildedItems, true);
                        }
                        else {
                            newItems = __spreadArray(__spreadArray([], items, true), rebuildedItems, true);
                        }
                        if (defaultSelect === undefined) {
                            setDataItems(newItems);
                            setItemAlls(newItems);
                        }
                        else {
                            apiValues_1 = newItems.map(function (item) { return item.foreignKey; });
                            if (defaultSelect.constructor.name === "Array") {
                                val = defaultSelect;
                                validVals = val.filter(function (displayTextAndForeignKeyModel) {
                                    return displayTextAndForeignKeyModel.displayText &&
                                        displayTextAndForeignKeyModel.foreignKey;
                                });
                                filtered = validVals.filter(function (validVal) { return !apiValues_1.includes(validVal.foreignKey); });
                                newItems = __spreadArray(__spreadArray([], filtered, true), newItems, true);
                            }
                            else {
                                val = defaultSelect;
                                if (val.displayText &&
                                    val.foreignKey &&
                                    !apiValues_1.includes(val.foreignKey)) {
                                    newItems = __spreadArray([val], newItems, true);
                                }
                            }
                            setItemAlls(newItems);
                            setDataItems(newItems);
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_1 = _a.sent();
                        return [3 /*break*/, 3];
                    case 3:
                        setIsFetching(false);
                        return [2 /*return*/];
                }
            });
        });
    };
    var fetchDataFromAPI = (0, useCustomDebouncedCallback_1.useCustomDebouncedCallback)(function (value) { return fetchData(currentPage(items, defaultSelect), value); }, 1000);
    var setDataItems = function (newItems) {
        if (typeForm === displayTextAndForeignKeyModel_1.TypeAjaxPageModel.MY_COMPANY_ORGANIZATION) {
            if (itemSelect && itemSelect.length > 0) {
                var arr_1 = __spreadArray([], newItems, true);
                itemSelect.map(function (i) {
                    var arrFilter = arr_1.filter(function (f) { return f.foreignKey !== i.value; });
                    arr_1 = arrFilter;
                });
                setItems(arr_1);
            }
            else {
                setItems(newItems);
            }
        }
        else {
            setItems(newItems);
        }
    };
    var searchAPIValue = (0, useCustomDebouncedCallback_1.useCustomDebouncedCallback)(function (value) {
        if (searchValue !== value) {
            fetchData(1, value);
        }
        else {
            fetchData(currentPage(items, defaultSelect), value);
        }
        setSearchValue(value);
    });
    var onScroll = function (event) {
        var target = event.target;
        if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
            setIsFetching(true);
            fetchDataFromAPI(searchValue);
        }
    };
    (0, react_1.useEffect)(function () {
        fetchDataFromAPI();
    }, [itemUpdateKey]);
    (0, react_1.useEffect)(function () {
        if (itemSelect) {
            setDataItems(items);
        }
    }, [itemSelect]);
    var onSelect = function (value) {
        var arr = __spreadArray([], itemSelect, true);
        var item = itemSelect.filter(function (f) { return f.keyNumber === keyNumber; });
        if (item && !item.length) {
            arr.push({
                keyNumber: keyNumber,
                value: value,
            });
            setItemSelect(arr);
        }
        else {
            var items_1 = arr.map(function (i) { return (__assign(__assign({}, i), { value: i.keyNumber === keyNumber ? value : i.value })); });
            setItemSelect(items_1);
        }
    };
    var onClear = function (value) {
        if (typeForm === displayTextAndForeignKeyModel_1.TypeAjaxPageModel.MY_COMPANY_ORGANIZATION) {
            var findItem_1 = itemAlls.find(function (f) { return f.displayText === value; });
            var item = itemSelect.filter(function (f) { return f.value !== (findItem_1 === null || findItem_1 === void 0 ? void 0 : findItem_1.foreignKey); });
            setItemSelect(item);
        }
    };
    var onDropdownVisibleChange = function (open) {
        if (open) {
            setDataItems(itemAlls);
        }
    };
    var computeDisabled = function () {
        var findItem = itemSelect.find(function (i) { return i.keyNumber === keyNumber; });
        if (itemAlls.length < 3) {
            if (1 < itemSelect.length && !findItem) {
                return true;
            }
            else if (itemSelect.length === 1 &&
                itemSelect.length === itemAlls.length &&
                !findItem) {
                return true;
            }
            else {
                return false;
            }
        }
    };
    var setValues = function (foreignKey) {
        if (typeForm === displayTextAndForeignKeyModel_1.TypeAjaxPageModel.MY_COMPANY_ORGANIZATION) {
            var item = itemAlls.find(function (f) { return f.foreignKey === foreignKey; });
            return item === null || item === void 0 ? void 0 : item.displayText;
        }
        return foreignKey;
    };
    var renderSelect = function () {
        return (react_1.default.createElement(antd_1.Select, __assign({ notFoundContent: react_1.default.createElement(antd_1.Result, { icon: react_1.default.createElement(antd_1.Empty, { image: antd_1.Empty.PRESENTED_IMAGE_SIMPLE, description: "\u30C7\u30FC\u30BF\u304C\u3042\u308A\u307E\u305B\u3093" }) }), showSearch: true, filterOption: function (input, option) {
                return ((option === null || option === void 0 ? void 0 : option.props.children.toLowerCase().indexOf(input.toLowerCase())) >= 0);
            }, style: { width: "100%" } }, props, { value: setValues(value), placeholder: placeholder, onPopupScroll: onScroll, dropdownRender: function (originNode) { return (react_1.default.createElement(antd_1.Spin, { spinning: isFetching }, originNode)); }, disabled: computeDisabled() || disabled, onDeselect: onClear, onSelect: onSelect, onDropdownVisibleChange: onDropdownVisibleChange, onSearch: searchAPIValue, onBlur: function (_) {
                if (searchValue) {
                    searchAPIValue("");
                }
            } }), items.map(function (entry) { return (react_1.default.createElement(antd_1.Select.Option, { key: entry.foreignKey, value: entry.foreignKey }, entry.displayText)); })));
    };
    return (react_1.default.createElement(antd_1.Spin, { size: "small", spinning: isFetching }, disabled ? (react_1.default.createElement(antd_1.Tooltip, { title: "特定の権限で操作できます" }, renderSelect())) : (react_1.default.createElement(react_1.default.Fragment, null, renderSelect()))));
};
exports.default = AjaxSelect;
