// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BackOfficeCommentUserFormItem-container-ixsSk{width:100%;text-align:left}.BackOfficeCommentUserFormItem-userInput-F9pCY{width:100%}.BackOfficeCommentUserFormItem-tooltip-BL9lK{margin-left:5px}.BackOfficeCommentUserFormItem-infoIcon-C5L8A{align-self:center}.BackOfficeCommentUserFormItem-marginBottom-V2neF{margin-bottom:1%}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/backOffice/BackOfficeBoardPage/BackOfficeBoardSearchDrawer/BackOfficeCommentUserFormItem/BackOfficeCommentUserFormItem.scss"],"names":[],"mappings":"AAAA,+CACE,UAAW,CACX,eAAgB,CACjB,+CAGC,UAAW,CACZ,6CAGC,eAAgB,CACjB,8CAGC,iBAAkB,CACnB,kDAGC,gBAAiB","sourcesContent":[".container {\n  width: 100%;\n  text-align: left;\n}\n\n.userInput {\n  width: 100%;\n}\n\n.tooltip {\n  margin-left: 5px;\n}\n\n.infoIcon {\n  align-self: center;\n}\n\n.marginBottom {\n  margin-bottom: 1%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `BackOfficeCommentUserFormItem-container-ixsSk`,
	"userInput": `BackOfficeCommentUserFormItem-userInput-F9pCY`,
	"tooltip": `BackOfficeCommentUserFormItem-tooltip-BL9lK`,
	"infoIcon": `BackOfficeCommentUserFormItem-infoIcon-C5L8A`,
	"marginBottom": `BackOfficeCommentUserFormItem-marginBottom-V2neF`
};
export default ___CSS_LOADER_EXPORT___;
