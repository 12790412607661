// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContactTagFormItem-container-kCEpw{width:100%;text-align:left}.ContactTagFormItem-userInput-hM1SV{width:100%}.ContactTagFormItem-tooltip-XRTA9{margin-left:5px}.ContactTagFormItem-infoIcon-tUrPS{align-self:center}.ContactTagFormItem-select_option-UyC9W{text-align:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/ContactSearchPage/ContactSearchDrawer/ContactTagFormItem/ContactTagFormItem.scss"],"names":[],"mappings":"AAAA,oCACI,UAAW,CACX,eAAgB,CACnB,oCAGG,UAAW,CACd,kCAGG,eAAgB,CACnB,mCAGG,iBAAkB,CACrB,wCAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n\n.select_option {\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ContactTagFormItem-container-kCEpw`,
	"userInput": `ContactTagFormItem-userInput-hM1SV`,
	"tooltip": `ContactTagFormItem-tooltip-XRTA9`,
	"infoIcon": `ContactTagFormItem-infoIcon-tUrPS`,
	"select_option": `ContactTagFormItem-select_option-UyC9W`
};
export default ___CSS_LOADER_EXPORT___;
