// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProjectDistributionFormItem-container-zBqy1{width:100%;text-align:left}.ProjectDistributionFormItem-userInput-DjcfL{width:100%}.ProjectDistributionFormItem-tooltip-jfxbO{margin-left:5px}.ProjectDistributionFormItem-infoIcon-Y_Gvm{align-self:center}.ProjectDistributionFormItem-select_option-xHbbU{text-align:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/project/ProjectBoardPage/ProjectBoardSearchDrawer/ProjectDistributionFormItem/ProjectDistributionFormItem.scss"],"names":[],"mappings":"AAAA,6CACI,UAAW,CACX,eAAgB,CACnB,6CAGG,UAAW,CACd,2CAGG,eAAgB,CACnB,4CAGG,iBAAkB,CACrB,iDAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n\n.select_option {\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ProjectDistributionFormItem-container-zBqy1`,
	"userInput": `ProjectDistributionFormItem-userInput-DjcfL`,
	"tooltip": `ProjectDistributionFormItem-tooltip-jfxbO`,
	"infoIcon": `ProjectDistributionFormItem-infoIcon-Y_Gvm`,
	"select_option": `ProjectDistributionFormItem-select_option-xHbbU`
};
export default ___CSS_LOADER_EXPORT___;
