// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RecruitCommentCreateDateFormItem-container-eteVo{width:100%;text-align:left}.RecruitCommentCreateDateFormItem-userInput-OzdKo{width:100%}.RecruitCommentCreateDateFormItem-tooltip-faJRw{margin-left:5px}.RecruitCommentCreateDateFormItem-infoIcon-N226j{align-self:center}.RecruitCommentCreateDateFormItem-marginBottom-iUTqH{margin-bottom:1%}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/recruit/RecruitBoardPage/RecruitBoardSearchDrawer/RecruitCommentCreateDateFormItem/RecruitCommentCreateDateFormItem.scss"],"names":[],"mappings":"AAAA,kDACE,UAAW,CACX,eAAgB,CACjB,kDAGC,UAAW,CACZ,gDAGC,eAAgB,CACjB,iDAGC,iBAAkB,CACnB,qDAGC,gBAAiB","sourcesContent":[".container {\n  width: 100%;\n  text-align: left;\n}\n\n.userInput {\n  width: 100%;\n}\n\n.tooltip {\n  margin-left: 5px;\n}\n\n.infoIcon {\n  align-self: center;\n}\n\n.marginBottom {\n  margin-bottom: 1%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `RecruitCommentCreateDateFormItem-container-eteVo`,
	"userInput": `RecruitCommentCreateDateFormItem-userInput-OzdKo`,
	"tooltip": `RecruitCommentCreateDateFormItem-tooltip-faJRw`,
	"infoIcon": `RecruitCommentCreateDateFormItem-infoIcon-N226j`,
	"marginBottom": `RecruitCommentCreateDateFormItem-marginBottom-iUTqH`
};
export default ___CSS_LOADER_EXPORT___;
