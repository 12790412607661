const Paths = {
    index: "/",
    login: "/login/",
    users: "/users",
    userBulkEdit: "/users/bulkEdit",
    dashboard: "/dashboard",
    userRegister: "/users/register",
    userInvite: "/users/invite",
    myProfile: "/myProfile",
    myCompany: "/myCompany",
    contacts: "/contacts",
    contactRegister: "/contacts/register",
    contactBulkEdit: "/contacts/bulkEdit",
    contactsCsvUpload: "/contacts/csvUpload",
    sharedMails: "/sharedMails",
    sharedMailBulkEdit: "/sharedMails/bulkEdit",
    sharedMailNotifications: "/sharedMailNotifications",
    sharedMailNotificationRegister: "/sharedMailNotifications/register",
    organizations: "/organizations",
    organizationsRegister: "/organizations/register",
    organizationsCsvUpload: "/organizations/csvUpload",
    organizationsBulkEdit: "/organizations/bulkEdit",
    scheduledMails: "/scheduledMails",
    scheduledMailsRegister: "/scheduledMails/register",
    scheduledMailsDownloadAttachment: "/scheduledMails/files/download",
    scheduledMailsBulkEdit: "/scheduledMails/bulkEdit",
    tags: "/tags",
    tagCsvUpload: "/tags/csvUpload",
    tagRegister: "/tags/register",
    tagBulkEdit: "/tags/bulkEdit",
    displaySettings: "/displaySettings",
    sharedEmailSettings: "/sharedEmailSettings",
    scheduledEmailSettings: "/scheduledEmailSettings",
    personnelSettings: "/personnelSettings",
    projectSettings: "/projectSettings",
    recruitSettings: "/recruitSettings",
    salesSettings: "/salesSettings",
    backOfficeSettings: "/backOfficeSettings",
    passwordReset: "/passwordReset",
    passwordChange: "/passwordChange",
    emailCheck: "/emailCheck",
    addons: "/addons",
    purchaseHistory: "/purchaseHistory",
    payment: "/payment",
    plan: {
        index: "/plan",
        startup: {
            application: "/plan/startup/application",
        },
    },
    tenant: "/tenant",
    maintenance: "/maintenance",
    personnel: {
        board: "/personnel/board",
        gantt: "/personnel/gantt",
        csvUpload: "/personnel/board/csvUpload",
    },
    project: {
        board: "/project/board",
        gantt: "/project/gantt",
        csvUpload: "/project/board/csvUpload",
    },
    notificationSettings: "/notifications/settings",
    reminderSettings: "/reminderSettings",
    recruit: {
        board: "/recruit/board",
        gantt: "/recruit/gantt",
    },
    sales: {
        board: "/sales/board",
        gantt: "/sales/gantt",
    },
    backOffice: {
        board: "/backOffice/board",
        gantt: "/backOffice/gantt",
    },
};

export const Suffixes = {
    mailProfile: "mailProfile", // This is a suffix string, not a prefix.
};

export default Paths;
