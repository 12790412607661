// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BoardBaseCardAssigneePopover-content-oZhA3{width:230px;padding-bottom:10px}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Common/Board/BoardBaseCard/BoardBaseCardAssignees/BoardBaseCardAssigneePopover/BoardBaseCardAssigneePopover.scss"],"names":[],"mappings":"AAAA,4CACI,WAAY,CACZ,mBAAoB","sourcesContent":[".content {\n    width: 230px;\n    padding-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `BoardBaseCardAssigneePopover-content-oZhA3`
};
export default ___CSS_LOADER_EXPORT___;
