"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRecruitBoardUtils = void 0;
var useTag_1 = require("../useTag");
var useRecruitBoardUtils = function () {
    var tags = (0, useTag_1.useFetchTagsAPIQuery)({
        deps: {
            page: 1,
            value: "",
            pageSize: 2000,
        },
    }).data;
    var getTags = function (tags_) {
        var tag = tags_ === null || tags_ === void 0 ? void 0 : tags_.map(function (id) {
            return tags === null || tags === void 0 ? void 0 : tags.results.find(function (tag) { return tag.id === id; });
        });
        return tag === null || tag === void 0 ? void 0 : tag.map(function (item) { return item === null || item === void 0 ? void 0 : item.value; }).toString().replace(/,/g, "、");
    };
    var getTextInfo = function (values) {
        var _data = [
            {
                label: "",
                value: "========================================",
            },
            {
                label: "候補者名:   ",
                value: "".concat(values === null || values === void 0 ? void 0 : values.detail),
            },
            {
                label: "タグ: ",
                value: getTags(values === null || values === void 0 ? void 0 : values.tags),
            },
        ];
        var result = _data.map(function (item) {
            if (!item.value) {
                return "";
            }
            if (!item.label) {
                return item.value + "\n";
            }
            return "".concat(item.label, " ").concat(item.value, "\n");
        });
        var bottomValue = ["========================================"];
        return result.concat(bottomValue).join("");
    };
    return {
        getTags: getTags,
        getTextInfo: getTextInfo,
    };
};
exports.useRecruitBoardUtils = useRecruitBoardUtils;
