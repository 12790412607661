// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomSelectTag-selectTag-p5iFC{margin-top:3px;margin-bottom:3px;margin-inline-end:4.8px;margin-inline-start:8px;padding-inline-end:4px;width:100%;white-space:normal}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Common/CustomSelectTag/CustomSelectTag.scss"],"names":[],"mappings":"AAAA,iCACI,cAAe,CACf,iBAAkB,CAClB,uBAAwB,CACxB,uBAAwB,CACxB,sBAAuB,CACvB,UAAW,CACX,kBAAmB","sourcesContent":[".selectTag {\n    margin-top: 3px;\n    margin-bottom: 3px;\n    margin-inline-end: 4.8px;\n    margin-inline-start: 8px;\n    padding-inline-end: 4px;\n    width: 100%;\n    white-space: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectTag": `CustomSelectTag-selectTag-p5iFC`
};
export default ___CSS_LOADER_EXPORT___;
