"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var utils_1 = require("./utils");
var comments = function (client) {
    var path = function (dynamicPath) {
        if (dynamicPath === void 0) { dynamicPath = ""; }
        return utils_1.personnelBoardBasePath + "/cards" + dynamicPath;
    };
    return {
        fetchComments: function (cardId) {
            var url = path("/".concat(cardId, "/comments"));
            return client.get(url);
        },
        createComment: function (postData) {
            var url = path("/".concat(postData.cardId, "/comments"));
            return client.post(url, postData);
        },
        fetchComment: function (commentId) {
            var url = path("/" + commentId);
            return client.get(url);
        },
        updateComment: function (commentId, postData) {
            var url = path("/".concat(postData.id, "/comments/").concat(commentId));
            return client.patch(url, postData);
        },
        deleteComment: function (postData) {
            var url = path("/".concat(postData.cardId, "/comments/").concat(postData.commentId));
            return client.delete(url);
        },
        fetchSubComments: function (commentId) {
            var url = path("/" + commentId + "/sub-comments");
            return client.get(url);
        },
    };
};
exports.default = comments;
