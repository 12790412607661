// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CommentForm-editor-cPs5s{margin:auto;padding:16px 0;text-align:left}.CommentForm-controlButton-_XJv4{margin-right:5px}.CommentForm-colorFocus-sRttK{margin-right:5px}.CommentForm-colorFocus-sRttK:focus{border-color:#A7CF69;background-color:#A7CF69}.CommentForm-snippetsButton-adwBy{margin-right:5px}.CommentForm-snippetsButton-adwBy:focus{border-color:#A7CF69;background-color:#A7CF69}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Forms/CommentForm/CommentForm.scss"],"names":[],"mappings":"AAAA,0BACI,WAAY,CACZ,cAAe,CACf,eAAgB,CACnB,iCAGG,gBAAiB,CACpB,8BAEG,gBAAiB,CADrB,oCAGQ,oBAAqB,CACrB,wBAAyB,CAC5B,kCAGD,gBAAiB,CADrB,wCAGQ,oBAAqB,CACrB,wBAAyB","sourcesContent":[".editor {\n    margin: auto;\n    padding: 16px 0;\n    text-align: left;\n}\n\n.controlButton {\n    margin-right: 5px;\n}\n.colorFocus{\n    margin-right: 5px;\n    &:focus{\n        border-color: #A7CF69;\n        background-color: #A7CF69;\n    }\n}\n.snippetsButton{\n    margin-right: 5px;\n    &:focus{\n        border-color: #A7CF69;\n        background-color: #A7CF69;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editor": `CommentForm-editor-cPs5s`,
	"controlButton": `CommentForm-controlButton-_XJv4`,
	"colorFocus": `CommentForm-colorFocus-sRttK`,
	"snippetsButton": `CommentForm-snippetsButton-adwBy`
};
export default ___CSS_LOADER_EXPORT___;
