"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var api_1 = require("~/domain/api");
var plan = function (client) {
    return {
        fetchUserPlanInfo: function () {
            var url = api_1.Endpoint.plan;
            return client.get(url);
        },
        registerPlan: function (postData) {
            var url = api_1.Endpoint.plan;
            return client.post(url, { plan_master: postData.plan_master_id });
        },
        updatePlan: function (postData) {
            var url = api_1.Endpoint.plan + '/' + postData.planId;
            return client.patch(url, { plan_master: postData.plan_master_id });
        },
        addAccountLimit: function () {
            var url = api_1.Endpoint.addUserCount;
            return client.post(url);
        },
        removeAccountLimit: function () {
            var url = api_1.Endpoint.deleteUserCount;
            return client.post(url);
        },
        fetchPlanSummary: function () {
            var url = api_1.Endpoint.planSummary;
            return client.get(url);
        },
    };
};
exports.default = plan;
