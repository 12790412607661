// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SalesGanttInfo-over-FSed4{color:#EB5A46}.SalesGanttInfo-today-wTahF{color:#FFAB4A}.SalesGanttInfo-prevDay-b8GH4{color:#1890FF}.SalesGanttInfo-completed-l1AwN{color:#A7CF69}.SalesGanttInfo-default-r6Svd{color:#BFBFBF}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/sales/SalesGanttPage/SalesGanttInfo/SalesGanttInfo.scss"],"names":[],"mappings":"AAEA,2BACI,aAAc,CACjB,4BAGG,aAAc,CACjB,8BAGG,aAAc,CACjB,gCAGG,aAAc,CACjB,8BAGG,aAAc","sourcesContent":["@import \"~coreStylesheet\";\n\n.over {\n    color: #EB5A46;\n}\n\n.today {\n    color: #FFAB4A;\n}\n\n.prevDay {\n    color: #1890FF;\n}\n\n.completed {\n    color: #A7CF69;\n}\n\n.default {\n    color: #BFBFBF;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"over": `SalesGanttInfo-over-FSed4`,
	"today": `SalesGanttInfo-today-wTahF`,
	"prevDay": `SalesGanttInfo-prevDay-b8GH4`,
	"completed": `SalesGanttInfo-completed-l1AwN`,
	"default": `SalesGanttInfo-default-r6Svd`
};
export default ___CSS_LOADER_EXPORT___;
