// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RecruitPriorityFormItem-container-msmEa{width:100%;text-align:left}.RecruitPriorityFormItem-userInput-lAyKE{width:100%}.RecruitPriorityFormItem-tooltip-RLo2k{margin-left:5px}.RecruitPriorityFormItem-infoIcon-gJqrh{align-self:center}.RecruitPriorityFormItem-select_option-OSBfH{text-align:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/recruit/RecruitBoardPage/RecruitBoardSearchDrawer/RecruitPriorityFormItem/RecruitPriorityFormItem.scss"],"names":[],"mappings":"AAAA,yCACI,UAAW,CACX,eAAgB,CACnB,yCAGG,UAAW,CACd,uCAGG,eAAgB,CACnB,wCAGG,iBAAkB,CACrB,6CAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n\n.select_option {\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `RecruitPriorityFormItem-container-msmEa`,
	"userInput": `RecruitPriorityFormItem-userInput-lAyKE`,
	"tooltip": `RecruitPriorityFormItem-tooltip-RLo2k`,
	"infoIcon": `RecruitPriorityFormItem-infoIcon-gJqrh`,
	"select_option": `RecruitPriorityFormItem-select_option-OSBfH`
};
export default ___CSS_LOADER_EXPORT___;
