// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-container-kflkt{margin:auto}.page-pageHeader-NkkAb{padding:16px 0 0 0;text-align:left}.page-pageBody-ocIVc{padding:16px 0 0 0}.page-buttonWrapper-ekPrn{text-align:center;margin:auto;display:flex;justify-content:center}.page-button-fJM6c{margin:16px 32px;width:128px}.page-registerPageButtonWrapper-u8nki{text-align:left;margin:16px auto}.page-downloadButtonWrapper-otHP5{margin-top:8px;display:flex;justify-content:space-between;margin-bottom:16px}.page-search_template-fBhhd{margin:8px auto;text-align:left}.page-tableControlButton-DRVBm{margin-right:5px}.page-tableControlButton-DRVBm:focus{border-color:#A7CF69;background:#A7CF69}.page-tableSettingButton-ntbnU{margin-left:1%}.page-tableNewLine-DRZqP{white-space:pre-wrap}.page-simpleCardLabel-sipAc{margin-top:2px;margin-right:4px}.page-errorMessages-jxrP7{margin-bottom:20px;text-align:left}.page-nowrap-coi8L{white-space:nowrap}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/page.scss"],"names":[],"mappings":"AAAA,sBACE,WAAY,CACb,uBAGC,kBAAmB,CACnB,eAAgB,CACjB,qBAGC,kBAAmB,CACpB,0BAGC,iBAAkB,CAClB,WAAY,CACZ,YAAa,CACb,sBAAuB,CACxB,mBAGC,gBAAiB,CACjB,WAAY,CACb,sCAGC,eAAgB,CAChB,gBAAiB,CAClB,kCAGC,cAAe,CACf,YAAa,CACb,6BAA8B,CAC9B,kBAAmB,CACpB,4BAGC,eAAgB,CAChB,eAAgB,CACjB,+BAGC,gBAAiB,CADnB,qCAGI,oBAAqB,CACrB,kBAAmB,CACpB,+BAID,cAAe,CAChB,yBAGC,oBAAqB,CACtB,4BAGC,cAAe,CACf,gBAAiB,CAClB,0BAGC,kBAAmB,CACnB,eAAgB,CACjB,mBAGC,kBAAmB","sourcesContent":[".container {\n  margin: auto;\n}\n\n.pageHeader {\n  padding: 16px 0 0 0;\n  text-align: left;\n}\n\n.pageBody {\n  padding: 16px 0 0 0;\n}\n\n.buttonWrapper {\n  text-align: center;\n  margin: auto;\n  display: flex;\n  justify-content: center;\n}\n\n.button {\n  margin: 16px 32px;\n  width: 128px;\n}\n\n.registerPageButtonWrapper {\n  text-align: left;\n  margin: 16px auto;\n}\n\n.downloadButtonWrapper {\n  margin-top: 8px;\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 16px;\n}\n\n.search_template {\n  margin: 8px auto;\n  text-align: left;\n}\n\n.tableControlButton {\n  margin-right: 5px;\n  &:focus{\n    border-color: #A7CF69;\n    background: #A7CF69;\n  }\n}\n\n.tableSettingButton {\n  margin-left: 1%;\n}\n\n.tableNewLine {\n  white-space: pre-wrap;\n}\n\n.simpleCardLabel {\n  margin-top: 2px;\n  margin-right: 4px;\n}\n\n.errorMessages {\n  margin-bottom: 20px;\n  text-align: left;\n}\n\n.nowrap {\n  white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `page-container-kflkt`,
	"pageHeader": `page-pageHeader-NkkAb`,
	"pageBody": `page-pageBody-ocIVc`,
	"buttonWrapper": `page-buttonWrapper-ekPrn`,
	"button": `page-button-fJM6c`,
	"registerPageButtonWrapper": `page-registerPageButtonWrapper-u8nki`,
	"downloadButtonWrapper": `page-downloadButtonWrapper-otHP5`,
	"search_template": `page-search_template-fBhhd`,
	"tableControlButton": `page-tableControlButton-DRVBm`,
	"tableSettingButton": `page-tableSettingButton-ntbnU`,
	"tableNewLine": `page-tableNewLine-DRZqP`,
	"simpleCardLabel": `page-simpleCardLabel-sipAc`,
	"errorMessages": `page-errorMessages-jxrP7`,
	"nowrap": `page-nowrap-coi8L`
};
export default ___CSS_LOADER_EXPORT___;
