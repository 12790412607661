// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TooltipCopy-tooltip-aKPbj{cursor:pointer;float:right;display:none}.TooltipCopy-tooltip-aKPbj svg:active{color:#A7CF69}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Common/TooltipCopy/TooltipCopy.scss"],"names":[],"mappings":"AAAA,2BACI,cAAe,CACf,WAAY,CACZ,YAAa,CAHjB,sCAKQ,aAAc","sourcesContent":[".tooltip {\n    cursor: pointer;\n    float: right;\n    display: none;\n    svg:active {\n        color: #A7CF69;\n    }\n}\n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": `TooltipCopy-tooltip-aKPbj`
};
export default ___CSS_LOADER_EXPORT___;
