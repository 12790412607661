// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProjGAssigneeFormItem-container-nzMmv{width:100%;text-align:left}.ProjGAssigneeFormItem-userInput-szzR2{width:100%}.ProjGAssigneeFormItem-tooltip-XZDIJ{margin-left:5px}.ProjGAssigneeFormItem-infoIcon-Jb75r{align-self:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/project/ProjectGanttPage/ProjectGanttSearchDrawer/ProjGAssigneeFormItem/ProjGAssigneeFormItem.scss"],"names":[],"mappings":"AAAA,uCACI,UAAW,CACX,eAAgB,CACnB,uCAGG,UAAW,CACd,qCAGG,eAAgB,CACnB,sCAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ProjGAssigneeFormItem-container-nzMmv`,
	"userInput": `ProjGAssigneeFormItem-userInput-szzR2`,
	"tooltip": `ProjGAssigneeFormItem-tooltip-XZDIJ`,
	"infoIcon": `ProjGAssigneeFormItem-infoIcon-Jb75r`
};
export default ___CSS_LOADER_EXPORT___;
