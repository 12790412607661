// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserServiceIdFormItem-field-WKIhD{max-width:800px;margin:8px 0;text-align:left}@media screen and (max-width: 768px){.UserServiceIdFormItem-field-WKIhD{margin:8px auto;width:100%}}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Forms/UserServiceIdFormItem/UserServiceIdFormItem.scss"],"names":[],"mappings":"AACA,mCACI,eAAgB,CAChB,YAAa,CACb,eAAgB,CAKnB,qCARD,mCAKQ,eAAgB,CAChB,UAAW,CAElB","sourcesContent":["@import \"../../../../stylesheets/constants\";\n.field {\n    max-width: 800px;\n    margin: 8px 0;\n    text-align: left;\n    @include mobile-devices {\n        margin: 8px auto;\n        width: 100%;\n    }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"field": `UserServiceIdFormItem-field-WKIhD`
};
export default ___CSS_LOADER_EXPORT___;
