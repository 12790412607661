"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var utils_1 = require("./utils");
var trainStations = function (client) {
    var path = function (dynamicPath) {
        if (dynamicPath === void 0) { dynamicPath = ""; }
        return utils_1.personnelBoardBasePath + "/train_stations" + dynamicPath;
    };
    return {
        fetchTrainStations: function (name) {
            var url = path("?name=" + name);
            return client.get(url);
        },
    };
};
exports.default = trainStations;
