// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DynamicRowTitlesFormItem-item-fOYSO{margin-bottom:"1%"}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/ProjectSettingsPage/components/DynamicRowTitlesFormItem/DynamicRowTitlesFormItem.scss"],"names":[],"mappings":"AAAA,qCACI,kBAAmB","sourcesContent":[".item {\n    margin-bottom: \"1%\";\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `DynamicRowTitlesFormItem-item-fOYSO`
};
export default ___CSS_LOADER_EXPORT___;
