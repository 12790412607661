// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StartupScaleRightContent-titleWrapper-MkYOW{display:flex}.StartupScaleRightContent-titleWrapper-MkYOW img{width:40px}.StartupScaleRightContent-titleWrapper-MkYOW .StartupScaleRightContent-titleDescription-k_8OT{text-align:left}.StartupScaleRightContent-titleWrapper-MkYOW .StartupScaleRightContent-titleDescription-k_8OT h2{margin-bottom:0 !important}.StartupScaleRightContent-titleWrapper-MkYOW .StartupScaleRightContent-textDiscount-EeHTD{color:#FFB340;font-size:20px;margin:0 !important}.StartupScaleRightContent-textWrapper-k03zw{margin-top:50px;line-height:22px}.StartupScaleRightContent-textWrapper-k03zw .StartupScaleRightContent-stepIcon-b2hzO{width:100%;margin-bottom:10px}.StartupScaleRightContent-textWrapper-k03zw .StartupScaleRightContent-mainText-niDik{display:inline-grid;margin:12px 0}.StartupScaleRightContent-textWrapper-k03zw .StartupScaleRightContent-mainText-niDik .StartupScaleRightContent-hightLightGreen-Byyoq{color:#A7CF69;background-color:transparent}.StartupScaleRightContent-textWrapper-k03zw .StartupScaleRightContent-helpText-keVOX{display:inline-grid}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/StartupRegisterScalePage/StartupScaleRightContent/StartupScaleRightContent.scss"],"names":[],"mappings":"AAAA,6CACI,YAAa,CADjB,iDAGQ,UAAW,CAHnB,8FAOQ,eAAgB,CAPxB,iGASY,0BAA2B,CATvC,0FAaQ,aAAc,CACd,cAAe,CACf,mBAAoB,CACvB,4CAGD,eAAgB,CAChB,gBAAiB,CAFrB,qFAIQ,UAAW,CACX,kBAAmB,CAL3B,qFAQQ,mBAAoB,CACpB,aAAc,CATtB,qIAYY,aAAc,CACd,4BAA6B,CAbzC,qFAkBQ,mBAAoB","sourcesContent":[".titleWrapper {\n    display: flex;\n    & img {\n        width: 40px;\n    }\n\n    .titleDescription {\n        text-align: left;\n        & h2 {\n            margin-bottom: 0 !important;\n        }\n    }\n    .textDiscount {\n        color: #FFB340;\n        font-size: 20px;\n        margin: 0 !important;\n    }\n}\n.textWrapper {\n    margin-top: 50px;\n    line-height: 22px;\n    .stepIcon {\n        width: 100%;\n        margin-bottom: 10px;\n    }\n    .mainText {\n        display: inline-grid;\n        margin: 12px 0;\n\n        .hightLightGreen {\n            color: #A7CF69;\n            background-color: transparent;\n        }\n    }\n\n    .helpText {\n        display: inline-grid;\n    }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleWrapper": `StartupScaleRightContent-titleWrapper-MkYOW`,
	"titleDescription": `StartupScaleRightContent-titleDescription-k_8OT`,
	"textDiscount": `StartupScaleRightContent-textDiscount-EeHTD`,
	"textWrapper": `StartupScaleRightContent-textWrapper-k03zw`,
	"stepIcon": `StartupScaleRightContent-stepIcon-b2hzO`,
	"mainText": `StartupScaleRightContent-mainText-niDik`,
	"hightLightGreen": `StartupScaleRightContent-hightLightGreen-Byyoq`,
	"helpText": `StartupScaleRightContent-helpText-keVOX`
};
export default ___CSS_LOADER_EXPORT___;
