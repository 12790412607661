"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RecruitBoardFormsContext = void 0;
var react_1 = __importStar(require("react"));
var antd_1 = require("antd");
var ConfirmModal_1 = require("~/components/Modals/ConfirmModal");
var board_1 = require("~/hooks/recruit/board");
var react_query_1 = require("react-query");
var constants_1 = require("~/utils/constants");
var commentActions_1 = require("~/actionCreators/commentActions");
var react_redux_1 = require("react-redux");
var store_1 = require("~/models/store");
var actionTypes_1 = require("~/actions/actionTypes");
exports.RecruitBoardFormsContext = (0, react_1.createContext)(undefined);
var RecruitBoardFormsProvider = function (_a) {
    var children = _a.children;
    // NOTE(joshua-hashimoto): states
    var _b = (0, react_1.useState)(false), isModalOpen = _b[0], setIsModalOpen = _b[1];
    var _c = (0, react_1.useState)(false), isInvalid = _c[0], setIsInvalid = _c[1];
    var _d = (0, react_1.useState)(false), cardError = _d[0], setCardError = _d[1];
    // NOTE(joshua-hashimoto): third party hooks
    var queryClient = (0, react_query_1.useQueryClient)();
    var dispatch = (0, react_redux_1.useDispatch)();
    // NOTE(joshua-hashimoto): Forms
    var cardInfoForm = antd_1.Form.useForm()[0];
    var baseInfoForm = antd_1.Form.useForm()[0];
    var scheduledEmailTemplateForm = antd_1.Form.useForm()[0];
    var copyForm = antd_1.Form.useForm()[0];
    // NOTE(joshua-hashimoto): API methods
    var _e = (0, board_1.useRecruitBoardUpdateCardAPIMutation)(), updateCard = _e.mutate, isCardUpdating = _e.isLoading;
    var deleteCard = (0, board_1.useRecruitBoardDeleteCardAPIMutation)().mutate;
    var copyCard = (0, board_1.useRecruitBoardDuplicateCardAPIMutation)().mutate;
    var newCommentState = (0, store_1.useTypedSelector)(function (state) { return state.recruitBoardDetailPageNewComments; });
    var editCommentState = (0, store_1.useTypedSelector)(function (state) { return state.recruitBoardDetailPageEditComments; });
    var replyCommentState = (0, store_1.useTypedSelector)(function (state) {
        return state.recruitBoardDetailPageReplyComments;
    });
    var onUpdate = function (cardId, onSuccess, onSettled) {
        onUpdateCard(cardId, onSuccess, onSettled);
    };
    var onUpdateAndContinue = function (cardId) {
        onUpdate(cardId, function (response) {
            var data = response.data;
            var id = data.id;
            if (id) {
                queryClient.invalidateQueries([
                    constants_1.QueryKeys.recruitBoard.card,
                    { cardId: id },
                ]);
            }
        });
    };
    var onUpdateAndFinish = function (cardId, onAction) {
        onUpdate(cardId, function (response) {
            queryClient.invalidateQueries([
                constants_1.QueryKeys.recruit.board.allListCards,
            ]);
        }, function () {
            onModalClose();
            onAction();
        });
    };
    var onUpdateCard = function (cardId, onSuccess, onSettled) { return __awaiter(void 0, void 0, void 0, function () {
        var commentExists, newCommentValue, editCommentValue, replyCommentValue, cardInfoValues, baseInfoValues, postData, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    commentExists = false;
                    if (newCommentState !== undefined &&
                        typeof newCommentState === "object") {
                        newCommentValue = newCommentState.commentValue;
                        if (newCommentValue) {
                            dispatch({
                                type: actionTypes_1.PARENT_CANNOT_SUBMIT_WHEN_NEW_COMMENT_EXISTS,
                            });
                            commentExists = true;
                        }
                    }
                    if (editCommentState !== undefined &&
                        typeof editCommentState === "object") {
                        editCommentValue = editCommentState.commentValue;
                        if (editCommentValue) {
                            dispatch({
                                type: actionTypes_1.PARENT_CANNOT_SUBMIT_WHEN_EDIT_COMMENT_EXISTS,
                            });
                            commentExists = true;
                        }
                    }
                    if (replyCommentState !== undefined &&
                        typeof replyCommentState === "object") {
                        replyCommentValue = replyCommentState.commentValue;
                        if (replyCommentValue) {
                            dispatch({
                                type: actionTypes_1.PARENT_CANNOT_SUBMIT_WHEN_REPLY_COMMENT_EXISTS,
                            });
                            commentExists = true;
                        }
                    }
                    if (commentExists) {
                        return [2 /*return*/];
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, , 5]);
                    return [4 /*yield*/, cardInfoForm.validateFields()];
                case 2:
                    cardInfoValues = _a.sent();
                    return [4 /*yield*/, baseInfoForm.validateFields()];
                case 3:
                    baseInfoValues = _a.sent();
                    postData = __assign(__assign({}, cardInfoValues), baseInfoValues);
                    updateCard({
                        cardId: cardId,
                        postData: postData,
                    }, {
                        onSuccess: onSuccess,
                        onSettled: onSettled,
                    });
                    return [3 /*break*/, 5];
                case 4:
                    err_1 = _a.sent();
                    console.error(err_1);
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var onCopyCard = function (values) {
        copyCard(values, {
            onSuccess: function () {
                // NOTE(joshua-hashimoto): 更新処理をした後はモーダルを閉じるので、閉じるためのsubmitを実行
            },
        });
    };
    var onDeleteCard = function (cardId, onAction) {
        (0, ConfirmModal_1.confirmModal)({
            title: "この採用カードを削除しますか？",
            content: (react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement("p", null,
                    "OK\u3092\u62BC\u3059\u3068\u3001\u524A\u9664\u304C\u5B9F\u884C\u3055\u308C\u307E\u3059\u3002",
                    react_1.default.createElement("br", null),
                    "\u5143\u306B\u306F\u623B\u305B\u307E\u305B\u3093\u3002"))),
            onOk: function () {
                deleteCard(cardId);
                onModalClose();
                onAction();
            },
            onCancel: function () { },
        });
    };
    var removeQueries = function () {
        queryClient.removeQueries(constants_1.QueryKeys.recruitBoard.card, {
            exact: false,
        });
        queryClient.removeQueries(constants_1.QueryKeys.recruitBoard.contracts, {
            exact: false,
        });
        queryClient.removeQueries(constants_1.QueryKeys.recruit.board.scheduledEmailTemplate.fetch, { exact: false });
        queryClient.removeQueries(constants_1.QueryKeys.recruitBoard.skillSheet, {
            exact: false,
        });
        queryClient.removeQueries(constants_1.QueryKeys.recruitBoard.checklist, {
            exact: false,
        });
    };
    var onClean = function () {
        cardInfoForm.resetFields();
        baseInfoForm.resetFields();
        scheduledEmailTemplateForm.resetFields();
        removeQueries();
        copyForm.resetFields();
    };
    var onModalOpen = function () {
        setIsModalOpen(true);
    };
    var onModalClose = function () {
        dispatch(commentActions_1.CommentActions.newCommentClearAction());
        dispatch(commentActions_1.CommentActions.editCommentClearAction());
        setIsModalOpen(false);
        onClean();
    };
    var setIsArchived = function (value) {
        baseInfoForm.setFieldsValue({
            isArchived: value,
        });
    };
    var isUpdating = isCardUpdating;
    return (react_1.default.createElement(exports.RecruitBoardFormsContext.Provider, { value: {
            isModalOpen: isModalOpen,
            onModalOpen: onModalOpen,
            onModalClose: onModalClose,
            setIsArchived: setIsArchived,
            cardInfoForm: cardInfoForm,
            baseInfoForm: baseInfoForm,
            copyForm: copyForm,
            onUpdateCard: onUpdateCard,
            onUpdateAndContinue: onUpdateAndContinue,
            onUpdateAndFinish: onUpdateAndFinish,
            onCopyCard: onCopyCard,
            onDeleteCard: onDeleteCard,
            onClean: onClean,
            isUpdating: isUpdating,
            isInvalid: isInvalid,
            setIsInvalid: setIsInvalid,
            cardError: cardError,
            setCardError: setCardError,
        } }, children));
};
exports.default = RecruitBoardFormsProvider;
