// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContactsTable-paginator-Buu2W{margin:16px auto}.ContactsTable-tableWrapper-tDRoq{text-align:left}.ContactsTable-table-V5swd{margin:16px auto}.ContactsTable-ignored-PVXRv{color:silver}.ContactsTable-genericTable-vzknZ{word-break:break-all}.ContactsTable-newLine-VhFq1{white-space:pre-wrap}.ContactsTable-container-xRuFY{margin-top:4%}.ContactsTable-tableSpacer-gC_9G{margin:7px 0}.ContactsTable-tooltipCopy-PCsV3:hover span{display:initial}.ContactsTable-tagMailCC-IZwz3{margin-top:5px !important;margin-bottom:5px !important;display:inline-block !important;padding-top:0.5px !important;padding-bottom:0.5px !important}.ContactsTable-toolTipMailCC-_TEbd{margin:10px;float:right}.ContactsTable-mailTag-XRaV4{margin-top:5px}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Tables/Table.scss","webpack://./frontend/src/components/Tables/ContactsTable/ContactsTable.scss"],"names":[],"mappings":"AAAA,+BACE,gBAAiB,CAClB,kCAGC,eAAgB,CACjB,2BAGC,gBAAiB,CAClB,6BAGC,YAAa,CACd,kCAGC,oBAAqB,CACtB,6BAGC,oBAAqB,CACtB,+BAGC,aAAc,CACf,iCAGC,YAAa,CACd,4CAKO,eAAgB,CCjCxB,+BACE,yBAA0B,CAC1B,4BAA6B,CAC7B,+BAAgC,CAChC,4BAA6B,CAC7B,+BAAgC,CACjC,mCAGC,WAAY,CACZ,WACF,CAAC,6BAGC,cAAe","sourcesContent":[".paginator {\n  margin: 16px auto;\n}\n\n.tableWrapper {\n  text-align: left;\n}\n\n.table {\n  margin: 16px auto;\n}\n\n.ignored {\n  color: silver;\n}\n\n.genericTable {\n  word-break: break-all;\n}\n\n.newLine {\n  white-space: pre-wrap;\n}\n\n.container {\n  margin-top: 4%;\n}\n\n.tableSpacer {\n  margin: 7px 0;\n}\n\n.tooltipCopy {\n  &:hover {\n      span {\n        display: initial;\n      }\n  }\n}\n","@import \"../Table.scss\";\n\n.tagMailCC {\n  margin-top: 5px !important;\n  margin-bottom: 5px !important;\n  display: inline-block !important;\n  padding-top: 0.5px !important;\n  padding-bottom: 0.5px !important;\n}\n\n.toolTipMailCC {\n  margin: 10px;\n  float: right\n}\n\n.mailTag{\n  margin-top: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paginator": `ContactsTable-paginator-Buu2W`,
	"tableWrapper": `ContactsTable-tableWrapper-tDRoq`,
	"table": `ContactsTable-table-V5swd`,
	"ignored": `ContactsTable-ignored-PVXRv`,
	"genericTable": `ContactsTable-genericTable-vzknZ`,
	"newLine": `ContactsTable-newLine-VhFq1`,
	"container": `ContactsTable-container-xRuFY`,
	"tableSpacer": `ContactsTable-tableSpacer-gC_9G`,
	"tooltipCopy": `ContactsTable-tooltipCopy-PCsV3`,
	"tagMailCC": `ContactsTable-tagMailCC-IZwz3`,
	"toolTipMailCC": `ContactsTable-toolTipMailCC-_TEbd`,
	"mailTag": `ContactsTable-mailTag-XRaV4`
};
export default ___CSS_LOADER_EXPORT___;
