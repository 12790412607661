"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var api_1 = require("~/domain/api");
var purchaseHistory = function (client) {
    return {
        fetchPurchaseHistory: function (query) {
            var url = api_1.Endpoint.purchaseHistory + "?" + "page_size=".concat(query.pageSize);
            if (query.page) {
                url += "&page=" + query.page;
            }
            return client.get(url);
        },
        downloadAttachments: function () {
            var url = api_1.Endpoint.purchaseHistoryDownLoad;
            return client.post(url);
        },
    };
};
exports.default = purchaseHistory;
