// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CsvDownloadModal-tooltipPosition-ld5eJ{margin-top:6px}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Common/CsvDownloadModal/CsvDownloadModal.scss"],"names":[],"mappings":"AAAA,wCACI,cAAe","sourcesContent":[".tooltipPosition{\n    margin-top: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltipPosition": `CsvDownloadModal-tooltipPosition-ld5eJ`
};
export default ___CSS_LOADER_EXPORT___;
