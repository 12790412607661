// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ConfirmModal-focusButton-m8izz:focus{border-color:#A7CF69;background:#A7CF69}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Modals/ConfirmModal/ConfirmModal.scss"],"names":[],"mappings":"AACA,sCAEI,oBAAqB,CACrB,kBAAmB","sourcesContent":["\n.focusButton {\n  &:focus{\n    border-color: #A7CF69;\n    background: #A7CF69;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"focusButton": `ConfirmModal-focusButton-m8izz`
};
export default ___CSS_LOADER_EXPORT___;
