"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OpenRank = void 0;
var OpenRank;
(function (OpenRank) {
    OpenRank["S"] = "S";
    OpenRank["A"] = "A";
    OpenRank["B"] = "B";
    OpenRank["C"] = "C";
    OpenRank["D"] = "D";
    OpenRank["E"] = "E";
    OpenRank["NONE"] = "NONE";
    // NOTE(Chikama): actual value is null.
    OpenRank["NEVER"] = "NEVER";
})(OpenRank = exports.OpenRank || (exports.OpenRank = {}));
