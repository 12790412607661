import createEditPage from "./Factories/createEditPage";

import { MY_COMPANY_PAGE } from "./pageIds";

import MyCompanyForm from "../Forms/MyCompanyForm/MyCompanyForm";

import { Endpoint } from "../../domain/api";

import Paths from "../Routes/Paths";
import {
    convertCompanyResponseDataEntry,
    convertCompanyParamToAPI,
} from "../../domain/data";

const pageId = MY_COMPANY_PAGE;
const pageTitle = "自社プロフィール";
const resourceURL = `${Endpoint.getBaseUrl()}/${Endpoint.myCompanyPath}`;
const resourceName = "my_company";
const deleteAuthorized = () => {
    return false;
};
const accessAuthorized = (authorizedActions) => {
    return (
        authorizedActions &&
        authorizedActions[resourceName] &&
        authorizedActions[resourceName]["_all"]
    );
};

const MyCompanyPageContainer = createEditPage(
    pageId,
    "myCompanyPage",
    pageTitle,
    MyCompanyForm,
    resourceURL,
    "",
    Paths.index,
    convertCompanyResponseDataEntry,
    convertCompanyParamToAPI,
    undefined,
    undefined,
    deleteAuthorized,
    accessAuthorized,
    undefined,
    true,
    () => pageTitle,
    true,
    Paths.index,
    undefined,
    undefined
);

export default MyCompanyPageContainer;
