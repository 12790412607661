// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RecruitAttachmentFormItem-container-eeTUA{width:100%;text-align:left}.RecruitAttachmentFormItem-userInput-o9kXD{width:100%}.RecruitAttachmentFormItem-tooltip-uX4jt{margin-left:5px}.RecruitAttachmentFormItem-infoIcon-HSuT6{align-self:center}.RecruitAttachmentFormItem-select_option-oqjVu{text-align:center}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/recruit/RecruitBoardPage/RecruitBoardSearchDrawer/RecruitAttachmentFormItem/RecruitAttachmentFormItem.scss"],"names":[],"mappings":"AAAA,2CACI,UAAW,CACX,eAAgB,CACnB,2CAGG,UAAW,CACd,yCAGG,eAAgB,CACnB,0CAGG,iBAAkB,CACrB,+CAGG,iBAAkB","sourcesContent":[".container {\n    width: 100%;\n    text-align: left;\n}\n\n.userInput {\n    width: 100%;\n}\n\n.tooltip {\n    margin-left: 5px;\n}\n\n.infoIcon {\n    align-self: center;\n}\n\n.select_option {\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `RecruitAttachmentFormItem-container-eeTUA`,
	"userInput": `RecruitAttachmentFormItem-userInput-o9kXD`,
	"tooltip": `RecruitAttachmentFormItem-tooltip-uX4jt`,
	"infoIcon": `RecruitAttachmentFormItem-infoIcon-HSuT6`,
	"select_option": `RecruitAttachmentFormItem-select_option-oqjVu`
};
export default ___CSS_LOADER_EXPORT___;
