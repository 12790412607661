// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SESBSFSendServerSettingLabelFormItem-formItemContainer-H6Z2w{padding:0;margin:0}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/ScheduledEmailSettingPage/ScheduledEmailSettingForm/ScheduledEmailSettingBaseSettingsForm/SESBSFSendServerSettingLabelFormItem/SESBSFSendServerSettingLabelFormItem.scss"],"names":[],"mappings":"AAAA,8DACI,SAAU,CACV,QAAS","sourcesContent":[".formItemContainer {\n    padding: 0;\n    margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formItemContainer": `SESBSFSendServerSettingLabelFormItem-formItemContainer-H6Z2w`
};
export default ___CSS_LOADER_EXPORT___;
