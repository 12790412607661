// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PBContractPriceFormItem-wrapper-oRpCH{margin-bottom:0;padding-bottom:0}.PBContractPriceFormItem-input-l331S{width:100%}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/personnel/PersonnelBoardPage/PersonnelBoardDetailModal/PersonnelBoardContracts/PersonnelBoardContractsRow/PBContractPriceFormItem/PBContractPriceFormItem.scss"],"names":[],"mappings":"AAAA,uCACI,eAAgB,CAChB,gBAAiB,CACpB,qCAGG,UAAW","sourcesContent":[".wrapper {\n    margin-bottom: 0;\n    padding-bottom: 0;\n}\n\n.input {\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `PBContractPriceFormItem-wrapper-oRpCH`,
	"input": `PBContractPriceFormItem-input-l331S`
};
export default ___CSS_LOADER_EXPORT___;
