// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SharedEmailDetailPage-container-n0TpR{margin:auto}.SharedEmailDetailPage-pageHeader-JLfsv{padding:16px 0 0 0;text-align:left}.SharedEmailDetailPage-pageBody-sJmy_{padding:16px 0 0 0}.SharedEmailDetailPage-buttonWrapper-Kqby2{text-align:center;margin:auto;display:flex;justify-content:center}.SharedEmailDetailPage-button-vN9nK{margin:16px 32px;width:128px}.SharedEmailDetailPage-registerPageButtonWrapper-RoiLY{text-align:left;margin:16px auto}.SharedEmailDetailPage-downloadButtonWrapper-SPAHe{margin-top:8px;display:flex;justify-content:space-between;margin-bottom:16px}.SharedEmailDetailPage-search_template-_RRFS{margin:8px auto;text-align:left}.SharedEmailDetailPage-tableControlButton-dgcHB{margin-right:5px}.SharedEmailDetailPage-tableControlButton-dgcHB:focus{border-color:#A7CF69;background:#A7CF69}.SharedEmailDetailPage-tableSettingButton-hxvQo{margin-left:1%}.SharedEmailDetailPage-tableNewLine-f6l_B{white-space:pre-wrap}.SharedEmailDetailPage-simpleCardLabel-bdkKA{margin-top:2px;margin-right:4px}.SharedEmailDetailPage-errorMessages-H4DMY{margin-bottom:20px;text-align:left}.SharedEmailDetailPage-nowrap-m_AZ5{white-space:nowrap}.SharedEmailDetailPage-staffFormItem-IjgNm{width:100%}.SharedEmailDetailPage-staffSelectContainer-zekwX{margin-top:3%;width:100%}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/page.scss","webpack://./frontend/src/components/Pages/SharedEmailDetailPage/SharedEmailDetailPage.scss"],"names":[],"mappings":"AAAA,uCACE,WAAY,CACb,wCAGC,kBAAmB,CACnB,eAAgB,CACjB,sCAGC,kBAAmB,CACpB,2CAGC,iBAAkB,CAClB,WAAY,CACZ,YAAa,CACb,sBAAuB,CACxB,oCAGC,gBAAiB,CACjB,WAAY,CACb,uDAGC,eAAgB,CAChB,gBAAiB,CAClB,mDAGC,cAAe,CACf,YAAa,CACb,6BAA8B,CAC9B,kBAAmB,CACpB,6CAGC,eAAgB,CAChB,eAAgB,CACjB,gDAGC,gBAAiB,CADnB,sDAGI,oBAAqB,CACrB,kBAAmB,CACpB,gDAID,cAAe,CAChB,0CAGC,oBAAqB,CACtB,6CAGC,cAAe,CACf,gBAAiB,CAClB,2CAGC,kBAAmB,CACnB,eAAgB,CACjB,oCAGC,kBAAmB,CCnErB,2CACI,UAAW,CACd,kDAGG,aAAc,CACd,UAAW","sourcesContent":[".container {\n  margin: auto;\n}\n\n.pageHeader {\n  padding: 16px 0 0 0;\n  text-align: left;\n}\n\n.pageBody {\n  padding: 16px 0 0 0;\n}\n\n.buttonWrapper {\n  text-align: center;\n  margin: auto;\n  display: flex;\n  justify-content: center;\n}\n\n.button {\n  margin: 16px 32px;\n  width: 128px;\n}\n\n.registerPageButtonWrapper {\n  text-align: left;\n  margin: 16px auto;\n}\n\n.downloadButtonWrapper {\n  margin-top: 8px;\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 16px;\n}\n\n.search_template {\n  margin: 8px auto;\n  text-align: left;\n}\n\n.tableControlButton {\n  margin-right: 5px;\n  &:focus{\n    border-color: #A7CF69;\n    background: #A7CF69;\n  }\n}\n\n.tableSettingButton {\n  margin-left: 1%;\n}\n\n.tableNewLine {\n  white-space: pre-wrap;\n}\n\n.simpleCardLabel {\n  margin-top: 2px;\n  margin-right: 4px;\n}\n\n.errorMessages {\n  margin-bottom: 20px;\n  text-align: left;\n}\n\n.nowrap {\n  white-space: nowrap;\n}\n","@import \"../page.scss\";\n\n.staffFormItem {\n    width: 100%;\n}\n\n.staffSelectContainer {\n    margin-top: 3%;\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `SharedEmailDetailPage-container-n0TpR`,
	"pageHeader": `SharedEmailDetailPage-pageHeader-JLfsv`,
	"pageBody": `SharedEmailDetailPage-pageBody-sJmy_`,
	"buttonWrapper": `SharedEmailDetailPage-buttonWrapper-Kqby2`,
	"button": `SharedEmailDetailPage-button-vN9nK`,
	"registerPageButtonWrapper": `SharedEmailDetailPage-registerPageButtonWrapper-RoiLY`,
	"downloadButtonWrapper": `SharedEmailDetailPage-downloadButtonWrapper-SPAHe`,
	"search_template": `SharedEmailDetailPage-search_template-_RRFS`,
	"tableControlButton": `SharedEmailDetailPage-tableControlButton-dgcHB`,
	"tableSettingButton": `SharedEmailDetailPage-tableSettingButton-hxvQo`,
	"tableNewLine": `SharedEmailDetailPage-tableNewLine-f6l_B`,
	"simpleCardLabel": `SharedEmailDetailPage-simpleCardLabel-bdkKA`,
	"errorMessages": `SharedEmailDetailPage-errorMessages-H4DMY`,
	"nowrap": `SharedEmailDetailPage-nowrap-m_AZ5`,
	"staffFormItem": `SharedEmailDetailPage-staffFormItem-IjgNm`,
	"staffSelectContainer": `SharedEmailDetailPage-staffSelectContainer-zekwX`
};
export default ___CSS_LOADER_EXPORT___;
