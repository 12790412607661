// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AttachmentDownloaderModal-tag-m9jtP{margin-left:1;margin-right:1}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Common/AttachmentDownloaderModal/AttachmentDownloaderModal.scss"],"names":[],"mappings":"AAAA,qCACI,aAAc,CACd,cAAe","sourcesContent":[".tag {\n    margin-left: 1;\n    margin-right: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tag": `AttachmentDownloaderModal-tag-m9jtP`
};
export default ___CSS_LOADER_EXPORT___;
