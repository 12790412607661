// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SESBSFConnectionTypeFormItem-tooltip-XaTyi{margin-left:5px;color:#615748}.SESBSFConnectionTypeFormItem-infoIcon-PZy68{align-self:center}.SESBSFConnectionTypeFormItem-tooltipOverlay-hige3{min-width:270px}.SESBSFConnectionTypeFormItem-field-e9h3y{max-width:800px;margin:8px 0}@media screen and (max-width: 768px){.SESBSFConnectionTypeFormItem-field-e9h3y{margin:8px auto;width:100%}}.SESBSFConnectionTypeFormItem-inputNumberContainer-_5PNB{width:100%}
`, "",{"version":3,"sources":["webpack://./frontend/src/components/Pages/ScheduledEmailSettingPage/ScheduledEmailSettingForm/ScheduledEmailSettingBaseSettingsForm/SESBSFConnectionTypeFormItem/SESBSFConnectionTypeFormItem.scss","webpack://./frontend/stylesheets/constants.scss"],"names":[],"mappings":"AAEA,4CACI,eAAgB,CAChB,aCAoB,CDCvB,6CAGG,iBAAkB,CACrB,mDAGG,eAAgB,CACnB,0CAGG,eAAgB,CAChB,YAAa,CAKhB,qCAPD,0CAIQ,eAAgB,CAChB,UAAW,CAElB,CAED,yDACI,UAAW","sourcesContent":["@import \"~coreStylesheet\";\n\n.tooltip {\n    margin-left: 5px;\n    color: $komorebi-brown;\n}\n\n.infoIcon {\n    align-self: center;\n}\n\n.tooltipOverlay {\n    min-width: 270px;\n}\n\n.field {\n    max-width: 800px;\n    margin: 8px 0;\n    @include mobile-devices {\n        margin: 8px auto;\n        width: 100%;\n    }\n}\n\n.inputNumberContainer {\n    width: 100%;\n}\n","/* アプリケーション全体 */\n$breakpoint-mobile: 768px;\n$width-small-screen: 1500px;\n$primary-color: #a7cf69;\n$komorebi-brown: #615748;\n\n/* ガントチャート関連 */\n$gantt-over70: #003656;\n$gantt-between40to70: #80d6ff;\n$gantt-under40: #d0edff;\n\n@mixin mobile-devices() {\n    @media screen and (max-width: $breakpoint-mobile) {\n        @content;\n    }\n}\n\n@mixin small-screen() {\n    @media screen and (max-width: $width-small-screen) {\n        @content;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": `SESBSFConnectionTypeFormItem-tooltip-XaTyi`,
	"infoIcon": `SESBSFConnectionTypeFormItem-infoIcon-PZy68`,
	"tooltipOverlay": `SESBSFConnectionTypeFormItem-tooltipOverlay-hige3`,
	"field": `SESBSFConnectionTypeFormItem-field-e9h3y`,
	"inputNumberContainer": `SESBSFConnectionTypeFormItem-inputNumberContainer-_5PNB`
};
export default ___CSS_LOADER_EXPORT___;
