import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
    Button,
    Col,
    Comment,
    Form,
    Input,
    Divider,
    List,
    Tabs,
    Row,
    Checkbox,
    Switch,
    Radio,
    Tooltip,
    Typography,
    Spin,
} from "antd";
import {
    PushpinOutlined,
    SnippetsOutlined,
    CheckOutlined,
    QuestionCircleFilled,
    CloseOutlined,
    BankOutlined,
    HeartTwoTone,
    PushpinFilled,
    InfoCircleTwoTone,
} from "@ant-design/icons";
import Path from "~/components/Routes/Paths";
import BaseForm from "~/components/Forms/base/BaseForm";
import { Endpoint } from "~/domain/api";
import { finalized } from "~/components/Forms/helpers";
import { connect } from "react-redux";
import {
    fetchCommentTemplateAction,
    createCommentTemplateAction,
    fetchPlanSummary,
} from "~/actions/data";
import validateJapaneseMessages from "~/components/Forms/validateMessages";
import Paths from "~/components/Routes/Paths";
import BackButton from "~/components/Common/BackButton/BackButton";
import OrganizationAjaxSelect from "~/components/Forms/ajax/OrganizationAjaxSelect";
import CommentTemplateModal from "~/components/Modals/CommentTemplateModal/CommentTemplateModal";
import CommentTemplateCreateModal from "~/components/DataDisplay/CommentList/CommentTemplateCreateModal/CommentTemplateCreateModal";
import CommentTemplateUpdateModal from "~/components/DataDisplay/CommentList/CommentTemplateUpdateModal/CommentTemplateUpdateModal";
import CustomDatePicker from "~/components/Common/CustomDatePicker/CustomDatePicker";
import TelInputFormItem from "~/components/Forms/TelInputFormItem/TelInputFormItem";
import { confirmModal } from "~/components/Modals/ConfirmModal";
import {
    ErrorMessages,
    iconPrimaryColor,
    Links,
    ONLY_HANKAKU_REGEX,
    RESTRICT_SPACE_REGEX,
    NO_ONLY_SPACES_REGEX,
    ONLY_SPACES_AND_NEWLINE_REGEX,
    EMAIL_REGEX,
    iconCustomColor,
    PlanMasterId
} from "~/utils/constants";
import { disabledFutureDates } from "~/utils/utils";
import TagSelectFormItem from "~/components/Common/TagSelectFormItem/TagSelectFormItem";
import { CommentActions } from "~/actionCreators/commentActions";
import { checkExistEmailsAction } from "~/actions/data";
import { CONTACT_REGISTER_PAGE } from "~/components/Pages/pageIds";
import {
    customSuccessMessage,
    customErrorMessage,
} from "~/components/Common/AlertMessage/AlertMessage";
import ValidateTextArea from "~/components/Common/ValidateTextArea/ValidateTextArea";
import { ContactScheduledEmailTabPaneContent } from "~/components/Pages/ContactDetailPage";
import UserMentionsForms from "../UserMentionsForm/UserMentionsForm";
import UserAjaxSelect from "~/components/Common/UserAjaxSelect/UserAjaxSelect";
import HeartBrokenIcon from "~/components/icons/HeartBrokenIcon";
import { infoColor, svgColorIconLight, svgColorIconDark } from "~/utils/constants";
import ValidateEmailButton from "~/components/Common/ValidateEmailButton";
import { REQUIRED_EMAIL_FORM_RULES} from "../rules/formRules";
import { TooltipContentLink } from "~/components/Common/TooltipContentLink/TooltipContentLink";
import { convertPlanSummaryResponseModelToPlanSummaryModel } from "~/hooks/usePlan";
import { EmailHistoryTabTitle } from "./tooltipedItems";
import styles from "./ContactForm.scss";

const { Email: EmailValidateButton , CcEmail: CcMailValidateButton } = ValidateEmailButton;

let containerLayout = {
    span: 24,
};

const tailLayout = {
    wrapperCol: {
        xs: { offset: 0, span: 24 },
        sm: { offset: 6, span: 18 },
    },
};

const contactPaneFormItemLayoutLabelSpan = 5;
const contactPaneFormItemLayout = {
    labelCol: {
        span: contactPaneFormItemLayoutLabelSpan,
    },
    wrapperCol: {
        span: 18 - contactPaneFormItemLayoutLabelSpan,
    },
};

const streamTermsFormItemLayoutLabelSpan = 5;
const streamTermsFormItemLayout = {
    labelCol: {
        span: streamTermsFormItemLayoutLabelSpan,
    },
    wrapperCol: {
        span: 18 - streamTermsFormItemLayoutLabelSpan,
    },
};

const streamTermsContentFormItemLayoutLabelSpan =
    streamTermsFormItemLayoutLabelSpan + 3;
const streamTermsContentFormItemLayout = {
    labelCol: {
        span: streamTermsContentFormItemLayoutLabelSpan,
    },
    wrapperCol: {
        span: 18 - streamTermsContentFormItemLayoutLabelSpan,
    },
};

const emailItemLayoutLabelSpan = 8;
const emailItemLayout = {
    labelCol: {
        span: emailItemLayoutLabelSpan,
    },
    wrapperCol: {
        span: 24 - emailItemLayoutLabelSpan,
    },
};

const commentTemplateUrl = `${Endpoint.getBaseUrl()}/${
    Endpoint.commentTemplateContact
}`;
const planSummaryUrl = `${Endpoint.getBaseUrl()}/${
    Endpoint.planSummary
}`;

const { TabPane } = Tabs;
const { TextArea } = Input;
const { Title } = Typography;

const MaxTelLength = 15;
const MaxCommentLength = 2000;

class ContactForm extends BaseForm {
    // TODO: Create Form Items via factory.
    displayComment = true;

    constructor(props) {
        super(props);
        this.initialViewRendered = false;
    }

    state = {
        email_expand: true,
        qualificationExpand: true,
        checked: false,
        content: "",
        commentTemplateEditing: false,
        newTitle: undefined,
        newContent: undefined,
        selectedIndex: undefined,
        listVisible: false,
        registerVisible: false,
        updateVisible: false,
        deleteVisible: false,
        jobVisible: undefined,
        jobTypeChecked: undefined,
        jobTypeDevChecked: undefined,
        jobTypeInfraChecked: undefined,
        jobTypeOtherChecked: undefined,
        jobSkillChecked: undefined,
        jobSkillDevChecked: undefined,
        jobSkillInfraChecked: undefined,
        personnelVisible: undefined,
        hasSendGuide: true,
        personnelTypeChecked: undefined,
        personnelTypeDevChecked: undefined,
        personnelTypeInfraChecked: undefined,
        personnelTypeOtherChecked: undefined,
        personnelSkillChecked: undefined,
        personnelSkillDevChecked: undefined,
        personnelSkillInfraChecked: undefined,
        jobTypeNotSelected: false,
        jobSkillNotSelected: false,
        personnelTypeNotSelected: false,
        personnelSkillNotSelected: false,
        koyouNotSelected: false,
        locationNotSelected: false,
        jobSyouryuNotSelected: false,
        personnelSyouryuNotSelected: false,
        jobDevChecked: undefined,
        jobInfraChecked: undefined,
        jobOtherChecked: undefined,
        personnelDevChecked: undefined,
        personnelInfraChecked: undefined,
        personnelOtherChecked: undefined,
        jobDevNotSelected: false,
        jobInfraNotSelected: false,
        personnelDevNotSelected: false,
        personnelInfraNotSelected: false,
        currentTel: false,
        numberOfTel: false,
        errorFields: [],
        selectedCategory: undefined,
        isTitleValidationError: false,
        isContentValidationError: false,
        originationId: "",
        isShowCommentBox: true,
        isArchived: false,
        errorMessage: "",
    };

    onChangeCheck = () => {
        const { checked } = this.state;
        this.setState({ checked: !checked });
    };

    onChangeContent = (value) => {
        this.setState({ content: value });
    };

    handleSubmitWithCheck = () => {
        const { selectedCategory, isArchived } = this.state;
        let jobdevcheckresult = this.checkJobDevSelected();
        let jobinfracheckresult = this.checkJobInfraSelected();
        let jobitemcheckresult = this.checkJobItemSelected();
        let personneldevcheckresult = this.checkPersonnelDevSelected();
        let personnelinfracheckresult = this.checkPersonnelInfraSelected();
        let personnelitemcheckresult = this.checkPersonnelItemSelected();
        let koyoucheckresult = this.checkKoyouSelected();
        let locationcheckresult = this.checkLocationSelected();
        let jobsyouryucheckresult = this.checkJobSyouryuSelected();
        let personnelsyouryucheckresult = this.checkPersonnelSyouryuSelected();
        let currentfieldtels = [];

        this.baseform.current.validateFields().then((values) => {
            let telcheckresult = this.checkTelSelected(
                values,
                currentfieldtels
            );
            const isSubmittable =
                jobdevcheckresult &&
                jobinfracheckresult &&
                jobitemcheckresult &&
                personneldevcheckresult &&
                personnelinfracheckresult &&
                personnelitemcheckresult &&
                jobsyouryucheckresult &&
                koyoucheckresult &&
                locationcheckresult &&
                personnelsyouryucheckresult &&
                telcheckresult;

            values.tags = this.getTagIds(values.tags);

            if (isSubmittable) {
                if (values["comment.content"]) {
                    values["comment"] = {
                        content: values["comment.content"],
                        is_important: this.state.checked,
                    };
                }
                values.category = selectedCategory;
                if (!isArchived) {
                    values["is_archived"] = false;
                }
                this.submitHandler(values);
            }
        });
    };

    componentDidMount() {
        this.fetchComments();
        // FIXME(Chikama): This is bad practice.
        // Should use FC and react-query so that we can control refetch/cache in one place.
        this.fetchPlans();
    }

    componentWillUnmount(){
        const { dispatch } = this.props;
        dispatch(CommentActions.newCommentClearAction());
        dispatch(CommentActions.editCommentClearAction());
    }

    componentDidUpdate() {
        const {
            initialData,
            checkEmailExistEdit,
            checkEmailExistRegister,
            pageId,
        } = this.props;
        const {
            jobVisible,
            personnelVisible,
            jobTypeChecked,
            jobTypeDevChecked,
            jobTypeInfraChecked,
            jobTypeOtherChecked,
            jobSkillChecked,
            jobSkillDevChecked,
            jobSkillInfraChecked,
            personnelTypeChecked,
            personnelTypeDevChecked,
            personnelTypeInfraChecked,
            personnelTypeOtherChecked,
            personnelSkillChecked,
            personnelSkillDevChecked,
            personnelSkillInfraChecked,
            jobDevChecked,
            jobInfraChecked,
            jobOtherChecked,
            personnelDevChecked,
            personnelInfraChecked,
            personnelOtherChecked,
        } = this.state;
        const message =
            pageId === CONTACT_REGISTER_PAGE
                ? checkEmailExistRegister.message
                : checkEmailExistEdit.message;
        const errorMessage =
            pageId === CONTACT_REGISTER_PAGE
                ? checkEmailExistRegister.errorMessage
                : checkEmailExistEdit.errorMessage;
        if (message) {
            customSuccessMessage(message);
        }
        if (errorMessage) {
            customErrorMessage(errorMessage);
        }

        if (
            !this.initialViewRendered &&
            this.baseform.current &&
            initialData.email
        ) {
            if (initialData && initialData.email && jobVisible === undefined) {
                if (
                    (initialData.contactjobtypepreferences &&
                        initialData.contactjobtypepreferences.length > 0) ||
                    (initialData.contactjobskillpreferences &&
                        initialData.contactjobskillpreferences > 0)
                ) {
                    this.setState({ jobVisible: true });
                } else {
                    this.setState({ jobVisible: false });
                }
            }

            if (
                initialData &&
                initialData.email &&
                jobTypeChecked === undefined
            ) {
                if (
                    initialData.contactjobtypepreferences &&
                    initialData.contactjobtypepreferences.length > 14
                ) {
                    this.setState({ jobTypeChecked: true });
                } else {
                    this.setState({ jobTypeChecked: false });
                }
            }

            if (
                initialData &&
                initialData.email &&
                jobTypeDevChecked === undefined
            ) {
                if (
                    initialData.contactjobtypepreferences &&
                    (initialData.contactjobtypepreferences.includes(
                        "dev_designer"
                    ) ||
                        initialData.contactjobtypepreferences.includes(
                            "dev_server"
                        ) ||
                        initialData.contactjobtypepreferences.includes(
                            "dev_front"
                        ) ||
                        initialData.contactjobtypepreferences.includes(
                            "dev_pm"
                        ) ||
                        initialData.contactjobtypepreferences.includes(
                            "dev_other"
                        ))
                ) {
                    this.setState({ jobTypeDevChecked: true });
                    this.baseform.current.setFieldsValue({
                        jobstype_dev: true,
                    });
                } else {
                    this.setState({ jobTypeDevChecked: false });
                }
            }

            if (
                initialData &&
                initialData.email &&
                jobTypeInfraChecked === undefined
            ) {
                if (
                    initialData.contactjobtypepreferences &&
                    (initialData.contactjobtypepreferences.includes(
                        "infra_server"
                    ) ||
                        initialData.contactjobtypepreferences.includes(
                            "infra_network"
                        ) ||
                        initialData.contactjobtypepreferences.includes(
                            "infra_database"
                        ) ||
                        initialData.contactjobtypepreferences.includes(
                            "infra_security"
                        ) ||
                        initialData.contactjobtypepreferences.includes(
                            "infra_sys"
                        ) ||
                        initialData.contactjobtypepreferences.includes(
                            "infra_other"
                        ))
                ) {
                    this.setState({ jobTypeInfraChecked: true });
                    this.baseform.current.setFieldsValue({
                        jobstype_infra: true,
                    });
                } else {
                    this.setState({ jobTypeInfraChecked: false });
                }
            }

            if (
                initialData &&
                initialData.email &&
                jobTypeOtherChecked === undefined
            ) {
                if (
                    initialData.contactjobtypepreferences &&
                    (initialData.contactjobtypepreferences.includes(
                        "other_eigyo"
                    ) ||
                        initialData.contactjobtypepreferences.includes(
                            "other_kichi"
                        ) ||
                        initialData.contactjobtypepreferences.includes(
                            "other_support"
                        ) ||
                        initialData.contactjobtypepreferences.includes(
                            "other_other"
                        ))
                ) {
                    this.setState({ jobTypeOtherChecked: true });
                    this.baseform.current.setFieldsValue({
                        jobstype_other: true,
                    });
                } else {
                    this.setState({ jobTypeOtherChecked: false });
                }
            }

            if (
                initialData &&
                initialData.email &&
                jobSkillChecked === undefined
            ) {
                if (
                    initialData.contactjobskillpreferences &&
                    initialData.contactjobskillpreferences.length > 14
                ) {
                    this.setState({ jobSkillChecked: true });
                } else {
                    this.setState({ jobSkillChecked: false });
                }
            }

            if (
                initialData &&
                initialData.email &&
                jobSkillDevChecked === undefined
            ) {
                if (
                    initialData.contactjobskillpreferences &&
                    (initialData.contactjobskillpreferences.includes(
                        "dev_youken"
                    ) ||
                        initialData.contactjobskillpreferences.includes(
                            "dev_kihon"
                        ) ||
                        initialData.contactjobskillpreferences.includes(
                            "dev_syousai"
                        ) ||
                        initialData.contactjobskillpreferences.includes(
                            "dev_seizou"
                        ) ||
                        initialData.contactjobskillpreferences.includes(
                            "dev_test"
                        ) ||
                        initialData.contactjobskillpreferences.includes(
                            "dev_hosyu"
                        ) ||
                        initialData.contactjobskillpreferences.includes(
                            "dev_beginner"
                        ))
                ) {
                    this.setState({ jobSkillDevChecked: true });
                    this.baseform.current.setFieldsValue({
                        jobskill_dev: true,
                    });
                } else {
                    this.setState({ jobSkillDevChecked: false });
                }
            }

            if (
                initialData &&
                initialData.email &&
                jobSkillInfraChecked === undefined
            ) {
                if (
                    initialData.contactjobskillpreferences &&
                    (initialData.contactjobskillpreferences.includes(
                        "infra_youken"
                    ) ||
                        initialData.contactjobskillpreferences.includes(
                            "infra_kihon"
                        ) ||
                        initialData.contactjobskillpreferences.includes(
                            "infra_syousai"
                        ) ||
                        initialData.contactjobskillpreferences.includes(
                            "infra_kouchiku"
                        ) ||
                        initialData.contactjobskillpreferences.includes(
                            "infra_test"
                        ) ||
                        initialData.contactjobskillpreferences.includes(
                            "infra_hosyu"
                        ) ||
                        initialData.contactjobskillpreferences.includes(
                            "infra_kanshi"
                        ) ||
                        initialData.contactjobskillpreferences.includes(
                            "infra_beginner"
                        ))
                ) {
                    this.setState({ jobSkillInfraChecked: true });
                    this.baseform.current.setFieldsValue({
                        jobskill_infra: true,
                    });
                } else {
                    this.setState({ jobSkillInfraChecked: false });
                }
            }

            if (
                initialData &&
                initialData.email &&
                personnelVisible === undefined
            ) {
                if (
                    (initialData.contactpersonneltypepreferences &&
                        initialData.contactpersonneltypepreferences.length >
                            0) ||
                    (initialData.contactpersonnelskillpreferences &&
                        initialData.contactpersonnelskillpreferences > 0)
                ) {
                    this.setState({ personnelVisible: true });
                } else {
                    this.setState({ personnelVisible: false });
                }
            }

            if (
                initialData &&
                initialData.email &&
                personnelTypeChecked === undefined
            ) {
                if (
                    initialData.contactpersonneltypepreferences &&
                    initialData.contactpersonneltypepreferences.length > 14
                ) {
                    this.setState({ personnelTypeChecked: true });
                } else {
                    this.setState({ personnelTypeChecked: false });
                }
            }

            if (
                initialData &&
                initialData.email &&
                personnelTypeDevChecked === undefined
            ) {
                if (
                    initialData.contactpersonneltypepreferences &&
                    (initialData.contactpersonneltypepreferences.includes(
                        "dev_designer"
                    ) ||
                        initialData.contactpersonneltypepreferences.includes(
                            "dev_server"
                        ) ||
                        initialData.contactpersonneltypepreferences.includes(
                            "dev_front"
                        ) ||
                        initialData.contactpersonneltypepreferences.includes(
                            "dev_pm"
                        ) ||
                        initialData.contactpersonneltypepreferences.includes(
                            "dev_other"
                        ))
                ) {
                    this.setState({ personnelTypeDevChecked: true });
                    this.baseform.current.setFieldsValue({
                        personneltype_dev: true,
                    });
                } else {
                    this.setState({ personnelTypeDevChecked: false });
                }
            }

            if (
                initialData &&
                initialData.email &&
                personnelTypeInfraChecked === undefined
            ) {
                if (
                    initialData.contactpersonneltypepreferences &&
                    (initialData.contactpersonneltypepreferences.includes(
                        "infra_server"
                    ) ||
                        initialData.contactpersonneltypepreferences.includes(
                            "infra_network"
                        ) ||
                        initialData.contactpersonneltypepreferences.includes(
                            "infra_database"
                        ) ||
                        initialData.contactpersonneltypepreferences.includes(
                            "infra_security"
                        ) ||
                        initialData.contactpersonneltypepreferences.includes(
                            "infra_sys"
                        ) ||
                        initialData.contactpersonneltypepreferences.includes(
                            "infra_other"
                        ))
                ) {
                    this.setState({ personnelTypeInfraChecked: true });
                    this.baseform.current.setFieldsValue({
                        personneltype_infra: true,
                    });
                } else {
                    this.setState({ personnelTypeInfraChecked: false });
                }
            }

            if (
                initialData &&
                initialData.email &&
                personnelTypeOtherChecked === undefined
            ) {
                if (
                    initialData.contactpersonneltypepreferences &&
                    (initialData.contactpersonneltypepreferences.includes(
                        "other_eigyo"
                    ) ||
                        initialData.contactpersonneltypepreferences.includes(
                            "other_kichi"
                        ) ||
                        initialData.contactpersonneltypepreferences.includes(
                            "other_support"
                        ) ||
                        initialData.contactpersonneltypepreferences.includes(
                            "other_other"
                        ))
                ) {
                    this.setState({ personnelTypeOtherChecked: true });
                    this.baseform.current.setFieldsValue({
                        personneltype_other: true,
                    });
                } else {
                    this.setState({ personnelTypeOtherChecked: false });
                }
            }

            if (
                initialData &&
                initialData.email &&
                personnelSkillChecked === undefined
            ) {
                if (
                    initialData.contactpersonnelskillpreferences &&
                    initialData.contactpersonnelskillpreferences.length > 14
                ) {
                    this.setState({ personnelSkillChecked: true });
                } else {
                    this.setState({ personnelSkillChecked: false });
                }
            }

            if (
                initialData &&
                initialData.email &&
                personnelSkillDevChecked === undefined
            ) {
                if (
                    initialData.contactpersonnelskillpreferences &&
                    (initialData.contactpersonnelskillpreferences.includes(
                        "dev_youken"
                    ) ||
                        initialData.contactpersonnelskillpreferences.includes(
                            "dev_kihon"
                        ) ||
                        initialData.contactpersonnelskillpreferences.includes(
                            "dev_syousai"
                        ) ||
                        initialData.contactpersonnelskillpreferences.includes(
                            "dev_seizou"
                        ) ||
                        initialData.contactpersonnelskillpreferences.includes(
                            "dev_test"
                        ) ||
                        initialData.contactpersonnelskillpreferences.includes(
                            "dev_hosyu"
                        ) ||
                        initialData.contactpersonnelskillpreferences.includes(
                            "dev_beginner"
                        ))
                ) {
                    this.setState({ personnelSkillDevChecked: true });
                    this.baseform.current.setFieldsValue({
                        personnelskill_dev: true,
                    });
                } else {
                    this.setState({ personnelSkillDevChecked: false });
                }
            }

            if (
                initialData &&
                initialData.email &&
                personnelSkillInfraChecked === undefined
            ) {
                if (
                    initialData.contactpersonnelskillpreferences &&
                    (initialData.contactpersonnelskillpreferences.includes(
                        "infra_youken"
                    ) ||
                        initialData.contactpersonnelskillpreferences.includes(
                            "infra_kihon"
                        ) ||
                        initialData.contactpersonnelskillpreferences.includes(
                            "infra_syousai"
                        ) ||
                        initialData.contactpersonnelskillpreferences.includes(
                            "infra_kouchiku"
                        ) ||
                        initialData.contactpersonnelskillpreferences.includes(
                            "infra_test"
                        ) ||
                        initialData.contactpersonnelskillpreferences.includes(
                            "infra_hosyu"
                        ) ||
                        initialData.contactpersonnelskillpreferences.includes(
                            "infra_kanshi"
                        ) ||
                        initialData.contactpersonnelskillpreferences.includes(
                            "infra_beginner"
                        ))
                ) {
                    this.setState({ personnelSkillInfraChecked: true });
                    this.baseform.current.setFieldsValue({
                        personnelskill_infra: true,
                    });
                } else {
                    this.setState({ personnelSkillInfraChecked: false });
                }
            }

            if (
                initialData &&
                initialData.email &&
                jobDevChecked === undefined
            ) {
                if (
                    (initialData.contactjobtypepreferences &&
                        (initialData.contactjobtypepreferences.includes(
                            "dev_designer"
                        ) ||
                            initialData.contactjobtypepreferences.includes(
                                "dev_server"
                            ) ||
                            initialData.contactjobtypepreferences.includes(
                                "dev_front"
                            ) ||
                            initialData.contactjobtypepreferences.includes(
                                "dev_pm"
                            ) ||
                            initialData.contactjobtypepreferences.includes(
                                "dev_other"
                            ))) ||
                    (initialData.contactjobskillpreferences &&
                        (initialData.contactjobskillpreferences.includes(
                            "dev_youken"
                        ) ||
                            initialData.contactjobskillpreferences.includes(
                                "dev_kihon"
                            ) ||
                            initialData.contactjobskillpreferences.includes(
                                "dev_syousai"
                            ) ||
                            initialData.contactjobskillpreferences.includes(
                                "dev_seizou"
                            ) ||
                            initialData.contactjobskillpreferences.includes(
                                "dev_test"
                            ) ||
                            initialData.contactjobskillpreferences.includes(
                                "dev_hosyu"
                            ) ||
                            initialData.contactjobskillpreferences.includes(
                                "dev_beginner"
                            )))
                ) {
                    this.setState({ jobDevChecked: true });
                    this.baseform.current.setFieldsValue({ job_dev: true });
                } else {
                    this.setState({ jobDevChecked: false });
                }
            }

            if (
                initialData &&
                initialData.email &&
                jobInfraChecked === undefined
            ) {
                if (
                    (initialData.contactjobtypepreferences &&
                        (initialData.contactjobtypepreferences.includes(
                            "infra_server"
                        ) ||
                            initialData.contactjobtypepreferences.includes(
                                "infra_network"
                            ) ||
                            initialData.contactjobtypepreferences.includes(
                                "infra_database"
                            ) ||
                            initialData.contactjobtypepreferences.includes(
                                "infra_security"
                            ) ||
                            initialData.contactjobtypepreferences.includes(
                                "infra_sys"
                            ) ||
                            initialData.contactjobtypepreferences.includes(
                                "infra_other"
                            ))) ||
                    (initialData.contactjobskillpreferences &&
                        (initialData.contactjobskillpreferences.includes(
                            "infra_youken"
                        ) ||
                            initialData.contactjobskillpreferences.includes(
                                "infra_kihon"
                            ) ||
                            initialData.contactjobskillpreferences.includes(
                                "infra_syousai"
                            ) ||
                            initialData.contactjobskillpreferences.includes(
                                "infra_kouchiku"
                            ) ||
                            initialData.contactjobskillpreferences.includes(
                                "infra_test"
                            ) ||
                            initialData.contactjobskillpreferences.includes(
                                "infra_hosyu"
                            ) ||
                            initialData.contactjobskillpreferences.includes(
                                "infra_kanshi"
                            ) ||
                            initialData.contactjobskillpreferences.includes(
                                "infra_beginner"
                            )))
                ) {
                    this.setState({ jobInfraChecked: true });
                    this.baseform.current.setFieldsValue({ job_infra: true });
                } else {
                    this.setState({ jobInfraChecked: false });
                }
            }

            if (
                initialData &&
                initialData.email &&
                jobOtherChecked === undefined
            ) {
                if (
                    initialData.contactjobtypepreferences &&
                    (initialData.contactjobtypepreferences.includes(
                        "other_eigyo"
                    ) ||
                        initialData.contactjobtypepreferences.includes(
                            "other_kichi"
                        ) ||
                        initialData.contactjobtypepreferences.includes(
                            "other_support"
                        ) ||
                        initialData.contactjobtypepreferences.includes(
                            "other_other"
                        ))
                ) {
                    this.setState({ jobOtherChecked: true });
                    this.baseform.current.setFieldsValue({ job_other: true });
                } else {
                    this.setState({ jobOtherChecked: false });
                }
            }

            if (
                initialData &&
                initialData.email &&
                personnelDevChecked === undefined
            ) {
                if (
                    (initialData.contactpersonneltypepreferences &&
                        (initialData.contactpersonneltypepreferences.includes(
                            "dev_designer"
                        ) ||
                            initialData.contactpersonneltypepreferences.includes(
                                "dev_server"
                            ) ||
                            initialData.contactpersonneltypepreferences.includes(
                                "dev_front"
                            ) ||
                            initialData.contactpersonneltypepreferences.includes(
                                "dev_pm"
                            ) ||
                            initialData.contactpersonneltypepreferences.includes(
                                "dev_other"
                            ))) ||
                    (initialData.contactpersonnelskillpreferences &&
                        (initialData.contactpersonnelskillpreferences.includes(
                            "dev_youken"
                        ) ||
                            initialData.contactpersonnelskillpreferences.includes(
                                "dev_kihon"
                            ) ||
                            initialData.contactpersonnelskillpreferences.includes(
                                "dev_syousai"
                            ) ||
                            initialData.contactpersonnelskillpreferences.includes(
                                "dev_seizou"
                            ) ||
                            initialData.contactpersonnelskillpreferences.includes(
                                "dev_test"
                            ) ||
                            initialData.contactpersonnelskillpreferences.includes(
                                "dev_hosyu"
                            ) ||
                            initialData.contactpersonnelskillpreferences.includes(
                                "dev_beginner"
                            )))
                ) {
                    this.setState({ personnelDevChecked: true });
                    this.baseform.current.setFieldsValue({
                        personnel_dev: true,
                    });
                } else {
                    this.setState({ personnelDevChecked: false });
                }
            }

            if (
                initialData &&
                initialData.email &&
                personnelInfraChecked === undefined
            ) {
                if (
                    (initialData.contactpersonneltypepreferences &&
                        (initialData.contactpersonneltypepreferences.includes(
                            "infra_server"
                        ) ||
                            initialData.contactpersonneltypepreferences.includes(
                                "infra_network"
                            ) ||
                            initialData.contactpersonneltypepreferences.includes(
                                "infra_database"
                            ) ||
                            initialData.contactpersonneltypepreferences.includes(
                                "infra_security"
                            ) ||
                            initialData.contactpersonneltypepreferences.includes(
                                "infra_sys"
                            ) ||
                            initialData.contactpersonneltypepreferences.includes(
                                "infra_other"
                            ))) ||
                    (initialData.contactpersonnelskillpreferences &&
                        (initialData.contactpersonnelskillpreferences.includes(
                            "infra_youken"
                        ) ||
                            initialData.contactpersonnelskillpreferences.includes(
                                "infra_kihon"
                            ) ||
                            initialData.contactpersonnelskillpreferences.includes(
                                "infra_syousai"
                            ) ||
                            initialData.contactpersonnelskillpreferences.includes(
                                "infra_kouchiku"
                            ) ||
                            initialData.contactpersonnelskillpreferences.includes(
                                "infra_test"
                            ) ||
                            initialData.contactpersonnelskillpreferences.includes(
                                "infra_hosyu"
                            ) ||
                            initialData.contactpersonnelskillpreferences.includes(
                                "infra_kanshi"
                            ) ||
                            initialData.contactpersonnelskillpreferences.includes(
                                "infra_beginner"
                            )))
                ) {
                    this.setState({ personnelInfraChecked: true });
                    this.baseform.current.setFieldsValue({
                        personnel_infra: true,
                    });
                } else {
                    this.setState({ personnelInfraChecked: false });
                }
            }

            if (
                initialData &&
                initialData.email &&
                personnelOtherChecked === undefined
            ) {
                if (
                    initialData.contactpersonneltypepreferences &&
                    (initialData.contactpersonneltypepreferences.includes(
                        "other_eigyo"
                    ) ||
                        initialData.contactpersonneltypepreferences.includes(
                            "other_kichi"
                        ) ||
                        initialData.contactpersonneltypepreferences.includes(
                            "other_support"
                        ) ||
                        initialData.contactpersonneltypepreferences.includes(
                            "other_other"
                        ))
                ) {
                    this.setState({ personnelOtherChecked: true });
                    this.baseform.current.setFieldsValue({
                        personnel_other: true,
                    });
                } else {
                    this.setState({ personnelOtherChecked: false });
                }
            }

            if (initialData && initialData.category) {
                this.setState({ selectedCategory: initialData.category });
            }

            if (initialData) {
                this.setState({ hasSendGuide: initialData.has_send_guide });
            }

            this.baseform.current.setFieldsValue(initialData);
            this.setState({
                isArchived: initialData.is_archived,
            });
            this.initialViewRendered = true;
        }
    }

    // FIXME(Chikama): This is bad practice.
    //  Should use FC and react-query so that we can control refetch/cache in one place.
    fetchPlans = async () => {
        const { dispatch, token } = this.props;
        dispatch(fetchPlanSummary(token, planSummaryUrl, convertPlanSummaryResponseModelToPlanSummaryModel));
    }

    fetchComments = () => {
        const { dispatch, token, pageId } = this.props;
        dispatch(fetchCommentTemplateAction(pageId, token, commentTemplateUrl));
    };

    createComments = (comment_templates) => {
        const { dispatch, token, pageId } = this.props;
        dispatch(
            createCommentTemplateAction(pageId, token, commentTemplateUrl, {
                templates: comment_templates,
            })
        );
    };

    setCommentTemplateEditing = () => {
        const { commentTemplateEditing } = this.state;
        this.setState({ commentTemplateEditing: !commentTemplateEditing });
    };

    // list
    showList = () => {
        this.setState({ listVisible: true });
    };

    // register
    showRegister = () => {
        this.setState({
            registerVisible: true,
            newTitle: undefined,
            newContent: undefined,
        });
    };

    registerOnOk = ({ newTitle, newContent }) => {
        const { comment_templates } = this.props;
        if (newTitle && newContent) {
            const newTemplate = {
                title: newTitle,
                content: newContent,
            };
            this.createComments([...comment_templates, newTemplate]);
            this.setState({ registerVisible: false });
            if (
                comment_templates
                    .map((template) => template.title)
                    .includes(newTitle)
            ) {
                return;
            }
            comment_templates.push(newTemplate);
        }
    };

    registerOnCancel = () => {
        this.setState({
            registerVisible: false,
            isTitleValidationError: false,
            isContentValidationError: false,
        });
    };

    onChangeContentWithValidation = (e) => {
        const value = e.target.value;
        this.setState({
            isContentValidationError: value && value.length > 500,
        });
    };

    // update
    showUpdate = (index) => {
        this.setState({
            updateVisible: true,
            selectedIndex: index,
            newTitle: undefined,
            newContent: undefined,
        });
    };

    updateOnOk = ({ newTitle: updatedTitle, newContent: updatedContent }) => {
        const { selectedIndex } = this.state;
        const { comment_templates } = this.props;
        var updated_templates = [];
        comment_templates.map((template, index) => {
            if (index === selectedIndex) {
                var title = updatedTitle ? updatedTitle : template.title;
                var content = updatedContent
                    ? updatedContent
                    : template.content;
                updated_templates.push({ title: title, content: content });
            } else {
                updated_templates.push(template);
            }
        });
        this.setState({ updateVisible: false });
        this.createComments(updated_templates);
    };

    updateOnCancel = () => {
        this.setState({ updateVisible: false, selectedIndex: undefined });
    };

    // delete
    showDelete = (index) => {
        this.setState({ deleteVisible: true, selectedIndex: index });
        confirmModal({
            title: "このテンプレートを削除しますか？",
            content: (
                <div>
                    <p>OKを押すと、削除が実行されます。</p>
                    <p>元には戻せません。</p>
                </div>
            ),
            onOk: this.deleteOnOk,
            onCancel: this.deleteOnCancel,
        });
    };

    deleteOnOk = () => {
        const { selectedIndex } = this.state;
        const { comment_templates } = this.props;
        var not_deleted_templates = [];
        comment_templates.map((template, index) => {
            if (index !== selectedIndex) {
                not_deleted_templates.push(template);
            }
        });
        this.setState({
            deleteVisible: false,
            comment_templates: not_deleted_templates,
        });
        this.createComments(not_deleted_templates);
    };

    deleteOnCancel = () => {
        this.setState({ deleteVisible: false, selectedIndex: undefined });
    };

    setNewTitle = (e) => {
        this.setState({ newTitle: e.target.value });
    };

    setNewContent = (e) => {
        this.setState({ newContent: e.target.value });
    };

    setTemplateValueToCommentForm = (index) => {
        const { comment_templates } = this.props;
        const { setFieldsValue } = this.baseform.current;
        setFieldsValue({ "comment.content": comment_templates[index].content });
    };

    onChangeJobSwitch = (ok_action) => {
        const { jobVisible } = this.state;
        if (jobVisible) {
            ok_action();
        } else {
            this.setState({ jobVisible: !jobVisible });
        }
    };

    resetJobProfile = () => {
        const { jobVisible, personnelVisible } = this.state;
        const { setFieldsValue } = this.baseform.current;
        if (jobVisible) {
            this.setJobTypeDev(undefined);
            this.setJobTypeInfra(undefined);
            this.setJobTypeOther(undefined);
            this.setJobSkillDev(undefined);
            this.setJobSkillInfra(undefined);
            setFieldsValue({ job_syouryu: undefined });
            setFieldsValue({ jobtype_select_all: undefined });
            setFieldsValue({ jobtype_dev: undefined });
            setFieldsValue({ jobtype_infra: undefined });
            setFieldsValue({ jobtype_other: undefined });
            setFieldsValue({ jobskill_select_all: undefined });
            setFieldsValue({ jobskill_dev: undefined });
            setFieldsValue({ jobskill_infra: undefined });
            setFieldsValue({ job_other: undefined });
            this.setState({
                jobOtherChecked: undefined,
                jobTypeOtherChecked: undefined,
                jobInfraChecked: undefined,
                jobTypeInfraChecked: undefined,
                jobSkillInfraChecked: undefined,
                jobDevChecked: undefined,
                jobTypeDevChecked: undefined,
                jobSkillDevChecked: undefined,
                jobTypeNotSelected: false,
                jobDevNotSelected: false,
                jobInfraNotSelected: false,
                jobSyouryuNotSelected: undefined,
                locationNotSelected: false,
            });
            setFieldsValue({ job_infra: undefined });
            setFieldsValue({ job_dev: undefined });
            if (!personnelVisible) {
                setFieldsValue({ wants_location_hokkaido_japan: undefined });
                setFieldsValue({ wants_location_touhoku_japan: undefined });
                setFieldsValue({ wants_location_kanto_japan: undefined });
                setFieldsValue({ wants_location_kansai_japan: undefined });
                setFieldsValue({ wants_location_chubu_japan: undefined });
                setFieldsValue({ wants_location_kyushu_japan: undefined });
                setFieldsValue({ wants_location_other_japan: undefined });
                setFieldsValue({ wants_location_chugoku_japan: undefined });
                setFieldsValue({ wants_location_shikoku_japan: undefined });
                setFieldsValue({ wants_location_toukai_japan: undefined });
                this.setState({ locationNotSelected: false });
            }
        }
        this.setState({ jobVisible: !jobVisible });
    };

    onChangeJobType = () => {
        const { jobTypeChecked } = this.state;
        const { setFieldsValue } = this.baseform.current;

        let value = jobTypeChecked ? undefined : true;
        this.setJobTypeDev(value);
        this.setJobTypeInfra(value);
        this.setJobTypeOther(value);
        setFieldsValue({ jobtype_dev: value });
        setFieldsValue({ jobtype_infra: value });
        setFieldsValue({ jobtype_other: value });
        this.setState({
            jobTypeChecked: !jobTypeChecked,
            jobTypeDevChecked: !jobTypeChecked,
            jobTypeInfraChecked: !jobTypeChecked,
            jobTypeOtherChecked: !jobTypeChecked,
        });
    };

    onChangeJobTypeAndSkillDev = () => {
        const { jobDevChecked } = this.state;
        const { setFieldsValue } = this.baseform.current;
        let value = jobDevChecked ? undefined : true;
        this.setJobTypeDev(value);
        this.setJobSkillDev(value);
        this.setState({
            jobDevChecked: !jobDevChecked,
            jobTypeDevChecked: !jobDevChecked,
            jobSkillDevChecked: !jobDevChecked,
        });
        setFieldsValue({ job_dev: value });
        setFieldsValue({ jobtype_dev: value });
        setFieldsValue({ jobskill_dev: value });
    };

    onChangeJobTypeAndSkillInfra = () => {
        const { jobInfraChecked } = this.state;
        const { setFieldsValue } = this.baseform.current;
        let value = jobInfraChecked ? undefined : true;
        this.setJobTypeInfra(value);
        this.setJobSkillInfra(value);
        this.setState({
            jobInfraChecked: !jobInfraChecked,
            jobTypeInfraChecked: !jobInfraChecked,
            jobSkillInfraChecked: !jobInfraChecked,
        });
        setFieldsValue({ job_infra: value });
        setFieldsValue({ jobtype_infra: value });
        setFieldsValue({ jobskill_infra: value });
    };

    onChangeJobTypeAndSkillOther = () => {
        const { jobOtherChecked } = this.state;
        const { setFieldsValue } = this.baseform.current;
        let value = jobOtherChecked ? undefined : true;
        this.setJobTypeOther(value);
        this.setState({
            jobOtherChecked: !jobOtherChecked,
            jobTypeOtherChecked: !jobOtherChecked,
        });
        setFieldsValue({ job_other: value });
        setFieldsValue({ jobtype_other: value });
    };

    onChangePersonnelTypeAndSkillDev = () => {
        const { personnelDevChecked } = this.state;
        const { setFieldsValue } = this.baseform.current;
        let value = personnelDevChecked ? undefined : true;
        this.setPersonnelTypeDev(value);
        this.setPersonnelSkillDev(value);
        this.setState({
            personnelDevChecked: !personnelDevChecked,
            personnelTypeDevChecked: !personnelDevChecked,
            personnelSkillDevChecked: !personnelDevChecked,
        });
        setFieldsValue({ personnel_dev: value });
        setFieldsValue({ personneltype_dev: value });
        setFieldsValue({ personnelskill_dev: value });
    };

    onChangePersonnelTypeAndSkillInfra = () => {
        const { personnelInfraChecked } = this.state;
        const { setFieldsValue } = this.baseform.current;
        let value = personnelInfraChecked ? undefined : true;
        this.setPersonnelTypeInfra(value);
        this.setPersonnelSkillInfra(value);
        this.setState({
            personnelInfraChecked: !personnelInfraChecked,
            personnelTypeInfraChecked: !personnelInfraChecked,
            personnelSkillInfraChecked: !personnelInfraChecked,
        });
        setFieldsValue({ personnel_infra: value });
        setFieldsValue({ personneltype_infra: value });
        setFieldsValue({ personnelskill_infra: value });
    };

    onChangePersonnelTypeAndSkillOther = () => {
        const { personnelOtherChecked } = this.state;
        const { setFieldsValue } = this.baseform.current;
        let value = personnelOtherChecked ? undefined : true;
        this.setPersonnelTypeOther(value);
        this.setState({
            personnelOtherChecked: !personnelOtherChecked,
            personnelTypeOtherChecked: !personnelOtherChecked,
        });
        setFieldsValue({ personnel_other: value });
        setFieldsValue({ personneltype_other: value });
    };

    onChangeJobTypeDev = () => {
        const { jobTypeDevChecked, jobSkillDevChecked } = this.state;
        let value = jobTypeDevChecked ? undefined : true;
        this.setJobTypeDev(value);
        this.setState({ jobTypeDevChecked: !jobTypeDevChecked });

        const { setFieldsValue } = this.baseform.current;
        if (value) {
            setFieldsValue({ job_dev: true });
            this.setState({ jobDevChecked: true });
        } else if (!jobSkillDevChecked) {
            setFieldsValue({ job_dev: false });
            this.setState({ jobDevChecked: false });
        }
    };

    onChangeJobTypeInfra = () => {
        const { jobTypeInfraChecked, jobSkillInfraChecked } = this.state;
        let value = jobTypeInfraChecked ? undefined : true;
        this.setJobTypeInfra(value);
        this.setState({ jobTypeInfraChecked: !jobTypeInfraChecked });

        const { setFieldsValue } = this.baseform.current;
        if (value) {
            setFieldsValue({ job_infra: true });
            this.setState({ jobInfraChecked: true });
        } else if (!jobSkillInfraChecked) {
            setFieldsValue({ job_infra: false });
            this.setState({ jobInfraChecked: false });
        }
    };

    onChangeJobTypeOther = () => {
        const { jobTypeOtherChecked } = this.state;
        let value = jobTypeOtherChecked ? undefined : true;
        this.setJobTypeOther(value);
        this.setState({ jobTypeOtherChecked: !jobTypeOtherChecked });

        const { setFieldsValue } = this.baseform.current;
        if (value) {
            setFieldsValue({ job_other: true });
            this.setState({ jobOtherChecked: true });
        } else {
            setFieldsValue({ job_other: false });
            this.setState({ jobOtherChecked: false });
        }
    };

    setJobTypeDev = (value) => {
        const { setFieldsValue } = this.baseform.current;
        setFieldsValue({ jobtype_dev_designer: value });
        setFieldsValue({ jobtype_dev_front: value });
        setFieldsValue({ jobtype_dev_server: value });
        setFieldsValue({ jobtype_dev_pm: value });
        setFieldsValue({ jobtype_dev_other: value });
    };

    setJobTypeInfra = (value) => {
        const { setFieldsValue } = this.baseform.current;
        setFieldsValue({ jobtype_infra_server: value });
        setFieldsValue({ jobtype_infra_network: value });
        setFieldsValue({ jobtype_infra_security: value });
        setFieldsValue({ jobtype_infra_database: value });
        setFieldsValue({ jobtype_infra_sys: value });
        setFieldsValue({ jobtype_infra_other: value });
    };

    setJobTypeOther = (value) => {
        const { setFieldsValue } = this.baseform.current;
        setFieldsValue({ jobtype_other_eigyo: value });
        setFieldsValue({ jobtype_other_kichi: value });
        setFieldsValue({ jobtype_other_support: value });
        setFieldsValue({ jobtype_other_other: value });
    };

    onChangeJobSkillDev = () => {
        const { jobSkillDevChecked, jobTypeDevChecked } = this.state;
        let value = jobSkillDevChecked ? undefined : true;
        this.setJobSkillDev(value);
        this.setState({ jobSkillDevChecked: !jobSkillDevChecked });

        const { setFieldsValue } = this.baseform.current;
        if (value) {
            setFieldsValue({ job_dev: true });
            this.setState({ jobDevChecked: true });
        } else if (!jobTypeDevChecked) {
            setFieldsValue({ job_dev: false });
            this.setState({ jobDevChecked: false });
        }
    };

    onChangeJobSkillInfra = () => {
        const { jobSkillInfraChecked, jobTypeInfraChecked } = this.state;
        let value = jobSkillInfraChecked ? undefined : true;
        this.setJobSkillInfra(value);
        this.setState({ jobSkillInfraChecked: !jobSkillInfraChecked });

        const { setFieldsValue } = this.baseform.current;
        if (value) {
            setFieldsValue({ job_infra: true });
            this.setState({ jobInfraChecked: true });
        } else if (!jobTypeInfraChecked) {
            setFieldsValue({ job_infra: false });
            this.setState({ jobInfraChecked: false });
        }
    };

    setJobSkillDev = (value) => {
        const { setFieldsValue } = this.baseform.current;
        setFieldsValue({ jobskill_dev_youken: value });
        setFieldsValue({ jobskill_dev_kihon: value });
        setFieldsValue({ jobskill_dev_syousai: value });
        setFieldsValue({ jobskill_dev_seizou: value });
        setFieldsValue({ jobskill_dev_test: value });
        setFieldsValue({ jobskill_dev_hosyu: value });
        setFieldsValue({ jobskill_dev_beginner: value });
    };

    setJobSkillInfra = (value) => {
        const { setFieldsValue } = this.baseform.current;
        setFieldsValue({ jobskill_infra_youken: value });
        setFieldsValue({ jobskill_infra_kihon: value });
        setFieldsValue({ jobskill_infra_syousai: value });
        setFieldsValue({ jobskill_infra_kouchiku: value });
        setFieldsValue({ jobskill_infra_test: value });
        setFieldsValue({ jobskill_infra_hosyu: value });
        setFieldsValue({ jobskill_infra_kanshi: value });
        setFieldsValue({ jobskill_infra_beginner: value });
    };

    onChangePersonnelSwitch = (ok_action) => {
        const { personnelVisible } = this.state;

        if (personnelVisible) {
            ok_action();
        } else {
            this.setState({ personnelVisible: !personnelVisible });
        }
    };

    resetPersonnelProfile = () => {
        const { jobVisible, personnelVisible } = this.state;
        const { setFieldsValue } = this.baseform.current;
        if (personnelVisible) {
            this.setPersonnelTypeDev(undefined);
            this.setPersonnelTypeInfra(undefined);
            this.setPersonnelTypeOther(undefined);
            this.setPersonnelSkillDev(undefined);
            this.setPersonnelSkillInfra(undefined);
            setFieldsValue({ job_koyou_free: undefined });
            setFieldsValue({ job_koyou_proper: undefined });
            setFieldsValue({ personnel_syouryu: undefined });
            setFieldsValue({ personneltype_select_all: undefined });
            setFieldsValue({ personneltype_dev: undefined });
            setFieldsValue({ personnelskill_select_all: undefined });
            this.setState({
                personnelDevChecked: undefined,
                personnelTypeDevChecked: undefined,
                personnelSkillDevChecked: undefined,
                personnelInfraChecked: undefined,
                personnelTypeInfraChecked: undefined,
                personnelSkillInfraChecked: undefined,
                personnelOtherChecked: undefined,
                personnelTypeOtherChecked: undefined,
                personnelTypeNotSelected: false,
                personnelDevNotSelected: false,
                personnelInfraNotSelected: false,
                koyouNotSelected: false,
                personnelSyouryuNotSelected: false,
                locationNotSelected: false,
            });
            setFieldsValue({ personnel_dev: undefined });
            setFieldsValue({ personneltype_dev: undefined });
            setFieldsValue({ personnelskill_dev: undefined });
            setFieldsValue({ personnel_infra: undefined });
            setFieldsValue({ personneltype_infra: undefined });
            setFieldsValue({ personnelskill_infra: undefined });
            setFieldsValue({ personnel_other: undefined });
            setFieldsValue({ personneltype_other: undefined });
            if (!jobVisible) {
                setFieldsValue({ wants_location_hokkaido_japan: undefined });
                setFieldsValue({ wants_location_touhoku_japan: undefined });
                setFieldsValue({ wants_location_kanto_japan: undefined });
                setFieldsValue({ wants_location_kansai_japan: undefined });
                setFieldsValue({ wants_location_chubu_japan: undefined });
                setFieldsValue({ wants_location_kyushu_japan: undefined });
                setFieldsValue({ wants_location_other_japan: undefined });
                setFieldsValue({ wants_location_chugoku_japan: undefined });
                setFieldsValue({ wants_location_shikoku_japan: undefined });
                setFieldsValue({ wants_location_toukai_japan: undefined });
                this.setState({ locationNotSelected: false });
            }
        }
        this.setState({ personnelVisible: !personnelVisible });
    };

    onChangePersonnelType = () => {
        const { personnelTypeChecked } = this.state;
        const { setFieldsValue } = this.baseform.current;

        let value = personnelTypeChecked ? undefined : true;
        this.setPersonnelTypeDev(value);
        this.setPersonnelTypeInfra(value);
        this.setPersonnelTypeOther(value);
        setFieldsValue({ personneltype_dev: value });
        setFieldsValue({ personneltype_infra: value });
        setFieldsValue({ personneltype_other: value });
        this.setState({
            personnelTypeChecked: !personnelTypeChecked,
            personnelTypeDevChecked: !personnelTypeChecked,
            personnelTypeInfraChecked: !personnelTypeChecked,
            personnelTypeOtherChecked: !personnelTypeChecked,
        });
    };

    onChangePersonnelTypeDev = () => {
        const { personnelTypeDevChecked, personnelSkillDevChecked } =
            this.state;
        let value = personnelTypeDevChecked ? undefined : true;
        this.setPersonnelTypeDev(value);
        this.setState({ personnelTypeDevChecked: !personnelTypeDevChecked });

        const { setFieldsValue } = this.baseform.current;
        if (value) {
            setFieldsValue({ personnel_dev: true });
            this.setState({ personnelDevChecked: true });
        } else if (!personnelSkillDevChecked) {
            setFieldsValue({ personnel_dev: false });
            this.setState({ personnelDevChecked: false });
        }
    };

    onChangePersonnelTypeInfra = () => {
        const { personnelTypeInfraChecked, personnelSkillInfraChecked } =
            this.state;
        let value = personnelTypeInfraChecked ? undefined : true;
        this.setPersonnelTypeInfra(value);
        this.setState({
            personnelTypeInfraChecked: !personnelTypeInfraChecked,
        });

        const { setFieldsValue } = this.baseform.current;
        if (value) {
            setFieldsValue({ personnel_infra: true });
            this.setState({ personnelInfraChecked: true });
        } else if (!personnelSkillInfraChecked) {
            setFieldsValue({ personnel_infra: false });
            this.setState({ personnelInfraChecked: false });
        }
    };

    onChangePersonnelTypeOther = () => {
        const { personnelTypeOtherChecked } = this.state;
        let value = personnelTypeOtherChecked ? undefined : true;
        this.setPersonnelTypeOther(value);
        this.setState({
            personnelTypeOtherChecked: !personnelTypeOtherChecked,
        });

        const { setFieldsValue } = this.baseform.current;
        if (value) {
            setFieldsValue({ personnel_other: true });
            this.setState({ personnelOtherChecked: true });
        } else {
            setFieldsValue({ personnel_other: false });
            this.setState({ personnelOtherChecked: false });
        }
    };

    setPersonnelTypeDev = (value) => {
        const { setFieldsValue } = this.baseform.current;
        setFieldsValue({ personneltype_dev_designer: value });
        setFieldsValue({ personneltype_dev_front: value });
        setFieldsValue({ personneltype_dev_server: value });
        setFieldsValue({ personneltype_dev_pm: value });
        setFieldsValue({ personneltype_dev_other: value });
    };

    setPersonnelTypeInfra = (value) => {
        const { setFieldsValue } = this.baseform.current;
        setFieldsValue({ personneltype_infra_server: value });
        setFieldsValue({ personneltype_infra_network: value });
        setFieldsValue({ personneltype_infra_security: value });
        setFieldsValue({ personneltype_infra_database: value });
        setFieldsValue({ personneltype_infra_sys: value });
        setFieldsValue({ personneltype_infra_other: value });
    };

    setPersonnelTypeOther = (value) => {
        const { setFieldsValue } = this.baseform.current;
        setFieldsValue({ personneltype_other_eigyo: value });
        setFieldsValue({ personneltype_other_kichi: value });
        setFieldsValue({ personneltype_other_support: value });
        setFieldsValue({ personneltype_other_other: value });
    };

    onChangePersonnelSkillDev = () => {
        const { personnelSkillDevChecked, personnelTypeDevChecked } =
            this.state;
        let value = personnelSkillDevChecked ? undefined : true;
        this.setPersonnelSkillDev(value);
        this.setState({ personnelSkillDevChecked: !personnelSkillDevChecked });

        const { setFieldsValue } = this.baseform.current;
        if (value) {
            setFieldsValue({ personnel_dev: true });
            this.setState({ personnelDevChecked: true });
        } else if (!personnelTypeDevChecked) {
            setFieldsValue({ personnel_dev: false });
            this.setState({ personnelDevChecked: false });
        }
    };

    onChangePersonnelSkillInfra = () => {
        const { personnelSkillInfraChecked, personnelTypeInfraChecked } =
            this.state;
        let value = personnelSkillInfraChecked ? undefined : true;
        this.setPersonnelSkillInfra(value);
        this.setState({
            personnelSkillInfraChecked: !personnelSkillInfraChecked,
        });

        const { setFieldsValue } = this.baseform.current;
        if (value) {
            setFieldsValue({ personnel_infra: true });
            this.setState({ personnelInfraChecked: true });
        } else if (!personnelTypeInfraChecked) {
            setFieldsValue({ personnel_infra: false });
            this.setState({ personnelInfraChecked: false });
        }
    };

    setPersonnelSkillDev = (value) => {
        const { setFieldsValue } = this.baseform.current;
        setFieldsValue({ personnelskill_dev_youken: value });
        setFieldsValue({ personnelskill_dev_kihon: value });
        setFieldsValue({ personnelskill_dev_syousai: value });
        setFieldsValue({ personnelskill_dev_seizou: value });
        setFieldsValue({ personnelskill_dev_test: value });
        setFieldsValue({ personnelskill_dev_hosyu: value });
        setFieldsValue({ personnelskill_dev_beginner: value });
    };

    setPersonnelSkillInfra = (value) => {
        const { setFieldsValue } = this.baseform.current;
        setFieldsValue({ personnelskill_infra_youken: value });
        setFieldsValue({ personnelskill_infra_kihon: value });
        setFieldsValue({ personnelskill_infra_syousai: value });
        setFieldsValue({ personnelskill_infra_kouchiku: value });
        setFieldsValue({ personnelskill_infra_test: value });
        setFieldsValue({ personnelskill_infra_hosyu: value });
        setFieldsValue({ personnelskill_infra_kanshi: value });
        setFieldsValue({ personnelskill_infra_beginner: value });
    };

    toggle = () => {
        const { email_expand } = this.state;
        this.setState({ email_expand: !email_expand });
    };

    onChangeJobTypeDevItem = (e) => {
        const { getFieldValue, setFieldsValue } = this.baseform.current;
        let jobtype_dev_designer =
            e.target.id === "jobtype_dev_designer"
                ? e.target.checked
                : getFieldValue("jobtype_dev_designer");
        let jobtype_dev_front =
            e.target.id === "jobtype_dev_front"
                ? e.target.checked
                : getFieldValue("jobtype_dev_front");
        let jobtype_dev_server =
            e.target.id === "jobtype_dev_server"
                ? e.target.checked
                : getFieldValue("jobtype_dev_server");
        let jobtype_dev_pm =
            e.target.id === "jobtype_dev_pm"
                ? e.target.checked
                : getFieldValue("jobtype_dev_pm");
        let jobtype_dev_other =
            e.target.id === "jobtype_dev_other"
                ? e.target.checked
                : getFieldValue("jobtype_dev_other");
        let target_checked_array = [
            jobtype_dev_designer,
            jobtype_dev_front,
            jobtype_dev_server,
            jobtype_dev_pm,
            jobtype_dev_other,
        ];

        if (target_checked_array.includes(true)) {
            setFieldsValue({ jobtype_dev: true });
            this.setState({ jobTypeDevChecked: true });
            setFieldsValue({ job_dev: true });
            this.setState({ jobDevChecked: true });
        } else {
            setFieldsValue({ jobtype_dev: false });
            this.setState({ jobTypeDevChecked: false });

            let jobskill_dev_youken =
                e.target.id === "jobskill_dev_youken"
                    ? e.target.checked
                    : getFieldValue("jobskill_dev_youken");
            let jobskill_dev_kihon =
                e.target.id === "jobskill_dev_kihon"
                    ? e.target.checked
                    : getFieldValue("jobskill_dev_kihon");
            let jobskill_dev_syousai =
                e.target.id === "jobskill_dev_syousai"
                    ? e.target.checked
                    : getFieldValue("jobskill_dev_syousai");
            let jobskill_dev_seizou =
                e.target.id === "jobskill_dev_seizou"
                    ? e.target.checked
                    : getFieldValue("jobskill_dev_seizou");
            let jobskill_dev_test =
                e.target.id === "jobskill_dev_test"
                    ? e.target.checked
                    : getFieldValue("jobskill_dev_test");
            let jobskill_dev_hosyu =
                e.target.id === "jobskill_dev_hosyu"
                    ? e.target.checked
                    : getFieldValue("jobskill_dev_hosyu");
            let jobskill_dev_beginner =
                e.target.id === "jobskill_dev_beginner"
                    ? e.target.checked
                    : getFieldValue("jobskill_dev_beginner");
            let target_checked_array = [
                jobskill_dev_youken,
                jobskill_dev_kihon,
                jobskill_dev_syousai,
                jobskill_dev_seizou,
                jobskill_dev_test,
                jobskill_dev_hosyu,
                jobskill_dev_beginner,
            ];
            if (!target_checked_array.includes(true)) {
                setFieldsValue({ job_dev: false });
                this.setState({ jobDevChecked: false });
            }
        }
    };

    onChangeJobTypeInfraItem = (e) => {
        const { getFieldValue, setFieldsValue } = this.baseform.current;
        let jobtype_infra_server =
            e.target.id === "jobtype_infra_server"
                ? e.target.checked
                : getFieldValue("jobtype_infra_server");
        let jobtype_infra_network =
            e.target.id === "jobtype_infra_network"
                ? e.target.checked
                : getFieldValue("jobtype_infra_network");
        let jobtype_infra_database =
            e.target.id === "jobtype_infra_database"
                ? e.target.checked
                : getFieldValue("jobtype_infra_database");
        let jobtype_infra_security =
            e.target.id === "jobtype_infra_security"
                ? e.target.checked
                : getFieldValue("jobtype_infra_security");
        let jobtype_infra_sys =
            e.target.id === "jobtype_infra_sys"
                ? e.target.checked
                : getFieldValue("jobtype_infra_sys");
        let jobtype_infra_other =
            e.target.id === "jobtype_infra_other"
                ? e.target.checked
                : getFieldValue("jobtype_infra_other");
        let target_checked_array = [
            jobtype_infra_server,
            jobtype_infra_network,
            jobtype_infra_database,
            jobtype_infra_security,
            jobtype_infra_sys,
            jobtype_infra_other,
        ];

        if (target_checked_array.includes(true)) {
            setFieldsValue({ jobtype_infra: true });
            this.setState({ jobTypeInfraChecked: true });
            setFieldsValue({ job_infra: true });
            this.setState({ jobInfraChecked: true });
        } else {
            setFieldsValue({ jobtype_infra: false });
            this.setState({ jobTypeInfraChecked: false });

            let jobskill_infra_youken =
                e.target.id === "jobskill_infra_youken"
                    ? e.target.checked
                    : getFieldValue("jobskill_infra_youken");
            let jobskill_infra_kihon =
                e.target.id === "jobskill_infra_kihon"
                    ? e.target.checked
                    : getFieldValue("jobskill_infra_kihon");
            let jobskill_infra_syousai =
                e.target.id === "jobskill_infra_syousai"
                    ? e.target.checked
                    : getFieldValue("jobskill_infra_syousai");
            let jobskill_infra_kouchiku =
                e.target.id === "jobskill_infra_kouchiku"
                    ? e.target.checked
                    : getFieldValue("jobskill_infra_kouchiku");
            let jobskill_infra_test =
                e.target.id === "jobskill_infra_test"
                    ? e.target.checked
                    : getFieldValue("jobskill_infra_test");
            let jobskill_infra_hosyu =
                e.target.id === "jobskill_infra_hosyu"
                    ? e.target.checked
                    : getFieldValue("jobskill_infra_hosyu");
            let jobskill_infra_kanshi =
                e.target.id === "jobskill_infra_kanshi"
                    ? e.target.checked
                    : getFieldValue("jobskill_infra_kanshi");
            let jobskill_infra_beginner =
                e.target.id === "jobskill_infra_beginner"
                    ? e.target.checked
                    : getFieldValue("jobskill_infra_beginner");
            let target_checked_array = [
                jobskill_infra_youken,
                jobskill_infra_kihon,
                jobskill_infra_syousai,
                jobskill_infra_kouchiku,
                jobskill_infra_test,
                jobskill_infra_hosyu,
                jobskill_infra_kanshi,
                jobskill_infra_beginner,
            ];
            if (!target_checked_array.includes(true)) {
                setFieldsValue({ job_infra: false });
                this.setState({ jobInfraChecked: false });
            }
        }
    };

    onChangeJobTypeOtherItem = (e) => {
        const { getFieldValue, setFieldsValue } = this.baseform.current;
        let jobtype_other_eigyo =
            e.target.id === "jobtype_other_eigyo"
                ? e.target.checked
                : getFieldValue("jobtype_other_eigyo");
        let jobtype_other_kichi =
            e.target.id === "jobtype_other_kichi"
                ? e.target.checked
                : getFieldValue("jobtype_other_kichi");
        let jobtype_other_support =
            e.target.id === "jobtype_other_support"
                ? e.target.checked
                : getFieldValue("jobtype_other_support");
        let jobtype_other_other =
            e.target.id === "jobtype_other_other"
                ? e.target.checked
                : getFieldValue("jobtype_other_other");
        let target_checked_array = [
            jobtype_other_eigyo,
            jobtype_other_kichi,
            jobtype_other_support,
            jobtype_other_other,
        ];

        if (target_checked_array.includes(true)) {
            setFieldsValue({ jobtype_other: true });
            this.setState({ jobTypeOtherChecked: true });
            setFieldsValue({ job_other: true });
            this.setState({ jobOtherChecked: true });
        } else {
            setFieldsValue({ jobtype_other: false });
            this.setState({ jobTypeOtherChecked: false });
            setFieldsValue({ job_other: false });
            this.setState({ jobOtherChecked: false });
        }
    };

    onChangeJobSkillDevItem = (e) => {
        const { getFieldValue, setFieldsValue } = this.baseform.current;
        let jobskill_dev_youken =
            e.target.id === "jobskill_dev_youken"
                ? e.target.checked
                : getFieldValue("jobskill_dev_youken");
        let jobskill_dev_kihon =
            e.target.id === "jobskill_dev_kihon"
                ? e.target.checked
                : getFieldValue("jobskill_dev_kihon");
        let jobskill_dev_syousai =
            e.target.id === "jobskill_dev_syousai"
                ? e.target.checked
                : getFieldValue("jobskill_dev_syousai");
        let jobskill_dev_seizou =
            e.target.id === "jobskill_dev_seizou"
                ? e.target.checked
                : getFieldValue("jobskill_dev_seizou");
        let jobskill_dev_test =
            e.target.id === "jobskill_dev_test"
                ? e.target.checked
                : getFieldValue("jobskill_dev_test");
        let jobskill_dev_hosyu =
            e.target.id === "jobskill_dev_hosyu"
                ? e.target.checked
                : getFieldValue("jobskill_dev_hosyu");
        let jobskill_dev_beginner =
            e.target.id === "jobskill_dev_beginner"
                ? e.target.checked
                : getFieldValue("jobskill_dev_beginner");
        let target_checked_array = [
            jobskill_dev_youken,
            jobskill_dev_kihon,
            jobskill_dev_syousai,
            jobskill_dev_seizou,
            jobskill_dev_test,
            jobskill_dev_hosyu,
            jobskill_dev_beginner,
        ];

        if (target_checked_array.includes(true)) {
            setFieldsValue({ jobskill_dev: true });
            this.setState({ jobSkillDevChecked: true });
            setFieldsValue({ job_dev: true });
            this.setState({ jobDevChecked: true });
        } else {
            setFieldsValue({ jobskill_dev: false });
            this.setState({ jobSkillDevChecked: false });
            let jobtype_dev_designer =
                e.target.id === "jobtype_dev_designer"
                    ? e.target.checked
                    : getFieldValue("jobtype_dev_designer");
            let jobtype_dev_front =
                e.target.id === "jobtype_dev_front"
                    ? e.target.checked
                    : getFieldValue("jobtype_dev_front");
            let jobtype_dev_server =
                e.target.id === "jobtype_dev_server"
                    ? e.target.checked
                    : getFieldValue("jobtype_dev_server");
            let jobtype_dev_pm =
                e.target.id === "jobtype_dev_pm"
                    ? e.target.checked
                    : getFieldValue("jobtype_dev_pm");
            let jobtype_dev_other =
                e.target.id === "jobtype_dev_other"
                    ? e.target.checked
                    : getFieldValue("jobtype_dev_other");
            let target_checked_array = [
                jobtype_dev_designer,
                jobtype_dev_front,
                jobtype_dev_server,
                jobtype_dev_pm,
                jobtype_dev_other,
            ];
            if (!target_checked_array.includes(true)) {
                setFieldsValue({ job_dev: false });
                this.setState({ jobDevChecked: false });
            }
        }
    };

    onChangeJobSkillInfraItem = (e) => {
        const { getFieldValue, setFieldsValue } = this.baseform.current;
        let jobskill_infra_youken =
            e.target.id === "jobskill_infra_youken"
                ? e.target.checked
                : getFieldValue("jobskill_infra_youken");
        let jobskill_infra_kihon =
            e.target.id === "jobskill_infra_kihon"
                ? e.target.checked
                : getFieldValue("jobskill_infra_kihon");
        let jobskill_infra_syousai =
            e.target.id === "jobskill_infra_syousai"
                ? e.target.checked
                : getFieldValue("jobskill_infra_syousai");
        let jobskill_infra_kouchiku =
            e.target.id === "jobskill_infra_kouchiku"
                ? e.target.checked
                : getFieldValue("jobskill_infra_kouchiku");
        let jobskill_infra_test =
            e.target.id === "jobskill_infra_test"
                ? e.target.checked
                : getFieldValue("jobskill_infra_test");
        let jobskill_infra_hosyu =
            e.target.id === "jobskill_infra_hosyu"
                ? e.target.checked
                : getFieldValue("jobskill_infra_hosyu");
        let jobskill_infra_kanshi =
            e.target.id === "jobskill_infra_kanshi"
                ? e.target.checked
                : getFieldValue("jobskill_infra_kanshi");
        let jobskill_infra_beginner =
            e.target.id === "jobskill_infra_beginner"
                ? e.target.checked
                : getFieldValue("jobskill_infra_beginner");
        let target_checked_array = [
            jobskill_infra_youken,
            jobskill_infra_kihon,
            jobskill_infra_syousai,
            jobskill_infra_kouchiku,
            jobskill_infra_test,
            jobskill_infra_hosyu,
            jobskill_infra_kanshi,
            jobskill_infra_beginner,
        ];

        if (target_checked_array.includes(true)) {
            setFieldsValue({ jobskill_infra: true });
            this.setState({ jobSkillInfraChecked: true });
            setFieldsValue({ job_infra: true });
            this.setState({ jobInfraChecked: true });
        } else {
            setFieldsValue({ jobskill_infra: false });
            this.setState({ jobSkillInfraChecked: false });
            let jobtype_infra_server =
                e.target.id === "jobtype_infra_server"
                    ? e.target.checked
                    : getFieldValue("jobtype_infra_server");
            let jobtype_infra_network =
                e.target.id === "jobtype_infra_network"
                    ? e.target.checked
                    : getFieldValue("jobtype_infra_network");
            let jobtype_infra_database =
                e.target.id === "jobtype_infra_database"
                    ? e.target.checked
                    : getFieldValue("jobtype_infra_database");
            let jobtype_infra_security =
                e.target.id === "jobtype_infra_security"
                    ? e.target.checked
                    : getFieldValue("jobtype_infra_security");
            let jobtype_infra_sys =
                e.target.id === "jobtype_infra_sys"
                    ? e.target.checked
                    : getFieldValue("jobtype_infra_sys");
            let jobtype_infra_other =
                e.target.id === "jobtype_infra_other"
                    ? e.target.checked
                    : getFieldValue("jobtype_infra_other");
            let target_checked_array = [
                jobtype_infra_server,
                jobtype_infra_network,
                jobtype_infra_database,
                jobtype_infra_security,
                jobtype_infra_sys,
                jobtype_infra_other,
            ];
            if (!target_checked_array.includes(true)) {
                setFieldsValue({ job_infra: false });
                this.setState({ jobInfraChecked: false });
            }
        }
    };

    onChangePersonnelTypeDevItem = (e) => {
        const { getFieldValue, setFieldsValue } = this.baseform.current;
        let personneltype_dev_designer =
            e.target.id === "personneltype_dev_designer"
                ? e.target.checked
                : getFieldValue("personneltype_dev_designer");
        let personneltype_dev_front =
            e.target.id === "personneltype_dev_front"
                ? e.target.checked
                : getFieldValue("personneltype_dev_front");
        let personneltype_dev_server =
            e.target.id === "personneltype_dev_server"
                ? e.target.checked
                : getFieldValue("personneltype_dev_server");
        let personneltype_dev_pm =
            e.target.id === "personneltype_dev_pm"
                ? e.target.checked
                : getFieldValue("personneltype_dev_pm");
        let personneltype_dev_other =
            e.target.id === "personneltype_dev_other"
                ? e.target.checked
                : getFieldValue("personneltype_dev_other");
        let target_checked_array = [
            personneltype_dev_designer,
            personneltype_dev_front,
            personneltype_dev_server,
            personneltype_dev_pm,
            personneltype_dev_other,
        ];

        if (target_checked_array.includes(true)) {
            setFieldsValue({ personneltype_dev: true });
            this.setState({ personnelTypeDevChecked: true });
            setFieldsValue({ personnel_dev: true });
            this.setState({ personnelDevChecked: true });
        } else {
            setFieldsValue({ personneltype_dev: false });
            this.setState({ personnelTypeDevChecked: false });
            let personnelskill_dev_youken =
                e.target.id === "personnelskill_dev_youken"
                    ? e.target.checked
                    : getFieldValue("personnelskill_dev_youken");
            let personnelskill_dev_kihon =
                e.target.id === "personnelskill_dev_kihon"
                    ? e.target.checked
                    : getFieldValue("personnelskill_dev_kihon");
            let personnelskill_dev_syousai =
                e.target.id === "personnelskill_dev_syousai"
                    ? e.target.checked
                    : getFieldValue("personnelskill_dev_syousai");
            let personnelskill_dev_seizou =
                e.target.id === "personnelskill_dev_seizou"
                    ? e.target.checked
                    : getFieldValue("personnelskill_dev_seizou");
            let personnelskill_dev_test =
                e.target.id === "personnelskill_dev_test"
                    ? e.target.checked
                    : getFieldValue("personnelskill_dev_test");
            let personnelskill_dev_hosyu =
                e.target.id === "personnelskill_dev_hosyu"
                    ? e.target.checked
                    : getFieldValue("personnelskill_dev_hosyu");
            let personnelskill_dev_beginner =
                e.target.id === "personnelskill_dev_beginner"
                    ? e.target.checked
                    : getFieldValue("personnelskill_dev_beginner");
            let target_checked_array = [
                personnelskill_dev_youken,
                personnelskill_dev_kihon,
                personnelskill_dev_syousai,
                personnelskill_dev_seizou,
                personnelskill_dev_test,
                personnelskill_dev_hosyu,
                personnelskill_dev_beginner,
            ];
            if (!target_checked_array.includes(true)) {
                setFieldsValue({ personnel_dev: false });
                this.setState({ personnelDevChecked: false });
            }
        }
    };

    onChangePersonnelTypeInfraItem = (e) => {
        const { getFieldValue, setFieldsValue } = this.baseform.current;
        let personneltype_infra_server =
            e.target.id === "personneltype_infra_server"
                ? e.target.checked
                : getFieldValue("personneltype_infra_server");
        let personneltype_infra_network =
            e.target.id === "personneltype_infra_network"
                ? e.target.checked
                : getFieldValue("personneltype_infra_network");
        let personneltype_infra_database =
            e.target.id === "personneltype_infra_database"
                ? e.target.checked
                : getFieldValue("personneltype_infra_database");
        let personneltype_infra_security =
            e.target.id === "personneltype_infra_security"
                ? e.target.checked
                : getFieldValue("personneltype_infra_security");
        let personneltype_infra_sys =
            e.target.id === "personneltype_infra_sys"
                ? e.target.checked
                : getFieldValue("personneltype_infra_sys");
        let personneltype_infra_other =
            e.target.id === "personneltype_infra_other"
                ? e.target.checked
                : getFieldValue("personneltype_infra_other");
        let target_checked_array = [
            personneltype_infra_server,
            personneltype_infra_network,
            personneltype_infra_database,
            personneltype_infra_security,
            personneltype_infra_sys,
            personneltype_infra_other,
        ];

        if (target_checked_array.includes(true)) {
            setFieldsValue({ personneltype_infra: true });
            this.setState({ personnelTypeInfraChecked: true });
            setFieldsValue({ personnel_infra: true });
            this.setState({ personnelInfraChecked: true });
        } else {
            setFieldsValue({ personneltype_infra: false });
            this.setState({ personnelTypeInfraChecked: false });
            let personnelskill_infra_youken =
                e.target.id === "personnelskill_infra_youken"
                    ? e.target.checked
                    : getFieldValue("personnelskill_infra_youken");
            let personnelskill_infra_kihon =
                e.target.id === "personnelskill_infra_kihon"
                    ? e.target.checked
                    : getFieldValue("personnelskill_infra_kihon");
            let personnelskill_infra_syousai =
                e.target.id === "personnelskill_infra_syousai"
                    ? e.target.checked
                    : getFieldValue("personnelskill_infra_syousai");
            let personnelskill_infra_kouchiku =
                e.target.id === "personnelskill_infra_kouchiku"
                    ? e.target.checked
                    : getFieldValue("personnelskill_infra_kouchiku");
            let personnelskill_infra_test =
                e.target.id === "personnelskill_infra_test"
                    ? e.target.checked
                    : getFieldValue("personnelskill_infra_test");
            let personnelskill_infra_hosyu =
                e.target.id === "personnelskill_infra_hosyu"
                    ? e.target.checked
                    : getFieldValue("personnelskill_infra_hosyu");
            let personnelskill_infra_kanshi =
                e.target.id === "personnelskill_infra_kanshi"
                    ? e.target.checked
                    : getFieldValue("personnelskill_infra_kanshi");
            let personnelskill_infra_beginner =
                e.target.id === "personnelskill_infra_beginner"
                    ? e.target.checked
                    : getFieldValue("personnelskill_infra_beginner");
            let target_checked_array = [
                personnelskill_infra_youken,
                personnelskill_infra_kihon,
                personnelskill_infra_syousai,
                personnelskill_infra_kouchiku,
                personnelskill_infra_test,
                personnelskill_infra_hosyu,
                personnelskill_infra_kanshi,
                personnelskill_infra_beginner,
            ];
            if (!target_checked_array.includes(true)) {
                setFieldsValue({ personnel_infra: false });
                this.setState({ personnelInfraChecked: false });
            }
        }
    };

    onChangePersonnelTypeOtherItem = (e) => {
        const { getFieldValue, setFieldsValue } = this.baseform.current;
        let personneltype_other_eigyo =
            e.target.id === "personneltype_other_eigyo"
                ? e.target.checked
                : getFieldValue("personneltype_other_eigyo");
        let personneltype_other_kichi =
            e.target.id === "personneltype_other_kichi"
                ? e.target.checked
                : getFieldValue("personneltype_other_kichi");
        let personneltype_other_support =
            e.target.id === "personneltype_other_support"
                ? e.target.checked
                : getFieldValue("personneltype_other_support");
        let personneltype_other_other =
            e.target.id === "personneltype_other_other"
                ? e.target.checked
                : getFieldValue("personneltype_other_other");
        let target_checked_array = [
            personneltype_other_eigyo,
            personneltype_other_kichi,
            personneltype_other_support,
            personneltype_other_other,
        ];

        if (target_checked_array.includes(true)) {
            setFieldsValue({ personneltype_other: true });
            this.setState({ personnelTypeOtherChecked: true });
            setFieldsValue({ personnel_other: true });
            this.setState({ personnelOtherChecked: true });
        } else {
            setFieldsValue({ personneltype_other: false });
            this.setState({ personnelTypeOtherChecked: false });
            setFieldsValue({ personnel_other: false });
            this.setState({ personnelOtherChecked: false });
        }
    };

    onChangePersonnelSkillDevItem = (e) => {
        const { getFieldValue, setFieldsValue } = this.baseform.current;
        let personnelskill_dev_youken =
            e.target.id === "personnelskill_dev_youken"
                ? e.target.checked
                : getFieldValue("personnelskill_dev_youken");
        let personnelskill_dev_kihon =
            e.target.id === "personnelskill_dev_kihon"
                ? e.target.checked
                : getFieldValue("personnelskill_dev_kihon");
        let personnelskill_dev_syousai =
            e.target.id === "personnelskill_dev_syousai"
                ? e.target.checked
                : getFieldValue("personnelskill_dev_syousai");
        let personnelskill_dev_seizou =
            e.target.id === "personnelskill_dev_seizou"
                ? e.target.checked
                : getFieldValue("personnelskill_dev_seizou");
        let personnelskill_dev_test =
            e.target.id === "personnelskill_dev_test"
                ? e.target.checked
                : getFieldValue("personnelskill_dev_test");
        let personnelskill_dev_hosyu =
            e.target.id === "personnelskill_dev_hosyu"
                ? e.target.checked
                : getFieldValue("personnelskill_dev_hosyu");
        let personnelskill_dev_beginner =
            e.target.id === "personnelskill_dev_beginner"
                ? e.target.checked
                : getFieldValue("personnelskill_dev_beginner");
        let target_checked_array = [
            personnelskill_dev_youken,
            personnelskill_dev_kihon,
            personnelskill_dev_syousai,
            personnelskill_dev_seizou,
            personnelskill_dev_test,
            personnelskill_dev_hosyu,
            personnelskill_dev_beginner,
        ];

        if (target_checked_array.includes(true)) {
            setFieldsValue({ personnelskill_dev: true });
            this.setState({ personnelSkillDevChecked: true });
            setFieldsValue({ personnel_dev: true });
            this.setState({ personnelDevChecked: true });
        } else {
            setFieldsValue({ personnelskill_dev: false });
            this.setState({ personnelSkillDevChecked: false });
            let personneltype_dev_designer =
                e.target.id === "personneltype_dev_designer"
                    ? e.target.checked
                    : getFieldValue("personneltype_dev_designer");
            let personneltype_dev_front =
                e.target.id === "personneltype_dev_front"
                    ? e.target.checked
                    : getFieldValue("personneltype_dev_front");
            let personneltype_dev_server =
                e.target.id === "personneltype_dev_server"
                    ? e.target.checked
                    : getFieldValue("personneltype_dev_server");
            let personneltype_dev_pm =
                e.target.id === "personneltype_dev_pm"
                    ? e.target.checked
                    : getFieldValue("personneltype_dev_pm");
            let personneltype_dev_other =
                e.target.id === "personneltype_dev_other"
                    ? e.target.checked
                    : getFieldValue("personneltype_dev_other");
            let target_checked_array = [
                personneltype_dev_designer,
                personneltype_dev_front,
                personneltype_dev_server,
                personneltype_dev_pm,
                personneltype_dev_other,
            ];
            if (!target_checked_array.includes(true)) {
                setFieldsValue({ personnel_dev: false });
                this.setState({ personnelDevChecked: false });
            }
        }
    };

    onChangePersonnelSkillInfraItem = (e) => {
        const { getFieldValue, setFieldsValue } = this.baseform.current;
        let personnelskill_infra_youken =
            e.target.id === "personnelskill_infra_youken"
                ? e.target.checked
                : getFieldValue("personnelskill_infra_youken");
        let personnelskill_infra_kihon =
            e.target.id === "personnelskill_infra_kihon"
                ? e.target.checked
                : getFieldValue("personnelskill_infra_kihon");
        let personnelskill_infra_syousai =
            e.target.id === "personnelskill_infra_syousai"
                ? e.target.checked
                : getFieldValue("personnelskill_infra_syousai");
        let personnelskill_infra_kouchiku =
            e.target.id === "personnelskill_infra_kouchiku"
                ? e.target.checked
                : getFieldValue("personnelskill_infra_kouchiku");
        let personnelskill_infra_test =
            e.target.id === "personnelskill_infra_test"
                ? e.target.checked
                : getFieldValue("personnelskill_infra_test");
        let personnelskill_infra_hosyu =
            e.target.id === "personnelskill_infra_hosyu"
                ? e.target.checked
                : getFieldValue("personnelskill_infra_hosyu");
        let personnelskill_infra_kanshi =
            e.target.id === "personnelskill_infra_kanshi"
                ? e.target.checked
                : getFieldValue("personnelskill_infra_kanshi");
        let personnelskill_infra_beginner =
            e.target.id === "personnelskill_infra_beginner"
                ? e.target.checked
                : getFieldValue("personnelskill_infra_beginner");
        let target_checked_array = [
            personnelskill_infra_youken,
            personnelskill_infra_kihon,
            personnelskill_infra_syousai,
            personnelskill_infra_kouchiku,
            personnelskill_infra_test,
            personnelskill_infra_hosyu,
            personnelskill_infra_kanshi,
            personnelskill_infra_beginner,
        ];

        if (target_checked_array.includes(true)) {
            setFieldsValue({ personnelskill_infra: true });
            this.setState({ personnelSkillInfraChecked: true });
            setFieldsValue({ personnel_infra: true });
            this.setState({ personnelInfraChecked: true });
        } else {
            setFieldsValue({ personnelskill_infra: false });
            this.setState({ personnelSkillInfraChecked: false });
            let personneltype_infra_server =
                e.target.id === "personneltype_infra_server"
                    ? e.target.checked
                    : getFieldValue("personneltype_infra_server");
            let personneltype_infra_network =
                e.target.id === "personneltype_infra_network"
                    ? e.target.checked
                    : getFieldValue("personneltype_infra_network");
            let personneltype_infra_database =
                e.target.id === "personneltype_infra_database"
                    ? e.target.checked
                    : getFieldValue("personneltype_infra_database");
            let personneltype_infra_security =
                e.target.id === "personneltype_infra_security"
                    ? e.target.checked
                    : getFieldValue("personneltype_infra_security");
            let personneltype_infra_sys =
                e.target.id === "personneltype_infra_sys"
                    ? e.target.checked
                    : getFieldValue("personneltype_infra_sys");
            let personneltype_infra_other =
                e.target.id === "personneltype_infra_other"
                    ? e.target.checked
                    : getFieldValue("personneltype_infra_other");
            let target_checked_array = [
                personneltype_infra_server,
                personneltype_infra_network,
                personneltype_infra_database,
                personneltype_infra_security,
                personneltype_infra_sys,
                personneltype_infra_other,
            ];
            if (!target_checked_array.includes(true)) {
                setFieldsValue({ personnel_infra: false });
                this.setState({ personnelInfraChecked: false });
            }
        }
    };

    checkJobDevSelected = () => {
        const { getFieldValue } = this.baseform.current;
        const { jobVisible } = this.state;

        let type_selected = true;
        if (
            !getFieldValue("jobtype_dev_designer") &&
            !getFieldValue("jobtype_dev_front") &&
            !getFieldValue("jobtype_dev_server") &&
            !getFieldValue("jobtype_dev_pm") &&
            !getFieldValue("jobtype_dev_other")
        ) {
            type_selected = false;
        }

        let skill_selected = true;
        if (
            !getFieldValue("jobskill_dev_youken") &&
            !getFieldValue("jobskill_dev_kihon") &&
            !getFieldValue("jobskill_dev_syousai") &&
            !getFieldValue("jobskill_dev_seizou") &&
            !getFieldValue("jobskill_dev_test") &&
            !getFieldValue("jobskill_dev_hosyu") &&
            !getFieldValue("jobskill_dev_beginner")
        ) {
            skill_selected = false;
        }

        if (
            jobVisible &&
            (type_selected || skill_selected) &&
            type_selected !== skill_selected
        ) {
            this.setState({ jobDevNotSelected: true });
            return false;
        } else {
            this.setState({ jobDevNotSelected: false });
            return true;
        }
    };

    checkJobInfraSelected = () => {
        const { getFieldValue } = this.baseform.current;
        const { jobVisible } = this.state;

        let type_selected = true;
        if (
            !getFieldValue("jobtype_infra_server") &&
            !getFieldValue("jobtype_infra_network") &&
            !getFieldValue("jobtype_infra_database") &&
            !getFieldValue("jobtype_infra_security") &&
            !getFieldValue("jobtype_infra_sys") &&
            !getFieldValue("jobtype_infra_other")
        ) {
            type_selected = false;
        }

        let skill_selected = true;
        if (
            !getFieldValue("jobskill_infra_youken") &&
            !getFieldValue("jobskill_infra_kihon") &&
            !getFieldValue("jobskill_infra_syousai") &&
            !getFieldValue("jobskill_infra_kouchiku") &&
            !getFieldValue("jobskill_infra_test") &&
            !getFieldValue("jobskill_infra_hosyu") &&
            !getFieldValue("jobskill_infra_kanshi") &&
            !getFieldValue("jobskill_infra_beginner")
        ) {
            skill_selected = false;
        }

        if (
            jobVisible &&
            (type_selected || skill_selected) &&
            type_selected !== skill_selected
        ) {
            this.setState({ jobInfraNotSelected: true });
            return false;
        } else {
            this.setState({ jobInfraNotSelected: false });
            return true;
        }
    };

    checkPersonnelDevSelected = () => {
        const { getFieldValue } = this.baseform.current;
        const { personnelVisible } = this.state;

        let type_selected = true;
        if (
            !getFieldValue("personneltype_dev_designer") &&
            !getFieldValue("personneltype_dev_front") &&
            !getFieldValue("personneltype_dev_server") &&
            !getFieldValue("personneltype_dev_pm") &&
            !getFieldValue("personneltype_dev_other")
        ) {
            type_selected = false;
        }

        let skill_selected = true;
        if (
            !getFieldValue("personnelskill_dev_youken") &&
            !getFieldValue("personnelskill_dev_kihon") &&
            !getFieldValue("personnelskill_dev_syousai") &&
            !getFieldValue("personnelskill_dev_seizou") &&
            !getFieldValue("personnelskill_dev_test") &&
            !getFieldValue("personnelskill_dev_hosyu") &&
            !getFieldValue("personnelskill_dev_beginner")
        ) {
            skill_selected = false;
        }

        if (
            personnelVisible &&
            (type_selected || skill_selected) &&
            type_selected !== skill_selected
        ) {
            this.setState({ personnelDevNotSelected: true });
            return false;
        } else {
            this.setState({ personnelDevNotSelected: false });
            return true;
        }
    };

    checkPersonnelInfraSelected = () => {
        const { getFieldValue } = this.baseform.current;
        const { personnelVisible } = this.state;

        let type_selected = true;
        if (
            !getFieldValue("personneltype_infra_server") &&
            !getFieldValue("personneltype_infra_network") &&
            !getFieldValue("personneltype_infra_database") &&
            !getFieldValue("personneltype_infra_security") &&
            !getFieldValue("personneltype_infra_sys") &&
            !getFieldValue("personneltype_infra_other")
        ) {
            type_selected = false;
        }

        let skill_selected = true;
        if (
            !getFieldValue("personnelskill_infra_youken") &&
            !getFieldValue("personnelskill_infra_kihon") &&
            !getFieldValue("personnelskill_infra_syousai") &&
            !getFieldValue("personnelskill_infra_kouchiku") &&
            !getFieldValue("personnelskill_infra_test") &&
            !getFieldValue("personnelskill_infra_hosyu") &&
            !getFieldValue("personnelskill_infra_kanshi") &&
            !getFieldValue("personnelskill_infra_beginner")
        ) {
            skill_selected = false;
        }

        if (
            personnelVisible &&
            (type_selected || skill_selected) &&
            type_selected !== skill_selected
        ) {
            this.setState({ personnelInfraNotSelected: true });
            return false;
        } else {
            this.setState({ personnelInfraNotSelected: false });
            return true;
        }
    };

    checkJobItemSelected = () => {
        const { getFieldValue } = this.baseform.current;
        const { jobVisible } = this.state;
        if (
            jobVisible &&
            !getFieldValue("jobtype_dev_designer") &&
            !getFieldValue("jobtype_dev_front") &&
            !getFieldValue("jobtype_dev_server") &&
            !getFieldValue("jobtype_dev_pm") &&
            !getFieldValue("jobtype_dev_other") &&
            !getFieldValue("jobtype_infra_server") &&
            !getFieldValue("jobtype_infra_network") &&
            !getFieldValue("jobtype_infra_database") &&
            !getFieldValue("jobtype_infra_security") &&
            !getFieldValue("jobtype_infra_sys") &&
            !getFieldValue("jobtype_infra_other") &&
            !getFieldValue("jobtype_other_eigyo") &&
            !getFieldValue("jobtype_other_kichi") &&
            !getFieldValue("jobtype_other_support") &&
            !getFieldValue("jobtype_other_other") &&
            !getFieldValue("jobskill_dev_youken") &&
            !getFieldValue("jobskill_dev_kihon") &&
            !getFieldValue("jobskill_dev_syousai") &&
            !getFieldValue("jobskill_dev_seizou") &&
            !getFieldValue("jobskill_dev_test") &&
            !getFieldValue("jobskill_dev_hosyu") &&
            !getFieldValue("jobskill_dev_beginner") &&
            !getFieldValue("jobskill_infra_youken") &&
            !getFieldValue("jobskill_infra_kihon") &&
            !getFieldValue("jobskill_infra_syousai") &&
            !getFieldValue("jobskill_infra_kouchiku") &&
            !getFieldValue("jobskill_infra_test") &&
            !getFieldValue("jobskill_infra_hosyu") &&
            !getFieldValue("jobskill_infra_kanshi") &&
            !getFieldValue("jobskill_infra_beginner")
        ) {
            this.setState({ jobTypeNotSelected: true });
            return false;
        } else {
            return true;
        }
    };

    checkJobSkillSelected = () => {
        const { getFieldValue } = this.baseform.current;
        const { jobVisible } = this.state;
        if (
            jobVisible &&
            !getFieldValue("jobskill_dev_youken") &&
            !getFieldValue("jobskill_dev_kihon") &&
            !getFieldValue("jobskill_dev_syousai") &&
            !getFieldValue("jobskill_dev_seizou") &&
            !getFieldValue("jobskill_dev_test") &&
            !getFieldValue("jobskill_dev_hosyu") &&
            !getFieldValue("jobskill_dev_beginner") &&
            !getFieldValue("jobskill_infra_youken") &&
            !getFieldValue("jobskill_infra_kihon") &&
            !getFieldValue("jobskill_infra_syousai") &&
            !getFieldValue("jobskill_infra_kouchiku") &&
            !getFieldValue("jobskill_infra_test") &&
            !getFieldValue("jobskill_infra_hosyu") &&
            !getFieldValue("jobskill_infra_kanshi") &&
            !getFieldValue("jobskill_infra_beginner")
        ) {
            this.setState({ jobSkillNotSelected: true });
            return false;
        } else {
            return true;
        }
    };

    checkPersonnelItemSelected = () => {
        const { getFieldValue } = this.baseform.current;
        const { personnelVisible } = this.state;
        if (
            personnelVisible &&
            !getFieldValue("personneltype_dev_designer") &&
            !getFieldValue("personneltype_dev_front") &&
            !getFieldValue("personneltype_dev_server") &&
            !getFieldValue("personneltype_dev_pm") &&
            !getFieldValue("personneltype_dev_other") &&
            !getFieldValue("personneltype_infra_server") &&
            !getFieldValue("personneltype_infra_network") &&
            !getFieldValue("personneltype_infra_database") &&
            !getFieldValue("personneltype_infra_security") &&
            !getFieldValue("personneltype_infra_sys") &&
            !getFieldValue("personneltype_infra_other") &&
            !getFieldValue("personneltype_other_eigyo") &&
            !getFieldValue("personneltype_other_kichi") &&
            !getFieldValue("personneltype_other_support") &&
            !getFieldValue("personneltype_other_other") &&
            !getFieldValue("personnelskill_dev_youken") &&
            !getFieldValue("personnelskill_dev_kihon") &&
            !getFieldValue("personnelskill_dev_syousai") &&
            !getFieldValue("personnelskill_dev_seizou") &&
            !getFieldValue("personnelskill_dev_test") &&
            !getFieldValue("personnelskill_dev_hosyu") &&
            !getFieldValue("personnelskill_dev_beginner") &&
            !getFieldValue("personnelskill_infra_youken") &&
            !getFieldValue("personnelskill_infra_kihon") &&
            !getFieldValue("personnelskill_infra_syousai") &&
            !getFieldValue("personnelskill_infra_kouchiku") &&
            !getFieldValue("personnelskill_infra_test") &&
            !getFieldValue("personnelskill_infra_hosyu") &&
            !getFieldValue("personnelskill_infra_kanshi") &&
            !getFieldValue("personnelskill_infra_beginner")
        ) {
            this.setState({ personnelTypeNotSelected: true });
            return false;
        } else {
            return true;
        }
    };

    checkPersonnelSkillSelected = () => {
        const { getFieldValue } = this.baseform.current;
        const { personnelVisible } = this.state;
        if (
            personnelVisible &&
            !getFieldValue("personnelskill_dev_youken") &&
            !getFieldValue("personnelskill_dev_kihon") &&
            !getFieldValue("personnelskill_dev_syousai") &&
            !getFieldValue("personnelskill_dev_seizou") &&
            !getFieldValue("personnelskill_dev_test") &&
            !getFieldValue("personnelskill_dev_hosyu") &&
            !getFieldValue("personnelskill_dev_beginner") &&
            !getFieldValue("personnelskill_infra_youken") &&
            !getFieldValue("personnelskill_infra_kihon") &&
            !getFieldValue("personnelskill_infra_syousai") &&
            !getFieldValue("personnelskill_infra_kouchiku") &&
            !getFieldValue("personnelskill_infra_test") &&
            !getFieldValue("personnelskill_infra_hosyu") &&
            !getFieldValue("personnelskill_infra_kanshi") &&
            !getFieldValue("personnelskill_infra_beginner")
        ) {
            this.setState({ personnelSkillNotSelected: true });
            return false;
        } else {
            return true;
        }
    };

    checkKoyouSelected = () => {
        const { getFieldValue } = this.baseform.current;
        const { personnelVisible } = this.state;
        if (
            personnelVisible &&
            !getFieldValue("job_koyou_proper") &&
            !getFieldValue("job_koyou_free")
        ) {
            this.setState({ koyouNotSelected: true });
            return false;
        } else {
            return true;
        }
    };

    checkCountrySelected = () => {
        const { getFieldValue } = this.baseform.current;
        const { personnelVisible } = this.state;
        if (
            personnelVisible &&
            !getFieldValue("personnel_country_japan") &&
            !getFieldValue("personnel_country_other")
        ) {
            this.setState({ countryNotSelected: true });
            return false;
        } else {
            return true;
        }
    };

    checkLocationSelected = () => {
        const { getFieldValue } = this.baseform.current;
        const { jobVisible, personnelVisible } = this.state;
        if (
            (jobVisible || personnelVisible) &&
            !getFieldValue("wants_location_hokkaido_japan") &&
            !getFieldValue("wants_location_touhoku_japan") &&
            !getFieldValue("wants_location_kanto_japan") &&
            !getFieldValue("wants_location_kansai_japan") &&
            !getFieldValue("wants_location_chubu_japan") &&
            !getFieldValue("wants_location_kyushu_japan") &&
            !getFieldValue("wants_location_other_japan") &&
            !getFieldValue("wants_location_chugoku_japan") &&
            !getFieldValue("wants_location_shikoku_japan") &&
            !getFieldValue("wants_location_toukai_japan")
        ) {
            this.setState({ locationNotSelected: true });
            return false;
        } else {
            return true;
        }
    };

    checkJobSyouryuSelected = () => {
        const { getFieldValue } = this.baseform.current;
        const { jobVisible } = this.state;
        if (jobVisible && !getFieldValue("job_syouryu")) {
            this.setState({ jobSyouryuNotSelected: true });
            return false;
        } else {
            return true;
        }
    };

    checkPersonnelSyouryuSelected = () => {
        const { getFieldValue } = this.baseform.current;
        const { personnelVisible } = this.state;
        if (personnelVisible && !getFieldValue("personnel_syouryu")) {
            this.setState({ personnelSyouryuNotSelected: true });
            return false;
        } else {
            return true;
        }
    };

    isRequiredItem = (key) => {
        const { selectedRequireItemKeys } = this.props;
        return selectedRequireItemKeys && selectedRequireItemKeys.includes(key);
    };

    getCurrentFieldError = (name) => {
        return (
            this.baseform.current &&
            this.baseform.current.getFieldError(name).length > 0
        );
    };

    handleKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
        }
    };

    SubmitError = ({ values, errorFields }) => {
        if (values.organization) {
            this.setState({ originationId: values.organization });
        }
        let currentfieldtels = errorFields.filter(
            (field) =>
                field["name"][0] === "tel1" ||
                field["name"][0] === "tel2" ||
                field["name"][0] === "tel3"
        );
        this.checkTelSelected(values, currentfieldtels);
        this.checkNumberOfTelSelected(values, currentfieldtels);
        if (errorFields) {
            let errorFieldNames = errorFields.map((field) => {
                return field["name"][0];
            });
            this.setState({ errorFields: errorFieldNames }); // stateに変更を入れないとエラーが画面に反映されないため
        }
    };

    checkTelSelected = (values, currentfieldtels) => {
        if (currentfieldtels.length > 0) {
            this.setState({ currentTel: false });
            return true;
        }
        if (!values["tel1"] && !values["tel2"] && !values["tel3"]) {
            this.setState({ currentTel: false });
            return true;
        }

        if (!values["tel1"] || !values["tel2"] || !values["tel3"]) {
            this.setState({ currentTel: true });
            return false;
        } else {
            this.setState({ currentTel: false });
            return true;
        }
    };

    checkNumberOfTelSelected = (values, currentfieldtels) => {
        if (currentfieldtels.length > 0) {
            this.setState({ numberOfTel: false });
            return true;
        }
        if (values["tel1"] === undefined) {
            values["tel1"] = "";
        }
        if (values["tel2"] === undefined) {
            values["tel2"] = "";
        }
        if (values["tel3"] === undefined) {
            values["tel3"] = "";
        }
        let tel = values["tel1"] + values["tel2"] + values["tel3"];
        if (tel.length > MaxTelLength) {
            this.setState({ numberOfTel: true });
            return false;
        } else {
            this.setState({ numberOfTel: false });
            return true;
        }
    };

    onClickCategory = (category) => {
        // NOTE(joshua-hashimoto): 渡される値はheartもしくはfrown
        const form = this.baseform.current;
        const { selectedCategory } = this.state;
        if (selectedCategory === category) {
            this.setState({ selectedCategory: undefined });
            form.setFieldsValue({
                category: undefined,
            });
            return;
        }
        this.setState({ selectedCategory: category });
        form.setFieldsValue({
            category,
        });
    };

    // Convert tag from object to string of id in each item in array
    getTagIds(tags) {
        if (tags && Array.isArray(tags)) {
            tags = tags.map((tag) => {
                if (typeof tag === "object") {
                    tag = tag.id;
                }
                return tag;
            });
        }

        return tags;
    }

    organizationList = () => {
        const { initialData } = this.props;
        const organizationPath = `${Path.organizations}/${initialData.organization}`;
        const organizationName = initialData.organization__name;
        const dataSource = [
            {
                name: organizationName,
            },
        ];
        return (
            <>
                {organizationName ? (
                    <List
                        header={<div>所属取引先</div>}
                        size="small"
                        bordered={false}
                        split={false}
                        itemLayout="horizontal"
                        dataSource={dataSource}
                        renderItem={(organization) => (
                            <List.Item>
                                <List.Item.Meta
                                    avatar={
                                        <BankOutlined
                                            style={{ color: iconPrimaryColor }}
                                        />
                                    }
                                    title={<Link to={organizationPath} target="_blank" style={{color: iconPrimaryColor}}>{organization.name}</Link>}
                                />
                            </List.Item>
                        )}
                    />
                ) : undefined}
            </>
        );
    };

    renderOrganizationAjaxSelect = () => {
        const { initialData, initialDataRegisterOrg, initialDataEditOrg } =
            this.props;
        const initialDataNew = initialDataEditOrg.id
            ? initialDataEditOrg
            : initialDataRegisterOrg;
        const { organizationId, organizationName, valueRemove } = this.state;
        if (initialDataNew.id && initialDataNew.name) {
            this.setState({ organizationId: initialDataNew.id });
            this.setState({ organizationName: initialDataNew.name });
        }
        const organization_id = !valueRemove
            ? organizationId || initialData.organization
            : organizationId;
        const organization_name = !valueRemove
            ? organizationName || initialData.organization__name
            : organizationName;
        const branch_id = initialData.organization_branch;
        const branch_name = initialData.organization_branch__name;
        const isBranch = branch_id && branch_name;

        const defaultSelect = {
            name: isBranch
                ? `${organization_name} - ${branch_name}`
                : organization_name,
            id: isBranch ? `${organization_id}/${branch_id}` : organization_id,
        };

        return (
            <OrganizationAjaxSelect
                defaultSelect={defaultSelect}
                onChange={(value) => {
                    this.setState({ organizationId: value });
                    if (value === undefined) {
                        this.setState({ valueRemove: true });
                    } else {
                        this.setState({ valueRemove: false });
                    }
                }}
            />
        );
    };

    buildDefaultSelectTags = () => {
        const { initialData } = this.props;
        let selectedTags = [];
        if (initialData.tag_id0 && initialData.tag_name0) {
            selectedTags.push({
                id: initialData.tag_id0,
                value: initialData.tag_name0,
            });
        }
        if (initialData.tag_id1 && initialData.tag_name1) {
            selectedTags.push({
                id: initialData.tag_id1,
                value: initialData.tag_name1,
            });
        }
        if (initialData.tag_id2 && initialData.tag_name2) {
            selectedTags.push({
                id: initialData.tag_id2,
                value: initialData.tag_name2,
            });
        }
        if (initialData.tag_id3 && initialData.tag_name3) {
            selectedTags.push({
                id: initialData.tag_id3,
                value: initialData.tag_name3,
            });
        }
        if (initialData.tag_id4 && initialData.tag_name4) {
            selectedTags.push({
                id: initialData.tag_id4,
                value: initialData.tag_name4,
            });
        }
        return selectedTags;
    };

    getBodyData = (type) => {
        const emailValue = this.baseform.current.getFieldValue("email");
        if (type === "email" && emailValue) {
            return emailValue;
        }
        const ccEmailsValue = this.baseform.current.getFieldValue("ccEmails");
        if (type === "ccEmails" && ccEmailsValue) {
            return ccEmailsValue;
        }
        return false;
    };

    onCheckExistEmail = (type) => {
        const { pageId, token, dispatch } = this.props;
        const bodyData = this.getBodyData(type);
        if (!bodyData) {
            return;
        }
        dispatch(
            checkExistEmailsAction(
                pageId,
                token,
                `${Endpoint.getBaseUrl()}/${Endpoint.emailCheck}`,
                bodyData
            )
        );
    };

    onChangeIsArchived = (checked) => {
        this.setState({ isArchived: checked });
    };

    tabBarExtraContent = () => {
        const { initialData, fieldErrors, authorizedActions } = this.props;
        const { isArchived } = this.state;

        let archiveAuthorized =
            authorizedActions &&
            authorizedActions["contacts"] &&
            authorizedActions["contacts"]["archive"];

        return (
            <Form.Item
                label={<span style={{ fontSize: "10px" }}>アーカイブ</span>}
                className={styles.field}
                help={fieldErrors.is_archived}
                name="is_archived"
                initialValue={initialData.is_archived}
                colon={false}
                labelAlign="right"
                labelCol={{ span: 16 }}>
                {archiveAuthorized ? (
                    <Switch
                        size="small"
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        onChange={this.onChangeIsArchived}
                        checked={isArchived}
                    />
                ) : (
                    <Tooltip title={"特定の権限で操作できます"}>
                        <Switch
                            size="small"
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={isArchived}
                            disabled={true}
                        />
                    </Tooltip>
                )}
            </Form.Item>
        );
    };

    render() {
        const {
            initialData,
            fieldErrors,
            created,
            comment_templates,
            authorizedActions,
            comments,
            deleteButton,
            commentsReducerName,
            initialDataRegisterOrg,
            initialDataEditOrg,
            selectedRequireItemKeys,
            checkEmailExistRegister,
            checkEmailExistEdit,
            pageId,
            planSummary,
        } = this.props;
        const { isArchived, errorMessage } = this.state;
        const isLoading =
            pageId === CONTACT_REGISTER_PAGE
                ? checkEmailExistRegister.loading
                : checkEmailExistEdit.loading;
        const initialDataNew = initialDataEditOrg.id
            ? initialDataEditOrg
            : initialDataRegisterOrg;
        const {
            selectedIndex,
            jobVisible,
            personnelVisible,
            jobTypeDevChecked,
            jobTypeInfraChecked,
            jobTypeOtherChecked,
            jobSkillDevChecked,
            jobSkillInfraChecked,
            personnelTypeDevChecked,
            personnelTypeInfraChecked,
            personnelTypeOtherChecked,
            personnelSkillDevChecked,
            personnelSkillInfraChecked,
            jobDevChecked,
            jobInfraChecked,
            jobOtherChecked,
            personnelDevChecked,
            personnelInfraChecked,
            personnelOtherChecked,
            selectedCategory,
            organizationName,
            hasSendGuide,
        } = this.state;
        let createAuthorized =
            authorizedActions &&
            authorizedActions["contacts"] &&
            authorizedActions["contacts"]["create"];
        let updateAuthorized =
            authorizedActions &&
            authorizedActions["contacts"] &&
            authorizedActions["contacts"]["update"];
        let commentPinAuthorization =
            authorizedActions &&
            authorizedActions["comment"] &&
            authorizedActions["comment"]["pin"];
        let requiredFields = selectedRequireItemKeys
            .toString()
            .replace("name", "last_name")
            .replace("email_to", "email")
            .replace("tag", "tags")
            .replace("tel", "tel1")
            .replace("email_cc", "ccEmails")
            .split(",");
        if (organizationName) {
            requiredFields = requiredFields.filter(
                (item) => item !== "organization"
            );
        }

        const createCommentPinTooltipMessage = () => {
            if (!(this.state.checked) && commentPinAuthorization) {
                return "コメントを固定"
            }
            if ((this.state.checked) && commentPinAuthorization) {
                return "コメントの固定解除"
            }
            return ErrorMessages.isNotAuthorized
        }

        const isPersonalPlan = planSummary?.data?.planId === PlanMasterId.PERSONAL;
        const isPlanLoading = planSummary?.isLoading;

        return (
            <Spin spinning={isLoading}>
                <Row
                    justify="start"
                    style={{ backgroundColor: isArchived ? "#efefef" : "" }}>
                    <Col {...containerLayout}>
                        <Form
                            onFinish={this.handleSubmitWithCheck}
                            onFinishFailed={this.handleSubmitError}
                            className={styles.container}
                            ref={this.baseform}
                            validateMessages={validateJapaneseMessages}
                            labelAlign="right">
                            <Tabs
                                defaultActiveKey="1"
                                tabBarExtraContent={this.tabBarExtraContent()}
                                onChange={this.changeTab}>
                                <TabPane tab="取引先担当者情報" key="1">
                                    <Form.Item
                                        {...contactPaneFormItemLayout}
                                        label="取引先担当者名"
                                        className={styles.field}
                                        required>
                                        <Form.Item
                                            name="last_name"
                                            help={fieldErrors.last_name}
                                            dependencies={["first_name"]}
                                            rules={[
                                                ({ getFieldValue }) => ({
                                                    validator: (_, value) => {
                                                        const lastName = !!value
                                                            ? value
                                                            : "";

                                                        // NOTE(joshua-hashimoto): スペース制御
                                                        if (
                                                            !RESTRICT_SPACE_REGEX.test(
                                                                lastName
                                                            )
                                                        ) {
                                                            return Promise.reject(
                                                                new Error(
                                                                    ErrorMessages.validation.regex.space
                                                                )
                                                            );
                                                        }

                                                        const firstNameValue =
                                                            getFieldValue(
                                                                "first_name"
                                                            );
                                                        const firstName =
                                                            !!firstNameValue
                                                                ? firstNameValue
                                                                : "";

                                                        // NOTE(joshua-hashimoto): スペース制御
                                                        if (
                                                            !RESTRICT_SPACE_REGEX.test(
                                                                firstName
                                                            )
                                                        ) {
                                                            return Promise.reject(
                                                                new Error(
                                                                    ErrorMessages.validation.regex.space
                                                                )
                                                            );
                                                        }

                                                        const fullName =
                                                            lastName +
                                                            firstName;

                                                        // NOTE(joshua-hashimoto): 必須項目制御
                                                        if (
                                                            !lastName ||
                                                            !fullName
                                                        ) {
                                                            return Promise.reject(
                                                                new Error(
                                                                    ErrorMessages.form.required
                                                                )
                                                            );
                                                        }
                                                        if (
                                                            fullName.length > 50
                                                        ) {
                                                            return Promise.reject(
                                                                new Error(
                                                                    ErrorMessages.validation.length.max50
                                                                )
                                                            );
                                                        }
                                                        return Promise.resolve();
                                                    },
                                                }),
                                            ]}
                                            noStyle>
                                            <Input
                                                placeholder="姓"
                                                style={{ width: "50%" }}
                                                onKeyDown={this.handleKeyDown}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="first_name"
                                            dependencies={["last_name"]}
                                            initialValue={
                                                initialData.first_name
                                            }
                                            noStyle>
                                            <Input
                                                placeholder="名"
                                                style={{ width: "50%" }}
                                                onKeyDown={this.handleKeyDown}
                                            />
                                        </Form.Item>
                                    </Form.Item>
                                    <Form.Item
                                        {...contactPaneFormItemLayout}
                                        label="所属取引先"
                                        className={styles.field}
                                        help={fieldErrors.organization}
                                        name="organization"
                                        initialValue={
                                            initialData.organization_branch
                                                ? `${initialData.organization}/${initialData.organization_branch}`
                                                : initialDataNew.id ||
                                                  initialData.organization
                                        }
                                        rules={[{ required: true }]}>
                                        {this.renderOrganizationAjaxSelect()}
                                    </Form.Item>
                                    <Form.Item
                                        {...contactPaneFormItemLayout}
                                        label="メールアドレス"
                                        className={styles.field}
                                    />
                                    <Form.Item noStyle>
                                        <Form.Item className={styles.field}>
                                            <Row>
                                                <Col span={18}>
                                                    <Form.Item
                                                        {...emailItemLayout}
                                                        className={
                                                            styles.fieldItem
                                                        }
                                                        label="TO"
                                                        validateStatus={
                                                            fieldErrors.email
                                                                ? "error"
                                                                : undefined
                                                        }
                                                        help={fieldErrors.email}
                                                        name="email"
                                                        initialValue={
                                                            initialData.email
                                                        }
                                                        rules={REQUIRED_EMAIL_FORM_RULES}>
                                                        <TextArea
                                                            autoSize={{
                                                                minRows: 1,
                                                            }}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6}>
                                                    <Form.Item
                                                        shouldUpdate
                                                        className={
                                                            styles.fieldItem
                                                        }>
                                                        {() => {
                                                            const hasError = !!this.baseform?.current?.getFieldError
                                                                ?.("email")?.length;
                                                            const hasValue = !!this.baseform?.current?.getFieldValue
                                                                ?.("email");
                                                            const value = hasValue? this.getBodyData("email") : undefined;
                                                            return (<>
                                                                <EmailValidateButton
                                                                    disabled={ hasError || !hasValue || isPlanLoading }
                                                                    value={ value }
                                                                    isPersonalPlan={ isPersonalPlan }
                                                                />
                                                                <Tooltip title="メールアドレス(TO)の有効性を確認します。この確認には数秒かかる場合があります。なお、対象のメールサーバーの状況によっては正しい判定結果を返せない可能性があります。">
                                                                    <InfoCircleTwoTone
                                                                        twoToneColor={
                                                                            infoColor
                                                                        }
                                                                    />
                                                                </Tooltip>
                                                            </>
                                                        )}}
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Form.Item>
                                        <Form.Item className={styles.field}>
                                            <Row>
                                                <Col span={18}>
                                                    <Form.Item
                                                        {...emailItemLayout}
                                                        label="CC"
                                                        className={
                                                            styles.fieldItem
                                                        }
                                                        validateStatus={
                                                            fieldErrors.ccEmails ||
                                                            this.getCurrentFieldError(
                                                                "ccEmails"
                                                            )
                                                                ? "error"
                                                                : undefined
                                                        }
                                                        help={
                                                            fieldErrors.ccEmails
                                                        }
                                                        name="ccEmails"
                                                        initialValue={initialData.cc_emails?.toString()}
                                                        rules={[
                                                            {
                                                                required:
                                                                    this.isRequiredItem(
                                                                        "email_cc"
                                                                    ),
                                                            },
                                                            {
                                                                validator: (
                                                                    _,
                                                                    value
                                                                ) => {
                                                                    if (
                                                                        !value
                                                                    ) {
                                                                        return Promise.resolve();
                                                                    }
                                                                    const emailList =
                                                                        value.split(
                                                                            ","
                                                                        );
                                                                    for (const email of emailList) {
                                                                        if (
                                                                            !email.match(
                                                                                EMAIL_REGEX
                                                                            )
                                                                        ) {
                                                                            return Promise.reject(
                                                                                new Error(
                                                                                    ErrorMessages.validation.regex.checkListMail
                                                                                )
                                                                            );
                                                                        }
                                                                    }
                                                                    return Promise.resolve();
                                                                },
                                                            },
                                                            {
                                                                pattern:
                                                                    ONLY_HANKAKU_REGEX,
                                                                message:
                                                                    ErrorMessages
                                                                        .validation
                                                                        .regex
                                                                        .onlyHankaku,
                                                            },
                                                            {
                                                                pattern:
                                                                    RESTRICT_SPACE_REGEX,
                                                                message:
                                                                    ErrorMessages
                                                                        .validation
                                                                        .regex
                                                                        .space,
                                                            },
                                                            {
                                                                max: 500,
                                                                message:
                                                                    "カンマを含めて" +
                                                                    ErrorMessages
                                                                        .validation
                                                                        .length
                                                                        .max500,
                                                            },
                                                        ]}>
                                                        <TextArea
                                                            autoSize={{
                                                                minRows: 1,
                                                            }}
                                                            placeholder="複数の場合はカンマ区切り"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6}>
                                                    <Form.Item
                                                        shouldUpdate
                                                        className={styles.fieldItem}
                                                    >
                                                        {() => {
                                                            const hasError = !!this.baseform?.current?.getFieldError
                                                                ?.("ccEmails")?.length;
                                                            const hasValue = !!this.baseform?.current?.getFieldValue
                                                                ?.("ccEmails");
                                                            const value = hasValue? this.getBodyData("ccEmails") : undefined;
                                                            return (<>
                                                                <CcMailValidateButton
                                                                    disabled={ hasError || !hasValue || isPlanLoading }
                                                                    value={ value }
                                                                    isPersonalPlan={ isPersonalPlan }
                                                                />
                                                                <Tooltip title="メールアドレス(CC)の有効性を確認します。この確認には数秒かかる場合があります。なお、対象のメールサーバーの状況によっては正しい判定結果を返せない可能性があります。">
                                                                    <InfoCircleTwoTone
                                                                        twoToneColor={
                                                                            infoColor
                                                                        }
                                                                    />
                                                                </Tooltip>
                                                            </>
                                                        )}}
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Form.Item>
                                    </Form.Item>
                                    <div hidden={this.state.email_expand} />
                                    <TelInputFormItem
                                        {...contactPaneFormItemLayout}
                                        validateStatus={
                                            fieldErrors?.tel
                                                ? "error"
                                                : undefined
                                        }
                                        help={fieldErrors?.tel}
                                        required={this.isRequiredItem("tel")}
                                    />
                                    <Form.Item
                                        {...contactPaneFormItemLayout}
                                        label="役職"
                                        className={styles.field}
                                        validateStatus={
                                            fieldErrors.position
                                                ? "error"
                                                : undefined
                                        }
                                        help={fieldErrors.position}
                                        name="position"
                                        initialValue={initialData.position}
                                        rules={[
                                            {
                                                required:
                                                    this.isRequiredItem(
                                                        "position"
                                                    ),
                                            },
                                            {
                                                max: 50,
                                                message:
                                                    ErrorMessages.validation
                                                        .length.max50,
                                            },
                                            {
                                                pattern: NO_ONLY_SPACES_REGEX,
                                                message: ErrorMessages.validation.regex.notOnlySpace
                                            }
                                        ]}>
                                        <Input onKeyDown={this.handleKeyDown} />
                                    </Form.Item>
                                    <Form.Item
                                        {...contactPaneFormItemLayout}
                                        label="部署"
                                        className={styles.field}
                                        validateStatus={
                                            fieldErrors.department
                                                ? "error"
                                                : undefined
                                        }
                                        help={fieldErrors.department}
                                        name="department"
                                        initialValue={initialData.department}
                                        rules={[
                                            {
                                                required:
                                                    this.isRequiredItem(
                                                        "department"
                                                    ),
                                            },
                                            {
                                                max: 50,
                                                message:
                                                    ErrorMessages.validation
                                                        .length.max50,
                                            },
                                            {
                                                pattern: NO_ONLY_SPACES_REGEX,
                                                message: ErrorMessages.validation.regex.notOnlySpace
                                            }
                                        ]}>
                                        <Input onKeyDown={this.handleKeyDown} />
                                    </Form.Item>
                                    <Form.Item
                                        {...contactPaneFormItemLayout}
                                        label={
                                            <span>
                                                自社担当者&nbsp;
                                                <Tooltip
                                                    title={
                                                        <span>
                                                            無効化されているユーザーは選択表示されません。
                                                            <br />
                                                            <a
                                                                href={
                                                                    Links.helps
                                                                        .users
                                                                        .activeToggle
                                                                }
                                                                target="_blank"
                                                                rel="noopener noreferrer">
                                                                詳細
                                                            </a>
                                                        </span>
                                                    }>
                                                    <QuestionCircleFilled
                                                        style={{
                                                            color: iconCustomColor,
                                                        }}
                                                        className={
                                                            styles.tooltip
                                                        }
                                                    />
                                                </Tooltip>
                                            </span>
                                        }
                                        className={styles.field}
                                        help={fieldErrors.staff}
                                        name="staff"
                                        initialValue={initialData.staff}
                                        rules={[
                                            {
                                                required:
                                                    this.isRequiredItem(
                                                        "staff"
                                                    ),
                                            },
                                        ]}>
                                        <UserAjaxSelect allowClear />
                                    </Form.Item>
                                    <Form.Item
                                        {...contactPaneFormItemLayout}
                                        label="最終商談日"
                                        className={styles.field}
                                        help={fieldErrors.last_visit}
                                        name="last_visit"
                                        initialValue={initialData.last_visit}
                                        rules={[
                                            {
                                                required:
                                                    this.isRequiredItem(
                                                        "last_visit"
                                                    ),
                                            },
                                        ]}>
                                        <CustomDatePicker
                                            placeholder="日付を選択"
                                            showTime={false}
                                            allowClear
                                            inputReadOnly
                                            disabledDate={disabledFutureDates}
                                        />
                                    </Form.Item>
                                    <TagSelectFormItem
                                        {...contactPaneFormItemLayout}
                                        required={this.isRequiredItem("tag")}
                                        onTagAdd={(newTag) => {
                                            const {
                                                getFieldValue,
                                                setFieldsValue,
                                            } = this.baseform.current;
                                            const tags =
                                                getFieldValue("tags") ?? [];
                                            setFieldsValue({
                                                tags: [...tags, newTag.id],
                                            });
                                        }}
                                        defaults={this.buildDefaultSelectTags()}
                                    />
                                    <Form.Item
                                        {...contactPaneFormItemLayout}
                                        label="相性"
                                        className={styles.field}
                                        help={
                                            fieldErrors.category ||
                                            this.getCurrentFieldError(
                                                "category"
                                            )
                                                ? "必ず選択してください。"
                                                : "この値は個人ごとに保持されますので、別ユーザーと共有されません。"
                                        }
                                        name="category"
                                        initialValue={initialData.category}
                                        rules={[
                                            {
                                                required:
                                                    this.isRequiredItem(
                                                        "category"
                                                    ),
                                            },
                                        ]}>
                                        <Tooltip title="良い">
                                            <Button
                                                className={styles.button}
                                                size="small"
                                                type="link"
                                                onClick={() =>
                                                    this.onClickCategory(
                                                        "heart"
                                                    )
                                                }
                                                icon={
                                                    <HeartTwoTone
                                                        twoToneColor={
                                                            selectedCategory ===
                                                            "heart"
                                                                ? "#eb2f96"
                                                                : "#d3d3d3"
                                                        }
                                                    />
                                                }
                                            />
                                        </Tooltip>
                                        <Tooltip title="悪い">
                                            <Button
                                                className={styles.button}
                                                size="small"
                                                type="link"
                                                onClick={() =>
                                                    this.onClickCategory(
                                                        "frown"
                                                    )
                                                }
                                                icon={<HeartBrokenIcon style={{color: (selectedCategory === "frown") ? svgColorIconLight :  svgColorIconDark}} />}/>
                                        </Tooltip>
                                    </Form.Item>
                                    <Form.Item
                                        {...tailLayout}
                                        className={styles.field}
                                    />
                                </TabPane>
                                <TabPane
                                    tab="配信条件"
                                    key="2"
                                    forceRender={this.initialViewRendered}>
                                    <Form.Item
                                        label={
                                            <span>
                                                希望エリア&nbsp;
                                                <Tooltip
                                                    title={
                                                        <span>
                                                            取引先担当者が配信を希望するエリアを選択します。
                                                        </span>
                                                    }>
                                                    <QuestionCircleFilled
                                                        style={{
                                                            color: iconCustomColor,
                                                        }}
                                                    />
                                                </Tooltip>
                                            </span>
                                        }
                                        {...streamTermsFormItemLayout}
                                        className={styles.field}
                                        validateStatus={
                                            this.state.locationNotSelected
                                                ? "error"
                                                : "success"
                                        }
                                        help={
                                            this.state.locationNotSelected
                                                ? "必ず1つ選択してください"
                                                : undefined
                                        }
                                        required={
                                            jobVisible || personnelVisible
                                        }>
                                        <Form.Item
                                            name="wants_location_hokkaido_japan"
                                            initialValue={
                                                initialData.wants_location_hokkaido_japan
                                            }
                                            valuePropName="checked"
                                            noStyle>
                                            <Checkbox
                                                className={
                                                    styles.horizontalCheckboxStyle
                                                }
                                                disabled={
                                                    !jobVisible &&
                                                    !personnelVisible
                                                }>
                                                北海道
                                            </Checkbox>
                                        </Form.Item>
                                        <Form.Item
                                            name="wants_location_touhoku_japan"
                                            initialValue={
                                                initialData.wants_location_touhoku_japan
                                            }
                                            valuePropName="checked"
                                            noStyle>
                                            <Checkbox
                                                className={
                                                    styles.horizontalCheckboxStyle
                                                }
                                                disabled={
                                                    !jobVisible &&
                                                    !personnelVisible
                                                }>
                                                東北
                                            </Checkbox>
                                        </Form.Item>
                                        <Form.Item
                                            name="wants_location_kanto_japan"
                                            initialValue={
                                                initialData.wants_location_kanto_japan
                                            }
                                            valuePropName="checked"
                                            noStyle>
                                            <Checkbox
                                                className={
                                                    styles.horizontalCheckboxStyle
                                                }
                                                disabled={
                                                    !jobVisible &&
                                                    !personnelVisible
                                                }>
                                                関東
                                            </Checkbox>
                                        </Form.Item>
                                        <Form.Item
                                            name="wants_location_chubu_japan"
                                            initialValue={
                                                initialData.wants_location_chubu_japan
                                            }
                                            valuePropName="checked"
                                            noStyle>
                                            <Checkbox
                                                className={
                                                    styles.horizontalCheckboxStyle
                                                }
                                                disabled={
                                                    !jobVisible &&
                                                    !personnelVisible
                                                }>
                                                中部
                                            </Checkbox>
                                        </Form.Item>
                                        <Form.Item
                                            name="wants_location_toukai_japan"
                                            initialValue={
                                                initialData.wants_location_toukai_japan
                                            }
                                            valuePropName="checked"
                                            noStyle>
                                            <Checkbox
                                                className={
                                                    styles.horizontalCheckboxStyle
                                                }
                                                disabled={
                                                    !jobVisible &&
                                                    !personnelVisible
                                                }>
                                                東海
                                            </Checkbox>
                                        </Form.Item>
                                        <Form.Item
                                            name="wants_location_kansai_japan"
                                            initialValue={
                                                initialData.wants_location_kansai_japan
                                            }
                                            valuePropName="checked"
                                            noStyle>
                                            <Checkbox
                                                className={
                                                    styles.horizontalCheckboxStyle
                                                }
                                                disabled={
                                                    !jobVisible &&
                                                    !personnelVisible
                                                }>
                                                関西
                                            </Checkbox>
                                        </Form.Item>
                                        <Form.Item
                                            name="wants_location_shikoku_japan"
                                            initialValue={
                                                initialData.wants_location_shikoku_japan
                                            }
                                            valuePropName="checked"
                                            noStyle>
                                            <Checkbox
                                                className={
                                                    styles.horizontalCheckboxStyle
                                                }
                                                disabled={
                                                    !jobVisible &&
                                                    !personnelVisible
                                                }>
                                                四国
                                            </Checkbox>
                                        </Form.Item>
                                        <Form.Item
                                            name="wants_location_chugoku_japan"
                                            initialValue={
                                                initialData.wants_location_chugoku_japan
                                            }
                                            valuePropName="checked"
                                            noStyle>
                                            <Checkbox
                                                className={
                                                    styles.horizontalCheckboxStyle
                                                }
                                                disabled={
                                                    !jobVisible &&
                                                    !personnelVisible
                                                }>
                                                中国
                                            </Checkbox>
                                        </Form.Item>
                                        <Form.Item
                                            name="wants_location_kyushu_japan"
                                            initialValue={
                                                initialData.wants_location_kyushu_japan
                                            }
                                            valuePropName="checked"
                                            noStyle>
                                            <Checkbox
                                                className={
                                                    styles.horizontalCheckboxStyle
                                                }
                                                disabled={
                                                    !jobVisible &&
                                                    !personnelVisible
                                                }>
                                                九州
                                            </Checkbox>
                                        </Form.Item>
                                        <Form.Item
                                            name="wants_location_other_japan"
                                            initialValue={
                                                initialData.wants_location_other_japan
                                            }
                                            valuePropName="checked"
                                            noStyle>
                                            <Checkbox
                                                className={
                                                    styles.horizontalCheckboxStyle
                                                }
                                                disabled={
                                                    !jobVisible &&
                                                    !personnelVisible
                                                }>
                                                その他
                                            </Checkbox>
                                        </Form.Item>
                                    </Form.Item>
                                    <Form.Item
                                        label={
                                            <span>
                                                要員配信&nbsp;
                                                <Tooltip
                                                    title={
                                                        <span>
                                                            取引先担当者が要員配信を希望する場合は有効にしてください。
                                                        </span>
                                                    }>
                                                    <QuestionCircleFilled
                                                        style={{
                                                            color: iconCustomColor,
                                                        }}
                                                    />
                                                </Tooltip>
                                            </span>
                                        }
                                        className={styles.field}
                                        {...streamTermsFormItemLayout}>
                                        <Switch
                                            checkedChildren={<CheckOutlined />}
                                            unCheckedChildren={
                                                <CloseOutlined />
                                            }
                                            onChange={() =>
                                                this.onChangePersonnelSwitch(
                                                    this.resetPersonnelProfile
                                                )
                                            }
                                            checked={personnelVisible}
                                        />
                                    </Form.Item>
                                    <div hidden={!personnelVisible}>
                                        <Form.Item
                                            label={
                                                <span>
                                                    希望職種&nbsp;
                                                    <Tooltip
                                                        title={
                                                            <span>
                                                                取引先担当者が配信を希望する案件または要員のスキルを選択します。
                                                                「職種詳細」と「スキル詳細」は必ず1つ以上選択する必要があります。
                                                                <br />
                                                                <a
                                                                    href={
                                                                        Links
                                                                            .helps
                                                                            .contacts
                                                                            .settingSteamingCondition
                                                                    }
                                                                    target="_blank"
                                                                    rel="noopener noreferrer">
                                                                    詳細
                                                                </a>
                                                            </span>
                                                        }>
                                                        <QuestionCircleFilled
                                                            style={{
                                                                color: iconCustomColor,
                                                            }}
                                                            className={
                                                                styles.tooltip
                                                            }
                                                        />
                                                    </Tooltip>
                                                </span>
                                            }
                                            className={styles.field}
                                            validateStatus={
                                                this.state
                                                    .personnelTypeNotSelected ||
                                                this.state
                                                    .personnelDevNotSelected ||
                                                this.state
                                                    .personnelInfraNotSelected
                                                    ? "error"
                                                    : "success"
                                            }
                                            help={
                                                this.state
                                                    .personnelTypeNotSelected ||
                                                this.state
                                                    .personnelDevNotSelected ||
                                                this.state
                                                    .personnelInfraNotSelected
                                                    ? "職種詳細とスキル詳細は必ず1つ選択してください"
                                                    : undefined
                                            }
                                            required={personnelVisible}
                                            {...streamTermsContentFormItemLayout}>
                                            <Comment
                                                content={
                                                    <Form.Item
                                                        name="personnel_dev"
                                                        initialValue={
                                                            personnelDevChecked
                                                        }
                                                        valuePropName="checked"
                                                        noStyle>
                                                        <Checkbox
                                                            onChange={
                                                                this
                                                                    .onChangePersonnelTypeAndSkillDev
                                                            }>
                                                            開発
                                                        </Checkbox>
                                                    </Form.Item>
                                                }>
                                                <Comment
                                                    content={
                                                        <Form.Item
                                                            name="personneltype_dev"
                                                            initialValue={
                                                                personnelTypeDevChecked
                                                            }
                                                            valuePropName="checked"
                                                            noStyle>
                                                            <Checkbox
                                                                onChange={
                                                                    this
                                                                        .onChangePersonnelTypeDev
                                                                }>
                                                                職種詳細
                                                            </Checkbox>
                                                        </Form.Item>
                                                    }>
                                                    <Comment
                                                        content={
                                                            <Row>
                                                                <Form.Item
                                                                    name="personneltype_dev_designer"
                                                                    initialValue={
                                                                        initialData.contactpersonneltypepreferences &&
                                                                        initialData.contactpersonneltypepreferences.includes(
                                                                            "dev_designer"
                                                                        )
                                                                    }
                                                                    valuePropName="checked"
                                                                    noStyle>
                                                                    <Checkbox
                                                                        className={
                                                                            styles.horizontalCheckboxStyle
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .onChangePersonnelTypeDevItem
                                                                        }>
                                                                        デザイナー
                                                                    </Checkbox>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name="personneltype_dev_front"
                                                                    initialValue={
                                                                        initialData.contactpersonneltypepreferences &&
                                                                        initialData.contactpersonneltypepreferences.includes(
                                                                            "dev_front"
                                                                        )
                                                                    }
                                                                    valuePropName="checked"
                                                                    noStyle>
                                                                    <Checkbox
                                                                        className={
                                                                            styles.horizontalCheckboxStyle
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .onChangePersonnelTypeDevItem
                                                                        }>
                                                                        フロントエンド
                                                                    </Checkbox>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name="personneltype_dev_server"
                                                                    initialValue={
                                                                        initialData.contactpersonneltypepreferences &&
                                                                        initialData.contactpersonneltypepreferences.includes(
                                                                            "dev_server"
                                                                        )
                                                                    }
                                                                    valuePropName="checked"
                                                                    noStyle>
                                                                    <Checkbox
                                                                        className={
                                                                            styles.horizontalCheckboxStyle
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .onChangePersonnelTypeDevItem
                                                                        }>
                                                                        バックエンド
                                                                    </Checkbox>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name="personneltype_dev_pm"
                                                                    initialValue={
                                                                        initialData.contactpersonneltypepreferences &&
                                                                        initialData.contactpersonneltypepreferences.includes(
                                                                            "dev_pm"
                                                                        )
                                                                    }
                                                                    valuePropName="checked"
                                                                    noStyle>
                                                                    <Checkbox
                                                                        className={
                                                                            styles.horizontalCheckboxStyle
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .onChangePersonnelTypeDevItem
                                                                        }>
                                                                        PM・ディレクター
                                                                    </Checkbox>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name="personneltype_dev_other"
                                                                    initialValue={
                                                                        initialData.contactpersonneltypepreferences &&
                                                                        initialData.contactpersonneltypepreferences.includes(
                                                                            "dev_other"
                                                                        )
                                                                    }
                                                                    valuePropName="checked"
                                                                    noStyle>
                                                                    <Checkbox
                                                                        className={
                                                                            styles.horizontalCheckboxStyle
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .onChangePersonnelTypeDevItem
                                                                        }>
                                                                        その他
                                                                    </Checkbox>
                                                                </Form.Item>
                                                            </Row>
                                                        }
                                                    />
                                                </Comment>
                                                <Comment
                                                    content={
                                                        <Form.Item
                                                            name="personnelskill_dev"
                                                            initialValue={
                                                                personnelSkillDevChecked
                                                            }
                                                            valuePropName="checked"
                                                            noStyle>
                                                            <Checkbox
                                                                onChange={
                                                                    this
                                                                        .onChangePersonnelSkillDev
                                                                }>
                                                                スキル詳細
                                                            </Checkbox>
                                                        </Form.Item>
                                                    }>
                                                    <Comment
                                                        content={
                                                            <Row>
                                                                <Form.Item
                                                                    name="personnelskill_dev_youken"
                                                                    initialValue={
                                                                        initialData.contactpersonnelskillpreferences &&
                                                                        initialData.contactpersonnelskillpreferences.includes(
                                                                            "dev_youken"
                                                                        )
                                                                    }
                                                                    valuePropName="checked"
                                                                    noStyle>
                                                                    <Checkbox
                                                                        className={
                                                                            styles.horizontalCheckboxStyle
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .onChangePersonnelSkillDevItem
                                                                        }>
                                                                        要件定義
                                                                    </Checkbox>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name="personnelskill_dev_kihon"
                                                                    initialValue={
                                                                        initialData.contactpersonnelskillpreferences &&
                                                                        initialData.contactpersonnelskillpreferences.includes(
                                                                            "dev_kihon"
                                                                        )
                                                                    }
                                                                    valuePropName="checked"
                                                                    noStyle>
                                                                    <Checkbox
                                                                        className={
                                                                            styles.horizontalCheckboxStyle
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .onChangePersonnelSkillDevItem
                                                                        }>
                                                                        基本設計
                                                                    </Checkbox>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name="personnelskill_dev_syousai"
                                                                    initialValue={
                                                                        initialData.contactpersonnelskillpreferences &&
                                                                        initialData.contactpersonnelskillpreferences.includes(
                                                                            "dev_syousai"
                                                                        )
                                                                    }
                                                                    valuePropName="checked"
                                                                    noStyle>
                                                                    <Checkbox
                                                                        className={
                                                                            styles.horizontalCheckboxStyle
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .onChangePersonnelSkillDevItem
                                                                        }>
                                                                        詳細設計
                                                                    </Checkbox>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name="personnelskill_dev_seizou"
                                                                    initialValue={
                                                                        initialData.contactpersonnelskillpreferences &&
                                                                        initialData.contactpersonnelskillpreferences.includes(
                                                                            "dev_seizou"
                                                                        )
                                                                    }
                                                                    valuePropName="checked"
                                                                    noStyle>
                                                                    <Checkbox
                                                                        className={
                                                                            styles.horizontalCheckboxStyle
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .onChangePersonnelSkillDevItem
                                                                        }>
                                                                        製造
                                                                    </Checkbox>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name="personnelskill_dev_test"
                                                                    initialValue={
                                                                        initialData.contactpersonnelskillpreferences &&
                                                                        initialData.contactpersonnelskillpreferences.includes(
                                                                            "dev_test"
                                                                        )
                                                                    }
                                                                    valuePropName="checked"
                                                                    noStyle>
                                                                    <Checkbox
                                                                        className={
                                                                            styles.horizontalCheckboxStyle
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .onChangePersonnelSkillDevItem
                                                                        }>
                                                                        テスト・検証
                                                                    </Checkbox>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name="personnelskill_dev_hosyu"
                                                                    initialValue={
                                                                        initialData.contactpersonnelskillpreferences &&
                                                                        initialData.contactpersonnelskillpreferences.includes(
                                                                            "dev_hosyu"
                                                                        )
                                                                    }
                                                                    valuePropName="checked"
                                                                    noStyle>
                                                                    <Checkbox
                                                                        className={
                                                                            styles.horizontalCheckboxStyle
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .onChangePersonnelSkillDevItem
                                                                        }>
                                                                        保守・運用
                                                                    </Checkbox>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name="personnelskill_dev_beginner"
                                                                    initialValue={
                                                                        initialData.contactpersonnelskillpreferences &&
                                                                        initialData.contactpersonnelskillpreferences.includes(
                                                                            "dev_beginner"
                                                                        )
                                                                    }
                                                                    valuePropName="checked"
                                                                    noStyle>
                                                                    <Checkbox
                                                                        className={
                                                                            styles.horizontalCheckboxStyle
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .onChangePersonnelSkillDevItem
                                                                        }>
                                                                        未経験
                                                                    </Checkbox>
                                                                </Form.Item>
                                                            </Row>
                                                        }
                                                    />
                                                </Comment>
                                            </Comment>
                                            <Comment
                                                content={
                                                    <Form.Item
                                                        name="personnel_infra"
                                                        initialValue={
                                                            personnelInfraChecked
                                                        }
                                                        valuePropName="checked"
                                                        noStyle>
                                                        <Checkbox
                                                            onChange={
                                                                this
                                                                    .onChangePersonnelTypeAndSkillInfra
                                                            }>
                                                            インフラ
                                                        </Checkbox>
                                                    </Form.Item>
                                                }>
                                                <Comment
                                                    content={
                                                        <Form.Item
                                                            name="personneltype_infra"
                                                            initialValue={
                                                                personnelTypeInfraChecked
                                                            }
                                                            valuePropName="checked"
                                                            noStyle>
                                                            <Checkbox
                                                                onChange={
                                                                    this
                                                                        .onChangePersonnelTypeInfra
                                                                }>
                                                                職種詳細
                                                            </Checkbox>
                                                        </Form.Item>
                                                    }>
                                                    <Comment
                                                        content={
                                                            <Row>
                                                                <Form.Item
                                                                    name="personneltype_infra_server"
                                                                    initialValue={
                                                                        initialData.contactpersonneltypepreferences &&
                                                                        initialData.contactpersonneltypepreferences.includes(
                                                                            "infra_server"
                                                                        )
                                                                    }
                                                                    valuePropName="checked"
                                                                    noStyle>
                                                                    <Checkbox
                                                                        className={
                                                                            styles.horizontalCheckboxStyle
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .onChangePersonnelTypeInfraItem
                                                                        }>
                                                                        サーバー
                                                                    </Checkbox>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name="personneltype_infra_network"
                                                                    initialValue={
                                                                        initialData.contactpersonneltypepreferences &&
                                                                        initialData.contactpersonneltypepreferences.includes(
                                                                            "infra_network"
                                                                        )
                                                                    }
                                                                    valuePropName="checked"
                                                                    noStyle>
                                                                    <Checkbox
                                                                        className={
                                                                            styles.horizontalCheckboxStyle
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .onChangePersonnelTypeInfraItem
                                                                        }>
                                                                        ネットワーク
                                                                    </Checkbox>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name="personneltype_infra_security"
                                                                    initialValue={
                                                                        initialData.contactpersonneltypepreferences &&
                                                                        initialData.contactpersonneltypepreferences.includes(
                                                                            "infra_security"
                                                                        )
                                                                    }
                                                                    valuePropName="checked"
                                                                    noStyle>
                                                                    <Checkbox
                                                                        className={
                                                                            styles.horizontalCheckboxStyle
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .onChangePersonnelTypeInfraItem
                                                                        }>
                                                                        セキュリティ
                                                                    </Checkbox>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name="personneltype_infra_database"
                                                                    initialValue={
                                                                        initialData.contactpersonneltypepreferences &&
                                                                        initialData.contactpersonneltypepreferences.includes(
                                                                            "infra_database"
                                                                        )
                                                                    }
                                                                    valuePropName="checked"
                                                                    noStyle>
                                                                    <Checkbox
                                                                        className={
                                                                            styles.horizontalCheckboxStyle
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .onChangePersonnelTypeInfraItem
                                                                        }>
                                                                        データベース
                                                                    </Checkbox>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name="personneltype_infra_sys"
                                                                    initialValue={
                                                                        initialData.contactpersonneltypepreferences &&
                                                                        initialData.contactpersonneltypepreferences.includes(
                                                                            "infra_sys"
                                                                        )
                                                                    }
                                                                    valuePropName="checked"
                                                                    noStyle>
                                                                    <Checkbox
                                                                        className={
                                                                            styles.horizontalCheckboxStyle
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .onChangePersonnelTypeInfraItem
                                                                        }>
                                                                        情報システム
                                                                    </Checkbox>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name="personneltype_infra_other"
                                                                    initialValue={
                                                                        initialData.contactpersonneltypepreferences &&
                                                                        initialData.contactpersonneltypepreferences.includes(
                                                                            "infra_other"
                                                                        )
                                                                    }
                                                                    valuePropName="checked"
                                                                    noStyle>
                                                                    <Checkbox
                                                                        className={
                                                                            styles.horizontalCheckboxStyle
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .onChangePersonnelTypeInfraItem
                                                                        }>
                                                                        その他
                                                                    </Checkbox>
                                                                </Form.Item>
                                                            </Row>
                                                        }
                                                    />
                                                </Comment>
                                                <Comment
                                                    content={
                                                        <Form.Item
                                                            name="personnelskill_infra"
                                                            initialValue={
                                                                personnelSkillInfraChecked
                                                            }
                                                            valuePropName="checked"
                                                            noStyle>
                                                            <Checkbox
                                                                onChange={
                                                                    this
                                                                        .onChangePersonnelSkillInfra
                                                                }>
                                                                スキル詳細
                                                            </Checkbox>
                                                        </Form.Item>
                                                    }>
                                                    <Comment
                                                        content={
                                                            <Row>
                                                                <Form.Item
                                                                    name="personnelskill_infra_youken"
                                                                    initialValue={
                                                                        initialData.contactpersonnelskillpreferences &&
                                                                        initialData.contactpersonnelskillpreferences.includes(
                                                                            "infra_youken"
                                                                        )
                                                                    }
                                                                    valuePropName="checked"
                                                                    noStyle>
                                                                    <Checkbox
                                                                        className={
                                                                            styles.horizontalCheckboxStyle
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .onChangePersonnelSkillInfraItem
                                                                        }>
                                                                        要件定義
                                                                    </Checkbox>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name="personnelskill_infra_kihon"
                                                                    initialValue={
                                                                        initialData.contactpersonnelskillpreferences &&
                                                                        initialData.contactpersonnelskillpreferences.includes(
                                                                            "infra_kihon"
                                                                        )
                                                                    }
                                                                    valuePropName="checked"
                                                                    noStyle>
                                                                    <Checkbox
                                                                        className={
                                                                            styles.horizontalCheckboxStyle
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .onChangePersonnelSkillInfraItem
                                                                        }>
                                                                        基本設計
                                                                    </Checkbox>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name="personnelskill_infra_syousai"
                                                                    initialValue={
                                                                        initialData.contactpersonnelskillpreferences &&
                                                                        initialData.contactpersonnelskillpreferences.includes(
                                                                            "infra_syousai"
                                                                        )
                                                                    }
                                                                    valuePropName="checked"
                                                                    noStyle>
                                                                    <Checkbox
                                                                        className={
                                                                            styles.horizontalCheckboxStyle
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .onChangePersonnelSkillInfraItem
                                                                        }>
                                                                        詳細設計
                                                                    </Checkbox>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name="personnelskill_infra_kouchiku"
                                                                    initialValue={
                                                                        initialData.contactpersonnelskillpreferences &&
                                                                        initialData.contactpersonnelskillpreferences.includes(
                                                                            "infra_kouchiku"
                                                                        )
                                                                    }
                                                                    valuePropName="checked"
                                                                    noStyle>
                                                                    <Checkbox
                                                                        className={
                                                                            styles.horizontalCheckboxStyle
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .onChangePersonnelSkillInfraItem
                                                                        }>
                                                                        構築
                                                                    </Checkbox>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name="personnelskill_infra_test"
                                                                    initialValue={
                                                                        initialData.contactpersonnelskillpreferences &&
                                                                        initialData.contactpersonnelskillpreferences.includes(
                                                                            "infra_test"
                                                                        )
                                                                    }
                                                                    valuePropName="checked"
                                                                    noStyle>
                                                                    <Checkbox
                                                                        className={
                                                                            styles.horizontalCheckboxStyle
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .onChangePersonnelSkillInfraItem
                                                                        }>
                                                                        テスト・検証
                                                                    </Checkbox>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name="personnelskill_infra_hosyu"
                                                                    initialValue={
                                                                        initialData.contactpersonnelskillpreferences &&
                                                                        initialData.contactpersonnelskillpreferences.includes(
                                                                            "infra_hosyu"
                                                                        )
                                                                    }
                                                                    valuePropName="checked"
                                                                    noStyle>
                                                                    <Checkbox
                                                                        className={
                                                                            styles.horizontalCheckboxStyle
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .onChangePersonnelSkillInfraItem
                                                                        }>
                                                                        保守・運用
                                                                    </Checkbox>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name="personnelskill_infra_kanshi"
                                                                    initialValue={
                                                                        initialData.contactpersonnelskillpreferences &&
                                                                        initialData.contactpersonnelskillpreferences.includes(
                                                                            "infra_kanshi"
                                                                        )
                                                                    }
                                                                    valuePropName="checked"
                                                                    noStyle>
                                                                    <Checkbox
                                                                        className={
                                                                            styles.horizontalCheckboxStyle
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .onChangePersonnelSkillInfraItem
                                                                        }>
                                                                        監視
                                                                    </Checkbox>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name="personnelskill_infra_beginner"
                                                                    initialValue={
                                                                        initialData.contactpersonnelskillpreferences &&
                                                                        initialData.contactpersonnelskillpreferences.includes(
                                                                            "infra_beginner"
                                                                        )
                                                                    }
                                                                    valuePropName="checked"
                                                                    noStyle>
                                                                    <Checkbox
                                                                        className={
                                                                            styles.horizontalCheckboxStyle
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .onChangePersonnelSkillInfraItem
                                                                        }>
                                                                        未経験
                                                                    </Checkbox>
                                                                </Form.Item>
                                                            </Row>
                                                        }
                                                    />
                                                </Comment>
                                            </Comment>
                                            <Comment
                                                content={
                                                    <Form.Item
                                                        name="personnel_other"
                                                        initialValue={
                                                            personnelOtherChecked
                                                        }
                                                        valuePropName="checked"
                                                        noStyle>
                                                        <Checkbox
                                                            onChange={
                                                                this
                                                                    .onChangePersonnelTypeAndSkillOther
                                                            }>
                                                            その他
                                                        </Checkbox>
                                                    </Form.Item>
                                                }>
                                                <Comment
                                                    content={
                                                        <Form.Item
                                                            name="personneltype_other"
                                                            initialValue={
                                                                personnelTypeOtherChecked
                                                            }
                                                            valuePropName="checked"
                                                            noStyle>
                                                            <Checkbox
                                                                onChange={
                                                                    this
                                                                        .onChangePersonnelTypeOther
                                                                }>
                                                                職種詳細
                                                            </Checkbox>
                                                        </Form.Item>
                                                    }>
                                                    <Comment
                                                        content={
                                                            <Row>
                                                                <Form.Item
                                                                    name="personneltype_other_eigyo"
                                                                    initialValue={
                                                                        initialData.contactpersonneltypepreferences &&
                                                                        initialData.contactpersonneltypepreferences.includes(
                                                                            "other_eigyo"
                                                                        )
                                                                    }
                                                                    valuePropName="checked"
                                                                    noStyle>
                                                                    <Checkbox
                                                                        className={
                                                                            styles.horizontalCheckboxStyle
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .onChangePersonnelTypeOtherItem
                                                                        }>
                                                                        営業・事務
                                                                    </Checkbox>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name="personneltype_other_kichi"
                                                                    initialValue={
                                                                        initialData.contactpersonneltypepreferences &&
                                                                        initialData.contactpersonneltypepreferences.includes(
                                                                            "other_kichi"
                                                                        )
                                                                    }
                                                                    valuePropName="checked"
                                                                    noStyle>
                                                                    <Checkbox
                                                                        className={
                                                                            styles.horizontalCheckboxStyle
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .onChangePersonnelTypeOtherItem
                                                                        }>
                                                                        基地局
                                                                    </Checkbox>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name="personneltype_other_support"
                                                                    initialValue={
                                                                        initialData.contactpersonneltypepreferences &&
                                                                        initialData.contactpersonneltypepreferences.includes(
                                                                            "other_support"
                                                                        )
                                                                    }
                                                                    valuePropName="checked"
                                                                    noStyle>
                                                                    <Checkbox
                                                                        className={
                                                                            styles.horizontalCheckboxStyle
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .onChangePersonnelTypeOtherItem
                                                                        }>
                                                                        コールセンター・サポートデスク
                                                                    </Checkbox>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name="personneltype_other_other"
                                                                    initialValue={
                                                                        initialData.contactpersonneltypepreferences &&
                                                                        initialData.contactpersonneltypepreferences.includes(
                                                                            "other_other"
                                                                        )
                                                                    }
                                                                    valuePropName="checked"
                                                                    noStyle>
                                                                    <Checkbox
                                                                        className={
                                                                            styles.horizontalCheckboxStyle
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .onChangePersonnelTypeOtherItem
                                                                        }>
                                                                        その他
                                                                    </Checkbox>
                                                                </Form.Item>
                                                            </Row>
                                                        }
                                                    />
                                                </Comment>
                                            </Comment>
                                        </Form.Item>
                                        <Form.Item
                                            label={
                                                <span>
                                                    希望雇用形態&nbsp;
                                                    <Tooltip
                                                        title={
                                                            <span>
                                                                取引先担当者が配信を希望する要員の雇用形態を選択します。
                                                            </span>
                                                        }>
                                                        <QuestionCircleFilled
                                                            style={{
                                                                color: iconCustomColor,
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </span>
                                            }
                                            className={styles.field}
                                            validateStatus={
                                                this.state.koyouNotSelected
                                                    ? "error"
                                                    : "success"
                                            }
                                            help={
                                                this.state.koyouNotSelected
                                                    ? "必ず1つ選択してください"
                                                    : undefined
                                            }
                                            required={personnelVisible}
                                            {...streamTermsContentFormItemLayout}>
                                            <Form.Item
                                                name="job_koyou_proper"
                                                initialValue={
                                                    initialData.job_koyou_proper
                                                }
                                                valuePropName="checked"
                                                noStyle>
                                                <Checkbox>プロパー</Checkbox>
                                            </Form.Item>
                                            <Form.Item
                                                name="job_koyou_free"
                                                initialValue={
                                                    initialData.job_koyou_free
                                                }
                                                valuePropName="checked"
                                                noStyle>
                                                <Checkbox>
                                                    フリーランス
                                                </Checkbox>
                                            </Form.Item>
                                        </Form.Item>
                                        <Form.Item
                                            label={
                                                <span>
                                                    商流制限&nbsp;
                                                    <Tooltip
                                                        title={
                                                            <span>
                                                                取引先担当者が配信を希望する案件または要員の商流の制限を選択します。
                                                                <br />
                                                                <a
                                                                    href={
                                                                        Links
                                                                            .helps
                                                                            .commercialDistribution
                                                                            .restriction
                                                                    }
                                                                    target="_blank"
                                                                    rel="noopener noreferrer">
                                                                    詳細
                                                                </a>
                                                            </span>
                                                        }>
                                                        <QuestionCircleFilled
                                                            style={{
                                                                color: iconCustomColor,
                                                            }}
                                                            className={
                                                                styles.tooltip
                                                            }
                                                        />
                                                    </Tooltip>
                                                </span>
                                            }
                                            className={styles.field}
                                            validateStatus={
                                                this.state
                                                    .personnelSyouryuNotSelected
                                                    ? "error"
                                                    : "success"
                                            }
                                            help={
                                                this.state
                                                    .personnelSyouryuNotSelected
                                                    ? "必ず1つ選択してください"
                                                    : undefined
                                            }
                                            required={personnelVisible}
                                            name="personnel_syouryu"
                                            initialValue={
                                                initialData.personnel_syouryu
                                            }
                                            {...streamTermsContentFormItemLayout}>
                                            <Radio.Group>
                                                <Radio value={1}>なし</Radio>
                                                <Radio value={4}>
                                                    自社所属まで
                                                </Radio>
                                                <Radio value={3}>
                                                    1社先所属まで
                                                </Radio>
                                                <Radio value={2}>
                                                    2社先所属まで
                                                </Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </div>
                                    <Form.Item
                                        label={
                                            <span>
                                                案件配信&nbsp;
                                                <Tooltip
                                                    title={
                                                        <span>
                                                            取引先担当者が案件配信を希望する場合は有効にしてください。
                                                        </span>
                                                    }>
                                                    <QuestionCircleFilled
                                                        style={{
                                                            color: iconCustomColor,
                                                        }}
                                                    />
                                                </Tooltip>
                                            </span>
                                        }
                                        className={styles.field}
                                        name="jobvisible"
                                        {...streamTermsFormItemLayout}>
                                        <Switch
                                            checkedChildren={<CheckOutlined />}
                                            unCheckedChildren={
                                                <CloseOutlined />
                                            }
                                            onChange={() =>
                                                this.onChangeJobSwitch(
                                                    this.resetJobProfile
                                                )
                                            }
                                            checked={jobVisible}
                                        />
                                    </Form.Item>
                                    <div hidden={!jobVisible}>
                                        <Form.Item
                                            label={
                                                <span>
                                                    希望職種&nbsp;
                                                    <Tooltip
                                                        title={
                                                            <span>
                                                                取引先担当者が配信を希望する案件または要員のスキルを選択します。
                                                                「職種詳細」と「スキル詳細」は必ず1つ以上選択する必要があります。
                                                                <br />
                                                                <a
                                                                    href={
                                                                        Links
                                                                            .helps
                                                                            .contacts
                                                                            .settingSteamingCondition
                                                                    }
                                                                    target="_blank"
                                                                    rel="noopener noreferrer">
                                                                    詳細
                                                                </a>
                                                            </span>
                                                        }>
                                                        <QuestionCircleFilled
                                                            style={{
                                                                color: iconCustomColor,
                                                            }}
                                                            className={
                                                                styles.tooltip
                                                            }
                                                        />
                                                    </Tooltip>
                                                </span>
                                            }
                                            className={styles.field}
                                            validateStatus={
                                                this.state.jobTypeNotSelected ||
                                                this.state.jobDevNotSelected ||
                                                this.state.jobInfraNotSelected
                                                    ? "error"
                                                    : "success"
                                            }
                                            help={
                                                this.state.jobTypeNotSelected ||
                                                this.state.jobDevNotSelected ||
                                                this.state.jobInfraNotSelected
                                                    ? "職種詳細とスキル詳細は必ず1つ選択してください"
                                                    : undefined
                                            }
                                            required={jobVisible}
                                            {...streamTermsContentFormItemLayout}>
                                            <Comment
                                                content={
                                                    <Form.Item
                                                        name="job_dev"
                                                        initialValue={
                                                            jobDevChecked
                                                        }
                                                        valuePropName="checked"
                                                        noStyle>
                                                        <Checkbox
                                                            onChange={
                                                                this
                                                                    .onChangeJobTypeAndSkillDev
                                                            }>
                                                            開発
                                                        </Checkbox>
                                                    </Form.Item>
                                                }>
                                                <Comment
                                                    content={
                                                        <Form.Item
                                                            name="jobtype_dev"
                                                            initialValue={
                                                                jobTypeDevChecked
                                                            }
                                                            valuePropName="checked"
                                                            noStyle>
                                                            <Checkbox
                                                                onChange={
                                                                    this
                                                                        .onChangeJobTypeDev
                                                                }>
                                                                職種詳細
                                                            </Checkbox>
                                                        </Form.Item>
                                                    }>
                                                    <Comment
                                                        content={
                                                            <Row justify="start">
                                                                <Form.Item
                                                                    name="jobtype_dev_designer"
                                                                    initialValue={
                                                                        initialData.contactjobtypepreferences &&
                                                                        initialData.contactjobtypepreferences.includes(
                                                                            "dev_designer"
                                                                        )
                                                                    }
                                                                    valuePropName="checked"
                                                                    noStyle>
                                                                    <Checkbox
                                                                        className={
                                                                            styles.horizontalCheckboxStyle
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .onChangeJobTypeDevItem
                                                                        }>
                                                                        デザイナー
                                                                    </Checkbox>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name="jobtype_dev_front"
                                                                    initialValue={
                                                                        initialData.contactjobtypepreferences &&
                                                                        initialData.contactjobtypepreferences.includes(
                                                                            "dev_front"
                                                                        )
                                                                    }
                                                                    valuePropName="checked"
                                                                    noStyle>
                                                                    <Checkbox
                                                                        className={
                                                                            styles.horizontalCheckboxStyle
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .onChangeJobTypeDevItem
                                                                        }>
                                                                        フロントエンド
                                                                    </Checkbox>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name="jobtype_dev_server"
                                                                    initialValue={
                                                                        initialData.contactjobtypepreferences &&
                                                                        initialData.contactjobtypepreferences.includes(
                                                                            "dev_server"
                                                                        )
                                                                    }
                                                                    valuePropName="checked"
                                                                    noStyle>
                                                                    <Checkbox
                                                                        className={
                                                                            styles.horizontalCheckboxStyle
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .onChangeJobTypeDevItem
                                                                        }>
                                                                        バックエンド
                                                                    </Checkbox>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name="jobtype_dev_pm"
                                                                    initialValue={
                                                                        initialData.contactjobtypepreferences &&
                                                                        initialData.contactjobtypepreferences.includes(
                                                                            "dev_pm"
                                                                        )
                                                                    }
                                                                    valuePropName="checked"
                                                                    noStyle>
                                                                    <Checkbox
                                                                        className={
                                                                            styles.horizontalCheckboxStyle
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .onChangeJobTypeDevItem
                                                                        }>
                                                                        PM・ディレクター
                                                                    </Checkbox>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name="jobtype_dev_other"
                                                                    initialValue={
                                                                        initialData.contactjobtypepreferences &&
                                                                        initialData.contactjobtypepreferences.includes(
                                                                            "dev_other"
                                                                        )
                                                                    }
                                                                    valuePropName="checked"
                                                                    noStyle>
                                                                    <Checkbox
                                                                        className={
                                                                            styles.horizontalCheckboxStyle
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .onChangeJobTypeDevItem
                                                                        }>
                                                                        その他
                                                                    </Checkbox>
                                                                </Form.Item>
                                                            </Row>
                                                        }
                                                    />
                                                </Comment>
                                                <Comment
                                                    content={
                                                        <Form.Item
                                                            name="jobskill_dev"
                                                            initialValue={
                                                                jobSkillDevChecked
                                                            }
                                                            valuePropName="checked"
                                                            noStyle>
                                                            <Checkbox
                                                                onChange={
                                                                    this
                                                                        .onChangeJobSkillDev
                                                                }>
                                                                スキル詳細
                                                            </Checkbox>
                                                        </Form.Item>
                                                    }>
                                                    <Comment
                                                        content={
                                                            <Row>
                                                                <Form.Item
                                                                    name="jobskill_dev_youken"
                                                                    initialValue={
                                                                        initialData.contactjobskillpreferences &&
                                                                        initialData.contactjobskillpreferences.includes(
                                                                            "dev_youken"
                                                                        )
                                                                    }
                                                                    valuePropName="checked"
                                                                    noStyle>
                                                                    <Checkbox
                                                                        className={
                                                                            styles.horizontalCheckboxStyle
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .onChangeJobSkillDevItem
                                                                        }>
                                                                        要件定義
                                                                    </Checkbox>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name="jobskill_dev_kihon"
                                                                    initialValue={
                                                                        initialData.contactjobskillpreferences &&
                                                                        initialData.contactjobskillpreferences.includes(
                                                                            "dev_kihon"
                                                                        )
                                                                    }
                                                                    valuePropName="checked"
                                                                    noStyle>
                                                                    <Checkbox
                                                                        className={
                                                                            styles.horizontalCheckboxStyle
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .onChangeJobSkillDevItem
                                                                        }>
                                                                        基本設計
                                                                    </Checkbox>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name="jobskill_dev_syousai"
                                                                    initialValue={
                                                                        initialData.contactjobskillpreferences &&
                                                                        initialData.contactjobskillpreferences.includes(
                                                                            "dev_syousai"
                                                                        )
                                                                    }
                                                                    valuePropName="checked"
                                                                    noStyle>
                                                                    <Checkbox
                                                                        className={
                                                                            styles.horizontalCheckboxStyle
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .onChangeJobSkillDevItem
                                                                        }>
                                                                        詳細設計
                                                                    </Checkbox>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name="jobskill_dev_seizou"
                                                                    initialValue={
                                                                        initialData.contactjobskillpreferences &&
                                                                        initialData.contactjobskillpreferences.includes(
                                                                            "dev_seizou"
                                                                        )
                                                                    }
                                                                    valuePropName="checked"
                                                                    noStyle>
                                                                    <Checkbox
                                                                        className={
                                                                            styles.horizontalCheckboxStyle
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .onChangeJobSkillDevItem
                                                                        }>
                                                                        製造
                                                                    </Checkbox>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name="jobskill_dev_test"
                                                                    initialValue={
                                                                        initialData.contactjobskillpreferences &&
                                                                        initialData.contactjobskillpreferences.includes(
                                                                            "dev_test"
                                                                        )
                                                                    }
                                                                    valuePropName="checked"
                                                                    noStyle>
                                                                    <Checkbox
                                                                        className={
                                                                            styles.horizontalCheckboxStyle
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .onChangeJobSkillDevItem
                                                                        }>
                                                                        テスト・検証
                                                                    </Checkbox>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name="jobskill_dev_hosyu"
                                                                    initialValue={
                                                                        initialData.contactjobskillpreferences &&
                                                                        initialData.contactjobskillpreferences.includes(
                                                                            "dev_hosyu"
                                                                        )
                                                                    }
                                                                    valuePropName="checked"
                                                                    noStyle>
                                                                    <Checkbox
                                                                        className={
                                                                            styles.horizontalCheckboxStyle
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .onChangeJobSkillDevItem
                                                                        }>
                                                                        保守・運用
                                                                    </Checkbox>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name="jobskill_dev_beginner"
                                                                    initialValue={
                                                                        initialData.contactjobskillpreferences &&
                                                                        initialData.contactjobskillpreferences.includes(
                                                                            "dev_beginner"
                                                                        )
                                                                    }
                                                                    valuePropName="checked"
                                                                    noStyle>
                                                                    <Checkbox
                                                                        className={
                                                                            styles.horizontalCheckboxStyle
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .onChangeJobSkillDevItem
                                                                        }>
                                                                        未経験
                                                                    </Checkbox>
                                                                </Form.Item>
                                                            </Row>
                                                        }
                                                    />
                                                </Comment>
                                            </Comment>
                                            <Comment
                                                content={
                                                    <Form.Item
                                                        name="job_infra"
                                                        initialValue={
                                                            jobInfraChecked
                                                        }
                                                        valuePropName="checked"
                                                        noStyle>
                                                        <Checkbox
                                                            onChange={
                                                                this
                                                                    .onChangeJobTypeAndSkillInfra
                                                            }>
                                                            インフラ
                                                        </Checkbox>
                                                    </Form.Item>
                                                }>
                                                <Comment
                                                    content={
                                                        <Form.Item
                                                            name="jobtype_infra"
                                                            initialValue={
                                                                jobTypeInfraChecked
                                                            }
                                                            valuePropName="checked"
                                                            noStyle>
                                                            <Checkbox
                                                                onChange={
                                                                    this
                                                                        .onChangeJobTypeInfra
                                                                }>
                                                                職種詳細
                                                            </Checkbox>
                                                        </Form.Item>
                                                    }>
                                                    <Comment
                                                        content={
                                                            <Row>
                                                                <Form.Item
                                                                    name="jobtype_infra_server"
                                                                    initialValue={
                                                                        initialData.contactjobtypepreferences &&
                                                                        initialData.contactjobtypepreferences.includes(
                                                                            "infra_server"
                                                                        )
                                                                    }
                                                                    valuePropName="checked"
                                                                    noStyle>
                                                                    <Checkbox
                                                                        className={
                                                                            styles.horizontalCheckboxStyle
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .onChangeJobTypeInfraItem
                                                                        }>
                                                                        サーバー
                                                                    </Checkbox>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name="jobtype_infra_network"
                                                                    initialValue={
                                                                        initialData.contactjobtypepreferences &&
                                                                        initialData.contactjobtypepreferences.includes(
                                                                            "infra_network"
                                                                        )
                                                                    }
                                                                    valuePropName="checked"
                                                                    noStyle>
                                                                    <Checkbox
                                                                        className={
                                                                            styles.horizontalCheckboxStyle
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .onChangeJobTypeInfraItem
                                                                        }>
                                                                        ネットワーク
                                                                    </Checkbox>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name="jobtype_infra_security"
                                                                    initialValue={
                                                                        initialData.contactjobtypepreferences &&
                                                                        initialData.contactjobtypepreferences.includes(
                                                                            "infra_security"
                                                                        )
                                                                    }
                                                                    valuePropName="checked"
                                                                    noStyle>
                                                                    <Checkbox
                                                                        className={
                                                                            styles.horizontalCheckboxStyle
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .onChangeJobTypeInfraItem
                                                                        }>
                                                                        セキュリティ
                                                                    </Checkbox>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name="jobtype_infra_database"
                                                                    initialValue={
                                                                        initialData.contactjobtypepreferences &&
                                                                        initialData.contactjobtypepreferences.includes(
                                                                            "infra_database"
                                                                        )
                                                                    }
                                                                    valuePropName="checked"
                                                                    noStyle>
                                                                    <Checkbox
                                                                        className={
                                                                            styles.horizontalCheckboxStyle
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .onChangeJobTypeInfraItem
                                                                        }>
                                                                        データベース
                                                                    </Checkbox>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name="jobtype_infra_sys"
                                                                    initialValue={
                                                                        initialData.contactjobtypepreferences &&
                                                                        initialData.contactjobtypepreferences.includes(
                                                                            "infra_sys"
                                                                        )
                                                                    }
                                                                    valuePropName="checked"
                                                                    noStyle>
                                                                    <Checkbox
                                                                        className={
                                                                            styles.horizontalCheckboxStyle
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .onChangeJobTypeInfraItem
                                                                        }>
                                                                        情報システム
                                                                    </Checkbox>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name="jobtype_infra_other"
                                                                    initialValue={
                                                                        initialData.contactjobtypepreferences &&
                                                                        initialData.contactjobtypepreferences.includes(
                                                                            "infra_other"
                                                                        )
                                                                    }
                                                                    valuePropName="checked"
                                                                    noStyle>
                                                                    <Checkbox
                                                                        className={
                                                                            styles.horizontalCheckboxStyle
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .onChangeJobTypeInfraItem
                                                                        }>
                                                                        その他
                                                                    </Checkbox>
                                                                </Form.Item>
                                                            </Row>
                                                        }
                                                    />
                                                </Comment>
                                                <Comment
                                                    content={
                                                        <Form.Item
                                                            name="jobskill_infra"
                                                            initialValue={
                                                                jobSkillInfraChecked
                                                            }
                                                            valuePropName="checked"
                                                            noStyle>
                                                            <Checkbox
                                                                onChange={
                                                                    this
                                                                        .onChangeJobSkillInfra
                                                                }>
                                                                スキル詳細
                                                            </Checkbox>
                                                        </Form.Item>
                                                    }>
                                                    <Comment
                                                        content={
                                                            <Row>
                                                                <Form.Item
                                                                    name="jobskill_infra_youken"
                                                                    initialValue={
                                                                        initialData.contactjobskillpreferences &&
                                                                        initialData.contactjobskillpreferences.includes(
                                                                            "infra_youken"
                                                                        )
                                                                    }
                                                                    valuePropName="checked"
                                                                    noStyle>
                                                                    <Checkbox
                                                                        className={
                                                                            styles.horizontalCheckboxStyle
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .onChangeJobSkillInfraItem
                                                                        }>
                                                                        要件定義
                                                                    </Checkbox>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name="jobskill_infra_kihon"
                                                                    initialValue={
                                                                        initialData.contactjobskillpreferences &&
                                                                        initialData.contactjobskillpreferences.includes(
                                                                            "infra_kihon"
                                                                        )
                                                                    }
                                                                    valuePropName="checked"
                                                                    noStyle>
                                                                    <Checkbox
                                                                        className={
                                                                            styles.horizontalCheckboxStyle
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .onChangeJobSkillInfraItem
                                                                        }>
                                                                        基本設計
                                                                    </Checkbox>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name="jobskill_infra_syousai"
                                                                    initialValue={
                                                                        initialData.contactjobskillpreferences &&
                                                                        initialData.contactjobskillpreferences.includes(
                                                                            "infra_syousai"
                                                                        )
                                                                    }
                                                                    valuePropName="checked"
                                                                    noStyle>
                                                                    <Checkbox
                                                                        className={
                                                                            styles.horizontalCheckboxStyle
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .onChangeJobSkillInfraItem
                                                                        }>
                                                                        詳細設計
                                                                    </Checkbox>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name="jobskill_infra_kouchiku"
                                                                    initialValue={
                                                                        initialData.contactjobskillpreferences &&
                                                                        initialData.contactjobskillpreferences.includes(
                                                                            "infra_kouchiku"
                                                                        )
                                                                    }
                                                                    valuePropName="checked"
                                                                    noStyle>
                                                                    <Checkbox
                                                                        className={
                                                                            styles.horizontalCheckboxStyle
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .onChangeJobSkillInfraItem
                                                                        }>
                                                                        構築
                                                                    </Checkbox>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name="jobskill_infra_test"
                                                                    initialValue={
                                                                        initialData.contactjobskillpreferences &&
                                                                        initialData.contactjobskillpreferences.includes(
                                                                            "infra_test"
                                                                        )
                                                                    }
                                                                    valuePropName="checked"
                                                                    noStyle>
                                                                    <Checkbox
                                                                        className={
                                                                            styles.horizontalCheckboxStyle
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .onChangeJobSkillInfraItem
                                                                        }>
                                                                        テスト・検証
                                                                    </Checkbox>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name="jobskill_infra_hosyu"
                                                                    initialValue={
                                                                        initialData.contactjobskillpreferences &&
                                                                        initialData.contactjobskillpreferences.includes(
                                                                            "infra_hosyu"
                                                                        )
                                                                    }
                                                                    valuePropName="checked"
                                                                    noStyle>
                                                                    <Checkbox
                                                                        className={
                                                                            styles.horizontalCheckboxStyle
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .onChangeJobSkillInfraItem
                                                                        }>
                                                                        保守・運用
                                                                    </Checkbox>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name="jobskill_infra_kanshi"
                                                                    initialValue={
                                                                        initialData.contactjobskillpreferences &&
                                                                        initialData.contactjobskillpreferences.includes(
                                                                            "infra_kanshi"
                                                                        )
                                                                    }
                                                                    valuePropName="checked"
                                                                    noStyle>
                                                                    <Checkbox
                                                                        className={
                                                                            styles.horizontalCheckboxStyle
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .onChangeJobSkillInfraItem
                                                                        }>
                                                                        監視
                                                                    </Checkbox>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name="jobskill_infra_beginner"
                                                                    initialValue={
                                                                        initialData.contactjobskillpreferences &&
                                                                        initialData.contactjobskillpreferences.includes(
                                                                            "infra_beginner"
                                                                        )
                                                                    }
                                                                    valuePropName="checked"
                                                                    noStyle>
                                                                    <Checkbox
                                                                        className={
                                                                            styles.horizontalCheckboxStyle
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .onChangeJobSkillInfraItem
                                                                        }>
                                                                        未経験
                                                                    </Checkbox>
                                                                </Form.Item>
                                                            </Row>
                                                        }
                                                    />
                                                </Comment>
                                            </Comment>
                                            <Comment
                                                content={
                                                    <Form.Item
                                                        name="job_other"
                                                        initialValue={
                                                            jobOtherChecked
                                                        }
                                                        valuePropName="checked"
                                                        noStyle>
                                                        <Checkbox
                                                            onChange={
                                                                this
                                                                    .onChangeJobTypeAndSkillOther
                                                            }>
                                                            その他
                                                        </Checkbox>
                                                    </Form.Item>
                                                }>
                                                <Comment
                                                    content={
                                                        <Form.Item
                                                            name="jobtype_other"
                                                            initialValue={
                                                                jobTypeOtherChecked
                                                            }
                                                            valuePropName="checked"
                                                            noStyle>
                                                            <Checkbox
                                                                onChange={
                                                                    this
                                                                        .onChangeJobTypeOther
                                                                }>
                                                                職種詳細
                                                            </Checkbox>
                                                        </Form.Item>
                                                    }>
                                                    <Comment
                                                        content={
                                                            <Row>
                                                                <Form.Item
                                                                    name="jobtype_other_eigyo"
                                                                    initialValue={
                                                                        initialData.contactjobtypepreferences &&
                                                                        initialData.contactjobtypepreferences.includes(
                                                                            "other_eigyo"
                                                                        )
                                                                    }
                                                                    valuePropName="checked"
                                                                    noStyle>
                                                                    <Checkbox
                                                                        className={
                                                                            styles.horizontalCheckboxStyle
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .onChangeJobTypeOtherItem
                                                                        }>
                                                                        営業・事務
                                                                    </Checkbox>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name="jobtype_other_kichi"
                                                                    initialValue={
                                                                        initialData.contactjobtypepreferences &&
                                                                        initialData.contactjobtypepreferences.includes(
                                                                            "other_kichi"
                                                                        )
                                                                    }
                                                                    valuePropName="checked"
                                                                    noStyle>
                                                                    <Checkbox
                                                                        className={
                                                                            styles.horizontalCheckboxStyle
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .onChangeJobTypeOtherItem
                                                                        }>
                                                                        基地局
                                                                    </Checkbox>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name="jobtype_other_support"
                                                                    initialValue={
                                                                        initialData.contactjobtypepreferences &&
                                                                        initialData.contactjobtypepreferences.includes(
                                                                            "other_support"
                                                                        )
                                                                    }
                                                                    valuePropName="checked"
                                                                    noStyle>
                                                                    <Checkbox
                                                                        className={
                                                                            styles.horizontalCheckboxStyle
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .onChangeJobTypeOtherItem
                                                                        }>
                                                                        コールセンター・サポートデスク
                                                                    </Checkbox>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name="jobtype_other_other"
                                                                    initialValue={
                                                                        initialData.contactjobtypepreferences &&
                                                                        initialData.contactjobtypepreferences.includes(
                                                                            "other_other"
                                                                        )
                                                                    }
                                                                    valuePropName="checked"
                                                                    noStyle>
                                                                    <Checkbox
                                                                        className={
                                                                            styles.horizontalCheckboxStyle
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .onChangeJobTypeOtherItem
                                                                        }>
                                                                        その他
                                                                    </Checkbox>
                                                                </Form.Item>
                                                            </Row>
                                                        }
                                                    />
                                                </Comment>
                                            </Comment>
                                        </Form.Item>
                                        <Form.Item
                                            label={
                                                <span>
                                                    商流制限&nbsp;
                                                    <Tooltip
                                                        title={
                                                            <span>
                                                                取引先担当者が配信を希望する案件または要員の商流の制限を選択します。
                                                                <br />
                                                                <a
                                                                    href={
                                                                        Links
                                                                            .helps
                                                                            .commercialDistribution
                                                                            .restriction
                                                                    }
                                                                    target="_blank"
                                                                    rel="noopener noreferrer">
                                                                    詳細
                                                                </a>
                                                            </span>
                                                        }>
                                                        <QuestionCircleFilled
                                                            style={{
                                                                color: iconCustomColor,
                                                            }}
                                                            className={
                                                                styles.tooltip
                                                            }
                                                        />
                                                    </Tooltip>
                                                </span>
                                            }
                                            className={styles.field}
                                            validateStatus={
                                                this.state.jobSyouryuNotSelected
                                                    ? "error"
                                                    : "success"
                                            }
                                            help={
                                                this.state.jobSyouryuNotSelected
                                                    ? "必ず1つ選択してください"
                                                    : undefined
                                            }
                                            required={jobVisible}
                                            name="job_syouryu"
                                            initialValue={
                                                initialData.job_syouryu
                                            }
                                            {...streamTermsContentFormItemLayout}>
                                            <Radio.Group>
                                                <Radio value={1}>なし</Radio>
                                                <Radio value={4}>
                                                    エンド直・元請直まで
                                                </Radio>
                                                <Radio value={3}>
                                                    1次請まで
                                                </Radio>
                                                <Radio value={2}>
                                                    2次請まで
                                                </Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </div>

                                    <Form.Item
                                        name="has_send_guide"
                                        label={
                                            <span>
                                                ご案内配信&nbsp;
                                                <Tooltip
                                                    title={
                                                        <span>
                                                            取引先担当者がご案内配信を希望しない場合は無効にしてください。（ご案内の例：年末年始のご案内、オフィス移転のご案内等）
                                                            <a
                                                                href={
                                                                    Links.helps
                                                                        .contacts
                                                                        .settingHasSendGuide
                                                                }
                                                                target="_blank"
                                                                rel="noopener noreferrer">
                                                                詳細
                                                            </a>
                                                        </span>
                                                    }>
                                                    <QuestionCircleFilled
                                                        style={{
                                                            color: iconCustomColor,
                                                        }}
                                                    />
                                                </Tooltip>
                                            </span>
                                        }
                                        className={styles.field}
                                        {...streamTermsFormItemLayout}>
                                        <Switch
                                            checkedChildren={<CheckOutlined />}
                                            unCheckedChildren={
                                                <CloseOutlined />
                                            }
                                            onChange={() =>
                                                this.setState({
                                                    hasSendGuide: !hasSendGuide,
                                                })
                                            }
                                            checked={hasSendGuide}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        {...tailLayout}
                                        className={styles.field}
                                    />
                                </TabPane>
                                {initialData.id && (
                                    <TabPane tab={<EmailHistoryTabTitle isPersonalPlan={isPersonalPlan} />}
                                             key="4"
                                             disabled={isPersonalPlan}
                                    >
                                        <ContactScheduledEmailTabPaneContent openRank={initialData?.open_rate__rank}/>
                                    </TabPane>
                                )}
                                {initialData.id && (
                                    <TabPane tab="所属取引先" key="3">
                                        <Row>
                                            <Col span={18}>
                                                {this.organizationList()}
                                                <Form.Item
                                                    {...tailLayout}
                                                    className={
                                                        styles.field
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    </TabPane>
                                )}
                            </Tabs>
                            {!initialData.id ? (
                                <div>
                                    <Title level={5}>コメント</Title>
                                    <Form.Item
                                        name="comment.content"
                                        className="validate-textarea"
                                        rules={[
                                            {
                                                max: MaxCommentLength,
                                                message:
                                                    ErrorMessages.validation
                                                        .length.max2000,
                                            },
                                            {
                                                validator: (_, value) => {
                                                    if (ONLY_SPACES_AND_NEWLINE_REGEX.test(value)) {
                                                        this.setState({ errorMessage: ErrorMessages.validation.regex.notOnlySpace });
                                                        return Promise.reject(new Error(ErrorMessages.validation.regex.notOnlySpace));
                                                    }
                                                    this.setState({ errorMessage: "" });
                                                    return Promise.resolve();
                                                },
                                            }
                                        ]}>
                                        <UserMentionsForms
                                            value={this.state.content}
                                            onChange={this.onChangeContent}
                                            maxLength={1024}
                                            autoSize={{ minRows: 5 }}
                                            status={
                                                this.state.content.length > MaxCommentLength || errorMessage
                                                    ? "error"
                                                    : undefined
                                            }
                                        />
                                    </Form.Item>
                                    <ValidateTextArea
                                        contentTextArea={this.state.content}
                                        errorMessages={
                                            ErrorMessages.validation.length
                                                .max2000
                                        }
                                        margin="0px"
                                        className="validate-textarea-error"
                                        extraErrorMessage={errorMessage}
                                    />
                                    <Form.Item>
                                        <Tooltip
                                           title={createCommentPinTooltipMessage()}>
                                            <Button
                                                className={styles.controlButton}
                                                size="small"
                                                onClick={() =>
                                                    this.onChangeCheck()
                                                }
                                                icon={
                                                    this.state.checked ? (
                                                        <PushpinFilled />
                                                    ) : (
                                                        <PushpinOutlined />
                                                    )
                                                }
                                                type={"primary"}
                                                disabled={
                                                    !commentPinAuthorization
                                                }
                                            />
                                        </Tooltip>
                                        <Tooltip
                                            title={isPersonalPlan ? (
                                                <span>
                                                    この機能はライト以上でご利用可能です。アップグレードは
                                                    <TooltipContentLink
                                                        to={Paths.plan.index}
                                                        title="こちら"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    />
                                                    から。
                                                </span>
                                            ) : "コメントのテンプレート選択" }>
                                            <Button
                                                className={styles.controlButton}
                                                size="small"
                                                type="primary"
                                                onClick={() => this.showList()}
                                                icon={<SnippetsOutlined />}
                                                disabled={isPersonalPlan}
                                            />
                                        </Tooltip>
                                    </Form.Item>
                                    <CommentTemplateModal
                                        reducerId={commentsReducerName}
                                        isModalVisible={this.state.listVisible}
                                        onModalClose={() =>
                                            this.setState({
                                                listVisible: false,
                                            })
                                        }
                                        onCreateTemplate={this.showRegister}
                                        onInsertTemplate={
                                            this.setTemplateValueToCommentForm
                                        }
                                        onEditTemplate={this.showUpdate}
                                        onDeleteTemplate={this.showDelete}
                                    />
                                    <CommentTemplateCreateModal
                                        isOpen={this.state.registerVisible}
                                        onOk={this.registerOnOk}
                                        onCancel={this.registerOnCancel}
                                    />
                                    <CommentTemplateUpdateModal
                                        initialData={
                                            comment_templates[selectedIndex] &&
                                            comment_templates[selectedIndex]
                                                .title &&
                                            comment_templates[selectedIndex]
                                                .content
                                                ? {
                                                      newTitle:
                                                          comment_templates[
                                                              selectedIndex
                                                          ].title,
                                                      newContent:
                                                          comment_templates[
                                                              selectedIndex
                                                          ].content,
                                                  }
                                                : {
                                                      newTitle: "",
                                                      newContent: "",
                                                  }
                                        }
                                        isOpen={this.state.updateVisible}
                                        onOk={this.updateOnOk}
                                        onCancel={this.updateOnCancel}
                                    />
                                </div>
                            ) : (
                                <></>
                            )}
                            {comments}
                            <Divider />
                            <Row>
                                <Col span={12}>
                                    <Form.Item>
                                        <Row justify="start">
                                            {initialData.id && (
                                                <BackButton
                                                    fallback={
                                                        Paths.contacts +
                                                            "?page=1"
                                                    }
                                                />
                                            )}
                                            {(updateAuthorized &&
                                                initialData.id) ||
                                            createAuthorized ? (
                                                <Form.Item shouldUpdate>
                                                    {() => (
                                                        <Button
                                                            type="primary"
                                                            htmlType="submit"
                                                            className={
                                                                styles.button
                                                            }
                                                            disabled={
                                                                created ||
                                                                (this.baseform
                                                                    .current &&
                                                                    (!!this.baseform.current
                                                                        .getFieldsError()
                                                                        .filter(
                                                                            ({
                                                                                errors,
                                                                            }) =>
                                                                                errors.length
                                                                        )
                                                                        .length ||
                                                                        !this.baseform.current.isFieldsTouched(
                                                                            requiredFields,
                                                                            true
                                                                        )))
                                                            }>
                                                            {initialData.id
                                                                ? "更新"
                                                                : "登録"}
                                                        </Button>
                                                    )}
                                                </Form.Item>
                                            ) : (
                                                <Tooltip
                                                    title={
                                                        "特定の権限で操作できます"
                                                    }>
                                                    <Button
                                                        type="primary"
                                                        htmlType="submit"
                                                        className={
                                                            styles.button
                                                        }
                                                        disabled={true}>
                                                        {initialData.id
                                                            ? "更新"
                                                            : "登録"}
                                                    </Button>
                                                </Tooltip>
                                            )}
                                        </Row>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Row justify="end">{deleteButton}</Row>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Spin>
        );
    }
}

ContactForm.propTypes = {
    resourceURL: PropTypes.string.isRequired,
    initialData: PropTypes.shape({
        // Corresponds to backend API.
        id: PropTypes.string,
        last_name: PropTypes.string,
        first_name: PropTypes.string,
        organization: PropTypes.string, // Foreign key
        position: PropTypes.string,
        department: PropTypes.string,
        email: PropTypes.string,
        //    last_visit: PropTypes.instanceOf(dayjs),
        score: PropTypes.number,
        tags: PropTypes.arrayOf(PropTypes.string),
        ccEmail1: PropTypes.string,
        ccEmail2: PropTypes.string,
        ccEmail3: PropTypes.string,
        ccEmails: PropTypes.string,
        staff: PropTypes.string, // Foreign key
        comment: PropTypes.shape({
            content: PropTypes.string,
            is_important: PropTypes.bool,
        }),
        contactjobtypepreferences: PropTypes.arrayOf(PropTypes.string),
        contactjobskillpreferences: PropTypes.arrayOf(PropTypes.string),
        contactpersonneltypepreferences: PropTypes.arrayOf(PropTypes.string),
        contactpersonnelskillpreferences: PropTypes.arrayOf(PropTypes.string),
        wants_location_hokkaido_japan: PropTypes.bool,
        wants_location_touhoku_japan: PropTypes.bool,
        wants_location_kanto_japan: PropTypes.bool,
        wants_location_kansai_japan: PropTypes.bool,
        wants_location_chubu_japan: PropTypes.bool,
        wants_location_kyushu_japan: PropTypes.bool,
        wants_location_other_japan: PropTypes.bool,
        wants_location_chugoku_japan: PropTypes.bool,
        wants_location_shikoku_japan: PropTypes.bool,
        wants_location_toukai_japan: PropTypes.bool,
        job_koyou_proper: PropTypes.bool,
        job_koyou_free: PropTypes.bool,
        job_syouryu: PropTypes.number,
        personnel_syouryu: PropTypes.number,
        is_archived: PropTypes.bool,
    }), // Override in child class and use PropTypes.shape instead.
    fieldErrors: PropTypes.shape({
        last_name: PropTypes.arrayOf(PropTypes.string),
        first_name: PropTypes.arrayOf(PropTypes.string),
        organization: PropTypes.arrayOf(PropTypes.string),
        tel: PropTypes.arrayOf(PropTypes.string),
        position: PropTypes.arrayOf(PropTypes.string),
        department: PropTypes.arrayOf(PropTypes.string),
        email: PropTypes.arrayOf(PropTypes.string),
        last_visit: PropTypes.arrayOf(PropTypes.string),
        score: PropTypes.arrayOf(PropTypes.string),
        tags: PropTypes.arrayOf(PropTypes.string),
        ccEmail1: PropTypes.arrayOf(PropTypes.string),
        ccEmail2: PropTypes.arrayOf(PropTypes.string),
        ccEmail3: PropTypes.arrayOf(PropTypes.string),
        ccEmails: PropTypes.arrayOf(PropTypes.string),
        staff: PropTypes.arrayOf(PropTypes.string),
        is_archived: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    submitHandler: PropTypes.func.isRequired,
    resetFormHandler: PropTypes.func.isRequired,
    created: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    tagResisterResult: PropTypes.string,
    comment_templates: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            content: PropTypes.string.isRequired,
        })
    ).isRequired,
    pageId: PropTypes.string,
    authorizedActions: PropTypes.object.isRequired,
    selectedRequireItemKeys: PropTypes.arrayOf(PropTypes.string),
    comments: PropTypes.object,
    deleteButton: PropTypes.object,
    commentsReducerName: PropTypes.string,
    planSummary: PropTypes.object,
};

ContactForm.defaultProps = {
    initialData: {},
    created: false,
    last_visit: undefined,
    tagResisterResult: undefined,
    comment_templates: [],
};

function mapStateToProps(state) {
    return {
        token: state.login.token,
        authorizedActions: state.login.authorizedActions,
        checkEmailExistRegister: state.checkEmailExistRegister,
        checkEmailExistEdit: state.checkEmailExistEdit,
        initialDataRegisterOrg: state.organizationRegisterPage.initialData,
        initialDataEditOrg: state.organizationEditPage.initialData,
        planSummary: state.planSummary
    };
}

const _ContactFormWrapper = finalized(ContactForm);

const ContactFormWrapper = connect(mapStateToProps)(_ContactFormWrapper);

export default ContactFormWrapper;
